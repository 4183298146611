import React from "react";
import {connect} from "react-redux";
import {CallbackComponent} from "redux-oidc";
import userManager from "../../config/userManager";
import {CallHistoryMethodAction, push} from "connected-react-router";
import {Dispatch} from "redux";
import {User} from "oidc-client";
import {LocalizationEnum, localize} from '../../localization';

interface IProps {
    dispatch: Dispatch<CallHistoryMethodAction>;
}

class CallbackModule extends React.Component<IProps> {
    render() {

        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user: User) => {
                    let redirectPath = user.state.path as string;
                    this.props.dispatch(push(redirectPath));
                }}
                errorCallback={error => {
                    // TODO Воспроизвести когда срабатывает этот колбэк
                    this.props.dispatch(push('/'));
                    console.error(error);
                }}
            >
                <div>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</div>
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackModule);

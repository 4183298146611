import { FC, useContext } from 'react';
import classNames from 'classnames';
import { WorkPlanningsContent } from '../components/LineContent/variants/workPlannings/WorkPlanningsContent';
import { WorkPlanningItemContext } from '../../../../../context/CalendarItemContext';
import { DraftBorder } from '../components/Borders/common/DraftBorder';
import { CalendarColors } from '../../../../../utils/calendarColors';

export const WorkPlanningsCalendarItem: FC = () => {
    const { item } = useContext(WorkPlanningItemContext);

    const {
        start_time,
        end_time,
        isDraft,
        record: { stateCode },
    } = item;
    const border = `1px dashed ${CalendarColors.workPlanningsStateCodeMap[stateCode]}`;

    return (
        <div className={classNames('rct-item-content', 'calendar-item-content')}>
            {isDraft && <DraftBorder border={border} isRounded={false} start_time={start_time} end_time={end_time} />}
            <WorkPlanningsContent />
        </div>
    );
};

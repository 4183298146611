import { EntityActionsHook } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { LocationInfoRead } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { EntityActionType } from '../../../../../../../index';
import { PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { locationsApi, useArchiveLocationsMutation, useDeleteLocationMutation } from '../api/locations.api';

export const useLocationEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const [archiveLocations, { isLoading: isArchiveLocationsLoading }] = useArchiveLocationsMutation();

    const [deleteLocation, { isLoading: isDeleteLocationLoading }] = useDeleteLocationMutation();

    const isLoading = isArchiveLocationsLoading || isDeleteLocationLoading;

    const editLocationCallback = useCallback(
        (
            location: LocationInfoRead,
            options?: {
                withView?: boolean;
            }
        ) => {
            dispatch(
                locationsApi.endpoints.locationById.initiate(
                    {
                        businessAccountId,
                        entityId: location.id,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());
            searchParams.append('modal', 'edit');
            searchParams.append('id', String(location.id));

            if (options?.withView) {
                const booleanParam = PageURLParamDescriptions.booleanParam.toString(true);
                if (booleanParam) {
                    searchParams.append('view', booleanParam);
                }
            }

            const path = `${rootPath}/?` + searchParams.toString();
            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editLocationForDrawerCallback = useCallback(
        (location: LocationInfoRead) => {
            editLocationCallback(location, {
                withView: true,
            });
        },
        [editLocationCallback]
    );

    const archiveLocationCallback = useCallback(
        (locationRecord: LocationInfoRead) => {
            archiveLocations({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: locationRecord.id,
                            archive: !locationRecord.archive,
                            businessVersion: locationRecord.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveLocations]
    );

    const deleteLocationCallback = useCallback(
        async (location: LocationInfoRead) => {
            deleteLocation({
                id: location.id,
                businessVersionObj: {
                    businessVersion: location.businessVersion,
                },
            });
        },
        [deleteLocation]
    );

    const onRowAction = useCallback(
        (item: LocationInfoRead, action: EntityActionType) => {
            const actionHandler: Partial<Record<typeof action, Function>> = {
                edit: () => editLocationCallback(item),
                archive: () => archiveLocationCallback(item),
                delete: () => deleteLocationCallback(item),
            };

            actionHandler[action]?.();
        },
        [archiveLocationCallback, deleteLocationCallback, editLocationCallback]
    );

    return useMemo(
        () => ({
            editAction: editLocationCallback,
            editActionForDrawer: editLocationForDrawerCallback,
            archiveActionForDrawer: archiveLocationCallback,
            deleteActionForDrawer: deleteLocationCallback,
            onRowAction,
            isLoading,
        }),
        [archiveLocationCallback, deleteLocationCallback, editLocationCallback, editLocationForDrawerCallback, isLoading, onRowAction]
    );
}) satisfies EntityActionsHook<LocationInfoRead>;

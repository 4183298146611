import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../shared/reducers';
import { connect } from 'react-redux';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { goBack, push } from 'connected-react-router';
import { offerModalFormFields } from '../offerModalFormFields';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { ModalActionData } from '../../../../../components/modalForm/utils';
import { OfferCreateModalFormData } from './offerCreateModal.types';
import { ProjectInfoCreate, ProjectTypeCodeEnum, TypeOfPricingCodeEnum } from '../../../../../server';
import { createOffer } from '../../reducers/offer/offer.reducer';
import { getStore } from '../../../../../../index';
import { showNotification } from '../../../../../components/notification/showNotification';
import { projectsPageUrlRoute } from '../../../projects/projectsPage';

import { ProjectsPageTabsEnum } from '../../../../../shared/constants/tabEnums';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {}

export class _OfferCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: offerModalFormFields,
    };

    onOk = async (data: unknown) => {
        const { businessAccountId } = this.props;
        const formData = data as OfferCreateModalFormData;
        if (formData.renterId) {
            const projectInfoCreate: ProjectInfoCreate = {
                renterId: formData.renterId,
                shortName: formData.shortName,
                fullName: formData.fullName,
                mainProjectId: formData.mainProjectId,
                partOfThePrice: formData.partOfThePrice,
                stateCode: formData.stateCode,
                projectType: ProjectTypeCodeEnum.OFFER,
                typeOfPricing: TypeOfPricingCodeEnum.STRAIGHT,

                assigneeId: formData.assigneeId,
                hasOwnAssignee: !formData.useMainProjectAssignee,
                description: formData.description,
                comment: formData.comment,

                sourceProjectId: formData.sourceProjectId,
                copyWorkPlannings: formData.copyParams?.includes('copyWorkPlannings') || false,
                copyRentElements: formData.copyParams?.includes('copyRentElements') || false,
                copyExpenses: formData.copyParams?.includes('copyExpenses') || false,
                isSimpleOrder: false,
                saveCrewMembers: true,
                saveTrackedInstances: true,
                projectContract: {} // #2120
            };

            getStore()
                .dispatch(
                    createOffer({
                        businessAccountId,
                        projectInfoCreate,
                    })
                )
                .then((offer) => {
                    showNotification('success', 'Смета создана');
                    this.props.push(`/${offer.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}/${offer.id}`);
                    this.props.onSuccess?.(null);
                })
                .catch((error) => {
                    showNotification('error', 'Не удалось создать смету');
                });
        }
    };

    onCancel = async (isFieldsTouched: boolean) => {
        const yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.goBack();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.offer.updating,
    updatingError: storeState.offer.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    push,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OfferCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_OfferCreateModal));

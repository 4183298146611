import { EntityGridName } from '../../../components/page/entityGrid/types/grid';
import { EntityGridRootPath } from '../../../components/page/entityGrid/types/params';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { useEffect } from 'react';
import { getGridStorageData } from '../../../components/grid/utils';
import _ from 'lodash';
import { getPathFromState } from '../../../shared/util/utils';
import { replace } from 'connected-react-router';

export const useCheckUrlParamsOnMountEffect = <Params extends object>(
    currentParams: Params,
    gridName: EntityGridName,
    rootPath: EntityGridRootPath
) => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (location.search) return;

        const storageData = getGridStorageData(gridName);
        const paramsFromStorage = {
            ...storageData.filters,
            ...storageData.params,
        };

        const isCurrentParamsEqualsInStorageData = Object.entries(paramsFromStorage)
            .filter((param) => param != null)
            .every(([key, value]) => currentParams.hasOwnProperty(key) && _.isEqual(value, currentParams[key]));

        if (isCurrentParamsEqualsInStorageData) return;

        const path = getPathFromState(rootPath, '', paramsFromStorage);

        dispatch(replace(path));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

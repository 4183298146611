import React, { FC } from 'react';
import { Row } from 'antd';
import { CrewMemberInfoRead } from '../../../../../../../../../server';
import { ContactBlock } from './components/ContactBlock/ContactBlock';
import { InfoBlock } from './components/InfoBlock/InfoBlock';
import { ContactModal } from './components/ContactModal/ContactModal';
import { useGetContactByIdQuery } from '../../../../../../../renters/tabs/contacts/api/contactsApi';
import { businessAccountIdSelector } from '../../../../../../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../../../../../../store/hooks';

interface CrewMemberDescriptionProps {
    entity: CrewMemberInfoRead;
}

export enum CrewMemberDescriptionModalType {
    CREW_MEMBER = 'crewMember',
    CONTACT = 'contact',
}

export const CrewMemberDescription: FC<CrewMemberDescriptionProps> = (props) => {
    const { entity } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const { data: contact, isLoading: contactIsLoading } = useGetContactByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: entity.contact.id,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    return (
        <>
            <Row gutter={32}>
                {/* Левый блок */}
                <ContactBlock crewMember={entity} contact={contact} contactIsLoading={contactIsLoading} />
                {/* Правый блок */}
                <InfoBlock entity={entity} />
            </Row>
            {<ContactModal crewMember={entity} contact={contact} />}
        </>
    );
};

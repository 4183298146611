import { EntityLifecycleEventTypeCode } from '../../../../../../../../server';
import { Icon } from 'antd';
import { IconEdit, IconExchangeAltSolid, IconPlus, IconTrash } from '../../../../../../../../components/icons';
import React from 'react';

export const lifecycleEventIconMap: Record<EntityLifecycleEventTypeCode, JSX.Element> = {
    [EntityLifecycleEventTypeCode.CREATE]: <Icon style={{ color: '#34bfa3' }} component={IconPlus} />,
    [EntityLifecycleEventTypeCode.UPDATE]: <Icon style={{ color: '#383f77' }} component={IconEdit} />,
    [EntityLifecycleEventTypeCode.DELETE]: <Icon style={{ color: '#F4516C' }} component={IconTrash} />,
    [EntityLifecycleEventTypeCode.WORKFLOWTRANSITION]: <Icon style={{ color: '#fc8c2f' }} component={IconExchangeAltSolid} />,
};

import React, { useContext } from 'react';
import { EntityActions } from '../../../hooks/useRowActions';
import { DrawerUrlContext } from '../../context/DrawerUrlContext';
import { EntityGridDrawerWrapper } from '../../../types/drawer';
import { usePageUrlParamsContext } from '../../context/PageUrlParamsContext';

export interface EntityDrawerProps<EntityInfoRead extends object> {
    drawer: EntityGridDrawerWrapper<EntityInfoRead>;
    entityActions: EntityActions<EntityInfoRead>;
    refetchList: () => void;
}

export const EntityDrawer = <PageParams extends object, EntityInfoRead extends object>(props: EntityDrawerProps<EntityInfoRead>) => {
    const { drawer: Drawer, entityActions, refetchList } = props;
    const { pageParams } = usePageUrlParamsContext<PageParams>();

    const clearEntityId = useContext(DrawerUrlContext)[1];

    return pageParams.view && pageParams.id ? (
        <Drawer
            entityId={pageParams.id}
            onClose={clearEntityId}
            editActionForDrawer={entityActions?.editActionForDrawer}
            deleteActionForDrawer={entityActions?.deleteActionForDrawer}
            archiveActionForDrawer={entityActions?.archiveActionForDrawer}
            copyActionForDrawer={entityActions?.copyActionForDrawer}
            isLoading={entityActions.isLoading}
            refetchEntitiesList={refetchList}
        />
    ) : null;
};

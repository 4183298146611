import { CSSProperties, FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Icon, Popover } from 'antd';
import { getImagePath, isDefined } from '../../../shared/util/utils';
import { IconNoImage } from '../../icons';
import { ImageObj, NomenclatureEntityTypeCodeEnum } from '../../../server';
import { getStoreState, GridRecord } from '../../../../index';
import { Avatar } from '../../avatar/avatar';
import ReactDOM from 'react-dom';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface TableCellAvatarRendererProps {
    value?: ImageObj;
    rowData?: GridRecord;
    opts?: {
        highlight: boolean;
        isKit?: boolean;
    };
}

export const TableCellAvatarRenderer: FC<TableCellAvatarRendererProps> = memo((props) => {
    const { value, rowData, opts } = props;
    const { gridViewMode } = getStoreState().userSettings;
    const popoverRef = useRef(null);
    const avatarRef = useRef<HTMLAnchorElement>(null);
    const [currentYOffset, setCurrentYOffset] = useState<number | undefined>(undefined);

    const changePopoverPlacement = useCallback(() => {
        const popoverNode = ReactDOM.findDOMNode(popoverRef.current);

        const nodeRect = (popoverNode as Element)?.getBoundingClientRect();
        if (nodeRect) {
            setCurrentYOffset(nodeRect.y);
        }
    }, []);

    const isKit = opts?.isKit || (rowData && (('nomenclatureEntityTypeCode' in rowData && rowData.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.KIT) ||
        (!('nomenclatureEntityTypeCode' in rowData) && 'kitId' in rowData && isDefined(rowData.kitId))));

    const highlight = opts?.highlight || isKit;
    const borderColor = isKit ? '#383e75' : '#3d64d4';

    const highlightStyles: CSSProperties|undefined = highlight  ? {
        border: `3px solid ${borderColor}`,
        borderRadius: '50%',
        boxSizing: 'content-box',
        marginTop: gridViewMode === 'compact' && highlight ? -1 : -3,
    } : undefined;

    const popoverPlacement: TooltipPlacement = currentYOffset ? (currentYOffset > 250 ? 'topLeft' : 'bottomLeft') : 'topLeft';
    const avatarSize = gridViewMode === 'compact' && highlight ? 34 : 40;

    useEffect(() => {
        if (value) {
            window.addEventListener('scroll', changePopoverPlacement, true);
        }

        return () => {
            if (value) {
                window.removeEventListener('scroll', changePopoverPlacement);
            }
        };
    });

    return value ? (
        <div
            style={{
                borderRadius: '50%',
            }}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.stopPropagation();
                let el = e.target as HTMLElement;
                if (el.classList.contains('rr-grid-full-image')) window.open(getImagePath(value), '_blank');
            }}
        >
            <Popover
                ref={popoverRef}
                key={value.id}
                content={
                    <a ref={avatarRef} target={'_blank'} rel="noopener noreferrer" href={getImagePath(value)}>
                        <img alt={''} className={'rr-grid-full-image'} src={getImagePath(value, '-x200')} />
                    </a>
                }
                placement={popoverPlacement}
                trigger="hover"
            >
                <Avatar
                    size={avatarSize}
                    style={{
                        backgroundColor: '#ffffff',
                        verticalAlign: 'middle',
                        ...highlightStyles,
                    }}
                    src={getImagePath(value, '40')}
                >
                    <span style={{fontSize: 18}}>
                        <Icon component={IconNoImage} style={{ color: '#DDDDDD' }} />
                    </span>
                </Avatar>
            </Popover>
        </div>
    ) : (
        <Avatar
            size={avatarSize}
            style={{
                backgroundColor: '#ffffff',
                verticalAlign: 'middle',
                ...highlightStyles,
            }}
        >
            <span style={{ fontSize: 18 }}>
                <Icon component={IconNoImage} style={{ color: '#DDDDDD' }} />
            </span>
        </Avatar>
    );
});

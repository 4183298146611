import { useContactEntityActions } from '../../../../../../../../../renters/tabs/contacts/hooks/useContactEntityActions';
import { CrewMemberDescriptionModalType } from '../../CrewMemberDescription';
import { Route } from 'react-router';
import { ContactEditModalWrapper } from '../../../../../../../../../renters/tabs/contacts/modal/edit/ContactEditModalWrapper';
import { ContactDrawerWrapper } from '../../../../../../../../../renters/tabs/contacts/drawer/components/wrappers/ContactDrawerWrapper';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { businessAccountIdSelector } from '../../../../../../../../../../../shared/reducers/system.reducer';
import { useLocation } from 'react-router-dom';
import { useSetUrlForDrawer } from '../../../../../../../../../../../core/hooks/useDrawer';
import { ContactInfoRead, CrewMemberInfoRead } from '../../../../../../../../../../../server';
import { CrewMemberUtils } from '../../../../../../utils/helpers';

import { CrewMemberPageTabsEnum } from '../../../../../../../../../../../shared/constants/tabEnums';

interface ContactModalProps {
    crewMember: CrewMemberInfoRead;
    contact?: ContactInfoRead;
}

export const ContactModal: FC<ContactModalProps> = (props) => {
    const { crewMember, contact } = props;

    const location = useLocation();
    const businessAccountId = useSelector(businessAccountIdSelector);

    const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries());
    const currentRootPath = CrewMemberUtils.getRootPath(businessAccountId);
    const currentCardPath = CrewMemberUtils.getCardPath(businessAccountId, crewMember.id);

    const [setEntityId, clearEntityId] = useSetUrlForDrawer<CrewMemberInfoRead>(currentCardPath, new URLSearchParams());

    const contactEntityActions = useContactEntityActions({
        rootPath: currentCardPath,
        urlSearchParams: new URLSearchParams({
            tab: CrewMemberPageTabsEnum.DESCRIPTION,
            type: CrewMemberDescriptionModalType.CONTACT,
        }),
    });

    return (
        <>
            {searchParams.modal === 'edit' &&
                searchParams.id &&
                searchParams.type === CrewMemberDescriptionModalType.CONTACT &&
                contact && <Route path={currentRootPath} render={() => <ContactEditModalWrapper entity={contact} />} />}
            {searchParams.modal === 'view' && searchParams.id && (
                <Route
                    path={currentRootPath}
                    render={() => (
                        <ContactDrawerWrapper
                            entityId={crewMember.contact.id}
                            onClose={() => clearEntityId()}
                            editActionForDrawer={contactEntityActions.editActionForDrawer}
                            archiveActionForDrawer={contactEntityActions.archiveActionForDrawer}
                            deleteActionForDrawer={contactEntityActions.deleteActionForDrawer}
                        />
                    )}
                />
            )}
        </>
    );
};

import React from 'react';
import {FormItemType, SelectItem} from '../../../../components/dynamicForm/DynamicForm';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../shared/util/validateField';
import { ProjectStateCodeEnum, ProjectTypeCodeEnum } from '../../../../server';
import { LocalizationEnum, localize } from '../../../../localization';
import { ProjectSelectProps } from '../../../../components/v2/select/projectSelect/projectSelect';
import { FormUtils, TypedFormField, WrappedFormUtilsTyped } from '../../../../core/utils/formUtils';
import { OfferCreateModalFormData } from './create/offerCreateModal.types';
import { UserSelect } from '../../../../components/v2/select/userSelect/userSelect';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { getStoreState } from '../../../../../index';
import { offerAllStateCodes, offerCreationStateCodes } from '../data/offersData';
import { OfferStateBadge } from '../components/OfferStateBadge/OfferStateBadge';
import { ProjectSelectAndCreate } from '../../../../components/v2/select/projectSelect/projectSelectAndCreate';
import {crewModuleEnabledSelector, expensesModuleEnabledSelector} from "../../../../shared/reducers/businessAccountPreferences.reducer";

const idField: TypedFormField<OfferCreateModalFormData> = {
    id: 'id',
    type: FormItemType.Hidden,
};

const copyParamsField: TypedFormField<OfferCreateModalFormData> = {
    label: 'Скопировать',
    id: 'copyParams',
    type: FormItemType.CheckboxGroup,
    values: ()=>{
        const crewModuleEnabled = crewModuleEnabledSelector(getStoreState());
        const expensesModuleEnabled = expensesModuleEnabledSelector(getStoreState());

        return [
            {
                name: 'Обязательства',
                value: 'copyRentElements'
            },
            crewModuleEnabled ? {
                name: 'Работы',
                value: 'copyWorkPlannings'
            } : undefined,
            expensesModuleEnabled ? {
                name: 'Затраты',
                value: 'copyExpenses'
            } : undefined
        ].filter(item=>item !== undefined) as SelectItem[];
    },
    className: 'rr-checkbox-group-vertical',
};

const sourceProjectIdField: TypedFormField<OfferCreateModalFormData> = {
    id: 'sourceProjectId',
    type: FormItemType.Hidden,
};

const shortTitleField: TypedFormField<OfferCreateModalFormData> = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
    id: 'shortName',
    type: FormItemType.String,
    required: true,
    maxLength: 50,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<OfferCreateModalFormData>) => {
        const mainProjectId = form.getFieldValue('mainProjectId');
        if (mainProjectId) {
            const ignoreCurrentId = form.getFieldValue('id');
            validateField(
                'project',
                'offerShortName',
                value,
                mainProjectId,
                ignoreCurrentId,
                'Краткое наименование уже используется в текущем проекте',
                cb
            );
        } else cb();
    }, 500),
};

const fullNameField: TypedFormField<OfferCreateModalFormData> = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
    id: 'fullName',
    type: FormItemType.String,
    required: false,
    maxLength: 255,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<OfferCreateModalFormData>) => {
        let mainProjectId = form.getFieldValue('mainProjectId');
        if (mainProjectId) {
            const ignoreCurrentId = form.getFieldValue('id');
            validateField(
                'project',
                'offerFullName',
                value,
                mainProjectId,
                ignoreCurrentId,
                'Полное наименование уже используется в текущем проекте',
                cb
            );
        } else cb();
    }, 500),
};

const mainProjectField: TypedFormField<OfferCreateModalFormData> = {
    label: 'Основной проект',
    id: 'mainProjectId',
    type: FormItemType.Component,
    component: ProjectSelectAndCreate,
    required: true,
    componentProps: (form): ProjectSelectProps => ({
        filters: {
            stateCode: [ProjectStateCodeEnum.DRAFT, ProjectStateCodeEnum.INPROGRESS, ProjectStateCodeEnum.FINISHED],
            projectType: [ProjectTypeCodeEnum.BASE],
            archive: false,
        },
        onDataChange: (record) => {
            const project = record?.[0];
            form.setFieldsValue({
                renterId: project?.renterId,
                mainProjectAssigneeId: project?.assigneeId,
                assigneeId: form.getFieldValue('useMainProjectAssignee') === true ? project?.assigneeId : form.getFieldValue('assigneeId'),
            });
            form.validateFields(['shortName', 'fullName']);
        },
    }),
    disabled: (getFieldValue) => !!getFieldValue('id'),
};

const mainProjectAssigneeIdField: TypedFormField<OfferCreateModalFormData> = {
    id: 'mainProjectAssigneeId',
    type: FormItemType.Hidden,
};

const partOfThePriceField: TypedFormField<OfferCreateModalFormData> = {
    label: 'Включена в стоимость проекта',
    id: 'partOfThePrice',
    type: FormItemType.Switch,
    defaultValue: false,
    visible: (getFieldValue) => {
        return canViewFinancialDataSelector(getStoreState());
    },
};

const statusField: TypedFormField<OfferCreateModalFormData> = {
    label: LocalizationEnum.ASPECT__FILTERS__STATUS,
    placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
    id: 'stateCode',
    type: FormItemType.Select,
    defaultValue: ProjectStateCodeEnum.COORDINATION,
    required: true,
    values: (form) => {
        return (form.getFieldValue('id') ? offerAllStateCodes : offerCreationStateCodes).map((code) => ({
            name: <OfferStateBadge type={code} dot={true} />,
            value: code,
        }));
    },
    disabled: (getFieldValue) => !!getFieldValue('id'),
};

const hasOwnAssigneeField: TypedFormField<OfferCreateModalFormData> = {
    label: 'По основному проекту',
    id: 'useMainProjectAssignee',
    type: FormItemType.Switch,
    defaultValue: true,
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
    onChange: (value, form) => {
        form.setFieldsValue({
            assigneeId: value ? form.getFieldValue('mainProjectAssigneeId') : undefined,
        });
    },
};

const assigneeIdField: TypedFormField<OfferCreateModalFormData> = {
    id: 'assigneeId',
    type: FormItemType.Component,
    component: UserSelect,
    disabled: (getFieldValue) => getFieldValue('useMainProjectAssignee') === true,
    placeholder: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED),
};

const descriptionField: TypedFormField<OfferCreateModalFormData> = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
    id: 'description',
    type: FormItemType.RichText,
    validationRules: [
        {
            max: 5000,
            message: (
                <>
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                </>
            ),
        },
    ],
};

const commentField: TypedFormField<OfferCreateModalFormData> = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
    id: 'comment',
    type: FormItemType.Text,
    maxLength: 2000,
};

const renterIdField: TypedFormField<OfferCreateModalFormData> = {
    id: 'renterId',
    type: FormItemType.Hidden,
};

// dynamic form fields
export const offerModalFormFields = FormUtils.typedFormFields<OfferCreateModalFormData>([
    {
        fields: [{
            id: 'copy',
            type: FormItemType.Hidden,
        }]
    },
    {
        fields: [copyParamsField],
        visible: (form) => {
            return !!form.getFieldValue('copy');
        },
    },
    {
        fields: [
            idField,
            sourceProjectIdField,
            mainProjectAssigneeIdField,
            renterIdField,
            shortTitleField,
            fullNameField,
            mainProjectField,
            partOfThePriceField,
            statusField,
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE),
        fields: [hasOwnAssigneeField, assigneeIdField],
    },
    {
        fields: [descriptionField],
    },
    {
        fields: [commentField],
    },
]);

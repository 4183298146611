import React, {ReactElement, ReactNode, useCallback, useState} from 'react';
import {Button, Popover, Tooltip} from "antd";
import {PopoverProps} from "antd/lib/popover";
import classNames from "classnames";
import {Action, ActionButtonsData} from "../actionButtonsGroup/actionButtonsGroup";
import {useLocalize} from "../../../../core/hooks/useLocalize";
import './actionButtonsPopover.less';

interface ActionsPopoverProps<T extends string> {
    children: ReactNode;
    title?: ReactElement;
    icon?: ReactElement;
    getPopupContainer?: PopoverProps['getPopupContainer'];
    data: ActionButtonsData<T>;
    actions: Action<T>[];
    onAction?: (action: T) => void;
    trigger?: 'click' | 'hover';
    placement?: 'bottomLeft' | 'rightTop';
    hideHeader?: boolean;
}

export const ActionButtonsPopover = <T extends string, >({
                                                             children,
                                                             title,
                                                             icon,
                                                             actions,
                                                             data,
                                                             onAction,
                                                             trigger,
                                                             hideHeader,
                                                             placement,
                                                             ...props
                                                         }: ActionsPopoverProps<T>) => {

    const L = useLocalize();
    const [visible, setVisible] = useState(false);

    const visibleChangeHandler = useCallback((visible: boolean) => {
        setVisible(visible);
    }, []);

    return (
        <Popover
            visible={visible}
            onVisibleChange={visibleChangeHandler}
            overlayClassName={classNames('rr-actions-popover', 'rr-actions-buttons-popover')}
            autoAdjustOverflow
            arrowPointAtCenter
            placement={placement || 'bottomLeft'}
            trigger={trigger || 'click'}
            getPopupContainer={props.getPopupContainer} // getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
            content={
                <>
                    {
                        !hideHeader && (
                            <div className={'rr-actions-popover-header'}>
                                {
                                    (icon || title) && (
                                        <div>
                                            {icon && <div style={{marginRight: 12, lineHeight: 0}}>{icon}</div>}
                                            {title}
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    <div>
                        {
                            actions.filter(action => action).map((action, index) => {
                                if (action) {
                                    const type = typeof action === 'object' ? action.type : action;
                                    const disabled = typeof action === 'object' ? action.disabled : undefined;
                                    const nestedActions = typeof action === 'object' ? action.nestedActions : undefined;
                                    const tooltip = typeof action === 'object' ? action.tooltip : undefined;
                                    const buttonData = data[type];
                                    let text = 'localizationId' in buttonData ? L(buttonData.localizationId) : undefined;
                                    if ('name' in buttonData) text = buttonData.name;

                                    const button = (
                                        <Button
                                            className={classNames('rr-action-block-btn', `rr-action-block-btn--${buttonData.color}`)}
                                            key={type}
                                            disabled={disabled}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setVisible(false);
                                                onAction?.(type);
                                            }}
                                        >
                                            {buttonData.icon}
                                            {text}
                                        </Button>
                                    );

                                    if (nestedActions && nestedActions.length > 0 && !disabled) {

                                        return (
                                            <ActionButtonsPopover
                                                data={data}
                                                actions={nestedActions}
                                                onAction={(action) => {
                                                    onAction?.(action);
                                                    setVisible(false);
                                                }}
                                                trigger={'hover'}
                                                placement={'rightTop'}
                                                hideHeader={true}
                                            >
                                                {button}
                                            </ActionButtonsPopover>
                                        );

                                    } else {
                                        return tooltip ? <Tooltip title={tooltip}><div>{button}</div></Tooltip> : button;
                                    }
                                }
                            })
                        }
                    </div>
                </>
            }>
            {
                children
            }
        </Popover>
    );
};

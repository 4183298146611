import { Unit } from 'react-calendar-timeline';
import { useMemo } from 'react';

import { MSEC_IN_DAY } from '../../../utils/constants';

export const useDateHeaderUnits = (visibleTimeStart?: number, visibleTimeEnd?: number) => {
    return useMemo(() => {
        let primaryDateHeaderUnit: Unit = 'day';
        let secondaryDateHeaderUnit: Unit = 'hour';

        let diff = 0;
        if (!visibleTimeEnd || !visibleTimeStart) {
            diff = +new Date() - 2 - (+new Date() + 2);
        } else {
            diff = visibleTimeEnd - visibleTimeStart;
        }

        if (diff > MSEC_IN_DAY && diff <= MSEC_IN_DAY * 8) {
            primaryDateHeaderUnit = 'week';
            secondaryDateHeaderUnit = 'day';
        } else if (diff > MSEC_IN_DAY * 8 && diff < MSEC_IN_DAY * 60) {
            primaryDateHeaderUnit = 'month';
            secondaryDateHeaderUnit = 'day';
        } else if (diff >= MSEC_IN_DAY * 31) {
            primaryDateHeaderUnit = 'year';
            secondaryDateHeaderUnit = 'month';
        }

        return {
            primaryDateHeaderUnit,
            secondaryDateHeaderUnit,
        };
    }, [visibleTimeEnd, visibleTimeStart]);
};

import { StateBadgeData } from '../../../../../../components/v2/stateBadge/stateBadge';
import { PaymentStateCodeEnum } from '../../../../../../server';
import { Color } from '../../../../../../core/types/color';

export const paymentStateBadgeData: StateBadgeData<PaymentStateCodeEnum> = {
    [PaymentStateCodeEnum.PLANNED]: {
        color: Color.Azure,
        name: 'Запланирован',
    },
    [PaymentStateCodeEnum.DONE]: {
        color: Color.Green,
        name: 'Проведен',
    },
    [PaymentStateCodeEnum.DELETED]: {
        color: Color.Grey2,
        name: 'Удален',
    },
};

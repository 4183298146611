import React from "react";
import axios from 'axios';
import Spin from "../../components/spin/spin";
import {IRootState} from "../../shared/reducers";
import {connect} from "react-redux";
import {Col, Icon, Modal} from "antd";
import {IconEnvelopeOpen,} from "../../components/icons";
import {rulesList} from "../../config/rules_ru";
import {showNotification} from "../../components/notification/showNotification";
import {ReactComponent as LogoIcon} from "../../assets/images/rr-logo-icon.svg";
import {ReactComponent as LogoText} from "../../assets/images/rr-logo-text.svg";
import './invitation.less';
import {LocalizationEnum, localize, localizeIntl} from '../../localization';
import {injectIntl} from "react-intl";
import {API_SERVER_BASE_PATH} from "../../config/config";
import {hidePreloader} from "../../App";

class ShowInvitation extends React.Component<any> {
    state = {
        data: undefined,
        ref: undefined,
        visible: true,
        err: undefined
    };

    async componentDidMount() {
        await this.setState({
            ref: window.location.pathname.replace('/invitations/', '')
        });

        hidePreloader();

        await axios.get(`${API_SERVER_BASE_PATH}/invitations/${this.state.ref}`)
            .then(response => {

                this.setState({
                    data: response.data
                });
            })
            .catch(error => {
                console.log(error);

                this.setState({
                    data: 'err',
                    err: error
                });
            });
    }

    handleOk = (e) => {
        // push(`/acceptInvitation/${this.state.ref}`)
        window.location.replace(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/acceptInvitation/${this.state.ref}`);
    };

    handleCancel = e => {
        window.location.replace('http://rentrabb.it/');
    };

    render() {
        const data: any = this.state.data;
        if (data === undefined) {
            return <Spin/>;
        }

        if (data === 'err') {
            return (
                <>

                <Modal
                    className="invitation"
                    title={
                        (
                            <>
                                <div className={'rr-menu-logo-container2'} style={{position: 'absolute', backgroundColor: '#8b899a', zIndex: 2001, left: 'calc(50% - 165px)', top: '-120px' }}>
                                    <LogoIcon />
                                    <LogoText />
                                </div>
                                <Icon component={IconEnvelopeOpen}
                                      style={{marginRight: '20px', fontSize: '28px', color: 'rgb(0, 173, 238)'}}/>
                                <span style={{color: '#fff'}}>{localize(LocalizationEnum.PAGE__INVITATIONS__MODAL_TITLE)}</span>
                            </>)}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText={localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__ACCEPT_BUTTON)}
                    cancelText={localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__DECLINE_BUTTON)}
                    maskClosable={false}
                    okButtonProps={{disabled:true}}
                    closable={false}
                    centered
                    wrapClassName="invitation"
                    cancelButtonProps={{type: 'link'}}
                >

                    <Col className='rr-form-item' style={{marginBottom: 29}}>
                        <div className='rr-value'>{localize(LocalizationEnum.PAGE__INVITATIONS__INVITATION_MAIN_TEXT)}</div>
                    </Col>
                    <Col className='rr-form-item' style={{marginBottom: 29}}>
                        <div className='rr-label'>{localize(LocalizationEnum.ASPECT__ENTITY_TYPE__BUSINESS_ACCOUNT)}</div>
                        <div className='rr-value'>-</div>
                    </Col>
                    <Col className='rr-form-item' style={{marginBottom: 29}}>
                        <div className='rr-label'>Роль</div>
                        <div className='rr-value'>-</div>
                    </Col>

                </Modal>
                    { showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__POPUP_NOTIFICATIONS__INVITATION_OUT_OF_DATE))}
                    </>
            );
        }

        const roles = data.baRoles.map((el, num) => {

            let rule:React.ReactElement;


            if (data.baRoles.length===num+1) {
                rule = <span>{rulesList.find(element => element.value === el)!.name} </span>;
            } else {
                rule = <span>{rulesList.find(element => element.value === el)!.name}, </span>;
            }




            return rule;
        });

        return (
            <>
                <Modal
                    title={
                        (
                            <>
                                <div className={'rr-menu-logo-container2'} style={{position: 'absolute', backgroundColor: '#8b899a', zIndex: 2001, left: 'calc(50% - 165px)', top: '-120px' }}>
                                    <LogoIcon />
                                    <LogoText />
                                </div>
                                <Icon component={IconEnvelopeOpen}
                                      style={{marginRight: '20px', fontSize: '28px', color: 'rgb(0, 173, 238)'}}/>
                                <span style={{color: '#fff'}}>{localize(LocalizationEnum.PAGE__INVITATIONS__MODAL_TITLE)}</span>
                            </>)}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText={localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__ACCEPT_BUTTON)}
                    cancelText={localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__DECLINE_BUTTON)}
                    maskClosable={false}
                    centered
                    wrapClassName="invitation"
                    cancelButtonProps={{type: 'link'}}
                >
                    <Col className='rr-form-item' style={{marginBottom: 29}}>
                        <div className='rr-value'>{localize(LocalizationEnum.PAGE__INVITATIONS__INVITATION_MAIN_TEXT)}</div>
                    </Col>
                    <Col className='rr-form-item' style={{marginBottom: 29}}>
                        <div className='rr-label'>{localize(LocalizationEnum.ASPECT__ENTITY_TYPE__BUSINESS_ACCOUNT)}</div>
                        <div className='rr-value'>{data.businessAccountFullName}</div>
                    </Col>
                    <Col className='rr-form-item' style={{marginBottom: 29}}>
                        <div className='rr-label'>Роль</div>
                        <div className='rr-value'>{roles}</div>

                    </Col>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        locationSearchParams: storeState.router.location.search,
    };
};

export default connect(
    mapStateToProps,
)(injectIntl(ShowInvitation));

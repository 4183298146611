import React, {FC, ReactNode} from 'react';
import {Icon} from "antd";
import {PricingSchemeExternalRepresentationObj} from "../../../../server";
import {PricingSchemePricePopover} from "../../settings/pricingSchemes/pricingSchemePricePopover";
import {PricingSchemeMaps} from "../../../../core/utils/pricingSchemeUtils";
import './price.less';

export interface PriceProps {
    price: ReactNode;
    pricingSchemeData?: {
        pricingScheme: PricingSchemeExternalRepresentationObj;
        basePrise: number;
        shiftCount?: number;
    }
}

export const Price: FC<PriceProps> = ({pricingSchemeData, price}) => {

    return (
        <div className={'rr-price'}>
            {pricingSchemeData ? (
                <PricingSchemePricePopover
                    trigger={'hover'}
                    data={{
                        id: pricingSchemeData.pricingScheme.id,
                        typeCode: pricingSchemeData.pricingScheme.typeCode,
                        label: pricingSchemeData.pricingScheme.name,
                        price: pricingSchemeData.basePrise,
                        steps: pricingSchemeData.pricingScheme.steps,
                        shiftCount: pricingSchemeData.shiftCount
                    }}
                >
                    <Icon
                        className={'rr-price-pricingScheme-icon rr-icon-dark'}
                        component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingSchemeData.pricingScheme.typeCode]}
                    />
                </PricingSchemePricePopover>
            ) : null}
            {price}
            {/*<span>{formatMoneyWithFixedDecimalScale(price)}{pricePostfix}</span>*/}
        </div>
    );
};

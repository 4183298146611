import React, { FC, useCallback, useState } from 'react';
import { Popover } from '../../../popover/Popover';
import './ProfessionSelectPopover.less';
import { Button, Icon, Select } from 'antd';
import { EntityRemoteSelect } from '../../EntityRemoteSelect';
import { LabeledValue } from 'antd/lib/select';
import { ProfessionRecord, ProfessionStateCodeEnum } from '../../../../server';
import { IconCheck } from '../../../icons';
import classNames from 'classnames';
import { Dot } from '../../../dot/dot';

interface ProfessionSelectPopoverProps {
    onOk: (entities: ProfessionSelectEntity[]) => void;
    selectedIds?: number[];
}

export type ProfessionSelectEntity = LabeledValue & {
    data: ProfessionRecord;
};

export const ProfessionSelectPopover: FC<ProfessionSelectPopoverProps> = (props) => {
    const { onOk, selectedIds = [], children } = props;

    const [isVisible, setIsVisible] = useState(false);
    const [selectedEntities, setSelectedEntities] = useState<ProfessionSelectEntity[]>([]);

    const onVisibleChange = useCallback((visible: boolean) => {
        setIsVisible(visible);
        if (!visible) setSelectedEntities([]);
    }, []);

    const onChange = useCallback(
        (entities: ProfessionSelectEntity[]) => {
            setSelectedEntities(entities);
        },
        [setSelectedEntities]
    );

    const optionRenderer = useCallback(
        (profession: { id: number; data: ProfessionRecord }) => {
            const { stateCode } = profession.data;
            const isSelected = selectedIds.some((id) => profession.id === id);
            const isBlocked = stateCode === ProfessionStateCodeEnum.BLOCKED;

            return (
                <Select.Option disabled={isSelected || isBlocked} key={profession['id']}>
                    <div>
                        {isSelected && <Icon component={IconCheck} className={classNames('crew-member-icon', 'select-option')} />}
                        {isBlocked && <Dot className={`rr-status-bg-` + stateCode} style={{ marginRight: 10 }} />}
                        {profession['name']}
                    </div>
                </Select.Option>
            );
        },
        [selectedIds]
    );

    return (
        <Popover
            visible={isVisible}
            onVisibleChange={onVisibleChange}
            destroyTooltipOnHide
            headerClassName={'profession-popover-header'}
            overlayClassName={'profession-popover-overlay rr-operationForm-kit-add-element-popover1'}
            header={
                <>
                    <EntityRemoteSelect
                        className={'rr-select-gray'}
                        dropdownStyle={{ maxWidth: 360 }}
                        filters={[`archive;EQ;false`]}
                        maxTagCount={5}
                        multiple={true}
                        nameField={'shortName'}
                        onChange={onChange}
                        operationName={'listProfessions'}
                        placeholder={<>Выберите специальность</>}
                        showSearch={true}
                        sortBy={'lastActivityDate'}
                        sortOrder={'DESC'}
                        style={{ width: '100%' }}
                        value={selectedEntities}
                        renderer={optionRenderer}
                    />
                    <Button
                        className={'rr-btn-blue'}
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                            onOk(selectedEntities);
                            setIsVisible(false);
                            setSelectedEntities([]);
                        }}
                        disabled={!selectedEntities.length}
                    >
                        OK
                    </Button>
                </>
            }
        >
            {children}
        </Popover>
    );
};

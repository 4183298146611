import React, {memo, useState} from 'react';
import {HelpTooltip} from "../../../../../components/helpTooltip/HelpTooltip";
import {LocalizationEnum} from "../../../../../localization";
import CalendarNavigation, {getTimeCalendar} from "../CalendarNavigation/CalendarNavigation";
import CalendarInterval from "../CalendarInterval/CalendarInterval";
import {useVisibleOnScroll} from "./hooks/useVisibleOnScroll";
import classNames from "classnames";
import "./СalendarBottomNavigation.less";
import {CalendarCapture, CalendarGridParams} from "../Calendar/types/params";


import {CalendarPageTabsEnum} from "../../../../../shared/constants/tabEnums";

interface Props {
    positionTop: boolean;
    pageParams: CalendarGridParams,
    pageName: CalendarPageTabsEnum
    defaultCollapsed?: boolean
    screenLeft?: number,
    screenRight?: number,
    capture?: CalendarCapture
}

export const CalendarBottomNavigation = memo(
    (props: Props) => {
        const { positionTop, defaultCollapsed, pageParams, screenLeft, screenRight, capture, pageName} = props
        const [isCollapsed, setIsCollapsed] = useState(Boolean(defaultCollapsed))
        const isVisible = useVisibleOnScroll()

        const isAllMode = capture === 'all' || capture === 'allWithNow';

        const actualScreenLeft = pageParams.screenLeft ?? screenLeft;
        const actualScreenRight = pageParams.screenRight ?? screenRight;

        return (
            <div
                id="calendar-bottom-navigation"
                className={classNames("calendar-bottom-navigation", {
                    visible: isVisible,
                    hidden: !isVisible
                })}
            >
                {isCollapsed ? (
                    <div
                        className={'collapsed'}
                        onClick={() => {
                            setIsCollapsed(false);
                        }}
                    >
                        <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A0}>
                            {getTimeCalendar(screenLeft, screenRight)}
                        </HelpTooltip>
                    </div>
                ) : (
                    <>
                        <div
                            className={"opened"}
                        >
                            <CalendarNavigation
                                pageName={pageName}
                                positionTop={positionTop}
                                crossClose={() => {
                                    setIsCollapsed(true);
                                }}
                                isAllMode={isAllMode}
                                screenLeft={actualScreenLeft}
                                screenRight={actualScreenRight} step={0.2} />
                        </div>
                        <div style={{ border: '1px solid #bbb' }}>
                            <CalendarInterval
                                screenLeft={screenLeft}
                                screenRight={screenRight}
                                capture={capture}
                                pageName={pageName}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
);

import React from 'react';
import userManager from "../../config/userManager";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {CallHistoryMethodAction} from "connected-react-router";
import {RouteComponentProps, withRouter} from "react-router";

interface IProps extends RouteComponentProps {
    dispatch: Dispatch<CallHistoryMethodAction>;
}

class Component extends React.Component<IProps> {

    componentDidMount() {
        userManager.signinRedirect({
            data: {path: window.location.pathname + window.location.search}
        });
    }

    render() {
        return null;
    }
}

export const LoginModule = withRouter(connect()(Component));

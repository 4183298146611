import { serverApi } from '../../../../server';
import { DownloadDocumentTemplateSelectedValue, SUPPORTED_SERVER_METHOD } from '../DownloadDocumentPopover';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { ExportSettings } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface UseHandleVisibleChangeArgs {
    documentsLocalStorage: ExportSettings;
    entityRemoteSelectCustomParams?: any;
    method: SUPPORTED_SERVER_METHOD | undefined;
    onVisibleChange: ((visible: boolean) => boolean) | undefined;
    templateSelectedValue: DownloadDocumentTemplateSelectedValue | undefined;
    setDocumentsLocalStorage: (value: ExportSettings) => void;
    setLoading: (value: boolean) => void;
    setTemplateSelectedValue: (value: DownloadDocumentTemplateSelectedValue | undefined) => void;
    setVisible: (value: boolean) => void;
    withTemplate: boolean | undefined;
}

export const useHandleVisibleChange = (args: UseHandleVisibleChangeArgs) => {
    const {
        documentsLocalStorage,
        entityRemoteSelectCustomParams,
        method,
        onVisibleChange,
        templateSelectedValue,
        setDocumentsLocalStorage,
        setLoading,
        setTemplateSelectedValue,
        setVisible,
        withTemplate,
    } = args;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const checkTemplateById = async (id: number) => {
        setLoading(true);
        setTemplateSelectedValue(documentsLocalStorage.template);
        let res;
        try {
            if (method === SUPPORTED_SERVER_METHOD.LIST_DOCUMENT_TEMPLATES_FOR_REPORT_INSTANCE) {
                res = await serverApi.listDocumentTemplatesForReportChartVisualization(
                    businessAccountId,
                    entityRemoteSelectCustomParams.reportInstanceId,
                    undefined,
                    1,
                    0,
                    [`id;EQ;${id}`],
                    undefined,
                    undefined
                );
            } else {
                res = await serverApi.listDocumentTemplates(
                    businessAccountId,
                    1,
                    0,
                    [`id;EQ;${id}`],
                    undefined,
                    undefined,
                    undefined,
                    false
                );
            }
            if (res.data.records && res.data.records[0]) {
                // Найден
                if (
                    method === SUPPORTED_SERVER_METHOD.LIST_DOCUMENT_TEMPLATES_FOR_REPORT_INSTANCE &&
                    documentsLocalStorage.template &&
                    !documentsLocalStorage.template.label
                ) {
                    setTemplateSelectedValue({
                        key: documentsLocalStorage.template.key,
                        label: res.data.records[0].shortName,
                        common: undefined,
                    });
                }
            } else {
                // Не найден
                setTemplateSelectedValue(undefined);
                setDocumentsLocalStorage({
                    ...documentsLocalStorage,
                    template: undefined,
                });
            }
        } catch (e) {
            setTemplateSelectedValue(undefined);
        }
        setLoading(false);
    };

    return (visible: boolean) => {
        if (onVisibleChange) {
            setVisible(onVisibleChange(visible));
        } else {
            setVisible(visible);
        }
        if (withTemplate && visible && ((templateSelectedValue && templateSelectedValue.key) || documentsLocalStorage.template?.key)) {
            // Получить шаблон
            checkTemplateById(templateSelectedValue?.key || documentsLocalStorage.template?.key);
        }
    };
};

import React, { FC } from 'react';
import { Card, Col, Row } from 'antd';
import { AbstractEntityInfoCard } from '../../../../../../../components';
import { EntityRentIndicatorsBlock } from '../../../../../../../components/currentRentIndicators/entityRentIndicatorsBlock';
import {
    ProjectPageDescriptionAttachmentsBlock,
    ProjectPageDescriptionContactsBlock,
    ProjectPageDescriptionContractBlock,
    ProjectPageDescriptionDescriptionBlock,
    ProjectPageDescriptionLeftBlock,
    ProjectPageDescriptionPeriodAndLocationBlock,
    ProjectPageDescriptionPriceBlock,
} from './blocks';
import { ProjectInfoRead, ProjectTypeCodeEnum } from '../../../../../../../server';
import { ProjectPageDescriptionPriceOfferBlock } from './blocks/projectPageDescriptionOfferPriceBlock';
import {useAppDispatch} from "../../../../../../../store/hooks";
import {loadEntity} from "../../../reducers/project.reducer";

interface ProjectPageDescriptionTabPaneProps {
    entity: ProjectInfoRead | null;
}

export const ProjectPageDescriptionTabPane: FC<ProjectPageDescriptionTabPaneProps> = (props) => {
    const { entity: data } = props;
    const dispatch = useAppDispatch();

    return data ? (
        <Row gutter={32} className={'rr-project-page-description'}>
            {/* Левый блок */}
            <ProjectPageDescriptionLeftBlock data={data} />

            {/* Правый блок блок */}
            <Col xxl={18} xl={16} lg={14} span={24}>
                <Card bordered={false} className={'rr-card-without-padding'}>
                    <Row style={{ marginLeft: 8, marginRight: 8 }}>
                        <Col>
                            <Row gutter={16} type={'flex'}>
                                <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-right-border'}>
                                    {data.projectType === ProjectTypeCodeEnum.BASE && (
                                        <Row gutter={16} style={{ padding: '0px', borderBottom: '1px solid #eaeaea' }}>
                                            <EntityRentIndicatorsBlock
                                                entityType={'project'}
                                                entity={data}
                                                storageKey={'project-rent'}
                                                costOfRent={data.costOfRent}
                                            />
                                        </Row>
                                    )}

                                    {data.projectType === ProjectTypeCodeEnum.BASE ? (
                                        <ProjectPageDescriptionPriceBlock data={data} />
                                    ) : (
                                        <ProjectPageDescriptionPriceOfferBlock data={data} />
                                    )}

                                    {data.projectType === ProjectTypeCodeEnum.BASE && (
                                        <ProjectPageDescriptionPeriodAndLocationBlock data={data} />
                                    )}
                                </Col>
                                <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-top-border'}>
                                    {data.projectType === ProjectTypeCodeEnum.BASE && <ProjectPageDescriptionContactsBlock data={data} />}

                                    {data.projectType === ProjectTypeCodeEnum.BASE && <ProjectPageDescriptionContractBlock data={data} updateParentEntity={()=>{
                                        dispatch(loadEntity(data.businessAccountId, data.id));
                                    }} />}

                                    <ProjectPageDescriptionAttachmentsBlock data={data} updateParentEntity={()=>{
                                        dispatch(loadEntity(data.businessAccountId, data.id));
                                    }} />

                                    <ProjectPageDescriptionDescriptionBlock data={data} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>

                <AbstractEntityInfoCard leftBlockSpanLG={24} data={data} style={{ marginTop: 32 }} />
            </Col>
        </Row>
    ) : null;
};

import { useCallback, useMemo } from 'react';
import { DragGroup } from '../types/types';

export const useGroupActions = (groups: DragGroup[], setGroups: (groups: DragGroup[]) => void) => {
    const renameGroup = useCallback(
        (index: number, name: string) => {
            const newGroups = [...groups];
            newGroups[index].name = name;
            setGroups(newGroups);
        },
        [groups, setGroups]
    );

    const addNewGroup = useCallback(
        (name: string) => {
            let newGroups = [...groups];
            newGroups.push({
                name: name,
                main: false,
                fields: [],
            });

            setGroups(newGroups);
        },
        [groups, setGroups]
    );

    const deleteGroup = useCallback(
        (index: number) => {
            let newGroups = [...groups];
            newGroups.splice(index, 1);
            setGroups(newGroups);
        },
        [groups, setGroups]
    );

    return useMemo(
        () => ({
            renameGroup,
            addNewGroup,
            deleteGroup,
        }),
        [addNewGroup, deleteGroup, renameGroup]
    );
};

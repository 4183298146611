import React, {FC, useState} from 'react';
import {ChangeElementsParamPopover} from "../changeElementsParamPopover";
import {useAppDispatch, useAppSelector} from "../../../../../../../store/hooks";
import {isDefined} from "../../../../../../../shared/util/utils";
import {PPC} from "./PPC";
import {ShiftCountInput} from "../../../../../workPlannings/modal/shiftCountInput";
import {setShiftCountForSelectedIds} from "../../../../reducers/operationForm.reducer";

interface Props {
    selectedIds: number[];
    onClose?: ()=>void;
}

export const ChangeElementsShiftCountPopover:FC<Props> = (props) => {
    return (
        <ChangeElementsParamPopover
            selectedIds={props.selectedIds}
            component={ChangeElementsShiftCountPopoverContent}
            componentProps={{onClose: props.onClose}}
        >
            {props.children}
        </ChangeElementsParamPopover>
    );
};

interface ChangeElementsDateParamPopoverProps {
    selectedIds: number[];
    onClose: ()=>void;
}

export const ChangeElementsShiftCountPopoverContent:FC<ChangeElementsDateParamPopoverProps> = ({children, selectedIds, onClose}) => {
    const dispatch = useAppDispatch();
    const elements = useAppSelector((state)=> state.operationForm.elements.entities);
    const selectedElements = elements.filter((el)=>selectedIds.includes(el.id));
    const defaultValue = selectedElements.every((el) => el.shiftCount === selectedElements[0].shiftCount) ? selectedElements[0].shiftCount : undefined;

    const [value, setValue] = useState<number|undefined>(defaultValue);
    const canApply = isDefined(value);

    return (
        <PPC
            label={'Смены'}
            input={
                <ShiftCountInput onChange={(v)=>{
                    setValue(v);
                }} value={value} />
            }
            canApply={canApply}
            onApply={()=>{
                if(isDefined(value)){
                    onClose?.();
                    dispatch(setShiftCountForSelectedIds(selectedIds, value));
                }
            }}
            onCancel={()=>{
                onClose?.();
            }}
        />
    );
};

import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    CustomFieldInfoCreate,
    CustomFieldInfoRead,
    CustomFieldInfoUpdate,
    CustomFieldsLimitObjList,
    serverApi,
    ServerError,
} from '../../../../../server';
import { getServerError } from '../../../../../shared/util/utils';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'customField/LOAD_ENTITY',
    UPDATE_ENTITY: 'customField/UPDATE_ENTITY',
    CREATE_ENTITY: 'customField/CREATE_ENTITY',
    DELETE_ENTITY: 'customField/DELETE_ENTITY',
    RESET: 'customField/RESET',
    RESET_ERROR: 'customField/RESET_ERROR',
    GET_CUSTOM_FIELDS_LIMIT: 'customField/GET_CUSTOM_FIELDS_LIMIT',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entity: null as CustomFieldInfoRead | null,
    fieldsLimit: undefined as CustomFieldsLimitObjList | undefined,
    updating: false,
    updatingError: undefined as undefined | ServerError,
};

export type CustomFieldState = Readonly<typeof initialState>;

// Reducer

export default (state: CustomFieldState = initialState, action): CustomFieldState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                loadingError: undefined,
                updatingError: undefined,
            };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
            };

        case SUCCESS(ACTION_TYPES.GET_CUSTOM_FIELDS_LIMIT):
            return {
                ...state,
                fieldsLimit: action.payload.data,
            };

        default:
            return state;
    }
};

// Actions

export const getCustomFieldsLimit = (businessAccountId: number) => ({
    type: ACTION_TYPES.GET_CUSTOM_FIELDS_LIMIT,
    payload: serverApi.listCustomFieldsLimits(businessAccountId),
});

export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getCustomFieldById(businessAccountId, id),
});

export const createEntity = (businessAccountId: number, customFieldInfoCreate: CustomFieldInfoCreate) => ({
    type: ACTION_TYPES.CREATE_ENTITY,
    payload: serverApi.createCustomField(businessAccountId, customFieldInfoCreate),
});

export const updateEntity = (businessAccountId: number, id: number, customFieldInfoUpdate: CustomFieldInfoUpdate) => ({
    type: ACTION_TYPES.UPDATE_ENTITY,
    payload: serverApi.updateCustomFieldById(businessAccountId, id, customFieldInfoUpdate),
});

export const deleteEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: serverApi.deleteCustomFieldById(businessAccountId, id),
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR,
});

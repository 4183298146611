import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {serverApi, ServerError, TemplateInfoCreate, TemplateInfoRead, TemplateInfoUpdate} from '../../../../../server';
import {push} from 'connected-react-router';
import {getServerError} from '../../../../../shared/util/utils';
import {showNotification} from '../../../../../components/notification/showNotification';

import {LocalizationEnum, localizeIntl} from '../../../../../localization';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'template/LOAD_ENTITY',
    UPDATE_ENTITY: 'template/UPDATE_ENTITY',
    CHANGE_ENTITY_STATUS: 'template/CHANGE_ENTITY_STATUS',
    CREATE_ENTITY: 'template/CREATE_ENTITY',
    RESET: 'template/RESET',
    RESET_ERROR: 'template/RESET_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    updating: false,
    updatingError: undefined as (undefined | ServerError),
    entity: null as (TemplateInfoRead | null)
};

export type TemplateState = Readonly<typeof initialState>;

// Reducer

export default (state: TemplateState = initialState, action): TemplateState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):

            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case REQUEST(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: true
            };

        case FAILURE(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false
            };

        case SUCCESS(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined
            };

        default:
            return state;
    }
};

// Actions

// Получение сущности
export const loadEntity = (businessAccountId: number, id: number) => {
    return ({
        type: ACTION_TYPES.LOAD_ENTITY,
        payload: serverApi.getTemplateById(businessAccountId, id)
    });
};

// Создание сущности
export const createTemplate = (intl, businessAccountId: number, entity: TemplateInfoCreate, cb?: (data: TemplateInfoRead) => void) => {

    // let newEntity = {...entity};
    // if (entity.acceptedPaymentsSum === undefined) {
    //     newEntity.acceptedPaymentsSum = 0;
    // }


    return ({
        type: ACTION_TYPES.CREATE_ENTITY,
        payload: serverApi.createTemplate(businessAccountId, entity)
    });
};

// Изменение сущности
export const updateEntity = (intl, businessAccountId: number, id: number, entity: TemplateInfoUpdate) => {
    return dispatch => {

        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateTemplateById(businessAccountId, id, entity)
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Шаблон не изменен'); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            } else {
                // Сущность обновлена успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__TEMPLATE_UPDATED,)); // TODO мб выводить норм сообщение
                dispatch(push('/' + businessAccountId + '/projects/templates/' + id + '?tab=description'));
                dispatch(loadEntity(businessAccountId, id));
                // updateEntityAnalytics('update project', {'entity id': id});


            }
        });
    };
};

export const updatePay = (intl, businessAccountId: number, id: number, entity: TemplateInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateTemplateById(businessAccountId, id, entity)
        });
    };
};


// Изменение статуса
export const setStatusAndArchive = (intl, businessAccountId: number, id: number, businessVersion: number | undefined, status?: 'ACTIVATE' | 'BLOCK', archive?: boolean) => {

    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ENTITY_STATUS,
            payload: serverApi.transitTemplateWorkflowBulk(businessAccountId, {
                directives: [{
                    id,
                    businessVersion: businessVersion || 0,
                    transitionCode: status,
                    archive
                }]
            })
        }).then((result) => {
            if (result instanceof Error) {
                // Статус не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            } else {
                showNotification('success', archive ? localizeIntl(intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__ARCHIVED) : localizeIntl(intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__UNARCHIVED));
                dispatch(loadEntity(businessAccountId, id));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});
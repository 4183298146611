import { ModalFormDisplayGroup } from '../types/displayData';

export const allFieldsDisplayGroup = {
    title: 'Все поля',
    value: 'ALL',
    subGroup: {
        title: 'Скрыть',
        value: 'MAIN',
    },
} satisfies ModalFormDisplayGroup<'ALL' | 'MAIN'>;

export const mainFieldsDisplayGroup = {
    title: 'Только основные',
    value: 'MAIN',
    subGroup: {
        title: 'Показать всё',
        value: 'ALL',
    },
} satisfies ModalFormDisplayGroup<'ALL' | 'MAIN'>;

export class MetricUtils {
    static fromTonsToGrams(tons: number) {
        return tons * 1000 * 1000;
    }
    static fromGramsToTons(millimeters: number) {
        return millimeters / 1000 / 1000;
    }
    static fromMillimetersTiMeters(millimeters: number) {
        return millimeters / 1000;
    }
    static fromMetersToMillimeters(meters: number) {
        return meters * 1000;
    }
    static fromCubicMillimetersToCubicMeters(millimeters: number) {
        return millimeters / 1000 / 1000 / 1000;
    }
    static fromCubicMetersToCubicMillimeters(millimeters: number) {
        return millimeters * 1000 * 1000 * 1000;
    }
}

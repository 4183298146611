import { CalendarSortValue } from '../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { WorkPlanningsCalendarFilters } from '../../filters/workPlanningsCalendarFilters.types';
import { DeepKeys } from '@tanstack/react-table';
import { WorkPlanningsRecord } from '../../../../../../../server';

export type WorkPlanningsSortValue = 'renter' | 'project' | 'profession' | 'status' | 'start' | 'end';

export const workPlanningsCalendarSortData: CalendarSortValue<WorkPlanningsSortValue>[] = [
    { value: 'renter', title: 'Контрагентам' },
    { value: 'project', title: 'Проектам' },
    { value: 'profession', title: 'Специальностям' },
    { value: 'status', title: 'Статусу' },
    { value: 'start', title: 'Началу' },
    { value: 'end', title: 'Завершению' },
];

export const workPlanningsCalendarSortValueToItemName = {
    renter: 'counterpartyShortName',
    project: 'projectShortName',
    profession: 'professionShortName',
    status: 'stateCode',
} satisfies Partial<Record<WorkPlanningsCalendarFilters['group'] & string, DeepKeys<WorkPlanningsRecord>>>;

export const workPlanningsCalendarSortValueToItemId = {
    renter: 'counterpartyId',
    project: 'projectId',
    profession: 'professionId',
    status: 'stateCode',
} satisfies Partial<Record<WorkPlanningsCalendarFilters['group'] & string, DeepKeys<WorkPlanningsRecord>>>;

import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'antd';
import classNames from 'classnames';
import { ActionButtonsPopover } from '../actionButtonsPopover/actionButtonsPopover';
import { ActionButtonsData } from '../actionButtonsGroup/actionButtonsGroup';
import { IconEllipsisHSolid } from '../../../icons';
import './actionButtonsInlineGroup.less';
import { useLocalize } from '../../../../core/hooks/useLocalize';

export type Action<ActionType, PopoverComponentProps extends object> =
    | {
          type: ActionType;
          disabled?: boolean;
          popover?: {
              component: FunctionComponent<PopoverComponentProps> | typeof React.Component<PopoverComponentProps>;
              props: PopoverComponentProps;
          };
      }
    | ActionType
    | undefined
    | null
    | false;

export interface ActionButtonsGroupProps<ActionType extends string, PopoverComponentProps extends object> {
    mainActions?: Action<ActionType, PopoverComponentProps>[];
    additionalActions?: Action<ActionType, PopoverComponentProps>[];
    onAction?: (action: ActionType) => void;
    data: ActionButtonsData<ActionType>;
}

export const ActionButtonsInlineGroup = <ActionType extends string, PopoverComponentProps extends object>({
    mainActions,
    additionalActions,
    onAction,
    data,
}: ActionButtonsGroupProps<ActionType, PopoverComponentProps>) => {
    additionalActions = additionalActions?.filter((action) => action);

    const L = useLocalize();

    return (
        <div className={'rr-table-action-buttons-inline-group'}>
            {[
                ...(mainActions?.map((action, index, arr) => {
                    if (action) {
                        const type = typeof action === 'object' ? action.type : action;
                        const disabled = typeof action === 'object' ? action.disabled : undefined;
                        const popover = typeof action === 'object' ? action.popover : undefined;

                        const buttonData = data[type];
                        const label =
                            'localizationId' in buttonData ? L(buttonData.localizationId) : 'name' in buttonData ? buttonData.name : null;

                        const btn = (
                            <Button
                                type={'link'}
                                className={classNames('rr-action-link-btn', `rr-action-link-btn--${buttonData.color}`)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!popover) onAction?.(type);
                                }}
                                disabled={disabled}
                            >
                                {buttonData.icon}
                            </Button>
                        );

                        return (
                            <div className={'rr-table-action-buttons-inline-group-cell'}>
                                {popover ? <popover.component {...popover.props}>{btn}</popover.component> : btn}
                            </div>
                        );
                    } else {
                        return null;
                    }
                }) || []),

                additionalActions && additionalActions.length > 0 && (
                    <div className={'rr-table-action-buttons-inline-group-cell'}>
                        <ActionButtonsPopover
                            data={data}
                            actions={additionalActions}
                            onAction={(action) => {
                                onAction?.(action);
                            }}
                        >
                            <Button
                                type={'link'}
                                className={'rr-action-link-btn'}
                                style={{ height: 28 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Icon component={IconEllipsisHSolid} style={{ fontSize: 28 }} />
                            </Button>
                        </ActionButtonsPopover>
                    </div>
                ),
            ]}
        </div>
    );
};

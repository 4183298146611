import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import subrentShippingsListTabPaneColumns from '../subrentShippingsListTabPaneColumns';
import { subrentsInitialParamsState } from '../reducers/subrents.reducer';

export const getSubrentsSortBy = (params: typeof subrentsInitialParamsState) => {
    return getAvailableGridSortById(
        params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy,
        subrentShippingsListTabPaneColumns,
        ['problemsAndWarnings.severity'],
        subrentsInitialParamsState.sortBy
    );
};

import { useCallback } from 'react';
import { BASE_ITEM_CLASS, KIT_ITEM_CLASS } from '../utils/data/calendarClasses';
import { CalendarLine } from '../types/items';

export const useHorizontalLineClassNamesForGroup = (items: CalendarLine[]) => {
    return useCallback(
        (group) => {
            const item = items.find((item) => item.id === group.itemId);
            if ((item?.elementType === 'rent' || item?.elementType === 'subrent') && item?.isKitChild) return [KIT_ITEM_CLASS];

            return [BASE_ITEM_CLASS];
        },
        [items]
    );
};

import {FAILURE, REQUEST, SUCCESS} from './action-type.util';
import {BusinessAccountContactsInfoRead, BusinessAccountContactsInfoUpdate, serverApi, ServerError} from "../../server";
import {getServerError} from "../util/utils";
import {IRootState} from "./index";

export const ACTION_TYPES = {
    GET_BUSINESS_ACCOUNT_CONTACTS: 'businessAccountContacts/GET_BUSINESS_ACCOUNT_CONTACTS',
    UPDATE_BUSINESS_ACCOUNT_CONTACTS: 'businessAccountContacts/UPDATE_BUSINESS_ACCOUNT_CONTACTS',
    RESET_BUSINESS_ACCOUNT_CONTACTS_ERROR: 'businessAccountContacts/RESET_BUSINESS_ACCOUNT_CONTACTS_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    loadingLastTime: undefined as (undefined | Date),
    entity: null as (BusinessAccountContactsInfoRead | null),
    updating: false,
    updatingError: undefined as (undefined | ServerError)
};

export type BusinessAccountContactsState = Readonly<typeof initialState>;

// Reducer

export default (state: BusinessAccountContactsState = initialState, action): BusinessAccountContactsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_BUSINESS_ACCOUNT_CONTACTS):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
                loadingLastTime: new Date()
            };

        case FAILURE(ACTION_TYPES.GET_BUSINESS_ACCOUNT_CONTACTS):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
                loadingLastTime: undefined
            };

        case SUCCESS(ACTION_TYPES.GET_BUSINESS_ACCOUNT_CONTACTS):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
                loadingLastTime: new Date()
            };


        case REQUEST(ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_CONTACTS):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_CONTACTS):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case SUCCESS(ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_CONTACTS):
            return {
                ...state,
                entity: action.payload.data,
                updating: false,
                loadingLastTime: new Date()
            };

        case ACTION_TYPES.RESET_BUSINESS_ACCOUNT_CONTACTS_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined
            };

        default:
            return state;
    }
};

// Actions

// Получение данных контактов БА
export const getBusinessAccountContacts = (businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: BusinessAccountContactsState = (getState() as IRootState).businessAccountContacts;
        if (!state.loadingLastTime || Date.now() > state.loadingLastTime.getTime() + 10 * 1000) {
            return dispatch({
                type: ACTION_TYPES.GET_BUSINESS_ACCOUNT_CONTACTS,
                payload: serverApi.getBusinessAccountContactsById(businessAccountId)
            });
        }
    };
};

// Обновление данных контактов БА
export const updateBusinessAccountContacts = (businessAccountId: number, entity: BusinessAccountContactsInfoUpdate) => ({
    type: ACTION_TYPES.UPDATE_BUSINESS_ACCOUNT_CONTACTS,
    payload: serverApi.updateBusinessAccountContactsById(businessAccountId, entity)
});

// Сброс ошибок
export const resetBusinessAccountContactsError = () => ({
    type: ACTION_TYPES.RESET_BUSINESS_ACCOUNT_CONTACTS_ERROR
});

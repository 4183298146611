import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { IRootState } from '../../../shared/reducers';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router';
import { getPathFromState, isStringInEnum } from '../../../shared/util/utils';
import { getGridStorageData } from '../../../components/grid/utils';
import React from 'react';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom';
import RoundButton from '../../../components/button/roundButton';
import { PageUtils } from '../../../shared/util/pageUtils';
import { push, replace } from 'connected-react-router';
import { ProfessionsList } from './tabs/professions/list/ProfessionsList';
import { crewDefaultTab, crewPageUrlRoute, tabToCreateButtonText } from './utils/tabs';
import { CrewMembersList } from './tabs/crewMembers/list/CrewMembersList';
import { ParamsUtils } from '../../../core/utils/paramsUtils';

import { CrewPageTabsEnum } from '../../../shared/constants/tabEnums';

interface CrewPageProps extends StoreState, IBasePageProps {}

interface CrewPageState {}

class CrewPageComponent extends BasePage<CrewPageProps, CrewPageState> {
    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(this.urlMainPath);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('crew', this.currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
    }

    onTabChange = (key: string) => {
        const newTabKey = isStringInEnum(key, CrewPageTabsEnum, crewDefaultTab);

        const path = PageUtils.getPathForPushOnPageTab(this.urlMainPath, newTabKey);

        this.props.replace(path);
    };

    getPathForNewEntity = (tab: string): string => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');

        const optionalSlash = this.props.location.pathname[this.props.location.pathname.length - 1] === '/' ? '/' : '';
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';

        return `${this.urlMainPath}/${tab}${optionalSlash}${paramsString}`;
    };

    get urlMainPath() {
        return `/${this.props.businessAccountId}/${crewPageUrlRoute}` as const;
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(crewPageUrlRoute, CrewPageTabsEnum);
    }

    renderContent() {
        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, CrewPageTabsEnum, crewDefaultTab);
            const gridData = getGridStorageData(tab);
            return (
                <Redirect
                    to={getPathFromState(`${this.urlMainPath}/${tab}`, '', {
                        ...gridData.filters,
                        ...gridData.params,
                    })}
                />
            );
        }

        const currentTabCreateButtonText = tabToCreateButtonText[currentTab];

        return (
            <Tabs
                activeKey={currentTab}
                onChange={this.onTabChange}
                className={'rr-tabs'}
                type="line"
                animated={false}
                destroyInactiveTabPane
                tabBarExtraContent={
                    <Link to={this.getPathForNewEntity(currentTab)}>
                        <RoundButton icon={'plus'} colorScheme={'success'}>
                            {currentTabCreateButtonText}
                        </RoundButton>
                    </Link>
                }
            >
                {
                    <Tabs.TabPane tab={'Специальности'} key={CrewPageTabsEnum.PROFESSIONS}>
                        <ProfessionsList gridName={CrewPageTabsEnum.PROFESSIONS} />
                    </Tabs.TabPane>
                }
                {
                    <Tabs.TabPane tab={'Проектные работники'} key={CrewPageTabsEnum.CREW_MEMBERS}>
                        <CrewMembersList gridName={CrewPageTabsEnum.CREW_MEMBERS} />
                    </Tabs.TabPane>
                }
            </Tabs>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: businessAccountIdSelector(storeState),
        tabFromStore: getCurrentTabsSelector(storeState)?.['crew'],
    };
};

const mapDispatchToProps = {
    setBlockedUrl,
    push,
    replace,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreState = ConnectedProps<typeof connector>;

export const CrewPage = connector(CrewPageComponent);

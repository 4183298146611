import React from 'react';
import {Icon} from "antd";
import {IconAngleDoubleLeft, IconAngleDoubleRight} from "../icons";

import './Pagination.less';
import {LocalizationEnum} from '../../localization';

interface IPaginationProps {
    current: number; // Текущая страница
    pageSize: number; // Обязательств на странице
    total: number; // Всего обязательств
    hideOnSinglePage: Boolean; // Не выводить пагинацию, если одна страница
    onChange?: (page: number, pageSize?: number) => void; // Callback при изменении текущей страницы
}

interface IPaginationState {
    current: number;
}

class Pagination1 extends React.PureComponent<IPaginationProps, IPaginationState> {

    public static defaultProps = {
        current: 1,
        hideOnSinglePage: true
    };

    constructor(props) {
        super(props);

        this.state = {
            current: props.current
        };

        this.onPrevClick = this.onPrevClick.bind(this);
        this.onBeginClick = this.onBeginClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onEndClick = this.onEndClick.bind(this);
    }

    componentWillReceiveProps(nextProps:IPaginationProps) {
        const allPages = Math.floor((nextProps.total - 1) / nextProps.pageSize) + 1;
        let newCurrent = nextProps.current;

        if(newCurrent < 1) newCurrent = 1;
        else if(newCurrent > allPages) newCurrent = allPages;

        //console.log('newCurrent', newCurrent, nextProps.current);

        if(this.state.current !== newCurrent){
            this.setState({
                current: newCurrent
            });
        }
    }

    /*shouldComponentUpdate(nextProps:IPaginationProps, nextState:IPaginationState) {
        if ((this.props.current !== nextProps.current && nextProps.current && nextProps.current != this.state.current) ||
            this.props.pageSize !== nextProps.pageSize ||
            this.props.total !== nextProps.total ||
            this.props.hideOnSinglePage !== nextProps.hideOnSinglePage ||
            this.state.current !== nextState.current
        ) {
            return true;
        } else {
            return false;
        }
    }*/

    changeCurrentPage(page) {
        if(this.state.current !== page){
            this.setState({
                current: page
            });
            if(this.props.onChange) this.props.onChange(page, this.props.pageSize);
        }
    }

    onPrevClick() {
        this.changeCurrentPage(this.state.current > 1 ? this.state.current - 1 : 1);
    }

    onBeginClick() {
        this.changeCurrentPage(1);
    }

    onItemClick(e) {
        this.changeCurrentPage(e);
    }

    onNextClick() {
        const allPages = Math.floor((this.props.total - 1) / this.props.pageSize) + 1;
        this.changeCurrentPage(this.state.current < allPages ? this.state.current + 1 : allPages);
    }

    onEndClick() {
        const allPages = Math.floor((this.props.total - 1) / this.props.pageSize) + 1;
        this.changeCurrentPage(allPages);
    }

    render() {
        console.log('Pagination1 render');

        const current = this.state.current;
        const allPages = Math.floor((this.props.total - 1) / this.props.pageSize) + 1;
        if(this.props.hideOnSinglePage && allPages <= 1) return null;

        let startFrom;
        if(allPages <= 7){
            startFrom = 1;
        }
        else if(current < 5) startFrom = 1;
        else if(current >= 5 && current <= allPages - 3) startFrom = current - 3;
        else startFrom = allPages - 6;

        return (
            <ul className="ant-pagination rr-pagination">
                <li title={LocalizationEnum.ASPECT__GRID__TOOLTIP__TO_THE_FIRST_PAGE} tabIndex={0} className={(current <=1 ? 'ant-pagination-disabled' : '') + " ant-pagination-prev"} aria-disabled={current <= 1}>
                    {/* eslint-disable-next-line */}
                    <a className="ant-pagination-item-link" onClick={this.onBeginClick}>
                        <Icon component={IconAngleDoubleLeft} />
                    </a>
                </li>
                <li title={LocalizationEnum.ASPECT__GRID__TOOLTIP__TO_PREVIOUS_PAGE} tabIndex={0} className={(current <=1 ? 'ant-pagination-disabled' : '') + " ant-pagination-prev"} aria-disabled={current <= 1}>
                    {/* eslint-disable-next-line */}
                    <a className="ant-pagination-item-link" onClick={this.onPrevClick}>
                        <Icon type={'left'} />
                    </a>
                </li>

                {[...Array(allPages > 7 ? 7 : allPages)].map((x, i) =>{
                    const pageNum = startFrom + i;
                        return (
                            <li key={pageNum} title={''+pageNum} className={"ant-pagination-item ant-pagination-item-"+pageNum + (pageNum === current ? ' ant-pagination-item-active' : '')} tabIndex={0} onClick={e => this.onItemClick(pageNum)}>
                                    {/* eslint-disable-next-line */}
                                    <a>{pageNum}</a>
                            </li>
                        );
                    }
                )}

                <li title={LocalizationEnum.ASPECT__GRID__TOOLTIP__TO_NEXT_PAGE} tabIndex={0} className={(current>=allPages ? 'ant-pagination-disabled' : '') + " ant-pagination-prev"} aria-disabled={current >= allPages}>
                    {/* eslint-disable-next-line */}
                    <a className="ant-pagination-item-link" onClick={this.onNextClick}>
                        <Icon type={'right'} />
                    </a>
                </li>
                <li title={LocalizationEnum.ASPECT__GRID__TOOLTIP__TO_THE_LAST_PAGE} tabIndex={0} className={(current>=allPages ? 'ant-pagination-disabled' : '') + " ant-pagination-prev"} aria-disabled={current >= allPages}>
                    {/* eslint-disable-next-line */}
                    <a className="ant-pagination-item-link" onClick={this.onEndClick}>
                        <Icon component={IconAngleDoubleRight} />
                    </a>
                </li>
            </ul>
        );
    }
}

export default Pagination1;
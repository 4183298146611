import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import { useIntl } from 'react-intl';
import { showConfirm2 } from '../../../../../../components/confirm/showConfirm';
import { ProfessionRecord, SimpleTransitionCodeEnum } from '../../../../../../server';
import { RoundButton } from '../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { IconArchive, IconCheck, IconLevelUp, IconTrash, IconUnlock } from '../../../../../../components/icons';
import { useDeleteProfessionsMutation, useSetStatusForProfessionsMutation } from '../api/professions.api';
import { GridRefContextValue } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';

export const useProfessionEntityActionsButtons = ({
    selectedRecords,
    gridRef,
}: {
    selectedRecords: ProfessionRecord[];
    gridRef: GridRefContextValue;
}) => {
    const intl = useIntl();

    const [setProfessionsStatus, { isLoading: isSetStatusLoading }] = useSetStatusForProfessionsMutation();

    const [deleteProfessions, { isLoading: isDeleteLoading }] = useDeleteProfessionsMutation();

    const deleteSelectedProfessions = useCallback(async () => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => ({
            id,
            businessVersion,
        }));

        let yes = await showConfirm2({
            intl: intl,
            title:
                versionedSelectedEntitiesObjects.length > 1 ? 'Удалить специальности безвозвратно?' : 'Удалить специальность безвозвратно?',
        });

        if (yes) {
            deleteProfessions({
                versionedEntityObjList: {
                    entities: versionedSelectedEntitiesObjects,
                },
            });

            gridRef?.current?.clearSelection();
        }
    }, [deleteProfessions, gridRef, intl, selectedRecords]);

    const archiveSelectedProfessions = useCallback(() => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, archive, businessVersion }) => {
            return {
                id,
                archive: !archive,
                businessVersion,
            };
        });

        setProfessionsStatus({
            workflowDirectiveBulk: {
                directives: versionedSelectedEntitiesObjects,
            },
        });

        gridRef?.current?.clearSelection();
    }, [setProfessionsStatus, gridRef, selectedRecords]);

    const transitSelectedProfessions = useCallback(
        (transitionCode: SimpleTransitionCodeEnum) => {
            const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => {
                return {
                    id,
                    businessVersion,
                    transitionCode,
                };
            });

            setProfessionsStatus({
                workflowDirectiveBulk: {
                    directives: versionedSelectedEntitiesObjects,
                },
            });

            gridRef?.current?.clearSelection();
        },
        [gridRef, selectedRecords, setProfessionsStatus]
    );

    const isLoading = isSetStatusLoading || isDeleteLoading;

    return useMemo(
        () => ({
            entityActionsButtons: [
                _.every(selectedRecords, (profession: ProfessionRecord) => !profession.archive && profession.archivable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        onClick={archiveSelectedProfessions}
                        colorScheme={'TO_ARCHIVE'}
                    >
                        <Icon component={IconArchive} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}</span>
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (profession: ProfessionRecord) => profession.archive) ? (
                    <RoundButton
                        title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        onClick={archiveSelectedProfessions}
                        colorScheme={'FROM_ARCHIVE'}
                    >
                        <Icon component={IconLevelUp} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}</span>
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (profession: ProfessionRecord) =>
                    profession.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE)
                ) ? (
                    <RoundButton
                        title={LocalizationEnum.ASPECT__ACTION__ACTIVATE}
                        colorScheme={'success'}
                        onClick={() => {
                            transitSelectedProfessions(SimpleTransitionCodeEnum.ACTIVATE);
                        }}
                    >
                        <Icon component={IconCheck} />
                        {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (profession: ProfessionRecord) =>
                    profession.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.BLOCK)
                ) ? (
                    <RoundButton
                        title={LocalizationEnum.ASPECT__ACTION__BLOCK}
                        colorScheme={'blocked'}
                        onClick={() => {
                            transitSelectedProfessions(SimpleTransitionCodeEnum.BLOCK);
                        }}
                    >
                        <Icon component={IconUnlock} />
                        {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (profession: ProfessionRecord) => profession.deleteable) ? (
                    <RoundButton title={LocalizationEnum.ASPECT__ACTION__DELETE} onClick={deleteSelectedProfessions} colorScheme={'DELETE'}>
                        <Icon component={IconTrash} />
                        {localize(LocalizationEnum.ASPECT__ACTION__DELETE, 'span')}
                    </RoundButton>
                ) : null,
            ],
            loading: isLoading,
        }),
        [selectedRecords, archiveSelectedProfessions, deleteSelectedProfessions, isLoading, transitSelectedProfessions]
    );
};

import React, {CSSProperties, FC} from 'react';
import {Icon} from "antd";
import classNames from "classnames";
import {IconCrosshairsSolid} from "../../../../../icons";
import './todayButton.less';

interface TodayButtonProps {
    onClick?: ()=>void;
    showLabel?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    className?: string;
}

export const TodayButton:FC<TodayButtonProps> = (props) => {
    return (
        <span
            className={classNames('rr-today-btn', props.disabled && 'rr-today-btn-disabled', props.className)}
            style={props.style}
            onClick={!props.disabled ? props.onClick : undefined}
        >
            <Icon component={IconCrosshairsSolid} title={!props.showLabel ? 'Сейчас' : undefined} />
            {props.showLabel && 'Сейчас'}
        </span>
    );
};

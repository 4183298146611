import React from 'react';
import {IRootState} from "../../../../../../shared/reducers";
import {connect} from "react-redux";
import {BaseCategoriesSelect} from "../../../../../../components/baseCategoriesSelect/baseCategoriesSelect";

interface IProps {
    onChange?: (value: any, label: any, extra: any) => void;
    value?: string | number | Array<any>;
    placeholder?: string;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

class _RenterSelect extends React.Component<IProps & StateProps, any> {
    render() {
        return (
            <BaseCategoriesSelect
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    categories: storeState.entities.categories.renters || [],
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const CounterpartyCategoriesSelect = connect(
    mapStateToProps
)(_RenterSelect);

import moment from 'moment';
import { ElementsCalendarState } from '../../elements/reducers/elementsCalendar.reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkPlanningsRecord } from '../../../../../../server';
import { WorkPlanningsCalendarFilters } from '../filters/workPlanningsCalendarFilters.types';
import { defaultScreenLeft, defaultScreenRight } from '../../../components/Calendar/utils/data/calendarBoundaries';
import { CalendarBoundaries } from '../../../components/Calendar/types/params';

export interface WorkPlanningsCalendarState extends CalendarBoundaries {}

const initialState: ElementsCalendarState = {
    screenLeft: defaultScreenLeft(),
    screenRight: defaultScreenRight(),
};

const workPlanningsCalendarSlice = createSlice({
    name: 'workPlanningsCalendar',
    initialState,
    reducers: {
        setCalendarBoundaries: (
            state,
            action: PayloadAction<{
                params: WorkPlanningsCalendarFilters;
                records: WorkPlanningsRecord[];
            }>
        ) => {
            let { screenLeft, screenRight, capture } = action.payload.params;
            const { records } = action.payload;

            if (records && records.length > 0) {
                if (capture === 'all' || capture === 'allWithNow') {
                    const filteredRecords = records.filter((record) => record.startDate != null && record.endDate != null);
                    let minDate = Math.min(...filteredRecords.map((record) => +moment(record.startDate)));
                    let maxDate = Math.max(...filteredRecords.map((record) => +moment(record.endDate)));

                    if (capture === 'allWithNow') {
                        minDate = Math.min(minDate, Date.now());
                        maxDate = Math.max(maxDate, Date.now());
                    }
                    let diff = (maxDate - minDate) * 0.05;
                    screenLeft = minDate - diff;
                    screenRight = maxDate + diff;
                }
            }

            if (screenLeft) state.screenLeft = screenLeft;
            if (screenRight) state.screenRight = screenRight;
        },
    },
});

export const { setCalendarBoundaries } = workPlanningsCalendarSlice.actions;
export default workPlanningsCalendarSlice.reducer;

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../store/rtqQueryApi';
import { IRootState } from '../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { KitsApiUtils } from './kits.api.utils';
import { AvailableFiltersValuesWithCustomFieldsListObj, serverApi } from '../../../../../server';
import { AxiosError } from 'axios';
import { showNotification } from '../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { KitsListArgs, KitsListData } from './kits.api.types';
import { ACTION_TYPES } from '../reducers/kits.reducers';

export const kitsApi = createApi({
    reducerPath: 'kitsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['KitsList', 'KitsAvailableFiltersList'],
    endpoints: (builder) => ({
        listKits: builder.query<KitsListData, KitsListArgs>({
            queryFn: async (
                { intl, params, availableFiltersValues, customFieldMarkers, customFiltersRequestFilters },
                { dispatch, getState }
            ) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const subrentModuleEnabled = subrentModuleEnabledSelector(state);
                const { sortBy, limit, page, sortOrder, search } = params;

                const actualSortBy = sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : sortBy;

                const requestFilters = KitsApiUtils.getRequestFilters(params, subrentModuleEnabled);

                try {
                    const { data: kitsData } = await serverApi.listKits(
                        businessAccountId,
                        limit,
                        (page - 1) * limit,
                        undefined,
                        actualSortBy,
                        sortOrder,
                        search,
                        {
                            query: {
                                filters: [...requestFilters, ...customFiltersRequestFilters],
                            },
                        }
                    );

                    return {
                        data: {
                            entitiesData: kitsData,
                            availableIntervalFiltersData: availableFiltersValues
                                ? {
                                      values: availableFiltersValues,
                                      maps: {
                                          metrics: KitsApiUtils.productsIntervalMapFunction,
                                          formats: KitsApiUtils.productsIntervalFormatStyleMap,
                                      },
                                  }
                                : undefined,
                            customFieldMarkers,
                        },
                    };
                } catch (error: unknown) {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__FAILED_TO_GET_KIT_LIST));

                    return {
                        error: error as AxiosError,
                    };
                } finally {
                    dispatch({
                        type: ACTION_TYPES.CHANGE_LOADING,
                        payload: false,
                    });
                }
            },
            providesTags: ['KitsList'],
        }),
        listKitsAvailableFilters: builder.query<AvailableFiltersValuesWithCustomFieldsListObj, undefined>({
            queryFn: async (_, { getState }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    const { data } = await serverApi.listKitsAvailableFiltersValues(businessAccountId);

                    return {
                        data,
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['KitsAvailableFiltersList'],
        }),
    }),
});

export const { useListKitsQuery, useListKitsAvailableFiltersQuery } = kitsApi;

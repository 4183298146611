import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { push, replace } from 'connected-react-router';
import { PageUtils } from '../../../../../../shared/util/pageUtils';
import { rentersPageUrlRoute } from '../../../data/tabs';
import { ContactDrawerProps } from '../drawer/components/ContactDrawer/ContactDrawer';

import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { useArchiveContactsMutation, useDeleteContactMutation } from '../api/contactsApi';

export const useContactActionsFromDrawer = () => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const urlMainPath = `/${businessAccountId}/${rentersPageUrlRoute}` as const;

    const [archiveContacts, { isLoading: isArchiveContactLoading }] = useArchiveContactsMutation();

    const [deleteContact, { isLoading: isDeleteContactLoading }] = useDeleteContactMutation();

    const isLoading = isArchiveContactLoading || isDeleteContactLoading;

    const usePushToContactsAndEditEntity = useCallback(
        (contact) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(urlMainPath, RentersPageTabsEnum.CONTACTS)));
            dispatch(
                push(
                    PageUtils.getPathForPushOnPageTab(urlMainPath, RentersPageTabsEnum.CONTACTS, {
                        modal: 'edit',
                        id: String(contact.id),
                    })
                )
            );
        },
        [dispatch, urlMainPath]
    ) satisfies ContactDrawerProps['editContact'];

    const usePushToContactsAndDeleteEntity: ContactDrawerProps['deleteContact'] = useCallback(
        async (contact) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(urlMainPath, RentersPageTabsEnum.CONTACTS)));

            deleteContact({
                contactId: contact.id,
                businessVersionObj: {
                    businessVersion: contact.businessVersion,
                },
            });
        },
        [deleteContact, dispatch, urlMainPath]
    );

    const usePushToContactsAndArchiveEntity: ContactDrawerProps['archiveContact'] = useCallback(
        (contact) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(urlMainPath, RentersPageTabsEnum.CONTACTS)));

            archiveContacts({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: contact.id,
                            archive: !contact.archive,
                            businessVersion: contact.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveContacts, dispatch, urlMainPath]
    );

    return useMemo(
        () => ({
            edit: usePushToContactsAndEditEntity,
            delete: usePushToContactsAndDeleteEntity,
            archive: usePushToContactsAndArchiveEntity,
            isLoading,
        }),
        [isLoading, usePushToContactsAndArchiveEntity, usePushToContactsAndDeleteEntity, usePushToContactsAndEditEntity]
    );
};

import React from 'react';
import { Card, Icon, Tabs } from 'antd';
import {
    GlobalBusinessAccountsPageParams,
    initialParamsState,
    loadEntities,
    reset,
    setArchiveState,
    setStatus,
    updateEntity,
} from './reducers/globalBusinessAccounts.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import RoundButton from '../../../components/button/roundButton';
import { Spin } from '../../../components';
import { getPathFromState, getStateFromPath2 } from '../../../shared/util/utils';
import {
    BusinessAccountInfoUpdate,
    BusinessAccountRecord,
    BusinessAccountStateCodeEnum,
    BusinessAccountTransitionCodeEnum,
    GlobalPermissionCodeEnum,
} from '../../../server/api';
import { businessAccountsPageColumns } from './businessAccountsPageColumns';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { filters } from './businessAccountsPageFilters';
import debounce from 'lodash/debounce';
import { LocalizationEnum, localize } from '../../../localization';
import { getGridStorageData, setGridStorageDataFilters } from '../../../components/grid/utils';
import { Grid } from '../../../components/grid/Grid';
import { PageUtils } from '../../../shared/util/pageUtils';
import { serverApi } from '../../../server';
import { showNotification } from '../../../components/notification/showNotification';
import BusinessAccountDeleteModal from './businessAccountDeleteModal';
import every from 'lodash/every';
import { IconArchive, IconLevelUp, IconUnlock, IconUnlockAlt } from '../../../components/icons';
import BusinessAccountEditingModal from './businessAccountEditingModal';

const GRID_NAME = 'globalBusinessAccounts';

interface IState {
    selection: number[];
    popupVisible: boolean;
    deletableCompanyRecord?: BusinessAccountRecord;
    deleting: boolean;
    editing?: BusinessAccountRecord;
}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

class Component extends BasePage<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters: boolean = false;
    private initialValues;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['search', 'stateCode', 'typeCode', 'tariff', 'rentIndustryCode', 'hideArchive', 'sortBy', 'sortOrder'];

    constructor(props: IProps) {
        super(props);
        this.state = {
            selection: [],
            popupVisible: false,
            deleting: false,
        };
        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount() {
        super.componentDidMount();
        this.initialValues = this.props.pageParams;

        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.history.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.history.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    }

    componentDidUpdate = (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );

        if (!this.props.updating && !this.props.updatingError && prevProps.updating) {
            this.setState({ ...this.state, editing: undefined });
        }
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(GRID_NAME);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.history.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadRenters();
        if (!this.fromFilters) {
            this.filtersForm.setFieldsValue({ ...initialParamsState, ...this.props.pageParams });
        }
        this.fromFilters = false;
    };

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    onItemAction = async (item: BusinessAccountRecord, action: string) => {
        if (action === 'delete') {
            this.setState({ popupVisible: true, deletableCompanyRecord: item });
        } else if (action === 'archive') {
            this.props.setArchiveState(this.props.intl, [
                {
                    id: item.id,
                    businessVersion: item.businessVersion,
                    archive: !item.archive,
                },
            ]);
        } else if (action === 'lock') {
            this.props.setArchiveState(this.props.intl, [
                {
                    id: item.id,
                    businessVersion: item.businessVersion,
                    transitionCode: item.stateCode === BusinessAccountStateCodeEnum.ACTIVE ? 'BLOCK' : 'ACTIVATE',
                },
            ]);
        } else if (action === 'edit') {
            this.setState({ ...this.state, editing: item });
        }
    };

    loadRenters = async () => {
        await this.props.loadEntities(this.props.intl);
    };

    _onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.history.location.pathname, this.props.history.location.search, {
                limit: size,
                page: undefined,
            })
        );
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.history.location.pathname, this.props.history.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, { page: page }));
        this.clearSelection();
    };

    _onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    onArchiveBtnClick = () => {
        //console.log(this.state.selection);
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return { id: item.id, businessVersion: item.businessVersion, archive: !item.archive };
                });
            //console.log(selectedItems);
            this.props.setArchiveState(this.props.intl, selectedItems);
            this.clearSelection();
        }
    };

    onChangeStateButtonClick = () => {
        //console.log(this.state.selection);
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return {
                        id: item.id,
                        businessVersion: item.businessVersion,
                        transitionCode: item.stateCode === 'ACTIVE' ? 'BLOCK' : 'ACTIVATE',
                    };
                });
            //console.log(selectedItems);
            this.props.setStatus(this.props.intl, selectedItems);
            this.clearSelection();
        }
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    getActionButtons = () => {
        let selectedItems = this.props.entities
            ? this.props.entities.filter((item: BusinessAccountRecord) => {
                  return this.state.selection.indexOf(item.id) > -1;
              })
            : [];
        return this.props.permissions.includes(GlobalPermissionCodeEnum.UPDATEBUSINESSACCOUNT)
            ? [
                  every(selectedItems, (item: BusinessAccountRecord) =>
                      item.availableTransitionCodes?.includes(BusinessAccountTransitionCodeEnum.BLOCK)
                  ) ? (
                      <RoundButton
                          key={1}
                          title={LocalizationEnum.ASPECT__ACTION__BLOCK}
                          colorScheme={'blocked'}
                          onClick={this.onChangeStateButtonClick}
                      >
                          <Icon component={IconUnlock} />
                          {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                      </RoundButton>
                  ) : null,
                  every(
                      selectedItems,
                      (item: BusinessAccountRecord) =>
                          item.stateCode === BusinessAccountStateCodeEnum.BLOCKED &&
                          item.availableTransitionCodes?.includes(BusinessAccountTransitionCodeEnum.ACTIVATE)
                  ) ? (
                      <RoundButton
                          key={2}
                          title={LocalizationEnum.ASPECT__ACTION__UNLOCK}
                          colorScheme={'success'}
                          onClick={this.onChangeStateButtonClick}
                      >
                          <Icon component={IconUnlockAlt} />
                          {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                      </RoundButton>
                  ) : null,
                  every(selectedItems, (item: BusinessAccountRecord) => !item.archive && item.archivable) ? (
                      <RoundButton
                          key={3}
                          title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                          onClick={this.onArchiveBtnClick}
                          colorScheme={'TO_ARCHIVE'}
                      >
                          <Icon component={IconArchive} />
                          {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                      </RoundButton>
                  ) : null,
                  every(selectedItems, (item: BusinessAccountRecord) => item.archive) ? (
                      <RoundButton
                          key={4}
                          title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                          onClick={this.onArchiveBtnClick}
                          colorScheme={'FROM_ARCHIVE'}
                      >
                          <Icon component={IconLevelUp} />
                          {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                      </RoundButton>
                  ) : null,
              ]
            : [];
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        if (data.hideArchive === false) data.hideArchive = undefined;
        data.page = undefined;
        this.fromFilters = true;
        this.props.push(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, data));
        setGridStorageDataFilters(GRID_NAME, data);
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    resetFilters = () => {
        let data: GlobalBusinessAccountsPageParams = {
            search: undefined,
            stateCode: undefined,
            typeCode: undefined,
            rentIndustryCode: undefined,
            tariff: undefined,
            hideArchive: undefined,
            page: undefined,
        };
        this.updatingAfterSetFromStorage = true;
        this.props.push(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, data));
        setGridStorageDataFilters(GRID_NAME, data);
    };

    renderContent() {
        let { entities, loading, filteredCount } = this.props;
        let err;

        if (this.props.updatingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.updatingError.title}</strong>
                    </div>
                    <div>{this.props.updatingError.message}</div>
                </>
            );
        }

        return (
            <>
                <Tabs
                    className={'rr-tabs'}
                    type="line"
                    animated={false}
                    tabBarExtraContent={
                        <a target={'_blank'} href={`/companyRegistration?step=1`} rel="noreferrer">
                            <RoundButton icon={'plus'} title={LocalizationEnum.ASPECT__ACTION__CREATE_RENTER} colorScheme={'success'}>
                                <span>Зарегистрировать компанию</span>
                            </RoundButton>
                        </a>
                    }
                >
                    <Tabs.TabPane tab={'Компании'} key={'list'}>
                        <Spin spinning={loading || this.state.deleting}>
                            <Card bordered={false}>
                                <Grid
                                    filtersData={filters}
                                    filtersInitialValues={this.initialValues}
                                    filtersCurrentValues={this.props.pageParams}
                                    filtersDefaultValues={initialParamsState}
                                    filtersGetFiltersFormRef={this.getFiltersForm}
                                    filtersOnChange={this.onFiltersChange}
                                    filtersResetFiltersCb={this.resetFilters}
                                    ref={(ref) => {
                                        if (!this.grid) this.grid = ref;
                                    }}
                                    onRowAction={this.onItemAction}
                                    onSortedChange={this.onSortedChange}
                                    onPageChanged={this.onPageChanged}
                                    onPageSizeChanged={this._onPageSizeChanged}
                                    onSelectionChanged={this._onSelectionChanged}
                                    filtered={filteredCount}
                                    pageSize={this.props.pageParams.limit}
                                    currentPage={this.props.pageParams.page}
                                    columns={businessAccountsPageColumns}
                                    data={entities}
                                    entityType={'businessAccount'}
                                    indexFieldName={'id'}
                                    actionButtons={this.getActionButtons()}
                                    defaultSorted={this.props.pageParams.sortBy}
                                    defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                                    hideArchive={this.props.pageParams.hideArchive}
                                    gridName={GRID_NAME}
                                    selectable={true}
                                />
                            </Card>
                        </Spin>
                    </Tabs.TabPane>
                </Tabs>
                {this.state.popupVisible && (
                    <BusinessAccountDeleteModal
                        onClose={() => {
                            this.setState({ popupVisible: false, deletableCompanyRecord: undefined, deleting: false });
                        }}
                        onOk={async (code) => {
                            if (this.state.deletableCompanyRecord) {
                                this.setState({ popupVisible: false, deleting: true });
                                try {
                                    await serverApi.deleteBusinessAccountById(this.state.deletableCompanyRecord.id, code);
                                    showNotification('success', 'Компания удалена');
                                } catch (e) {
                                    showNotification('error', 'Компания не удалена');
                                }
                                this.clearSelection();
                                await this.loadRenters();
                                this.setState({ deletableCompanyRecord: undefined, deleting: false });
                            }
                        }}
                        companyShortName={this.state.deletableCompanyRecord?.shortName}
                    />
                )}
                {this.state.editing !== undefined && (
                    <BusinessAccountEditingModal
                        data={this.state.editing}
                        err={err}
                        loading={this.props.updating}
                        onOk={async (data: BusinessAccountInfoUpdate) => {
                            console.log(data);
                            if (this.state.editing) {
                                this.props.updateEntity(this.props.intl, this.state.editing?.id, data);
                                // try {
                                //     const res = await serverApi.updateBusinessAccountById(this.state.editing?.id, data);
                                //     console.log(res);

                                //     showNotification('success', 'Данные компании обновлены!');
                                //     this.setState({ ...this.state, editing: undefined });
                                //     await this.loadRenters();
                                // } catch (e) {
                                //     console.error(e);
                                //     showNotification('error', 'Возникла ошибка при изиенении данных!');
                                // }
                            }
                        }}
                        onClose={() => this.setState({ ...this.state, editing: undefined })}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    return {
        entities: storeState.globalBusinessAccounts.entities,
        loading: storeState.globalBusinessAccounts.loading,
        locationSearchParams: storeState.router.location.search,
        locationPathname: storeState.router.location.pathname,
        pageParams: storeState.globalBusinessAccounts.params,
        filteredCount: storeState.globalBusinessAccounts.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        permissions: storeState.permissions.permissions,
        updating: storeState.globalBusinessAccounts.updating,
        updatingError: storeState.globalBusinessAccounts.updatingError,
    };
};

const mapDispatchToProps = {
    loadEntities,
    updateEntity,
    reset,
    replace,
    push,
    setArchiveState,
    setStatus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const BusinessAccountsPage = connect(mapStateToProps, mapDispatchToProps)(Component);

import { BasePage, IBasePageProps } from '../../../../../../../../components/page/BasePage';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../../../../../../shared/reducers';
import { clearCrewMember, loadCrewMember } from '../../../reducers/crewMember/crewMember.reducer';
import { Redirect, RouteComponentProps } from 'react-router';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { getPathFromState, isStringInEnum } from '../../../../../../../../shared/util/utils';
import { getGridStorageData } from '../../../../../../../../components/grid/utils';
import React from 'react';
import { crewPageUrlRoute } from '../../../../../utils/tabs';
import Spin from '../../../../../../../../components/spin/spin';
import { Alert, Tabs } from 'antd';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { CrewMemberDescription } from '../tabs/description/CrewMemberDescription';
import { TabBarButtons } from './components/TabBarContent/TabBarButtons';
import { IPageHeaderProps } from '../../../../../../../../components/pageHeader/pageHeader';
import { CrewMemberProfessions } from '../tabs/professions/CrewMemberProfessions';
import { push } from 'connected-react-router';
import { CrewMemberModal } from '../tabs/description/components/CrewMemberModal/CrewMemberModal';
import { canViewFinancialDataSelector } from '../../../../../../../../shared/reducers/permissions.reducer';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';
import { LinkToCalendar } from '../../../../../../../../components/link/toCalendar/LinkToCalendar';
import { CalendarPageTabsEnum, CrewMemberPageTabsEnum, CrewPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';
import { CrewMemberPageHeaderNavigationBlock } from '../../../components/HeaderNavigationBlock/CrewMemberPageHeaderNavigationBlock';

const defaultTab = CrewMemberPageTabsEnum.DESCRIPTION;

interface CrewMemberPageProps extends IBasePageProps {}

class CrewMemberPageComponent extends BasePage<CrewMemberPageProps & StoreProps, {}> {
    componentDidMount() {
        super.componentDidMount();
        const { businessAccountId, entityId, loadCrewMember } = this.props;

        loadCrewMember({
            businessAccountId,
            entityId,
        });
    }

    componentDidUpdate(prevProps: CrewMemberPageProps & StoreProps) {
        const { businessAccountId, entityId, loadCrewMember, clearCrewMember, currentTab } = this.props;

        if (currentTab) saveCurrentTabToStore('crewMember', currentTab);

        if (prevProps.entityId !== entityId) {
            clearCrewMember();

            loadCrewMember({
                businessAccountId,
                entityId,
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.clearCrewMember();
    }

    onTabChange = (tab: string) => {
        const newTabKey = isStringInEnum(tab, CrewMemberPageTabsEnum, defaultTab);
        const path = this.getTabUrl(newTabKey);
        saveCurrentTabToStore('crewMember', newTabKey);
        this.props.push(path);
    };

    getPageHeaderProps(): IPageHeaderProps {
        const { crewMemberEntity, contactEntity } = this.props;

        return {
            name: (crewMemberEntity || undefined) && crewMemberEntity?.contact.personShortName,
            parentPagePath: crewMemberEntity || undefined ? `/${this.props.businessAccountId}/${crewPageUrlRoute}` : undefined,
            subsectionPagePath:
                crewMemberEntity || undefined
                    ? `/${this.props.businessAccountId}/${crewPageUrlRoute}/${CrewPageTabsEnum.CREW_MEMBERS}`
                    : undefined,
            rightBlock: crewMemberEntity ? (
                <LinkToCalendar
                    calendarPath={linkToCalendar(
                        {
                            crewMemberId: crewMemberEntity.id,
                            capture: 'all',
                            group: 'project',
                        },
                        CalendarPageTabsEnum.WORK_PLANNINGS
                    )}
                />
            ) : undefined,
            NavigationBlock: (
                <CrewMemberPageHeaderNavigationBlock crewMember={crewMemberEntity || undefined} contact={contactEntity ?? undefined} />
            ),
        };
    }

    getTabUrl(tab: CrewMemberPageTabsEnum) {
        const storageData = getGridStorageData(tab);
        return getPathFromState(`${this.urlMainPath}`, '', {
            ...storageData.filters,
            ...storageData.params,
            tab: tab,
        });
    }

    get urlMainPath() {
        const { businessAccountId, entityId } = this.props;

        return `/${businessAccountId}/${crewPageUrlRoute}/crewMembers/${entityId}` as const;
    }

    renderContent(): JSX.Element {
        const { currentTab, loading, crewMemberEntity } = this.props;
        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, CrewMemberPageTabsEnum, defaultTab);

            return <Redirect to={this.getTabUrl(tab)} />;
        }

        return (
            <Spin spinning={loading}>
                <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                    {this.props.loadingError ? (
                        <Alert
                            message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                            description={
                                <>
                                    <div>
                                        <strong>{this.props.loadingError.title}</strong>
                                    </div>
                                    <div>{this.props.loadingError.message}</div>
                                </>
                            }
                            type="error"
                            showIcon
                            closable
                            style={{ marginBottom: 30 }}
                        />
                    ) : null}
                    {crewMemberEntity ? (
                        <div className="card-container">
                            <Tabs
                                activeKey={currentTab}
                                onChange={this.onTabChange}
                                className={'rr-tabs'}
                                type="line"
                                animated={false}
                                destroyInactiveTabPane
                                tabBarExtraContent={<TabBarButtons crewMember={crewMemberEntity} />}
                            >
                                <Tabs.TabPane
                                    tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                    key={CrewMemberPageTabsEnum.DESCRIPTION}
                                >
                                    <CrewMemberDescription entity={crewMemberEntity} />
                                </Tabs.TabPane>
                                {this.props.isCanViewFinancialData && (
                                    <Tabs.TabPane tab={'Специальности и ставки'} key={CrewMemberPageTabsEnum.PROFESSIONS}>
                                        <CrewMemberProfessions crewMember={crewMemberEntity} />
                                    </Tabs.TabPane>
                                )}
                            </Tabs>
                        </div>
                    ) : null}
                </div>
                {crewMemberEntity && <CrewMemberModal crewMember={crewMemberEntity} />}
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => ({
    businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
    entityId: +ownProps.match.params['id'] || 0,
    crewMemberEntity: storeState.crewMember.entity,
    contactEntity: storeState.contact.entity,
    loading: storeState.crewMember.loading,
    loadingError: storeState.crewMember.loadingError,
    currentTab: new URLSearchParams(ownProps.location.search).get('tab'),
    tabFromStore: getCurrentTabsSelector(storeState)?.['crewMember'],
    isCanViewFinancialData: canViewFinancialDataSelector(storeState),
});

const mapDispatchToProps = {
    loadCrewMember,
    clearCrewMember,
    push,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const CrewMemberPage = connector(CrewMemberPageComponent);

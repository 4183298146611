import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { BusinessAccountContactsInfoUpdate, BusinessAccountInfoUpdate } from '../../../../server/api';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { formFields } from './businessAccountProfileEditModalFields';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { getImagePath } from '../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
    resetBusinessAccountContactsError,
    updateBusinessAccountContacts,
} from '../../../../shared/reducers/businessAccountContacts.reducer';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    goBackPath: string;
}

class _BusinessAccountProfileEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps & WrappedComponentProps> {
    static defaultProps = {
        formFields: formFields,
    };

    getInitialValues = () => {
        let initialValues: BusinessAccountInfoUpdate | undefined;

        if (this.props.initialValues) {
            initialValues = { ...this.props.initialValues } as BusinessAccountInfoUpdate;

            if (this.props.initialValues.image) {
                initialValues['imageData'] = [
                    {
                        uid: this.props.initialValues.image.id,
                        url: getImagePath(this.props.initialValues.image),
                        response: this.props.initialValues.image,
                    },
                ];
            }
        }
        return initialValues;
    };

    onOk = async (data: any) => {
        let imageId: number =
            data.imageData && data.imageData[0] && data.imageData[0].response && data.imageData[0].response.id
                ? data.imageData[0].response.id
                : undefined;
        delete data.imageData;

        let newData: BusinessAccountContactsInfoUpdate = {
            ...this.props.initialValues,
            ...data,
        } as BusinessAccountContactsInfoUpdate;

        if (newData.description === '') delete newData.description;
        if (newData.comment === '') delete newData.comment;
        if (newData.contact?.phoneNumber === '') delete newData.contact.phoneNumber;
        if (newData.contact?.position === '') delete newData.contact.position;
        if (newData.contact?.fullName.secondname === '') delete newData.contact.fullName.secondname;

        newData.image = imageId ? { id: imageId } : undefined;
        let result = await this.props.updateBusinessAccountContacts(this.props.businessAccountId, newData);
        if (!(result instanceof Error)) {
            showNotification('success', localizeIntl(this.props.intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__UPDATED)); // TODO мб выводить норм сообщение
            this.props.push(this.props.goBackPath);
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.push(this.props.goBackPath);
            this.props.resetBusinessAccountContactsError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.businessAccountContacts.updating,
    updatingError: storeState.businessAccountContacts.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    updateBusinessAccountContacts,
    push,
    resetBusinessAccountContactsError,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const BusinessAccountProfileEditModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_BusinessAccountProfileEditModal));

import React, { ReactNode } from 'react';
import { Icon, Popover, Radio } from 'antd';
import { IconSortAmountDownAltSolid } from '../../../components/icons';
import { LocalizationEnum, localize } from '../../../localization';
import './categories-block-sort.less';
import { CategorySortOrderEnum } from '../../../server';

export interface CategoriesBlockSortProps {
    value: CategorySortOrderEnum;
    titles: { [k in CategorySortOrderEnum]: string };
    onChangeSortState: (sortState: Partial<CategoriesBlockSortProps>) => void;
}

const CategoriesBlockSortContent: React.FC<CategoriesBlockSortProps> = ({ value, titles, onChangeSortState }) => {
    const radioButtons: ReactNode[] = [];
    for (let value in CategorySortOrderEnum) {
        const enumValue = CategorySortOrderEnum[value];
        radioButtons.push(
            <Radio key={enumValue} className={'radio'} value={enumValue}>
                {titles[enumValue]}
            </Radio>
        );
    }

    return (
        <div className={'rr-categories-header_popover-content'}>
            <div className="popup-header">{localize(LocalizationEnum.ASPECT__FILTERS__SORT_BY)}:</div>

            <Radio.Group
                className={'radio-group'}
                onChange={(e) => {
                    onChangeSortState({
                        value: e.target.value,
                    });
                }}
                value={value}
            >
                {radioButtons}
            </Radio.Group>
        </div>
    );
};

export const CategoriesBlockSort: React.FC<CategoriesBlockSortProps> = (props) => {
    return (
        <>
            <div className={'sort-block'}>
                <Icon className={'rr-grid-settings-icon'} component={IconSortAmountDownAltSolid} />
                <span className={'text-regular'}>
                    {localize(LocalizationEnum.ASPECT__FILTERS__SORT_BY)}:
                    <Popover
                        trigger={'click'}
                        autoAdjustOverflow
                        placement="bottom"
                        content={<CategoriesBlockSortContent {...props} />}
                    >
                        <span className={'text-bold'}>{props.titles[props.value]}</span>
                    </Popover>
                </span>
            </div>
        </>
    );
};

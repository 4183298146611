import React, { ReactNode } from 'react';
import moment from 'moment';
import DashboardDay from './dashboardDay';

interface IProps {
    value: Date;
    days?: number;
    dayBold?: boolean;
}

export default class DashboardDate extends React.PureComponent<IProps> {
    render() {
        const { value, days, dayBold } = this.props;
        let valueDate: ReactNode;

        const daysParam = days || 0;

        if (moment(value).isSame(Date.now(), 'day')) {
            valueDate = <DashboardDay value={value} day={0} daysParam={daysParam} dayBold={dayBold} />;
        } else if (moment(value).isSame(moment(Date.now()).add(-1, 'day'), 'day')) {
            valueDate = <DashboardDay value={value} day={-1} daysParam={daysParam} dayBold={dayBold} />;
        } else if (moment(value).isSame(moment(Date.now()).add(1, 'day'), 'day')) {
            valueDate = <DashboardDay value={value} day={1} daysParam={daysParam} dayBold={dayBold} />;
        } else if (moment(value).isSame(Date.now(), 'year')) {
            let currentDate = Date.parse(new Date().toString());
            let currentDays = (currentDate - Date.parse(value.toString())) / 86400000; //86400000 - ms в дне

            if (daysParam < Math.round(currentDays) && days) {
                valueDate = moment(value).format('D MMMM');
            } else {
                valueDate = moment(value).format('D MMMM, HH:mm');
            }
        } else {
            let currentDate = Date.parse(new Date().toString());
            let days = (currentDate - Date.parse(value.toString())) / 86400000; //86400000 - ms в дне

            if (daysParam < Math.round(days) && days) {
                valueDate = moment(value).format('L ');
            } else {
                valueDate = moment(value).format('L HH:mm ');
            }
        }

        return <>{valueDate}</>;
    }
}

export const getDashboardDataPeriod = (period?: string) => {
    if (period === '00') {
        //Сегодня и ранее
        return {
            dataEnd: +moment().endOf('day'),
            dataStart: +moment().startOf('day'),
        };
    } else if (period === '01') {
        //Вчера
        return {
            dataStart: +moment().subtract(1, 'day').endOf('day') + 1,
            dataEnd: +moment().subtract(1, 'day').startOf('day'),
        };
    } else if (period === '02') {
        // Сегодня
        return {
            dataStart: +moment().endOf('day') + 1,
            dataEnd: +moment().startOf('day'),
        };
    } else if (period === '03') {
        //Завтра
        return {
            dataStart: +moment().add(1, 'day').endOf('day') + 1,
            dataEnd: +moment().add(1, 'day').startOf('day'),
        };
    } else if (period === '06') {
        //Послезавтра
        return {
            dataStart: +moment().add(2, 'day').endOf('day') + 1,
            dataEnd: +moment().add(2, 'day').startOf('day'),
        };
    } else if (period === '04') {
        //Неделя
        return {
            dataStart: +moment().startOf('day').add(6, 'day').endOf('day') + 1,
            dataEnd: +moment().startOf('day'),
        };
    } else if (period === '05') {
        //Месяц
        return {
            dataStart: +moment().startOf('day').add(29, 'day').endOf('day') + 1,
            dataEnd: +moment().startOf('day'),
        };
    }

    return {
        dataStart: undefined,
        dataEnd: undefined,
    };
};

import { LogisticsPageTabsEnum } from '../../../../shared/constants/tabEnums';

export const logisticsPageUrlRoute = 'logistics';
export const defaultTab = LogisticsPageTabsEnum.LOCATIONS;

export const tabToCreateButtonText: Record<LogisticsPageTabsEnum, string> = {
    [LogisticsPageTabsEnum.LOCATIONS]: 'Создать локацию',
    [LogisticsPageTabsEnum.VEHICLES]: 'Создать транспорт',
    [LogisticsPageTabsEnum.TRANSPORTATIONS]: 'Создать перевозку',
};

import './TagSelect.less';
import React, { FC, KeyboardEvent, memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TagEntityTypeCode } from '../../../server';
import { useAppSelector } from '../../../store/hooks';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { CustomSelect } from '../CustomSelect';
import { Select } from 'antd';
import { LocalizationEnum, localize } from '../../../localization';
import Spin from '../../spin/spin';
import { LabeledValue, SelectProps, SelectValue } from 'antd/lib/select';
import ReactDOM from 'react-dom';
import { TagsUtils } from './utils/tags.utils';
import { CreateEntityButton } from '../../buttons/EntityButton/v1/CreateEntityButton';
import { ListTagsArgs } from './api/types';
import { ServerUtils } from '../../../core/utils/serverUtils';
import { useLocation, useParams } from 'react-router-dom';
import { useLazyListTagsQuery } from './api/tags.api';

interface TagSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
    tagType: TagEntityTypeCode;
    value?: string[];
    onChange?: (value: string[]) => void;
    parentEntity?: ListTagsArgs['parentEntity'];
    withButton?: boolean;
    ignoreQueryFilters?: boolean;
}

export const TagSelect: FC<TagSelectProps> = memo((props) => {
    const { tagType, value, onChange, parentEntity, withButton, ignoreQueryFilters = false, ...selectProps } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const location = useLocation();
    const params = useParams<{ id?: string }>();
    const [searchInput, setSearchInput] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const selectRef = useRef<CustomSelect>(null);
    const selectRefNode = ReactDOM.findDOMNode(selectRef.current) as Element | undefined;
    const inputNode = selectRefNode?.querySelector('.ant-select-search__field') as HTMLInputElement | undefined;
    const blockSearchInputEnter = useRef(false);

    const renterId = location.pathname.includes('/crm/counterparties') && params.id ? params.id : undefined;

    const queryFilters = renterId && !ignoreQueryFilters ? ServerUtils.createRequestFilters([['renterId', 'EQ', renterId]]) : undefined;

    const [fetchFilters, { data: loadedTags, isLoading, isError, isSuccess }] = useLazyListTagsQuery();

    const onSearch = useCallback((value: string) => {
        setSearchInput(value);
    }, []);

    const onBlur = useCallback(() => {
        setTimeout(() => {
            setSearchInput('');
        }, 150);
    }, []);

    const clearSearchInput = useCallback(() => {
        setSearchInput('');
        inputNode?.removeAttribute('style');
    }, [inputNode]);

    const onSelectChange = useCallback(
        (values: SelectValue) => {
            if (!Array.isArray(values)) return;

            const selectedTags = (values as LabeledValue[]).map((value) => (typeof value === 'object' ? value.key : value));
            onChange?.(selectedTags);
        },
        [onChange]
    );

    const onSelect: SelectProps['onSelect'] = useCallback(
        (selectValue: string | number | LabeledValue) => {
            if (
                searchInput &&
                typeof selectValue === 'object' &&
                'key' in selectValue &&
                TagsUtils.includes(selectValue.key, searchInput)
            ) {
                blockSearchInputEnter.current = true;
            }
            onSelectChange([...(value ?? []), selectValue] as SelectValue);
        },
        [onSelectChange, searchInput, value]
    );

    const currentValue: SelectValue = useMemo(
        () =>
            value?.map((tag) => ({
                key: tag,
                label: tag,
            })) ?? [],
        [value]
    );

    const filteredTags = loadedTags?.filter((tag) => !Boolean(searchInput) || TagsUtils.includes(tag, searchInput));
    const availableTags = filteredTags?.map((tag) => <Select.Option key={tag}>{tag}</Select.Option>);

    const addSearchInputToTags = useCallback(() => {
        const newValue = filteredTags?.find((tag) => TagsUtils.compare(tag, searchInput)) ?? searchInput;

        if (newValue.trim().length === 0) return;

        onSelectChange([
            ...currentValue,
            {
                key: newValue,
                label: newValue,
            },
        ]);
        clearSearchInput();
    }, [clearSearchInput, currentValue, filteredTags, onSelectChange, searchInput]);

    const isButtonDisabled =
        !searchInput ||
        filteredTags == null ||
        filteredTags.some((tag) => TagsUtils.compare(tag, searchInput)) ||
        currentValue == null ||
        currentValue.some(({ key }) => TagsUtils.compare(key, searchInput));

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                setTimeout(() => {
                    if (!searchInput || currentValue.some((value) => TagsUtils.compare(value.key, searchInput))) return;

                    if (blockSearchInputEnter.current) {
                        blockSearchInputEnter.current = false;
                        return;
                    }

                    addSearchInputToTags();
                }, 100);
            }

            if (event.key === 'Escape') {
                setDropdownVisible(false);
                clearSearchInput();
                inputNode?.blur();
            }
        },
        [addSearchInputToTags, clearSearchInput, currentValue, inputNode, searchInput]
    );

    const onDropdownVisibleChange = useCallback(
        (visible) => {
            setDropdownVisible(visible);

            if (!isSuccess) {
                fetchFilters({
                    businessAccountId,
                    tagType,
                    parentEntity,
                    additionalQueryFilters: queryFilters,
                    ignoreQueryFilters,
                });
            }
        },
        [businessAccountId, fetchFilters, ignoreQueryFilters, isSuccess, parentEntity, queryFilters, tagType]
    );

    useEffect(() => {
        if (inputNode?.value) {
            inputNode.value = searchInput;
        }
    }, [inputNode, inputNode?.value, searchInput]);

    useEffect(() => {
        if (isSuccess) {
            inputNode?.focus();
        }
    }, [inputNode, isSuccess]);

    return (
        <div className={'tag-select-container'}>
            <CustomSelect
                ref={selectRef}
                open={dropdownVisible}
                onDropdownVisibleChange={onDropdownVisibleChange}
                allowClear={Boolean(searchInput) || Boolean(value?.length)}
                autoClearSearchValue={true}
                className={'tag-select'}
                defaultActiveFirstOption={false}
                disabled={isError || isLoading}
                dropdownRender={(menu: ReactNode) => (
                    <>
                        {menu}
                        {isLoading && !isError ? (
                            <div className={'rr-custom-select-loading-block'}>
                                <Spin size={'small'} delay={0} />
                                <span>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</span>
                            </div>
                        ) : null}
                        {!isLoading && isError ? (
                            <div className={'rr-custom-select-error-block'}>
                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR)}
                            </div>
                        ) : null}
                    </>
                )}
                filterOption={false}
                getInputElement={() => <input className={'input'} maxLength={30} />}
                labelInValue
                loading={isLoading}
                mode={'multiple'}
                notFoundContent={isLoading || isError ? null : localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)}
                onChange={onSelectChange}
                onSearch={onSearch}
                onSelect={onSelect}
                onInputKeyDown={onKeyDown}
                placeholder={'Выберите или введите тэг'}
                showSearch
                value={currentValue}
                onBlur={onBlur}
                {...selectProps}
            >
                {availableTags}
            </CustomSelect>
            {withButton && <CreateEntityButton disabled={isButtonDisabled} onMouseDown={addSearchInputToTags} />}
        </div>
    );
});

import React, { FC } from 'react';
import { CalendarDisplayType } from '../../../../../Calendar/utils/data/calendarConstants';
import './CalendarGroupItem.less';
import { CalendarItemGroup } from '../../../../../Calendar/types/items';
import { GroupItemProps } from '../../utils/types';
import { CalendarGroupItemContainer } from './components/container/CalendarGroupItemContainer';

export type CalendarGroupsObject<SortValue extends string> = Partial<Record<SortValue, FC<GroupItemProps>>>;
export type CalendarGroupsCallbackObject<SortValue extends string> = Partial<Record<SortValue, () => void>>;

export interface CalendarGroupItemProps<SortValue extends string> {
    item: CalendarItemGroup;
    displayType: CalendarDisplayType;
    groupsData: CalendarGroupsObject<SortValue>;
}

export const CalendarGroupItem = <SortValue extends string>(props: CalendarGroupItemProps<SortValue>) => {
    const { item, displayType, groupsData } = props;

    const GroupComponent = groupsData[item.sortGroup];

    const Group = GroupComponent ? <GroupComponent groupItem={item} displayType={displayType} /> : null;

    return (
        <CalendarGroupItemContainer displayType={displayType} item={item}>
            {Group}
        </CalendarGroupItemContainer>
    );
};

import { getAllStatusesExceptSelected } from '../../../../../../shared/util/utils4';
import { HideValue } from '../filters/elementsCalendarFilters.data';
import { ALL_STATE_CODES, RENT_STATE_CODES, SUBRENT_STATE_CODES } from '../../../../../../types';
import { RentStateCodeEnum } from '../../../../../../server';

export abstract class ElementsCalendarApiUtilsFilters {
    static getHideStateCodeFilter(hide: HideValue | undefined): RentStateCodeEnum[] | undefined {
        if (hide == null) return undefined;

        return (
            {
                [HideValue.INACTIVE]: getAllStatusesExceptSelected(ALL_STATE_CODES, [
                    RentStateCodeEnum.RENT,
                    RentStateCodeEnum.ORDERED,
                    RentStateCodeEnum.BOOKED,
                    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                    RentStateCodeEnum.SUBRENT,
                    RentStateCodeEnum.MIXED,
                ]),
                [HideValue.CANCELLED]: [RentStateCodeEnum.CANCELED, RentStateCodeEnum.SUBRENTCANCELED],
                [HideValue.CANCELED_DRAFT]: [
                    RentStateCodeEnum.CANCELED,
                    RentStateCodeEnum.SUBRENTCANCELED,
                    RentStateCodeEnum.DRAFT,
                    RentStateCodeEnum.SUBRENTDRAFT,
                ],
                [HideValue.RENT]: RENT_STATE_CODES,
                [HideValue.SUBRENT]: SUBRENT_STATE_CODES,
            } satisfies Record<HideValue, RentStateCodeEnum[]>
        )[hide];
    }
    static getTypeCodesFilter(typeCodes: string[] | undefined, subrentModuleEnabled: boolean | undefined) {
        if (typeCodes == null) return undefined;

        const filteredTypeCodes = subrentModuleEnabled
            ? typeCodes
            : typeCodes.filter(
                  (typeCode) =>
                      !(
                          [
                              RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                              RentStateCodeEnum.SUBRENT,
                              RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
                              RentStateCodeEnum.SUBRENTCANCELED,
                              RentStateCodeEnum.SUBRENTDRAFT,
                          ] as string[]
                      ).includes(typeCode)
              );

        if (filteredTypeCodes.length === 0) return undefined;

        return filteredTypeCodes;
    }
}

import React, {MouseEventHandler} from 'react';
import {Badge, Button, Icon, Layout, Menu, Popover} from 'antd';
import {Link, matchPath} from 'react-router-dom';
import {IRouterItem, routes} from '../../../config/routes';
import {ReactComponent as LogoIcon} from '../../../assets/images/rr-logo-icon.svg';
import {ReactComponent as LogoText} from '../../../assets/images/rr-logo-text.svg';
import {MENU_COLLAPSED_WIDTH} from '../../../config/constants';
import {IRootState} from '../../reducers';
import {connect} from 'react-redux';
import {getBusinessAccountId} from '../../../../index';
import {FormattedMessage} from 'react-intl';
import UserPopover from '../../../components/userPopover/userPopover';
import {getOperationStatusTypeData} from '../../../modules/main/operationForm/utils/utils';
import {BusinessAccountTypeCodeEnum, OperationTypeCodeEnum} from '../../../server';
import {Avatar} from '../../../components/avatar/avatar';
import {setRenterAndProject} from '../../../modules/main/operationForm/reducers/operationForm.reducer';
import {push} from 'connected-react-router';
import {IconBriefcase, IconDollySolid, IconShoppingBagSolid} from '../../../components/icons';
import {
    ordersModuleEnabledSelector,
    projectsModuleEnabledSelector,
    subrentModuleEnabledSelector
} from '../../reducers/businessAccountPreferences.reducer';
import './sidebar.less';
import {InventoryPageTabsEnum, ProjectsPageTabsEnum, RentersPageTabsEnum, SubrentPageTabsEnum} from '../../constants/tabEnums';

interface SidebarProps extends StateProps, DispatchProps {
    data: ReadonlyArray<IRouterItem>;
    collapsed: boolean;
    onCollapse: Function;
    width: number;
    height: number | string;
}

interface State {
    collapsed: boolean;
    visible: boolean;
}

class _Sidebar extends React.PureComponent<SidebarProps, State> {
    _matchPath;
    timer;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: props.collapsed,
            visible: false,
        };
    }

    menuItemRenderer(item: IRouterItem) {
        let linkPath = item.menuPath || item.path.toString();
        let menuItemKey = item.menuKey;
        let pathParams;
        if (this._matchPath) {
            pathParams = this._matchPath.params;
            for (let k in pathParams) {
                linkPath = linkPath.replace(':' + k, pathParams[k]);
            }
        }

        const { subrentModuleEnabled, ordersModuleEnabled, projectsModuleEnabled } = this.props;

        let badgeCount = 0,
            delayCount = 0,
            shortageCount = 0,
            badgeColor = '#f4516c',
            badgeTitle = '',
            badgeLinkPath = '',
            operationColorClass = '';

        let enabledRentModulesCount = 0;
        if(projectsModuleEnabled) enabledRentModulesCount += 1;
        if(ordersModuleEnabled) enabledRentModulesCount += 1;
        if(subrentModuleEnabled) enabledRentModulesCount += 1;
        const showOperationPopover = enabledRentModulesCount > 1;

        if (['operations', 'projects', 'counterparties', 'inventory', 'calendar', 'subrent'].includes(item.menuKey)) {
            let {
                currentDelays_currentProjectsCount,
                currentShortages_currentProjectsCount,
                currentDelays_currentRentersCount,
                currentShortages_currentRentersCount,
                currentDelays_currentProductsCount,
                currentShortages_currentProductsCount,
                currentDelays_currentRentElementsCount,
                currentShortages_currentRentElementsCount,
                currentDelays_currentSubrentsCount,
                currentShortages_currentSubrentsCount,

                currentDelays_rentProblem,
                currentDelays_subrentProblem,
                currentShortages_rentProblem,
                currentShortages_subrentProblem,
            } = this.props;

            if (item.menuKey === 'projects') {
                delayCount = currentDelays_currentProjectsCount || 0;
                shortageCount = currentShortages_currentProjectsCount || 0;
                badgeLinkPath = `${linkPath}/${ProjectsPageTabsEnum.PROJECTS}${
                    linkPath.indexOf('?') < 0 ? '?' : '&'
                }problem=ANY_PROBLEM_OR_WARNING`;
            } else if (item.menuKey === 'counterparties') {
                delayCount = currentDelays_currentRentersCount || 0;
                shortageCount = currentShortages_currentRentersCount || 0;
                badgeLinkPath = `${linkPath}/${RentersPageTabsEnum.COUNTERPARTIES}${
                    linkPath.indexOf('?') < 0 ? '?' : '&'
                }problem=ANY_PROBLEM_OR_WARNING`;
            } else if (item.menuKey === 'inventory') {
                delayCount = currentDelays_currentProductsCount || 0;
                shortageCount = currentShortages_currentProductsCount || 0;
                badgeLinkPath = `${linkPath}/${InventoryPageTabsEnum.PRODUCTS}${
                    linkPath.indexOf('?') < 0 ? '?' : '&'
                }problem=ANY_PROBLEM_OR_WARNING`;
            } else if (item.menuKey === 'calendar') {
                delayCount = currentDelays_currentRentElementsCount || 0;
                shortageCount = currentShortages_currentRentElementsCount || 0;
                badgeLinkPath = `${linkPath}?tab=elements&problem=ANY_PROBLEM_OR_WARNING&group=project&capture=all`;
            } else if (item.menuKey === 'subrent') {
                delayCount = currentDelays_currentSubrentsCount || 0;
                shortageCount = currentShortages_currentSubrentsCount || 0;
                badgeLinkPath = `${linkPath}/${SubrentPageTabsEnum.SHIPPINGS}${
                    linkPath.indexOf('?') < 0 ? '?' : '&'
                }problem=ANY_PROBLEM_OR_WARNING`;
            }
            badgeCount = delayCount + shortageCount;
            badgeTitle = 'Просрочек: ' + delayCount + ', Нехваток: ' + shortageCount;
            if (!badgeLinkPath) badgeLinkPath = `${linkPath}${linkPath.indexOf('?') < 0 ? '?' : '&'}problem=ANY_PROBLEM_OR_WARNING`;

            if (badgeCount > 0) {
                badgeColor = '#ff8d3a';
                if (item.menuKey === 'inventory' || item.menuKey === 'calendar' || item.menuKey === 'counterparties') {
                    if (
                        currentDelays_rentProblem ||
                        currentDelays_subrentProblem ||
                        currentShortages_rentProblem ||
                        currentShortages_subrentProblem
                    )
                        badgeColor = '#f4516c';
                } else if (item.menuKey === 'projects') {
                    if (currentDelays_rentProblem || currentShortages_rentProblem) badgeColor = '#f4516c';
                } else if (item.menuKey === 'subrent') {
                    if (currentDelays_subrentProblem || currentShortages_subrentProblem) badgeColor = '#f4516c';
                }
            }
        } else if (item.menuKey === 'operation') {
            if (this.props.operationMnemoKey) badgeCount = this.props.totalOperationElementsCount;
            // if(this.props.isSimpleOrder && this.props.operationMnemoKey){
            //     linkPath = '';
            // }

            // Когда тип операции EDIT, то при нажатии на кнопку операции
            // то перенаправляем на сохраненную страницу где было редактирование
            if(this.props.operationMnemoKey && this.props.operationTypeCode === OperationTypeCodeEnum.EDIT && this.props.operationFormGoBackPath){
                linkPath = this.props.operationFormGoBackPath;
            }

            if(!this.props.operationMnemoKey){
                if(this.props.projectsModuleEnabled || this.props.subrentModuleEnabled) linkPath = `/${this.props.businessAccountId}/operation`;
                else if(this.props.ordersModuleEnabled)  linkPath = `/${this.props.businessAccountId}/order`;
            }

            badgeColor = '';
            badgeTitle = 'Обязательств: ' + badgeCount;
            badgeLinkPath = linkPath;
        }

        let title = item.title ? (
            <span>
                <FormattedMessage id={item.title} />
            </span>
        ) : null;
        let { icon } = item;

        if (item.menuKey === 'operation' && this.props.operationTypeCode) {
            let statusData = getOperationStatusTypeData(this.props.operationTypeCode, false);

            icon = statusData.iconComponent;
            title = <span>{statusData.name}</span>;
            operationColorClass =
                this.props.operationTypeCode !== OperationTypeCodeEnum.DRAFT
                    ? ' rr-operation-status-bg-' + this.props.operationTypeCode
                    : ' rr-menu-operation-draft-item';
        }

        if (item.menuKey === 'operation' && !this.props.operationTypeCode) {
            if(enabledRentModulesCount === 1){
                if(projectsModuleEnabled || subrentModuleEnabled) {
                    title = <span>Новая операция</span>;
                }  else if(ordersModuleEnabled) {
                    title = <span>Новый заказ</span>;
                }
            }
        }

        const isLinkBlocked = this.props.locationPathname.includes(linkPath) && this.props.blockedUrl === linkPath;
        let onClick = !isLinkBlocked ? undefined : (e) => e.preventDefault();
        if(!linkPath) onClick = (e) => e.preventDefault();

        return (
            <Menu.Item key={menuItemKey} className={'rr-menu-item-' + menuItemKey + operationColorClass} style={{ boxShadow: 'none' }}>
                <Link to={badgeLinkPath}>
                    <Badge
                        overflowCount={999}
                        showZero={item.menuKey === 'operation' && this.props.operationTypeCode !== undefined && !this.state.collapsed}
                        style={badgeColor ? { backgroundColor: badgeColor, animation: 'none' } : {}}
                        title={badgeTitle}
                        count={badgeCount}
                        dot={badgeCount !== undefined && badgeCount > 0 && this.state.collapsed}
                    >
                        {<Icon className={'rr-menu-icon'} component={icon} />}
                        {title}
                    </Badge>
                </Link>
                {showOperationPopover && item.menuKey === 'operation' && !this.props.operationMnemoKey && !this.props.subrentForShortage ? (
                    <Popover
                        overlayClassName={'rr-app-menu-operation-popover'}
                        trigger={'hover'}
                        mouseEnterDelay={0.09}
                        mouseLeaveDelay={0.11}
                        overlayStyle={{ zIndex: 1100, width: 308 }}
                        {...{}}
                        align={{ offset: [0, -60] }}
                        placement="rightTop"
                        content={
                            item.menuKey === 'operation' && (
                                <div className={'rr-app-menu-operation-popover-content'}>
                                    {
                                        ordersModuleEnabled && (
                                            <Button
                                                block
                                                onClick={async (e) => {
                                                    this.props.push(`/${this.props.businessAccountId}/order`);
                                                }}
                                            >
                                                <Icon style={{ color: '#2196F3' }} component={IconShoppingBagSolid} />
                                                <span>Создать заказ</span>
                                            </Button>
                                        )
                                    }
                                    {
                                        projectsModuleEnabled && (
                                            <Button
                                                block
                                                onClick={async (e) => {
                                                    await this.props.setRenterAndProject(0, '', undefined, undefined, 0, false);
                                                    this.props.push(`/${this.props.businessAccountId}/operation`);
                                                }}
                                            >
                                                <Icon style={{ color: '#525A95' }} component={IconBriefcase} />
                                                <span>Операция аренды</span>
                                            </Button>
                                        )
                                    }
                                    {
                                        subrentModuleEnabled && (
                                            <Button
                                                block
                                                onClick={async (e) => {
                                                    await this.props.setRenterAndProject(0, '', undefined, undefined, 0, true);
                                                    this.props.push(`/${this.props.businessAccountId}/operation`);
                                                }}
                                            >
                                                <Icon style={{ color: '#ffb822' }} component={IconDollySolid} />
                                                <span>Операция субаренды</span>
                                            </Button>
                                        )
                                    }
                                </div>
                            )
                        }
                    >
                        <Link to={linkPath} onClick={onClick} />
                    </Popover>
                ) : (
                    <Link to={linkPath} onClick={onClick} />
                )}
            </Menu.Item>
        );
    }

    onUnvisiblePopoverAccount: MouseEventHandler<HTMLDivElement> = (e) => {
        let el = document.querySelector('.user-popover-account-hover');
        this.timer = setTimeout(() => {
            if (el !== null) {
                el.className = 'user-popover-account-unhover';
            }
            if (this.state.visible) {
                this.setState({
                    visible: false,
                });
            }
        }, 700);
    };

    onVisiblePopoverAccount = (e) => {
        let el = document.querySelector('.user-popover-account-unhover');
        if (el !== null) {
            el.className = 'user-popover-account-hover';
        }
        this.setState({
            visible: true,
        });
        clearTimeout(this.timer);
    };

    render() {
        this._matchPath = null;
        let matchKeys: string[] = [];
        routes.forEach((item) => {
            let match = matchPath(this.props.locationPathname, item);
            if (match) {
                if (!this._matchPath) this._matchPath = match;
                matchKeys.push(item.menuKey || '');
            }
        });

        let menu1 = this.props.data.slice(0, 9),
            menu2 = this.props.data.slice(9);

        return (
            <Layout.Sider
                style={{ height: this.props.height, position: 'fixed', left: 0, background: '#383f77' }}
                width={this.props.width}
                breakpoint="md"
                collapsedWidth={MENU_COLLAPSED_WIDTH}
                collapsed={this.state.collapsed}
            >
                <div className={'rr-app-menu-container'}>
                    <div className={'rr-menu-logo-container'}>
                        <Link to={`/${this.props.businessAccountId}/`}>
                            <LogoIcon />
                            <LogoText />
                        </Link>
                    </div>
                    <div className={'rr-app-menu-scrollContainer'}>
                        <div className={'rr-app-menu-items1'}>
                            <Menu mode={'inline'} selectedKeys={matchKeys} inlineIndent={18}>
                                {menu1.map((item: IRouterItem) => this.menuItemRenderer(item))}
                            </Menu>
                        </div>
                        <div className={'rr-app-menu-items2'}>
                            <Menu mode={'inline'} selectedKeys={matchKeys} inlineIndent={18}>
                                {menu2.map((item: IRouterItem) => this.menuItemRenderer(item))}
                            </Menu>
                            <Link to={`/${getBusinessAccountId()}/userProfile`}>
                                <div
                                    onMouseEnter={this.onVisiblePopoverAccount}
                                    onMouseLeave={this.onUnvisiblePopoverAccount}
                                    className={
                                        'rr-app-menu rr-app-menu-profile' +
                                        (this.props.collapsed ? ' rr-app-menu-profile-collapsed' : '') +
                                        (matchKeys.indexOf('userProfile') !== -1 ? ' rr-app-menu-profile-selected' : '')
                                    }
                                >
                                    <div className={'rr-app-menu-profile-avatar'}>
                                        <Avatar icon="user" src={this.props.userAvatar} />
                                    </div>
                                    {this.props.collapsed ? (
                                        <div style={{ width: '15px' }}></div>
                                    ) : (
                                        <div className={'rr-app-menu-profile-name'} style={{ opacity: this.props.collapsed ? 0 : 1 }}>
                                            <div style={{ marginBottom: '5px', wordBreak: 'break-word' }}>{this.props.userName}</div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <div style={{ wordBreak: 'break-word', fontWeight: 600 }}>
                                                    {this.props.businessAccountShortName}
                                                </div>
                                                {this.props.businessAccountTypeCode === BusinessAccountTypeCodeEnum.TRIAL && (
                                                    <div>
                                                        <span
                                                            style={{
                                                                verticalAlign: 'super',
                                                                fontSize: 11,
                                                                fontWeight: 600,
                                                                color: '#7dd9e9',
                                                                wordBreak: 'normal',
                                                                display: 'block',
                                                                marginTop: -2,
                                                            }}
                                                        >
                                                            TRIAL
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div style={{ height: 0 }}>
                        <Button
                            htmlType={'button'}
                            shape={'circle'}
                            size={'large'}
                            style={{
                                animationDuration: '0s',
                                position: 'absolute',
                                right: -25,
                                top: '50%',
                                zIndex: 11,
                                borderWidth: 0,
                                color: '#383f77',
                                width: 50,
                                height: 50,
                                marginTop: -25 + 70 / 2,
                            }}
                            icon={this.state.collapsed ? 'right' : 'left'}
                            onClick={() => {
                                this.setState({ collapsed: !this.state.collapsed });
                                if (this.props.onCollapse) this.props.onCollapse(!this.state.collapsed);

                                window.dispatchEvent(new Event('resize'));
                                setTimeout(() => {
                                    window.dispatchEvent(new Event('resize'));
                                }, 300);
                            }}
                        />
                    </div>
                </div>

                <UserPopover
                    onVisiblePopoverAccount={this.onVisiblePopoverAccount}
                    onUnvisiblePopoverAccount={this.onUnvisiblePopoverAccount}
                    visible={this.state.visible}
                    collapsed={this.props.collapsed}
                />
            </Layout.Sider>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    userName: storeState.userProfilePopover.entity?.shortName,
    businessAccountShortName: storeState.businessAccountContacts.entity?.shortName,
    totalOperationElementsCount: storeState.operationForm.elements.entities.filter((el) => !el.parentId).length,
    operationTypeCode: storeState.operationForm.typeCode,
    businessAccountId: storeState.system.businessAccountId,
    locationPathname: storeState.router.location.pathname,

    currentDelays_currentProjectsCount: storeState.businessAccountIndicators.indicators?.currentDelays.currentProjectsCount,
    currentShortages_currentProjectsCount: storeState.businessAccountIndicators.indicators?.currentShortages.currentProjectsCount,

    currentDelays_currentRentersCount: storeState.businessAccountIndicators.indicators?.currentDelays.currentRentersCount,
    currentShortages_currentRentersCount: storeState.businessAccountIndicators.indicators?.currentShortages.currentRentersCount,

    currentDelays_currentProductsCount: storeState.businessAccountIndicators.indicators?.currentDelays.currentProductsCount,
    currentShortages_currentProductsCount: storeState.businessAccountIndicators.indicators?.currentShortages.currentProductsCount,

    currentDelays_currentRentElementsCount: storeState.businessAccountIndicators.indicators?.currentDelays.currentRentElementsCount,
    currentShortages_currentRentElementsCount: storeState.businessAccountIndicators.indicators?.currentShortages.currentRentElementsCount,

    currentDelays_currentSubrentsCount: storeState.businessAccountIndicators.indicators?.currentDelays.currentSubrentsCount,
    currentShortages_currentSubrentsCount: storeState.businessAccountIndicators.indicators?.currentShortages.currentSubrentsCount,

    currentDelays_rentProblem: storeState.businessAccountIndicators.indicators?.currentDelays.rentProblem,
    currentDelays_subrentProblem: storeState.businessAccountIndicators.indicators?.currentDelays.subrentProblem,

    currentShortages_rentProblem: storeState.businessAccountIndicators.indicators?.currentShortages.rentProblem,
    currentShortages_subrentProblem: storeState.businessAccountIndicators.indicators?.currentShortages.subrentProblem,

    operationMnemoKey: storeState.operationForm.mnemoKey,
    subrentForShortage: storeState.operationForm.subrentForShortage,

    businessAccountTypeCode: storeState.businessAccount.entity?.typeCode,

    userAvatar: storeState.oidc.user?.profile.picture,

    blockedUrl: storeState.userSettings.blockedUrl,

    subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    ordersModuleEnabled: ordersModuleEnabledSelector(storeState),
    projectsModuleEnabled: projectsModuleEnabledSelector(storeState),
    isSimpleOrder: storeState.operationForm.isSimpleOrder,
    operationFormGoBackPath: storeState.operationForm.goBackPath,
});

const mapDispatchToProps = { setRenterAndProject, push };
type DispatchProps = typeof mapDispatchToProps;

type StateProps = ReturnType<typeof mapStateToProps>;

export const Sidebar = connect(mapStateToProps, mapDispatchToProps)(_Sidebar);

import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { resetError, updateSettings } from './reducers/systemOptions.reducer';
import { SystemOptionInfoUpdate } from '../../../../server/api';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    goBackPath: string;
    settings: any;
    sectionId: number;
}

class SystemOptionsEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps & WrappedComponentProps> {
    onOk = async (data: any) => {
        let options: SystemOptionInfoUpdate[] = [];
        this.props.settings.forEach((group) => {
            if (group.systemOptions) {
                group.systemOptions.forEach((option) => {
                    if (data[option.id] !== undefined) {
                        options.push({
                            id: option.id,
                            businessVersion: option.businessVersion,
                            value: data[option.id],
                        });
                    }
                });
            }
        });

        let result = await this.props.updateSettings(this.props.sectionId, options);
        if (!(result instanceof Error)) {
            showNotification(
                'success',
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__EXTRA__POPUP_NOTIFICATIONS__SETTINGS_UPDATED)
            ); // TODO мб выводить норм сообщение
            this.props.push(this.props.goBackPath);
        }
    };

    onCancel = (isFieldsTouched: Boolean) => {
        showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT), undefined, () => {
            this.props.push(this.props.goBackPath);
            this.props.resetError();
        });
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.systemOptions.updating,
    updatingError: storeState.systemOptions.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = { updateSettings, push, resetError, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SystemOptionsEditModal));

import { FC } from 'react';
import moment from 'moment/moment';

interface PopoverDateProps {
    time: number | null;
}

export const PopoverDate: FC<PopoverDateProps> = (props) => {
    const { time } = props;

    return (
        <span className={'date'}>
            {time ? (
                <>
                    {moment(time).format('DD.MM.YY')}
                    <br />
                    {moment(time).format('HH:mm')}
                </>
            ) : (
                <span
                    style={{
                        fontWeight: 600,
                        color: '#AAA',
                        paddingTop: 2,
                    }}
                >
                    ?
                </span>
            )}
        </span>
    );
};

import { FC, useContext } from 'react';
import classNames from 'classnames';
import { WorkPlanningItemContext } from '../../../../../../../../context/CalendarItemContext';
import { WorkPlanningsInnerContent } from './WorkPlanningsInnerContent';
import { CALENDAR_LINE_BASE_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';

export const WorkPlanningsContent: FC = () => {
    const { item, itemContext } = useContext(WorkPlanningItemContext);
    const {
        isDraft,
        record: { stateCode },
    } = item;

    const lineWidth = itemContext.dimensions.width;

    const textColor = isDraft ? CalendarColors.staticColors.TEXT_COLOR : '#fff';
    const backgroundColor = isDraft ? undefined : CalendarColors.workPlanningsStateCodeMap[stateCode];

    return (
        <div
            className={classNames('line-main-block-container')}
            style={{
                background: backgroundColor,
                color: textColor,
                borderRadius: CALENDAR_LINE_BASE_RADIUS,
            }}
        >
            <span
                className={classNames('line-main-block', 'forward')}
                style={{
                    width: lineWidth,
                    clipPath: `inset(0 calc(100% - ${lineWidth}px) 0 0)`,
                }}
            >
                <WorkPlanningsInnerContent backgroundColor={backgroundColor} />
            </span>
            <span
                className={classNames('line-main-block', 'backward')}
                style={{
                    clipPath: `inset(0 0 0 ${lineWidth}px)`,
                }}
            >
                <WorkPlanningsInnerContent backgroundColor={backgroundColor} />
            </span>
        </div>
    );
};

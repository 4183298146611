import React, { useCallback } from 'react';
import { _Grid } from '../../../../../../components/grid/Grid';
import { TransportationTransitionCodeEnum } from '../../../../../../server';
import { useSetStatusForTransportationsMutation } from '../api/transportations.api';

export const useSetStatusForTransportationsCallback = (
    selectedRecords: { id: number; businessVersion: number }[],
    gridRef: React.RefObject<_Grid> | undefined,
    setStatusForTransportations: ReturnType<typeof useSetStatusForTransportationsMutation>[0]
) => {
    return useCallback(
        (transitionCode: TransportationTransitionCodeEnum) => {
            const workflowDirectiveBulkDirectives = selectedRecords.map(({ id, businessVersion }) => {
                return {
                    id,
                    businessVersion,
                    transitionCode,
                };
            });

            setStatusForTransportations({
                workflowDirectiveBulk: {
                    directives: workflowDirectiveBulkDirectives,
                },
            });

            if (gridRef != null) {
                gridRef.current?.clearSelection();
            }
        },
        [gridRef, selectedRecords, setStatusForTransportations]
    );
};

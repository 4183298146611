import React, { FC, useEffect, useState } from 'react';
import { Popover } from 'antd';
import { AbstractTooltipProps } from 'antd/lib/tooltip';
import { ProductVariantPopoverContent } from './productVariantPopoverContent';
import { VariantRecord } from '../../../../../../server';
import { isDefined } from '../../../../../../shared/util/utils';

interface ProductVariantPopoverProps {
    /**
     * id продукта
     */
    productId: number;
    /**
     * id варианта
     */
    variantId: number;
    /**
     * Срабатывает когда закрываем поповер
     */
    onClose?: () => void;

    /**
     * Срабатывает когда кликаем на крестик
     */
    onCloseButtonClick?: () => void;

    /**
     * Вызывается когда нажимается какая-то ссылка и нужно закрыть родительский поповер
     * Пример: Поповер варианта показан из попапа экземпляра, и в попопе варианта жмем ссылку и нужно закрыть оба попапа
     */
    onLinkClick?: () => void;
    /**
     * Управление видимостью поповера
     */
    visible?: boolean;

    /**
     * рекорд из грида
     */
    record?: VariantRecord;

    /**
     *
     */
    placement?: AbstractTooltipProps['placement'];
}

export const ProductVariantPopover: FC<ProductVariantPopoverProps> = ({
    record,
    productId,
    variantId,
    children,
    onClose,
    onLinkClick,
    placement,
    onCloseButtonClick,
    ...props
}) => {
    const [visible, setVisible] = useState<boolean | undefined>(props.visible || false);

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <Popover
            overlayClassName={'rr-grid-actions-popover'}
            overlayStyle={{ width: 670 }}
            trigger={'click'}
            onVisibleChange={(visible) => {
                if (!isDefined(props.visible)) {
                    setVisible(visible);
                    if (!visible) onClose?.();
                }
            }}
            visible={visible}
            autoAdjustOverflow
            arrowPointAtCenter
            placement={placement ? placement : 'right'}
            content={
                <ProductVariantPopoverContent
                    productId={record?.productId || productId}
                    variantId={record?.id || variantId}
                    onClose={() => {
                        setVisible(false);
                        onClose?.();
                    }}
                    onLinkClick={() => {
                        onCloseButtonClick?.();
                        setVisible(false);
                        onClose?.();
                        onLinkClick?.();
                    }}
                    onCloseButtonClick={onCloseButtonClick}
                />
            }
        >
            {typeof children === 'function' ? children() : children}
        </Popover>
    );
};

import { isNumber } from '../../shared/util/isNumber';
import moment, { Moment } from 'moment/moment';
import { isDefined } from '../../shared/util/utils';

export type PageURLParamDescriptionObject<T = any> = { [key in keyof T]: PageURLParamDescription<T[key]> };

export interface PageURLParamDescription<T = any> {
    id?: string;
    defaultValue: T;
    toString: (param: T) => string | undefined;
    fromString: (param: string) => T;
}

export class PageURLParamDescriptions {
    static pageParam: PageURLParamDescription<number> = {
        defaultValue: 1,
        toString: (v) => '' + v,
        fromString: (v) => {
            if (isNumber(v) && +v >= 1) return Math.round(+v);
            else return 1;
        },
    };

    static limitParam: PageURLParamDescription<number> = {
        defaultValue: 20,
        toString: (v) => '' + v,
        fromString: (v) => {
            if (isNumber(v) && +v >= 0) return Math.round(+v);
            else return 20;
        },
    };

    static sortByParam: PageURLParamDescription<string| undefined> = {
        defaultValue: undefined,
        toString: (v) => v || undefined,
        fromString: (v) => v || undefined,
    };

    static sortOrderParam: PageURLParamDescription<'ASC' | 'DESC' | undefined> = {
        defaultValue: undefined,
        toString: (value) => (value === 'ASC' || value === 'DESC' ? value : undefined),
        fromString: (value) => (value === 'ASC' || value === 'DESC' ? value : undefined),
    };

    static modalParam: PageURLParamDescription<'edit' | 'new' | 'copy' | undefined> = {
        defaultValue: undefined,
        toString: (value) => (['edit', 'new', 'copy'].includes(value!) ? value : undefined),
        fromString: (value) => (['edit', 'new', 'copy'].includes(value) ? (value as 'edit' | 'new' | 'copy') : undefined),
    };

    static searchParam = <T extends string = string>(): PageURLParamDescription<T | undefined> => ({
        defaultValue: undefined,
        toString: (value) => value || undefined,
        fromString: (value) => (value as T) || undefined,
    });

    static hideParam: PageURLParamDescription<string | undefined> = {
        defaultValue: undefined,
        toString: (v) => (v ? v : undefined),
        fromString: (v) => (v ? v : undefined),
    };

    static dateTimeIntervals: PageURLParamDescription<[Moment | undefined, Moment | undefined] | undefined> = {
        defaultValue: undefined,
        toString: (v) => {
            if (v && v.length > 0) {
                if (v[0] && v[1]) return [v[0].valueOf(), v[1].valueOf()].toString();
                else if (v[0]) return [v[0].valueOf()].toString();
                else if (v[1]) return [undefined, v[1].valueOf()].toString();
            } else return undefined;
        },
        fromString: (v) => {
            if (v) {
                let dates = v.split(',').map((item) => (item && isNumber(item) && +item >= 0 ? moment(+item) : undefined));
                if (dates[0] && dates[1]) return [dates[0], dates[1]];
                else if (dates[0]) return [dates[0], undefined];
                else if (dates[1]) return [undefined, dates[1]];
                else return undefined;
            } else return undefined;
        },
    };

    static arrayOfStringsParam: PageURLParamDescription<string[] | undefined> = {
        defaultValue: undefined,
        toString: (v) => {
            return v && v.length > 0 ? v.toString() : undefined;
        },
        fromString: (v) => (v && v.split(',').length > 0 ? v.split(',') : undefined),
    };

    static arrayOfEnumParam = <T extends object>(_enum: T): PageURLParamDescription<T[keyof T][] | undefined> => ({
        defaultValue: undefined,
        toString: (value) => {
            return value && value.length > 0 ? value.toString() : undefined;
        },
        fromString: (value) => (value && value.split(',').length > 0 ? (value.split(',') as unknown as T[keyof T][]) : undefined),
    });

    static numberParam: PageURLParamDescription<number | undefined> = {
        defaultValue: undefined,
        toString: (value) => (value ? '' + value : undefined),
        fromString: (value) => (isNumber(value) ? +value : undefined),
    };

    static numbersSelectParam: PageURLParamDescription<number | number[] | undefined> = {
        defaultValue: undefined,
        toString: (value) => {
            if (Array.isArray(value) && value.length === 0) value = undefined;

            return value ? '' + value : undefined;
        },
        fromString: (value) => {
            if (value.indexOf(',') !== -1) {
                return value
                    .split(',')
                    .map((item) => (isNumber(item) ? +item : undefined))
                    .filter((item) => item !== undefined) as number[];
            } else if (isNumber(value)) {
                return +value;
            } else return undefined;
        },
    };

    static enumSelectParam = <T extends object>(_enum: T): PageURLParamDescription<T[keyof T] | undefined> => ({
        defaultValue: undefined,
        toString: (value) => (value ? '' + value : undefined),
        fromString: (value) => {
            if (value) {
                return (value.split(',') as T[keyof T][])[0];
            } else {
                return undefined;
            }
        },
    });

    static numberInterval: PageURLParamDescription<[number | undefined, number | undefined] | undefined> = {
        id: 'numberInterval',
        defaultValue: undefined,
        toString: (v) => {
            if (v && v.length > 0) {
                if (isDefined(v[0]) && isDefined(v[1])) return [v[0], v[1]].toString();
                else if (isDefined(v[0])) return [v[0]].toString();
                else if (isDefined(v[1])) return [undefined, v[1]].toString();
            } else return undefined;
        },
        fromString: (v) => {
            if (v) {
                let nums = v.split(',').map((item) => (isNumber(item) ? +item : undefined));
                if (isDefined(nums[0]) && isDefined(nums[1])) return [nums[0], nums[1]];
                else if (isDefined(nums[0])) return [nums[0], undefined];
                else if (isDefined(nums[1])) return [undefined, nums[1]];
                else return undefined;
            } else return undefined;
        },
    };

    static booleanParam: PageURLParamDescription<boolean | undefined> = {
        defaultValue: undefined,
        toString: (v) => {
            if (v === true) return 'true';
            else if (v === false) return 'false';
            else return undefined;
        },
        fromString: (v) => {
            if (v === '✔' || v === 'true' || v === '1' || v === '+') return true;
            else if (v === '' || v === 'false' || v === '0' || v === '-') return false;
            else return undefined;
        },
    };

    static switchParam: PageURLParamDescription<boolean | undefined> = {
        defaultValue: undefined,
        toString: (v) => {
            if (v === true) return 'true';
            else return undefined;
        },
        fromString: (v) => {
            if (v === '✔' || v === 'true' || v === '1' || v === '+') return true;
            else return undefined;
        },
    };

    static readonly basePageParams = {
        page: PageURLParamDescriptions.pageParam,
        limit: PageURLParamDescriptions.limitParam,
        sortBy: PageURLParamDescriptions.sortByParam,
        sortOrder: PageURLParamDescriptions.sortOrderParam,
    };
}

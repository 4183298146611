import React, { useEffect, useState } from 'react';
import Highlight from 'react-highlighter';
import { Icon, Tree } from 'antd';
import { IconCircle, IconGripVerticalSoft } from '../../../../components/icons';
import { CategoriesBlockProps, Category, CategoryData } from '../categories-block';
import './TreeBySiblingOrder.less';
import { TreeProps } from 'antd/lib/tree';
import { MAX_DEEP_CATEGORIES } from '../../../../config/constants';
import { getCategoryDepth } from '../utils';
import classNames from 'classnames';

declare module 'antd/lib/tree/Tree' {
    interface AntTreeNodeProps {
        dataRef?: Category & { level: number };
    }
}

export interface TreeBySiblingOrderProps extends Pick<CategoriesBlockProps, 'searchString'>, Pick<CategoryData, 'selectedCategoryId'> {
    availableCategories: Category[];
    draggedCategory: Category | null;
    expandedKeys: string[];
    onExpand: (expandedKeys: string[]) => void;
    onSelect?: (selectedKeys: string[]) => void;
    onDragStart: TreeProps['onDragStart'];
    onDragEnd: TreeProps['onDragEnd'];
    onDrop: TreeProps['onDrop'];
}

export const siblingOrderRootNodeValue = -1;
const addRootNodeToCategories = (categories: Category[]): Category[] => {
    return [
        {
            key: siblingOrderRootNodeValue,
            title: 'Корневой уровень',
            value: siblingOrderRootNodeValue,
            siblingOrder: 0,
            children: categories,
        },
    ];
};

export const TreeBySiblingOrder: React.FC<TreeBySiblingOrderProps> = ({
    availableCategories,
    expandedKeys,
    onExpand,
    onSelect,
    onDragStart,
    onDragEnd,
    onDrop,
    searchString,
    selectedCategoryId,
    draggedCategory,
}) => {
    const [availableCategoriesLocal, setAvailableCategoriesLocal] = useState<Category[]>(availableCategories);

    const renderTreeNodes = (categories: Category[], level: number = 0) => {
        return categories.map((item) => {
            const isRootNode: boolean = item.value === siblingOrderRootNodeValue;
            let disabledDragOver: boolean = false;
            let disabledDragGap: boolean = false;
            let disabledDragAll: boolean = false;
            if (draggedCategory) {
                const dragOverLimitExceeded: boolean = getCategoryDepth(draggedCategory) + level > MAX_DEEP_CATEGORIES;
                const dragGapLimitExceeded: boolean = getCategoryDepth(draggedCategory) + level > MAX_DEEP_CATEGORIES + 1;

                disabledDragOver = dragOverLimitExceeded;
                disabledDragGap = dragGapLimitExceeded;
                disabledDragAll = disabledDragOver && disabledDragGap;
            }

            if (item.children) {
                const dataRef = {
                    ...item,
                    level,
                };
                const icon = isRootNode ? (
                    <>
                        <Icon component={IconCircle} />
                    </>
                ) : selectedCategoryId === String(item.key) ? (
                    <>
                        <Icon component={IconGripVerticalSoft} />
                    </>
                ) : undefined;
                return (
                    <Tree.TreeNode
                        key={String(item.key)}
                        title={
                            <Highlight matchElement={'span'} matchStyle={{ color: 'red' }} search={searchString}>
                                {item.title}
                            </Highlight>
                        }
                        className={classNames({
                            'rr-tree-node-sibling': true,
                            'root-node': isRootNode,
                            'ant-tree-treenode-disabled': disabledDragOver,
                            'drag-over-disabled': disabledDragOver,
                        })}
                        dataRef={dataRef}
                        disabled={disabledDragAll}
                        icon={icon}
                        selectable={!isRootNode}
                    >
                        {renderTreeNodes(item.children, level + 1)}
                    </Tree.TreeNode>
                );
            }
            return <Tree.TreeNode {...item} key={String(item.key)} children={[]} />;
        });
    };

    useEffect(() => {
        setAvailableCategoriesLocal(availableCategories);
    }, [availableCategories]);

    const currentExpandedKeys = Array.from(new Set([String(siblingOrderRootNodeValue), ...expandedKeys]));

    return (
        <>
            <Tree
                defaultExpandAll={false}
                showLine
                showIcon
                draggable
                onSelect={onSelect}
                onExpand={onExpand}
                expandedKeys={currentExpandedKeys}
                onDrop={onDrop}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                selectedKeys={['' + selectedCategoryId || '']}
                defaultExpandedKeys={currentExpandedKeys}
            >
                {renderTreeNodes(addRootNodeToCategories(availableCategoriesLocal))}
            </Tree>
        </>
    );
};

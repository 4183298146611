import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { serverApi, ServerError, UserInfoRead } from '../../../../server';
import { getServerError } from '../../../../shared/util/utils';
import { showNotification } from '../../../../components/notification/showNotification';
import { IRootState } from '../../../../shared/reducers';
import { LocalizationEnum, localizeIntl } from '../../../../localization';

export const ACTION_TYPES = {
    LOAD_PROFILE: 'userProfile/LOAD_PROFILE',
    UPDATE_PROFILE: 'userProfile/UPDATE_PROFILE',
    RESET: 'userProfile/RESET',
    RESET_ERROR: 'userProfile/RESET_ERROR',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entity: null as UserInfoRead | null,
};

export type UserProfileState = Readonly<typeof initialState>;

// Reducer

export default (state: UserProfileState = initialState, action): UserProfileState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_PROFILE):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.LOAD_PROFILE):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.LOAD_PROFILE):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.UPDATE_PROFILE):
            return {
                ...state,
                updatingError: undefined,
                updating: true,
            };

        case FAILURE(ACTION_TYPES.UPDATE_PROFILE):
            return {
                ...state,
                updatingError: getServerError(action.payload),
                updating: false,
            };

        case SUCCESS(ACTION_TYPES.UPDATE_PROFILE):
            return {
                ...state,
                entity: action.payload.data,
                updating: false,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                loadingError: undefined,
                updatingError: undefined,
            };

        default:
            return state;
    }
};

// Actions

// Получение данных профиля
export const loadProfile = (intl, id?: number) => {
    return (dispatch, getState) => {
        let businessAccountId: number = (getState() as IRootState).system.businessAccountId;
        return dispatch({
            type: ACTION_TYPES.LOAD_PROFILE,
            payload: id ? serverApi.getUserById(businessAccountId, id) : serverApi.getCurrentProfile(),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__GET_FAILED));
            } else {
            }
        });
    };
};

export const updateProfile = (intl, data) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_PROFILE,
            payload: serverApi.updateProfileData(data),
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR,
});

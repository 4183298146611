import React from 'react';
import { Icon } from 'antd';
import { formatMoney } from '../../../../shared/util/formatMoney';
import sortBy from 'lodash/sortBy';
import { isDefined } from '../../../../shared/util/utils';
import './pricingSchemePricePopoverContent.less';
import { PricingSchemePopoverData } from './pricingSchemePricePopover';
import { PricingSchemeMaps, PricingSchemeUtils } from '../../../../core/utils/pricingSchemeUtils';
import { PricingSchemeTypeCodeEnum } from '../../../../server';

interface IProps {
    data: PricingSchemePopoverData;
    showMore: () => void;
}

export class PricingSchemePricePopoverContent extends React.Component<IProps> {
    render() {
        const { shiftCount, typeCode, price: basePrice } = this.props.data;
        let steps = sortBy(this.props.data.steps || [], 'effectsAfterShiftCount');
        let currentSteps =
            isDefined(shiftCount) && isDefined(typeCode)
                ? PricingSchemeMaps.pricingSchemeTypeCodeToGetStepsFunctionMap[typeCode]({ steps, shiftCount })
                : undefined;
        const { typeIcon } = PricingSchemeUtils.getPricingSchemeData(typeCode);

        return (
            <div className={'rr-PricingScheme-price__popover-content'}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {typeCode ? <Icon style={{ fontSize: 21, marginRight: 10, color: '#3d64d4' }} component={typeIcon} /> : undefined}
                    <span style={{ wordBreak: 'break-word' }}>{this.props.data.label}</span>
                </div>
                <div>
                    <div
                        key={-1}
                        style={PricingSchemeUtils.getBasePriceStyles({
                            shiftCount,
                            currentSteps,
                            typeCode,
                        })}
                    >
                        <div>Базовая</div>
                        <div>{formatMoney(basePrice)}</div>
                    </div>
                    {steps.map((step, index) => {
                        return (
                            <div key={index} style={currentSteps?.includes(step) ? { color: '#525893', fontWeight: 700 } : undefined}>
                                <div>
                                    {typeCode === PricingSchemeTypeCodeEnum.MULTILEVEL ? 'после' : 'от'} {step.effectsAfterShiftCount} см.
                                </div>
                                <div>{formatMoney(basePrice - basePrice * ((step.extraDiscount || 0) / 100))}</div>
                            </div>
                        );
                    })}
                </div>
                <div>
                    <span
                        onClick={() => {
                            this.props.showMore();
                        }}
                    >
                        Подробнее
                    </span>
                </div>
            </div>
        );
    }
}

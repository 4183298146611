import React from 'react';
import {connect} from "react-redux";
import {IRootState} from "../../../../shared/reducers";
import {ElementsListWrapper} from "../../elements/elementsListWrapper";

interface IProps extends StateProps, DispatchProps {
}

class Component extends React.PureComponent<IProps> {

    render() {
        return this.props.templateEntity &&
            <ElementsListWrapper
                gridName={'projectTemplate-elements'}
                visible={true}
                parentType={'projectTemplate'}
                parentId={this.props.templateEntity?.id}
                location={this.props.location}
                updateParentEntity={()=>{
                    //
                }}
            />;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    templateEntity: storeState.template.entity,
    location: storeState.router.location
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const TemplatePageElementsTabPane = connect(mapStateToProps, mapDispatchToProps)(Component);

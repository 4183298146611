import React from 'react';
import { Link } from 'react-router-dom';
import { LastPaymentsSchemePopover } from '../../../modules/main/settings/lastPaymentsSchemes/lastPaymentsSchemePopover';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';

export interface LinkToPaymentsProps {
    paymentsCount?: number;
    params?:
        | {
              projectId: number;
          }
        | {
              subrentId: number;
          }
        | {
              counterpartyId: number;
          };
}

function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[0];
    } // из моего 0
    if (n1 > 1 && n1 < 5) {
        return text_forms[2];
    } // из моего 2
    if (n1 === 1) {
        return text_forms[1];
    } // из моего 1
    return text_forms[0]; // из моего 0
}

export const LinkToPayments = ({ paymentsCount, params, ...props }: LinkToPaymentsProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    if (paymentsCount === undefined || paymentsCount === null || paymentsCount <= 0) return null;

    let paramsStr = '';
    if (params) {
        for (let k in params) {
            if (params[k] !== undefined && params[k] !== null) {
                if (!paramsStr) paramsStr = '?';
                else paramsStr += '&';
                paramsStr += `${k}=${params[k]}`;
            }
        }
    }
    const v = <>{`${paymentsCount} ${declOfNum(paymentsCount, ['платежей', 'платеж', 'платежа'])}`}</>;
    const link = `/${businessAccountId}/history/payments${paramsStr}`;
    const linkDisabled = !subrentModuleEnabled && params && 'subrentId' in params;

    return (
        <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
            за{' '}
            {paramsStr ? (
                <LastPaymentsSchemePopover
                    businessAccountId={businessAccountId}
                    link={linkDisabled ? undefined : link}
                    params={params}
                    trigger={'hover'}
                    placement={'right'}
                >
                    {linkDisabled ? <span>{v}</span> : <Link to={link}>{v}</Link>}
                </LastPaymentsSchemePopover>
            ) : (
                v
            )}
        </span>
    );
};

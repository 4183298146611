import React, { FC, useCallback } from 'react';
import { CustomSelect } from '../../../../select/CustomSelect';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { useIntl } from 'react-intl';
import { Icon, Select } from 'antd';
import { DownloadPeriodValues, useSelectOptions } from './hooks/useSelectOptions';
import { IconCalendar, IconClose } from '../../../../icons';
import moment from 'moment/moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { ExportSettings } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { DownloadDocumentPeriodRangeCalendar } from './components/DownloadDocumentPeriodRangeCalendar/DownloadDocumentPeriodRangeCalendar';

export interface DownloadDocumentPopoverCalculationProps {
    documentsLocalStorage: ExportSettings;
    setDocumentsLocalStorage: (value: ExportSettings) => void;
    periodValue: DownloadPeriodValues | RangePickerValue | [string, string] | undefined;
    setPeriodValue: (value: DownloadPeriodValues | RangePickerValue | undefined) => void;
}

const defaultSelectValue = [moment().startOf('day'), moment().endOf('day').subtract(1, 'second')];

export const DownloadDocumentPopoverPeriod: FC<DownloadDocumentPopoverCalculationProps> = (props) => {
    const { documentsLocalStorage, setDocumentsLocalStorage, periodValue, setPeriodValue } = props;
    const intl = useIntl();
    const options = useSelectOptions();

    const onChange = useCallback(
        (value: DownloadPeriodValues | RangePickerValue | undefined) => {
            setPeriodValue(value);
            setDocumentsLocalStorage({
                ...documentsLocalStorage,
                period: value,
            });
        },
        [documentsLocalStorage, setDocumentsLocalStorage, setPeriodValue]
    );

    const isDatesSelected = Array.isArray(periodValue) && periodValue[0] != null && periodValue[1] != null;
    const isOptionSelected = !isDatesSelected && (periodValue == null || typeof periodValue === 'string');
    const selectedValue = isDatesSelected
        ? periodValue.map((value: (DownloadDocumentPopoverCalculationProps['periodValue'] & object)[number]) => moment(value))
        : defaultSelectValue;

    return (
        <div className={'content'}>
            <span className={'title'}>
                Расчёт за период:
                {periodValue && <Icon component={IconClose} className={'icon'} onClick={() => onChange(undefined)} />}
            </span>
            <div className={'period'}>
                {isOptionSelected && (
                    <CustomSelect
                        style={{ width: '100%' }}
                        onChange={(value) => onChange(value as DownloadPeriodValues)}
                        value={periodValue}
                        placeholder={localizeIntl(intl, LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                        allowClear
                    >
                        {options.map(({ value, name }) => (
                            <Select.Option value={value}>{name}</Select.Option>
                        ))}
                    </CustomSelect>
                )}
                {isDatesSelected && (
                    <DownloadDocumentPeriodRangeCalendar selectedValue={selectedValue} onChange={onChange}>
                        <div className={'dates'}>
                            <span>{moment(periodValue[0]).format('DD.MM.YY')}</span>
                            <span
                                className="ant-calendar-range-picker-separator"
                                style={{
                                    height: 'auto',
                                    userSelect: 'none',
                                }}
                            >
                                ⟶
                            </span>
                            <span>{moment(periodValue[1]).format('DD.MM.YY')}</span>
                        </div>
                    </DownloadDocumentPeriodRangeCalendar>
                )}
                <DownloadDocumentPeriodRangeCalendar selectedValue={selectedValue} onChange={onChange}>
                    <Icon component={IconCalendar} className={'icon'} />
                </DownloadDocumentPeriodRangeCalendar>
            </div>
        </div>
    );
};

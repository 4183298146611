import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../localization';
import { BusinessAccountPermissionCodeEnum, DocumentTypeCodeEnum, GlobalPermissionCodeEnum } from '../../../../server';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { DocumentTemplatesEditModalUploadFile } from './documentTemplatesEditModalUploadFile';
import { getStoreState } from '../../../../../index';
import { DocumentTemplatesModuleUtils } from './documentTemplatesModuleUtils';
import { UploadFile } from 'antd/lib/upload/interface';
import { createHiddenField } from '../../../../components/modalForm/utils';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            {
                label: 'Стандартный',
                id: 'common',
                type: FormItemType.Switch,
                visible: (getFieldValue) => getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS),
                disabled: (getFieldValue) => getFieldValue('id') !== undefined,
            },
            {
                label: 'Наименование',
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
            },
            {
                label: LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE,
                id: 'documentType',
                required: true,
                type: FormItemType.Select,
                placeholder: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE__PLACEHOLDER),
                values: (value: any, form: WrappedFormUtils) => {
                    const list = DocumentTemplatesModuleUtils.getDocumentTemplateCodes();
                    return list.map((item) => {
                        return {
                            name: DocumentTemplatesModuleUtils.getDocumentTemplateTypeByCode(item),
                            value: item,
                            disabled:
                                !getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) &&
                                item !== DocumentTypeCodeEnum.ACT &&
                                item !== DocumentTypeCodeEnum.CONTRACT,
                        };
                    });
                },
                disabled: (getFieldValue) => getFieldValue('id') !== undefined,
                onChange: async (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        file: undefined,
                    });
                    if (value === DocumentTypeCodeEnum.BILL) {
                        form.setFieldsValue({
                            financial: true,
                        });
                    }
                },
            },
            {
                label: 'Финансовый',
                id: 'financial',
                type: FormItemType.Switch,
                disabled: (getFieldValue) => getFieldValue('documentType') === DocumentTypeCodeEnum.BILL,
            },
            {
                label: 'Файл шаблона',
                id: 'file',
                type: FormItemType.Component,
                required: true,
                infoMessage: 'Шаблон документа в формате DOCX или ZIP в зависимости от типа документа',
                component: DocumentTemplatesEditModalUploadFile,
                disabled: (getFieldValue) => getFieldValue('documentType') === undefined,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        documentType: form.getFieldValue('documentType'),
                    };
                },
                requiredMessage: 'Файл не выбран',
                validationRules: [
                    {
                        validator: (rule, value: UploadFile, callback) => {
                            if (value && value.error) {
                                callback(value.error);
                                return;
                            }
                            callback();
                        },
                    },
                ],
                visible: (getFieldValue) =>
                    getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) ||
                    (getStoreState().permissions.permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS) &&
                        (getFieldValue('id') === undefined ||
                            getFieldValue('documentType') === DocumentTypeCodeEnum.ACT ||
                            getFieldValue('documentType') === DocumentTypeCodeEnum.CONTRACT)),
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

import { LocalizationEnum, localize } from '../../../../../../../localization';
import React, { memo } from 'react';
import { ContactInfoRead } from '../../../../../../../server';
import { useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { editCategoryModalFilters } from '../create/ContactCreateModalData';
import { ContactEditModal } from './ContactEditModal';
import { EntityGridEditModalWrapper } from '../../../../../../../components/page/entityGrid/types/modal';

export const ContactEditModalWrapper: EntityGridEditModalWrapper<ContactInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <ContactEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование контакта'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            filters={editCategoryModalFilters}
            modalName={'edit-contact'}
            editMode
            contact={entity}
        />
    );
});

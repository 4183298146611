import React, {ReactNode} from 'react';
import {Upload} from 'antd';
import {DocumentTypeCodeEnum} from "../../server/api";
import {MAX_DOCUMENT_TEMPLATE_FILE_SIZE_LIMIT_IN_BYTES} from "../../config/constants";
import {formatBytes} from "../../shared/util/formatBytes";
import {UploadFile} from "antd/lib/upload/interface";
import {getFileExtension} from "../../shared/util/getFileExtension";

interface IProps {
    onChange: (file: UploadFile) => void;
    children?: ReactNode;
    disabled?: boolean;
    documentType?: DocumentTypeCodeEnum;
    showUploadList?: boolean;
    file?: UploadFile;
}

export class UploadDocumentTemplateFile extends React.Component<IProps> {

    render() {

        return (
            <Upload
                fileList={this.props.file ? [this.props.file] : undefined}
                disabled={this.props.disabled}
                beforeUpload={(file: UploadFile) => {
                    const maxSize = MAX_DOCUMENT_TEMPLATE_FILE_SIZE_LIMIT_IN_BYTES;

                    const sizeError = file.size > maxSize;
                    if (sizeError) {
                        file.status = 'error';
                        file.error = new Error(`Файл имеет недопустимый объем в байтах! Объем - ${formatBytes(file.size, 2)}, максимально допустимый - ${formatBytes(maxSize, 2)}`);
                    }else {
                        const fileExtension = getFileExtension(file.name.toLowerCase());
                        if (
                            ((this.props.documentType === DocumentTypeCodeEnum.ACT || this.props.documentType === DocumentTypeCodeEnum.CONTRACT || this.props.documentType === DocumentTypeCodeEnum.BILLOFLADING) && fileExtension !== 'docx') ||
                            ((this.props.documentType !== DocumentTypeCodeEnum.ACT && this.props.documentType !== DocumentTypeCodeEnum.CONTRACT && this.props.documentType !== DocumentTypeCodeEnum.BILLOFLADING) && fileExtension !== 'zip')
                        ) {
                            file.status = 'error';
                            file.error = new Error(`Файл имеет не верное расширение`);
                        }
                    }

                    if (this.props.onChange) this.props.onChange(file);
                    return false;
                }}
                showUploadList={this.props.showUploadList}
                multiple={false}
                accept={this.props.documentType === DocumentTypeCodeEnum.ACT || this.props.documentType === DocumentTypeCodeEnum.CONTRACT || this.props.documentType === DocumentTypeCodeEnum.BILLOFLADING ? '.docx' : '.zip'}
            >
                {this.props.children}
            </Upload>
        );
    }
}

import React, { FC, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { showConfirm } from '../../confirm/showConfirm';
import { useIntl } from 'react-intl';
import { Location } from 'history';
import { Redirect } from 'react-router';

interface PromptRedirectProps {
    onRedirect?: ()=>void;
}

export const PromptRedirect: FC<PromptRedirectProps> = ({onRedirect}) => {
    const intl = useIntl();
    const [redirectLocation, setRedirectLocation] = useState<Location<unknown> | undefined>(undefined);

    if (redirectLocation) {
        const redirectUrl = redirectLocation.pathname + redirectLocation.search;
        return <Redirect to={redirectUrl} push={true} />;
    }

    return (
        <Prompt
            message={(nextLocation) => {
                showConfirm(intl, 'Прервать редактирование?').then((answer) => {
                    if (answer) {
                        setRedirectLocation(nextLocation);
                        onRedirect?.();
                    }
                });

                return false;
            }}
        />
    );
};

import React, { FC } from 'react';
import { Button, Icon, Popover } from 'antd';
import {
    IconCalendarPlus,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconEraser,
    IconFlagCheckered,
    IconRouteSolid,
    IconShippingFastSolid,
    IconTrash,
} from '../../../../../../../components/icons';
import { Status, StatusStateCode } from '../../../../../../../components/status/status';
import { RoundButton } from '../../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { TransportationInfoRead, TransportationTransitionCodeEnum } from '../../../../../../../server';
import { AdditionalEntityActionsButton } from '../../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { TransportationUtils } from '../../utils';
import { TransportationDrawerProps } from './TransportationDrawer';

interface TransportationDrawerTitleProps
    extends Pick<TransportationDrawerProps, 'editVehicle' | 'deleteVehicle' | 'onClose' | 'copyVehicle'> {
    transportation?: TransportationInfoRead;
    actionPopoverVisible: boolean;
    setActionPopoverVisible: (visible: boolean) => void;
    setStatusForTransportationsCallback: (transitionCode: TransportationTransitionCodeEnum) => void;
    isLoading: boolean;
}

export const TransportationDrawerHeader: FC<TransportationDrawerTitleProps> = ({
    transportation,
    actionPopoverVisible,
    setActionPopoverVisible,
    editVehicle,
    deleteVehicle,
    copyVehicle,
    setStatusForTransportationsCallback,
    onClose,
    isLoading,
}) => {
    const actualStateCodeText = TransportationUtils.getActualStateCode(transportation?.stateCode);

    return transportation ? (
        <div className={'entity-drawer-header'}>
            <div className={'title-block'}>
                <Icon className={'title-icon'} component={IconRouteSolid} />
                <span className={'title'}>
                    Перевозка
                    <span
                        style={{
                            fontWeight: 400,
                            marginLeft: 8,
                        }}
                    >
                        #{transportation.number}
                    </span>
                </span>
                {actualStateCodeText && transportation.stateCode ? (
                    <Status
                        entityType={'transportation'}
                        code={transportation.stateCode as StatusStateCode}
                        customText={actualStateCodeText}
                    />
                ) : undefined}
            </div>
            <div>
                {editVehicle ? (
                    <RoundButton
                        colorScheme={'default'}
                        onClick={() => {
                            editVehicle(transportation);
                        }}
                        disabled={isLoading}
                    >
                        <Icon component={IconEdit} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                    </RoundButton>
                ) : undefined}
                <span style={{ marginLeft: '12px' }}>
                    <Popover
                        onVisibleChange={(visible) => {
                            setActionPopoverVisible(visible);
                        }}
                        visible={actionPopoverVisible}
                        overlayClassName={'rr-grid-actions-popover'}
                        autoAdjustOverflow
                        arrowPointAtCenter
                        placement="bottomLeft"
                        trigger="click"
                        content={
                            <>
                                <div className={'rr-grid-actions-popover-header1'} />
                                <div
                                    className={'rr-grid-actions-popover-content'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionPopoverVisible(false);
                                    }}
                                >
                                    <>
                                        {transportation ? (
                                            <>
                                                {/*<ExportBillOfLadingPopover*/}
                                                {/*    onDownload={async (fileType, templateId: number, isCommonTemplate: boolean) => {*/}
                                                {/*        DownloadDocumentUtils.billOfLadingGenerateAndDownload({*/}
                                                {/*            businessAccountId: transportation?.businessAccountId,*/}
                                                {/*            documentTemplateId: templateId,*/}
                                                {/*            common: isCommonTemplate,*/}
                                                {/*            id: transportation?.id*/}
                                                {/*        }, 'Транспортная накладная', fileType).catch((e) => {*/}
                                                {/*            showNotification('error', 'Ошибка генерации файла');*/}
                                                {/*        });*/}
                                                {/*    }}>*/}
                                                {/*    <Button*/}
                                                {/*        block*/}
                                                {/*        onClick={(e) => {*/}
                                                {/*            e.stopPropagation();*/}
                                                {/*            setActionPopoverVisible(true);*/}
                                                {/*            //setStatusForTransportationsCallback(TransportationTransitionCodeEnum.PLAN);*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        <Icon style={{color: '#21bfa4'}} component={IconClipboardListSolid}/>*/}
                                                {/*        Транспортная накладная*/}
                                                {/*    </Button>*/}
                                                {/*</ExportBillOfLadingPopover>*/}
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActionPopoverVisible(false);
                                                        copyVehicle?.(transportation, {
                                                            withView: false,
                                                        });
                                                    }}
                                                >
                                                    <Icon component={IconControlPointDuplicateBlack} style={{ color: '#34bfa3' }} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                                                </Button>
                                                {transportation.availableTransitionCodes?.includes(
                                                    TransportationTransitionCodeEnum.PLAN
                                                ) ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActionPopoverVisible(false);
                                                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.PLAN);
                                                        }}
                                                    >
                                                        <Icon style={{ color: '#04b0f1' }} component={IconCalendarPlus} />
                                                        Запланировать
                                                    </Button>
                                                ) : undefined}
                                                {transportation.availableTransitionCodes?.includes(
                                                    TransportationTransitionCodeEnum.START
                                                ) ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActionPopoverVisible(false);
                                                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.START);
                                                        }}
                                                    >
                                                        <Icon style={{ color: '#FC8C2F' }} component={IconShippingFastSolid} />
                                                        {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__START, 'span')}
                                                    </Button>
                                                ) : undefined}
                                                {transportation.availableTransitionCodes?.includes(
                                                    TransportationTransitionCodeEnum.FINISH
                                                ) ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActionPopoverVisible(false);
                                                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.FINISH);
                                                        }}
                                                    >
                                                        <Icon style={{ color: '#27a48b' }} component={IconFlagCheckered} />
                                                        {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH, 'span')}
                                                    </Button>
                                                ) : undefined}
                                                {transportation.availableTransitionCodes?.includes(
                                                    TransportationTransitionCodeEnum.CANCEL
                                                ) ? (
                                                    <Button
                                                        block
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActionPopoverVisible(false);
                                                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.CANCEL);
                                                        }}
                                                    >
                                                        <Icon style={{ color: '#a0a0a0' }} component={IconEraser} />
                                                        {localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL, 'span')}
                                                    </Button>
                                                ) : undefined}
                                            </>
                                        ) : undefined}
                                        {deleteVehicle ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    deleteVehicle(transportation).then(() => onClose());
                                                }}
                                            >
                                                <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                <span>Удалить</span>
                                            </Button>
                                        ) : undefined}
                                    </>
                                </div>
                            </>
                        }
                    >
                        <AdditionalEntityActionsButton disabled={isLoading} />
                    </Popover>
                </span>
            </div>
        </div>
    ) : null;
};

import React from 'react';
import {Collapse, Icon} from "antd";
import {
    InstanceInfoRead,
    KitInfoRead,
    OperationInfoRead,
    ProductInfoRead,
    ProjectInfoRead,
    RenterInfoRead,
    SubrentInfoRead,
    VariantInfoRead
} from "../../server/api";
import {CardProps} from "antd/lib/card";
import {IconAngleDown, IconAngleUp, IconBriefcase, IconDollySolid} from "../icons";
import {CurrentRentIndicatorsTab} from "./currentRentIndicatorsTab";
import {CurrentSubrentIndicatorsTab} from "./currentSubrentIndicatorsTab";
import {IRootState} from "../../shared/reducers";
import {canViewFinancialData} from "../../shared/util/permissionUtils";
import {connect} from "react-redux";
import './currentRentIndicatorsBlock.less';

export type CurrentRentIndicatorsEntityType =
    'product'
    | 'project'
    | 'renter'
    | 'operation'
    | 'product-instance'
    | 'kit'
    | 'variant'
    | 'shipping';
export type CurrentRentIndicatorsEntity =
    ProductInfoRead
    | ProjectInfoRead
    | RenterInfoRead
    | OperationInfoRead
    | InstanceInfoRead
    | KitInfoRead
    | VariantInfoRead
    | SubrentInfoRead;

interface IProps extends CardProps, StateProps, DispatchProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: CurrentRentIndicatorsEntity;
    onCollapsed? (arr:string[]):void;
    collapsed?: string[] | undefined;
    typeRent?: 'rent' | 'subrent' | 'all';
    subrentModuleEnabled: boolean|undefined; /* Можно было здесь брать из стора, но в одном месте нужно показывать вкладку субаренды даже при отключенной субаренде, по этому вынесено в пропс и в то*/
}

class _CurrentRentIndicatorsBlock extends React.PureComponent<IProps> {

    callback = (key) => {
        if(this.props.onCollapsed) {
            this.props.onCollapsed(key);
        }
    };

    render() {
        const showFinancialData = this.props.canViewFinancialData;
        const {rentIndicators} = this.props.entity;
        const {subrentModuleEnabled} = this.props;
        const {Panel} = Collapse;

        let typeProblemColor: 'tab-header-problem' | 'tab-header-hard-problem' | 'tab-header-not-problem';

        if (rentIndicators?.problemsAndWarnings.returnDelay.elementsCount || rentIndicators?.problemsAndWarnings.bookShortage.elementsCount) {
            typeProblemColor = 'tab-header-hard-problem';
        } else if (rentIndicators?.problemsAndWarnings.giveawayDelay.elementsCount || rentIndicators?.problemsAndWarnings.orderShortage.elementsCount) {
            typeProblemColor = 'tab-header-problem';
        } else {
            typeProblemColor = 'tab-header-not-problem';
        }

        let typeSubrentProblemColor: 'tab-header-subrent-problem' | 'tab-header-subrent-hard-problem' | 'tab-header-subrent-not-problem' | undefined;

        if (this.props.entityType !== 'project') {
            if (rentIndicators?.problemsAndWarnings.subrentShipmentDelay.elementsCount) {
                typeSubrentProblemColor = 'tab-header-subrent-hard-problem';
            } else if (rentIndicators?.problemsAndWarnings.subrentReturnToShipperDelay.elementsCount) {
                typeSubrentProblemColor = 'tab-header-subrent-problem';
            } else {
                typeSubrentProblemColor = 'tab-header-subrent-not-problem';
            }
        }

        return rentIndicators || this.props.collapsed?.length === 0 ?
            <div className="rr-rent-indicators">
                <Collapse
                    activeKey={this.props.collapsed}
                    onChange={this.callback}
                    expandIcon={(icon) => {
                        return <><Icon component={icon.isActive === true ? IconAngleUp : IconAngleDown}
                                       className="rr-collapsed-header-icon"/> </>;
                    }}
                    className={`${typeProblemColor} ${typeSubrentProblemColor} rr-collapsed-header`}
                >
                    {
                        this.props.typeRent !== 'subrent' &&
                        <Panel className={'rr-rent-indicators-rent-tab'} header={
                            <div className={'rr-rent-indicators-tab-title'}>
                                <Icon component={IconBriefcase}
                                      className={`tab-header-icon tab-header-icon-color-rent`}/>
                                <span className="rr-collapsed-header-span">Аренда</span>
                                <div className={'rr-rent-indicators-tab-title-hint'}>{showFinancialData ? 'Число и сумма обязательств' : 'Число обязательств'}</div>
                            </div>
                        } key="1">
                            <div className="rr-current-rent-indicators-panel" style={this.props.entityType === 'kit' ? {marginLeft: '8px'} : {}}>
                                <CurrentRentIndicatorsTab entity={this.props.entity} entityType={this.props.entityType}/>
                            </div>
                        </Panel>
                    }
                    {
                        subrentModuleEnabled && this.props.typeRent !== 'rent' &&
                        <Panel className={'rr-rent-indicators-subrent-tab'} header={
                            <div className={'rr-rent-indicators-tab-title'}>
                                <Icon component={IconDollySolid}
                                      className="tab-header-icon tab-header-icon-color-subrent"/>
                                <span className="rr-collapsed-header-span">Субаренда</span>
                                <div className={'rr-rent-indicators-tab-title-hint'}>{showFinancialData ? 'Число и сумма обязательств' : 'Число обязательств'}</div>
                            </div>
                        } key="2">
                            <div className="rr-current-rent-indicators-panel"  style={this.props.entityType === 'kit' ? {marginLeft: '8px'} : {}}>
                                <CurrentSubrentIndicatorsTab
                                    entity={this.props.entity}
                                    entityType={this.props.entityType} />
                            </div>
                        </Panel>
                    }
                </Collapse>
            </div>
            : null;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions)
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const CurrentRentIndicatorsBlock = connect(
    mapStateToProps,
    mapDispatchToProps
)(_CurrentRentIndicatorsBlock);

import React, { FC } from 'react';
import { Icon } from 'antd';
import { IconFilter } from '../../../icons';

interface FiltersVisibleToggleProps {
    onClick: () => void;
    bold: boolean;
    collapsed: boolean;
}

export const FiltersVisibleToggle: FC<FiltersVisibleToggleProps> = (props) => {
    const { onClick, bold, collapsed } = props;

    return (
        <span className={'rr-grid-smart_filters-toggle-btn'} onClick={onClick}>
            <Icon component={IconFilter} />
            <span style={{ fontWeight: bold ? 600 : undefined }}>Все фильтры</span>
            <Icon type={'down'} rotate={collapsed ? 0 : 180} />
        </span>
    );
};

import {AxiosError} from "axios";
import {SerializedError} from "@reduxjs/toolkit";
import {ServerError} from "../../server";

export const useServerError = (err: AxiosError | SerializedError | undefined): ServerError | undefined => {
    if (!err) return err;

    let message:string|undefined;
    if(err && 'isAxiosError' in err && err.isAxiosError === true){
        if(err.response?.data?.message) message = err.response.data.message;
        else {
            message = err.message;
            if (message === 'Network Error') {
                if(window.navigator.onLine){
                    message = 'Cервер недоступен'; // TODO локализовать
                }else{
                    message = 'Нет подключения к интернету'; // TODO локализовать
                }
            }
        }
        return {message};
    }else if(err && err.message){
        return {
            message: err.message
        }
    }
};

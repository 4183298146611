import React, { useContext } from 'react';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { ActivityFramesCalendarItem as IActivityFramesCalendarItem, CalendarItem } from '../../../../components/Calendar/types/items';
import { CalendarLineContainer } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarLineContainer/CalendarLineContainer';
import { CALENDAR_LINE_BASE_RADIUS } from '../../../../components/Calendar/utils/data/calendarConstants';
import { ActivityFrameItemContext } from '../../../../components/CalendarItemRenderer/context/CalendarItemContext';
import { CalendarItemRendererProps } from '../../../../components/CalendarItemRenderer/CalendarItemRenderer';
import { ActivityFramesCalendarItem } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarItem/variants/ActivityFramesCalendarItem';
import { useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import classNames from 'classnames';
import { TimeRangeContext } from '../../../../components/CalendarItemRenderer/context/TimeRangeContext';

export const ActivityFramesItemRenderer = <SortValue extends string>(
    props: CalendarItemRendererProps<SortValue, IActivityFramesCalendarItem>
) => {
    const { baseProps, calendarProps } = props;
    const { displayType, sortByValue, onItemClick } = baseProps;
    const { item } = calendarProps;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);
    const { id, elementType, start_time, end_time } = item;

    const activityPath =
        elementType === 'activityProject' ? 'projects/production' : elementType === 'activitySubrent' ? 'subrent/shippings' : undefined;
    const linkPath = `/${businessAccountId}/${activityPath}/${id}`;

    return (
        <CalendarLineContainer
            calendarProps={calendarProps}
            displayType={displayType}
            borderRadius={CALENDAR_LINE_BASE_RADIUS}
            onItemClick={onItemClick}
            linkPath={linkPath}
            className={classNames('item', {
                'left-boundary': +start_time <= visibleTimeStart,
                'right-boundary': +end_time >= visibleTimeEnd,
            })}
        >
            <ActivityFrameItemContext.Provider
                value={calendarProps as ReactCalendarItemRendererProps<CalendarItem<IActivityFramesCalendarItem>>}
            >
                <ActivityFramesCalendarItem sortByValue={sortByValue} />
            </ActivityFrameItemContext.Provider>
        </CalendarLineContainer>
    );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { RentElementRecordList, RentStateCodeEnum, serverApi } from '../../../../server';
import { ListElementsArgs } from './types';
import { getElementsListFilters } from '../reducers/elements.reducer';
import { AxiosError } from 'axios';

export const elementsApi = createApi({
    reducerPath: 'projectsElementsApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        listElementsForEstimate: builder.query<RentElementRecordList, ListElementsArgs>({
            queryFn: async ({ businessAccountId, entityType, params }) => {
                let filters: string[] = getElementsListFilters(params).concat([
                    `hideStateCode;IN;${RentStateCodeEnum.SUBRENTCANCELED};${RentStateCodeEnum.CANCELED}`,
                ]);
                const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;

                const loadOperationMap = {
                    project: serverApi.listProjectElements,
                    shipping: serverApi.listSubrentElements,
                    projectTemplate: serverApi.listTemplateElements,
                    operation: serverApi.listOperationElements,
                } satisfies Record<typeof entityType, Function>;
                const currentLoadOperation = loadOperationMap[entityType];

                try {
                    const response = await currentLoadOperation.call(
                        serverApi,
                        businessAccountId,
                        params.projectId || 0,
                        params.limit,
                        (params.page - 1) * params.limit,
                        undefined,
                        sortBy,
                        params.sortOrder,
                        params.search,
                        undefined,
                        true,
                        undefined,
                        { query: { filters } }
                    );

                    return {
                        data: response.data,
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useLazyListElementsForEstimateQuery } = elementsApi;

import { useAppSelector } from '../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { useMemo } from 'react';
import { DeepKeys } from '@tanstack/react-table';
import { ProjectRecord } from '../../../../server';
import { ListOffersParams } from '../api/types';

export const useExcludedValues = (projectId?: number) => {
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    return useMemo(() => {
        const excludeColumns: DeepKeys<ProjectRecord>[] = [];
        const filtersExcludeFields: DeepKeys<ListOffersParams>[] = [];

        if (!canViewFinancialData) {
            excludeColumns.push('partOfThePrice', 'defaultDiscount', 'costOfRent', 'costOfWork', 'costOfExpenses', 'sumToBePaidAfterTaxes');
            filtersExcludeFields.push('partOfThePrice');
        }

        if (projectId) {
            excludeColumns.push(
                'renterShortName',
                'mainProjectId',
                'locationName',
                'startDate',
                'targetFinishDate',
                'defaultDiscount',
                'contactPersonShortName',
                'mainProjectShortName'
            );
            filtersExcludeFields.push('projectId', 'renterId');
        }

        return {
            excludeColumns,
            filtersExcludeFields,
        };
    }, [canViewFinancialData, projectId]);
};

import React, {ReactNode} from "react";

interface LabelValueProps {
    label: ReactNode;
    children: ReactNode;
}

export const LabelValue = (props: LabelValueProps) => {
    return (
        <div>
            <div className={'rr-label'}>{props.label}</div>
            <div>{props.children}</div>
        </div>
    );
};
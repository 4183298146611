import React, {FC} from 'react';
import './roundButtonGroup.less';

interface Props {

}

export const RoundButtonGroup:FC<Props> = (props) => {
    return (
        <div className={'rr-round-btn-group'}>
            {props.children}
        </div>
    );
};

import {showConfirmNew} from '../../../../components/confirm/showConfirm';
import React from 'react';
import {IntlProvider, IntlShape} from 'react-intl';
import {ConcurrentOperationsList} from './concurrentOperationsList';
import {ConcurrentOperationObj} from '../../../../server';
import {Provider} from 'react-redux';
import {getStore} from '../../../../../index';
import './showConcurrentOperationsConfirm.less';

export const showConcurrentOperationsConfirm = async (
    intl: IntlShape,
    operations: ConcurrentOperationObj[],
    allowedToPerform: boolean
): Promise<boolean> => {
    let ok = await showConfirmNew({
        intl: intl,
        title: allowedToPerform
            ? 'Присутствуют параллельные операции, хотите продолжить?'
            : 'Для продолжения требуются завершить параллельные операции',
        content: (getModalObj: () => { destroy: () => void }) => (
            <IntlProvider {...intl}>
                <Provider store={getStore()}>
                    <ConcurrentOperationsList data={operations} getParentModalObj={getModalObj}/>
                    <div
                        style={{
                            borderTop: '1px solid #ebedf2',
                            fontSize: 12,
                            color: '#bcbcbc',
                            padding: '28px 32px',
                        }}>
                        {allowedToPerform
                            ? 'Если параллельно применить две операции к одному обязательству, будет конфликт, защита от перезаписи изменений не даст применить более позднюю операцию'
                            : 'Работа с параллельными операциями запрещена в настройках аккаунта компании'}
                    </div>
                </Provider>
            </IntlProvider>
        ),
        cancelButtonProps: {
            className: 'ant-btn-error rr-btn-concurrent-operations-confirm',
            style: allowedToPerform ? {} : {display: 'none'},
        },
        className: 'rr-modal-concurrent-operations-confirm',
        okText: 'Отмена',
        cancelText: 'Продолжить',
    });
    return ok;
};

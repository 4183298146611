import React from 'react';
import {Button, Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import {IconClose} from "../../icons";
import {RRoundButton} from "../../button/rRoundButton";

interface OperationResetButtonProps {
    onClick?:ButtonProps['onClick'];
    style?:ButtonProps['style'];
    title?: string;
}

export const OperationResetHeaderButton = (props: OperationResetButtonProps) => {
    return (
        <RRoundButton
            style={{marginLeft: 40, marginRight: -20, ...props.style}}
            disabled={false}
            colorScheme={'danger2'}
            onClick={props.onClick}
            icon={IconClose}
            title={props.title || 'Сбросить изменения'}
        />
    )
};
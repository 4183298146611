import React from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { Redirect, Route } from 'react-router';
import RoundButton from '../../../components/button/roundButton';
import { SubrentShippingCreateModal } from './shippings/subrentShippingCreateModal';
import { SubrentShippingsListTabPane } from './shippings/SubrentShippingsListTabPane';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { LocalizationEnum, localize } from '../../../localization';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { SubrentInfoRead } from '../../../server';
import { push, replace } from 'connected-react-router';
import { getGridStorageData } from '../../../components/grid/utils';
import { getPathFromState, isStringInEnum } from '../../../shared/util/utils';
import { createSubrentShippingSchemeModalFilters } from './shippings/subrentShippingCreateModalData';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';

import { SubrentPageTabsEnum } from '../../../shared/constants/tabEnums';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

export const subrentPageUrlRoute = 'subrent';
const defaultTab = SubrentPageTabsEnum.SHIPPINGS;

class Component extends BasePage<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${subrentPageUrlRoute}`);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('shippings', this.currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
    }

    getPageHeaderProps(): IPageHeaderProps {
        return {
            title: this.props.intl.formatMessage({ id: LocalizationEnum.ASPECT__MAIN_MENU__SUBRENT__RENT }),
        };
    }

    onTabChange = (key: string, replace: boolean = false) => {
        let gridData = getGridStorageData(key);
        const urlMethod = replace ? this.props.replace : this.props.push;
        const newTabKey = isStringInEnum(key, SubrentPageTabsEnum, defaultTab);

        if (newTabKey === SubrentPageTabsEnum.SHIPPINGS) {
            urlMethod(
                getPathFromState(this.getCurrentUrl(SubrentPageTabsEnum.SHIPPINGS), '', {
                    ...gridData.filters,
                    ...gridData.params,
                })
            );
        } else if (newTabKey === SubrentPageTabsEnum.TEMPLATES) {
            urlMethod(
                getPathFromState(this.getCurrentUrl(SubrentPageTabsEnum.TEMPLATES), '', {
                    ...gridData.filters,
                    ...gridData.params,
                })
            );
        }

        saveCurrentTabToStore('shippings', newTabKey);
    };

    createShipping = () => {
        this.props.push(
            getPathFromState(
                `/${this.props.businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/new`,
                this.props.location.search
            )
        );
    };

    createTemplate = () => {
        this.props.push(
            getPathFromState(
                `/${this.props.businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.TEMPLATES}/new`,
                this.props.location.search
            )
        );
    };

    getCurrentUrl(tab: SubrentPageTabsEnum) {
        return `/${this.props.businessAccountId}/${subrentPageUrlRoute}/${tab}`;
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(subrentPageUrlRoute, SubrentPageTabsEnum);
    }

    renderContent() {
        const { currentTab } = this;

        if (currentTab == null) {
            return <Redirect to={this.getCurrentUrl(isStringInEnum(this.props.tabFromStore, SubrentPageTabsEnum, defaultTab))} />;
        }

        return (
            <>
                <Tabs
                    className={'rr-tabs'}
                    onChange={this.onTabChange}
                    type="line"
                    animated={false}
                    activeKey={currentTab}
                    tabBarExtraContent={
                        currentTab === SubrentPageTabsEnum.SHIPPINGS ? (
                            <RoundButton icon={'plus'} title={'Создать поставку'} colorScheme={'success'} onClick={this.createShipping}>
                                Создать поставку
                            </RoundButton>
                        ) : (
                            <RoundButton icon={'plus'} title={'Создать шаблон'} colorScheme={'success'} onClick={this.createTemplate}>
                                Создать шаблон
                            </RoundButton>
                        )
                    }
                >
                    <Tabs.TabPane tab={'Поставки'} key={SubrentPageTabsEnum.SHIPPINGS}>
                        <SubrentShippingsListTabPane gridName={SubrentPageTabsEnum.SHIPPINGS} location={this.props.location} />
                    </Tabs.TabPane>
                </Tabs>

                <Route
                    exact
                    path={`/${this.props.businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/new`}
                    render={(props) => (
                        <SubrentShippingCreateModal
                            title={'Создание новой поставки'}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            businessAccountId={this.props.businessAccountId}
                            successCallback={(data: SubrentInfoRead) => {
                                this.props.push(
                                    `/${this.props.businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/${data.id}?tab=description`
                                );
                            }}
                            filters={createSubrentShippingSchemeModalFilters}
                            modalName={'create-subrent_shipping'}
                        />
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: IBasePageProps) => {
    let { businessAccountId } = storeState.system;

    return {
        businessAccountId: businessAccountId,
        location: ownProps.location,
        tabFromStore: getCurrentTabsSelector(storeState)?.['subrent'],
    };
};

const mapDispatchToProps = { resetOperationForm, push, replace, setBlockedUrl };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SubrentPage = connect(mapStateToProps, mapDispatchToProps)(Component);

import {ActionButtonsData} from "../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup";
import {BasicActionsData} from "../../../../components/v2/actionButtons/actionButton/actionButton";
import {Icon} from "antd";
import {IconEyeSlashSolid, IconEyeSolid} from "../../../../components/icons";
import {Color} from "../../../../core/types/color";
import React from "react";

export enum CustomFieldActionType {
    edit = 'edit',
    delete = 'delete',
    hide = 'hide',
    show = 'show'
}

export const customFieldActionsMap: ActionButtonsData<CustomFieldActionType> = {
    [CustomFieldActionType.edit]: BasicActionsData.editAction,
    [CustomFieldActionType.delete]: BasicActionsData.deleteAction,
    [CustomFieldActionType.hide]: {
        name: 'Сделать скрытым',
        icon: <Icon component={IconEyeSlashSolid}/>,
        color: Color.Grey2,
    },
    [CustomFieldActionType.show]: {
        name: 'Убрать скрытие',
        icon: <Icon component={IconEyeSolid}/>,
        color: Color.Orange1,
    }
};

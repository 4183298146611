type EntityType = 'contact';

type SelectData = {
    [Key in EntityType]?: {
        isFetching: boolean;
        requests: {
            ids: (number | string)[];
            resolve: (value: any[]) => void;
            reject: () => void
        }[];
    }
};

const data:SelectData = {};

export const useBaseSelectFetchRecordsBulk = <R,>(entityType:EntityType, f:(ids:(number|string)[])=>Promise<R[]>, getRecordId: (record:R) =>number) =>{

    const fetchRecordsBulk = (id:number[])=>{
        return new Promise<R[]>((resolve, reject) =>{
            const ids = !Array.isArray(id) ? [id] : id;

            if(!data[entityType]) data[entityType] = {
                isFetching: false,
                requests: []
            };
            const dataObj = data[entityType];
            if(dataObj){
                dataObj.requests.push({
                    ids, resolve, reject
                });

                if(!dataObj.isFetching){
                    dataObj.isFetching = true;
                    setTimeout(async ()=>{
                        const idsToFetch:(number|string)[] = [];
                        dataObj.requests.forEach((req)=>{
                            req.ids.forEach(i=>{
                                if(!idsToFetch.includes(i)) idsToFetch.push(i);
                            })
                        });
                        let response:R[]|undefined;
                        try{
                            response = await f(idsToFetch);
                        }catch(err){
                            dataObj.requests.forEach((req)=>{
                                req.reject();
                            });
                        }

                        if(response){
                            dataObj.requests.forEach((req)=>{
                                const data = response ?? [];
                                const newData = data.filter((record)=>req.ids.includes(getRecordId(record)));
                                req.resolve(newData);
                            });
                        }
                        dataObj.requests = [];
                        dataObj.isFetching = false;
                    }, 50);
                }
            }

        });
    }
    return fetchRecordsBulk;
};

import React, { FC } from 'react';
import CalendarNavigation from '../CalendarNavigation/CalendarNavigation';
import CalendarInterval from '../CalendarInterval/CalendarInterval';
import { calendarHeaderBlockFiltersDescription } from './filters/calendarHeaderBlockFiltersDescription';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import { useShareFiltersChangesFromTabs } from '../../../../../core/hooks/urlParams/useShareFiltersChangesFromTabs';
import { useAppSelector } from '../../../../../store/hooks';
import {
    workPlanningsCalendarScreenLeft,
    workPlanningsCalendarScreenRight,
} from '../../tabs/workPlannings/reducers/workPlanningsCalendar.selectors';
import { elementsCalendarScreenLeft, elementsCalendarScreenRight } from '../../tabs/elements/reducers/elementsCalendar.reducer.selectors';
import {
    activityFramesCalendarScreenLeft,
    activityFramesCalendarScreenRight,
} from '../../tabs/activityFrames/reducers/activityFramesCalendar.selectors';

import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';

interface CalendarHeaderBlockProps {
    pageName: CalendarPageTabsEnum;
}

export const CalendarHeaderBlock: FC<CalendarHeaderBlockProps> = (props) => {
    const { pageName } = props;

    const elementsScreenLeft = useAppSelector(elementsCalendarScreenLeft);
    const elementsScreenRight = useAppSelector(elementsCalendarScreenRight);
    const activityFramesScreenLeft = useAppSelector(activityFramesCalendarScreenLeft);
    const activityFramesScreenRight = useAppSelector(activityFramesCalendarScreenRight);
    const workPlanningsScreenLeft = useAppSelector(workPlanningsCalendarScreenLeft);
    const workPlanningsScreenRight = useAppSelector(workPlanningsCalendarScreenRight);

    const { pageParams } = usePageURLParams({
        paramsDescription: calendarHeaderBlockFiltersDescription,
        pageName,
    });
    const { capture, screenLeft, screenRight } = pageParams;

    const currentSavedScreenLeft = {
        [CalendarPageTabsEnum.ELEMENTS]: elementsScreenLeft,
        [CalendarPageTabsEnum.ACTIVITY_FRAMES]: activityFramesScreenLeft,
        [CalendarPageTabsEnum.WORK_PLANNINGS]: workPlanningsScreenLeft,
        [CalendarPageTabsEnum.ACCESSIBILITY_MAP]: elementsScreenLeft,
        [CalendarPageTabsEnum.SUMMARY_SCHEDULE]: elementsScreenLeft,
    }[pageName ?? ''];

    const currentSavedScreenRight = {
        [CalendarPageTabsEnum.ELEMENTS]: elementsScreenRight,
        [CalendarPageTabsEnum.ACTIVITY_FRAMES]: activityFramesScreenRight,
        [CalendarPageTabsEnum.WORK_PLANNINGS]: workPlanningsScreenRight,
        [CalendarPageTabsEnum.ACCESSIBILITY_MAP]: elementsScreenRight,
        [CalendarPageTabsEnum.SUMMARY_SCHEDULE]: elementsScreenRight,
    }[pageName ?? ''];

    const actualScreenLeft = screenLeft ?? currentSavedScreenLeft;
    const actualScreenRight = screenRight ?? currentSavedScreenRight;

    const isAllMode = capture === 'all' || capture === 'allWithNow';

    useShareFiltersChangesFromTabs(pageParams, calendarHeaderBlockFiltersDescription, CalendarPageTabsEnum);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div className={'header-block-container'}>
                <div className={'calendar-navigation-container'}>
                    <CalendarNavigation
                        pageName={pageName}
                        positionTop={true}
                        step={0.2}
                        isAllMode={isAllMode}
                        screenLeft={actualScreenLeft}
                        screenRight={actualScreenRight}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CalendarInterval pageName={pageName} screenLeft={actualScreenLeft} screenRight={actualScreenRight} capture={capture} />
            </div>
        </div>
    );
};

import React from 'react';
import {FormFieldsGroup, FormItemType} from '../../../../../components/dynamicForm/DynamicForm';
import {getStoreState} from '../../../../../../index';
import {LocalizationEnum, localize} from '../../../../../localization';
import {canUpdateFinancialData, canViewFinancialData} from '../../../../../shared/util/permissionUtils';
import {DefaultTaxContent} from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFieldsData';
import {defaultTaxCalculationTypeField} from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFields';
import debounce from "lodash/debounce";
import {validateField} from "../../../../../shared/util/validateField";
import {FormUtils, TypedFormField} from "../../../../../core/utils/formUtils";
import {ProjectInfoRead} from "../../../../../server";
import {projectContractFields} from "../../production/modal/project-create-modal-fields";

type FormValues = ProjectInfoRead;

export const simpleOrderFormFields: FormFieldsGroup[] = FormUtils.typedFormFields<FormValues>([
    {
        fields: [
            {
                id: 'id',
                type: FormItemType.Hidden,
            },
            {
                id: 'renterId',
                type: FormItemType.Hidden,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const renterId = form.getFieldValue('renterId');

                    if (renterId) {
                        validateField(
                            'project',
                            fieldName,
                            value,
                            renterId,
                            form.getFieldValue('id'),
                            'Краткое наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
        ]
    },
    {
        helpMessage: DefaultTaxContent,
        fields: [
            {
                label: LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TAX_PERCENT,
                id: 'taxRate',
                type: FormItemType.Tax,
                defaultValue: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            defaultTaxCalculationTypeField as any

        ],
    },
    {
        fields: projectContractFields as TypedFormField<FormValues>[]
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    }
]);

import {OperationTypeCodeEnum} from "../../server";
import React from "react";
import {Icon, Select} from "antd";
import {injectIntl} from "react-intl";
import {getOperationStatusTypeData} from "../../modules/main/operationForm/utils/utils";
import {CustomSelect} from "./CustomSelect";

interface IProps {
    type?: 'project'|'projectTemplate'|'shipping'|'shippingTemplate';
    intl: any;
}

const TypeCodeFilter = (props:IProps) => {
    const {Option} = Select;

    const arrSubrent = [
        OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
        OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
        OperationTypeCodeEnum.SUBRENTPROLONG,
        OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER,
        OperationTypeCodeEnum.SUBRENTCANCEL,
        OperationTypeCodeEnum.SUBRENTDRAFT,
    ];

    const arrRent = [
        OperationTypeCodeEnum.ORDER,
        OperationTypeCodeEnum.BOOK,
        OperationTypeCodeEnum.RENT,
        OperationTypeCodeEnum.PROLONG,
        OperationTypeCodeEnum.RETURN,
        OperationTypeCodeEnum.RETURNBROKEN,
        OperationTypeCodeEnum.LOSTNORETURN,
        OperationTypeCodeEnum.CANCEL,
        OperationTypeCodeEnum.DRAFT
    ];

    const arrOther = [
        OperationTypeCodeEnum.AUTOMATICRULE,
        OperationTypeCodeEnum.CORRECT
    ];

    let newArr;

    if(props.type === 'project'){
        newArr = [
            ...arrRent,
            undefined,
            ...arrOther
        ];
    }else if(props.type === 'projectTemplate'){
        newArr = [
            OperationTypeCodeEnum.CANCEL,
            OperationTypeCodeEnum.DRAFT
        ];
    }else if(props.type === 'shipping'){
        newArr = [
            ...arrSubrent,
            undefined,
            ...arrOther
        ];
    }else{
        newArr = [
            ...arrRent,
            undefined,
            ...arrSubrent,
            undefined,
            ...arrOther
        ];
    }

    const arr: [] = newArr.map((item) => {
        let typeData = getOperationStatusTypeData(item);
        return (
            <Option className={!item ? 'rr-select-dropdown-menu-item-seperator' : undefined} value={item} disabled={!item}>
                {item ? <Icon style={{fontSize: 20, position: 'relative', top: 2, marginRight: 5}} className={'rr-operation-status-color-' + item} component={typeData.iconComponent} /> : null}
                {typeData?.name}
            </Option>
        );
    });

    return (
        <CustomSelect {...props} allowClear={true}>
            {arr}
        </CustomSelect>
    );
};

export default injectIntl(TypeCodeFilter);

import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../../../../store/rtqQueryApi';
import {InstanceInfoRead, serverApi} from '../../../../../server';

interface GetProductInstanceByIdArgs {
    businessAccountId: number;
    id: number;
}

export const getProductInstanceByIdQueryFn = async ({ businessAccountId, id }: GetProductInstanceByIdArgs) => {
    try {
        return await serverApi.getInstanceById(businessAccountId, id)
    } catch (error: unknown) {
        throw error;
    }
};

export const productInstancesApi = createApi({
    reducerPath: 'productInstancesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ProductInstanceList', 'ProductInstance'],
    endpoints: (builder) => ({
        getProductInstanceById: builder.query<InstanceInfoRead, GetProductInstanceByIdArgs>({
            queryFn: getProductInstanceByIdQueryFn,
            providesTags: ['ProductInstance'],
        }),
    }),
});

export const { useGetProductInstanceByIdQuery } = productInstancesApi;

// dispatch(productInstancesApi.util?.invalidateTags(['ProductInstance']));
import {IRouterItem} from "../../../config/routes";
import UserProfileDescription from "./user-profile-description";
import React from "react";
import NotFoundModule from "../settings/notFound/notFoundModule";

export const userRoutes: Array<IRouterItem> = [
    {
        menuKey: 'description',
        title: 'Описание',
        path: '/:businessAccountId/users/:userId',
        component: UserProfileDescription,
        sortOrder: 0,
        exact: true
    },
    {
        menuKey: 'notfound',
        path: `/:businessAccountId/users/:userId/`,
        component: NotFoundModule,
        sortOrder: 0
    }
];

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import RoundButton from '../../../../components/button/roundButton';
import { Link, Route } from 'react-router-dom';
import { BusinessAccountProfileEditModal } from './businessAccountProfileEditModal';
import { Card, Col, Icon, Row } from 'antd';
import { IconEdit, IconStoreSolid } from '../../../../components/icons';
import { getFormattedMessageComponent, localize } from '../../../../localization/localizationUtils';
import { RouteComponentProps } from 'react-router';
import { RouteConfigComponentProps } from 'react-router-config';
import { getImagePath, isDefined, printUserName } from '../../../../shared/util/utils';
import { FormValue } from '../../../../components';
import { BusinessAccountContactsInfoRead, EntityTypeCodeEnum } from '../../../../server/api';
import { LocalizationEnum } from '../../../../localization';
import { getBusinessAccountContacts } from '../../../../shared/reducers/businessAccountContacts.reducer';
import { RichTextView } from '../../../../components/richTextView/RichTextView';
import { Avatar } from '../../../../components/avatar/avatar';
import { editBusinessAccountModalFilters } from './businessAccountProfileEditModalData';
import { LegalDetailsActionType } from '../../legalDetails/data/legalDetailsData';
import { push, replace } from 'connected-react-router';
import { LegalDetailsModal } from '../../legalDetails/modal/legalDetailsModal';
import { getBusinessAccount } from '../../../../shared/reducers/businessAccount.reducer';
import { LegalDetailsBlockWrapper } from '../../legalDetails/components/legalDetailsBlockWrapper';
import { AddButton } from '../../../../components/buttons/addButton/addButton';
import classNames from 'classnames';
import './businessAccountProfileModule.less';

interface IProps extends StateProps, DispatchProps, RouteComponentProps, RouteConfigComponentProps {}

const linkToBusinessAccountProfile = (businessAccountId: number) => `/${businessAccountId}/settings/businessAccountProfile`;
const linkToCreateLegalDetails = (businessAccountId: number) => `/${businessAccountId}/settings/businessAccountProfile/createLegalDetails`;
const linkToEditLegalDetails = (businessAccountId: number, id: number) =>
    `/${businessAccountId}/settings/businessAccountProfile/editLegalDetails/${id}`;

class _BusinessAccountProfileModule extends React.PureComponent<IProps> {
    componentDidMount() {
        this.props.getBusinessAccountContacts(this.props.businessAccountId);
        this.props.getBusinessAccount(this.props.businessAccountId);
    }

    render() {
        let { entity, route } = this.props;
        return (
            <>
                <Card
                    className={'rr-card'}
                    bodyStyle={{ display: 'none' }}
                    style={{ borderBottom: '1px solid #cccccc' }}
                    title={<span className="card-title">{getFormattedMessageComponent(route ? route.title : undefined)}</span>}
                    extra={
                        <Link to={`/${this.props.businessAccountId}/settings/businessAccountProfile/edit`}>
                            <RoundButton colorScheme={'default'}>
                                <Icon component={IconEdit} />
                                {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                            </RoundButton>
                        </Link>
                    }
                />
                {entity ? this.renderEntity(entity) : null}
            </>
        );
    }

    renderEntity(entity: BusinessAccountContactsInfoRead) {
        return (
            <>
                <Card id={'description'} className={'rr-card'}>
                    <div style={{ marginBottom: 28 }}>
                        <Avatar className={'rr-avatar-big'} style={{ backgroundColor: '#90dcff' }} src={getImagePath(entity.image, '158')}>
                            <Icon component={IconStoreSolid} />
                        </Avatar>
                    </div>

                    <Row>
                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                            <div className="rr-value">
                                <FormValue
                                    value={entity.shortName}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>

                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>
                                {localize(LocalizationEnum.PAGE__SETTINGS__BA_PROFILE__FIELD__FULL_NAME__LONG_TITLE)}
                            </div>
                            <div className="rr-value">
                                <FormValue
                                    value={entity.fullName}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>

                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}</div>
                            <div className="rr-text">
                                <FormValue
                                    value={entity.description && <RichTextView value={entity.description} />}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>

                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}</div>
                            <div className={'rr-text'}>
                                <FormValue
                                    value={entity.comment}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>

                <Card
                    title={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CONTACT_PERSON)}
                    id={'contact'}
                    style={{ marginTop: 32 }}
                    className={'rr-card'}
                >
                    <Row>
                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__GLOBAL__PERSON_FULL_NAME)}</div>
                            <div className="rr-value">
                                <FormValue
                                    value={printUserName(entity.contact?.fullName)}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>

                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER)}</div>
                            <div className="rr-value">
                                <FormValue
                                    value={entity.contact?.phoneNumber}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>

                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__POSITION)}</div>
                            <div className="rr-value">
                                <FormValue
                                    value={entity.contact?.position}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>

                        <Col className={'rr-form-item'}>
                            <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__GLOBAL__EMAIL)}</div>
                            <div className="rr-value">
                                <FormValue
                                    value={entity.contact?.email}
                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>

                <Card
                    title={'Реквизиты'}
                    id={'legalAttributes'}
                    style={{ marginTop: 32 }}
                    bodyStyle={{ padding: 0 }}
                    className={classNames('rr-card', 'rr-legalAttributes-card')}
                    extra={
                        <AddButton
                            onClick={() => {
                                this.props.push(linkToCreateLegalDetails(this.props.businessAccountId));
                            }}
                        />
                    }
                >
                    {this.props.businessAccount?.legalDetails.map((item) => {
                        return (
                            <LegalDetailsBlockWrapper
                                storageKey={`businessAccountId-legalDetails-${item.id!}`}
                                entityType={EntityTypeCodeEnum.BUSINESSACCOUNT}
                                data={item}
                                onAction={async (action) => {
                                    if (action === LegalDetailsActionType.create) {
                                        this.props.push(linkToCreateLegalDetails(this.props.businessAccountId));
                                    } else if (action === LegalDetailsActionType.edit) {
                                        this.props.push(linkToEditLegalDetails(this.props.businessAccountId, item.id!));
                                    }
                                }}
                                afterDeleteCb={() => {
                                    this.props.getBusinessAccount(this.props.businessAccountId, true);
                                }}
                            />
                        );
                    })}

                    {!this.props.businessAccount?.legalDetails ||
                        (this.props.businessAccount.legalDetails.length === 0 && (
                            <LegalDetailsBlockWrapper
                                storageKey={`businessAccountId-legalDetails-${0}`}
                                entityType={EntityTypeCodeEnum.BUSINESSACCOUNT}
                                data={undefined}
                                onAction={async (action) => {}}
                                afterDeleteCb={() => {}}
                            />
                        ))}
                </Card>

                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/businessAccountProfile/edit`}
                    render={(props) => (
                        <BusinessAccountProfileEditModal
                            goBackPath={linkToBusinessAccountProfile(this.props.businessAccountId)}
                            title={localize(LocalizationEnum.PAGE__FORM__UPDATE__FORM_TITLE)}
                            initialValues={entity as { [k: string]: any }}
                            filters={editBusinessAccountModalFilters}
                            modalName={'edit-business_account'}
                        />
                    )}
                />

                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/businessAccountProfile/editLegalDetails/:id`}
                    render={(props) => {
                        const id = isDefined(props.match.params.id) ? +props.match.params.id : undefined;
                        const legalDetails = this.props.businessAccount?.legalDetails.find((item) => item.id === id);

                        return this.props.entity?.businessAccountId && legalDetails ? (
                            <LegalDetailsModal
                                referenceEntityId={this.props.entity.businessAccountId}
                                entityType={EntityTypeCodeEnum.BUSINESSACCOUNT}
                                legalDetails={legalDetails}
                                onCancel={() => {
                                    this.props.replace(linkToBusinessAccountProfile(this.props.businessAccountId));
                                }}
                                onSuccess={() => {
                                    this.props.getBusinessAccountContacts(this.props.businessAccountId);
                                    this.props.replace(linkToBusinessAccountProfile(this.props.businessAccountId));
                                    this.props.getBusinessAccount(this.props.businessAccountId, true);
                                }}
                            />
                        ) : null;
                    }}
                />

                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/businessAccountProfile/createLegalDetails`}
                    render={(props) =>
                        this.props.entity?.businessAccountId ? (
                            <LegalDetailsModal
                                referenceEntityId={this.props.entity.businessAccountId}
                                entityType={EntityTypeCodeEnum.BUSINESSACCOUNT}
                                onCancel={() => {
                                    this.props.replace(linkToBusinessAccountProfile(this.props.businessAccountId));
                                }}
                                onSuccess={() => {
                                    this.props.getBusinessAccountContacts(this.props.businessAccountId);
                                    this.props.replace(linkToBusinessAccountProfile(this.props.businessAccountId));
                                    this.props.getBusinessAccount(this.props.businessAccountId, true);
                                }}
                            />
                        ) : null
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        currencies: storeState.businessAccount.entity,
        entity: storeState.businessAccountContacts.entity,
        businessAccount: storeState.businessAccount.entity,
    };
};

const mapDispatchToProps = { getBusinessAccountContacts, push, replace, getBusinessAccount };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const BusinessAccountProfileModule = connect(mapStateToProps, mapDispatchToProps)(_BusinessAccountProfileModule);

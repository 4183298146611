import React from 'react';
import {InputNumber} from "antd";
import {InputNumberProps} from "antd/lib/input-number";
import {inputNumberParser, inputNumberPercentFormatter, onInputChange} from ".";

export interface NumberInputTaxProps extends Omit<InputNumberProps, 'formatter'|'parser'|'min'|'step'> {}

export class NumberInputTax extends React.Component<NumberInputTaxProps> {
    render() {
        const {onChange, ...props} = this.props;
        return (
            <InputNumber
                max={Number.MAX_SAFE_INTEGER}
                {...props}
                parser={inputNumberParser}
                formatter={inputNumberPercentFormatter}
                onChange={(value) => onInputChange(value, onChange)}
                min={0}
                step={1}
            />
        );
    };
}

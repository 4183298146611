import { DownloadDocumentPopoverProps, IDownloadDocumentParamsItem } from '../../../DownloadDocumentPopover';
import { FileTypeCodeEnum } from '../../../../../../server';

export enum ExportProductsInventoryListPopoverOptions {
    WITH_INSTANCES = 'withInstances',
    SHOW_BAR_CODE = 'showBarcode',
    SHOW_PURCHASE_COST = 'showPurchaseCost',
    SHOW_CARRYING_VALUE = 'showCarryingValue',
    RECONCILIATION_COLUMNS = 'reconciliationColumns',
    EXPAND_INSTANCE = 'expandInstance',
    GROUP_BY_CATEGORIES = 'groupByCategories',
    INCLUDE_SUBRENTED_INSTANCES = 'includeSubrentedInstances',
    WITH_SUPPLIER = 'withSupplier',
}

const parentOptions = [
    ExportProductsInventoryListPopoverOptions.WITH_INSTANCES,
    ExportProductsInventoryListPopoverOptions.INCLUDE_SUBRENTED_INSTANCES,
];

export const options: IDownloadDocumentParamsItem[] = [
    { key: ExportProductsInventoryListPopoverOptions.SHOW_BAR_CODE, label: 'Штриховой код' },
    { key: ExportProductsInventoryListPopoverOptions.WITH_INSTANCES, label: 'Свои именов. экземпляры' },
    { key: ExportProductsInventoryListPopoverOptions.INCLUDE_SUBRENTED_INSTANCES, label: 'Чужие именов. экземпляры' },
    { key: ExportProductsInventoryListPopoverOptions.WITH_SUPPLIER, label: 'Поставщик', parent: parentOptions },
    {
        key: ExportProductsInventoryListPopoverOptions.EXPAND_INSTANCE,
        label: 'Описания состояний',
        parent: parentOptions,
    },
    { key: ExportProductsInventoryListPopoverOptions.RECONCILIATION_COLUMNS, label: 'Колонки для сверки' },
    { key: ExportProductsInventoryListPopoverOptions.GROUP_BY_CATEGORIES, label: 'Группировку по категории' },
];

export const financialOptions: IDownloadDocumentParamsItem[] = [
    {
        key: ExportProductsInventoryListPopoverOptions.SHOW_PURCHASE_COST,
        label: 'Закупочную стоимость',
        parent: parentOptions,
    },
    {
        key: ExportProductsInventoryListPopoverOptions.SHOW_CARRYING_VALUE,
        label: 'Балансовую стоимость',
        parent: parentOptions,
    },
];

export const availableFileTypes = [FileTypeCodeEnum.EXCEL, FileTypeCodeEnum.PDFTYPE] as const;
export type ProductsInventoryListFileType = (typeof availableFileTypes)[number];
export type ProductsInventoryListOnDownload = DownloadDocumentPopoverProps<
    ProductsInventoryListFileType,
    ExportProductsInventoryListPopoverOptions
>['onDownload'];

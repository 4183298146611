import { DurationInputArg1, Moment } from 'moment';
import moment from 'moment/moment';

export abstract class DateUtils {
    static getCalendarIntervalCenteredByDate = (date: Moment, offset: DurationInputArg1): [number, number] => {
        const halfMonthInterval = moment.duration(offset);
        const startOfInterval = date.clone().subtract(halfMonthInterval);
        const endOfInterval = date.clone().add(halfMonthInterval);

        return [+startOfInterval, +endOfInterval];
    };
    static getCalendarBoundaries = (date: Moment, type: 'hour' | 'day' | 'month'): [number, number] => {
        if (type === 'hour') return [+date - 86400000 / 2, +date + 86400000 / 2];
        if (type === 'day') return [+date - 604800000 / 2, +date + 604800000 / 2];
        if (type === 'month') return [+date - 2592000000 / 2, +date + 2592000000 / 2];

        const _: never = type;
        return [0, 0];
    };
}

import {
    PageURLParamDescriptionObject,
    PageURLParamDescriptions
} from '../../../../../../../../../../core/utils/descriptions';

export const productCompositionFiltersDescription: Required<PageURLParamDescriptionObject<ProductCompositionFilters>> = {
    limit: PageURLParamDescriptions.numberParam,
    page: PageURLParamDescriptions.numberParam,
    search: PageURLParamDescriptions.searchParam(),
};

import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { logisticsPageUrlRoute } from '../../../data/tabs';
import { useCallback, useMemo } from 'react';
import { push, replace } from 'connected-react-router';
import { PageUtils } from '../../../../../../shared/util/pageUtils';
import { LocationDrawerProps } from '../../locations/drawer/LocationDrawer';
import { VehicleDrawerProps } from '../../vehicles/drawer/components/VehicleDrawer';
import { useContactActionsFromDrawer } from '../../../../renters/tabs/contacts/hooks/useContactActionsFromDrawer';

import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { useArchiveLocationsMutation, useDeleteLocationMutation } from '../../locations/api/locations.api';
import { useArchiveVehiclesMutation, useDeleteVehicleMutation } from '../../vehicles/api/vehicles.api';

export const useLocationsActions = () => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const logisticsUrlMainPath = `/${businessAccountId}/${logisticsPageUrlRoute}` as const;

    const [archiveLocations, { isLoading: isArchiveLocationsLoading }] = useArchiveLocationsMutation();

    const [deleteLocation, { isLoading: isDeleteLocationLoading }] = useDeleteLocationMutation();

    const isLoading = isArchiveLocationsLoading || isDeleteLocationLoading;

    const usePushToLocationsAndEditEntity = useCallback(
        (locationRecord) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.LOCATIONS)));
            dispatch(
                push(
                    PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.LOCATIONS, {
                        modal: 'edit',
                        id: String(locationRecord.id),
                    })
                )
            );
        },
        [dispatch, logisticsUrlMainPath]
    ) satisfies LocationDrawerProps['editLocation'];

    const usePushToLocationsAndDeleteEntity: LocationDrawerProps['deleteLocation'] = useCallback(
        async (locationRecord) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.LOCATIONS)));

            deleteLocation({
                id: locationRecord.id,
                businessVersionObj: {
                    businessVersion: locationRecord.businessVersion,
                },
            });
        },
        [deleteLocation, dispatch, logisticsUrlMainPath]
    );

    const usePushToLocationsAndArchiveEntity: LocationDrawerProps['archiveLocation'] = useCallback(
        (locationRecord) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.LOCATIONS)));

            archiveLocations({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: locationRecord.id,
                            archive: !locationRecord.archive,
                            businessVersion: locationRecord.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveLocations, dispatch, logisticsUrlMainPath]
    );

    return useMemo(
        () => ({
            edit: usePushToLocationsAndEditEntity,
            delete: usePushToLocationsAndDeleteEntity,
            archive: usePushToLocationsAndArchiveEntity,
            isLoading,
        }),
        [usePushToLocationsAndEditEntity, usePushToLocationsAndDeleteEntity, usePushToLocationsAndArchiveEntity, isLoading]
    );
};

const useVehiclesActions = () => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const logisticsUrlMainPath = `/${businessAccountId}/${logisticsPageUrlRoute}` as const;

    const [archiveVehicles, { isLoading: isArchiveVehiclesLoading }] = useArchiveVehiclesMutation();

    const [deleteVehicle, { isLoading: isDeleteVehicleLoading }] = useDeleteVehicleMutation();

    const isLoading = isArchiveVehiclesLoading || isDeleteVehicleLoading;

    const usePushToVehiclesAndEditEntity = useCallback(
        (vehicle) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.VEHICLES)));
            dispatch(
                push(
                    PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.VEHICLES, {
                        modal: 'edit',
                        id: String(vehicle.id),
                    })
                )
            );
        },
        [dispatch, logisticsUrlMainPath]
    ) satisfies VehicleDrawerProps['editVehicle'];

    const usePushToVehiclesAndDeleteEntity: VehicleDrawerProps['deleteVehicle'] = useCallback(
        async (vehicle) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.VEHICLES)));

            deleteVehicle({
                id: vehicle.id,
                businessVersionObj: {
                    businessVersion: vehicle.businessVersion,
                },
            });
        },
        [deleteVehicle, dispatch, logisticsUrlMainPath]
    );

    const usePushToVehiclesAndArchiveEntity: VehicleDrawerProps['archiveVehicle'] = useCallback(
        (vehicle) => {
            dispatch(replace(PageUtils.getPathForPushOnPageTab(logisticsUrlMainPath, LogisticsPageTabsEnum.VEHICLES)));

            archiveVehicles({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: vehicle.id,
                            archive: !vehicle.archive,
                            businessVersion: vehicle.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveVehicles, dispatch, logisticsUrlMainPath]
    );

    return useMemo(
        () => ({
            edit: usePushToVehiclesAndEditEntity,
            delete: usePushToVehiclesAndDeleteEntity,
            archive: usePushToVehiclesAndArchiveEntity,
            isLoading,
        }),
        [isLoading, usePushToVehiclesAndArchiveEntity, usePushToVehiclesAndDeleteEntity, usePushToVehiclesAndEditEntity]
    );
};

export const usePushToPages = () => {
    const locationActions = useLocationsActions();
    const contactActions = useContactActionsFromDrawer();
    const vehiclesActions = useVehiclesActions();

    return useMemo(
        () => ({
            locationActions,
            contactActions,
            vehiclesActions,
        }),
        [contactActions, locationActions, vehiclesActions]
    );
};

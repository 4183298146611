import React, { ReactNode } from 'react';
import { Button, Icon, Popover } from 'antd';
import { removeInstances } from '../../operationForm/reducers/operationForm.reducer';
import { connect } from 'react-redux';
import { IconCheck, IconControlPointDuplicateBlack, IconEdit, IconTimesCircle, IconToolsSolid } from '../../../../components/icons';
import { InstanceRecord, InstanceStateCodeEnum, InstanceTransitionCodeEnum } from '../../../../server/api';
import './product-page-instances-action-popover.less';
import { IRootState } from '../../../../shared/reducers';
import { LocalizationEnum, localize } from '../../../../localization';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';

interface IProps extends DispatchProps, StateProps {
    record: InstanceRecord;
    onAction: Function;
    children: () => ReactNode;
    disabled: boolean;
}

interface IState {
    visible: boolean;
    correctionPopoverVisible: boolean;
    amount?: number;
}

class ProductPageInstancesActionPopover extends React.PureComponent<IProps, IState> {
    state = {
        visible: false,
        correctionPopoverVisible: false,
        amount: 0,
    };

    handleVisibleChange = (visible) => {
        this.setState(visible ? { visible, amount: undefined } : { visible });
    };

    handleCorrectionPopoverVisibleChange = (visible) => {
        this.setState({ correctionPopoverVisible: visible });
    };

    onAmountChanged = (value: number | undefined) => {
        this.setState({ amount: value });
    };

    onButtonClick = (e, type: InstanceStateCodeEnum | string) => {
        e.stopPropagation();
        this.setState({ visible: false, correctionPopoverVisible: false });
        this.props.onAction(this.props.record, type);
    };

    headerOnClick = (e) => {
        e.stopPropagation();
    };

    render() {
        console.log('ProductPageInstancesActionPopover render()');
        const buttonsAction = this.props.record.availableTransitionCodes?.map((el: InstanceTransitionCodeEnum, num: number) => {
            return (
                <div key={num}>
                    <Button block onClick={(e) => this.onButtonClick(e, el)}>
                        {el === InstanceTransitionCodeEnum.ACTIVATE ? (
                            <>
                                <Icon style={{ color: '#34BFA3' }} component={IconCheck} />
                                <span>{localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE)}</span>
                            </>
                        ) : null}
                        {el === InstanceTransitionCodeEnum.SENDUNDERMAINTENANCE ? (
                            <>
                                <Icon style={{ color: '#525993' }} component={IconToolsSolid} />
                                <span>{localize(LocalizationEnum.ASPECT__INSTANCE_TRANSITIONS__SEND_UNDER_MAINTENANCE)}</span>
                            </>
                        ) : null}
                        {el === InstanceTransitionCodeEnum.DECOMMISSION ? (
                            <>
                                <Icon style={{ color: '#a0a0a0' }} component={IconTimesCircle} />
                                <span>{localize(LocalizationEnum.ASPECT__INSTANCE_TRANSITIONS__DECOMISSION)}</span>
                            </>
                        ) : null}

                        {el === InstanceTransitionCodeEnum.SUPPLIERDECOMMISSION ? (
                            <>
                                <Icon style={{ color: '#a0a0a0' }} component={IconTimesCircle} />
                                <span>Списать чужой</span>
                            </>
                        ) : null}
                        {el === InstanceTransitionCodeEnum.SUPPLIERACTIVATE ? (
                            <>
                                <Icon style={{ color: '#34BFA3' }} component={IconCheck} />
                                <span>Активировать чужой</span>
                            </>
                        ) : null}
                    </Button>
                </div>
            );
        });

        return (
            <Popover
                onVisibleChange={this.handleVisibleChange}
                visible={this.props.disabled === true ? false : this.state.visible}
                overlayClassName={'rr-grid-actions-popover'}
                autoAdjustOverflow
                arrowPointAtCenter
                placement="bottomLeft"
                trigger="click"
                content={
                    <>
                        <div onClick={this.headerOnClick} className={'rr-grid-actions-popover-header1'}>
                            {/*<span style={{marginRight: 7}}>{localize(LocalizationEnum.ASPECT__GRID__POPUP__TITLE__ACTIONS)}</span>*/}
                        </div>
                        <div className={'rr-grid-actions-popover-content'}>
                            {
                                <>
                                    <Button block onClick={(e) => this.onButtonClick(e, 'edit')}>
                                        <Icon style={{ color: '#383f77' }} component={IconEdit} />
                                        <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT)}</span>
                                    </Button>
                                    <Button block onClick={(e) => this.onButtonClick(e, 'copy')}>
                                        <Icon style={{ color: '#34bfa3' }} component={IconControlPointDuplicateBlack} />
                                        <span>{localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE)}</span>
                                    </Button>
                                    <div>{buttonsAction}</div>
                                    <Button disabled={!this.props.record.deleteable} block onClick={(e) => this.onButtonClick(e, 'delete')}>
                                        <SystemIcon type={SystemIconType.delete} />
                                        <span>Удалить</span>
                                    </Button>
                                </>
                            }
                        </div>
                    </>
                }
            >
                {this.props.children()}
            </Popover>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entity: storeState.product.entity,
    };
};

const mapDispatchToProps = { removeInstances };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageInstancesActionPopover);

import { PersonFullName } from '../../server';

export class DrawerUtils {
    static getFullName(person: PersonFullName): string {
        const { lastname, firstname, secondname } = person;
        return [lastname, firstname, secondname].filter(Boolean).join(' ');
    }
    static getCoordinates(latitude: number, longitude: number): string;
    static getCoordinates(latitude?: number, longitude?: number): string | undefined {
        if (!latitude || !longitude) {
            return undefined;
        }
        return `${latitude}, ${longitude}`;
    }
}

import React, { useEffect, useState } from 'react';
import { PageDescriptionTabPane } from '../../../components/PageDescription/PageDescription';
import { ImageObj } from '../../../../../../server';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../shared/reducers';
import { getGridStorageData } from '../../../../../../components/grid/utils';
import { canViewFinancialData } from '../../../../../../shared/util/permissionUtils';

interface IProps {
    deviceType: 'phone' | 'tablet' | 'desktop';
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    updateParentEntity: () => void;
}

export const ProductPageDescriptionTabNew = (props: IProps) => {
    const entity = useSelector((state: IRootState) => state.product.entity);
    const showFinancialData = useSelector((store: IRootState) => canViewFinancialData(store.permissions.permissions));

    const [currentImage, setCurrentImage] = useState<ImageObj | undefined>(undefined);
    const [collapsedIndicators, setCollapsedIndicators] = useState<boolean>(true);
    const [collapsed, setCollapsed] = useState<string[] | undefined>([]);

    const onSetCurrentImage = ({ currentImage }: { currentImage: ImageObj }) => {
        setCurrentImage(currentImage);
    };

    const onSetCollapsedIndicators = (props) => {
        setCollapsedIndicators(props);
    };

    const onSetCollapsed = (props) => {
        setCollapsed(props);
    };

    useEffect(() => {
        let collapsed = getGridStorageData('product-description');
        setCollapsed(collapsed.filters?.collapsedIndicators);
    }, []);

    return (
        <PageDescriptionTabPane
            gridName={'product-description'}
            responsive={props.responsive}
            deviceType={props.deviceType}
            currentImage={currentImage}
            setCurrentImage={onSetCurrentImage}
            collapsedIndicators={collapsedIndicators}
            setCollapsedIndicators={onSetCollapsedIndicators}
            collapsed={collapsed}
            setCollapsed={onSetCollapsed}
            entity={entity}
            typeDescription={'product'}
            showFinancialData={showFinancialData}
            updateParentEntity={props.updateParentEntity}
        />
    );
};

import React, { useMemo } from 'react';
import { ColumnTypes, TableColumn } from '../../../grid/Table';
import { GridProps } from '../../../grid/Grid';
import { CustomFieldObjRead, CustomFieldWithValueObjRead, NomenclatureRecord } from '../../../../server';
import DescriptionCustomField from '../../../../modules/main/inventory/components/DescriptionCustomField/DescriptionCustomField';
import { CustomFieldsUtils } from '../../../../core/utils/customFieldsUtils';

export const useGridColumns = <EntityRecord extends object>({
    columns,
    customFieldMarkers,
    insertIndex,
}: {
    columns: TableColumn<EntityRecord>[];
    customFieldMarkers: CustomFieldObjRead[] | undefined;
    insertIndex: number;
}) => {
    return useMemo(() => {
        if (customFieldMarkers == null) return columns;

        const newColumns = [...columns];

        customFieldMarkers.forEach((item, index) => {
            if (item.customFieldIndexKey == null) return;

            newColumns.splice(insertIndex + index, 0, {
                title: item.name,
                dataIndex: CustomFieldsUtils.addPrefix(item.customFieldIndexKey) as TableColumn<EntityRecord>['dataIndex'],
                type: ColumnTypes.CustomRender,
                minWidth: 140,
                resizable: true,
                defaultHidden: true,
                render: (value: any, rowData: NomenclatureRecord) => {
                    let field: CustomFieldWithValueObjRead | undefined;

                    if (rowData.customFieldValues) {
                        let customField = rowData.customFieldValues.find((field) => field.customFieldIndexKey === item.customFieldIndexKey);
                        if (customField) {
                            field = {
                                ...item,
                                values: customField.values,
                                linkValues: customField.linkValues,
                            };
                        }
                    }

                    if (field == null) return null;

                    return (
                        <div
                            style={{
                                fontWeight: 400,
                            }}
                            className={'rr-grid-text-cell-left'}
                        >
                            <DescriptionCustomField
                                field={field}
                                ignoreEmpty
                                customFieldsParameters={{
                                    forGrid: true,
                                    separator: ', ',
                                }}
                            />
                        </div>
                    );
                },
            });
        });

        return newColumns satisfies GridProps['columns'];
    }, [columns, customFieldMarkers]);
};

import {FormFieldsGroup, FormItemType} from "../../../../../components/dynamicForm/DynamicForm";
import {LocalizationEnum, localize} from "../../../../../localization";
import debounce from "lodash/debounce";
import {WrappedFormUtils} from "antd/lib/form/Form";
import {validateField} from "../../../../../shared/util/validateField";

// dynamic form fields
export const formFields:FormFieldsGroup[] = [
    {
        fields: [
            {
                id: 'productId',
                type: FormItemType.Hidden,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__INSTANCE__CODE_OR_NAME,
                id: 'nameOrCode',
                type: FormItemType.String,
                style: {minWidth: 120},
                className: 'rr-select-gray',
                maxLength: 30,
                required: true,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    validateField('instance', fieldName, value, form.getFieldValue('productId'), undefined,'Наименование уже использовано в текущем продукте', cb);
                }, 500)
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__INSTANCE__INVENTORY_NUMBER,
                id: 'inventoryNumber',
                type: FormItemType.String,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    validateField('instance', fieldName, value, undefined, undefined,'Официальный инвентарный номер уже использован в текущем бизнес-аккаунте', cb);
                }, 500)
            },
            {
                label: LocalizationEnum.PAGE__PRODUCTS__CONDITION_DESCRIPTION,
                id: 'stateDescription',
                type: FormItemType.Text,
                maxLength: 2000
            }
        ]
    }
];

import { PropsWithChildren, useMemo } from 'react';
import { DrawerUrlContext, DrawerUrlContextValue } from '../../context/DrawerUrlContext';
import { useSetUrlForDrawer } from '../../../../../../core/hooks/useDrawer';
import { EntityGridProps } from '../../../types/grid';

import { PageURLParamDescriptionObject } from '../../../../../../core/utils/descriptions';
import { EntityGridDrawerWrapper } from '../../../types/drawer';
import { PageUrlDescriptionContext, PageUrlParamsContext } from '../../context/PageUrlParamsContext';
import { CustomFieldObjRead } from '../../../../../../server';
import { CustomFieldsUtils } from '../../../../../../core/utils/customFieldsUtils';
import { PageUrlParamsObject } from '../../../../../../core/hooks/urlParams/types';

interface DrawerUrlProviderProps<
    PageParams extends object,
    PageParamsDescription extends PageURLParamDescriptionObject<PageParams>,
    EntityRecord extends object,
    EntityInfoRead extends object
> {
    rootPath: EntityGridProps<PageParamsDescription, EntityRecord, EntityInfoRead>['metaData']['rootPath'];
    pageParamsObject: PageUrlParamsObject<PageParams>;
    drawer?: EntityGridDrawerWrapper<EntityInfoRead>;
}

export const EntityGridProviders = <
    PageParams extends object,
    PageParamsDescription extends PageURLParamDescriptionObject<PageParams>,
    EntityRecord extends object,
    EntityInfoRead extends object
>(
    props: PropsWithChildren<
        {
            pageParamsDescription: PageParamsDescription;
            customFieldMarkers: CustomFieldObjRead[] | undefined;
        } & DrawerUrlProviderProps<PageParams, PageParamsDescription, EntityRecord, EntityInfoRead>
    >
) => {
    const { rootPath, pageParamsObject, drawer, pageParamsDescription, customFieldMarkers, children } = props;

    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);

    const [setEntityId, clearEntityId] = useSetUrlForDrawer<EntityInfoRead>(rootPath, urlSearchParams, Boolean(drawer));
    const memoizedDrawerHelpers = useMemo(() => [setEntityId, clearEntityId], [clearEntityId, setEntityId]);

    const actualPageParamsDescription = CustomFieldsUtils.generateCustomFieldsDescription(customFieldMarkers, pageParamsDescription);

    return (
        <PageUrlDescriptionContext.Provider value={actualPageParamsDescription}>
            <DrawerUrlContext.Provider value={memoizedDrawerHelpers as DrawerUrlContextValue}>
                <PageUrlParamsContext.Provider value={pageParamsObject}>{children}</PageUrlParamsContext.Provider>
            </DrawerUrlContext.Provider>
        </PageUrlDescriptionContext.Provider>
    );
};

import React from "react";
import {Button, Card, Col, Form, Radio, Row, Select, Switch} from "antd";
import '../user-profile-page.less';
import {Alert} from "../../../../components/alert/alert";
import ReactDOM from "react-dom";
import {LocalizationEnum, localize} from '../../../../localization';
import {FormComponentProps} from "antd/lib/form";
import {NotificationCustomizationObjEventFilterEnum} from "../../../../server";
import {notificationCode2LocalizationEnum} from "./getEvent";

interface Props extends FormComponentProps {
    isOk: (newState: any) => Promise<any>;
    state: any;
    error?: any;
}

interface State {
    globalEnabled: boolean;
    globalShowOwnActions: boolean;
    baCustomizations: any[];
    baCustomizationsCompany: any[];
    err: any;
    update: boolean;
}

class UserProfileNotificationsPopover extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let state = JSON.parse(JSON.stringify(this.props.state));

        this.state = {
            globalEnabled: state.globalEnabled,
            globalShowOwnActions: state.globalShowOwnActions,
            baCustomizations: state.baCustomizations,
            baCustomizationsCompany: state.baCustomizationsCompany,
            err: undefined,
            update: false
        };
    }

    componentDidUpdate = (prevProps) => {
        // Если есть ошибка, то нужно скролить на верх
        if (this.props.error && prevProps.error !== this.props.error) {
            let node = ReactDOM.findDOMNode(this);
            if (node instanceof Element && (node as Element).getElementsByClassName('ant-modal-wrap ')[0]) {
                (node as Element).getElementsByClassName('ant-modal-wrap ')[0].scrollTop = 0;
            }
        }
    };

    settingsBusinessAccounts = (e, num, item, operation) => {
        let newBaCustomizations, newBaCustomizationsCompany;

        if (operation === 'enabled') {
            newBaCustomizations = this.state.baCustomizations.map((el) => {
                if (el.businessAccountId === item.businessAccountId) {
                    return {...el, enabled: !el.enabled}
                }
                return el;
            });
            newBaCustomizationsCompany = this.state.baCustomizationsCompany.length === 0 ? [] : [{
                ...item,
                enabled: !item.enabled
            }]
        } else if (operation === 'projectFilter') {
            const projectFilter = e.target.value === 1 ? 'ALL' : 'ASSIGNED';
            newBaCustomizations = this.state.baCustomizations.map((el) => {
                if (el.businessAccountId === item.businessAccountId) {
                    return {...el, projectFilter: projectFilter}
                }
                return el;
            });
            newBaCustomizationsCompany = this.state.baCustomizationsCompany.length === 0 ? [] : [{
                ...item,
                projectFilter: projectFilter
            }]
        }

        this.setState({
            baCustomizations: newBaCustomizations,
            baCustomizationsCompany: newBaCustomizationsCompany
        });
    };

    globalEnabled = () => {
        this.setState({
            globalEnabled: !this.state.globalEnabled
        });
    };

    globalShowOwnActions = () => {
        this.setState({
            globalShowOwnActions: !this.state.globalShowOwnActions
        });
    };

    getLocalEventDopArr = (arr) => {
        const defaultArr = [
            NotificationCustomizationObjEventFilterEnum.RENTACTIVITY,
            NotificationCustomizationObjEventFilterEnum.WARNING,
            NotificationCustomizationObjEventFilterEnum.PROBLEM,
            NotificationCustomizationObjEventFilterEnum.ASSIGNMENT,
            NotificationCustomizationObjEventFilterEnum.RENTCORRECTION,
            NotificationCustomizationObjEventFilterEnum.RENTLOSTORBROKEN,
            NotificationCustomizationObjEventFilterEnum.NEWORDERFROMSTOREFRONT,
            NotificationCustomizationObjEventFilterEnum.ORDERFROMSTOREFRONTCANCELED,
            NotificationCustomizationObjEventFilterEnum.NEWPAYMENTFROMSTOREFRONT,
            NotificationCustomizationObjEventFilterEnum.ASSIGNMENTTOWORK,
        ].map(item => {
            return {key: item, value: notificationCode2LocalizationEnum(item)}
        });
        return defaultArr.map(el => {
            return (
                <Select.Option key={el.key}>{el.value ? localize(el.value) : ''}</Select.Option>
            );
        });
    };

    getStringNameEvent = (arr) => {
        const defaultArr = [
            {key: 'RENT_ACTIVITY', value: LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__RENTACTIVITY},
            {key: 'WARNING', value: LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__WARNING},
            {key: 'PROBLEM', value: LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__PROBLEM},
            {key: 'ASSIGNMENT', value: LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__ASSIGNMENT},
            {key: 'RENT_CORRECTION', value: LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CORRECT},
            {key: 'RENT_LOST_OR_BROKEN', value: LocalizationEnum.PAGE__USER_PROFILE__EVENT_TYPE__LOST_OR_BROKEN},
            {
                key: 'NEW_ORDER_FROM_STOREFRONT',
                value: LocalizationEnum.PAGE__USER_PROFILE__EVENT_TYPE__NEW_ORDER_FROM_STOREFRONT
            },
            {
                key: NotificationCustomizationObjEventFilterEnum.ORDERFROMSTOREFRONTCANCELED,
                value: LocalizationEnum.PAGE__USER_PROFILE__EVENT_TYPE__ORDER_FROM_STOREFRONT_CANCELED
            },
        ];
        return arr.map((item) => {
            return localize(defaultArr.find(i => i.key === item)!.value);
        })
    }

    onchangeBaCustomizationsEvents = async (e, id) => {
        let newBaCustomizations = this.state.baCustomizations.map(i => {
            if (i.businessAccountId === id) {
                i.eventFilter = e;
            }
            return i;
        })
        this.setState({
            baCustomizations: newBaCustomizations,
        });
    };

    localization = (arr) => {
        if (!arr) {
            return [];
        }
        return arr;
    };

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll({scroll: {offsetTop: 270}}, async (err, values) => {

            if (!err) {
                await this.props.isOk(this.state)
                    .then(err => {
                        this.setState({
                            err
                        });
                        document.getElementsByClassName('ant-modal-wrap')[0].scrollTop = 0;
                    });
            }
        });
    };

    compareEvent = (rule, value, callback) => {
        if (rule.field === 'mainEvent') {
            callback();
        } else {
            if (this.state.baCustomizations[+rule.field].eventFilter.length === 0) {
                callback(localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__VALIDATION__FIELD_IS_EMPTY));
            } else {
                callback();
            }
        }

        this.setState({
            update: false
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {Option} = Select;
        const company = this.state.baCustomizationsCompany.length === 0 ? this.state.baCustomizations : this.state.baCustomizationsCompany;
        const viewSettingBusinessAccounts = company.map((item: any, num) => {

            const lastBorder = this.state.baCustomizations.length - 1 !== num ? 'user-profile-border-model-small' : '';

            if (!item.useDefaults) {
                return (
                    <div key={item.businessAccountId}>
                        <Card bodyStyle={{minWidth: '800px', padding: '20px 40px', borderBottom: '1px solid #e4e5ec'}}>
                            <Row className="user-profile-row" style={{}}>
                                {
                                    this.state.baCustomizationsCompany.length === 0 ?
                                        <>
                                            <Col>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <h3 className="user-profile-h4"
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 700,
                                                            color: 'rgb(87, 89, 98)'
                                                        }}
                                                    >{item.businessAccountShortName}</h3>
                                                </div>
                                            </Col>

                                        </>
                                        :
                                        null
                                }
                                <Col style={{marginBottom: '10px'}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{marginRight: '30px'}}>
                                            <div style={{marginBottom: '20px', marginTop: '10px'}}>
                                                Отправка уведомлений о событиях компании
                                            </div>
                                            <div style={{marginBottom: '20px',}}>
                                                <Switch
                                                    checked={item.enabled}
                                                    onClick={() => this.settingsBusinessAccounts(undefined, num, item, 'enabled')}
                                                    className="user-profile-radio"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {
                                    item.enabled ? (
                                        <>
                                            <Col className={'rr-form-item user-profile-col-model'}>
                                                Бизнес процессы, по которым будут отправляться уведомления
                                                <div style={{marginTop: '5px'}}>
                                                    <Radio.Group disabled={!item.enabled}
                                                                 onChange={(e) => this.settingsBusinessAccounts(e, num, item, 'projectFilter')}
                                                                 value={item.projectFilter === 'ALL' ? 1 : 2}>
                                                        <Radio className="user-profile-radio"
                                                               value={1}>{localize(LocalizationEnum.ASPECT__GLOBAL__ANY)}</Radio>
                                                        <Radio className="user-profile-radio"
                                                               value={2}>Только назначенные мне</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </Col>
                                            <Col className={' rr-form-item user-profile-col-model'}>
                                                <div>
                                                    {
                                                        item.eventFilter ?
                                                            <div style={{marginTop: '8px'}}>
                                                                <Form.Item
                                                                    label={'События, при возникновении которых будут отправляться уведомления'}
                                                                    hasFeedback>
                                                                    {getFieldDecorator(num.toString(), {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__VALIDATION__FIELD_IS_EMPTY),
                                                                            }
                                                                        ],
                                                                        initialValue: this.localization(item.eventFilter)
                                                                    })(
                                                                        <Select
                                                                            disabled={!item.enabled}
                                                                            mode="multiple"
                                                                            style={{width: '100%'}}
                                                                            defaultActiveFirstOption={false}
                                                                            placeholder={localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__PLACEHOLDER__CHOOSE_TARGET_EVENT_TYPES)}
                                                                            onChange={(e) => this.onchangeBaCustomizationsEvents(e, item.businessAccountId)}
                                                                            suffixIcon={<></>}
                                                                        >
                                                                            {
                                                                                this.getLocalEventDopArr(item.eventFilter)
                                                                            }
                                                                        </Select>
                                                                    )}
                                                                </Form.Item>

                                                            </div> : <div style={{height: '5px'}}></div>
                                                    }
                                                </div>
                                            </Col>
                                        </>
                                    ) : null}
                                <div className={`user-profile-border-model-small-padding ${lastBorder} `}></div>
                            </Row>
                        </Card>
                    </div>
                );
            } else {
                return (
                    <div key={item.businessAccountId}>
                        <Card key={item.businessAccountId} bodyStyle={{
                            padding: `${this.state.baCustomizationsCompany.length === 0 ? '14px 40px' : '0px 40px 14px 40px'}`,
                            minWidth: '800px',
                            borderBottom: '1px solid #e4e5ec'
                        }}>
                            <>
                                <Col style={{marginBottom: '10px', marginTop: '20px'}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{marginRight: '30px'}}>
                                            <div style={{marginBottom: '20px', marginTop: '10px'}}>
                                                {localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__SEND_NOTOFICATIONS)}
                                            </div>
                                            <div style={{marginBottom: '20px',}}>
                                                <Switch
                                                    checked={item.enabled}
                                                    disabled={true}
                                                    className="user-profile-radio"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>


                                <Col className={'rr-form-item user-profile-col-model'}>
                                    {localize(LocalizationEnum.ASPECT__MAIN_MENU__PROJECTS)}
                                    <div style={{marginTop: '5px'}}>
                                        <Radio.Group disabled={true}
                                                     value={item.projectFilter === 'ALL' ? 1 : 2}>
                                            <Radio className="user-profile-radio"
                                                   value={1}>{localize(LocalizationEnum.ASPECT__GLOBAL__ANY)}</Radio>
                                            <Radio className="user-profile-radio"
                                                   value={2}>{localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__ASSIGNED_TO_ME)}</Radio>
                                        </Radio.Group>
                                    </div>
                                </Col>
                                <Col className={' rr-form-item user-profile-col-model'}>
                                    <div>
                                        {
                                            item.eventFilter ?
                                                <div style={{marginTop: '8px'}}>

                                                    <Select
                                                        disabled={true}
                                                        mode="multiple"
                                                        style={{width: '100%'}}
                                                        defaultActiveFirstOption={false}
                                                        placeholder={localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__PLACEHOLDER__CHOOSE_TARGET_EVENT_TYPES)}
                                                        value={this.getStringNameEvent(this.state.baCustomizationsCompany[0].eventFilter)}
                                                    >
                                                    </Select>
                                                </div> : <div style={{height: '5px'}}></div>
                                        }
                                    </div>
                                </Col>
                            </>
                        </Card>
                    </div>
                );
            }
        });

        return (
            <>
                {
                    this.state.err ?
                        <Alert
                            message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                            description={(
                                <>
                                    <div style={{fontWeight: 700}}> {this.state.err.title}</div>
                                    {this.state.err.message}
                                </>
                            )}
                            type="error"
                            showIcon
                            closable
                            style={{marginTop: '8px', marginBottom: '36px', marginLeft: '10px', marginRight: '10px'}}
                        /> : null
                }
                <Form onSubmit={this.handleSubmit} style={{margin: 0}}>
                    {
                        this.state.baCustomizationsCompany.length === 0 ?
                            <div style={{display: 'flex', justifyContent: 'center',}} className="form-notification">
                                <div>
                                    <div className='rr-value user-profile-header-model'>
                                        <h3 style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            color: '#575962',
                                            borderBottom: '1px solid #ebedf2',
                                            paddingLeft: '40px',
                                            paddingBottom: '19px'
                                        }}>
                                            {localize(LocalizationEnum.PAGE__USER_PROFILE__TAB__NOTIFICATIONS__BLOCK__GLOBAL_SETTINGS)}</h3>
                                    </div>

                                    <Card bodyStyle={{
                                        minWidth: '800px',
                                        margin: '0px',
                                        padding: '0px 40px',
                                        marginTop: '-6px',
                                        borderBottom: '1px solid #ebedf2',
                                        paddingBottom: '22px'
                                    }}>
                                        <Row>
                                            <Col>
                                                <div style={{marginBottom: '16px', marginTop: '20px'}}>
                                                    Разрешить отправку уведомлений
                                                </div>
                                                <div style={{marginBottom: '24px'}}>
                                                    <Switch checked={this.state.globalEnabled}
                                                            onClick={this.globalEnabled}/>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div style={{marginBottom: '16px'}}>
                                                    Отправка уведомлений о собственных действиях
                                                </div>
                                                <div style={{marginBottom: '8px'}}>
                                                    <Switch checked={this.state.globalShowOwnActions}
                                                            onClick={this.globalShowOwnActions}
                                                            className="user-profile-radio"/>
                                                </div>
                                            </Col>
                                            <div className="user-profile-border-model-middle"></div>
                                        </Row>
                                    </Card>

                                    <div className='rr-value user-profile-header-model'
                                         style={{borderBottom: '1px solid #e4e5ec',}}>
                                        <h3 className="user-profile-h4" style={{
                                            fontSize: '18px',
                                            padding: '20px 40px',
                                            marginBottom: '0px',
                                            color: '#575962',
                                            fontWeight: 700
                                        }}>{localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__NOTIFICATIONS_SETTINGS_BY_BUSINESS_ACCOUNT)}</h3>
                                    </div>
                                    {viewSettingBusinessAccounts}
                                    <div className='ant-modal-footer' style={{paddingBottom: '0px'}}>
                                        <Form.Item style={{margin: '0px', padding: '0px'}}>
                                            <Button onClick={() => this.props.isOk(undefined)}
                                                    className={"ant-btn rr-btn-default"}>{localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}</Button>
                                            <Button type="primary" htmlType="submit">
                                                {localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{display: 'flex', justifyContent: 'center',}} className="form-notification">
                                <div>

                                    <Card bodyStyle={{
                                        padding: '0px 40px 14px 40px',
                                        minWidth: 800,
                                        borderBottom: '1px solid #e4e5ec',
                                    }}>
                                        <Row className="user-profile-row">
                                            <Col style={this.state.baCustomizationsCompany.length === 0 ? {
                                                paddingTop: 6,
                                                paddingBottom: 6
                                            } : {paddingTop: 0, paddingBottom: 6}}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginBottom: 0,
                                                }}>
                                                    <h3 style={{
                                                        fontSize: 14,
                                                        fontWeight: 700,
                                                        color: '#575962',
                                                        marginBottom: 0
                                                    }}>{this.state.baCustomizationsCompany[0].businessAccountShortName}</h3>
                                                </div>
                                            </Col>
                                            <div
                                                className={`user-profile-border-model-small-padding user-profile-border-model-small`}></div>
                                        </Row>
                                    </Card>

                                    {viewSettingBusinessAccounts}
                                    <div className='ant-modal-footer' style={{paddingBottom: '0px'}}>
                                        <Form.Item style={{margin: '0px', padding: '0px'}}>
                                            <Button onClick={() => this.props.isOk(undefined)}
                                                    className={"ant-btn rr-btn-default"}>{localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}</Button>
                                            <Button type="primary" htmlType="submit">
                                                {localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                    }
                </Form>

            </>
        );
    }
}

export default Form.create<Props>({name: 'register'})(UserProfileNotificationsPopover);

import { Icon, Switch } from 'antd';
import React from 'react';
import { IntlShape } from 'react-intl';
import { showConfirmNew } from '../../components/confirm/showConfirm';
import { joinClasses } from './utils';
import { IconSyncSolid } from '../../components/icons';
import { getStore, getStoreState } from '../../../index';
import { updateRecalculateShiftsCount } from '../reducers/userSettings/userSettings.reducer';

export const showActualizeDatesConfirm = async (
    intl: IntlShape,
    message,
    operationType: 'rent' | 'return' | 'subrentAcceptShipment' | 'subrentReturnToShipper'
): Promise<{ setActualDate: boolean; recalculateShiftCount: boolean }> => {
    let recalculateShiftCount = getStoreState().userSettings.recalculateShiftsCount[operationType] || false;
    let setActualDate = await showConfirmNew({
        intl: intl,
        title: message,
        content: (
            <>
                <Icon
                    style={{ marginRight: 10 }}
                    className={joinClasses('rr-RecalculateShiftsCountButton__icon', 'rr-RecalculateShiftsCountButton__icon__without-hover')}
                    component={IconSyncSolid}
                />
                Пересчитать смены
                <Switch
                    style={{ marginLeft: 12, verticalAlign: 'middle' }}
                    defaultChecked={recalculateShiftCount}
                    onChange={(checked: boolean) => {
                        recalculateShiftCount = checked;
                        getStore().dispatch(updateRecalculateShiftsCount({ [operationType]: checked }));
                    }}
                />
                <div style={{ fontSize: 12, color: '#a6a8b0', marginTop: 22 }}>
                    Если включено, то при установке текущей даты число смен к оплате будет пересчитано
                </div>
            </>
        ),
        cancelButtonProps: {
            icon: 'crosshairs',
            className: 'rr-btn-default rr-btn-confirm-actualize-dates',
        },
        okText: 'Пропустить',
        cancelText: 'Установить текущую',
    });
    return {
        setActualDate,
        recalculateShiftCount,
    };
};

import _ from 'lodash';
import { useDisplayItems } from './useDisplayItems';

const suppressedDisplayItemsIds: string[] = ['shipper', 'consignee'];

export const useDocumentPopoverWarnings = (displayItems: ReturnType<typeof useDisplayItems>): string[] => {
    const missingRequiredDisplayItems = _.filter(
        displayItems,
        (item) => item.value == null && (!item.id || !suppressedDisplayItemsIds.includes(item.id))
    );
    const missingLabels = missingRequiredDisplayItems.map(({ label }) => label);

    const documentPopoverWarnings: string[] = [];

    if (missingLabels.length) {
        documentPopoverWarnings.push(
            `Пол${missingLabels.length > 1 ? 'я' : 'е'} не заполнен${missingLabels.length > 1 ? 'ы' : 'о'}: ${missingLabels
                .slice(0, 3)
                .join(', ')}${missingLabels.length > 3 ? ` и ещё ${missingLabels.length - 3}` : ''}`
        );
    }

    return documentPopoverWarnings;
};

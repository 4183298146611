import { TimelineGroupBase } from 'react-calendar-timeline';
import { useMemo } from 'react';
import { CalendarGroup } from '../types/items';
import { CalendarLineUtils } from '../../CalendarItemRenderer/utils/calendarLineUtils';

export const useGroups = <Group extends TimelineGroupBase>(items: any[], calendarStyle: 'normal' | 'compact'): CalendarGroup[] => {
    return useMemo(() => {
        if (items.length === 0) return [{ id: 1000, title: `group_end`, height: 1, groupProps: { marginTop: '-2px' } }] as (Group & any)[];

        return items.map((item, index) => ({
            id: index + 1,
            itemId: item.id,
            title: ``,
            height: CalendarLineUtils.map.groupHeightFromDisplayType.base[calendarStyle],
        }));
    }, [calendarStyle, items]);
};

import {Tooltip} from "antd";
import React from "react";
import {LocalizationEnum, localize} from "../../localization";
import {TimetableTypeCodeEnum} from "../../server/api";
import {ProductCalendarPopover} from "../../modules/main/dev/OperationFormProductCalendarPopover";

interface IProps {
    availableInstanceCount?: number;
    occupiedInstanceCount?: number;
    underMaintenanceInstanceCount?: number;
    stockInstanceCount?: number;
    productId?: number;
    variantId?: number;
    kitId?:number;
    //instance?:boolean;
    //instanceVariant?: boolean;
    instanceId?: number;
}

/**
 * Блок со счетчиками продукта (Для карточки продукта и поповера варианта)
 * @param props
 * @constructor
 */
export const ProductCountsBlock = (props:IProps) => {
    let {availableInstanceCount, occupiedInstanceCount, underMaintenanceInstanceCount, stockInstanceCount} = props;

    let data: {
        color:string;
        value:  number | undefined;
        value2?: number | undefined | string | React.ReactNode;
        name: React.ReactElement;
        typeCode: TimetableTypeCodeEnum;
    }[] = [];

    const getValue = (instanceCount:number | undefined) => {
        let value;
        if (!instanceCount || instanceCount === 0) {
            value = '-';
        } else if (instanceCount === 1) {
            value = <strong>✓</strong>;
        } else {
            value = instanceCount;
        }
        return value;
    };

    if (props.kitId) {
        data = [
            {
                color: '#34bfa3',
                value: availableInstanceCount,
                name: localize(LocalizationEnum.PAGE__PRODUCTS__CARD__AVAILABLE_NEUTER_COUNT),
                typeCode: TimetableTypeCodeEnum.AVAILABLE
            },
        ];
    } else if (!!props.instanceId) {
        data = [
            {
                color: '#34bfa3',
                value: availableInstanceCount,
                value2: getValue(availableInstanceCount),
                name: localize(LocalizationEnum.PAGE__PRODUCTS__CARD__AVAILABLE_NEUTER_COUNT),
                typeCode: TimetableTypeCodeEnum.AVAILABLE
            },
            {
                color: '#FFB822',
                value: occupiedInstanceCount,
                value2: getValue(occupiedInstanceCount),
                name: localize(LocalizationEnum.PAGE__PRODUCTS__CARD__NOT_AVAILABLE_COUNT),
                typeCode: TimetableTypeCodeEnum.OCCUPIED},
            {
                color: '#f4516c',
                value: underMaintenanceInstanceCount,
                value2: getValue(underMaintenanceInstanceCount),
                name: localize(LocalizationEnum.ASPECT__RENT_BUSINESS__UNDER_MAINTENANCE),
                typeCode: TimetableTypeCodeEnum.MAINTENANCE
            }
        ];
    }
    else {
        data = [
            {
                color: '#34bfa3',
                value: availableInstanceCount,
                name: localize(LocalizationEnum.PAGE__PRODUCTS__CARD__AVAILABLE_NEUTER_COUNT),
                typeCode: TimetableTypeCodeEnum.AVAILABLE
            },
            {
                color: '#FFB822',
                value: occupiedInstanceCount,
                name: localize(LocalizationEnum.PAGE__PRODUCTS__CARD__NOT_AVAILABLE_COUNT),
                typeCode: TimetableTypeCodeEnum.OCCUPIED
            },
            {
                color: '#f4516c',
                value: underMaintenanceInstanceCount,
                name: localize(LocalizationEnum.ASPECT__RENT_BUSINESS__UNDER_MAINTENANCE),
                typeCode: TimetableTypeCodeEnum.MAINTENANCE
            }
        ];
    }

    return (
        <>
            {
                data.map((item, index) => (
                    item.value !== undefined ?
                        <ProductCalendarPopover
                            kitId={props.kitId}
                            variantId={props.variantId}
                            productId={props.productId}
                            typeCode={item.typeCode}
                            key={index}
                            instanceId={props.instanceId}
                            >
                            {
                                () => <Tooltip key={index} title={<>{item.name} <b>{item.value}</b> {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)} <b>{item.value2 ? item.value : stockInstanceCount}</b></>}>
                                    <span className={'rr-vvv'} style={{backgroundColor: item.color}}>{item.value2 ? item.value2 : item.value}</span>
                                </Tooltip>
                            }
                        </ProductCalendarPopover>
                         : null
                    )
                )
            }
        </>
    );
};

import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'antd';
import { IconAngleDown, IconAngleUp } from '../../../../../../../../../../../components/icons';
import { CalendarDisplayType } from '../../../../../../../Calendar/utils/data/calendarConstants';
import { CalendarItemGroup } from '../../../../../../../Calendar/types/items';

interface CalendarGroupItemContainerProps {
    displayType: CalendarDisplayType;
    item: CalendarItemGroup;
}

export const CalendarGroupItemContainer: FC<CalendarGroupItemContainerProps> = (props) => {
    const { displayType, item, children } = props;

    return (
        <div className={classNames('rct-item-content', 'calendar-group-item-content')}>
            <Icon
                component={item.isCollapsed ? IconAngleUp : IconAngleDown}
                className={classNames('calendar-group-item-collapse-icon', {
                    normal: displayType === CalendarDisplayType.NORMAL,
                    compact: displayType === CalendarDisplayType.COMPACT,
                    unfold: item.isCollapsed,
                })}
            />
            {children}
        </div>
    );
};

import { useEffect } from 'react';

export const useScrollEffect = (
    eventFunction: (event: globalThis.KeyboardEvent) => void,
    element: Document | Element | undefined = document,
    options?: boolean | AddEventListenerOptions
) => {
    useEffect(() => {
        if (element) {
            element.addEventListener('scroll', eventFunction as EventListenerOrEventListenerObject, options);
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', eventFunction as EventListenerOrEventListenerObject, options);
            }
        };
    }, [element, eventFunction, options]);
};

import { ServerUtils } from '../../../../core/utils/serverUtils';
import { WorkPlanningsRecord, WorkPlanningsStateCodeEnum } from '../../../../server';
import { ListWorkPlanningsArgs } from './workPlannings.api.types';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../components/page/entityGrid/types/api';
import { workPlanningsAllStateCodes } from '../data/workPlanningsData';

export abstract class WorkPlanningsUtils {
    static createRequestFilters = (params: ListWorkPlanningsArgs['params'], projectId?: number) => {
        return ServerUtils.createRequestFilters<WorkPlanningsRecord>([
            params.projectId ? ['projectId', 'EQ', params.projectId] : undefined,
            params.stateCode ? ['stateCode', 'IN', params.stateCode] : undefined,
            params.professionId ? ['professionId', 'EQ', params.professionId] : undefined,
            params.hideCancelled
                ? ['stateCode', 'IN', workPlanningsAllStateCodes.filter((stateCode) => stateCode !== WorkPlanningsStateCodeEnum.CANCELED)]
                : undefined,

            // Доп фильтры
            params.crewMemberIds
                ? ['crewMemberIds', 'IN', Array.isArray(params.crewMemberIds) ? params.crewMemberIds : [params.crewMemberIds]]
                : undefined,
            params.startDate?.[0] ? ['startDate', 'GE', params.startDate[0].valueOf()] : undefined,
            params.startDate?.[1] ? ['startDate', 'LE', params.startDate[1].valueOf()] : undefined,
            params.endDate?.[0] ? ['endDate', 'GE', params.endDate[0].valueOf()] : undefined,
            params.endDate?.[1] ? ['endDate', 'LE', params.endDate[1].valueOf()] : undefined,
            params.shiftCount?.[0] ? ['shiftCount', 'GE', params.shiftCount[0]] : undefined,
            params.shiftCount?.[1] ? ['shiftCount', 'LE', params.shiftCount[1]] : undefined,
            params.numberOfCrew?.[0] ? ['numberOfCrew', 'GE', params.numberOfCrew[0]] : undefined,
            params.numberOfCrew?.[1] ? ['numberOfCrew', 'LE', params.numberOfCrew[1]] : undefined,
            params.effectivePrice?.[0] ? ['effectivePrice', 'GE', params.effectivePrice[0] * 100] : undefined,
            params.effectivePrice?.[1] ? ['effectivePrice', 'LE', params.effectivePrice[1] * 100] : undefined,
            params.finalTotalPrice?.[0] ? ['finalTotalPrice', 'GE', params.finalTotalPrice[0] * 100] : undefined,
            params.finalTotalPrice?.[1] ? ['finalTotalPrice', 'LE', params.finalTotalPrice[1] * 100] : undefined,
            projectId != null ? ['projectId', 'EQ', projectId] : undefined,
        ]);
    };

    static pricePerShiftIntervalMetricsMap: IntervalFiltersMetricsMap<any> = {
        // WorkPlanningsRecord TODO
        effectivePrice: (value) => value / 100,
        finalTotalPrice: (value) => value / 100,
        numberOfCrew: (value) => value,
        shiftCount: (value) => value,
    };
    static pricePerShiftIntervalFormatMap: IntervalFiltersFormatsMap<WorkPlanningsRecord> = {
        effectivePrice: 'currency',
        finalTotalPrice: 'currency',
        shiftCount: 'number',
        numberOfCrew: 'number',
    };
}

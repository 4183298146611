import { isNumber } from '../../shared/util/isNumber';

export const inputNumberParser = (displayValue: string | undefined): number | string => {
    let newV = displayValue || '';
    newV = newV.replace(/[^\d\,\.-]+/g, ''); // Удаляем всё кроме цифр, запятой точки и минуса
    newV = newV.replaceAll(',', '.'); // Заменяем запятые на точки
    if (newV.indexOf('.-') === 0) newV = newV.replace('.', ''); // Если перед минусом есть точка, удаляем ее
    newV = newV.replaceAll('-', (match, p1) => (p1 > 0 ? '' : match)); // Удаляем все минусы кроме первого
    const firstEntry = newV.indexOf('.'); // Вхождение первой точки
    newV = newV.replace(/\./g, (match, p1) => (p1 > firstEntry ? '' : '.')); // Удаляем все кроме первой точки

    return newV;
};

export const inputNumberPercentFormatter = (value: number | string | undefined): string => {
    return value !== undefined && value !== '' ? `${value}%` : '';
};

export const onInputChange = (value: number | undefined | null | string, onChange?: (value: number | undefined) => void) => {
    // тут могут возвращаться строки числа undefined и null
    if (onChange) {
        if (isNumber(value) && value !== '') onChange(value === null ? undefined : value !== undefined ? +value : undefined); // тут возвращаем число или undefined
    }
};

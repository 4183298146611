import React, {CSSProperties, FC, useCallback} from 'react';
import {Icon} from "antd";
import {AxiosResponse} from "axios";
import classNames from "classnames";
import {NomenclatureEntityTypeCodeEnum, serverApi} from "../../server";
import {useAppSelector} from "../../store/hooks";
import {businessAccountIdSelector} from "../../shared/reducers/system.reducer";
import {getCurrentTimezoneUTCOffset} from "../../utils/timeUtils/getCurrentTimezoneUTCOffset";
import {pdfDataToPrint} from "../../shared/util/downloadDataFromExcel";
import {showNotification} from "../notification/showNotification";
import {IconBarcodeSolid} from "../icons";
import './barcodePrint.less';

interface BarcodePrintProps {
    nomenclatureType: NomenclatureEntityTypeCodeEnum;
    nomenclatureId: number;
    style?: CSSProperties;
}

export const BarcodePrint:FC<BarcodePrintProps> = ({nomenclatureType, nomenclatureId, style}) => {

    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const defaultBadgeTemplate = useAppSelector((state) => state.businessAccountPreferences.preferences?.defaultBadgeTemplate);
    const downloadDisabled = !defaultBadgeTemplate;

    const onDownloadButtonClick = useCallback(async () => {
        try{
            const res:AxiosResponse<Blob> = await serverApi.generateBadgePdf(
                businessAccountId,
                nomenclatureType,
                nomenclatureId,
                getCurrentTimezoneUTCOffset(),
                { responseType: 'blob' }
            );
            pdfDataToPrint(res.data);
        }catch(error){
            showNotification('error', 'Ошибка');
        }
    }, [businessAccountId, nomenclatureType, nomenclatureId]);

    const componentName = `rr-barcode-print`;

    return (
        <Icon
            className={classNames(`${componentName}-icon`, downloadDisabled && `${componentName}-icon-disabled`)}
            component={IconBarcodeSolid}
            style={style}
            onClick={!downloadDisabled ? (e)=>{
                e.stopPropagation();
                onDownloadButtonClick();
            } : undefined}
        />
    );
};

import moment from 'moment/moment';
import { NestedRentElementRecord, RentElementRecord } from '../../../../../../../server';
import { CalendarItem, ElementsCalendarItem } from '../../../../components/Calendar/types/items';

export const isGiveawayDelayProblemForRentElement = (record: RentElementRecord) => {
    return (
        (record.commonFields.problemsAndWarnings.returnDelay || record.commonFields.problemsAndWarnings.subrentReturnToShipperDelay) &&
        +moment(record.commonFields.rentTerms.rentPeriodEndDate) < +moment()
    );
};

export const isGiveawayDelayProblemForNestedRentElement = (record: NestedRentElementRecord) => {
    return (
        (record.problemsAndWarnings.returnDelay || record.problemsAndWarnings.subrentReturnToShipperDelay) &&
        +moment(record.rentTerms.rentPeriodEndDate) < +moment()
    );
};

export const isGiveawayDelayProblemForItemElement = (item: CalendarItem<ElementsCalendarItem>) => {
    return (
        (item.problemsAndWarnings.returnDelay || item.problemsAndWarnings.subrentReturnToShipperDelay) &&
        +moment(item.rentTerms.rentPeriodEndDate) < +moment()
    );
};

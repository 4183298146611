import React, { FC, useEffect } from 'react';
import { PopoverHeader } from '../../../../../../components/gridRowPopover/PopoverHeader';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Tabs } from 'antd';
import { Styles } from '../../../../../../styles';
import { IconShapesSolid } from '../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { VariantInfoRead } from '../../../../../../server';
import { CurrentIndicatorsProblemIcons } from '../../../../../../components/currentRentIndicators/currentIndicatorsProblemIcons';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { EntityActionType } from '../../../../../../../index';
import { showConfirm2 } from '../../../../../../components/confirm/showConfirm';
import { useGetProductVariantByIdQuery } from '../../api/productVariants.api';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import { deleteEntities, setArchiveState } from '../../reducers/product-variants.reducer';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { ProductVariantPopoverContentFeaturesTab } from './productVariantPopoverContentFeaturesTab';
import { ProductVariantPopoverContentSubrentTab } from './productVariantPopoverContentSubrentTab';
import { ProductVariantPopoverContentRentTab } from './productVariantPopoverContentRentTab';
import { ProductVariantPopoverContentDescriptionTab } from './productVariantPopoverContentDescriptionTab';
import { ProductVariantPopoverTabsEnum } from '../../../../../../shared/constants/tabEnums';

interface ProductVariantPopoverContentProps {
    productId: number;
    variantId: number;
    onClose: () => void;
    onLinkClick: () => void;
    onCloseButtonClick?: () => void;
}

export const ProductVariantPopoverContent: FC<ProductVariantPopoverContentProps> = (props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();

    const { data: entity, error } = useGetProductVariantByIdQuery(
        { businessAccountId, productId: props.productId, id: props.variantId },
        { refetchOnMountOrArgChange: true } // TODO сделать норм инвалидацию кэша, что б какждый раз не делался запрос
    );

    useEffect(() => {
        if (error) {
            showNotification('error', 'Не удалось загрузить вариант');
            // Тут если ошибка, то остаются спиннеры крутящиеся, по этому пока скрываю поповер при ошибке
            props.onClose?.();
        }
    }, [error, props.onClose]);

    const onLinkClicked = () => {
        props.onLinkClick?.();
    };

    const goToProduct = () => {
        props.onLinkClick?.();
    };

    const onAction = async (item: VariantInfoRead, action: EntityActionType) => {
        if (entity) {
            if (action === 'addInstance') {
                dispatch(push(`/${businessAccountId}/inventory/products/${entity.productId}/addInst?tab=variants&variantId=${entity.id}`));
            }
            onLinkClicked();
            if (action === 'edit') {
                dispatch(push(`/${businessAccountId}/inventory/products/${entity.productId}?tab=variants&mode=edit&id=${entity.id}`));
            } else if (action === 'archive') {
                dispatch(push(`/${businessAccountId}/inventory/products/${entity.productId}?tab=variants`));
                if (entity ? entity.productId : undefined)
                    dispatch(
                        setArchiveState(intl, businessAccountId, entity.productId, [
                            {
                                id: item.id,
                                businessVersion: item.businessVersion,
                                archive: !entity.archive,
                            },
                        ])
                    );
            } else if (action === 'setQuantity') {
                dispatch(push(`/${businessAccountId}/inventory/products/${entity.productId}?tab=variants`));
                let editPath = getPathFromState(location.pathname, location.search, {
                    mode: 'edit-count',
                    id: item.id,
                });
                dispatch(push(editPath));
            } else if (action === 'delete') {
                let yes = await showConfirm2({ intl: intl, title: 'Удалить вариант и все его экземпляры безвозвратно?' });
                if (yes) {
                    dispatch(
                        deleteEntities(intl, businessAccountId, entity.productId, [
                            { id: entity.id, businessVersion: entity.businessVersion },
                        ])
                    );
                }
            } else if (action === 'copy') {
                const { productId, id: variantId } = item;
                dispatch(push(`/${businessAccountId}/inventory/products/${productId}/?tab=variants&mode=copy&id=${variantId}`));
            }
        }
    };

    let returnDelayCount = 0,
        bookShortageCount = 0,
        giveawayDelayCount = 0,
        orderShortageCount = 0,
        subrentShipmentDelayCount = 0,
        subrentReturnToShipperDelayCount = 0;

    let typeProblemColor: string | undefined;
    let typeSubrentProblemColor: string | undefined;

    if (entity) {
        returnDelayCount = entity.rentIndicators.problemsAndWarnings.returnDelay.elementsCount;
        bookShortageCount = entity.rentIndicators.problemsAndWarnings.bookShortage.elementsCount;
        giveawayDelayCount = entity.rentIndicators.problemsAndWarnings.giveawayDelay.elementsCount;
        orderShortageCount = entity.rentIndicators.problemsAndWarnings.orderShortage.elementsCount;
        subrentShipmentDelayCount = entity.rentIndicators.problemsAndWarnings.subrentShipmentDelay.elementsCount;
        subrentReturnToShipperDelayCount = entity.rentIndicators.problemsAndWarnings.subrentReturnToShipperDelay.elementsCount;

        if (returnDelayCount || bookShortageCount) {
            typeProblemColor = '#F4516c';
        } else if (giveawayDelayCount || orderShortageCount) {
            typeProblemColor = '#FC8C2F';
        }

        if (entity.rentIndicators?.problemsAndWarnings.subrentShipmentDelay.elementsCount) {
            typeSubrentProblemColor = '#F4516c';
        } else if (entity.rentIndicators?.problemsAndWarnings.subrentReturnToShipperDelay.elementsCount) {
            typeSubrentProblemColor = '#FC8C2F';
        }
    }

    return (
        <div>
            {entity ? (
                <PopoverHeader
                    businessAccountId={businessAccountId}
                    title={
                        <>
                            <Link to={`/${businessAccountId}/inventory/products/${entity ? entity.productId : undefined}`}>
                                <div style={{ display: 'flex', maxWidth: '450px' }} onClick={() => goToProduct()}>
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {entity.productShortName}
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        : {entity ? entity.name : undefined}
                                    </span>
                                </div>
                            </Link>
                        </>
                    }
                    closeCb={() => {
                        props.onClose();
                        props.onCloseButtonClick?.();
                    }}
                    entityType={'variant'}
                    entity={entity}
                    onAction={onAction}
                    closeElementPopup={props.onLinkClick || (() => {})}
                />
            ) : (
                <div className={'rr-grid-actions-popover-header'} style={{ display: 'flex' }}>
                    <Icon style={{ marginLeft: 4, marginRight: 10 }} className={Styles.ICON_26} component={IconShapesSolid} />
                </div>
            )}
            <div className={`tab-popap-instance tab-popap-variant`}>
                <div className="rr-rent-indicators">
                    <Tabs type="line">
                        <Tabs.TabPane
                            tab={
                                <div
                                    style={{
                                        height: '60px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                </div>
                            }
                            key={ProductVariantPopoverTabsEnum.DESCRIPTION}
                        >
                            <ProductVariantPopoverContentDescriptionTab entity={entity} onLinkClick={props.onLinkClick} />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            tab={
                                returnDelayCount || bookShortageCount || giveawayDelayCount || orderShortageCount ? (
                                    <div
                                        style={{
                                            color: typeProblemColor,
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CurrentIndicatorsProblemIcons
                                            problemsAndWarnings={entity?.rentIndicators.problemsAndWarnings}
                                            entityType={'variant'}
                                            typeRent={'rent'}
                                            id={entity?.id}
                                            entity={this}
                                            isPopup={true}
                                        />
                                        <span>Аренда</span>
                                    </div>
                                ) : (
                                    <span style={{ height: '60px', display: 'flex', alignItems: 'center' }}>Аренда</span>
                                )
                            }
                            key={ProductVariantPopoverTabsEnum.RENT}
                        >
                            <ProductVariantPopoverContentRentTab entity={entity} />
                        </Tabs.TabPane>
                        {subrentModuleEnabled && (
                            <Tabs.TabPane
                                tab={
                                    subrentShipmentDelayCount || subrentReturnToShipperDelayCount ? (
                                        <div
                                            style={{
                                                color: typeSubrentProblemColor,
                                                height: '60px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CurrentIndicatorsProblemIcons
                                                problemsAndWarnings={entity?.rentIndicators.problemsAndWarnings}
                                                entityType={'variant'}
                                                typeRent={'subrent'}
                                                id={entity?.id}
                                                entity={entity}
                                                isPopup={true}
                                            />
                                            <span>Субаренда</span>
                                        </div>
                                    ) : (
                                        <span style={{ height: '60px', display: 'flex', alignItems: 'center' }}>Субаренда</span>
                                    )
                                }
                                key={ProductVariantPopoverTabsEnum.SUBRENT}
                            >
                                <ProductVariantPopoverContentSubrentTab entity={entity} />
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane
                            tab={
                                <div
                                    style={{
                                        height: 60,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {localize(LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FEATURES)}
                                </div>
                            }
                            key={ProductVariantPopoverTabsEnum.FEATURES}
                            style={{ overflow: 'auto' }}
                        >
                            <ProductVariantPopoverContentFeaturesTab entity={entity} onLinkClick={props.onLinkClick} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

import { DeepKeys } from '@tanstack/react-table';

export type AllAvailableKeys<T extends object> = T extends object
    ? {
          [K in keyof T]-?: K | (T[K] extends (infer U | undefined) & object ? `${K & string}.${keyof U & string}` : never);
      }[keyof T]
    : never;

type EQFilter<T extends object> = [DeepKeys<T>, 'EQ', string | number | boolean];
type GEFilter<T extends object> = [DeepKeys<T>, 'GE', number];
type LEFilter<T extends object> = [DeepKeys<T>, 'LE', number];
type INFilter<T extends object> = [DeepKeys<T>, 'IN', (string | number)[]];

export class ServerUtils {
    static createRequestFilters<T extends object>(
        filters: (EQFilter<T> | GEFilter<T> | LEFilter<T> | INFilter<T> | null | undefined | 0 | false)[]
    ) {
        return filters
            .filter((filter): filter is EQFilter<T> | GEFilter<T> | LEFilter<T> | INFilter<T> => Boolean(filter))
            .map((filter) => {
                if (!filter) return '';

                const filterValue = Array.isArray(filter[2])
                    ? filter[2].join(';')
                    : typeof filter[2] === 'number' && (filter[1] === 'GE' || filter[1] === 'LE')
                    ? Math.round(filter[2])
                    : filter[2];

                return `${String(filter[0])};${filter[1]};${filterValue}`;
            });
    }
}

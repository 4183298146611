import React from 'react';
import {Button} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import classNames from "classnames";
import './linkButton.less';

interface AddButtonProps {
    disabled?: ButtonProps['disabled'];
    style?: ButtonProps['style'];
    className?: ButtonProps['className'];
    onClick?: ButtonProps['onClick'];
    children?: ButtonProps['children'];
    block?: ButtonProps['block'];
}

export const LinkButton = ({style, className, disabled, onClick, children, block}: AddButtonProps) => {
    return (
        <Button type={'link'} block={block} style={style} className={classNames('rr-link-btn', className)} disabled={disabled}
                onClick={onClick}>
            {children}
        </Button>
    );
};

export const isActionAvailable = <
    EntityRecord extends {
        availableTransitionCodes?: TransitionCodeEnum[];
    },
    Action,
    TransitionCodeEnum
>(
    records: EntityRecord[] | EntityRecord | undefined,
    code: TransitionCodeEnum,
    action: Action
): Action | undefined => {
    if (Array.isArray(records)) return records.every((item) => item.availableTransitionCodes?.includes(code)) ? action : undefined;
    else return records && records.availableTransitionCodes?.includes(code) ? action : undefined;
};

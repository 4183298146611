import {FAILURE, REQUEST, SUCCESS} from './action-type.util';
import {BusinessAccountIndicatorsObj, serverApi, ServerError} from "../../server";
import {getServerError} from "../util/utils";
import {IRootState} from "./index";

export const ACTION_TYPES = {
    GET_BUSINESS_ACCOUNT_INDICATORS: 'businessAccountIndicators/GET_BUSINESS_ACCOUNT_INDICATORS',
    RESET_BUSINESS_ACCOUNT_INDICATORS: 'businessAccountIndicators/RESET_BUSINESS_ACCOUNT_INDICATORS',
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    loadingLastTime: undefined as (undefined | Date),
    indicators: undefined as (BusinessAccountIndicatorsObj | undefined)
};

export type BusinessAccountIndicatorsState = Readonly<typeof initialState>;

// Reducer

export default (state: BusinessAccountIndicatorsState = initialState, action): BusinessAccountIndicatorsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_BUSINESS_ACCOUNT_INDICATORS):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
                loadingLastTime: new Date()
            };

        case FAILURE(ACTION_TYPES.GET_BUSINESS_ACCOUNT_INDICATORS):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
                loadingLastTime: undefined
            };

        case SUCCESS(ACTION_TYPES.GET_BUSINESS_ACCOUNT_INDICATORS):
            return {
                ...state,
                indicators: action.payload.data,
                loading: false,
                loadingLastTime: new Date()
            };

        case ACTION_TYPES.RESET_BUSINESS_ACCOUNT_INDICATORS:
            return {
                ...state,
                loadingLastTime: undefined
            };

        default:
            return state;
    }
};

// Actions

// Получение индикаторов БА
export const getBusinessAccountIndicators = (businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: BusinessAccountIndicatorsState = (getState() as IRootState).businessAccountIndicators;
        if (!state.loadingLastTime || Date.now() > state.loadingLastTime.getTime() + 10 * 1000) {
            return dispatch({
                type: ACTION_TYPES.GET_BUSINESS_ACCOUNT_INDICATORS,
                payload: serverApi.getBusinessAccountIndicators(businessAccountId)
            });
        }
    };
};

export const resetBusinessAccountIndicators = () => ({
    type: ACTION_TYPES.RESET_BUSINESS_ACCOUNT_INDICATORS
});

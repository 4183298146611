const getParamsLink = (entity, typeOperation: 'project' | 'operations' | 'renters' | 'product' | 'popover'):string =>  {
    let projId, rentId;
    if (entity) {
        if (typeOperation === 'project') {
            projId = entity.id;
            rentId = entity.renterId;
            return `?renterId=${rentId}&projectId=${projId}`;
        } else if (typeOperation === 'operations') {
            projId = entity.projectId;
            rentId = entity.renterId;
            return `?renterId=${rentId}&projectId=${projId}`;
        }  else if (typeOperation === 'renters') {
            rentId = entity.id;
            return `?renterId=${rentId}&group=project`;
        } else if (typeOperation === 'product') {
            return `?productId=${entity.id}&group=project`;
        } else if (typeOperation === 'popover') {
            projId = entity.projectId;
            rentId = entity.renterId;
            return `?renterId=${rentId}&projectId=${projId}&elementId=${entity.id}`;
        }
    }
    return '';
};

export default getParamsLink;
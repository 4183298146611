import React, {CSSProperties, ReactNode, useCallback, useEffect, useState} from 'react';
import {Icon, Select} from "antd";
import {LocalizationEnum, localize} from "../../../../localization";
import Spin from "../../../../components/spin/spin";
import {InstanceRecord, InstanceRecordList, serverApi, TimetableTypeCodeEnum} from "../../../../server";
import {SelectValue} from "antd/lib/select";
import {AxiosResponse} from "axios";
import {ProductInstancePopover} from "../../inventory/instances/components/productInstancePopover/productInstancePopover";
import {IconClose} from "../../../../components/icons";
import {tableCellProblemRenderer} from "../../../../components/grid/renderers/tableCellProblemRenderer";
import {useSelector} from "react-redux";
import {IRootState} from "../../../../shared/reducers";
import '../../operationForm/operationModule/product-instances-select/ProductInstancesSelect.less';

interface InstanceState {
    disabled: boolean;
    problem: boolean;
    warning: boolean;
    item: InstanceRecord;
    intervals: [number, number][];
    availableInstanceCount: number;
}

export interface InventoryMovementCreateModalInstancesSelectProps {
    onChange?: (value: SelectValue) => void;
    value?: SelectValue;
    dropdownStyle?: CSSProperties;
    productId: number;
    variantId?: number;
    from: Date;
    until: Date;
    instanceIds? :number[];
}

export const InventoryMovementCreateModalInstancesSelect = ({productId, variantId, from, until, ...props}: InventoryMovementCreateModalInstancesSelectProps) => {

    const [instances, setInstances] = useState<InstanceRecord[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState<any>();
    const businessAccountId = useSelector((store:IRootState) => store.system.businessAccountId);

    useEffect(() => {
        // setup
        loadData().then();
        return () => {
            // clean up
        };
    }, []);

    const loadData = useCallback(async () => {
        let request:AxiosResponse<InstanceRecordList>;
        const filters:string[] = [];
        if(props.instanceIds){
            filters.push(`id;IN;${props.instanceIds.join(';')}`);
        }

        if (variantId) {
            request = await serverApi.listProductVariantInstancesOnInterval(
                businessAccountId,
                productId,
                variantId,
                from.getTime(),
                until.getTime(),
                {
                    possibleDelayedRentElementIds: undefined,
                    listFilters: filters
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
            );
        } else {
            request = await serverApi.listProductInstancesOnInterval(
                businessAccountId,
                productId,
                from.getTime(),
                until.getTime(),
                {
                    possibleDelayedRentElementIds: undefined,
                    listFilters: filters
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
            );
        }

        if(request){
            setInstances(request.data.records);
        }

    }, []);

    const onChange = (value: SelectValue) => {
        props.onChange?.(value);
    };

    const getInstancesData = (instances: InstanceRecord[]): InstanceState[] => {
        return instances.map(item => {

            let disabled = false;
            let warning = false;
            let problem = false;

            let availableInstanceCount = 0;
            let intervals: [number, number][] = [];


            //item.stackMapList


            // let timeTable = findTimeTable(this.props.timeTables, {
            //     productId: this.props.productId,
            //     variantId: this.props.variantId,
            //     instanceId: item.id
            // });
            //
            // let yyy = this.props.isSubrentOperation ? -1 : 1;
            //
            // if (timeTable) {
            //     // Карта по этому экземпляру, которая типа в гриде
            //     let map = isOrderOperation(this.props.operationTypeCode) ? timeTable.orderAvailable : timeTable.available1;
            //     intervals = !this.props.projectTemplate ? map : timeTable.stock;
            //     let itemSelected = this.props.value && (this.props.value as number[]).includes(item.id);
            //
            //     let requiredIndent = (this.props.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;
            //
            //     if (!this.props.elementRecord || this.props.copyMode === true) {
            //         // Создание в продуктах или копирование в Инвентаре
            //         let availableIntervals;
            //         if (itemSelected) {
            //             // Вычитаем столько сколько хотим добавить
            //             intervals = ProductUtils.getNewIntervals(intervals, [[yyy * 1, this.props.startDate, this.props.endDate]]);
            //
            //             availableIntervals = ProductUtils.getNewIntervals(intervals, [
            //                 [yyy * 1, this.props.startDate - requiredIndent, this.props.startDate],
            //                 [yyy * 1, this.props.endDate, this.props.endDate + requiredIndent],
            //                 //...ProductUtils.findInstancesInElements(this.props.elements, item.id)
            //             ]);
            //         } else {
            //             availableIntervals = intervals;
            //         }
            //
            //         availableInstanceCount = ProductUtils.getMinimumValueInInterval(availableIntervals/*intervals*/, this.props.startDate - requiredIndent, this.props.endDate + requiredIndent);
            //
            //         if (itemSelected) {
            //             // Экземпляр выбран
            //             if (availableInstanceCount < 0) {
            //                 let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode);
            //                 if (err === OperationErrorCodeEnum.PROBLEM) problem = true;
            //                 else if (err === OperationErrorCodeEnum.WARNING) warning = true;
            //             }
            //         } else {
            //             // Экземпляр не выбран
            //             if (availableInstanceCount <= 0 && !operationWithShortage(this.props.operationTypeCode)) {
            //                 disabled = true;
            //             }
            //         }
            //
            //     } else if (this.props.elementRecord && this.props.elementRecord.id < 0) {
            //         // Редактирование нового
            //         if (this.props.elementRecord.instanceIds?.includes(item.id)) {
            //             intervals = ProductUtils.getNewIntervals(intervals, [
            //                 // Компенсируем что было в начале
            //                 [yyy * -1, this.props.elementRecord.rentPeriodStartDate.getTime(), this.props.elementRecord.rentPeriodEndDate.getTime()],
            //             ]);
            //         }
            //
            //         let availableIntervals;
            //         if (itemSelected) {
            //             // Вычитаем столько сколько хотим добавить
            //             intervals = ProductUtils.getNewIntervals(intervals, [[yyy * 1, this.props.startDate, this.props.endDate]]);
            //
            //             availableIntervals = ProductUtils.getNewIntervals(intervals, [
            //                 [yyy * 1, this.props.startDate - requiredIndent, this.props.startDate],
            //                 [yyy * 1, this.props.endDate, this.props.endDate + requiredIndent],
            //             ]);
            //         } else {
            //             availableIntervals = intervals;
            //         }
            //
            //         availableInstanceCount = ProductUtils.getMinimumValueInInterval(availableIntervals, this.props.startDate - requiredIndent, this.props.endDate + requiredIndent);
            //
            //         if (availableInstanceCount <= 0 && this.props.operationTypeCode !== OperationTypeCodeEnum.ORDER && this.props.operationTypeCode !== OperationTypeCodeEnum.DRAFT && this.props.operationTypeCode !== OperationTypeCodeEnum.CORRECT && !itemSelected){
            //             disabled = true;
            //         }
            //         if (availableInstanceCount < 0 && itemSelected) {
            //             let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode);
            //             if (err === OperationErrorCodeEnum.PROBLEM) problem = true;
            //             else if (err === OperationErrorCodeEnum.WARNING) warning = true;
            //         }
            //
            //         if (isSubrentOperation(this.props.operationTypeCode, this.props.operationTypeCorrectionCode)) {
            //             disabled = false;
            //         }
            //     } else if (this.props.elementRecord.rentPeriodStartDateOriginal && this.props.elementRecord.rentPeriodEndDateOriginal) {
            //         // Редактирование существующего
            //
            //         let xxx = this.props.elementRecord.stateCode === RentStateCodeEnum.BOOKED ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.RENT ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.RETURNED ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.RETURNEDBROKEN ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.LOSTDURINGRENT ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED ||
            //             this.props.elementRecord.stateCode === RentStateCodeEnum.SUBRENT
            //         ;
            //
            //         let isCorrectCancelOperation = this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT && this.props.operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;
            //
            //         intervals = ProductUtils.getNewIntervals(intervals, [
            //             // Компенсируем текущее значение
            //             [yyy * (isCorrectCancelOperation ? 0 : 1) * -(this.props.elementRecord.instanceIds?.includes(item.id) ? 1 : 0), this.props.elementRecord.rentPeriodStartDate.getTime(), this.props.elementRecord.rentPeriodEndDate.getTime()],
            //             // Компенсируем остаток
            //             [yyy * ((xxx || (this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED && isOrderOperation(this.props.operationTypeCorrectionCode || this.props.operationTypeCode))) && this.props.elementRecord.variantId === this.props.elementRecord.variantIdOriginal ? 1 : 0) * -(this.props.elementRecord.instanceIdsOriginal?.includes(item.id) && !this.props.elementRecord.instanceIds?.includes(item.id) ? 1 : 0), this.props.elementRecord.rentPeriodStartDateOriginal.getTime(), this.props.elementRecord.rentPeriodEndDateOriginal.getTime()]
            //         ]);
            //
            //         let availableIntervals;
            //         if (itemSelected) {
            //             intervals = ProductUtils.getNewIntervals(intervals, [
            //                 // Вычитаем текущее значение
            //                 [yyy * (isCorrectCancelOperation ? 0 : 1) * 1, this.props.startDate, this.props.endDate]
            //             ]);
            //
            //             availableIntervals = ProductUtils.getNewIntervals(intervals, [
            //                 [yyy * (isCorrectCancelOperation ? 0 : 1) * 1, this.props.startDate - requiredIndent, this.props.startDate],
            //                 [yyy * (isCorrectCancelOperation ? 0 : 1) * 1, this.props.endDate, this.props.endDate + requiredIndent],
            //                 //...ProductUtils.findInstancesInElements(this.props.elements, item.id, this.props.elementRecord.id)
            //             ]);
            //         } else {
            //             availableIntervals = intervals;
            //         }
            //
            //         // Вычитаем остаток
            //         if(this.props.elementRecord.keepLeftover){
            //             intervals = ProductUtils.getNewIntervals(intervals, [
            //                 [yyy * (xxx && this.props.elementRecord.variantId === this.props.elementRecord.variantIdOriginal ? 1 : 0) * (this.props.elementRecord.instanceIdsOriginal?.includes(item.id) && !itemSelected ? 1 : 0), this.props.elementRecord.rentPeriodStartDateOriginal.getTime(), this.props.elementRecord.rentPeriodEndDateOriginal.getTime()]
            //             ]);
            //         }
            //
            //         availableInstanceCount = ProductUtils.getMinimumValueInInterval(availableIntervals, this.props.startDate - requiredIndent, this.props.endDate + requiredIndent);
            //
            //         if (availableInstanceCount <= 0 && this.props.operationTypeCode !== OperationTypeCodeEnum.ORDER && this.props.operationTypeCode !== OperationTypeCodeEnum.DRAFT && this.props.operationTypeCode !== OperationTypeCodeEnum.CORRECT && !itemSelected){
            //             disabled = true;
            //         }
            //
            //         if (availableInstanceCount < 0 && itemSelected) {
            //             let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode);
            //             if (err === OperationErrorCodeEnum.PROBLEM) problem = true;
            //             else if (err === OperationErrorCodeEnum.WARNING) warning = true;
            //         } else if (availableInstanceCount < 0) {
            //             let err = canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationTypeCorrectionCode);
            //             if (err === OperationErrorCodeEnum.WARNING) disabled = false;
            //         }
            //         if (isSubrentOperation(this.props.operationTypeCode, this.props.operationTypeCorrectionCode)) {
            //             disabled = false;
            //         }
            //         if (warning) disabled = false;
            //
            //         if(this.props.operationTypeCode === OperationTypeCodeEnum.PROLONG && this.props.elementRecord && this.props.elementRecord.instanceIdsOriginal && !this.props.elementRecord.instanceIdsOriginal.includes(item.id)){
            //             disabled = true;
            //         }
            //
            //     }
            // }
            //
            // if (this.props.projectTemplate) {
            //     if (problem || warning) warning = true;
            //     problem = false;
            // }

            return {
                problem,
                warning,
                disabled,
                item,
                intervals,
                availableInstanceCount
            };
        });
    };

    const renderData = (data: InstanceRecord[]): ReactNode => {
        return getInstancesData(data).map(({disabled, warning, problem, item, intervals}, index) => {
            return (
                <Select.Option
                    key={index}
                    value={item.id}
                    disabled={disabled}
                    label={
                        <div
                            className={'rr-product-variant-select__choice' + (/*aviableCount < 0*/ warning || problem ? ' rr-product-variant-select__choice-not-available' : '') + (warning ? ' rr-product-variant-select__choice-warning' : '') + (problem ? ' rr-product-variant-select__choice-error' : '')}>
                            <ProductInstancePopover instanceId={item.id}>
                                <span onClick={e => {
                                    e.stopPropagation();
                                }}>{item.nameOrCode}</span>
                            </ProductInstancePopover> <Icon className={'rr-product-variant-select__choice-closeIcon'} component={IconClose}/>
                        </div>
                    }>
                    <div
                        className={'rr-product-variant-select-menu-item' + (item.problemsAndWarnings.anyShortage || item.problemsAndWarnings.anyDelay ? ' rr-grid-problem-row' : '') + (disabled ? ' rr-product-variant-select-menu-item-not-available' + (/*this.props.elementRecord ? '__element' :*/ '__product') : '') + (warning ? ' rr-product-variant-select-menu-item__warning' : '') + (problem ? ' rr-product-variant-select-menu-item__error' : '')}>
                        {item.problemsAndWarnings.anyShortage || item.problemsAndWarnings.anyDelay ? <div
                            className={'rr-product-variant-select-menu-item__problem'} /*style={{pointerEvents: 'none'}}*/>{tableCellProblemRenderer(undefined, item, undefined, 'product-instances')}</div> : null}
                        <div className={'rr-grid-shortName-cell rr-product-variant-select-menu-item__title'}>
                            <ProductInstancePopover instanceId={item.id}>
                                <span onClick={e => {
                                    e.stopPropagation();
                                }}>{item.nameOrCode}</span>
                            </ProductInstancePopover>
                        </div>
                        <div className={'rr-product-variant-select-menu-item__calendar'}>
                            {
                                // <OperationInstanceCalendarPopover startDate={this.props.startDate}
                                //                                       endDate={this.props.endDate}
                                //                                       productId={this.props.productId}
                                //                                       variantId={this.props.variantId}
                                //                                       instanceId={item.id} intervals={intervals}>
                                //         {calendarIcon}
                                //     </OperationInstanceCalendarPopover>
                            }
                        </div>
                    </div>
                </Select.Option>
            );
        });
    };

    return (
        <div>
            <Select
                onDropdownVisibleChange={(visible: boolean) => {
                    //if (visible) loadData();
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode as any}
                //ref={this.props.ref1}
                showAction={['click']}
                defaultActiveFirstOption={false}
                value={instances.length ? props.value : []}
                onChange={onChange}
                className={'rr-product-variant-select'}
                dropdownClassName={'rr-product-variant-select-dropdown'}
                dropdownStyle={props.dropdownStyle}
                mode="multiple"
                style={{width: '100%'}}
                placeholder="Выбрать экземпляры"
                optionLabelProp="label"
                filterOption={(inputValue, option) => {
                    let id = option.props.value;
                    if (instances) {
                        let filteredInstances = instances.filter((item) => item.nameOrCode.toLowerCase().includes(inputValue.toLowerCase()));
                        if (filteredInstances.length > 0) {
                            if (filteredInstances.find((item) => item.id === id)) return true;
                        }
                    }
                    return false;
                }}
                notFoundContent={
                    localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)
                }
                dropdownRender={menu => (
                    <>
                        {!loading ? menu : undefined}
                        {loading && !loadingError ? (
                            <div className={'rr-custom-select-loading-block'}>
                                <Spin size={'small'} delay={0}/>
                                <span>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</span>
                            </div>
                        ) : null}
                        {!loading && loadingError ? (
                            <div className={'rr-custom-select-error-block'}>
                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR)}
                            </div>
                        ) : null}
                    </>
                )}
            >
                {instances ? renderData(instances) : null}
            </Select>
        </div>
    );
};


import {FAILURE, REQUEST, SUCCESS} from '../../../../shared/reducers/action-type.util';
import {RentElementInfoRead, serverApi, ServerError} from "../../../../server";
import {getServerError} from "../../../../shared/util/utils";

export const ACTION_TYPES = {
    LOAD_ENTITY: 'rentElement/LOAD_ENTITY',
    RESET: 'rentElement/RESET',
    RESET_ERROR: 'rentElement/RESET_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entity: null as (RentElementInfoRead | null)
};

export type RentElementState = Readonly<typeof initialState>;

// Reducer

export default (state: RentElementState = initialState, action): RentElementState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                loadingError: undefined
            };

        default:
            return state;
    }
};

// Actions

// Получение сущности
export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getRentElementById(businessAccountId, id)
});

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});
export class ArrayUtils {
    static random<T>(array: T[]): T {
        return array[Math.round(Math.random() * (array.length - 1))];
    }

    static removeDuplicates<T>(array: T[]): T[] {
        return [...new Set(array)];
    }

    static addOrDeleteItem<T>(array: T[], item: T): T[] {
        let newArray = [...array];
        if (newArray.indexOf(item) === -1) {
            newArray.push(item);
        } else {
            newArray = newArray.filter((value) => value !== item);
        }

        return newArray;
    }
}

import React, {CSSProperties, ReactNode, useMemo} from 'react';
import {DeepKeys} from '@tanstack/react-table';
import classNames from 'classnames';
import './SimpleTable.less';

export interface SimpleTableProps<T> extends React.Attributes {
    data?: T[] | null;
    columns: SimpleTableColumn<T>[];
    className?: string;
}

export interface SimpleTableColumn<T> {
    id: DeepKeys<T>;
    render?: (value: any, row: T, index: number) => ReactNode;
    width: CSSProperties["width"];
    minWidth?: CSSProperties["minWidth"];
    maxWidth?: CSSProperties["maxWidth"];
    className?: string;
    style?: CSSProperties;
    align?: 'center' | 'left' | 'right';
    header?: ReactNode;
}

export const SimpleTable = <T, >({data, columns, className, ...props}:SimpleTableProps<T>) => {
    const componentClassName = 'rr-simple-table';
    const rowClassName = `${componentClassName}-row`;
    const headerRowClassName = `${componentClassName}-header-row`;
    const cellClassName = `${componentClassName}-cell`;
    const headerCellClassName = `${componentClassName}-header-cell`;

    let result = useMemo(()=> {
        const header = columns.some(column => column.header) ? (
            <div className={classNames(headerRowClassName)}>
                {
                    columns.map((column, index) => {
                        let w = column.width;
                        let minw = column.minWidth;
                        let maxw = column.maxWidth;
                        return <div key={index}
                                    className={classNames(headerCellClassName)}
                                    style={{
                                        width: w,
                                        minWidth: minw,
                                        maxWidth: maxw
                                    }}>{column.header}</div>;
                    })
                }
            </div>
        ) : null;

        return (
            <div key={props.key} className={classNames(componentClassName, className)}>
                {header}
                {
                    data?.map((dataItem, rowIndex) => {
                        return <div key={rowIndex} className={classNames(rowClassName, `${rowClassName}-${rowIndex % 2 ? 'even' : 'odd'}`)}>
                            {
                                columns.map((column, index) => {
                                    let key = (column as any).id;
                                    let value = (dataItem as any)[key];
                                    let w = column.width;
                                    let minw = column.minWidth;
                                    let maxw = column.maxWidth;
                                    return <div key={index}
                                                className={classNames(cellClassName, column.className, column.align && `rr-simple-table-cell-align-${column.align}`)}
                                                style={{
                                                    width: w,
                                                    minWidth: minw,
                                                    maxWidth: maxw,

                                                    ...column.style
                                                }}>
                                        <div>
                                            {
                                                column.render ? column.render(value, dataItem, rowIndex) : value
                                            }
                                        </div>
                                    </div>;
                                })
                            }
                        </div>;
                    })
                }
            </div>
        );
    }, [data, columns, className]);

    return result;
};

import React from 'react';
import { Icon, Spin } from 'antd';
import {
    IconAngleLeft,
    IconClone,
    IconDollarSignSolid,
    IconHourglassHalfSolid,
    IconMicroscopeSolid,
    IconRandomSolid,
    IconSignOutAltSolid,
    IconStoreSolid,
} from '../icons';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { userProfilePopover } from './reducers/userPopover.reducer';
import { getBusinessAccounts } from '../../shared/reducers/businessAccounts.reducer';
import './userPopover.less';
import { Link } from 'react-router-dom';
import { getBusinessAccountId } from '../../../index';
import { rrLogout } from '../../shared/util/rrLogout';
import { LocalizationEnum, localize } from '../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { push } from 'connected-react-router';
import { Avatar } from '../avatar/avatar';
import InfiniteScroll from 'react-infinite-scroller';
import { BusinessAccountRecord, BusinessAccountStateCodeEnum, BusinessAccountTypeCodeEnum, serverApi } from '../../server';
import { canViewBusinessAccount } from '../../shared/util/permissionUtils';

export const listBusinessAccounts0 = (businessAccounts: BusinessAccountRecord[], canViewBusinessAccount: boolean) => {
    return (
        <>
            {businessAccounts.map((item, index) => {
                let icon;
                let iconColor: string | undefined;
                let iconComponent: any;
                let textColor: string = 'rgba(87, 89, 98, 0.9)';
                let disabled = false;
                if (canViewBusinessAccount) {
                    if (item.typeCode === BusinessAccountTypeCodeEnum.DEMOTEMPLATE) {
                        iconComponent = IconClone;
                        iconColor = '#343f9a';
                    } else if (item.typeCode === BusinessAccountTypeCodeEnum.SANDBOX) {
                        iconComponent = IconMicroscopeSolid;
                        iconColor = '#a0a0a0';
                    } else if (item.typeCode === BusinessAccountTypeCodeEnum.TRIAL) {
                        iconComponent = IconHourglassHalfSolid;
                        iconColor = '#fc8c2f';
                    } else if (item.typeCode === BusinessAccountTypeCodeEnum.PRODUCTION) {
                        iconComponent = IconDollarSignSolid;
                        iconColor = '#34bfa3';
                    }
                }

                if (item.stateCode === BusinessAccountStateCodeEnum.BLOCKED) {
                    textColor = '#a0a0a0';
                    iconColor = '#a0a0a0';
                    if (!canViewBusinessAccount) disabled = true;
                }

                if (iconComponent && iconColor) {
                    icon = <Icon component={iconComponent} style={{ fontSize: 21, color: iconColor, marginRight: 6 }} />;
                }

                return (
                    <div
                        onClick={() => {
                            if (!disabled) window.location.href = `/${item.id}/`;
                        }}
                        className="user-popover-action"
                        style={{
                            color: textColor,
                            padding: `10px 0px 10px 10px`,
                            cursor: !disabled ? 'pointer' : 'default',
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {icon && <span>{icon}</span>}
                        <span>{item.shortName}</span>
                    </div>
                );
            })}
        </>
    );
};

interface IProps extends WrappedComponentProps, StateProps, DispatchProps {
    userProfile;
    visible;
    collapsed;
    onVisiblePopoverAccount;
    onUnvisiblePopoverAccount;
}

class UserPopover extends React.Component<IProps> {
    componentDidMount(): void {
        this.loadUserProfile();
    }

    componentDidUpdate() {
        if (this.state.collapsed !== this.props.collapsed) {
            this.setState({
                collapsed: this.props.collapsed,
            });
        }
        if (!this.props.visible && this.state.changeProfile) {
            this.setState({
                changeProfile: false,
            });
        }
    }

    state = {
        changeProfile: false,
        collapsed: true,
        businessAccounts: [] as BusinessAccountRecord[],
        hasMore: false,
    };

    loadUserProfile = () => {
        this.props.userProfilePopover(this.props.intl);
    };

    onChangeAccount = async () => {
        await this.setState((state: any) => {
            return {
                changeProfile: !state.changeProfile,
            };
        });

        if (this.props.businessAccount) await this.props.getBusinessAccounts(100, 0);

        this.setState((state: any) => {
            return {
                businessAccounts: this.props.businessAccounts!.records,
            };
        });
    };

    onBackAccount = () => {
        this.setState((state: any) => {
            return {
                changeProfile: !state.changeProfile,
            };
        });
    };

    onLogoutClick = () => {
        rrLogout();
    };

    render() {
        let email = null;
        let fullName = '';
        if (this.props.userProfile) {
            email = this.props.userProfile.email;
            fullName = this.props.userProfile.fullName.firstname + ' ' + this.props.userProfile.fullName.lastname;
        }
        const { changeProfile } = this.state;

        const listBusinessAccounts = listBusinessAccounts0(
            this.state.businessAccounts.filter((ba) => ba.id !== this.props.businessAccountContacts!.id),
            this.props.canViewBusinessAccount
        );

        const handleInfiniteOnLoad = async () => {
            if (this.state.businessAccounts.length < this.props.businessAccounts!.listAttributes!.filteredCount) {
                const aaa: any = await serverApi.listBusinessAccounts(100, this.state.businessAccounts.length);
                this.setState((state: any) => {
                    return {
                        businessAccounts: [...this.state.businessAccounts, ...aaa.data.records],
                    };
                });
            }
        };

        const viewPopover = changeProfile ? (
            <>
                <div className="user-popover-icon-action2" style={{ borderRadius: ' 4px' }}>
                    <Link to={`/${getBusinessAccountId()}/userProfile`}>
                        <div style={{ padding: '10px 20px 10px 20px', display: 'flex' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon
                                    className="user-popover-icon-action"
                                    component={IconAngleLeft}
                                    style={{ fontSize: '22px', marginRight: '20px' }}
                                    onClick={this.onBackAccount}
                                />
                            </div>
                            <div>
                                <div style={{ color: '#fff', fontSize: '14px' }}>
                                    <span style={{ color: '#fff' }}>Компании</span>
                                </div>
                                <div>
                                    <span style={{ color: '#fff', fontWeight: 600 }}>{email}</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div>
                    <Spin spinning={this.state.businessAccounts.length === 0} delay={0}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                paddingTop: '5px',
                                zIndex: 1000,
                                maxHeight: '400px',
                                minHeight: '400px',
                                overflow: 'auto',
                            }}
                        >
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={handleInfiniteOnLoad}
                                hasMore={true}
                                useWindow={false}
                            >
                                {listBusinessAccounts}
                            </InfiniteScroll>
                        </div>
                    </Spin>
                </div>
            </>
        ) : (
            <UserProfile
                businessAccountContacts={this.props.businessAccountContacts}
                email={email}
                fullName={fullName}
                onChangeAcaunt={this.onChangeAccount}
                businessAccount={this.props.businessAccount}
                avatar={this.props.userAvatar}
            />
        );

        return (
            <div
                className="user-popover-account-unhover"
                style={{
                    width: `320px`,
                    zIndex: 100,
                    left: `${this.state.collapsed ? 70 : 230}px`,
                    position: 'fixed',
                    bottom: `${this.state.collapsed ? -10 : 0}px`,
                }}
                onMouseEnter={this.props.onVisiblePopoverAccount}
                onMouseLeave={this.props.onUnvisiblePopoverAccount}
            >
                <div className="user-popover-icon-action2  " style={{ width: '300px', padding: '0px', borderRadius: ' 4px' }}>
                    {viewPopover}
                    <div
                        onClick={this.onLogoutClick}
                        className="user-popover-icon-action2-hover"
                        style={{
                            padding: '15px',
                            marginLeft: '0px',
                            paddingTop: '25px',
                            paddingBottom: '25px',
                            cursor: 'pointer',
                            color: '#fff',
                            display: 'flex',
                        }}
                    >
                        <Icon component={IconSignOutAltSolid} style={{ fontSize: '22px', marginRight: '20px' }} />
                        <span style={{ fontSize: '14px', marginTop: '1px' }}>
                            {localize(LocalizationEnum.ASPECT__CURRENT_USER_PROFILE_POPUP__SIGN_OUT)}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        width: '5px',
                        height: '5px',
                        border: '5px solid transparent',
                        borderRight: '5px solid #383F77',
                        position: 'absolute',
                        bottom: '50px',
                        left: '10px',
                    }}
                >
                    {' '}
                </div>
                <div style={{ height: '23px' }}></div>
            </div>
        );
    }
}

const UserProfile = ({ email, fullName, onChangeAcaunt, businessAccount, businessAccountContacts, avatar }) => {
    return businessAccount ? (
        <>
            <div className="user-popover-icon-action2 user-popover-icon-action2-hover" style={{ borderRadius: ' 4px' }}>
                <Link to={`/${getBusinessAccountId()}/userProfile`}>
                    <div style={{ padding: '10px 0px 10px 0px' }}>
                        <div style={{ display: 'flex', marginLeft: '17px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }} className={'rr-app-menu-profile-avatar'}>
                                <Avatar icon="user" src={avatar} />
                            </div>
                            <div style={{ marginLeft: '-5px' }}>
                                <div style={{ marginTop: '7px', fontSize: '14px', padding: '0px 20px' }}>
                                    <span style={{ color: '#fff', cursor: 'pointer' }}>{fullName}</span>
                                </div>
                                <div style={{ marginBottom: '10px', fontSize: '14px', padding: '0px 20px' }}>
                                    <span style={{ color: '#fff', fontWeight: 600, cursor: 'pointer' }}>{email}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div style={{ borderRadius: ' 4px' }}>
                <div style={{ backgroundColor: '#fff' }}>
                    <Link to={`/${businessAccount.id}/settings/businessAccountProfile`}>
                        <div
                            className="user-popover-action"
                            style={{
                                color: '#383F77',
                                padding: '10px 0px 10px 20px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon component={IconStoreSolid} style={{ marginRight: '20px', fontSize: '22px' }} />
                            </div>
                            <span style={{ marginTop: '2px' }}>{businessAccountContacts ? businessAccountContacts.shortName : ''}</span>
                        </div>
                    </Link>
                    <div
                        className="user-popover-action"
                        style={{ color: '#383F77', cursor: 'pointer', fontSize: '14px', padding: '10px 0px 10px 20px', display: 'flex' }}
                        onClick={onChangeAcaunt}
                    >
                        <Icon component={IconRandomSolid} style={{ marginRight: '20px', fontSize: '22px' }} />
                        <span style={{ marginTop: '2px' }}>Сменить компанию</span>
                    </div>
                </div>
            </div>
        </>
    ) : null;
};

const mapStateToProps = (storeState: IRootState) => {
    return {
        userProfile: storeState.userProfilePopover.entity,
        businessAccount: storeState.businessAccount.entity,
        businessAccounts: storeState.businessAccounts.entities,
        businessAccountContacts: storeState.businessAccountContacts.entity,
        userAvatar: storeState.oidc.user?.profile.picture,
        canViewBusinessAccount: canViewBusinessAccount(storeState.permissions.permissions),
    };
};

const mapDispatchToProps = { userProfilePopover, getBusinessAccounts, push };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserPopover));

import React, {FC, memo} from 'react';
import '../../../../components/Calendar/components/Footer/CalendarFooter.less';
import Spin from "../../../../../../../components/spin/spin";

interface CalendarFooterProps {
    totalCount: number;
    onClick: ()=>void;
    isFetching: boolean;
}

export const SummaryScheduleCalendarFooter: FC<CalendarFooterProps> = memo((props) => {
    const { totalCount, onClick, isFetching } = props;
    return (
        <div className="calendar-footer-container" onClick={onClick}>
            <span className={'calendar-footer'} style={{marginRight: 0}}>
                <span>Показать всю номенклатуру</span>
                <span className="items-count"> ({totalCount})</span>
            </span>
            <div style={{width: 40, height: 30}}>
                {isFetching && <Spin spinning={true} style={{marginLeft: 10}} />}
            </div>
        </div>
    );
});

import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import {
    BusinessAccountInfoUpdate,
    BusinessAccountRecord,
    BusinessAccountRecordList,
    RenterRecordList,
    serverApi,
    ServerError,
    WorkflowDirective,
} from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { IRootState } from '../../../../shared/reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getServerError, getStateFromPath2, xxxParams } from '../../../../shared/util/utils';
import { IntlShape } from 'react-intl';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { getAvailableGridSortById } from '../../../../shared/util/getAvailableGridSortById';
import { businessAccountsPageColumns } from '../businessAccountsPageColumns';
import { gridDataChangedSignal } from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'globalBusinessAccounts/LOAD_ENTITIES',
    RESET: 'globalBusinessAccounts/RESET',
    CHANGE_ARCHIVE: 'globalBusinessAccounts/CHANGE_ARCHIVE',
    CHANGE_STATUS: 'globalBusinessAccounts/CHANGE_STATUS',
    UPDATE_ENTITY: 'globalBusinessAccounts/UPDATE_ENTITY',
};

interface PageParams {
    startFrom?: number | undefined;
    page?: number | undefined;
    limit?: number | undefined;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
}

export interface GlobalBusinessAccountsPageParams extends PageParams {
    search?: string;
    stateCode?: string;
    typeCode?: string;
    tariff?: string;
    hideArchive?: boolean;
    rentIndustryCode?: string;
}

export const initialParamsState: GlobalBusinessAccountsPageParams = {
    startFrom: 0,
    page: 1,
    limit: 20,
    sortBy: undefined,
    sortOrder: undefined,
    hideArchive: undefined,
};

const initialState = {
    loading: false,
    loadingError: null,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entities: null as Array<BusinessAccountRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    } as GlobalBusinessAccountsPageParams,
};

export type GlobalBusinessAccountsState = Readonly<typeof initialState>;

// Reducer
export default (state: GlobalBusinessAccountsState = initialState, action): GlobalBusinessAccountsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let path = action.payload?.location?.pathname || '';

            if (/\/\d+\/businessAccounts/.test(path)) {
                let params = getStateFromPath2(action.payload.location.search);
                let newParams = xxxParams(state.params, params, initialParamsState);
                return {
                    ...state,
                    params: {
                        ...newParams,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as BusinessAccountRecordList).records,
                filteredCount: (action.payload.data as RenterRecordList).listAttributes.filteredCount,
            };

        case REQUEST(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: false,
            };

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };

        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

// Загрузить список компаний
export const loadEntities = (intl: IntlShape) => {
    return (dispatch, getState) => {
        let state: GlobalBusinessAccountsState = (getState() as IRootState).globalBusinessAccounts;
        let params = state.params;

        let filters: string[] = [];
        if (params.stateCode) filters.push(`stateCode;EQ;${params.stateCode}`);
        if (params.typeCode) filters.push(`typeCode;EQ;${params.typeCode}`);
        if (params.tariff) filters.push(`tariff;EQ;${params.tariff}`);
        if (params.rentIndustryCode) filters.push(`rentIndustryCode;EQ;${params.rentIndustryCode}`);
        if (params.hideArchive === true) filters.push(`archive;EQ;false`);

        let page = params.page !== undefined ? params.page : initialParamsState.page || 1;
        let limit = params.limit !== undefined ? params.limit : initialParamsState.limit || 10;
        const sortBy = getAvailableGridSortById(params.sortBy, businessAccountsPageColumns);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.globalListBusinessAccounts(
                params.limit,
                (page - 1) * limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось загрузить список компаний');
            }
        });
    };
};

export const setArchiveState = (intl: IntlShape, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitBusinessAccountWorkflowBulk({ directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // В архив не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // В архив изменен
                showNotification('success', directives.length === 1 ? 'Состояние компании изменено' : 'Состояние компаний изменено');
                dispatch(loadEntities(intl));
            }
        });
    };
};

export const setStatus = (intl: IntlShape, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_STATUS,
            payload: serverApi.transitBusinessAccountWorkflowBulk({ directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус арендатора не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Статус арендатора изменен
                showNotification('success', directives.length === 1 ? 'Состояние компании изменено' : 'Состояние компаний изменено');
                dispatch(loadEntities(intl));
            }
        });
    };
};

export const updateEntity = (intl: IntlShape, id: number, data: BusinessAccountInfoUpdate) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateBusinessAccountById(id, data),
        }).then((result) => {
            if (result instanceof Error) {
                // Компания не обновлена
            } else {
                // Компания обновлена успешно
                showNotification('success', 'Данные компании обновлены!');
                dispatch(loadEntities(intl));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

import { ImageObj } from '../../../server';
import { GridRecord } from '../../../../index';
import { TableCellAvatarRenderer } from './tableCellAvatarRenderer';

export const TableCellAvatarRendererWrapper = (
    value: ImageObj,
    rowData: GridRecord = undefined,
    opts?: {
        highlight: boolean;
    }
) => {
    return <TableCellAvatarRenderer value={value} rowData={rowData} opts={opts} />;
};

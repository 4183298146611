import React, { ReactNode } from 'react';
import { ModalFormFilters, ModalFormFiltersProps } from './components/ModalFormFilters/ModalFormFilters';
import './ModalFormHeader.less';

interface ModalFormHeaderProps extends ModalFormFiltersProps {
    titleNode: ReactNode;
}

export const ModalFormHeader: React.FC<ModalFormHeaderProps> = ({ titleNode, ...props }) => {
    return (
        <div className={'modal-form-header_container'}>
            <span>{titleNode}</span>
            <ModalFormFilters {...props} />
        </div>
    );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { PaymentInfoRead, PaymentStateCodeEnum, serverApi } from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { GetPaymentByIdArgs, ListPaymentsArgs, ListPaymentsData, TransitPaymentWorkflowBulk } from './payments.api.types';
import { AxiosError } from 'axios';
import { IRootState } from '../../../../shared/reducers';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { getAvailableGridSortById } from '../../../../shared/util/getAvailableGridSortById';
import paymentsColumns from '../columns/paymentsListColumns';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { PaymentsApiUtils } from './payments.api.utils';

export const paymentsApi = createApi({
    reducerPath: 'paymentsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['PaymentsList', 'Payment'],
    endpoints: (builder) => ({
        listPayments: builder.query<ListPaymentsData, ListPaymentsArgs>({
            queryFn: async (args, { getState }) => {
                const { params } = args;
                const state = getState() as IRootState;
                const subrentModuleEnabled = subrentModuleEnabledSelector(state);
                const businessAccountId = businessAccountIdSelector(state);

                const sortBy = getAvailableGridSortById(params.sortBy, paymentsColumns);
                const sortOrder = sortBy != null ? params.sortOrder : undefined;
                const filters: string[] = PaymentsApiUtils.createRequestFilters(params, subrentModuleEnabled);

                try {
                    const [
                        { data: availableFilters },
                        {
                            data: { records, listAttributes },
                        },
                    ] = await Promise.all([
                        serverApi.listPaymentsAvailableFiltersValues(businessAccountId),
                        serverApi.listPayments(
                            businessAccountId,
                            params.limit,
                            (params.page - 1) * params.limit,
                            undefined,
                            sortBy,
                            sortOrder,
                            undefined,
                            { query: { filters } }
                        ),
                    ]);

                    return {
                        data: {
                            entitiesData: {
                                records: records.map((record) => ({
                                    ...record,
                                    id: record.id!,
                                    disabled: record.stateCode === PaymentStateCodeEnum.DELETED,
                                })),
                                listAttributes,
                            },
                            availableIntervalFiltersData: {
                                values: availableFilters,
                                maps: {
                                    metrics: PaymentsApiUtils.intervalMapFunction,
                                    formats: PaymentsApiUtils.intervalFormatStyleMap,
                                },
                            },
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['PaymentsList'],
        }),
        paymentById: builder.query<PaymentInfoRead, GetPaymentByIdArgs>({
            queryFn: async ({ businessAccountId, entityId }) => {
                try {
                    return await serverApi.getPaymentById(businessAccountId, entityId);
                } catch (error: unknown) {
                    showNotification('error', 'Не удалось загрузить платёж');
                    const errorMessage = error instanceof Error ? error.message : undefined;
                    throw new Error(errorMessage);
                }
            },
            providesTags: ['Payment'],
        }),
        transitWorkflowBulk: builder.mutation<void, TransitPaymentWorkflowBulk>({
            queryFn: async (args, { getState }) => {
                const { directives } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitPaymentsWorkflowBulk(businessAccountId, { directives });
                    showNotification('success', 'Платеж изменен');
                    return { data: undefined };
                } catch (error) {
                    showNotification('error', 'Не удалось изменить платеж');
                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['PaymentsList', 'Payment'],
        }),
    }),
});

export const { useListPaymentsQuery, usePaymentByIdQuery, useTransitWorkflowBulkMutation } = paymentsApi;

import { Icon } from 'antd';
import React, { ComponentType, FC } from 'react';
import { IconCheck, IconClose } from '../../../../../../../components/icons';

interface RegisterStepThreeModuleLineProps {
    value: boolean;
    title: string;
    icon: ComponentType;
}

export const RegisterStepThreeModuleLine: FC<RegisterStepThreeModuleLineProps> = (props) => {
    const { value, title, icon } = props;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                gap: 16,
                maxWidth: 260,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 16,
                    marginTop: 2,
                }}
            >
                {value ? (
                    <Icon
                        component={IconCheck}
                        style={{
                            fontSize: 22,
                            color: '#34bfa3',
                        }}
                    />
                ) : (
                    <Icon
                        component={IconClose}
                        style={{
                            fontSize: 22,
                            color: '#f4516c',
                        }}
                    />
                )}
                <Icon
                    component={icon}
                    style={{
                        fontSize: 22,
                    }}
                />
            </div>
            <span
                style={{
                    fontSize: 18,
                }}
            >
                {title}
            </span>
        </div>
    );
};

import React, {FC, useCallback, useState} from 'react';
import {Popover} from "../../../../../../components/popover/Popover";
import {PopoverContent} from "../../../../../../components/popover/PopoverContent";
import {Button, Radio, Row} from "antd";
import {LocalizationEnum, localize} from "../../../../../../localization";
import {
    BlockData,
    useRenderEffectivePriceBlock,
    useRenderFinalPricePerShiftBlock,
    useRenderFinalTotalPriceBlock
} from "./changeElementsPricePopoverHooks";
import {useAppSelector} from "../../../../../../store/hooks";
import {WarningsList} from "../../../../../../components/text/WarningsList/WarningsList";
import './changeElementsPricePopover.less';

interface Props {
    selectedIds: number[];
    onClose?: ()=>void;
}

export const ChangeElementsPricePopover:FC<Props> = ({selectedIds, children, onClose}) => {
    const [visible, setVisible] = useState(false);

    const onVisibleChange = useCallback((visible)=>{
        setVisible(visible);
    }, [setVisible]);

    return (
        <Popover
            overlayStyle={{minWidth: 0, width: 'unset'}}
            destroyTooltipOnHide
            onVisibleChange={onVisibleChange}
            visible={visible}
            content2={
                <ChangeElementsPricePopoverContent
                    onClose={()=>{
                        setVisible(false);
                        onClose?.();
                    }}
                    selectedIds={selectedIds}
                />
            }>
            {children}
        </Popover>
    );
};

enum Mode {
    EffectivePrice = 'EffectivePrice',
    FinalPricePerShift = 'FinalPricePerShift',
    FinalTotalPrice = 'FinalTotalPrice'
}

interface ChangeElementsPricePopoverContentProps {
    onClose: ()=>void;
    selectedIds: number[];
}

export const ChangeElementsPricePopoverContent:FC<ChangeElementsPricePopoverContentProps> = ({onClose, selectedIds}) => {
    const [mode, setMode] = useState(Mode.EffectivePrice);
    const elements = useAppSelector((state)=> state.operationForm.elements.entities);
    const selectedElements = elements.filter((el)=>selectedIds.includes(el.id));

    const effectivePriceAndDiscountBlockData = useRenderEffectivePriceBlock({selectedElements});
    const finalPricePerShiftBlockData = useRenderFinalPricePerShiftBlock({selectedElements});
    const finalTotalPriceBlockData = useRenderFinalTotalPriceBlock({selectedElements});

    let block:BlockData|undefined;
    if(mode === Mode.EffectivePrice) block = effectivePriceAndDiscountBlockData;
    else if(mode === Mode.FinalPricePerShift) block = finalPricePerShiftBlockData;
    else if(mode === Mode.FinalTotalPrice) block = finalTotalPriceBlockData;

    const disabled = selectedElements.some((el)=>el.effectivePrice === 0);

    return (
        <PopoverContent
            content={
                <div style={{minWidth: 430}}>
                    <Row style={{ padding: 32, borderBottom: '1px solid #E4E5EC'}}>
                        <div style={{marginBottom: 6}}>Установить для каждой позиции:</div>
                        <Radio.Group
                            value={mode}
                            onChange={(e)=>{
                                setMode(e.target.value as Mode);
                            }}
                        >
                            <Radio value={Mode.EffectivePrice}>Цену</Radio>
                            <Radio disabled={disabled} value={Mode.FinalPricePerShift}>Стоимость</Radio>
                            <Radio disabled={disabled} value={Mode.FinalTotalPrice}>Итого</Radio>
                        </Radio.Group>
                        {
                            disabled && <WarningsList warnings={['Выбраны позиции с нулевой ценой, нельзя задать стоимость']} style={{marginTop: 20}} />
                        }
                    </Row>
                    <Row style={{ padding: 32 }}>
                        {block?.content}
                    </Row>
                </div>
            }
            header={<></>}
            headerClassName={'rr-changeElementsPricePopover-header'}
            footer={
                <>
                    <Button className={'rr-btn-default'} onClick={()=>{
                        onClose();
                    }}>
                        {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                    </Button>
                    <Button
                        disabled={!block?.canApply}
                        type={'primary'}
                        onClick={()=>{
                            block?.onApply();
                            onClose();
                        }}>
                        {'Применить'}
                    </Button>
                </>
            }>

        </PopoverContent>
    );
};

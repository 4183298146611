import React, {FC} from 'react';
import {CalendarHeaderButtonsProps} from '../../../../components/Calendar/types/props';
import {SortByButton} from '../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import {CalendarURLParams} from '../../../../components/Calendar/types/params';
import {CalendarDisplayTypeButton} from '../../../../components/Calendar/components/ActionsButtons/DisplayTypeButton/CalendarDisplayTypeButton';
import {Switch} from "antd";
import {CalendarHeaderContainer} from "../../../../components/Calendar/components/Header/HeaderContainer/CalendarHeaderContainer";
import {SummaryScheduleCalendarFilters} from "../../filters/summaryScheduleCalendarFilters";
import {ElementsCount} from "../../../../components/Calendar/components/Header/ElementsCount/ElementsCount";
import {SummaryScheduleCalendarSortValue} from "../../data/sortData";

type Params = SummaryScheduleCalendarFilters;
type SortValue = SummaryScheduleCalendarSortValue;

interface SummaryScheduleCalendarHeaderProps extends Omit<CalendarHeaderButtonsProps<Params, SortValue>, 'setSortByValue'> {
    visibleCount: number;
    totalCount: number;
    sidebarWidth: number;
}

export const SummaryScheduleCalendarHeader: FC<SummaryScheduleCalendarHeaderProps> = (props) => {
    const { isLoading, sortData, sortByValue, pageParamsObject, displayType, visibleCount, totalCount, setDisplayType } = props;
    const { updatePageParams, pageParams } = pageParamsObject;
    const { includeRentElements } = pageParams;

    const onChange = (checked: boolean) => {
        updatePageParams({
            includeRentElements: checked ? true : undefined
        });
    };

    return (
        <CalendarHeaderContainer>
            <ElementsCount style={{marginLeft: props.sidebarWidth}} count={visibleCount} filteredCount={totalCount} />
            <div className={'actions-block'}>
                {/*<label style={{marginRight: 32}}>*/}
                {/*    Показывать обязательства <Switch checked={includeRentElements} onChange={onChange} />*/}
                {/*</label>*/}

                <SortByButton
                    disabled={isLoading}
                    sortData={sortData}
                    sortValue={sortByValue}
                    onSortChange={(value) => {
                        updatePageParams({
                            group: value,
                        } as CalendarURLParams<Params, SortValue>);
                    }}
                />

                <CalendarDisplayTypeButton
                    displayType={displayType}
                    onChange={(value) => {
                        setDisplayType(value);
                        updatePageParams(
                            {
                                displayType: value,
                            } as CalendarURLParams<Params, SortValue>,
                            {
                                replaceWithoutRerender: true,
                            }
                        );
                    }}
                />
            </div>
        </CalendarHeaderContainer>
    );
};

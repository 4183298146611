import React from 'react';
import {Button, Card, Col, Icon, Modal, Row} from 'antd';
import {IconCheck, IconClose, IconEdit, IconStoreSolid} from '../../../../components/icons';
import UserProfileNotificationPopover from './user-profile-notifications-popover';
import '../user-profile-page.less';
import {IRootState} from '../../../../shared/reducers';
import {userProfilePopover} from '../../../../components/userPopover/reducers/userPopover.reducer';
import {getBusinessAccounts} from '../../../../shared/reducers/businessAccounts.reducer';
import {connect} from 'react-redux';
import {NotificationCustomizationObjEventFilterEnum, NotificationSchemaInfoUpdate, serverApi} from '../../../../server';
import {notificationCode2LocalizationEnum} from './getEvent';
import {push} from 'connected-react-router';
import {getServerError, getStateFromPath} from '../../../../shared/util/utils';
import {showNotification} from '../../../../components/notification/showNotification';
import {LocalizationEnum, localize, localizeIntl} from '../../../../localization';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RoundButton} from "../../../../components";
import {FormValue2} from "../../../../components/formValue/FormValue2";

interface Props extends WrappedComponentProps, DispatchProps, StateProps {}

interface State {
    id: number;
    businessVersion: number;
    userId: number;
    globalEnabled: boolean;
    globalShowOwnActions: boolean;
    baCustomizations: any[],
    baCustomizationsCompany: any[],
    visible: boolean;
    err: any;
}

class UserProfileNotifications extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            id: 0,
            businessVersion: 0,
            userId: 0,
            globalEnabled: true,
            globalShowOwnActions: true,
            baCustomizations: [],
            baCustomizationsCompany: [],
            visible: false,
            err: undefined,
        };
    }

    componentDidMount() {
        this.getCurrentNotificationSchema(this.props.intl);

        if (getStateFromPath(this.props.location.search).unsubscribe) {
            this.onUnsubscribe();
        }
    }

    updateCurrentNotificationSchema = async (intl, newState: any) => {
        const newBaCustomizations = newState.baCustomizations.map(el => {
            return {
                businessAccountId: el.businessAccountId,
                enabled: el.enabled,
                projectFilter: el.projectFilter,
                eventFilter: el.eventFilter
            };
        });

        const updateState: NotificationSchemaInfoUpdate = {
            id: this.state.id,
            businessVersion: this.state.businessVersion,
            globalEnabled: newState.globalEnabled,
            globalShowOwnActions: newState.globalShowOwnActions,
            baCustomizations: newBaCustomizations,
        };

        await serverApi.updateCurrentNotificationSchema(updateState)
            .then(
                (result) => {
                    this.setState({
                        id: result.data.id,
                        businessVersion: result.data.businessVersion,
                        userId: result.data.userId,
                        globalEnabled: result.data.globalEnabled,
                        globalShowOwnActions: result.data.globalShowOwnActions,
                        baCustomizations: result.data.baCustomizations,
                        visible: false,
                    });

                    showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__CHANGED));

                }
            ).catch(error => {
                    this.setState({
                        err: getServerError(error)
                    });
                }
            );
    };

    getCurrentNotificationSchema = async (intl) => {
        await serverApi.getCurrentNotificationSchema()
            .then(
                (result) => {
                    this.setState({
                        id: result.data.id,
                        businessVersion: result.data.businessVersion,
                        userId: result.data.userId,
                        globalEnabled: result.data.globalEnabled,
                        globalShowOwnActions: result.data.globalShowOwnActions,
                        baCustomizations: result.data.baCustomizations,
                    });
                }
            ).catch((err) => {
                if (err instanceof Error) {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__GET_FAILED));
                } else {

                }
            });
    };

    globalEnabled = () => {
        this.setState({
            globalEnabled: !this.state.globalEnabled
        });
    };

    globalShowOwnActions = () => {
        this.setState({
            globalShowOwnActions: !this.state.globalShowOwnActions
        });
    };

    handleOk = async (newState) => {
        if (newState) {
            await this.updateCurrentNotificationSchema(this.props.intl, newState);
            return this.state.err;
        } else {
            this.setState({
                visible: false
            });
        }
    };

    handleCancel = () => {
        Modal.confirm({
            className: 'rr-modal-confirm',
            title: <span
                className={'rr-modal-confirm-title'}>{localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)}</span>,
            content: null,
            onCancel: () => {
                this.setState({
                    visible: false,
                });
            },
            onOk: () => {
            },
            centered: true,
            cancelButtonProps: {className: 'rr-btn-default'},
            autoFocusButton: 'ok',
            icon: null,
            width: 500,
            okText: localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__NO),
            cancelText: localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__YES),
            // okText: 'Нет',
            // cancelText: 'Да',
            keyboard: false
        });
    };

    onUnsubscribe = () => {
        Modal.confirm({
            className: 'rr-modal-confirm',
            title: <span
                className={'rr-modal-confirm-title'}>{localizeIntl(this.props.intl, LocalizationEnum.PAGE__USER_PROFILE__MODAL__QUESTION__TURN_OFF_ALL_NOTIFICATIONS)}</span>,
            content: null,
            onCancel: () => {
            },
            onOk: () => {
                const newState: any = {...this.state, globalEnabled: false};
                this.updateCurrentNotificationSchema(this.props.intl, newState);
            },
            centered: true,
            cancelButtonProps: {className: 'rr-btn-default'},
            autoFocusButton: 'ok',
            icon: null,
            width: 500,
            okText: localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__NO),
            cancelText: localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__YES),
            // okText: 'Нет',
            // cancelText: 'Да',
            keyboard: false
        });
    };

    renderEventTypes = (events:NotificationCustomizationObjEventFilterEnum[]|undefined|null) => {
        return events?.map((e)=>{
            let id = notificationCode2LocalizationEnum(e);
            return id ? localizeIntl(this.props.intl, id) : undefined
        }).join(', ');
    };

    onEdit = (company) => {
        this.setState((prev: any) => {
            return {
                visible: !this.state.visible,
                baCustomizationsCompany: company ? [prev.baCustomizations.find((item) => company === item.businessAccountId)] : []
            }
        });
    };

    render() {
        const {visible} = this.state;
        const viewSettingBusinessAccounts = this.state.baCustomizations.map((item, num) => {
            return (

                <Card key={num} bodyStyle={this.state.baCustomizations.length !== num + 1 ? {
                    padding: '0px',
                    marginBottom: '17px',
                    width: '100%'
                } : {padding: '0px', width: '100%'}}>
                    <Row>
                        <div className="user-profile-header"
                             style={{
                                 display: 'flex',
                                 borderBottom: '1px solid #e4e5ec',
                                 padding: '13px',
                                 paddingLeft: '40px',
                                 fontSize: '18px',
                                 alignItems: 'center'
                             }}>
                            <div>
                                <Icon component={IconStoreSolid} style={{
                                    fontSize: 20,
                                    color: '#525A95',
                                    marginRight: 15
                                }}/>
                            </div>
                            <span className="user-profile-header-plashka " style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{item.businessAccountShortName}</span>
                            <div style={{marginLeft: 'auto', marginRight: '10px', cursor: 'pointer'}}
                                 onClick={() => this.onEdit(item.businessAccountId)}>
                                <Button type={'link'}>
                                    <Icon component={IconEdit} style={{fontSize: 20, color: '#525A95'}} />
                                    Редактировать
                                </Button>
                            </div>

                        </div>

                        <Col className={'rr-form-item user-profile-col3'} style={{padding: '20px 40px 14px'}}>
                            <div className={'rr-label'}>
                                Отправка уведомлений о событиях компании
                            </div>
                            {
                                item.enabled ?
                                    (
                                        <>
                                            <div className='rr-value user-profile-block' style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingBottom: '10px'
                                            }}>
                                                <Icon component={IconCheck} style={{
                                                    fontSize: '20px',
                                                    color: '#34BFA3',
                                                    marginBottom: '10px',
                                                    marginRight: '15px'
                                                }}/>
                                                <h3 className="user-profile-h3"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: '18px',
                                                        color: '#575962'
                                                    }}
                                                >Включена</h3>
                                            </div>
                                            <div className={'rr-label'}>
                                                Бизнес процессы, по которым будут отправляться уведомления
                                            </div>
                                            <div className='rr-value user-profile-block'
                                                 style={{paddingBottom: '10px'}}>
                                                <h3 className="user-profile-h3"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: '18px',
                                                        color: '#575962'
                                                    }}
                                                >
                                                    {item.projectFilter === 'ALL' ? localize(LocalizationEnum.ASPECT__GLOBAL__ANY) : localize(LocalizationEnum.PAGE__USER_PROFILE__TAB__NOTIFICATIONS__PROJECT_SELECTOR__OPTION__ASSIGNED_TO_ME)}
                                                </h3></div>
                                            <div className={'rr-label user-profile-block'}>
                                                События, при возникновении которых будут отправляться уведомления
                                            </div>
                                            <div className={'rr-value user-profile-block'}>
                                                {
                                                    !item.eventFilter || item.eventFilter.length === 0 ? (
                                                        <FormValue2 content={null} emptyContent={'Не указаны'} />
                                                    ) : (
                                                        <h3 className="user-profile-h3"
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: '18px',
                                                                color: '#575962'
                                                            }}
                                                        >
                                                            {this.renderEventTypes(item.eventFilter)}
                                                        </h3>
                                                    )
                                                }

                                            </div>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <div className='rr-value user-profile-block'
                                                 style={{display: "flex", alignItems: 'center'}}>
                                                <Icon component={IconClose} style={{
                                                    fontSize: '20px',
                                                    color: '#F4516C',
                                                    marginBottom: '10px',
                                                    marginRight: '15px'
                                                }}/>
                                                <h3 className="user-profile-h3"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: '18px',
                                                        color: '#575962'
                                                    }}

                                                >Выключена</h3>
                                            </div>
                                        </>
                                    )
                            }
                        </Col>
                    </Row>
                </Card>
            );

        });

        return (
            <>
                <Modal
                    title={localize(LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__FORM_TILTE)}
                    visible={visible}
                    onCancel={this.handleCancel}
                    width={800}
                    destroyOnClose={true}
                    footer={false}
                    className={'user-profile-model'}
                >
                    <UserProfileNotificationPopover
                        isOk={(newState) => this.handleOk(newState)} state={this.state} />
                </Modal>

                <Card
                    className={'rr-card'}
                    bodyStyle={{display: 'none'}}
                    style={{borderBottom: '1px solid #cccccc', marginBottom: '17px'}}
                    title={<span className="card-title">Настройки уведомлений</span>}
                    extra={
                        <RoundButton colorScheme={'default'} onClick={() => this.onEdit(false)}>
                            <Icon component={IconEdit}/>
                            {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                        </RoundButton>
                    }
                />

                <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap',}}>
                    <div style={{width: '100%'}}>
                        <Card bodyStyle={{padding: '0px', marginBottom: '32px', flexGrow: 1}}>
                            <Row>
                                <div className="user-profile-header" style={{
                                    borderBottom: '1px solid #e4e5ec',
                                    padding: '13px',
                                    paddingLeft: '40px',
                                    fontSize: '18px',
                                    fontWeight: 700
                                }}>
                                    Общие настройки
                                </div>
                                <Col className={'rr-form-item user-profile-col'} style={{padding: '20px 40px 14px'}}>
                                    <div className={'rr-label'}>
                                        Разрешить отправку уведомлений
                                    </div>
                                    <div className='rr-value  user-profile-block'
                                         style={{display: 'flex', alignItems: 'center', paddingBottom: '10px'}}>
                                        {
                                            this.state.globalEnabled ?
                                                (
                                                    <>
                                                        <Icon component={IconCheck} style={{
                                                            fontSize: '20px',
                                                            color: '#34BFA3',
                                                            marginBottom: '10px',
                                                            marginRight: '15px'
                                                        }}/>
                                                        <h3 className="user-profile-h3" style={{
                                                            fontSize: '18px',
                                                            fontWeight: 700,
                                                            color: '#575962'
                                                        }}> {localize(LocalizationEnum.ASPECT__GLOBAL__YES)}</h3>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <Icon component={IconClose} style={{
                                                            fontSize: '20px',
                                                            color: '#F4516C',
                                                            marginBottom: '10px',
                                                            marginRight: '15px'
                                                        }}/>
                                                        <h3 className="user-profile-h3" style={{
                                                            fontSize: '18px',
                                                            fontWeight: 700,
                                                            color: '#575962'
                                                        }}>{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</h3>
                                                    </>
                                                )
                                        }
                                    </div>
                                    <div className={'rr-label'}>
                                        Отправка уведомлений о собственных действиях
                                    </div>
                                    <div className='rr-value  user-profile-block'
                                         style={{display: 'flex', alignItems: 'center'}}>
                                        {
                                            this.state.globalShowOwnActions ?
                                                (
                                                    <>
                                                        <Icon component={IconCheck} style={{
                                                            fontSize: '20px',
                                                            color: '#34BFA3',
                                                            marginBottom: '10px',
                                                            marginRight: '15px'
                                                        }}/>
                                                        <h3 className="user-profile-h3" style={{
                                                            fontSize: '18px',
                                                            fontWeight: 700,
                                                            color: '#575962'
                                                        }}>Включена</h3>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <Icon component={IconClose} style={{
                                                            fontSize: '20px',
                                                            color: '#F4516C',
                                                            marginBottom: '10px',
                                                            marginRight: '15px'
                                                        }}/>
                                                        <h3 className="user-profile-h3" style={{
                                                            fontSize: '18px',
                                                            fontWeight: 700,
                                                            color: '#575962'
                                                        }}>Выключена</h3>
                                                    </>
                                                )
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card>

                        <Card bodyStyle={{padding: '0px', marginBottom: '17px',}}>
                            <Row>
                                <div className="user-profile-header" style={{
                                    borderBottom: '1px solid #e4e5ec',
                                    padding: '13px',
                                    paddingLeft: '40px',
                                    fontSize: '18px',
                                    fontWeight: 700
                                }}>
                                    Настройки уведомлений по компаниям
                                </div>
                            </Row>
                        </Card>
                    </div>
                    <div className='notification-col' style={{width: '100%'}}>
                        {viewSettingBusinessAccounts}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        location: storeState.router.location
    };
};

const mapDispatchToProps = {userProfilePopover, getBusinessAccounts, push};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(UserProfileNotifications));

import {InstanceTrackingTypeCodeEnum, ProjectTypeCodeEnum} from '../../../../../../server/api';
import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../../../components/select/EntityRemoteSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Icon, Select } from 'antd';
import React from 'react';
import { getOperationStatusTypeData } from '../../../../operationForm/utils/utils';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../../components/select/problemsFilter';
import { StatusesFilter, StatusesFilterProps } from '../../../../../../components/select/StatusesFilters/v1/StatusesFilter';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { getStoreState } from '../../../../../../../index';
import { HideValue } from './elementsCalendarFilters.data';
import { CalendarCategoriesSelect } from '../../../components/CalendarCategoriesSelect';

export const elementsCalendarFilters: FormFieldsGroup[] = [
    {
        fields: [
            {
                id: 'productInstanceTrackingTypeCode',
                type: FormItemType.Hidden,
                defaultValue: undefined,
            },
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Component,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                component: StatusesFilter,
                componentProps: (form: WrappedFormUtils): StatusesFilterProps => ({
                    type: 'all',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                className: 'rr-select-gray',
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'all',
                }),
            },
            {
                label: 'Скрыть',
                id: 'hide',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                values: () => {
                    const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());

                    const values: {
                        name: string | undefined;
                        value: HideValue | undefined;
                    }[] = [
                        { name: 'Неактивные', value: HideValue.INACTIVE },
                        { name: 'Отмененные', value: HideValue.CANCELLED },
                        { name: 'Отмененные и черновики', value: HideValue.CANCELED_DRAFT },
                    ];

                    if (subrentModuleEnabled) {
                        values.push(
                            { name: undefined, value: undefined },
                            { name: 'Все по аренде', value: HideValue.RENT },
                            { name: 'Все по субаренде', value: HideValue.SUBRENT }
                        );
                    }

                    return values;
                },
            },
        ],
    },
    {
        fields: [
            {
                label: 'Контрагент',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A8,
                id: 'renterId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    getEntityOperationName: 'getRenterById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                }),
                getInitialValue: (value) => {
                    return value;
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ projectId: undefined });
                    form.setFieldsValue({ operationId: undefined });
                    form.setFieldsValue({ subrentId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A9,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    let renterId = form.getFieldValue('renterId');
                    if (renterId?.key) renterId = renterId.key;
                    const filters = [`projectType;IN;${ProjectTypeCodeEnum.BASE}`];
                    if(renterId) filters.push('renterId;EQ;' + renterId);
                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        subrentId: undefined,
                        operationId: undefined,
                    });
                },
            },
            {
                label: 'Поставка',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'subrentId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    let renterId = form.getFieldValue('renterId');
                    if (renterId?.key) renterId = renterId.key;
                    return {
                        operationName: 'listSubrents',
                        nameField: 'shortName',
                        getEntityOperationName: 'getSubrentById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: renterId ? ['supplierId;EQ;' + renterId] : undefined,
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        projectId: undefined,
                        operationId: undefined,
                    });
                },
                visible: () => {
                    const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());
                    return Boolean(subrentModuleEnabled);
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATION,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A10,
                id: 'operationId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    let filters: string[] | undefined;
                    if (form.getFieldValue('projectId')) {
                        filters = ['projectId;EQ;' + (form.getFieldValue('projectId').key || form.getFieldValue('projectId'))];
                    } else if (form.getFieldValue('subrentId')) {
                        filters = ['subrentId;EQ;' + (form.getFieldValue('subrentId').key || form.getFieldValue('subrentId'))];
                    }

                    return {
                        operationName: 'listOperations',
                        nameField: 'mnemoKey',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getOperationById',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: filters,
                        renderer: (data, index) => {
                            let typeData = getOperationStatusTypeData(data.data.typeCode);
                            return (
                                <Select.Option key={data['id']}>
                                    <Icon
                                        style={{ fontSize: 20, position: 'relative', top: 2, marginRight: 10 }}
                                        className={'rr-operation-status-color-' + data.data.typeCode}
                                        component={typeData.iconComponent}
                                    />

                                    {data['data']['standard']}
                                    {data['name']}
                                </Select.Option>
                            );
                        },
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?, currentValues?) => {
                    return currentValues?.operationId || Boolean(getFieldValue('projectId') || getFieldValue('subrentId'));
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__KIT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A11,
                id: 'kitId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listKits',
                    nameField: 'shortName',
                    getEntityOperationName: 'getKitById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                }),
                getInitialValue: (value) => {
                    return value;
                },
                onChange: async (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        productId: undefined,
                        variantId: undefined,
                        instanceId: undefined,
                        productInstanceTrackingTypeCode: undefined,
                    });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PRODUCT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A11,
                id: 'productId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listProducts',
                    nameField: 'shortName',
                    getEntityOperationName: 'getProductById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                }),
                getInitialValue: (value) => {
                    return value;
                },
                onChange: async (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        variantId: undefined,
                        instanceId: undefined,
                        kitId: undefined,
                        productInstanceTrackingTypeCode: value?.data?.instanceTrackingTypeCode,
                    });
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A12,
                id: 'variantId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        operationName: 'listProductVariants',
                        nameField: 'name',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductVariantById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?: WrappedFormUtils, currentValues?) => {
                    let instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.variantId ||
                        getFieldValue('variantId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ instanceId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A13,
                id: 'instanceId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    const variantId = form.getFieldValue('variantId')
                        ? form.getFieldValue('variantId').key || form.getFieldValue('variantId')
                        : undefined;
                    return {
                        operationName: 'listProductInstances',
                        nameField: 'nameOrCode',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductInstanceById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                        filters: variantId ? ['variantId;EQ;' + variantId] : undefined,
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?: WrappedFormUtils, currentValues?) => {
                    let instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.instanceId ||
                        getFieldValue('instanceId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    if (!form.getFieldValue('variantId') && value && value.data && value.data.variantId) {
                        form.setFieldsValue({ variantId: value.data.variantId });
                    }
                    if (!value) {
                        if (form.getFieldValue('problem') === 4) {
                            form.setFieldsValue({ problem: undefined });
                        }
                    }
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: CalendarCategoriesSelect,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER),
            },
        ],
    },
];

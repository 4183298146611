import React, { useMemo, useState } from 'react';
import { TimetableTypeCodeEnum } from '../../../../../../server';
import { ArrayUtils } from '../../../../../../core/utils/arrayUtils';
import { getDefaultAvailableStacks } from '../utils/getAvailableStacks';
import { useAppSelector } from '../../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { AccessibilityMapFilters } from '../filters/accessibility/accessibilityMapCalendarFilters.types';

const getKitDisabledStacks = (subrentModuleEnabled: boolean | undefined): TimetableTypeCodeEnum[] =>
    (
        [
            TimetableTypeCodeEnum.ORDER,
            TimetableTypeCodeEnum.OCCUPIED,
            subrentModuleEnabled && TimetableTypeCodeEnum.SUBRENTED,
            TimetableTypeCodeEnum.MAINTENANCE,
        ] as TimetableTypeCodeEnum[]
    ).filter(Boolean);

export const useCurrentStacks = (
    pageParams: AccessibilityMapFilters
): [TimetableTypeCodeEnum[], React.Dispatch<React.SetStateAction<TimetableTypeCodeEnum[]>>] => {
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const kitDisabledStacks = useMemo(() => getKitDisabledStacks(subrentModuleEnabled), [subrentModuleEnabled]);

    const defaultBaseStacks = useMemo(
        () =>
            ArrayUtils.removeDuplicates([
                TimetableTypeCodeEnum.AVAILABLE,
                ...(pageParams.stacks ?? getDefaultAvailableStacks(subrentModuleEnabled)),
            ]),
        [pageParams.stacks, subrentModuleEnabled]
    );

    const [stacks, setStacks] = useState<TimetableTypeCodeEnum[]>(defaultBaseStacks);

    const currentStacks = useMemo(
        () => (pageParams.kitId ? stacks.filter((stack) => !kitDisabledStacks.includes(stack)) : stacks),
        [kitDisabledStacks, pageParams.kitId, stacks]
    );

    return useMemo(
        () => [currentStacks, setStacks] as [TimetableTypeCodeEnum[], React.Dispatch<React.SetStateAction<TimetableTypeCodeEnum[]>>],
        [currentStacks]
    );
};

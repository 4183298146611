import React, { FC, useCallback } from 'react';
import moment from 'moment';
import { CollapsibleBlock, CollapsibleBlockProps } from '../../../../components/v2/collapsibleBlock/CollapsibleBlock';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { EntityTypeCodeEnum, LegalDetailsFieldTypeCodeEnum, LegalDetailsInfoRead } from '../../../../server';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import {
    getRentIndicatorsBlockSettingsSelector,
    RentIndicatorsBlockSettings,
    updateRentIndicatorsBlockSettings,
} from '../../../../shared/reducers/userSettings/userSettings.reducer';
import { DataDisplayBlock, DataDisplayItemProps, DataDisplayItemType } from '../../../../components/dataDisplay';
import { LocalizationEnum, localize } from '../../../../localization';
import {
    legalCountriesNamesMap,
    legalDetailsActionsMap,
    LegalDetailsActionType,
    legalDetailsFieldNamesMap,
    legalSubjectNamesMap,
} from '../data/legalDetailsData';
import { useLocalize } from '../../../../core/hooks/useLocalize';
import { useGetLegalDetailsFieldsListByCountryQuery } from '../api/legalDetails.api';
import { LegalDetailsModalProps } from '../modal/legalDetailsModal';
import { ActionButtonsInlineGroup } from '../../../../components/v2/actionButtons/actionButtonsInlineGroup/actionButtonsInlineGroup';
import './legalDetailsBlock.less';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';

export interface LegalDetailsBlockProps {
    data?: LegalDetailsInfoRead;
    onAction: (action: LegalDetailsActionType) => void;
    entityType: LegalDetailsModalProps['entityType'];
    storageKey: `businessAccountId-legalDetails-${number}` | 'counterparty-legalDetails';
}

export const LegalDetailsBlock: FC<LegalDetailsBlockProps> = ({ data, onAction, entityType, storageKey }) => {
    const L = useLocalize();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const dispatch = useAppDispatch();
    const settings: RentIndicatorsBlockSettings | undefined = useAppSelector(getRentIndicatorsBlockSettingsSelector)[storageKey];
    const collapsed = data ? settings?.collapsed : true;
    const getFieldsQuery = useGetLegalDetailsFieldsListByCountryQuery(
        {
            businessAccountId,
            legalCountryTypeCode: data?.country!,
        },
        { skip: data?.country === undefined }
    );

    const onCollapse: CollapsibleBlockProps['onCollapse'] = useCallback(
        (collapsed) => {
            dispatch(updateRentIndicatorsBlockSettings({ [storageKey]: { collapsed: collapsed } }));
        },
        [dispatch, updateRentIndicatorsBlockSettings, storageKey]
    );

    const groups: DataDisplayItemProps[][] = [];

    if (data) {
        groups.push([
            {
                label: 'Тип',
                type: DataDisplayItemType.String,
                value: data?.subjectType ? L(legalSubjectNamesMap[data?.subjectType]) : undefined,
                emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                style: {
                    paddingRight: 18,
                },
            },
            {
                label: 'Страна',
                type: DataDisplayItemType.String,
                value: data?.country ? legalCountriesNamesMap[data?.country] : undefined,
                emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
            },
        ]);
    }

    let fields = getFieldsQuery.data?.find((item) => item.subject === data?.subjectType)?.fields;
    fields = fields?.filter((f) => !f.financial || (f.financial && canViewFinancialData));

    fields?.map((field, index, arr) => {
        const prevField = arr[index - 1];

        let group: DataDisplayItemProps[];
        if (field.maxLength && field.maxLength > 30) {
            group = [];
            groups.push(group);
        } else {
            if (prevField && prevField.maxLength && prevField.maxLength > 30) {
                group = [];
                groups.push(group);
            } else {
                group = groups[groups.length - 1];
                if (group.length > 1) {
                    group = [];
                    groups.push(group);
                }
            }
        }

        let value = data?.fields?.find((f) => f.name === field.name)?.value;
        if (value && (field.name === LegalDetailsFieldTypeCodeEnum.BIRTHDATE || field.name === LegalDetailsFieldTypeCodeEnum.IDENTITYDOCUMENTEXPIRATIONDATE)) {
            value = moment(value).format('D MMMM YYYY');
        } else if (value && field.name === LegalDetailsFieldTypeCodeEnum.SEX) {
            if (value === 'M') value = L(LocalizationEnum.ASPECT__GLOBAL__SEX__MALE);
            else if (value === 'F') value = L(LocalizationEnum.ASPECT__GLOBAL__SEX__FEMALE);
        }

        group.push({
            label: L(legalDetailsFieldNamesMap[field.name] as LocalizationEnum),
            type: DataDisplayItemType.String,
            value: value,
            emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
        });
    });

    const withData = Boolean(data);
    let title: String | undefined;

    if (withData && entityType === EntityTypeCodeEnum.BUSINESSACCOUNT) {
        title = data?.fullName;
    } else {
        title = withData ? 'Реквизиты' : 'Реквизиты не указаны';
    }

    return (
        <CollapsibleBlock
            collapsed={collapsed}
            onCollapse={onCollapse}
            icon={<SystemIcon type={SystemIconType.legalAttributes} />}
            title={title}
            titleStyle={withData && entityType === EntityTypeCodeEnum.BUSINESSACCOUNT ? { fontWeight: 'bold' } : undefined}
            disabled={!withData}
            className={'rr-LegalDetailsBlock'}
            extraContent={
                <>
                    <ActionButtonsInlineGroup
                        data={legalDetailsActionsMap}
                        mainActions={[
                            !withData && entityType === EntityTypeCodeEnum.RENTER ? LegalDetailsActionType.create : undefined,
                            withData ? LegalDetailsActionType.edit : undefined,
                            withData ? LegalDetailsActionType.delete : undefined,
                        ]}
                        onAction={(action) => {
                            onAction(action);
                        }}
                    />
                </>
            }
        >
            <DataDisplayBlock paddings={false} data={groups} />
        </CollapsibleBlock>
    );
};

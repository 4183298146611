import { Popover } from 'antd';
import React, { FC } from 'react';
import { AdditionalEntityActionsButton } from '../additionalEntityActionsButton';
import { ProjectRecord, TagEntityTypeCode } from '../../../server';
import { TagEntity, TagsChangeAction } from './TagsChangeAction/TagsChangeAction';

interface NestedChangeTagsButtonProps {
    entities: TagEntity[];
    tagType: TagEntityTypeCode;
    clearSelection: () => void;
    onSuccess?: () => void;
}

export const NestedAdditionalEntityActionsForTags: FC<NestedChangeTagsButtonProps> = (props) => {
    const { entities, tagType, clearSelection, onSuccess } = props;

    return (
        <Popover
            overlayClassName={'rr-grid-actions-popover'}
            autoAdjustOverflow
            arrowPointAtCenter
            placement="bottomLeft"
            trigger="click"
            content={
                <>
                    <div className={'rr-grid-actions-popover-header1'}></div>
                    <TagsChangeAction entities={entities} tagType={tagType} clearSelection={clearSelection} onSuccess={onSuccess} />
                </>
            }
        >
            <AdditionalEntityActionsButton />
        </Popover>
    );
};

import React, {FC} from 'react';
import {Card, Col, Row} from 'antd';
import {LocalizationEnum, localize} from '../../../../../../../../../../../localization';
import {AbstractEntityInfoCard, FormValue} from '../../../../../../../../../../../components';
import {PricingBlock} from '../PricingBlock/PricingBlock';
import {AttachmentsBlock} from '../../../../../../../../../../../components/attachmentsBlock/attachmentsBlock';
import {AttachmentParentEntityTypeCodeEnum, CrewMemberInfoRead} from '../../../../../../../../../../../server';
import {RichTextView} from '../../../../../../../../../../../components/richTextView/RichTextView';
import {loadCrewMember} from '../../../../../../reducers/crewMember/crewMember.reducer';
import {businessAccountIdSelector} from '../../../../../../../../../../../shared/reducers/system.reducer';
import {useAppDispatch, useAppSelector} from '../../../../../../../../../../../store/hooks';
import './InfoBlock.less';
import {canViewFinancialDataSelector} from '../../../../../../../../../../../shared/reducers/permissions.reducer';
import classNames from "classnames";

interface InfoBlockProps {
    entity: CrewMemberInfoRead;
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
    const { entity } = props;
    const dispatch = useAppDispatch();

    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <Col xxl={18} xl={16} lg={14} span={24}>
            <Card bordered={false} className={'rr-card-without-padding'}>
                <Row className={'container'}>
                    <Col>
                        <Row gutter={16} type={'flex'}>
                            <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-right-border'}>
                                <Row className={'description-row'}>
                                    <Col span={24} className={'rr-form-item'}>
                                        <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}</div>
                                        <div className={'rr-text'}>
                                            <FormValue
                                                value={entity.description && <RichTextView value={entity.description} />}
                                                emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xxl={12} xl={24} lg={24} span={24}
                                 style={{ backgroundColor: '#f0f2f5' }}>
                                <Row className={"right-block"}>
                                    {canViewFinancialData && (
                                        <>
                                            <Col className={'prime-rate-col'}>
                                                <Row className={'prime-rate-row'}>Базовая ставка</Row>
                                            </Col>
                                            <PricingBlock entity={entity} />
                                        </>
                                    )}
                                    <Col className={classNames('attachments-col', {
                                        "no-finances": !canViewFinancialData
                                    })} >
                                        <AttachmentsBlock
                                            parentEntityId={entity.id}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.CREWMEMBER}
                                            attachments={entity.attachments?.records || []}
                                            updateParentEntity={() => {
                                                if (entity) {
                                                    dispatch(
                                                        loadCrewMember({
                                                            businessAccountId: businessAccountId,
                                                            entityId: entity.id,
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <AbstractEntityInfoCard leftBlockSpanLG={24} data={entity} style={{ marginTop: 32 }} />
        </Col>
    );
};

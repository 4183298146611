import React from 'react';
import { IconFileInvoiceDollarSolid } from '../../../../icons';
import { Icon } from 'antd';
import { DocumentTypeCodeEnum } from '../../../../../server/api';
import { DownloadDocumentPopover } from '../../DownloadDocumentPopover';
import { availableFileTypes, options, ProductsPriceListOnDownload } from './utils/data';

interface IProps {
    onDownload: ProductsPriceListOnDownload;
    storageKey: 'productsPriceList'|'kitsPriceList';
}

export class ExportProductsPriceListPopover extends React.Component<IProps> {
    render() {
        return (
            <DownloadDocumentPopover
                documentType={DocumentTypeCodeEnum.PRICELIST}
                availableFileTypes={[...availableFileTypes]}
                storageKey={this.props.storageKey}
                options={options}
                title={
                    <>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconFileInvoiceDollarSolid} />
                        <div>Скачать прайс-лист</div>
                    </>
                }
                onDownload={this.props.onDownload}
                withTemplate
                withDiscount
                showLegalDetails
                isCompact
            >
                <span className={'rr-grid-export-button'}>
                    <Icon component={IconFileInvoiceDollarSolid} />
                    <span>Прайс-лист</span>
                </span>
            </DownloadDocumentPopover>
        );
    }
}

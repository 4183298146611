import React from 'react';
import { VehicleInfoRead } from '../../../../../../../server';
import { DataDisplayItem, DataDisplayItemProps, DataDisplayItemType } from '../../../../../../../components/dataDisplay';
import { Col } from 'antd';
import classNames from 'classnames';
import { GROUP_ROW_CLASSNAME } from '../../../../../../../components/dataDisplay/dataDisplayGroup';
import { DrawerAvatar } from '../../../../../../../components/v2/entityDrawer/components/DrawerAvatar';
import { MetricUtils } from '../../../../../../../core/utils/metricUtils';
import {
    DataDisplayLinkProps,
    DataDisplayNumberProps,
    DataDisplayStringProps,
} from '../../../../../../../components/dataDisplay/dataDisplayItemProps';
import { PageUtils } from '../../../../../../../shared/util/pageUtils';
import { useAppSelector } from '../../../../../../../store/hooks';
import { toOptionalFixed } from '../../../../../../../shared/util/utils5';
import { vehicleLoadingTypeMap } from '../../utils/enumMappers';
import { logisticsPageUrlRoute } from '../../../../data/tabs';

import { LogisticsPageTabsEnum } from '../../../../../../../shared/constants/tabEnums';

export const useDisplayItems = (vehicle?: VehicleInfoRead) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const mainDisplayBlock: DataDisplayItemProps[][] = [
        [
            {
                type: DataDisplayItemType.Custom,
                value: (
                    <div className={'contact-main-block'}>
                        <DrawerAvatar image={vehicle?.image} />
                        <div className={classNames(GROUP_ROW_CLASSNAME, 'items-gap')}>
                            <Col span={24}>
                                <DataDisplayItem label={'Наименование'} type={DataDisplayItemType.String} value={vehicle?.shortName} />
                            </Col>
                            <Col span={24}>
                                <DataDisplayItem label={'Номер'} type={DataDisplayItemType.String} value={vehicle?.officialNumber} />
                            </Col>
                        </div>
                    </div>
                ),
            },
        ],
    ];

    const threeDimensionsDisplayItem: DataDisplayItemProps = (function () {
        const dimensionsData = [
            vehicle?.length ? MetricUtils.fromMillimetersTiMeters(vehicle?.length) : null,
            vehicle?.width ? MetricUtils.fromMillimetersTiMeters(vehicle?.width) : null,
            vehicle?.height ? MetricUtils.fromMillimetersTiMeters(vehicle?.height) : null,
        ];

        const value = dimensionsData.some((value) => value != null)
            ? dimensionsData.map((value) => (value != null ? String(value) : '?')).join(' x ')
            : undefined;

        return {
            label: 'Длина, ширина, высота, м',
            type: DataDisplayItemType.String,
            value,
        };
    })();

    const transportationCountDisplayItem: DataDisplayLinkProps | DataDisplayNumberProps =
        vehicle && vehicle.transportationCount > 0
            ? {
                  label: 'Количество перевозок',
                  type: DataDisplayItemType.Link,
                  value: {
                      text: String(vehicle.transportationCount),
                      link: PageUtils.getPathForPushOnPageTab(
                          `/${businessAccountId}/${logisticsPageUrlRoute}`,
                          LogisticsPageTabsEnum.TRANSPORTATIONS,
                          vehicle?.type
                              ? {
                                    type: vehicle.type,
                                }
                              : undefined
                      ),
                      isRoute: true,
                  },
              }
            : {
                  label: 'Количество перевозок',
                  type: DataDisplayItemType.Number,
                  value: vehicle?.transportationCount,
              };

    const vehicleVolumeDisplayItem: DataDisplayStringProps = {
        label: 'Объем, м³',
        type: DataDisplayItemType.String,
        value:
            vehicle?.width && vehicle?.height && vehicle?.length
                ? toOptionalFixed(
                      (vehicle?.width ? MetricUtils.fromMillimetersTiMeters(vehicle?.width) : 1) *
                          (vehicle?.height ? MetricUtils.fromMillimetersTiMeters(vehicle?.height) : 1) *
                          (vehicle?.length ? MetricUtils.fromMillimetersTiMeters(vehicle?.length) : 1),
                      2
                  )
                : undefined,
    };

    const loadingTypesDisplayItem: DataDisplayStringProps = {
        label: 'Погрузка',
        type: DataDisplayItemType.String,
        value: vehicle?.loadingTypes?.length ? vehicle.loadingTypes.map((type) => vehicleLoadingTypeMap[type]).join(', ') : undefined,
    };

    return {
        mainDisplayBlock,
        threeDimensionsDisplayItem,
        transportationCountDisplayItem,
        vehicleVolumeDisplayItem,
        loadingTypesDisplayItem,
    } satisfies Record<string, DataDisplayItemProps | DataDisplayItemProps[][]>;
};

import { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';
import { CalendarLineUtils } from '../../../../../../../../utils/calendarLineUtils';
import moment from 'moment';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { isGiveawayDelayProblemForItemElement } from '../../../../../../../../../../tabs/elements/utils/checks/isGiveawayDelayProblemForRentElement';

interface SubrentShipmentDelayBorderProps {
    width: number;
}

export const SubrentShipmentDelayBorder: FC<SubrentShipmentDelayBorderProps> = (props) => {
    const { width } = props;
    const { item, itemContext } = useContext(ElementItemContext);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const { stateCode, end_time } = item;

    const fromStartToNowWidth = CalendarLineUtils.ratio.sublineWidth(
        item,
        itemContext,
        visibleTimeStart,
        CalendarLineUtils.difference.fromStartToNow(item, visibleTimeStart)
    );
    const fromEndToNowWidth = CalendarLineUtils.ratio.sublineWidth(
        item,
        itemContext,
        visibleTimeStart,
        CalendarLineUtils.difference.fromEndToNow(item, visibleTimeStart, visibleTimeEnd)
    );


    const startsAfterLeftBoundary = +item.start_time > visibleTimeStart;
    const endsBeforeRightBoundary = +item.end_time < visibleTimeEnd;

    const baseBorder = `2px solid ${CalendarColors.staticColors.DELAY_COLOR}`;
    const baseBorderRadius = CALENDAR_LINE_ROUND_RADIUS;
    const tailBorder = `2px solid ${CalendarColors.elementsStateCodeMap[stateCode]}`;
    const tailBorderRadius = CALENDAR_LINE_ROUND_RADIUS;

    const hasTail = CalendarLineUtils.check.hasTail(item, visibleTimeStart, visibleTimeEnd);
    //const isGiveawayDelayProblem = isGiveawayDelayProblemForItemElement(item);

    return (
        <>
            <div
                className={'calendar-line-border'}
                style={{
                    clipPath: +moment() > +end_time ? undefined : `inset(0 ${fromEndToNowWidth}px 0 0)`,
                    border: baseBorder,
                    borderRadius: baseBorderRadius,
                    borderLeft: startsAfterLeftBoundary ? baseBorder : 'none',
                    borderTopLeftRadius: startsAfterLeftBoundary ? baseBorderRadius : 0,
                    borderBottomLeftRadius: startsAfterLeftBoundary ? baseBorderRadius : 0,
                    borderRight: endsBeforeRightBoundary ? baseBorder : 'none',
                    borderTopRightRadius: endsBeforeRightBoundary && !hasTail ? baseBorderRadius : 0,
                    borderBottomRightRadius: endsBeforeRightBoundary && !hasTail ? baseBorderRadius : 0,
                    //borderTopRightRadius: endsBeforeRightBoundary && !isGiveawayDelayProblem ? baseBorderRadius : 0,
                    //borderBottomRightRadius: endsBeforeRightBoundary && !isGiveawayDelayProblem ? baseBorderRadius : 0,
                    //width,
                }}
            />
            {+end_time > +moment() && (
                <div
                    className={'calendar-line-border'}
                    style={{
                        clipPath: `inset(0 0 0 ${fromStartToNowWidth}px)`,
                        border: tailBorder,
                        borderRadius: tailBorderRadius,
                        borderLeft: startsAfterLeftBoundary ? tailBorder : 'none',
                        borderTopLeftRadius: startsAfterLeftBoundary ? tailBorderRadius : 0,
                        borderBottomLeftRadius: startsAfterLeftBoundary ? tailBorderRadius : 0,
                        borderRight: endsBeforeRightBoundary ? tailBorder : 'none',
                        borderTopRightRadius: endsBeforeRightBoundary ? tailBorderRadius : 0,
                        borderBottomRightRadius: endsBeforeRightBoundary ? tailBorderRadius : 0,
                        //width,
                    }}
                />
            )}
        </>
    );
};

import { createContext } from 'react';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import {
    AccessibilityMapCalendarItem,
    ActivityFramesCalendarItem,
    CalendarItem,
    ElementsCalendarItem,
    WorkPlanningsCalendarItem,
} from '../../Calendar/types/items';
import { CalendarDisplayType } from '../../Calendar/utils/data/calendarConstants';

export const ElementItemContext = createContext<ReactCalendarItemRendererProps<CalendarItem<ElementsCalendarItem>>>(
    {} as ReactCalendarItemRendererProps<CalendarItem<ElementsCalendarItem>>
);
export const ActivityFrameItemContext = createContext<ReactCalendarItemRendererProps<CalendarItem<ActivityFramesCalendarItem>>>(
    {} as ReactCalendarItemRendererProps<CalendarItem<ActivityFramesCalendarItem>>
);
export const WorkPlanningItemContext = createContext<ReactCalendarItemRendererProps<CalendarItem<WorkPlanningsCalendarItem>>>(
    {} as ReactCalendarItemRendererProps<CalendarItem<WorkPlanningsCalendarItem>>
);
export const AccessibilityMapItemContext = createContext<ReactCalendarItemRendererProps<CalendarItem<AccessibilityMapCalendarItem>>>(
    {} as ReactCalendarItemRendererProps<CalendarItem<AccessibilityMapCalendarItem>>
);
export const DisplayTypeItemContext = createContext<CalendarDisplayType>(CalendarDisplayType.NORMAL);

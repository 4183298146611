import { DrawerUtils } from '../../../../../../shared/util/drawerUtils';
import { DataDisplayItemProps, DataDisplayItemType } from '../../../../../../components/dataDisplay';
import { LocationInfoRead } from '../../../../../../server';
import { DataDisplayLinkProps, DataDisplayStringProps } from '../../../../../../components/dataDisplay/dataDisplayItemProps';

export const useDisplayItems = (location?: LocationInfoRead) => {
    const coordinatesDisplayItem = (function () {
        const coordinatesLinkObject =
            location?.latitude && location?.longitude
                ? {
                      type: DataDisplayItemType.Link as DataDisplayItemType.Link,
                      value: {
                          text: DrawerUtils.getCoordinates(location.longitude, location.latitude),
                          link: `https://yandex.ru/maps/?ll=${location.longitude},${location.latitude}&mode=whatshere&whatshere[point]=${location.latitude},${location.longitude}&whatshere[zoom]=18&z=18`,
                      },
                  }
                : undefined;

        const coordinatesStringObject = {
            type: DataDisplayItemType.String as DataDisplayItemType.String,
            value: location?.latitude || location?.longitude ? `${location?.latitude ?? '?'}, ${location?.longitude ?? '?'}` : undefined,
        };

        const currentProps: DataDisplayLinkProps | DataDisplayStringProps = coordinatesLinkObject ?? coordinatesStringObject;

        return {
            label: 'Координаты',
            ...currentProps,
        };
    })();

    return {
        coordinatesDisplayItem,
    } satisfies Record<string, DataDisplayItemProps>;
};

import { EntityGridProps } from '../../../../../components/page/entityGrid/types/grid';
import { useCallback, useEffect, useMemo } from 'react';
import { ProductsListQueryParams } from '../api/products.api.types';
import { CategoryTypeCodeEnum } from '../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { loadCategories } from '../../../../../shared/reducers/entities.reducer';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { useEntityGridContext } from '../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useCategoryIds } from './useCategoryIds';
import { PageUrlParamsObject } from '../../../../../core/hooks/urlParams/types';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';
import { InventoryPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { kitsApi } from '../../kits/api/kits.api';

export const useCategoriesProps = (
    pageParamsObject: PageUrlParamsObject<ProductsListQueryParams>
): EntityGridProps<never, never, never>['categoriesProps'] => {
    const { pageParams, updatePageParams } = pageParamsObject;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();

    const categoryIds = useCategoryIds(pageParams);

    const categoriesOnSelect = useCallback(
        (selectedKeys?: string[]) => {
            if (selectedKeys == null) return;

            const categoryIds = selectedKeys.map(Number);

            gridRef?.current?.clearSelection();
            ParamsUtils.setGridStorageDataParamsFilters(
                InventoryPageTabsEnum.KITS,
                {
                    categoryIds: categoryIds.map(String).toString(),
                },
                true
            );
            kitsApi.util.invalidateTags(['KitsList', 'KitsAvailableFiltersList']);
            updatePageParams({
                page: 1,
                categoryIds,
            });
        },
        [gridRef, updatePageParams]
    );

    useEffect(() => {
        dispatch(loadCategories(businessAccountId, CategoryTypeCodeEnum.PRODUCT));
    }, [businessAccountId, dispatch]);

    return useMemo(
        () =>
            ({
                categoriesType: 'PRODUCT',
                categoriesFilterOffsetBottom: 131,
                categoriesSelectedKeys: categoryIds,
                categoriesOnSelect: categoriesOnSelect,
                categoriesFilter: true,
            } satisfies EntityGridProps<never, never, never>['categoriesProps']),
        [categoriesOnSelect, categoryIds]
    );
};

import { ContactInfoRead } from '../../../../../../../server';
import { IModalFormFilters } from '../../../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../../../components/modalForm/components/Header/types/displayData';
import {
    allFieldsDisplayGroup,
    mainFieldsDisplayGroup,
} from '../../../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof ContactInfoRead> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['lastName', 'firstName']],
    },
];

export const createCategoryModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editCategoryModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

import { useMemo } from 'react';
import _ from 'lodash';
import { CalendarGridParams } from '../types/params';
import { URLDrawerParams } from '../../../../../../components/page/entityGrid/types/params';

export const useCalendarQueryParams = <Params extends object>(params: Params & CalendarGridParams & URLDrawerParams) => {
    return useMemo(() => {
        const queryParams = _.cloneDeep(params);
        delete queryParams.id;
        delete queryParams.modal;
        delete queryParams.view;
        delete queryParams.displayType;
        return queryParams;
    }, [params]);
};

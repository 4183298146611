import { FC } from 'react';
import { SideBarGroup } from '../../utils/types';
import { workPlanningsCalendarSortValueToItemName } from '../../../../../../../../utils/data/sortData';
import { WorkPlanningsSplittedCalendarSidebarGroup } from '../../WorkPlanningsSplittedCalendarSidebarGroup';
import { getStatusIcon, workPlanningStateCodeToNameMap } from '../../../../../../../../utils/data/statusData';
import { CalendarDisplayType } from '../../../../../../../../../../components/Calendar/utils/data/calendarConstants';

interface StatusSidebarGroupProps extends SideBarGroup {}

export const StatusSidebarGroup: FC<StatusSidebarGroupProps> = (props) => {
    const { isCollapsed, onClick, workPlannings, displayType } = props;

    const titleKey = workPlanningsCalendarSortValueToItemName['status'];
    const title = workPlanningStateCodeToNameMap[workPlannings[0][titleKey]];

    const { stateCode } = workPlannings[0];

    const isCompact = displayType === CalendarDisplayType.COMPACT;

    const iconSize = isCompact ? 8 : 11;

    return (
        <WorkPlanningsSplittedCalendarSidebarGroup
            workPlannings={workPlannings}
            displayType={displayType}
            onClick={onClick}
            isCollapsed={isCollapsed}
            title={title}
            subtitle={undefined}
            iconComponent={
                <div style={{ width: iconSize, height: iconSize, display: 'flex', alignItems: 'center' }}>
                    {getStatusIcon(stateCode, iconSize)}
                </div>
            }
        />
    );
};

import React, { FC, useMemo, useState } from 'react';
import { DashboardData } from '../../../types/DashboardData';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { useDashboardGridState } from '../../../hooks/useDashboardGridState';
import Spin from '../../../../../../components/spin/spin';
import { useListDashboardQuery } from '../../../api/rent/dashboardApi';
import { Button, Card, Form, Icon } from 'antd';
import { DashboardList } from '../../dashboardsList';
import { dashboardSubRentColumnRight } from '../../../columns/dashboardColumns';
import { PeriodSelect } from '../../../components/PeriodSelect/PeriodSelect';
import { IconClose, IconStopwatchSolid } from '../../../../../../components/icons';
import { getStylesForSubrent } from '../left/utils/getStylesForSubrent';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { calendarPageUrlRoute } from '../../../../calendar/utils/tabs';
import { Avatar } from '../../../../../../components/avatar/avatar';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { getOperationStatusTypeData } from '../../../../operationForm/utils/utils';
import { OperationTypeCodeEnum } from '../../../../../../server';
import { DashboardApiUtils } from '../../../api/rent/dashboardApiUtils';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import {CalendarPageTabsEnum} from "../../../../../../shared/constants/tabEnums";

interface DashboardSubrentRightListProps {
    dashboardData: DashboardData;
}

const dashboardSubrentRightGridName: string = 'dashboard-elements-rent';

export const DashboardSubrentRightList: FC<DashboardSubrentRightListProps> = (props) => {
    const { dashboardData } = props;
    const intl = useIntl();
    const location = useLocation();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const showFinancialData = useAppSelector(canViewFinancialDataSelector);
    const gridData = getGridStorageData(dashboardSubrentRightGridName);
    const [isSubrentButtonSelected, setIsSubrentButtonSelected] = useState(gridData.filters?.isBtnSubrent === true);

    const { currentSubrentElementsCount: elementsCount, currentSubrentReturnToShipperDelay } = dashboardData;

    const [{ page, pageSize, period, sortBy, sortOrder }, { onPageChanged, onPeriodChange, onSetPeriod, onSortedChange }] =
        useDashboardGridState(dashboardSubrentRightGridName);

    const requestFilters = useMemo(
        () => [
            ...DashboardApiUtils.getCommonFilters(period),
            ...ServerUtils.createRequestFilters([
                ['stateCode', 'EQ', 'SUBRENT'],
                isSubrentButtonSelected && ['problemsAndWarnings.anyDelay', 'EQ', true],
            ]),
        ],
        [isSubrentButtonSelected, period]
    );

    const { data: dashboardListData, isLoading } = useListDashboardQuery(
        {
            intl,
            params: {
                pageSize,
                sortOrder,
                sortBy,
                page,
                period,
            },
            side: 'right',
            isSubrent: true,
            filters: requestFilters,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const onSubrentFilter = () => {
        setGridStorageDataFilters(dashboardSubrentRightGridName, { isBtnSubrent: !isSubrentButtonSelected });
        setIsSubrentButtonSelected((prev) => !prev);
        onPeriodChange();
    };

    const typeData = getOperationStatusTypeData(OperationTypeCodeEnum.RETURN);
    const stylesForBtnSubrent = getStylesForSubrent(isSubrentButtonSelected, currentSubrentReturnToShipperDelay, true);

    return (
        <Spin spinning={isLoading}>
            <div className={`dashboard-grid-header ${period ? 'dashboard-header-input-select' : 'dashboard-header-input'}`}>
                <Link
                    to={getPathFromState(`/${businessAccountId}/${calendarPageUrlRoute}`, '', {
                        tab: CalendarPageTabsEnum.ELEMENTS,
                        typeCode: 'SUBRENT_SHIPMENT_BOOKED',
                        group: 'startDate',
                        capture: 'all',
                    })}
                >
                    <div className="dashboard-header-avatar-button dashboard-header-avatar-button-right">
                        <Avatar size={48} style={{ marginRight: '20px', border: '4px solid #34BFA3', backgroundColor: '#fff' }}>
                            <div style={{ marginTop: '-3px' }}>
                                <Icon component={typeData.iconComponent} style={{ color: '#34BFA3' }} />
                            </div>
                        </Avatar>
                        <div>
                            <div>
                                <strong style={{ fontSize: '20px' }}>Возврат поставщику</strong>
                            </div>
                            <div>
                                {elementsCount} {localize(LocalizationEnum.ASPECT__GLOBAL__TOTAL_NUMBER)}
                            </div>
                        </div>
                    </div>
                </Link>

                <div style={{ marginTop: '12px' }} className="dashboard-filters-buttons">
                    <Button
                        type="danger"
                        style={stylesForBtnSubrent}
                        onClick={onSubrentFilter}
                        disabled={currentSubrentReturnToShipperDelay === 0 && !isSubrentButtonSelected}
                    >
                        <Icon component={IconStopwatchSolid} style={{ fontSize: '20px' }} />
                        <span style={{ fontWeight: 600 }}>{currentSubrentReturnToShipperDelay}</span>
                        {isSubrentButtonSelected ? <Icon component={IconClose} /> : null}
                    </Button>
                </div>

                <Form.Item style={{ marginRight: '30px', marginTop: '12px' }}>
                    <PeriodSelect period={period} onSetPeriod={onSetPeriod} />
                </Form.Item>
            </div>

            <Card bordered={false} className="grid-dashboard grid-dashboar-left">
                <DashboardList
                    businessAccountId={businessAccountId}
                    columns={dashboardSubRentColumnRight}
                    currentPage={page}
                    data={dashboardListData?.records}
                    defaultSortDesc={sortOrder}
                    defaultSorted={sortBy}
                    entities={dashboardListData?.records}
                    excludeColumns={showFinancialData ? undefined : ['finalTotalPrice']}
                    filtered={dashboardListData?.listAttributes.filteredCount}
                    gridName={dashboardSubrentRightGridName}
                    location={location}
                    onPageChanged={onPageChanged}
                    onSortedChange={onSortedChange}
                    parentType={'operation'}
                />
            </Card>
        </Spin>
    );
};

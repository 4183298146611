import React, { FC, memo } from 'react';
import { DataDisplayBlock, DataDisplayItemProps, DataDisplayItemType } from '../../../../../../../../components/dataDisplay';
import { DrawerAvatar } from '../../../../../../../../components/v2/entityDrawer/components/DrawerAvatar';
import { ProfessionInfoRead } from '../../../../../../../../server';
import { DrawerUtils } from '../../../../../../../../shared/util/drawerUtils';
import { CrewMemberUtils } from '../../../../crewMembers/utils/helpers';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { canViewFinancialDataSelector } from '../../../../../../../../shared/reducers/permissions.reducer';

interface ProfessionDataDisplayBlocks {
    profession: ProfessionInfoRead;
}

export const DataDisplayBlocks: FC<ProfessionDataDisplayBlocks> = memo((props) => {
    const { profession } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const creationAuthorFullName = profession?.creationAuthorFullName
        ? DrawerUtils.getFullName(profession.creationAuthorFullName)
        : undefined;

    return (
        <>
            <DataDisplayBlock
                data={[
                    [
                        {
                            type: DataDisplayItemType.Custom,
                            value: (
                                <div className={'contact-main-block'}>
                                    <DrawerAvatar image={profession?.mainImage} />
                                    <DataDisplayBlock
                                        data={[
                                            [
                                                {
                                                    label: 'Краткое наименование',
                                                    type: DataDisplayItemType.String,
                                                    value: profession?.shortName,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Полное наименование',
                                                    type: DataDisplayItemType.String,
                                                    value: profession?.fullName,
                                                },
                                            ],
                                        ]}
                                        paddings={false}
                                    />
                                </div>
                            ),
                        },
                    ],
                ]}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Количество сотрудников',
                            type: DataDisplayItemType.Link,
                            value: profession?.ownActiveCrewMemberCount
                                ? {
                                      text: String(profession?.ownActiveCrewMemberCount),
                                      link: CrewMemberUtils.getFullUrlPath(businessAccountId, {
                                          professionId: [profession.id],
                                      }),
                                      isRoute: true,
                                  }
                                : undefined,
                        },
                        {
                            label: 'Артикул',
                            type: DataDisplayItemType.String,
                            value: profession?.externalCode,
                        },
                    ],
                ]}
            />
            <DataDisplayBlock
                data={[
                    ...[
                        isCanViewFinancialData
                            ? ([
                                  {
                                      label: 'Цена за смену',
                                      type: DataDisplayItemType.Money,
                                      value: profession?.pricePerShift,
                                  },
                                  {
                                      label: 'Ценовая схема',
                                      type: DataDisplayItemType.PricingScheme,
                                      pricingScheme: profession?.pricingScheme,
                                      pricePerShift: profession?.pricePerShift,
                                  },
                              ] as DataDisplayItemProps[])
                            : undefined,
                    ],
                    [
                        {
                            label: 'Длительность смены',
                            type: DataDisplayItemType.ShiftDuration,
                            durationInMinutes: profession?.shiftLengthInMinutes,
                            hasOwnShiftLength: profession?.hasOwnShiftLength,
                        },
                    ],
                ]}
            />
            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Описание',
                            type: DataDisplayItemType.RichText,
                            value: profession?.description,
                        },
                    ],
                ]}
            />
            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Дата последнего изменения',
                            type: DataDisplayItemType.DateWithTime,
                            value: profession.lastUpdateDate,
                        },
                        {
                            label: 'Автор последнего изменения',
                            type: DataDisplayItemType.String,
                            value: creationAuthorFullName,
                        },
                    ],
                ]}
            />
            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Комментарий',
                            type: DataDisplayItemType.Comment,
                            value: profession.comment,
                        },
                    ],
                ]}
            />
        </>
    );
});

import React, { FC } from 'react';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconTags } from '../../../../../../../../components/icons';
import { inventoryPageUrlRoute } from '../../../../../../inventory/inventory-page';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { restItemsGroupId } from '../../../../../components/Calendar/hooks/useCalendarItems';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { ElementsCalendarItem } from '../../../../../components/Calendar/types/items';

import { InventoryPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';

export const ProductGroupLine: FC<GroupItemProps<ElementsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { id, record } = groupItem;
    const {
        commonFields: { nomenclatureShortName: title },
    } = record;

    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const link = `/${businessAccountId}/${inventoryPageUrlRoute}/${InventoryPageTabsEnum.PRODUCTS}/${id}`;

    return title ? (
        <GroupLine
            IconComponent={IconTags}
            displayType={displayType}
            title={{
                text: id !== restItemsGroupId ? title : localize(LocalizationEnum.PAGE__CALENDAR__TAB_LABEL_MIXED_STATES),
                link: id !== restItemsGroupId ? link : undefined,
            }}
        />
    ) : null;
};

import {ModalProps} from "../../../../components/modalForm/ModalFormNEW";
import {VariantInfoRead} from "../../../../server";
import React, {FC} from "react";
import {LocalizationEnum, localize} from "../../../../localization";
import {createProductVariantsModalFilters} from "./product-variants-create-modal-data";
import {ProductVariantsCreateModal} from "./product-variants-create-modal";
import {useIntl} from "react-intl";

export interface ProductVariantCreateModalNewProps extends ModalProps<VariantInfoRead> {
}

export const ProductVariantCreateModalNew:FC<ProductVariantCreateModalNewProps> = (props) => {
    const intl = useIntl();

    return (
        <ProductVariantsCreateModal
            editMode={false}
            title={localize(LocalizationEnum.PAGE__VARIANTS__FORM__FORM_TITLE__CREATE)}
            intl={intl}
            goBackPath={''}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            filters={createProductVariantsModalFilters}
            modalName={'create-product_variants'}
            onSuccess={props.onSuccess}
            onCancel={props.onCancel}
        />
    );
};
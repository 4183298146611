import React, {useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import {PaymentMethodInfoRead, PaymentMethodRecord} from '../../../../../server';
import {push} from 'connected-react-router';
import {deletePaymentMethod, loadPaymentMethod, updatePaymentMethod} from '../reducers/paymentMethod/paymentMethod.reducer';
import {setStatusForPaymentMethods} from '../reducers/paymentMethods/paymentMethods.reducer';
import {businessAccountIdSelector} from '../../../../../shared/reducers/system.reducer';
import {PaymentMethodActionType} from '../data/paymentMethodsData';
import {EntityActions, EntityActionsHook} from '../../../../../components/page/entityGrid/hooks/useRowActions';
import {URLDrawerParams} from '../../../../../components/page/entityGrid/types/params';
import {showConfirm} from "../../../../../components/confirm/showConfirm";
import {useIntl} from "react-intl";

type PaymentMethodEntityActions = EntityActions<PaymentMethodInfoRead>;

export const useEntityActions = (({rootPath, urlSearchParams}) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const intl = useIntl();

    const editPaymentMethodCallback: PaymentMethodEntityActions['editAction'] = useCallback(
        (entity: PaymentMethodInfoRead, options) => {
            dispatch(
                loadPaymentMethod({
                    businessAccountId,
                    entityId: entity.id!,
                })
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.set('modal', modalType);
            searchParams.set('id', String(entity.id));

            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const setArchiveForPaymentMethodCallback = useCallback(
        ({id, businessVersion}: PaymentMethodInfoRead, archive: boolean) => {
            dispatch(
                setStatusForPaymentMethods({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id: id!,
                                businessVersion: businessVersion!,
                                archive,
                            },
                        ],
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const setIsDefaultForPaymentMethodCallback = useCallback(
        (record: PaymentMethodRecord, isDefault: boolean) => {
            dispatch(
                updatePaymentMethod({
                    businessAccountId,
                    id: record.id,
                    infoUpdate: {
                        id: record.id,
                        businessVersion: record.businessVersion,
                        name: record.name,
                        isDefault: isDefault
                    }
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const deletePaymentMethodCallback = useCallback(
        async (record: PaymentMethodRecord) => {
            const yes = await showConfirm(intl, 'Вы уверены что хотите удалить способ оплаты?');
            if (yes) {
                dispatch(
                    deletePaymentMethod({
                        businessAccountId,
                        id: record.id,
                        businessVersion: record.businessVersion
                    })
                );
            }
        },
        [businessAccountId, dispatch]
    );

    const onRowAction: PaymentMethodEntityActions['onRowAction'] = useCallback(
        (item: PaymentMethodRecord, action: any) => {
            // Тут было EntityActionType, а надо PaymentMethodEntityActionType, пока any
            const actionHandler: Partial<Record<typeof action, Function>> = {
                [PaymentMethodActionType.edit]: () => editPaymentMethodCallback(item),
                [PaymentMethodActionType.delete]: () => deletePaymentMethodCallback(item),
                [PaymentMethodActionType.toArchive]: () => setArchiveForPaymentMethodCallback(item, true),
                [PaymentMethodActionType.returnFromArchive]: () => setArchiveForPaymentMethodCallback(item, false),
                [PaymentMethodActionType.setAsDefaultMethod]: () => setIsDefaultForPaymentMethodCallback(item, true),
                [PaymentMethodActionType.unsetAsDefaultMethod]: () => setIsDefaultForPaymentMethodCallback(item, false),
            };
            actionHandler[action]?.();
        },
        [editPaymentMethodCallback, deletePaymentMethodCallback, setArchiveForPaymentMethodCallback, setIsDefaultForPaymentMethodCallback]
    ) as any;

    return useMemo(
        () => ({
            editAction: editPaymentMethodCallback,
            onRowAction,
        }),
        [editPaymentMethodCallback, onRowAction]
    );
}) satisfies EntityActionsHook<PaymentMethodInfoRead>;

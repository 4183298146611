import { LocalizationEnum } from '../../../../../../localization';
import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../core/utils/formUtils';
import { ListProfessionsParams } from '../api/types';
import { SliderRangeWithManualInput } from '../../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { professionsAllStateCodes } from '../data/professionsData';
import { ProfessionStateBadge } from '../components/professionStateBadge';
import { getStoreState } from '../../../../../../../index';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';

export const professionsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListProfessionsParams>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'stateCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: (form) => {
                    return professionsAllStateCodes.map((code) => ({
                        name: <ProfessionStateBadge type={code} dot={true} />,
                        value: code,
                    }));
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Цена смены',
                id: 'pricePerShift',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                visible: () => canViewFinancialDataSelector(getStoreState()),
                componentProps: (form: WrappedFormUtils) => ({
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000,
                    step: 1,
                }),
            },
        ],
    },
]);

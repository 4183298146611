import React, { FC } from 'react';
import { formatCustomFieldValues } from '../../../../../shared/util/customFieldsUtils';
import { LocalizationEnum, localize } from '../../../../../localization';
import { CustomFieldDataTypeCodeEnum, CustomFieldProcessingTypeCodeEnum, CustomFieldWithValueObjRead } from '../../../../../server';
import { ConditionalLink } from '../../../../../components/lib/ConditionalLink/ConditionalLink';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import './DescriptionCustomField.less';
import { subrentPageUrlRoute } from '../../../subrent/subrentPage';
import { projectsPageUrlRoute } from '../../../projects/projectsPage';
import { rentersPageUrlRoute } from '../../../renters/data/tabs';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useIdForDrawer } from '../../../logistics/tabs/transportations/hooks/useIdForDrawer';
import { ContactDrawer } from '../../../renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { useContactActions } from './hooks/useContactActions';

import { ProjectsPageTabsEnum, RentersPageTabsEnum, SubrentPageTabsEnum } from '../../../../../shared/constants/tabEnums';

interface CustomFieldElementProps {
    field: CustomFieldWithValueObjRead;
    customFieldsParameters?: Partial<Omit<Parameters<typeof formatCustomFieldValues>[0], 'values' | 'typeCode'>>;
    ignoreEmpty?: boolean;
    ignoreDrawer?: boolean;
    setContactId?: (id: number) => void;
}

const DescriptionCustomField: FC<CustomFieldElementProps> = (props) => {
    const { field, customFieldsParameters, ignoreEmpty, ignoreDrawer, setContactId } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const { values, linkValues, typeCode, processingType } = field;
    const [contactIdForDrawer, setContactIdForDrawer, clearContactIdForDrawer] = useIdForDrawer();

    const contactActions = useContactActions();

    if (values && values.length) {
        return (
            <div
                className={classNames({
                    'rr-value': !customFieldsParameters?.forGrid,
                    'grid-checkbox': customFieldsParameters?.forGrid && typeCode === CustomFieldDataTypeCodeEnum.BOOLEAN,
                })}
            >
                {formatCustomFieldValues({
                    values,
                    typeCode,
                    processingType,
                    ...customFieldsParameters,
                })}
            </div>
        );
    }

    const getLink = (id: number) => {
        const links = {
            [CustomFieldProcessingTypeCodeEnum.USERLINK]: `/${businessAccountId}/users/${id}`,
            [CustomFieldProcessingTypeCodeEnum.SUBRENTLINK]: `/${businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/${id}`,
            [CustomFieldProcessingTypeCodeEnum.PROJECTLINK]: `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${id}`,
            [CustomFieldProcessingTypeCodeEnum.RENTERLINK]: `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${id}`,
        };

        return links[processingType];
    };

    if (linkValues && linkValues.length) {
        return (
            <div
                className={classNames('description-custom-field-container', {
                    'rr-value': !customFieldsParameters?.forGrid,
                    'grid-value': customFieldsParameters?.forGrid,
                })}
            >
                {linkValues.map(({ id, title }) => {
                    const link = getLink(id);

                    return (
                        <>
                            <Tooltip title={title} className={'description-custom-field'}>
                                <ConditionalLink
                                    condition={Boolean(link)}
                                    to={link}
                                    onClick={(event) => {
                                        if (processingType === CustomFieldProcessingTypeCodeEnum.CONTACTLINK) {
                                            event.stopPropagation();
                                            setContactIdForDrawer(id);
                                            setContactId?.(id);
                                        }
                                    }}
                                >
                                    {title}
                                </ConditionalLink>
                            </Tooltip>
                            {!ignoreDrawer &&
                            processingType === CustomFieldProcessingTypeCodeEnum.CONTACTLINK &&
                            contactIdForDrawer &&
                            contactIdForDrawer === id ? (
                                <ContactDrawer
                                    contactId={contactIdForDrawer}
                                    onClose={clearContactIdForDrawer}
                                    editContact={contactActions.edit}
                                    archiveContact={contactActions.archive}
                                />
                            ) : null}
                        </>
                    );
                })}
            </div>
        );
    }

    if (ignoreEmpty) return null;

    return <div className={'rr-empty-value'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}</div>;
};

export default DescriptionCustomField;

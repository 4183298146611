import { ComponentProps, FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import { Checkbox } from 'antd';
import ReactDOM from 'react-dom';
import { DomUtils } from '../../../../core/utils/domUtils';

interface CheckboxDragControllerProps extends ComponentProps<'div'> {
    children: (props: { onMouseEnter: (index: number) => void; refSetter: (index: number) => (ref: Checkbox | null) => void }) => ReactNode;
}

export const CheckboxDragController: FC<CheckboxDragControllerProps> = (props) => {
    const { children, ...divProps } = props;
    const checkboxesRef = useRef<HTMLInputElement[]>([]);
    const isSelectionActiveRef = useRef(false);
    const startIndexRef = useRef<number | undefined>(undefined);
    const endIndexRef = useRef<number | undefined>(undefined);
    const containerRef = useRef<HTMLDivElement>(null);

    const setStartIndex = (index: number) => {
        if (!isSelectionActiveRef.current || startIndexRef.current == null) {
            if (checkboxesRef.current[index].disabled) {
                startIndexRef.current = undefined;
            } else {
                startIndexRef.current = index;
            }
        }
    };

    const processCheckbox = (index: number) => {
        if (startIndexRef.current == null) return;
        endIndexRef.current = index;
        const checkboxNode = checkboxesRef.current[index];
        const firstCheckboxNode: HTMLInputElement | null | undefined = checkboxesRef.current[startIndexRef.current];
        if (checkboxNode == null || firstCheckboxNode == null) return;
        if (firstCheckboxNode.checked !== checkboxNode.checked && !checkboxNode.disabled) {
            checkboxNode.click();
        }
    };

    const onMouseEnter = useCallback((index: number) => {
        setStartIndex(index);
        processCheckbox(index);
    }, []);

    const onLeaveContainer = useCallback(() => {
        isSelectionActiveRef.current = false;
    }, []);

    const refSetter = useCallback(
        (index: number) => (ref: Checkbox | null) => {
            const checkboxNode: HTMLInputElement | null | undefined = (ReactDOM.findDOMNode(ref) as Element | undefined)?.querySelector(
                '.ant-checkbox-input'
            );
            if (checkboxNode != null) {
                checkboxesRef.current[index] = checkboxNode;
            }
        },
        []
    );

    const onPressingDown = useCallback(() => {
        isSelectionActiveRef.current = true;
        if (startIndexRef.current != null) {
            checkboxesRef.current[startIndexRef.current].click();
        }
    }, []);

    const onPressingUp = useCallback(() => {
        isSelectionActiveRef.current = false;
        if (startIndexRef.current) startIndexRef.current = endIndexRef.current;
    }, []);

    useEffect(() => {
        const containerElement = containerRef.current;
        if (containerElement == null) return;

        const handleClick = (event: MouseEvent) => {
            if (!isSelectionActiveRef.current) {
                event.preventDefault();
                event.stopPropagation();
            }
        };

        const handleMouseDown = (event: MouseEvent) => {
            event.preventDefault();
            if (containerRef.current == null) return;
            if (!DomUtils.isClickInsideElement(containerRef.current, event)) return;

            if (!isSelectionActiveRef.current) {
                onPressingDown();
            }
        };
        const handleMouseUp = (event: MouseEvent) => {
            if (isSelectionActiveRef.current) {
                onPressingUp();
            }
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isSelectionActiveRef.current && event.key === 'Shift') {
                onPressingDown();
            }
        };
        const handleKeyUp = (event: KeyboardEvent) => {
            if (isSelectionActiveRef.current && event.key === 'Shift') {
                onPressingUp();
            }
        };

        const checkboxContainers = checkboxesRef.current.map((checkbox) => checkbox.parentElement!.parentElement!);
        checkboxContainers.forEach((container) => {
            container.addEventListener('click', handleClick);
        });

        const checkboxes = [...checkboxesRef.current].filter(Boolean);
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('click', handleClick);
        });

        containerElement.addEventListener('click', handleClick);
        containerElement.addEventListener('mousedown', handleMouseDown);
        containerElement.addEventListener('mouseup', handleMouseUp);
        containerElement.addEventListener('keydown', handleKeyDown);
        containerElement.addEventListener('keyup', handleKeyUp);

        return () => {
            checkboxContainers.forEach((container) => {
                container.removeEventListener('click', handleClick);
            });
            checkboxes.forEach((checkbox) => {
                checkbox.removeEventListener('click', handleClick);
            });
            containerElement.removeEventListener('click', handleClick);
            containerElement.removeEventListener('mousedown', handleMouseDown);
            containerElement.removeEventListener('mouseup', handleMouseUp);
            containerElement.removeEventListener('keydown', handleKeyDown);
            containerElement.removeEventListener('keyup', handleKeyUp);
        };
    }, [onPressingDown, onPressingUp]);

    return (
        <div ref={containerRef} onMouseLeave={onLeaveContainer} {...divProps}>
            {children({ onMouseEnter, refSetter })}
        </div>
    );
};

import {PageURLParamDescriptionObject, PageURLParamDescriptions} from "../../../../core/utils/descriptions";
import {PaymentSourceCodeEnum, PaymentStateCodeEnum} from "../../../../server";
import {ListPaymentsFilters} from "../api/payments.api.types";

export const PaymentsFiltersDescription: PageURLParamDescriptionObject<ListPaymentsFilters> = {
    counterpartyId: PageURLParamDescriptions.numberParam,
    stateCode: PageURLParamDescriptions.enumSelectParam(PaymentStateCodeEnum),
    sourceCode: PageURLParamDescriptions.enumSelectParam( PaymentSourceCodeEnum),
    projectId: PageURLParamDescriptions.numberParam,
    subrentId: PageURLParamDescriptions.numberParam,
    amount: PageURLParamDescriptions.numberInterval,
    number: PageURLParamDescriptions.numberParam,
    hideDeleted: PageURLParamDescriptions.switchParam,
    paymentMethodId: PageURLParamDescriptions.numberParam,
    authorId: PageURLParamDescriptions.numberParam,
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
}
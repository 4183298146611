import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { crewPageUrlRoute } from '../../../utils/tabs';
import { getGridStorageData } from '../../../../../../components/grid/utils';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { ListCrewMembersFilters } from '../api/types';

import { CrewPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

export abstract class CrewMemberUtils {
    static getRootPath(businessAccountId: number): EntityGridRootPath {
        return `/${businessAccountId}/${crewPageUrlRoute}/${CrewPageTabsEnum.CREW_MEMBERS}`;
    }
    static getCardPath(businessAccountId: number, crewMemberId: number) {
        return `${CrewMemberUtils.getRootPath(businessAccountId)}/${crewMemberId}` as EntityGridRootPath;
    }
    static getFullUrlPath(businessAccountId: number, params?: ListCrewMembersFilters) {
        const gridData = getGridStorageData(CrewPageTabsEnum.CREW_MEMBERS);
        return getPathFromState(CrewMemberUtils.getRootPath(businessAccountId), '', {
            ...gridData.filters,
            ...gridData.params,
            ...(params ?? {}),
        });
    }
}

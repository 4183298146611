import {AxiosError} from "axios";
import {SerializedError} from "@reduxjs/toolkit";
import {useEffect} from "react";
import {showNotification} from "../../components/notification/showNotification";

export const useErrorEffect = (isError: boolean, error?: AxiosError<any> | SerializedError) => {
    useEffect(() => {
        if (isError && error) {
            console.error('ERROR: ', error);
            showNotification('error', 'Ошибка');
        }
    }, [isError, error]);
};
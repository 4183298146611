import React, { ReactNode } from 'react';
import { DatePickerMode, RangePickerProps, RangePickerValue } from 'antd/lib/date-picker/interface';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import moment, {Moment} from 'moment';
import './new-date-picker.less';
import { Button, Switch } from 'antd';
import { getCalendarLocaleByCurrentLocale, localize } from '../../localization/localizationUtils';
import {PricingSchemeExternalRepresentationObj, TimetableTypeCodeEnum} from '../../server';
import { LocalizationEnum } from '../../localization';
import { TimeRangePicker } from '../timeRangePicker/timeRangePicker';
import { RecalculateShiftsCountButton } from '../../modules/main/operationForm/RecalculateShiftsCountButton';
import { IRootState } from '../../shared/reducers';
import { connect } from 'react-redux';
import { setRecalculateShiftCount, updateRecalculateShiftsCount } from '../../shared/reducers/userSettings/userSettings.reducer';
import { getShiftCountFromDates } from '../../shared/util/utils';
import { CustomRangeCalendarDateItem } from './customRangeCalendarDateItem';
import classNames from 'classnames';
import {TodayButton} from "../v2/calendar/rangePicker/components/todayButton/todayButton";
import {canSetEndDateToTargetDate, canSetStartDateToTargetDate} from "../../utils/timeUtils/timeUtils";
import {GridProps} from "../grid/Grid";

export interface CustomRangeCalendarProps extends RangePickerProps {
    onChange?: (dates: RangePickerValue, dateStrings: [string, string]) => void;
    intervals?: [number, number][];
    instanceCount?: number;
    selectedValue?: RangePickerValue;
    defaultSelectedValue?: RangePickerValue;
    dontShowBadges?: boolean;
    className?: string;
    onValueChange?: (value: any) => void;
    instance?: boolean;
    onOpenChange?: (open: boolean) => void;
    typeCode?: TimetableTypeCodeEnum;
    hideShiftRecalculateBlock?: boolean;
    shiftLengthInMin?: number;
    pricingScheme: undefined | PricingSchemeExternalRepresentationObj;
    hasOwnShiftLength?: boolean;
    shiftLengthHint?: ReactNode;

    requiredTimeIndentBetweenElementsInMinutes?: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    hideFooter?: boolean;
    customFooter?: ReactNode;
    shiftCount?: number;
    onShiftCountChange?: (shiftCount: number) => void;
    storeKey?: 'workPlannings';
    showRequiredTimeIndentBetweenElementsInMinutesIcon?: boolean;

    getTodayDate?: ()=>Moment;
    onStartDateTodayButtonClick1?:(()=>void)|null;
    onEndDateTodayButtonClick1?:(()=>void)|null;
}

interface IState {
    selectedValue?: RangePickerValue;
    value?: RangePickerValue;
    v: number;
    mode: [DatePickerMode, DatePickerMode];
}

export class _CustomRangeCalendar extends React.Component<CustomRangeCalendarProps & StateProps & DispatchProps, IState> {
    public static defaultProps = {
        typeCode: TimetableTypeCodeEnum.AVAILABLE,
        hideFooter: false,
    };

    constructor(props) {
        super(props);

        let selectedValue = props.selectedValue || props.defaultSelectedValue || undefined;
        let value = props.value || props.defaultValue;

        this.state = {
            selectedValue: selectedValue,
            value: value,
            v: 0,
            mode: ['date', 'date'],
        };
    }

    static getDerivedStateFromProps(nextProps: CustomRangeCalendarProps, prevState: IState) {
        if (nextProps && nextProps.selectedValue) {
            return {
                selectedValue: nextProps.selectedValue,
            };
        } else return null;
    }

    componentDidUpdate(prevProps: Readonly<CustomRangeCalendarProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.intervals?.toString() !== this.props.intervals?.toString()) {
            this.setState({ v: this.state.v + 1 });
        }
    }

    onChange = (dates: RangePickerValue, dateStrings: [string, string]) => {
        let { selectedValue } = this.state;
        let startDate = selectedValue && selectedValue[0] ? selectedValue[0] : undefined;
        let endDate = selectedValue && selectedValue[1] ? selectedValue[1] : undefined;

        let startDateNew = dates && dates[0] ? dates[0] : undefined;
        let endDateNew = dates && dates[1] ? dates[1] : undefined;

        if (startDate && endDate && startDateNew && endDateNew) {
            endDate = endDate.clone();
            endDate.year(endDateNew.year()).month(endDateNew.month()).date(endDateNew.date());

            startDate = startDate.clone();
            startDate.year(startDateNew.year()).month(startDateNew.month()).date(startDateNew.date());

            if (moment(endDate.valueOf() - 15 * 60 * 1000).isSameOrBefore(startDate)) {
                endDate = startDate.clone().add(15, 'minutes');
            }
            if (this.props.selectedValue && this.props.onChange) this.props.onChange([startDate, endDate], ['', '']);
            else this.setState({ selectedValue: [startDate, endDate] }, this.onCb);
        } else if (!startDate && !endDate && startDateNew && endDateNew) {
            if (this.props.selectedValue && this.props.onChange) this.props.onChange([startDateNew, endDateNew], ['', '']);
            else this.setState({ selectedValue: [startDateNew, endDateNew] }, this.onCb);
        }

        if (this.props.recalculateShiftCount && startDateNew && endDateNew) {
            let newShiftCount =
                this.props.shiftLengthInMin !== undefined
                    ? getShiftCountFromDates(this.props.pricingScheme, startDateNew, endDateNew, this.props.shiftLengthInMin, this.props.shiftCountRoundingType)
                    : 0;
            this.props.onShiftCountChange?.(newShiftCount);
        }
    };

    onCb = () => {};

    render() {
        const renderDate = (current: moment.Moment, today: moment.Moment): React.ReactNode => {
            return (
                <CustomRangeCalendarDateItem
                    current={current}
                    today={today}
                    typeCode={this.props.typeCode || TimetableTypeCodeEnum.AVAILABLE}
                    dontShowBadges={this.props.dontShowBadges}
                    instance={this.props.instance}
                    instanceCount={this.props.instanceCount}
                    intervals={this.props.intervals}
                    v={this.state.v}
                />
            );
        };

        const { selectedValue } = this.state;

        const params = {};
        if (selectedValue) params['selectedValue'] = selectedValue;
        if (this.props.mode) params['mode'] = this.props.mode;

        const newShiftCount =
            selectedValue && selectedValue[0] && selectedValue[1] && this.props.shiftLengthInMin !== undefined
                ? getShiftCountFromDates(this.props.pricingScheme, selectedValue[0], selectedValue[1], this.props.shiftLengthInMin, this.props.shiftCountRoundingType)
                : 0;
        let shiftCount =
            this.state.value && this.state.value[0] && this.state.value[1] && this.props.shiftLengthInMin !== undefined
                ? getShiftCountFromDates(
                      this.props.pricingScheme,
                      this.state.value[0],
                      this.state.value[1],
                      this.props.shiftLengthInMin,
                      this.props.shiftCountRoundingType
                  )
                : 0;
        if (this.props.shiftCount) shiftCount = this.props.shiftCount;

        const todayDate = this.props.getTodayDate ? this.props.getTodayDate().startOf('minute') : moment().startOf('minute');
        const startDateValue = selectedValue?.[0]?.startOf('minute');
        const endDateValue = selectedValue?.[1]?.startOf('minute');
        //const datesSelected = Boolean(startDateValue && endDateValue);
        const startDateTodayButtonDisabled = !canSetStartDateToTargetDate(todayDate, startDateValue, endDateValue);//!datesSelected || (endDateValue && todayDate.valueOf() >= endDateValue.valueOf());
        const endDateTodayButtonDisabled = !canSetEndDateToTargetDate(todayDate, startDateValue, endDateValue);//!datesSelected || (startDateValue && todayDate.valueOf() <= startDateValue.valueOf());

        return (
            <RangeCalendar
                prefixCls={'ant-calendar'}
                className={classNames(
                    'rr-range-calendar',
                    this.props.intervals && 'rr-range-calendar-with-intervals',
                    this.props.className,
                    this.props.mode && 'rr-range-calendar-my-select-disabled'
                )}
                showToday={false}
                {...params}
                dateRender={this.props.intervals ? renderDate : undefined}
                disabledDate={this.props.disabledDate}
                mode={this.props.mode || this.state.mode}
                onChange={this.onChange}
                locale={getCalendarLocaleByCurrentLocale()}
                onPanelChange={(value, m: [DatePickerMode, DatePickerMode]) => {
                    let newMode = [...m] as [DatePickerMode, DatePickerMode];
                    if (this.state.mode[0] === 'year' && m[0] === 'date') newMode[0] = 'month';
                    if (this.state.mode[1] === 'year' && m[1] === 'date') newMode[1] = 'month';
                    this.setState({ mode: newMode });
                }}
                onValueChange={(value) => {
                    this.setState({ value });
                    if (this.props.onValueChange) this.props.onValueChange(value);
                }}
                renderSidebar={()=>{
                    return (
                        <>
                            <TodayButton
                                disabled={startDateTodayButtonDisabled}
                                onClick={()=>{
                                    const startDate = this.props.getTodayDate ? this.props.getTodayDate().startOf('minute') : moment().startOf('minute');
                                    const endDate:Moment = selectedValue?.[1] as Moment;
                                    const value:RangePickerValue = [startDate, endDate];
                                    this.setState({ selectedValue: value });
                                    this.props.onChange?.(value, ['', '']);
                                }}
                                style={{position: "absolute", zIndex: 11, top: 21, right: 414}}
                            />

                            <TodayButton
                                disabled={endDateTodayButtonDisabled}
                                onClick={()=>{
                                    const startDate = selectedValue?.[0] as Moment;
                                    const endDate:Moment = this.props.getTodayDate ? this.props.getTodayDate().startOf('minute') : moment().startOf('minute');//selectedValue?.[1] as Moment;
                                    const value:RangePickerValue = [startDate, endDate];
                                    this.setState({ selectedValue: value });
                                    this.props.onChange?.(value, ['', '']);
                                }}
                                style={{position: "absolute", zIndex: 11, top: 21, right: 68}}
                            />

                        </>

                    )
                }}
                renderFooter={() => {
                    return !this.props.hideFooter ? (
                        this.props.customFooter ? (
                            this.props.customFooter
                        ) : (
                            <div className={classNames('ant-calendar-footer', 'ant-calendar-range-bottom', 'ant-calendar-footer-show-ok')}>
                                <div className="ant-calendar-footer-btn">
                                    <div className="ant-calendar-footer-extra" style={{ width: '100%', padding: '16px 0' }}>
                                        <div
                                            style={{
                                                width: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {this.props.showTime && (
                                                <TimeRangePicker
                                                    requiredTimeIndentBetweenElementsInMinutes={
                                                        this.props.requiredTimeIndentBetweenElementsInMinutes
                                                    }
                                                    productHasOwnRequiredTimeIndentBetweenElements={
                                                        this.props.productHasOwnRequiredTimeIndentBetweenElements
                                                    }
                                                    showRequiredTimeIndentBetweenElementsInMinutesIcon={
                                                        this.props.showRequiredTimeIndentBetweenElementsInMinutesIcon
                                                    }
                                                    value={this.state.selectedValue}
                                                    minutesStep={5}
                                                    onChange={(value) => {
                                                        const startDate =
                                                            this.state.selectedValue && this.state.selectedValue[0]
                                                                ? this.state.selectedValue[0]
                                                                : undefined;
                                                        const endDate =
                                                            this.state.selectedValue && this.state.selectedValue[1]
                                                                ? this.state.selectedValue[1]
                                                                : undefined;
                                                        if (startDate && endDate) {
                                                            if (value) {
                                                                if (value[0]) startDate.hours(value[0].hours()).minutes(value[0].minutes());
                                                                if (value[1]) endDate.hours(value[1].hours()).minutes(value[1].minutes());
                                                            }

                                                            if (this.props.selectedValue && this.props.onChange)
                                                                this.props.onChange([startDate, endDate], ['', '']);
                                                            else this.setState({ selectedValue: [startDate, endDate] }, this.onCb);

                                                            if (this.props.recalculateShiftCount && startDate && endDate) {
                                                                let newShiftCount =
                                                                    this.props.shiftLengthInMin !== undefined
                                                                        ? getShiftCountFromDates(
                                                                              this.props.pricingScheme,
                                                                              startDate,
                                                                              endDate,
                                                                              this.props.shiftLengthInMin,
                                                                              this.props.shiftCountRoundingType
                                                                          )
                                                                        : 0;
                                                                this.props.onShiftCountChange?.(newShiftCount);
                                                            }
                                                        }
                                                    }}
                                                    onStartDateTodayButtonClick1={this.props.onStartDateTodayButtonClick1}
                                                    onEndDateTodayButtonClick1={this.props.onEndDateTodayButtonClick1}
                                                />
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                width: '38%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {!this.props.hideShiftRecalculateBlock && this.props.showTime && (
                                                <>
                                                    <RecalculateShiftsCountButton
                                                        hasOwnShiftLength={this.props.hasOwnShiftLength}
                                                        hint={this.props.shiftLengthHint}
                                                        shiftLengthInMinutes={this.props.shiftLengthInMin}
                                                        style={{ marginRight: 6 }}
                                                        from={shiftCount}
                                                        to={newShiftCount}
                                                    />
                                                    Пересчитать смены
                                                    <Switch
                                                        style={{ marginLeft: 12, verticalAlign: 'middle' }}
                                                        defaultChecked={this.props.recalculateShiftCount}
                                                        onChange={async (checked: boolean) => {
                                                            if (this.props.storeKey)
                                                                await this.props.updateRecalculateShiftsCount({
                                                                    [this.props.storeKey]: checked,
                                                                });
                                                            else await this.props.setRecalculateShiftCount(checked);
                                                            if (this.state.selectedValue) {
                                                                this.onChange(this.state.selectedValue, ['', '']);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div style={{ width: '12%' }}>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    this.props.onOpenChange?.(false);
                                                    if (this.props.onChange && this.state.selectedValue) {
                                                        this.props.onChange(this.state.selectedValue, ['', '']);
                                                    }
                                                }}
                                            >
                                                {localize(LocalizationEnum.ASPECT__GLOBAL__OK)}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : null;
                }}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps:CustomRangeCalendarProps) => ({
    recalculateShiftCount: ownProps.storeKey
        ? storeState.userSettings.recalculateShiftsCount[ownProps.storeKey]
        : storeState.userSettings.operationFormRecalculateShiftCount,
    shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
});

const mapDispatchToProps = { setRecalculateShiftCount, updateRecalculateShiftsCount };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const CustomRangeCalendar = connect(mapStateToProps, mapDispatchToProps)(_CustomRangeCalendar);

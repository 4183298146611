import {FormFieldsGroup, IFormField, IFormFieldImageUpload, SelectItem, TreeSelectItem} from '../../components/dynamicForm/DynamicForm';
import { AllAvailableKeys } from './serverUtils';
import {ValidateCallback, ValidateFieldsOptions, WrappedFormUtils} from "antd/lib/form/Form";

export type WrappedFormUtilsTyped<T extends object> = Omit<WrappedFormUtils, 'getFieldValue' | 'setFieldsValue' | 'validateFields'> & {
    getFieldValue: <K extends AllAvailableKeys<T>>(fieldName: K) => T[K];
    setFieldsValue(obj: Partial<T>, callback?: Function): void;
    validateFields(fieldNames: Array<AllAvailableKeys<T>>): void;
    validateFields(fieldNames: Array<AllAvailableKeys<T>>, options: ValidateFieldsOptions): void;
};

export type TypedFormField<T extends object> = (
    | Omit<IFormFieldImageUpload, 'id' | 'validationFunction' | 'componentProps' | 'onChange'|'values' /*| 'disabled'*/>
    | Omit<IFormField, 'id' | 'validationFunction' | 'componentProps' | 'onChange'|'values' /*| 'disabled'*/>
) & {
    id: AllAvailableKeys<T>;
    validationFunction?: (fieldName: AllAvailableKeys<T>, value: any, cb: Function, form: WrappedFormUtilsTyped<T>) => void;
    componentProps?: { [k: string]: any } | ((form: WrappedFormUtilsTyped<T>) => any);
    onChange?: (value: any, form: WrappedFormUtilsTyped<T>) => void;
    //disabled?: ((getFieldValue: <K extends AllAvailableKeys<T>>(fieldName: K) => T[K])=>boolean);//(<K extends AllAvailableKeys<T>>(fieldName:K) => /*T[K]|undefined*/boolean);
    values?: (SelectItem | TreeSelectItem | string)[] | ((form: WrappedFormUtilsTyped<T>)=>(SelectItem | TreeSelectItem | string)[]);
};
type TypedFormFieldsGroup<T extends object> = Omit<FormFieldsGroup, 'fields'> & { fields: TypedFormField<T>[] };

export class FormUtils {
    static typedFormFields<T extends object>(fields: TypedFormFieldsGroup<T>[]) {
        return fields as FormFieldsGroup[];
    }
}

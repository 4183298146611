import { NomenclatureRecordCustom } from '../../api/products.api.types';
import { NomenclatureEntityTypeCodeEnum } from '../../../../../../server';
import { useCallback } from 'react';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { setArchiveState } from '../../reducers/products.reducer';

export const useArchiveProducts = (selectedRecords: NomenclatureRecordCustom[]) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();

    return useCallback(() => {
        const selectedItems = selectedRecords.map((item) => ({
            id: item.variantId || item.productId,
            businessVersion: item.businessVersion,
            archive: !item.archive,
            nomenclatureEntityTypeCode: item.variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : NomenclatureEntityTypeCodeEnum.PRODUCT,
        }));

        setArchiveState(intl, businessAccountId, selectedItems)(dispatch);
        gridRef?.current?.clearSelection();
    }, [businessAccountId, dispatch, gridRef, intl, selectedRecords]);
};

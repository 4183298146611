import React from 'react';
import {connect} from "react-redux";
import {IRootState} from "../../../../shared/reducers";
import ModalForm from "../../../../components/modalForm/ModalForm";
import {inviteUserToBusinessAccount, resetError} from "./reducers/user.reducer";
import {goBack} from 'connected-react-router';
import {showConfirm} from "../../../../components/confirm/showConfirm";
import {formFields} from "./usersModuleInviteUserModalFields";
import {LocalizationEnum, localize, localizeIntl} from '../../../../localization';
import {injectIntl, WrappedComponentProps} from "react-intl";

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
}

class InviteUserBA extends React.PureComponent<IProps> {

    onOk = (data) => {
        this.props.inviteUserToBusinessAccount(this.props.intl, this.props.businessAccountId, data.mail, data.rules);
    };

    onCancel = async (isFieldsTouched:Boolean) => {
        let yes = !isFieldsTouched || await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION));
        if(yes){
            this.props.goBack();
            this.props.resetError();
        }
    };

    render() {

        let err;
        if (this.props.updatingError) {
            err = <>
                <div><strong>{this.props.updatingError.title}</strong></div>
                <div>{this.props.updatingError.message}</div>
            </>;
        }

        return (
            <ModalForm
                title={localize(LocalizationEnum.PAGE__MODAL__MODAL_TITLE)}
                visible={true}
                onCancel={this.onCancel}
                onOk={this.onOk}
                formFieldsData={formFields}
                saving={this.props.updating}
                error={err}
                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SEND)}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.userBA.updating,
    updatingError: storeState.userBA.updatingError,
    businessAccountId: storeState.system.businessAccountId
});

const mapDispatchToProps = {inviteUserToBusinessAccount, goBack, resetError};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(InviteUserBA));

import {ListCrewMembersFilters} from '../api/types';
import {CrewMemberStateCodeEnum} from '../../../../../../server';
import {PageURLParamDescriptionObject, PageURLParamDescriptions} from '../../../../../../core/utils/descriptions';

export const CrewMembersPageFiltersDescription: Required<PageURLParamDescriptionObject<ListCrewMembersFilters>> = {
    hideArchive: PageURLParamDescriptions.switchParam,
    search: PageURLParamDescriptions.searchParam(),
    stateCode: PageURLParamDescriptions.enumSelectParam(CrewMemberStateCodeEnum),
    professionId: PageURLParamDescriptions.numbersSelectParam,
    ratePerShift: PageURLParamDescriptions.numberInterval,
    ratePerHour: PageURLParamDescriptions.numberInterval,
    id: PageURLParamDescriptions.numbersSelectParam,
};

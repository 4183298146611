import React, { RefObject } from 'react';
import { Select, Select as SelectComponent } from 'antd';
import { SelectProps } from 'antd/lib/select';
import './custom-select.less';
import isEqual from 'lodash/isEqual';

interface CustomSelectProps extends SelectProps {
    _ref?: RefObject<Select>;
}

export class CustomSelect extends React.Component<CustomSelectProps> {
    shouldComponentUpdate(nextProps: Readonly<SelectProps>): boolean {
        return !isEqual(nextProps, this.props);
    }

    render() {
        return (
            <SelectComponent
                dropdownMatchSelectWidth={this.props.dropdownMatchSelectWidth === undefined ? false : this.props.dropdownMatchSelectWidth}
                allowClear={this.props.allowClear === undefined ? true : this.props.allowClear}
                {...this.props}
                ref={this.props._ref}
                defaultActiveFirstOption={this.props.defaultActiveFirstOption || false}
                className={'rr-custom-select ' + this.props.className}
            ></SelectComponent>
        );
    }
}

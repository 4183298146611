import { ContactInfoRead } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { EntityActionType } from '../../../../../../../index';
import { EntityActionsHook } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { contactsApi, useArchiveContactsMutation, useDeleteContactMutation } from '../api/contactsApi';

export const useContactEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const [archiveContacts, { isLoading: isArchiveContactsLoading }] = useArchiveContactsMutation();

    const [deleteContact, { isLoading: isDeleteContactLoading }] = useDeleteContactMutation();

    const isLoading = isArchiveContactsLoading || isDeleteContactLoading;

    const editContactCallback = useCallback(
        (
            ContactInfoRead: ContactInfoRead,
            options?: {
                withView?: boolean;
            }
        ) => {
            dispatch(
                contactsApi.endpoints.getContactById.initiate(
                    {
                        businessAccountId: businessAccountId,
                        entityId: ContactInfoRead.id,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());
            searchParams.append('modal', 'edit');
            searchParams.append('id', String(ContactInfoRead.id));

            if (options?.withView) {
                const booleanParam = PageURLParamDescriptions.booleanParam.toString(true);
                if (booleanParam) {
                    searchParams.append('view', booleanParam);
                }
            }

            const path = `${rootPath}/?` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editContactForDrawerCallback = useCallback(
        (contact: ContactInfoRead) => {
            editContactCallback(contact, {
                withView: true,
            });
        },
        [editContactCallback]
    );

    const deleteContactCallback = useCallback(
        async (contact: ContactInfoRead) => {
            deleteContact({
                contactId: contact.id,
                businessVersionObj: {
                    businessVersion: contact.businessVersion,
                },
            });
        },
        [deleteContact]
    );

    const archiveContactCallback = useCallback(
        (contact: ContactInfoRead) => {
            archiveContacts({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: contact.id,
                            archive: !contact.archive,
                            businessVersion: contact.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveContacts]
    );

    const onRowAction = useCallback(
        (item: ContactInfoRead, action: EntityActionType) => {
            const actionHandler: Partial<Record<typeof action, Function>> = {
                edit: () => editContactCallback(item),
                archive: () => archiveContactCallback(item),
                delete: () => deleteContactCallback(item),
            };

            actionHandler[action]?.();
        },
        [archiveContactCallback, deleteContactCallback, editContactCallback]
    );

    return useMemo(
        () => ({
            editAction: editContactCallback,
            editActionForDrawer: editContactForDrawerCallback,
            archiveActionForDrawer: archiveContactCallback,
            deleteActionForDrawer: deleteContactCallback,
            onRowAction,
            isLoading,
        }),
        [archiveContactCallback, deleteContactCallback, editContactCallback, editContactForDrawerCallback, isLoading, onRowAction]
    );
}) satisfies EntityActionsHook<ContactInfoRead>;

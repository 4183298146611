import {FormFieldsGroup, FormItemType} from "../../../../components/dynamicForm/DynamicForm";
import {FormUtils} from "../../../../core/utils/formUtils";
import {InstanceTrackingTypeCodeEnum, InventoryMovementEventTypeCodeEnum, InventoryMovementInfoCreate} from "../../../../server";
import {WrappedFormUtils} from "antd/lib/form/Form";
import {UploadAttachments} from "../../../../components/upload/uploadAttachments";
import {
    InventoryMovementCreateModalInstancesSelect,
    InventoryMovementCreateModalInstancesSelectProps
} from "./InventoryMovementCreateModalInstancesSelect";
import {RentElementsGridItem} from "../../../../types";

export const inventoryMovementCreateModalFields: FormFieldsGroup[] = FormUtils.typedFormFields<InventoryMovementInfoCreate & { files, rentElement, eventType }>([
    {
        fields: [
            {
                id: 'rentElement',
                type: FormItemType.Hidden,
            },
            {
                id: 'eventType',
                type: FormItemType.Hidden,
            },
            {
                label: 'Экземпляры',
                id: 'instanceIds',
                type: FormItemType.Component,
                component: InventoryMovementCreateModalInstancesSelect,
                componentProps: (form:WrappedFormUtils):InventoryMovementCreateModalInstancesSelectProps => {
                    const rentElement:RentElementsGridItem = form.getFieldValue('rentElement');
                    return {
                        from: new Date(rentElement.rentTerms.rentPeriodStartDate),
                        until: new Date(rentElement.rentTerms.rentPeriodEndDate),
                        productId: rentElement.productId,
                        variantId: rentElement.variantId,
                        instanceIds: rentElement.instanceIds
                    };
                },
                visible: (getFieldValue)=> {
                    const rentElement:RentElementsGridItem = getFieldValue('rentElement');
                    const visible = rentElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || rentElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED;
                    return visible;
                }
            },
            {
                label: 'Экземпляры',
                id: 'instanceCount',
                type: FormItemType.Integer,
                minLength: 1,
                maxLength: (form)=>{
                    const rentElement:RentElementsGridItem = form.getFieldValue('rentElement');
                    return rentElement.instanceCount;
                },
                defaultValue: 1,
                visible: (getFieldValue)=> {
                    const rentElement:RentElementsGridItem = getFieldValue('rentElement');
                    const visible = rentElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK || rentElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK;
                    return visible;
                }
            },
            {
                label: 'Дата',
                id: 'eventDate',
                required: true,
                type: FormItemType.DateTime
            },
            {
                label: 'Выведен из строя',
                id: 'outOfOrder',
                type: FormItemType.Switch,
                defaultValue: false,
                visible: (getFieldValue)=> {
                    const eventType:InventoryMovementEventTypeCodeEnum = getFieldValue('eventType');
                    return eventType === InventoryMovementEventTypeCodeEnum.DAMAGE;
                }
            },
            {
                label: 'Вина на стороне арендатора',
                id: 'causedByRenter',
                type: FormItemType.Switch,
                defaultValue: true
            },
            {
                id: 'files',
                type: FormItemType.Component,
                component: UploadAttachments,
                customFormItemClassName: 'inventoryMovementCreateModal-attachments-upload',
                validationRules: [
                    { max: 10, message: 'Число вложений не должно превышать 10', type: 'array' },
                ],
            }
        ]
    },
    {
        fields: [
            {
                label: 'Коментарий',
                id: 'comment',
                type: FormItemType.Text
            }
        ]
    }
]);

import {Icon, notification} from "antd";
import React, {ReactNode} from "react";
import './notification.less';
import {IconBell} from "../icons";

/**
 * Метод для показа боковых уведомлений
 * @param type - Тип уведомления
 * @param message - Сообщение
 * @param description - Описание
 * @param key - Ключ для уведомлений, что б показывалось в одно и тоже уведомление
 * @param onClose - колбэк при закрытии
 * @param duration - Время для исчезновения (null - не исчезает)
 */
export const showNotification = (type: 'error' | 'success' | 'info' | 'warning', message: string | ReactNode, description?: string | ReactNode, key?: string, onClose?:() => void, duration?: number|null) => {
    notification.success({
        className: 'rr-notification-notice rr-notification-notice-' + type,
        message: message,
        description: description ? <div className={'rr-notification-notice-description'}>{description}</div> : null,
        duration: duration,
        key: key,
        onClose: onClose,
        icon: <Icon component={IconBell} />
    });
};

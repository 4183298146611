import { FileTypeCodeEnum, serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import moment from 'moment/moment';

export interface ActGenerateArgs {
    businessAccountId: number;
    parentId: number;
    common: boolean;
    documentTemplateId: number;
    expandKits: boolean;
    joinSimilarRows: boolean;
    groupByCategories: boolean;
    search?: string;
    filters?: string[];
    includeWorkPlannings: boolean;
    includeExpenses: boolean;
    period?: [string, string];
    legalDetailId?: number;
}

export const generateAct = async (
    args: ActGenerateArgs,
    parentType: 'operation' | 'project' | 'projectTemplate' | 'shipping',
    fileType: FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
) => {
    if (parentType === 'operation') {
        if (fileType === FileTypeCodeEnum.WORD) {
            const res: AxiosResponse<Blob> = await serverApi.generateOperationActDocumentDocx(
                args.businessAccountId,
                args.parentId,
                args.common,
                args.documentTemplateId,
                args.expandKits,
                args.joinSimilarRows,
                args.groupByCategories,
                getCurrentTimezoneUTCOffset(),
                undefined,
                undefined,
                args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
                args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
                args.legalDetailId,
                undefined,
                'externalCode',
                'ASC',
                args.search,
                { query: { filters: args.filters }, responseType: 'blob' }
            );

            return res.data;
        } else {
            const res: AxiosResponse<Blob> = await serverApi.generateOperationActDocumentPdf(
                args.businessAccountId,
                args.parentId,
                args.common,
                args.documentTemplateId,
                args.expandKits,
                args.joinSimilarRows,
                args.groupByCategories,
                getCurrentTimezoneUTCOffset(),
                undefined,
                undefined,
                args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
                args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
                args.legalDetailId,
                undefined,
                'externalCode',
                'ASC',
                args.search,
                { query: { filters: args.filters }, responseType: 'blob' }
            );

            return res.data;
        }
    }

    if (fileType === FileTypeCodeEnum.WORD) {
        const res: AxiosResponse<Blob> = await serverApi.generateProjectActDocumentDocx(
            args.businessAccountId,
            args.parentId,
            args.includeWorkPlannings,
            args.includeExpenses,
            args.common,
            args.documentTemplateId,
            args.expandKits,
            args.joinSimilarRows,
            args.groupByCategories,
            getCurrentTimezoneUTCOffset(),
            undefined,
            undefined,
            args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
            args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
            args.legalDetailId,
            undefined,
            'externalCode',
            'ASC',
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    } else {
        const res: AxiosResponse<Blob> = await serverApi.generateProjectActDocumentPdf(
            args.businessAccountId,
            args.parentId,
            args.includeWorkPlannings,
            args.includeExpenses,
            args.common,
            args.documentTemplateId,
            args.expandKits,
            args.joinSimilarRows,
            args.groupByCategories,
            getCurrentTimezoneUTCOffset(),
            undefined,
            undefined,
            args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
            args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
            args.legalDetailId,
            undefined,
            'externalCode',
            'ASC',
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    }
};

import { isDefined } from '../../../shared/util/utils';
import { isNumber } from '../../../shared/util/isNumber';

export const centsToDollars = (cents: number | undefined) => {
    if (isDefined(cents) && isNumber(cents)) {
        cents = Number(cents);
        let value = +Math.abs(cents).toString().split('.')[0]; // обрубаем дробную часть, и приводим к числу
        const isNegative = cents < 0;
        let str: string | undefined;
        if (value < 100) str = (value < 10 ? '00' : '0') + value;
        else str = '' + value;
        return Number((isNegative ? '-' : '') + str.substring(0, str.length - 2) + '.' + str.substring(str.length - 2));
    } else return undefined;
};

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverApi, VehicleInfoRead } from '../../../../../../../server';
import { AppDispatch, GetState } from '../../../../../../../../index';
import { BaseThunkResponse } from '../../../../../../../store/rtqQueryApi/types';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { goBack } from 'connected-react-router';
import { vehiclesApi } from '../../api/vehicles.api';
import { CreateVehicleArgs, DeleteContactFromVehicleArgs, UpdateVehicleArgs, VehicleState } from './vehicle.types';
import { getServerError } from '../../../../../../../shared/util/utils';

const ACTION_TYPES = {
    CREATE_VEHICLE: 'vehicle/CREATE_VEHICLE',
    UPDATE_VEHICLE: 'vehicle/UPDATE_VEHICLE',
    LOAD_VEHICLE: 'vehicle/LOAD_VEHICLE',
    DELETE_VEHICLE: 'vehicle/DELETE_VEHICLE',
};

export const initialState: VehicleState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createVehicleThunk = createAsyncThunk(ACTION_TYPES.CREATE_VEHICLE, async (args: CreateVehicleArgs, { rejectWithValue }) => {
    const { businessAccountId, vehicleInfoCreate } = args;
    try {
        return await serverApi.createVehicle(businessAccountId, vehicleInfoCreate);
    } catch (error) {
        throw rejectWithValue(error as AxiosError);
    }
});

export const createVehicle = (args: CreateVehicleArgs) => (dispatch: AppDispatch) => {
    return dispatch(createVehicleThunk(args))
        .unwrap()
        .then((result) => {
            if (!(result instanceof Error)) {
                dispatch(vehiclesApi.util?.invalidateTags(['VehiclesList', 'Vehicle']));
                return result.data;
            } else {
                throw result;
            }
        });
};

export const updateVehicleThunk = createAsyncThunk(ACTION_TYPES.UPDATE_VEHICLE, async (args: UpdateVehicleArgs, { rejectWithValue }) => {
    const { businessAccountId, id, vehicleInfoUpdate } = args;

    try {
        return await serverApi.updateVehicleById(businessAccountId, id, vehicleInfoUpdate);
    } catch (error: unknown) {
        throw rejectWithValue(error as AxiosError);
    }
});

export const updateVehicle = (args: UpdateVehicleArgs) => (dispatch, getState: GetState) => {
    return dispatch(updateVehicleThunk(args))
        .unwrap()
        .then((result: BaseThunkResponse<VehicleInfoRead>) => {
            showNotification('success', 'Транспортное средство успешно изменено');
            dispatch(goBack());
            dispatch(vehiclesApi.util?.invalidateTags(['VehiclesList', 'Vehicle']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось изменить транспортное средство');
        });
};

export const deleteContactFromVehicle = (args: DeleteContactFromVehicleArgs) => (dispatch, getState: GetState) => {
    const contacts = [...(args.vehicleInfoUpdate.contacts || [])];
    const contactIndex = contacts.findIndex((contact) => contact.contactId === args.contactId);

    contacts.splice(contactIndex, 1);
    return dispatch(
        updateVehicleThunk({
            ...args,
            vehicleInfoUpdate: {
                ...args.vehicleInfoUpdate,
                contacts,
            },
        })
    ).then((result: BaseThunkResponse<VehicleInfoRead>) => {
        if ('error' in result.payload) {
            showNotification('error', 'Не удалось удалить контакт');
        } else {
            showNotification('success', 'Контакт успешно удален');
            dispatch(vehiclesApi.util?.invalidateTags(['VehiclesList', 'Vehicle']));
        }
    });
};

const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        clearVehicle: (state) => {
            state.entity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateVehicleThunk.pending, (state) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addCase(updateVehicleThunk.fulfilled, (state, action) => {
                state.updating = false;
                if (!('error' in action.payload.data)) {
                    state.entity = action.payload.data;
                }
            })
            .addCase(updateVehicleThunk.rejected, (state, action) => {
                state.updating = false;
                state.updatingError = getServerError(action.payload);
            });

        builder
            .addMatcher(vehiclesApi.endpoints.vehicleById.matchPending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addMatcher(vehiclesApi.endpoints.vehicleById.matchFulfilled, (state, { payload }) => {
                state.loading = false;
                state.entity = payload;
            })
            .addMatcher(vehiclesApi.endpoints.vehicleById.matchRejected, (state, { payload }) => {
                state.loading = false;
                state.loadingError = getServerError(payload);
            });

        builder
            .addMatcher(isAnyOf(createVehicleThunk.pending), (state) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(createVehicleThunk.fulfilled), (state) => {
                state.updating = false;
            })
            .addMatcher(isAnyOf(createVehicleThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = getServerError(action.payload);
            });
    },
});

export const { clearVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;

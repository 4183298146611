import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../../core/utils/descriptions';
import { AccessibilityMapFilters } from './accessibilityMapCalendarFilters.types';
import { CalendarDisplayType } from '../../../../components/Calendar/utils/data/calendarConstants';
import { InstanceTrackingTypeCodeEnum, TimetableTypeCodeEnum } from '../../../../../../../server';
import { HideValue } from '../../../elements/filters/elementsCalendarFilters.data';

export const accessibilityMapCalendarFiltersDescription: Required<PageURLParamDescriptionObject<AccessibilityMapFilters>> = {
    productId: PageURLParamDescriptions.numberParam,
    variantId: PageURLParamDescriptions.numberParam,
    instanceId: PageURLParamDescriptions.numberParam,
    renterId: PageURLParamDescriptions.numberParam,
    kitId: PageURLParamDescriptions.numberParam,
    projectId: PageURLParamDescriptions.numberParam,
    subrentId: PageURLParamDescriptions.numberParam,
    elementId: PageURLParamDescriptions.numberParam,
    hide: PageURLParamDescriptions.enumSelectParam(HideValue),
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    group: PageURLParamDescriptions.searchParam(),
    capture: PageURLParamDescriptions.searchParam(),
    displayType: PageURLParamDescriptions.enumSelectParam(CalendarDisplayType),
    productInstanceTrackingTypeCode: PageURLParamDescriptions.enumSelectParam(InstanceTrackingTypeCodeEnum),
    stacks: PageURLParamDescriptions.arrayOfEnumParam(TimetableTypeCodeEnum),
};

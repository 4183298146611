import { useCallback, useMemo } from 'react';
import { EntityActions } from '../../../../../components/page/entityGrid/hooks/useRowActions';
import { KitInfoRead } from '../../../../../server';
import { EntityGridRootPath } from '../../../../../components/page/entityGrid/types/params';
import { saveCurrentTabToStore } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { push } from 'connected-react-router';
import { deleteEntities, setArchiveState, setStatus } from '../reducers/kits.reducers';
import { useIntl } from 'react-intl';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { showConfirm2 } from '../../../../../components/confirm/showConfirm';
import { useEntityGridContext } from '../../../../../components/page/entityGrid/components/context/GridRefContext';

import { KitPageTabsEnum } from '../../../../../shared/constants/tabEnums';

type KitEntityActions = EntityActions<KitInfoRead>;

export const useEntityActions = (rootPath: EntityGridRootPath): EntityActions<KitInfoRead> => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();

    const getItemUrl = useCallback((kit: KitInfoRead) => `${rootPath}/${kit.id}`, [rootPath]);

    const editKitCallback: KitEntityActions['editAction'] = useCallback(
        (kit) => {
            saveCurrentTabToStore('kit', KitPageTabsEnum.DESCRIPTION);

            const itemUrl = getItemUrl(kit);
            dispatch(push(itemUrl));
            dispatch(push(`${itemUrl}/edit?tab=${KitPageTabsEnum.DESCRIPTION}`));
        },
        [dispatch, getItemUrl]
    );

    const archiveKitCallback = useCallback(
        (kit: KitInfoRead) => {
            dispatch(
                setArchiveState(intl, businessAccountId, [
                    {
                        id: kit.id,
                        businessVersion: kit.businessVersion,
                        archive: !kit.archive,
                    },
                ])
            );
        },
        [businessAccountId, dispatch, intl]
    );

    const copyKitCallback = useCallback(
        (kit: KitInfoRead) => {
            saveCurrentTabToStore('kit', KitPageTabsEnum.DESCRIPTION);

            const itemUrl = getItemUrl(kit);
            dispatch(push(itemUrl));
            dispatch(push(`${itemUrl}/copy?tab=${KitPageTabsEnum.DESCRIPTION}`));
        },
        [dispatch, getItemUrl]
    );

    const lockKitCallback = useCallback(
        (kit: KitInfoRead) => {
            dispatch(
                setStatus(intl, businessAccountId, [
                    {
                        id: kit.id,
                        businessVersion: kit.businessVersion,
                        transitionCode: kit.stateCode === 'NEW' || kit.stateCode === 'NOT_AVAILABLE' ? 'ACTIVATE' : 'BLOCK',
                    },
                ])
            );
        },
        [businessAccountId, dispatch, intl]
    );

    const deleteKitCallback = useCallback(
        async (kit: KitInfoRead) => {
            const yes = await showConfirm2({ intl, title: 'Удалить набор безвозвратно?' });
            if (yes) {
                dispatch(deleteEntities(intl, businessAccountId, [{ id: kit.id, businessVersion: kit.businessVersion }]));
                gridRef?.current?.clearSelection();
            }
        },
        [businessAccountId, dispatch, gridRef, intl]
    );

    const onRowAction: KitEntityActions['onRowAction'] = useCallback(
        (kit, action) => {
            const actionHandler: Partial<Record<typeof action, Function>> = {
                edit: () => editKitCallback(kit),
                archive: () => archiveKitCallback(kit),
                copy: () => copyKitCallback(kit),
                lock: () => lockKitCallback(kit),
                delete: () => deleteKitCallback(kit),
            };

            actionHandler[action]?.();
        },
        [archiveKitCallback, copyKitCallback, deleteKitCallback, editKitCallback, lockKitCallback]
    );

    return useMemo(
        () =>
            ({
                editAction: editKitCallback,
                onRowAction,
            } satisfies EntityActions<KitInfoRead>),
        [editKitCallback, onRowAction]
    );
};

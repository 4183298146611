import { useMemo } from 'react';
import { ProductsListFilters } from '../api/products.api.types';

export const useCategoryIds = (pageParams: ProductsListFilters) => {
    const { categoryIds } = pageParams;

    return useMemo(() => {
        if (categoryIds == null) return undefined;
        const newCategoryIds = Array.isArray(categoryIds) ? [...categoryIds] : [categoryIds];

        return newCategoryIds.map(String);
    }, [categoryIds]);
};

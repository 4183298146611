import React from 'react';
import { CustomIconComponentProps } from 'antd/lib/icon';
import {
    IconBriefcase,
    IconBullhorn,
    IconCalendar,
    IconCogSolid,
    IconDollyFlatbedSolid,
    IconDollySolid,
    IconExchange,
    IconHardHatSolid,
    IconHistorySolid,
    IconMapMarkedAltSolid,
    IconPlusCircle,
    IconQuestionCircle,
    IconScrollSolid, IconShoppingBagSolid,
    IconSitemap,
    IconStoreSolid,
    IconTachometerAltSolid,
    IconTags,
    IconUser,
    IconUsers,
} from '../components/icons';
import { LocalizationEnum } from '../localization';
import ProductPage from '../modules/main/inventory/products/ProductPage';
import { RentersPage } from '../modules/main/renters/RentersPage';
import RenterPage from '../modules/main/renters/tabs/counterparties/page/CounterpartyPage';
import CategoriesPage from '../modules/main/categories/categories-page';
import { NotfoundModule } from '../modules/main/notfound/notfoundModule';
import { ProjectsPage } from '../modules/main/projects/projectsPage';
import ProjectPage from '../modules/main/projects/production/page/projectPage';
import OperationPage from '../modules/main/operations/operationPage';
import { OperationFormPage } from '../modules/main/operationForm/OperationFormPage';
import SettingsPage from '../modules/main/settings/settingsPage';
import UserProfilePage from '../modules/main/userProfile/user-profile-page';
import KBPage from '../modules/main/kb/kb-page';
import SupportPage from '../modules/main/support/support-page';
import { Dashboard } from '../modules/main/dashboard/dashboard';
import { UserPermissionEnum } from '../shared/UserPermission';
import KitOfProductPage from '../modules/main/inventory/kits/kit-page';
import { InventoryPage } from '../modules/main/inventory/inventory-page';
import TemplatePage from '../modules/main/projects/templates/templatePage';
import { SubrentPage } from '../modules/main/subrent/subrentPage';
import { SubrentShippingPage } from '../modules/main/subrent/shippings/subrentShippingPage';
import SupportCreateRequestPage from '../modules/main/support/support-create-request-page';
import SupportRequestsPage from '../modules/main/support/support-requests-page';
import AnalyticReportsList from '../modules/main/analytics/analyticsPage';
import ReportPage from '../modules/main/analytics/reportPage';
import { IconChartArea } from '../components/icons/IconChartArea';
import { BusinessAccountModuleCodeEnum, BusinessAccountPermissionCodeEnum, GlobalPermissionCodeEnum } from '../server';
import { BusinessAccountsPage } from '../modules/main/businessAccounts/businessAccountsPage';
import HistoryPage from '../modules/main/history/HistoryPage';
import ComponentsPage from '../modules/main/dev/components/components-page';
import { DevPage } from '../modules/main/dev/dev-page';
import { GridPage } from '../modules/main/dev/grid/grid-page';
import { TestPage } from '../modules/main/testPage/testPage';
import { TestGridPage } from '../modules/main/dev/testGrid/testGridPage';
import LogisticsPage from '../modules/main/logistics/LogisticsPage';
import { CrewPage } from '../modules/main/crew/CrewPage';
import { CrewMemberPage } from '../modules/main/crew/tabs/crewMembers/page/components/page/CrewMemberPage';
import CalendarPage from '../modules/main/calendar/CalendarPage';
import {OfferPage} from "../modules/main/offers/page/offerPage";
import SimpleOrderPage from "../modules/main/projects/simpleOrders/page/simpleOrderPage";
import {NewOrderPage} from "../modules/main/operationForm/newOrderPage";

export interface IRouterItem {
    menuKey: string;
    title?: any;
    subsectionTitle?: any;
    subsectionIcon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
    path: string | never | string[];
    icon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
    component: any;
    exact?: boolean;
    permissions?: ReadonlyArray<UserPermissionEnum>; // Если не указано, или пустой массив, то РАЗРЕШЕНИЯ не проверяются
    sortOrder: number;
    inMenu?: boolean;
    menuPath?: string; // Это то что должно ставиться в ссылку для пункта меню
    childs?: IRouterItem[];
    moduleCode?: BusinessAccountModuleCodeEnum;
}

export const routes: Array<IRouterItem> = [
    {
        menuKey: 'testGridPage',
        path: '/:businessAccountId/testGridPage',
        component: process.env.NODE_ENV === 'development' ? TestGridPage : NotfoundModule,
        sortOrder: 0,
        inMenu: false,
    },
    {
        menuKey: 'test',
        path: '/:businessAccountId/test',
        component: process.env.NODE_ENV === 'development' ? TestPage : NotfoundModule,
        sortOrder: 0,
        inMenu: false,
    },
    {
        menuKey: 'grid',
        path: '/:businessAccountId/grid',
        component: process.env.NODE_ENV === 'development' ? GridPage : NotfoundModule,
        sortOrder: 0,
        inMenu: false,
    },
    {
        menuKey: 'components',
        path: '/:businessAccountId/components',
        component: process.env.NODE_ENV === 'development' ? ComponentsPage : NotfoundModule,
        sortOrder: 0,
        inMenu: false,
    },
    {
        menuKey: 'dev',
        path: '/:businessAccountId/dev',
        component: process.env.NODE_ENV === 'development' ? DevPage : NotfoundModule,
        sortOrder: 0,
        inMenu: false,
    },
    // Создать заявку
    {
        menuKey: 'operation',
        title: 'Действие',
        icon: IconPlusCircle,
        path: '/:businessAccountId/operation',
        component: OperationFormPage,
        sortOrder: 5,
        inMenu: true,
    },
    // Создать заявку
    {
        menuKey: 'operation',
        title: 'Новый заказ',
        icon: IconShoppingBagSolid,
        path: '/:businessAccountId/order',
        component: NewOrderPage,
        sortOrder: 6,
        inMenu: false,
    },
    // Dashboard
    {
        menuKey: 'dashboard',
        title: LocalizationEnum.ASPECT__MAIN_MENU__DASHBOARD,
        // icon: IconHome,
        icon: IconTachometerAltSolid,
        path: '/:businessAccountId/',
        component: Dashboard,
        exact: true,
        sortOrder: 10,
        inMenu: true,
        menuPath: '/:businessAccountId/',
    },
    // Календарь
    {
        menuKey: 'calendar',
        title: LocalizationEnum.ASPECT__MAIN_MENU__CALENDAR,
        icon: IconCalendar,
        path: '/:businessAccountId/calendar',
        component: CalendarPage,
        exact: true,
        permissions: [],
        sortOrder: 20,
        inMenu: true,
        menuPath: '/:businessAccountId/calendar',
    },
    // Операции, Редактирование комментария
    // {
    //     menuKey: 'operations',
    //     title: LocalizationEnum.ASPECT__MAIN_MENU__OPERATIONS,
    //     icon: IconExchange,
    //     path: ['/:businessAccountId/operations', '/:businessAccountId/operations/new'],
    //     exact: true,
    //     component: OperationsPage,
    //     sortOrder: 65,
    //     inMenu: true,
    //     menuPath: '/:businessAccountId/operations'
    // },
    // Карточка операции, Редактирование комментария
    {
        menuKey: 'operations',
        title: LocalizationEnum.ASPECT__MAIN_MENU__OPERATIONS,
        icon: IconExchange,
        path: '/:businessAccountId/history/operations/:id/:action?',
        exact: false,
        component: OperationPage,
        sortOrder: 30,
        inMenu: false,
    },
    {
        menuKey: 'logistics',
        title: 'Логистика',
        icon: IconMapMarkedAltSolid,
        path: [
            '/:businessAccountId/logistics',
            '/:businessAccountId/logistics/transportations',
            '/:businessAccountId/logistics/locations',
            '/:businessAccountId/logistics/vehicles',
        ],
        exact: true,
        component: LogisticsPage,
        sortOrder: 65,
        inMenu: true,
        menuPath: '/:businessAccountId/logistics',
        moduleCode: BusinessAccountModuleCodeEnum.LOGISTICS,
    },
    {
        menuKey: 'history',
        title: 'История',
        icon: IconHistorySolid,
        path: [
            '/:businessAccountId/history',
            '/:businessAccountId/history/operations',
            '/:businessAccountId/history/payments',
            '/:businessAccountId/history/payments/:id/edit',
            '/:businessAccountId/history/inventoryMovements',
            '/:businessAccountId/history/expenses',
            '/:businessAccountId/history/lifecycleEvents',
        ],
        exact: true,
        component: HistoryPage,
        sortOrder: 66,
        inMenu: true,
        menuPath: '/:businessAccountId/history',
    },
    // Проекты, Создание проекта
    {
        menuKey: 'projects',
        title: LocalizationEnum.ASPECT__MAIN_MENU__RENT,
        icon: IconBriefcase,
        path: [
            '/:businessAccountId/projects',
            '/:businessAccountId/projects/production',
            '/:businessAccountId/projects/production/new',
            '/:businessAccountId/projects/templates',
            '/:businessAccountId/projects/templates/new',
            '/:businessAccountId/projects/offers',
            '/:businessAccountId/projects/simpleOrders',
            //'/:businessAccountId/projects/simpleOrders/new',
        ],
        exact: true,
        component: ProjectsPage,
        sortOrder: 40,
        inMenu: true,
        menuPath: '/:businessAccountId/projects',
    },
    // Карточка проекта, Редактирование проекта
    {
        menuKey: 'projects',
        title: LocalizationEnum.ASPECT__MAIN_MENU__RENT,
        icon: IconBriefcase,
        subsectionTitle: LocalizationEnum.PAGE__PROJECTS__SUBSECTION_NAME_PROJECT,
        path: '/:businessAccountId/projects/production/:id/:action?',
        exact: false,
        component: ProjectPage,
        sortOrder: 40,
        inMenu: false,
    },
    // Карточка шаблона, Редактирование шаблона
    {
        menuKey: 'projects',
        title: LocalizationEnum.ASPECT__MAIN_MENU__RENT,
        icon: IconBriefcase,
        subsectionIcon: IconScrollSolid,
        subsectionTitle: LocalizationEnum.ASPECT__ENTITY_TYPE__TEMPLATES,
        path: '/:businessAccountId/projects/templates/:id/:action?',
        exact: false,
        component: TemplatePage,
        sortOrder: 40,
        inMenu: false,
    },
    {
        menuKey: 'projects',
        title: LocalizationEnum.ASPECT__MAIN_MENU__RENT,
        icon: IconBriefcase,
        subsectionTitle: 'Сметы',
        path: '/:businessAccountId/projects/offers/:id/:action?',
        exact: false,
        component: OfferPage,
        sortOrder: 40,
        inMenu: false,
        moduleCode: BusinessAccountModuleCodeEnum.OFFERS,
    },
    {
        menuKey: 'projects',
        title: LocalizationEnum.ASPECT__MAIN_MENU__RENT,
        icon: IconBriefcase,
        subsectionTitle: LocalizationEnum.PAGE__PROJECTS__SUBSECTION_NAME_PROJECT,
        path: '/:businessAccountId/projects/simpleOrders/:id/:action?',
        exact: false,
        component: SimpleOrderPage,
        sortOrder: 41,
        inMenu: false,
    },
    // Арендаторы, Создание арендатора
    {
        menuKey: 'counterparties',
        title: LocalizationEnum.ASPECT__MAIN_MENU__COUNTERPARTIES,
        icon: IconUsers,
        path: [
            '/:businessAccountId/crm',
            '/:businessAccountId/crm/counterparties',
            '/:businessAccountId/crm/counterparties/new',
            '/:businessAccountId/crm/contacts',
            '/:businessAccountId/crm/contacts/new',
            '/:businessAccountId/crm/contacts/:id/edit',
        ],
        exact: true,
        component: RentersPage,
        sortOrder: 50,
        inMenu: true,
        menuPath: '/:businessAccountId/crm',
    },
    // Карточка арендатора, Редактирование арендатора
    {
        menuKey: 'counterparties',
        title: LocalizationEnum.ASPECT__MAIN_MENU__COUNTERPARTIES,
        icon: IconUsers,
        path: '/:businessAccountId/crm/counterparties/:id/:action?',
        exact: false,
        component: RenterPage,
        sortOrder: 50,
        inMenu: false,
    },
    // Продукты
    {
        menuKey: 'inventory',
        title: LocalizationEnum.ASPECT__MAIN_MENU__INVENTORY,
        icon: IconTags,
        path: [
            '/:businessAccountId/inventory',
            '/:businessAccountId/inventory/products',
            '/:businessAccountId/inventory/products/new',
            '/:businessAccountId/inventory/kits',
            '/:businessAccountId/inventory/kits/new',
        ],
        exact: true,
        component: InventoryPage,
        sortOrder: 60,
        inMenu: true,
        menuPath: '/:businessAccountId/inventory',
    },

    // Персонал
    {
        menuKey: 'crew',
        title: LocalizationEnum.ASPECT__MAIN_MENU__CREW,
        icon: IconHardHatSolid,
        path: ['/:businessAccountId/crew', '/:businessAccountId/crew/professions', '/:businessAccountId/crew/crewMembers'],
        exact: true,
        component: CrewPage,
        sortOrder: 60,
        inMenu: true,
        menuPath: '/:businessAccountId/crew',
        moduleCode: BusinessAccountModuleCodeEnum.CREW,
    },

    // Карточка сотрудника
    {
        menuKey: 'crew',
        title: LocalizationEnum.ASPECT__MAIN_MENU__CREW,
        icon: IconHardHatSolid,
        subsectionTitle: 'Проектные работники',
        path: '/:businessAccountId/crew/crewMembers/:id/:action?',
        exact: false,
        component: CrewMemberPage,
        sortOrder: 60,
        inMenu: false,
    },

    // Наборы
    {
        menuKey: 'inventory',
        title: LocalizationEnum.ASPECT__MAIN_MENU__INVENTORY,
        icon: IconTags,
        path: ['/:businessAccountId/inventory/kits/', '/:businessAccountId/inventory/kits/new'],
        exact: true,
        component: InventoryPage,
        sortOrder: 60,
        inMenu: false,
    },

    // Карточка продукта
    {
        menuKey: 'inventory',
        title: LocalizationEnum.ASPECT__MAIN_MENU__INVENTORY,
        icon: IconTags,
        subsectionTitle: LocalizationEnum.ASPECT__MAIN_MENU__PRODUCTS,
        path: '/:businessAccountId/inventory/products/:id/:action?',
        exact: false,
        component: ProductPage,
        sortOrder: 60,
        inMenu: false,
    },
    // Карточка набора
    {
        menuKey: 'inventory',
        title: LocalizationEnum.ASPECT__MAIN_MENU__INVENTORY,
        icon: IconTags,
        subsectionTitle: LocalizationEnum.ASPECT__MAIN_MENU__KITS,
        subsectionIcon: IconDollyFlatbedSolid,
        path: ['/:businessAccountId/inventory/kits/:id/:action?'],
        exact: true,
        component: KitOfProductPage,
        sortOrder: 60,
        inMenu: false,
    },

    // Проекты, Создание проекта
    {
        menuKey: 'subrent',
        title: LocalizationEnum.ASPECT__MAIN_MENU__SUBRENT,
        icon: IconDollySolid,
        path: [
            '/:businessAccountId/subrent',
            '/:businessAccountId/subrent/shippings',
            '/:businessAccountId/subrent/shippings/new',
            '/:businessAccountId/subrent/templates',
            '/:businessAccountId/subrent/templates/new',
        ],
        exact: true,
        component: SubrentPage,
        sortOrder: 61,
        inMenu: true,
        menuPath: '/:businessAccountId/subrent',
        moduleCode: BusinessAccountModuleCodeEnum.SUBRENT,
    },
    // Карточка поставки, Редактирование поставки
    {
        menuKey: 'subrent',
        title: LocalizationEnum.ASPECT__MAIN_MENU__SUBRENT,
        icon: IconDollySolid,
        path: '/:businessAccountId/subrent/shippings/:id/:action?',
        exact: false,
        component: SubrentShippingPage,
        sortOrder: 61,
        inMenu: false,
    },
    // Раздел аналитики
    {
        menuKey: 'analytics',
        title: LocalizationEnum.ASPECT__MAIN_MENU__ANALYTICS,
        path: '/:businessAccountId/reports',
        icon: IconChartArea,
        component: AnalyticReportsList,
        sortOrder: 66,
        exact: true,
        inMenu: true,
        moduleCode: BusinessAccountModuleCodeEnum.STATISTICS,
    },
    {
        menuKey: 'analytics',
        title: LocalizationEnum.ASPECT__MAIN_MENU__ANALYTICS,
        path: '/:businessAccountId/reports/:id',
        icon: IconChartArea,
        subsectionTitle: 'Отчёты',
        exact: false,
        sortOrder: 66,
        inMenu: false,
        component: ReportPage,
        moduleCode: BusinessAccountModuleCodeEnum.STATISTICS,
    },

    // Категории
    {
        menuKey: 'categories',
        title: LocalizationEnum.ASPECT__MAIN_MENU__CATEGORIES,
        icon: IconSitemap,
        path: '/:businessAccountId/categories',
        component: CategoriesPage,
        sortOrder: 70,
        inMenu: true,
    },

    // Настройки
    {
        menuKey: 'settings',
        title: LocalizationEnum.ASPECT__MAIN_MENU__SETTINGS,
        icon: IconCogSolid,
        path: '/:businessAccountId/settings',
        component: SettingsPage,
        sortOrder: 100,
        inMenu: true,
        permissions: [GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS, BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS],
        menuPath: '/:businessAccountId/settings',
    },
    // Помощь
    {
        menuKey: 'support',
        title: LocalizationEnum.ASPECT__MAIN_MENU__SUPPORT,
        icon: IconBullhorn,
        path: ['/:businessAccountId/support', '/:businessAccountId/support/requests'],
        component: SupportPage,
        sortOrder: 110,
        exact: true,
        inMenu: true,
        menuPath: '/:businessAccountId/support',
    },
    {
        menuKey: 'support',
        title: LocalizationEnum.ASPECT__MAIN_MENU__SUPPORT,
        icon: IconBullhorn,
        path: '/:businessAccountId/support/requests/create/:requestTypeId',
        component: SupportCreateRequestPage,
        sortOrder: 111,
        inMenu: false,
    },
    {
        menuKey: 'support',
        title: LocalizationEnum.ASPECT__MAIN_MENU__SUPPORT__REQUESTS,
        icon: IconBullhorn,
        path: '/:businessAccountId/support/requests/:id',
        component: SupportRequestsPage,
        sortOrder: 112,
        inMenu: false,
    },
    //KB

    {
        menuKey: 'kb',
        title: LocalizationEnum.ASPECT__MAIN_MENU__KB,
        icon: IconQuestionCircle,
        path: '/:businessAccountId/kb',
        component: KBPage,
        exact: true,
        sortOrder: 120,
        inMenu: true,
    },
    {
        menuKey: 'kb',
        title: LocalizationEnum.ASPECT__MAIN_MENU__KB,
        icon: IconQuestionCircle,
        path: '/:businessAccountId/kb/:id',
        component: KBPage,
        sortOrder: 121,
        inMenu: false,
    },

    {
        menuKey: 'businessAccounts',
        title: 'Компании',
        icon: IconStoreSolid,
        path: '/:businessAccountId/businessAccounts',
        component: BusinessAccountsPage,
        exact: true,
        sortOrder: 122,
        inMenu: true,
        permissions: [GlobalPermissionCodeEnum.VIEWBUSINESSACCOUNT],
    },
    // Профиль
    {
        menuKey: 'userProfile',
        title: LocalizationEnum.ASPECT__MAIN_MENU__PROFILE,
        icon: IconUser,
        path: '/:businessAccountId/userProfile',
        component: UserProfilePage,
        sortOrder: 130,
        inMenu: false,
    },
    // Профиль пользователя
    {
        menuKey: 'profile',
        title: LocalizationEnum.ASPECT__MAIN_MENU__PROFILE,
        icon: IconUser,
        path: '/:businessAccountId/users/:id',
        component: UserProfilePage,
        sortOrder: 0,
        inMenu: false,
    },
    // Страница не найдена
    {
        menuKey: 'notfound',
        path: '/:businessAccountId/',
        component: NotfoundModule,
        sortOrder: 1000,
        inMenu: false,
    },
];

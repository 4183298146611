import React, {ReactNode} from 'react';
import {Icon, Popover} from 'antd';
import {IconHourglassSolid, IconSyncSolid} from "../../../components/icons";
import "./RecalculateShiftsCount.less";
import {isDefined, joinClasses} from "../../../shared/util/utils";
import {convertMinutesToHoursMinString} from "../../../shared/util/getDateString";

interface IProps {
    from?: number;
    to?: number;
    shiftLengthInMinutes?: number;
    hasOwnShiftLength?: boolean;
    onClick?: ()=>void;
    className?: string;
    style?: React.CSSProperties;
    hint?: ReactNode;
}

export class RecalculateShiftsCountButton extends React.Component<IProps> {

    render = () => {
        let disabled = this.props.from === this.props.to || (this.props.to !== undefined && this.props.to < 0);
        return (
            <Popover overlayClassName={'rr-RecalculateShiftsCountButton__popover'} placement={'rightTop'} content={
                <>
                    <div>
                        <div className={joinClasses('rr-RecalculateShiftsCountButton__popover__from', isDefined(this.props.from) && this.props.from < 0 ? 'rr-RecalculateShiftsCountButton__popover__from--error' : undefined)}>{this.props.from}</div>
                        <div className={joinClasses('rr-RecalculateShiftsCountButton__popover__arrow')}>→</div>
                        <div className={joinClasses('rr-RecalculateShiftsCountButton__popover__to', !disabled ? 'rr-RecalculateShiftsCountButton__popover__to--highlited' : undefined, this.props.to !== undefined && this.props.to < 0 ? 'rr-RecalculateShiftsCountButton__popover__to--error' : undefined)}>{this.props.to}</div>
                    </div>
                    {
                        this.props.shiftLengthInMinutes!== undefined && <div className={'rr-RecalculateShiftsCountButton__popover__shiftLengthInMinutes-block'}>
                            <div>
                                <Icon component={IconHourglassSolid} style={{fontSize: 16, marginRight: 6, marginTop: 2, marginLeft: -4, verticalAlign: 'top'}} />
                                {convertMinutesToHoursMinString(this.props.shiftLengthInMinutes)}
                            </div>
                            {!isDefined(this.props.hint) ? (this.props.hasOwnShiftLength ? <div>(По продукту)</div> : <div>(По компании)</div>) : null}
                            {isDefined(this.props.hint) && <div>{this.props.hint}</div>}
                        </div>
                    }
                </>
            }>
                <Icon
                    style={this.props.style}
                    className={joinClasses('rr-RecalculateShiftsCountButton__icon', disabled ? 'rr-RecalculateShiftsCountButton__icon--disabled' : undefined, this.props.className, !this.props.onClick ? 'rr-RecalculateShiftsCountButton__icon__without-hover' : undefined)}
                    onClick={!disabled && this.props.onClick ? ()=>{
                        if(!disabled && this.props.onClick) this.props.onClick();
                    } : undefined}
                    component={IconSyncSolid}
                />
            </Popover>
        );
    };
}

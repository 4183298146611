import React from 'react';
import { Button, Icon } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { IconPlus } from '../../../icons';
import './EntityButton.less';
import classNames from 'classnames';

interface CreateEntityButtonProps extends ButtonProps {}

export const CreateEntityButton = (props: CreateEntityButtonProps) => {
    return (
        <Button {...props} className={classNames('rr-create-entity-btn', props.className)}>
            <Icon component={IconPlus} />
        </Button>
    );
};

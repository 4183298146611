import {
    CreateCrewMemberArgs,
    CrewMemberState,
    DeleteCrewMemberArgs,
    UpdateCrewMemberArgs,
    UpdateCrewMemberProfessionsArgs,
} from './types';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { crewMembersApi, getCrewMemberById } from '../../api/crewMembers.api';
import { serverApi } from '../../../../../../../server';
import { AxiosError } from 'axios';
import { AppDispatch } from '../../../../../../../../index';
import { getServerError } from '../../../../../../../shared/util/utils';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { goBack } from 'connected-react-router';
import { IRootState } from '../../../../../../../shared/reducers';

const ACTION_TYPES = {
    LOAD_CREW_MEMBER: 'crewMember/LOAD_CREW_MEMBER',
    CREATE_CREW_MEMBER: 'crewMember/CREATE_CREW_MEMBER',
    UPDATE_CREW_MEMBER: 'crewMember/UPDATE_CREW_MEMBER',
    UPDATE_CREW_MEMBER_PROFESSIONS: 'crewMember/UPDATE_CREW_MEMBER_PROFESSIONS',
    DELETE_CREW_MEMBER: 'crewMember/DELETE_CREW_MEMBER',
};

const initialState: CrewMemberState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createCrewMemberThunk = createAsyncThunk(ACTION_TYPES.CREATE_CREW_MEMBER, async (args: CreateCrewMemberArgs, { rejectWithValue }) => {
    const { businessAccountId, crewMemberInfoCreate } = args;

    try {
        return await serverApi.createCrewMember(businessAccountId, crewMemberInfoCreate);
    } catch (error) {
        throw rejectWithValue(error as AxiosError);
    }
});

export const createCrewMember = (args: CreateCrewMemberArgs) => (dispatch: AppDispatch) => {
    return dispatch(createCrewMemberThunk(args))
        .unwrap()
        .then((result) => {
            if (result.status === 201) {
                showNotification('success', 'Проектный работник создан');
                dispatch(crewMembersApi.util?.invalidateTags(['CrewMembersList', 'CrewMember']));
                return result.data;
            }
        })
        .catch((error) => {
            showNotification('error', 'Не удалось создать проектного работника');
            throw error;
        });
};

export const updateCrewMemberThunk = createAsyncThunk(
    ACTION_TYPES.UPDATE_CREW_MEMBER,
    async (args: UpdateCrewMemberArgs, { rejectWithValue }) => {
        const { businessAccountId, id, crewMemberInfoUpdate } = args;

        try {
            return await serverApi.updateCrewMember(businessAccountId, id, crewMemberInfoUpdate);
        } catch (error: unknown) {
            throw rejectWithValue(error);
        }
    }
);

export const updateCrewMember = (args: UpdateCrewMemberArgs) => (dispatch: AppDispatch) => {
    return dispatch(updateCrewMemberThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Проектный работник обновлен');
            dispatch(goBack());
            dispatch(crewMembersApi.util?.invalidateTags(['CrewMembersList', 'CrewMember']));
        })
        .catch((error) => {
            showNotification('error', 'Не удалось обновить проектного работника');
        });
};

export const deleteCrewMember = createAsyncThunk(
    ACTION_TYPES.DELETE_CREW_MEMBER,
    async (args: DeleteCrewMemberArgs, { dispatch, getState }) => {
        const { businessAccountId, id, businessVersionObj } = args;

        try {
            await serverApi.deleteCrewMember(businessAccountId, id, businessVersionObj);
            showNotification('success', 'Проектный работник успешно удалён');

            dispatch(crewMembersApi.util?.invalidateTags(['CrewMembersList', 'CrewMember']));
            const state = getState() as IRootState;
            if (state.crewMember.entity) {
                dispatch(
                    loadCrewMember({
                        businessAccountId,
                        entityId: id,
                    })
                );
            }
        } catch (error: unknown) {
            showNotification('error', 'Не удалось проектного работника');
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

export const updateCrewMemberProfessions = createAsyncThunk(
    ACTION_TYPES.UPDATE_CREW_MEMBER_PROFESSIONS,
    async (args: UpdateCrewMemberProfessionsArgs, { dispatch, rejectWithValue }) => {
        const { businessAccountId, crewMemberId, professionAssignmentObjWrite } = args;

        try {
            await serverApi.updateCrewMemberRates(businessAccountId, crewMemberId, professionAssignmentObjWrite);
            dispatch(
                loadCrewMember({
                    businessAccountId,
                    entityId: crewMemberId,
                })
            );
        } catch (e) {
            throw rejectWithValue(e);
        }
    }
);

export const loadCrewMember = createAsyncThunk(ACTION_TYPES.LOAD_CREW_MEMBER, getCrewMemberById);

export const crewMemberSlice = createSlice({
    name: 'crewMember',
    initialState,
    reducers: {
        clearCrewMember: () => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCrewMember.pending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addCase(loadCrewMember.rejected, (state, action) => {
                state.loading = false;
                state.loadingError = getServerError(action.payload);
            })
            .addMatcher(
                isAnyOf(createCrewMemberThunk.pending, updateCrewMemberThunk.pending, updateCrewMemberProfessions.pending),
                (state, action) => {
                    state.updating = true;
                    state.updatingError = undefined;
                }
            )
            .addMatcher(isAnyOf(createCrewMemberThunk.fulfilled, updateCrewMemberProfessions.fulfilled), (state, action) => {
                state.updating = false;
            })
            .addMatcher(isAnyOf(loadCrewMember.fulfilled, updateCrewMemberThunk.fulfilled), (state, action) => {
                state.loading = false;
                state.updating = false;
                state.entity = action.payload.data;
            })
            .addMatcher(
                isAnyOf(createCrewMemberThunk.rejected, updateCrewMemberThunk.rejected, updateCrewMemberProfessions.rejected),
                (state, action) => {
                    state.updating = false;
                    state.updatingError = getServerError(action.payload);
                }
            );
    },
});

export const { clearCrewMember } = crewMemberSlice.actions;
export default crewMemberSlice.reducer;

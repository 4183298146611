import {PageURLParamDescriptionObject} from '../../utils/descriptions';
import {EntityGridName} from '../../../components/page/entityGrid/types/grid';
import {EntityGridURLParams} from '../../../components/page/entityGrid/types/params';
import {useMemo} from 'react';
import {entityGridMainParamsDescription} from '../../../components/page/entityGrid/data/paramsDescription';
import {usePageURLParams} from './usePageURLParams';
import {PageUrlParamsObject, UsePageURLParamsProps} from './types';

export const usePageURLParamsFromDescription = <PageParams extends object>(args: {
    pageParamsDescription: PageURLParamDescriptionObject<PageParams>;
    gridName: EntityGridName;
    skipLocationChange?: boolean;
    outerParams?: (keyof PageParams)[];
}): PageUrlParamsObject<EntityGridURLParams<PageParams>> => {
    const { pageParamsDescription, gridName, skipLocationChange, outerParams } = args;

    const pageParamsProps = useMemo<UsePageURLParamsProps<PageParams>>(
        () => ({
            paramsDescription: {
                ...pageParamsDescription,
                ...entityGridMainParamsDescription,
            },
            pageName: gridName,
            skip: skipLocationChange,
            outerParams,
            storageSuppressedKeys: ["modal", "view", "id"]
        }),
        [gridName, outerParams, pageParamsDescription, skipLocationChange]
    );
    return usePageURLParams(pageParamsProps) as PageUrlParamsObject<EntityGridURLParams<PageParams>>;
};

import { PageUrlParamsObject } from '../../../../../core/hooks/urlParams/types';
import { KitsListQueryParams } from '../api/kits.api.types';
import { EntityGridProps } from '../../../../../components/page/entityGrid/types/grid';
import { useCallback, useEffect, useMemo } from 'react';
import { GridCategoriesTree } from '../../../../../components/categories/GridCategoriesTree/GridCategoriesTree';
import { useCategoryIds } from '../../products/hooks/useCategoryIds';
import { useEntityGridContext } from '../../../../../components/page/entityGrid/components/context/GridRefContext';
import { loadCategories } from '../../../../../shared/reducers/entities.reducer';
import { CategoryTypeCodeEnum } from '../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { InventoryPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';
import { productsApi } from '../../products/api/products.api';

export const useCategoriesProps = (
    pageParamsObject: PageUrlParamsObject<KitsListQueryParams>
): EntityGridProps<never, never, never>['categoriesProps'] => {
    const { pageParams, updatePageParams } = pageParamsObject;
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const categoryIds = useCategoryIds(pageParams);

    const categoriesOnSelect = useCallback(
        (selectedKeys?: string[]) => {
            if (selectedKeys == null) return;

            const categoryIds = selectedKeys.map(Number);

            gridRef?.current?.clearSelection();
            ParamsUtils.setGridStorageDataParamsFilters(
                InventoryPageTabsEnum.PRODUCTS,
                {
                    categoryIds: categoryIds.map(String).toString(),
                },
                true
            );
            productsApi?.util.invalidateTags(['ProductsList', 'ProductsAvailableFiltersList']);
            updatePageParams({
                page: 1,
                categoryIds,
            });
        },
        [gridRef, updatePageParams]
    );

    useEffect(() => {
        dispatch(loadCategories(businessAccountId, CategoryTypeCodeEnum.PRODUCT));
    }, [businessAccountId, dispatch]);

    return useMemo(
        () =>
            ({
                categoriesType: 'PRODUCT',
                categoriesFilterOffsetBottom: 131,
                categoriesFilter: true,
                categoriesComponent: GridCategoriesTree,
                categoriesSelectedKeys: categoryIds,
                categoriesOnSelect,
            } satisfies EntityGridProps<never, never, never>['categoriesProps']),
        [categoriesOnSelect, categoryIds]
    );
};

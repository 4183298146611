import React from 'react';
import {useFormatDecimal} from "../../core/hooks/useFormatDecimal";

interface FormatNumberProps {
    value: number;
    withoutDots?: boolean;
    precision?: number;
}

export const FormatNumber = ({value, withoutDots, ...props}: FormatNumberProps) => {
    let render = useFormatDecimal(value, props.precision, 'number', withoutDots);
    return render;
};

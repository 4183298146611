import {IRootState} from '../../../../../shared/reducers';
import {resetError, updateEntity} from '../reducers/simpleOrder.reducer';
import {goBack, push} from 'connected-react-router';
import {connect} from 'react-redux';
import {ProjectInfoUpdate} from '../../../../../server';
import {showConfirm} from '../../../../../components/confirm/showConfirm';
import {ModalFormNEW, ModalFormNEWProps} from '../../../../../components/modalForm/ModalFormNEW';
import {FormFieldsGroup} from '../../../../../components/dynamicForm/DynamicForm';
import {simpleOrderFormFields} from './simpleOrderFormFields';
import {LocalizationEnum, localizeIntl} from '../../../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {updateModalFilters} from "../../../../../shared/reducers/userSettings/userSettings.reducer";

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
}

class _ProjectCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {

    static defaultProps = {
        formFields: simpleOrderFormFields,
    };

    onOk = (data: any) => {
        let newData = {...this.props.initialValues, ...data} as ProjectInfoUpdate;
        if (newData.comment === '') delete newData.comment;
        if(newData.id) this.props.updateEntity(this.props.intl, this.props.businessAccountId, newData.id, newData);
    };

    getInitialValues = () => {
        let initialValues: any;
        initialValues = {
            ...this.props.initialValues,
        };
        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            if (!this.props.onCancel) {
                this.props.goBack();
            } else {
                this.props.onCancel();
            }
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.simpleOrder.updating,
    updatingError: storeState.simpleOrder.updatingError,
    businessAccountPreferences: storeState.businessAccountPreferences.preferences,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {updateEntity, goBack, resetError, push, updateModalFilters};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SimpleOrderEditModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ProjectCreateModal));

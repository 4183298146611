import React, {ReactNode} from 'react';
import {Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import classNames from "classnames";
import {IconPlus} from "../../icons";
import {RoundButton} from "../../index";
import './addButton2.less';

interface AddButtonProps {
    disabled?: ButtonProps['disabled'];
    style?: ButtonProps['style'];
    className?: ButtonProps['className'];
    onClick?: ButtonProps['onClick'];
    label?: ReactNode;
}

export const AddButton2 = ({style, className, disabled, onClick, label}: AddButtonProps) => {
    // 'rr-download-document-button', 'rr-project-page-description--export-contract-button'
    return (
        <RoundButton
            colorScheme={'successSecondary'}
            style={style}
            className={classNames('rr-add-btn2', className)}
            disabled={disabled}
            onClick={onClick}
        >
            <Icon component={IconPlus}/>
            {label === undefined ? 'Добавить' : label}
        </RoundButton>
    );
};

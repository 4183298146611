import { BusinessAccountPermissionCodeEnum, GlobalPermissionCodeEnum } from '../../server';
import { UserPermissionEnum } from '../UserPermission';

export const canViewFinancialData = (permissions: UserPermissionEnum[] | undefined | null) => {
    return Boolean(
        permissions &&
            (permissions.includes(GlobalPermissionCodeEnum.VIEWFINANCIALDATA) ||
                permissions.includes(BusinessAccountPermissionCodeEnum.VIEWFINANCIALDATA))
    );
};

export const canUpdateFinancialData = (permissions: UserPermissionEnum[] | undefined | null) => {
    return Boolean(
        permissions &&
            (permissions.includes(GlobalPermissionCodeEnum.UPDATEFINANCIALDATA) ||
                permissions.includes(BusinessAccountPermissionCodeEnum.UPDATEFINANCIALDATA))
    );
};

export const canUpdateSystemOptions = (permissions: UserPermissionEnum[] | undefined | null) => {
    return Boolean(
        permissions &&
            (permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) ||
                permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS))
    );
};

export const canCreateCorrectionOperation = (permissions: UserPermissionEnum[] | undefined | null) => {
    return Boolean(
        permissions &&
            (permissions.includes(GlobalPermissionCodeEnum.CREATECORRECTIONOPERATION) ||
                permissions.includes(BusinessAccountPermissionCodeEnum.CREATECORRECTIONOPERATION))
    );
};

export const canCreateBusinessAccount = (permissions: UserPermissionEnum[] | undefined | null) => {
    return Boolean(permissions && permissions.includes(GlobalPermissionCodeEnum.CREATEBUSINESSACCOUNT));
};

export const canViewBusinessAccount = (permissions: UserPermissionEnum[] | undefined | null) => {
    return Boolean(permissions && permissions.includes(GlobalPermissionCodeEnum.VIEWBUSINESSACCOUNT));
};

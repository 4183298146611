import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { ProjectInfoRead, serverApi } from '../../../../server';
import { ListProjectsArgs, ListProjectsData } from './types';
import { AxiosError } from 'axios';
import { showNotification } from '../../../../components/notification/showNotification';
import { OffersUtils } from './offers.api.utils';
import { GetEntityByIdArgs } from '../../../../components/page/entityGrid/types/api';

export const getProjectByIdQueryFunction = async ({ businessAccountId, entityId }: GetEntityByIdArgs) => {
    try {
        return await serverApi.getProjectById(businessAccountId, entityId);
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить смету');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const offersApi = createApi({
    reducerPath: 'offersApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ProjectsList', 'Project'],
    endpoints: (builder) => ({
        listProjects: builder.query<ListProjectsData, ListProjectsArgs>({
            queryFn: async ({ businessAccountId, params, projectId }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = OffersUtils.createRequestFilters(params);
                const sortBy = params.sortBy || undefined;
                const sortOrder = params.sortBy ? params.sortOrder : undefined;

                try {
                    const response = await serverApi.listProjects(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        sortBy,
                        sortOrder,
                        params.search
                    );

                    return {
                        data: {
                            entitiesData: {
                                ...response.data,
                                records: response.data.records.map((item) => ({
                                    ...item,
                                })),
                            },
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['ProjectsList'],
        }),
        getProjectById: builder.query<ProjectInfoRead, GetEntityByIdArgs>({
            queryFn: getProjectByIdQueryFunction,
            providesTags: ['Project'],
        }),
    }),
});

export const { useListProjectsQuery, useGetProjectByIdQuery } = offersApi;

import { FC, useMemo, useRef, useState } from 'react';
import { GridRefContext } from '../../context/GridRefContext';
import { _Grid } from '../../../../../grid/Grid';
import { EntitiesSelectionContext, EntitiesSelectionContextValue } from '../../context/EntitiesSelectionContext';

export const EntityGridSelectionProvider: FC = ({ children }) => {
    /* Grid state */
    const gridRef = useRef<_Grid>(null);
    const [entitySelection, setEntitySelection] = useState<number[]>([]);
    const memoizedEntitySelectionState = useMemo(() => [entitySelection, setEntitySelection], [entitySelection]);

    return (
        <GridRefContext.Provider value={gridRef}>
            <EntitiesSelectionContext.Provider value={memoizedEntitySelectionState as EntitiesSelectionContextValue}>
                {children}
            </EntitiesSelectionContext.Provider>
        </GridRefContext.Provider>
    );
};

import { TransportationRecord } from '../../../server';
import React from 'react';

export const tableCellClickRenderer = <T extends TransportationRecord>(value: any, row: T, columnDef) => {
    const onClick = columnDef.onCellAction;
    const dataIndex = columnDef.id;
    const columnCustomRenderer = columnDef.columnCustomRenderer;

    return (
        <a
            className={'rr-grid-text-cell-left'}
            style={{
                width: 'fit-content',
            }}
            title={value}
            onClick={(event) => {
                event.stopPropagation();
                onClick(dataIndex, row);
            }}
        >
            {columnCustomRenderer ? columnCustomRenderer(value, row) : value}
        </a>
    );
};

import React, { FC } from 'react';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconBriefcase, IconTags } from '../../../../../../../../components/icons';
import { RentActivityFrameTypeCodeEnum } from '../../../../../../../../server';
import { ActivityFramesCalendarItem } from '../../../../../components/Calendar/types/items';

export const TypeGroupLine: FC<GroupItemProps<ActivityFramesCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const {
        record: { rentActivityFrameTypeCode },
    } = groupItem;

    if (rentActivityFrameTypeCode == null) return null;

    return (
        <GroupLine
            IconComponent={rentActivityFrameTypeCode === RentActivityFrameTypeCodeEnum.PROJECT ? IconBriefcase : IconTags}
            displayType={displayType}
            title={{
                text: rentActivityFrameTypeCode === RentActivityFrameTypeCodeEnum.PROJECT ? 'Проект' : 'Поставка',
            }}
        />
    );
};

import { EntityTypeCodeEnum } from '../../server';
import { LocalizationEnum } from '../index';

type OmitCodes =
    | EntityTypeCodeEnum.COMMONDOCUMENTTEMPLATE
    | EntityTypeCodeEnum.REPORTCONFIGURATION
    | EntityTypeCodeEnum.REPORTVISUALIZATION
    | EntityTypeCodeEnum.REPORTINSTANCE
    | EntityTypeCodeEnum.USER
    | EntityTypeCodeEnum.SYSTEMOPTION
    | EntityTypeCodeEnum.PAYMENTMETHOD
    | EntityTypeCodeEnum.LEGALDETAILS;

export const entityTypeCodeMap: Omit<Record<EntityTypeCodeEnum, LocalizationEnum>, OmitCodes> = {
    [EntityTypeCodeEnum.RENTER]: LocalizationEnum.ASPECT__ENTITY_TYPE__RENTER,
    [EntityTypeCodeEnum.PROJECT]: LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT,
    [EntityTypeCodeEnum.TEMPLATE]: LocalizationEnum.ASPECT__ENTITY_TYPE__TEMPLATE,
    [EntityTypeCodeEnum.SUBRENT]: LocalizationEnum.ASPECT__ENTITY_TYPE__SUBRENT,
    [EntityTypeCodeEnum.OPERATION]: LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATION,
    [EntityTypeCodeEnum.RENTELEMENT]: LocalizationEnum.ASPECT__ENTITY_TYPE__ELEMENT,
    [EntityTypeCodeEnum.DOCUMENTTEMPLATE]: LocalizationEnum.ASPECT__ENTITY_TYPE__DOCUMENT_TEMPLATE,
    [EntityTypeCodeEnum.KIT]: LocalizationEnum.ASPECT__ENTITY_TYPE__KIT,
    [EntityTypeCodeEnum.PRODUCT]: LocalizationEnum.ASPECT__ENTITY_TYPE__PRODUCT,
    [EntityTypeCodeEnum.VARIANT]: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
    [EntityTypeCodeEnum.INSTANCE]: LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCE,
    [EntityTypeCodeEnum.PAYMENT]: LocalizationEnum.ASPECT__ENTITY_TYPE__PAYMENT,
    [EntityTypeCodeEnum.BUSINESSACCOUNT]: LocalizationEnum.ASPECT__ENTITY_TYPE__BUSINESS_ACCOUNT,
    [EntityTypeCodeEnum.CATEGORY]: LocalizationEnum.ASPECT__ENTITY_TYPE__CATEGORY,
    [EntityTypeCodeEnum.CUSTOMFIELD]: LocalizationEnum.ASPECT__ENTITY_TYPE__CUSTOM_FIELD,
    [EntityTypeCodeEnum.CONTACT]: LocalizationEnum.ASPECT__ENTITY_TYPE__CONTACT,
    [EntityTypeCodeEnum.LOCATION]: LocalizationEnum.ASPECT__ENTITY_TYPE__LOCATION,
    [EntityTypeCodeEnum.VEHICLE]: LocalizationEnum.ASPECT__ENTITY_TYPE__VEHICLE,
    [EntityTypeCodeEnum.INVENTORYMOVEMENT]: LocalizationEnum.ASPECT__ENTITY_TYPE__INVENTORY_MOVEMENT,
    [EntityTypeCodeEnum.PRICINGSCHEME]: LocalizationEnum.ASPECT__ENTITY_TYPE__PRICING_SCHEME,
    [EntityTypeCodeEnum.TRANSPORTATION]: LocalizationEnum.ASPECT__ENTITY_TYPE__TRANSPORTATION,
    [EntityTypeCodeEnum.EXPENSE]: LocalizationEnum.ASPECT__ENTITY_TYPE__EXPENSE,
    [EntityTypeCodeEnum.WORKPLANNINGS]: LocalizationEnum.ASPECT__ENTITY_TYPE__WORK_PLANNINGS,
    [EntityTypeCodeEnum.ATTACHMENT]: LocalizationEnum.ASPECT__ENTITY_TYPE__ATTACHMENT,
    [EntityTypeCodeEnum.PROFESSION]: LocalizationEnum.ASPECT__ENTITY_TYPE__PROFESSION,
    [EntityTypeCodeEnum.CREWMEMBER]: LocalizationEnum.ASPECT__ENTITY_TYPE__CREW_MEMBER,
};

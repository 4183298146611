import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { KitInfoCreate, KitInfoRead, KitInfoUpdate, serverApi, ServerError } from '../../../../../server';
import { push, replace } from 'connected-react-router';
import { getServerError } from '../../../../../shared/util/utils';
import { showNotification } from '../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import lodash from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IntlShape } from 'react-intl';
import { IRootState } from '../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'kit/LOAD_ENTITY',
    LOAD_ENTITY2: 'kit/LOAD_ENTITY2',

    UPDATE_KIT: 'kit/UPDATE_ENTITY',
    UPDATE_KIT_COUNTS: 'kit/UPDATE_ENTITY_COUNTS',
    CHANGE_KIT_STATUS: 'kit/CHANGE_ENTITY_STATUS',
    CREATE_KIT: 'kit/CREATE_ENTITY',
    RESET: 'kit/RESET',
    RESET_ERROR: 'kit/RESET_ERROR',
    CREATE_KIT_INSTANCE: 'kit/CREATE_KIT_INSTANCE',
    DELETE_ENTITY: 'kit/DELETE_ENTITY',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entity: null as KitInfoRead | null,
};

export type KitState = Readonly<typeof initialState>;
// Reducer

export default (state: KitState = initialState, action): KitState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            const members = lodash.sortBy([...action.payload.data.members], 'memberOrder');
            return {
                ...state,
                entity: { ...action.payload.data, members },
                loading: false,
            };

        case REQUEST(ACTION_TYPES.LOAD_ENTITY2):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY2):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };

        case ACTION_TYPES.LOAD_ENTITY2:
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
            };

        case REQUEST(ACTION_TYPES.CREATE_KIT):
        case REQUEST(ACTION_TYPES.UPDATE_KIT):
        case REQUEST(ACTION_TYPES.UPDATE_KIT_COUNTS):
        case updateKitComposition.pending.type:
        case updateKitFeatures.pending.type:
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };

        case FAILURE(ACTION_TYPES.CREATE_KIT):
        case FAILURE(ACTION_TYPES.UPDATE_KIT):
        case FAILURE(ACTION_TYPES.UPDATE_KIT_COUNTS):
        case updateKitComposition.rejected.type:
        case updateKitFeatures.rejected.type:
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };

        case REQUEST(ACTION_TYPES.CHANGE_KIT_STATUS):
            return {
                ...state,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.CHANGE_KIT_STATUS):
            return {
                ...state,
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.CREATE_KIT):
        case SUCCESS(ACTION_TYPES.UPDATE_KIT):
        case updateKitComposition.fulfilled.type:
        case updateKitFeatures.fulfilled.type:
            return {
                ...state,
                updating: false,
                entity: action.payload.data,
            };

        case SUCCESS(ACTION_TYPES.CHANGE_KIT_STATUS):
            return {
                ...state,
                loading: false,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined,
            };

        case REQUEST(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
// Actions

// Получение состояния набора
export const loadEntity = (businessAccountId: number, id: number) => {
    return {
        type: ACTION_TYPES.LOAD_ENTITY,
        payload: serverApi.getKitById(businessAccountId, id),
    };
};

export const loadEntity2 = (data) => {
    return {
        type: ACTION_TYPES.LOAD_ENTITY2,
        payload: data,
    };
};

//Создание набора
export const createKit = (intl, businessAccountId: number, entity: KitInfoCreate) => {
    console.log(entity);

    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CREATE_KIT,
            payload: serverApi.createKit(businessAccountId, entity),
        }).then((result) => {
            if (result instanceof Error) {
                // Продукт не создан
            } else {
                // Продукт создан
                let { id } = result.action.payload.data;
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KIT_CREATED)); // TODO мб выводить норм сообщение
                dispatch(push(`/${businessAccountId}/inventory/kits/${id || ''}`));
                // createEntityAnalytics('create product', {'entity id': id});
            }
        });
    };
};

// Изменение набора
export const updateKit = (intl, businessAccountId: number, id: number, entity: KitInfoUpdate) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_KIT,
            payload: serverApi.updateKitById(businessAccountId, id, entity),
        }).then((result) => {
            if (result instanceof Error) {
                // Продукт не обновлен
            } else {
                // Продукт обновлен успешно

                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KIT_UPDATED)); // TODO мб выводить норм сообщение
                dispatch(push('/' + businessAccountId + '/inventory/kits/' + id + '?tab=description'));
                // dispatch(loadEntity(businessAccountId, id));
                // updateEntityAnalytics('update product', {'entity id': id});
            }
        });
    };
};

export const updateKitFeatures = createAsyncThunk(
    'kit/updateFeatures',
    async (
        args: {
            intl: IntlShape;
            entity: KitInfoRead;
            newData: KitInfoUpdate;
            successCallback?: Function;
        },
        { rejectWithValue, getState }
    ) => {
        const { intl, entity, newData, successCallback } = args;
        const state = getState() as IRootState;
        const businessAccountId = businessAccountIdSelector(state);

        try {
            const response = await serverApi.updateKitById(businessAccountId, entity.id, newData);

            showNotification(
                'success',
                localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CUSTOM_FIELDS_SETUP_UPDATED)
            );
            successCallback?.();
            return response;
        } catch (error) {
            showNotification(
                'error',
                localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CUSTOM_FIELDS_SETUP_UPDATE_FAILED)
            );
            throw rejectWithValue(error);
        }
    }
);

export const updateKitComposition = createAsyncThunk(
    'kit/updateComposition',
    async (
        args: {
            businessAccountId: number;
            id: number;
            kitInfoUpdate: KitInfoUpdate;
            successCallback?: Function;
        },
        { rejectWithValue }
    ) => {
        const { businessAccountId, id, kitInfoUpdate, successCallback } = args;

        try {
            const response = await serverApi.updateKitById(businessAccountId, id, kitInfoUpdate);
            showNotification('success', 'Список компонентов набора изменен');
            successCallback?.();
            return response;
        } catch (error: any) {
            if (error.response.status === 409) {
                showNotification(
                    'error',
                    'Защита от перезаписи изменений. Набор был изменен другим пользователем или в другой вкладке во время редактирования'
                );
            } else {
                showNotification('error', 'Не удалось изменить набор');
            }
            throw rejectWithValue(error);
        }
    }
);

// Изменение статуса
export const setStatusAndArchive = (
    intl,
    businessAccountId: number,
    id: number,
    businessVersion: number,
    status?: 'ACTIVATE' | 'BLOCK',
    archive?: boolean
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_KIT_STATUS,
            payload: serverApi.transitKitWorkflowBulk(businessAccountId, {
                directives: [{ id, businessVersion, transitionCode: status, archive }],
            }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            } else {
                if (archive === undefined) {
                    showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__STATE_CHANGED));
                } else {
                    showNotification(
                        'success',
                        archive
                            ? localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KIT_ARCHIVED)
                            : localizeIntl(intl, LocalizationEnum.PAGE__KITS__POPUP_NOTIFICATIONS__KIT_UNARCHIVED)
                    );
                }
                dispatch(loadEntity(businessAccountId, id));
                // updateEntityAnalytics('update product', {'entity id': id});
            }
        });
    };
};

export const deleteEntity = (businessAccountId: number, id: number, businessVersion: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITY,
            payload: serverApi.deleteKitById(businessAccountId, id, { businessVersion }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус не изменен
                showNotification('error', 'Набор не удален');
            } else {
                showNotification('success', 'Набор удален');
                dispatch(replace('/' + businessAccountId + '/inventory/kits/'));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR,
});

import { IDownloadDocumentParamsItem } from '../../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover';
import { LocalizationEnum, localize } from '../../../../localization';
import { ExportActPopoverOptions } from '../../../../components/exportPopovers/downloadDocument/instances/ExportActPopover/utils/data';
import { ExportEstimatePopoverOptions } from '../../../../components/exportPopovers/downloadDocument/instances/ExportEstimatePopover/utils/data';

export const operationExportActOptions: IDownloadDocumentParamsItem[] = [
    { key: ExportActPopoverOptions.EXPAND_KITS, label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS) },
    { key: ExportActPopoverOptions.FREE_ITEMS, label: 'Бесплатные позиции' },
    { key: ExportActPopoverOptions.JOIN_SIMILAR_ROWS, label: 'Одинаковые в одну строку' },
    { key: ExportActPopoverOptions.GROUP_BY_CATEGORIES, label: 'Группировку по категории' },
];

export const operationExportEstimateOptions: IDownloadDocumentParamsItem[] = [
    {
        key: ExportEstimatePopoverOptions.EXPAND_KITS,
        label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS),
    },
    { key: ExportEstimatePopoverOptions.FREE_ITEMS, label: 'Бесплатные позиции' },
    { key: ExportEstimatePopoverOptions.JOIN_SIMILAR_ROWS, label: 'Одинаковые в одну строку' },
];

import { BusinessAccountStateCodeEnum, BusinessAccountTypeCodeEnum, RentIndustryCodeEnum, TariffCodeEnum } from '../../../server/api';
import { FormItemType } from '../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../localization';
import React from 'react';
import { Icon } from 'antd';
import { IconClone, IconDollarSignSolid, IconHourglassHalfSolid, IconMicroscopeSolid } from '../../../components/icons';
import { getTariffByCode } from '../../../shared/util/utils4';

export const filters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'stateCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Активный', value: BusinessAccountStateCodeEnum.ACTIVE },
                    { name: 'Заблокирован', value: BusinessAccountStateCodeEnum.BLOCKED },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                                <span className="calendar-multi-select">{item.name}</span>{' '}
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: 'Тип',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Песочница', value: BusinessAccountTypeCodeEnum.SANDBOX },
                    { name: 'Демо шаблон', value: BusinessAccountTypeCodeEnum.DEMOTEMPLATE },
                    { name: 'Триал', value: BusinessAccountTypeCodeEnum.TRIAL },
                    { name: 'Коммерческий', value: BusinessAccountTypeCodeEnum.PRODUCTION },
                ].map((item) => {
                    let icon;
                    if (item.value === BusinessAccountTypeCodeEnum.DEMOTEMPLATE)
                        icon = <Icon component={IconClone} style={{ fontSize: 21, color: '#343f9a', marginRight: 12 }} />;
                    else if (item.value === BusinessAccountTypeCodeEnum.SANDBOX)
                        icon = <Icon component={IconMicroscopeSolid} style={{ fontSize: 21, color: '#a0a0a0', marginRight: 12 }} />;
                    else if (item.value === BusinessAccountTypeCodeEnum.TRIAL)
                        icon = <Icon component={IconHourglassHalfSolid} style={{ fontSize: 21, color: '#fc8c2f', marginRight: 12 }} />;
                    else if (item.value === BusinessAccountTypeCodeEnum.PRODUCTION)
                        icon = <Icon component={IconDollarSignSolid} style={{ fontSize: 21, color: '#34bfa3', marginRight: 12 }} />;
                    return {
                        name: (
                            <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                {icon}
                                {item.name}
                            </div>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: 'Тариф',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'tariff',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: getTariffByCode(TariffCodeEnum.TRIAL), value: TariffCodeEnum.TRIAL },
                    { name: getTariffByCode(TariffCodeEnum.START), value: TariffCodeEnum.START },
                    { name: getTariffByCode(TariffCodeEnum.ECONOMY), value: TariffCodeEnum.ECONOMY },
                    { name: getTariffByCode(TariffCodeEnum.BUSINESS), value: TariffCodeEnum.BUSINESS },
                    { name: getTariffByCode(TariffCodeEnum.ENTERPRISE), value: TariffCodeEnum.ENTERPRISE },
                ],
            },
            {
                label: 'Отрасль',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'rentIndustryCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__AV),
                        value: RentIndustryCodeEnum.AV,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__HR),
                        value: RentIndustryCodeEnum.HR,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CLOTHES),
                        value: RentIndustryCodeEnum.CLOTHES,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__EVENTS),
                        value: RentIndustryCodeEnum.EVENTS,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CONSTRUCTION),
                        value: RentIndustryCodeEnum.CONSTRUCTION,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__PROPS),
                        value: RentIndustryCodeEnum.PROPS,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__SPORT_INVENTORY),
                        value: RentIndustryCodeEnum.SPORT,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__TRANSPORT),
                        value: RentIndustryCodeEnum.TRANSPORT,
                    },
                    {
                        name: localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__OTHER),
                        value: RentIndustryCodeEnum.OTHER,
                    },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [],
    },
];

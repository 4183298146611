import React from 'react';
import { EntityType, GridRecord } from '../../../../index';
import { CurrentIndicatorsProblemIcons } from '../../currentRentIndicators/currentIndicatorsProblemIcons';

export const tableCellProblemRenderer = (
    value: number | undefined,
    rowData: GridRecord,
    onRowAction: ((entity: any, action: string, instanceCount?: number) => void) | undefined,
    entityType: EntityType
) => {
    const problemsAndWarnings = rowData && 'problemsAndWarnings' in rowData ? rowData.problemsAndWarnings : undefined;

    return (
        <CurrentIndicatorsProblemIcons
            problemsAndWarnings={problemsAndWarnings}
            entityType={entityType}
            typeRent={'all'}
            id={rowData?.id}
            entity={rowData}
        />
    );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../store/rtqQueryApi';
import { NomenclatureRecordCustom, ProductsAvailableFiltersArgs, ProductsListArgs, ProductsListData } from './products.api.types';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { IRootState } from '../../../../../shared/reducers';
import { AxiosError } from 'axios';
import { AvailableFiltersValuesWithCustomFieldsListObj, serverApi } from '../../../../../server';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProductsApiUtils } from './products.api.utils';
import { ACTION_TYPES } from '../reducers/products.reducer';
import { showNotification } from '../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ProductsList', 'ProductsAvailableFiltersList'],
    endpoints: (builder) => ({
        listProducts: builder.query<ProductsListData, ProductsListArgs>({
            queryFn: async (
                { intl, params, availableFiltersValues, customFieldMarkers, customFiltersRequestFilters },
                { getState, dispatch }
            ) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const subrentModuleEnabled = subrentModuleEnabledSelector(state);

                const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;

                const entitiesRequestFilters = ProductsApiUtils.createEntityRequestFilters(params, subrentModuleEnabled);

                try {
                    const { data: nomenclatureData } = await serverApi.listNomenclature(
                        businessAccountId,
                        params.limit,
                        (params.page - 1) * params.limit,
                        [...entitiesRequestFilters, ...customFiltersRequestFilters],
                        sortBy,
                        params.sortOrder,
                        params.search
                    );

                    const records = nomenclatureData.records.map(
                        (record): NomenclatureRecordCustom => ({
                            ...record,
                            id: Number(record.uuid!.split('_')[1]),
                        })
                    );

                    return {
                        data: {
                            entitiesData: {
                                listAttributes: nomenclatureData.listAttributes,
                                records: records,
                            },
                            availableIntervalFiltersData: availableFiltersValues
                                ? {
                                      values: availableFiltersValues,
                                      maps: {
                                          metrics: ProductsApiUtils.intervalMapFunction,
                                          formats: ProductsApiUtils.intervalFormatStyleMap,
                                      },
                                  }
                                : undefined,
                            customFieldMarkers,
                        },
                    };
                } catch (error: unknown) {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));

                    return {
                        error: error as AxiosError,
                    };
                } finally {
                    dispatch({
                        type: ACTION_TYPES.CHANGE_LOADING,
                        payload: false,
                    });
                }
            },
            providesTags: ['ProductsList'],
        }),
        listAvailableFilters: builder.query<AvailableFiltersValuesWithCustomFieldsListObj, ProductsAvailableFiltersArgs>({
            queryFn: async ({ search }, { getState }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const urlParams = new URLSearchParams(search);

                const availableFiltersRequestFilters = ProductsApiUtils.createAvailableFiltersRequestFilters(urlParams);

                try {
                    const { data } = await serverApi.listNomenclatureAvailableFiltersValues(
                        businessAccountId,
                        availableFiltersRequestFilters
                    );

                    return {
                        data,
                    };
                } catch (error) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['ProductsAvailableFiltersList'],
        }),
    }),
});

export const { useListProductsQuery, useListAvailableFiltersQuery } = productsApi;

import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { ArrayUtils } from '../../../../../../core/utils/arrayUtils';
import ReactDOM from 'react-dom';
import { ItemContext } from 'react-calendar-timeline';
import { BASE_ITEM_CLASS, KIT_ITEM_CLASS, SELECTED_ITEM_CLASS } from '../utils/data/calendarClasses';
import { CalendarId, CalendarItem, CalendarLine, ElementsCalendarItem } from '../types/items';
import { CalendarURLParams } from '../types/params';
import { ElementsCalendarFilters } from '../../../tabs/elements/filters/elementsCalendarFilters.types';
import { useLocation } from 'react-router-dom';
import { CalendarType } from '../types/props';

const suppressedCalendarTypes: CalendarType[] = ['activityFrames', 'workPlannings'];

export const useOnCalendarItemChanges = <Params extends object, SortValue extends string>({
    items,
    calendarType,
    selectedIndexRef,
    scrollRef,
    setIsPopoverVisible,
    setCollapsedKitsId,
    setCollapsedGroupsId,
    pageParams,
    onClick,
    isLoading,
}: {
    items: CalendarLine[];
    calendarType: CalendarType;
    selectedIndexRef: MutableRefObject<number | undefined>;
    scrollRef: MutableRefObject<HTMLDivElement | undefined>;
    setIsPopoverVisible: (visible: boolean) => void;
    setCollapsedKitsId: React.Dispatch<React.SetStateAction<CalendarId[]>>;
    setCollapsedGroupsId: React.Dispatch<React.SetStateAction<CalendarId[]>>;
    pageParams: CalendarURLParams<Params, SortValue>;
    isLoading: boolean;
    onClick?: (itemId: CalendarId) => void;
}) => {
    const location = useLocation();

    const removeClassesFromSelectedLine = useCallback(
        (horizontalLines: NodeListOf<ChildNode>) => {
            if (!selectedIndexRef.current || suppressedCalendarTypes.includes(calendarType)) return;

            const item = items[selectedIndexRef.current];
            if (item == null) return;

            const selectedLine = horizontalLines[selectedIndexRef.current] as Element;
            selectedLine.classList.remove(SELECTED_ITEM_CLASS);
            selectedLine.classList.add((item as CalendarItem<ElementsCalendarItem>).isKitChild ? KIT_ITEM_CLASS : BASE_ITEM_CLASS);
        },
        [calendarType, items, selectedIndexRef]
    );

    const getHorizontalLinesNodes = useCallback(() => {
        const node = ReactDOM.findDOMNode(scrollRef.current) as Element;
        if (node == null) return undefined;
        const horizontalLinesParentNode = node?.querySelector('.rct-horizontal-lines');
        if (horizontalLinesParentNode == null) return undefined;

        return horizontalLinesParentNode.childNodes;
    }, [scrollRef]);

    const onPopoverVisibleChange = useCallback(
        (visible: boolean, key?: string) => {
            if (!visible && selectedIndexRef.current && key !== 'calendar') {
                const horizontalLines = getHorizontalLinesNodes();

                if (horizontalLines) {
                    removeClassesFromSelectedLine(horizontalLines);
                }
            }
            setIsPopoverVisible(visible);
        },
        [getHorizontalLinesNodes, removeClassesFromSelectedLine, selectedIndexRef, setIsPopoverVisible]
    );

    const onItemClick = useCallback(
        (itemId: number | string, itemContext?: ItemContext) => {
            const itemIndex = items.findIndex((item) => item.id === itemId);
            if (itemIndex < 0) return;
            const item = items[itemIndex];
            if (item.elementType === 'group') {
                setCollapsedGroupsId((collapsedGroupsId) => {
                    return ArrayUtils.addOrDeleteItem([...collapsedGroupsId], itemId);
                });
                return;
            }

            onClick?.(itemId);
            if (suppressedCalendarTypes.includes(calendarType)) return;

            if ((item as CalendarItem<ElementsCalendarItem>).kitId) {
                setCollapsedKitsId((collapsedKitsId) => {
                    return ArrayUtils.addOrDeleteItem([...collapsedKitsId], itemId);
                });
            } else {
                const horizontalLines = getHorizontalLinesNodes();
                if (horizontalLines == null) return;

                if (selectedIndexRef.current) {
                    removeClassesFromSelectedLine(horizontalLines);
                }
                const selectedLine = horizontalLines[itemIndex] as (Element|undefined);
                if(selectedLine){
                    selectedLine.classList.remove(KIT_ITEM_CLASS);
                    selectedLine.classList.remove(BASE_ITEM_CLASS);
                    selectedLine.classList.add(SELECTED_ITEM_CLASS);
                    selectedIndexRef.current = itemIndex;

                    const offset =
                        selectedLine.getBoundingClientRect().top +
                        document.documentElement.scrollTop -
                        document.documentElement.clientHeight / 4;

                    window.scrollTo(0, offset);
                }
            }
        },
        [
            calendarType,
            getHorizontalLinesNodes,
            items,
            onClick,
            removeClassesFromSelectedLine,
            selectedIndexRef,
            setCollapsedGroupsId,
            setCollapsedKitsId,
        ]
    );

    const itemsLoaded = useRef(false);
    const groupsCollapsed = useRef(false);
    const itemHighlighted = useRef(false);

    const tapSelectedItemOnLoad = useCallback(() => {
        setTimeout(()=>{
            if (calendarType !== 'elements' || (itemsLoaded.current && groupsCollapsed.current && itemHighlighted.current)) return;

            if (isLoading) return;

            itemsLoaded.current = true;

            const { elementId } = pageParams as CalendarURLParams<ElementsCalendarFilters, SortValue>;
            if (!elementId) return;

            const undescriptedParams = Object.fromEntries(new URLSearchParams(location.search).entries());

            if (undescriptedParams.kit && !groupsCollapsed.current) {
                setCollapsedKitsId((collapsedKitsId) => {
                    return ArrayUtils.addOrDeleteItem([...collapsedKitsId], Number(undescriptedParams.kit));
                });
                groupsCollapsed.current = true;
                return;
            }

            onItemClick(elementId, undefined);
            groupsCollapsed.current = true;
            itemHighlighted.current = true;
        }, 100);
    }, [calendarType, isLoading, location.search, onItemClick, pageParams, setCollapsedKitsId]);

    useEffect(() => {
        itemHighlighted.current = false;
    }, [pageParams]);

    useEffect(tapSelectedItemOnLoad, [pageParams.group, tapSelectedItemOnLoad]);

    return useMemo(
        () => ({
            onItemClick,
            onPopoverVisibleChange,
        }),
        [onItemClick, onPopoverVisibleChange]
    );
};

import React from "react";
import {getBusinessAccountId} from "../../../../index";
import {Link} from "react-router-dom";
import {Icon} from "antd";
import {IconStarSolid} from "../../icons";
import '../../currentRentIndicators/currentRentIndicatorsBlock.less';

export const tableCellProductNameRenderer = (value:any, rowData) => {
    if(!rowData) return null;

    const partOfKit = rowData.subRows && rowData.rentElementsKitMemberCount && rowData.subRows.length < rowData.rentElementsKitMemberCount ? `(${rowData.subRows.length} из ${rowData.rentElementsKitMemberCount})` : undefined;

    return (
        <div className={'rr-grid-shortName-cell ' } title={rowData.productShortName} style={{textAlign: 'left', fontWeight: 600}}>
            <Link to={`/${getBusinessAccountId()}/inventory/${rowData.kitId ? 'kits' : 'products'}/${rowData.kitId ? rowData.kitId : rowData.productId}`}>
                {rowData.mainKitMember ? <Icon style={{marginRight: '4px'}} component={IconStarSolid} /> : null}
                {rowData.nomenclatureShortName}
                {partOfKit ? <span style={{fontWeight: 400}}> {partOfKit}</span> : null}
            </Link>
        </div>
    );
};

import { FormUtils, TypedFormField, WrappedFormUtilsTyped } from '../../../../../../../core/utils/formUtils';
import {
    BodyTypeCodeEnum,
    ContactLinkObjWrite,
    LoadingTypeCodeEnum,
    VehicleInfoCreate,
    VehicleTypeCodeEnum,
} from '../../../../../../../server';
import { FormFieldsGroup, FormItemType } from '../../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../../../../shared/util/validateField';
import { ContactsEditor } from '../../../../../../../components/contactsEditor/contactsEditor';
import { createHiddenField } from '../../../../../../../components/modalForm/utils';
import { vehicleTypeMap } from '../../utils/enumMappers';

interface FormValues extends VehicleInfoCreate {
    isEdit?: boolean;
    lockDimensions?: boolean;
}

export const formFields: FormFieldsGroup[] = FormUtils.typedFormFields<FormValues>([
    {
        fields: [
            createHiddenField('id') as TypedFormField<FormValues>,
            createHiddenField('isEdit') as TypedFormField<FormValues>,
            createHiddenField('lockDimensions') as TypedFormField<FormValues>,
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__NAME,
                id: 'shortName' as const,
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<FormValues>) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? form.getFieldValue('id') : undefined;
                    validateField('vehicle', fieldName, value, undefined, ignoreCurrentId, 'Наименование уже используется', cb);
                }, 500),
            },
            {
                label: 'Номер',
                id: 'officialNumber' as const,
                type: FormItemType.String,
                required: true,
                maxLength: 20,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<FormValues>) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? (form.getFieldValue('id') as number) : undefined;
                    validateField('vehicle', fieldName, value, undefined, ignoreCurrentId, 'Номер уже используется', cb);
                }, 500),
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^[A-ZА-Я0-9 ]+$',
                        message: 'Строка должна содержать только заглавные буквы, цифры и пробелы',
                    },
                ],
            },
            {
                label: 'Тип',
                id: 'type' as const,
                type: FormItemType.Select,
                required: true,
                defaultValue: VehicleTypeCodeEnum.STRAIGHTTRUCK,
                values: [
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.TRACTORTRUCK],
                        value: VehicleTypeCodeEnum.TRACTORTRUCK,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.STRAIGHTTRUCK],
                        value: VehicleTypeCodeEnum.STRAIGHTTRUCK,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.MINIBUS],
                        value: VehicleTypeCodeEnum.MINIBUS,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.TRAILER],
                        value: VehicleTypeCodeEnum.TRAILER,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.SEMITRAILER],
                        value: VehicleTypeCodeEnum.SEMITRAILER,
                    },
                ],
                onChange: async (value: any, form) => {
                    if (value === VehicleTypeCodeEnum.TRACTORTRUCK) {
                        form.setFieldsValue({
                            length: undefined,
                            width: undefined,
                            height: undefined,
                            bodyType: undefined,
                            loadingTypes: undefined,
                            lockDimensions: true,
                        });
                    } else {
                        form.setFieldsValue({
                            lockDimensions: undefined,
                        });
                    }
                },
            },
            {
                label: 'Марка и модель',
                id: 'brandAndModel',
                type: FormItemType.String,
                maxLength: 50,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__IMAGE,
                id: 'image',
                type: FormItemType.UploadImage,
                imagesMaxCount: 1,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
            },
        ],
    },
    {
        fields: [
            {
                label: 'Грузоподъемность, т.',
                id: 'loadCapacity' as const,
                type: FormItemType.Number,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value > 1000 ? 'Максимальное значение - 1000 тонн' : undefined);
                },
            },
            {
                label: 'Длина, м.',
                id: 'length',
                type: FormItemType.Number,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value > 100 ? 'Максимальное значение - 100 метров' : undefined);
                },
                disabled: (getFieldValue) => getFieldValue('lockDimensions'),
            },
            {
                label: 'Ширина, м.',
                id: 'width',
                type: FormItemType.Number,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value > 100 ? 'Максимальное значение - 100 метров' : undefined);
                },
                disabled: (getFieldValue) => getFieldValue('lockDimensions'),
            },
            {
                label: 'Высота, м.',
                id: 'height',
                type: FormItemType.Number,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value > 100 ? 'Максимальное значение - 100 метров' : undefined);
                },
                disabled: (getFieldValue) => getFieldValue('lockDimensions'),
            },
        ],
    },
    {
        fields: [
            {
                label: 'Тип кузова',
                id: 'bodyType',
                type: FormItemType.Select,
                values: [
                    {
                        name: 'Бортовой открытый',
                        value: BodyTypeCodeEnum.SIDEOPEN,
                    },
                    {
                        name: 'Бортовой тентованный',
                        value: BodyTypeCodeEnum.SIDEAWNING,
                    },
                    {
                        name: 'Будка',
                        value: BodyTypeCodeEnum.BOOTH,
                    },
                    {
                        name: 'Будка изотерм',
                        value: BodyTypeCodeEnum.ISOTHERMALBOOTH,
                    },
                ],
                disabled: (getFieldValue) => getFieldValue('lockDimensions'),
            },
            {
                label: 'Тип погрузки',
                id: 'loadingTypes',
                type: FormItemType.MultiSelect,
                values: [
                    {
                        name: 'Задняя',
                        value: LoadingTypeCodeEnum.REAR,
                    },
                    {
                        name: 'Задняя гидроборт',
                        value: LoadingTypeCodeEnum.REARHYDROBOARD,
                    },
                    {
                        name: 'Боковая',
                        value: LoadingTypeCodeEnum.SIDE,
                    },
                    {
                        name: 'Верхняя',
                        value: LoadingTypeCodeEnum.TOP,
                    },
                ],
                disabled: (getFieldValue) => getFieldValue('lockDimensions'),
            },
        ],
    },
    {
        fields: [
            {
                id: 'contacts',
                type: FormItemType.Component,
                component: ContactsEditor,
                validationRules: [
                    {
                        validator: (rule, value: ContactLinkObjWrite[] | undefined, cb) => {
                            if (value && value.some((item) => item.contactId === undefined && item.position)) cb('Контакт не выбран');
                            else cb();
                        },
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);

import React, { FC } from 'react';
import { PaymentInfoRead, PaymentStateCodeEnum } from '../../../../../../server';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../components/dataDisplay';
import classNames from 'classnames';
import { formatMoney } from '../../../../../../shared/util/formatMoney';
import { ExportBillPopover } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/ExportBillPopover';
import { onBillDownload } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/utils/onBillDownload';
import { RoundButton } from '../../../../../../components';
import { PriceIcon } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/components/PriceIcon/PriceIcon';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { paymentsUtils } from '../../../utils/paymentsUtils';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { DrawerUtils } from '../../../../../../shared/util/drawerUtils';
import { SystemIcon, SystemIconType } from '../../../../../../components/v2/systemIcon/systemIcon';
import { Link } from 'react-router-dom';

interface PaymentDrawerDataBlocksProps {
    payment: PaymentInfoRead | undefined;
    onClose: () => void;
}

export const PaymentDrawerDataBlocks: FC<PaymentDrawerDataBlocksProps> = (props) => {
    const { payment, onClose } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const balanceChangeAmount = payment?.balanceChangeAmount ?? 0;

    const isPaymentDeleted = payment?.stateCode === PaymentStateCodeEnum.DELETED;

    return payment ? (
        <>
            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Изменение баланса',
                            type: DataDisplayItemType.Custom,
                            style: {
                                color: balanceChangeAmount > 0 ? '#34bfa3' : balanceChangeAmount < 0 ? '#f4516c' : undefined,
                            },
                            value: (
                                <div
                                    className={'rr-value'}
                                    style={{
                                        color: balanceChangeAmount > 0 ? '#34bfa3' : balanceChangeAmount < 0 ? '#f4516c' : undefined,
                                        position: 'relative',
                                    }}
                                >
                                    {<span>{formatMoney(balanceChangeAmount)}</span>}
                                    {payment.incoming && payment.projectPayment && (
                                        <ExportBillPopover
                                            disabled={isPaymentDeleted}
                                            onDownload={async ({ fileType, templateId, isCommonTemplate , legalDetailId}) => {
                                                if (payment.id != null)
                                                    await onBillDownload(
                                                        businessAccountId,
                                                        payment.id,
                                                        fileType,
                                                        templateId ?? 0,
                                                        isCommonTemplate,
                                                        legalDetailId
                                                    );
                                            }}
                                        >
                                            <RoundButton
                                                colorScheme={'successSecondary'}
                                                style={{
                                                    position: 'absolute',
                                                    right: 16,
                                                    bottom: 0,
                                                }}
                                                className={classNames(
                                                    'rr-download-document-button',
                                                    'rr-project-page-description--export-contract-button'
                                                )}
                                                disabled={isPaymentDeleted}
                                            >
                                                <PriceIcon disabled={isPaymentDeleted} />
                                                <span>Скачать счёт</span>
                                            </RoundButton>
                                        </ExportBillPopover>
                                    )}
                                </div>
                            ),
                        },
                        {
                            label: 'Дата',
                            type: DataDisplayItemType.DateWithTime,
                            value: payment.date,
                        },
                    ],
                    [
                        {
                            label: 'Контрагент',
                            type: DataDisplayItemType.Counterparty,
                            value: {
                                baId: businessAccountId,
                                name: payment.counterpartyShortName,
                                id: payment.counterpartyId
                            }
                        },
                        {
                            label: payment.projectPayment ? 'Проект' : 'Поставка',
                            type: DataDisplayItemType.Custom,
                            value: (
                                <div className={'h5 rr-value'}>
                                    <SystemIcon
                                        type={payment.projectPayment ? SystemIconType.project : SystemIconType.subrent}
                                        style={{ verticalAlign: 'middle', marginRight: 6, marginTop: -2 }}
                                    />
                                    {payment.projectPayment ? (
                                        <Link to={`/${businessAccountId}/projects/production/${payment.projectId}`}>
                                            {payment.rentActivityFrameShortName}
                                        </Link>
                                    ) : (
                                        <Link to={`/${businessAccountId}/subrent/shippings/${payment.subrentId}`}>
                                            {payment.rentActivityFrameShortName}
                                        </Link>
                                    )}
                                </div>
                            ),
                        },
                    ],
                ]}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Источник',
                            type: DataDisplayItemType.Custom,
                            value: payment.sourceCode ? (
                                <div className={'rr-value'}>{paymentsUtils.getPaymentSourceByCode(payment.sourceCode)}</div>
                            ) : undefined,
                        },
                        {
                            label: 'Способ оплаты',
                            type: DataDisplayItemType.String,
                            value: payment.paymentMethodName,
                            emptyValueText: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M),
                        },
                    ],
                ]}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Дата',
                            type: DataDisplayItemType.DateWithTime,
                            value: payment.lastUpdateDate,
                        },
                        {
                            label: 'Автор посл. изменения',
                            type: DataDisplayItemType.Link,
                            value: payment.lastUpdateAuthorFullName
                                ? {
                                      text: DrawerUtils.getFullName({
                                          ...payment.lastUpdateAuthorFullName,
                                      }),
                                      link: `/${businessAccountId}/users/${payment.lastUpdateAuthorId}`,
                                      isRoute: true,
                                      onClick: () => {
                                          onClose();
                                      },
                                  }
                                : undefined,
                        },
                    ],
                ]}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Комментарий',
                            type: DataDisplayItemType.Comment,
                            value: payment.comment,
                        },
                    ],
                ]}
            />
        </>
    ) : null;
};

import moment from 'moment';
import { CalendarItemGroup } from '../../../../components/Calendar/types/items';
import { WorkPlanningGroupRecord } from '../../../../../../../server';

interface ProcessWorkPlanningsSplittedCalendarGroupArgs {
    items: CalendarItemGroup[];
    groupRecord: WorkPlanningGroupRecord;
    groupIndex: { current: number };
    screenLeft: number;
    screenRight: number;
    isCollapsed: boolean;
}

export const processWorkPlanningsSplittedCalendarGroup = (args: ProcessWorkPlanningsSplittedCalendarGroupArgs) => {
    const { items, groupRecord, isCollapsed, groupIndex, screenLeft, screenRight } = args;

    items.push({
        id: groupRecord.entityId!,
        record: groupRecord,
        group: groupIndex.current,
        start_time: moment(screenLeft),
        end_time: moment(screenRight),
        isCollapsed,
        elementType: 'group',
        sortGroup: '',
    } satisfies CalendarItemGroup);

    groupIndex.current += 1;
};

import { ModalProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { VehicleInfoRead } from '../../../../../../../server';
import React, { FC } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { createVehicleModalFilters } from './VehicleCreateModalData';
import { VehicleCreateModal, VehicleCreateModalProps } from './VehicleCreateModal';

export interface VehicleCreateModalNewProps extends ModalProps<VehicleInfoRead> {
    typeCode?: VehicleCreateModalProps['typeCode'];
}

export const VehicleCreateModalNew: FC<VehicleCreateModalNewProps> = (props) => {
    const businessAccountId = useAppSelector((store) => store.system.businessAccountId);
    return (
        <VehicleCreateModal
            businessAccountId={businessAccountId}
            title={'Создание нового транспорта'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            filters={createVehicleModalFilters}
            modalName={'create-vehicle'}
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
            typeCode={props.typeCode}
        />
    );
};

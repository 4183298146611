import React from 'react';
import {SelectAndCreate} from "../selectAndCreate";
import {ProductVariantSelect, ProductVariantSelectProps} from "./productVariantSelect";
import {ProductVariantCreateModalNew} from "../../../../modules/main/inventory/variants/productVariantCreateModalNew";

export interface ProductVariantSelectAndCreateProps extends ProductVariantSelectProps {
}

export class ProductVariantSelectAndCreate extends React.Component<ProductVariantSelectAndCreateProps> {
    render() {
        const {props} = this;
        return (
            <SelectAndCreate
                select={()=><ProductVariantSelect {...props} />}
                modal={({close}) => {
                    return (
                        <ProductVariantCreateModalNew
                            onSuccess={(data) => {
                                props.onChange?.(data.id);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}

import React, { FC, useCallback, useState } from 'react';
import { PaymentTransitionCodeEnum } from '../../../../../server';
import { Button, Icon, Popover } from 'antd';
import { IconCashRegisterSolid, IconEdit } from '../../../../../components/icons';
import { PaymentStateBadge } from '../stateBadge/PaymentStateBadge';
import { RoundButton } from '../../../../../components';
import { PaymentInfoReadWithId } from '../../api/payments.api.types';
import { paymentsUtils } from '../../utils/paymentsUtils';
import { AdditionalEntityActionsButton } from '../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { useTransitWorkflowBulkMutation } from '../../api/payments.api';
import { ActionTypeEnum } from '../../../../../core/types/ActionTypeEnum';
import { getIconByActionType, getLabelByActionType } from '../../../../../components/v2/gridActions/gridActions';

interface PaymentDrawerTitleProps {
    payment: PaymentInfoReadWithId | undefined;
    editPayment?: (payment: PaymentInfoReadWithId) => void;
    transitWorkflowBulk: ReturnType<typeof useTransitWorkflowBulkMutation>[0];
    isLoading: boolean;
}

export const PaymentDrawerTitle: FC<PaymentDrawerTitleProps> = (props) => {
    const { payment, editPayment, transitWorkflowBulk, isLoading } = props;
    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);

    const canMake = payment?.availableTransitionCodes?.includes(PaymentTransitionCodeEnum.MAKE);
    const canRecover = payment?.availableTransitionCodes?.includes(PaymentTransitionCodeEnum.RECOVER);
    const canDelete = payment?.availableTransitionCodes?.includes(PaymentTransitionCodeEnum.DELETE);

    const isActionsUnavailable = !canMake && !canRecover && !canDelete;

    const transitWorkflowBulkCallback = useCallback(
        async (payment: PaymentInfoReadWithId, action: ActionTypeEnum) => {
            if (payment.id == null || payment.businessVersion == null) return;

            transitWorkflowBulk({
                directives: [
                    {
                        id: payment.id,
                        businessVersion: payment.businessVersion,
                        transitionCode: paymentsUtils.getTransitionCodeByActionType(action),
                    },
                ],
            });
        },
        [transitWorkflowBulk]
    );

    return payment ? (
        <div className={'entity-drawer-header'}>
            <div className={'title-block'}>
                <Icon
                    className={'title-icon'}
                    component={IconCashRegisterSolid}
                    style={{
                        color: '#ffcd43',
                    }}
                />
                <span className={'title'}>
                    {payment.incoming ? 'Входящий' : 'Исходящий'} платеж{' '}
                    <span
                        style={{
                            fontWeight: 400,
                        }}
                    >
                        #{payment.numberInBusinessAccount}
                    </span>
                </span>
                <PaymentStateBadge type={payment.stateCode} />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                }}
            >
                {editPayment ? (
                    <RoundButton
                        colorScheme={'default'}
                        onClick={() => {
                            editPayment(payment);
                        }}
                        style={{
                            width: 40,
                            height: 40,
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        disabled={isLoading}
                    >
                        <Icon
                            component={IconEdit}
                            style={{
                                width: 22,
                                height: 22,
                                marginBottom: 4,
                                marginLeft: 1,
                            }}
                        />
                    </RoundButton>
                ) : undefined}
                <Popover
                    onVisibleChange={(visible) => {
                        setActionPopoverVisible(visible);
                    }}
                    visible={actionPopoverVisible}
                    overlayClassName={'rr-grid-actions-popover'}
                    autoAdjustOverflow
                    arrowPointAtCenter
                    placement="bottomLeft"
                    trigger="click"
                    content={
                        <>
                            <div className={'rr-grid-actions-popover-header1'} />
                            <div
                                className={'rr-grid-actions-popover-content'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActionPopoverVisible(false);
                                }}
                            >
                                <>
                                    {canMake ? (
                                        <Button
                                            block
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setActionPopoverVisible(false);
                                                transitWorkflowBulkCallback(payment, ActionTypeEnum.PAYMENT_MAKE);
                                            }}
                                        >
                                            {getIconByActionType(ActionTypeEnum.PAYMENT_MAKE)}
                                            <span>{getLabelByActionType(ActionTypeEnum.PAYMENT_MAKE)}</span>
                                        </Button>
                                    ) : undefined}
                                    {canRecover ? (
                                        <Button
                                            block
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setActionPopoverVisible(false);
                                                transitWorkflowBulkCallback(payment, ActionTypeEnum.PAYMENT_RECOVER);
                                            }}
                                        >
                                            {getIconByActionType(ActionTypeEnum.PAYMENT_RECOVER)}
                                            <span>{getLabelByActionType(ActionTypeEnum.PAYMENT_RECOVER)}</span>
                                        </Button>
                                    ) : undefined}
                                    {canDelete ? (
                                        <Button
                                            block
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setActionPopoverVisible(false);
                                                transitWorkflowBulkCallback(payment, ActionTypeEnum.PAYMENT_DELETE);
                                            }}
                                        >
                                            {getIconByActionType(ActionTypeEnum.PAYMENT_DELETE)}
                                            <span>{getLabelByActionType(ActionTypeEnum.PAYMENT_DELETE)}</span>
                                        </Button>
                                    ) : undefined}
                                </>
                            </div>
                        </>
                    }
                >
                    <AdditionalEntityActionsButton disabled={isActionsUnavailable || isLoading} />
                </Popover>
            </div>
        </div>
    ) : null;
};

import React, { FC, memo, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { useListRentActivityFramesQuery } from './api/activityFramesCalendar.api';
import { activityFramesCalendarFiltersDescription } from './filters/activityFramesCalendarFilters.description';
import { useFiltersChanges } from './hooks/useFiltersChanges';
import { useCalendarQueryParams } from '../../components/Calendar/hooks/useCalendarQueryParams';
import { CalendarVisibleItemsLimit } from '../../components/Calendar/utils/data/calendarConstants';
import {
    activityFramesCalendarSortData,
    ActivityFramesSortValue,
    useActivityFramesCalendarGroups,
    useActivityFramesCalendarSortValueToItemKey,
} from './utils/calendarData';
import { processActivityFramesCalendarItem } from './utils/processCalendarItems';
import { activityFramesCalendarScreenLeft, activityFramesCalendarScreenRight } from './reducers/activityFramesCalendar.selectors';
import { EntityCalendar } from '../../components/EntityCalendar/EntityCalendar';
import { logisticsModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { CalendarSortValue } from '../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { LocationDrawer } from '../../../logistics/tabs/locations/drawer/LocationDrawer';
import { useLocationDrawerActions } from './hooks/useLocationDrawerActions';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import { ActivityFramesItemRenderer } from './components/ItemRenderer/ActivityFramesItemRenderer';
import { useCurrentFiltersData } from './hooks/useCurrentFiltersData';

import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { CalendarHeaderButtonsBase } from '../../components/Calendar/components/ActionsButtonsBase/CalendarHeaderButtonsBase';

export const activityFramesCalendarPageName = CalendarPageTabsEnum.ACTIVITY_FRAMES;

interface ActivityFramesCalendarProps {}

export const ActivityFramesCalendar: FC<ActivityFramesCalendarProps> = memo(() => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const isLogisticsModuleEnabled = useAppSelector(logisticsModuleEnabledSelector);
    const screenLeft = useAppSelector(activityFramesCalendarScreenLeft);
    const screenRight = useAppSelector(activityFramesCalendarScreenRight);
    const [visibleItemsLimit, setVisibleItemsLimit] = useState<CalendarVisibleItemsLimit>(CalendarVisibleItemsLimit.FIRST_LIMIT);
    const [chosenLocationId, setChosenLocationId] = useState<number | undefined>(undefined);

    const pageParamsObject = usePageURLParams({
        paramsDescription: activityFramesCalendarFiltersDescription,
        pageName: activityFramesCalendarPageName,
        initialValues: {
            group: 'start'
        }
    });
    const { pageParams } = pageParamsObject;

    const queryParams = useCalendarQueryParams({
        ...pageParams,
    });

    const { data: activityFramesList, isFetching } = useListRentActivityFramesQuery(
        {
            businessAccountId,
            params: queryParams,
            visibleItemsLimit,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const filtersData = useCurrentFiltersData();

    const sortData: CalendarSortValue<ActivityFramesSortValue>[] = useMemo(() => {
        const sortData = [...activityFramesCalendarSortData];

        if (isLogisticsModuleEnabled) {
            sortData.splice(1, 0, { value: 'location', title: 'Локации' });
        }

        return sortData;
    }, [isLogisticsModuleEnabled]);

    const activityFramesCalendarGroups = useActivityFramesCalendarGroups((id) => {
        setChosenLocationId(id);
    });

    const activityFramesCalendarSortValueToItemKey = useActivityFramesCalendarSortValueToItemKey();

    const locationActions = useLocationDrawerActions();

    return (
        <>
            <EntityCalendar
                calendarType={'activityFrames'}
                filtersData={filtersData}
                groupsData={activityFramesCalendarGroups}
                isLoading={isFetching}
                pageName={activityFramesCalendarPageName}
                pageParamsDescription={activityFramesCalendarFiltersDescription}
                pageParamsObject={pageParamsObject}
                processItemFunction={processActivityFramesCalendarItem}
                recordList={activityFramesList}
                screenLeft={screenLeft}
                screenRight={screenRight}
                setVisibleItemsLimit={setVisibleItemsLimit}
                sortData={sortData}
                sortGroupMap={activityFramesCalendarSortValueToItemKey}
                useFiltersChanges={useFiltersChanges}
                visibleItemsLimit={visibleItemsLimit}
                itemRenderer={ActivityFramesItemRenderer}
                headerButtons={CalendarHeaderButtonsBase}
            />
            {chosenLocationId && (
                <LocationDrawer
                    locationId={chosenLocationId}
                    onClose={() => setChosenLocationId(undefined)}
                    editLocation={locationActions.edit}
                    deleteLocation={locationActions.delete}
                    archiveLocation={locationActions.archive}
                    isEntityActionsLoading={locationActions.isLoading}
                />
            )}
        </>
    );
});

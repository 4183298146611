import {PricingLadderStepObj, PricingSchemeExternalRepresentationObj, PricingSchemeRecord, PricingSchemeTypeCodeEnum} from '../../server';
import { MoneyUtils } from './moneyUtils';
import { IconLadder, IconLayerGroupSolid } from '../../components/icons';
import { PricingSchemesState } from '../../modules/main/settings/pricingSchemes/reducers/pricingSchemes.reducer';
import { ServerUtils } from './serverUtils';

type PricingSchemeStepsData = {
    shiftCount: number;
    steps: PricingLadderStepObj[];
};

export abstract class PricingSchemeUtils {
    static getPricingSchemeCodes = (): PricingSchemeTypeCodeEnum[] => {
        return [PricingSchemeTypeCodeEnum.LADDER, PricingSchemeTypeCodeEnum.MULTILEVEL];
    };

    static getPricingSchemeData(typeCode?: PricingSchemeTypeCodeEnum): {
        typeTitle: string;
        typeIcon: (() => JSX.Element) | undefined;
    } {
        if (typeCode == null) {
            return {
                typeTitle: '',
                typeIcon: undefined,
            };
        }

        return {
            typeTitle: PricingSchemeMaps.pricingSchemeTypeCodeToTitleMap[typeCode],
            typeIcon: PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[typeCode],
        };
    }

    static getStepsFromPricingSchemeLadderSteps({ shiftCount, steps }: PricingSchemeStepsData): PricingLadderStepObj[] | undefined {
        const processedSteps = [...steps].sort((a, b) => (a.effectsAfterShiftCount || 0) - (b.effectsAfterShiftCount || 0));
        const filteredSteps = processedSteps.filter((step) => shiftCount >= (step.effectsAfterShiftCount || 0));

        return filteredSteps.length ? [filteredSteps[filteredSteps.length - 1]] : undefined;
    }

    static getStepsFromPricingSchemeMultiLevelSteps({ shiftCount, steps }: PricingSchemeStepsData): PricingLadderStepObj[] | undefined {
        const processedSteps = [...steps].sort((a, b) => (a.effectsAfterShiftCount || 0) - (b.effectsAfterShiftCount || 0));
        const filteredSteps = processedSteps.filter((step) => shiftCount > (step.effectsAfterShiftCount || 0));

        return filteredSteps.length ? filteredSteps : undefined;
    }

    static calculatePriceByPricingScheme({shiftCount, basePrice, pricingScheme}: {
        shiftCount: number;
        basePrice: number;
        pricingScheme: PricingSchemeExternalRepresentationObj;
    }) {
        let discount = 0;
        if(pricingScheme.typeCode === PricingSchemeTypeCodeEnum.LADDER){
            let steps = this.getStepsFromPricingSchemeLadderSteps({ shiftCount, steps: pricingScheme.steps || [] });
            if (steps && steps[0].extraDiscount) discount = steps[0].extraDiscount;
        }else if(pricingScheme.typeCode === PricingSchemeTypeCodeEnum.MULTILEVEL){
            let steps = this.getStepsFromPricingSchemeMultiLevelSteps({ shiftCount, steps: pricingScheme.steps || [] });
            let p = 0;
            if(!steps) steps = [];
            if(steps.length === 0 || (steps[0] && steps[0].effectsAfterShiftCount > 0)){
                steps.unshift({effectsAfterShiftCount: 0, extraDiscount: 0});
            }
            for(let i = 0; i < steps?.length; i++){
                const shifts = (steps[i+1] ? steps[i+1].effectsAfterShiftCount : shiftCount) - steps[i].effectsAfterShiftCount;
                p += ((100 - steps[i].extraDiscount) * shifts);
            }
            discount = shiftCount !== 0 ? (100 - p/shiftCount) : 0;
        }else{
            throw new Error(`Unknown PricingSchemeTypeCodeEnum value: ${pricingScheme.typeCode}`)
        }
        basePrice = MoneyUtils.calculateFinalInstancePrice(basePrice, discount);
        return basePrice;
    }

    static getBasePriceStyles({
        shiftCount,
        currentSteps,
        typeCode,
    }: {
        typeCode?: PricingSchemeTypeCodeEnum;
        shiftCount?: number;
        currentSteps?: PricingLadderStepObj[];
    }) {
        if (typeCode == null) return undefined;

        if (typeCode === PricingSchemeTypeCodeEnum.LADDER) {
            return shiftCount != null && currentSteps == null ? { color: '#525893', fontWeight: 700 } : undefined;
        }
        if (typeCode === PricingSchemeTypeCodeEnum.MULTILEVEL) {
            return (shiftCount == null && currentSteps == null) ||
                (currentSteps != null && currentSteps.some(({ effectsAfterShiftCount }) => effectsAfterShiftCount === 0))
                ? undefined
                : { color: '#525893', fontWeight: 700 };
        }
    }
}

export abstract class PricingSchemeMaps {
    static pricingSchemeTypeCodeToTitleMap: Record<PricingSchemeTypeCodeEnum, string> = {
        [PricingSchemeTypeCodeEnum.LADDER]: 'Ступенчатая',
        [PricingSchemeTypeCodeEnum.MULTILEVEL]: 'Многоуровневая',
    };
    static pricingSchemeTypeCodeToIconMap: Record<PricingSchemeTypeCodeEnum, () => JSX.Element> = {
        [PricingSchemeTypeCodeEnum.LADDER]: IconLadder,
        [PricingSchemeTypeCodeEnum.MULTILEVEL]: IconLayerGroupSolid,
    };
    static pricingSchemeTypeCodeToGetStepsFunctionMap = {
        [PricingSchemeTypeCodeEnum.LADDER]: PricingSchemeUtils.getStepsFromPricingSchemeLadderSteps,
        [PricingSchemeTypeCodeEnum.MULTILEVEL]: PricingSchemeUtils.getStepsFromPricingSchemeMultiLevelSteps,
    } satisfies Record<PricingSchemeTypeCodeEnum, Function>;
}

export abstract class PricingSchemeParamsUtils {
    static createRequestFilters = (params: PricingSchemesState['params']) => {
        return ServerUtils.createRequestFilters<PricingSchemeRecord>([params.typeCode ? ['typeCode', 'EQ', params.typeCode] : undefined]);
    };
}

import React, { useState } from 'react';
import { Popover } from 'antd';
import CalendarLegendContent from './CalendarLegendContent';
import classNames from 'classnames';
import './CalendarLegend.less';

const CalendarLegend = (props) => {
    const [visible, setVisible] = useState<boolean>(false);

    const onVisibleChange = (visible: boolean) => {
        setVisible(visible);
        if (props.onClosePopover) props.onClosePopover(visible);
    };

    return (
        <Popover
            overlayClassName={classNames('rr-grid-actions-popover', 'calendar-legend-container')}
            overlayStyle={{ width: 650 }}
            trigger={'click'}
            onVisibleChange={onVisibleChange}
            visible={visible}
            autoAdjustOverflow
            arrowPointAtCenter={true}
            placement={'bottomLeft'}
            content={<CalendarLegendContent />}
        >
            {props.children}
        </Popover>
    );
};

export default CalendarLegend;

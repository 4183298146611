import React from 'react';
import {Tooltip} from "antd";
import {TooltipPlacement} from "antd/lib/tooltip";
import {HELP_TOOLTIPS_SHOW_DELAY_SEC} from "../../config/constants";
import {LocalizationEnum, localize} from "../../localization";

interface IProps {
    title?: LocalizationEnum;
    content:LocalizationEnum | React.ReactNode;
    placement?: TooltipPlacement;
}

interface IState {}

export const HelpTooltipContext = React.createContext({
    showTooltips: true
} as HelpTooltipContextParams);

interface HelpTooltipContextParams {
    showTooltips: boolean;
    keys?: string[];
}

export class HelpTooltip extends React.PureComponent<IProps, IState> {

    render() {
        //console.log('HelpTooltip render()');



        let {title, content, placement, children} = this.props,
            context:HelpTooltipContextParams = this.context,
            tooltipContent =
            <>
                {title ? <b>{localize(title, undefined, undefined, true)}</b> : null}
                {title ? <br/> : null}
                { typeof this.props.content === 'string' ? localize(content as LocalizationEnum,  'div', undefined, true) : this.props.content }
            </>;

        return (
            //context.showTooltips ?
            //    <Tooltip autoAdjustOverflow arrowPointAtCenter mouseEnterDelay={HELP_TOOLTIPS_SHOW_DELAY_SEC} placement={placement} title={tooltipContent}>
            //        {children}
            //    </Tooltip> :
                children
        );
    }
}

HelpTooltip.contextType = HelpTooltipContext;

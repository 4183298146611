import React from 'react';
import {Layout} from "antd";
import "./footer.less";
import {LocalizationEnum, localize} from "../../../localization";
import {APP_VERSION} from "../../../config/version";

// TODO Ссылки заменить на нормальные, когда будут
const Footer = (props) => (
    <Layout.Footer className={'rr-app-footer'}>
        <div className={'rr-app-footer-copyrights'}>
            <span>2018 - {new Date().getFullYear()} © </span>
            <a href={'http://rentrabb.it/'} target={'_blank'} rel="noopener noreferrer">RentRabbit</a> <span style={{fontSize: 12}}>(v{APP_VERSION})</span>
        </div>
        <div className={'rr-app-footer-links'}>
            <a href={'http://rentrabb.it/'} target={'_blank'} rel="noopener noreferrer">{localize(LocalizationEnum.ASPECT__FOOTER__ABOUT)}</a>
            <a href={'https://rentrabb.it/conditions/privacy-policy/'} target={'_blank'} rel="noopener noreferrer">{localize(LocalizationEnum.ASPECT__FOOTER__PRIVACY_POLICY)}</a>
        </div>
    </Layout.Footer>
);

export default Footer;

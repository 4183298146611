import React, { FC, memo } from 'react';
import { getOperationStatusTypeData } from '../../../../../../operationForm/utils/utils';
import { OperationTypeCodeEnum, RentStateCodeEnum } from '../../../../../../../../server';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';
import { DashboardWidgetBase } from '../../../../../components/DashboardWidget/instances/base/DashboardWidgetBase';
import { IconCube, IconHandshake } from '../../../../../../../../components/icons';
import { DashboardData } from '../../../../../types/DashboardData';

interface DashboardSubrentBookedWidgetProps {
    dashboardData: DashboardData;
}

export const DashboardSubrentBookedWidget: FC<DashboardSubrentBookedWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const {
        currentSubrentShipmentBookedSum,
        currentSubrentShipmentBookedElementsCount,
        currentSubrentShipmentBookedInstancesCount,
        loading,
    } = dashboardData;

    return (
        <DashboardWidgetBase
            loading={loading}
            fill={false}
            color="rgb(139, 92, 177)"
            title={'Забронировано к поставке'}
            sum={currentSubrentShipmentBookedSum}
            elementsCount={currentSubrentShipmentBookedElementsCount}
            elementsIcons={[IconHandshake, IconCube]}
            instancesCount={currentSubrentShipmentBookedInstancesCount}
            icon={getOperationStatusTypeData(OperationTypeCodeEnum.BOOK).iconComponent}
            url={linkToCalendar({
                typeCode: [RentStateCodeEnum.SUBRENTSHIPMENTBOOKED],
                group: 'project',
                hideInactive: true,
                capture: 'all',
            })}
        />
    );
});

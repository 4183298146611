import { ImageObj } from '../../server';
import { UploadFile } from 'antd/lib/upload/interface';
import { getImagePath } from '../../shared/util/utils';

export abstract class ImageUtils {
    static clearExternalIdForImageObj = (image: ImageObj) => {
        return {
            ...image,
            externalId: image?.externalId?.replace(/-/g, ''),
        };
    };
    static getImageFromImageObj(imageObj: ImageObj): Partial<UploadFile<ImageObj>> {
        return {
            uid: String(imageObj.id),
            url: getImagePath({
                ...imageObj,
                externalId: imageObj.externalId?.replace(/-/g, ''),
            }),
            response: imageObj,
        };
    }
}

import {CategorySortOrderEnum, CategoryTypeCodeEnum} from '../../../server';
import {Category} from './categories-block';

export type CategoriesSortOrderData = {
    [Key in CategoryTypeCodeEnum]?: CategorySortOrderEnum;
};

export type CategoriesExpandedKeysData = {
    [Key in CategoryTypeCodeEnum]?: string[];
};

export const getCategoryById = (categoryId: number, categories: Category[]): Category | null => {
    for (let category of categories) {
        if (category.value === categoryId) return category;

        const categoryInside = getCategoryById(categoryId, category.children);

        if (categoryInside) return categoryInside;
    }

    return null;
};

export const getCategoryDepth = (category: Category): number => {
    if (!category.children || category.children.length === 0) return 1;
    else {
        const childDepths = category.children.map(getCategoryDepth);
        return Math.max(...childDepths) + 1;
    }
};

export const getParentCategory = (targetCategory: Category, categories: Category[]): Category | null => {
    for (let category of categories) {
        if (Boolean(category.children.find((category) => category.value === targetCategory.value))) {
            return category;
        }

        const parentCategory = getParentCategory(targetCategory, category.children);

        if (parentCategory) return parentCategory;
    }

    return null;
};

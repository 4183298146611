import React, { CSSProperties, FC } from 'react';
import { Icon, Popover, Radio } from 'antd';
import { IconSliders } from '../../../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import '../ControlPopoverStyles.less';
import { CalendarDisplayType } from '../../../utils/data/calendarConstants';

const radioStyle: CSSProperties = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

interface CalendarStyleButtonProps {
    displayType: CalendarDisplayType;
    onChange: (value: CalendarDisplayType) => void;
    placement?: 'left' | 'right';
}

export const CalendarDisplayTypeButton: FC<CalendarStyleButtonProps> = (props) => {
    const { displayType, onChange, placement = 'left' } = props;

    return (
        <Popover
            trigger={'click'}
            placement={placement === 'left' ? 'bottomRight' : 'bottomLeft'}
            arrowPointAtCenter
            content={
                <div className={'control-popover-container'}>
                    <div className="control-popover-header">{localize(LocalizationEnum.PAGE__CALENDAR__POPUP_LABEL_RENDER_TYPE)}</div>

                    <Radio.Group onChange={(e) => onChange(e.target.value as CalendarDisplayType)} value={displayType}>
                        <Radio style={radioStyle} value={CalendarDisplayType.NORMAL}>
                            {localize(LocalizationEnum.PAGE__CALENDAR__RENDER_TYPE_OPTION_NORMAL)}
                        </Radio>
                        <Radio style={radioStyle} value={CalendarDisplayType.COMPACT}>
                            {localize(LocalizationEnum.PAGE__CALENDAR__RENDER_TYPE_OPTION_COMPACT)}
                        </Radio>
                    </Radio.Group>
                </div>
            }
        >
            <Icon className={'rr-grid-settings-icon'} component={IconSliders} style={{ marginRight: 11 }} />
        </Popover>
    );
};

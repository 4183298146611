import { ColumnTypes, TableColumn } from '../../../../components/grid/Table';
import React, { CSSProperties } from 'react';
import { PaymentRecord, PaymentSourceCodeEnum, PaymentStateCodeEnum, RentActivityFrameTypeCodeEnum } from '../../../../server/api';
import { LocalizationEnum, localize } from '../../../../localization';
import { getActionsColumnWidth } from '../../../../config/constants';
import DashboardDate from '../../../../components/dashboardDate/dashboardDate';
import { tableCellMoneyRenderer } from '../../../../components/grid/renderers/tableCellMoneyRenderer';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { GridRowActions } from '../../../../components/v2/gridActions/gridActions';
import { paymentsUtils } from '../utils/paymentsUtils';
import { GridColumnCreator } from '../../../../components/grid/gridColumnCreator';
import { IconsGroup } from '../../../../components/v2/gridActionsContainer/IconsGroup';
import { ExportBillPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/ExportBillPopover';
import { PriceIcon } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/components/PriceIcon/PriceIcon';
import { onBillDownload } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/utils/onBillDownload';

const paymentsColumns: TableColumn<PaymentRecord>[] = [
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NUMBER),
        dataIndex: 'numberInBusinessAccount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        render: (value: any, rowData: PaymentRecord) => {
            return <strong className={'main-value'}>#{rowData.numberInBusinessAccount}</strong>;
        },
    },
    GridColumnCreator.createCounterpartyColumn<PaymentRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.counterpartyId,
            name: row.counterpartyShortName,
        }),
        {
            title: 'Контрагент',
            dataIndex: 'counterpartyShortName',
        }
    ),
    GridColumnCreator.createActivityFrameColumn<PaymentRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.rentActivityFrameId,
            name: row.rentActivityFrameShortName,
            activityFrameTypeCode: row.projectPayment ? RentActivityFrameTypeCodeEnum.PROJECT : RentActivityFrameTypeCodeEnum.SUBRENT,
        }),
        {
            title: 'Проект или поставка',
            dataIndex: 'rentActivityFrameShortName',
        },
        true
    ),
    {
        title: 'Дата платежа',
        dataIndex: 'date',
        type: ColumnTypes.DateTime,
        minWidth: 150,
        resizable: false,
    },
    {
        title: 'Изменение баланса',
        dataIndex: 'balanceChangeAmount',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (value: any, rowData: PaymentRecord) => {
            let color: string | undefined;
            if (rowData.stateCode != PaymentStateCodeEnum.DELETED) {
                if (value > 0) color = '#34bfa3';
                else if (value < 0) color = '#f4516c';
            }
            return <span style={{ color, fontWeight: 600 }}>{tableCellMoneyRenderer(rowData.balanceChangeAmount)}</span>;
        },
    },
    {
        title: 'Статус',
        dataIndex: 'stateCode',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: any, rowData: PaymentRecord) => {
            return paymentsUtils.getPaymentStatusByCode(rowData.stateCode);
        },
    },
    {
        title: 'Источник',
        dataIndex: 'sourceCode',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: any, rowData: PaymentRecord) => {
            let style: CSSProperties | undefined;
            if (rowData.sourceCode === PaymentSourceCodeEnum.MANAGERINTERFACE) style = { color: '#CCCCCC' };
            return <span style={style}>{paymentsUtils.getPaymentSourceByCode(rowData.sourceCode)}</span>;
        },
    },
    {
        title: 'Способ оплаты',
        dataIndex: 'paymentMethodName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: any, rowData: PaymentRecord) => {
            return rowData.paymentMethodName;
        },
    },
    GridColumnCreator.createUserColumn<PaymentRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор посл. изменения',
            dataIndex: 'lastUpdateAuthorShortName',
        }
    ),
    {
        title: 'Дата посл. изменения',
        dataIndex: 'lastUpdateDate',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: Date, rowData: PaymentRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.CustomRender,
        width: getActionsColumnWidth(2),
        maxWidth: getActionsColumnWidth(2),
        minWidth: getActionsColumnWidth(2),
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: PaymentRecord, onAction?: (rowData: PaymentRecord, action: string) => void) => {
            const isPaymentDeleted = rowData.stateCode === PaymentStateCodeEnum.DELETED;

            const icons = [
                rowData.incoming && rowData.projectPayment ? (
                    <ExportBillPopover
                        disabled={isPaymentDeleted}
                        onDownload={async ({ fileType, templateId, isCommonTemplate, legalDetailId }) =>
                            await onBillDownload(
                                rowData.businessAccountId,
                                rowData.id,
                                fileType,
                                templateId || 0,
                                isCommonTemplate,
                                legalDetailId
                            )
                        }
                    >
                        <span
                            style={{
                                height: 24,
                            }}
                        >
                            <PriceIcon disabled={isPaymentDeleted} />
                        </span>
                    </ExportBillPopover>
                ) : (
                    <div style={{ width: 24 }} />
                ),
                <GridRowActions
                    additionalActions={paymentsUtils.getAvailableActionsForRecord(rowData)}
                    onAction={(type) => {
                        if (onAction) onAction(rowData, type);
                    }}
                />,
            ].filter(Boolean);

            return <IconsGroup items={icons} />;
        },
    },
];

export default paymentsColumns;

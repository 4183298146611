import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { GroupedRentElementRecord, GroupedRentElementRecordList, serverApi, ServerError } from '../../../../server';
import { getServerError } from '../../../../shared/util/utils';
import { getDashboardDataPeriod } from '../../../../components/dashboardDate/dashboardDate';
import { showNotification } from '../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../localization';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'rentElements1/LOAD_ENTITIES',
    SET_PAGE_PARAMS: 'rentElements1/SET_PAGE_PARAMS',
    RESET: 'rentElements1/RESET',
    SET_SEARCH: 'rentElements1/SET_SEARCH',
};

const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    typeCode: undefined as string | undefined,
    page: 1,
    onPage: 10,
    archive: false,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    renterId: undefined as string | undefined,
    projectId: undefined as number | undefined,

    period: undefined as string | undefined,

    fromStartDate: undefined as number | undefined,
    toStartDate: undefined as number | undefined,

    fromEndDate: undefined as number | undefined,
    toEndDate: undefined as number | undefined,

    creationAuthorId: undefined as string | undefined,
    finalTotalPriceMin: undefined as number | undefined,
    finalTotalPriceMax: undefined as number | undefined,

    discountMin: undefined as string | undefined,
    discountMax: undefined as string | undefined,

    shiftCountMin: undefined as string | undefined,
    shiftCountMax: undefined as string | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<GroupedRentElementRecord> | null,
    totalCount: 0,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    },
};

export type RentElementsState = Readonly<typeof initialState>;

// Reducer

export default (state: RentElementsState = initialState, action): RentElementsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            // const entities:RentElementsGridItem[] = (action.payload.data as RentElementRecordList).records.map((item) => {
            //     return convertRentElementRecordToRentElementsGridItem(item);
            // });

            let data = action.payload.data as GroupedRentElementRecordList;

            return {
                ...state,
                loading: false,
                entities: data.records,
                totalCount: data.listAttributes.filteredCount,
                filteredCount: data.listAttributes.filteredCount,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.SET_SEARCH:
            let newParams = { ...initialParamsState, search: action.payload };

            return {
                ...state,
                params: newParams,
            };

        case SUCCESS(ACTION_TYPES.SET_PAGE_PARAMS): {
            let newParams = { ...initialParamsState };
            Object.keys(action.payload).forEach(function (key) {
                let value = action.payload[key];
                if (value !== undefined) newParams[key] = value;
            });
            return {
                ...state,
                params: newParams,
            };
        }

        default:
            return state;
    }
};

// Actions

// Загрузить список обязательств
export const loadEntities = (
    intl,
    businessAccountId: number,
    paramsState: any,
    currentDelayedBookedElementsCount,
    currentShortagedBookedElementsCount,
    typeRent: 'rent' | 'subrent'
) => {
    return (dispatch, getState) => {
        let { pageNumber, pageSize, sortBy, sortOrder, isBtnTime, isBtnError, isBtnSubrent, period } = paramsState;

        let filters: string[] = [];

        let sortOrd: any = undefined;

        if (sortOrder) {
            sortOrd = 'DESC';
        } else {
            sortOrd = 'ASC';
        }
        let startFrom = 0;
        if (pageNumber > 1) {
            startFrom = (pageNumber - 1) * pageSize;
        }

        if (typeRent === 'rent') {
            filters.push('stateCode;EQ;BOOKED');
        } else {
            filters.push('stateCode;EQ;SUBRENT_SHIPMENT_BOOKED');
        }

        if (isBtnTime && !!currentDelayedBookedElementsCount && typeRent === 'rent') {
            filters.push('problemsAndWarnings.anyDelay;EQ;true');
        }
        if (isBtnError && !!currentShortagedBookedElementsCount && typeRent === 'rent') {
            filters.push('problemsAndWarnings.anyShortage;EQ;true');
        }
        if (isBtnSubrent && typeRent === 'subrent') {
            filters.push('problemsAndWarnings.anyDelay;EQ;true');
        }

        const p = getDashboardDataPeriod(period);

        if (p.dataEnd || p.dataStart) {
            if (period === '00') {
                filters.push(`periodDate;LT;${p.dataEnd}`);
            } else {
                filters.push(`periodDate;GE;${p.dataEnd}`);
                filters.push(`periodDate;LT;${p.dataStart}`);
            }
        }

        let _sortBy: string | undefined;
        if (sortBy === 'date') {
            _sortBy = 'DATE';
        } else if (sortBy === 'rentElementCount') {
            _sortBy = 'RENT_ELEMENTS_COUNT';
        } else if (sortBy === 'instanceCount') {
            _sortBy = 'INSTANCES_COUNT';
        } else if (sortBy === 'finalTotalSum') {
            _sortBy = 'SUM';
        }

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listBusinessAccountElementsGroupedByPeriod(
                businessAccountId,
                typeRent === 'subrent',
                true,
                pageSize,
                startFrom,
                undefined,
                _sortBy,
                sortOrd,
                undefined,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            }
        });
    };
};

export const setSearch = (search: string) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.SET_SEARCH,
            payload: search,
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const setPageParams = (params: { [k: string]: any }) => ({
    type: ACTION_TYPES.SET_PAGE_PARAMS,
    payload: Promise.resolve(params),
});

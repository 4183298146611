import { TblColumn, TblColumnType } from '../tblColumns.types';
import { ColumnTypes, TableColumn } from '../../../components/grid/Table';
import { isDefined } from '../../../shared/util/utils';
import React from 'react';
import { ConditionalLink } from '../../../components/lib/ConditionalLink/ConditionalLink';

export const tblColumnsLink = <Record extends object, Action extends string = ''>(
    column: TblColumn<Record, Action>
): TableColumn<Record> => {
    if (column.type === TblColumnType.LINK) {
        return {
            title: column.title,
            dataIndex: column.dataIndex,
            type: ColumnTypes.CustomRender,
            minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
            resizable: false,
            render: (value: string, rowData: Record) => {
                const url = column.getUrl(rowData);
                const isClickable = Boolean(url);
                return (
                    <ConditionalLink
                        style={
                            isClickable
                                ? {
                                      color: '#343f9a',
                                  }
                                : undefined
                        }
                        condition={isClickable}
                        to={url}
                        onClick={(event) => event.stopPropagation()}
                    >
                        {value}
                    </ConditionalLink>
                );
            },
        };
    } else {
        throw Error('error');
    }
};

import { EntityTypeCodeEnum } from '../../../../../server';
import { LocalStorageUtils } from '../../../../../core/utils/localStorageUtils';
import { LabeledValue } from 'antd/es/select';
import { getChosenCustomFieldsForActionStorageKey } from '../../../inventory/products/hooks/customFields/useCustomFieldsStorageValue';

export const deleteSelectedCustomIdsFromStorage = (id: number, businessAccountId: number, email: string | undefined) => {
    for (let key in EntityTypeCodeEnum) {
        const storageKey = LocalStorageUtils.getKey(
            getChosenCustomFieldsForActionStorageKey(EntityTypeCodeEnum[key]),
            businessAccountId,
            email
        );
        const storageValue = localStorage.getItem(storageKey);

        if (storageValue != null) {
            try {
                const customFieldsValues = storageValue.split(';').map<LabeledValue>((jsonString) => JSON.parse(jsonString));

                const idIndex = customFieldsValues.findIndex((labeledValue) => Number(labeledValue.key) === id);
                if (idIndex != null) {
                    customFieldsValues.splice(idIndex, 1);

                    if (customFieldsValues.length > 0) {
                        localStorage.setItem(storageKey, customFieldsValues.map((value) => JSON.stringify(value)).join(';'));
                    } else {
                        localStorage.removeItem(storageKey);
                    }
                }
            } catch (e) {}
        }
    }
};

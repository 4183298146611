import { CalendarGroupsObject } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import { RenterGroupLine } from '../../../../elements/components/GroupLine/variants/RenterGroupLine';
import { ProjectGroupLine } from '../../../../elements/components/GroupLine/variants/ProjectGroupLine';
import { StatusGroupLine } from '../../../../elements/components/GroupLine/variants/StatusGroupLine';
import { AccessibilityElementsCalendarSortValue } from './sortData';

export const accessibilityElementsCalendarGroups: CalendarGroupsObject<AccessibilityElementsCalendarSortValue> = {
    renter: RenterGroupLine,
    project: ProjectGroupLine,
    stateCode: StatusGroupLine,
};

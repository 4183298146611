import React from 'react';
import { Card, Col, Icon, Row } from 'antd';
import { IconEdit, IconUser } from '../../../components/icons';
import FormValue from '../../../components/formValue/FormValue';
import { printUserName } from '../../../shared/util/utils';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationEnum, localize } from '../../../localization';
import { RoundButton, Spin } from '../../../components';
import { UserProfileDescriptionEdit } from './user-profile-description-edit';
import { push } from 'connected-react-router';
import { Avatar } from '../../../components/avatar/avatar';
import { IRootState } from '../../../shared/reducers';

const UserProfileDescription = (props) => {
    const dispatch = useDispatch();

    const userPicture = useSelector((state: IRootState) => state.oidc.user?.profile.picture);
    const userProfile = useSelector((state: IRootState) => state.userProfile.entity);
    const businessAccountId = useSelector((state: IRootState) => state.system.businessAccountId);
    const location = useSelector((state: IRootState) => state.router.location);

    const isCurrentProfile = location.pathname.indexOf(`/${businessAccountId}/userProfile/description`) === 0;

    return (
        <>
            <Card
                className={'rr-card'}
                bodyStyle={{ display: 'none' }}
                style={{ borderBottom: '1px solid #cccccc' }}
                title={<span className="card-title">Описание</span>}
                extra={
                    isCurrentProfile && (
                        <RoundButton
                            colorScheme={'default'}
                            onClick={() => {
                                dispatch(push(`/${businessAccountId}/userProfile/description/update`));
                            }}
                        >
                            <Icon component={IconEdit} />
                            {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                        </RoundButton>
                    )
                }
            />

            <Row type="flex" justify="center">
                <Col span={24}>
                    <Card>
                        <div>
                            <Row>
                                <Col style={{ marginBottom: 28 }}>
                                    <div style={{ marginBottom: 28 }}>
                                        <Avatar
                                            className={'rr-avatar-big'}
                                            style={{ backgroundColor: '#90dcff' }}
                                            src={isCurrentProfile ? userPicture : undefined}
                                        >
                                            <Icon component={IconUser} />
                                        </Avatar>
                                    </div>
                                </Col>
                                {!userProfile ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Spin />
                                    </div>
                                ) : (
                                    <>
                                        <Col className={'rr-form-item'}>
                                            <div className={'rr-label'}>
                                                {localize(LocalizationEnum.PAGE__USER_PROFILE__TAB__DESCRIPTION__FIELD__LOGIN)}
                                            </div>
                                            <div className="rr-value">
                                                <h3
                                                    style={{
                                                        fontSize: 24,
                                                        fontWeight: 'bold',
                                                        color: '#575962',
                                                        lineHeight: '29px',
                                                    }}
                                                >
                                                    <FormValue
                                                        value={userProfile.username}
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                                    />
                                                </h3>
                                            </div>
                                        </Col>
                                        {!userProfile.specialSystemUser ? (
                                            <Col className="rr-form-item">
                                                <div className="rr-label">
                                                    {localize(LocalizationEnum.ASPECT__GLOBAL__PERSON_FULL_NAME)}
                                                </div>
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={printUserName(userProfile.fullName)}
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__MANY)}
                                                    />
                                                </div>
                                            </Col>
                                        ) : null}
                                        <Col className="rr-form-item">
                                            <div className="rr-label">{localize(LocalizationEnum.ASPECT__GLOBAL__EMAIL)}</div>
                                            <div className="rr-value">
                                                <FormValue
                                                    value={userProfile.email}
                                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="rr-form-item">
                                            <div className="rr-label">{localize(LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER)}</div>
                                            <div className="rr-value">
                                                <FormValue
                                                    value={userProfile.phoneNumber}
                                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                                />
                                            </div>
                                        </Col>
                                        {!userProfile.specialSystemUser ? (
                                            <Col className="rr-form-item">
                                                <div className="rr-label">
                                                    {localize(LocalizationEnum.PAGE__USER_PROFILE__TAB__DESCRIPTION__FIELD__LOCALE)}
                                                </div>
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={userProfile.locale}
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F)}
                                                    />
                                                </div>
                                            </Col>
                                        ) : null}
                                        {!userProfile.specialSystemUser ? (
                                            <Col className="rr-form-item">
                                                <div className="rr-label">
                                                    {localize(LocalizationEnum.PAGE__USER_PROFILE__TAB__DESCRIPTION__FIELD__ROLES)}
                                                </div>
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={
                                                            userProfile.roles && userProfile.roles.length > 0
                                                                ? userProfile.roles.map((item, index, arr) => (
                                                                      <div key={index}>
                                                                          {item}
                                                                          {index < arr.length - 1 ? ',' : ''}
                                                                      </div>
                                                                  ))
                                                                : ''
                                                        }
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__MANY)}
                                                    />
                                                </div>
                                            </Col>
                                        ) : null}
                                    </>
                                )}
                            </Row>
                        </div>
                    </Card>
                </Col>

                {location.pathname === `/${businessAccountId}/userProfile/description/update` && userProfile ? (
                    <UserProfileDescriptionEdit title={'Редактирование профиля пользователя'} />
                ) : null}
            </Row>
        </>
    );
};

export default UserProfileDescription;

import { IRootState } from '../../../shared/reducers';
import { resetError, updateEntity } from './reducers/operation.reducer';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { OperationInfoUpdate } from '../../../server/api';
import { showConfirm } from '../../../components/confirm/showConfirm';
import { ModalFormNEW, ModalFormNEWProps } from '../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../components/dynamicForm/DynamicForm';
import { formFields } from './operationCreateModalFields';
import { LocalizationEnum, localizeIntl } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { updateModalFilters } from '../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
}

class _OperationCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = (data: object) => {
        let dataTmp: OperationInfoUpdate = { ...this.props.initialValues, ...data } as OperationInfoUpdate;
        let newData: OperationInfoUpdate = {
            businessVersion: dataTmp.businessVersion,
            comment: dataTmp.comment,
        };
        if (newData.comment === '') delete newData.comment;
        this.props.updateEntity(this.props.intl, this.props.businessAccountId, dataTmp.id || 0, newData);
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            // this.props.goBack();
            this.props.push(`/${this.props.businessAccountId}/history/operations/${this.props.initialValues?.id}/?tab=description`);
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.operation.updating,
    updatingError: storeState.operation.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = { updateEntity, goBack, resetError, push, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_OperationCreateModal));

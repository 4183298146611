import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import moment from "moment";
import {CustomRangePicker} from "../../../../../components/datePicker/CustomRangePicker";
import {OperationElement, setRentPeriodForElement, TimeTable, updateAnyTimetables} from "../../reducers/operationForm.reducer";
import {OperationTypeCodeEnum, RentStateCodeEnum} from "../../../../../server";
import {findTimeTable, getLeftoverInstances, isOrderOperation} from "../../utils/utils";
import {ProductUtils} from "../../../../../shared/util/productUtils";
import {useSelector} from "react-redux";
import {IRootState} from "../../../../../shared/reducers";
import {getShiftCountFromDates} from "../../../../../shared/util/utils";
import {getStore} from "../../../../../../index";
import {NomenclatureOnInterval, rentElementToNomenclatureBaseObj, loadNomenclaturesOnIntervalsIfNeed, getDateIntervalsForCalendar} from "../../utils/nomenclaturesUtils";
import {canSetEndDateTimeToTargetDateTime, canSetStartTimeToTargetDateTime} from "../../../../../utils/timeUtils/timeUtils";
import {getIsElementReducesAvailability} from "../../utils/operationUtils";

interface Props {
    children: ReactNode;
    value?: any;
    onEditing?: (isEditing: boolean) => void;
    canBeEdit: boolean;
    element: OperationElement;
}

export const InlinePeriodPicker = (props: Props) => {
    const [editable, setEditable] = useState<boolean|undefined>(undefined);
    const [beforeEditValue, setBeforeEditValue] = useState<[Date, Date] | undefined>();
    const [beforeEditShiftCount, setBeforeEditShiftCount] = useState<number | undefined>();

    const setPrevValues = useCallback(()=>{
        if(beforeEditValue) getStore().dispatch(setRentPeriodForElement(props.element.id, beforeEditValue, beforeEditShiftCount));
        setEditable(false);
    }, [beforeEditValue, beforeEditShiftCount, props.element.id]);

    useEffect(() => {
        if(editable !== undefined){
            props.onEditing?.(editable);
        }
    }, [editable]);

    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === "Escape" && editable) {
                setPrevValues();
            }
        }
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [editable, setPrevValues]);

    let hasOwnShiftLength = props.element.hasOwnShiftLength;
    let shiftLengthInMin = props.element.shiftLengthInMinutes;
    let requiredTimeIndentBetweenElementsInMinutes = props.element.requiredTimeIndentBetweenElementsInMinutes;
    let productHasOwnRequiredTimeIndentBetweenElements = props.element.productHasOwnRequiredTimeIndentBetweenElements;
    let shiftCount = props.element.shiftCount;

    const operationTypeCode = useSelector((state: IRootState) => state.operationForm.typeCode);
    const operationTypeCorrectionCode = useSelector((state: IRootState) => state.operationForm.targetStateCode);
    const isSubrentOperation = useSelector((state: IRootState) => state.operationForm.isSubrent);
    const timeTables = useSelector((state: IRootState) => state.operationForm.timeTables);
    const recalculateShiftCount = useSelector((state: IRootState) => state.userSettings.operationFormRecalculateShiftCount);
    const shiftCountRoundingType = useSelector((state: IRootState) => state.operationForm.shiftCountRoundingType);
    const operationStartTime = useSelector((state: IRootState) => state.operationForm.operationStartTime);
    const pricingScheme = props.element.pricingSchemeId || undefined;

    let intervals = getIntervals(props.element, operationTypeCode, operationTypeCorrectionCode, isSubrentOperation, timeTables);

    const [from, until] = getDateIntervalsForCalendar(props.value[0], props.value[1]);

    useEffect(() => {
        if(editable){
            const n:NomenclatureOnInterval = {...rentElementToNomenclatureBaseObj(props.element), from, until};
            loadNomenclaturesOnIntervalsIfNeed([n]).then((data)=>{
                getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
            }).catch((error)=>{
                console.error(error);
            });
        }
    }, [editable, from, until]);

    const setRentPeriod = (dates) => {
        let startDate = dates[0] ? dates[0].toDate() : undefined;
        let endDate = dates[1] ? dates[1].toDate() : undefined;
        let shiftLengthInMin = props.element.shiftLengthInMinutes;
        let diff = startDate && endDate ? getShiftCountFromDates(pricingScheme, moment(startDate), moment(endDate), shiftLengthInMin, shiftCountRoundingType) : 0;
        getStore().dispatch(setRentPeriodForElement(props.element.id, [startDate, endDate], recalculateShiftCount ? diff : undefined));
    };

    const getTodayDate = () => {
        return moment(operationStartTime)
    }

    const startDateValue = props.value[0] ? moment(props.value[0]) : undefined;
    const endDateValue = props.value[1] ? moment(props.value[1]) : undefined;

    const todayDate = getTodayDate ? getTodayDate().startOf('minute') : moment().startOf('minute');



    const startDateTodayButtonDisabled1 = !canSetStartTimeToTargetDateTime(todayDate, startDateValue, endDateValue);
    const endDateTodayButtonDisabled1 = !canSetEndDateTimeToTargetDateTime(todayDate, startDateValue, endDateValue);

    const onStartDateTodayButtonClick1 = !startDateTodayButtonDisabled1 ? ()=>{
        if(startDateValue && endDateValue){
            const curDate = getTodayDate ? getTodayDate().startOf('minute') : moment().startOf('minute');
            const startDate = startDateValue?.clone().hours(curDate.hours()).minutes(curDate.minutes());
            setRentPeriod([startDate, endDateValue]);
            //onChange?.([startDate, endDateValue]);
        }
    } : null;

    const onEndDateTodayButtonClick1 = !endDateTodayButtonDisabled1 ? ()=>{
        if(startDateValue && endDateValue){
            const curDate = getTodayDate ? getTodayDate().startOf('minute') : moment().startOf('minute');
            const endDate = endDateValue?.clone().hours(curDate.hours()).minutes(curDate.minutes());
            setRentPeriod([startDateValue, endDate]);
            //onChange?.([startDateValue, endDate]);
        }
    } : null;

    return (
        <CustomRangePicker
            disabled={!props.canBeEdit}
            allowClear={false}
            intervals={intervals}
            showTime
            value={[moment(props.value[0]), moment(props.value[1])]}
            onChange={(dates, datesStrings) => {
                setRentPeriod(dates);
            }}
            open={editable}
            shiftLengthInMin={shiftLengthInMin}
            hasOwnShiftLength={hasOwnShiftLength}
            requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
            productHasOwnRequiredTimeIndentBetweenElements={productHasOwnRequiredTimeIndentBetweenElements}
            shiftCount={shiftCount}
            onOpenChange={(open)=>{
                setEditable(open);
                if(open){
                    setBeforeEditValue([props.value[0], props.value[1]]);
                    setBeforeEditShiftCount(props.element.shiftCount);
                }
            }}
            align={{points: ['bl', 't'], offset: [0, -6]}}
            getTodayDate={()=>{
                return moment(operationStartTime)
            }}
            onStartDateTodayButtonClick1={onStartDateTodayButtonClick1}
            onEndDateTodayButtonClick1={onEndDateTodayButtonClick1}
            pricingScheme={pricingScheme}
        >
            <div className={props.canBeEdit ? 'rr-inline-editable' : undefined}>{props.children}</div>
        </CustomRangePicker>
    );
};

const getIntervals = (elementRecord: OperationElement, operationTypeCode: OperationTypeCodeEnum | undefined, operationTypeCorrectionCode: RentStateCodeEnum | undefined, isSubrentOperation: boolean | undefined, timeTables: TimeTable[]) => {
    let rentPeriodStartDate = elementRecord.rentPeriodStartDate,
        rentPeriodEndDate = elementRecord.rentPeriodEndDate;
    let intervals: [number, number][] = [];
    // Кол-во экземпляров
    let instanceCount = elementRecord.instanceCount;
    let timeTable = findTimeTable(timeTables, {
        productId: elementRecord.productId,
        variantId: elementRecord.variantId
    });

    let availabilityFactor = isSubrentOperation ? -1 : 1;

    if (timeTable && rentPeriodStartDate && rentPeriodEndDate) {
        let map = isOrderOperation(operationTypeCode) ? timeTable.orderAvailable : timeTable.available1;

        intervals = map;

        let rentPeriodStartDate1 = rentPeriodStartDate;
        let rentPeriodEndDate1 = rentPeriodEndDate;

        if (elementRecord && elementRecord.id < 0) {
            // Для НОВОГО нужно компенсировать что вычли из карты
            intervals = ProductUtils.getNewIntervals(intervals, [
                // Компенсируем что было в начале
                [availabilityFactor * -elementRecord.instanceCount, elementRecord.rentPeriodStartDate.getTime(), elementRecord.rentPeriodEndDate.getTime()],
            ]);
            intervals = ProductUtils.getNewIntervals(intervals, [[availabilityFactor * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime()]]);
        } else if (elementRecord && elementRecord.rentPeriodStartDateOriginal && elementRecord.rentPeriodEndDateOriginal) {
            // Редактирование существующего
            // Для заявки и черновика нужно компенсировать что пришло с сервера

            let elementIntervals: [number, number, number, string][];
            let isElementReducesAvailability = getIsElementReducesAvailability(elementRecord);
            let isCorrectCancelOperation = operationTypeCode === OperationTypeCodeEnum.CORRECT && operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;

            // Компенсируем
            elementIntervals = [];
            elementIntervals.push([availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * -elementRecord.instanceCount, elementRecord.rentPeriodStartDate.getTime(), elementRecord.rentPeriodEndDate.getTime(), 'Компенсируем текущее значение']);
            let leftoverInstances = getLeftoverInstances(elementRecord.instanceIdsOriginal, elementRecord.instanceIds);
            let leftover = Math.max(0, elementRecord.instanceCountOriginal - elementRecord.instanceCount, leftoverInstances.length);
            if (elementRecord.keepLeftover) elementIntervals.push([availabilityFactor * ((isElementReducesAvailability || elementRecord.stateCode === RentStateCodeEnum.ORDERED) && elementRecord.variantId === elementRecord.variantIdOriginal ? 1 : 0) * -leftover, elementRecord.rentPeriodStartDateOriginal.getTime(), elementRecord.rentPeriodEndDateOriginal.getTime(), 'Компенсируем остаток']);
            intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);

            // Вычитаем
            elementIntervals = [];
            elementIntervals.push([availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime(), 'Вычитаем текущее значение']);
            leftoverInstances = getLeftoverInstances(elementRecord.instanceIdsOriginal, elementRecord.instanceIds); //TODO было this.state.selectedInstances
            leftover = Math.max(0, elementRecord.instanceCountOriginal - instanceCount, leftoverInstances.length);
            if (elementRecord.keepLeftover) elementIntervals.push([availabilityFactor * ((isElementReducesAvailability/* || this.props.elementRecord.stateCode === RentStateCodeEnum.ORDERED*/) && elementRecord.variantId === elementRecord.variantIdOriginal ? 1 : 0) * leftover, elementRecord.rentPeriodStartDateOriginal.getTime(), elementRecord.rentPeriodEndDateOriginal.getTime(), 'Вычитаем остаток']);
            intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);
        }
    }

    return intervals;
};


import {
    OperationInfoCreate,
    OperationTypeCodeEnum,
    ProjectInfoCreate,
    ProjectStateCodeEnum,
    ProjectTypeCodeEnum,
    RentElementInfoWrite,
    RentElementsKitObjWrite,
    TaxBaseTypeCodeEnum,
    TypeOfPricingCodeEnum
} from "../../../../server";
import {ProductUtils} from "../../../../shared/util/productUtils";
import {OperationElement, OperationFormState} from "./operationForm.reducer";
import {isDefined} from "../../../../shared/util/utils";

export const getRentElementsToCreate = (state: OperationFormState) => {
    let rentElementsToCreate: RentElementInfoWrite[] = state.elements.entities
        .filter((element) => /*element.id <= 0 &&*/ !element.kitId && !element.parentId)
        .filter((element: OperationElement) => element.isCancelled !== true)
        .map((element: OperationElement) => {
            if (element.id <= 0) return ProductUtils.createRentElementInfoCreate(element, state.typeCode === OperationTypeCodeEnum.EDIT, !state.projectTemplate);
            else return ProductUtils.createRentElementInfoUpdate(element, state.typeCode === OperationTypeCodeEnum.EDIT, !state.projectTemplate);
        });
    return rentElementsToCreate;
};

export const getRentElementsKitsToCreate = (state: OperationFormState) => {
    let rentElementsKitsToCreate: RentElementsKitObjWrite[] = state.elements.entities
        .filter((element: OperationElement) => /*element.id <= 0 &&*/ isDefined(element.kitId))
        .filter((element: OperationElement) => element.isCancelled !== true)
        .map((element: OperationElement) => {
            if (element.id <= 0) {
                let rentElementsToCreate = state.elements.entities
                    .filter((element: OperationElement) => element.isCancelled !== true)
                    .filter((_element: OperationElement) => _element.parentId === element.id)
                    .map((element: OperationElement) =>
                        element.id < 0
                            ? ProductUtils.createRentElementInfoCreate(element, state.typeCode === OperationTypeCodeEnum.EDIT, !state.projectTemplate)
                            : ProductUtils.createRentElementInfoUpdate(element, state.typeCode === OperationTypeCodeEnum.EDIT, !state.projectTemplate)
                    );
                return {
                    kitId: element.kitId || 0,
                    rentElements: rentElementsToCreate,
                };
            } else {
                let rentElementsToUpdate = state.elements.entities
                    .filter((element: OperationElement) => element.isCancelled !== true)
                    .filter((_element: OperationElement) => _element.parentId === element.id)
                    .map((element: OperationElement) =>
                        element.id < 0
                            ? ProductUtils.createRentElementInfoCreate(element,state.typeCode === OperationTypeCodeEnum.EDIT, !state.projectTemplate)
                            : ProductUtils.createRentElementInfoUpdate(element, state.typeCode === OperationTypeCodeEnum.EDIT, !state.projectTemplate)
                    );
                return {
                    id: element.id,
                    kitId: element.kitId || 0,
                    rentElements: rentElementsToUpdate,
                    businessVersion: element.businessVersion,
                };
            }
        });
    return rentElementsKitsToCreate;
}

export const getCancelledElements = (state: OperationFormState) => {
    let cancelledElements = state.elements.entities
        .filter((el) => /*el.canBeCancelled && */ el.isCancelled)
        .map((el) => {
            return { id: el.id, businessVersion: el.businessVersion, rentElementsKitId: (el.parentId || null) as any };
        });
    return cancelledElements;
}

export const getOperationInfoCreate = (state: OperationFormState) => {
    const rentElementsToCreate = getRentElementsToCreate(state);
    const rentElementsKitsToCreate = getRentElementsKitsToCreate(state);
    const cancelledElements = getCancelledElements(state);

    const operationInfoCreate: OperationInfoCreate = {
        comment:
            state.currentOperation?.comment && state.currentOperation.comment.length > 0 ? state.currentOperation.comment : undefined,
        rentElements: rentElementsToCreate.length ? rentElementsToCreate : undefined,
        rentElementsKits: rentElementsKitsToCreate.length ? rentElementsKitsToCreate : undefined,
        canceledRentElements: cancelledElements.length ? { entities: cancelledElements } : undefined,
        projectId: state.isSubrent ? undefined : state.projectTemplate ? undefined : state.projectId,
        subrentId: !state.isSubrent ? undefined : state.projectTemplate ? undefined : state.projectId,
        templateId: state.projectTemplate ? state.projectId : undefined,
        typeCode: state.typeCode!,
        commonRentTerms: {
            discount: state.discount,
            shiftCount: state.shiftCount,
            rentPeriodStartDate: state.rentPeriodStartDate || new Date(),
            rentPeriodEndDate: state.rentPeriodEndDate || new Date(),
        },
        targetStateCode: state.targetStateCode,
        concurrentOperationUUID: state.currentOperationUUID,
    };
    return operationInfoCreate;
};

export const getProjectInfoCreate = () => {
    const projectInfoCreate:ProjectInfoCreate = {
        renterId: 1261,
        shortName: 'AUTO_NAME',
        projectType: ProjectTypeCodeEnum.BASE,
        stateCode: ProjectStateCodeEnum.DRAFT,
        copyExpenses: false,
        copyRentElements: false,
        copyWorkPlannings: false,
        saveTrackedInstances: true,
        saveCrewMembers: true,
        isSimpleOrder: true,
        typeOfPricing: TypeOfPricingCodeEnum.STRAIGHT,
        taxBaseType: TaxBaseTypeCodeEnum.COSTBEFORETAX,
        taxRate: 0,
        hasOwnAssignee: false,
        hasOwnDefaultDiscount: false,
        hasOwnContacts: false,

        startDate: new Date(),
        targetFinishDate: new Date(),
        projectContract: {} // #2120
    };
    return projectInfoCreate;
}

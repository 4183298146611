import { ColumnTypes, TableColumn } from '../../../components/grid/Table';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';
import moment from 'moment';
import { formatMoneyWithFixedDecimalScale } from '../../../shared/util/formatMoney';
import { ElementsElementPopover } from '../elements/elements-element-popover';
import { allSumOfElements } from '../../../shared/util/allSumOfElements';
import { LocalizationEnum, localize } from '../../../localization';
import { GRID_IMAGE_COLUMN_WIDTH, MAX_WIDTH } from '../../../config/constants';
import isEqual from 'lodash/isEqual';
import { RentAuditWithPredecessorRecord } from '../../../server/api';
import { tableCellPercentsRenderer } from '../../../components/grid/renderers/tableCellPercentsRenderer';
import { tableCellShiftCountRenderer } from '../../../components/grid/renderers/tableCellShiftCountRenderer';
import { HistoryElementsPopupWrapper } from './history-elements-popup';
import {ProductVariantPopover} from "../inventory/variants/components/productVariantPopover/productVariantPopover";
import './history-elements-columns.less';
import classNames from 'classnames';

// GRID COLUMNS
const columns: TableColumn<RentAuditWithPredecessorRecord>[] = [
    {
        title: '',
        dataIndex: '' as any, // TODO
        type: ColumnTypes.CustomRender,
        width: 56,
        maxWidth: MAX_WIDTH(56),
        resizable: false,
        sortable: true,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            if (!rowData.predecessor) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: '#34BFA3',
                                borderRadius: '5px',
                                marginLeft: '20px',
                            }}
                        ></div>
                    </div>
                );
            } else {
                if (
                    rowData.predecessor.anonymousInstanceCount !== rowData.audit.anonymousInstanceCount ||
                    rowData.predecessor.instanceCount !== rowData.audit.instanceCount ||
                    !isEqual(rowData.predecessor.instances, rowData.audit.instances)
                ) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: '#04b0f1',
                                    borderRadius: '5px',
                                    marginLeft: '20px',
                                }}
                            ></div>
                        </div>
                    );
                } else return null;
            }
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NUMBER),
        dataIndex: 'audit.productId',
        type: ColumnTypes.CustomRender,
        minWidth: 200,
        maxWidth: MAX_WIDTH(200),
        sortable: false,
        resizable: !false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            return (
                <>
                    {rowData.audit.rentElementIds /*[556678, 556678, 556678, 556678, 556678, 556678]*/
                        .map((id, index, arr) => (
                            <>
                                <ElementsElementPopover entityId={id} hideActionsButton={true}>
                                    <strong className={'main-value'}>
                                        <span style={{ cursor: 'pointer' }}>#{id}</span>
                                    </strong>
                                </ElementsElementPopover>
                                {index < arr.length - 1 ? ', ' : ' '}
                            </>
                        ))}
                </>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__IMAGE),
        dataIndex: 'audit.productMainImage',
        type: ColumnTypes.Image,
        width: GRID_IMAGE_COLUMN_WIDTH,
        maxWidth: GRID_IMAGE_COLUMN_WIDTH,
        sortable: false,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
        dataIndex: 'audit.instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 80,
        sortable: false,
        maxWidth: MAX_WIDTH(70),
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            const anonymousInstance =
                rowData.audit.anonymousInstanceCount &&
                (rowData.audit.instanceTrackingTypeCode === 'PRODUCT_INSTANCE_TRACKED' ||
                    rowData.audit.instanceTrackingTypeCode === 'PRODUCT_VARIANT_INSTANCE_TRACKED')
                    ? `(${rowData.audit.anonymousInstanceCount}?)`
                    : null;
            let canPopupVisible: boolean = false;

            let isChange: string;

            if (rowData.predecessor) {
                if (
                    rowData.predecessor.anonymousInstanceCount !== rowData.audit.anonymousInstanceCount ||
                    rowData.predecessor.instanceCount !== rowData.audit.instanceCount ||
                    !isEqual(rowData.predecessor.instances, rowData.audit.instances)
                ) {
                    canPopupVisible = true;
                    isChange = '#04b0f1';
                } else {
                    isChange = '#343f9a';
                }
            } else {
                isChange = '#34BFA3';
            }

            return (
                <HistoryElementsPopupWrapper
                    value={<>{rowData.predecessor?.instanceCount}</>}
                    canVisible={canPopupVisible}
                >
                    <span style={{ fontWeight: 600, color: isChange }}>
                        {' '}
                        {value} {anonymousInstance}
                    </span>
                </HistoryElementsPopupWrapper>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRODUCT),
        dataIndex: 'audit.productShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        maxWidth: MAX_WIDTH(170),
        sortable: false,
        resizable: true,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => (
            <div className={'rr-grid-shortName-cell'} title={rowData.audit.productShortName}>
                <Link to={`/${rowData.audit.businessAccountId}/inventory/products/${rowData.audit.productId}`}>
                    {rowData.audit.productShortName}
                </Link>
            </div>
        ),
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT),
        dataIndex: 'audit.variantName',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        maxWidth: MAX_WIDTH(170),
        resizable: true,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let previousVariantName: JSX.Element | undefined;
            let canPopupVisible: boolean = false;

            const getJsx = (): JSX.Element => {
                if (rowData.audit.variantId) {
                    let valueJsx: JSX.Element;

                    if (!rowData.predecessor) valueJsx = <strong style={{ color: '#34BFA3' }}>{value}</strong>;
                    else if (rowData.audit.variantId !== rowData.predecessor.variantId) {
                        canPopupVisible = true;
                        previousVariantName = <>{rowData.predecessor.variantName}</>;
                        valueJsx = <strong style={{ color: '#04b0f1' }}>{value}</strong>;
                    } else {
                        valueJsx = value;
                    }

                    return (
                        <div
                            className={classNames({
                                'rr-grid-shortName-cell': true,
                                'hover-disabled': canPopupVisible,
                            })}
                            title={value}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {rowData ? (
                                <span className="popover-header-name grid-column-variant">{valueJsx}</span>
                            ) : null}
                        </div>
                    );
                }

                return <></>;
            };

            const jsx = getJsx();

            return (
                <HistoryElementsPopupWrapper value={previousVariantName} canVisible={canPopupVisible}>
                    {canPopupVisible ? (
                        jsx
                    ) : (
                        rowData.audit.variantId ? <ProductVariantPopover
                            productId={rowData.audit.productId}
                            variantId={rowData.audit.variantId}
                        >
                            {jsx}
                        </ProductVariantPopover> : null
                    )}
                </HistoryElementsPopupWrapper>
            );
        },
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATE_CODE_BEFORE),
        dataIndex: 'predecessor.stateCode',
        type: ColumnTypes.Status,
        sortable: false,
        minWidth: 110,
        maxWidth: MAX_WIDTH(110),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT),
        dataIndex: 'audit.rentElementEffectivePrice',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        maxWidth: MAX_WIDTH(140),
        sortable: false,
        resizable: false,
        render: (value: number, rowData: RentAuditWithPredecessorRecord) => {
            let priceShift = formatMoneyWithFixedDecimalScale(rowData.audit.rentElementEffectivePrice);
            let previousPriceShift: JSX.Element = <></>;
            let canPopupVisible: boolean = false;

            const getJsx = (): JSX.Element => {
                if (!rowData.predecessor) {
                    return <strong style={{ color: '#34BFA3' }}>{priceShift}</strong>;
                } else {
                    if (rowData.audit.rentElementEffectivePrice !== rowData.predecessor.rentElementEffectivePrice) {
                        const predecessorRentElementEffectivePrice = rowData.predecessor.rentElementEffectivePrice;
                        if (value !== predecessorRentElementEffectivePrice) {
                            canPopupVisible = true;
                            previousPriceShift = formatMoneyWithFixedDecimalScale(predecessorRentElementEffectivePrice);
                            return <strong style={{ color: '#04b0f1' }}>{priceShift}</strong>;
                        } else return priceShift;
                    } else return priceShift;
                }
            };

            const jsx = getJsx();

            return (
                <HistoryElementsPopupWrapper value={previousPriceShift} canVisible={canPopupVisible}>
                    {jsx}
                </HistoryElementsPopupWrapper>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
        dataIndex: 'audit.rentTerms.rentPeriodStartDate',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        maxWidth: MAX_WIDTH(150),
        sortable: false,
        //maxWidth: 150,
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let valueDate = moment(value).format('DD.MM.YYYY HH:mm');
            let previousValueDate: JSX.Element | undefined;
            let canPopupVisible: boolean = false;

            const getJsx = (): JSX.Element => {
                if (!rowData.predecessor) {
                    return <strong style={{ color: '#34BFA3' }}>{valueDate}</strong>;
                } else {
                    if (
                        rowData.audit.rentTerms.rentPeriodStartDate !==
                        rowData.predecessor.rentTerms.rentPeriodStartDate
                    ) {
                        const predecessorRentPeriodStartDate = rowData.predecessor.rentTerms.rentPeriodStartDate;
                        if (value !== predecessorRentPeriodStartDate) {
                            canPopupVisible = true;
                            previousValueDate = (
                                <>{moment(predecessorRentPeriodStartDate).format('DD.MM.YYYY HH:mm')}</>
                            );
                            return <strong style={{ color: '#04b0f1' }}>{valueDate}</strong>;
                        } else return <>{valueDate}</>;
                    } else return <>{valueDate}</>;
                }
            };

            const jsx = getJsx();

            return (
                <HistoryElementsPopupWrapper value={previousValueDate} canVisible={canPopupVisible}>
                    {jsx}
                </HistoryElementsPopupWrapper>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__END_DATE),
        dataIndex: 'audit.rentTerms.rentPeriodEndDate',
        type: ColumnTypes.CustomRender,
        sortable: false,
        minWidth: 150,
        maxWidth: MAX_WIDTH(150),
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let valueDate = moment(value).format('DD.MM.YYYY HH:mm');
            let previousValueDate: JSX.Element | undefined;
            let canPopupVisible: boolean = false;

            const getJsx = (): JSX.Element => {
                if (!rowData.predecessor) {
                    return <strong style={{ color: '#34BFA3' }}>{valueDate}</strong>;
                } else {
                    if (rowData.audit.rentTerms.rentPeriodEndDate !== rowData.predecessor.rentTerms.rentPeriodEndDate) {
                        const predecessorRentPeriodEndDate = rowData.predecessor.rentTerms.rentPeriodEndDate;
                        if (value !== predecessorRentPeriodEndDate) {
                            canPopupVisible = true;
                            previousValueDate = <>{moment(predecessorRentPeriodEndDate).format('DD.MM.YYYY HH:mm')}</>;
                            return <strong style={{ color: '#04b0f1' }}>{valueDate}</strong>;
                        } else return <>{valueDate}</>;
                    } else return <>{valueDate}</>;
                }
            };

            const jsx = getJsx();

            return (
                <HistoryElementsPopupWrapper value={previousValueDate} canVisible={canPopupVisible}>
                    {jsx}
                </HistoryElementsPopupWrapper>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__SHIFT_COUNT),
        dataIndex: 'audit.rentTerms.shiftCount',
        type: ColumnTypes.CustomRender,
        sortable: false,
        minWidth: 90,
        maxWidth: MAX_WIDTH(90),
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let shiftCount = tableCellShiftCountRenderer(value);
            let previousShiftCount: JSX.Element | undefined;
            let canPopupVisible: boolean = false;

            const getJsx = (): JSX.Element => {
                if (!rowData.predecessor) {
                    return <strong style={{ color: '#34BFA3' }}>{shiftCount}</strong>;
                } else {
                    if (rowData.audit.rentTerms.shiftCount !== rowData.predecessor.rentTerms.shiftCount) {
                        const predecessorShiftCount = rowData.predecessor.rentTerms.shiftCount;
                        if (value !== predecessorShiftCount) {
                            canPopupVisible = true;
                            previousShiftCount = tableCellShiftCountRenderer(predecessorShiftCount);
                            return <strong style={{ color: '#04b0f1' }}>{shiftCount}</strong>;
                        } else return shiftCount;
                    } else return shiftCount;
                }
            };

            const jsx = getJsx();

            return (
                <HistoryElementsPopupWrapper value={previousShiftCount} canVisible={canPopupVisible}>
                    {jsx}
                </HistoryElementsPopupWrapper>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'audit.rentTerms.discount',
        type: ColumnTypes.CustomRender,
        minWidth: 60,
        maxWidth: MAX_WIDTH(60),
        sortable: false,
        //maxWidth: 100,
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let valuePercents = tableCellPercentsRenderer(value);
            let previousValuePercents: JSX.Element | undefined;
            let canPopupVisible: boolean = false;

            const getJsx = (): JSX.Element => {
                if (!rowData.predecessor) {
                    return <strong style={{ color: '#34BFA3' }}>{valuePercents}</strong>;
                } else {
                    if (rowData.audit.rentTerms.discount !== rowData.predecessor.rentTerms.discount) {
                        const predecessorDiscount = rowData.predecessor.rentTerms.discount;
                        if (value !== predecessorDiscount) {
                            canPopupVisible = true;
                            previousValuePercents = tableCellPercentsRenderer(predecessorDiscount);
                            return <strong style={{ color: '#04b0f1' }}>{valuePercents}</strong>;
                        } else return valuePercents;
                    } else return valuePercents;
                }
            };

            const jsx = getJsx();

            return (
                <HistoryElementsPopupWrapper value={previousValuePercents} canVisible={canPopupVisible}>
                    {jsx}
                </HistoryElementsPopupWrapper>
            );
        },
    },

    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__COST_INSTANCE_PER_SHIFT),
        dataIndex: 'costInstancePerShift',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 180,
        maxWidth: MAX_WIDTH(180),
        sortable: false,
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let valueSum = allSumOfElements(
                rowData.audit.instanceCount,
                1,
                rowData.audit.rentElementEffectivePrice,
                rowData.audit.rentTerms.discount
            );
            return formatMoneyWithFixedDecimalScale(valueSum);
        },
    },
    {
        type: ColumnTypes.String,
        minWidth: 5,
        resizable: false,
        sortable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE),
        dataIndex: 'allSum' as any, // TODO
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        maxWidth: MAX_WIDTH(140),
        sortable: false,
        resizable: false,
        render: (value: any, rowData: RentAuditWithPredecessorRecord) => {
            let valueSum = allSumOfElements(
                rowData.audit.instanceCount,
                rowData.audit.rentTerms.shiftCount,
                rowData.audit.rentElementEffectivePrice,
                rowData.audit.rentTerms.discount
            );
            return formatMoneyWithFixedDecimalScale(valueSum);
        },
    },
];

export default columns;

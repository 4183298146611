import ReactDOM from "react-dom";
import React from "react";
import {Modal} from "antd";
import "./img-show-modal.less";
import {RenterInfoRead} from "../../../server";

export interface ImgShowModalState {
}

export interface ImgShowModalProps {
    src: string;
    onCancel: () => void;
}

export class ImgShowModal extends React.PureComponent<ImgShowModalProps, ImgShowModalState> {
    componentDidMount = () => {
        console.log(`${this.constructor.name} componentDidMount()`);
    };

    componentWillUnmount = () => {
        console.log(`${this.constructor.name} componentWillUnmount()`);
    };

    componentDidUpdate = (prevProps: ImgShowModalProps) => {

    };




    render() {
        console.log(`${this.constructor.name} render()`, this.props);
         //   {title, updating} = this.props;

        return (
            <Modal
                visible
                centered={true}
                closable={false}
                footer={null}
                width={'unset'}
                className={'rr-img-modal'}
                onCancel={this.props.onCancel}
            >
                <img src={this.props.src}/>

            </Modal>
        );
    }
}
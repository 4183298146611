import { DownloadPeriodValues } from '../hooks/useSelectOptions';
import moment, { Moment } from 'moment';

export const getPeriodValuesByOptionValue = (periodValue: DownloadPeriodValues) => {
    return (
        {
            currentMonth: [moment().startOf('month'), moment().endOf('month')],
            lastMonth: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
            monthBeforeLast: [moment().subtract(2, 'months').startOf('month'), moment().subtract(2, 'months').endOf('month')],
            currentYear: [moment().startOf('year'), moment().endOf('year')],
            lastYear: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')],
        } satisfies Record<DownloadPeriodValues, [Moment, Moment]>
    )[periodValue];
};

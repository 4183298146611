import {withEntityListProviders} from '../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import {GridProps} from '../../../../../components/grid/Grid';
import {ListPaymentMethodsQueryParams} from '../api/types';
import {ModalFormNEWProps} from '../../../../../components/modalForm/ModalFormNEW';
import {FC, useContext} from 'react';
import {useAppSelector} from '../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../shared/reducers/system.reducer';
import {EntityList} from '../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import {paymentMethodsColumns} from './columns/paymentMethodsColumns';
import {paymentMethodsFilters} from './filters/paymentMethodsFilters';
import {PaymentMethodsPageFiltersDescription} from './filters/paymentMethodsFiltersDescription';
import {GridRefContext} from '../../../../../components/page/entityGrid/components/context/GridRefContext';
import {EntitiesSelectionContext} from '../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import {paymentMethodsLoadingSelector} from '../reducers/paymentMethods/paymentMethods.selectors';
import {paymentMethodEntitySelector} from '../reducers/paymentMethod/paymentMethod.selectors';
import {useEntityActions} from '../hooks/paymentMethodsListHooks';
import {PaymentMethodCreateModalWrapper} from '../modal/create/paymentMethodCreateModalWrapper';
import {PaymentMethodEditModalWrapper} from '../modal/edit/paymentMethodEditModalWrapper';
import {clearPaymentMethod, loadPaymentMethod} from '../reducers/paymentMethod/paymentMethod.reducer';
import {EntityGridRootPath} from '../../../../../components/page/entityGrid/types/params';
import {usePageURLParamsFromDescription, useQueryParamsWithoutModalParams} from '../../../../../components/page/entityGrid/hooks/useParams';
import {useListPaymentMethodsQuery} from '../api/paymentMethods.api';
import './paymentMethodsList.less';

interface PaymentMethodsListComponentProps {
    gridName: 'paymentMethods';
    urlPath: `settings/paymentMethods`;
    gridProps?: Partial<GridProps>;
    staticPageParams?: Partial<ListPaymentMethodsQueryParams>;
    modalProps?: Partial<ModalFormNEWProps>;
    visible?: boolean;
}

const PaymentMethodsListComponent: FC<PaymentMethodsListComponentProps> = (props) => {
    const {gridName, urlPath, visible, staticPageParams, gridProps, modalProps} = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(paymentMethodEntitySelector);
    const entitiesLoading = useAppSelector(paymentMethodsLoadingSelector);
    const gridRef = useContext(GridRefContext);
    const [entitySelection] = useContext(EntitiesSelectionContext);

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: PaymentMethodsPageFiltersDescription,
        gridName,
        skipLocationChange: !visible,
    });
    const {pageParams, getSearchParams} = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListPaymentMethodsQuery(
        {
            businessAccountId: businessAccountId,
            params: {
                ...queryParams,
                ...staticPageParams,
            },
            //projectId: parentProjectEntity?.id,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    let rootPath = `/${businessAccountId}/${urlPath}` as EntityGridRootPath;
    // if (parentProjectEntity?.id) {
    //     rootPath = rootPath.split('?')[0] as EntityGridRootPath;
    // }

    const entityActions = useEntityActions({
        rootPath,
        urlSearchParams,
    });

    return (
        <div className={'rr-paymentMethods-list'}>
            <EntityList
                pageParamsObject={pageParamsObject}
                gridProps={{
                    static: {
                        columns: paymentMethodsColumns,
                        filtersData: paymentMethodsFilters,
                        pageParamsDescription: PaymentMethodsPageFiltersDescription,
                    },
                    metaData: {
                        entityType: 'paymentMethod',
                        gridName,
                        rootPath: rootPath,
                    },
                    queryData: queryData,
                    entitiesLoading,
                    entityActions: entityActions as any,
                    //entityActionsButtons,
                    selectable: false,
                    ...gridProps,
                }}
                modalProps={{
                    currentEntity,
                    modals: {
                        create: {
                            component: PaymentMethodCreateModalWrapper,
                            //props: createModalProps,
                        },
                        edit: {
                            component: PaymentMethodEditModalWrapper,
                        },
                    },
                    entityLoadActions: {
                        loadEntity: loadPaymentMethod,
                        clearEntity: clearPaymentMethod,
                    },
                }}
            />
        </div>
    );
};

export const PaymentMethodsList = withEntityListProviders(PaymentMethodsListComponent);

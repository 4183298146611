import { Configuration } from '../server/api';
import { getUserAccessToken } from '../../index';
import { UserManagerSettings } from 'oidc-client';
import fromEntries from 'fromentries';

export const API_SERVER_BASE_PATH = window['rrAppConfig'].apiServerBasePath;
export const CONTENT_SERVER_BASE_PATH = window['rrAppConfig'].contentServerBasePath;
export const SUPPORT_SERVER_BASE_PATH = window['rrAppConfig'].supportServerBasePath;
export const WIKI_SERVER_BASE_PATH = window['rrAppConfig'].wikiServerBasePath;
export const AUTH_SERVER_PATH = window['rrAppConfig'].authServerPath;
export const WIKI_LANG_PAGE_IDS = fromEntries(window['rrAppConfig'].wikiLangPageIds.split(';').map((el) => el.split(':')));
export const LOG_NOMENCLATURE_STACKMAPS = true;

export const serverApiConfig = new Configuration({
    basePath: API_SERVER_BASE_PATH,
    accessToken: () => {
        return getUserAccessToken();
    },
});

export const userManagerConfig: UserManagerSettings = {
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
    }/callback`,
    authority: AUTH_SERVER_PATH,
    client_id: 'rentrabbit-js',
    response_type: 'code',
    scope: 'openid email profile',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
    }/`,
};

import { useEffect, useMemo, useState } from 'react';

export const useDeferredItems = <Items>(items: Items, isLoading: boolean) => {
    const [deferredItems, setDeferredItems] = useState(items);

    useEffect(() => {
        if (!isLoading) {
            setDeferredItems(items);
        }
    }, [isLoading, items]);

    return useMemo(() => deferredItems, [deferredItems]);
};

import moment, {Moment} from "moment";

export const canSetStartDateToTargetDate = (targetDate:Moment|Date|number, startDate:Moment|Date|number|undefined, endDate:Moment|Date|number|undefined) => {
    const td = moment(targetDate).startOf('minute').valueOf();
    if(startDate && endDate){
        const sd = moment(startDate).startOf('minute').valueOf();
        const ed = moment(endDate).startOf('minute').valueOf();
        return (td < ed && sd !== td);
    }else return false;
};

export const canSetEndDateToTargetDate = (targetDate:Moment|Date|number, startDate:Moment|Date|number|undefined, endDate:Moment|Date|number|undefined) => {
    const td = moment(targetDate).startOf('minute').valueOf();
    if(startDate && endDate){
        const sd = moment(startDate).startOf('minute').valueOf();
        const ed = moment(endDate).startOf('minute').valueOf();
        return (td > sd && ed !== td);
    }else return false;
};

export const canSetStartTimeToTargetDateTime = (targetDate:Moment|Date|number, startDate:Moment|Date|number|undefined, endDate:Moment|Date|number|undefined) => {
    const h = moment(targetDate).hours();
    const m = moment(targetDate).minutes();
    const td = moment(targetDate).valueOf();
    if(startDate && endDate){
        const sd = moment(startDate).startOf('minute').valueOf();
        const nsd = moment(startDate).hours(h).minutes(m).startOf('minute').valueOf();
        const ed = moment(endDate).startOf('minute').valueOf();
        //console.log('start', moment(td).toString(), moment(sd).toString(), moment(ed).toString(), nsd < ed, sd !== nsd);
        return (nsd < ed && sd !== nsd);
    }else return false;
};

export const canSetEndDateTimeToTargetDateTime = (targetDate:Moment|Date|number, startDate:Moment|Date|number|undefined, endDate:Moment|Date|number|undefined) => {
    const h = moment(targetDate).hours();
    const m = moment(targetDate).minutes();
    const td = moment(targetDate).startOf('minute').valueOf();
    if(startDate && endDate){
        const sd = moment(startDate).startOf('minute').valueOf();
        const ed = moment(endDate).startOf('minute').valueOf();
        const ned = moment(endDate).hours(h).minutes(m).startOf('minute').valueOf();
        //console.log('end', moment(td).toString(), moment(sd).toString(), moment(ed).toString(), moment(ned).toString(), ned > sd, ed !== ned);
        return (ned > sd && ed !== ned);
    }else return false;
};

import {FormFieldsGroup, FormItemType, SelectItem} from '../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../../../localization';
import { StatusesFilter, StatusesFilterProps } from '../../../../../../components/select/StatusesFilters/v2/StatusesFilter';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../../components/select/problemsFilter';
import { ProjectStateCodeEnum, RentActivityFrameTypeCodeEnum, TagEntityTypeCode } from '../../../../../../server';
import { EntityRemoteSelect } from '../../../../../../components/select/EntityRemoteSelect';
import { LocationSelect } from '../../../../../../components/v2/select/locationSelect/locationSelect';
import { TagSelect } from '../../../../../../components/select/TagSelect/TagSelect';
import { UserSelect } from '../../../../../../components/v2/select/userSelect/userSelect';
import {getStoreState} from "../../../../../../../index";
import {
    ordersModuleEnabledSelector,
    projectsModuleEnabledSelector
} from "../../../../../../shared/reducers/businessAccountPreferences.reducer";

const searchFilter = {
    label: '',
    placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
    id: 'search',
    type: FormItemType.Search,
    className: 'rr-input-gray',
};

const typeCodeFilter = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__TYPE,
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'rentActivityFrameTypeCode',
    type: FormItemType.Select,
    style: { minWidth: 120 },
    className: 'rr-select-gray',
    values: [
        {
            name: 'Проект',
            value: RentActivityFrameTypeCodeEnum.PROJECT,
        },
        {
            name: 'Поставка',
            value: RentActivityFrameTypeCodeEnum.SUBRENT,
        },
    ],
};

const problemFilter = {
    label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
    id: 'problem',
    type: FormItemType.Component,
    style: { minWidth: 160 },
    className: 'rr-select-gray',
    component: ProblemsFilter,
    componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
        typeStatuses: 'all',
    }),
};

const statusFilter = {
    label: LocalizationEnum.ASPECT__FILTERS__STATUS,
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'typeCode',
    type: FormItemType.Component,
    component: StatusesFilter,
    componentProps: (form: WrappedFormUtils): StatusesFilterProps => {
        const state = getStoreState();
        const projectsModuleEnabled = projectsModuleEnabledSelector(state);
        const ordersModuleEnabled = ordersModuleEnabledSelector(state);

        const selectItems:StatusesFilterProps['selectItems'] = [];

        if(projectsModuleEnabled){
            selectItems.push(
                {
                    name: 'Черновик',
                    value: ProjectStateCodeEnum.DRAFT,
                },
                {
                    name: 'Согласование',
                    value: ProjectStateCodeEnum.COORDINATION,
                },
                {
                    name: LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS,
                    value: ProjectStateCodeEnum.INPROGRESS,
                },
                {
                    name: LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED,
                    value: ProjectStateCodeEnum.FINISHED,
                },
                {
                    name: 'Отменен',
                    value: ProjectStateCodeEnum.CANCELED,
                }
            );
        }

        if(projectsModuleEnabled && ordersModuleEnabled){
            selectItems.push(
                undefined
            );
        }

        if(ordersModuleEnabled){
            if(projectsModuleEnabled){
                selectItems.push(
                    {
                        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED,
                        value: ProjectStateCodeEnum.BOOKED,
                    },
                    {
                        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT,
                        value: ProjectStateCodeEnum.RENTED,
                    },
                );
            }else{
                selectItems.push(
                    {
                        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__DRAFT,
                        value: ProjectStateCodeEnum.DRAFT,
                    },
                    {
                        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED,
                        value: ProjectStateCodeEnum.BOOKED,
                    },
                    {
                        name: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT,
                        value: ProjectStateCodeEnum.RENTED,
                    },
                    {
                        name: 'Завершен',
                        value: ProjectStateCodeEnum.FINISHED,
                    },
                    {
                        name: 'Отменен',
                        value: ProjectStateCodeEnum.CANCELED,
                    }
                );
            }
        }

        return {
            selectItems
        };
    },
};

const hideFilter = {
    id: 'hide',
    label: 'Скрыть',
    placeholder: 'Не выбрано',
    type: FormItemType.Select,
    style: { minWidth: 160, maxWidth: 260 },
    className: 'rr-select-gray',
    values: [
        { name: 'Архив', value: 'archive' },
        { name: 'Отмененные', value: 'cancelled' },
        { name: 'Архив и отмененные', value: 'archive+cancelled' },
    ],
};

const locationFilter = {
    label: 'Локация',
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
    id: 'locationId',
    type: FormItemType.Component,
    style: { minWidth: 120 },
    component: LocationSelect,
};

const tagsFilter = {
    label: 'Теги',
    placeholder: 'Любые',
    id: 'tags',
    type: FormItemType.Component,
    style: { minWidth: 120 },
    component: TagSelect,
    componentProps: {
        tagType: TagEntityTypeCode.PROJECT,
    },
};

const assigneeFilter = {
    label: 'Ответственный',
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'assigneeId',
    type: FormItemType.Component,
    style: { minWidth: 120 },
    component: UserSelect,
};

const renterFilter = {
    label: 'Контрагент',
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A8,
    id: 'renterId',
    type: FormItemType.Component,
    style: { minWidth: 120 },
    component: EntityRemoteSelect,
    componentProps: () => ({
        operationName: 'listRenters',
        nameField: 'shortName',
        getEntityOperationName: 'getRenterById',
        sortBy: 'lastActivityDate',
        sortOrder: 'DESC',
        showSearch: true,
    }),
    getInitialValue: (value) => {
        return value;
    },
};

export const activityFramesCalendarFilters: FormFieldsGroup[] = [
    {
        fields: [searchFilter, typeCodeFilter, problemFilter, statusFilter, hideFilter],
    },
    {
        fields: [renterFilter, locationFilter, tagsFilter, assigneeFilter],
    },
];

export const activityFramesCalendarFiltersWithoutLogistics: FormFieldsGroup[] = [
    {
        fields: [searchFilter, typeCodeFilter, problemFilter, statusFilter, hideFilter],
    },
    {
        fields: [renterFilter, tagsFilter, assigneeFilter],
    },
];

export const activityFramesCalendarFiltersWithoutSubrent: FormFieldsGroup[] = [
    {
        fields: [searchFilter, problemFilter, statusFilter, hideFilter],
    },
    {
        fields: [renterFilter, locationFilter, tagsFilter, assigneeFilter],
    },
];

export const activityFramesCalendarFiltersWithoutLogisticsAndSubrent: FormFieldsGroup[] = [
    {
        fields: [searchFilter, problemFilter, statusFilter, hideFilter],
    },
    {
        fields: [renterFilter, tagsFilter, assigneeFilter],
    },
];

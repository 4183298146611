const CalendarOutline = {
    name: 'calendar',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: { viewBox: '0 0 32 32', focusable: false },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M 9 4 L 9 5 L 6 5 L 5 5 L 5 6 L 5 26 L 5 27 L 6 27 L 26 27 L 27 27 L 27 26 L 27 6 L 27 5 L 26 5 L 23 5 L 23 4 L 21 4 L 21 5 L 11 5 L 11 4 L 9 4 z M 7 7 L 9 7 L 9 8 L 11 8 L 11 7 L 21 7 L 21 8 L 23 8 L 23 7 L 25 7 L 25 9 L 7 9 L 7 7 z M 7 11 L 25 11 L 25 25 L 7 25 L 7 11 z M 13 13 L 13 15 L 15 15 L 15 13 L 13 13 z M 17 13 L 17 15 L 19 15 L 19 13 L 17 13 z M 21 13 L 21 15 L 23 15 L 23 13 L 21 13 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z M 13 17 L 13 19 L 15 19 L 15 17 L 13 17 z M 17 17 L 17 19 L 19 19 L 19 17 L 17 17 z M 21 17 L 21 19 L 23 19 L 23 17 L 21 17 z M 9 21 L 9 23 L 11 23 L 11 21 L 9 21 z M 13 21 L 13 23 L 15 23 L 15 21 L 13 21 z M 17 21 L 17 23 L 19 23 L 19 21 L 17 21 z'
                }
            }
        ]
    }
};
export default CalendarOutline;
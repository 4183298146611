import { IModalFormFilters } from '../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../components/modalForm/components/Header/types/displayData';
import { InstanceInfoAbstractCommons } from '../../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof InstanceInfoAbstractCommons | 'list' | 'listVariants'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['listVariants', 'nameOrCode', 'list', 'barcode']],
    },
];

export const createProductInstancesModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editProductInstancesModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

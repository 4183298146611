import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { TransportationCreateModal } from './TransportationCreateModal';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import React, { memo, useMemo } from 'react';
import { TransportationInfoRead } from '../../../../../../../../server';
import { TransportationCreateModalFormData } from './TransportationCreateModal.types';
import moment from 'moment';
import { createTransportationModalFilters } from '../../data/TransportationModalDisplayData';
import { EntityGridCreateModalWrapper } from '../../../../../../../../components/page/entityGrid/types/modal';

export const TransportationCreateModalWrapper: EntityGridCreateModalWrapper<TransportationInfoRead> = memo((props) => {
    const { rootPath, urlSearchParams, entity, ...modalProps } = props;

    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const initialValues: TransportationCreateModalFormData | {} = useMemo(() => {
        if (entity == null) return modalProps?.initialValues ?? {};

        return {
            car: entity.car?.id,
            cargo: entity.cargo,
            cargoDeliveryLocation: entity.cargoDeliveryLocation?.id,
            comment: entity.comment,
            consignee: entity.consignee?.id,
            dateTimeOfLoading: entity.dateTimeOfLoading ? moment(entity.dateTimeOfLoading) : undefined,
            dateTimeOfUnloading: entity.dateTimeOfUnloading ? moment(entity.dateTimeOfUnloading) : undefined,
            driverIds: (entity.drivers ?? []).map(({ contactId }) => contactId),
            isEdit: false,
            loadingLocation: entity.loadingLocation.id,
            project: entity.project?.id,
            receiver: entity.receiver?.contactId,
            recipient: entity.recipient?.contactId,
            requestDate: entity.requestDate ? moment(entity.requestDate) : undefined,
            shipper: entity.shipper?.id,
            transportationDate: entity.transportationDate ? moment(entity.transportationDate) : undefined,
            trailer: entity.trailer?.id,
            unloadingLocation: entity.unloadingLocation.id,
            stateCode: entity.stateCode,
            ...((modalProps?.initialValues as Partial<TransportationCreateModalFormData>) ?? {}),
        } satisfies Record<
            keyof TransportationCreateModalFormData,
            TransportationCreateModalFormData[keyof TransportationCreateModalFormData]
        >;
    }, [entity, modalProps]);

    return (
        <TransportationCreateModal
            businessAccountId={businessAccountId}
            title={'Создание новой перевозки'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            rootPath={rootPath}
            urlSearchParams={urlSearchParams}
            filters={createTransportationModalFilters}
            modalName={'create-transportation'}
            {...(modalProps ?? {})}
            initialValues={initialValues}
        />
    );
});

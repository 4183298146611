import React, { FC } from 'react';
import { LocalizationEnum, localize } from '../../../../localization';
import { Icon } from 'antd';
import { IconClose } from '../../../icons';
import './FiltersCountBlock.less';

interface FiltersCountBlockProps {
    selectedFiltersCount: number;
    resetFiltersCb: (() => void) | undefined;
}

export const FiltersCountBlock: FC<FiltersCountBlockProps> = (props) => {
    const { selectedFiltersCount, resetFiltersCb } = props;

    return (
        <div className={'filters-reset-container'}>
            <div>
                {selectedFiltersCount} {localize(LocalizationEnum.ASPECT__PLURAL__FILTER, undefined, { value: selectedFiltersCount })}
            </div>
            <div>
                <Icon
                    className={'icon'}
                    component={IconClose}
                    onClick={() => {
                        resetFiltersCb?.();
                    }}
                />
            </div>
        </div>
    );
};

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../core/utils/descriptions';
import { ProductsListFilters } from '../api/products.api.types';
import { NomenclatureStateCodeEnum } from '../../../../../server';
import { ProblemEnum } from '../../../../../types';

export const productsFiltersDescription: Required<PageURLParamDescriptionObject<ProductsListFilters>> = {
    search: PageURLParamDescriptions.searchParam(),
    hideArchive: PageURLParamDescriptions.switchParam,
    status: PageURLParamDescriptions.enumSelectParam(NomenclatureStateCodeEnum),
    hideVariants: PageURLParamDescriptions.switchParam,
    problem: PageURLParamDescriptions.enumSelectParam(ProblemEnum),
    categoryIds: PageURLParamDescriptions.numbersSelectParam,
    pricePerShift: PageURLParamDescriptions.numberInterval,
    instanceId: PageURLParamDescriptions.numbersSelectParam,
};

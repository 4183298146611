import React from 'react';
import {Drawer} from 'antd';
import {IRouterItem} from '../../../config/routes';
import {Sidebar} from './sidebar';
import './sidebarWrapper.less';

interface IProps {
    deviceType: 'phone' | 'tablet' | 'desktop';
    menuData: ReadonlyArray<IRouterItem>;
    collapsed: boolean;
    onCollapse: Function;
    width: number;
    withTopMessage: boolean;
}

export const SidebarWrapper = React.memo((props: IProps) => {
    const { menuData, collapsed, onCollapse, deviceType, width } = props;

    let style:React.CSSProperties = { padding: 0, height: '100vh' };
    let height:string = props.withTopMessage ? 'calc(100vh - 25px)' : '100vh';

    if (deviceType === 'desktop') {
        return <Sidebar height={height} width={width} collapsed={collapsed} data={menuData} onCollapse={onCollapse} />;
    } else if (deviceType === 'tablet' || deviceType === 'phone') {
        return !collapsed ? (
            <Drawer
                className={'rr-sidebar-drawer'}
                bodyStyle={{ padding: 0, marginTop: props.withTopMessage ? 25 : 0 }}
                width={240}
                visible={!collapsed}
                placement="left"
                onClose={() => onCollapse(true)}
                style={style}>
                <Sidebar height={height} width={240} collapsed={collapsed} data={menuData} onCollapse={onCollapse} />
            </Drawer>
        ) : (
            <Sidebar height={height} width={80} collapsed={collapsed} data={menuData} onCollapse={onCollapse} />
        );
    } else if (deviceType === 'phone') {
        return (
            <Drawer
                className={'rr-sidebar-drawer'}
                bodyStyle={{ padding: 0, marginTop: props.withTopMessage ? 25 : 0 }}
                width={240}
                visible={!collapsed}
                placement="left"
                onClose={() => onCollapse(true)}
                style={style}>
                <Sidebar height={height} width={240} collapsed={collapsed} data={menuData} onCollapse={onCollapse} />
            </Drawer>
        );
    } else return null;
});

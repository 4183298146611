import { LocationDrawer } from '../LocationDrawer';
import React, { memo } from 'react';
import { LocationInfoRead } from '../../../../../../../server';
import { EntityGridDrawerWrapper } from '../../../../../../../components/page/entityGrid/types/drawer';

export const LocationDrawerWrapper: EntityGridDrawerWrapper<LocationInfoRead> = memo(
    ({ entityId, onClose, refetchEntitiesList, editActionForDrawer, deleteActionForDrawer, archiveActionForDrawer, isLoading }) => {
        return (
            <LocationDrawer
                locationId={entityId}
                onClose={onClose}
                editLocation={editActionForDrawer}
                deleteLocation={deleteActionForDrawer}
                archiveLocation={archiveActionForDrawer}
                isEntityActionsLoading={isLoading}
                refetchList={refetchEntitiesList}
            />
        );
    }
);

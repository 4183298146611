import React, { FC } from 'react';
import { Col, Icon, Row, Tabs } from 'antd';
import {
    IconBoxesSolid,
    IconClockO,
    IconHourglassEndSolid,
    IconInfoSolid,
    IconShippingFastSolid,
    IconStickyNoteO,
    IconStopwatchSolid,
} from '../../../../../components/icons';
import './CalendarLegendContent.less';

const CalendarLegendContent: FC = () => {
    return (
        <div className="calendar-legend">
            <div className={`tab-popap-instance tab-popap-variant`}>
                <div className="rr-rent-indicators">
                    <Tabs type="line">
                        <Tabs.TabPane
                            disabled
                            tab={
                                <div
                                    style={{
                                        display: 'flex',
                                        height: '60px',
                                        alignItems: 'center',
                                        marginLeft: '-8px',
                                        marginRight: '-22px',
                                    }}
                                >
                                    <Icon
                                        component={IconInfoSolid}
                                        style={{ fontSize: '32px', marginLeft: '0px', color: 'rgb(82, 90, 149)' }}
                                    />
                                </div>
                            }
                            key={'icon'}
                        ></Tabs.TabPane>
                        <Tabs.TabPane
                            tab={<div style={{ height: '60px', display: 'flex', alignItems: 'center' }}>Аренда</div>}
                            key={'rent'}
                        >
                            <Row className="instance-row" gutter={62}>
                                <Col span={12} style={{ marginBottom: 30 }}>
                                    <div style={{ fontWeight: 600, marginBottom: '18px' }}>Статусы обязательств</div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line  rr-status-bg-ORDERED">
                                            <div className="dot"></div>
                                        </div>
                                        В заявке
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-BOOKED">
                                            <div className="dot"></div>
                                        </div>
                                        Бронь
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-RENT">
                                            <div className="dot"></div>
                                        </div>
                                        В аренде
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-RETURNED">
                                            <div className="dot"></div>
                                        </div>
                                        Возвращено
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-RETURNED_BROKEN">
                                            <div className="dot"></div>
                                        </div>
                                        Повреждено
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-LOST_DURING_RENT">
                                            <div className="dot"></div>
                                        </div>
                                        Утрачено
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-CANCELED">
                                            <div className="dot"></div>
                                        </div>
                                        Отменено
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line" style={{ border: '1px dashed #575962' }}>
                                            <div
                                                className="dot"
                                                style={{
                                                    border: '2px solid #575962',
                                                    marginTop: '5px',
                                                    width: '7px',
                                                    height: '7px',
                                                    borderRadius: '4px',
                                                }}
                                            ></div>
                                        </div>
                                        Черновик
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10, marginTop: 23 }} className="rr-label">
                                        <div className="line rr-status-bg-MIXED">
                                            <div className="dot"></div>
                                        </div>
                                        Разный статус (для наборов)
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ fontWeight: 600, marginBottom: '18px' }}>Просрочки</div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div
                                            className="line rr-status-bg-RENT"
                                            style={{ display: 'flex', justifyContent: 'space-between' }}
                                        >
                                            <div className="dot"></div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row-reverse',
                                                    backgroundColor: '#f4516c',
                                                    width: '15px',
                                                }}
                                            >
                                                <div
                                                    style={{ backgroundColor: '#383F77', height: '28px', width: '2px', marginTop: '-4px' }}
                                                ></div>
                                            </div>
                                        </div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                                            component={IconHourglassEndSolid}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Просрочка возврата
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line  rr-status-bg-ORDERED" style={{ border: '2px solid #FC8C2F' }}>
                                            <div className="dot" style={{ marginTop: '5px', marginLeft: '4px' }}></div>
                                        </div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                                            component={IconClockO}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Просрочка выдачи
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: '18px' }} className="rr-label">
                                        <div className="line rr-status-bg-BOOKED" style={{ border: '2px solid #FC8C2F' }}>
                                            <div className="dot" style={{ marginTop: '5px', marginLeft: '4px' }}></div>
                                        </div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                                            component={IconClockO}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Просрочка выдачи
                                    </div>

                                    <div style={{ fontWeight: 600, marginBottom: '18px' }}>Нехватки</div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line  rr-status-bg-ORDERED" style={{ border: '3px dashed #FC8C2F' }}>
                                            <div className="dot" style={{ marginTop: '4px', marginLeft: '3px' }}></div>
                                        </div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                                            component={IconStickyNoteO}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Нехватка по заявке
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="line rr-status-bg-BOOKED" style={{ border: '3px dashed rgb(244, 81, 108)' }}>
                                            <div className="dot" style={{ marginTop: '4px', marginLeft: '3px' }}></div>
                                        </div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                                            component={IconBoxesSolid}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Нехватка по брони
                                    </div>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={<span style={{ height: '60px', display: 'flex', alignItems: 'center' }}>Субаренда</span>}
                            key={'subrent'}
                        >
                            <Row className="instance-row">
                                <Col span={12} style={{ marginBottom: 30 }}>
                                    <div style={{ fontWeight: 600, marginBottom: '18px' }}>Статусы обязательств</div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="subrent-line rr-status-bg-SUBRENT_SHIPMENT_BOOKED">
                                            <div
                                                className="dot rr-status-bg-SUBRENT_SHIPMENT_BOOKED"
                                                style={{ marginTop: '4px', width: '7px', height: '7px', borderRadius: '4px' }}
                                            ></div>
                                        </div>
                                        Бронь СА
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="subrent-line rr-status-bg-SUBRENT">
                                            <div
                                                className="dot rr-status-bg-SUBRENT"
                                                style={{ marginTop: '4px', width: '7px', height: '7px', borderRadius: '4px' }}
                                            ></div>
                                        </div>
                                        Субаренда
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="subrent-line rr-status-bg-SUBRENT_RETURNED_TO_SHIPPER">
                                            <div
                                                className="dot rr-status-bg-SUBRENT_RETURNED_TO_SHIPPER"
                                                style={{ marginTop: '4px', width: '7px', height: '7px', borderRadius: '4px' }}
                                            ></div>
                                        </div>
                                        Завершено СА
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="subrent-line rr-status-bg-SUBRENT_CANCELED">
                                            <div
                                                className="dot rr-status-bg-SUBRENT_CANCELED"
                                                style={{ marginTop: '4px', width: '7px', height: '7px', borderRadius: '4px' }}
                                            ></div>
                                        </div>
                                        Отменено СА
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div className="subrent-line" style={{ border: '1px dashed #575962' }}>
                                            <div
                                                className="dot rr-status-bg-SUBRENT_DRAFT"
                                                style={{ marginTop: '5px', width: '7px', height: '7px', borderRadius: '4px' }}
                                            ></div>
                                        </div>
                                        Черновик СА
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ fontWeight: 600, marginBottom: '18px' }}>Просрочки</div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '0px',
                                                borderRadius: '10px 0px 0px 10px',
                                                borderLeft: '2px solid #f4516c',
                                                borderTop: '2px solid #f4516c',
                                                borderBottom: '2px solid #f4516c',
                                            }}
                                        >
                                            <div
                                                className="dot rr-status-bg-SUBRENT_SHIPMENT_BOOKED"
                                                style={{ marginTop: '4px', width: '7px', height: '7px', borderRadius: '4px' }}
                                            ></div>
                                        </div>
                                        <div style={{ backgroundColor: '#383F77', height: '28px', width: '2px', marginTop: '-4px' }}></div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '12px',
                                                height: '20px',
                                                marginRight: '14px',
                                                borderRadius: '0px 10px 10px 0px',
                                                borderRight: '2px solid #8b5cb1',
                                                borderTop: '2px solid #8b5cb1',
                                                borderBottom: '2px solid #8b5cb1',
                                            }}
                                        ></div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                                            component={IconShippingFastSolid}
                                            style={{ marginRight: 8 }}
                                        />
                                        Просрочка поставки субаренды
                                    </div>

                                    <div style={{ display: 'flex', marginBottom: 10 }} className="rr-label">
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '24px',
                                                height: '20px',
                                                marginRight: '0px',
                                                borderRadius: '10px 0px 0px 10px',
                                                borderLeft: '2px solid #FC8C2F',
                                                borderTop: '2px solid #FC8C2F',
                                                borderBottom: '2px solid #FC8C2F',
                                            }}
                                        >
                                            <div
                                                className="dot"
                                                style={{
                                                    marginLeft: '5px',
                                                    marginTop: '4px',
                                                    width: '7px',
                                                    height: '7px',
                                                    borderRadius: '4px',
                                                    border: '2px solid #FC8C2F',
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '18px',
                                                height: '20px',
                                                borderRadius: '0px 10px 10px 0px',
                                                backgroundColor: '#f4516c',
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                backgroundColor: '#383F77',
                                                height: '28px',
                                                width: '3px',
                                                marginTop: '-4px',
                                                marginRight: '12px',
                                            }}
                                        ></div>
                                        <Icon
                                            className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                                            component={IconStopwatchSolid}
                                            style={{ marginRight: 8 }}
                                        />
                                        Просрочка возврата субаренды поставщику
                                    </div>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default CalendarLegendContent;

import { useMemo } from 'react';

export const useSelectedRecords = <EntityRecord extends { id: number; businessVersion: number; businessAccountId: number }>(
    entitySelection: number[],
    entitiesRecords?: EntityRecord[]
) => {
    return useMemo(() => {
        return entitiesRecords?.filter((item) => entitySelection.includes(item.id)) ?? [];
    }, [entitiesRecords, entitySelection]);
};

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../../core/utils/descriptions';
import { AccessibilityMapFilters } from '../accessibility/accessibilityMapCalendarFilters.types';

export type AccessibilityElementsCalendarFilters = Pick<AccessibilityMapFilters, 'projectId' | 'subrentId' | 'renterId'>;

export const accessibilityElementsCalendarFiltersDescription: Required<
    PageURLParamDescriptionObject<AccessibilityElementsCalendarFilters>
> = {
    renterId: PageURLParamDescriptions.numberParam,
    projectId: PageURLParamDescriptions.numberParam,
    subrentId: PageURLParamDescriptions.numberParam,
};

import { getBusinessAccountId } from '../../../index';
import { ElementsCalendarFilters } from '../../modules/main/calendar/tabs/elements/filters/elementsCalendarFilters.types';
import { WorkPlanningsCalendarFilters } from '../../modules/main/calendar/tabs/workPlannings/filters/workPlanningsCalendarFilters.types';
import { ActivityFramesCalendarFilters } from '../../modules/main/calendar/tabs/activityFrames/filters/activityFramesCalendarFilters.types';
import { RentStateCodeEnum } from '../../server';
import moment from 'moment';
import { AccessibilityMapFilters } from '../../modules/main/calendar/tabs/accessibilityMap/filters/accessibility/accessibilityMapCalendarFilters.types';
import { getGridStorageData } from '../../components/grid/utils';
import { HideValue } from '../../modules/main/calendar/tabs/elements/filters/elementsCalendarFilters.data';

import { CalendarPageTabsEnum } from '../constants/tabEnums';

export type CategoriesPathProps = {
    tab: 'PRODUCT' | 'RENTER';
};

interface LinkToCalendarFromShortageProps {
    kitId?: ElementsCalendarFilters['kitId'];
    productId?: ElementsCalendarFilters['productId'];
    variantId?: ElementsCalendarFilters['variantId'];
    instanceId?: ElementsCalendarFilters['instanceId'];
    rentPeriodStartDate?: Date;
    rentPeriodEndDate?: Date;
    stateCode?: RentStateCodeEnum;
    kit?: number;
    elementId?: ElementsCalendarFilters['elementId'];
}

export const linkToCalendarFromShortage = ({
    kitId,
    productId,
    variantId,
    instanceId,
    rentPeriodStartDate,
    rentPeriodEndDate,
    elementId,
}: LinkToCalendarFromShortageProps) => {
    const from = rentPeriodStartDate ? moment(rentPeriodStartDate).valueOf() : undefined;
    const to = rentPeriodEndDate ? moment(rentPeriodEndDate).valueOf() : undefined;

    const gridData = getGridStorageData(CalendarPageTabsEnum.ACCESSIBILITY_MAP);
    const { stacks } = gridData.filters as AccessibilityMapFilters;

    const linkFromElementsParams: AccessibilityMapFilters = {
        elementId, // Подсвечивает строку в календаре
        kitId,
        productId,
        variantId,
        instanceId,
        screenLeft: from,
        screenRight: to,
        group: 'project',
        stacks,
        hide: HideValue.CANCELLED,
    };

    return linkToCalendar(linkFromElementsParams, CalendarPageTabsEnum.ACCESSIBILITY_MAP);
};

export interface ElementsCalendarPageParams extends ElementsCalendarFilters {
    kit?: number; // Развертывает родительский набор
}

export const linkToCalendar = (
    params?: ElementsCalendarPageParams | ActivityFramesCalendarFilters | WorkPlanningsCalendarFilters,
    tab?: CalendarPageTabsEnum
): string => {
    let paramsStr = `?tab=${tab ?? CalendarPageTabsEnum.ELEMENTS}`;
    if (params) {
        for (let k in params) {
            if (params[k] !== undefined && params[k] !== null) {
                if (!paramsStr) paramsStr = '?';
                else paramsStr += '&';
                paramsStr += `${k}=${params[k]}`;
            }
        }
    }
    return `/${getBusinessAccountId()}/calendar${paramsStr}`;
};

export const linkToCategories = (params?: CategoriesPathProps): string => {
    let paramsStr = '';
    if (params) {
        for (let k in params) {
            if (params[k] !== undefined) {
                if (!paramsStr) paramsStr = '?';
                else paramsStr += '&';
                paramsStr += `${k}=${params[k]}`;
            }
        }
    }
    return `/${getBusinessAccountId()}/categories${paramsStr}`;
};

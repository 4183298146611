import { FormItemType, SelectItem } from '../../../../../../../components/dynamicForm/DynamicForm';
import { ProjectStateCodeEnum, TransportationStateCodeEnum, VehicleTypeCodeEnum } from '../../../../../../../server';
import { FormUtils, TypedFormField } from '../../../../../../../core/utils/formUtils';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { Dot } from '../../../../../../../components/dot/dot';
import { getStoreState } from '../../../../../../../../index';
import { TransportationCreateModalFormData } from '../components/create/TransportationCreateModal.types';
import { transformationStateCodeMap } from '../../utils';
import { createHiddenField } from '../../../../../../../components/modalForm/utils';
import { LocationSelectAndCreate } from '../../../../../../../components/v2/select/locationSelect/locationSelectAndCreate';
import {
    ContactSelectAndCreate,
    ContactSelectAndCreateProps,
} from '../../../../../../../components/v2/select/contactSelect/contactSelectAndCreate';
import { VehicleSelectProps } from '../../../../../../../components/v2/select/vehicleSelect/vehicleSelect';
import { ProjectSelectProps } from '../../../../../../../components/v2/select/projectSelect/projectSelect';
import { ProjectSelectAndCreate } from '../../../../../../../components/v2/select/projectSelect/projectSelectAndCreate';
import {
    CounterpartySelectAndCreate,
    CounterpartySelectAndCreateProps,
} from '../../../../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate';
import {
    VehicleSelectAndCreate,
    VehicleSelectAndCreateProps,
} from '../../../../../../../components/v2/select/vehicleSelect/vehicleSelectAndCreate';

export const transportationFormFields = FormUtils.typedFormFields<TransportationCreateModalFormData>([
    {
        fields: [createHiddenField('isEdit') as TypedFormField<TransportationCreateModalFormData>],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT,
                id: 'project',
                type: FormItemType.Component,
                component: ProjectSelectAndCreate,
                componentProps: (form: WrappedFormUtils): ProjectSelectProps => ({
                    filters: {
                        stateCode: [ProjectStateCodeEnum.DRAFT, ProjectStateCodeEnum.INPROGRESS, ProjectStateCodeEnum.FINISHED],
                        archive: false,
                    },
                    getOptionProps: (record) => {
                        const isNewStatus = record.stateCode === ProjectStateCodeEnum.DRAFT;
                        const disabled = isNewStatus && form.getFieldValue('stateCode') === ProjectStateCodeEnum.INPROGRESS;
                        return {
                            disabled,
                            label: (
                                <>
                                    {isNewStatus && <Dot className={`rr-status-bg-NEW`} style={{ marginRight: 10 }} />}
                                    {record.shortName}
                                </>
                            ),
                        };
                    },
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: TransportationStateCodeEnum.PLANNED,
                required: true,
                disabled: (getFieldValue) => {
                    return Boolean(getFieldValue('isEdit'));
                },
                values: (form) => {
                    const values: SelectItem[] = [
                        {
                            name: transformationStateCodeMap[TransportationStateCodeEnum.PLANNED],
                            value: TransportationStateCodeEnum.PLANNED,
                        },
                        {
                            name: transformationStateCodeMap[TransportationStateCodeEnum.INPROGRESS],
                            value: TransportationStateCodeEnum.INPROGRESS,
                        },
                        {
                            name: transformationStateCodeMap[TransportationStateCodeEnum.FINISHED],
                            value: TransportationStateCodeEnum.FINISHED,
                        },
                    ];

                    return values.map((item) => ({
                        ...item,
                        name: (
                            <>
                                <Dot className={`rr-status-bg-` + item.value} style={{ marginRight: 10 }} /> {item.name}
                            </>
                        ),
                    }));
                },
            },
        ],
    },
    {
        title: 'Грузоотправитель',
        fields: [
            {
                label: 'Дата и время погрузки (локальное время)',
                id: 'dateTimeOfLoading',
                type: FormItemType.DateTime,
                required: true,
            },
            {
                label: 'Грузоотправитель',
                id: 'shipper',
                type: FormItemType.Component,
                component: CounterpartySelectAndCreate,
                componentProps: (): CounterpartySelectAndCreateProps => {
                    const businessAccount = getStoreState().businessAccountContacts.entity;
                    return {
                        placeholder: businessAccount ? businessAccount.fullName ?? businessAccount.shortName : '',
                    };
                },
            },
            {
                label: 'Адрес места погрузки',
                id: 'loadingLocation',
                type: FormItemType.Component,
                required: true,
                component: LocationSelectAndCreate,
            },
            {
                label: 'Лицо, осуществляющее погрузку',
                id: 'receiver',
                type: FormItemType.Component,
                component: ContactSelectAndCreate,
                componentProps: (): ContactSelectAndCreateProps => ({
                    filters: { hideArchive: true },
                }),
            },
        ],
    },
    {
        title: 'Грузополучатель',
        fields: [
            {
                label: 'Дата и время выгрузки (локальное время)',
                id: 'dateTimeOfUnloading',
                type: FormItemType.DateTime,
                required: true,
            },
            {
                label: 'Грузополучатель',
                id: 'consignee',
                type: FormItemType.Component,
                component: CounterpartySelectAndCreate,
                componentProps: (): CounterpartySelectAndCreateProps => {
                    const businessAccount = getStoreState().businessAccountContacts.entity;
                    return {
                        placeholder: businessAccount ? businessAccount.fullName ?? businessAccount.shortName : '',
                    };
                },
            },
            {
                label: 'Адрес места выгрузки',
                id: 'unloadingLocation',
                type: FormItemType.Component,
                required: true,
                component: LocationSelectAndCreate,
            },
            {
                label: 'Контакт грузополучателя',
                id: 'recipient',
                type: FormItemType.Component,
                component: ContactSelectAndCreate,
                componentProps: (): ContactSelectAndCreateProps => ({
                    filters: { hideArchive: true },
                }),
            },
            {
                label: 'Адрес доставки',
                id: 'cargoDeliveryLocation',
                type: FormItemType.Component,
                component: LocationSelectAndCreate,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Автомобиль',
                id: 'car',
                type: FormItemType.Component,
                component: VehicleSelectAndCreate,
                componentProps: (): VehicleSelectAndCreateProps => ({
                    filters: {
                        typeCode: [VehicleTypeCodeEnum.TRACTORTRUCK, VehicleTypeCodeEnum.STRAIGHTTRUCK, VehicleTypeCodeEnum.MINIBUS],
                        archive: false,
                    },
                }),
            },
            {
                label: 'Прицеп',
                id: 'trailer',
                type: FormItemType.Component,
                component: VehicleSelectAndCreate,
                componentProps: (): VehicleSelectProps => ({
                    filters: {
                        typeCode: [VehicleTypeCodeEnum.TRAILER, VehicleTypeCodeEnum.SEMITRAILER],
                        archive: false,
                    },
                }),
            },
            {
                label: 'Водители',
                id: 'driverIds',
                type: FormItemType.Component,
                component: ContactSelectAndCreate,
                componentProps: (): ContactSelectAndCreateProps => ({
                    multiple: true,
                    filters: { hideArchive: true },
                }),
            },
            {
                label: 'Груз',
                id: 'cargo',
                type: FormItemType.String,
                maxLength: 500,
            },
        ],
    },
    {
        title: 'Информация для транспортной накладной',
        fields: [
            {
                label: 'Дата транспортной накладной (локальная дата)',
                id: 'transportationDate',
                type: FormItemType.Date,
            },
            {
                label: 'Дата заявки (запроса) (локальная дата)',
                id: 'requestDate',
                type: FormItemType.Date,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);

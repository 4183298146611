import React, {CSSProperties, FC, memo} from 'react';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { getOperationStatusTypeData } from '../../../../../../operationForm/utils/utils';
import { OperationTypeCodeEnum, RentStateCodeEnum } from '../../../../../../../../server';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';
import { DashboardWidgetBase } from '../../../../../components/DashboardWidget/instances/base/DashboardWidgetBase';
import { IconCube, IconHandshake } from '../../../../../../../../components/icons';
import { DashboardData } from '../../../../../types/DashboardData';

interface DashboardRentOrderedWidgetProps {
    dashboardData: DashboardData;
    style?: CSSProperties;
    XXL?: boolean;
}

export const DashboardRentOrderedWidget: FC<DashboardRentOrderedWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const { currentOrderedSum, currentOrderedElementsCount, currentOrderedInstancesCount, loading } = dashboardData;

    return (
        <DashboardWidgetBase
            loading={loading}
            color="#04b0f1"
            title={localize(LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED)}
            elementsCount={currentOrderedElementsCount}
            elementsIcons={[IconHandshake, IconCube]}
            instancesCount={currentOrderedInstancesCount}
            icon={getOperationStatusTypeData(OperationTypeCodeEnum.ORDER).iconComponent}
            sum={currentOrderedSum}
            url={linkToCalendar({
                typeCode: [RentStateCodeEnum.ORDERED],
                group: 'project',
                hideInactive: true,
                capture: 'all',
            })}
            style={props.style}
            XXL={props.XXL}
        />
    );
});

import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { formFields } from './businessAccountPreferencesEditModalFields';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
    resetBusinessAccountPreferencesError,
    updateBusinessAccountPreferences,
} from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { AxiosPromise } from 'axios';
import { BusinessAccountPreferencesInfoRead } from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { ModalActionData } from '../../../../components/modalForm/utils';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    initialValues?: BusinessAccountPreferencesInfoRead;
}

class _BusinessAccountPreferencesEditModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    getInitialValues = () => {
        const { initialValues } = this.props;

        return initialValues
            ? {
                  ...initialValues,
                  defaultBadgeTemplateId: initialValues.defaultBadgeTemplate?.id,
              }
            : undefined;
    };

    onOk = (data) => {
        let request: AxiosPromise<BusinessAccountPreferencesInfoRead> = this.props.updateBusinessAccountPreferences(
            this.props.businessAccountId,
            {
                ...this.props.initialValues,
                ...data,
                defaultBadgeTemplateId: undefined,
            }
        ) as any;

        request
            .then((result) => {
                showNotification('success', localizeIntl(this.props.intl, LocalizationEnum.PAGE__BASIC__BASIC_SETTINGS_CHANGED)); // TODO мб выводить норм сообщение
                this.props.push(`/${this.props.businessAccountId}/settings/basic/`);
            })
            .catch((error) => {
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.PAGE__BASIC__MAIN_SETTINGS_COULD_NOT_BE_CHANGED));
            });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.push(`/${this.props.businessAccountId}/settings/basic/`);
            this.props.resetBusinessAccountPreferencesError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.businessAccountPreferences.updating,
    updatingError: storeState.businessAccountPreferences.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateBusinessAccountPreferences,
    loadCategories,
    push,
    resetBusinessAccountPreferencesError,
    updateModalFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const BusinessAccountPreferencesEditModal = connector(injectIntl(_BusinessAccountPreferencesEditModal));

import React, {CSSProperties, ReactNode} from 'react';
import {Collapse, Icon} from "antd";
import './CollapsibleBlock.css';
import {IconAngleDown, IconAngleUp} from "../../icons";
import classNames from "classnames";

export interface CollapsibleBlockProps {
    children?: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
    title?: ReactNode;
    titleStyle?: CSSProperties;
    extraContent?: ReactNode;
    style?: CSSProperties;
    className?: string;
    collapsed?: boolean;
    onCollapse?: (collapsed:boolean)=>void;
    hideCollapseIcon?: boolean;
}

export const CollapsibleBlock = ({children, titleStyle, ...props}: CollapsibleBlockProps) => {
    const componentClassName = 'rr-collapsible-block';
    const PANEL_KEY = 'panel';

    return (
        <Collapse
            accordion={true}
            style={props.style}
            className={classNames(componentClassName, props.className)}
            onChange={(key)=>{
                if(props.onCollapse) props.onCollapse(key ? false : true);
            }}
            expandIcon={(icon) => {
                return !props.disabled && !props.hideCollapseIcon && <Icon component={icon.isActive ? IconAngleUp : IconAngleDown} />;
            }}
            {...props.collapsed === undefined ? {} : {activeKey: props.collapsed ? undefined : PANEL_KEY}}
        >
            <Collapse.Panel disabled={props.disabled} header={
                <div className={'rr-collapsible-block-header'} style={!props.disabled && !props.hideCollapseIcon ? {marginLeft: 30} : undefined}>
                    {
                        props.icon && (
                            <div
                                className={props.disabled ? 'rr-collapsible-block-header-icon--disabled' : 'rr-collapsible-block-header-icon'}>
                                {props.icon}
                            </div>
                        )
                    }
                    <div className={'rr-collapsible-block-header-title'} style={titleStyle}>
                        {props.title}
                    </div>
                    <div className={'rr-collapsible-block-header-extra'}>
                        {props.extraContent}
                    </div>
                </div>
            } key={PANEL_KEY}>
                {children}
            </Collapse.Panel>
        </Collapse>
    );
};

import React, { FC, ReactNode } from 'react';
import { Col, Icon, Row } from 'antd';
import { InstanceTrackingTypeCodeEnum, VariantInfoRead } from '../../../../../../server';
import { getImagePath } from '../../../../../../shared/util/utils';
import { Avatar } from '../../../../../../components/avatar/avatar';
import { IconBoxSolid, IconNoImage, IconTagSolid } from '../../../../../../components/icons';
import { Spin } from '../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { formatMoney } from '../../../../../../shared/util/formatMoney';
import FormValue from '../../../../../../components/formValue/FormValue';
import { InventoryCurrentStateBlock } from '../../../components/InventoryCurrentStateBlock/InventoryCurrentStateBlock';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

interface ProductVariantPopoverContentDescriptionTabProps {
    entity?: VariantInfoRead;
    onLinkClick: () => void;
}

export const ProductVariantPopoverContentDescriptionTab: FC<ProductVariantPopoverContentDescriptionTabProps> = ({
    entity,
    onLinkClick,
}) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    let inventoryIcon: ReactNode;
    let inventoryLink: string | undefined;

    if (entity) {
        if (entity.tracked) {
            inventoryLink = `/${businessAccountId}/inventory/products/${entity.productId}?tab=instances&variantId=${entity.id}`;
            inventoryIcon = IconTagSolid;
        } else {
            inventoryLink = `/${businessAccountId}/inventory/products/${entity.productId}?tab=variants&mode=edit-count&id=${entity.id}`;
            inventoryIcon = IconBoxSolid;
        }
    }

    return (
        <Row type={'flex'} style={{ height: '100%' }}>
            <Col span={14} style={{ height: '100%', padding: 32 }}>
                <Row>
                    <Col span={13} style={{ marginBottom: 30 }}>
                        {entity ? (
                            entity.image ? (
                                <div style={{ border: '1px solid #ccc' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            paddingTop: '32px',
                                            maxHeight: '152px',
                                        }}
                                    >
                                        <a
                                            href={getImagePath(entity ? entity.image : undefined)}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className={'rr-product-page__main-image'}
                                                style={{ maxHeight: '152px' }}
                                                alt={''}
                                                src={getImagePath(entity ? entity.image : undefined, '-x200')}
                                            />
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    <Avatar className={'rr-avatar-medium'} style={{ backgroundColor: '#e2e5ec' }}>
                                        <Icon component={IconNoImage} />
                                    </Avatar>
                                </div>
                            )
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100px',
                                }}
                            >
                                <Spin />
                            </div>
                        )}
                    </Col>
                    <Col span={3}></Col>
                    <Col span={8}>
                        <Row>
                            <Col className={'rr-form-item'}>
                                <div className="rr-label">{localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE)}</div>
                                {entity ? (
                                    <>
                                        <div className={'rr-value'}>{entity ? entity.externalCode : ''}</div>
                                        <div
                                            style={{
                                                fontSize: '11px',
                                                color: '#aaa',
                                            }}
                                        >
                                            {entity ? (
                                                entity.hasOwnExternalCode ? null : (
                                                    <span>
                                                        ({localize(LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FIELD_VALUE_BY_PRODUCT)})
                                                    </span>
                                                )
                                            ) : null}
                                        </div>
                                    </>
                                ) : (
                                    <Spin />
                                )}
                            </Col>
                            <Col className={'rr-form-item'}>
                                <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__PRICE_PER_SHIFT)}</div>
                                {entity ? (
                                    <>
                                        <div className={'rr-value'}>{formatMoney(entity ? entity.pricePerShift : undefined)}</div>
                                        <div
                                            style={{
                                                fontSize: '11px',
                                                color: '#aaa',
                                            }}
                                        >
                                            {entity ? (
                                                entity.hasOwnPricePerShift ? null : (
                                                    <span>
                                                        ({localize(LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FIELD_VALUE_BY_PRODUCT)})
                                                    </span>
                                                )
                                            ) : null}
                                        </div>
                                    </>
                                ) : (
                                    <Spin />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} className="rr-form-item">
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}</div>
                        <div className={'rr-text'}>
                            {entity ? (
                                <FormValue
                                    value={entity.comment}
                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                />
                            ) : (
                                <Spin />
                            )}
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={10} style={{ height: '100%', padding: 32, borderLeft: '1px solid #e8e8e8' }}>
                <Row>
                    {entity && (
                        <InventoryCurrentStateBlock
                            stockInstanceCount={entity.warehousePresenceCounters.stockInstanceCount}
                            underMaintenanceInstanceCount={entity.warehousePresenceCounters.underMaintenanceInstanceCount}
                            rentedInstanceCount={entity?.warehousePresenceCounters.rentedInstanceCount}
                            subrentedInstanceCount={entity?.warehousePresenceCounters.subrentedInstanceCount}
                            availableInstanceCount={entity.timetableStackCounters.availableInstanceCount}
                            warehouseInstanceCount={entity?.warehousePresenceCounters.warehouseInstanceCount}
                            phoneMode={false}
                            inventoryIcon={inventoryIcon}
                            inventoryLink={inventoryLink}
                            inventoryLinkOnClick={onLinkClick}
                            variantId={entity.id}
                            productId={entity.productId}
                            instanceTrackingTypeCode={
                                entity?.tracked ? InstanceTrackingTypeCodeEnum.INSTANCETRACKED : InstanceTrackingTypeCodeEnum.BULK
                            }
                            timetableStackCounters={entity.timetableStackCounters}
                        />
                    )}
                </Row>
            </Col>
        </Row>
    );
};

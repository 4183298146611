import React from 'react';
import { FormFieldsGroup, FormItemType, IFormField, IFormFieldImageUpload } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../localization';

export const shortTitleField: IFormField = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
    id: 'shortName',
    required: true,
    type: FormItemType.String,
    maxLength: 30,
};

export const lastNameField: IFormField = {
    label: LocalizationEnum.ASPECT__GLOBAL__LASTNAME,
    id: 'contact.fullName.lastname',
    type: FormItemType.String,
    maxLength: 60,
    required: true,
};

export const firstNameField: IFormField = {
    label: LocalizationEnum.ASPECT__GLOBAL__FIRSTNAME,
    id: 'contact.fullName.firstname',
    type: FormItemType.String,
    maxLength: 60,
    required: true,
};

export const phoneNumberField: IFormField = {
    label: LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER,
    id: 'contact.phoneNumber',
    type: FormItemType.String,
    maxLength: 25,
};

export const emailField: IFormField = {
    label: LocalizationEnum.ASPECT__GLOBAL__EMAIL,
    id: 'contact.email',
    type: FormItemType.String,
    maxLength: 60,
    validationRules: [
        {
            // TODO Нормальное сообщение об ошибке
            pattern: '^.+@.+\\..{2,}$',
            message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
        },
    ],
};

export const fullNameLongTitleField: IFormField = {
    label: LocalizationEnum.PAGE__SETTINGS__BA_PROFILE__FIELD__FULL_NAME__LONG_TITLE,
    id: 'fullName',
    type: FormItemType.String,
    required: false,
    maxLength: 255,
};

export const commonImageField: IFormFieldImageUpload = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__IMAGE,
    id: 'imageData',
    type: FormItemType.UploadImage,
    imagesMaxCount: 1,
    infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
};

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__DESCRIPTION),
        fields: [
            shortTitleField,
            fullNameLongTitleField,
            commonImageField,
            {
                label: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__CONTACT_PERSON),
        fields: [
            lastNameField,
            firstNameField,
            {
                label: LocalizationEnum.ASPECT__GLOBAL__SECONDNAME,
                id: 'contact.fullName.secondname',
                type: FormItemType.String,
                maxLength: 60,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__POSITION,
                id: 'contact.position',
                type: FormItemType.String,
                maxLength: 60,
            },
            phoneNumberField,
            emailField,
        ],
    },
];

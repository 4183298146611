/**
 * Все типы действий в проекте доступные
 */
export enum ActionTypeEnum {
    /**
     * Удалить сущность
     */
    DELETE_ENTITY = 'DELETE_ENTITY',
    /**
     * Редактировать сущность
     */
    EDIT_ENTITY = 'EDIT_ENTITY',
    /**
     * Скрыть сущность
     */
    HIDE_ENTITY = 'HIDE_ENTITY',
    /**
     * Вернуть сущность из скрытых
     */
    SHOW_ENTITY = 'SHOW_ENTITY',
    /**
     * Загрузить файл шаблона документа
     */
    UPLOAD_DOCUMENT_TEMPLATE_FILE = 'UPLOAD_DOCUMENT_TEMPLATE_FILE',
    /**
     * Скачать файл шаблона документа
     */
    DOWNLOAD_DOCUMENT_TEMPLATE_FILE = 'DOWNLOAD_DOCUMENT_TEMPLATE_FILE',

    /**
     * Восстановить платеж
     */
    PAYMENT_RECOVER = 'PAYMENT_RECOVER',

    /**
     * Провести оплату
     */
    PAYMENT_MAKE = 'PAYMENT_MAKE',

    /**
     * Удалить платеж
     */
    PAYMENT_DELETE = 'PAYMENT_DELETE',

    /**
     * Заменить анонимные экземпляры на доступные (в попапе создания/редактирование элемента в форме операции)
     */
    REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT = 'REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT',

    /**
     * Создать повреждение (в списке обязательств в меню - Повреждение)
     */
    CREATE_DAMAGE_INVENTORY_MOVEMENT = 'CREATE_DAMAGE_INVENTORY_MOVEMENT',

    /**
     * Создать потерю (в списке обязательств в меню - Потеря)
     */
    CREATE_LOSS_INVENTORY_MOVEMENT = 'CREATE_LOSS_INVENTORY_MOVEMENT',
}

import React, { CSSProperties } from 'react';

interface IProps {
    value: any;
    emptyText?: string | React.ReactNode;
    originalValue?: any;
    style?: CSSProperties;
    onClick?: any;
    className?: string;
}

const FormValue = (props: IProps) => {
    return props.originalValue === 0 || props.value === 0 ? (
        <span onClick={props.onClick} style={props.style} className={'rr-0-value' + (props.className ? ' ' + props.className : '')}>
            {props.value}
        </span>
    ) : props.emptyText && (props.value === '' || props.value === undefined || props.value === null) ? (
        <span onClick={props.onClick} style={props.style} className={'rr-empty-value' + (props.className ? ' ' + props.className : '')}>
            {props.emptyText}
        </span>
    ) : (
        <span onClick={props.onClick} style={props.style} className={props.className}>
            {props.value}
        </span>
    );
};

export default FormValue;

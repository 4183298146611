import React from 'react';
import { CalendarURLParams } from '../../types/params';
import { SortByButton } from '../ActionsButtons/SortButton/SortByButton';
import { CalendarDisplayTypeButton } from '../ActionsButtons/DisplayTypeButton/CalendarDisplayTypeButton';
import { CalendarHeaderButtonsProps } from '../../types/props';

export const CalendarHeaderButtonsBase = <Params extends object, SortValue extends string>(
    props: CalendarHeaderButtonsProps<Params, SortValue>
) => {
    const { isLoading, sortData, sortByValue, setSortByValue, pageParamsObject, displayType, setDisplayType } = props;
    const { updatePageParams } = pageParamsObject;

    return (
        <>
            <SortByButton
                disabled={isLoading}
                sortData={sortData}
                sortValue={sortByValue}
                onSortChange={(value) => {
                    setSortByValue(value);
                    setTimeout(() => {
                        updatePageParams({
                            group: value,
                        } as CalendarURLParams<Params, SortValue>);
                    }, 100);
                }}
            />
            <CalendarDisplayTypeButton
                displayType={displayType}
                onChange={(value) => {
                    setDisplayType(value);
                    updatePageParams(
                        {
                            displayType: value,
                        } as CalendarURLParams<Params, SortValue>,
                        {
                            replaceWithoutRerender: true,
                        }
                    );
                }}
            />
        </>
    );
};

import { ContactDrawerWrapper } from '../../../../../../renters/tabs/contacts/drawer/components/wrappers/ContactDrawerWrapper';
import { VehicleDrawer } from '../../../../vehicles/drawer/components/VehicleDrawer';
import { LocationDrawerWrapper } from '../../../../locations/drawer/wrappers/LocationDrawerWrapper';
import React, { FC, memo } from 'react';

import { usePushToPages } from '../../../hooks/usePushToPages';

interface TransportationAdditionalModalsProps {
    refetchList: () => void;

    contactIdForDrawer?: number;
    contactActions: ReturnType<typeof usePushToPages>['contactActions'];
    clearContactIdForDrawer: () => void;

    vehicleIdForDrawer?: number;
    vehiclesActions: ReturnType<typeof usePushToPages>['vehiclesActions'];
    clearVehicleIdForDrawer: () => void;

    locationIdForDrawer?: number;
    locationActions: ReturnType<typeof usePushToPages>['locationActions'];
    clearLocationIdForDrawer: () => void;
}

export const TransportationAdditionalModals: FC<TransportationAdditionalModalsProps> = memo((props) => {
    const {
        refetchList,
        contactIdForDrawer,
        contactActions,
        clearContactIdForDrawer,

        vehicleIdForDrawer,
        vehiclesActions,
        clearVehicleIdForDrawer,

        locationIdForDrawer,
        locationActions,
        clearLocationIdForDrawer,
    } = props;

    return (
        <>
            {contactIdForDrawer ? (
                <ContactDrawerWrapper
                    entityId={contactIdForDrawer}
                    onClose={clearContactIdForDrawer}
                    editActionForDrawer={contactActions.edit}
                    archiveActionForDrawer={contactActions.archive}
                    deleteActionForDrawer={contactActions.delete}
                    refetchEntitiesList={refetchList}
                />
            ) : undefined}
            {vehicleIdForDrawer ? (
                <VehicleDrawer
                    vehicleId={vehicleIdForDrawer}
                    onClose={clearVehicleIdForDrawer}
                    editVehicle={vehiclesActions.edit}
                    deleteVehicle={vehiclesActions.delete}
                    archiveVehicle={vehiclesActions.archive}
                    refetchEntitiesList={refetchList}
                />
            ) : undefined}
            {locationIdForDrawer ? (
                <LocationDrawerWrapper
                    entityId={locationIdForDrawer}
                    onClose={clearLocationIdForDrawer}
                    editActionForDrawer={locationActions.edit}
                    deleteActionForDrawer={locationActions.delete}
                    archiveActionForDrawer={locationActions.archive}
                    isLoading={locationActions.isLoading}
                    refetchEntitiesList={refetchList}
                />
            ) : undefined}
        </>
    );
});

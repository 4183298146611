import {FormItemType} from '../../../../components/dynamicForm/DynamicForm';
import {FormUtils} from '../../../../core/utils/formUtils';
import {LocalizationEnum, localize} from '../../../../localization';
import React from 'react';
import {ExpenseCreateModalFormData} from './create/expenseCreateModal.types';
import debounce from 'lodash/debounce';
import {validateField} from '../../../../shared/util/validateField';
import {ExpenseStateBadge} from '../components/expenseStateBadge';
import {expensesAllStateCodes, expensesCreationStateCodes, expensesCreationStateCodesOnlyDraft} from '../data/expensesData';
import {ProjectStateCodeEnum, ProjectTypeCodeEnum} from "../../../../server";

export const expenseModalFormFields = FormUtils.typedFormFields<ExpenseCreateModalFormData>([
    {
        fields: [
            {
                id: 'id',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectId',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectStateCode',
                type: FormItemType.Hidden,
            },
            {
                id: 'projectType',
                type: FormItemType.Hidden,
            },
            {
                label: 'Наименование',
                id: 'name',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const ignoreCurrentId = form.getFieldValue('id');
                    const projectId = form.getFieldValue('projectId');
                    validateField('expense', fieldName, value, projectId, ignoreCurrentId, 'Наименование уже используется в проекте', cb);
                }, 500),
                colSpan: 24,
            },
            {
                label: 'Статус',
                id: 'state',
                type: FormItemType.Select,
                values: (form) => {
                    //console.log('DDD', form.getFieldsValue());
                    const creation = !Boolean(form.getFieldValue('id'));
                    const isNewProjectOrOffer = form.getFieldValue('projectStateCode') === ProjectStateCodeEnum.DRAFT || form.getFieldValue('projectType') === ProjectTypeCodeEnum.OFFER;
                    return (creation ? (isNewProjectOrOffer ? expensesCreationStateCodesOnlyDraft : expensesCreationStateCodes) : expensesAllStateCodes).map((item) => {
                        return {
                            name: <ExpenseStateBadge type={item} dot={true} />,
                            value: item,
                        };
                    });
                },
                disabled: (getFieldValue) => !!getFieldValue('id'),
                colSpan: 24,
            },
            //
            {
                label: 'Дата',
                id: 'date',
                type: FormItemType.DateTime,
                required: true,
                defaultValue: ()=>{
                    return new Date()
                },
                colSpan: 24,
            },
            {
                label: 'Внутренняя стоимость',
                id: 'internalFinalTotalPrice',
                type: FormItemType.Money,
                required: true,
                minLength: 0,
                defaultValue: 0,
                step: 50,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                colSpan: 24,
            },
            {
                label: 'Внешняя стоимость',
                id: 'externalFinalTotalPrice',
                type: FormItemType.Money,
                required: true,
                minLength: 0,
                defaultValue: 0,
                colSpan: 12,
                step: 50,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
            },
            {
                label: 'Облагается налогом',
                id: 'includeTaxInExternalExpenseSum',
                type: FormItemType.Switch,
                colSpan: 12,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
                colSpan: 24,
            },
        ],
    },
]);

import React from "react";
import {flexRender, Header} from "@tanstack/react-table";
import {Icon} from "antd";
import {IconAngleUp, IconClose} from "../../icons";
import {getColumnMaxWidth} from "./ReactTable_utils";

interface IProps {
    headers: Header<any, any>[];
    table?: any;
    onColumnRemove?(id?: string): void;
    onResizeModeChange(resize: boolean);
}

interface IState {
    resizeMode: boolean;
}

export class ReactTableHeader extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            resizeMode: false
        };
    }

    onMouseUp = (e) => {
        setTimeout(() => {
            this.setState({
                resizeMode: false
            });
        }, 1000);
        this.props.onResizeModeChange(false);
        window.removeEventListener('mouseup', this.onMouseUp);
    };

    componentDidMount() {
        //window.addEventListener('mouseup', this.onMouseUp);
    };

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
    };

    render() {
        const {headers, table} = this.props;
        return <div className={'tr rt-tr'}
                    style={{display: 'flex', flex: '1 0 auto'}}>
            {headers.map(header => {

                const sortOrder = header.column.getIsSorted();

                let newWidth = table.getState().columnSizingInfo.startSize + table.getState().columnSizingInfo.deltaOffset;
                let delta = table.getState().columnSizingInfo.deltaOffset;
                let maxSize = header.column.columnDef.maxSize || header.getSize();
                let minSize = header.column.columnDef.minSize || header.getSize();
                if(newWidth > maxSize) delta = maxSize - table.getState().columnSizingInfo.startSize;
                else if(newWidth < minSize) delta = minSize - table.getState().columnSizingInfo.startSize;
                const headerClassName = header.column.columnDef['headerClassName'];
                return header.column.columnDef.header ? (
                    <div key={header.id}
                         className={'th rt-th' + (header.column.getCanSort() ? ' -cursor-pointer' : '') + (sortOrder ? ' -sort-' + sortOrder : '') + (headerClassName ? ' ' + headerClassName : '')}
                         style={{
                             width: header.getSize(),
                             minWidth: header.getSize(),
                             maxWidth: header.column.getCanResize() ? header.getSize() : getColumnMaxWidth(header.column.columnDef.maxSize),//header.column.columnDef.maxSize ? header.column.columnDef.maxSize : undefined,
                             // box-sizing: border-box; flex: 58 0 auto; min-width: 58px; width: 58px; position: relative; cursor: pointer;
                             boxSizing: 'border-box',
                             position: 'relative',
                             //flex: `${header.getSize()} 0 auto`
                         }} onClick={(e) => {
                        if (!this.state.resizeMode) {
                            (header.column.getToggleSortingHandler as any)()(e);
                        }
                    }}>

                        <div>
                            <div className={'rr-grid-table-header-cell'}
                                 style={{marginLeft: (header.column.getCanSort() ? 14 + 2 : 0) + ((header.column.columnDef as any).removable ? 21 : 0)}}>
                                <div>
                                    <div>
                                        {
                                            header.isPlaceholder ? null : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        }
                                    </div>
                                </div>

                                {
                                    header.column.getCanSort() ?
                                        <div>
                                            <Icon component={IconAngleUp} className={'rr-grid-sort-icon'}/>
                                        </div> : null
                                }

                                {(header.column.columnDef as any).removable ? <div onClick={(e) => {
                                    e.stopPropagation();
                                    if (this.props.onColumnRemove) {
                                        this.props.onColumnRemove(header.column.id);
                                    }
                                }}>
                                    <Icon className={'rr-grid-table-remove-icon'}
                                          component={IconClose}/>
                                </div> : null}

                            </div>

                            {
                                header.column.getCanResize() && (
                                    <div draggable="false"
                                         role="separator"
                                         className={`resizer ${
                                             header.column.getIsResizing() ? 'isResizing' : ''
                                         }`}
                                         style={{
                                             cursor: 'col-resize',
                                             transform:
                                                 header.column.getIsResizing()
                                                     ? `translateX(${
                                                         delta + 6//table.getState().columnSizingInfo.deltaOffset
                                                     }px)`
                                                     : '',

                                         }}
                                         onMouseDown={(e) => {
                                             this.setState({
                                                 resizeMode: true
                                             });
                                             header.getResizeHandler()(e);
                                             this.props.onResizeModeChange(true);
                                             window.addEventListener('mouseup', this.onMouseUp);
                                         }}
                                        //onTouchStart={header.getResizeHandler()}
                                        // onMouseUp={(e)=>{
                                        //     this.setState({
                                        //         resizeMode: false
                                        //     });
                                        //    //e.stopPropagation();
                                        // }}

                                    />
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div key={header.id}
                         className={'th rt-th'}
                         style={{
                             width: header.getSize(),
                             minWidth: header.getSize(),
                             maxWidth: header.column.getCanResize() ? header.getSize() : getColumnMaxWidth(header.column.columnDef.maxSize),//header.column.columnDef.maxSize ? header.column.columnDef.maxSize : undefined,
                             // box-sizing: border-box; flex: 58 0 auto; min-width: 58px; width: 58px; position: relative; cursor: pointer;
                             boxSizing: 'border-box',
                             position: 'relative',
                             //flex: `${header.getSize()} 0 auto`
                         }}
                    >
                        {/*<div>*/}
                        {/*    <div className={'rr-grid-table-header-cell'}>?</div>*/}
                        {/*</div>*/}
                    </div>
                );

            })}
        </div>;
    }
}

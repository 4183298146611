import React, { CSSProperties, ReactNode } from 'react';

interface GridActionsContainerProps {
    items: ReactNode[];
    style?: CSSProperties;
}

export const IconsGroup = (props: GridActionsContainerProps): any => {
    return (
        <div style={{ display: 'inline-flex', width: '100%', ...props.style }}>
            {props.items.map((item) => {
                return (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        {item}
                    </div>
                );
            })}
        </div>
    );
};

import React, {ReactNode} from 'react';
import {Icon} from 'antd';
import {IconClose} from "../icons";
import Spin from "../spin/spin";

export interface IProps {
    header?: ReactNode;
    headerClassName?: string;
    onClose?: () => void;
    content?: ReactNode;
    footer?: ReactNode;
    spinning?: boolean;
}

export interface IState {}

export class PopoverContent extends React.PureComponent<IProps, IState> {

    render() {

        const {header, headerClassName, onClose, content, footer, spinning} = this.props;

        return (
            <Spin spinning={spinning === true}>
                {header ? <div
                    className={
                        'rr-grid-actions-popover-header' + (headerClassName ? ' ' + headerClassName : '')
                    }>
                    {header}
                    {
                        onClose ? (
                            <>
                                <div style={{ flex: '1 1 auto' }}></div>
                                <Icon style={{ fontSize: 20 }} component={IconClose} onClick={onClose} />
                            </>
                        ) : null
                    }
                </div> : null}

                <div className={'rr-grid-actions-popover-content'}>{content}</div>

                {footer ? (
                    <div className="ant-modal-footer" style={{ backgroundColor: '#f8f9fa' }}>
                        {footer}
                    </div>
                ) : null}
            </Spin>
        );
    }

}

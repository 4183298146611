import { CreateTransportationArgs, TransportationState, UpdateTransportationArgs } from './transportation.types';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { serverApi } from '../../../../../../../server';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { goBack, push } from 'connected-react-router';
import { transportationsApi } from '../../api/transportations.api';
import { getServerError } from '../../../../../../../shared/util/utils';
import { ParamsUtils } from '../../../../../../../core/utils/paramsUtils';

const ACTION_TYPES = {
    CREATE_TRANSPORTATION: 'transportation/CREATE_TRANSPORTATION',
    UPDATE_TRANSPORTATION: 'transportation/UPDATE_TRANSPORTATION',
    LOAD_TRANSPORTATION: 'transportation/LOAD_TRANSPORTATION',
    DELETE_TRANSPORTATION: 'transportation/DELETE_TRANSPORTATION',
};

export const initialState: TransportationState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createTransportationThunk = createAsyncThunk(
    ACTION_TYPES.CREATE_TRANSPORTATION,
    async (args: CreateTransportationArgs, { rejectWithValue }) => {
        const { businessAccountId, transportationInfoCreate } = args;

        try {
            return await serverApi.createTransportation(businessAccountId, transportationInfoCreate);
        } catch (error: unknown) {
            throw rejectWithValue(error);
        }
    }
);

export const createTransportation = (args: CreateTransportationArgs, rootPath: string, urlSearchParams?: URLSearchParams) => (dispatch) => {
    return dispatch(createTransportationThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Перевозка создана');
            let path = rootPath;
            if (urlSearchParams) {
                path += `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}`;
            }
            dispatch(push(path));
            dispatch(transportationsApi.util?.invalidateTags(['TransportationsList', 'Transportation']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось создать перевозку');
        });
};

export const updateTransportationThunk = createAsyncThunk(
    ACTION_TYPES.UPDATE_TRANSPORTATION,
    async (args: UpdateTransportationArgs, { rejectWithValue }) => {
        const { businessAccountId, id, transportationInfoUpdate } = args;

        try {
            return await serverApi.updateTransportationById(businessAccountId, id, transportationInfoUpdate);
        } catch (error: unknown) {
            throw rejectWithValue(error);
        }
    }
);

export const updateTransportation = (args: UpdateTransportationArgs) => (dispatch) => {
    return dispatch(updateTransportationThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Перевозка обновлена');
            dispatch(goBack());
            dispatch(transportationsApi.util?.invalidateTags(['TransportationsList', 'Transportation']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось обновить перевозку');
        });
};

const transportationSlice = createSlice({
    name: 'transportation',
    initialState,
    reducers: {
        clearTransportation: (state) => {
            state.entity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(transportationsApi.endpoints.transportationById.matchPending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addMatcher(transportationsApi.endpoints.transportationById.matchFulfilled, (state, { payload }) => {
                state.loading = false;
                state.entity = payload;
            })
            .addMatcher(transportationsApi.endpoints.transportationById.matchRejected, (state, { payload }) => {
                state.loading = false;
                state.loadingError = getServerError(payload);
            })

            .addMatcher(isAnyOf(createTransportationThunk.pending, updateTransportationThunk.pending), (state, action) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(createTransportationThunk.fulfilled, updateTransportationThunk.fulfilled), (state, action) => {
                state.updating = false;
            })
            .addMatcher(isAnyOf(createTransportationThunk.rejected, updateTransportationThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = getServerError(action.payload);
            });
    },
});

export const { clearTransportation } = transportationSlice.actions;
export default transportationSlice.reducer;

import React, {CSSProperties} from 'react';
import {AttachmentDocumentTypeCodeEnum, FileTypeCodeEnum} from "../../../server";
import {Icon} from "antd";
import classNames from "classnames";
import {CustomIconComponentProps} from "antd/lib/icon";
import {
    IconFile,
    IconFileAlt,
    IconFileArchive,
    IconFileAudio,
    IconFileExcel,
    IconFileImage,
    IconFilePdf,
    IconFilePowerpoint,
    IconFileVideo,
    IconFileWord, IconStampSolid
} from "../../../components/icons";
import './attachmentIcon.less';

interface AttachmentIconProps {
    type?: FileTypeCodeEnum;
    documentType?: AttachmentDocumentTypeCodeEnum;
    style?: CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const AttachmentIcon = ({type, documentType, ...props}: AttachmentIconProps) => {
    let component: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined;

    const iconType = documentType || type;

    let className: string | undefined = `rr-icon-attachment-${iconType}`;

    if(iconType === AttachmentDocumentTypeCodeEnum.CONTRACT) component = IconStampSolid;
    else if(iconType === FileTypeCodeEnum.EXCEL) component = IconFileExcel;
    else if(iconType === FileTypeCodeEnum.AUDIO) component = IconFileAudio;
    else if(iconType === FileTypeCodeEnum.ARCHIVE) component = IconFileArchive;
    else if(iconType === FileTypeCodeEnum.IMAGE) component = IconFileImage;
    else if(iconType === FileTypeCodeEnum.WORD) component = IconFileWord;
    else if(iconType === FileTypeCodeEnum.PDFTYPE) component = IconFilePdf;
    else if(iconType === FileTypeCodeEnum.PRESENTATION) component = IconFilePowerpoint;
    else if(iconType === FileTypeCodeEnum.TEXTDOCUMENT) component = IconFileAlt;
    else if(iconType === FileTypeCodeEnum.VIDEO) component = IconFileVideo;
    else if(iconType === FileTypeCodeEnum.OTHERTYPE) component = IconFile;

    return <Icon className={classNames('rr-icon-', className, props.className)} style={props.style} component={component} onClick={props.onClick}/>;
};

export const INITIAL_INCREMENT_FREQUENCY_TIME = 500;
export const FULL_SPEED_INCREASE_TIME = 2500;
export const MAXIMUM_SPEED_INCREASE = 5;

export const MAXIMUM_INCREMENT_FREQUENCY_TIME = INITIAL_INCREMENT_FREQUENCY_TIME / MAXIMUM_SPEED_INCREASE;

export const calculateActionIncrementDelay = (startTime: number) => {
    const currentTime = Date.now();

    const timePassed = currentTime - startTime;
    let timePassedRatio = timePassed / FULL_SPEED_INCREASE_TIME;
    if (timePassedRatio > 1) timePassedRatio = 1;

    return INITIAL_INCREMENT_FREQUENCY_TIME - (INITIAL_INCREMENT_FREQUENCY_TIME - MAXIMUM_INCREMENT_FREQUENCY_TIME) * timePassedRatio;
};

import { CalendarHeaderButtonsProps } from '../../../../components/Calendar/types/props';
import { SortByButton } from '../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { CalendarURLParams } from '../../../../components/Calendar/types/params';
import { CalendarDisplayTypeButton } from '../../../../components/Calendar/components/ActionsButtons/DisplayTypeButton/CalendarDisplayTypeButton';
import React, { FC } from 'react';
import { ElementsCalendarFilters } from '../../filters/elementsCalendarFilters.types';
import { ICalButton } from '../../../../components/Calendar/components/ActionsButtons/ICalButton/ICalButton';
import { ExcelButton } from '../../../../components/Calendar/components/ActionsButtons/ExcelButton/ExcelButton';
import { LegendButton } from '../../../../components/Calendar/components/ActionsButtons/LegendButton/LegendButton';
import { ElementsCalendarSortValue } from '../../utils/data/groupsData';

type Params = ElementsCalendarFilters;
type SortValue = ElementsCalendarSortValue;

export const ElementsCalendarHeaderButtons: FC<CalendarHeaderButtonsProps<Params, SortValue>> = (props) => {
    const { isLoading, sortData, sortByValue, setSortByValue, pageParamsObject, displayType, setDisplayType } = props;
    const { pageParams, updatePageParams } = pageParamsObject;

    return (
        <>
            <ICalButton />
            <SortByButton
                disabled={isLoading}
                sortData={sortData}
                sortValue={sortByValue}
                onSortChange={(value) => {
                    setSortByValue(value);
                    setTimeout(() => {
                        updatePageParams({
                            group: value,
                        } as CalendarURLParams<Params, SortValue>);
                    }, 100);
                }}
            />
            <ExcelButton storageKey={'calendarExcell'} params={pageParams} />
            <CalendarDisplayTypeButton
                displayType={displayType}
                onChange={(value) => {
                    setDisplayType(value);
                    updatePageParams(
                        {
                            displayType: value,
                        } as CalendarURLParams<Params, SortValue>,
                        {
                            replaceWithoutRerender: true,
                        }
                    );
                }}
            />
            <LegendButton />
        </>
    );
};

import React, { FC } from 'react';
import { TemplateInfoRead } from '../../../../../../server';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { HeaderNavigationBlock } from '../../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { IconScrollSolid } from '../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Link } from 'react-router-dom';
import { ProjectsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { ArchiveBadge } from '../../../../../../components/archiveBadge/archiveBadge';
import { projectsPageUrlRoute } from '../../../projectsPage';

interface TemplatePageHeaderNavigationBlockProps {
    template: TemplateInfoRead | undefined;
}

export const TemplatePageHeaderNavigationBlock: FC<TemplatePageHeaderNavigationBlockProps> = (props) => {
    const { template } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (template == null) return null;

    const { archive, fullName, shortName } = template;
    const name = fullName || shortName;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon className={'icon'} component={IconScrollSolid} />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${projectsPageUrlRoute}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__MAIN_MENU__RENT)}
                        </Link>
                        <span>/</span>
                        <Link to={`/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.TEMPLATES}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__TEMPLATES)}
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

import React, { FC } from 'react';
import { LocalizationEnum } from '../../../../../../../../localization';
import { Icon } from 'antd';
import { IconInfoSolid } from '../../../../../../../../components/icons';
import { HelpTooltip } from '../../../../../../../../components/helpTooltip/HelpTooltip';
import './LegendButton.less';
import CalendarLegend from '../../../../CalendarLegend/CalendarLegend';

export const LegendButton: FC = () => {
    return (
        <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A0}>
            <CalendarLegend>
                <Icon component={IconInfoSolid} className="legend-icon" />
            </CalendarLegend>
        </HelpTooltip>
    );
};

import {
    CalendarGroupsObject
} from "../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem";
import {useMemo} from "react";
import {RenterGroupLine} from "../components/GroupLine/variants/RenterGroupLine";
import {ProjectGroupLine} from "../components/GroupLine/variants/ProjectGroupLine";
import {ProfessionGroupLineWrapper} from "../components/GroupLine/variants/ProfessionGroupLine";
import {StatusGroupLine} from "../components/GroupLine/variants/StatusGroupLine";
import {WorkPlanningsSortValue} from "../utils/data/sortData";

export const useWorkPlanningsCalendarGroups = (onClick: (id: number) => void): CalendarGroupsObject<WorkPlanningsSortValue> => {
    return useMemo(
        () => ({
            renter: RenterGroupLine,
            project: ProjectGroupLine,
            profession: ProfessionGroupLineWrapper(onClick),
            status: StatusGroupLine,
        }),
        [onClick]
    );
};
import React from 'react';
import {Icon} from 'antd';
import {IconBinoculars} from '../../../../components/icons';
import {getFormattedMessageComponent} from '../../../../localization/localizationUtils';
import {LocalizationEnum} from '../../../../localization';
import {Avatar} from "../../../../components/avatar/avatar";

const NotFoundModule = ({ route, location }) => (
    <div className={'rr-grid-notfound-block'}>
        <Avatar className={'rr-avatar-big'}>
            <Icon component={IconBinoculars} />
        </Avatar>
        <div className={'rr-grid-notfound-block-title'}>
            {getFormattedMessageComponent(LocalizationEnum.PAGE__ERROR__NOT_FOUND__SHORT_MESSAGE)}
        </div>
    </div>
);

export default NotFoundModule;

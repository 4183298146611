import { CreateWorkPlanningArgs, UpdateWorkPlanningArgs, WorkPlanningState } from './workPlanning.types';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { serverApi } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { goBack, push } from 'connected-react-router';
import { workPlanningsApi } from '../../api/workPlannings.api';
import { getServerError } from '../../../../../shared/util/utils';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';
import { workPlanningsCalendarApi } from '../../../calendar/tabs/workPlannings/api/workPlanningsCalendar.api';
import { updateProjectEntity } from '../../../expenses/reducers/expense/expense.reducer';

const ACTION_TYPES = {
    CREATE_WORK_PLANNING: 'workPlanning/CREATE_WORK_PLANNING',
    UPDATE_WORK_PLANNING: 'workPlanning/UPDATE_WORK_PLANNING',
    LOAD_WORK_PLANNING: 'workPlanning/LOAD_WORK_PLANNING',
};

export const initialState: WorkPlanningState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createWorkPlanningThunk = createAsyncThunk(ACTION_TYPES.CREATE_WORK_PLANNING, async (args: CreateWorkPlanningArgs) => {
    const { businessAccountId, infoCreate } = args;
    try {
        return await serverApi.createWorkPlanning(businessAccountId, infoCreate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const createWorkPlanning =
    (args: CreateWorkPlanningArgs, rootPath: string, urlSearchParams?: URLSearchParams) => (dispatch, getState) => {
        return dispatch(createWorkPlanningThunk(args))
            .unwrap()
            .then(() => {
                showNotification('success', 'Работы созданы');
                let path = rootPath;
                if (urlSearchParams) {
                    path += `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}`;
                }
                dispatch(push(path));
                dispatch(workPlanningsApi.util?.invalidateTags(['WorkPlanningsList', 'WorkPlanning']));
                updateProjectEntity(getState, dispatch);
            })
            .catch(() => {
                showNotification('error', 'Не удалось создать работы');
            });
    };

export const updateWorkPlanningThunk = createAsyncThunk(ACTION_TYPES.UPDATE_WORK_PLANNING, async (args: UpdateWorkPlanningArgs) => {
    const { businessAccountId, id, workPlanningsInfoUpdate } = args;
    try {
        return await serverApi.updateWorkPlanning(businessAccountId, id, workPlanningsInfoUpdate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const updateWorkPlanning = (args: UpdateWorkPlanningArgs) => (dispatch, getState) => {
    return dispatch(updateWorkPlanningThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Работы обновлены');
            dispatch(goBack());
            dispatch(workPlanningsApi.util?.invalidateTags(['WorkPlanningsList', 'WorkPlanning']));
            dispatch(workPlanningsCalendarApi.util?.invalidateTags(['WorkPlanningsList']));
            updateProjectEntity(getState, dispatch);
        })
        .catch(() => {
            showNotification('error', 'Не удалось обновить работы');
        });
};

const workPlanningSlice = createSlice({
    name: 'workPlanning',
    initialState,
    reducers: {
        clearWorkPlanning: (state) => {
            state.entity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(workPlanningsApi.endpoints.getWorkPlanningById.matchPending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addMatcher(workPlanningsApi.endpoints.getWorkPlanningById.matchFulfilled, (state, { payload }) => {
                state.loading = false;
                state.entity = payload;
            })
            .addMatcher(workPlanningsApi.endpoints.getWorkPlanningById.matchRejected, (state, { payload }) => {
                state.loading = false;
                state.loadingError = getServerError(payload);
            })

            .addMatcher(isAnyOf(updateWorkPlanningThunk.pending, createWorkPlanningThunk.pending), (state, action) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(updateWorkPlanningThunk.fulfilled, createWorkPlanningThunk.fulfilled), (state, action) => {
                state.updating = false;
                if (action.payload?.data) state.entity = action.payload?.data;
            })
            .addMatcher(isAnyOf(updateWorkPlanningThunk.rejected, createWorkPlanningThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = action.error;
            });
    },
});

export const { clearWorkPlanning } = workPlanningSlice.actions;
export default workPlanningSlice.reducer;

import { LocationCreateModal } from './LocationCreateModal';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { createLocationModalFilters } from './LocationCreateModalData';
import { goBack, push } from 'connected-react-router';
import { ParamsUtils } from '../../../../../../../core/utils/paramsUtils';
import React, { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';

import { LocationInfoRead } from '../../../../../../../server';
import { EntityGridCreateModalWrapper } from '../../../../../../../components/page/entityGrid/types/modal';

export const LocationCreateModalWrapper: EntityGridCreateModalWrapper<LocationInfoRead> = memo(({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <LocationCreateModal
            businessAccountId={businessAccountId}
            title={'Создание новой локации'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            filters={createLocationModalFilters}
            modalName={'create-location'}
            onCancel={() => {
                dispatch(goBack());
            }}
            onSuccess={(data) => {
                dispatch(push(`${rootPath}${urlSearchParams ? `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}` : ''}`));
            }}
        />
    );
});

import React, {FC, ReactNode} from 'react';
import {Button, Col, Row} from "antd";
import {LocalizationEnum, localize} from "../../../../../../../localization";
import {PopoverContent} from "../../../../../../../components/popover/PopoverContent";
import {ShiftCountInput} from "../../../../../workPlannings/modal/shiftCountInput";
//import './changeElementsPricePopover.less';

interface PPCProps {
    onApply?: ()=>void;
    onCancel?: ()=>void;
    canApply?: boolean;
    width?: number;
    label: ReactNode;
    input: ReactNode;
}

export const PPC:FC<PPCProps> = ({onApply, onCancel, canApply, width, label, input}) => {

    return (
        <PopoverContent
            content={<div style={{width}}>{
                <Row>
                    <Col span={24} className="rr-form-item" style={{ marginBottom: 0, padding: 32}}>
                        <div className="rr-label" style={{ marginBottom: 10 }}>{label}</div>
                        {input}
                    </Col>
                </Row>
            }</div>}
            header={<></>}
            headerClassName={'rr-changeElementsPricePopover-header'}
            footer={
                <>
                    <Button className={'rr-btn-default'} onClick={()=>{
                        onCancel?.();
                    }}>
                        {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                    </Button>
                    <Button
                        disabled={!canApply}
                        type={'primary'}
                        onClick={()=>{
                            onApply?.();
                        }}
                    >
                        {'Применить'}
                    </Button>
                </>
            }>

        </PopoverContent>
    );
};

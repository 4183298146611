import { IModalFormFilters } from '../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../components/modalForm/components/Header/types/displayData';
import { ProductInfoRead } from '../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof ProductInfoRead | 'name' | 'usePricePerShiftProduct'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['name'], ['usePricePerShiftProduct', 'pricePerShift']],
    },
];

export const createProductVariantsModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editProductVariantsModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

import React from "react";
import './FormValue2.less';
import classNames from "classnames";

interface IProps {
    content: React.ReactNode;
    emptyContent?: React.ReactNode;
}

export const FormValue2 = (props: IProps) => {
    return (
        <div className={classNames('rr-v', !props.content && 'rr-v-empty')}>
            {
                props.content || props.emptyContent
            }
        </div>
    );
};

import { ExcelExportTemplateTypeCodeEnum, serverApi } from '../../../../../server';
import { downloadDataFromPdf } from '../../../../../shared/util/downloadDataFromExcel';
import { showNotification } from '../../../../notification/showNotification';

export const downloadPdfOffloading = async (args: {
    businessAccountId: number;
    columns: string[];
    filters: string[];
    fileName: string;
    exportTemplateType: ExcelExportTemplateTypeCodeEnum;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    additionalFilters?: string[];
    search?: string;
}) => {
    const { businessAccountId, columns, filters, sortBy, sortOrder, fileName, exportTemplateType, additionalFilters, search } = args;

    try {
        const response = await serverApi.generateExportPdf(
            businessAccountId,
            {
                columns,
                exportTemplateType,
            },
            filters.concat(additionalFilters ?? []),
            sortBy,
            sortOrder,
            search,
            {
                responseType: 'blob',
            }
        );

        downloadDataFromPdf(response.data, fileName);
    } catch (e) {
        showNotification('error', 'Экспорт в PDF выполнить не удалось');
    }
};

import React from 'react';
import { Card, Col, Icon, Row } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { AbstractEntityInfoCard, FormValue } from '../../../../components';
import { IconScrollSolid } from '../../../../components/icons';
import moment from 'moment';
import { LocalizationEnum, localize } from '../../../../localization';
import { formatPercents } from '../../../../shared/util/formatPercents';
import { RichTextView } from '../../../../components/richTextView/RichTextView';
import { Avatar } from '../../../../components/avatar/avatar';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';

interface IProps extends StateProps, DispatchProps {}

class TemplatePageDescriptionTabPane extends React.PureComponent<IProps> {
    render() {
        console.log('ProjectPageDescriptionTabPane render()');
        let { data, canViewFinancialData } = this.props;

        let targetFinishDate = data && data.targetFinishDate ? moment(data.targetFinishDate) : null;
        if (targetFinishDate) targetFinishDate.hours(0).minutes(0).seconds(0).milliseconds(0);

        let sumProject = data?.rentElementsSum || 0;
        let taxRate = 0; //data?.taxRate || 0;

        return data ? (
            <Row gutter={32}>
                {/* Левый блок */}
                <Col xxl={6} xl={8} lg={10} span={24} style={{ marginBottom: 32 }}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <div>
                            <div style={{ padding: 32, paddingTop: 30 }}>
                                <div style={{ textAlign: 'center', marginBottom: 28 }}>
                                    <Avatar className={'rr-avatar-medium-110'} style={{ backgroundColor: '#90dcff' }}>
                                        <Icon component={IconScrollSolid} />
                                    </Avatar>
                                </div>

                                <Row>
                                    <Col className="rr-form-item" style={{ marginBottom: 29 }}>
                                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                                        <h3>
                                            <FormValue
                                                value={data.shortName}
                                                emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                            />
                                        </h3>
                                    </Col>

                                    {canViewFinancialData && (
                                        <Col className="rr-form-item">
                                            <div className="rr-label">
                                                {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT)}
                                            </div>
                                            <div className="rr-value">
                                                {data.defaultDiscount === undefined || data.defaultDiscount === null ? (
                                                    <FormValue
                                                        originalValue={data.defaultDiscount}
                                                        value={
                                                            data.defaultDiscount !== undefined && data.defaultDiscount !== null
                                                                ? data.defaultDiscount + '%'
                                                                : null
                                                        }
                                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                                    />
                                                ) : (
                                                    <FormValue value={formatPercents(data.defaultDiscount)} originalValue={140} />
                                                )}
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                    </Card>
                </Col>

                {/* Правый блок блок */}
                <Col xxl={18} xl={16} lg={14} span={24}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <Row style={{ marginLeft: 8, marginRight: 8 }}>
                            <Col>
                                <Row gutter={16}>
                                    <Col>
                                        <Row style={{ padding: '32px 24px' }}>
                                            <Col span={24} className={'rr-form-item'}>
                                                <div className={'rr-label'}>
                                                    {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                                </div>
                                                <div className={'rr-text'}>
                                                    <FormValue
                                                        value={data.description && <RichTextView value={data.description} />}
                                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <AbstractEntityInfoCard leftBlockSpanLG={24} data={data} style={{ marginTop: 32 }} />
                </Col>
            </Row>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    data: storeState.template.entity,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePageDescriptionTabPane);

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {isDefined} from "../../../shared/util/utils";

export const tblColumnsMoney = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.MONEY) {
        return (
            {
                title: column.title,
                dataIndex: column.dataIndex,
                type: ColumnTypes.Money,
                minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
                resizable: false,
                className: 'money',
            }
        );
    } else {
        throw Error('error');
    }
};

import React, {FC, useCallback} from 'react';
import { CrewMembersPageFiltersDescription } from '../filters/crewMembersFiltersDescription';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { crewMembersListColumns } from '../columns/crewMembersListColumns';
import { crewMembersFilters } from '../filters/crewMembersFilters';
import { crewMembersLoadingSelector } from '../reducers/crewMembers/crewMembers.selectors';
import { useListCrewMembersQuery } from '../api/crewMembers.api';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';
import { clearCrewMember, loadCrewMember } from '../reducers/crewMember/crewMember.reducer';
import { crewMemberEntitySelector } from '../reducers/crewMember/crewMember.selectors';
import { CrewMemberCreateModalWrapper } from '../modal/components/wrappers/CrewMemberCreateModalWrapper';
import { CrewMemberEditModalWrapper } from '../modal/components/wrappers/CrewMemberEditModalWrapper';
import { useCrewMemberEntityActions } from '../hooks/useCrewMemberEntityActions';
import { CrewMemberUtils } from '../utils/helpers';
import { useSelectedRecords } from '../../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { useCrewMemberEntityActionsButtons } from '../hooks/useCrewMemberEntityActionsButtons';
import { useEntitySelection } from '../../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import {
    CrewMemberFinancialFieldsTypeCode,
    CrewMemberNotFinancialFieldsTypeCode,
    CrewMemberRecord,
    ExcelExportTemplateTypeCodeEnum
} from '../../../../../../server';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { CrewPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import {ExportOffloadingPopover} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {useIntl} from "react-intl";
import {CrewMembersApiUtils} from "../api/crewMembers.api.utils";

interface CrewMembersListComponentProps {
    gridName: CrewPageTabsEnum;
}

const CrewMembersListComponent: FC<CrewMembersListComponentProps> = (props) => {
    const { gridName } = props;
    const location = useLocation();
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const entitiesLoading = useAppSelector(crewMembersLoadingSelector);
    const currentEntity = useAppSelector(crewMemberEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: CrewMembersPageFiltersDescription,
        gridName,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListCrewMembersQuery(
        {
            businessAccountId: businessAccountId,
            params: queryParams,
        },
        {
            skip: !location.pathname.includes(gridName),
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const rootPath = CrewMemberUtils.getRootPath(businessAccountId);

    const entityActions = useCrewMemberEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<CrewMemberRecord>(entitySelection, queryData.data?.entitiesData.records);
    const entityActionsButtons = useCrewMemberEntityActionsButtons({
        selectedRecords,
        gridRef,
    });
    const excludeColumns = canViewFinancialData ? undefined : ['defaultRates.ratePerShift', 'defaultRates.ratePerHour'];

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = CrewMembersApiUtils.createRequestFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.CREWMEMBERTEMPLATE,
                fileName: 'Экспорт проектных работников',
                fileType,
                filters,
                financialFieldsEnum: CrewMemberFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: CrewMemberNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: crewMembersListColumns,
                    filtersData: crewMembersFilters,
                    pageParamsDescription: CrewMembersPageFiltersDescription,
                },
                metaData: {
                    rootPath,
                    entityType: 'crewMember',
                    gridName,
                },
                queryData,
                entitiesLoading,
                entityActions,
                actionButtons: entityActionsButtons,
                excludeColumns,
                exportBlock: <ExportOffloadingPopover storageKey={'crewMembersOffloading'} onDownload={onOffloadingDownload} />,
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: CrewMemberCreateModalWrapper,
                    },
                    edit: {
                        component: CrewMemberEditModalWrapper,
                    },
                },
                entityLoadActions: {
                    loadEntity: loadCrewMember,
                    clearEntity: clearCrewMember,
                },
            }}
        />
    );
};

export const CrewMembersList = withEntityListProviders(CrewMembersListComponent);

import { CalendarSortValue } from '../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';

export type SummaryScheduleCalendarSortValue = 'name' | 'category' | 'article' | 'status' | 'type';

export const summaryScheduleCalendarSortData: CalendarSortValue<SummaryScheduleCalendarSortValue>[] = [
    { value: 'name', title: 'Наименованию' },
    { value: 'category', title: 'Категориям (основным)' },
    { value: 'article', title: 'Артикулу' },
    { value: 'status', title: 'Статусу' },
    { value: 'type', title: 'Типу' },
];

import {StateBadgeData, StateBadgeProps, useStateBadge} from "../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../core/types/color";

export enum ModulesState {
    IN_PROGRESS = 'IN_PROGRESS'
}

export const modulesStatesMap: StateBadgeData<ModulesState> = {
    [ModulesState.IN_PROGRESS]: {
        color: Color.Azure,
        name: 'В разработке',
    }
};

export const ModuleBadge = (props: StateBadgeProps<ModulesState>) => {
    return useStateBadge()({ ...props, data: modulesStatesMap });
};

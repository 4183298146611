import {ActionButtonsData} from "../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup";
import {BasicActionsData} from "../../../../components/v2/actionButtons/actionButton/actionButton";
import {Icon} from "antd";
import {IconClose, IconRedoAltSolid} from "../../../../components/icons";
import {Color} from "../../../../core/types/color";
import React from "react";

export enum UserActionType {
    invite = 'invite',
    edit = 'edit',
    remove = 'remove'
}

export const userActionsMap: ActionButtonsData<UserActionType> = {
    [UserActionType.invite]: {
        icon: <Icon component={IconRedoAltSolid}/>,
        color: Color.Green,
    },
    [UserActionType.edit]: BasicActionsData.editAction,
    [UserActionType.remove]: {
        icon: <Icon component={IconClose}/>,
        color: Color.Red1,
    },
};

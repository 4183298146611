import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../localization';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../shared/util/validateField';
import { getStoreState } from '../../../../../index';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { createHiddenField } from '../../../../components/modalForm/utils';
import { isDefined } from '../../../../shared/util/utils';
import React from 'react';

export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('variantEdit'),
            createHiddenField('productId'),
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__NAME,
                id: 'name',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('variantEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'variant',
                        fieldName,
                        value,
                        form.getFieldValue('productId'),
                        ignoreCurrentId,
                        'Наименование варианта уже использовано в продукте',
                        cb
                    );
                }, 500),
            },
            {
                label: 'Штриховой код',
                id: 'barcode',
                type: FormItemType.String,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('variantEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'variant',
                        fieldName,
                        value,
                        form.getFieldValue('productId'),
                        ignoreCurrentId,
                        'Штриховой код уже использован',
                        cb
                    );
                }, 500),
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^[0-9a-zA-Z-_]+$',
                        message: 'Поле должно содержать только символы: A-Z, a-z, 0-9, - и _',
                    },
                ],
                infoMessage: 'Допустимы символы: A-Z, a-z, 0-9, - и _',
            },
            createHiddenField('ownExternalCode'),
            createHiddenField('productExternalCode'),
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
        fields: [
            createHiddenField('useExternalCodeProductDefault'),
            {
                label: LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FIELD_VALUE_BY_PRODUCT,
                id: 'useExternalCodeProduct',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form: WrappedFormUtils) => {
                    let externalCode: string | undefined;
                    const productExternalCode: string | undefined = form.getFieldValue('productExternalCode');
                    const ownExternalCode: string | undefined = form.getFieldValue('ownExternalCode');
                    if (value) {
                        externalCode = productExternalCode;
                        form.setFieldsValue({ externalCode });
                    } else {
                        externalCode = isDefined(ownExternalCode) ? ownExternalCode : productExternalCode;
                        form.setFieldsValue({ externalCode });
                        let interval = setInterval(() => {
                            if (value === form.getFieldValue('useExternalCodeProduct')) {
                                form.validateFields(['externalCode']);
                                clearInterval(interval);
                            }
                        }, 10);
                    }
                },
            },
            {
                id: 'externalCode',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
                disabled: (getFieldValue) => getFieldValue('useExternalCodeProduct') === true,
                onChange: (value: any, form: WrappedFormUtils) => {
                    const useExternalCodeProduct = form.getFieldValue('useExternalCodeProduct');
                    if (!useExternalCodeProduct) {
                        form.setFieldsValue({ ownExternalCode: value });
                    }
                },
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const useExternalCodeProduct = form.getFieldValue('useExternalCodeProduct');
                    if (!useExternalCodeProduct)
                        validateField(
                            'variant',
                            fieldName,
                            value,
                            form.getFieldValue('useExternalCodeProduct') ? form.getFieldValue('__externalCode') : undefined,
                            undefined,
                            'Артикул уже использован',
                            cb
                        );
                    else cb();
                }, 0),
                validateInitialValue: (form: WrappedFormUtils) => {
                    let validate =
                        !form.getFieldValue('useExternalCodeProduct') &&
                        (form.getFieldValue('useExternalCodeProductDefault') === true ||
                            form.getFieldValue('useExternalCodeProductDefault') === undefined);
                    return validate;
                },
                validationRules: [
                    {
                        max: 30,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} {30}{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__SYMBOLS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__PRICE_PER_SHIFT),
        visible: (form) => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p);
        },
        fields: [
            createHiddenField('ownPricePerShift'),
            createHiddenField('productPricePerShift'),
            {
                label: LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FIELD_VALUE_BY_PRODUCT,
                id: 'usePricePerShiftProduct',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form: WrappedFormUtils) => {
                    let pricePerShift: number | undefined;
                    const productPricePerShift = form.getFieldValue('productPricePerShift');
                    const ownPricePerShift = form.getFieldValue('ownPricePerShift');
                    if (value) {
                        pricePerShift = productPricePerShift;
                    } else {
                        pricePerShift = isDefined(ownPricePerShift) ? ownPricePerShift : productPricePerShift;
                    }
                    form.setFieldsValue({ pricePerShift });
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return !canUpdateFinancialData(p);
                },
            },
            {
                id: 'pricePerShift',
                type: FormItemType.Money,
                step: 50,
                minLength: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return getFieldValue('usePricePerShiftProduct') === true || !canUpdateFinancialData(p);
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ ownPricePerShift: value });
                },
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FORMS__MAIN_IMAGE,
                id: 'image',
                type: FormItemType.UploadImage,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
                imagesMaxCount: 1,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

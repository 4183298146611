import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { exportSettingsSelector } from '../../../../shared/reducers/userSettings/userSettings.selectors';
import { useCallback, useEffect, useMemo } from 'react';
import { ExportSettings, updateExportSettings } from '../../../../shared/reducers/userSettings/userSettings.reducer';

export const useDocumentLocalStorage = (
    storageKey: string,
    entityRemoteSelectCustomParams: any
): [documentsLocalStorage: ExportSettings, setDocumentsLocalStorage: (value: ExportSettings) => void] => {
    const dispatch = useAppDispatch();
    const documentsLocalStorageSelector = exportSettingsSelector(storageKey);

    const initialDocumentsLocalStorage = useMemo(
        () => ({
            params: [] as string[],
            discount: undefined,
            template: entityRemoteSelectCustomParams?.defaultTemplateId
                ? {
                      key: entityRemoteSelectCustomParams.defaultTemplateId,
                      label: '',
                  }
                : undefined,
            period: undefined,
            legalDetail: undefined,
        }),
        [entityRemoteSelectCustomParams?.defaultTemplateId]
    );

    const documentsLocalStorage = useAppSelector(documentsLocalStorageSelector) ?? initialDocumentsLocalStorage;
    const setDocumentsLocalStorage = useCallback(
        (value: ExportSettings) => {
            dispatch(
                updateExportSettings({
                    [storageKey]: value,
                })
            );
        },
        [dispatch, storageKey]
    );

    useEffect(() => {
        if (!documentsLocalStorage.template && entityRemoteSelectCustomParams?.defaultTemplateId) {
            setDocumentsLocalStorage({
                ...documentsLocalStorage,
                template: {
                    key: entityRemoteSelectCustomParams?.defaultTemplateId,
                    label: '',
                },
            });
        }
    }, [documentsLocalStorage, entityRemoteSelectCustomParams?.defaultTemplateId, setDocumentsLocalStorage]);

    return [documentsLocalStorage, setDocumentsLocalStorage];
};

import {BaseQueryFn} from "@reduxjs/toolkit/dist/query/react";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";

export const axiosBaseQuery =
    (
        {baseUrl}: { baseUrl: string } = {baseUrl: ''}
    ): BaseQueryFn<{
        url: string
        method: AxiosRequestConfig['method']
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params']
    },
        AxiosResponse, AxiosError> =>
        async ({url, method, data, params}) => {
            try {
                const result = await axios({url: baseUrl + url, method, data, params});
                return {data: result.data};
            } catch (axiosError) {
                let err = axiosError as AxiosError;
                return {
                    error: err
                };
            }
        };
import React, { FC } from 'react';
import { SideBarGroup } from '../../utils/types';
import { workPlanningsCalendarSortValueToItemName } from '../../../../../../../../utils/data/sortData';
import { WorkPlanningsSplittedCalendarSidebarGroup } from '../../WorkPlanningsSplittedCalendarSidebarGroup';
import { ConditionalLink } from '../../../../../../../../../../../../../components/lib/ConditionalLink/ConditionalLink';
import { IconGraduationCapSolid } from '../../../../../../../../../../../../../components/icons';

interface ProfessionSidebarGroupProps extends SideBarGroup {}

export const ProfessionSidebarGroup =
    (setChosenProfessionId: (value: React.SetStateAction<number | undefined>) => void): FC<ProfessionSidebarGroupProps> =>
    (props) => {
        const { isCollapsed, onClick, workPlannings, displayType } = props;

        const titleKey = workPlanningsCalendarSortValueToItemName['profession'];
        const { professionId } = workPlannings[0];

        const title = (
            <ConditionalLink
                className={'link'}
                condition={false}
                onClick={(e) => {
                    e.stopPropagation();
                    setChosenProfessionId(professionId);
                }}
            >
                {workPlannings[0][titleKey]}
            </ConditionalLink>
        );

        return (
            <WorkPlanningsSplittedCalendarSidebarGroup
                workPlannings={workPlannings}
                displayType={displayType}
                onClick={onClick}
                isCollapsed={isCollapsed}
                iconComponent={IconGraduationCapSolid}
                title={title}
                subtitle={undefined}
            />
        );
    };

import React, { memo } from 'react';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { goBack, push } from 'connected-react-router';
import { ParamsUtils } from '../../../../../../../core/utils/paramsUtils';
import { VehicleCreateModalNew } from './VehicleCreateModalNew';
import { VehicleInfoRead } from '../../../../../../../server';
import { EntityGridCreateModalWrapper } from '../../../../../../../components/page/entityGrid/types/modal';

export const VehicleCreateModalWrapper: EntityGridCreateModalWrapper<VehicleInfoRead> = memo(
    ({ rootPath, urlSearchParams, ...modalProps }) => {
        const dispatch = useAppDispatch();

        return (
            <VehicleCreateModalNew
                onSuccess={(data) => {
                    dispatch(
                        push(`${rootPath}${urlSearchParams ? `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}` : ''}`)
                    );
                }}
                onCancel={() => {
                    dispatch(goBack());
                }}
                {...(modalProps ?? {})}
            />
        );
    }
);

import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import moment from 'moment';
import { IconCashRegisterSolid } from '../../../../components/icons';
import { tableCellMoneyRenderer } from '../../../../components/grid/renderers/tableCellMoneyRenderer';
import { paymentsUtils } from '../../payments/utils/paymentsUtils';
import { PaymentRecordList, PaymentStateCodeEnum, serverApi } from '../../../../server';
import { LinkToPaymentsProps } from '../../../../components/link/toPayments/LinkToPayments';
import './lastPaymentsSchemePopover.less';
import { ExportBillPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/ExportBillPopover';
import { PriceIcon } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/components/PriceIcon/PriceIcon';
import { onBillDownload } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/utils/onBillDownload';
import { isPaymentCanDownloadBill } from '../../../../components/exportPopovers/downloadDocument/instances/ExportBillPopover/utils/isPaymentCanDownloadBill';

interface IProps extends PopoverProps {
    businessAccountId: number;
    link?: string;
    params: LinkToPaymentsProps['params'];
}

interface IState {
    operationsData?: PaymentRecordList;
    visible: boolean;
}

export class LastPaymentsSchemePopover extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    async getLastOperations() {
        const payments = await serverApi.listPayments(this.props.businessAccountId, 5, 0, undefined, 'lastUpdateDate', 'DESC', undefined, {
            query: {
                filters: getLastOperationsListFilters(this.props.params),
            },
        });
        return payments.data;
    }

    render() {
        const { businessAccountId, link, ...popoverProps } = this.props;
        const { visible } = this.state;
        const operationsData = this.state?.operationsData;

        const isPaymentsHasDownloadBill = operationsData?.records.some((record) => isPaymentCanDownloadBill(record)) ?? false;

        return (
            <Popover
                visible={visible}
                onVisibleChange={async (visible) => {
                    if (visible) {
                        const operationsData = await this.getLastOperations();
                        this.setState({
                            operationsData,
                        });
                        this.setState({
                            visible: operationsData?.records.length > 0,
                        });
                    } else {
                        this.setState({
                            visible: false,
                        });
                    }
                }}
                overlayClassName={'rr-OperationsScheme__popover-content-container'}
                content={
                    operationsData &&
                    operationsData?.records.length > 0 && (
                        <div onClick={(e) => e.stopPropagation()}>
                            <div className={'rr-OperationsScheme__popover-content'}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon className={'financialIcon'} component={IconCashRegisterSolid} />
                                    <span>Последние платежи</span>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {operationsData.records.map((record) => {
                                        const isPaymentDeleted = record.stateCode === PaymentStateCodeEnum.DELETED;

                                        return (
                                            <div
                                                key={record.id}
                                                className={'rr-OperationsScheme__popover-content-item'}
                                                style={{
                                                    minWidth: isPaymentsHasDownloadBill ? 420 : 370,
                                                }}
                                            >
                                                <div>
                                                    <span>{moment(record.date).format('DD.MM.YY HH:mm')}</span>
                                                </div>
                                                <div>
                                                    <div
                                                        className={'rr-OperationsScheme__popover-balance-change-amount'}
                                                        style={{
                                                            color: record.balanceChangeAmount > 0 ? '#34bfa3' : '#f4516c',
                                                        }}
                                                    >
                                                        {tableCellMoneyRenderer(record.balanceChangeAmount)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>{paymentsUtils.getPaymentStatusByCode(record.stateCode)}</span>
                                                </div>
                                                {record.incoming && record.projectPayment ? (
                                                    <ExportBillPopover
                                                        visible={visible}
                                                        disabled={isPaymentDeleted}
                                                        onDownload={async ({ fileType, templateId, isCommonTemplate, legalDetailId }) =>
                                                            await onBillDownload(
                                                                this.props.businessAccountId,
                                                                record.id,
                                                                fileType,
                                                                templateId || 0,
                                                                isCommonTemplate,
                                                                legalDetailId
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            style={{
                                                                height: 24,
                                                            }}
                                                        >
                                                            <PriceIcon disabled={isPaymentDeleted} />
                                                        </span>
                                                    </ExportBillPopover>
                                                ) : isPaymentsHasDownloadBill ? (
                                                    <div style={{ width: 24, flex: 'none' }} />
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                                {link && (
                                    <div>
                                        <span>
                                            {operationsData.listAttributes.filteredCount > 5 && (
                                                <Link to={link} className={'rr-OperationsScheme__popover-content-link'}>
                                                    Смотреть все{' '}
                                                    <b>
                                                        (Ещё {operationsData.listAttributes.filteredCount - operationsData.records.length})
                                                    </b>
                                                </Link>
                                            )}
                                            {operationsData.listAttributes.filteredCount <= 5 && (
                                                <Link to={link} className={'rr-OperationsScheme__popover-content-link'}>
                                                    Смотреть список платежей
                                                </Link>
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
                {...popoverProps}
            >
                {this.props.children}
            </Popover>
        );
    }
}

const getLastOperationsListFilters = (params: LinkToPaymentsProps['params']): string[] => {
    if (!params) return [];

    let filters: string[] = [];

    if ('counterpartyId' in params) filters.push('counterpartyId;EQ;' + params.counterpartyId);
    if ('projectId' in params) filters.push('projectId;EQ;' + params.projectId);
    if ('subrentId' in params) filters.push('subrentId;EQ;' + params.subrentId);

    filters.push('stateCode;IN;' + PaymentStateCodeEnum.DONE + ';' + PaymentStateCodeEnum.PLANNED);

    return filters;
};

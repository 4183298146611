import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { DocumentTemplateInfoCreate, DocumentTemplateInfoUpdate } from '../../../../server/api';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { formFields } from './documentTemplatesEditModalFields';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { createEntity, reset, updateEntity } from './reducers/documentTemplate.reducer';
import { IntlShape } from 'react-intl';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { loadEntities } from './reducers/documentTemplates.reducer';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    goBackPath: string;
    intl: IntlShape;
    entityId?: number;
}

class DocumentTemplatesEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = async (data: any) => {
        if (this.props.initialValues) {
            // Редактирование
            const newData: DocumentTemplateInfoUpdate = {
                ...this.props.initialValues,
                ...data,
                hidden: false,
            } as DocumentTemplateInfoUpdate;

            delete newData['file'];
            delete newData['common'];
            if (data.comment === '') delete newData['file'];
            let result = await this.props.updateEntity(
                this.props.businessAccountId,
                data.common,
                newData.id || 0,
                data.file?.uid ? data.file : null,
                newData
            );
            if (!(result instanceof Error)) {
                showNotification('success', 'Шаблон успешно изменен');
                this.props.push(this.props.goBackPath);
                this.props.loadEntities(this.props.businessAccountId);
            }
        } else {
            // Создание
            const newData: DocumentTemplateInfoCreate = {
                shortName: data.shortName,
                documentType: data.documentType,
                financial: data.financial || false,
                comment: data.comment,
                hidden: false,
            };
            let res = await this.props.createEntity(this.props.businessAccountId, data.common || false, data.file, newData);
            if (!(res instanceof Error)) {
                showNotification('success', 'Шаблон создан');
                this.props.push(this.props.goBackPath);
                this.props.loadEntities(this.props.businessAccountId);
                this.props.reset();
            }
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                localizeIntl(
                    this.props.intl,
                    this.props.initialValues ? LocalizationEnum.ASPECT__MODAL__ABORT_EDIT : LocalizationEnum.ASPECT__MODAL__ABORT_CREATION
                )
            ));
        if (yes) {
            this.props.push(this.props.goBackPath);
            this.props.reset();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.documentTemplate.updating || storeState.documentTemplate.loading,
    updatingError: storeState.documentTemplate.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = { createEntity, updateEntity, push, loadEntities, reset, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplatesEditModal);

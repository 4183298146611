import React, { FC } from 'react';
import { SubrentInfoRead } from '../../../../../../server';
import { HeaderNavigationBlock } from '../../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { IconDollySolid } from '../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Link } from 'react-router-dom';
import { rentersPageUrlRoute } from '../../../../renters/data/tabs';
import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import classNames from 'classnames';
import { ArchiveBadge } from '../../../../../../components/archiveBadge/archiveBadge';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { Status } from '../../../../../../components/status/status';

interface SubrentPageHeaderNavigationBlockProps {
    subrent: SubrentInfoRead | undefined;
}

export const SubrentPageHeaderNavigationBlock: FC<SubrentPageHeaderNavigationBlockProps> = (props) => {
    const { subrent } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (subrent == null) return null;

    const { stateCode, archive, supplierId, supplierShortName, fullName, shortName } = subrent;
    const name = fullName || shortName;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon
                            className={'icon'}
                            component={IconDollySolid}
                            style={{
                                color: '#ffb822',
                            }}
                        />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                        <Status entityType={'subrent'} code={stateCode} />
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES)}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${supplierId}`}
                            className={classNames('link', 'bold')}
                        >
                            {supplierShortName}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${supplierId}?tab=shippings`}
                            className={'link'}
                        >
                            Поставки
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

import UsersModule from './users/usersModule';
import { BusinessAccountProfileModule } from './businessAccountProfile/businessAccountProfileModule';
import CustomFieldsModule from './customFields/customFieldsModule';
import PricingSchemesModule from './pricingSchemes/pricingSchemesModule';
import { IRouterItem } from '../../../config/routes';
import { LocalizationEnum } from '../../../localization';
import { BusinessAccountPreferencesModule } from './businessAccountPreferences/businessAccountPreferencesModule';
import { BusinessAccountPermissionCodeEnum, GlobalPermissionCodeEnum } from '../../../server';
import DocumentTemplatesModule from './documentTemplates/documentTemplatesModule';
import {ModulesModule} from "./modules/modulesModule";
import {PaymentMethodsModule} from "./paymentMethods/paymentMethodsModule";

export const settingsRoutes: Array<IRouterItem> = [
    {
        menuKey: 'basic',
        title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BASIC,
        path: '/:businessAccountId/settings/basic',
        component: BusinessAccountPreferencesModule,
        sortOrder: 0,
    },
    {
        menuKey: 'users',
        title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__USERS,
        path: '/:businessAccountId/settings/users',
        component: UsersModule,
        sortOrder: 0,
    },
    {
        menuKey: 'businessAccountProfile',
        title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BA_PROFILE,
        path: '/:businessAccountId/settings/businessAccountProfile',
        component: BusinessAccountProfileModule,
        sortOrder: 0,
        childs: [
            {
                menuKey: 'description',
                title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__DESCRIPTION,
                path: '/:businessAccountId/settings/businessAccountProfile#description',
                component: BusinessAccountProfileModule,
                sortOrder: 0,
            },
            {
                menuKey: 'contact',
                title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__CONTACT_PERSON,
                path: '/:businessAccountId/settings/businessAccountProfile#contact',
                component: BusinessAccountProfileModule,
                sortOrder: 0,
            },
            {
                menuKey: 'legalAttributes',
                title: 'Реквизиты',
                path: '/:businessAccountId/settings/businessAccountProfile#legalAttributes',
                component: BusinessAccountProfileModule,
                sortOrder: 0,
            },
        ],
    },
    {
        menuKey: 'documentTemplates',
        title: 'Шаблоны документов',
        menuPath: '/:businessAccountId/settings/documentTemplates',
        path: [
            '/:businessAccountId/settings/documentTemplates/:id/edit',
            '/:businessAccountId/settings/documentTemplates/new',
            '/:businessAccountId/settings/documentTemplates',
        ],
        component: DocumentTemplatesModule,
        sortOrder: 0,
    },
    {
        menuKey: 'customFields',
        title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__CUSTOM_FIELDS,
        menuPath: '/:businessAccountId/settings/customFields',
        path: [
            '/:businessAccountId/settings/customFields/:id/edit',
            '/:businessAccountId/settings/customFields/new',
            '/:businessAccountId/settings/customFields',
        ],
        component: CustomFieldsModule,
        sortOrder: 0,
    },
    {
        menuKey: 'pricingSchemes',
        title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__PRICING_SCHEMES,
        menuPath: '/:businessAccountId/settings/pricingSchemes',
        path: [
            '/:businessAccountId/settings/pricingSchemes/:id/edit',
            '/:businessAccountId/settings/pricingSchemes/new',
            '/:businessAccountId/settings/pricingSchemes',
        ],
        component: PricingSchemesModule,
        sortOrder: 0,
        permissions: [GlobalPermissionCodeEnum.VIEWFINANCIALDATA, BusinessAccountPermissionCodeEnum.VIEWFINANCIALDATA]
    },
    {
        menuKey: 'paymentMethods',
        title: 'Способы оплаты',
        menuPath: '/:businessAccountId/settings/paymentMethods',
        path: [
            '/:businessAccountId/settings/paymentMethods',
        ],
        component: PaymentMethodsModule,
        sortOrder: 0,
        permissions: [GlobalPermissionCodeEnum.VIEWFINANCIALDATA, BusinessAccountPermissionCodeEnum.VIEWFINANCIALDATA]
    },
    {
        menuKey: 'modules',
        title: LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__MODULES,
        menuPath: '/:businessAccountId/settings/modules',
        path: [
            '/:businessAccountId/settings/modules/:id/edit',
            '/:businessAccountId/settings/modules/new',
            '/:businessAccountId/settings/modules'
        ],
        component: ModulesModule,
        sortOrder: 0,
    }
];

import React from 'react';
import { Card, Icon } from 'antd';
import { initialParamsState, loadTemplates, reset, setArchiveState } from './reducers/templates.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { push, replace } from 'connected-react-router';
import RoundButton from '../../../../components/button/roundButton';
import { Spin } from '../../../../components';
import {
    convertAvailableFiltersToObj,
    getPathFromState,
    getStateFromPath2,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../../shared/util/utils';
import { ProjectRecord, serverApi } from '../../../../server';
import every from 'lodash/every';
import templatesColumns from './templatesListColumns';
import { Location } from 'history';
import { filters } from './templatesListFilters';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { LocalizationEnum, localize } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid } from '../../../../components/grid/Grid';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';
import { IconArchive, IconLevelUp } from '../../../../components/icons';
import { IFormField } from '../../../../components/dynamicForm/DynamicForm';
import { PageUtils } from '../../../../shared/util/pageUtils';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { EntityActionType } from '../../../../../index';
import { saveCurrentTabToStore } from '../../../../shared/reducers/userSettings/userSettings.reducer';
import { GridName } from '../../../../components/grid/utils/types';
import { TemplatePageTabsEnum } from '../../../../shared/constants/tabEnums';

interface IState {
    selection: number[];
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    renterId?: number;
    gridName: GridName;
}

class Component extends React.Component<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters;
    private initialValues;
    private availableFilters;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['search', 'rentElementsSum', 'hideArchive', 'startDate', 'sortBy', 'sortOrder'];

    constructor(props: IProps) {
        super(props);
        this.state = {
            selection: [],
        };
        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = async () => {
        this.initialValues = this.props.pageParams;
        await this.getPriceShift();

        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    };

    componentDidUpdate = (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(this.props.gridName);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadProjects();
        if (!this.fromFilters && this.filtersForm) {
            const values = { ...initialParamsState, ...this.props.pageParams };
            if (!values.rentElementsSum && this.availableFilters) {
                if (this.availableFilters.rentElementsSum) {
                    values.rentElementsSum = [
                        this.availableFilters.rentElementsSum[0] / 100,
                        this.availableFilters.rentElementsSum[1] / 100,
                    ];
                }
            }
            this.filtersForm.setFieldsValue(values);
        }
        this.fromFilters = false;
    };

    componentWillUnmount = () => {
        this.props.reset();
    };

    getPriceShift = async () => {
        try {
            const availableFiltersValues = await serverApi.listTemplatesAvailableFiltersValues(this.props.businessAccountId);
            this.availableFilters = convertAvailableFiltersToObj(availableFiltersValues.data.filters);
            const filter = this.availableFilters.rentElementsSum;

            if (filter) {
                let field = (filters[0].fields as IFormField[]).find((item) => item.id === 'rentElementsSum');
                if (field) {
                    const min = roundMinFilterValue(filter[0] / 100);
                    const max = roundMaxFilterValue(filter[1] / 100);
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min,
                        max,
                    };
                    if (!this.props.pageParams.rentElementsSum) {
                        this.filtersForm.setFieldsValue({ rentElementsSum: [filter[0] / 100, filter[1] / 100] });
                    }
                }
            }
        } catch (e) {
            console.error(e);
            if (!this.props.pageParams.rentElementsSum && this.filtersForm) {
                this.filtersForm.setFieldsValue({ rentElementsSum: [0, 1000] });
            }
        }
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onItemAction = (item: ProjectRecord, action: EntityActionType) => {
        const callback = (
            {
                edit: () => {
                    saveCurrentTabToStore('template', TemplatePageTabsEnum.DESCRIPTION);
                    this.props.push(`/${this.props.businessAccountId}/projects/templates/${item.id}`);
                    this.props.push(`/${this.props.businessAccountId}/projects/templates/${item.id}/edit?tab=description`);
                },
                archive: () => {
                    this.props.setArchiveState(this.props.intl, this.props.businessAccountId, [
                        {
                            id: item.id,
                            businessVersion: item.businessVersion,
                            archive: !item.archive,
                        },
                    ]);
                },
                copy: () => {
                    saveCurrentTabToStore('template', TemplatePageTabsEnum.DESCRIPTION);
                    this.props.push(`/${this.props.businessAccountId}/projects/templates/${item.id}`);
                    this.props.push(`/${this.props.businessAccountId}/projects/templates/${item.id}/copy?tab=description`);
                },
                createProjectFromTemplate: () => {
                    saveCurrentTabToStore('template', TemplatePageTabsEnum.DESCRIPTION);
                    this.props.push(`/${this.props.businessAccountId}/projects/templates/${item.id}`);
                    this.props.push(
                        `/${this.props.businessAccountId}/projects/templates/${item.id}/createProjectFromTemplate?tab=description`
                    );
                },
            } satisfies Partial<Record<EntityActionType, () => void>>
        )[action];

        if (callback) callback();
    };

    loadProjects = async () => {
        await this.props.loadTemplates(this.props.intl, this.props.businessAccountId);
    };

    onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                limit: size,
                page: 1,
            })
        );
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    onArchiveBtnClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => ({ id: item.id, businessVersion: item.businessVersion, archive: !item.archive }));
            this.props.setArchiveState(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    getActionButtons = () => {
        let selectedItems = this.props.entities
            ? this.props.entities.filter((item: any) => {
                  return this.state.selection.indexOf(item.id) > -1;
              })
            : [];
        return [
            every(selectedItems, (item) => !item.archive && item.archivable) ? (
                <RoundButton
                    key={3}
                    title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            every(selectedItems, (item) => item.archive) ? (
                <RoundButton
                    key={4}
                    title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
        ];
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        if (data.rentElementsSum) {
            if (
                data.rentElementsSum.toString() ===
                [
                    Math.round(this.availableFilters.rentElementsSum[0] / 100),
                    Math.round(this.availableFilters.rentElementsSum[1] / 100),
                ].toString()
            ) {
                data.rentElementsSum = undefined;
            }
        }

        if (data.startDate) {
            const startDate = data.startDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!startDate[0] && !startDate[1]) data.startDate = undefined;
            else if (!startDate[1]) data.startDate = [startDate[0]];
            else data.startDate = startDate;
        }

        if (data.hideArchive === false) data.hideArchive = undefined;

        data.page = 1;

        this.fromFilters = true;
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            rentElementsSum: undefined,
            hideArchive: undefined,
            startDate: undefined,
        };
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
    };

    render() {
        const { entities, templatesLoading, filteredCount, canViewFinancialData } = this.props;
        const excludeColumns = canViewFinancialData ? [] : ['defaultDiscount', 'rentElementsSum'];
        const excludeFilters = canViewFinancialData ? [] : ['rentElementsSum'];

        return (
            <Spin spinning={templatesLoading}>
                <Card bordered={false}>
                    <Grid
                        filtersResetFiltersCb={this.resetFilters}
                        filtersCurrentValues={this.props.pageParams}
                        filtersDefaultValues={initialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersData={filters}
                        filtersInitialValues={this.initialValues}
                        filtersExcludeFields={this.props.renterId === undefined ? excludeFilters : [...excludeFilters, 'renterId']}
                        excludeColumns={this.props.renterId === undefined ? excludeColumns : [...excludeColumns, 'renterShortName']}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        onRowAction={this.onItemAction}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        filtered={filteredCount}
                        pageSize={this.props.pageParams.limit}
                        currentPage={this.props.pageParams.page}
                        columns={templatesColumns}
                        data={entities}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        entityType={'template'}
                        defaultSorted={this.props.pageParams.sortBy}
                        defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                        hideArchive={this.props.pageParams.hideArchive}
                        gridName={this.props.gridName}
                    />
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.templates.entities,
        templatesLoading: storeState.templates.loading,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.templates.params,
        filteredCount: storeState.templates.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        locationPathname: storeState.router.location.pathname,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    };
};

const mapDispatchToProps = { loadTemplates, reset, push, replace, setArchiveState };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const TemplatesList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));

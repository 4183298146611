import React from 'react';
import {StateBadgeData} from "../../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../../core/types/color";
import {ProjectStateCodeEnum} from "../../../../../server";
import {LocalizationEnum} from "../../../../../localization";

export type ProjectStateCode = ProjectStateCodeEnum.DRAFT | ProjectStateCodeEnum.RENTED | ProjectStateCodeEnum.BOOKED | ProjectStateCodeEnum.INPROGRESS | ProjectStateCodeEnum.FINISHED | ProjectStateCodeEnum.CANCELED | ProjectStateCodeEnum.COORDINATION;

export const projectEntityStateBadgeData: StateBadgeData<(ProjectStateCode)> = {
    [ProjectStateCodeEnum.INPROGRESS]: {
        color: Color.Orange1,
        name: 'В работе',
    },
    [ProjectStateCodeEnum.FINISHED]: {
        color: Color.Green,
        name: 'Завершен',
    },
    [ProjectStateCodeEnum.CANCELED]: {
        color: Color.Grey2,
        name: 'Отменен',
    },
    [ProjectStateCodeEnum.DRAFT]: {
        color: Color.WhiteGrey1,
        localizationId: LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT
    },
    [ProjectStateCodeEnum.RENTED]: {
        color: Color.Orange1,
        localizationId: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT
    },
    [ProjectStateCodeEnum.BOOKED]: {
        color: Color.Purple3,
        localizationId: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED
    },
    [ProjectStateCodeEnum.COORDINATION]: {
        color: Color.Azure,
        name: 'Согласование'
    },
};

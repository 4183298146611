import {useAppSelector} from '../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../shared/reducers/system.reducer';
import {OfferCreateModal} from './offerCreateModal';
import {LocalizationEnum, localize} from '../../../../../localization';
import React, {FC, memo, useMemo} from 'react';
import {ProjectInfoRead, ProjectStateCodeEnum} from '../../../../../server';
import {OfferCreateModalFormData} from './offerCreateModal.types';
import {ModalFormNEWProps} from '../../../../../components/modalForm/ModalFormNEW';
import {createOfferModalFilters} from '../offerModalFormData';
import {crewModuleEnabledSelector, expensesModuleEnabledSelector} from "../../../../../shared/reducers/businessAccountPreferences.reducer";

type CreateModalWrapperProps = {
    entity?: ProjectInfoRead;
    projectId?: number;
} & Partial<ModalFormNEWProps>;

export const OfferCreateModalWrapper: FC<CreateModalWrapperProps> = memo(({ entity, projectId, ...modalProps }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const crewModuleEnabled = useAppSelector(crewModuleEnabledSelector);
    const expensesModuleEnabled = useAppSelector(expensesModuleEnabledSelector);

    const initialValues: OfferCreateModalFormData = useMemo(() => {
        if (entity == null) return {
            mainProjectId: projectId,
            shortName: '',
            partOfThePrice: false,
            stateCode: ProjectStateCodeEnum.COORDINATION
        };

        const copyParams:OfferCreateModalFormData['copyParams'] = ['copyRentElements'];
        if(crewModuleEnabled) copyParams.push('copyWorkPlannings');
        if(expensesModuleEnabled) copyParams.push('copyExpenses');

        return {
            sourceProjectId: entity.id,
            shortName: entity.shortName,
            fullName: entity.fullName,
            renterId: entity.renterId,
            mainProjectId: entity.mainProjectId,
            partOfThePrice: entity.partOfThePrice,
            stateCode: ProjectStateCodeEnum.DRAFT,
            assigneeId: entity.assigneeId,
            useMainProjectAssignee: !entity.hasOwnAssignee,
            description: entity.description,
            comment: entity.comment,
            copyParams: copyParams,
            copy: true
        };

    }, [entity, modalProps]);

    return (
        <OfferCreateModal
            {...(modalProps ?? {})}
            initialValues={initialValues}
            businessAccountId={businessAccountId}
            title={'Создание сметы'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            validateAfterCreate={Boolean(entity)}
            filters={createOfferModalFilters}
            modalName={'create-offer'}
        />
    );
});

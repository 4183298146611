import { FC } from 'react';

export const DashboardWidgetHeaderRedLine: FC = () => {
    return (
        <div
            style={{
                backgroundColor: 'rgb(244, 81, 108)',
                width: 12,
            }}
        />
    );
};

import React from 'react';
import { ProductCalendarPopover } from '../../../dev/OperationFormProductCalendarPopover';
import { TimetableTypeCodeEnum } from '../../../../../server';
import { Col, Icon } from 'antd';
import { InventoryCurrentStateBlockProps } from './InventoryCurrentStateBlock';
import classNames from 'classnames';
import { IconProps } from 'antd/lib/icon';

interface InventoryCurrentStateBlockItemProps
    extends Pick<InventoryCurrentStateBlockProps, 'phoneMode' | 'productId' | 'variantId' | 'instanceId'> {
    title: string;
    typeCode: TimetableTypeCodeEnum;
    icon: IconProps['component'];
    iconClass: string;
    countElement?: JSX.Element;
    scheduleCountElement?: JSX.Element;
    isLast?: boolean;
}

export const InventoryCurrentStateBlockItem: React.FC<InventoryCurrentStateBlockItemProps> = ({
    title,
    phoneMode,
    productId,
    variantId,
    instanceId,
    icon,
    iconClass,
    typeCode,
    countElement,
    scheduleCountElement,
    isLast = false,
}) => {
    return (
        <div
            className={classNames({
                ['rr-product-page__main-indicators-block-item1-container-offset']: !isLast,
            })}
        >
            <Col
                span={phoneMode ? 8 : 24}
                className={'rr-product-page__main-indicators-block-item1'}
                style={{
                    marginBottom: isLast || scheduleCountElement ? undefined : 17,
                }}
            >
                <>
                    <div className={'rr-product-page__main-indicators-block-item1-label'}>{title}</div>
                    <ProductCalendarPopover productId={productId} variantId={variantId} instanceId={instanceId} typeCode={typeCode}>
                        <div style={{ display: 'flex', alignItems: 'center' }} className={'rr-product-page__main-indicators-link'}>
                            <Icon component={icon} className={classNames('rr-product-page__main-indicators-block-item1-icon', iconClass)} />
                            <span className={'rr-product-page__main-indicators-block-item1-value'}>{countElement}</span>
                        </div>
                    </ProductCalendarPopover>
                </>
            </Col>
            {scheduleCountElement && (
                <div className={'rr-product-page__main-indicators-block-item1-schedule'}>
                    По расписанию: <span>{scheduleCountElement}</span>
                </div>
            )}
        </div>
    );
};

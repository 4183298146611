import { createApi } from '@reduxjs/toolkit/query/react';
import { DeleteVehicleArgs, DeleteVehiclesArgs, ListVehiclesArgs, ListVehiclesData, TransitVehiclesArgs } from './vehicles.api.types';
import { AxiosError } from 'axios';
import { VehiclesUtils } from './vehicles.api.utils';
import { GetEntityByIdArgs } from '../../../../../../components/page/entityGrid/types/api';
import { serverApi, VehicleInfoRead } from '../../../../../../server';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export const getVehicleByIdQueryFunction = async ({ businessAccountId, entityId }: GetEntityByIdArgs) => {
    try {
        return await serverApi.getVehicleById(businessAccountId, entityId);
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить транспорт');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const vehiclesApi = createApi({
    reducerPath: 'vehiclesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['VehiclesList', 'Vehicle'],
    endpoints: (builder) => ({
        listVehicles: builder.query<ListVehiclesData, ListVehiclesArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = VehiclesUtils.createRequestFilters(params);
                const sortBy = params.sortBy || undefined;
                const sortOrder = params.sortBy ? params.sortOrder : undefined;

                try {
                    const [{ data: vehiclesListAvailableFilters }, { data: vehicles }] = await Promise.all([
                        serverApi.listVehiclesAvailableFilters(businessAccountId),
                        serverApi.listVehicles(businessAccountId, params.search, params.limit, startFrom, sortBy, sortOrder, filters),
                    ]);

                    return {
                        data: {
                            entitiesData: vehicles,
                            availableIntervalFiltersData: {
                                values: vehiclesListAvailableFilters,
                                maps: {
                                    metrics: VehiclesUtils.vehiclesIntervalMapFunction,
                                    formats: VehiclesUtils.vehiclesIntervalFormatStyleMap,
                                },
                            },
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['VehiclesList'],
        }),
        vehicleById: builder.query<VehicleInfoRead, GetEntityByIdArgs>({
            queryFn: getVehicleByIdQueryFunction,
            providesTags: ['Vehicle'],
        }),
        deleteVehicles: builder.mutation<void, DeleteVehiclesArgs>({
            queryFn: async (args, { getState }) => {
                const { versionedEntityObjList } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteVehicles(businessAccountId, versionedEntityObjList);
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1
                            ? 'Транспортные средство успешно удалены'
                            : 'Транспортное средство успешно удалено';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1
                            ? 'Не удалось удалить транспортные средства'
                            : 'Не удалось удалить транспортное средство';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['VehiclesList', 'Vehicle'],
        }),
        deleteVehicle: builder.mutation<void, DeleteVehicleArgs>({
            queryFn: async (args, { getState }) => {
                const { id, businessVersionObj } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteVehicleById(businessAccountId, id, businessVersionObj);
                    showNotification('success', 'Транспортное средство удалено');

                    return { data: undefined };
                } catch (error: unknown) {
                    showNotification('error', 'Транспортное средство не удалено');

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['VehiclesList', 'Vehicle'],
        }),
        archiveVehicles: builder.mutation<void, TransitVehiclesArgs>({
            queryFn: async (args, { getState }) => {
                const { workflowDirectiveBulk } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitVehicleWorkflowBulk(businessAccountId, workflowDirectiveBulk);
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Транспортные средства успешно изменены'
                            : 'Транспортное средство успешно изменено';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Не удалось изменить транспортные средства'
                            : 'Не удалось изменить транспортное средство';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['VehiclesList', 'Vehicle'],
        }),
    }),
});

export const {
    useListVehiclesQuery,
    useVehicleByIdQuery,
    useArchiveVehiclesMutation,
    useDeleteVehiclesMutation,
    useDeleteVehicleMutation,
} = vehiclesApi;

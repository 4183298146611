import React, { useState } from 'react';
import { Icon } from 'antd';
import RoundButton from '../../../../components/button/roundButton';
import { IconUploadSolid } from '../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../localization';
import { UploadDocumentTemplateFile } from '../../../../components/upload/uploadDocumentTemplateFile';
import { DocumentTypeCodeEnum } from '../../../../server/api';
import { UploadFile } from 'antd/lib/upload/interface';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';

interface Props {
    onChange: any;
    disabled?: boolean;
    documentType?: DocumentTypeCodeEnum;
    value?: any;
}

export const DocumentTemplatesEditModalUploadFile = (props: Props) => {
    const [file, setFile] = useState(props.value as undefined | UploadFile);

    return (
        <div>
            <UploadDocumentTemplateFile
                file={file}
                onChange={(file) => {
                    props.onChange(file);
                    setFile(file);
                }}
                documentType={props.documentType}
                showUploadList={false}
            >
                <RoundButton disabled={props.disabled} colorScheme={'default'}>
                    <Icon component={IconUploadSolid} /> {localize(LocalizationEnum.ASPECT__FORMS__UPLOAD_FILE, 'span')}
                </RoundButton>
            </UploadDocumentTemplateFile>
            {file && (
                <div style={{ display: 'flex', marginTop: 18, alignItems: 'center' }}>
                    <div
                        style={{
                            borderRadius: 4,
                            background: '#e2e2e2',
                            paddingLeft: 20,
                            paddingRight: 20,
                            minWidth: 378,
                            display: 'flex',
                            alignItems: 'center',
                            height: 38,
                        }}
                    >
                        <SystemIcon type={SystemIconType.attachment} style={{ marginRight: 12 }} />
                        {file.name}
                    </div>
                    <SystemIcon
                        style={{ marginLeft: 15 }}
                        type={SystemIconType.delete}
                        onClick={() => {
                            props.onChange(undefined);
                            setFile(undefined);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

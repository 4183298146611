export abstract class TagsUtils {
    static format(tag: string): string {
        return tag.trim().toLowerCase();
    }
    static includes(tag: string, search: string): boolean {
        return TagsUtils.format(tag).includes(TagsUtils.format(search));
    }
    static compare(tag1: string, tag2: string): boolean {
        return TagsUtils.format(tag1) === TagsUtils.format(tag2);
    }
}

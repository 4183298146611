import { ListCrewMembersParams } from './types';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { CrewMemberRecord } from '../../../../../../server';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../../../components/page/entityGrid/types/api';
import { MoneyUtils } from '../../../../../../core/utils/moneyUtils';

export class CrewMembersApiUtils {
    static createRequestFilters = (params: ListCrewMembersParams) => {
        return ServerUtils.createRequestFilters<CrewMemberRecord>([
            params.hideArchive ? ['archive', 'EQ', false] : undefined,
            params.stateCode ? ['stateCode', 'EQ', params.stateCode] : undefined,
            params.professionId
                ? ['professionIds', 'IN', Array.isArray(params.professionId) ? params.professionId : [params.professionId]]
                : undefined,
            params.ratePerShift?.[0] != null && ['defaultRates.ratePerShift', 'GE', MoneyUtils.toCents(params.ratePerShift[0])],
            params.ratePerShift?.[1] != null && ['defaultRates.ratePerShift', 'LE', MoneyUtils.toCents(params.ratePerShift[1])],
            params.ratePerHour?.[0] != null && ['defaultRates.ratePerHour', 'GE', MoneyUtils.toCents(params.ratePerHour[0])],
            params.ratePerHour?.[1] != null && ['defaultRates.ratePerHour', 'LE', MoneyUtils.toCents(params.ratePerHour[1])],
            params.id ? ['id', 'IN', Array.isArray(params.id) ? params.id : [params.id]] : undefined,
        ]);
    };
    static pricePerShiftIntervalMetricsMap: IntervalFiltersMetricsMap<CrewMemberRecord['defaultRates']> = {
        ratePerShift: (value) => value / 100,
        ratePerHour: (value) => value / 100,
    };
    static pricePerShiftIntervalFormatMap: IntervalFiltersFormatsMap<CrewMemberRecord['defaultRates']> = {
        ratePerShift: 'currency',
        ratePerHour: 'currency',
    };
}

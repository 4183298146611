import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import './support-page.less';
import { Col, Row, Tabs } from 'antd';
import { SUPPORT_SERVER_BASE_PATH } from '../../../config/config';
import { push } from 'connected-react-router';
import Spin from '../../../components/spin/spin';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { TelegramLink } from '../../../components/pageHeader/components/TelegramLink/TelegramLink';
import { SupportPageTabsEnum } from '../../../shared/constants/tabEnums';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

interface IState {
    framePortalHeight: number;
    frameRequestsHeight: number;
    frameIsLoading: boolean;
}

export const iframeBaseParams: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = {
    frameBorder: 0,
    width: '100%',
};

class SupportPage extends BasePage<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            framePortalHeight: 0,
            frameRequestsHeight: 0,
            frameIsLoading: true,
        };
        this.props.resetOperationForm();
    }

    componentDidMount() {
        window.addEventListener('message', this.handleIframeTask);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeTask);
    }

    frameOnLoad = (e) => {
        this.setState((state, props) => {
            let newState = { ...state, frameIsLoading: false };
            if (props.match.path === '/:businessAccountId/support') {
                if (!newState.framePortalHeight) newState.framePortalHeight = 100;
            } else {
                if (!newState.frameRequestsHeight) newState.frameRequestsHeight = 100;
            }
            return newState;
        });
    };

    handleIframeTask = (e: MessageEvent) => {
        if (e.data) {
            let { type } = e.data;
            if (type) {
                console.log(e.data);
                if (type === 'sd_portal_resized') {
                    this.setState({ framePortalHeight: e.data.height });
                } else if (type === 'sd_requests_resized') {
                    this.setState({ frameRequestsHeight: e.data.height });
                } else if (type === 'sd_portal_create_request') {
                    this.props.push(`/${this.props.businessAccountId}/support/requests/create/${e.data.request_type_id}`);
                } else if (type === 'sd_view_wiki') {
                    this.props.push(`/${this.props.businessAccountId}/kb/${e.data.page_id}`);
                } else if (type === 'sd_requests_view') {
                    this.props.push(`/${this.props.businessAccountId}/support/requests/${e.data.request_id}`);
                }
            }
        }
    };

    onTabChange = (activeKey: string) => {
        if (activeKey === SupportPageTabsEnum.PORTAL) this.props.push(`/${this.props.businessAccountId}/support`);
        else if (activeKey === SupportPageTabsEnum.REQUESTS) this.props.push(`/${this.props.businessAccountId}/support/requests`);
        this.setState({ frameIsLoading: true, framePortalHeight: 0, frameRequestsHeight: 0 });
    };

    override getPageHeaderProps(): IPageHeaderProps {
        return {
            rightBlock: <TelegramLink />,
        };
    }

    renderContent() {
        const activeTab =
            this.props.match.path === '/:businessAccountId/support' ? SupportPageTabsEnum.PORTAL : SupportPageTabsEnum.REQUESTS;

        return (
            <Row type="flex" justify="center">
                <Col span={24} style={{ maxWidth: activeTab === SupportPageTabsEnum.PORTAL ? 840 : 990 }}>
                    <Tabs
                        activeKey={activeTab}
                        onChange={this.onTabChange}
                        className={'rr-tabs'}
                        type="line"
                        animated={false}
                        destroyInactiveTabPane={true}
                    >
                        <Tabs.TabPane tab={'Создать новый запрос'} key={SupportPageTabsEnum.PORTAL} style={{ maxWidth: 840 }}>
                            <Spin delay={0} spinning={this.state.frameIsLoading} wrapperClassName={'rr-support-page-spin'}>
                                <iframe
                                    {...iframeBaseParams}
                                    title={'Создать новый запрос'}
                                    onLoad={this.frameOnLoad}
                                    src={`${SUPPORT_SERVER_BASE_PATH}/plugins/servlet/ru.rentrabbit.jira.jira-plugin/iframe/${this.props.userUUID}/${this.props.businessAccountId}/portal?os_authType=none`}
                                    height={this.state.framePortalHeight}
                                />
                            </Spin>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Список запросов компании'} key={SupportPageTabsEnum.REQUESTS} style={{ maxWidth: 990 }}>
                            <Spin delay={0} spinning={this.state.frameIsLoading} wrapperClassName={'rr-support-page-spin'}>
                                <iframe
                                    {...iframeBaseParams}
                                    title={'Список запросов компании'}
                                    onLoad={this.frameOnLoad}
                                    src={`${SUPPORT_SERVER_BASE_PATH}/plugins/servlet/ru.rentrabbit.jira.jira-plugin/iframe/${this.props.userUUID}/${this.props.businessAccountId}/requests?os_authType=none`}
                                    height={this.state.frameRequestsHeight}
                                />
                            </Spin>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = { push, resetOperationForm };

const mapStateToProps = (storeState: IRootState, ownProps: IBasePageProps) => ({
    businessAccountId: storeState.system.businessAccountId,
    userUUID: storeState.oidc.user ? storeState.oidc.user.profile.sub : '',
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);

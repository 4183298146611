import { useContext } from 'react';
import { gridRefContextDefaultValue, useEntityGridContext } from '../components/context/GridRefContext';
import { EntitiesSelectionContext, entitiesSelectionContextDefaultValue } from '../components/context/EntitiesSelectionContext';
import { withEntityListProviders } from '../components/decorators/withEntityListProviders';

export const useCheckEnabledProviders = () => {
    const gridRefContext = useEntityGridContext();
    const entitiesSelectionContext = useContext(EntitiesSelectionContext);

    if (
        process.env.NODE_ENV === 'development' &&
        entitiesSelectionContext === entitiesSelectionContextDefaultValue &&
        gridRefContext === gridRefContextDefaultValue
    ) {
        throw new Error(`Экземпляр EntityList не обёрнут декоратором ${withEntityListProviders.name}`);
    }
};

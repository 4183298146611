import { EntityDrawer } from '../../../../../../components/v2/entityDrawer/EntityDrawer';
import { AttachmentParentEntityTypeCodeEnum, LocationInfoRead } from '../../../../../../server';
import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useLocationByIdQuery } from '../api/locations.api';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../components/dataDisplay';
import { AttachmentsBlock } from '../../../../../../components/attachmentsBlock/attachmentsBlock';
import { DrawerUtils } from '../../../../../../shared/util/drawerUtils';
import { LocationDrawerTitle } from './LocationDrawerTitle';
import { useDisplayItems } from './LocationDrawerHooks';
import { ContactsBlock } from '../../../../../../components/contactsBlock/contactsBlock';
import { deleteContactFromLocation } from '../reducers/location/location.reducer';

export interface LocationDrawerProps {
    className?: string;
    locationId: number;
    onClose: () => void;
    refetchList?: () => void;
    editLocation?: (location: LocationInfoRead) => void;
    deleteLocation?: (location: LocationInfoRead) => Promise<void>;
    archiveLocation?: (location: LocationInfoRead) => void;
    isEntityActionsLoading?: boolean;
}

const emptyValueText = 'Не указано';

export const LocationDrawer: FC<LocationDrawerProps> = ({
    locationId,
    onClose,
    editLocation,
    deleteLocation,
    archiveLocation,
    refetchList,
    className,
    isEntityActionsLoading,
}) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);
    const dispatch = useAppDispatch();

    const {
        data: location,
        error,
        isFetching,
        isLoading: isEntityLoading,
        refetch,
    } = useLocationByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: locationId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const { coordinatesDisplayItem } = useDisplayItems(location);

    const isLoading = Boolean(isEntityLoading || isEntityActionsLoading);

    return (
        <EntityDrawer
            className={className}
            title={
                <LocationDrawerTitle
                    location={location}
                    onClose={onClose}
                    archiveLocation={archiveLocation}
                    deleteLocation={deleteLocation}
                    editLocation={editLocation}
                    isLoading={isLoading || isFetching}
                />
            }
            onClose={onClose}
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
        >
            {location && (
                <>
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE),
                                    type: DataDisplayItemType.String,
                                    value: location.shortName,
                                    emptyValueText,
                                },
                                coordinatesDisplayItem,
                            ],
                            [
                                {
                                    label: 'Адрес',
                                    type: DataDisplayItemType.Link,
                                    value: {
                                        text: location.address,
                                        link: 'https://yandex.ru/maps/?text=' + location.address,
                                    },
                                    emptyValueText,
                                },
                            ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Количество перевозок',
                                    type: DataDisplayItemType.Number,
                                    value: location.transportationCount,
                                    emptyValueText,
                                },
                                {
                                    label: 'Количество проектов',
                                    type: DataDisplayItemType.Link,
                                    value: location.projectCount
                                        ? {
                                              text: '' + location.projectCount,
                                              link: `/${businessAccountId}/projects/production?locationId=${location.id}`,
                                              isRoute: true,
                                              onClick: () => {
                                                  onClose();
                                              },
                                          }
                                        : undefined,
                                    emptyValueText: location.projectCount === 0 ? '0' : emptyValueText,
                                },
                            ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <ContactsBlock
                                            data={location.contacts ?? []}
                                            parentEntityId={location.id!}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.LOCATION}
                                            deleteContact={async (contactId) => {
                                                await dispatch(
                                                    deleteContactFromLocation({
                                                        businessAccountId: location.businessAccountId,
                                                        locationId: location.id,
                                                        locationInfoUpdate: location,
                                                        contactId: contactId,
                                                    })
                                                );
                                                refetch();
                                                refetchList?.();
                                            }}
                                        />
                                    ),
                                },
                            ],
                        ]}
                        paddings={false}
                    />

                    <DataDisplayBlock
                        paddings={false}
                        data={[
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <AttachmentsBlock
                                            attachments={location.attachments?.records ?? []}
                                            parentEntityId={location.id!}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.LOCATION}
                                            updateParentEntity={() => {
                                                refetch();
                                                refetchList?.();
                                            }}
                                        />
                                    ),
                                },
                            ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Дата последнего изменения',
                                    type: DataDisplayItemType.DateWithTime,
                                    value: location.lastUpdateDate,
                                    emptyValueText,
                                },
                                {
                                    label: 'Автор последнего изменения',
                                    type: DataDisplayItemType.Link,
                                    value: {
                                        text: location.lastUpdateAuthorFullName
                                            ? DrawerUtils.getFullName(location.lastUpdateAuthorFullName)
                                            : undefined,
                                        link: `/${businessAccountId}/users/${location.lastUpdateAuthorId}`,
                                        isRoute: true,
                                    },
                                    emptyValueText,
                                },
                            ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Комментарий',
                                    type: DataDisplayItemType.Comment,
                                    value: location.comment,
                                    emptyValueText,
                                },
                            ],
                        ]}
                    />
                </>
            )}
        </EntityDrawer>
    );
};

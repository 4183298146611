import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { showConfirm } from '../../../components/confirm/showConfirm';
import { ModalFormNEW, ModalFormNEWProps } from '../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../components/dynamicForm/DynamicForm';
import { formFields } from './user-profile-description-fields';
import { LocalizationEnum, localizeIntl } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../shared/reducers';
import { resetError, updateProfile } from './reducers/user-profile.reducer';
import { LocaleCodeEnum, UserInfoRead, UserInfoUpdate } from '../../../server';
import { showNotification } from '../../../components/notification/showNotification';
import userManager from '../../../config/userManager';
import { rrLogout } from '../../../shared/util/rrLogout';
import { userProfilePopover } from '../../../components/userPopover/reducers/userPopover.reducer';
import { updateModalFilters } from '../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
}

class _UserProfileDescriptionEdit extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = async (d: any) => {
        if (this.props.userProfile) {
            const formData = d as UserInfoRead;
            const data: UserInfoUpdate = {
                firstName: formData.fullName.firstname,
                lastName: formData.fullName.lastname,
                locale: formData.locale as LocaleCodeEnum,
                email: formData.email,
                phoneNumber: formData.phoneNumber !== '' ? formData.phoneNumber : undefined,
            } as any;
            const prevProfile = this.props.userProfile;

            let yes = true;
            if (prevProfile.email !== data.email) {
                yes = await showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__COMFIRM_CHANGE_EMAIL)
                );
            }
            if (yes) {
                let res = await this.props.updateProfile(this.props.intl, data);

                if (!(res instanceof Error)) {
                    showNotification('success', 'Профиль пользователя был изменен');
                    this.props.goBack();
                    this.props.resetError();
                    this.props.userProfilePopover(this.props.intl);

                    if (prevProfile.email !== data.email) {
                        rrLogout();
                    } else {
                        if (prevProfile.locale !== data.locale) {
                            await userManager.signinSilent();
                            await userManager.removeUser();
                        }
                    }
                } else {
                    // Если надо будет обработать ошибку
                }
            }
        }
    };

    getInitialValues = () => {
        return { ...this.props.userProfile, locale: this.props.userProfile ? this.props.userProfile.locale.toUpperCase() : undefined };
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.businessAccount.entity?.id || 0,
    user: storeState.oidc.user,
    updating: storeState.userProfile.updating,
    updatingError: storeState.userProfile.updatingError,
    userProfile: storeState.userProfile.entity,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = { goBack, push, updateProfile, resetError, userProfilePopover, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const UserProfileDescriptionEdit = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_UserProfileDescriptionEdit));

import { ElementsCalendarFilters } from '../filters/elementsCalendarFilters.types';
import { RentElementRecord } from '../../../../../../server';
import moment from 'moment/moment';

export const processActualCalendarBoundaries = (params: ElementsCalendarFilters, records: RentElementRecord[]) => {
    let { screenLeft, screenRight, capture } = params;

    if (records && records.length > 0) {
        if (capture === 'all' || capture === 'allWithNow') {
            let minDate = Math.min(...records.map((record) => +moment(record.commonFields.rentTerms.rentPeriodStartDate)));
            let maxDate = Math.max(...records.map((record) => +moment(record.commonFields.rentTerms.rentPeriodEndDate)));

            if (capture === 'allWithNow') {
                minDate = Math.min(minDate, Date.now());
                maxDate = Math.max(maxDate, Date.now());
            }

            let diff = Math.round((maxDate - minDate) * 0.05);
            screenLeft = minDate - diff;
            screenRight = maxDate + diff;
        }
    }

    return {
        screenLeft,
        screenRight,
    };
};

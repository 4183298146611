import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { DocumentTemplateRecord, DocumentTypeCodeEnum, serverApi } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';

export interface DocumentTemplateSelectProps extends BaseSelectBaseProps<DocumentTemplateRecord> {
    filters?: {
        documentType?: DocumentTypeCodeEnum[];
    };
}

const DocumentTemplateSelectFC = ({ ...props }: DocumentTemplateSelectProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listDocumentTemplates(businessAccountId, undefined, 0, [`id;IN;${ids}`]);

        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        const filters: string[] = [];
        if (props.filters?.documentType) filters.push(`documentType;IN;${props.filters?.documentType.join(';')}`);
        const response = await serverApi.listDocumentTemplates(
            businessAccountId,
            limit,
            startFrom,
            filters,
            'lastUpdateDate',
            'DESC',
            search
        );

        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(item) => item.id}
            getOptionProps={(item) => ({
                label: item.shortName,
                children: item.shortName,
            })}
        />
    );
};

export class DocumentTemplateSelect extends React.Component<DocumentTemplateSelectProps> {
    render() {
        return <DocumentTemplateSelectFC {...this.props} />;
    }
}

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FormFieldsGroup, IFormProps} from "../../dynamicForm/DynamicForm";
import {Modal, Skeleton} from "antd";
import {useLocalize} from "../../../core/hooks/useLocalize";
import {LocalizationEnum} from "../../../localization";
import {Alert, DynamicForm, Spin} from "../../index";
import {WrappedFormUtils} from "antd/es/form/Form";
import {showConfirm} from "../../confirm/showConfirm";
import {useIntl} from "react-intl";
import ReactDOM from "react-dom";
import {ServerError} from "../../../server";

interface ModalFormProps {
    title?: React.ReactNode;
    titleIcon?: React.ReactNode;
    zIndex?: number; // Для чего-то надо уже не помню
    formFields?: FormFieldsGroup[];
    okButtonText?: React.ReactNode;
    onOk?: (data: { [k: string]: any }) => void;
    onCancel?: () => void;
    initialValues?: { [k: string]: any }; // TODO
    validateAfterCreate?: boolean;
    error?: ServerError;
    cancelPreventionMessage?: React.ReactNode;
    isLoading?: boolean;
    isFetching?: boolean;
    width?: number;
    onErrorClose?: ()=>void;
    okButtonDisabled?: boolean;
    onFormChange?: (data:any)=>void;
    formRowType?: IFormProps['rowType'];
}

export const ModalForm = ({title, error, titleIcon, zIndex, formFields, initialValues, okButtonText, cancelPreventionMessage, isFetching, isLoading, width, onErrorClose, okButtonDisabled, onFormChange, ...props}: ModalFormProps) => {
    const [visible, setVisible] = useState(true);
    const intl = useIntl();
    const L = useLocalize();
    const formRef = useRef<any>(null);
    const getForm = () => {
        return formRef?.current?.props?.form as WrappedFormUtils || null;
    };

    const onOk = useCallback(() => {
        const form = getForm();
        if (form) {
            form.validateFieldsAndScroll({scroll: {offsetTop: 36}}, (err, values) => {
                if (!err && props.onOk) props.onOk(values);
            });
        }
    }, [props.onOk]);

    const onCancel = useCallback(async () => {
        const form = getForm();
        if (form) {
            let fields: string[] = [];
            if (formFields) {
                formFields.map((group) =>
                    group.fields.map(field => fields.push(field.id))
                );
            }
            let yes = !cancelPreventionMessage || !form.isFieldsTouched(fields) || await showConfirm(intl, cancelPreventionMessage);
            if (yes) setVisible(false);
        }
    }, [formFields, cancelPreventionMessage, intl, showConfirm, setVisible]);

    const onAfterClose = useCallback(() => {
        props.onCancel?.();
    }, []);

    const modalRef = useRef<Modal | null>(null);

    // Скроллим на самый верх, если есть ошибка
    useEffect(() => {
        if (error) {
            let node = ReactDOM.findDOMNode(modalRef.current);
            if (node instanceof Element && (node as Element).getElementsByClassName('ant-modal-wrap ')[0]) {
                (node as Element).getElementsByClassName('ant-modal-wrap ')[0].scrollTop = 0;
            }
        }
    }, [error, modalRef]);

    const buttonsDisabled = isLoading;

    return (
        <Modal
            ref={modalRef}
            title={<div>{titleIcon && <span style={{marginRight: 20}}>{titleIcon}</span>}{title}</div>}
            zIndex={zIndex}
            className={'rr-modal'}
            width={width ?? 800}
            cancelButtonProps={{className: 'rr-btn-default', disabled: buttonsDisabled || isFetching}}
            okButtonProps={{disabled: buttonsDisabled || okButtonDisabled}}
            visible={visible}
            afterClose={onAfterClose}
            onOk={onOk}
            onCancel={onCancel}
            closable={!!title}
            cancelText={L(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
            okText={okButtonText || L(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            confirmLoading={isFetching}
        >
            <Spin spinning={isFetching}>
                <div style={isLoading ? {filter: 'blur(10px)'} : undefined}>
                    {error ? <Alert
                        message={L(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                        description={error.message}
                        type="error"
                        showIcon
                        closable
                        style={{marginTop: 32}}
                        afterClose={onErrorClose}
                    /> : null}
                    {
                        !isLoading && (
                            <DynamicForm
                                validateInitialValues={props.validateAfterCreate}
                                wrappedComponentRef={formRef}
                                data={formFields}
                                initialValues={initialValues}
                                onChange={onFormChange}
                                rowType={props.formRowType}
                            />
                        )
                    }
                    {
                        isLoading && (
                            <Skeleton active={true} loading={true} title={true} paragraph={{rows: 4}}/>
                        )
                    }
                </div>
            </Spin>
        </Modal>
    );
};
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ProjectInfoCreate, ProjectInfoRead, ProjectInfoUpdate, serverApi, ServerError } from '../../../../../server';
import { getProjectByIdQueryFunction, offersApi } from '../../api/offers.api';
import { getServerError } from '../../../../../shared/util/utils';
import { AppDispatch, GetState } from '../../../../../../index';

const ACTION_TYPES = {
    CREATE_OFFER: 'offer/CREATE_OFFER',
    UPDATE_OFFER: 'offer/UPDATE_OFFER',
    LOAD_OFFER: 'offer/LOAD_OFFER',
};

export interface CreateOfferArgs {
    businessAccountId: number;
    projectInfoCreate: ProjectInfoCreate;
}

export interface UpdateOfferArgs {
    businessAccountId: number;
    id: number;
    offerInfoUpdate: ProjectInfoUpdate;
}

export interface OfferState {
    loading: boolean;
    loadingError?: ServerError;
    updating: boolean;
    updatingError?: ServerError;
    entity: ProjectInfoRead | null;
}

export const initialState: OfferState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createOfferThunk = createAsyncThunk(ACTION_TYPES.CREATE_OFFER, async (args: CreateOfferArgs) => {
    const { businessAccountId, projectInfoCreate } = args;
    try {
        return await serverApi.createProject(businessAccountId, projectInfoCreate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const createOffer = (args: CreateOfferArgs) => (dispatch: AppDispatch, getState: GetState) => {
    return dispatch(createOfferThunk(args))
        .unwrap()
        .then((result) => {
            dispatch(offersApi.util?.invalidateTags(['ProjectsList', 'Project']));
            return result.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const updateOfferThunk = createAsyncThunk(ACTION_TYPES.UPDATE_OFFER, async (args: UpdateOfferArgs) => {
    const { businessAccountId, id, offerInfoUpdate } = args;
    try {
        return await serverApi.updateProjectById(businessAccountId, id, offerInfoUpdate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const updateOffer = (args: UpdateOfferArgs) => (dispatch: AppDispatch, getState: GetState) => {
    return dispatch(updateOfferThunk(args))
        .unwrap()
        .then((result) => {
            dispatch(offersApi.util?.invalidateTags(['ProjectsList', 'Project']));
            return result.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const loadOffer = createAsyncThunk(ACTION_TYPES.LOAD_OFFER, getProjectByIdQueryFunction);

const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        clearOffer: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(loadOffer.pending, updateOfferThunk.pending, createOfferThunk.pending), (state, action) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(loadOffer.fulfilled, updateOfferThunk.fulfilled, createOfferThunk.fulfilled), (state, action) => {
                state.updating = false;
                state.entity = action.payload.data;
            })
            .addMatcher(isAnyOf(loadOffer.rejected, updateOfferThunk.rejected, createOfferThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = action.error;
            });
    },
});

export const { clearOffer } = offerSlice.actions;
export default offerSlice.reducer;

import React, { ComponentProps, FC, forwardRef, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

export type ConditionalLinkInheritanceProps = Pick<ComponentProps<'div'>, 'className' | 'style'>;

export interface ConditionalLinkProps extends ConditionalLinkInheritanceProps {
    condition: boolean;
    to?: string;
    onClick?: (event: MouseEvent<any>) => void;
}

export const ConditionalLink: FC<ConditionalLinkProps> = forwardRef<any, ConditionalLinkProps>((props, ref) => {
    const { condition, to, children, ...divProps } = props;

    return condition && to ? (
        <Link to={to} ref={ref} {...divProps}>
            {children}
        </Link>
    ) : (
        <div {...divProps}>{children}</div>
    );
});

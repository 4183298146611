import React from 'react';
import {StateBadgeData} from '../../../../../../components/v2/stateBadge/stateBadge';
import {CrewMemberStateCodeEnum} from '../../../../../../server';
import {Color} from "../../../../../../core/types/color";

export const crewMemberStateBadgeData: StateBadgeData<CrewMemberStateCodeEnum> = {
    [CrewMemberStateCodeEnum.ACTIVE]: {
        color: Color.Green,
        name: 'Активный',
    },
    [CrewMemberStateCodeEnum.BLOCKED]: {
        color: Color.Grey2,
        name: 'Заблокирован',
    }
};

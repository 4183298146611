import React, { FC } from 'react';
import { getPathFromState } from '../../../../../shared/util/utils';
import { push } from 'connected-react-router';
import { LocalizationEnum, localize } from '../../../../../localization';
import { HelpTooltip } from '../../../../../components/helpTooltip/HelpTooltip';
import { useAppDispatch } from '../../../../../store/hooks';
import { useLocation } from 'react-router-dom';
import './CalendarInterval.less';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';
import { CalendarCapture } from '../Calendar/types/params';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import moment from 'moment';

import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';

interface CalendarIntervalProps {
    screenLeft: number | undefined;
    screenRight: number | undefined;
    capture: CalendarCapture | undefined;
    pageName: CalendarPageTabsEnum;
}

const CalendarInterval: FC<CalendarIntervalProps> = (props) => {
    const { screenLeft, screenRight, capture, pageName } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();

    const getInterval = (type: 'hour' | 'day' | 'month' | 'all') => {
        let screenLeft = Date.now();
        let screenRight = Date.now();

        if (type === 'hour') {
            [screenLeft, screenRight] = DateUtils.getCalendarBoundaries(moment(Date.now()), 'hour');
        }
        if (type === 'day') {
            [screenLeft, screenRight] = DateUtils.getCalendarBoundaries(moment(Date.now()), 'day');
        }
        if (type === 'month') {
            [screenLeft, screenRight] = DateUtils.getCalendarBoundaries(moment(Date.now()), 'month');
        }

        const newParams = {
            screenLeft: type === 'all' ? undefined : screenLeft,
            screenRight: type === 'all' ? undefined : screenRight,
            capture: type === 'all' ? 'all' : undefined,
        };

        ParamsUtils.setGridStorageDataParamsFilters(pageName, newParams, true);
        dispatch(push(getPathFromState(location.pathname, location.search, newParams)));
    };

    const getGroupButtons = (noneInterval: boolean) => (
        <div className={'calendar-interval'} style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
            <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A4}>
                <button
                    className={
                        'rr-calendar-nav-button' +
                        (!noneInterval && interval === 'hour' && selectDate ? ' rr-calendar-nav-button-selected' : '')
                    }
                    style={noneInterval ? { cursor: 'not-allowed', color: '#ccc' } : {}}
                    onClick={noneInterval ? undefined : () => getInterval('hour')}
                >
                    {localize(LocalizationEnum.ASPECT__TIME__DAY, undefined, { value: 1 })}
                </button>
            </HelpTooltip>
            <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A5}>
                <button
                    className={
                        'rr-calendar-nav-button' +
                        (!noneInterval && interval === 'day' && selectDate ? ' rr-calendar-nav-button-selected' : '')
                    }
                    style={noneInterval ? { cursor: 'not-allowed', color: '#ccc' } : {}}
                    onClick={noneInterval ? undefined : () => getInterval('day')}
                >
                    {localize(LocalizationEnum.ASPECT__TIME__WEEK, undefined, { value: 1 })}
                </button>
            </HelpTooltip>
            <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A6}>
                <button
                    className={
                        'rr-calendar-nav-button' +
                        (!noneInterval && interval === 'month' && selectDate ? ' rr-calendar-nav-button-selected' : '')
                    }
                    style={noneInterval ? { cursor: 'not-allowed', color: '#ccc' } : {}}
                    onClick={noneInterval ? undefined : () => getInterval('month')}
                >
                    {localize(LocalizationEnum.ASPECT__TIME__MONTH, undefined, { value: 1 })}
                </button>
            </HelpTooltip>
            <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A7}>
                <button
                    className={'rr-calendar-nav-button' + (!noneInterval && interval === 'all' ? ' rr-calendar-nav-button-selected' : '')}
                    style={noneInterval ? { cursor: 'not-allowed', color: '#ccc', backgroundColor: '#A9A9A9' } : {}}
                    onClick={noneInterval ? undefined : () => getInterval('all')}
                >
                    {localize(LocalizationEnum.ASPECT__GLOBAL__ANY)}
                </button>
            </HelpTooltip>
        </div>
    );

    const noneInterval: boolean = !screenLeft || !screenRight;

    if (!screenLeft || !screenRight) return getGroupButtons(noneInterval);

    const ratio = (screenRight - screenLeft) / 1000 / 60 / 60 / 24;

    let selectDate = ratio === 1 || ratio === 7 || ratio === 30;

    let interval: string;
    if (capture) {
        interval = 'all';
    } else {
        if (ratio === 1) {
            interval = 'hour';
        } else if (ratio === 7) {
            interval = 'day';
        } else if (ratio === 30) {
            interval = 'month';
        }
    }
    return getGroupButtons(noneInterval);
};

export default CalendarInterval;

import React, {CSSProperties, FC, ReactNode} from 'react';
import { Icon } from 'antd';
import { IconExclamationTriangleSolid } from '../../icons';
import './WarningsList.less';

interface WarningsListProps {
    style?: CSSProperties;
    warnings: (string | ReactNode)[];
}

export const WarningsList: FC<WarningsListProps> = (props) => {
    const { warnings } = props;

    return (
        <div className={'warning-list'} style={props.style}>
            <span className={'title'}>Предупреждени{warnings.length > 1 ? 'я' : 'е'}:</span>
            <div className={'warnings'}>
                {warnings.map((warning, index) => (
                    <span key={index} className={'warning-item'}>
                        <Icon component={IconExclamationTriangleSolid} />
                        <span>{warning}</span>
                    </span>
                ))}
            </div>
        </div>
    );
};

import { TransportationStateCodeEnum } from '../../../../../server';

export const transformationStateCodeMap = {
    [TransportationStateCodeEnum.PLANNED]: 'Запланирована',
    [TransportationStateCodeEnum.INPROGRESS]: 'В пути',
    [TransportationStateCodeEnum.FINISHED]: 'Завершена',
    [TransportationStateCodeEnum.CANCELED]: 'Отменена',
};

export class TransportationUtils {
    static getActualStateCode = (stateCode?: string): string | undefined => {
        return transformationStateCodeMap[stateCode ?? ''];
    };
}

import React, {FC, ReactElement} from 'react';
import {Popover} from "antd";
import './picker.less';
import {PopoverProps} from "antd/es/popover";

interface Props {
    calendar: ReactElement;
    open?: boolean;
    onOpenChange?: (open: boolean)=>void;
    align?: object;
    getPopupContainer?: PopoverProps['getPopupContainer'];
}

export const Picker:FC<Props> = (props) => {

    return (
        <Popover
            getPopupContainer={props.getPopupContainer}
            overlayClassName={'rr-calendar-picker'}
            onVisibleChange={props.onOpenChange}
            content={props.calendar}
            trigger={'click'}
            transitionName={'slide-up'}
            destroyTooltipOnHide={true}
            align={props.align}
            {...(props.open !== undefined ? {visible: props.open} : undefined)}
        >
            {props.children}
        </Popover>
    );
};

import React, {CSSProperties} from "react";
import classNames from "classnames";
import {LocalizationEnum, useLocalize} from "../../../core/hooks/useLocalize";
import {Color} from "../../../core/types/color";
import './stateBadge.less';

export type StateBadgeData<T extends string> = Record<T, StateBadgeDataRecordWithName | StateBadgeDataRecordWithLocalizationId>;

export interface StateBadgeDataRecordWithName {
    name: string;
    color: Color;
}

export interface StateBadgeDataRecordWithLocalizationId {
    localizationId: LocalizationEnum;
    color: Color;
}

export interface StateBadgeProps<T extends string> {
    type: T;
    dot?: boolean;
    style?: CSSProperties;
    className?: string;
    hideLabel?: boolean;
}

export const useStateBadge = () => {
    const L = useLocalize();
    const componentName = `rr-state-badge`;
    return <T extends string>(props: StateBadgeProps<T> & {data: StateBadgeData<T>}) => {
        const d = props.data[props.type];
        if (d) {
            const text = 'localizationId' in d ? L(d.localizationId) : d.name;
            return (
                <span style={props.style} className={classNames(componentName, `${componentName}--${d.color}`, props.dot && `${componentName}-with-dot`, props.className)}>
                    {props.dot && <span className={`${componentName}-dot`}/>}
                    {!props.hideLabel && <span className={`${componentName}-text`}>{text}</span>}
                </span>
            );
        }
        return null;
    }
};

import { WrappedFormUtils } from 'antd/es/form/Form';
import { useAppDispatch } from '../../../../../../store/hooks';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { ActivityFramesCalendarFilters } from '../filters/activityFramesCalendarFilters.types';
import { setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { push } from 'connected-react-router';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { activityFramesCalendarPageName } from '../ActivityFramesCalendar';
import { ParamsUtils } from '../../../../../../core/utils/paramsUtils';
import _ from 'lodash';
import { UseFiltersChanges } from '../../../components/CalendarFilters/CalendarFilters';

export const useFiltersChanges: UseFiltersChanges<ActivityFramesCalendarFilters> = (filtersForm: WrappedFormUtils | undefined) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    return useMemo(
        () =>
            debounce(async (params: ActivityFramesCalendarFilters) => {
                const newParams = _.cloneDeep(
                    ParamsUtils.getProcessedParamsWithSelectors({
                        pageParams: params,
                    })
                );

                if (Array.isArray(newParams.typeCode) && newParams.typeCode.length === 0) {
                    newParams.typeCode = undefined;
                }

                setGridStorageDataFilters(activityFramesCalendarPageName, newParams);
                dispatch(push(getPathFromState(location.pathname, location.search, newParams)));
            }, 300),
        [dispatch, location.pathname, location.search]
    );
};

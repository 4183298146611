import {Icon} from 'antd';
import {IconBinoculars} from '../../../../../../components/icons';
import React, {ReactNode} from 'react';
import {Avatar} from '../../../../../../components/avatar/avatar';
import classNames from 'classnames';

interface IProps {
    children: ReactNode;
}

export const SimpleOrderElementsNotFoundBlock = (props: IProps) => {
    const {children} = props;

    return (
        <div className={classNames('rr-grid-notfound-block')}>
            <Avatar className={'rr-avatar-big'}>
                <Icon component={IconBinoculars}/>
            </Avatar>
            <div className={'rr-grid-notfound-block-title'}>
                В заказе еще нет позиций
            </div>
            <div className={'rr-grid-notfound-block-message'}>
                {children}
            </div>
        </div>
    );
};

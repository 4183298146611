import {
    activityFramesCalendarFilters,
    activityFramesCalendarFiltersWithoutLogistics,
    activityFramesCalendarFiltersWithoutLogisticsAndSubrent,
    activityFramesCalendarFiltersWithoutSubrent,
} from '../filters/activityFramesCalendarFilters';
import { useMemo } from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
    logisticsModuleEnabledSelector,
    subrentModuleEnabledSelector,
} from '../../../../../../shared/reducers/businessAccountPreferences.reducer';

export const useCurrentFiltersData = () => {
    const isLogisticsModuleEnabled = useAppSelector(logisticsModuleEnabledSelector);
    const isSubrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    return useMemo(() => {
        if (isLogisticsModuleEnabled && isSubrentModuleEnabled) return activityFramesCalendarFilters;
        if (!isLogisticsModuleEnabled && !isSubrentModuleEnabled) return activityFramesCalendarFiltersWithoutLogisticsAndSubrent;
        if (!isLogisticsModuleEnabled && isSubrentModuleEnabled) return activityFramesCalendarFiltersWithoutLogistics;
        if (isLogisticsModuleEnabled && !isSubrentModuleEnabled) return activityFramesCalendarFiltersWithoutSubrent;

        return activityFramesCalendarFilters;
    }, [isLogisticsModuleEnabled, isSubrentModuleEnabled]);
};

import { FormFieldsGroup, FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../core/utils/formUtils';
import { WorkPlanningsParams } from '../../api/workPlannings.api.types';
import { LocalizationEnum } from '../../../../../localization';
import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SliderRangeWithManualInput } from '../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { workPlanningsAllStateCodes } from '../../data/workPlanningsData';
import { WorkPlanningsStateBadge } from '../../components/WorkPlanningsStateBadge/WorkPlanningsStateBadge';
import { ProfessionSelect } from '../../../../../components/v2/select/professionSelect/ProfessionSelect';
import { FiltersDateTimeRangePicker } from '../../../../../components/datePicker/FiltersDateTimeRangePicker';
import { CrewMemberSelect } from '../../../../../components/v2/select/crewMemberSelect/crewMemberSelect';

export const workPlanningsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<WorkPlanningsParams>([
    {
        fields: [
            {
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: 'Статус',
                placeholder: 'Любой',
                id: 'stateCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: (form) => {
                    return workPlanningsAllStateCodes.map((code) => ({
                        name: <WorkPlanningsStateBadge type={code} dot={true} />,
                        value: code,
                    }));
                },
            },
            {
                label: 'Специальность',
                placeholder: 'Любая',
                id: 'professionId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: ProfessionSelect,
            },
            {
                label: 'Скрыть отменённые',
                id: 'hideCancelled',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Работник',
                placeholder: 'Любой',
                id: 'crewMemberIds',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: CrewMemberSelect,
            },

            {
                label: LocalizationEnum.ASPECT__FILTERS__START_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'startDate',
                type: FormItemType.Component,
                component: FiltersDateTimeRangePicker,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__END_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'endDate',
                type: FormItemType.Component,
                component: FiltersDateTimeRangePicker,
            },
            {
                label: 'Смены к оплате',
                id: 'shiftCount',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    min: 0,
                    max: 1000,
                    step: 1,
                }),
            },

            {
                label: 'Количество работников',
                id: 'numberOfCrew',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    min: 0,
                    max: 1000,
                    step: 1,
                }),
            },

            {
                label: 'Цена смены',
                id: 'effectivePrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 1,
                }),
            },

            {
                label: 'Итоговая стоимость',
                id: 'finalTotalPrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 1,
                }),
            },
        ],
    },
]);

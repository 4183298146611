import React from 'react';
import moment from 'moment';
import {LocalizationEnum, localizeIntl} from '../../localization';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {connect} from 'react-redux';

interface IProps extends WrappedComponentProps {
    day:number,
    value: Date,
    daysParam: number,
    dayBold?: boolean,
}

class DashboardDay extends React.Component<IProps> {

    render() {

        if (this.props.day === 0) {

            let currentDate = Date.parse(new Date().toString());
            let days = (currentDate - Date.parse(this.props.value.toString()))/86400000;       //86400000 - ms в дне

            if (this.props.daysParam < Math.round(days) && this.props.daysParam !== 0 ) {
                return (
                    <span className="format-column-data"
                          style={this.props.dayBold ? {fontWeight: 600} : {}}>{moment(this.props.value).format(`[${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__TIME__TODAY)}]`)}</span>
                );
            } else {
                return (
                    <span className="format-column-data"
                          style={this.props.dayBold ? {fontWeight: 600} : {}}>{moment(this.props.value).format(`[${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__TIME__TODAY)},] HH:mm`)}</span>
                );
            }
        }

        if (this.props.day === 1) {

            let currentDate = Date.parse(new Date().toString());
            let days = (currentDate - Date.parse(this.props.value.toString()))/86400000;       //86400000 - ms в дне

            if (this.props.daysParam < Math.round(days) && this.props.daysParam !== 0 ) {
                return (
                    <span className="format-column-data"
                          style={this.props.dayBold ? {fontWeight: 600} : {}}>{moment(this.props.value).format(`[${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__TIME__TOMORROW)}]`)}</span>
                );
            } else {
                return (
                    <span className="format-column-data"
                          style={this.props.dayBold ? {fontWeight: 600} : {}}>{moment(this.props.value).format(`[${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__TIME__TOMORROW)},] HH:mm`)}</span>
                );
            }
        }

        if (this.props.day === -1) {

            let currentDate = Date.parse(new Date().toString());
            let days = (currentDate - Date.parse(this.props.value.toString()))/86400000;       //86400000 - ms в дне

            if (this.props.daysParam < Math.round(days) && this.props.daysParam !== 0 ) {
                return (
                    <span className="format-column-data"
                          style={this.props.dayBold ? {fontWeight: 600} : {}}>{moment(this.props.value).format(`[${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__TIME__YESTERDAY)}]`)}</span>
                );
            } else {
                return (
                    <span className="format-column-data"
                          style={this.props.dayBold ? {fontWeight: 600} : {}}>{moment(this.props.value).format(`[${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__TIME__YESTERDAY)},] HH:mm`)}</span>
                );
            }
        }
    }
}

export default connect()(injectIntl(DashboardDay));



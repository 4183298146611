import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../shared/util/validateField';
import { LocalizationEnum, localize } from '../../../../localization';
import React from 'react';
import { getStoreState } from '../../../../../index';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { createHiddenField } from '../../../../components/modalForm/utils';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('templateEdit'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 40,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('templateEdit') ? form.getFieldValue('id') : undefined;

                    validateField(
                        'template',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        'Краткое наименование шаблона уже использовано',
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('templateEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'template',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        'Полное наименование шаблона уже использовано',
                        cb
                    );
                }, 500),
            },
        ],
    },
    {
        fields: [
            {
                label: '',
                id: 'renterDefaultDiscount',
                type: FormItemType.Hidden,
                defaultValue: 0,
                visible: (form) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                id: 'defaultDiscount',
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT,
                type: FormItemType.Discount,
                defaultValue: 0,
                required: true,
                visible: (form) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

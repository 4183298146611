import { IModalFormFilters } from '../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../components/modalForm/components/Header/types/displayData';
import { ProjectInfoRead } from '../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof ProjectInfoRead> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['renterDefaultDiscount', 'shortName', 'defaultDiscount']],
    },
];

export const createTemplateModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editTemplateModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

import React from 'react';
import { ContactSelectFC, ContactSelectProps } from './contactSelect';
import { SelectAndCreate } from '../selectAndCreate';
import { ContactCreateModalNew } from '../../../../modules/main/renters/tabs/contacts/modal/create/ContactCreateModalNew';

export interface ContactSelectAndCreateProps extends ContactSelectProps {}

export class ContactSelectAndCreate extends React.PureComponent<ContactSelectAndCreateProps> {
    render() {
        const { props } = this;
        return (
            <SelectAndCreate
                select={() => <ContactSelectFC {...props} />}
                modal={({ close }) => {
                    return (
                        <ContactCreateModalNew
                            onSuccess={(data) => {
                                let value: number | number[] | undefined;
                                if (props.multiple) {
                                    if (props.value) {
                                        if (Array.isArray(props.value)) value = [...props.value.map((item) => +item), data.id];
                                        else value = [+props.value, data.id];
                                    } else {
                                        value = [data.id];
                                    }
                                } else {
                                    value = data.id;
                                }
                                props.onChange?.(value);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}

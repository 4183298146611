import React, { FC, memo, SetStateAction, useCallback, useRef, useState } from 'react';
import { TimeRangeContext } from '../../../../components/CalendarItemRenderer/context/TimeRangeContext';
import Timeline, { TimelineHeaders } from 'react-calendar-timeline';
import {
    CALENDAR_LINE_HEIGHT_RATIO,
    CALENDAR_SIDEBAR_WIDTH_SMALL,
    CalendarDisplayType,
    CalendarVisibleItemsLimit,
} from '../../../../components/Calendar/utils/data/calendarConstants';
import { CalendarItemRenderer } from '../../../../components/CalendarItemRenderer/CalendarItemRenderer';
import { ElementsCalendarItemRenderer } from '../../../elements/components/ItemRenderer/ElementsCalendarItemRenderer';
import { CalendarMarker } from '../../../../components/CalendarMarker/CalendarMarker';
import { CalendarFooter } from '../../../../components/Calendar/components/Footer/CalendarFooter';
import { ItemsNotFoundBlock } from '../../../../../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import { CalendarBottomNavigation } from '../../../../components/СalendarBottomNavigation/СalendarBottomNavigation';
import { Card } from 'antd';
import { accessibilityMapCalendarPageName } from '../../AccessibilityMapCalendar';
import { RentElementRecordList } from '../../../../../../../server';
import { useCalendarItems } from '../../../../components/Calendar/hooks/useCalendarItems';
import { AccessibilityMapFilters } from '../../filters/accessibility/accessibilityMapCalendarFilters.types';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { elementsCalendarScreenLeft, elementsCalendarScreenRight } from '../../../elements/reducers/elementsCalendar.reducer.selectors';
import { useDeferredItems } from '../../../../components/Calendar/hooks/useDeferredItems';
import { useGroups } from '../../../../components/Calendar/hooks/useGroups';
import { useOnCalendarItemChanges } from '../../../../components/Calendar/hooks/useOnCalendarItemChanges';
import { useHorizontalLineClassNamesForGroup } from '../../../../components/Calendar/hooks/useClassNames';
import { useDisableMouseScrollEffect } from '../../../../components/Calendar/hooks/useDisableMouseScrollEffect';
import { CalendarId } from '../../../../components/Calendar/types/items';
import { CalendarLineUtils } from '../../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import './AccessibilityElementsCalendar.less';
import { ElementsCount } from '../../../../components/Calendar/components/Header/ElementsCount/ElementsCount';
import { CalendarDisplayTypeButton } from '../../../../components/Calendar/components/ActionsButtons/DisplayTypeButton/CalendarDisplayTypeButton';
import { SetPageParams } from '../../../../../../../core/hooks/urlParams/types';
import { LegendButton } from '../../../../components/Calendar/components/ActionsButtons/LegendButton/LegendButton';
import { CalendarURLParams } from '../../../../components/Calendar/types/params';
import { SortByButton } from '../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { ExcelButton } from '../../../../components/Calendar/components/ActionsButtons/ExcelButton/ExcelButton';
import { accessibilityElementsCalendarGroups } from './data/groupsData';
import {
    accessibilityElementsCalendarSortData,
    AccessibilityElementsCalendarSortValue,
    accessibilityElementsCalendarSortValueToItemKey,
} from './data/sortData';
import classNames from 'classnames';
import { AccessibilityCalendarGreyForm } from './components/greyForm/AccessibilityCalendarGreyForm';
import { FiltersVisibleToggle } from '../../../../../../../components/dynamicFilters/components/FiltersVisibleToggle/FiltersVisibleToggle';
import { accessibilityElementsCalendarFiltersDescription } from '../../filters/elements/accessibilityElementsCalendarFilters.description';
import { AccessibilityCalendarHideForm } from './components/hideForm/AccessibilityCalendarHideForm';
import { updateGridSettings } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { GridStorageData } from '../../../../../../../components/grid/utils';
import { processElementsCalendarItem } from '../../../elements/utils/processing/processElementsCalendarItem';

interface AccessibilityElementsCalendarProps {
    displayType: CalendarDisplayType;
    elementsData: RentElementRecordList | undefined;
    isLoading: boolean;
    pageParams: AccessibilityMapFilters;
    setDisplayType: React.Dispatch<SetStateAction<CalendarDisplayType>>;
    setVisibleItemsLimit: React.Dispatch<SetStateAction<CalendarVisibleItemsLimit>>;
    updatePageParams: SetPageParams<AccessibilityMapFilters>;
    visibleItemsLimit: CalendarVisibleItemsLimit;
    accessibilityEntitySelected: boolean;
    greyFiltersFormRef: any | undefined;
    hideFiltersFormRef: any | undefined;
}

export const AccessibilityElementsCalendar: FC<AccessibilityElementsCalendarProps> = memo((props) => {
    const {
        displayType,
        elementsData,
        isLoading,
        pageParams,
        accessibilityEntitySelected,
        setDisplayType,
        setVisibleItemsLimit,
        updatePageParams,
        visibleItemsLimit,
        greyFiltersFormRef,
        hideFiltersFormRef,
    } = props;
    const dispatch = useAppDispatch();
    const gridSettings = useAppSelector((storeState) => storeState.userSettings.gridSettings[accessibilityMapCalendarPageName]);
    const screenLeft = useAppSelector(elementsCalendarScreenLeft);
    const screenRight = useAppSelector(elementsCalendarScreenRight);
    const scrollRef = useRef<HTMLDivElement>();
    const selectedIndexRef = useRef<number | undefined>();
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [collapsedKitsId, setCollapsedKitsId] = useState<CalendarId[]>([]);
    const [collapsedGroupsId, setCollapsedGroupsId] = useState<CalendarId[]>([]);
    const [sortByValue, setSortByValue] = useState<AccessibilityElementsCalendarSortValue | undefined>(pageParams.group);
    const filtersContainerRef = useRef<HTMLDivElement>(null);
    const isAllFiltersToggleCollapsed = gridSettings?.hideFilters;
    const [greyFiltersVisible, setGreyFiltersVisible] = useState(isAllFiltersToggleCollapsed ?? false);

    const filteredCount = elementsData?.listAttributes.filteredCount ?? 0;

    const { items, visibleTimeStart, visibleTimeEnd } = useCalendarItems(
        elementsData?.records,
        processElementsCalendarItem,
        pageParams.screenLeft ?? screenLeft,
        pageParams.screenRight ?? screenRight,
        isLoading,
        collapsedKitsId,
        collapsedGroupsId,
        accessibilityElementsCalendarSortValueToItemKey,
        pageParams.group
    );

    const { onItemClick, onPopoverVisibleChange } = useOnCalendarItemChanges({
        items,
        calendarType: 'elements',
        selectedIndexRef,
        scrollRef,
        setIsPopoverVisible,
        setCollapsedKitsId,
        setCollapsedGroupsId,
        onClick: undefined,
        pageParams,
        isLoading,
    });

    const horizontalLineClassNamesForGroup = useHorizontalLineClassNamesForGroup(items);

    const deferredItems = useDeferredItems(items, isLoading);
    const groups = useGroups(items, displayType);
    const lineHeight = CalendarLineUtils.map.lineHeightFromDisplayType[displayType];

    const isGreyFiltersSelected = Object.keys(accessibilityElementsCalendarFiltersDescription).some((key) => pageParams[key] != null);

    const onAllFiltersToggle = useCallback(() => {
        setGreyFiltersVisible((visible: boolean) => {
            const newValue = !visible;

            dispatch(
                updateGridSettings({
                    [accessibilityMapCalendarPageName]: {
                        ...(gridSettings ?? {}),
                        hideFilters: !greyFiltersVisible,
                    } as GridStorageData,
                })
            );

            return newValue;
        });
    }, [dispatch, greyFiltersVisible, gridSettings]);

    useDisableMouseScrollEffect(scrollRef);

    return (
        <Card className={'accessibility-elements-calendar-card'}>
            {
                <AccessibilityCalendarGreyForm
                    ref={greyFiltersFormRef}
                    visible={greyFiltersVisible}
                    filtersContainerRef={filtersContainerRef}
                    pageParams={pageParams}
                />
            }
            <div ref={filtersContainerRef}>
                {
                    <div className={'buttons-panel'}>
                        <div className={'column'}>
                            <FiltersVisibleToggle
                                onClick={onAllFiltersToggle}
                                bold={isGreyFiltersSelected}
                                collapsed={greyFiltersVisible}
                            />
                            <AccessibilityCalendarHideForm
                                ref={hideFiltersFormRef}
                                filtersContainerRef={filtersContainerRef}
                                pageParams={pageParams}
                            />
                            <div className={'buttons'}>
                                <CalendarDisplayTypeButton
                                    displayType={displayType}
                                    placement={'right'}
                                    onChange={(value) => {
                                        setDisplayType(value);
                                        updatePageParams(
                                            {
                                                displayType: value,
                                            },
                                            {
                                                replaceWithoutRerender: true,
                                            }
                                        );
                                    }}
                                />
                                <LegendButton />
                                <SortByButton
                                    disabled={isLoading}
                                    sortData={accessibilityElementsCalendarSortData}
                                    sortValue={sortByValue}
                                    placement={'right'}
                                    onSortChange={(value) => {
                                        setSortByValue(value);
                                        setTimeout(() => {
                                            updatePageParams({
                                                group: value,
                                            } as AccessibilityMapFilters);
                                        }, 100);
                                    }}
                                />
                                <ExcelButton
                                    storageKey={'accessibilityCalendarExcell'}
                                    params={
                                        pageParams as CalendarURLParams<AccessibilityMapFilters, AccessibilityElementsCalendarSortValue>
                                    }
                                />
                            </div>
                            {accessibilityEntitySelected && items.length > 0 && (
                                <ElementsCount items={items} filteredCount={filteredCount} className={'elements-count'} />
                            )}
                        </div>
                    </div>
                }
                {accessibilityEntitySelected && items.length > 0 && (
                    <div
                        className={'calendar-timeline'}
                        style={{
                            opacity: isPopoverVisible ? 0.5 : 1,
                            marginTop: 0,
                            minHeight: 230,
                        }}
                    >
                        {
                            <TimeRangeContext.Provider
                                value={{
                                    visibleTimeStart,
                                    visibleTimeEnd,
                                }}
                            >
                                <Timeline
                                    canMove={false}
                                    canResize={false}
                                    groups={groups}
                                    horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
                                    itemHeightRatio={CALENDAR_LINE_HEIGHT_RATIO}
                                    items={deferredItems}
                                    lineHeight={lineHeight}
                                    scrollRef={(el) => (scrollRef.current = el)}
                                    sidebarWidth={CALENDAR_SIDEBAR_WIDTH_SMALL}
                                    visibleTimeStart={visibleTimeStart}
                                    visibleTimeEnd={visibleTimeEnd}
                                    itemRenderer={CalendarItemRenderer({
                                        itemRenderer: ElementsCalendarItemRenderer,
                                        displayType,
                                        onPopoverVisibleChange,
                                        onItemClick,
                                        groupsData: accessibilityElementsCalendarGroups,
                                        sortByValue,
                                    })}
                                >
                                    <TimelineHeaders>
                                        <></>
                                    </TimelineHeaders>
                                    {items?.length && <CalendarMarker />}
                                </Timeline>
                            </TimeRangeContext.Provider>
                        }
                        {
                            <CalendarFooter
                                filteredCount={filteredCount}
                                visibleItemsLimit={visibleItemsLimit}
                                setVisibleItemsLimit={setVisibleItemsLimit}
                            />
                        }
                    </div>
                )}
                {!accessibilityEntitySelected && !isLoading && (
                    <div className={'no-product-block-container'}>
                        <div
                            style={{
                                width: CALENDAR_SIDEBAR_WIDTH_SMALL,
                            }}
                        />
                        <div className={classNames('no-product-block', 'medium-gap')}>
                            <ItemsNotFoundBlock
                                avatarSize={'big'}
                                title={''}
                                message={'Календарь обязательств будет показан после выбора номенклатуры'}
                                entityType={'element'}
                                style={{ marginTop: 0, marginBottom: 20 }}
                            />
                        </div>
                    </div>
                )}
                {accessibilityEntitySelected && items.length === 0 && (
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                width: CALENDAR_SIDEBAR_WIDTH_SMALL,
                            }}
                        />
                        <ItemsNotFoundBlock style={{ marginTop: 36, marginBottom: 20, flexGrow: 1 }} entityType={'element'} />
                    </div>
                )}
            </div>
            <CalendarBottomNavigation
                capture={pageParams.capture}
                pageName={accessibilityMapCalendarPageName}
                pageParams={pageParams}
                positionTop={false}
                screenLeft={screenLeft}
                screenRight={screenRight}
            />
        </Card>
    );
});

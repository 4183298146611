import _ from 'lodash';
import { GripPageProcessingPipelineFunction } from './useFiltersChanges';
import { ListParams, URLDrawerParams } from '../types/params';
import { FormFieldsGroup, IFormField } from '../../../dynamicForm/DynamicForm';
import { AvailableIntervalFiltersData } from '../types/api';
import { useMemo, useRef } from 'react';
import { roundMaxFilterValue, roundMinFilterValue } from '../../../../shared/util/utils';
import { CustomFieldObjRead } from '../../../../server';
import { CustomFieldsUtils } from '../../../../core/utils/customFieldsUtils';

export const useProcessedFiltersDataWithIntervals = <PageParams extends URLDrawerParams & ListParams, EntityRecord extends object>(props: {
    filtersData: FormFieldsGroup[];
    pageParams: PageParams;
    availableIntervalFiltersData: AvailableIntervalFiltersData<EntityRecord>;
    customFieldMarkers?: CustomFieldObjRead[];
}): {
    processedFiltersData: FormFieldsGroup[];
    processedFiltersInitialValues: PageParams;
    processedFiltersDefaultValues: PageParams | {};
    processedFiltersCurrentValues: PageParams;
} => {
    const { filtersData, pageParams, availableIntervalFiltersData, customFieldMarkers } = props;

    const filtersDataRef = useRef<FormFieldsGroup[]>();
    const filtersActualInitialValuesRef = useRef<PageParams>({} as PageParams);
    const filtersActualInitialValues: PageParams = filtersActualInitialValuesRef.current;

    const emptyIntervalFiltersData: AvailableIntervalFiltersData<EntityRecord> = {
        maps: {
            metrics: {},
            formats: {},
        },
        values: {
            filters: [],
        },
    };
    const processedFiltersData: FormFieldsGroup[] = filtersDataRef.current ?? _.cloneDeep(filtersData);
    const filtersCurrentValues: PageParams = _.cloneDeep(pageParams);

    const {
        maps: { metrics: intervalFunctionMap, formats: intervalFormatMap },
        values,
    } = (availableIntervalFiltersData ?? emptyIntervalFiltersData) as AvailableIntervalFiltersData<EntityRecord> & object;
    const { filters: availableFiltersValues } = values;

    if (availableFiltersValues.length > 0) {
        for (const { fieldName, minLong, maxLong } of availableFiltersValues) {
            const groupIndex = processedFiltersData.findIndex((group) => group.fields.some(({ id }) => fieldName === id));

            if (groupIndex === -1) continue;

            const formField: IFormField = processedFiltersData[groupIndex].fields.find(({ id }) => fieldName === id) as IFormField;

            if (formField) {
                const mapFunction = intervalFunctionMap[fieldName];
                const formatStyle = intervalFormatMap[fieldName];
                const [minValue, maxValue] = [
                    minLong != null && maxLong != null ? roundMinFilterValue(mapFunction(minLong)) : 0,
                    minLong != null && maxLong != null ? roundMaxFilterValue(mapFunction(maxLong)) : 0,
                ];
                const props = typeof formField.componentProps !== 'function' ? formField.componentProps : {};
                if (mapFunction && formatStyle) {
                    formField.componentProps = {
                        ...props,
                        formatStyle,
                        min: minValue,
                        max: maxValue,
                        step: 1,
                    };
                    filtersActualInitialValues[fieldName] = [minValue, maxValue];
                }
            }
        }
    }

    filtersDataRef.current = processedFiltersData;

    const { filtersDataWithCustomFields, initialValuesWithCustom } = useMemo(
        () =>
            CustomFieldsUtils.getProcessedFiltersWithCustomFields(
                filtersDataRef.current ?? processedFiltersData,
                customFieldMarkers,
                pageParams,
                filtersActualInitialValues,
                availableFiltersValues
            ),
        [availableFiltersValues, customFieldMarkers, filtersActualInitialValues, pageParams, processedFiltersData]
    );

    const customFieldsNames = customFieldMarkers?.map(({ customFieldIndexKey }) => customFieldIndexKey) ?? [];
    const customFieldsFilters = availableFiltersValues.filter(({ fieldName }) =>
        customFieldsNames.includes(CustomFieldsUtils.deletePrefix(fieldName))
    );

    const processIntervalValues = (key: keyof PageParams) => {
        const verifiedKey = key as keyof PageParams;
        const value = filtersCurrentValues[verifiedKey];
        if (value == null && verifiedKey in initialValuesWithCustom) {
            filtersCurrentValues[verifiedKey] = initialValuesWithCustom[verifiedKey];
        }
        if (value && Array.isArray(value) && value.length === 0) {
            filtersCurrentValues[verifiedKey] = undefined!;
        }
    };

    for (const key in intervalFormatMap) {
        if (key in filtersCurrentValues) {
            processIntervalValues(key as keyof PageParams);
        }
    }

    customFieldsFilters.forEach(({ fieldName }) => processIntervalValues(CustomFieldsUtils.deletePrefix(fieldName) as keyof PageParams));

    return useMemo(
        () => ({
            processedFiltersData: filtersDataWithCustomFields,
            processedFiltersInitialValues: initialValuesWithCustom,
            processedFiltersDefaultValues: initialValuesWithCustom,
            processedFiltersCurrentValues: filtersCurrentValues,
        }),
        [filtersCurrentValues, filtersDataWithCustomFields, initialValuesWithCustom]
    );
};

export const getProcessedParamsWithIntervals: GripPageProcessingPipelineFunction = ({
    pageParams,
    filtersInitialValues,
    intervalFiltersValues,
}) => {
    if (intervalFiltersValues == null) return pageParams;

    const {
        values: { filters: intervalFilters = [] },
    } = intervalFiltersValues;
    const processedParams = { ...pageParams };

    intervalFilters.forEach(({ fieldName }) => {
        const cleanedFieldName = CustomFieldsUtils.deletePrefix(fieldName);
        const value = processedParams[cleanedFieldName] as [number, number] | undefined;
        const initialValue = filtersInitialValues[cleanedFieldName];

        if (value != null && initialValue != null && _.isEqual(value, initialValue)) {
            processedParams[cleanedFieldName] = undefined;
        }
    });

    return processedParams;
};

import React, {FC} from 'react';
import {NomenclaturesList} from "./list/nomenclaturesList";

interface NomenclaturesTabProps {}

export const NomenclaturesTab:FC<NomenclaturesTabProps> = (props) => {
    return (
        <NomenclaturesList />
    );
};

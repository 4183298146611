import React, { useEffect, useState } from 'react';
import { LegalDetailsInfoRead } from '../../../../server';
import { ExportSettings } from '../../../../shared/reducers/userSettings/userSettings.reducer';

export const useLegalDetailsValue = (
    legalDetails: LegalDetailsInfoRead[] | undefined,
    documentsLocalStorage: ExportSettings,
    showLegalDetails?: boolean
): [number | undefined, React.Dispatch<React.SetStateAction<number | undefined>>] => {
    const [legalDetailValue, setLegalDetailValue] = useState<number | undefined>(documentsLocalStorage.legalDetail);

    useEffect(() => {
        if (legalDetails == null || !showLegalDetails) {
            setLegalDetailValue(undefined);
            return;
        }

        if (!legalDetails.some(({ id }) => legalDetailValue === id)) {
            setLegalDetailValue(undefined);
            return;
        }

        if (legalDetails.length <= 1) {
            setLegalDetailValue(undefined);
            return;
        }
    }, [legalDetailValue, legalDetails, setLegalDetailValue, showLegalDetails]);

    return [legalDetailValue, setLegalDetailValue];
};

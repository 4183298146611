import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {
    serverApi,
    ServerError,
    SystemOptionInfoUpdate,
    SystemOptionsGroupRecord,
    SystemOptionsSectionReadInfo
} from "../../../../../server";
import {getServerError} from "../../../../../shared/util/utils";
import {IRootState} from "../../../../../shared/reducers";

export const ACTION_TYPES = {
    LOAD_OPTIONS: 'systemOptions/LOAD_OPTIONS',
    UPDATE_OPTIONS: 'systemOptions/UPDATE_OPTIONS',
    RESET_ERROR: 'systemOptions/RESET_ERROR',
    RESET_SETTINGS: 'systemOptions/RESET_SETTINGS'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    updating: false,
    updatingError: undefined as (undefined | ServerError),
    entities: null as (Array<SystemOptionsGroupRecord> | null)
};

export type SystemOptionsState = Readonly<typeof initialState>;

// Reducer

export default (state: SystemOptionsState = initialState, action): SystemOptionsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_OPTIONS):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_OPTIONS):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_OPTIONS): {
            let response: SystemOptionsSectionReadInfo = (action.payload.data as SystemOptionsSectionReadInfo);
            return {
                ...state,
                entities: response && response.systemOptionsGroups && response.systemOptionsGroups.records ? response.systemOptionsGroups.records : [],
                loading: false
            };
        }

        case REQUEST(ACTION_TYPES.UPDATE_OPTIONS):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.UPDATE_OPTIONS):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case SUCCESS(ACTION_TYPES.UPDATE_OPTIONS): {
            let response: SystemOptionsSectionReadInfo = (action.payload.data as SystemOptionsSectionReadInfo);
            return {
                ...state,
                entities: response && response.systemOptionsGroups && response.systemOptionsGroups.records ? response.systemOptionsGroups.records : [],
                updating: false
            };
        }

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined
            };

        case ACTION_TYPES.RESET_SETTINGS:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

// Actions

// Получение настроек
export const loadSettings = (sectionId:number) => {
    return (dispatch, getState) => {
        let businessAccountId:number = (getState() as IRootState).system.businessAccountId;
        return dispatch({
            type: ACTION_TYPES.LOAD_OPTIONS,
            payload: serverApi.getSystemOptionsSectionById(businessAccountId, sectionId)
        });
    };
};

// Изменение настроек
export const updateSettings = (sectionId:number, systemOptions: SystemOptionInfoUpdate[]) => {
    return (dispatch, getState) => {
        let businessAccountId:number = (getState() as IRootState).system.businessAccountId;
        return dispatch({
            type: ACTION_TYPES.UPDATE_OPTIONS,
            payload: serverApi.updateSystemOptionsBulk(businessAccountId, sectionId, {systemOptions})
        });
    };
};

// Сброс ошибок
export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});

export const resetSettings = () => ({
    type: ACTION_TYPES.RESET_SETTINGS
});


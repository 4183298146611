import React from 'react';
import PricingSchemePopoverContent from './pricingSchemePopoverContent';
import { Popover } from 'antd';
import { PricingSchemeTypeCodeEnum } from '../../../../server';

interface IProps {
    record: BaseRecordData;
    children?: any; //ReactNode | (() => ReactNode);
    placement?: any;
    trigger?: any;
    onClosePopover?: (visible: boolean) => void;
    arrowPointAtCenter?: boolean;
}

interface IState {
    visible: boolean;
    dataLoaded: boolean;
}

export interface BaseRecordData {
    id: number;
    name: string;
    typeCode?: PricingSchemeTypeCodeEnum;
}

export class PricingSchemePopover extends React.Component<IProps, IState> {
    private popoverRef;

    state = {
        visible: false,
        dataLoaded: false,
    };

    onVisibleChange = (visible: boolean) => {
        this.setState({ visible });
        if (this.props.onClosePopover) this.props.onClosePopover(visible);
        if (!visible) this.setState({ dataLoaded: false });
    };

    closeCB = () => {
        this.onVisibleChange(false);
    };

    onDataChangedCb = () => {
        if (this.popoverRef) {
            this.popoverRef.forceUpdate();
        }
        console.log('onDataChangedCb');
        this.setState({ dataLoaded: true });
    };

    getPopoverRef = (ref) => {
        if (!this.popoverRef) this.popoverRef = ref;
    };

    render() {
        return (
            <Popover
                ref={this.getPopoverRef}
                overlayClassName={'rr-grid-actions-popover'}
                overlayStyle={{ minWidth: 570, display: this.state.dataLoaded ? 'block' : 'none' }}
                trigger={this.props.trigger ? this.props.trigger : 'click'}
                onVisibleChange={this.onVisibleChange}
                visible={this.state.visible}
                autoAdjustOverflow
                arrowPointAtCenter={this.props.arrowPointAtCenter === undefined ? true : this.props.arrowPointAtCenter}
                placement={this.props.placement ? this.props.placement : 'bottomLeft'}
                destroyTooltipOnHide={true}
                content={
                    <PricingSchemePopoverContent record={this.props.record} closeCb={this.closeCB} onDataChangedCb={this.onDataChangedCb} />
                }
            >
                {typeof this.props.children === 'function' ? this.props.children() : this.props.children}
            </Popover>
        );
    }
}

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ExpenseInfoRead, ExpenseInfoUpdate } from '../../../../../server';
import { IRootState } from '../../../../../shared/reducers';
import { ModalActionData } from '../../../../../components/modalForm/utils';
import { connect } from 'react-redux';
import { expenseModalFormFields } from '../expenseModalFormFields';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { goBack } from 'connected-react-router';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { clearExpense, updateExpense } from '../../reducers/expense/expense.reducer';
import { ExpenseCreateModalFormData } from '../create/expenseCreateModal.types';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    expense: ExpenseInfoRead;
}

export class _ExpenseEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: expenseModalFormFields,
    };

    getInitialValues = () => {
        const { expense } = this.props;

        let initialValues: Partial<ExpenseCreateModalFormData> = {
            id: expense.id,
            projectId: expense.projectId,
            name: expense.name,
            internalFinalTotalPrice: expense.internalFinalTotalPrice,
            externalFinalTotalPrice: expense.externalFinalTotalPrice,
            state: expense.state,
            comment: expense.comment,
            includeTaxInExternalExpenseSum: Boolean(expense.includeTaxInExternalExpenseSum),
            date: expense.date,
        };

        return {
            ...initialValues,
            ...this.props.initialValues,
        };
    };

    onOk = (data: unknown) => {
        const formData = data as ExpenseCreateModalFormData;

        const expenseInfoUpdate: ExpenseInfoUpdate = {
            name: formData.name,
            internalFinalTotalPrice: formData.internalFinalTotalPrice,
            externalFinalTotalPrice: formData.externalFinalTotalPrice,
            projectId: this.props.expense.projectId,
            comment: formData.comment,
            businessVersion: this.props.expense.businessVersion,
            date: formData.date,
            includeTaxInExternalExpenseSum: Boolean(formData.includeTaxInExternalExpenseSum)
        };

        this.props.updateExpense({
            businessAccountId: this.props.businessAccountId,
            id: this.props.expense.id,
            expenseInfoUpdate: expenseInfoUpdate,
        });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.clearExpense();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.expense.updating,
    updatingError: storeState.expense.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    updateExpense,
    clearExpense,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ExpenseEditModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ExpenseEditModal));

import React, { memo } from 'react';
import { EntityGridDrawerWrapper } from '../../../../components/page/entityGrid/types/drawer';
import { WorkPlanningsInfoRead } from '../../../../server';
import { WorkPlanningsDrawer } from './workPlanningsDrawer';

export const WorkPlanningsDrawerWrapper: EntityGridDrawerWrapper<WorkPlanningsInfoRead> = memo((props) => {
    const { entityId, onClose, editActionForDrawer, isLoading } = props;

    return (
        <WorkPlanningsDrawer
            workPlanningsId={entityId}
            onClose={onClose}
            editWorkPlanning={editActionForDrawer}
            isEntityActionsLoading={isLoading}
        />
    );
});

import { ModalFormDisplayData } from '../../../../../../../components/modalForm/components/Header/types/displayData';
import { TransportationInfoRead } from '../../../../../../../server';
import { IModalFormFilters } from '../../../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import {
    allFieldsDisplayGroup,
    mainFieldsDisplayGroup,
} from '../../../../../../../components/modalForm/components/Header/data/displayData';

const displayData: ModalFormDisplayData<keyof TransportationInfoRead> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [
            ['project', 'stateCode'],
            ['dateTimeOfLoading', 'shipper', 'loadingLocation'],
            ['dateTimeOfUnloading', 'consignee', 'unloadingLocation'],
        ],
    },
];

export const createTransportationModalFilters: IModalFormFilters = {
    data: displayData,
    defaultValue: 'MAIN',
};

export const editTransportationModalFilters: IModalFormFilters = {
    data: displayData,
    defaultValue: 'ALL',
};

export abstract class DomUtils {
    static isClickInsideElement(element: HTMLElement, event: MouseEvent) {
        const { clientX: mouseX, clientY: mouseY } = event;

        const containerRect = element.getBoundingClientRect();

        if (containerRect.top > mouseY) return false;
        if (containerRect.bottom < mouseY) return false;
        if (containerRect.left > mouseX) return false;
        if (containerRect.right < mouseX) return false;

        return true;
    }
}

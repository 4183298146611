import React from 'react';
import { RangePickerProps, RangePickerValue } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import { LocalizationEnum, localize } from '../../localization';
import { DateTimePicker } from '../dateTimePicker/dateTimePicker';
import classNames from 'classnames';
import './FiltersDateTimeRangePicker.less';

export interface FiltersDateTimeRangePickerProps extends RangePickerProps {
    value?: RangePickerValue;
    defaultValue?: RangePickerValue;
    onChange?: (dates: RangePickerValue) => void;
    placeholder?: [any, any];
    utc?: boolean;
    theme?: 'light' | 'gray';
}

interface IState {
    value: RangePickerValue;
}

export class FiltersDateTimeRangePicker extends React.PureComponent<FiltersDateTimeRangePickerProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue || this.props.value || [],
        };
    }

    static getDerivedStateFromProps(nextProps: FiltersDateTimeRangePickerProps, prevState: IState) {
        if (nextProps) {
            return {
                value: nextProps.value,
            };
        } else return null;
    }

    onStartDateChange = async (value) => {
        const v = this.clearOffset([value, this.state.value ? this.state.value[1] : undefined]);
        await this.setState({ value: v });
        if (this.props.onChange) this.props.onChange(v);
    };

    onEndDateChange = async (value) => {
        const v = this.clearOffset([this.state.value ? this.state.value[0] : undefined, value]);
        await this.setState({ value: v });
        if (this.props.onChange) this.props.onChange(v);
    };

    clearOffset = (v: RangePickerValue) => {
        if (!this.props.utc) {
            return v;
        } else {
            if (v && v[0]) v[0] = v[0].clone().utcOffset(0, true);
            if (v && v[1]) v[1] = v[1].clone().utcOffset(0, true);
            return v;
        }
    };

    render() {
        const { utc, placeholder, theme = 'light' } = this.props;
        const { value } = this.state;

        const startDateValue = value && value[0] ? (utc ? moment.utc(value[0]) : moment(value[0])) : undefined;
        const endDateValue = value && value[1] ? (utc ? moment.utc(value[1]) : moment(value[1])) : undefined;

        return (
            <>
                <div style={{ display: 'inline-block' }} className={value && value[0] ? 'rr-filters-selected-filter' : ''}>
                    <DateTimePicker
                        style={{ width: 150 }}
                        placeholder={placeholder && placeholder[0] ? placeholder[0] : undefined}
                        value={startDateValue}
                        onChange={this.onStartDateChange}
                        align={{ points: ['tc', 'bc'], offset: [0, 6] }}
                        className={classNames({
                            'range-picker-gray': theme === 'gray',
                            'border-none': startDateValue == null,
                        })}
                    />
                </div>

                <div className="ant-form-item-label" style={{ verticalAlign: 'unset', lineHeight: 'unset', overflow: 'unset' }}>
                    <label htmlFor="startDate1" className="ant-form-item-no-colon" style={{ marginLeft: 10 }}>
                        {localize(LocalizationEnum.ASPECT__FILTERS__UPPER_DATE_LIMIT_LABEL)}
                    </label>
                </div>
                <div style={{ display: 'inline-block' }} className={value && value[1] ? 'rr-filters-selected-filter' : ''}>
                    <DateTimePicker
                        style={{ width: 150 }}
                        placeholder={placeholder && placeholder[1] ? placeholder[1] : undefined}
                        value={endDateValue}
                        onChange={this.onEndDateChange}
                        align={{ points: ['tc', 'bc'], offset: [0, 6] }}
                        className={classNames({
                            'range-picker-gray': theme === 'gray',
                            'border-none': endDateValue == null,
                        })}
                    />
                </div>
            </>
        );
    }
}

import {LocalizationEnum} from "../../localization";
import {useIntl} from "react-intl";
import {PrimitiveType} from "intl-messageformat";

export {
    LocalizationEnum
};

export type LocalizeFn = (id: LocalizationEnum, values?: Record<string, PrimitiveType>) => string;

export const useLocalize = () => {
    const intl = useIntl();
    const f: LocalizeFn = (id, values) => {
        return !id || (id && id.match(/[а-яА-Я]/)) ? id : intl.formatMessage({id: id}, values);
    };
    return f;
};

import { FC } from 'react';
import classNames from 'classnames';
import {
    ConditionalLink,
    ConditionalLinkInheritanceProps,
} from '../../../../../../../../../components/lib/ConditionalLink/ConditionalLink';
import { ItemContext, ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { CalendarId, CalendarLine as ICalendarLine } from '../../../../../Calendar/types/items';
import { CalendarLineUtils } from '../../../../utils/calendarLineUtils';
import {
    CALENDAR_LINE_BASE_RADIUS,
    CALENDAR_LINE_ROUND_RADIUS,
    CalendarDisplayType,
} from '../../../../../Calendar/utils/data/calendarConstants';
import { DisplayTypeItemContext } from '../../../../context/CalendarItemContext';

interface CalendarLineContainerProps extends ConditionalLinkInheritanceProps {
    calendarProps: ReactCalendarItemRendererProps<ICalendarLine>;
    displayType: CalendarDisplayType;
    borderRadius:
        | typeof CALENDAR_LINE_BASE_RADIUS
        | typeof CALENDAR_LINE_ROUND_RADIUS
        | `${typeof CALENDAR_LINE_ROUND_RADIUS}px 0 0 ${typeof CALENDAR_LINE_ROUND_RADIUS}px`
        | undefined;
    onItemClick: (itemId: CalendarId, itemContext: ItemContext) => void;
    linkPath: string | undefined;
}

export const CalendarLineContainer: FC<CalendarLineContainerProps> = (props) => {
    const { calendarProps, linkPath, displayType, borderRadius, onItemClick, children, ...linkProps } = props;
    const { item, itemContext, getItemProps } = calendarProps;

    const fontSize = CalendarLineUtils.map.fontSizeFromDisplayType[displayType];
    const itemProps = getItemProps(item.itemProps ?? {});

    return (
        <ConditionalLink
            {...linkProps}
            {...itemProps}
            condition={Boolean(linkPath)}
            to={linkPath}
            className={classNames(itemProps.className, 'calendar-line-item', linkProps.className)}
            style={{
                ...itemProps.style,
                fontSize,
                background: 'white',
                borderRadius,
                border: undefined,
                marginTop: 4,
                width: Math.max(1, itemContext.dimensions.width),
                ...linkProps.style,
            }}
            onClick={() => {
                onItemClick(item.id, itemContext);
            }}
        >
            <DisplayTypeItemContext.Provider value={displayType}>{children}</DisplayTypeItemContext.Provider>
        </ConditionalLink>
    );
};

import { serverApi } from '../../server';
import { getBusinessAccountId } from '../../../index';
import { getServerError } from './utils';
import { LocalizationEnum, localize } from '../../localization';

export const validateField = (
    type: Parameters<typeof serverApi.getValidationForField>[1] | 'pricingScheme' | 'expense' | 'workPlanning' | 'paymentMethod', // TODO pricingScheme нужно в yaml что б было прописано
    fieldName: string,
    value: string,
    parentId: number | undefined,
    ignoreCurrentId: number | undefined,
    errorMessage: any,
    cb: Function,
    cbFinally?: Function
) => {
    if (value) {
        serverApi
            .getValidationForField(getBusinessAccountId(), type as any, fieldName, value, ignoreCurrentId, parentId)
            .then((body) => cb())
            .catch((error) => {
                let e = getServerError(error);
                let err =
                    errorMessage && e && '' + e.status === '409'
                        ? errorMessage
                        : localize(LocalizationEnum.ASPECT__POPUP_NOTIFICATIONS__VALIDATION_ERROR);
                cb(err);
            })
            .finally(() => {
                if (cbFinally) cbFinally();
            });
    } else {
        cb();
        if (cbFinally) cbFinally();
    }
};

import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { formFields } from '../ContactModalFields';
import { IRootState } from '../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../components/modalForm/utils';
import { resetErrors } from '../../../../reducers/renters/renter.reducer';
import { goBack, push } from 'connected-react-router';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import {ContactInfoCreate, ContactInfoRead, SocialNetworkLinkAbstractInfo} from '../../../../../../../server';
import { UploadFile } from 'antd/lib/upload/interface';
import _ from 'lodash';
import { createContact } from '../../reducers/contact/contact.reducer';

import { RentersPageTabsEnum } from '../../../../../../../shared/constants/tabEnums';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    onClose?: (data?: ContactInfoRead) => void;
}

export interface CategoryFormData {
    comment?: string;
    email?: string;
    firstName: string;
    id?: number;
    isEdit?: boolean;
    isUser?: boolean;
    lastName: string;
    mainImage?: UploadFile<ContactInfoCreate['mainImage']>[];
    passport?: string;
    primaryPosition?: string;
    secondName?: string;
    socialNetworks?: SocialNetworkLinkAbstractInfo[];
}

export class _CategoryCreateModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = async (data: unknown) => {
        const formData = _.cloneDeep(data) as CategoryFormData;
        const imageResponse = formData?.mainImage?.[0]?.response;
        const mainImage = imageResponse
            ? {
                  id: imageResponse.id,
              }
            : undefined;

        const contactCreateData: ContactInfoCreate = {
            archive: false,
            ...formData,
            mainImage: mainImage,
        };

        try {
            const contact = await this.props.createContact({
                businessAccountId: this.props.businessAccountId,
                contactInfoCreate: contactCreateData,
            });

            if (contact) {
                this.props.onClose?.(contact);
                this.props.onSuccess?.(contact);
            }
        } catch (e) {}
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            if (this.props.onClose || this.props.onCancel) {
                this.props.onClose?.();
                this.props.onCancel?.();
            } else {
                this.props.initialValues
                    ? this.props.push(
                          `/${this.props.businessAccountId}/${RentersPageTabsEnum.CONTACTS}/${this.props.initialValues?.id}?tab=description`
                      )
                    : this.props.goBack();
            }
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.contact.updating,
    updatingError: storeState.contact.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    push,
    createContact,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const ContactCreateModal = connector(injectIntl(_CategoryCreateModal));

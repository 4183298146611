import { LocalizationEnum, localize } from '../../../../../../../localization';
import { ParamsUtils } from '../../../../../../../core/utils/paramsUtils';
import { push } from 'connected-react-router';
import React, { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { ContactCreateModal } from './ContactCreateModal';
import { createCategoryModalFilters } from './ContactCreateModalData';
import { ContactInfoRead } from '../../../../../../../server';
import { EntityGridCreateModalWrapper } from '../../../../../../../components/page/entityGrid/types/modal';

export const ContactCreateModalWrapper: EntityGridCreateModalWrapper<ContactInfoRead> = memo(({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <ContactCreateModal
            businessAccountId={businessAccountId}
            title={'Создание нового контакта'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            filters={createCategoryModalFilters}
            modalName={'create-contact'}
            onClose={() => {
                const path = `${rootPath}/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}`;
                dispatch(push(path));
            }}
        />
    );
});

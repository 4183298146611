import {ColumnTypes, TableColumn} from '../../../../components/grid/Table';
import React from 'react';
import {BAUserRecord, UserBARoleCodeEnum, UserStateCodeEnum} from '../../../../server/api';
import {rulesList} from '../../../../config/rules_ru';
import {LocalizationEnum, localize} from '../../../../localization';
import {getActionsColumnWidth} from '../../../../config/constants';
import {UsersModuleRoleSelectPopover} from './usersModuleRoleSelectPopover';
import {GridColumnCreator} from '../../../../components/grid/gridColumnCreator';
import {getStoreState} from '../../../../../index';
import {ActionButtonsTableGroup} from "../../../../components/v2/actionButtons/actionButtonsTableGroup/actionButtonsTableGroup";
import {userActionsMap, UserActionType} from "./usersModuleData";

// GRID COLUMNS
export const usersListColumns: TableColumn<BAUserRecord>[] = [
    GridColumnCreator.createUserEmailColumn<BAUserRecord>((row) => ({
        id: row.id,
        email: row.email,
        invited: row.stateCode === 'INVITED',
        baId: getStoreState().system.businessAccountId,
    })),
    GridColumnCreator.createUserColumn<BAUserRecord>(
        (row) => ({
            baId: getStoreState().system.businessAccountId,
            id: row.id,
            name: row.shortName,
            emptyValue: row.stateCode === 'INVITED' && localize(LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT),
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GLOBAL__PERSON_FULL_NAME),
            dataIndex: 'shortName',
        }
    ),
    GridColumnCreator.createStatusColumn(),
    {
        title: 'Роль',
        dataIndex: 'baRoles',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        render: (value: [string], rowData: BAUserRecord) => {
            return rowData.baRoles.map((role, index, arr) => {
                let roleObj = rulesList?.find((rule) => rule.value === role);
                let title = roleObj && roleObj.name ? roleObj.name : role;
                return (
                    <span key={index}>
                        {title}
                        {index < arr.length - 1 ? ', ' : ''}
                    </span>
                );
            });
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIONS),
        type: ColumnTypes.CustomRender,
        width: getActionsColumnWidth(3),
        minWidth: getActionsColumnWidth(3),
        maxWidth: getActionsColumnWidth(3),
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: BAUserRecord, onAction: (record: BAUserRecord, action: string) => void) => {
            let role: UserBARoleCodeEnum | undefined;
            if (rowData.baRoles) {
                if (rowData.baRoles.length === 1) role = rowData.baRoles[0];
                else {
                    if (rowData.baRoles.includes(UserBARoleCodeEnum.ADMIN)) role = UserBARoleCodeEnum.ADMIN;
                    else if (rowData.baRoles.includes(UserBARoleCodeEnum.AGENT)) role = UserBARoleCodeEnum.AGENT;
                    else if (rowData.baRoles.includes(UserBARoleCodeEnum.STOREKEEPER)) role = UserBARoleCodeEnum.STOREKEEPER;
                }
            }

            return (
                <ActionButtonsTableGroup
                    data={userActionsMap}
                    mainActions={[
                        rowData.stateCode === UserStateCodeEnum.INVITED ? UserActionType.invite : undefined,
                        {
                            type: UserActionType.edit,
                            popover: {
                                component: UsersModuleRoleSelectPopover,
                                props: {
                                    roles: rowData.baRoles || [],
                                    role: role,
                                    onChange:(role?: UserBARoleCodeEnum) => {
                                        if (onAction && role){
                                            onAction({...rowData, baRoles: [role]}, UserActionType.edit);
                                        }
                                    }
                                }
                            }
                        },
                        UserActionType.remove
                    ]}
                    onAction={(action) => {
                        onAction?.(rowData, action);
                    }}
                />
            );
        },
    },
];

import React, { FC, useState } from 'react';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../localization';
import { Button, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { localeCodes } from '../../../../../localization/localeCodes';
import { CountryCodeEnum } from '../../../../../server';
import classNames from 'classnames';
import {RR_OFFER_AGREEMENT_LINK} from "../../../../../config/constants";

interface RegistrationStepOneProps {
    nameValueInput: string | undefined;
    setNameValueInput: (value: string | undefined) => void;
    phoneValueInput: string | undefined;
    setPhoneValueInput: (value: string) => void;
    industriesValueInput: string | undefined;
    setIndustriesValueInput: (value: string | undefined) => void;
    promocodeValueInput: string | undefined;
    setPromocodeValueInput: (value: string) => void;
    industriesValueOk: boolean;
    setIndustriesValueOk: (value: boolean) => void;
    demoData: boolean | undefined;
    setDemoData: (value: boolean | undefined) => void;
    demoValueOk: boolean;
    setDemoValueOk: (value: boolean) => void;
    countryCode: CountryCodeEnum | undefined;
    setCountryCode: (value: CountryCodeEnum | undefined) => void;
    onChangeStep: () => void;
}

export const RegistrationStepOne: FC<RegistrationStepOneProps> = (props) => {
    const {
        nameValueInput,
        setNameValueInput,
        phoneValueInput,
        setPhoneValueInput,
        promocodeValueInput,
        setPromocodeValueInput,
        demoData,
        setDemoData,
        industriesValueInput,
        setIndustriesValueInput,
        industriesValueOk,
        setIndustriesValueOk,
        demoValueOk,
        setDemoValueOk,
        onChangeStep,
        countryCode,
        setCountryCode,
    } = props;
    const intl = useIntl();
    const [namePlaceholderInput, setPlaceholderValueInput] = useState<LocalizationEnum>(
        LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD__COMPANY_NAME
    );
    const [industriesInputOpen, setIndustriesInputOpen] = useState(false);
    const [demoInputOpen, setDemoInputOpen] = useState(false);
    const [tooltip, setTooltip] = useState(false);
    const [countryOpen, setCountryOpen] = useState(false);
    const regionNamesInEnglish = new Intl.DisplayNames(undefined, { type: 'region' });

    const onBlurInput = () => {
        setNameValueInput(nameValueInput?.trim());
        setPlaceholderValueInput(LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD__COMPANY_NAME);
    };

    const onFocusInput = () => {
        setPlaceholderValueInput(LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD__COMPANY_NAME);
    };

    const handleInputChange = (value) => {
        const name = value.target.value;

        setNameValueInput(name);
        if (name.trim() && phoneValueInput && industriesValueInput && demoValueOk) {
            setTooltip(false);
        }
    };

    const handleSelectChange = (value) => {
        setIndustriesValueOk(true);
        setIndustriesValueInput(value);
        if (nameValueInput && value && demoValueOk && phoneValueInput) {
            setTooltip(false);
        }
    };

    const onPhoneChange = (value) => {
        const name = value.target.value;

        setPhoneValueInput(name);
        if (name.trim() && nameValueInput && industriesValueInput && demoValueOk) {
            setTooltip(false);
        }
    };

    const onChange = (checked) => {
        setDemoValueOk(true);

        if (checked === 'NO') {
            setDemoData(false);
        } else if (checked === 'YES') {
            setDemoData(true);
        }

        if (nameValueInput?.trim() && industriesValueOk && checked && phoneValueInput) {
            setTooltip(false);
        }
    };

    const onPromoCodeChange = (e) => {
        setPromocodeValueInput(e.target.value || undefined);
    };

    const onBtnMove = () => {
        setTooltip(true);
    };

    const onBtnLeave = () => {
        setTooltip(false);
    };

    const isChangeStepDisabled =
        !nameValueInput || industriesValueOk == null || demoData == null || !phoneValueInput || countryOpen == null;

    return (
        <div className="form-wrap">
            <div className="rr-form-item">
                <Input
                    placeholder={
                        (localizeIntl(intl, namePlaceholderInput) as string) !== 'null'
                            ? (localizeIntl(intl, namePlaceholderInput) as string)
                            : ''
                    }
                    onChange={handleInputChange}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    maxLength={30}
                    value={nameValueInput}
                    style={
                        nameValueInput != null && nameValueInput?.trim() !== '' && nameValueInput?.trim() !== ' '
                            ? {
                                  borderColor: 'rgb(140, 221, 185)',
                                  backgroundColor: 'rgb(212, 248, 232)',
                              }
                            : undefined
                    }
                />
            </div>
            <div
                className={`rr-form-item ${industriesValueOk ? 'industriesValueOk' : ''} ${
                    industriesInputOpen ? 'rr-select-registration-open' : ''
                } `}
            >
                <div className={`${industriesInputOpen ? 'rr-select-registration-open' : ''}`}>
                    <Select
                        placeholder={
                            <span
                                className="rr-form-select-placeholder "
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD__INDUSTRY)}
                            </span>
                        }
                        value={industriesValueInput}
                        onSelect={handleSelectChange}
                        dropdownMenuStyle={{
                            border: '1px solid #d1d4da',
                            borderRadius: '0px 0px 5px 5px',
                            borderTop: 'none',
                        }}
                        defaultActiveFirstOption={false}
                        dropdownClassName={'company-registration-dropdown-menu'}
                        onDropdownVisibleChange={(value) => {
                            setIndustriesInputOpen(value);
                        }}
                        suffixIcon={
                            <b className={`rr-select-registration-icon ${industriesInputOpen ? 'rr-select-registration-icon-up ' : ''}`}>
                                &nbsp;&nbsp;
                            </b>
                        }
                        {...{
                            dropdownAlign: {
                                offset: [0, 0],
                            },
                        }}
                    >
                        <Select.Option value="AV" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__AV)}
                        </Select.Option>
                        <Select.Option value="SPORT" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__SPORT_INVENTORY)}
                        </Select.Option>
                        <Select.Option value="CLOTHES" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CLOTHES)}
                        </Select.Option>
                        <Select.Option value="PROPS" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__PROPS)}
                        </Select.Option>
                        <Select.Option value="EVENTS" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__EVENTS)}
                        </Select.Option>
                        <Select.Option value="HR" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__HR)}
                        </Select.Option>
                        <Select.Option value="CONSTRUCTION" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CONSTRUCTION)}
                        </Select.Option>
                        <Select.Option value="TRANSPORT" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__TRANSPORT)}
                        </Select.Option>
                        <Select.Option value="OTHER" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__OTHER)}
                        </Select.Option>
                    </Select>
                </div>
            </div>

            <div
                className={classNames('rr-form-item', {
                    industriesValueOk: countryCode != null,
                    'rr-select-registration-open': countryOpen,
                })}
            >
                <div className={`${countryOpen ? 'rr-select-registration-open' : ''}`}>
                    <Select
                        open={countryOpen}
                        onDropdownVisibleChange={(visible) => setCountryOpen(visible)}
                        value={countryCode}
                        onChange={(value) => {
                            setCountryCode(value);
                        }}
                        dropdownClassName={'company-registration-dropdown-menu'}
                        dropdownMenuStyle={{
                            border: '1px solid #d1d4da',
                            borderRadius: '0px 0px 5px 5px',
                            borderTop: 'none',
                        }}
                        defaultActiveFirstOption={false}
                        placeholder={
                            <span
                                className="rr-form-select-placeholder "
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Страна
                            </span>
                        }
                        {...{
                            dropdownAlign: {
                                offset: [0, 0],
                            },
                        }}
                    >
                        {localeCodes.map(({ code, name }) => (
                            <Select.Option value={code} className="rr-option-registration-menu">
                                {regionNamesInEnglish.of(code)}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>

            <div className={`rr-form-item ${demoValueOk ? 'industriesValueOk' : ''}`}>
                <div className={`${demoInputOpen ? 'rr-select-registration-open' : ''}`}>
                    <Select
                        placeholder={
                            <span className="rr-form-select-placeholder" style={{ display: 'flex', justifyContent: 'center' }}>
                                {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD__DEMO_DATA)}
                            </span>
                        }
                        onSelect={onChange}
                        dropdownClassName={'company-registration-dropdown-menu'}
                        dropdownMenuStyle={{
                            border: '1px solid #d1d4da',
                            borderRadius: '0px 0px 5px 5px',
                            borderTop: 'none',
                        }}
                        value={demoData != null ? (demoData ? 'YES' : 'NO') : undefined}
                        defaultActiveFirstOption={false}
                        onDropdownVisibleChange={(value) => {
                            setDemoInputOpen(value);
                        }}
                        suffixIcon={
                            <b className={`rr-select-registration-icon ${demoInputOpen ? 'rr-select-registration-icon-up ' : ''}`}>
                                &nbsp;&nbsp;
                            </b>
                        }
                        {...{
                            dropdownAlign: {
                                offset: [0, 0],
                            },
                        }}
                    >
                        <Select.Option value="YES" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD_OPTION__FILL_IN_THE_DEMO_DATA)}
                        </Select.Option>
                        <Select.Option value="NO" className="rr-option-registration-menu">
                            {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__FIELD_OPTION__DO_NOT_FILL_IN)}
                        </Select.Option>
                    </Select>
                </div>
            </div>

            <div className="rr-form-item">
                <Input
                    placeholder={'Номер телефона'}
                    onChange={onPhoneChange}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    maxLength={25}
                    value={phoneValueInput}
                    style={
                        phoneValueInput != null && phoneValueInput?.trim() !== '' && phoneValueInput?.trim() !== ' '
                            ? {
                                  borderColor: 'rgb(140, 221, 185)',
                                  backgroundColor: 'rgb(212, 248, 232)',
                              }
                            : undefined
                    }
                />
            </div>

            {/*<div className="rr-form-item">*/}
            {/*    <Input*/}
            {/*        placeholder={'Промокод'}*/}
            {/*        onChange={onPromoCodeChange}*/}
            {/*        onFocus={onFocusInput}*/}
            {/*        onBlur={onBlurInput}*/}
            {/*        maxLength={30}*/}
            {/*        value={promocodeValueInput}*/}
            {/*        style={*/}
            {/*            promocodeValueInput && promocodeValueInput.trim() !== '' && promocodeValueInput.trim() !== ' '*/}
            {/*                ? {*/}
            {/*                      borderColor: 'rgb(140, 221, 185)',*/}
            {/*                      backgroundColor: 'rgb(212, 248, 232)',*/}
            {/*                  }*/}
            {/*                : undefined*/}
            {/*        }*/}
            {/*    />*/}
            {/*</div>*/}

            {tooltip ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="tooltip">{localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__PLEASE_FILL_IN_ALL_THE_FIELDS)}</span>
                </div>
            ) : (
                <div style={{ height: '25px' }}></div>
            )}
            {isChangeStepDisabled ? (
                <div
                    className={`btn-green btn-gray btn-registration`}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    onMouseOver={onBtnMove}
                    onMouseOut={onBtnLeave}
                >
                    {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__BUTTON__CONTINUE)}
                </div>
            ) : (
                <Button
                    type="primary"
                    htmlType="submit"
                    className={`btn-green ${isChangeStepDisabled ? 'btn-gray' : ''} btn-registration`}
                    onClick={() => onChangeStep()}
                >
                    <span>{localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__BUTTON__CONTINUE)}</span>
                </Button>
            )}

            <div className="rr-company-registration-license">
                <span style={{ textAlign: 'center' }}>
                    {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__LEASE_AGREEMENT_LEGAL_STRING__START)}&nbsp;
                    <a href={RR_OFFER_AGREEMENT_LINK} target={'offer'}>
                        <span>{localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__LEASE_AGREEMENT_LEGAL_STRING__END)}</span>
                    </a>
                </span>
            </div>
        </div>
    );
};

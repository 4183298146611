import React, { FC } from 'react';
import { Icon } from 'antd';
import { IconCheck } from '../../../../../../../../components/icons';
import { Link } from 'react-router-dom';
import './CrewMemberLink.less';

interface CrewMemberLinkProps {
    link: string;
    linkText: string;
    items: string[];
}

export const CrewMemberLink: FC<CrewMemberLinkProps> = (props) => {
    const { link, linkText, items } = props;

    return (
        <div className={'crew-member-link-container'}>
            {items.length > 0 && (
                <span className={'crew-member-label-container'}>
                    <Icon component={IconCheck} className={'crew-member-icon'} />
                    <span className={'crew-member-label'}>
                        <b>{items[0]}</b>
                        {items.length > 1 ? ` и ещё ${items.slice(1).length}` : <></>}
                    </span>
                </span>
            )}
            <Link to={link} className={'crew-member-link'}>
                {linkText}
            </Link>
        </div>
    );
};

import { Card, Col, Icon, Row } from 'antd';
import { Avatar } from '../../../../../../../../../../../components/avatar/avatar';
import { getImagePath, getPathFromState } from '../../../../../../../../../../../shared/util/utils';
import { IconNoImage } from '../../../../../../../../../../../components/icons';
import { Status } from '../../../../../../../../../../../components/status/status';
import React, { CSSProperties, FC } from 'react';
import { ContactInfoRead, CrewMemberInfoRead } from '../../../../../../../../../../../server';
import { FormValueItem } from '../FormValueItem/FormValueItem';
import { Link, useLocation } from 'react-router-dom';
import Spin from '../../../../../../../../../../../components/spin/spin';
import { ImageUtils } from '../../../../../../../../../../../core/utils/imageUtils';

interface ContactBlockProps {
    crewMember: CrewMemberInfoRead;
    contact: ContactInfoRead | undefined;
    contactIsLoading: boolean;
}

const highlightStyles: CSSProperties = {
    border: '5px solid #3d64d4',
    borderRadius: '50%',
    boxSizing: 'content-box',
};

export const ContactBlock: FC<ContactBlockProps> = (props) => {
    const { crewMember, contact, contactIsLoading } = props;
    const location = useLocation();

    const linkToContact = (contact: ContactInfoRead) =>
        getPathFromState(location.pathname, location.search, {
            id: contact.id,
            modal: 'view',
        });

    const imageObj = contact?.mainImage ? ImageUtils.clearExternalIdForImageObj(contact.mainImage) : undefined;

    return (
        <Col xxl={6} xl={8} lg={10} span={24} style={{ marginBottom: 32 }}>
            <Spin spinning={contactIsLoading}>
                <Card bordered={false} className={'rr-card-without-padding'}>
                    {contact && (
                        <>
                            <Col style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                                <div
                                    style={{ padding: '12px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <span style={{ fontSize: 21 }}>Контакт</span>
                                    <Link to={linkToContact(contact)} style={{ color: '#525993', textDecoration: 'underline' }}>
                                        Смотреть контакт
                                    </Link>
                                </div>
                            </Col>
                            <div style={{ padding: 32, paddingTop: 30 }}>
                                <div style={{ textAlign: 'center', marginBottom: 28 }}>
                                    <Avatar
                                        className={'rr-avatar-big'}
                                        style={{
                                            backgroundColor: '#e2e5ec',
                                            verticalAlign: 'middle',
                                            ...(contact.isBusinessAccountRRUser ? highlightStyles : {}),
                                        }}
                                        src={getImagePath(imageObj, '158')}
                                        originalSrc={getImagePath(imageObj)}
                                    >
                                        <Icon component={IconNoImage} />
                                    </Avatar>
                                </div>

                                <div style={{ marginBottom: 9 }}>
                                    <Status entityType={'crewMember'} code={crewMember.stateCode} />
                                </div>

                                <Row>
                                    <Col className={'rr-form-item'}>
                                        <FormValueItem title={'Фамилия и инициалы'} value={contact.personShortName} tagWrapper={'h3'} />
                                    </Col>
                                    <Col className={'rr-form-item'}>
                                        <FormValueItem title={'Основная должность'} value={contact.primaryPosition} />
                                    </Col>
                                </Row>
                            </div>
                            <Row style={{ borderTop: '1px solid #eaeaea' }}>
                                <div style={{ padding: 32, paddingTop: 30 }}>
                                    <Col className={'rr-form-item'}>
                                        <FormValueItem title={'Номер телефона'} value={contact.phoneNumber} />
                                    </Col>
                                    <Col className={'rr-form-item'}>
                                        <FormValueItem title={'Email'} value={contact.email} />
                                    </Col>
                                </div>
                            </Row>
                        </>
                    )}
                </Card>
            </Spin>
        </Col>
    );
};

import { FileTypeCodeEnum, serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';

export interface NomenclaturePriceListGenerateArgs {
    businessAccountId: number;
    common: boolean;
    documentTemplateId: number;
    appliedDiscount: number;
    showDiscount: boolean;
    showPricingSchemes: boolean;
    showDescription: boolean;
    showCount: boolean;
    groupByCategories: boolean;
    filters?: Array<string>;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    search?: string;
    legalDetailId?: number;
}

export const generateNomenclaturePriceList = async (
    args: NomenclaturePriceListGenerateArgs,
    fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
): Promise<Blob> => {
    if (fileType === FileTypeCodeEnum.EXCEL) {
        const res: AxiosResponse<Blob> = await serverApi.generateNomenclaturePricelistXlsx(
            args.businessAccountId,
            args.common,
            args.documentTemplateId,
            args.appliedDiscount,
            args.showDiscount,
            args.showPricingSchemes,
            args.showDescription,
            args.showCount,
            args.groupByCategories,
            getCurrentTimezoneUTCOffset(),
            args.legalDetailId,
            undefined,
            1000,
            0,
            args.sortBy,
            args.sortOrder,
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    }

    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        const res: AxiosResponse<Blob> = await serverApi.generateNomenclaturePricelistPdf(
            args.businessAccountId,
            args.common,
            args.documentTemplateId,
            args.appliedDiscount,
            args.showDiscount,
            args.showPricingSchemes,
            args.showDescription,
            args.showCount,
            args.groupByCategories,
            getCurrentTimezoneUTCOffset(),
            args.legalDetailId,
            undefined,
            1000,
            0,
            args.sortBy,
            args.sortOrder,
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    }

    const _: never = fileType;
    return new Blob();
};

import React from 'react';
import { ProblemAndWarningRentIndicatorsRecordObj, RentElementInfoRead, RentStateCodeEnum } from '../../../../../server/api';
import { Col, Row } from 'antd';
import { HelpTooltip } from '../../../../../components/helpTooltip/HelpTooltip';
import { LocalizationEnum, localize } from '../../../../../localization';
import moment from 'moment';
import FormValue from '../../../../../components/formValue/FormValue';
import { formatPercents } from '../../../../../shared/util/formatPercents';
import { InstancesWidgetAnonymous } from './instances-widget/InstancesWidgetAnonymous';
import { InstancesWidget } from './instances-widget/InstancesWidget';
import { isDefined } from '../../../../../shared/util/utils';

interface IProps {
    shiftCount: number | undefined;
    calendarShiftCount: number | undefined;
    effectivePrice: number | undefined;
    discount: number | undefined;
    rentPeriodStartDate: any;
    rentPeriodEndDate: any;
    entity: RentElementInfoRead | null;
    closeCb: () => void;
    //entityId:number;
    numberInActivityFrame: number;
    withTrackedInstances: boolean | undefined;
    isProjectTemplate: boolean | undefined;
    problemsAndWarnings?: ProblemAndWarningRentIndicatorsRecordObj;
    showFinancialData: boolean;
}

const renderDate = (date: any, problem: ProblemAndWarningRentIndicatorsRecordObj | undefined, isColor, isEndDate: boolean = false) => {
    let typeColor;

    if ((problem?.bookShortage || problem?.subrentShipmentDelay || problem?.returnDelay) && isColor) {
        typeColor = '#F4516C';
    } else if ((problem?.giveawayDelay || problem?.subrentReturnToShipperDelay || problem?.orderShortage) && isColor) {
        typeColor = '#FC8C2F';
    } else {
        typeColor = undefined;
    }

    return [
        <span className="rr-value" style={{ color: typeColor }}>
            <span style={{ color: '#575962' }}>{isEndDate && ' → '}</span>
            {moment(date).format('DD.MM.YYYY')}{' '}
        </span>,

        <span className="rr-value-date-time" style={{ color: typeColor }}>
            {moment(date).format('HH:mm')}{' '}
        </span>,
    ];
};

export class ElementPopoverMainInfo extends React.PureComponent<IProps> {
    render() {
        const {
            shiftCount,
            discount,
            rentPeriodStartDate,
            rentPeriodEndDate,
            entity,
            numberInActivityFrame,
            withTrackedInstances,
            isProjectTemplate,
            calendarShiftCount,
        } = this.props;
        const stateCodeEntity = entity ? entity.stateCode : undefined;
        const rentPeriodStartDateProblem = stateCodeEntity !== RentStateCodeEnum.RENT && stateCodeEntity !== RentStateCodeEnum.SUBRENT;
        const rentPeriodEndDateProblem = stateCodeEntity === RentStateCodeEnum.RENT || stateCodeEntity === RentStateCodeEnum.SUBRENT;
        const subrentWorkedShiftCount = entity ? entity.subrentWorkedShiftCount : undefined;

        let numberLabel = 'Номер';
        if (entity?.projectId) numberLabel += ' в проекте';
        else if (entity?.subrentId) numberLabel += ' в поставке';
        else if (entity?.templateId) numberLabel += ' в шаблоне';

        const shortage =
            entity?.anonymousInstanceCount &&
            entity?.shortageInstanceCount >
                (entity?.instanceWithCurrentElementShortageIds ? entity?.instanceWithCurrentElementShortageIds.length : 0);

        return (
            <>
                {withTrackedInstances && (
                    <Row>
                        <Col className="rr-form-item" style={{ marginBottom: 18 }}>
                            <div className="rr-label">
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__018}>
                                    <span>{localize(LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCES)}</span>
                                </HelpTooltip>
                            </div>
                            <div style={{ minHeight: 49 }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                    {entity &&
                                        entity.instances &&
                                        entity.instances.map((item, index) => (
                                            <InstancesWidget
                                                key={index}
                                                closeElementPopup={this.props.closeCb}
                                                instanceWithCurrentElementShortageIds={entity?.instanceWithCurrentElementShortageIds || []}
                                                entity={item}
                                                element={entity}
                                            />
                                        ))}
                                    {entity && entity.anonymousInstanceCount > 0 && (
                                        <InstancesWidgetAnonymous
                                            key={-1}
                                            count={entity?.anonymousInstanceCount}
                                            shortage={shortage || false}
                                            shortageType={
                                                shortage ? (entity.stateCode === RentStateCodeEnum.ORDERED ? 'order' : 'book') : undefined
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {isProjectTemplate !== true && (
                    <Row>
                        <Col span={16} className="rr-form-item">
                            <div className="rr-label">
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__005}>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_BUSINESS__RENT_PERIOD)}</span>
                                </HelpTooltip>
                            </div>
                            <div>
                                {isDefined(rentPeriodStartDate) && isDefined(rentPeriodEndDate) ? (
                                    <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__005}>
                                        {
                                            <span>
                                                {renderDate(
                                                    rentPeriodStartDate,
                                                    this.props.problemsAndWarnings,
                                                    rentPeriodStartDateProblem
                                                )}
                                                {renderDate(
                                                    rentPeriodEndDate,
                                                    this.props.problemsAndWarnings,
                                                    rentPeriodEndDateProblem,
                                                    true
                                                )}
                                            </span>
                                        }
                                    </HelpTooltip>
                                ) : (
                                    <FormValue value={undefined} emptyText={'?'} />
                                )}
                            </div>
                        </Col>
                        <Col span={8} className="rr-form-item">
                            <div className="rr-label">
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__006}>
                                    <span>{numberLabel}</span>
                                </HelpTooltip>
                            </div>
                            <div className={'rr-value'}>
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__006}>
                                    <FormValue value={`#${numberInActivityFrame}`} />
                                </HelpTooltip>
                            </div>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col span={8} className="rr-form-item" style={{ marginBottom: 18 }}>
                        <div className="rr-label">
                            <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__008}>
                                <span>{localize(LocalizationEnum.ASPECT__GRID__COLUMN__SHIFT_COUNT)}</span>
                            </HelpTooltip>
                        </div>
                        <div className={'rr-value'}>
                            <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__008}>
                                <span>
                                    <FormValue
                                        value={shiftCount}
                                        originalValue={shiftCount}
                                        emptyText={'?'}
                                        style={shiftCount === 0 ? { color: '#575962', fontWeight: 'bold' } : {}}
                                    />{' '}
                                </span>
                            </HelpTooltip>
                            <span style={shiftCount !== calendarShiftCount ? { fontWeight: 400 } : { fontWeight: 400, color: '#cccccc' }}>
                                из <FormValue value={calendarShiftCount} originalValue={calendarShiftCount} emptyText={'?'} /> календ.
                            </span>
                        </div>
                    </Col>
                    {this.props.showFinancialData && (
                        <Col span={8} className="rr-form-item">
                            <div className="rr-label">
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__010}>
                                    <span>{localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT)}</span>
                                </HelpTooltip>
                            </div>
                            <div className={'rr-value'}>
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__010}>
                                    <span>
                                        <FormValue
                                            value={isDefined(discount) ? formatPercents(discount) : undefined}
                                            originalValue={discount}
                                            emptyText={'?'}
                                        />
                                    </span>
                                </HelpTooltip>
                            </div>
                        </Col>
                    )}
                    {entity?.subrentId && (
                        <Col span={8} className="rr-form-item">
                            <div className="rr-label">
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__010}>
                                    <span>Отработано смен</span>
                                </HelpTooltip>
                            </div>
                            <div className={'rr-value'}>
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__010}>
                                    <span>
                                        <FormValue
                                            value={subrentWorkedShiftCount}
                                            originalValue={subrentWorkedShiftCount}
                                            emptyText={'Неизвестно'}
                                        />
                                    </span>
                                </HelpTooltip>
                            </div>
                        </Col>
                    )}
                </Row>
            </>
        );
    }
}

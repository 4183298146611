import {createSlice} from "@reduxjs/toolkit";
import {ListLifecycleEventsState} from "./lifecycleEvents.types";

const initialState: ListLifecycleEventsState = {
    loading: false,
    loadingError: undefined,
    entities: undefined,
    filteredCount: 0,
}

const lifecycleEventsSlice = createSlice({
    name: "lifecycleEvents",
    initialState,
    reducers: {},
})

export default lifecycleEventsSlice.reducer
import React, { ReactNode, useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { ReportChartVisualizationRecord, serverApi } from '../../server';
import { AnalyticsPageUtils } from '../../modules/main/analytics/analyticsPageUtils';
import { Link } from 'react-router-dom';
import { navigate } from '../../core/navigate/navigate';
import { businessAccountIdSelector } from '../../shared/reducers/system.reducer';
import { useAppSelector } from '../../store/hooks';

export enum ReportReferenceTypeEnum {
    RENTER = 'RENTER',
    PRODUCT = 'PRODUCT',
    PROJECT = 'PROJECT',
    SUBRENT = 'SUBRENT',
}

interface Props {
    children?: ReactNode;
    referenceType: ReportReferenceTypeEnum;
    entityId: number;
}

export const ReportSelectDropdown = (props: Props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [data, setData] = useState(null as ReportChartVisualizationRecord[] | null);
    const [loadingError, setLoadingError] = useState<any>();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    useEffect(() => {
        if (dropdownVisible) {
            loadData();
        } else {
            setData(null);
        }
    }, [dropdownVisible]);

    const loadData = async () => {
        setLoadingError(null);

        serverApi
            .listBusinessAccountReportChartVisualizations(businessAccountId, undefined, 1000, 0, undefined, 'ASC', [
                `referenceType;EQ;${props.referenceType}`,
            ])
            .then((response) => {
                setData(response.data.records);
            })
            .catch((err) => {
                setLoadingError(err);
            });
    };

    const menu =
        data || loadingError ? (
            <Menu>
                {loadingError && <div className="rr-custom-select-error-block">Ошибка загрузки</div>}
                {data && data.length === 0 && <div>Ничего не найдено</div>}
                {data?.map((record, index) => {
                    let linkParams: navigate.AnalyticsReportPageParams0 | undefined;
                    if (props.referenceType === ReportReferenceTypeEnum.RENTER) {
                        linkParams = {
                            counterPartyId: props.entityId,
                        };
                    }
                    if (props.referenceType === ReportReferenceTypeEnum.PRODUCT) {
                        linkParams = {
                            productId: props.entityId,
                        };
                    }
                    if (props.referenceType === ReportReferenceTypeEnum.PROJECT) {
                        linkParams = {
                            projectId: props.entityId,
                        };
                    }
                    if (props.referenceType === ReportReferenceTypeEnum.SUBRENT) {
                        linkParams = {
                            subrentId: props.entityId,
                        };
                    }

                    return (
                        <Menu.Item key={index} className={'ant-select-dropdown-menu-item'}>
                            <Link to={navigate.toAnalyticsReport(businessAccountId, record.id, linkParams)}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {AnalyticsPageUtils.getReportTypeIconByCode(record.visualizationType, { marginRight: 10 })}
                                    <span>
                                        {record.reportConfigurationName}: {record.name}
                                    </span>
                                </div>
                            </Link>
                        </Menu.Item>
                    );
                })}
            </Menu>
        ) : (
            <></>
        );

    return (
        <Dropdown
            overlayClassName={'ant-select-dropdown'}
            placement={'bottomRight'}
            overlay={menu}
            trigger={['click']}
            overlayStyle={{ maxHeight: 270, overflowY: data && data.length > 8 ? 'auto' : undefined }}
            onVisibleChange={(visible: boolean) => {
                setDropdownVisible(visible);
            }}
            visible={dropdownVisible}
        >
            {props.children}
        </Dropdown>
    );
};

import React, { FC, RefObject, useMemo, useRef } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { accessibilityElementsCalendarFilters } from '../../../../filters/elements/accessibilityElementsCalendarFilters';
import { createFormItem } from '../../../../../../../../../components/dynamicForm/formUtils';
import { useIntl } from 'react-intl';
import _, { mapValues } from 'lodash';
import { accessibilityMapCalendarFiltersDescription } from '../../../../filters/accessibility/accessibilityMapCalendarFilters.description';
import { AccessibilityMapFilters } from '../../../../filters/accessibility/accessibilityMapCalendarFilters.types';
import { accessibilityMapCalendarPageName } from '../../../../AccessibilityMapCalendar';
import { AccessibilityElementsCalendarFilters } from '../../../../filters/elements/accessibilityElementsCalendarFilters.description';
import debounce from 'lodash/debounce';
import { ParamsUtils } from '../../../../../../../../../core/utils/paramsUtils';
import { setGridStorageDataFilters } from '../../../../../../../../../components/grid/utils';
import { push } from 'connected-react-router';
import { getPathFromState } from '../../../../../../../../../shared/util/utils';
import { useAppDispatch } from '../../../../../../../../../store/hooks';
import { useLocation } from 'react-router-dom';

export interface _AccessibilityCalendarGreyFormProps {
    pageParams: AccessibilityMapFilters;
    filtersContainerRef: RefObject<HTMLDivElement>;
    visible: boolean;
    form: WrappedFormUtils;
}

const _AccessibilityCalendarGreyForm: FC<_AccessibilityCalendarGreyFormProps> = (props) => {
    const { form, filtersContainerRef, pageParams, visible } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const initialValues = useRef({});
    const defaultValues = useMemo(() => mapValues(accessibilityMapCalendarFiltersDescription, () => undefined), []);

    const currentValues = useMemo(
        () => ({
            ...defaultValues,
            ...pageParams,
        }),
        [defaultValues, pageParams]
    );

    const onChange = useMemo(
        () =>
            debounce((params: AccessibilityElementsCalendarFilters) => {
                if ('getFieldsValue' in params) return;

                const newParams = _.cloneDeep(
                    ParamsUtils.getProcessedParamsWithSelectors({
                        pageParams: {
                            ...pageParams,
                            ...params,
                        },
                    })
                );

                setGridStorageDataFilters(accessibilityMapCalendarPageName, newParams);
                dispatch(push(getPathFromState(location.pathname, location.search, newParams)));
            }, 300),
        [dispatch, location.pathname, location.search, pageParams]
    );

    return (
        <Form layout={'inline'}>
            <div className={'rr-grid-smart_filters'} style={{ display: visible ? 'block' : 'none', marginBottom: 32 }}>
                <div className={'ant-form-inline'}>
                    {accessibilityElementsCalendarFilters[0].fields.map((item, index) =>
                        createFormItem(
                            item,
                            index,
                            {
                                form,
                                data: accessibilityElementsCalendarFilters,
                                intl,
                                onChange,
                                initialValues: initialValues.current,
                                currentValues,
                                defaultValues,
                                highlightChangedFields: true,
                            },
                            filtersContainerRef.current
                        )
                    )}
                </div>
            </div>
        </Form>
    );
};

export const AccessibilityCalendarGreyForm = Form.create<_AccessibilityCalendarGreyFormProps>()(_AccessibilityCalendarGreyForm);

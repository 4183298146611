import { RentStateCodeEnum } from '../../../../../../../server';

export const isItemSubrent = (stateCode: RentStateCodeEnum, subrentId: number | undefined) => {
    return (
        stateCode === RentStateCodeEnum.SUBRENT ||
        stateCode === RentStateCodeEnum.SUBRENTCANCELED ||
        stateCode === RentStateCodeEnum.SUBRENTDRAFT ||
        stateCode === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED ||
        stateCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER ||
        (stateCode === RentStateCodeEnum.MIXED && subrentId != null)
    );
};

import { UseFiltersChanges } from '../../../components/CalendarFilters/CalendarFilters';
import { AccessibilityMapFilters } from '../filters/accessibility/accessibilityMapCalendarFilters.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { useAppDispatch } from '../../../../../../store/hooks';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { ParamsUtils } from '../../../../../../core/utils/paramsUtils';
import { setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { push } from 'connected-react-router';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { accessibilityMapCalendarPageName } from '../AccessibilityMapCalendar';

export const useFiltersChanges: UseFiltersChanges<AccessibilityMapFilters> = (filtersForm: WrappedFormUtils | undefined) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    return useMemo(
        () =>
            debounce(async (params: AccessibilityMapFilters) => {
                const newParams = _.cloneDeep(
                    ParamsUtils.getProcessedParamsWithSelectors({
                        pageParams: params,
                    })
                );

                delete newParams.productInstanceTrackingTypeCode;

                setGridStorageDataFilters(accessibilityMapCalendarPageName, newParams);
                dispatch(push(getPathFromState(location.pathname, location.search, newParams)));
            }, 300),
        [dispatch, location.pathname, location.search]
    );
};

import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Icon } from 'antd';
import React from 'react';
import { PricingSchemesState } from './reducers/pricingSchemes.reducer';
import { FormUtils } from '../../../../core/utils/formUtils';
import { PricingSchemeMaps, PricingSchemeUtils } from '../../../../core/utils/pricingSchemeUtils';

export const pricingSchemesModuleListFilters: FormFieldsGroup[] = FormUtils.typedFormFields<PricingSchemesState['params']>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__TYPE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Select,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                values: (form) => {
                    const typeCodes = PricingSchemeUtils.getPricingSchemeCodes();
                    return typeCodes.map((typeCode) => {
                        return {
                            name: (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon
                                        className={'price-scheme-icon'}
                                        style={{ marginRight: 10, fontSize: 21 }}
                                        component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[typeCode]}
                                    />
                                    {PricingSchemeMaps.pricingSchemeTypeCodeToTitleMap[typeCode]}
                                </div>
                            ),
                            value: typeCode,
                        };
                    });
                },
            },
        ],
    },
]);

import React from 'react';
import RentElementPopoverContent from './customFieldsFieldPopoverContent';
import {Popover} from 'antd';

interface IProps {
    record: any;
    children?: any;//ReactNode | (() => ReactNode);
    placement?: any;
    trigger?: any;
    onClosePopover?: (visible: boolean) => void;
    arrowPointAtCenter?:boolean;
}

interface IState {
    visible: boolean;
}

export class CustomFieldsFieldPopover extends React.Component<IProps, IState> {

    state = {
        visible: false
    };

    componentDidMount = () => {
        console.log(`${this.constructor.name} componentDidMount()`);
    };

    onVisibleChange = (visible: boolean) => {
        this.setState({ visible });
        if (this.props.onClosePopover) this.props.onClosePopover(visible);
    };

    closeCB = () => {
        this.onVisibleChange(false);
    };

    render() {
        return (
            <Popover
                overlayClassName={
                    'rr-grid-actions-popover'
                }
                overlayStyle={{minWidth: 550}}
                trigger={this.props.trigger ? this.props.trigger : 'click'}
                onVisibleChange={this.onVisibleChange}
                visible={this.state.visible}
                autoAdjustOverflow
                arrowPointAtCenter={this.props.arrowPointAtCenter === undefined ? true : this.props.arrowPointAtCenter}
                placement={this.props.placement ? this.props.placement : 'bottomLeft'}
                content={
                    <RentElementPopoverContent
                        visible={this.state.visible}
                        record={this.props.record}
                        closeCb={this.closeCB}
                    />
                }>
                {typeof this.props.children === 'function' ? this.props.children() : this.props.children}
            </Popover>
        );
    }
}

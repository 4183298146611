import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import {
    ExpenseInfoRead,
    ExpenseListAggregations,
    ExpenseRecord,
    ExpenseStateCodeEnum,
    ProjectTypeCodeEnum,
    serverApi,
} from '../../../../server';
import { ListExpensesArgs, ListExpensesData, MoveExpensesToAnotherProjectArgs } from './expenses.api.types';
import { AxiosError } from 'axios';
import { showNotification } from '../../../../components/notification/showNotification';
import { ExpensesUtils } from './expenses.api.utils';
import { GetEntityByIdArgs } from '../../../../components/page/entityGrid/types/api';
import { ServerUtils } from '../../../../core/utils/serverUtils';
import { IRootState } from '../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { projectsPageUrlRoute } from '../../projects/projectsPage';
import { push } from 'connected-react-router';
import React from 'react';
import { ProjectPageTabsEnum, ProjectsPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { getPathFromState } from '../../../../shared/util/utils';

export const getExpenseByIdQueryFunction = async ({ businessAccountId, entityId }: GetEntityByIdArgs) => {
    try {
        return await serverApi.getExpense(businessAccountId, entityId);
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить затрату');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const expensesApi = createApi({
    reducerPath: 'expensesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ExpensesList', 'Expense'],
    endpoints: (builder) => ({
        listExpenses: builder.query<ListExpensesData, ListExpensesArgs>({
            queryFn: async ({ businessAccountId, params, projectId }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = ExpensesUtils.createRequestFilters(params);
                const sortBy = params.sortBy || undefined;
                const sortOrder = params.sortBy ? params.sortOrder : undefined;

                try {
                    const { data: expensesListAvailableFilters } = await serverApi.listExpenseAvailableFilters(
                        businessAccountId,
                        ServerUtils.createRequestFilters<ExpenseRecord>([projectId && ['projectId', 'EQ', projectId]])
                    );
                    const response = await serverApi.listExpenses(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        sortBy,
                        sortOrder,
                        params.search,
                        true,
                        false
                    );
                    return {
                        data: {
                            entitiesData: {
                                ...response.data,
                                records: response.data.records.map((item) => ({
                                    ...item,
                                    cancelled: item.state === ExpenseStateCodeEnum.CANCELED,
                                })),
                            },
                            availableIntervalFiltersData: {
                                values: expensesListAvailableFilters,
                                maps: {
                                    metrics: ExpensesUtils.pricePerShiftIntervalMetricsMap,
                                    formats: ExpensesUtils.pricePerShiftIntervalFormatMap,
                                },
                            },
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['ExpensesList'],
        }),
        fetchAggregations: builder.query<ExpenseListAggregations | undefined, ListExpensesArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = ExpensesUtils.createRequestFilters(params);
                const sortBy = params.sortBy || undefined;
                const sortOrder = params.sortBy ? params.sortOrder : undefined;

                try {
                    const response = await serverApi.listExpenses(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        sortBy,
                        sortOrder,
                        params.search,
                        false,
                        true
                    );
                    return {
                        data: response.data.aggregations,
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
        getExpenseById: builder.query<ExpenseInfoRead, GetEntityByIdArgs>({
            queryFn: getExpenseByIdQueryFunction,
            providesTags: ['Expense'],
        }),
        moveExpensesToAnotherProject: builder.mutation<void, MoveExpensesToAnotherProjectArgs>({
            queryFn: async (args, { dispatch, getState }) => {
                const { projectId, targetProjectId, copy, versionedEntityObjList, targetProjectType } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    const { data } = await serverApi.moveExpensesToOtherProject(
                        businessAccountId,
                        projectId,
                        targetProjectId,
                        copy,
                        versionedEntityObjList
                    );

                    const targetData: { title: string; url: string } | undefined = {
                        [ProjectTypeCodeEnum.OFFER]: {
                            title: 'Смета',
                            url: getPathFromState(
                                `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}/${targetProjectId}`,
                                '',
                                {
                                    tab: ProjectPageTabsEnum.EXPENSES,
                                }
                            ),
                        },
                        [ProjectTypeCodeEnum.BASE]: {
                            title: 'Проект',
                            url: getPathFromState(
                                `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${targetProjectId}`,
                                '',
                                {
                                    tab: ProjectPageTabsEnum.EXPENSES,
                                }
                            ),
                        },
                    }[targetProjectType ?? ''];

                    const successMessage =
                        targetData == null ? (
                            <span>Затраты успешно {copy ? 'скопированы' : 'перенесены'}</span>
                        ) : (
                            <span>
                                Затраты успешно {copy ? 'скопированы' : 'перенесены'} в{' '}
                                <a
                                    href={targetData.url}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(push(targetData.url));
                                    }}
                                    style={{
                                        color: '#fff',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {targetData.title}
                                </a>
                            </span>
                        );

                    showNotification('success', successMessage);

                    return {
                        data,
                    };
                } catch (error) {
                    const errorMessage = `Не удалось ${copy ? 'скопировать' : 'переместить'} затраты`;
                    showNotification('error', errorMessage);

                    return { error: error as AxiosError };
                }
            },
        }),
    }),
});

export const { useListExpensesQuery, useGetExpenseByIdQuery, useLazyFetchAggregationsQuery, useMoveExpensesToAnotherProjectMutation } =
    expensesApi;

import React, {ReactNode} from 'react';
import {BaseSelect, BaseSelectBaseProps, BaseSelectOptionProps, BaseSelectValue} from "../baseSelect";

interface V {
    id: number | string;
    icon?: ReactNode;
    label?: string;
    disabled?: boolean;
}

export interface SimpleSelectProps extends BaseSelectBaseProps<V> {
    values: V[];
    getOptionProps?: (record:V) => BaseSelectOptionProps;
}

const SimpleSelectFC = ({values, ...props}: SimpleSelectProps) => {

    const getEntitiesById = async (id: BaseSelectValue) => {
        return new Promise<V[]>((resolve) => {
            const ids = Array.isArray(id) ? id : [id];
            //setTimeout(()=>{
            resolve(values.filter((v) => ids.includes(v.id)));
            //}, 0);
        });
    };

    const getEntitiesList = (startFrom: number, limit: number, search?: string) => {
        return new Promise<Array<V>>((resolve) => {
            //setTimeout(()=>{
            let records: V[] = [];
            if (search) {
                records = values.filter(item => item.label && item.label.includes(search));
            } else {
                records = values;
            }
            resolve(records.slice(startFrom, limit));
            //}, 0);
        });
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={props.getOptionProps ? props.getOptionProps : (record) => ({
                disabled: record.disabled,
                label: record.label,
                children: (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: 6, fontSize: 21}}>{record.icon}</span>
                        <span>{record.label}</span>
                    </div>
                )
            })}
        />
    );
};

export class SimpleSelect extends React.Component<SimpleSelectProps> {
    render() {
        return (
            <SimpleSelectFC {...this.props} />
        );
    }
}

import { RentActivityFrameRecord, RentElementRecord, WorkPlanningGroupRecord, WorkPlanningsRecord } from '../../../../../../server';
import { useMemo } from 'react';
import _, { PropertyPath } from 'lodash';
import { CalendarId, CalendarItem, CalendarItemGroup, CalendarLine } from '../types/items';
import { useDeferredTime } from './useDeferredTime';
import { processItemGroup } from '../utils/processing/processItemGroup';

export const restItemsGroupId = -1;

export type ICalendarItem = RentElementRecord | RentActivityFrameRecord | WorkPlanningsRecord | WorkPlanningGroupRecord;
export type ICalendarItems = RentElementRecord[] | RentActivityFrameRecord[] | WorkPlanningsRecord[];
export type ProcessItemFunction<T extends ICalendarItem = ICalendarItem> = (props: {
    record: T;
    items: CalendarItem[];
    screenLeft: number;
    screenRight: number;
    groupIndex: {
        current: number;
    };
    collapsedKitsId: CalendarId[];
}) => void;

export type CalendarSortGroupMap<SortGroup extends string> = Partial<Record<SortGroup, string | string[]>>;

export const useCalendarItems = <T extends ICalendarItem, SortGroup extends string>(
    records: ICalendarItems | undefined,
    processItem: ProcessItemFunction<T>,
    screenLeft: number,
    screenRight: number,
    isLoading: boolean,
    collapsedKitsId: CalendarId[],
    collapsedGroupsId: CalendarId[],
    sortGroupMap: CalendarSortGroupMap<SortGroup>,
    group: SortGroup | undefined
): {
    items: CalendarLine[];
    visibleTimeStart: number;
    visibleTimeEnd: number;
} => {
    const [visibleTimeStart, visibleTimeEnd] = useDeferredTime(screenLeft, screenRight, isLoading);

    const items = useMemo(() => {
        if (records == null) return [];

        const items: CalendarLine[] = [];
        let groupIndex = {
            current: 1,
        };

        let currentGroupId: number | undefined = undefined;

        for (const record of records) {
            if (!group) {
                processItem({
                    record: record as T,
                    items: items as CalendarItem[],
                    screenLeft,
                    screenRight,
                    groupIndex,
                    collapsedKitsId,
                });
                continue;
            }

            let groupId: number | undefined = undefined;
            const sortId = sortGroupMap[group];

            if (Array.isArray(sortId)) {
                for (const id of sortId) {
                    const groupIdValue = _.get(record, id as PropertyPath);
                    if (groupIdValue != null) {
                        groupId = groupIdValue;
                        break;
                    }
                }
            } else {
                groupId = _.get(record, sortGroupMap[group] as PropertyPath);
            }

            const safeGroupId = groupId ?? restItemsGroupId;

            const isCollapsed = !collapsedGroupsId.includes(safeGroupId);

            if (sortId != null && safeGroupId !== currentGroupId) {
                processItemGroup({
                    groupId: safeGroupId,
                    groupIndex,
                    isCollapsed,
                    items: items as CalendarItemGroup[],
                    record,
                    screenLeft,
                    screenRight,
                    group,
                });
                currentGroupId = safeGroupId;
            }

            if (isCollapsed) {
                processItem({
                    record: record as T,
                    items: items as CalendarItem[],
                    screenLeft,
                    screenRight,
                    groupIndex,
                    collapsedKitsId,
                });
            }
        }

        return items;
    }, [collapsedGroupsId, collapsedKitsId, processItem, records, screenLeft, screenRight, group, sortGroupMap]);

    return useMemo(
        () => ({
            items,
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [items, visibleTimeEnd, visibleTimeStart]
    );
};

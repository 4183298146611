import { FC } from 'react';
import { Col, Row } from 'antd';
import { FormValueItem } from '../FormValueItem/FormValueItem';
import { CrewMemberInfoRead } from '../../../../../../../../../../../server';
import { formatMoney } from '../../../../../../../../../../../shared/util/formatMoney';

interface PricingBlockProps {
    entity: CrewMemberInfoRead;
}

export const PricingBlock: FC<PricingBlockProps> = (props) => {
    const { entity } = props;

    return (
        <Row gutter={16} style={{ padding: '32px 24px', borderBottom: '1px solid #eaeaea' }}>
            <Col>
                <Row>
                    <Col span={12}>
                        <FormValueItem title={'Ставка за смену'} value={formatMoney(entity.defaultRates.ratePerShift)} />
                    </Col>
                    <Col span={12}>
                        <FormValueItem title={'Ставка за час'} value={formatMoney(entity.defaultRates.ratePerHour)} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

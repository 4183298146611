import {ActionButtonsData} from "../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup";
import {BasicActionsData} from "../../../../components/v2/actionButtons/actionButton/actionButton";
import {PaymentMethodActionType} from "../paymentMethods/data/paymentMethodsData";

export enum PricingSchemeActionType {
    edit = 'edit',
    delete = 'delete'
}

export const pricingSchemeActionsMap: ActionButtonsData<PricingSchemeActionType> = {
    [PaymentMethodActionType.edit]: BasicActionsData.editAction,
    [PaymentMethodActionType.delete]: BasicActionsData.deleteAction
};

import React from 'react';
import {FormFieldsGroup, FormItemType, IFormField, SelectItem} from '../../../../components/dynamicForm/DynamicForm';
import {EntityRemoteSelect} from '../../../../components/select/EntityRemoteSelect';
import debounce from 'lodash/debounce';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import {validateField} from '../../../../shared/util/validateField';
import {
    ContractValidationTypeCodeEnum,
    CounterpartyActivityTypeCodeEnum,
    ProjectStateCodeEnum,
    RenterStateCodeEnum,
    serverApi,
    SubrentStateCodeEnum,
} from '../../../../server';
import {getBusinessAccountId, getStoreState} from '../../../../../index';
import {LocalizationEnum, localize} from '../../../../localization';
import {printUserName} from '../../../../shared/util/utils';
import {Dot} from '../../../../components/dot/dot';
import {toNumber} from '../../../../shared/util/utils5';
import {createHiddenField} from '../../../../components/modalForm/utils';
import {
    CounterpartySelectAndCreate,
    CounterpartySelectAndCreateProps,
} from '../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate';
import {RangePickerProps} from "../../../../components/v2/calendar/rangePicker/rangePicker";
import {CopyProjectParamsBlock} from "../../projects/production/components/copyProjectParamsBlock/copyProjectParamsBlock";
import {contractValidationTypeSelector} from "../../../../shared/reducers/businessAccountPreferences.reducer";

let responsible;

export const shortTitleField: IFormField = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
    id: 'shortName',
    type: FormItemType.String,
    required: true,
    maxLength: 50,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
        let supplierId = toNumber(form.getFieldValue('supplierId'));
        if (supplierId) {
            const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;
            validateField(
                'subrent',
                fieldName,
                value,
                supplierId,
                ignoreCurrentId,
                'Краткое наименование уже используется в текущем арендаторе',
                cb
            );
        } else cb();
    }, 500),
};

export const supplierField: IFormField = {
    label: 'Поставщик',
    id: 'supplierId',
    type: FormItemType.Component,
    required: true,
    component: CounterpartySelectAndCreate,
    disabled: (getFieldValue) => !!getFieldValue('id'),
    componentProps: (form: WrappedFormUtils): CounterpartySelectAndCreateProps => {
        return {
            counterpartyType: 'supplier',
            filters: {
                stateCode: [RenterStateCodeEnum.NEW, RenterStateCodeEnum.ACTIVE],
                activityTypeCode: [CounterpartyActivityTypeCodeEnum.SUPPLIERONLY, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER],
                archive: false,
            },
            getOptionProps: (record) => {
                const isNewStatus = record.stateCode === RenterStateCodeEnum.NEW;
                const disabled = isNewStatus && form.getFieldValue('stateCode') === ProjectStateCodeEnum.INPROGRESS;
                return {
                    disabled,
                    label: (
                        <>
                            {isNewStatus && <Dot className={`rr-status-bg-NEW`} style={{ marginRight: 10 }} />}
                            {record.shortName}
                        </>
                    ),
                };
            },
            onDataChange: async (data) => {
                const value = data?.[0];
                let supplierId = value?.id;
                //supplierId = supplierId && supplierId.key ? supplierId.key : undefined;

                form.setFieldsValue({ supplierStateCode: value?.stateCode });

                if (value?.stateCode === RenterStateCodeEnum.NEW) {
                    form.setFieldsValue({ stateCode: ProjectStateCodeEnum.DRAFT });
                }

                if (supplierId) {
                    let renter = await serverApi.getRenterById(getBusinessAccountId(), supplierId);
                    await form.setFieldsValue({ renterDefaultDiscount: renter.data.defaultDiscount });

                    if (value) {
                        if (form.getFieldValue('useRenterDefaultDiscount')) {
                            await form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') });
                        }

                        form.validateFields(['shortName']);
                        form.validateFields(['fullName']);

                        if (form.getFieldValue('useAssignee')) {
                            if (renter.data.assigneeFullName) {
                                await form.setFieldsValue({
                                    assigneeId: {
                                        key: renter.data.id,
                                        label: renter.data.assigneeFullName
                                            ? renter.data.assigneeFullName.lastname +
                                              (renter.data.assigneeFullName.firstname ? ' ' + renter.data.assigneeFullName.firstname : '')
                                            : '',
                                    },
                                });
                            } else {
                                await form.setFieldsValue({
                                    assigneeId: undefined,
                                });
                            }
                        }

                        await form.setFieldsValue({
                            contactsFIO: renter.data.contacts[0] ? (
                                (renter.data.contacts[0].fullName.lastname ? renter.data.contacts[0].fullName.lastname : '') +
                                ' ' +
                                (renter.data.contacts[0].fullName.firstname ? renter.data.contacts[0].fullName.firstname : '') +
                                ' ' +
                                (renter.data.contacts[0].fullName.secondname ? renter.data.contacts[0].fullName.secondname : '')
                            ) : (
                                <>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}</>
                            ),
                        });
                        responsible = renter.data.contacts[0] ? (
                            (renter.data.contacts[0].fullName.lastname ? renter.data.contacts[0].fullName.lastname : '') +
                            ' ' +
                            (renter.data.contacts[0].fullName.firstname ? renter.data.contacts[0].fullName.firstname : '') +
                            ' ' +
                            (renter.data.contacts[0].fullName.secondname ? renter.data.contacts[0].fullName.secondname : '')
                        ) : (
                            <>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}</>
                        );
                    }
                } else {
                    if (form.getFieldValue('useAssignee')) {
                        await form.setFieldsValue({
                            assigneeId: undefined,
                        });
                    }
                }
            },
            // operationName: 'listRenters',
            // nameField: 'shortName',
            // filters: [
            //     `stateCode;IN;${[RenterStateCodeEnum.NEW, RenterStateCodeEnum.ACTIVE].join(';')}`,
            //     `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.SUPPLIERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
            //     'archive;EQ;false',
            // ],
            // renderer: (data: { name: ReactNode; id: number; data?: RenterRecord }, index) => {
            //     const isNewStatus = data.data?.stateCode === RenterStateCodeEnum.NEW;
            //     const disabled = isNewStatus && form.getFieldValue('stateCode') === ProjectStateCodeEnum.INPROGRESS;
            //     return (
            //         <Select.Option key={data.id} disabled={disabled}>
            //             {isNewStatus && <Dot className={`rr-status-bg-NEW`} style={{ marginRight: 10 }} />}
            //             {data['name']}
            //         </Select.Option>
            //     );
            // },
        };
    },
    placeholder: 'Выберите поставщика',
    // onChange: async (value: any, form: WrappedFormUtils) => {
    //     let supplierId = value;
    //     supplierId = supplierId && supplierId.key ? supplierId.key : undefined;
    //
    //     form.setFieldsValue({ supplierStateCode: value?.data?.stateCode });
    //
    //     if (value?.data?.stateCode === RenterStateCodeEnum.NEW) {
    //         form.setFieldsValue({ stateCode: ProjectStateCodeEnum.NEW });
    //     }
    //
    //     if (supplierId) {
    //         let renter = await serverApi.getRenterById(getBusinessAccountId(), supplierId);
    //         await form.setFieldsValue({ renterDefaultDiscount: renter.data.defaultDiscount });
    //
    //         if (value) {
    //             await form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') });
    //
    //             form.validateFields(['shortName']);
    //             form.validateFields(['fullName']);
    //
    //             if (renter.data.assigneeFullName && renter.data.assigneeFullName) {
    //                 await form.setFieldsValue({
    //                     assigneeId: {
    //                         key: renter.data.id,
    //                         label: renter.data.assigneeFullName
    //                             ? renter.data.assigneeFullName.lastname +
    //                             (renter.data.assigneeFullName.firstname
    //                                 ? ' ' + renter.data.assigneeFullName.firstname
    //                                 : '')
    //                             : '', //label: renter.data.assigneeFullName ? renter.data.assigneeFullName.lastname : '' + ' ' + renter.data.assigneeFullName ? renter.data.assigneeFullName!.firstname : ''
    //                     },
    //                 });
    //             } else {
    //                 form.setFieldsValue({ assigneeId: undefined });
    //             }
    //
    //             await form.setFieldsValue({
    //                 contactsFIO: renter.data.contacts[0] ? (
    //                     (renter.data.contacts[0].fullName.lastname ? renter.data.contacts[0].fullName.lastname : '') +
    //                     ' ' +
    //                     (renter.data.contacts[0].fullName.firstname ? renter.data.contacts[0].fullName.firstname : '') +
    //                     ' ' +
    //                     (renter.data.contacts[0].fullName.secondname ? renter.data.contacts[0].fullName.secondname : '')
    //                 ) : (
    //                     <>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}</>
    //                 ),
    //             });
    //             responsible = renter.data.contacts[0] ? (
    //                 (renter.data.contacts[0].fullName.lastname ? renter.data.contacts[0].fullName.lastname : '') +
    //                 ' ' +
    //                 (renter.data.contacts[0].fullName.firstname ? renter.data.contacts[0].fullName.firstname : '') +
    //                 ' ' +
    //                 (renter.data.contacts[0].fullName.secondname ? renter.data.contacts[0].fullName.secondname : '')
    //             ) : (
    //                 <>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}</>
    //             );
    //         }
    //     }
    // },
};

export const statusField: IFormField = {
    label: LocalizationEnum.ASPECT__FILTERS__STATUS,
    placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
    id: 'stateCode',
    type: FormItemType.Select,
    defaultValue: SubrentStateCodeEnum.INPROGRESS,
    required: true,
    values: (form: WrappedFormUtils) => {
        let values: SelectItem[] = [];
        let supplierStateCode: string = form.getFieldValue('supplierStateCode');

        if (!form.getFieldValue('id')) {
            // Это создание
            const contractValidationType = contractValidationTypeSelector(getStoreState());
            if(contractValidationType === ContractValidationTypeCodeEnum.ONLYFILE || contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS){
                values = [
                    {
                        name: 'Черновик',
                        value: SubrentStateCodeEnum.DRAFT,
                        disabled: false,
                    }
                ];
            }else{
                values = [
                    {
                        name: 'Черновик',
                        value: SubrentStateCodeEnum.DRAFT,
                        disabled: false,
                    },
                    {
                        name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                        value: SubrentStateCodeEnum.INPROGRESS,
                        disabled: supplierStateCode === RenterStateCodeEnum.NEW,
                    },
                ];
            }
        } else if (form.getFieldValue('id')) {
            // Это редактирование
            values = [
                {
                    name: 'Черновик',
                    value: SubrentStateCodeEnum.DRAFT,
                    disabled: true,
                },
                {
                    name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                    value: SubrentStateCodeEnum.INPROGRESS,
                    disabled: true,
                },
                {
                    name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED),
                    value: SubrentStateCodeEnum.FINISHED,
                    disabled: true,
                },
            ];
        }

        return values.map((item) => ({
            ...item,
            name: (
                <>
                    <Dot className={`rr-status-bg-` + item.value} style={{ marginRight: 10 }} /> {item.name}
                </>
            ),
        }));
    },
    disabled: (getFieldValue) => !(!getFieldValue('id') && !getFieldValue('createFromOperationForm')),
    // onChange: (value: any, form: WrappedFormUtils) => {
    //     form.setFieldsValue({supplierId: undefined});
    // }
};

export const workPeriodField: IFormField = {
    label: 'Период работ',
    id: 'dates',
    type: FormItemType.DateRangePicker,
    componentProps: ():RangePickerProps => ({
        placeholder: ['Начало', 'Завершение'],
        style: {width: 360},
        pricingScheme: undefined
    }),
};

export const hasOwnContactsField: IFormField = {
    label: LocalizationEnum.PAGE__SHIPPINGS__FIELD_VALUE_BY_SUPPLIER,
    id: 'useHasOwnContacts',
    type: FormItemType.Switch,
    labelCol: { span: 5 },
    wrapperCol: { span: 12 },
    defaultValue: true,
    onChange: async (value: any, form: WrappedFormUtils) => {
        await form.setFieldsValue({ hasOwnContacts: !value });
        if (responsible) {
            await form.setFieldsValue({ contactsFIO: responsible });
        }
    },
};

export const contactsFIOField: IFormField = {
    label: LocalizationEnum.ASPECT__GLOBAL__PERSON_FULL_NAME,
    id: 'contactsFIO',
    maxLength: 60,
    disabled: true,
    type: FormItemType.String,
    // required: true,
    visible: (getFieldValue) => {
        return !getFieldValue('hasOwnContacts');
    },
};

export const bySupplierField: IFormField = {
    label: 'По поставщику',
    id: 'useAssignee',
    type: FormItemType.Switch,
    defaultValue: true,
    labelCol: { span: 5 },
    wrapperCol: { span: 12 },
    onChange: async (value: any, form: WrappedFormUtils) => {
        if (value) {
            if (form.getFieldValue('renterDefaultAssignee')) {
                form.setFieldsValue({ assigneeId: form.getFieldValue('renterDefaultAssignee') });
            } else {
                if (form.getFieldValue('supplierId')) {
                    let supplierId = form.getFieldValue('supplierId'); //.key;
                    let renter = await serverApi.getRenterById(getBusinessAccountId(), supplierId);

                    if (renter.data.assigneeFullName) {
                        form.setFieldsValue({
                            assigneeId: {
                                key: renter.data.id,
                                label: printUserName(renter.data.assigneeFullName),
                            },
                        });
                    } else {
                        form.setFieldsValue({ assigneeId: undefined });
                    }
                } else {
                    form.setFieldsValue({ assigneeId: undefined });
                }
            }
        } else {
            if (form.getFieldValue('defaultAssignee')) {
                form.setFieldsValue({ assigneeId: form.getFieldValue('defaultAssignee') });
            }
        }
    },
};

export const assigneeIdField: IFormField = {
    id: 'assigneeId',
    type: FormItemType.Component,
    component: EntityRemoteSelect,
    disabled: (getFieldValue) => getFieldValue('useAssignee') === true,
    componentProps: {
        operationName: 'listUsersSimple',
        nameField: 'title',
    },
    placeholder: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED),
};

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [{
            id: 'copyParams',
            type: FormItemType.Component,
            component: CopyProjectParamsBlock
        }],
        visible: (form) => {
            return form.getFieldValue('copyMode') === true;
        },
    },
    {
        fields: [
            {
                label: LocalizationEnum.PAGE__SHIPPINGS__FORM__FIELD__TEMPLATE,
                id: 'template',
                type: FormItemType.String,
                disabled: true,
                visible: (getFieldValue) => getFieldValue('fromTemplate') === true,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('fromTemplate') === true;
        },
    },
    {
        fields: [
            createHiddenField('copyMode'),
            createHiddenField('id'),
            createHiddenField('supplierStateCode'),
            createHiddenField('fromTemplate'),
            createHiddenField('projectEdit'),
            createHiddenField('createFromOperationForm'),
            shortTitleField,
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    let supplierId = toNumber(form.getFieldValue('supplierId'));
                    if (supplierId) {
                        const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;
                        validateField(
                            'subrent',
                            fieldName,
                            supplierId ? value : undefined,
                            supplierId,
                            ignoreCurrentId,
                            'Полное наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
            supplierField,
            statusField,
            workPeriodField,
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE),
        fields: [
            createHiddenField('renterDefaultAssignee'),
            createHiddenField('defaultAssignee'),
            createHiddenField('_defaultAssignee'),
            bySupplierField,
            assigneeIdField,
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CONTACT_PERSON),
        fields: [
            createHiddenField('hasOwnContacts'),
            hasOwnContactsField,
            {
                label: LocalizationEnum.ASPECT__GLOBAL__LASTNAME,
                id: 'contacts[0].fullName.lastname',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__FIRSTNAME,
                id: 'contacts[0].fullName.firstname',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__SECONDNAME,
                id: 'contacts[0].fullName.secondname',
                type: FormItemType.String,
                maxLength: 60,
                // required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__POSITION,
                id: 'contacts[0].position',
                type: FormItemType.String,
                maxLength: 60,
                // required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER,
                id: 'contacts[0].phoneNumber',
                type: FormItemType.String,
                maxLength: 25,
                // required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__EMAIL,
                id: 'contacts[0].email',
                type: FormItemType.String,
                maxLength: 60,
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^.+@.+\\..{2,}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
                    },
                ],
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            contactsFIOField,
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosResponse } from 'axios';
import {
    AvailableFiltersValuesListObj,
    InventoryMovementEventTypeCodeEnum,
    InventoryMovementInfoCreate,
    InventoryMovementInfoRead,
    InventoryMovementRecord,
    InventoryMovementRecordList,
    serverApi,
} from '../../../../server';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { ServerUtils } from '../../../../core/utils/serverUtils';
import { Moment } from 'moment';
import { ListParams } from '../../../../components/page/entityGrid/types/params';
import {InventoryMovementsApiUtils} from "./inventoryMovementsApi.utils";

export interface ListInventoryMovementsArgs {
    businessAccountId: number;
    params: ListInventoryMovementsParams;
}

export interface ListInventoryMovementsFilters {
    eventType?: InventoryMovementEventTypeCodeEnum[];
    eventDate?: [Moment | undefined, Moment | undefined];
    lastUpdateAuthorId?: number | number[];
    productId?: number | number[];
    variantId?: number | number[];
    instanceId?: number | number[];
    totalSum?: [number | undefined, number | undefined];
    counterpartyId?: number | number[];
    projectId?: number | number[];
    outOfOrder?: boolean;
    causedByRenter?: boolean;
    authorId?: number;
    screenLeft?: number;
    screenRight?: number;
}

export interface ListInventoryMovementsParams extends ListParams, ListInventoryMovementsFilters {}

interface ListInventoryMovementsAvailableFiltersValuesArgs {
    businessAccountId: number;
    productId?: number;
}

interface GetInventoryMovementByIdArgs {
    businessAccountId: number;
    id: number;
}

interface CreateInventoryMovementArgs {
    businessAccountId: number;
    inventoryMovementInfoCreate: InventoryMovementInfoCreate;
    attachmentInfoCreates?: Array<File>;
}

export const inventoryMovementsApi = createApi({
    reducerPath: 'inventoryMovementsApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        listInventoryMovements: builder.query<InventoryMovementRecordList, ListInventoryMovementsArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = (params.page - 1) * params.limit;
                const filters: string[] = InventoryMovementsApiUtils.createRequestFilters(params);/*ServerUtils.createRequestFilters<InventoryMovementRecord & { 'instances.instanceId': any }>([
                    params.eventType && ['eventType', 'IN', params.eventType],
                    params.eventDate && params.eventDate[0] && ['eventDate', 'GE', params.eventDate[0].valueOf()],
                    params.eventDate && params.eventDate[1] && ['eventDate', 'LE', params.eventDate[1].valueOf()],
                    params.lastUpdateAuthorId && ['lastUpdateAuthorId', 'EQ', String(params.lastUpdateAuthorId)],
                    params.productId && ['productId', 'EQ', String(params.productId)],
                    params.variantId && ['variantId', 'EQ', String(params.variantId)],
                    params.instanceId && [
                        'instances.instanceId',
                        'IN',
                        Array.isArray(params.instanceId) ? params.instanceId : [params.instanceId],
                    ],
                    params.totalSum && isDefined(params.totalSum[0]) && ['totalSum', 'GE', Math.round(params.totalSum[0] * 100)],
                    params.totalSum && isDefined(params.totalSum[1]) && ['totalSum', 'LE', Math.round(params.totalSum[1] * 100)],
                    params.counterpartyId && ['counterpartyId', 'EQ', String(params.counterpartyId)],
                    params.projectId && ['projectId', 'EQ', String(params.projectId)],
                    isDefined(params.outOfOrder) && ['outOfOrder', 'EQ', params.outOfOrder],
                    isDefined(params.causedByRenter) && ['causedByRenter', 'EQ', params.causedByRenter],
                    params.authorId && ['lastUpdateAuthorId', 'EQ', params.authorId],
                    params.screenLeft && ['eventDate', 'GE', params.screenLeft],
                    params.screenRight && ['eventDate', 'LE', params.screenRight],
                ]);*/

                const sortBy = InventoryMovementsApiUtils.getSortBy(params.sortBy);

                try {
                    const response = await serverApi.listInventoryMovements(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        sortBy,
                        params.sortOrder,
                        undefined
                    );
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
            extraOptions: {},
        }),
        listInventoryMovementsAvailableFiltersValues: builder.query<
            AvailableFiltersValuesListObj,
            ListInventoryMovementsAvailableFiltersValuesArgs
        >({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.listInventoryMovementsAvailableFiltersValues(
                        params.businessAccountId,
                        ServerUtils.createRequestFilters<InventoryMovementRecord>([
                            params.productId && ['productId', 'EQ', params.productId],
                        ])
                    );
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
        }),
        getInventoryMovementById: builder.query<InventoryMovementInfoRead, GetInventoryMovementByIdArgs>({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.getInventoryMovementById(params.businessAccountId, params.id);
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
        }),
        createInventoryMovement: builder.mutation<InventoryMovementInfoRead, CreateInventoryMovementArgs>({
            queryFn: async (params) => {
                try {
                    let response: AxiosResponse<InventoryMovementInfoRead> | undefined;
                    if (!params.attachmentInfoCreates || params.attachmentInfoCreates.length === 0) {
                        response = await serverApi.createInventoryMovement(params.businessAccountId, params.inventoryMovementInfoCreate);
                    } else {
                        response = await serverApi.createInventoryMovementWithAttachments(
                            params.businessAccountId,
                            new Blob([JSON.stringify(params.inventoryMovementInfoCreate)], {
                                type: 'application/json',
                            }) as any,
                            params.attachmentInfoCreates
                        );
                    }
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
        }),
    }),
});

export const {
    useListInventoryMovementsQuery,
    useListInventoryMovementsAvailableFiltersValuesQuery,
    useGetInventoryMovementByIdQuery,
    useCreateInventoryMovementMutation,
} = inventoryMovementsApi;

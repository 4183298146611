import React from 'react';
import {useSelector} from "react-redux";
import {IRootState} from "../../../../shared/reducers";
import {SimplePopover} from "../../../../components/v2/simplePopover/simplePopover";
import {ConcurrentOperationsList} from "./concurrentOperationsList";
import {Icon} from "antd";
import {IconExchange} from "../../../../components/icons";

export const ConcurrentOperationsPopover = () => {

    const currentOperationUUID = useSelector((state: IRootState) => state.operationForm.currentOperationUUID);
    const concurrentOperations = useSelector((state: IRootState) => state.operationForm.concurrentOperations.entities)?.filter((o) => o.uuid !== currentOperationUUID);

    return (
        concurrentOperations && concurrentOperations.length > 0 ? (
            <SimplePopover
                content={<div>
                    <ConcurrentOperationsList data={concurrentOperations}/>
                </div>}
            >
                <div className={'rr-page-header-right'} style={{fontWeight: 500, color: '#f12143'}}>
                    <Icon component={IconExchange}/>
                    {(concurrentOperations.length > 1 ? <>Параллельные<br/>операции
                        ({concurrentOperations.length})</> : <>Параллельная<br/>операция</>)}
                </div>
            </SimplePopover>
        ) : null
    );
};

import React, { FC, lazy, Suspense } from 'react';
import { VariantInfoRead } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Link } from 'react-router-dom';
import { Row } from 'antd';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

const DescriptionCustomField = lazy(() => import('../../../components/DescriptionCustomField/DescriptionCustomField'));

interface ProductVariantPopoverContentFeaturesTabProps {
    entity?: VariantInfoRead;
    onLinkClick: () => void;
}

export const ProductVariantPopoverContentFeaturesTab: FC<ProductVariantPopoverContentFeaturesTabProps> = ({ entity, onLinkClick }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <Row className="tab-popap-instance-row">
            <div>
                <div style={{ minHeight: '180px' }}>
                    {entity?.customFields?.length ? (
                        entity.customFields.map((field, index) => {
                            return (
                                <div key={index} className={'rr-product-page-features-view-item'}>
                                    <div className={'rr-product-page-features-view-item-name'}>
                                        <div className={'test'}>
                                            <span>{field.name}</span>
                                        </div>
                                    </div>
                                    {
                                        <div
                                            className={
                                                'rr-product-page-features-view-item-value' +
                                                (field.values && field.values.length ? '' : '-empty')
                                            }
                                        >
                                            <Suspense fallback={null}>
                                                <DescriptionCustomField field={field} />
                                            </Suspense>
                                        </div>
                                    }
                                </div>
                            );
                        })
                    ) : (
                        <div
                            key={0}
                            style={{
                                color: '#aaaaaa',
                                fontSize: 16,
                            }}
                        >
                            {localize(LocalizationEnum.PAGE__PRODUCTS__FEATURE_LIST_IS_EMPTY)}
                        </div>
                    )}
                </div>
                <div key={-1}>
                    <Link
                        style={{ textDecoration: 'underline', color: '#525993' }}
                        to={`/${businessAccountId}/inventory/products/${entity ? entity.productId : undefined}?tab=features`}
                        onClick={(e) => onLinkClick()}
                    >
                        {localize(LocalizationEnum.PAGE__PRODUCTS__PRODUCT_FEATURES)}
                    </Link>
                </div>
            </div>
        </Row>
    );
};

import React, { FC } from 'react';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { WorkPlanningsCalendarItem } from '../../../../../components/Calendar/types/items';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconBriefcase } from '../../../../../../../../components/icons';
import { projectsPageUrlRoute } from '../../../../../../projects/projectsPage';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';

export const ProjectGroupLine: FC<GroupItemProps<WorkPlanningsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const {
        record: { projectShortName, projectId },
    } = groupItem;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const title = projectShortName;

    const link = projectShortName ? `/${businessAccountId}/${projectsPageUrlRoute}/production/${projectId}` : undefined;

    return title ? (
        <GroupLine
            IconComponent={IconBriefcase}
            displayType={displayType}
            title={{
                text: title,
                link,
            }}
        />
    ) : null;
};

import React, { FC } from 'react';
import { Icon } from 'antd';
import { IconCube, IconTags, IconToolsSolid } from '../../../../../components/icons';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';

interface TabsExtraContentProps {
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    maintenanceProductsCount: number;
    maintenanceInstancesCount: number;
}

export const TabsExtraContent: FC<TabsExtraContentProps> = (props) => {
    const { responsive, maintenanceProductsCount, maintenanceInstancesCount } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <div style={{ display: 'flex', height: '44px' }}>
            <div className="dashboard-icon">
                <Icon component={IconToolsSolid} style={{ fontSize: '22px' }} />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    padding: '0px 15px',
                }}
            >
                <Link
                    to={`/${businessAccountId}/inventory/products?sortBy=warehousePresenceCounters.underMaintenanceInstanceCount&sortOrder=DESC&hideArchive=false`}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {responsive === 'xs' || responsive === 'sm' || responsive === 'md' ? null : <>На обслуживании</>}
                    <div
                        style={{
                            marginLeft: responsive === 'xs' || responsive === 'sm' || responsive === 'md' ? '0px' : '32px',
                            paddingRight: '17px',
                        }}
                    >
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <span className="rr-value" style={{ marginRight: '10px' }}>
                                <Icon component={IconTags} className={'rr-dashboard-info-panel-icon'} />
                                {maintenanceProductsCount}{' '}
                            </span>
                            <span className="rr-empty-value">
                                <Icon component={IconCube} className={'rr-dashboard-info-panel-icon'} />
                                {maintenanceInstancesCount}
                            </span>
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    );
};

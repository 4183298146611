import { rentersPageUrlRoute } from '../../../../renters/data/tabs';
import { ContactInfoRead } from '../../../../../../server';
import { AppDispatch } from '../../../../../../../index';
import { push } from 'connected-react-router';
import { PageUtils } from '../../../../../../shared/util/pageUtils';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { contactsApi } from '../../../../renters/tabs/contacts/api/contactsApi';

const getUrlMainPath = (businessAccountId: number) => `/${businessAccountId}/${rentersPageUrlRoute}` as const;

export const editContactEntity = (contact: ContactInfoRead, dispatch: AppDispatch, businessAccountId: number) => {
    const urlMainPath = getUrlMainPath(businessAccountId);

    dispatch(push(PageUtils.getPathForPushOnPageTab(urlMainPath, RentersPageTabsEnum.CONTACTS)));
    dispatch(
        push(
            PageUtils.getPathForPushOnPageTab(urlMainPath, RentersPageTabsEnum.CONTACTS, {
                modal: 'edit',
                id: String(contact.id),
            })
        )
    );
};

export const editContactEntityForClass = (contact: ContactInfoRead) => (dispatch, getState) => {
    const businessAccountId = businessAccountIdSelector(getState() as IRootState);

    editContactEntity(contact, dispatch, businessAccountId);
};

export const archiveContactEntity = (contact: ContactInfoRead, dispatch: AppDispatch, businessAccountId: number) => {
    dispatch(
        contactsApi.endpoints.archiveContacts.initiate({
            workflowDirectiveBulk: {
                directives: [
                    {
                        id: contact.id,
                        archive: !contact.archive,
                        businessVersion: contact.businessVersion,
                    },
                ],
            },
        })
    );
};

export const archiveContactEntityForClass = (contact: ContactInfoRead) => (dispatch, getState) => {
    const businessAccountId = businessAccountIdSelector(getState() as IRootState);

    archiveContactEntity(contact, dispatch, businessAccountId);
};

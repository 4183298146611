import {SocialNetworkTypeCodeEnum, UserBARoleCodeEnum} from '../../../../../../server';
import {IconCebookSquare, IconGlobeSolid, IconInstagram, IconTelegram, IconVk} from "../../../../../../components/icons";
import {IconProps} from "antd/es/icon";

interface SocialNetworkIconsMapObj {
    iconComponent: IconProps['component'];
    className: string;
}

export abstract class ContactMaps {
    static businessAccountRoleMap: Record<UserBARoleCodeEnum, string> = {
        [UserBARoleCodeEnum.AGENT]: 'Агент',
        [UserBARoleCodeEnum.ADMIN]: 'Администратор',
        [UserBARoleCodeEnum.STOREKEEPER]: 'Кладовщик',
    };

    static socialNetworksMap: Record<SocialNetworkTypeCodeEnum, string> = {
        [SocialNetworkTypeCodeEnum.TELEGRAM]: 'Telegram',
        [SocialNetworkTypeCodeEnum.VK]: 'VK',
        [SocialNetworkTypeCodeEnum.INSTAGRAM]: 'Instagram',
        [SocialNetworkTypeCodeEnum.FACEBOOK]: 'Facebook',
        [SocialNetworkTypeCodeEnum.INTERNETPAGE]: 'Сайт',
    }

    static socialNetworkIconsMap: Record<SocialNetworkTypeCodeEnum, SocialNetworkIconsMapObj> = {
        [SocialNetworkTypeCodeEnum.TELEGRAM]: {
            iconComponent: IconTelegram,
            className: 'telegram'
        },
        [SocialNetworkTypeCodeEnum.VK]: {
            iconComponent: IconVk,
            className: 'vk'
        },
        [SocialNetworkTypeCodeEnum.INSTAGRAM]: {
            iconComponent: IconInstagram,
            className: 'instagram'
        },
        [SocialNetworkTypeCodeEnum.FACEBOOK]: {
            iconComponent: IconCebookSquare,
            className: 'facebook'
        },
        [SocialNetworkTypeCodeEnum.INTERNETPAGE]: {
            iconComponent: IconGlobeSolid,
            className: 'website'
        },
    }
}

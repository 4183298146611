import { ElementsCalendarFilters } from '../filters/elementsCalendarFilters.types';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { getStringServerProblem } from '../../../../../../shared/util/utils';
import { ElementsCalendarApiUtilsFilters } from './elementsCalendar.api.utils.filters';
import { ProblemEnum } from '../../../../../../types';
import { defaultScreenRight } from '../../../components/Calendar/utils/data/calendarBoundaries';
import { ElementsCalendarSortValue } from '../utils/data/groupsData';

export abstract class ElementsCalendarApiUtils {
    static getSortBy = (sortGroup?: ElementsCalendarSortValue) => {
        const defaultValue = 'lastActivityDate';
        if (sortGroup == null) return defaultValue;

        const sortByValues: Required<Record<ElementsCalendarSortValue, string>> = {
            category: 'mainCategoryName',
            project: 'activityFrameId',
            renter: 'counterpartyId',
            product: 'productId',
            stateCode: 'stateCode',
            endDate: 'rentTerms.rentPeriodEndDate',
            startDate: 'rentTerms.rentPeriodStartDate',
        };

        return sortByValues[sortGroup] ?? defaultValue;
    };
    static getSortOrder = (sortByValue: string): 'DESC' | 'ASC' => {
        const descValues = ['lastActivityDate', 'stateCode'];

        if (descValues.includes(sortByValue)) return 'DESC';

        return 'ASC';
    };
    static getFilters = (params: ElementsCalendarFilters, subrentModuleEnabled: boolean | undefined): string[] => {
        const {
            capture,
            hide,
            instanceId,
            kitId,
            operationId,
            problem,
            problemInstances,
            productId,
            projectId,
            renterId,
            screenRight,
            subrentId,
            typeCode,
            variantId,
            categoryIds,
        } = params;

        const hideStateCodeFilter = ElementsCalendarApiUtilsFilters.getHideStateCodeFilter(hide);
        const typesCodeFilter = ElementsCalendarApiUtilsFilters.getTypeCodesFilter(typeCode, subrentModuleEnabled);

        const additionalFilters: string[] = [];
        if (
            problem &&
            (subrentModuleEnabled ||
                (!subrentModuleEnabled &&
                    ![ProblemEnum.SUBRENT_SHIPMENT_DELAY, ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY].includes(problem)))
        ) {
            additionalFilters.push(getStringServerProblem(problem));
        }

        return ServerUtils.createRequestFilters([
            capture !== 'all' && capture !== 'allWithNow' && ['rentTerms.rentPeriodStartDate', 'LE', screenRight ?? defaultScreenRight()],
            hideStateCodeFilter != null && ['hideStateCode', 'IN', hideStateCodeFilter],
            kitId != null && ['kitId', 'EQ', kitId],
            problemInstances != null &&
                instanceId != null &&
                problem != null &&
                ([ProblemEnum.ANY_SHORTAGE, ProblemEnum.ORDER_SHORTAGE, ProblemEnum.BOOK_SHORTAGE] as string[]).includes(problem) && [
                    'instanceWithCurrentElementShortageIds',
                    'EQ',
                    instanceId,
                ],
            renterId != null && ['counterpartyId', 'EQ', renterId],
            projectId != null && ['projectId', 'EQ', projectId],
            subrentModuleEnabled && subrentId != null && ['subrentId', 'EQ', subrentId],
            instanceId != null && ['instanceIds', 'IN', [instanceId]],
            operationId != null && ['operationIds', 'IN', [operationId]],
            productId != null && ['productId', 'EQ', productId],
            variantId != null && ['variantId', 'EQ', variantId],
            typesCodeFilter != null && ['stateCode', 'IN', typesCodeFilter],
            categoryIds != null && ['categoryIds', 'IN', Array.isArray(categoryIds) ? categoryIds : [categoryIds]],
            ['projectIsOffer', 'EQ', false],
            ['templateId', 'EQ', -1],
        ]).concat(additionalFilters);
    };
}

import { IRootState } from '../../../../shared/reducers';
import { createTemplate, resetError, updateEntity } from './reducers/template.reducer';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { RenterInfoRead, TemplateInfoCreate, TemplateInfoRead, TemplateInfoUpdate } from '../../../../server/api';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { formFields } from './templateCreateModalFields';
import { LabeledValue } from 'antd/lib/select';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { AxiosResponse } from 'axios';
import { showNotification } from '../../../../components/notification/showNotification';
import { logEvent } from '../../../../analytics/analytics';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
    successCallback?: (data: TemplateInfoRead) => void;
    renter?: LabeledValue;
    copyMode?: boolean;
    validateAfterCreate?: boolean;
    renterObj?: RenterInfoRead | null;
    autoActivate?: boolean;
}

class _TemplateCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };
    onOk = (data: any) => {
        delete data['_defaultDiscount'];
        delete data['renterDefaultDiscount'];

        if (this.props.initialValues) {
            if (!this.props.copyMode) {
                // Редактирование
                let newData: TemplateInfoUpdate = { ...this.props.initialValues, ...data } as TemplateInfoUpdate;

                if (newData.description === '') delete newData.description;
                if (newData.comment === '') delete newData.comment;

                delete newData['useDefaultDiscount'];

                // this.contactNotEmptyString(newData);
                if (newData.fullName === '') {
                    newData.fullName = undefined;
                }
                this.props.updateEntity(this.props.intl, this.props.businessAccountId, newData.id || 0, newData);
            } else {
                // Копирование
                let newData: TemplateInfoCreate = { taxRate: 0, ...data } as TemplateInfoCreate;

                if (newData.description === '') delete newData.description;
                if (newData.comment === '') delete newData.comment;

                delete newData['useRenterDefaultDiscount'];
                if (newData.fullName === '') {
                    newData.fullName = undefined;
                }

                let request = this.props.createTemplate(
                    this.props.intl,
                    this.props.businessAccountId,
                    newData,
                    this.props.successCallback
                ) as any;
                request.then((result: any) => {
                    if (result instanceof Error) {
                        showNotification('error', 'Шаблон не создан');
                    } else {
                        //
                        let res: AxiosResponse<TemplateInfoRead> = result.action.payload;
                        showNotification(
                            'success',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__CREATED)
                        );
                        this.props.push(`/${this.props.businessAccountId}/projects/templates/${res.data.id}?tab=description`);
                        if (this.props.successCallback) this.props.successCallback(res.data);
                    }
                });
            }
        } else {
            // Создание
            let newData: TemplateInfoCreate = { taxRate: 0, ...data } as TemplateInfoCreate;

            if (newData.description === '') delete newData.description;
            if (newData.comment === '') delete newData.comment;

            if (data['useRenterDefaultDiscount']) newData.defaultDiscount = undefined;
            if (newData.fullName === '') {
                newData.fullName = undefined;
            }
            delete newData['useRenterDefaultDiscount'];

            let request = this.props.createTemplate(
                this.props.intl,
                this.props.businessAccountId,
                newData,
                this.props.successCallback
            ) as any;
            request.then((result: any) => {
                if (result instanceof Error) {
                    showNotification('error', 'Шаблон не создан');
                } else {
                    let res: AxiosResponse<TemplateInfoRead> = result.action.payload;
                    showNotification(
                        'success',
                        localizeIntl(this.props.intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__CREATED)
                    );

                    if (this.props.successCallback) this.props.successCallback(res.data);
                    logEvent({
                        type: 'create project',
                        data: {
                            'entity id': data.id,
                        },
                    });
                }
            });
        }
    };

    getInitialValues = () => {
        let initialValues: any; //(ICustomFields & ProjectInfoUpdate | undefined);

        if (this.props.initialValues) {
            initialValues = {
                ...this.props.initialValues, //,
                //dates: this.props.initialValues.startDate || this.props.initialValues.targetFinishDate ? [this.props.initialValues.startDate ? moment(this.props.initialValues.startDate) : undefined, this.props.initialValues.targetFinishDate ? moment(this.props.initialValues.targetFinishDate) : undefined] : undefined
            };

            if (this.props.copyMode) {
                initialValues.id = undefined;
                initialValues.renterId = undefined;
            } else {
                initialValues.templateEdit = true;
            }

            initialValues.useRenterDefaultDiscount = false;
            initialValues._defaultDiscount = initialValues.defaultDiscount;
            initialValues['useRenterDefaultDiscount'] = false;
        } else {
            if (this.props.renter) {
                initialValues = {
                    renterId: this.props.renter,
                };

                if (this.props.renterObj) {
                    initialValues.hasOwnAssignee = true;
                    initialValues.defaultDiscount = this.props.renterObj.defaultDiscount;
                    initialValues._defaultDiscount = 0;
                    initialValues['useRenterDefaultDiscount'] = true;
                    initialValues.useRenterDefaultDiscount = true;
                    initialValues.renterDefaultDiscount = this.props.renterObj.defaultDiscount;
                }
            }
        }

        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            this.props.initialValues
                ? this.props.push(`/${this.props.businessAccountId}/projects/templates/${this.props.initialValues?.id}/?tab=description`)
                : this.props.goBack();

            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.template.updating,
    updatingError: storeState.template.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = { createTemplate, updateEntity, goBack, resetError, push, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const TemplateCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_TemplateCreateModal));

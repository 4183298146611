import {useCallback, useMemo} from 'react';
import {LabeledValue} from 'antd/es/select';
import {EntityTypeCodeEnum} from '../../../../../../server';
import {useAppSelector} from '../../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../../shared/reducers/system.reducer';
import {IRootState} from '../../../../../../shared/reducers';
import {LocalStorageUtils} from '../../../../../../core/utils/localStorageUtils';

export const getChosenCustomFieldsForActionStorageKey = (entityTypeCode: EntityTypeCodeEnum) => `${entityTypeCode}_target_custom_fields`;

export const useCustomFieldsStorageValue = (entityTypeCode: EntityTypeCodeEnum) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const email = useAppSelector((state: IRootState) => state.oidc.user?.profile.email);
    const storageItemKey = LocalStorageUtils.getKey(getChosenCustomFieldsForActionStorageKey(entityTypeCode), businessAccountId, email);

    const selectedCustomFieldsFromStorage = useMemo(() => {
        const record = localStorage.getItem(storageItemKey);
        if (record) {
            try {
                return record.split(';').map((jsonString) => JSON.parse(jsonString));
            } catch (e) {}
        }
    }, [storageItemKey]);

    const setSelectedStorageCustomFields = useCallback(
        (fieldsValue: LabeledValue[] | LabeledValue | undefined) => {
            if (fieldsValue) {
                const values = (Array.isArray(fieldsValue) ? fieldsValue : [fieldsValue]).map((labeledValue) =>
                    JSON.stringify(labeledValue)
                );
                localStorage.setItem(storageItemKey, values.join(';'));
            } else {
                localStorage.removeItem(storageItemKey);
            }
        },
        [storageItemKey]
    );

    return [selectedCustomFieldsFromStorage, setSelectedStorageCustomFields] as [
        LabeledValue[] | undefined,
        (fieldsIds: LabeledValue[] | LabeledValue | undefined) => void
    ];
};

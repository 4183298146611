import React, { FC } from 'react';
import {
    LocationDrawer as LocationDrawerComponent,
    LocationDrawerProps as LocationDrawerComponentProps,
} from '../../../../../logistics/tabs/locations/drawer/LocationDrawer';

import { usePushToPages } from '../../../../../logistics/tabs/transportations/hooks/usePushToPages';

interface LocationDrawerProps {
    id: LocationDrawerComponentProps['locationId'];
    onClose: LocationDrawerComponentProps['onClose'];
    className?: string;
}

// TODO Если действия по умолчанию задавать в оригинальном Дровере Локаций, то этот компонент будет не нужен
export const LocationDrawer: FC<LocationDrawerProps> = (props) => {
    const { locationActions } = usePushToPages();
    return (
        <LocationDrawerComponent
            className={props.className}
            locationId={props.id}
            onClose={props.onClose}
            editLocation={locationActions.edit}
            deleteLocation={locationActions.delete}
            archiveLocation={locationActions.archive}
            isEntityActionsLoading={locationActions.isLoading}
        />
    );
};

import {AmplitudeAnalytics} from "./amplitudeAnalytics";
import {getStoreState} from "../../index";

export interface IAnalyticsEvent {
    type: string;
    data:any;
}

interface IUserBaseParams {
    'current account id'?: number;
    'current account permissions'?: string[];
    'num screen width'?: number;
    'is left menu open'?: boolean;
    'num accounts available'?: number;
}

interface IViewEntityEvent extends IAnalyticsEvent {
    type: 'view renter' | 'view product' | 'view project' | 'view operation' |  'view element' |  'view category' | 'view current profile' | 'view other user profile';
    data: {
        'entity id'?: number;
        'tab'?: string;
    }
}

interface ICreateEntityEvent extends IAnalyticsEvent {
    type: 'create project' | 'create shipping' | 'create product' | 'create renter' | 'create category';
    data: {
        'entity id'?: number;
    }
}

interface IUpdateEntityEvent extends IAnalyticsEvent {
    type: 'update project' | 'update product' | 'update renter' | 'update category';
    data: {
        'entity id'?: number;
    }
}

interface IListProductsEvent extends IAnalyticsEvent {
    type: 'list products';
    data: {
        'filter status'?: string;
        'filter final total price from'?: number;
        'filter final total price to'?: number;
    } & IBaseListEntitiesEventParams
}

interface IListRentersEvent extends IAnalyticsEvent {
    type: 'list renters';
    data: {
        'filter status'?: string;
        'filter last activity date from'?: number;
        'filter last activity date to'?: number;
    } & IBaseListEntitiesEventParams
}

interface IListProjectsEvent extends IAnalyticsEvent {
    type: 'list projects';
    data: {
        'filter status'?: string;
        'filter renter id'?: string;
        'filter start date from'?: number;
        'filter start date to'?: number;
    } & IBaseListEntitiesEventParams
}

interface IListOperationsEvent extends IAnalyticsEvent {
    type: 'list operations';
    data: {
        'filter type code'?: string;
        'filter renter id'?: string;
        'filter project id'?: string;
        'filter creation author id'?: string;
        'filter start date from'?: number;
        'filter start date to'?: number;
        'filter final total price from'?: number;
        'filter final total price to'?: number;
    } & IBaseListEntitiesEventParams
}

interface IListElementsEvent extends IAnalyticsEvent {
    type: 'list elements';
    data: {
        'filter type code'?: string;
        'filter renter id'?: string;
        'filter project id'?: number;
        'filter creation author id'?: string;
        'filter start date from'?: number;
        'filter start date to'?: number;
        'filter end date from'?: number;
        'filter end date to'?: number;
        'filter discount from'?: number;
        'filter discount to'?: number;
        'filter shift count from'?: number;
        'filter shift count to'?: number;
        'filter final total price from'?: number;
        'filter final total price to'?: number;
    } & IBaseListEntitiesEventParams
}

interface IListCalendarEvent extends IAnalyticsEvent {
    type: 'list calendar';
    data: {
        'filter search'?: string;
        'filter delay'?: string;
        'filter shortage'?: string;
        'filter state code'?: string;
        'filter renter id'?: string;
        'filter project id'?: string;
        'filter product id'?: string;
        'filter group by'?: string;
    }
}

interface IListCategoriesEvent extends IAnalyticsEvent {
    type: 'list categories';
    data: {
        'tab': string;
    }
}

type AnalyticsEvent = IViewEntityEvent | IListProductsEvent | IListRentersEvent | IListProjectsEvent | IListOperationsEvent | IListElementsEvent | IListCalendarEvent | IListCategoriesEvent | ICreateEntityEvent | IUpdateEntityEvent  ;

interface IBaseListEntitiesEventParams {
    'num filtered': number;
    'num per page'?: number;
    'num of the page'?: number;
    'filter delay'?: boolean;
    'filter shortage'?: boolean;
    'filter search'?: string;
    'filter archive'?: boolean;
}


export interface IAnalyticsClient {
    init(apiKey: string):void;
    logEvent(event: IAnalyticsEvent):void;
}

const getUserParams = () => {
    const storeState = getStoreState();
    const params:IUserBaseParams = {
        'current account id': storeState.system.businessAccountId,
        'current account permissions': storeState.permissions.permissions,
        'num screen width': window.screen.width,
        'is left menu open': !storeState.userSettings.sidebarCollapsed,
        'num accounts available': storeState.businessAccounts.entities?.records.length
    };
    return params;
};

export class Analytics {
    private static instance:Analytics;
    private amplitude:AmplitudeAnalytics;

    private constructor() {
        this.amplitude = new AmplitudeAnalytics();
    }

    public static getInstance():Analytics {
        if (!Analytics.instance) Analytics.instance = new Analytics();
        return Analytics.instance;
    }

    public init(amplitudeApiKey: string) {
        //console.log('Analytics init()');
        this.amplitude.init(amplitudeApiKey);
    }

    public logEvent(e:AnalyticsEvent) {
        //console.log('Analytics logEvent()');
        this.amplitude.setUserProperties(getUserParams());
        this.amplitude.logEvent(e);
    }

    public setUserId(userId:string | null) {
        //console.log('Analytics setUserId()');
        this.amplitude.setUserId(userId);
    }
}

export const logEvent = (e:AnalyticsEvent) => {
    Analytics.getInstance().logEvent(e);
};

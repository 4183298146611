import { NomenclatureRecordCustom } from '../../api/products.api.types';
import { NomenclatureEntityTypeCodeEnum } from '../../../../../../server';
import { showConfirm2 } from '../../../../../../components/confirm/showConfirm';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { deleteProducts } from '../../reducers/products.reducer';

export const useDeleteProducts = (selectedRecords: NomenclatureRecordCustom[]) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();

    return useCallback(async () => {
        const selectedItems = selectedRecords.map((item) => ({
            id: item.variantId || item.productId,
            businessVersion: item.businessVersion,
            nomenclatureEntityTypeCode: item.variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : NomenclatureEntityTypeCodeEnum.PRODUCT,
        }));
        const isMany = selectedItems.length > 1;
        const yes = await showConfirm2({
            intl: intl,
            title: isMany
                ? 'Удалить безвозвратно продукты со всеми их вариантами и экземплярами?'
                : 'Удалить безвозвратно продукт со всеми его вариантами и экземплярами?',
        });

        if (yes) {
            deleteProducts(intl, businessAccountId, selectedItems)(dispatch);
            gridRef?.current?.clearSelection();
        }
    }, [businessAccountId, dispatch, gridRef, intl, selectedRecords]);
};

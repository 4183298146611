import { ProcessItemFunction } from '../../../../components/Calendar/hooks/useCalendarItems';
import { RentElementRecord, RentStateCodeEnum } from '../../../../../../../server';
import moment from 'moment';
import { CalendarItem } from '../../../../components/Calendar/types/items';

import { isItemSubrent } from '../checks/isItemSubrent';
import {
    isGiveawayDelayProblemForNestedRentElement,
    isGiveawayDelayProblemForRentElement,
} from '../checks/isGiveawayDelayProblemForRentElement';

export const processElementsCalendarItem: ProcessItemFunction<RentElementRecord> = ({
    record,
    items,
    screenLeft,
    screenRight,
    groupIndex,
    collapsedKitsId,
}) => {
    const startTime =
        +moment(record.commonFields.rentTerms.rentPeriodStartDate) < screenLeft
            ? moment(screenLeft)
            : moment(record.commonFields.rentTerms.rentPeriodStartDate);

    const entTime = isGiveawayDelayProblemForRentElement(record)
        ? moment(Math.min(screenRight, Math.max(+moment(record.commonFields.rentTerms.rentPeriodEndDate), +moment())))
        : +moment(record.commonFields.rentTerms.rentPeriodEndDate) > screenRight
        ? moment(screenRight)
        : moment(record.commonFields.rentTerms.rentPeriodEndDate);

    items.push({
        elementType: isItemSubrent(record.commonFields.stateCode, record.subrentId) ? 'subrent' : 'rent',
        end_time: entTime,
        group: groupIndex.current,
        parentRecord: undefined,
        record: record,
        id: record.id,
        instanceCount: record.commonFields.instanceCount,
        isDraft: [RentStateCodeEnum.DRAFT, RentStateCodeEnum.SUBRENTDRAFT].includes(record.commonFields.stateCode),
        kitId: record.kitId,
        problemsAndWarnings: record.commonFields.problemsAndWarnings,
        rentTerms: record.commonFields.rentTerms,
        start_time: startTime,
        stateCode: record.commonFields.stateCode,
        title: record.commonFields.nomenclatureShortName,
        variantName: record.commonFields.variantName,
        isCollapsed: collapsedKitsId.includes(record.id),
        isKitChild: false,
    } satisfies CalendarItem);

    groupIndex.current += 1;

    if (collapsedKitsId.includes(record.id)) {
        record.nestedRentElements?.forEach((nestedRecord) => {
            const startTime =
                +moment(nestedRecord.rentTerms.rentPeriodStartDate) < screenLeft
                    ? moment(screenLeft)
                    : moment(nestedRecord.rentTerms.rentPeriodStartDate);

            const entTime = isGiveawayDelayProblemForNestedRentElement(nestedRecord)
                ? moment(Math.min(screenRight, Math.max(+moment(nestedRecord.rentTerms.rentPeriodEndDate), +moment())))
                : +moment(nestedRecord.rentTerms.rentPeriodEndDate) > screenRight
                ? moment(screenRight)
                : moment(nestedRecord.rentTerms.rentPeriodEndDate);

            items.push({
                elementType: isItemSubrent(nestedRecord.stateCode, undefined) ? 'subrent' : 'rent',
                end_time: entTime,
                group: groupIndex.current,
                record: nestedRecord,
                parentRecord: record,
                id: nestedRecord.id,
                instanceCount: nestedRecord.instanceCount,
                isDraft: [RentStateCodeEnum.DRAFT, RentStateCodeEnum.SUBRENTDRAFT].includes(nestedRecord.stateCode),
                kitId: undefined,
                problemsAndWarnings: nestedRecord.problemsAndWarnings,
                rentTerms: nestedRecord.rentTerms,
                start_time: startTime,
                stateCode: nestedRecord.stateCode,
                title: nestedRecord.nomenclatureShortName,
                variantName: nestedRecord.variantName,
                isCollapsed: false,
                isKitChild: true,
            } satisfies CalendarItem);

            groupIndex.current += 1;
        });
    }
};

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { ProblemEnum } from '../../../../../../types';
import {NomenclaturesListFilters} from "../../../api/operationForm.api";
import {NomenclatureEntityTypeCodeEnum} from "../../../../../../server";

export const productsFiltersDescription: Required<PageURLParamDescriptionObject<NomenclaturesListFilters>> = {
    search: PageURLParamDescriptions.searchParam(),
    problem: PageURLParamDescriptions.enumSelectParam(ProblemEnum),
    categoryIds: PageURLParamDescriptions.numbersSelectParam,
    pricePerShift: PageURLParamDescriptions.numberInterval,
    nomenclatureEntityTypeCode: PageURLParamDescriptions.enumSelectParam(NomenclatureEntityTypeCodeEnum)
};

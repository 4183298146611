import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {isDefined} from "../../../shared/util/utils";
import React from "react";

export const tblColumnsText = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.TEXT) {
        return (
            {
                title: column.title,
                dataIndex: column.dataIndex,
                type: ColumnTypes.CustomRender,
                minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
                resizable: false,
                render: (value: any, rowData: Record) => {
                    const text = column.getData(rowData).value;
                    return text;
                }
            }
        );
    } else {
        throw Error('error');
    }
};

import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    CounterpartyActivityTypeCodeEnum,
    RenterInfoCreate,
    RenterInfoRead,
    RenterInfoUpdate,
    serverApi,
    ServerError,
} from '../../../../../server';
import { push } from 'connected-react-router';
import { getServerError } from '../../../../../shared/util/utils';
import { showNotification } from '../../../../../components/notification/showNotification';
import { logEvent } from '../../../../../analytics/analytics';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { IntlShape } from 'react-intl';
import { loadProjects } from '../../../projects/production/reducers/projects.reducer';

export const ACTION_TYPES = {
    LOAD_RENTER: 'renter/LOAD_RENTER',
    UPDATE_RENTER: 'renter/UPDATE_RENTER',
    CHANGE_ENTITY_STATUS: 'renter/CHANGE_ENTITY_STATUS',
    CREATE_RENTER: 'renter/CREATE_RENTER',
    RESET: 'renter/RESET',
    RESET_ERRORS: 'renter/RESET_ERRORS',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entity: null as RenterInfoRead | null,
};

export type RenterState = Readonly<typeof initialState>;

// Reducer

export default (state: RenterState = initialState, action): RenterState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_RENTER):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.LOAD_RENTER):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.LOAD_RENTER):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
            };

        case REQUEST(ACTION_TYPES.CREATE_RENTER): {
            let res = {
                ...state,
                updating: true,
                updatingError: undefined,
            };
            return res;
        }

        case FAILURE(ACTION_TYPES.CREATE_RENTER):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.CREATE_RENTER):
            return {
                ...state,
                updating: false,
            };

        case REQUEST(ACTION_TYPES.UPDATE_RENTER): {
            let res = {
                ...state,
                updating: true,
                updatingError: undefined,
            };
            return res;
        }

        case FAILURE(ACTION_TYPES.UPDATE_RENTER):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };

        case REQUEST(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.UPDATE_RENTER):
            return {
                ...state,
                updating: false,
                entity: action.payload.data,
            };

        case SUCCESS(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.RESET_ERRORS:
            return {
                ...state,
                loadingError: undefined,
                updatingError: undefined,
            };

        default:
            return state;
    }
};

// Actions

// Получение состояния арендатора
export const loadRenter = (businessAccountId: number, id: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.LOAD_RENTER,
            payload: serverApi.getRenterById(businessAccountId, id),
        }).then((result) => {
            if (result instanceof Error) {
                // Арендатор не загружен
            } else {
                // Арендатор загружен
            }
        });
    };
};

// Изменение состояния арендатора
export const updateRenter = (intl: IntlShape, businessAccountId: number, id: number, renterInfo: RenterInfoUpdate) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_RENTER,
            payload: serverApi.updateRenterById(businessAccountId, id, renterInfo),
        }).then(async (result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_UPDATE_RENTER));
            } else {
                // Арендатор обновлен успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTER_UPDATED));
                dispatch(push('/' + businessAccountId + '/crm/counterparties/' + id + '?tab=description'));

                setTimeout(() => {
                    dispatch(loadProjects(intl, businessAccountId));
                }, 2000);

                logEvent({
                    type: 'update renter',
                    data: {
                        'entity id': id,
                    },
                });
            }
        });
    };
};

export const changeCounterpartyActivityType = (
    intl: IntlShape,
    businessAccountId: number,
    renterInfo: RenterInfoRead,
    activityTypeCode: CounterpartyActivityTypeCodeEnum
) => {
    return (dispatch) => {
        const data: RenterInfoUpdate = { ...renterInfo };
        const id = renterInfo.id;
        data.activityTypeCode = activityTypeCode;
        return dispatch({
            type: ACTION_TYPES.UPDATE_RENTER,
            payload: serverApi.updateRenterById(businessAccountId, id, data),
        }).then(async (result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_UPDATE_RENTER));
            } else {
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTER_UPDATED));
            }
        });
    };
};

// Изменение статуса
export const setStatusAndArchive = (
    intl: IntlShape,
    businessAccountId: number,
    id: number,
    businessVersion: number | undefined,
    status?: 'ACTIVATE' | 'BLOCK',
    archive?: boolean
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ENTITY_STATUS,
            payload: serverApi.transitRenterWorkflowBulk(businessAccountId, {
                directives: [
                    {
                        id,
                        businessVersion: businessVersion || 0,
                        transitionCode: status,
                        archive,
                    },
                ],
            }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            } else {
                if (archive === undefined) {
                    showNotification(
                        'success',
                        localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_STATE_UPDATED)
                    );
                } else {
                    showNotification(
                        'success',
                        localizeIntl(
                            intl,
                            archive
                                ? LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__ARCHIVED
                                : LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__UNARCHIVED
                        )
                    );
                }
                dispatch(loadRenter(businessAccountId, id));

                setTimeout(() => {
                    dispatch(loadProjects(intl, businessAccountId));
                }, 2000);

                // updateEntityAnalytics('update renter', {'entity id': id});

                logEvent({
                    type: 'update renter',
                    data: {
                        'entity id': id,
                    },
                });
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetErrors = () => ({
    type: ACTION_TYPES.RESET_ERRORS,
});

// export const createRenter = (
//     intl: IntlShape,
//     businessAccountId: number,
//     renterInfo: RenterInfoCreate,
//     cb?: (data: RenterInfoRead) => void
// ) => {
//     return (dispatch) => {
//         return dispatch({
//             type: ACTION_TYPES.CREATE_RENTER,
//             payload: serverApi.createRenter(businessAccountId, renterInfo),
//         }).then((result) => {
//             if (result instanceof Error) {
//                 showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_CREATE_RENTER));
//                 // Арендатор не создан
//             } else {
//                 // Арендатор создан
//                 let { id } = result.action.payload.data;
//                 if (cb) cb(result.action.payload.data);
//                 else {
//                     showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__CREATED));
//                     dispatch(push(`/${businessAccountId}/counterparties/${id || ''}?tab=description`));
//                     logEvent({
//                         type: 'create renter',
//                         data: {
//                             'entity id': id,
//                         },
//                     });
//                 }
//                 return result?.action?.payload?.data;
//             }
//         });
//     };
// };

export const createRenter = (businessAccountId: number, renterInfo: RenterInfoCreate) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CREATE_RENTER,
            payload: serverApi.createRenter(businessAccountId, renterInfo),
        }).then((result) => {
            if (result instanceof Error) {
                throw result;
            } else {
                return result?.action?.payload?.data;
            }
            // if (result instanceof Error) {
            //     showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_CREATE_RENTER));
            //     // Арендатор не создан
            // } else {
            //     // Арендатор создан
            //     let { id } = result.action.payload.data;
            //     if (cb) cb(result.action.payload.data);
            //     else {
            //         showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__CREATED));
            //         dispatch(push(`/${businessAccountId}/counterparties/${id || ''}?tab=description`));
            //         logEvent({
            //             type: 'create renter',
            //             data: {
            //                 'entity id': id,
            //             },
            //         });
            //     }
            //     return result?.action?.payload?.data;
            // }
        });
    };
};

import { ServerUtils } from '../../../../core/utils/serverUtils';
import { PaymentInfoRead, PaymentStateCodeEnum } from '../../../../server';
import { ListPaymentsParams } from './payments.api.types';
import { getAllStatusesExceptSelected } from '../../../../shared/util/utils4';
import { MoneyUtils } from '../../../../core/utils/moneyUtils';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../components/page/entityGrid/types/api';

export abstract class PaymentsApiUtils {
    static createRequestFilters = (params: ListPaymentsParams, subrentModuleEnabled: boolean | undefined) => {
        const {
            counterpartyId,
            stateCode,
            sourceCode,
            projectId,
            subrentId,
            number,
            hideDeleted,
            authorId,
            screenLeft,
            screenRight,
            paymentMethodId,
            amount,
        } = params;

        return ServerUtils.createRequestFilters<
            PaymentInfoRead & {
                numberInBusinessAccount: any;
            }
        >([
            counterpartyId && ['counterpartyId', 'EQ', counterpartyId],
            stateCode && ['stateCode', 'EQ', stateCode],
            sourceCode && ['sourceCode', 'EQ', sourceCode],
            projectId && ['projectId', 'EQ', projectId],
            subrentModuleEnabled && subrentId && ['subrentId', 'EQ', subrentId],
            number && ['numberInBusinessAccount', 'EQ', number],
            hideDeleted === true && ['stateCode', 'IN', getAllStatusesExceptSelected(PaymentStateCodeEnum, PaymentStateCodeEnum.DELETED)],
            authorId && ['lastUpdateAuthorId', 'EQ', authorId],
            screenLeft && ['date', 'GE', screenLeft],
            screenRight && ['date', 'LE', screenRight],
            paymentMethodId && ['paymentMethodId', 'EQ', paymentMethodId],
            amount?.[0] != null && ['amount', 'GE', MoneyUtils.toCents(amount[0])],
            amount?.[1] != null && ['amount', 'LE', MoneyUtils.toCents(amount[1])],
        ]);
    };
    static intervalMapFunction: IntervalFiltersMetricsMap<PaymentInfoRead> = {
        amount: MoneyUtils.toWhole,
    };
    static intervalFormatStyleMap: IntervalFiltersFormatsMap<PaymentInfoRead> = {
        amount: 'currency',
    };
}

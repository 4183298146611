import React, {ReactNode} from 'react';
import './DynamicFilters.less';
import DynamicFiltersMain from './DynamicFiltersMain';
import { FormFieldsGroup } from '../dynamicForm/DynamicForm';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CustomFieldObjRead } from '../../server';
import { GridName } from '../grid/utils/types';
import { IRootState } from '../../shared/reducers';
import { GridStorageData } from '../grid/utils';
import { updateGridSettings } from '../../shared/reducers/userSettings/userSettings.reducer';
import { connect, ConnectedProps } from 'react-redux';

export interface DynamicFiltersProps extends WrappedComponentProps {
    data: FormFieldsGroup[];
    gridName: GridName;
    initialValues?: { [k: string]: any };
    onChange?: (data: any) => void;
    wrappedComponentRef?: any;
    excludeFields?: string[];
    defaultValues?: { [k: string]: any };
    currentValues?: { [k: string]: any };
    customParams?: { [k: string]: any };
    categoriesFilterSelected?: boolean;
    resetFiltersCb?: () => void;
    otherFiltersCollapsed?: boolean;
    onHideCustomValue?: (key: string) => void;
    onOtherFiltersCollapse?: (collapsed: boolean) => void;
    otherFiltersCollapsed1?: (otherFiltersCollapsed: boolean | undefined) => void;
    canResetFilters?: boolean;
    dontResetFilters?: string[];
    title?: React.ReactElement;
    fetchEntityErrorCallback?: (entityId: number) => void;
    customFieldMarkers?: CustomFieldObjRead[];
    hiddenFieldsForCount?: string[];
    rightBlockExtraContent?: ReactNode;
}

class _DynamicFilters extends React.PureComponent<DynamicFiltersProps & StoreProps> {
    render() {
        return <DynamicFiltersMain {...this.props} />;
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: DynamicFiltersProps) => ({
    permissions: storeState.permissions.permissions,
    businessAccountId: storeState.system.businessAccountId,
    gridSettings: storeState.userSettings.gridSettings[ownProps.gridName] || ({} as GridStorageData),
});

const mapDispatchToProps = { updateGridSettings };

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const DynamicFilters = connector(_DynamicFilters);

export default injectIntl(DynamicFilters);

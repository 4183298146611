import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { KnowledgeBaseArticleLinkObj } from '../../../../../server';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import classNames from 'classnames';
import './InfoSelectDropdownMenu.less';
import { useAppSelector } from '../../../../../store/hooks';

interface InfoSelectDropdownMenuProps {
    infoData: KnowledgeBaseArticleLinkObj[] | undefined;
    isLoadingError: boolean;
}

export const InfoSelectDropdownMenu: FC<InfoSelectDropdownMenuProps> = ({ infoData, isLoadingError }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <Menu>
            {isLoadingError && <div className="rr-custom-select-error-block">Ошибка загрузки</div>}
            {infoData && infoData.length === 0 && <div>Ничего не найдено</div>}
            {infoData?.map(({ name, articleId }, index) => {
                return (
                    <Menu.Item key={index} className={classNames('ant-select-dropdown-menu-item', 'info-select-dropdown-item')}>
                        <Link to={`/${businessAccountId}/kb/${articleId}`}>
                            <span>{name}</span>
                        </Link>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
};

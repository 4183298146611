import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../core/utils/descriptions';
import { ListWorkPlanningsFilters } from '../../api/workPlannings.api.types';
import { WorkPlanningsStateCodeEnum } from '../../../../../server';

export const WorkPlanningsPageFiltersDescription: PageURLParamDescriptionObject<ListWorkPlanningsFilters> = {
    search: PageURLParamDescriptions.searchParam(),
    stateCode: PageURLParamDescriptions.enumSelectParam(WorkPlanningsStateCodeEnum),
    professionId: PageURLParamDescriptions.numberParam,
    hideCancelled: PageURLParamDescriptions.switchParam,

    crewMemberIds: PageURLParamDescriptions.numbersSelectParam as any,
    crewMembers: PageURLParamDescriptions.numberInterval as any,
    startDate: PageURLParamDescriptions.dateTimeIntervals as any,
    endDate: PageURLParamDescriptions.dateTimeIntervals as any,
    effectivePrice: PageURLParamDescriptions.numberInterval as any,
    shiftCount: PageURLParamDescriptions.numberInterval as any,
    finalTotalPrice: PageURLParamDescriptions.numberInterval as any,
    numberOfCrew: PageURLParamDescriptions.numberInterval as any,
};

import {FormFieldsGroup, FormItemType} from "../../../components/dynamicForm/DynamicForm";
import {LocalizationEnum} from '../../../localization';

// dynamic form fields
export const formFields:FormFieldsGroup[] = [
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000
            }
        ]
    }
];

import {FormFieldsGroup} from '../../../../../../components/dynamicForm/DynamicForm';
import {FormUtils} from '../../../../../../core/utils/formUtils';
import {ListPaymentMethodsParams} from '../../api/types';
import React from 'react';

export const paymentMethodsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListPaymentMethodsParams>([
    {
        fields: [
            // {
            //     label: '',
            //     placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
            //     id: 'search',
            //     type: FormItemType.Search,
            //     className: 'rr-input-gray',
            // },
            //
            // {
            //     label: 'Скрыть отменённые',
            //     id: 'hideCancelled',
            //     type: FormItemType.Switch,
            // },
        ],
    }
]);

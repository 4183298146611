import { ExcelExportTemplateTypeCodeEnum, FileTypeCodeEnum } from '../../../../../server';
import { IntlShape } from 'react-intl';
import { downloadExcelOffloading } from './downloadExcelOffloading';
import { downloadPdfOffloading } from './downloadPdfOffloading';
import { ArrayUtils } from '../../../../../core/utils/arrayUtils';
import { ExportOffloadingPopoverOptions } from '../../instances/ExportOffloadingPopover/utils/data';

export const downloadOffloading = async (args: {
    intl: IntlShape;
    businessAccountId: number;
    selection: number[];
    fileType: FileTypeCodeEnum;
    values: ExportOffloadingPopoverOptions[];
    grid: any;
    financialFieldsEnum: object;
    notFinancialFieldsEnum: object;
    canViewFinancialData: boolean;
    filters: string[];
    fileName: string;
    exportTemplateType: ExcelExportTemplateTypeCodeEnum;
    sortBy: string | undefined;
    sortOrder: 'ASC' | 'DESC' | undefined;
    search?: string;
    excludeColumns: string[] | undefined;
    columnsMap?: Record<string, string>;
}) => {
    const {
        businessAccountId,
        canViewFinancialData,
        exportTemplateType,
        excludeColumns,
        fileName,
        fileType,
        filters,
        financialFieldsEnum,
        grid,
        intl,
        notFinancialFieldsEnum,
        selection,
        sortBy,
        sortOrder,
        values,
        columnsMap,
        search,
    } = args;

    if (selection && selection.length > 0) filters.push(`id;IN;${selection.join(';')}`);

    let enabledColumnKeys = Object.values(notFinancialFieldsEnum);
    if (canViewFinancialData) enabledColumnKeys.push(...Object.values(financialFieldsEnum));
    enabledColumnKeys = ArrayUtils.removeDuplicates(enabledColumnKeys);

    let columns: string[] = values.includes(ExportOffloadingPopoverOptions.ALL_COLUMNS) ? enabledColumnKeys : grid.getVisibleColumns();

    if (columnsMap != null) {
        Object.keys(columnsMap).forEach((key) => {
            const index = columns.findIndex((column) => column === key);
            if (index >= 0) columns.splice(index, 1, columnsMap[key]);
        });
    }

    columns = columns.filter(
        (columnKey) => enabledColumnKeys.includes(columnKey) && (excludeColumns == null || !excludeColumns.includes(columnKey))
    );

    if (fileType === FileTypeCodeEnum.EXCEL) {
        await downloadExcelOffloading({
            intl,
            businessAccountId,
            columns,
            filters,
            fileName,
            exportTemplateType,
            sortBy,
            sortOrder,
            search,
        });
    }

    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        await downloadPdfOffloading({
            businessAccountId,
            columns,
            filters,
            sortBy,
            sortOrder,
            fileName,
            exportTemplateType,
            search,
        });
    }
};

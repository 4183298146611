import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { crewMemberModalFields } from '../../data/crewMemberModalFields';
import { IRootState } from '../../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../../components/modalForm/utils';
import { updateModalFilters } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { showConfirm } from '../../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../../localization';
import { CrewMemberInfoRead, CrewMemberInfoUpdate, CrewMemberStateCodeEnum } from '../../../../../../../../server';
import { CrewMemberFormData } from '../../types/CrewMemberFormData';
import { goBack } from 'connected-react-router';
import { updateCrewMember } from '../../../reducers/crewMember/crewMember.reducer';

interface CrewMemberEditModalProps extends ModalFormNEWProps, WrappedComponentProps {
    crewMember: CrewMemberInfoRead;
}

class CrewMemberEditModalComponent extends ModalFormNEW<CrewMemberEditModalProps & StoreProps> {
    static defaultProps = {
        formFields: crewMemberModalFields,
    };

    getInitialValues = () => {
        const { crewMember } = this.props;

        let initialValues: CrewMemberFormData = {
            isEdit: true,
            contact: crewMember.contact.id,
            stateCode: crewMember.stateCode ?? CrewMemberStateCodeEnum.ACTIVE,
            professions: crewMember.professions?.map((profession) => profession.professionId),
            defaultRates: crewMember.defaultRates,
            comment: crewMember.comment,
            description: crewMember.description,
        };

        if (this.props.initialValues) {
            initialValues = {
                ...initialValues,
                ...this.props.initialValues,
            };
        }

        return initialValues;
    };

    onOk = (data: unknown) => {
        const formData = data as CrewMemberFormData;

        const crewMemberInfoUpdate: CrewMemberInfoUpdate = {
            ...this.props.crewMember,
            ...formData,
            contactId: formData.contact,
            professions: formData.professions?.map((profession) => ({
                professionId: profession,
            })),
        };

        this.props.updateCrewMember({
            businessAccountId: this.props.businessAccountId,
            id: this.props.crewMember.id,
            crewMemberInfoUpdate,
        });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.goBack();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.crewMember.updating,
    updatingError: storeState.crewMember.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    updateCrewMember,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const CrewMemberEditModal = connector(injectIntl(CrewMemberEditModalComponent));

import { ModalProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { ContactInfoRead } from '../../../../../../../server';
import React, { FC, memo } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { ContactCreateModal } from './ContactCreateModal';
import { createCategoryModalFilters } from './ContactCreateModalData';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';

export interface ContactCreateModalNewProps extends ModalProps<ContactInfoRead> {}

export const ContactCreateModalNew: FC<ContactCreateModalNewProps> = memo((props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    return (
        <ContactCreateModal
            businessAccountId={businessAccountId}
            title={'Создание нового контакта'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            filters={createCategoryModalFilters}
            modalName={'create-contact'}
            onSuccess={props.onSuccess}
            onCancel={props.onCancel}
        />
    );
});

import React, {FC, useState} from 'react';
import moment, {Moment} from "moment";
import {Button, Icon} from "antd";
import {TimeRangePickerPanel} from "../timeRangePicker/timeRangePickerPanel";
import {TimeInput} from "./timeInput";
import {LocalizationEnum, localize} from "../../localization";
import Trigger from 'rc-trigger';
import {TodayButton} from "../v2/calendar/rangePicker/components/todayButton/todayButton";
import {IconClockO} from "../icons";

interface DateTimePickerFooterProps {
    onSelectedDateChange: (value: Moment | null) => void;
    onCalendarClose: () => void;
    currentDisplayValue?: Moment | undefined;
    getCurrentDate?: () => Moment;
    onChange?: (date: moment.Moment | null, dateString: string) => void;
    selectedDate: Moment | null;
}

export const DateTimePickerFooter: FC<DateTimePickerFooterProps> = ({
                                                                        onSelectedDateChange,
                                                                        onCalendarClose,
                                                                        currentDisplayValue,
                                                                        getCurrentDate,
                                                                        onChange,
                                                                        selectedDate
                                                                    }) => {

    const [timePickerVisible, setTimePickerVisible] = useState(false);

    return (
        <>
            <TodayButton
                onClick={()=>{
                    const date = getCurrentDate?.() || moment().milliseconds(0).seconds(0);
                    onSelectedDateChange(date);
                    onChange?.(date, '');
                }}
                style={{position: "absolute", zIndex: 11, top: 20.5, right: 68}}
            />
            <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                <Trigger
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
                    popupTransitionName={'slide-up'}
                    destroyPopupOnHide
                    stretch={['width']}
                    popupVisible={timePickerVisible}
                    onPopupVisibleChange={(visible) => {
                        setTimePickerVisible(visible);
                    }}
                    popupAlign={{points: ['tc', 'bc'], offset: [0, -314]}}
                    action={['click']}
                    popup={
                        <TimeRangePickerPanel
                            value={currentDisplayValue}
                            minutesStep={5}
                            onChange={(value) => {
                            }}
                            onSelect={(value) => {
                                const date = value.milliseconds(0).seconds(0);
                                onSelectedDateChange(date)
                                onChange?.(date, '');
                            }}
                            onOk={() => {
                                // клик по кнопке ОК
                                setTimePickerVisible(false);
                            }}
                            onSetCurrentTime={() => {
                                const curDate = moment().milliseconds(0).seconds(0);
                                const date =
                                    selectedDate?.clone().hours(curDate.hours()).minutes(curDate.minutes()) ||
                                    curDate;
                                onSelectedDateChange(date)
                                onChange?.(date, '');
                            }}
                        />
                    }
                >
                    <div>
                        <span className="ant-calendar-picker-input ant-input rr-time-picker" style={{width: 130}}>
                            <TimeInput
                                value={currentDisplayValue}
                                onChange={(value) => {
                                    onSelectedDateChange(value || null)
                                    onChange?.(value || null, '');
                                }}
                                style={{paddingRight: 28}}
                            />
                            <span className="ant-time-picker-icon rr-time-picker-icon">
                                <Icon component={IconClockO} />
                            </span>
                        </span>
                    </div>
                </Trigger>
            </div>
            <Button
                type="primary"
                onClick={() => {
                    onCalendarClose();
                }}
            >
                {localize(LocalizationEnum.ASPECT__GLOBAL__OK)}
            </Button>
        </>
    );
};

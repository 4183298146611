import React, {FC, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {getPathFromState, getStateFromPath} from '../../../../../../shared/util/utils';
import {useAppDispatch, useAppSelector} from '../../../../../../store/hooks';
import {CustomFieldGroupObjRead, KitInfoRead, KitInfoUpdate, PricingSchemeModeCodeEnum} from '../../../../../../server';
import {CustomFieldsUtils} from '../../../../../../core/utils/customFieldsUtils';
import {useIntl} from 'react-intl';
import {push} from 'connected-react-router';
import {updateKitFeatures} from '../../reducers/kit.reducer';
import {businessAccountIdSelector} from '../../../../../../shared/reducers/system.reducer';
import {EntityFeaturesView} from '../../../features/components/EntityFeaturesView/EntityFeaturesView';
import {EntityFeaturesEdit} from '../../../features/components/EntityFeaturesEdit/EntityFeaturesEdit';

export const KitFeaturesTab: FC = () => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const searchParams = getStateFromPath(location.search);
    const editMode = Boolean(searchParams && searchParams.edit === true);
    const kit = useAppSelector((store) => store.kit.entity);
    const kitUpdating = useAppSelector((store) => store.kit.updating);

    const toViewMode = useCallback(() => {
        dispatch(push(getPathFromState(location.pathname, location.search, { edit: undefined })));
    }, [dispatch, location.pathname, location.search]);

    const onSave = useCallback(
        async (groups: CustomFieldGroupObjRead[], entity: KitInfoRead) => {
            const newData: KitInfoUpdate = {
                id: entity.id,
                businessAccountId: entity.businessAccountId,
                businessVersion: entity.businessVersion,
                creationAuthorId: entity.creationAuthorId,
                creationAuthorFullName: entity.creationAuthorFullName,
                creationDate: entity.creationDate,
                lastUpdateAuthorId: entity.lastUpdateAuthorId,
                lastUpdateAuthorFullName: entity.lastUpdateAuthorFullName,
                lastUpdateDate: entity.lastUpdateDate,
                comment: entity.comment,
                shortName: entity.shortName,
                fullName: entity.fullName,
                externalCode: entity.externalCode,
                barcode: entity.barcode,
                mainImage: entity.mainImage,
                images: entity.images,
                mainMemberOptionEnabled: Boolean(entity.mainMemberOptionEnabled),
                description: entity.description,
                categoryIds: entity.categories?.map(({ id }) => id),
                customFieldGroups: groups.map(CustomFieldsUtils.convertCustomFieldGroupObjReadToCustomFieldGroupObjWrite),
                members: entity.members,
                pricingSchemeMode: PricingSchemeModeCodeEnum.DISABLED
            };

            dispatch(
                updateKitFeatures({
                    intl,
                    entity,
                    newData,
                    successCallback: () => toViewMode(),
                })
            );
        },
        [dispatch, intl, toViewMode]
    );

    return (
        <div>
            {kit?.customFieldGroups ? (
                editMode ? (
                    <EntityFeaturesEdit onSave={onSave} groups={kit.customFieldGroups} entity={kit} updating={kitUpdating} />
                ) : (
                    <EntityFeaturesView entity={kit} />
                )
            ) : null}
        </div>
    );
};

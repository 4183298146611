import React, { FC } from 'react';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { WorkPlanningsCalendarItem } from '../../../../../components/Calendar/types/items';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconGraduationCapSolid } from '../../../../../../../../components/icons';
import { restItemsGroupId } from '../../../../../components/Calendar/hooks/useCalendarItems';

export const ProfessionGroupLineWrapper =
    (onClick: (id: number) => void): FC<GroupItemProps<WorkPlanningsCalendarItem>> =>
    ({ groupItem, displayType }) =>
        <ProfessionGroupLine groupItem={groupItem} displayType={displayType} onClick={onClick} />;

interface ProfessionGroupLineProps {
    onClick: (id: number) => void;
}

const ProfessionGroupLine: FC<GroupItemProps<WorkPlanningsCalendarItem> & ProfessionGroupLineProps> = (props) => {
    const { groupItem, displayType, onClick } = props;
    const {
        id,
        record: { professionShortName: title, professionId },
    } = groupItem;

    const hasCategory = id !== restItemsGroupId;

    return title ? (
        <GroupLine
            IconComponent={IconGraduationCapSolid}
            displayType={displayType}
            title={{
                text: title,
            }}
            onMouseUp={hasCategory ? () => onClick(professionId) : undefined}
            onClick={hasCategory ? (event) => event.stopPropagation() : undefined}
        />
    ) : null;
};

import { IModalFormFilters } from '../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../components/modalForm/components/Header/types/displayData';
import { KitRecord } from '../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof KitRecord | 'pricingScheme'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['shortName', 'externalCode', 'categoryIds'/*, 'pricingScheme'*/]],
    },
];

export const createKitModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editKitModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../../../shared/reducers';
import ModalForm from '../../../../../../../components/modalForm/ModalForm';
import { CategoryTypeCodeEnum, ProductInfoRead, ProductInfoUpdate } from '../../../../../../../server/api';
import { createEntity, resetError, updateEntityCounts } from '../../../reducers/product.reducer';
import { loadCategories } from '../../../../../../../shared/reducers/entities.reducer';
import { goBack } from 'connected-react-router';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { formFields } from './ProductChangeCountModalFields';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CustomFieldsUtils } from '../../../../../../../core/utils/customFieldsUtils';

interface IState {}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    initialValues?: any;
    businessAccountId: number;
}

class ProductChangeCountModal extends React.PureComponent<IProps, IState> {
    componentDidMount = () => {
        console.log('ProductCreateModal componentDidMount()');
        this.props.loadCategories(this.props.businessAccountId, CategoryTypeCodeEnum.PRODUCT);
    };

    componentWillUnmount = () => {
        console.log('ProductCreateModal componentWillUnmount()');
    };

    onOk = (data) => {
        const productEntity: ProductInfoRead = this.props.initialValues;
        let newData: ProductInfoUpdate = Object.assign(productEntity, {
            hasOwnRequiredTimeIndentBetweenElements: Boolean(productEntity.hasOwnRequiredTimeIndentBetweenElements),
            stockInstanceCount: data?.warehousePresenceCounters?.stockInstanceCount ?? 0,
            underMaintenanceInstanceCount: data?.warehousePresenceCounters?.underMaintenanceInstanceCount ?? 0,
            customFieldGroups: (productEntity.customFieldGroups ?? []).map(
                CustomFieldsUtils.convertCustomFieldGroupObjReadToCustomFieldGroupObjWrite
            ),
        });

        if (this.props.initialValues.categories && this.props.initialValues.categories.length) {
            newData.categoryIds = this.props.initialValues.categories.map((item) => item.id);
        }
        if (this.props.initialValues.pricingScheme) {
            newData.pricingSchemeId = this.props.initialValues.pricingScheme.id;
        }
        delete newData['pricingScheme'];

        this.props.updateEntityCounts(this.props.intl, this.props.businessAccountId, newData.id || 0, newData);
    };

    onCancel = async (isFieldsTouched: boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetError();
        }
    };

    render() {
        console.log('ProductChangeCountModal render()');

        let err;
        if (this.props.updatingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.updatingError.title}</strong>
                    </div>
                    <div>{this.props.updatingError.message}</div>
                </>
            );
        }

        let data = this.props.initialValues;
        let initialValues;

        if (data) {
            initialValues = { ...data } as ProductInfoUpdate;
        }

        return (
            <ModalForm
                title={localize(LocalizationEnum.PAGE__PRODUCTS__FORM__AMOUNT_EDITION_FORM_LABEL)}
                visible
                onCancel={this.onCancel}
                onOk={this.onOk}
                formFieldsData={formFields}
                initialValues={initialValues}
                saving={this.props.updating}
                error={err}
                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.product.updating,
    updatingError: storeState.product.updatingError,
});

const mapDispatchToProps = { createEntity, updateEntityCounts, loadCategories, goBack, resetError };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductChangeCountModal));

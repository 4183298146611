import {TableColumn} from "../../components/grid/Table";

/**
 * Метод возвращает поле для сортировки, если найдено в списке колонок или найдено в дополнительном списке, иначе возвращает дефолтный ключ, если задан, иначе undefined
 * @param sortBy id по которому хотим сортировать
 * @param columns список колонок для грида
 * @param extraIds дополнительный массив айдишников
 * @param defaultId дефолтное значение, если не найден id
 */
export const getAvailableGridSortById = (sortBy: string | undefined, columns: TableColumn<any>[], extraIds?: string[], defaultId?: string) => {
    let ids = columns.map((item) => {
        return item.dataIndex;
    }).concat(extraIds);
    return sortBy && ids.includes(sortBy) ? sortBy : defaultId;
};

import {RentElementCommonFieldsObj, RentElementRecord, RentStateCodeEnum} from "../../../../server";
import {getHideStateCodeFilterStateCodes, getHideStateCodeFilterTypes} from "../../../../shared/util/utils4";
import {ALL_STATE_CODES, ProblemEnum} from "../../../../types";
import moment from "moment";
import {EquipmentListParams, OperationElement, RentElementsGridItemCustom} from "./operationForm.reducer";
import {isDefined} from "../../../../shared/util/utils";
import {DeepKeys} from "@tanstack/react-table";

export const filterEquipmentData = (data: Array<RentElementsGridItemCustom>, params: EquipmentListParams): Array<RentElementsGridItemCustom> => {
    let entities = data;
    let newEntities: RentElementsGridItemCustom[] = entities ? [...entities] : [];
    let filtered: RentElementsGridItemCustom[] = [];

    newEntities = newEntities.map((element) => ({
        ...element,
        subRows: element.subRows?.map((subRow) => subRow),
    }));

    newEntities.forEach((element) => {
        if (element.subRows && element.subRows.length > 0) {
            // набор
            if (
                element.stateCode === RentStateCodeEnum.MIXED &&
                params.typeCode?.length === 1 &&
                params.typeCode?.includes(element.stateCode)
            )
                filtered.push(element);
            else {
                filtered.push(element);
                let filteredSubRows: RentElementsGridItemCustom[] = [];
                element.subRows.forEach((subRow) => {
                    if (filterEquipmentElement(subRow, params)) filteredSubRows.push(subRow);
                });
                element.subRows = filteredSubRows;
            }
        } else {
            if (filterEquipmentElement(element, params)) filtered.push(element);
        }
    });

    filtered = filtered.filter((element) => !element.subRows || element.subRows.length > 0);
    return filtered;
};

export const filterEquipmentElement = (element: RentElementsGridItemCustom, params: EquipmentListParams): boolean => {
    ///////
    // Фильтрация по статусу
    // то есть список целевых статусов = (УКАЗАННЫЕ В ФИЛЬТРЕ СТАТУС, если он пустой, то ВСЕ СТАТУСЫ) - СТАТУСЫ ВЫБРАННЫЕ В ФИЛЬТРЕ СКРЫТЬ
    let statuses: any[] = [];
    let _statuses: any[] = [];

    if (!params.typeCode || params.typeCode.length === 0) {
        statuses = [
            RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
            RentStateCodeEnum.SUBRENT,
            RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
            RentStateCodeEnum.SUBRENTCANCELED,
            RentStateCodeEnum.SUBRENTDRAFT,
            //
            RentStateCodeEnum.ORDERED,
            RentStateCodeEnum.BOOKED,
            RentStateCodeEnum.RENT,
            RentStateCodeEnum.RETURNED,
            RentStateCodeEnum.RETURNEDBROKEN,
            RentStateCodeEnum.LOSTDURINGRENT,
            RentStateCodeEnum.CANCELED,
            RentStateCodeEnum.DRAFT,
            //
            RentStateCodeEnum.MIXED,
        ];
    } else {
        statuses = [...params.typeCode];
    }

    let hiddenTypes = params.hide ? getHideStateCodeFilterTypes(params.hide) : [];
    statuses.forEach((status) => {
        if (!hiddenTypes.includes(status)) _statuses.push(status);
    });

    let newEntities: RentElementsGridItemCustom[] = [element];
    newEntities = newEntities.filter((element) => _statuses.includes(element.stateCode));
    if (newEntities.length === 0) return false;

    // if(params.typeCode && params.typeCode.length > 0){
    //     let newEntities:RentElementsGridItemCustom[] = [element];
    //         newEntities = newEntities.filter((element) => params.typeCode?.includes(element.stateCode));
    //     if(newEntities.length === 0) return false;
    // }

    // Фильтр по проблеме
    if (params.problem) {
        let problem = params.problem;
        let newEntities: RentElementsGridItemCustom[] = [element];
        if (problem === ProblemEnum.RETURN_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.returnDelay === true);
        } else if (problem === ProblemEnum.GIVEAWAY_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.giveawayDelay === true);
        } else if (problem === ProblemEnum.BOOK_SHORTAGE) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.bookShortage === true);
        } else if (problem === ProblemEnum.ORDER_SHORTAGE) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.orderShortage === true);
        } else if (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.subrentShipmentDelay === true);
        } else if (problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.subrentReturnToShipperDelay === true);
        } else if (problem === ProblemEnum.ANY_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.anyDelay === true);
        } else if (problem === ProblemEnum.ANY_SHORTAGE) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.anyShortage === true);
        } else if (problem === ProblemEnum.ANY_PROBLEM) {
            newEntities = newEntities.filter(
                (element) => element.problemsAndWarnings?.severity && element.problemsAndWarnings?.severity > 4
            );
        } else if (problem === ProblemEnum.ANY_PROBLEM_OR_WARNING) {
            newEntities = newEntities.filter(
                (element) => element.problemsAndWarnings?.severity && element.problemsAndWarnings?.severity > 0
            );
        }
        if (newEntities.length === 0) return false;
    }

    // Фильтр по дате начала
    if (params.startDate) {
        let newEntities: RentElementsGridItemCustom[] = [element];
        let dateFromMSec = params.startDate[0]
            ? moment(params.startDate[0]).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf()
            : undefined;
        let dateToMSec = params.startDate[1]
            ? moment(params.startDate[1]).hours(23).minutes(59).seconds(59).milliseconds(999).valueOf()
            : undefined;
        if (dateFromMSec && dateToMSec)
            newEntities = newEntities.filter(
                (element) =>
                    dateFromMSec &&
                    moment(element.rentTerms.rentPeriodStartDate).valueOf() >= dateFromMSec &&
                    dateToMSec &&
                    moment(element.rentTerms.rentPeriodStartDate).valueOf() <= dateToMSec
            );
        else if (dateFromMSec)
            newEntities = newEntities.filter(
                (element) => dateFromMSec && moment(element.rentTerms.rentPeriodStartDate).valueOf() >= dateFromMSec
            );
        else if (dateToMSec)
            newEntities = newEntities.filter(
                (element) => dateToMSec && moment(element.rentTerms.rentPeriodStartDate).valueOf() <= dateToMSec
            );
        if (newEntities.length === 0) return false;
    }

    // Фильтр по дате завершения
    if (params.endDate) {
        let newEntities: RentElementsGridItemCustom[] = [element];
        let dateFromMSec = params.endDate[0]
            ? moment(params.endDate[0]).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf()
            : undefined;
        let dateToMSec = params.endDate[1]
            ? moment(params.endDate[1]).hours(23).minutes(59).seconds(59).milliseconds(999).valueOf()
            : undefined;
        if (dateFromMSec && dateToMSec)
            newEntities = newEntities.filter(
                (element) =>
                    dateFromMSec &&
                    moment(element.rentTerms.rentPeriodEndDate).valueOf() >= dateFromMSec &&
                    dateToMSec &&
                    moment(element.rentTerms.rentPeriodEndDate).valueOf() <= dateToMSec
            );
        else if (dateFromMSec)
            newEntities = newEntities.filter(
                (element) => dateFromMSec && moment(element.rentTerms.rentPeriodEndDate).valueOf() >= dateFromMSec
            );
        else if (dateToMSec)
            newEntities = newEntities.filter(
                (element) => dateToMSec && moment(element.rentTerms.rentPeriodEndDate).valueOf() <= dateToMSec
            );
        if (newEntities.length === 0) return false;
    }

    // Фильтр по Скрыть
    // if(params.hide){
    //     let newEntities:RentElementsGridItemCustom[] = [element];
    //     let types = getHideStateCodeFilterTypes(params.hide);
    //     newEntities = newEntities.filter((element) => !types.includes(element.stateCode));
    //     if(newEntities.length === 0) return false;
    // }
    ///////

    return true;
};

interface OperationElementsParams {
    typeCode?: RentStateCodeEnum[];
    discount?: [number|undefined, number|undefined];
    shiftCount?: [number|undefined, number|undefined];
    finalTotalPrice?: [number|undefined, number|undefined];
    problem?: ProblemEnum;
    startDate?: [Date|undefined, Date|undefined];
    endDate?: [Date|undefined, Date|undefined];
    hide?: 'inactive' | 'cancelled' | 'cancelled+draft';
    selectedIds?: number[];
    tags?: string[];
    categoryIds?: number[];
}

export const filterOperationElements = (elements: OperationElement[], filters: OperationElementsParams|undefined, selectedIds: number[]|undefined): OperationElement[] => {
    let filtered: OperationElement[] = [];
    elements.filter((element)=>!element.kitId).forEach((element)=>{
        if(filterOperationElement(element, filters, selectedIds)){
            filtered.push(element);
        }
    });

    filtered.forEach((element)=>{
        if(element.parentId && !filtered.find(el => el.id === element.parentId)){
            let kit = elements.find(el => el.id === element.parentId);
            if(kit) filtered.push(kit);
        }
    })

    console.log('FILTER', filters, elements, filtered);
    return filtered;
};

export const filterOperationElement = (element: OperationElement, filters: OperationElementsParams|undefined, selectedIds: number[]|undefined): boolean => {
    // typeCode
    // problem
    // hide

    // hide=
    //
    //

    // hideStateCode;IN;RETURNED;RETURNED_BROKEN;LOST_DURING_RENT;CANCELED;DRAFT;SUBRENT_RETURNED_TO_SHIPPER;SUBRENT_CANCELED;SUBRENT_DRAFT



    // Фильтр по "Выбрано к правке", выбранным через галочки по id [selectedIds]
    if(selectedIds){
        if(!selectedIds.includes(element.id)) return false;
    }

    // Фильтр по "Категориям" [categoryIds]
    //if(filters?.categoryIds?.length && !filterByCategories(element, filters.categoryIds)) return false;

    // Фильтр по "Статус" [typeCode]
    if(filters?.typeCode?.length && !filterByTypeCode(element, filters.typeCode)) return false;

    // Фильтр по "Скрыть" [hide]
    if(filters?.hide && !filterByHideStateCode(element, filters.hide)) return false;

    // Фильтр по "Теги" [tags]
    if(filters?.tags?.length && !filterByTags(element, filters.tags)) return false;

    // Фильтр по "Наличие проблемы" [problem]
    //if(filters?.problem && filterByProblem(element, filters.problem)) return false;

    // Фильтр по "Начало с" [startDate]
    if(filters?.startDate && !filterDateInterval(element, filters.startDate, 'rentPeriodStartDate')) return false;

    // Фильтр по "Завершение с" [endDate]
    if(filters?.endDate && !filterDateInterval(element, filters.endDate, 'rentPeriodEndDate')) return false;

    // Фильтр по "Скидка" [discount]
    if(filters?.discount && !filterNumberInterval(element, filters.discount, 'discount')) return false;

    // Фильтр по "Смены к оплате" [shiftCount]
    if(filters?.shiftCount && !filterNumberInterval(element, filters.shiftCount, 'shiftCount')) return false;

    // Фильтр по "Итоговая сумма" [finalTotalPrice]
    if(filters?.finalTotalPrice){
        const f:[number|undefined, number|undefined] = [filters.finalTotalPrice[0] ? filters.finalTotalPrice[0] * 100 : filters.finalTotalPrice[0], filters.finalTotalPrice[1] ? filters.finalTotalPrice[1] * 100 : filters.finalTotalPrice[1]];
        if(!filterNumberIntervalByValue(element, f, element.finalTotalPrice)) return false;
    }

    return true;
};


const filterNumberInterval = (element: OperationElement, filter: [number|undefined, number|undefined], fieldName: DeepKeys<OperationElement>) => {
    if(![element].find(e => filter && isDefined(filter[0]) && isDefined(filter[1]) && e[fieldName] >= filter[0] && e[fieldName] <= filter[1])) return false;
    if(![element].find(e => filter && isDefined(filter[0]) && e[fieldName] >= filter[0])) return false;
    if(![element].find(e => filter && isDefined(filter[1]) && e[fieldName] <= filter[1])) return false;
    return true;
}

const filterNumberIntervalByValue = (element: OperationElement, filter: [number|undefined, number|undefined], value: number) => {
    const filterNumber0 = filter[0] || filter[0] === 0  ? filter[0] : undefined;
    const filterNumber1 = filter[1] || filter[1] === 0 ? filter[1] : undefined;

    if(![element].find(e => filterNumber0 !== undefined && filterNumber1 !== undefined && value >= filterNumber0 && value <= filterNumber1)) return false;
    if(![element].find(e => filterNumber0 !== undefined && value >= filterNumber0)) return false;
    if(![element].find(e => filterNumber1 !== undefined && value <= filterNumber1)) return false;
    return true;
}

const filterDateInterval = (element: OperationElement, filter: [Date|undefined, Date|undefined], fieldName: DeepKeys<OperationElement>) => {
    const filterDate0 = filter[0] ? filter[0].valueOf() : undefined;
    const filterDate1 = filter[1] ? filter[1].valueOf() : undefined;
    const elementDate = moment(element[fieldName]).valueOf();

    if(filterDate0 && filterDate1){
        if(![element].find(e => elementDate >= filterDate0 && elementDate <= filterDate1)) return false;
    }else if(filterDate0){
        if(![element].find(e => elementDate >= filterDate0)) return false;
    }else if(filterDate1){
        if(![element].find(e => elementDate <= filterDate1)) return false;
    }
    return true;
}

const filterByHideStateCode = (element: OperationElement, filter: 'inactive' | 'cancelled' | 'cancelled+draft') => {
    if(filter){
        if(element.stateCodeNew && getHideStateCodeFilterStateCodes(filter)?.includes(element.stateCodeNew)) return false;
    }
    return true;
}

const filterByTags = (element: OperationElement, filter: string[]) => {
    if(filter){
        let found = false;
        filter.forEach((f)=>{
            if((element.tags || []).includes(f)) found = true;
        });
        return found;
    }
    return true;
}

const filterByProblem = (element: RentElementsGridItemCustom|OperationElement, filter: ProblemEnum) => {
    if (filter === ProblemEnum.RETURN_DELAY && ![element].find((element) => element.problemsAndWarnings?.returnDelay === true)) return false;
    else if (filter === ProblemEnum.GIVEAWAY_DELAY && ![element].find((element) => element.problemsAndWarnings?.giveawayDelay === true)) return false;
    else if (filter === ProblemEnum.BOOK_SHORTAGE && ![element].find((element) => element.problemsAndWarnings?.bookShortage === true)) return false;
    else if (filter === ProblemEnum.ORDER_SHORTAGE && ![element].find((element) => element.problemsAndWarnings?.orderShortage === true)) return false;
    else if (filter === ProblemEnum.SUBRENT_SHIPMENT_DELAY && ![element].find((element) => element.problemsAndWarnings?.subrentShipmentDelay === true)) return false;
    else if (filter === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY && ![element].find((element) => element.problemsAndWarnings?.subrentReturnToShipperDelay === true)) return false;
    else if (filter === ProblemEnum.ANY_DELAY && ![element].find((element) => element.problemsAndWarnings?.anyDelay === true)) return false;
    else if (filter === ProblemEnum.ANY_SHORTAGE && ![element].find((element) => element.problemsAndWarnings?.anyShortage === true)) return false;
    else if (filter === ProblemEnum.ANY_PROBLEM && ![element].find((element) => element.problemsAndWarnings?.severity && element.problemsAndWarnings?.severity > 4)) return false;
    else if (filter === ProblemEnum.ANY_PROBLEM_OR_WARNING && ![element].find((element) => element.problemsAndWarnings?.severity && element.problemsAndWarnings?.severity > 0)) return false;
    return true;
}

const filterByTypeCode = (element: OperationElement, filter: RentStateCodeEnum[]|undefined) => {
    let statuses: any[] = [];
    let _statuses: any[] = [];

    if (!filter || filter.length === 0) {
        statuses = ALL_STATE_CODES;
    } else {
        statuses = [...filter];
    }

    // let hiddenTypes = params.hide ? getHideStateCodeFilterTypes(params.hide) : [];
    // statuses.forEach((status) => {
    //     if (!hiddenTypes.includes(status)) _statuses.push(status);
    // });
    //
    // let newEntities: RentElementsGridItemCustom[] = [element];
    // newEntities = newEntities.filter((element) => _statuses.includes(element.stateCode));
    // if (newEntities.length === 0) return false;
    if(![element].find((element) => statuses.includes(element.stateCodeNew))) return false;
    return true;
}

const filterByCategories = (element: OperationElement, filter: number[]) => {
    return true;
}

const arr:[SortByElements, SortByEditOperationElements][] = [
    ['numberInActivityFrame', 'numberInActivityFrame'],
    ['nomenclatureShortName', 'productShortName'],
    ['variantName', 'variantName'],
    ['instanceCount', 'instanceCount'],
    ['subrentedInstanceCount', 'subrentedInstanceCount'],
    ['externalCode', 'externalCode'],
    ['effectivePrice', 'effectivePrice'],
    ['stateCode', 'stateCodeNew'],
    ['rentTerms.rentPeriodStartDate', 'rentPeriodStartDate'],
    ['rentTerms.rentPeriodEndDate', 'rentPeriodEndDate'],
    ['rentTerms.shifts.shiftCount', 'shiftCount'],
    ['calendarShifts.shiftCount', 'calendarShiftCount'],
    ['rentTerms.discount', 'discount'],
    ['finalTotalPrice', 'finalPricePerShift'],
];

type SortByElements = DeepKeys<RentElementCommonFieldsObj>;
type SortByEditOperationElements = DeepKeys<OperationElement>;

export const sortByForEditOperationElements = (sortBy:SortByElements):SortByEditOperationElements => {
    const item = arr.find((item)=>item[1] === sortBy);
    return item?.[1] ?? 'productShortName';
};

export const sortByForElements = (sortBy:SortByEditOperationElements):SortByElements => {
    const item = arr.find((item)=>item[0] === sortBy);
    return item?.[0] ?? 'nomenclatureShortName';
};

import { getStringServerProblem } from '../../../../../shared/util/utils';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { subrentsInitialParamsState } from '../reducers/subrents.reducer';

export const getSubrentsFilters = (params: typeof subrentsInitialParamsState) => {
    const filters: string[] = [];

    if (params.status) filters.push('stateCode;IN;' + params.status.split(',').join(';'));
    if (params.hideArchive === true) filters.push('archive;EQ;false');
    if (params.problem) filters.push(getStringServerProblem(params.problem));

    if (params.supplierId !== undefined) filters.push('supplierId;EQ;' + params.supplierId);
    if (params.startDate !== undefined && params.startDate[0] !== undefined) filters.push('startDate;GE;' + params.startDate[0]);
    if (params.startDate !== undefined && params.startDate[1] !== undefined) filters.push('startDate;LE;' + params.startDate[1]);

    if (params.assigneeId !== undefined) filters.push('assigneeId;EQ;' + params.assigneeId);
    if (params.debtToSupplierSum) {
        if (params.debtToSupplierSum[0] != null) filters.push('debtToSupplierSum;GE;' + MoneyUtils.toCents(params.debtToSupplierSum[0]));
        if (params.debtToSupplierSum[1] != null) filters.push('debtToSupplierSum;LE;' + MoneyUtils.toCents(params.debtToSupplierSum[1]));
    }

    return filters;
};

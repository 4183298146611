import { useMemo } from 'react';
import { ProfessionsCompositionListColumnsObj } from '../columns/professionsCompositionColumns';
import { CrewMemberInfoRead } from '../../../../../../../../../../server';

interface UseStaticGridDataProps {
    crewMember: CrewMemberInfoRead;
}

export const useGridData = (props: UseStaticGridDataProps) => {
    const { crewMember } = props;

    return useMemo(
        () =>
            crewMember?.professions?.map(
                (professionObj, index): ProfessionsCompositionListColumnsObj => ({
                    ...professionObj,
                    memberOrder: index,
                    crewMember: crewMember,
                })
            ),
        [crewMember]
    );
};

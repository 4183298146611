import React from 'react';
import {BaseSelect, BaseSelectBaseProps, BaseSelectProps, BaseSelectValue} from "../baseSelect";
import {ProjectRecord, ProjectStateCodeEnum, ProjectTypeCodeEnum, serverApi} from "../../../../server";
import {ServerUtils} from "../../../../core/utils/serverUtils";
import {useAppSelector} from "../../../../store/hooks";

interface Filters {
    stateCode?: ProjectStateCodeEnum[];
    archive?: boolean;
    renterId?: number;
    projectType?: ProjectTypeCodeEnum[];
}

export interface ProjectSelectProps extends BaseSelectBaseProps<ProjectRecord> {
    getOptionProps?: BaseSelectProps<Record>['getOptionProps'];
    filters?: Filters;
}

type Record = ProjectRecord;
const sortBy = 'lastActivityDate';
const sortOrder = 'DESC';

export const ProjectSelectFC = ({filters, ...props}: ProjectSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const fetchRecordsById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listProjects(businessAccountId, undefined, 0, [`id;IN;${ids}`]);
        return response.data.records;
    };

    const fetchRecords = async (startFrom: number, limit: number, search?: string) => {
        let f = ServerUtils.createRequestFilters<ProjectRecord>([
            filters?.renterId ? ['renterId', 'EQ', filters?.renterId] : undefined,
            filters?.stateCode ? ['stateCode', 'IN', filters.stateCode] : undefined,
            filters?.projectType ? ['projectType', 'IN', filters.projectType] : undefined,
            filters?.archive !== undefined ? ['archive', 'EQ', filters.archive] : undefined
        ]);
        const response = await serverApi.listProjects(businessAccountId, limit, startFrom, f, sortBy, sortOrder, search);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={fetchRecordsById}
            fetchRecords={fetchRecords}
            getRecordId={(record) => record.id}
            getOptionProps={props.getOptionProps || ((record) => ({
                label: record.shortName
            }))}
        />
    );
};

export class ProjectSelect extends React.Component<ProjectSelectProps> {
    render() {
        return (
            <ProjectSelectFC {...this.props} />
        );
    }
}

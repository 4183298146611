import { InstanceTrackingTypeCodeEnum } from '../../../../../server';

export abstract class ProductTypeUtils {
    static setProductTypeToInitialValues = (initialValues: Record<string, any>): void => {
        if (initialValues.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) {
            initialValues.variant = true;
            initialValues.tracked = true;
        } else if (initialValues.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK) {
            initialValues.variant = true;
            initialValues.tracked = false;
        } else if (initialValues.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED) {
            initialValues.variant = false;
            initialValues.tracked = true;
        } else if (initialValues.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK) {
            initialValues.variant = false;
            initialValues.tracked = false;
        }
    };
}

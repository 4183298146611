import React, { FC } from 'react';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { WorkPlanningsCalendarItem } from '../../../../../components/Calendar/types/items';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { CrewPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { crewPageUrlRoute } from '../../../../../../crew/utils/tabs';
import { CalendarGroupItemContainer } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/components/container/CalendarGroupItemContainer';
import { IconHardHatSolid } from '../../../../../../../../components/icons';

export const WorkPlanningGroupGroupLine: FC<GroupItemProps<WorkPlanningsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const { record } = groupItem;
    const { shortName, entityId } = record;

    const link = `/${businessAccountId}/${crewPageUrlRoute}/${CrewPageTabsEnum.CREW_MEMBERS}/${entityId}`;

    return (
        <CalendarGroupItemContainer displayType={displayType} item={groupItem}>
            {shortName ? (
                <GroupLine
                    IconComponent={IconHardHatSolid}
                    displayType={displayType}
                    title={{
                        text: shortName,
                        link,
                    }}
                />
            ) : null}
        </CalendarGroupItemContainer>
    );
};

import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../../../store/rtqQueryApi';
import {
    BusinessVersionObj,
    CountryLegalDetailsFieldsObjRead,
    LegalCountryCodeEnum,
    LegalDetailsInfoRead,
    LegalDetailsInfoWrite,
    serverApi
} from '../../../../server';
import {AxiosError} from "axios";

interface GetLegalDetailsFieldsListByCountryQueryFunctionArgs {
    businessAccountId: number;
    legalCountryTypeCode: LegalCountryCodeEnum;
}

const getLegalDetailsFieldsListByCountryQueryFunction = async ({businessAccountId,legalCountryTypeCode}: GetLegalDetailsFieldsListByCountryQueryFunctionArgs) => {
    try {
        const response = await serverApi.getBALegalDetailsFieldsListByCountry(
            businessAccountId,
            legalCountryTypeCode,
        );
        return {data: response.data};
    } catch (error: unknown) {
        return {error: error as AxiosError};
    }
};

interface CreateLegalDetailsQueryFunctionArgs {
    businessAccountId: number;
    infoWrite: LegalDetailsInfoWrite;
}

const createLegalDetailsQueryFunction = async ({businessAccountId, infoWrite}: CreateLegalDetailsQueryFunctionArgs) => {
    try {
        const response = await serverApi.createLegalDetails(businessAccountId, infoWrite);
        return {data: response.data};
    } catch (error: unknown) {
        return {error: error as AxiosError};
    }
};

interface UpdateLegalDetailByIdQueryFunctionArgs {
    businessAccountId: number;
    id: number;
    infoWrite: LegalDetailsInfoWrite;
}

const updateLegalDetailByIdQueryFunction = async ({businessAccountId, id, infoWrite}: UpdateLegalDetailByIdQueryFunctionArgs) => {
    try {
        const response = await serverApi.updateLegalDetailById(businessAccountId, id, infoWrite);
        return {data: response.data};
    } catch (error: unknown) {
        return {error: error as AxiosError};
    }
};

interface DeleteLegalDetailByIdQueryFunctionArgs {
    businessAccountId: number;
    id: number;
    businessVersionObj: BusinessVersionObj
}

const deleteLegalDetailByIdQueryFunction = async ({businessAccountId, id, businessVersionObj}: DeleteLegalDetailByIdQueryFunctionArgs) => {
    try {
        const response = await serverApi.deleteLegalDetailById(businessAccountId, id, businessVersionObj);
        return {data: response.data};
    } catch (error: unknown) {
        return {error: error as AxiosError};
    }
};

export const legalDetailsApi = createApi({
    reducerPath: 'legalDetailsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['LegalDetailsFieldsList', 'LegalDetails'],
    endpoints: (builder) => ({
        getLegalDetailsFieldsListByCountry: builder.query<CountryLegalDetailsFieldsObjRead[], GetLegalDetailsFieldsListByCountryQueryFunctionArgs>({
            queryFn: getLegalDetailsFieldsListByCountryQueryFunction,
            providesTags: ['LegalDetailsFieldsList'],
        }),
        createLegalDetails: builder.mutation<LegalDetailsInfoRead, CreateLegalDetailsQueryFunctionArgs>({
            queryFn: createLegalDetailsQueryFunction
        }),
        updateLegalDetails: builder.mutation<LegalDetailsInfoRead, UpdateLegalDetailByIdQueryFunctionArgs>({
            queryFn: updateLegalDetailByIdQueryFunction
        }),
        deleteLegalDetailById: builder.mutation<void, DeleteLegalDetailByIdQueryFunctionArgs>({
            queryFn: deleteLegalDetailByIdQueryFunction
        }),
    }),
});

export const {
    useGetLegalDetailsFieldsListByCountryQuery,
    useCreateLegalDetailsMutation,
    useUpdateLegalDetailsMutation,
    useDeleteLegalDetailByIdMutation
} = legalDetailsApi;

import React, { FC } from 'react';
import { IconMoneyCheckSolid } from '../../../../../../icons';
import classNames from 'classnames';
import { Icon } from 'antd';

interface PriceIconProps {
    disabled: boolean;
}

export const PriceIcon: FC<PriceIconProps> = (props) => {
    const { disabled } = props;

    return (
        <Icon
            component={IconMoneyCheckSolid}
            style={{
                fontSize: 24,
                userSelect: 'none',
                ...(disabled
                    ? {
                          cursor: 'default',
                          pointerEvents: 'none',
                      }
                    : {
                          color: '#34bfa3',
                          cursor: 'pointer',
                      }),
            }}
            className={classNames({
                'faded-icon': disabled,
            })}
        />
    );
};

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../../shared/reducers';
import { updateModalFilters } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { resetErrors } from '../../../../../../renters/reducers/renters/renter.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { showConfirm } from '../../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../../localization';
import { ProfessionModalFields } from '../../data/ProfessionModalFields';
import { ProfessionInfoRead, ProfessionInfoUpdate } from '../../../../../../../../server';
import { goBack } from 'connected-react-router';
import { ProfessionFormData } from '../../types/ProfessionFormData';
import { CategoryFormData } from '../../../../../../renters/tabs/contacts/modal/create/ContactCreateModal';
import { updateProfession } from '../../../reducers/profession/profession.reducer';
import { ImageUtils } from '../../../../../../../../core/utils/imageUtils';

interface ProfessionEditModalProps extends ModalFormNEWProps, WrappedComponentProps {
    profession: ProfessionInfoRead;
    onOkCallback?: () => void;
}

class ProfessionEditModalComponent extends ModalFormNEW<ProfessionEditModalProps & StoreProps> {
    static defaultProps = {
        formFields: ProfessionModalFields,
    };

    getInitialValues = () => {
        const { profession } = this.props;
        const mainImage = profession.mainImage
            ? ([ImageUtils.getImageFromImageObj(profession.mainImage)] as CategoryFormData['mainImage'])
            : undefined;

        let initialValues: ProfessionFormData & {
            id: number;
        } = {
            ...profession,
            externalCode: profession.externalCode!,
            pricingScheme: profession.pricingScheme
                ? {
                      key: String(profession.pricingScheme.id),
                      label: profession.pricingScheme.name,
                      data: profession.pricingScheme,
                  }
                : undefined,
            shiftLengthInMinutes: profession.shiftLengthInMinutes!,
            mainImage,
            id: profession.id,
        };

        initialValues['_shiftLengthInMinutes'] = initialValues.shiftLengthInMinutes;
        initialValues.hasOwnShiftLength = !initialValues.hasOwnShiftLength;

        if (this.props.initialValues) {
            initialValues = {
                ...initialValues,
                ...this.props.initialValues,
            };
        }

        return initialValues;
    };

    onOk = async (data: unknown) => {
        const formData = data as ProfessionFormData;
        const imageResponse = formData?.mainImage?.[0]?.response;
        const mainImage = imageResponse
            ? {
                  id: imageResponse.id,
              }
            : undefined;
        const pricingSchemeId: number | string = formData.pricingScheme?.['id'] ?? formData.pricingScheme?.['key'];

        formData.hasOwnShiftLength = !formData.hasOwnShiftLength;

        const professionInfoUpdate: ProfessionInfoUpdate = {
            ...this.props.profession,
            ...formData,
            pricingSchemeId: pricingSchemeId ? Number(pricingSchemeId) : undefined,
            mainImage,
        };

        if (professionInfoUpdate.pricePerShift == null) professionInfoUpdate.pricePerShift = 0;

        delete professionInfoUpdate['pricingScheme'];
        delete professionInfoUpdate['_shiftLengthInMinutes'];

        try {
            await this.props.updateProfession({
                businessAccountId: this.props.businessAccountId,
                id: this.props.profession.id,
                professionInfoUpdate,
            });

            if (this.props.onOkCallback) this.props.onOkCallback();
        } catch (e) {}
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.profession.updating,
    updatingError: storeState.profession.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalFormNEWProps | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    updateProfession,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const ProfessionEditModal = connector(injectIntl(ProfessionEditModalComponent));

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { inputNumberParser } from './numberInput.utils';
import { isNumber } from '../../shared/util/isNumber';
import classNames from 'classnames';
import { centsToDollars } from './utils/centsToDollars';
import { dollarsToCents } from './utils/dollarsToCents';

export interface NumberInputMoneyProps extends Omit<InputNumberProps, 'formatter' | 'parser'> {
    flexible?: boolean;
}

const symbolLength = 12;
const flexibleMaxSymbols = 14;
const buttonWidth = 56;

const maxLength = 11;

export const NumberInputMoney: FC<NumberInputMoneyProps> = (props) => {
    const { flexible, ...inputProps } = props;
    const lastValueRef = useRef('');
    const [hasError, setHasError] = useState(false);

    const value = centsToDollars(props.value);
    const defaultValue = centsToDollars(props.defaultValue);

    const currentWidth = flexible && value ? value.toString().length * symbolLength + buttonWidth * 2 + symbolLength * 1.5 : undefined;
    const maxWidth = flexible ? flexibleMaxSymbols * symbolLength + buttonWidth * 2 : undefined;

    const parser = useCallback((displayValue: string | undefined): number | string => {
        if (displayValue && displayValue.length > maxLength) {
            setHasError(true);
            return lastValueRef.current;
        }

        let value = inputNumberParser(displayValue);

        if (isNumber(value) && value !== '' && (value.toString().split('.')[1] || '').length > 2) {
            const p = value.toString().split('.');
            value = p[0] + '.' + p[1].substring(0, 2);
        }

        const newValue = String(value).slice(0, maxLength);
        lastValueRef.current = newValue;

        return newValue;
    }, []);

    const onChange = useCallback(
        (value: number | undefined | null | string) => {
            if (inputProps.onChange) {
                let v: number | undefined;
                if (value === null || value === undefined || value === '') v = undefined;
                else if (isNumber(value)) v = dollarsToCents(Number(value));
                inputProps.onChange(v);
            }
        },
        [inputProps]
    );

    useEffect(() => {
        if (hasError) setTimeout(() => setHasError(false), 700);
    }, [hasError]);

    return (
        <InputNumber
            step={1}
            {...inputProps}
            parser={parser}
            style={{
                ...inputProps.style,
                width: currentWidth,
                maxWidth: maxWidth,
            }}
            className={classNames(inputProps.className, {
                'has-error': hasError,
                fast: hasError,
            })}
            onChange={onChange}
            {...value !== undefined ? {value} : {}}
            {...defaultValue !== undefined ? {defaultValue} : {}}
        />
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { Icon, Tabs } from 'antd';
import './categories-page.less';
import { IRootState } from '../../../shared/reducers';
import { changeType, createCategory, selectCategory } from './reducers/categories.reducer';
import { RouteComponentProps, withRouter } from 'react-router';
import RoundButton from '../../../components/button/roundButton';
import RenterCategoriesTabPane from './RenterCategoriesTabPane';
import ProductCategoriesTabPane from './ProductCategoriesTabPane';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { getPathFromState, getStateFromPath } from '../../../shared/util/utils';
import { push } from 'connected-react-router';
import { logEvent } from '../../../analytics/analytics';
import { LocalizationEnum, localize } from '../../../localization';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { IconSortSolid } from '../../../components/icons';
import { CategorySortOrderEnum, CategoryTypeCodeEnum } from '../../../server';
import { CategoriesBlockSortProps } from './categories-block-sort';
import { saveCurrentTabToStore, updateCategoriesSortOrder } from '../../../shared/reducers/userSettings/userSettings.reducer';

interface IState {
    mainBlockHeight: number;
    tabState: {
        selectedTab: CategoryTypeCodeEnum;
        sortState: CategoriesBlockSortProps;
    };
}

const sortTitles = {
    [CategorySortOrderEnum.NAME]: 'Наименованию',
    [CategorySortOrderEnum.SIBLINGORDER]: 'Заданному порядку',
};

export const getCategoriesUrlPath = (businessAccountId: number): string => `/${businessAccountId}/categories`;
export const getCategoriesSetOrderUrlPath = (businessAccountId: number): string => `${getCategoriesUrlPath(businessAccountId)}/setOrder`;

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

class CategoriesPage extends BasePage<IProps, IState> {
    logViewEvent = () => {
        logEvent({ type: 'list categories', data: { tab: this.state.tabState.selectedTab } });
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            mainBlockHeight: 0,
            tabState: {
                selectedTab: this.props.tab ?? CategoryTypeCodeEnum.PRODUCT,
                sortState: {
                    value: CategorySortOrderEnum.SIBLINGORDER,
                    titles: sortTitles,
                    onChangeSortState: this.onChangeSortState,
                },
            },
        };

        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
        this.logViewEvent();
    }

    componentDidUpdate() {
        if (this.props.tab) saveCurrentTabToStore('categories', this.props.tab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        let element = document.getElementsByClassName('rr-categories-main-block')[0];
        if (element) {
            let bodyRect = document.body.getBoundingClientRect();
            let elRect = element.getBoundingClientRect();
            let offset = elRect.top - bodyRect.top;
            this.setState({
                mainBlockHeight: window.innerHeight - offset - 110,
            });
        }
    };

    onCategoryTypeChange = (key: string) => {
        this.props.push(getPathFromState(this.props.location.pathname, '', { tab: key }));
        this.props.changeType(key as CategoryTypeCodeEnum);
        const sortStateValue = this.props.categoriesSortOrder?.[key as CategoryTypeCodeEnum];
        const newSortState: CategoriesBlockSortProps = sortStateValue
            ? {
                  ...this.state.tabState.sortState,
                  value: sortStateValue,
              }
            : this.state.tabState.sortState;
        this.setState({
            tabState: {
                ...this.state.tabState,
                selectedTab: key as string as CategoryTypeCodeEnum,
                sortState: newSortState,
            },
        });

        saveCurrentTabToStore('categories', key);
        this.logViewEvent();
    };

    createRootCategory = () => {
        this.props.createCategory(this.props.tab, null);
        this.props.selectCategory(this.props.tab, undefined);
    };

    setCategoriesOrder = () => {
        this.props.push(getCategoriesSetOrderUrlPath(this.props.businessAccountId) + `?tab=${this.state.tabState.selectedTab}`);
    };

    onChangeSortState = (sortState: Partial<CategoriesBlockSortProps>) => {
        this.setState({
            tabState: {
                ...this.state.tabState,
                sortState: {
                    ...this.state.tabState.sortState,
                    ...sortState,
                },
            },
        });
    };

    // TODO Название категории должно быть: Управление категориями
    renderContent() {
        let rootCategoryDisabled =
            this.props.tab === CategoryTypeCodeEnum.PRODUCT
                ? this.props.selectedCategory.products.selectedCategoryId === undefined
                : this.props.selectedCategory.renters.selectedCategoryId === undefined;

        return (
            <>
                <Tabs
                    onChange={this.onCategoryTypeChange}
                    activeKey={this.props.tab}
                    className={'rr-tabs'}
                    type="line"
                    animated={false}
                    tabBarExtraContent={
                        <div className={'buttons-block'}>
                            {this.state.tabState.sortState.value === CategorySortOrderEnum.SIBLINGORDER ? (
                                <RoundButton colorScheme={'default'} onClick={this.setCategoriesOrder}>
                                    <Icon component={IconSortSolid} />
                                    Задать порядок
                                </RoundButton>
                            ) : null}
                            {rootCategoryDisabled ? null : (
                                <RoundButton
                                    icon={'plus'}
                                    colorScheme={'success'}
                                    onClick={this.createRootCategory}
                                    disabled={rootCategoryDisabled}
                                >
                                    {localize(LocalizationEnum.PAGE__CATEGORIES__ACTIONS__CREATE_CATEGORY, 'span')}
                                </RoundButton>
                            )}
                        </div>
                    }
                >
                    <Tabs.TabPane tab={'Продукты и наборы'} key={CategoryTypeCodeEnum.PRODUCT}>
                        <ProductCategoriesTabPane
                            key={CategoryTypeCodeEnum.PRODUCT}
                            typeCode={CategoryTypeCodeEnum.PRODUCT}
                            sortState={this.state.tabState.sortState}
                            height={this.state.mainBlockHeight}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={localize(LocalizationEnum.ASPECT__MAIN_MENU__COUNTERPARTIES)} key={CategoryTypeCodeEnum.RENTER}>
                        <RenterCategoriesTabPane
                            key={CategoryTypeCodeEnum.RENTER}
                            typeCode={CategoryTypeCodeEnum.RENTER}
                            sortState={this.state.tabState.sortState}
                            height={this.state.mainBlockHeight}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        selectedType: storeState.categories.selectedType,
        selectedCategory: storeState.categories,
        tab:
            searchParams.tab && (searchParams.tab === CategoryTypeCodeEnum.PRODUCT || searchParams.tab === CategoryTypeCodeEnum.RENTER)
                ? searchParams.tab
                : CategoryTypeCodeEnum.PRODUCT,
        businessAccountId: storeState.system.businessAccountId,
        categoriesSortOrder: storeState.userSettings.categoriesSortOrder,
    };
};

const mapDispatchToProps = {
    changeType,
    createCategory,
    selectCategory,
    push,
    resetOperationForm,
    updateCategoriesSortOrder,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesPage));

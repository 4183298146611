import { Component, FC } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { BaseSelect, BaseSelectBaseProps, BaseSelectProps, BaseSelectValue } from '../baseSelect';
import { ProfessionRecord, serverApi } from '../../../../server';

export interface ProfessionSelectProps extends BaseSelectBaseProps<ProfessionRecord> {
    getOptionProps?: BaseSelectProps<ProfessionRecord>['getOptionProps'];
}

const ProfessionSelectComponent: FC<ProfessionSelectProps> = (props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        const response = await serverApi.listProfessions(
            businessAccountId,
            limit,
            startFrom,
            ['archive;EQ;false'],
            'lastActivityDate',
            'DESC',
            search
        );
        return response.data.records;
    };

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listProfessions(businessAccountId, undefined, 0, [`id;IN;${ids}`]);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecords={getEntitiesList}
            fetchRecordsById={getEntitiesById}
            getRecordId={(item) => item.id}
            getOptionProps={
                props.getOptionProps ||
                ((item) => ({
                    label: item.shortName,
                    children: item.shortName,
                }))
            }
        />
    );
};

export class ProfessionSelect extends Component<ProfessionSelectProps> {
    render() {
        return <ProfessionSelectComponent {...this.props} />;
    }
}

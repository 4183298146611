import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import './kb-page.less';
import { Col, Row } from 'antd';
import { WIKI_LANG_PAGE_IDS, WIKI_SERVER_BASE_PATH } from '../../../config/config';
import { push } from 'connected-react-router';
import Spin from '../../../components/spin/spin';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { ImgShowModal } from './img-show-modal';
import { TelegramLink } from '../../../components/pageHeader/components/TelegramLink/TelegramLink';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

interface IState {
    frameHeight: number;
    title?: string;
    imgSrc?: string;
    frameIsLoading: boolean;
}

export const iframeBaseParams: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = {
    frameBorder: 0,
    width: '100%',
};

class KBPage extends BasePage<IProps, IState> {
    private iframeRef;

    constructor(props: IProps) {
        super(props);
        this.state = {
            frameHeight: 0,
            frameIsLoading: true,
        };
        this.props.resetOperationForm();
    }

    componentDidMount() {
        window.addEventListener('message', this.handleIframeTask);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeTask);
    }

    onImageHide = () => {
        this.setState({ imgSrc: undefined });
    };

    frameOnLoad = (e) => {
        this.setState((state, props) => {
            let newState = { ...state, frameIsLoading: false };
            if (!newState.frameHeight) newState.frameHeight = 100;
            return newState;
        });
    };

    handleIframeTask = (e: MessageEvent) => {
        if (e.data) {
            let { id } = e.data;
            if (id === 'scroll_to') {
                window.scrollTo(0, this.iframeRef.getBoundingClientRect().top + e.data.offset - document.body.getBoundingClientRect().top);
            } else if (id === 'height_changed') {
                this.setState({ frameHeight: e.data.height });
            } else if (id === 'image_clicked') {
                this.setState({ imgSrc: WIKI_SERVER_BASE_PATH + e.data.src });
            } else if (id === 'title_changed') {
                this.setState({ title: e.data.title });
            } else if (id === 'page_clicked') {
                this.props.push(`/${this.props.businessAccountId}/kb/${encodeURIComponent(e.data.page_id)}`);
            }
        }
    };

    getPageHeaderProps(): IPageHeaderProps {
        return {
            name: this.props.pageId ? this.state.title : undefined,
            parentPagePath: this.props.pageId ? `/${this.props.businessAccountId}/kb` : undefined,
            rightBlock: <TelegramLink />,
        };
    }

    renderContent() {
        let pageId = this.props.pageId ? decodeURIComponent(this.props.pageId) : WIKI_LANG_PAGE_IDS[this.props.intl.locale];
        if (pageId == undefined) pageId = Object.values(WIKI_LANG_PAGE_IDS)[0];
        const frameProps: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = {
            src: `${WIKI_SERVER_BASE_PATH}/plugins/servlet/rr-remotepageview?pageId=${pageId}`, // 'https://wiki.dev.rr.council.ru/plugins/servlet/rr-remotepageview?pageId=1016044'
            height: this.state.frameHeight,
            onLoad: this.frameOnLoad,
        };

        return (
            <>
                <Row type="flex" justify="center">
                    <Col span={24}>
                        <Spin delay={0} spinning={this.state.frameIsLoading} wrapperClassName={'rr-kb-page-spin'}>
                            <iframe
                                ref={(ref) => (this.iframeRef = ref)}
                                key={frameProps.src}
                                {...iframeBaseParams}
                                {...frameProps}
                                title={'Вопросы и ответы'}
                            />
                        </Spin>
                    </Col>
                </Row>
                {this.state.imgSrc && <ImgShowModal onCancel={this.onImageHide} src={this.state.imgSrc} />}
            </>
        );
    }
}

const mapDispatchToProps = { push, resetOperationForm };

const mapStateToProps = (storeState: IRootState, ownProps: IBasePageProps) => ({
    businessAccountId: storeState.system.businessAccountId,
    pageId: ownProps.match.params['id'],
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(KBPage);

import { AxiosResponse } from 'axios';
import { serverApi } from '../../../../../server';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';

export interface PriceGenerateArgs {
    businessAccountId: number;
    id: number;
    common: boolean;
    documentTemplateId: number;
    legalDetailId?: number;
}

export const generateBill = async (args: PriceGenerateArgs): Promise<Blob> => {
    const { businessAccountId, id, common, documentTemplateId, legalDetailId } = args;

    const res: AxiosResponse<Blob> = await serverApi.generateBillPdf(
        businessAccountId,
        id,
        common,
        documentTemplateId,
        getCurrentTimezoneUTCOffset(),
        legalDetailId,
        {
            responseType: 'blob',
        }
    );

    return res.data;
};

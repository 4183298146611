import React from 'react';
import { VehicleSelectFC, VehicleSelectProps } from './vehicleSelect';
import { SelectAndCreate } from '../selectAndCreate';
import { VehicleCreateModalNew } from '../../../../modules/main/logistics/tabs/vehicles/modal/create/VehicleCreateModalNew';

export interface VehicleSelectAndCreateProps extends VehicleSelectProps {}

export class VehicleSelectAndCreate extends React.Component<VehicleSelectAndCreateProps> {
    render() {
        const { props } = this;
        return (
            <SelectAndCreate
                select={() => <VehicleSelectFC {...props} />}
                modal={({ close }) => {
                    return (
                        <VehicleCreateModalNew
                            onSuccess={(data) => {
                                props.onChange?.(data.id);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}

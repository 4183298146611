import React, {FC} from 'react';
import {VariantInfoRead} from "../../../../../../server";
import {CurrentSubrentIndicatorsTab} from "../../../../../../components/currentRentIndicators/currentSubrentIndicatorsTab";
import {Row} from "antd";

interface ProductVariantPopoverContentSubrentTabProps {
    entity?: VariantInfoRead;
}

export const ProductVariantPopoverContentSubrentTab:FC<ProductVariantPopoverContentSubrentTabProps> = ({entity}) => {
    return (
        <Row className="tab-popap-instance-row">
            {entity ? (
                <CurrentSubrentIndicatorsTab entity={entity} entityType={'variant'} />
            ) : null}
        </Row>
    );
};

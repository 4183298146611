import React, { FC } from 'react';
import { OffersList } from '../../../../../offers/list/offersList';
import { ProjectPageGridNamesEnum } from '../../projectPage';

import { ProjectsPageTabsEnum } from '../../../../../../../shared/constants/tabEnums';

interface ProjectOffersTabProps {
    visible: boolean;
    projectId: number;
}

export const ProjectOffersTab: FC<ProjectOffersTabProps> = ({ visible, projectId }) => {
    return (
        <>
            <OffersList
                gridName={ProjectPageGridNamesEnum.OFFERS}
                urlPath={`projects/production/${projectId}?tab=${ProjectsPageTabsEnum.OFFERS}`}
                visible={visible}
                projectId={projectId}
                staticPageParams={{ projectId }}
            />
        </>
    );
};

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { ListLifecycleEventsFilters } from '../api/lifecycleEvents.api.types';
import { EntityLifecycleEventTypeCode, EntityTypeCodeEnum } from '../../../../../../server';

export const LifecycleEventsFiltersDescription: Required<PageURLParamDescriptionObject<ListLifecycleEventsFilters>> = {
    authorId: PageURLParamDescriptions.numberParam,
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    entityTypeCode: PageURLParamDescriptions.enumSelectParam(EntityTypeCodeEnum),
    entityLifecycleEventTypeCode: PageURLParamDescriptions.enumSelectParam(EntityLifecycleEventTypeCode),
};

import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { push } from 'connected-react-router';
import { PageURLParamDescriptions } from '../utils/descriptions';

export const useSetUrlForDrawer = <Entity extends { id?: number | string }>(
    rootPath: string,
    urlSearchParams: URLSearchParams,
    isDrawerAvailable: boolean = true
): [(entity: Entity | null) => void, () => void] => {
    const searchParamsRef = useRef(urlSearchParams);
    const dispatch = useAppDispatch();
    useEffect(() => {
        searchParamsRef.current = urlSearchParams;
    }, [urlSearchParams]);

    const setUrlForDrawer = useCallback(
        (entity: Entity | null) => {
            if (!isDrawerAvailable) return;

            const searchParams = new URLSearchParams(searchParamsRef.current.toString());

            const entityId = entity?.id;
            const connectionString = rootPath.includes('?') ? '&' : '/?';

            if (entityId == null) {
                searchParams.delete('view');
                searchParams.delete('id');

                const path = `${rootPath}${searchParams.toString() ? `${connectionString}${searchParams.toString()}` : ''}`;
                dispatch(push(path));

                return;
            }

            searchParams.append('view', PageURLParamDescriptions.booleanParam.toString(true) ?? '');
            searchParams.append('id', String(entityId));

            const path = `${rootPath}${connectionString}${searchParams.toString()}`;
            dispatch(push(path));
        },
        [dispatch, isDrawerAvailable, rootPath]
    );

    const clearUrlForDrawer = useCallback(() => {
        setUrlForDrawer(null);
    }, [setUrlForDrawer]);

    return [setUrlForDrawer, clearUrlForDrawer];
};

import {
    ProjectInfoRead,
    ProjectTypeCodeEnum,
    VersionedEntityObjList,
    WorkPlanningsRecord,
    WorkPlanningsStateCodeEnum,
    WorkPlanningsTransitionCodeEnum,
} from '../../../../server';
import React, { useCallback, useMemo, useState } from 'react';
import { _Grid } from '../../../../components/grid/Grid';
import { useMoveWorkPlanningsToAnotherProjectMutation, workPlanningsApi } from '../api/workPlannings.api';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useSetStatusForWorkPlanningsCallback } from './useSetStatusForWorkPlanningsCallback';
import { ActionButtonsGroup } from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { workPlanningActionsMap, workPlanningActionToTransitionCodeMap, WorkPlanningActionType } from '../data/workPlanningsData';
import { isActionAvailable } from '../utils/isActionAvailable';
import RoundButton from '../../../../components/button/roundButton';
import { Button, Icon } from 'antd';
import { IconArrowRightSolid, IconCopy, IconCopyAnonymous } from '../../../../components/icons';
import AdditionalEntityActions from '../../../../components/additionalEntityActions/additionalEntityActions';
import { SelectProjectPopover } from '../../elements/components/SelectProjectPopover/SelectProjectPopover';
import { AdditionalEntityActionsButton } from '../../../../components/additionalEntityActions/additionalEntityActionsButton';

export const useEntityActionsButtons = ({
    selectedRecords,
    gridRef,
    parentProjectEntity,
    moveWorkPlanningsToAnotherProject,
}: {
    selectedRecords: WorkPlanningsRecord[];
    gridRef: React.RefObject<_Grid>;
    parentProjectEntity: ProjectInfoRead | undefined;
    moveWorkPlanningsToAnotherProject: ReturnType<typeof useMoveWorkPlanningsToAnotherProjectMutation>[0];
}) => {
    const dispatch = useAppDispatch();
    const projectId = useAppSelector((store) => store.project.entity?.id);
    const offerId = useAppSelector((store) => store.offer.entity?.id);
    const [closeMoveElementPopupVisible, setCloseMoveElementPopupVisible] = useState(false);

    const closeMoveElementPopup = useCallback(() => {
        setCloseMoveElementPopupVisible(true);
    }, []);

    const { callback: setStatusForWorkPlanningsCallback, isLoading } = useSetStatusForWorkPlanningsCallback(selectedRecords, gridRef);

    const moveAction = useCallback(
        (targetProjectId: number, copy: boolean, saveCrewMembers: boolean, targetProjectType: ProjectTypeCodeEnum | undefined) => {
            const currentProjectId = projectId || offerId;
            if (currentProjectId == null) return;

            const versionedEntityObjList: VersionedEntityObjList = {
                entities: selectedRecords.map((record) => ({
                    id: record.id,
                    businessVersion: record.businessVersion,
                })),
            };

            moveWorkPlanningsToAnotherProject({
                projectId: currentProjectId,
                copy: copy,
                targetProjectId,
                saveCrewMembers,
                versionedEntityObjList,
                targetProjectType,
            }).then((res) => {
                if ('data' in res) {
                    gridRef.current?.clearSelection();
                    dispatch(workPlanningsApi.util?.invalidateTags(['WorkPlanningsList']));
                }
            });
        },
        [dispatch, gridRef, moveWorkPlanningsToAnotherProject, offerId, projectId, selectedRecords]
    );

    const stateCodes = useMemo(() => {
        const active = selectedRecords.some((record) =>
            [WorkPlanningsStateCodeEnum.PLANNED, WorkPlanningsStateCodeEnum.INPROGRESS].includes(record.stateCode)
        );
        const finished = selectedRecords.some((record) => [WorkPlanningsStateCodeEnum.FINISHED].includes(record.stateCode));

        return {
            active,
            finished,
        };
    }, [selectedRecords]);

    return useMemo(
        () => ({
            buttons: [
                <ActionButtonsGroup
                    data={workPlanningActionsMap}
                    mainActions={[
                        isActionAvailable(selectedRecords, WorkPlanningsTransitionCodeEnum.TODRAFT, WorkPlanningActionType.toDraft),
                        isActionAvailable(selectedRecords, WorkPlanningsTransitionCodeEnum.PLAN, WorkPlanningActionType.plan),
                        isActionAvailable(selectedRecords, WorkPlanningsTransitionCodeEnum.START, WorkPlanningActionType.start),
                        isActionAvailable(selectedRecords, WorkPlanningsTransitionCodeEnum.FINISH, WorkPlanningActionType.finish),
                        isActionAvailable(selectedRecords, WorkPlanningsTransitionCodeEnum.CANCEL, WorkPlanningActionType.cancel),
                    ]}
                    onAction={(action) => {
                        const code = workPlanningActionToTransitionCodeMap[action];
                        if (code) setStatusForWorkPlanningsCallback(code);
                    }}
                />,
                <>
                    {parentProjectEntity != null && parentProjectEntity.projectType === ProjectTypeCodeEnum.OFFER && (
                        <RoundButton
                            colorScheme={'success'}
                            onClick={() => {
                                if (parentProjectEntity.mainProjectId == null) return;

                                moveAction(parentProjectEntity.mainProjectId, true, true, ProjectTypeCodeEnum.BASE);
                            }}
                        >
                            <Icon component={IconCopy} style={{ color: '#FFFFFF' }} />
                            <span>В основной проект</span>
                        </RoundButton>
                    )}
                </>,
                <AdditionalEntityActions
                    disabled={closeMoveElementPopupVisible}
                    isElement
                    overlayStyle={{ minWidth: 320 }}
                    content={
                        <>
                            <SelectProjectPopover
                                stateCodes={stateCodes}
                                closeParentElement={closeMoveElementPopup}
                                projectId={selectedRecords[0]?.projectId}
                                copy={false}
                                onOkCallback={(targetProject) => {
                                    moveAction(targetProject.id, false, false, targetProject.projectType);
                                }}
                            >
                                <Button block>
                                    <Icon component={IconArrowRightSolid} style={{ color: '#8d75ca' }} />
                                    <span>{'Переместить'}</span>
                                </Button>
                            </SelectProjectPopover>
                            <SelectProjectPopover
                                stateCodes={stateCodes}
                                closeParentElement={closeMoveElementPopup}
                                projectId={selectedRecords[0]?.projectId}
                                copy={true}
                                onOkCallback={(targetProject) => {
                                    moveAction(targetProject.id, true, true, targetProject.projectType);
                                }}
                            >
                                <Button block>
                                    <Icon component={IconCopy} style={{ color: '#57d6b9' }} />
                                    <span>{'Скопировать'}</span>
                                </Button>
                            </SelectProjectPopover>
                            <SelectProjectPopover
                                stateCodes={stateCodes}
                                closeParentElement={closeMoveElementPopup}
                                projectId={selectedRecords[0]?.projectId}
                                copy={true}
                                onOkCallback={(targetProject) => {
                                    moveAction(targetProject.id, true, false, targetProject.projectType);
                                }}
                            >
                                <Button block>
                                    <Icon component={IconCopyAnonymous} style={{ color: '#04b0f1' }} />
                                    <span>{'Скопировать без работников'}</span>
                                </Button>
                            </SelectProjectPopover>
                        </>
                    }
                >
                    <AdditionalEntityActionsButton
                        onClick={() => {
                            setCloseMoveElementPopupVisible(false);
                        }}
                    />
                </AdditionalEntityActions>,
            ],
            isLoading,
        }),
        [
            closeMoveElementPopup,
            closeMoveElementPopupVisible,
            isLoading,
            moveAction,
            parentProjectEntity,
            selectedRecords,
            setStatusForWorkPlanningsCallback,
            stateCodes,
        ]
    );
};

import { setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { push } from 'connected-react-router';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { useMemo } from 'react';
import { useAppDispatch } from '../../../../../../store/hooks';
import { ElementsCalendarFilters } from '../filters/elementsCalendarFilters.types';
import { useLocation } from 'react-router-dom';
import { elementsCalendarPageName } from '../ElementsCalendar';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { RentStateCodeEnum } from '../../../../../../server';
import _ from 'lodash';
import { ParamsUtils } from '../../../../../../core/utils/paramsUtils';
import { UseFiltersChanges } from '../../../components/CalendarFilters/CalendarFilters';

export const useFiltersChanges: UseFiltersChanges<ElementsCalendarFilters> = (filtersForm: WrappedFormUtils | undefined) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    return useMemo(
        () =>
            debounce(async (params: ElementsCalendarFilters) => {
                const newParams = _.cloneDeep(
                    ParamsUtils.getProcessedParamsWithSelectors({
                        pageParams: params,
                    })
                );

                if (newParams.hideInactive === false) newParams.hideInactive = undefined;

                delete newParams.productInstanceTrackingTypeCode;

                if (
                    newParams.typeCode &&
                    (newParams.typeCode!.indexOf(RentStateCodeEnum.MIXED) !== -1 ||
                        newParams.typeCode!.indexOf(RentStateCodeEnum.CANCELED) !== -1 ||
                        newParams.typeCode!.indexOf(RentStateCodeEnum.LOSTDURINGRENT) !== -1 ||
                        newParams.typeCode!.indexOf(RentStateCodeEnum.RETURNEDBROKEN) !== -1 ||
                        newParams.typeCode!.indexOf(RentStateCodeEnum.RETURNED) !== -1)
                ) {
                    newParams.hideInactive = false;
                }

                if (Array.isArray(newParams.typeCode) && newParams.typeCode.length === 0) {
                    newParams.typeCode = undefined;
                }

                if (Array.isArray(newParams.categoryIds) && newParams.categoryIds.length === 0) {
                    newParams.categoryIds = undefined;
                }

                setGridStorageDataFilters(elementsCalendarPageName, newParams);
                dispatch(push(getPathFromState(location.pathname, location.search, newParams)));
            }, 300),
        [dispatch, location.pathname, location.search]
    );
};

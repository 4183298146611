import { ExcelReportGenerationInfo, FileTypeCodeEnum, serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';

export interface ReportGenerateArgs {
    businessAccountId: number;
    id: number;
    excelReportGenerationInfo: ExcelReportGenerationInfo;
}

export const generateReport = async (args: ReportGenerateArgs, fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE) => {
    if (fileType === FileTypeCodeEnum.EXCEL) {
        const res: AxiosResponse<Blob> = await serverApi.generateReportXlsx(
            args.businessAccountId,
            args.id,
            getCurrentTimezoneUTCOffset(),
            args.excelReportGenerationInfo,
            { responseType: 'blob' }
        );

        return res.data;
    }

    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        const res: AxiosResponse<Blob> = await serverApi.generateReportPdf(
            args.businessAccountId,
            args.id,
            getCurrentTimezoneUTCOffset(),
            args.excelReportGenerationInfo,
            { responseType: 'blob' }
        );

        return res.data;
    }

    const _: never = fileType;
    return new Blob();
};

import React from 'react';

export const IconNoImage = () => (
    <svg viewBox="0 0 67 53" width="1em" height="1em" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" fill="none">
        <g fill="currentColor">
            <path d="M65.9384625,0 L1.06153749,0 C0.475356486,0 0,0.476518372 0,1.06460762 L0,51.9353924 C0,52.5234816 0.475356486,53 1.06153749,53 L65.9384625,53 C66.5250681,53 67,52.5234816 67,51.9353924 L67,1.06460762 C67,0.476518372 66.5250681,0 65.9384625,0 Z M60.6307751,44.5123093 L46.0312377,29.3493158 C45.869884,29.1821724 45.6059858,29.1713134 45.4318937,29.3250428 L35.3058876,38.2632755 L22.3631979,22.2811729 C22.2791242,22.1768414 22.1561981,22.1172233 22.0171367,22.1202042 C21.8831707,22.1229722 21.7581216,22.1874874 21.6780816,22.2952257 L6.36922492,42.8964478 L6.36922492,6.38764573 L60.6307751,6.38764573 L60.6307751,44.5123093 L60.6307751,44.5123093 Z"></path>
            <path d="M43.9998981,22 C46.759545,22 49,19.761699 49,17.0002038 C49,14.2395239 46.759545,12 43.9998981,12 C41.2380088,12 39,14.2395239 39,17.0002038 C39,19.7614952 41.2382127,22 43.9998981,22 Z"></path>
        </g>
    </g>
</svg>
);

import React from 'react';
import {Icon} from "antd";
import {IconEraser, IconReply} from "../../../../../components/icons";
import './OperationElementCancelToggleButton.less';
import {joinClasses} from "../../../../../shared/util/utils";

interface IProps {
    cancelled:boolean;
    onChange: (value:boolean)=>void;
}

interface IState {
}

export class OperationElementCancelToggleButton extends React.Component<IProps, IState> {

    onClick = () => {
        let cancelled = !this.props.cancelled;
        this.props.onChange(cancelled);
    };

    render = () => {
        return (
            <Icon className={joinClasses('rr-OperationElementCancelToggleButton', this.props.cancelled ? 'rr-OperationElementCancelToggleButton-cancelled' : '')} component={this.props.cancelled ? IconReply : IconEraser} onClick={this.onClick}  />
        );
    };
}

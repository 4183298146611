import { FileExtensionTypeCodeEnum, FileTypeCodeEnum } from '../../../server';
import { fileDataToDownload } from '../../../shared/util/downloadDataFromExcel';
import { ActGenerateArgs, generateAct } from './utils/generate/generateAct';
import { EstimateGenerateArgs, generateEstimate } from './utils/generate/generateEstimate';
import { generateBillOfLading, WaybillGenerateArgs } from './utils/generate/generateBillOfLading';
import { ContractGenerateArgs, generateContract } from './utils/generate/generateContract';
import { generateProductsInventoryList, ProductsInventoryListGenerateArgs } from './utils/generate/generateProductsInventoryList';
import { generateNomenclaturePriceList, NomenclaturePriceListGenerateArgs } from './utils/generate/generateNomenclaturePriceList';
import { generateReport, ReportGenerateArgs } from './utils/generate/generateReport';
import { generateBill, PriceGenerateArgs } from './utils/generate/generateBill';

export class DownloadDocumentUtils {
    public static async actGenerateAndDownload(
        args: ActGenerateArgs,
        parentType: 'operation' | 'project' | 'projectTemplate' | 'shipping',
        fileName: string,
        fileType: FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateAct(args, parentType, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    public static async estimateGenerateAndDownload(
        args: EstimateGenerateArgs,
        parentType: 'operation' | 'project' | 'projectTemplate' | 'shipping',
        fileName: string,
        fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateEstimate(args, parentType, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    public static async billOfLadingGenerateAndDownload(
        args: WaybillGenerateArgs,
        fileName: string,
        fileType: FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateBillOfLading(args, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    public static async contractGenerateAndDownload(
        args: ContractGenerateArgs,
        fileName: string,
        fileType: FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateContract(args, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    static async billGenerateAndDownload(args: PriceGenerateArgs, fileName: string, fileType: FileTypeCodeEnum.PDFTYPE) {
        const data = await generateBill(args);
        this.downloadDocument(data, fileName, fileType);
    }

    public static async productsInventoryListGenerateAndDownload(
        args: ProductsInventoryListGenerateArgs,
        fileName: string,
        fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateProductsInventoryList(args, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    public static async nomenclaturePricelistGenerateAndDownload(
        args: NomenclaturePriceListGenerateArgs,
        fileName: string,
        fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateNomenclaturePriceList(args, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    public static async reportGenerateAndDownload(
        args: ReportGenerateArgs,
        fileName: string,
        fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
    ) {
        const data = await generateReport(args, fileType);
        this.downloadDocument(data, fileName, fileType);
    }

    private static fileExtensionByCode = (fileType: FileTypeCodeEnum): string | undefined => {
        let ext: FileExtensionTypeCodeEnum | undefined;
        if (fileType === FileTypeCodeEnum.WORD) ext = FileExtensionTypeCodeEnum.DOCX;
        else if (fileType === FileTypeCodeEnum.EXCEL) ext = FileExtensionTypeCodeEnum.XLSX;
        else if (fileType === FileTypeCodeEnum.PDFTYPE) ext = FileExtensionTypeCodeEnum.PDF;
        return ext ? ext.toLowerCase() : ext;
    };

    private static downloadDocument = (data: Blob, fileName: string, fileType: FileTypeCodeEnum) => {
        let ext = this.fileExtensionByCode(fileType);
        let fileTypeStr: string | undefined;
        if (fileType === FileTypeCodeEnum.EXCEL) fileTypeStr = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileDataToDownload(data, fileName, ext, fileTypeStr);
    };
}

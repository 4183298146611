import React from 'react';
import FormValue from '../formValue/FormValue';
import { formatMoney } from '../../shared/util/formatMoney';
import { Col, Icon, Row } from 'antd';
import { IconCalendar, IconShippingFastSolid, IconStopwatchSolid } from '../icons';
import { LocalizationEnum, localize } from '../../localization';
import './currentRentIndicatorsBlock.less';
import { Link } from 'react-router-dom';
import { InstanceInfoRead, OperationInfoRead, VariantInfoRead } from '../../server';
import { getCapture } from './currentRentIndicatorsProblems';
import { linkToCalendar } from '../../shared/util/createLinkPath';
import { CurrentRentIndicatorsEntityType } from './currentRentIndicatorsBlock';
import { CalendarCapture } from '../../modules/main/calendar/components/Calendar/types/params';
import { useAppSelector } from '../../store/hooks';
import { businessAccountIdSelector } from '../../shared/reducers/system.reducer';

interface IProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: any;
    showFinancialData: boolean;
}

export const CurrentSubrentIndicatorsTabProblems = (props: IProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getUrlToCalendarStatus = (type) => {
        if (props.entityType === 'project') {
            return linkToCalendar({
                typeCode: type,
                projectId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'shipping') {
            return linkToCalendar({
                typeCode: type,
                subrentId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'renter') {
            return linkToCalendar({
                typeCode: type,
                renterId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'product') {
            return linkToCalendar({
                typeCode: type,
                productId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'operation') {
            return linkToCalendar({
                typeCode: type,
                projectId: (props.entity as OperationInfoRead).projectId,
                subrentId: (props.entity as OperationInfoRead).subrentId,
                renterId: (props.entity as OperationInfoRead).counterpartyId,
                operationId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'product-instance') {
            return linkToCalendar({
                typeCode: type,
                productId: (props.entity as InstanceInfoRead).productId,
                instanceId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'kit') {
            return linkToCalendar({
                typeCode: type,
                kitId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        if (props.entityType === 'variant') {
            return linkToCalendar({
                typeCode: type,
                productId: (props.entity as VariantInfoRead).productId,
                variantId: props.entity.id,
                group: 'project',
                capture: getCapture('all'),
            });
        }
        return `/${businessAccountId}/calendar`;
    };

    const getUrlToCalendarProblem = (type) => {
        let capture: CalendarCapture | undefined = 'allWithNow';

        if (props.entityType === 'project') {
            return linkToCalendar({
                problem: type,
                projectId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'shipping') {
            return linkToCalendar({
                problem: type,
                subrentId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'renter') {
            return linkToCalendar({
                problem: type,
                renterId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'product') {
            return linkToCalendar({
                problem: type,
                productId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'operation') {
            return linkToCalendar({
                problem: type,
                projectId: (props.entity as OperationInfoRead).projectId,
                operationId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'product-instance') {
            return linkToCalendar({
                problem: type,
                productId: (props.entity as InstanceInfoRead).productId,
                instanceId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'kit') {
            return linkToCalendar({
                problem: type,
                kitId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        if (props.entityType === 'variant') {
            return linkToCalendar({
                problem: type,
                productId: (props.entity as VariantInfoRead).productId,
                variantId: props.entity.id,
                group: 'project',
                capture,
            });
        }
        return `/${businessAccountId}/calendar`;
    };

    const renderProblemValue = (
        type: 'SUBRENT_SHIPMENT_DELAY' | 'SUBRENT_RETURN_TO_SHIPPER_DELAY',
        count: number,
        sum: number,
        iconImage
    ) => {
        const getProblem = (count: number, sum: number, iconImage) => {
            let icon, dataIndicatorSum;
            dataIndicatorSum = <FormValue className="rr-collapsed-problem-indicator-sum" value={formatMoney(sum)} originalValue={sum} />;

            let classColorIcon =
                type === 'SUBRENT_SHIPMENT_DELAY' ? 'problemsAndWarnings-icon-color-red' : 'problemsAndWarnings-icon-color-yellow';
            icon = <Icon component={iconImage} className={`problemsAndWarnings-icon ${classColorIcon}`} />;

            return {
                count,
                dataIndicatorSum,
                icon,
            };
        };

        return (
            <div style={{ display: 'flex' }}>
                <div className="rr-value">
                    {getProblem(count, sum, iconImage).count !== 0 ? (
                        <div className="problemsAndWarnings-status-line">
                            <Link to={getUrlToCalendarProblem(type)} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                {getProblem(count, sum, iconImage).icon}
                                <span>{getProblem(count, sum, iconImage).count}</span>
                                &nbsp;
                                {props.showFinancialData && (
                                    <span className="rr-collapsed-problem-span-color rr-collapsed-problem-span">
                                        на {getProblem(count, sum, iconImage).dataIndicatorSum}
                                    </span>
                                )}
                                <Icon
                                    component={IconCalendar}
                                    className={`problemsAndWarnings-icon-calendar`}
                                    style={{ marginTop: '-1px' }}
                                />
                            </Link>
                        </div>
                    ) : (
                        <span className="rr-value rr-collapsed-problem-not">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                    )}
                </div>
            </div>
        );
    };

    const renderDataValue = (title, color, sum, count, type, col) => {
        return (
            <Col span={col}>
                <div className="rr-label">{title}</div>
                <div className="rr-value">
                    {count !== 0 ? (
                        <div className="problemsAndWarnings-status-line">
                            <Link to={getUrlToCalendarStatus(type)} style={{ cursor: 'pointer' }}>
                                <div
                                    className={`rr-dot rr-dot-calendar-border  rr-dot-indicators-border`}
                                    style={{ border: `2px solid ${color}` }}
                                ></div>
                                <span>{count}</span>
                                &nbsp;
                                {props.showFinancialData && (
                                    <span className="rr-collapsed-problem-span-color rr-collapsed-problem-span">
                                        на{' '}
                                        <FormValue
                                            className="rr-collapsed-problem-indicator-sum"
                                            value={formatMoney(sum)}
                                            originalValue={sum}
                                        />
                                    </span>
                                )}
                                <Icon
                                    component={IconCalendar}
                                    className={`problemsAndWarnings-icon-calendar`}
                                    style={{ marginTop: '-1px' }}
                                />
                            </Link>
                        </div>
                    ) : (
                        <>
                            <span className="rr-collapsed-problem-gray">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                        </>
                    )}
                </div>
            </Col>
        );
    };

    const problemsAndWarnings = props.entity.rentIndicators?.problemsAndWarnings;
    const rentIndicators = props.entity.rentIndicators;

    return (
        <>
            <Row>
                <Col span={24} className={'rr-form-item'} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {/* Просрочки */}
                    <Col span={12}>
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__PROBLEM__DELAYS)}</div>
                        <div style={{ alignItems: 'center' }}>
                            {problemsAndWarnings.subrentShipmentDelay.elementsCount === 0 &&
                            problemsAndWarnings.subrentReturnToShipperDelay.elementsCount === 0 ? (
                                <span className="rr-value rr-collapsed-problem-not">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                            ) : (
                                <>
                                    {problemsAndWarnings.subrentShipmentDelay.elementsCount > 0 &&
                                        renderProblemValue(
                                            'SUBRENT_SHIPMENT_DELAY',
                                            problemsAndWarnings.subrentShipmentDelay.elementsCount,
                                            problemsAndWarnings.subrentShipmentDelay.sum,
                                            IconShippingFastSolid
                                        )}
                                    {problemsAndWarnings.subrentReturnToShipperDelay.elementsCount > 0 &&
                                        renderProblemValue(
                                            'SUBRENT_RETURN_TO_SHIPPER_DELAY',
                                            problemsAndWarnings.subrentReturnToShipperDelay.elementsCount,
                                            problemsAndWarnings.subrentReturnToShipperDelay.sum,
                                            IconStopwatchSolid
                                        )}
                                </>
                            )}
                        </div>
                    </Col>
                    {/* Сумма просрочек и Сумма нехваток */}
                    <Col span={12}>
                        {renderDataValue(
                            'Забронировано к поставке',
                            '#8b5cb1',
                            rentIndicators.currentSubrentShipmentBooked.sum,
                            rentIndicators.currentSubrentShipmentBooked.elementsCount,
                            'SUBRENT_SHIPMENT_BOOKED',
                            24
                        )}
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={'rr-form-item'} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {renderDataValue(
                        'В субаренде',
                        '#FC8C2F',
                        rentIndicators.currentSubrent.sum,
                        rentIndicators.currentSubrent.elementsCount,
                        'SUBRENT',
                        12
                    )}
                    {renderDataValue(
                        'Возвращено поставщику',
                        '#34bfa3',
                        rentIndicators.totalSubrentReturnedToShipper.sum,
                        rentIndicators.totalSubrentReturnedToShipper.elementsCount,
                        'SUBRENT_RETURNED_TO_SHIPPER',
                        12
                    )}
                </Col>
            </Row>
        </>
    );
};

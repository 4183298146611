import React from 'react';
import {Card} from 'antd';
import {BasePage, IBasePageProps} from '../../../../components/page/BasePage';

interface IProps extends IBasePageProps {
}

export class TestGridPage extends BasePage<IProps> {

    renderContent() {
        return (
            <Card>

            </Card>
        );
    }
}

import { ListLifecycleEventsArgs } from './lifecycleEvents.api.types';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { EntityLifecycleEventRecord } from '../../../../../../server';

export abstract class LifecycleEventsApiUtils {
    static createRequestFilters = (params: ListLifecycleEventsArgs['params']) => {
        return ServerUtils.createRequestFilters<EntityLifecycleEventRecord>([
            params.authorId && ['activityAuthorId', 'EQ', params.authorId],
            params.screenLeft && ['activityDate', 'GE', params.screenLeft],
            params.screenRight && ['activityDate', 'LE', params.screenRight],
            params.entityTypeCode && ['entityTypeCode', 'EQ', params.entityTypeCode],
            params.entityLifecycleEventTypeCode && ['entityLifecycleEventTypeCode', 'EQ', params.entityLifecycleEventTypeCode],
        ]);
    };
}

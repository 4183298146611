import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { matchPath, Redirect, RouteComponentProps } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { userProfileRoutes } from './user-profile-router';
import { userRoutes } from './user-router';
import './user-profile-page.less';
import { IRouterItem } from '../../../config/routes';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../config/constants';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { Spin } from '../../../components';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import SettingsMenu from '../../../components/settingsMenu/settings-menu';
import { loadProfile } from './reducers/user-profile.reducer';
import { LocalizationEnum, localizeIntl } from '../../../localization';
import { printUserName } from '../../../shared/util/utils';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

interface IState {
    settingsRoutes?: Array<IRouterItem>;
    collapsedMenuItems: Array<string>;
    settingsIsLoading: boolean;
}

class userProfilePage extends BasePage<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            collapsedMenuItems: [],
            settingsIsLoading: true,
        };
        this.props.resetOperationForm();
    }

    updateMenuPosition = (e) => {
        let el = document.querySelector('.rr-page-settings__menu .ant-menu') as HTMLElement;
        if (el) {
            if (el.parentElement) el.style.width = el.parentElement.offsetWidth + 'px';
            el.style.top = (window.scrollY > HEADER_HEIGHT ? 0 : HEADER_HEIGHT - window.scrollY) + 'px';
            el.style.height =
                window.innerHeight -
                (HEADER_HEIGHT + FOOTER_HEIGHT) +
                Math.min(HEADER_HEIGHT, window.scrollY) +
                Math.min(FOOTER_HEIGHT, document.body.scrollHeight - window.scrollY - window.innerHeight) +
                'px';
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateMenuPosition(null);
        }
    }

    componentDidMount = async () => {
        super.componentDidMount();

        try {
            let settingsRoutesNew = [...(this.props.userId ? userRoutes : userProfileRoutes)];

            await this.setState({ settingsRoutes: settingsRoutesNew, settingsIsLoading: false });

            window.addEventListener('scroll', this.updateMenuPosition);
            window.addEventListener('resize', this.updateMenuPosition);
            this.updateMenuPosition(null);

            this.props.loadProfile(this.props.intl, this.props.userId);

            setTimeout(() => {
                let hash = window.location.hash;
                if (hash) {
                    hash = hash.replace('#', '');
                    let element = document.getElementById(hash);
                    if (element) element.scrollIntoView();
                }
            }, 300);
        } catch (e) {
            await this.setState({ settingsIsLoading: false });
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateMenuPosition);
        window.removeEventListener('resize', this.updateMenuPosition);
    };

    getPageHeaderProps(): IPageHeaderProps {
        let pageTitle;
        this.state.settingsRoutes &&
            this.state.settingsRoutes.find((item) => {
                let match = matchPath(this.props.location.pathname, item);
                if (match) {
                    pageTitle = item.title;
                    return item;
                }
                return null;
            });

        const { userProfile } = this.props;

        let titleProfile: any = !this.props.userId
            ? localizeIntl(this.props.intl, LocalizationEnum.PAGE__USER_PROFILE__PAGE_TITLE__CURRENT_USER_PROFILE)
            : localizeIntl(this.props.intl, LocalizationEnum.PAGE__USER_PROFILE__PAGE_TILTE__OTHER_USER_PROFILE);

        return {
            name: userProfile ? (!userProfile.specialSystemUser ? printUserName(userProfile.fullName) : userProfile.shortName) : '',
            title: titleProfile,
        };
    }

    renderContent() {
        if (
            this.props.location.pathname === `/${this.props.businessAccountId}/userProfile` ||
            this.props.location.pathname === `/${this.props.businessAccountId}/userProfile/`
        ) {
            return <Redirect to={`/${this.props.businessAccountId}/userProfile/description`} />;
        }
        return (
            <div className={'rr-page-settings'}>
                <Spin spinning={this.state.settingsIsLoading}>
                    {
                        <div className={'rr-page-settings-container'}>
                            <SettingsMenu settingsRoutes={this.state.settingsRoutes} />
                            <div className={'rr-page-settings__content'}>
                                {this.state.settingsRoutes ? renderRoutes(this.state.settingsRoutes, { intl: this.props.intl }) : null}
                            </div>
                        </div>
                    }
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        userId: ownProps.match && ownProps.match.params && ownProps.match.params['id'] ? +ownProps.match.params['id'] : undefined,
        userProfile: storeState.userProfile.entity,
    };
};

const mapDispatchToProps = { resetOperationForm, loadProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(userProfilePage);

import {DefaultApi} from "./api";
import {serverApiConfig} from "../config/config";

export * from "./api";
export const serverApi:DefaultApi = new DefaultApi(serverApiConfig);

export interface ServerError {
    title?: string | React.ReactElement;
    message?: string;
    status?: string;
}

// TODO мб переименовать метод
export function checkServerMethodExist(name: string):boolean {
    if(typeof serverApi[name] !== 'function') {
        console.error(`serverApi method with name "${name}" not found!!!`);
        return false;
    }
    return true;
}

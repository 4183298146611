/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE: This file is auto generated. Do not edit this file manually.
 */

export const countries_ru:{value:string, name:string}[] = [
    {
        "value": "AU",
        "name": "Австралия"
    },
    {
        "value": "AT",
        "name": "Австрия"
    },
    {
        "value": "AZ",
        "name": "Азербайджан"
    },
    {
        "value": "AX",
        "name": "Аландские о-ва"
    },
    {
        "value": "AL",
        "name": "Албания"
    },
    {
        "value": "DZ",
        "name": "Алжир"
    },
    {
        "value": "AS",
        "name": "Американское Самоа"
    },
    {
        "value": "AI",
        "name": "Ангилья"
    },
    {
        "value": "AO",
        "name": "Ангола"
    },
    {
        "value": "AD",
        "name": "Андорра"
    },
    {
        "value": "AQ",
        "name": "Антарктида"
    },
    {
        "value": "AG",
        "name": "Антигуа и Барбуда"
    },
    {
        "value": "AR",
        "name": "Аргентина"
    },
    {
        "value": "AM",
        "name": "Армения"
    },
    {
        "value": "AW",
        "name": "Аруба"
    },
    {
        "value": "AF",
        "name": "Афганистан"
    },
    {
        "value": "BS",
        "name": "Багамы"
    },
    {
        "value": "BD",
        "name": "Бангладеш"
    },
    {
        "value": "BB",
        "name": "Барбадос"
    },
    {
        "value": "BH",
        "name": "Бахрейн"
    },
    {
        "value": "BY",
        "name": "Беларусь"
    },
    {
        "value": "BZ",
        "name": "Белиз"
    },
    {
        "value": "BE",
        "name": "Бельгия"
    },
    {
        "value": "BJ",
        "name": "Бенин"
    },
    {
        "value": "BM",
        "name": "Бермудские о-ва"
    },
    {
        "value": "BG",
        "name": "Болгария"
    },
    {
        "value": "BO",
        "name": "Боливия"
    },
    {
        "value": "BQ",
        "name": "Бонэйр, Синт-Эстатиус и Саба"
    },
    {
        "value": "BA",
        "name": "Босния и Герцеговина"
    },
    {
        "value": "BW",
        "name": "Ботсвана"
    },
    {
        "value": "BR",
        "name": "Бразилия"
    },
    {
        "value": "IO",
        "name": "Британская территория в Индийском океане"
    },
    {
        "value": "BN",
        "name": "Бруней-Даруссалам"
    },
    {
        "value": "BF",
        "name": "Буркина-Фасо"
    },
    {
        "value": "BI",
        "name": "Бурунди"
    },
    {
        "value": "BT",
        "name": "Бутан"
    },
    {
        "value": "VU",
        "name": "Вануату"
    },
    {
        "value": "VA",
        "name": "Ватикан"
    },
    {
        "value": "GB",
        "name": "Великобритания"
    },
    {
        "value": "HU",
        "name": "Венгрия"
    },
    {
        "value": "VE",
        "name": "Венесуэла"
    },
    {
        "value": "VG",
        "name": "Виргинские о-ва (Великобритания)"
    },
    {
        "value": "VI",
        "name": "Виргинские о-ва (США)"
    },
    {
        "value": "UM",
        "name": "Внешние малые о-ва (США)"
    },
    {
        "value": "TL",
        "name": "Восточный Тимор"
    },
    {
        "value": "VN",
        "name": "Вьетнам"
    },
    {
        "value": "GA",
        "name": "Габон"
    },
    {
        "value": "HT",
        "name": "Гаити"
    },
    {
        "value": "GY",
        "name": "Гайана"
    },
    {
        "value": "GM",
        "name": "Гамбия"
    },
    {
        "value": "GH",
        "name": "Гана"
    },
    {
        "value": "GP",
        "name": "Гваделупа"
    },
    {
        "value": "GT",
        "name": "Гватемала"
    },
    {
        "value": "GN",
        "name": "Гвинея"
    },
    {
        "value": "GW",
        "name": "Гвинея-Бисау"
    },
    {
        "value": "DE",
        "name": "Германия"
    },
    {
        "value": "GG",
        "name": "Гернси"
    },
    {
        "value": "GI",
        "name": "Гибралтар"
    },
    {
        "value": "HN",
        "name": "Гондурас"
    },
    {
        "value": "HK",
        "name": "Гонконг (САР)"
    },
    {
        "value": "GD",
        "name": "Гренада"
    },
    {
        "value": "GL",
        "name": "Гренландия"
    },
    {
        "value": "GR",
        "name": "Греция"
    },
    {
        "value": "GE",
        "name": "Грузия"
    },
    {
        "value": "GU",
        "name": "Гуам"
    },
    {
        "value": "DK",
        "name": "Дания"
    },
    {
        "value": "JE",
        "name": "Джерси"
    },
    {
        "value": "DJ",
        "name": "Джибути"
    },
    {
        "value": "DM",
        "name": "Доминика"
    },
    {
        "value": "DO",
        "name": "Доминиканская Республика"
    },
    {
        "value": "EG",
        "name": "Египет"
    },
    {
        "value": "ZM",
        "name": "Замбия"
    },
    {
        "value": "EH",
        "name": "Западная Сахара"
    },
    {
        "value": "ZW",
        "name": "Зимбабве"
    },
    {
        "value": "IL",
        "name": "Израиль"
    },
    {
        "value": "IN",
        "name": "Индия"
    },
    {
        "value": "ID",
        "name": "Индонезия"
    },
    {
        "value": "JO",
        "name": "Иордания"
    },
    {
        "value": "IQ",
        "name": "Ирак"
    },
    {
        "value": "IR",
        "name": "Иран"
    },
    {
        "value": "IE",
        "name": "Ирландия"
    },
    {
        "value": "IS",
        "name": "Исландия"
    },
    {
        "value": "ES",
        "name": "Испания"
    },
    {
        "value": "IT",
        "name": "Италия"
    },
    {
        "value": "YE",
        "name": "Йемен"
    },
    {
        "value": "CV",
        "name": "Кабо-Верде"
    },
    {
        "value": "KZ",
        "name": "Казахстан"
    },
    {
        "value": "KH",
        "name": "Камбоджа"
    },
    {
        "value": "CM",
        "name": "Камерун"
    },
    {
        "value": "CA",
        "name": "Канада"
    },
    {
        "value": "QA",
        "name": "Катар"
    },
    {
        "value": "KE",
        "name": "Кения"
    },
    {
        "value": "CY",
        "name": "Кипр"
    },
    {
        "value": "KG",
        "name": "Киргизия"
    },
    {
        "value": "KI",
        "name": "Кирибати"
    },
    {
        "value": "CN",
        "name": "Китай"
    },
    {
        "value": "KP",
        "name": "КНДР"
    },
    {
        "value": "CC",
        "name": "Кокосовые о-ва"
    },
    {
        "value": "CO",
        "name": "Колумбия"
    },
    {
        "value": "KM",
        "name": "Коморы"
    },
    {
        "value": "CG",
        "name": "Конго - Браззавиль"
    },
    {
        "value": "CD",
        "name": "Конго - Киншаса"
    },
    {
        "value": "CR",
        "name": "Коста-Рика"
    },
    {
        "value": "CI",
        "name": "Кот-д’Ивуар"
    },
    {
        "value": "CU",
        "name": "Куба"
    },
    {
        "value": "KW",
        "name": "Кувейт"
    },
    {
        "value": "CW",
        "name": "Кюрасао"
    },
    {
        "value": "LA",
        "name": "Лаос"
    },
    {
        "value": "LV",
        "name": "Латвия"
    },
    {
        "value": "LS",
        "name": "Лесото"
    },
    {
        "value": "LR",
        "name": "Либерия"
    },
    {
        "value": "LB",
        "name": "Ливан"
    },
    {
        "value": "LY",
        "name": "Ливия"
    },
    {
        "value": "LT",
        "name": "Литва"
    },
    {
        "value": "LI",
        "name": "Лихтенштейн"
    },
    {
        "value": "LU",
        "name": "Люксембург"
    },
    {
        "value": "MU",
        "name": "Маврикий"
    },
    {
        "value": "MR",
        "name": "Мавритания"
    },
    {
        "value": "MG",
        "name": "Мадагаскар"
    },
    {
        "value": "YT",
        "name": "Майотта"
    },
    {
        "value": "MO",
        "name": "Макао (САР)"
    },
    {
        "value": "MW",
        "name": "Малави"
    },
    {
        "value": "MY",
        "name": "Малайзия"
    },
    {
        "value": "ML",
        "name": "Мали"
    },
    {
        "value": "MV",
        "name": "Мальдивы"
    },
    {
        "value": "MT",
        "name": "Мальта"
    },
    {
        "value": "MA",
        "name": "Марокко"
    },
    {
        "value": "MQ",
        "name": "Мартиника"
    },
    {
        "value": "MH",
        "name": "Маршалловы Острова"
    },
    {
        "value": "MX",
        "name": "Мексика"
    },
    {
        "value": "MZ",
        "name": "Мозамбик"
    },
    {
        "value": "MD",
        "name": "Молдова"
    },
    {
        "value": "MC",
        "name": "Монако"
    },
    {
        "value": "MN",
        "name": "Монголия"
    },
    {
        "value": "MS",
        "name": "Монтсеррат"
    },
    {
        "value": "MM",
        "name": "Мьянма (Бирма)"
    },
    {
        "value": "NA",
        "name": "Намибия"
    },
    {
        "value": "NR",
        "name": "Науру"
    },
    {
        "value": "NP",
        "name": "Непал"
    },
    {
        "value": "NE",
        "name": "Нигер"
    },
    {
        "value": "NG",
        "name": "Нигерия"
    },
    {
        "value": "NL",
        "name": "Нидерланды"
    },
    {
        "value": "NI",
        "name": "Никарагуа"
    },
    {
        "value": "NU",
        "name": "Ниуэ"
    },
    {
        "value": "NZ",
        "name": "Новая Зеландия"
    },
    {
        "value": "NC",
        "name": "Новая Каледония"
    },
    {
        "value": "NO",
        "name": "Норвегия"
    },
    {
        "value": "BV",
        "name": "о-в Буве"
    },
    {
        "value": "IM",
        "name": "о-в Мэн"
    },
    {
        "value": "NF",
        "name": "о-в Норфолк"
    },
    {
        "value": "CX",
        "name": "о-в Рождества"
    },
    {
        "value": "SH",
        "name": "о-в Св. Елены"
    },
    {
        "value": "PN",
        "name": "о-ва Питкэрн"
    },
    {
        "value": "TC",
        "name": "о-ва Тёркс и Кайкос"
    },
    {
        "value": "HM",
        "name": "о-ва Херд и Макдональд"
    },
    {
        "value": "AE",
        "name": "ОАЭ"
    },
    {
        "value": "OM",
        "name": "Оман"
    },
    {
        "value": "KY",
        "name": "Острова Кайман"
    },
    {
        "value": "CK",
        "name": "Острова Кука"
    },
    {
        "value": "PK",
        "name": "Пакистан"
    },
    {
        "value": "PW",
        "name": "Палау"
    },
    {
        "value": "PS",
        "name": "Палестинские территории"
    },
    {
        "value": "PA",
        "name": "Панама"
    },
    {
        "value": "PG",
        "name": "Папуа — Новая Гвинея"
    },
    {
        "value": "PY",
        "name": "Парагвай"
    },
    {
        "value": "PE",
        "name": "Перу"
    },
    {
        "value": "PL",
        "name": "Польша"
    },
    {
        "value": "PT",
        "name": "Португалия"
    },
    {
        "value": "PR",
        "name": "Пуэрто-Рико"
    },
    {
        "value": "KR",
        "name": "Республика Корея"
    },
    {
        "value": "RE",
        "name": "Реюньон"
    },
    {
        "value": "RU",
        "name": "Россия"
    },
    {
        "value": "RW",
        "name": "Руанда"
    },
    {
        "value": "RO",
        "name": "Румыния"
    },
    {
        "value": "SV",
        "name": "Сальвадор"
    },
    {
        "value": "WS",
        "name": "Самоа"
    },
    {
        "value": "SM",
        "name": "Сан-Марино"
    },
    {
        "value": "ST",
        "name": "Сан-Томе и Принсипи"
    },
    {
        "value": "SA",
        "name": "Саудовская Аравия"
    },
    {
        "value": "MK",
        "name": "Северная Македония"
    },
    {
        "value": "MP",
        "name": "Северные Марианские о-ва"
    },
    {
        "value": "SC",
        "name": "Сейшельские Острова"
    },
    {
        "value": "BL",
        "name": "Сен-Бартелеми"
    },
    {
        "value": "MF",
        "name": "Сен-Мартен"
    },
    {
        "value": "PM",
        "name": "Сен-Пьер и Микелон"
    },
    {
        "value": "SN",
        "name": "Сенегал"
    },
    {
        "value": "VC",
        "name": "Сент-Винсент и Гренадины"
    },
    {
        "value": "KN",
        "name": "Сент-Китс и Невис"
    },
    {
        "value": "LC",
        "name": "Сент-Люсия"
    },
    {
        "value": "RS",
        "name": "Сербия"
    },
    {
        "value": "SG",
        "name": "Сингапур"
    },
    {
        "value": "SX",
        "name": "Синт-Мартен"
    },
    {
        "value": "SY",
        "name": "Сирия"
    },
    {
        "value": "SK",
        "name": "Словакия"
    },
    {
        "value": "SI",
        "name": "Словения"
    },
    {
        "value": "US",
        "name": "Соединенные Штаты"
    },
    {
        "value": "SB",
        "name": "Соломоновы Острова"
    },
    {
        "value": "SO",
        "name": "Сомали"
    },
    {
        "value": "SD",
        "name": "Судан"
    },
    {
        "value": "SR",
        "name": "Суринам"
    },
    {
        "value": "SL",
        "name": "Сьерра-Леоне"
    },
    {
        "value": "TJ",
        "name": "Таджикистан"
    },
    {
        "value": "TH",
        "name": "Таиланд"
    },
    {
        "value": "TW",
        "name": "Тайвань"
    },
    {
        "value": "TZ",
        "name": "Танзания"
    },
    {
        "value": "TG",
        "name": "Того"
    },
    {
        "value": "TK",
        "name": "Токелау"
    },
    {
        "value": "TO",
        "name": "Тонга"
    },
    {
        "value": "TT",
        "name": "Тринидад и Тобаго"
    },
    {
        "value": "TV",
        "name": "Тувалу"
    },
    {
        "value": "TN",
        "name": "Тунис"
    },
    {
        "value": "TM",
        "name": "Туркменистан"
    },
    {
        "value": "TR",
        "name": "Турция"
    },
    {
        "value": "UG",
        "name": "Уганда"
    },
    {
        "value": "UZ",
        "name": "Узбекистан"
    },
    {
        "value": "UA",
        "name": "Украина"
    },
    {
        "value": "WF",
        "name": "Уоллис и Футуна"
    },
    {
        "value": "UY",
        "name": "Уругвай"
    },
    {
        "value": "FO",
        "name": "Фарерские о-ва"
    },
    {
        "value": "FM",
        "name": "Федеративные Штаты Микронезии"
    },
    {
        "value": "FJ",
        "name": "Фиджи"
    },
    {
        "value": "PH",
        "name": "Филиппины"
    },
    {
        "value": "FI",
        "name": "Финляндия"
    },
    {
        "value": "FK",
        "name": "Фолклендские о-ва"
    },
    {
        "value": "FR",
        "name": "Франция"
    },
    {
        "value": "GF",
        "name": "Французская Гвиана"
    },
    {
        "value": "PF",
        "name": "Французская Полинезия"
    },
    {
        "value": "TF",
        "name": "Французские Южные территории"
    },
    {
        "value": "HR",
        "name": "Хорватия"
    },
    {
        "value": "CF",
        "name": "Центрально-Африканская Республика"
    },
    {
        "value": "TD",
        "name": "Чад"
    },
    {
        "value": "ME",
        "name": "Черногория"
    },
    {
        "value": "CZ",
        "name": "Чехия"
    },
    {
        "value": "CL",
        "name": "Чили"
    },
    {
        "value": "CH",
        "name": "Швейцария"
    },
    {
        "value": "SE",
        "name": "Швеция"
    },
    {
        "value": "SJ",
        "name": "Шпицберген и Ян-Майен"
    },
    {
        "value": "LK",
        "name": "Шри-Ланка"
    },
    {
        "value": "EC",
        "name": "Эквадор"
    },
    {
        "value": "GQ",
        "name": "Экваториальная Гвинея"
    },
    {
        "value": "ER",
        "name": "Эритрея"
    },
    {
        "value": "SZ",
        "name": "Эсватини"
    },
    {
        "value": "EE",
        "name": "Эстония"
    },
    {
        "value": "ET",
        "name": "Эфиопия"
    },
    {
        "value": "GS",
        "name": "Южная Георгия и Южные Сандвичевы о-ва"
    },
    {
        "value": "ZA",
        "name": "Южно-Африканская Республика"
    },
    {
        "value": "SS",
        "name": "Южный Судан"
    },
    {
        "value": "JM",
        "name": "Ямайка"
    },
    {
        "value": "JP",
        "name": "Япония"
    }
];

import {MutableRefObject, useEffect} from 'react';

export const useDisableMouseScrollEffect = (scrollRef: MutableRefObject<HTMLDivElement | undefined>) => {
    useEffect(() => {
        if (scrollRef.current == null) return;

        const suppressScroll = (event: MouseEvent) => {
            if (event.button === 1) {
                event.preventDefault();
            }
        };

        const scrollElement = scrollRef.current;

        scrollElement.addEventListener('mousedown', suppressScroll, true);

        return () => {
            scrollElement.removeEventListener('mousedown', suppressScroll);
        };
    }, [scrollRef]);
};

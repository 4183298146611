import { GridProps } from '../../../grid/Grid';
import { useCallback } from 'react';
import { ListParams } from '../types/params';
import { usePageUrlParamsContext } from '../components/context/PageUrlParamsContext';
import { SetPageParams } from '../../../../core/hooks/urlParams/types';

interface EntityGridHandlers
    extends Pick<
        GridProps,
        'onPageChanged' | 'onPageSizeChanged' | 'onSortedChange' | 'onSelectionChanged' | 'onRowClick' | 'onRowAction'
    > {}

interface useEntityGridHandlersProps<EntityInfoRead extends object> {
    setEntityId?: (entity: EntityInfoRead | null) => void;
    setEntitySelection?: (selection: number[]) => void;
}

export const useGridHandlers = <PageParams extends ListParams, EntityInfoRead extends object>({
    setEntityId,
    setEntitySelection,
}: useEntityGridHandlersProps<EntityInfoRead>): EntityGridHandlers => {
    const { setPageParams } = usePageUrlParamsContext<PageParams>();
    const onSelectionChanged = useCallback(
        (selectedIds: string[]) => {
            if (setEntitySelection) {
                setEntitySelection(selectedIds.map((id) => Number(id)));
            }
        },
        [setEntitySelection]
    );

    const { onPageChanged, onPageSizeChanged, onSortedChange } = useGridChanges(setPageParams);

    return {
        onPageChanged,
        onPageSizeChanged,
        onSortedChange,
        onRowClick: setEntityId,
        onSelectionChanged,
    };
};

export const useGridChanges = <PageParams extends ListParams>(setPageParams: SetPageParams<PageParams>) => {
    const onSortedChange = useCallback(
        (id: string, desc: boolean) => {
            setPageParams({ sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' } as PageParams);
        },
        [setPageParams]
    );

    const onPageChanged = useCallback(
        (page: number) => {
            setPageParams({ page } as PageParams);
        },
        [setPageParams]
    );

    const onPageSizeChanged = useCallback(
        (size: number) => {
            setPageParams({ limit: size, page: 1 } as PageParams);
        },
        [setPageParams]
    );

    return {
        onPageChanged,
        onPageSizeChanged,
        onSortedChange,
    };
};

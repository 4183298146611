import { DeepKeys } from '@tanstack/react-table';
import { NomenclatureRecordCustom } from '../reducers/products.reducer';
import { useAppSelector } from '../../../../../store/hooks';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProductsListFilters } from '../api/products.api.types';
import { useMemo } from 'react';

export const useExcludedValues = (pageParams: ProductsListFilters) => {
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const permissions = useAppSelector((store) => store.permissions.permissions);

    return useMemo(() => {
        const excludeColumns: DeepKeys<NomenclatureRecordCustom>[] = pageParams.hideVariants ? ['variantName'] : [];
        const filtersExcludeFields: DeepKeys<NomenclatureRecordCustom>[] = [];

        if (!canViewFinancialData(permissions)) {
            excludeColumns.push('pricePerShift');
            filtersExcludeFields.push('pricePerShift');
        }

        if (!subrentModuleEnabled) {
            excludeColumns.push('warehousePresenceCounters.subrentedInstanceCount');
        }

        return {
            excludeColumns,
            filtersExcludeFields,
        };
    }, [pageParams.hideVariants, permissions, subrentModuleEnabled]);
};

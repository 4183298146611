import {ActionButtonsData} from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import {LocalizationEnum} from '../../../../localization';
import {Icon} from 'antd';
import {IconBorderStyleSolid, IconCheck, IconCoinsSolid, IconEdit, IconEraser, IconStampSolid} from '../../../../components/icons';
import React from 'react';
import {StateBadgeData} from '../../../../components/v2/stateBadge/stateBadge';
import {ProjectStateCodeEnum, ProjectTransitionCodeEnum} from '../../../../server';
import {Color} from '../../../../core/types/color';
import {workPlanningActionsMap} from '../../workPlannings/data/workPlanningsData';
import {expenseEntityActionButtons} from '../../expenses/data/expensesData';
import {BasicActionsData} from "../../../../components/v2/actionButtons/actionButton/actionButton";

export type OfferStateCode = ProjectStateCodeEnum.DRAFT | ProjectStateCodeEnum.APPROVED | ProjectStateCodeEnum.COORDINATION | ProjectStateCodeEnum.CANCELED;

export enum OfferActionType {
    createDraft = 'createDraft',
    createExpense = 'createExpense',
    createWorkPlanning = 'createWorkPlanning',
    edit = 'edit',
    duplicate = 'duplicate',
    approve = 'approve',
    returnToDraft = 'returnToDraft',
    toCoordination = 'toCoordination',
    cancel = 'cancel',
    includeOfferInProjectPrice = 'includeOfferInProjectPrice',
    excludeOfferInProjectPrice = 'excludeOfferInProjectPrice',
}

export const offerActionButtons: ActionButtonsData<OfferActionType> = {
    [OfferActionType.createDraft]: {
        localizationId: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__DRAFT,
        icon: <Icon component={IconBorderStyleSolid} />,
        color: Color.WhiteGrey1,
    },
    [OfferActionType.createExpense]: expenseEntityActionButtons.create,
    [OfferActionType.createWorkPlanning]: workPlanningActionsMap.createWorkPlanning,
    [OfferActionType.edit]: {
        localizationId: LocalizationEnum.ASPECT__ACTION__EDIT,
        icon: <Icon component={IconEdit} />,
        color: Color.Purple1,
    },
    [OfferActionType.duplicate]: BasicActionsData.duplicate,
    [OfferActionType.approve]: {
        name: 'Утвердить',
        icon: <Icon component={IconCheck} />,
        color: Color.Green,
    },
    [OfferActionType.returnToDraft]: {
        name: 'В черновик',
        icon: <Icon component={IconBorderStyleSolid}/>,
        color: Color.WhiteGrey1,
    },
    [OfferActionType.toCoordination]: {
        name: 'На согласование',
        icon: <Icon component={IconStampSolid}/>,
        color: Color.Azure,
    },
    [OfferActionType.cancel]: {
        name: 'Отменить',
        icon: <Icon component={IconEraser} />,
        color: Color.Grey2,
    },
    [OfferActionType.includeOfferInProjectPrice]: {
        name: 'Включить в стоимость',
        icon: <Icon component={IconCoinsSolid} />,
        color: Color.WhiteOrange1Solid,
    },
    [OfferActionType.excludeOfferInProjectPrice]: {
        name: 'Исключить из стоимости',
        icon: <Icon component={IconCoinsSolid} />,
        color: Color.WhiteGrey2Solid,
    },
};

export const offersStatesMap: StateBadgeData<OfferStateCode> = {
    [ProjectStateCodeEnum.DRAFT]: {
        color: Color.WhiteGrey1,
        name: 'Черновик',
    },
    [ProjectStateCodeEnum.COORDINATION]: {
        color: Color.Azure,
        name: 'Согласование',
    },
    [ProjectStateCodeEnum.APPROVED]: {
        color: Color.Green,
        name: 'Утверждена',
    },
    [ProjectStateCodeEnum.CANCELED]: {
        color: Color.Grey2,
        name: 'Отменена',
    },
};

export const offerActionToTransitionCodeMap: Partial<Record<OfferActionType, ProjectTransitionCodeEnum>> = {
    [OfferActionType.approve]: ProjectTransitionCodeEnum.APPROVE,
    [OfferActionType.cancel]: ProjectTransitionCodeEnum.CANCEL,
    [OfferActionType.returnToDraft]: ProjectTransitionCodeEnum.RETURNTODRAFT,
    [OfferActionType.toCoordination]: ProjectTransitionCodeEnum.TOCOORDINATION,
};

export const offerAllStateCodes: OfferStateCode[] = [
    ProjectStateCodeEnum.DRAFT,
    ProjectStateCodeEnum.COORDINATION,
    ProjectStateCodeEnum.APPROVED,
    ProjectStateCodeEnum.CANCELED,
];

export const offerCreationStateCodes: OfferStateCode[] = [ProjectStateCodeEnum.DRAFT, ProjectStateCodeEnum.COORDINATION, ProjectStateCodeEnum.APPROVED];

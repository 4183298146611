import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { View } from '@council/uncharted';
import { DataTypeEnum, FileTypeCodeEnum, ReportInputArgumentModel, ReportInputParameters, ReportTypeCodeEnum } from '../../../server';
import React from 'react';
import { IRootState } from '../../../shared/reducers';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Alert, Card, Col, Icon, Row } from 'antd';
import { RoundButton, Spin } from '../../../components';
import { LocalizationEnum, localize } from '../../../localization';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { loadEntity, loadViewInfo, reset } from './reducers/report.reducer';
import DynamicFilters from '../../../components/dynamicFilters/DynamicFilters';
import { FormFieldsGroup, FormItemType, IFormField } from '../../../components/dynamicForm/DynamicForm';
import * as am5 from '@amcharts/amcharts5';
import './reportPage.less';
import { EntityRemoteSelect, EntityRemoteSelectProps } from '../../../components/select/EntityRemoteSelect';
import '../../../components/grid/Grid.less';
import { ChartAreaNotGeneratedBlock } from '../../../components/chartAreaNotGeneratedBlock/chartAreaNotGeneratedBlock';
import { IconChalkboardSolid } from '../../../components/icons/IconChalkboardSolid';
import { getPathFromState, getStateFromPath2, isDefined, joinClasses } from '../../../shared/util/utils';
import { ItemsNotFoundBlock } from '../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { RichTextView } from '../../../components/richTextView/RichTextView';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';
import { FormValue2 } from '../../../components/formValue/FormValue2';
import { SystemIcon, SystemIconType } from '../../../components/v2/systemIcon/systemIcon';
import moment, { Moment } from 'moment';
import { replace } from 'connected-react-router';
import { PageUtils } from '../../../shared/util/pageUtils';
import isEqual from 'lodash/isEqual';
import { IconPenSquare } from '../../../components/icons/IconPenSquare';
import { DownloadDocumentUtils } from '../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { IconChartArea } from '../../../components/icons/IconChartArea';
import { showNotification } from '../../../components/notification/showNotification';
import { ExportToExcelReportPagePopover } from '../../../components/exportPopovers/downloadDocument/instances/ExportToExcelReportPagePopover/ExportToExcelReportPagePopover';
import { ReportPageHeaderNavigationBlock } from './components/HeaderNavigationBlock/ReportPageHeaderNavigationBlock';

am5.addLicense('AM5C123456');

class Theme extends am5themes_Animated {
    protected setupDefaultRules() {
        super.setupDefaultRules();
        this.rule('Label').setAll({
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: '400',
            fill: am5.Color.fromHex(0x575962),
            textAlign: 'right',
        });
        this._root.interfaceColors.set('positive', am5.Color.fromHex(0x34bfa3));
        this._root.interfaceColors.set('negative', am5.Color.fromHex(0xf4516c));
        this._root.interfaceColors.set('userData', {
            GIVEN_OUT_COLOR: am5.Color.fromHex(0xfc8c2f),
            PURPLE: am5.Color.fromHex(0x8e00b2),
            RED: am5.Color.fromHex(0xe83e8c),
            RED_STRONG: am5.Color.fromHex(0xe80055),
            ORANGE: am5.Color.fromHex(0xfc8c2f),
            YELLOW: am5.Color.fromHex(0xffd04e),
            MARSH: am5.Color.fromHex(0x006980),
            GREEN: am5.Color.fromHex(0x34bfa3),
            BLUE: am5.Color.fromHex(0x316fc4),
            DARK_BLUE: am5.Color.fromHex(0x2c3e93),
            DARK_GREY: am5.Color.fromHex(0xa0a0a0),
            LIGHT_GREY: am5.Color.fromHex(0xcccccc),
        });
        this.rule('Legend').setAll({
            marginLeft: 20,
        });
        this.rule('ColorSet').setAll({
            reuse: true,
            colors: [
                am5.Color.fromHex(0xe83e8c),
                am5.Color.fromHex(0xe80055),
                am5.Color.fromHex(0xfc8c2f),
                am5.Color.fromHex(0xfcc12d),
                am5.Color.fromHex(0xffd04e),
                am5.Color.fromHex(0x006980),
                am5.Color.fromHex(0x527e93),
                am5.Color.fromHex(0x34bfa3),
                am5.Color.fromHex(0x316fc4),
                am5.Color.fromHex(0x2c3e93),
                am5.Color.fromHex(0x5800a1),
                am5.Color.fromHex(0x8e00b2),
            ],
        });
        this.rule('AxisLabel').setAll({
            oversizedBehavior: 'none',
        });
        this.rule('Line').setAll({
            strokeWidth: 2,
        });
        this.rule('LineSeries');
        this.rule('AxisLabel');
        this.rule('PieChart');
        this.rule('CategoryAxis');
    }
}

const getDynamicFiltersKey = (businessAccountId: number, userId: string, reportId: number) => {
    return `dynamic-params-${businessAccountId}-${userId}-${reportId}`;
};

enum DefaultDateValue {
    NOW = 'NOW',
    START_OF_DAY = 'START_OF_DAY',
    END_OF_DAY = 'END_OF_DAY',
    START_OF_WEEK = 'START_OF_WEEK',
    END_OF_WEEK = 'END_OF_WEEK',
    START_OF_MONTH = 'START_OF_MONTH',
    END_OF_MONTH = 'END_OF_MONTH',
    START_OF_YEAR = 'START_OF_YEAR',
    END_OF_YEAR = 'END_OF_YEAR',
}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

interface IState {
    requiredFiltersNotSet: boolean;
    blockHeight: number;
    chartAreaHeight: number;
    reportLoading: boolean;
}

class ReportPage extends BasePage<IProps, IState> {
    private fromFilters = false;
    private filtersForm;
    private view: View | null = null;
    private fields: FormFieldsGroup[] | undefined;

    constructor(props: IProps) {
        super(props);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.onGenerateButtonClick = this.onGenerateButtonClick.bind(this);
        this.getReportInputParameters = this.getReportInputParameters.bind(this);
        this.state = {
            requiredFiltersNotSet: false,
            blockHeight: 0,
            chartAreaHeight: 0,
            reportLoading: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
        this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
    }

    setSavedParams = () => {
        let defaultParams = this.getDefaultParams(this.props.entity?.parameters);
        let savedInfo = localStorage.getItem(getDynamicFiltersKey(this.props.businessAccountId, this.props.userId, this.props.entityId));
        let params = { ...defaultParams };
        if (savedInfo) {
            let initialParamsState = JSON.parse(savedInfo);
            if (initialParamsState) {
                for (const p in initialParamsState) {
                    params[p] = initialParamsState[p];
                }
            }
        }
        this.setState({ requiredFiltersNotSet: this.requiredParamsNotSet(params) });
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, params));
    };

    loadDataAndSetFilterValues = () => {
        this.setFiltersValuesFromParams();
    };

    getDefaultParams = (parameters: ReportInputArgumentModel[] | undefined) => {
        const params = {};
        parameters?.forEach((param) => {
            let defaultValue: any = param.placeholder;
            if (isDefined(defaultValue)) {
                if (param.dataType === DataTypeEnum.DateWithTime || param.dataType === DataTypeEnum.Date) {
                    let d = this.dateConstToNormalDateValue(defaultValue);
                    if (d) defaultValue = moment(d).valueOf();
                }
                params[param.name] = defaultValue;
            }
        });
        return params;
    };

    setFiltersValuesFromParams = () => {
        if (!this.fromFilters && this.filtersForm) {
            if (this.props.entity?.parameters) {
                let params = {}; // ...this.props.params
                for (const p in this.props.params) {
                    const paramData = this.props.entity?.parameters.find((item) => item.name === p);
                    if (paramData) {
                        let paramValue = this.props.params[p];
                        if (paramData.dataType === DataTypeEnum.Date) paramValue = moment(paramValue);
                        else if (paramData.dataType === DataTypeEnum.DateWithTime) paramValue = moment(paramValue);
                        else if (paramData.isReferenceToEntity) {
                            paramValue = {
                                key: paramValue,
                                label: '',
                            };
                        }
                        params[p] = paramValue;
                    }
                }
                this.setState({ requiredFiltersNotSet: this.requiredParamsNotSet(params) });
                console.log('PPP', params);
                this.filtersForm.setFieldsValue(params);
            }
        }
        this.fromFilters = false;
    };

    onWindowResize = () => {
        let element = document.getElementsByClassName('ant-card graphic')[0];
        if (element) {
            let bodyRect = document.body.getBoundingClientRect();
            let elRect = element.getBoundingClientRect();
            let offset = elRect.top - bodyRect.top;
            let height = window.innerHeight - offset - 12;
            let cardBody = element.children.item(0);
            let chartHeight = this.state.chartAreaHeight;
            if (cardBody) {
                let chartAreaElement = cardBody.children.item(2);
                if (chartAreaElement) {
                    let chartRect = chartAreaElement.getBoundingClientRect();
                    chartHeight = window.innerHeight - (chartRect.top - bodyRect.top) - 12;
                    if (chartHeight < 600) {
                        chartHeight = 600;
                        height = 600 + (chartRect.top - elRect.top) + 100;
                    }
                }
            }
            this.setState({
                ...this.state,
                chartAreaHeight: chartHeight,
                blockHeight: height,
            });
        }
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        // Тут загрузились данные для отчета, все его параметры
        if (this.props.entity !== prevProps.entity) {
            const params =
                this.props.entity?.parameters?.map((p) => {
                    return p.name;
                }) || [];
            PageUtils.setPageParamsOrLoadData(
                getStateFromPath2(this.props.location.search),
                params,
                () => {
                    // Тут когда без ссылки, просто из меню
                    this.setSavedParams();
                },
                () => {
                    // Тут когда происходит переход по ссылке
                    this.loadDataAndSetFilterValues();
                }
            );
        }

        // Тут когда изменились параметры или по ссылке или через фильтры
        if (!isEqual(prevProps.params, this.props.params)) {
            this.setFiltersValuesFromParams();
        }

        if (
            prevProps.viewInfo?.data !== this.props.viewInfo?.data ||
            prevProps.viewInfo?.settings !== this.props.viewInfo?.settings ||
            prevProps.viewInfo?.handlers !== this.props.viewInfo?.handlers ||
            prevProps.viewInfo?.preprocessor !== this.props.viewInfo?.preprocessor ||
            prevProps.viewInfo?.visualizationType !== this.props.viewInfo?.visualizationType
        ) {
            //console.log("DATA CHANGED")
            if (this.view) {
                await this.view.updateData();
                //console.log("DATA CHANGED Should update state!")
            }
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
        window.removeEventListener('resize', this.onWindowResize);
    }

    getPageHeaderProps(): IPageHeaderProps {
        let { entity } = this.props;
        if (entity !== null) {
            return {
                name: entity ? `${entity.reportConfigurationName}: ${entity.name}` : undefined,
                shortName: true,
                parentPagePath: entity ? `/${this.props.businessAccountId}/reports` : undefined,
                NavigationBlock: <ReportPageHeaderNavigationBlock report={entity || undefined} />,
            };
        }
        return {};
    }

    dateConstToNormalDateValue = (v: DefaultDateValue | any) => {
        let m: Moment | undefined;
        if (v === DefaultDateValue.NOW) {
            m = moment();
        } else if (v === DefaultDateValue.START_OF_DAY) {
            m = moment().startOf('day');
        } else if (v === DefaultDateValue.END_OF_DAY) {
            m = moment().endOf('day');
        } else if (v === DefaultDateValue.START_OF_WEEK) {
            m = moment().startOf('week');
        } else if (v === DefaultDateValue.END_OF_WEEK) {
            m = moment().endOf('week');
        } else if (v === DefaultDateValue.START_OF_MONTH) {
            m = moment().startOf('month');
        } else if (v === DefaultDateValue.END_OF_MONTH) {
            m = moment().endOf('month');
        } else if (v === DefaultDateValue.START_OF_YEAR) {
            m = moment().startOf('year');
        } else if (v === DefaultDateValue.END_OF_YEAR) {
            m = moment().endOf('year');
        }
        if (m) {
            return m.seconds(0).milliseconds(0).toJSON();
        } else {
            return v;
        }
    };

    getFilters(): FormFieldsGroup[] {
        let { entity } = this.props;
        let additionalProperties = {};
        let fields: IFormField[] = [];
        if (entity?.parameters) {
            for (const param of entity.parameters) {
                let type: FormItemType | undefined = undefined;
                switch (param.dataType) {
                    case DataTypeEnum.Double:
                    case DataTypeEnum.Float:
                        type = FormItemType.Float;
                        break;
                    case DataTypeEnum.Int:
                    case DataTypeEnum.Long:
                        type = FormItemType.Integer;
                        break;
                    case DataTypeEnum.Date:
                        type = FormItemType.Date;
                        break;
                    case DataTypeEnum.DateWithTime:
                        type = FormItemType.DateTime;
                        break;
                    case DataTypeEnum.Time:
                        break;
                    case DataTypeEnum.Timeinterval:
                        type = FormItemType.DateRangePicker;
                        break;
                }
                if (type) {
                    if (param.isReferenceToEntity) {
                        if (param.referencedTable) {
                            switch (param.referencedTable) {
                                case 'project':
                                    additionalProperties = {
                                        placeholder: 'Не выбран',
                                        type: FormItemType.Component,
                                        style: { minWidth: 134 },
                                        component: EntityRemoteSelect,
                                        componentProps: (): EntityRemoteSelectProps => {
                                            return {
                                                operationName: 'listProjects',
                                                nameField: 'shortName',
                                                getEntityOperationName: 'getProjectById',
                                                sortBy: 'lastActivityDate',
                                                sortOrder: 'DESC',
                                                showSearch: true,
                                            };
                                        },
                                    };
                                    break;
                                case 'subrent':
                                    additionalProperties = {
                                        placeholder: 'Не выбрана',
                                        type: FormItemType.Component,
                                        style: { minWidth: 142 },
                                        component: EntityRemoteSelect,
                                        componentProps: (): EntityRemoteSelectProps => {
                                            return {
                                                operationName: 'listSubrents',
                                                nameField: 'shortName',
                                                getEntityOperationName: 'getSubrentById',
                                                sortBy: 'lastActivityDate',
                                                sortOrder: 'DESC',
                                                showSearch: true,
                                            };
                                        },
                                    };
                                    break;
                                case 'renter':
                                    additionalProperties = {
                                        placeholder: 'Не выбран',
                                        type: FormItemType.Component,
                                        style: { minWidth: 134 },
                                        component: EntityRemoteSelect,
                                        componentProps: (): EntityRemoteSelectProps => {
                                            return {
                                                operationName: 'listRenters',
                                                nameField: 'shortName',
                                                getEntityOperationName: 'getRenterById',
                                                sortBy: 'lastActivityDate',
                                                sortOrder: 'DESC',
                                                showSearch: true,
                                            };
                                        },
                                    };
                                    break;
                                case 'product':
                                    additionalProperties = {
                                        placeholder: 'Не выбран',
                                        type: FormItemType.Component,
                                        style: { minWidth: 134 },
                                        component: EntityRemoteSelect,
                                        componentProps: () => ({
                                            operationName: 'listProducts',
                                            nameField: 'shortName',
                                            getEntityOperationName: 'getProductById',
                                            sortBy: 'lastActivityDate',
                                            sortOrder: 'DESC',
                                            showSearch: true,
                                        }),
                                        getInitialValue: (value) => {
                                            return value;
                                        },
                                    };
                                    break;
                            }
                        }
                    }
                    if (param.options) {
                        let values: { name: string; value: any }[] = [];
                        for (const option of param.options) {
                            values.push({
                                name: option.alias,
                                value: option.value,
                            });
                        }
                        additionalProperties = {
                            type: FormItemType.Select,
                            style: { minWidth: 70 },
                            values: values,
                        };
                    }
                    if (type === FormItemType.Date || type === FormItemType.DateTime) {
                        additionalProperties = {
                            placeholder: 'Любая дата',
                        };
                    }

                    let filter: IFormField = {
                        label: param.alias,
                        id: param.name,
                        required: param.required,
                        type: type,
                        style: { minWidth: 70, width: 144 },
                        requiredMessage: 'Обязательный параметр',
                        ...additionalProperties,
                    };
                    fields.push(filter);
                }
            }
        }

        return [
            {
                fields: fields,
            },
        ];
    }

    getReportInputParameters() {
        let { entity, params } = this.props;
        const arr: { name: string; value: any }[] = [];
        if (entity?.parameters) {
            for (const param of entity.parameters) {
                if (isDefined(params[param.name])) {
                    let paramValue = params[param.name];
                    if (param.dataType === DataTypeEnum.Date) paramValue = moment(paramValue).toJSON();
                    //moment(paramValue).format('YYYY-MM-DD');
                    else if (param.dataType === DataTypeEnum.DateWithTime) paramValue = moment(paramValue).toJSON();
                    arr.push({
                        name: param.name,
                        value: paramValue,
                    });
                }
            }
        }
        return {
            parameters: arr,
        };
    }

    onGenerateButtonClick() {
        if (this.state.requiredFiltersNotSet) {
            return;
        }
        if (this.props.recalculating) {
            return;
        }
        let paramsArguments: ReportInputParameters = this.getReportInputParameters();
        this.props.loadViewInfo(this.props.businessAccountId, this.props.entityId, paramsArguments);
    }

    onFiltersChange(data) {
        this.fromFilters = true;
        // Преобразуем параметры из фильтров в нормальный вид для урла
        const params = {};
        for (const p in data) {
            let v = data[p];
            if (isDefined(v)) {
                let paramData = this.props.entity?.parameters?.find((param) => param.name === p);
                if (paramData) {
                    if (v.key) {
                        params[p] = +v.key;
                    } else {
                        if (paramData.dataType === DataTypeEnum.Date || paramData.dataType === DataTypeEnum.DateWithTime) {
                            params[p] = v.valueOf();
                        } else {
                            params[p] = v;
                        }
                    }
                }
            } else {
                params[p] = undefined;
            }
        }
        // TODO обязательные параметры
        this.setState({ requiredFiltersNotSet: this.requiredParamsNotSet(params) });
        localStorage.setItem(
            getDynamicFiltersKey(this.props.businessAccountId, this.props.userId, this.props.entityId),
            JSON.stringify(params)
        );
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, params));
    }

    onDownloadReportClick = async (fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE, templateId: number | null) => {
        if (this.state.reportLoading) return;

        let paramsArguments: ReportInputParameters = this.getReportInputParameters();
        this.setState({ reportLoading: true });

        const id = templateId === null || templateId === -1 ? null : templateId;

        if (id == null) return;

        await DownloadDocumentUtils.reportGenerateAndDownload(
            {
                businessAccountId: this.props.businessAccountId,
                id,
                excelReportGenerationInfo: {
                    params: paramsArguments,
                },
            },
            `Отчет ${this.props.entity?.name || ''}`,
            fileType
        )
            .then(() => {
                this.setState({ reportLoading: false });
            })
            .catch((e) => {
                this.setState({ reportLoading: false });
                showNotification('error', 'Ошибка генерации файла');
            });
    };

    requiredParamsNotSet = (params) => {
        console.log('+++', params);
        let requiredNotSet = false;
        this.props.entity?.parameters?.forEach((item) => {
            if (item.required && !isDefined(params[item.name])) requiredNotSet = true;
        });
        return requiredNotSet;
    };

    renderContent(): JSX.Element {
        let { loading, businessAccountId, entityId, entity, viewInfo, canViewFinancialData } = this.props;

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        if (!!businessAccountId || !!entityId) {
            <Redirect to={'/'} />;
        }

        if (this.props.entity && !this.fields) {
            this.fields = this.getFilters();
        }

        return (
            <Spin spinning={loading && this.props.entity === null}>
                <Alert
                    className={'not-enough-space'}
                    message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                    description={<>Не хватает места для отображения графика!</>}
                    type="error"
                    showIcon
                    closable
                    style={{ marginBottom: 30 }}
                    icon={<Icon component={IconPenSquare} />}
                />
                <Card
                    bordered={false}
                    className={'graphic'}
                    bodyStyle={{ padding: '0', height: viewInfo !== null ? `${this.state.blockHeight}px` : '100%' }}
                >
                    {err ? (
                        <Alert
                            message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                            description={err}
                            type="error"
                            showIcon
                            closable
                            style={{ marginBottom: 30 }}
                            icon={<Icon component={IconPenSquare} />}
                        />
                    ) : null}
                    {this.props.entity && (
                        <Row style={{ padding: '32px 32px 8px 32px' }}>
                            <div className={'rr-report-filters'}>
                                <div style={{ flexGrow: 1 }}>
                                    <DynamicFilters
                                        gridName={'statisticReport'}
                                        wrappedComponentRef={this.getFiltersForm}
                                        title={<span>Параметры отчета</span>}
                                        otherFiltersCollapsed={true}
                                        initialValues={{}}
                                        defaultValues={{}}
                                        currentValues={{}}
                                        onChange={this.onFiltersChange}
                                        data={this.fields || []}
                                    />
                                </div>
                                <div style={{ marginLeft: '16px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className={'rr-report-filters-buttons'}>
                                        <ExportToExcelReportPagePopover
                                            title={
                                                <>
                                                    <Icon style={{ fontSize: 21, marginRight: 10 }} component={IconChartArea} />
                                                    <div>Скачать отчет</div>
                                                </>
                                            }
                                            onDownload={async ({ fileType, templateId }) => {
                                                await this.onDownloadReportClick(fileType, templateId);
                                            }}
                                            onVisibleChange={(visibility) => {
                                                if (this.state.requiredFiltersNotSet || this.state.reportLoading) {
                                                    return false;
                                                } else {
                                                    return visibility;
                                                }
                                            }}
                                            reportInstanceId={this.props.entity?.id || -1}
                                            defaultTemplateId={this.props.entity?.defaultExcelVisualizationId}
                                            storageKey={`reportExcell-${this.props.entityId}`}
                                        >
                                            <span
                                                className={
                                                    this.state.requiredFiltersNotSet || this.props.recalculating
                                                        ? 'rr-grid-export-button rr-grid-export-button-disabled rr-report-export-button'
                                                        : 'rr-grid-export-button rr-report-export-button'
                                                }
                                            >
                                                <Icon
                                                    className={
                                                        this.state.requiredFiltersNotSet || this.props.recalculating
                                                            ? 'rr-grid-export-button-disabled'
                                                            : ''
                                                    }
                                                    style={{
                                                        fontSize: 21,
                                                        height: '100%',
                                                        justifyContent: 'center',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    component={IconChartArea}
                                                />
                                                <span
                                                    style={{
                                                        height: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    Скачать отчет
                                                </span>
                                            </span>
                                        </ExportToExcelReportPagePopover>
                                        <RoundButton
                                            style={{ flexDirection: 'column-reverse', borderRadius: '4px' }}
                                            onClick={this.onGenerateButtonClick}
                                            colorScheme={
                                                this.state.requiredFiltersNotSet || this.props.recalculating ? 'blocked' : 'success'
                                            }
                                            disabled={this.state.requiredFiltersNotSet || this.props.recalculating}
                                        >
                                            <Icon component={IconChalkboardSolid} style={{ fontSize: '21px' }} />
                                            Пересчитать
                                        </RoundButton>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    )}
                    <div
                        className={'graphic'}
                        style={{ borderTop: '1px solid am5.Color.fromHex(0xeaeaea', width: '100%', height: '1px' }}
                    />
                    <Row
                        style={{
                            padding: '32px',
                            height: viewInfo !== null ? `${this.state.chartAreaHeight}px` : '364px',
                            width: '100%',
                        }}
                    >
                        <Col
                            style={{
                                minHeight: '300px',
                                height: viewInfo !== null ? `${this.state.chartAreaHeight - 64}px` : '300px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Spin
                                className={'containeruncharted'}
                                wrapperClassName={'chart-area-spinner'}
                                style={{ width: '100%', height: '100%' }}
                                spinning={this.props.recalculating}
                            >
                                {viewInfo !== null ? (
                                    <View
                                        _theme={Theme}
                                        gridCellIdentifier={entityId}
                                        ref={(view) => {
                                            if (!this.view) {
                                                this.view = view;
                                                this.onWindowResize();
                                            }
                                        }}
                                        errorView={() => {
                                            return <>Ошибочка</>;
                                        }}
                                        emptyDataView={() => (
                                            <>
                                                <ItemsNotFoundBlock
                                                    message={'Уточните параметры отчета и попробуйте снова!'}
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'inline-flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                />
                                            </>
                                        )}
                                        dataSupplier={viewInfo}
                                        language={'ru'}
                                        region={'RU'}
                                        className={'uncharted'}
                                    />
                                ) : this.state.requiredFiltersNotSet ? (
                                    <div className={'containeruncharted'}>
                                        <ChartAreaNotGeneratedBlock
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'inline-flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                            }}
                                            requiredNotSet={true}
                                            avatarStyle={{
                                                backgroundColor: 'am5.Color.fromHex(0xF4516C',
                                                opacity: 0.66,
                                            }}
                                            title={'Обязательные параметры не заданы'}
                                            message={'Задайте параметры и запустите перерасчет!'}
                                        />
                                    </div>
                                ) : (
                                    <div className={'containeruncharted'}>
                                        <ChartAreaNotGeneratedBlock
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'inline-flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                            }}
                                            avatarStyle={{ backgroundColor: 'rgb(144, 220, 255)' }}
                                            title={'Отчет еще не просчитан'}
                                            message={'Задайте параметры и запустите перерасчет!'}
                                        />
                                    </div>
                                )}
                            </Spin>
                        </Col>
                    </Row>
                </Card>
                {entity == null ? (
                    <></>
                ) : (
                    <div className={joinClasses('antd-card-body', 'card-no-padding')} style={{ cursor: 'default' }}>
                        <Row gutter={[24, 24]} style={{ paddingTop: '32px', marginTop: '0px' }} className={'padded-row'}>
                            <Col span={24} md={12} lg={canViewFinancialData ? 8 : 12}>
                                <Row>
                                    <Col className={'rr-label'}>Наименование отчета</Col>
                                </Row>
                                <Row>
                                    <Col className={'rr-value'}>{entity.reportConfigurationName}</Col>
                                </Row>
                            </Col>
                            <Col span={24} md={12} lg={canViewFinancialData ? 8 : 12}>
                                <Row>
                                    <Col className={'rr-label'}>Вид</Col>
                                </Row>
                                <Row>
                                    <Col className={'rr-value'}>
                                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            <FormValue2
                                                content={
                                                    <>
                                                        <SystemIcon
                                                            type={
                                                                entity.reportType === ReportTypeCodeEnum.COMMON
                                                                    ? SystemIconType.standard
                                                                    : SystemIconType.custom
                                                            }
                                                        />
                                                        {entity.reportType === ReportTypeCodeEnum.COMMON ? ' Стандартный' : ' Частный'}
                                                    </>
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {canViewFinancialData && (
                                <Col span={24} md={12} lg={canViewFinancialData ? 8 : 12}>
                                    <Row>
                                        <Col className={'rr-label'}>Финансовая информация</Col>
                                    </Row>
                                    <Row>
                                        <Col className={'rr-value'}>
                                            <FormValue2
                                                content={
                                                    entity.financial && (
                                                        <>
                                                            <SystemIcon type={SystemIconType.financial} />
                                                            <span>Содержит</span>
                                                        </>
                                                    )
                                                }
                                                emptyContent={'Не содержит'}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <div style={{ borderTop: '1px solid #EAEAEA', width: '100%', height: '1px' }} />
                        <Row style={{ paddingTop: '32px' }} className={'padded-row'}>
                            <Col>
                                <Row>
                                    <Col className={'rr-label'}>Описание</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {entity.description ? (
                                            <RichTextView style={{ fontWeight: 500 }} value={entity.description}></RichTextView>
                                        ) : (
                                            <span className="rr-empty-value">
                                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__N)}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    return {
        userId: storeState.oidc.user?.profile.email || 'undefined',
        entity: storeState.report.entity,
        loading: storeState.report.loading,
        loadingError: storeState.report.loadingError,
        recalculating: storeState.report.recalculating,
        viewInfo: storeState.report.viewInfo,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        params: storeState.report.params,
    };
};

const mapDispatchToProps = { loadEntity, loadViewInfo, /*downloadTemplate, */ reset, replace };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);

import React from 'react';
import {Button, Icon} from 'antd';
import {localize} from '../../localization/localizationUtils';
import {LocalizationEnum} from '../../localization';
import {IconArchive, IconCheck, IconControlPointDuplicateBlack, IconEdit, IconLevelUp, IconTags, IconUnlock, IconUnlockAlt} from '../icons';
import {RoundButton} from "../index";
import AdditionalEntityActions from "../additionalEntityActions/additionalEntityActions";
import {AdditionalEntityActionsButton} from "../additionalEntityActions/additionalEntityActionsButton";
import {SystemIcon, SystemIconType} from "../v2/systemIcon/systemIcon";

export interface IProps {
    onEdit?: () => void;
    onSetAmount?: () => void;
    onFromArchive?: () => void;

    onActivate?: () => void;
    onUnlock?: () => void;
    //
    onCopy?: () => void;
    onToArchive?: () => void;
    onLock?: () => void;
    onChangeType?: () => void;
    onDelete?: () => void;

    changeTypeDisabled?: boolean;
    deleteDisabled?: boolean;
}

export class EntityCardActionsBlock<P extends IProps, S = {}> extends React.Component<P, S> {

    render() {

        let additionalActions:any[] = [];
        if(this.props.onCopy){
            additionalActions.push(
                <Button className={'ant-btn-page-actions'} block onClick={()=>{
                    if(this.props.onCopy) this.props.onCopy();
                }}>
                    <Icon component={IconControlPointDuplicateBlack} style={{ color: '#34bfa3' }}/>
                    {' '}
                    {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                </Button>
            );
        }
        if(this.props.onToArchive){
            additionalActions.push(
                <Button className={'ant-btn-page-actions'} onClick={()=>{
                    if(this.props.onToArchive) this.props.onToArchive();
                }}>
                    <Icon component={ IconArchive} style= {{color: '#c4c5d6' }}/>
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </Button>
            );
        }
        if(this.props.onLock){
            additionalActions.push(
                <Button className={'ant-btn-page-actions'} block onClick={()=>{
                    if(this.props.onLock) this.props.onLock();
                }}>
                    <Icon component={IconUnlock} style={{color:'#A0A0A0'}} />
                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                </Button>
            );
        }
        if(this.props.onChangeType){
            additionalActions.push(
                <Button disabled={this.props.changeTypeDisabled} className={'ant-btn-page-actions'} block onClick={()=>{
                    if(this.props.onChangeType) this.props.onChangeType();
                }}>
                    <Icon component={IconTags} style={{color:'#00afee'}} />
                    <span>Изменить тип учета</span>
                </Button>
            );
        }
        if(this.props.onDelete){
            additionalActions.push(
                <Button disabled={this.props.deleteDisabled} className={'ant-btn-page-actions'} block onClick={()=>{
                    if(this.props.onDelete) this.props.onDelete();
                }}>
                    <SystemIcon type={SystemIconType.delete} style={{color: '#F4516C'}}/>
                    <span>Удалить</span>
                </Button>
            );
        }

        return (
            <>

                {/* Задать количество */}
                {
                    this.props.onSetAmount && (
                        <RoundButton colorScheme={'brandSecond2'} onClick={()=>{
                            if(this.props.onSetAmount) this.props.onSetAmount();
                        }}>
                            <Icon type={'cubes'} />
                            {localize(LocalizationEnum.PAGE__PRODUCTS__ACTIONS__SET_AMOUNT, 'span')}
                        </RoundButton>
                    )
                }

                {/* Редактировать */}
                {
                    this.props.onEdit && (
                        <RoundButton colorScheme={'default'} onClick={()=>{
                            if(this.props.onEdit) this.props.onEdit();
                        }}>
                            <Icon component={IconEdit} />{' '}
                            {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                        </RoundButton>
                    )
                }

                {/* Из архива */}
                {
                    this.props.onFromArchive && (
                        <RoundButton colorScheme={'FROM_ARCHIVE'} className={'ant-btn-page-actions'} onClick={()=>{
                            if(this.props.onFromArchive) this.props.onFromArchive();
                        }}>
                            <Icon component={ IconLevelUp } />
                            { localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span') }
                        </RoundButton>
                    )
                }

                {/* Активировать */}
                {
                    this.props.onActivate && (
                        <RoundButton colorScheme={'success'} onClick={()=>{
                            if(this.props.onActivate) this.props.onActivate();
                        }}>
                            <Icon component={IconCheck} />
                            {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                        </RoundButton>
                    )
                }

                {/* Разблокировать */}
                {
                    this.props.onUnlock && (
                        <RoundButton colorScheme={'success'} onClick={()=>{
                            if(this.props.onUnlock) this.props.onUnlock();
                        }}>
                            <Icon component={IconUnlockAlt} />
                            {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                        </RoundButton>
                    )
                }

                {
                    additionalActions.length > 0 && (
                        <span style={{ marginLeft: '16px' }}>
                            <AdditionalEntityActions
                                disabled={false}
                                content={
                                    additionalActions
                                }>
                                <AdditionalEntityActionsButton />
                            </AdditionalEntityActions>
                        </span>
                    )
                }

            </>
        );
    }
}

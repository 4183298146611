import {PaymentMethodsState} from './paymentMethods.types';
import {SetStatusForPaymentMethodsArgs} from '../../api/types';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {showNotification} from '../../../../../../components/notification/showNotification';
import {serverApi} from '../../../../../../server';
import {paymentMethodsApi} from '../../api/paymentMethods.api';
import {IRootState} from "../../../../../../shared/reducers";
import {loadEntity} from "../../../../projects/production/reducers/project.reducer";

const ACTION_TYPES = {
    CHANGE_STATUS: 'paymentMethods/CHANGE_STATUS',
};

export const initialState: PaymentMethodsState = {
    loading: false,
    loadingError: undefined,
    entities: undefined,
    filteredCount: 0,
};

export const setStatusForPaymentMethods = createAsyncThunk(
    ACTION_TYPES.CHANGE_STATUS,
    async (args: SetStatusForPaymentMethodsArgs, {dispatch, getState}) => {
        const {businessAccountId, workflowDirectiveBulk} = args;

        try {
            await serverApi.transitPaymentMethodsWorkflowBulk(businessAccountId, workflowDirectiveBulk);

            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1 ? 'Статус способов оплаты успешно обновлен' : 'Статус способа оплаты успешно обновлен';
            showNotification('success', notificationMessage);
            dispatch(paymentMethodsApi.util?.invalidateTags(['PaymentMethodsList', 'PaymentMethod']));
            const project = (getState() as IRootState).project.entity;
            if (project) dispatch(loadEntity(project.businessAccountId, project.id));
        } catch (error: unknown) {
            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1
                    ? 'Не удалось обновить статус способов оплаты платежей'
                    : 'Не удалось обновить статус способа оплаты платежей';
            showNotification('error', notificationMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

const slice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {},
});

export default slice.reducer;

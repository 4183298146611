import { CalendarSortValue } from '../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { CalendarSortGroupMap } from '../../../../components/Calendar/hooks/useCalendarItems';

import { ElementsCalendarSortValue } from './groupsData';

export const elementsCalendarSortData: CalendarSortValue<ElementsCalendarSortValue>[] = [
    { value: 'category', title: 'Категориям (основным)' },
    { value: 'renter', title: 'Контрагентам' },
    { value: 'project', title: 'Проектам и поставкам' },
    { value: 'product', title: localize(LocalizationEnum.ASPECT__FILTERS__SORT_BY_PRODUCT) },
    { value: 'stateCode', title: localize(LocalizationEnum.ASPECT__FILTERS__SORT_BY_STATE) },
    { value: 'startDate', title: localize(LocalizationEnum.PAGE__CALENDAR__GROUPING_OPTION__RENT_PERIOD_START) },
    { value: 'endDate', title: localize(LocalizationEnum.PAGE__CALENDAR__GROUPING_OPTION__RENT_PERIOD_END) },
];

export const elementsCalendarSortValueToItemKey = {
    category: 'commonFields.mainCategoryName',
    renter: 'counterpartyId',
    project: ['projectId', 'subrentId'],
    product: 'commonFields.productId',
    stateCode: 'commonFields.stateCode',
} satisfies CalendarSortGroupMap<ElementsCalendarSortValue>;

import { DownloadDocumentPopoverProps, IDownloadDocumentParamsItem } from '../../../DownloadDocumentPopover';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { FileTypeCodeEnum } from '../../../../../../server';

export enum ExportOffloadingPopoverOptions {
    ALL_COLUMNS = 'allColumns',
}

export const exportOffloadingPopoverOptions: IDownloadDocumentParamsItem<ExportOffloadingPopoverOptions>[] = [
    {
        key: ExportOffloadingPopoverOptions.ALL_COLUMNS,
        label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__ALL_COLUMNS),
    },
];

export const availableFileTypes = [FileTypeCodeEnum.EXCEL, FileTypeCodeEnum.PDFTYPE] as const;
export type OffloadingFileType = (typeof availableFileTypes)[number];
export type OffloadingDocumentOnDownload = DownloadDocumentPopoverProps<OffloadingFileType, ExportOffloadingPopoverOptions>['onDownload'];

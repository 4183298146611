import React from 'react';
import {BaseSelect, BaseSelectBaseProps, BaseSelectValue} from "../baseSelect";
import {InstanceRecord, InstanceRecordList, serverApi} from "../../../../server";
import {AxiosResponse} from "axios";
import {useAppSelector} from "../../../../store/hooks";

export interface InstanceSelectProps extends BaseSelectBaseProps<InstanceRecord> {
    productId?: number;
    variantId?: number;
}

const InstanceSelectFC = ({productId, variantId, ...props}: InstanceSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        let response: AxiosResponse<InstanceRecordList>;
        if (productId && !variantId) {
            response = await serverApi.listProductInstances(businessAccountId, productId, undefined, 0, [`id;IN;${ids}`]);
        } else if (productId && variantId) {
            response = await serverApi.listProductVariantInstances(businessAccountId, productId, variantId, undefined, 0, [`id;IN;${ids}`]);
        } else {
            response = await serverApi.listInstances(businessAccountId, undefined, 0, [`id;IN;${ids}`]);
        }
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        let response: AxiosResponse<InstanceRecordList>;
        if (productId && !variantId) {
            response = await serverApi.listProductInstances(businessAccountId, productId, limit, startFrom, undefined, 'lastActivityDate', 'DESC', search);
        } else if (productId && variantId) {
            response = await serverApi.listProductVariantInstances(businessAccountId, productId, variantId, limit, startFrom, undefined, 'lastActivityDate', 'DESC', search);
        } else {
            response = await serverApi.listInstances(businessAccountId, limit, startFrom, undefined, 'lastActivityDate', 'DESC', search);
        }
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.nameOrCode,
                children: record.nameOrCode,
            })}
        />
    );
};

export class InstanceSelect extends React.Component<InstanceSelectProps> {
    render() {
        return (
            <InstanceSelectFC {...this.props} />
        );
    }
}

import { ReportChartVisualizationInfoRead, ReportInputParameters, serverApi, ServerError, ViewInfo } from '../../../../server';
import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { getServerError, getStateFromPath2 } from '../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'report/LOAD_ENTITY',
    LOAD_VIEW_INFO: 'report/LOAD_VIEW_INFO',
    //DOWNLOAD_EXCEL_REPORT: 'report/DOWNLOAD_EXCEL',
    RESET: 'report/RESET',
};

const initialState = {
    loadingExcel: false as boolean,
    recalculating: false as boolean,
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entity: null as ReportChartVisualizationInfoRead | null,
    viewInfo: null as ViewInfo | null,
    params: {},
};

export type ReportState = Readonly<typeof initialState>;

export default (state: ReportState = initialState, action): ReportState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            if (/\/\d+\/reports\/\d+/.test(action?.payload?.location?.pathname)) {
                let params = getStateFromPath2(action.payload.location.search);
                for (const p in state.params) {
                    if (!params.hasOwnProperty(p)) params[p] = undefined;
                }

                let newParams = { ...params };
                return {
                    ...state,
                    params: {
                        ...state.params,
                        ...newParams,
                    },
                };
            }
            return state;
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loading: true,
                loadingError: undefined,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loading: false,
                loadingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            // let d = action.payload.data;
            // if(d.parameters){
            //     d.parameters.forEach((item)=>{
            //         if(item.name === 't0'){
            //             item.required = true;
            //             //item.placeholder = 'NOW';
            //         }
            //     });
            // }
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case REQUEST(ACTION_TYPES.LOAD_VIEW_INFO):
            return {
                ...state,
                loading: true,
                recalculating: true,
                loadingError: undefined,
            };
        case FAILURE(ACTION_TYPES.LOAD_VIEW_INFO):
            return {
                ...state,
                loading: false,
                recalculating: false,
                viewInfo: null,
                loadingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.LOAD_VIEW_INFO):
            return {
                ...state,
                recalculating: false,
                loading: false,
                viewInfo: action.payload.data,
            };
        // case REQUEST(ACTION_TYPES.DOWNLOAD_EXCEL_REPORT):
        //     return {
        //         ...state,
        //         loadingExcel: true,
        //         loadingError: undefined,
        //     };
        // case FAILURE(ACTION_TYPES.DOWNLOAD_EXCEL_REPORT):
        //     let serverError = getServerError(action.payload);
        //     return {
        //         ...state,
        //         loadingExcel: false,
        //         loadingError: serverError,
        //     };
        // case SUCCESS(ACTION_TYPES.DOWNLOAD_EXCEL_REPORT):
        //     let fileBytes = action.payload;
        //     const filename = `Отчет ${state.entity?.name || ''}`;
        //     downloadDataFromExcel(fileBytes.data, filename);
        //     return {
        //         ...state,
        //         loadingExcel: false,
        //     };
        default:
            return state;
    }
};

// Actions

// Получение состояния отчета
export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getReportChartVisualizationById(businessAccountId, id),
});

export const loadViewInfo = (businessAccountId: number, id: number, params: ReportInputParameters) => ({
    type: ACTION_TYPES.LOAD_VIEW_INFO,
    payload: serverApi.getViewInfoOfReportChartVisualization(businessAccountId, id, params),
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

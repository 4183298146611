import React, {CSSProperties, FC, memo} from 'react';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { IconClockO, IconHourglassEndSolid } from '../../../../../../../../components/icons';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';
import { ProblemEnum } from '../../../../../../../../types';
import { DashboardWidgetRedLine } from '../../../../../components/DashboardWidget/instances/redLine/DashboardWidgetRedLine';
import { DashboardData } from '../../../../../types/DashboardData';

interface DashboardRentWidgetProps {
    dashboardData: DashboardData;
    style?: CSSProperties;
    XXL?: boolean;
}

export const DashboardRentDelayWidget: FC<DashboardRentWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const { problemsAndWarnings, loading } = dashboardData;

    return (
        <DashboardWidgetRedLine
            loading={loading}
            title={localize(LocalizationEnum.ASPECT__PROBLEM__DELAYS)}
            icons={[IconHourglassEndSolid, IconClockO]}
            sums={[problemsAndWarnings?.returnDelay.sum, problemsAndWarnings?.giveawayDelay.sum]}
            elementsCount={[problemsAndWarnings?.returnDelay.elementsCount, problemsAndWarnings?.giveawayDelay.elementsCount]}
            urls={[
                linkToCalendar({ problem: ProblemEnum.RETURN_DELAY, group: 'project', capture: 'allWithNow' }),
                linkToCalendar({ problem: ProblemEnum.GIVEAWAY_DELAY, group: 'project', capture: 'allWithNow' }),
            ]}
            style={props.style}
            X={props.XXL}
        />
    );
});

import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { CalendarHeaderContainer } from '../../../../components/Calendar/components/Header/HeaderContainer/CalendarHeaderContainer';
import { ElementsCount } from '../../../../components/Calendar/components/Header/ElementsCount/ElementsCount';
import { WorkPlanningsCalendarHeaderButtons } from '../../components/HeaderButtons/WorkPlanningsCalendarHeaderButtons';
import { PageUrlParamsObject } from '../../../../../../../core/hooks/urlParams/types';
import { WorkPlanningsCalendarFilters } from '../../filters/workPlanningsCalendarFilters.types';
import {
    CALENDAR_LINE_HEIGHT_RATIO,
    CALENDAR_SIDEBAR_WIDTH_MEDIUM,
    CalendarDisplayType,
} from '../../../../components/Calendar/utils/data/calendarConstants';
import { workPlanningsCalendarSortData, WorkPlanningsSortValue } from '../../utils/data/sortData';
import { useCalendarItems } from './hooks/useCalendarItems';
import { CalendarId } from '../../../../components/Calendar/types/items';
import { ItemsNotFoundBlock } from '../../../../../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import { WorkPlanningGroupRecordList } from '../../../../../../../server';
import { useDeferredItems } from '../../../../components/Calendar/hooks/useDeferredItems';
import { useDisableMouseScrollEffect } from '../../../../components/Calendar/hooks/useDisableMouseScrollEffect';
import { TimeRangeContext } from '../../../../components/CalendarItemRenderer/context/TimeRangeContext';
import Timeline, { TimelineHeaders } from 'react-calendar-timeline';
import { CalendarHeader } from '../../../../components/CalendarHeader/CalendarHeader';
import { CalendarMarker } from '../../../../components/CalendarMarker/CalendarMarker';
import { CalendarLineUtils } from '../../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import { useDateHeaderUnits } from '../../../../components/Calendar/hooks/useDateHeaderUnits';
import { useIntervalClick } from '../../../../components/Calendar/hooks/useIntervalClick';
import { useHorizontalLineClassNamesForGroup } from '../../../../components/Calendar/hooks/useClassNames';
import { CalendarGroupsObject } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import { useOnCalendarItemChanges } from '../../../../components/Calendar/hooks/useOnCalendarItemChanges';
import { useCalendarGroups } from './hooks/useCalendarGroups';
import { WorkPlanningsSplittedCalendarGroupRenderer } from './components/groupRenderer/WorkPlanningsSplittedCalendarGroupRenderer';
import './WorkPlanningsSplittedCalendar.less';
import { WorkPlanningsSplittedCalendarItemRenderer } from './components/itemRenderer/WorkPlanningsSplittedCalendarItemRenderer';
import classNames from 'classnames';
import { isSplittedCalendarHasSortGroup } from './utils/isSplittedCalendarHasSortGroup';
import { useGetSidebarGroup } from './hooks/useGetSidebarGroup';

interface WorkPlanningsSplittedCalendarProps {
    workPlanningsGroupsData: WorkPlanningGroupRecordList | undefined;
    workPlanningsCalendarGroups: CalendarGroupsObject<WorkPlanningsSortValue>;
    isLoading: boolean;
    pageParamsObject: PageUrlParamsObject<WorkPlanningsCalendarFilters>;
    setChosenWorkId: (value: CalendarId | undefined) => void;
    setChosenProfessionId: (value: React.SetStateAction<number | undefined>) => void;
}

export const WorkPlanningsSplittedCalendar: FC<WorkPlanningsSplittedCalendarProps> = memo((props) => {
    const { workPlanningsGroupsData, setChosenWorkId, workPlanningsCalendarGroups, isLoading, pageParamsObject, setChosenProfessionId } =
        props;
    const { pageParams, updatePageParams } = pageParamsObject;
    const [sortByValue, setSortByValue] = useState<WorkPlanningsSortValue | undefined>(pageParams.group);
    const [displayType, setDisplayType] = useState<CalendarDisplayType>(pageParams.displayType ?? CalendarDisplayType.NORMAL);
    const [collapsedGroupsId, setCollapsedGroupsId] = useState<CalendarId[]>([]);
    const [collapsedSidebarGroupsId, setCollapsedSidebarGroupsId] = useState<CalendarId[]>([]);
    const [sidebarWidth, setSidebarWidth] = useState(isSplittedCalendarHasSortGroup(sortByValue!) ? CALENDAR_SIDEBAR_WIDTH_MEDIUM : 0);
    const scrollRef = useRef<HTMLDivElement>();
    const selectedIndexRef = useRef<number | undefined>();
    const sortByValueRef = useRef<WorkPlanningsSortValue | undefined>();

    const { records: groupRecords, listAttributes } = workPlanningsGroupsData ?? {};

    const filteredCount = listAttributes?.filteredCount ?? 0;

    const { items, visibleTimeStart, visibleTimeEnd } = useCalendarItems(
        groupRecords,
        isLoading,
        pageParams,
        collapsedGroupsId,
        collapsedSidebarGroupsId
    );

    const onIntervalClick = useIntervalClick(updatePageParams);

    const lineHeight = CalendarLineUtils.map.lineHeightFromDisplayType[displayType];

    const { onItemClick } = useOnCalendarItemChanges({
        items,
        calendarType: 'workPlannings',
        selectedIndexRef,
        scrollRef,
        setIsPopoverVisible: () => {},
        setCollapsedKitsId: () => {},
        setCollapsedGroupsId,
        onClick: (itemId) => {
            setChosenWorkId(itemId);
        },
        pageParams,
        isLoading,
    });

    const getSidebarGroup = useGetSidebarGroup(setChosenProfessionId);

    const horizontalLineClassNamesForGroup = useHorizontalLineClassNamesForGroup(items);

    const deferredItems = useDeferredItems(items, isLoading);
    const groups = useCalendarGroups(deferredItems, displayType);

    const { primaryDateHeaderUnit, secondaryDateHeaderUnit } = useDateHeaderUnits(visibleTimeStart, visibleTimeEnd);

    useDisableMouseScrollEffect(scrollRef);

    useEffect(() => {
        sortByValueRef.current = sortByValue;
    }, [sortByValue]);

    useEffect(() => {
        setSidebarWidth(isSplittedCalendarHasSortGroup(sortByValueRef.current!) ? CALENDAR_SIDEBAR_WIDTH_MEDIUM : 0);
    }, [deferredItems]);

    return (
        <div className={classNames('calendar-timeline', 'splitted')}>
            {
                <CalendarHeaderContainer>
                    <ElementsCount items={items} filteredCount={filteredCount} />
                    <div className={'actions-block'}>
                        <WorkPlanningsCalendarHeaderButtons
                            isLoading={isLoading}
                            pageParamsObject={pageParamsObject}
                            sortData={workPlanningsCalendarSortData}
                            sortByValue={sortByValue}
                            setSortByValue={setSortByValue}
                            displayType={displayType}
                            setDisplayType={setDisplayType}
                        />
                    </div>
                </CalendarHeaderContainer>
            }
            {
                <TimeRangeContext.Provider
                    value={{
                        visibleTimeStart,
                        visibleTimeEnd,
                    }}
                >
                    <Timeline
                        key={sidebarWidth}
                        canMove={false}
                        canResize={false}
                        groups={groups}
                        horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
                        itemHeightRatio={CALENDAR_LINE_HEIGHT_RATIO}
                        items={deferredItems}
                        lineHeight={lineHeight}
                        scrollRef={(el) => (scrollRef.current = el)}
                        sidebarWidth={sidebarWidth}
                        visibleTimeStart={visibleTimeStart}
                        visibleTimeEnd={visibleTimeEnd}
                        groupRenderer={({ group }) => (
                            <WorkPlanningsSplittedCalendarGroupRenderer
                                getSidebarGroup={getSidebarGroup}
                                group={group}
                                sortByValue={sortByValue}
                                displayType={displayType}
                                collapsedSidebarGroupsId={collapsedSidebarGroupsId}
                                setCollapsedSidebarGroupsId={setCollapsedSidebarGroupsId}
                            />
                        )}
                        itemRenderer={WorkPlanningsSplittedCalendarItemRenderer({
                            displayType,
                            onItemClick,
                            groupsData: workPlanningsCalendarGroups,
                            sortByValue,
                        })}
                    >
                        <TimelineHeaders>
                            <CalendarHeader.Primary
                                unit={primaryDateHeaderUnit}
                                onIntervalClick={onIntervalClick}
                                allNothing={!visibleTimeEnd || !visibleTimeStart}
                            />
                            <CalendarHeader.Secondary
                                primaryUnit={primaryDateHeaderUnit}
                                unit={secondaryDateHeaderUnit}
                                onIntervalClick={onIntervalClick}
                                allNothing={!visibleTimeEnd || !visibleTimeStart}
                                sidebarWidth={sidebarWidth}
                            />
                        </TimelineHeaders>
                        {items?.length && <CalendarMarker />}
                    </Timeline>
                </TimeRangeContext.Provider>
            }
            {items.length === 0 ? <ItemsNotFoundBlock style={{ marginTop: 56, marginBottom: 20 }} entityType={'element'} /> : null}
        </div>
    );
});

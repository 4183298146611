import { URLDrawerParams } from '../../components/page/entityGrid/types/params';
import { PageURLParamDescription, PageURLParamDescriptionObject } from './descriptions';
import { Location } from 'history';
import { getGridStorageData } from '../../components/grid/utils';
import _ from 'lodash';
import { getStore } from '../../../index';
import { updateGridSettings } from '../../shared/reducers/userSettings/userSettings.reducer';

export type ParamsDescription<T extends object> = { [key in keyof T]: PageURLParamDescription<T[key]> };

export abstract class ParamsUtils {
    static deleteDrawerParams = (urlSearchParams: URLSearchParams) => {
        const drawerParamsEmptyObject: URLDrawerParams = {
            view: undefined,
            modal: undefined,
            id: undefined,
        };

        Object.keys(drawerParamsEmptyObject).forEach((key) => {
            urlSearchParams.delete(key);
        });

        return urlSearchParams;
    };
    static getParamsFromSearchString = (search: string): { [k: string]: string } => {
        let obj: { [k: string]: string } = {};
        let searchParams: URLSearchParams = new URLSearchParams(search);
        searchParams.forEach((value, key) => {
            obj[key] = value;
        });
        return obj;
    };
    static getParamsFromParamsDescription = <T extends object>(
        paramsDescription: ParamsDescription<T>,
        queryParams: { [p: string]: string },
        initialValues?: Partial<T>
    ) => {
        const params: T = {} as T;
        for (const paramKey in paramsDescription) {
            let value: T[Extract<keyof T, string>];
            if (queryParams.hasOwnProperty(paramKey)) {
                value = paramsDescription[paramKey].fromString(queryParams[paramKey]);
            } else {
                value = initialValues?.[paramKey] ?? paramsDescription[paramKey].defaultValue;
            }
            params[paramKey] = value as T[Extract<keyof T, string>];
        }

        return params;
    };
    static getURLSearchParams = <Params extends object>(
        location: Location<unknown>,
        paramsDescription?: PageURLParamDescriptionObject<Params>,
        initialValues?: Partial<Params>
    ): URLSearchParams => {
        const urlParams = ParamsUtils.getParamsFromSearchString(location.search);
        const params = paramsDescription
            ? ParamsUtils.getParamsFromParamsDescription(paramsDescription, urlParams, initialValues)
            : urlParams;

        const searchParams = new URLSearchParams();
        for (const param in params) {
            if (params[param] == null) {
                delete params[param];
                continue;
            }

            searchParams.append(param, params[param]);
        }

        return searchParams;
    };
    static setGridStorageDataParamsFilters = (gridName: string, filters: { [k: string]: any }, withExistedFilters: boolean = false) => {
        const gridData = { ...getGridStorageData(gridName) };
        gridData.filters = _.assign(
            {},
            {
                ...(withExistedFilters ? gridData.filters : undefined),
                ...filters,
            }
        );
        if (gridData.filters.page !== undefined) delete gridData.filters.page;

        getStore().dispatch(updateGridSettings({ [gridName]: gridData }));
    };
    static getProcessedParamsWithSelectors = <PageParams extends object>({ pageParams }: { pageParams: PageParams }) => {
        const newParams = { ...pageParams };

        for (const key in newParams) {
            const param = newParams[key];
            if (param == null) continue;
            if (typeof param === 'object' && 'key' in param) {
                newParams[key] = Number(param.key) as any;
            }
            if (Array.isArray(param)) {
                if (param.length === 0) {
                    newParams[key] = undefined!;
                } else if (param.every((innerParam) => innerParam != null && typeof innerParam === 'object' && 'key' in innerParam)) {
                    newParams[key] = param.map((param) => Number(param.key)) as any;
                }
            }
        }

        return newParams;
    };
}

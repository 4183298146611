import {ServerUtils} from "../../../../core/utils/serverUtils";
import {InventoryMovementRecord} from "../../../../server";
import {isDefined} from "../../../../shared/util/utils";
import {ListInventoryMovementsFilters} from "./inventoryMovementsApi";

export class InventoryMovementsApiUtils {
    static createRequestFilters = (params: ListInventoryMovementsFilters) => {
        const filters: string[] = ServerUtils.createRequestFilters<InventoryMovementRecord & { 'instances.instanceId': any }>([
            params.eventType && ['eventType', 'IN', params.eventType],
            params.eventDate && params.eventDate[0] && ['eventDate', 'GE', params.eventDate[0].valueOf()],
            params.eventDate && params.eventDate[1] && ['eventDate', 'LE', params.eventDate[1].valueOf()],
            params.lastUpdateAuthorId && ['lastUpdateAuthorId', 'EQ', String(params.lastUpdateAuthorId)],
            params.productId && ['productId', 'EQ', String(params.productId)],
            params.variantId && ['variantId', 'EQ', String(params.variantId)],
            params.instanceId && [
                'instances.instanceId',
                'IN',
                Array.isArray(params.instanceId) ? params.instanceId : [params.instanceId],
            ],
            params.totalSum && isDefined(params.totalSum[0]) && ['totalSum', 'GE', Math.round(params.totalSum[0] * 100)],
            params.totalSum && isDefined(params.totalSum[1]) && ['totalSum', 'LE', Math.round(params.totalSum[1] * 100)],
            params.counterpartyId && ['counterpartyId', 'EQ', String(params.counterpartyId)],
            params.projectId && ['projectId', 'EQ', String(params.projectId)],
            isDefined(params.outOfOrder) && ['outOfOrder', 'EQ', params.outOfOrder],
            isDefined(params.causedByRenter) && ['causedByRenter', 'EQ', params.causedByRenter],
            params.authorId && ['lastUpdateAuthorId', 'EQ', params.authorId],
            params.screenLeft && ['eventDate', 'GE', params.screenLeft],
            params.screenRight && ['eventDate', 'LE', params.screenRight],
        ]);
        return filters;
    };

    static getSortBy = (sortBy:string|undefined) => {
        return sortBy === 'instances' ? 'instances.instanceId' : sortBy;
    };
}

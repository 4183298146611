import React, { FC } from 'react';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { CounterpartyCreateModal } from './CounterpartyCreateModal';
import { editRenterModalFilters } from './CounterpartyCreateModalData';
import { ModalProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { RenterInfoRead } from '../../../../../../server';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export interface CounterpartyEditModalNewProps extends ModalProps<RenterInfoRead> {
    entity?: RenterInfoRead | null;
}

export const CounterpartyEditModalNew: FC<CounterpartyEditModalNewProps> = (props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    return (
        <CounterpartyCreateModal
            businessAccountId={businessAccountId}
            copyMode={false}
            editMode={true}
            filters={editRenterModalFilters}
            initialValues={props.entity}
            modalName={'edit-renter'}
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
            title={localize(LocalizationEnum.PAGE__RENTERS__FORM__FORM_TITLE__EDIT)}
            validateAfterCreate={false}
        />
    );
};

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {TableColumn} from "../../../components/grid/Table";
import {GridColumnCreator} from "../../../components/grid/gridColumnCreator";
import {isDefined} from "../../../shared/util/utils";

export const tblColumnsLastUpdateDate = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.LAST_UPDATE_DATE) {
        return (
            GridColumnCreator.createLastUpdateDateColumn<Record>({
                title: column.title,
                dataIndex: column.dataIndex,
                minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
            })
        );
    } else {
        throw Error('error');
    }
};

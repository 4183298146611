import React, { FC } from 'react';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconUser } from '../../../../../../../../components/icons';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { ActivityFramesCalendarItem } from '../../../../../components/Calendar/types/items';

export const AssigneeGroupLine: FC<GroupItemProps<ActivityFramesCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { record } = groupItem;
    const { assigneeShortName, assigneeId } = record;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const link = assigneeId ? `/${businessAccountId}/users/${assigneeId}` : undefined;

    return (
        <GroupLine
            IconComponent={IconUser}
            displayType={displayType}
            title={{
                text: assigneeShortName ?? 'Ответственный не указан',
                link: link,
            }}
        />
    );
};

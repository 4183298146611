import React, {ComponentType, CSSProperties, FC, ReactNode} from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../../../shared/reducers/permissions.reducer';
import { DashboardWidgetLoader } from '../../components/loader/DashboardWidgetLoader/DashboardWidgetLoader';
import { DashboardWidgetHeaderIconBlock } from '../../components/header/components/IconBlock/DashboardWidgetHeaderIconBlock';
import { DashboardWidgetBaseCurrency } from '../../components/currency/DashboardWidgetBaseCurrency/DashboardWidgetBaseCurrency';
import { Icon } from 'antd';
import { IconBriefcase, IconDollySolid, IconUsers } from '../../../../../../../components/icons';
import classNames from 'classnames';
import { useLinksCallbacks } from './hooks/useLinksCallbacks';

interface DashboardWidgetBaseLinksProps {
    color: string;
    elementsCount: number;
    fill?: boolean;
    icon: ComponentType;
    instancesCount: number;
    sum: number;
    title: string | ReactNode;
    loading: boolean | undefined;
    isSubrent: boolean;
    style?: CSSProperties;
    XXL?: boolean;
}

export const DashboardWidgetBaseLinks: FC<DashboardWidgetBaseLinksProps> = (props) => {
    const { color, elementsCount, fill, icon, instancesCount, sum, title, loading, isSubrent } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const { onDebtOfRenterClick, onDebtToSupplierClick, onProjectDebtSumClick, onSubrentDebtSumClick } = useLinksCallbacks();

    if (loading) return <DashboardWidgetLoader />;

    const isIconBlockDisabled = elementsCount === 0 || (elementsCount[0] === 0 && elementsCount[1] === 0);

    return (
        <div className={'rr-dashboard-info-panel-link-2'} style={props.XXL ? {height: '100%'} : undefined}>
            <div style={{ display: 'flex', flexGrow: 1, ...props.style }}>
                <DashboardWidgetHeaderIconBlock color={color} isDisabled={isIconBlockDisabled} icon={icon} fill={fill} />
                <div
                    style={{
                        color: 'rgb(87, 89, 98)',
                        height: isCanViewFinancialData ? undefined : 108,
                    }}
                    className="rr-dashboard-info-panel-div"
                >
                    <div>{title}</div>
                    <DashboardWidgetBaseCurrency elementsCount={elementsCount} sum={sum} />

                    <div style={{ display: 'flex' }}>
                        <div
                            className={classNames('rr-dashboard-info-panel-flex-block', {
                                'rr-dashboard-info-panel-link': elementsCount !== 0,
                            })}
                            style={{ display: 'flex', flexWrap: 'nowrap' }}
                            onClick={(e) => {
                                if (elementsCount === 0) return;
                                e.preventDefault();
                                e.stopPropagation();
                                if (!isSubrent) onDebtOfRenterClick();
                                else onDebtToSupplierClick();
                            }}
                        >
                            <Icon
                                component={IconUsers}
                                className={'rr-dashboard-info-panel-icon'}
                                style={{ color: `${elementsCount === 0 ? '#aaaaaa' : `#343f9a`}` }}
                            />
                            {elementsCount === 0 ? (
                                <span className="rr-dashboard-info-panel-right-span-1">{elementsCount} </span>
                            ) : (
                                <span className="rr-dashboard-info-panel-right-span-2">{elementsCount} </span>
                            )}
                        </div>
                        <div
                            className={classNames('rr-dashboard-info-panel-flex-block', {
                                'rr-dashboard-info-panel-link': elementsCount !== 0,
                            })}
                            style={{ display: 'flex', flexWrap: 'nowrap' }}
                            onClick={(e) => {
                                if (elementsCount === 0) return;
                                e.preventDefault();
                                e.stopPropagation();
                                if (!isSubrent) onProjectDebtSumClick();
                                else onSubrentDebtSumClick();
                            }}
                        >
                            <Icon
                                component={!isSubrent ? IconBriefcase : IconDollySolid}
                                className="left-block-icon rr-dashboard-info-panel-icon"
                                style={{
                                    marginTop: 1,
                                    color: `${elementsCount === 0 ? '#aaaaaa' : isSubrent ? '#ffb822' : `#343f9a`}`,
                                }}
                            />
                            {elementsCount === 0 ? (
                                <span className="rr-dashboard-info-panel-right-span-1">{instancesCount} </span>
                            ) : (
                                <span className="rr-dashboard-info-panel-right-span-2">{instancesCount} </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

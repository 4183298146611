import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { push, replace } from 'connected-react-router';
import { Spin } from '../../../../components';
import { columns } from './documentTemplatesModuleListColums';
import debounce from 'lodash/debounce';
import { getPathFromState, getStateFromPath2 } from '../../../../shared/util/utils';
import { filters } from './documentTemplatesModuleListFilters';
import { showNotification } from '../../../../components/notification/showNotification';
import { IntlShape } from 'react-intl';
import { DocumentTemplateRecord } from '../../../../server/api';
import {
    deleteEntity,
    downloadTemplate,
    hideShow,
    initialParamsState,
    loadEntities,
    reset,
    uploadTemplate,
} from './reducers/documentTemplates.reducer';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { Grid } from '../../../../components/grid/Grid';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';
import { PageUtils } from '../../../../shared/util/pageUtils';
import { downloadDataFromDocx, downloadDataFromJasper } from '../../../../shared/util/downloadDataFromExcel';
import { UploadFile } from 'antd/lib/upload/interface';
import { DocumentTemplatesModuleUtils } from './documentTemplatesModuleUtils';

interface IProps extends StateProps, DispatchProps {
    intl: IntlShape;
}

interface IState {}

const GRID_NAME = 'settings-documentTemplates';

class DocumentTemplatesModuleList extends React.Component<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters;
    private initialValues;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['search', 'hideStandardTemplates', 'documentType', 'sortBy', 'sortOrder'];

    constructor(props: IProps) {
        super(props);
        this.state = {};
        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = () => {
        console.log('DocumentTemplatesModuleList componentDidMount()');
        this.initialValues = this.props.pageParams;
        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    };

    componentDidUpdate = (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(GRID_NAME);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadUsersModule();
        if (!this.fromFilters) {
            this.filtersForm.setFieldsValue({ ...initialParamsState, ...this.props.pageParams });
        }
        this.fromFilters = false;
    };

    componentWillUnmount = () => {
        console.log('DocumentTemplatesModuleList componentWillUnmount()');
        this.props.reset();
    };

    loadUsersModule = async () => {
        let res = await this.props.loadEntities(this.props.businessAccountId);
        if (res instanceof Error) {
            showNotification('error', 'Не удалось загрузить список шаблонов');
        }
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
    };

    onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                limit: size,
                page: 1,
            })
        );
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        this.fromFilters = true;
        data.page = 1;
        setGridStorageDataFilters(GRID_NAME, data);
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
    };

    onItemAction = async (item: DocumentTemplateRecord, action: string, data: any) => {
        if (action === 'edit') {
            this.props.push(
                getPathFromState(`/${this.props.businessAccountId}/settings/documentTemplates/${item.id}/edit`, this.props.location.search)
            );
        } else if (action === 'delete') {
            showConfirm(this.props.intl, 'Вы действительно хотите удалить шаблон?', undefined, async () => {
                let result = await this.props.deleteEntity(this.props.businessAccountId, item.id, item.common);
                if (!(result instanceof Error)) {
                    showNotification('success', 'Шаблон удален');
                    this.loadUsersModule();
                } else {
                    // TODO Мб норм сообщение об ошибке норм выводить
                    showNotification('error', 'Шаблон не удален');
                }
            });
        } else if (action === 'upload') {
            let file = data as UploadFile;
            if (file.error) {
                showNotification('error', 'Файл не загружен', file.error);
            } else {
                const result = await this.props.uploadTemplate(
                    this.props.businessAccountId,
                    item.id,
                    file,
                    item.businessVersion,
                    item.common
                );
                if (!(result instanceof Error)) {
                    showNotification('success', 'Файл загружен');
                    this.loadUsersModule();
                } else {
                    // TODO Мб норм сообщение об ошибке норм выводить
                    showNotification('error', 'Файл не загружен');
                }
            }
        } else if (action === 'download') {
            const result = await this.props.downloadTemplate(this.props.businessAccountId, item.id, item.common);
            if (!(result instanceof Error)) {
                const fileExt = DocumentTemplatesModuleUtils.getFileExtensionByCode(item.documentType);
                if (fileExt === 'docx') downloadDataFromDocx((result as any).value.data, item.shortName);
                else if (fileExt === 'zip') downloadDataFromJasper((result as any).value.data, item.shortName);
            } else {
                // TODO Мб норм сообщение об ошибке норм выводить
                showNotification('error', 'Ошибка');
            }
        } else if (action === 'hide' || action === 'show') {
            const result = await this.props.hideShow(
                this.props.businessAccountId,
                item.id,
                item.common,
                item.businessVersion,
                action === 'hide'
            );
            if (!(result instanceof Error)) {
                showNotification('success', 'Шаблон успешно изменен');
                this.loadUsersModule();
            } else {
                // TODO Мб норм сообщение об ошибке норм выводить
                showNotification('error', 'Шаблон не изменен');
            }
        }
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            documentType: undefined,
            hideStandardTemplates: undefined,
        };
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(GRID_NAME, data);
    };

    render() {
        console.log('DocumentTemplatesModuleList render()', this.props);

        let { entities, loading, filteredCount } = this.props;

        return (
            <Spin spinning={loading}>
                <Grid
                    filtersData={filters}
                    filtersInitialValues={this.initialValues}
                    filtersCurrentValues={this.props.pageParams}
                    filtersDefaultValues={initialParamsState}
                    filtersGetFiltersFormRef={this.getFiltersForm}
                    filtersOnChange={this.onFiltersChange}
                    filtersResetFiltersCb={this.resetFilters}
                    ref={(ref) => {
                        if (!this.grid) this.grid = ref;
                    }}
                    onSortedChange={this.onSortedChange}
                    onPageChanged={this.onPageChanged}
                    onPageSizeChanged={this.onPageSizeChanged}
                    onRowAction={this.onItemAction}
                    filtered={filteredCount}
                    pageSize={this.props.pageParams.limit}
                    currentPage={this.props.pageParams.page}
                    columns={columns}
                    data={entities}
                    indexFieldName={'id'}
                    entityType={'customField'}
                    selectable={false}
                    defaultSorted={this.props.pageParams.sortBy}
                    defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                    gridName={GRID_NAME}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.documentTemplates.entities,
        loading: storeState.documentTemplates.loading,
        location: storeState.router.location,
        pageParams: storeState.documentTemplates.params,
        filteredCount: storeState.documentTemplates.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
    };
};

const mapDispatchToProps = { loadEntities, reset, push, deleteEntity, replace, uploadTemplate, downloadTemplate, hideShow };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplatesModuleList);

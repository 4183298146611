import React, { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import moment from 'moment';

interface RentDelayShortageBorderProps {
    width: number;
}

export const RentDelayShortageBorder: FC<RentDelayShortageBorderProps> = (props) => {
    const { width } = props;
    const { item } = useContext(ElementItemContext);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const {
        problemsAndWarnings: { orderShortage },
    } = item;

    const now = +moment();

    const isNowBetweenBoundaries = now > visibleTimeStart && now < visibleTimeEnd;
    let isInnerBoundaryEqualsOuter = false;

    const outerColor = orderShortage ? CalendarColors.staticColors.WARNING_COLOR : CalendarColors.staticColors.DELAY_COLOR;
    const outerBorder = `3px dashed ${outerColor}`;

    const isItemStartsAfterLeftBoundary = +item.start_time > visibleTimeStart;
    const isItemEndsBeforeRightBoundary = +item.end_time < visibleTimeEnd;
    const isNowAfterLeftBoundary = now > visibleTimeStart;

    const isInnerBoundaryVisible = isNowBetweenBoundaries || (isInnerBoundaryEqualsOuter && isItemEndsBeforeRightBoundary);

    const innerBorder = `2px solid ${CalendarColors.staticColors.WARNING_COLOR}`;

    return (
        <>
            <div
                className={'calendar-line-border'}
                style={{
                    border: outerBorder,
                    width,
                }}
            />
            {isNowAfterLeftBoundary && width > 4 && (
                <div
                    className={'calendar-line-border'}
                    style={{
                        inset: 2,
                        border: innerBorder,
                        borderLeft: isItemStartsAfterLeftBoundary ? innerBorder : 'none',
                        borderRight: isInnerBoundaryVisible ? innerBorder : 'none',
                        width: width - 4,
                    }}
                />
            )}
        </>
    );
};

import { FC } from 'react';
import { Switch } from 'antd';
import './CrewMemberBreakdown.less';
import { PageUrlParamsObject } from '../../../../../../../../core/hooks/urlParams/types';
import { WorkPlanningsCalendarFilters } from '../../../../../tabs/workPlannings/filters/workPlanningsCalendarFilters.types';

interface CrewMemberBreakdownProps {
    pageParamsObject: PageUrlParamsObject<WorkPlanningsCalendarFilters>;
}

export const CrewMemberBreakdown: FC<CrewMemberBreakdownProps> = (props) => {
    const { pageParamsObject } = props;
    const { pageParams, updatePageParams } = pageParamsObject;
    const { isSplitted } = pageParams;

    const onChange = (checked: boolean) => {
        updatePageParams({
            isSplitted: checked ? true : undefined,
        });
    };

    return (
        <div className={'crew-member-breakdown'}>
            <span>Разбивка по работникам</span>
            <Switch checked={isSplitted} onChange={onChange} />
        </div>
    );
};

import { useAppSelector } from '../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { useMemo } from 'react';

export const useExcludeColumnsAndFields = (): {
    excludeColumns?: [string, string];
    filtersExcludeFields?: [string, string];
} => {
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    return useMemo(() => {
        if (!canViewFinancialData) {
            return {
                excludeColumns: ['finalTotalPrice', 'totalDiscount'],
                filtersExcludeFields: ['discount', 'finalTotalPrice'],
            };
        } else {
            return {
                excludeColumns: undefined,
                filtersExcludeFields: undefined,
            };
        }
    }, [canViewFinancialData]);
};

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../shared/reducers';
import { connect } from 'react-redux';
import { showConfirm } from '../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { goBack } from 'connected-react-router';
import { paymentMethodModalFormFields } from '../paymentMethodModalFormFields';
import { updateModalFilters } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { ModalActionData } from '../../../../../../components/modalForm/utils';
import { PaymentMethodModalFormData } from '../paymentMethodModalFormFields.types';
import { PaymentMethodInfoCreate } from '../../../../../../server';
import { clearPaymentMethod, createPaymentMethod } from '../../reducers/paymentMethod/paymentMethod.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    rootPath: string;
    urlSearchParams?: URLSearchParams;
}

export class _PaymentMethodCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: paymentMethodModalFormFields,
    };

    onOk = (data: unknown) => {
        const formData = data as PaymentMethodModalFormData;

        const infoCreate: PaymentMethodInfoCreate = {
            name: formData.name,
            isDefault: formData.isDefault,
            comment: formData.comment,
        };

        this.props.createPaymentMethod(
            {
                businessAccountId: this.props.businessAccountId,
                infoCreate: infoCreate,
            },
            this.props.rootPath,
            this.props.urlSearchParams
        );
    };

    onCancel = async (isFieldsTouched: boolean) => {
        const yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.goBack();
            //this.props.clearPaymentMethod();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.paymentMethod.updating,
    updatingError: storeState.paymentMethod.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    createPaymentMethod,
    clearPaymentMethod,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const PaymentMethodCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_PaymentMethodCreateModal));

import React, { FC } from 'react';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../localization';
import { Select } from 'antd';
import { CustomSelect } from '../../../../../components';
import { useIntl } from 'react-intl';

interface PeriodSelectProps {
    period: string | undefined;
    onSetPeriod: (value?: string | undefined) => void;
}

export const PeriodSelect: FC<PeriodSelectProps> = (props) => {
    const { period, onSetPeriod } = props;
    const intl = useIntl();

    return (
        <CustomSelect
            style={{ width: 170 }}
            onChange={(value) => onSetPeriod(value as string)}
            className={'rr-input-gray'}
            placeholder={localizeIntl(intl, LocalizationEnum.PAGE__CALENDAR__ANY_TIME) as string}
            allowClear
            defaultValue={period}
        >
            <Select.Option value="00">{localize(LocalizationEnum.PAGE__DASHBOARD__DATE_PICKER_OPTION_TODAY_OR_EARLIER)}</Select.Option>
            <Select.Option value="01">{localize(LocalizationEnum.ASPECT__TIME__YESTERDAY)}</Select.Option>
            <Select.Option value="02">{localize(LocalizationEnum.ASPECT__TIME__TODAY)}</Select.Option>
            <Select.Option value="03">{localize(LocalizationEnum.ASPECT__TIME__TOMORROW)}</Select.Option>
            <Select.Option value="06">Послезавтра</Select.Option>
            <Select.Option value="04">{localize(LocalizationEnum.ASPECT__TIME__WEEK, undefined, { value: 1 })}</Select.Option>
            <Select.Option value="05">{localize(LocalizationEnum.ASPECT__TIME__MONTH, undefined, { value: 1 })}</Select.Option>
        </CustomSelect>
    );
};

import {
    BusinessAccountInfoUpdate,
    BusinessAccountRecord,
    BusinessAccountStateCodeEnum,
    BusinessAccountTypeCodeEnum,
    TariffCodeEnum,
} from '../../../server';
import { LocalizationEnum, localize } from '../../../localization';
import ModalForm from '../../../components/modalForm/ModalForm';
import { FormFieldsGroup, FormItemType } from '../../../components/dynamicForm/DynamicForm';
import { Icon } from 'antd';
import { IconClone, IconDollarSignSolid, IconHourglassHalfSolid, IconMicroscopeSolid } from '../../../components/icons';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ReactNode, useMemo, useState } from 'react';
import moment from 'moment';
import { showNotification } from '../../../components/notification/showNotification';

interface Props {
    data: BusinessAccountRecord;
    onClose?: () => void;
    onOk?: (data: BusinessAccountInfoUpdate) => void;
    err: ReactNode | undefined;
    loading: boolean;
}

interface FormData {
    typeCode: BusinessAccountTypeCodeEnum;
    trialPeriodProlongedUntil?: Date;
    tariff: TariffCodeEnum;
    stateCode: BusinessAccountStateCodeEnum;
    allRolesUserCountLimit: number;
    managerRolesUserCountLimit: number;
}

const BusinessAccountEditingModal = ({ data, onClose, onOk, err, loading }: Props) => {
    const [currentType, setCurrentType] = useState<BusinessAccountTypeCodeEnum>(data.typeCode);

    const formFields: FormFieldsGroup[] = useMemo(
        () => [
            {
                fields: [
                    {
                        label: 'Тип',
                        id: 'typeCode',
                        type: FormItemType.Select,
                        required: true,
                        onChange: (value: BusinessAccountTypeCodeEnum) => {
                            setCurrentType(value);
                        },
                        values: [
                            { name: 'Песочница', value: BusinessAccountTypeCodeEnum.SANDBOX },
                            { name: 'Демо шаблон', value: BusinessAccountTypeCodeEnum.DEMOTEMPLATE },
                            { name: 'Триал', value: BusinessAccountTypeCodeEnum.TRIAL },
                            { name: 'Коммерческий', value: BusinessAccountTypeCodeEnum.PRODUCTION },
                        ].map((item) => {
                            let icon;
                            if (item.value === BusinessAccountTypeCodeEnum.DEMOTEMPLATE)
                                icon = <Icon component={IconClone} style={{ fontSize: 21, color: '#343f9a', marginRight: 12 }} />;
                            else if (item.value === BusinessAccountTypeCodeEnum.SANDBOX)
                                icon = <Icon component={IconMicroscopeSolid} style={{ fontSize: 21, color: '#a0a0a0', marginRight: 12 }} />;
                            else if (item.value === BusinessAccountTypeCodeEnum.TRIAL)
                                icon = (
                                    <Icon component={IconHourglassHalfSolid} style={{ fontSize: 21, color: '#fc8c2f', marginRight: 12 }} />
                                );
                            else if (item.value === BusinessAccountTypeCodeEnum.PRODUCTION)
                                icon = <Icon component={IconDollarSignSolid} style={{ fontSize: 21, color: '#34bfa3', marginRight: 12 }} />;
                            return {
                                name: (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {icon}
                                        {item.name}
                                    </div>
                                ),
                                value: item.value,
                            };
                        }),
                    },
                    currentType === BusinessAccountTypeCodeEnum.TRIAL
                        ? {
                              label: 'Триальный период продлен до',
                              id: 'trialPeriodProlongedUntil',
                              type: FormItemType.Date,
                          }
                        : {
                              label: 'Тариф',
                              id: 'tariff',
                              type: currentType === BusinessAccountTypeCodeEnum.PRODUCTION ? FormItemType.Select : FormItemType.Hidden,
                              required: true,
                              values: [
                                  { name: 'Триал', value: TariffCodeEnum.TRIAL },
                                  { name: 'Старт', value: TariffCodeEnum.START },
                                  { name: 'Развитие', value: TariffCodeEnum.ECONOMY },
                                  { name: 'Премиум', value: TariffCodeEnum.BUSINESS },
                                  { name: 'Корпоративный', value: TariffCodeEnum.ENTERPRISE },
                              ],
                          },
                    {
                        label: 'Статус',
                        id: 'stateCode',
                        type: FormItemType.Select,
                        disabled: true,
                        required: true,
                        values: [
                            { name: 'Активный', value: BusinessAccountStateCodeEnum.ACTIVE },
                            { name: 'Заблокирован', value: BusinessAccountStateCodeEnum.BLOCKED },
                        ].map((item) => {
                            return {
                                name: (
                                    <>
                                        <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                                        <span className="calendar-multi-select">{item.name}</span>{' '}
                                    </>
                                ),
                                value: item.value,
                            };
                        }),
                    },
                    {
                        label: 'Лимит обычных',
                        id: 'allRolesUserCountLimit',
                        type: FormItemType.Integer,
                        required: true,
                        minLength: 0,
                        validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                            cb(
                                value < +form.getFieldValue('managerRolesUserCountLimit')
                                    ? 'Лимит суперпользователей не должен превосходить общий лимит пользователей.'
                                    : undefined
                            );
                        },
                    },
                    {
                        label: 'Лимит суперпользователей',
                        id: 'managerRolesUserCountLimit',
                        type: FormItemType.Integer,
                        required: true,
                        minLength: 0,
                        validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                            cb(
                                value > +form.getFieldValue('allRolesUserCountLimit')
                                    ? 'Лимит суперпользователей не должен превосходить общий лимит пользователей.'
                                    : undefined
                            );
                        },
                    },
                ].filter(Boolean),
            },
        ],
        [currentType]
    );

    return (
        <ModalForm
            visible
            title="Редактирование компании"
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            cancelButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
            onOk={(formData: FormData) => {
                const { allRolesUserCountLimit, managerRolesUserCountLimit, typeCode, tariff, trialPeriodProlongedUntil } = formData;
                const newData: BusinessAccountInfoUpdate = {
                    id: data.id,
                    allRolesUserCountLimit,
                    managerRolesUserCountLimit,
                    tariff: typeCode === BusinessAccountTypeCodeEnum.PRODUCTION ? tariff : TariffCodeEnum.TRIAL,
                    typeCode,
                    trialPeriodProlongedUntil: trialPeriodProlongedUntil
                        ? moment(trialPeriodProlongedUntil).endOf('day').toDate()
                        : undefined,
                    businessVersion: data.businessVersion,
                };
                if (newData.allRolesUserCountLimit < newData.managerRolesUserCountLimit) {
                    showNotification('error', 'Лимит суперпользователей не должен превосходить общий лимит пользователей.');
                } else {
                    onOk?.(newData);
                }
            }}
            error={err}
            saving={loading}
            onCancel={onClose}
            formFieldsData={formFields}
            initialValues={{
                typeCode: data.typeCode,
                tariff: data.tariff,
                stateCode: data.stateCode,
                allRolesUserCountLimit: data.allRolesUserCountLimit,
                managerRolesUserCountLimit: data.managerRolesUserCountLimit,
            }}
        />
    );
};

export default BusinessAccountEditingModal;

import React, { FC, memo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useListWorkPlanningsGroupByCrewMemberQuery, useListWorkPlanningsQuery } from './api/workPlanningsCalendar.api';
import { CalendarVisibleItemsLimit } from '../../components/Calendar/utils/data/calendarConstants';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { useCalendarQueryParams } from '../../components/Calendar/hooks/useCalendarQueryParams';
import { workPlanningsCalendarFiltersDescription } from './filters/workPlanningsCalendarFilters.description';
import { getPathFromState } from '../../../../../shared/util/utils';
import { push } from 'connected-react-router';
import { useFiltersChanges } from './hooks/useFiltersChanges';
import { workPlanningsCalendarScreenLeft, workPlanningsCalendarScreenRight } from './reducers/workPlanningsCalendar.selectors';
import { WorkPlanningsDrawer } from '../../../workPlannings/drawer/workPlanningsDrawer';
import { workPlanningsCalendarFilters } from './filters/workPlanningsCalendarFilters';
import { ProfessionDrawer } from '../../../crew/tabs/professions/drawer/components/ProfessionDrawer/ProfessionDrawer';
import { useProfessionEntityActions } from '../../../crew/tabs/professions/hooks/useProfessionEntityActions';
import { useLocation } from 'react-router-dom';
import { Route } from 'react-router';
import { WorkPlanningsEditModalWrapper } from '../../../workPlannings/modal/edit/workPlanningsEditModalWrapper';
import { workPlanningEntitySelector } from '../../../workPlannings/reducers/workPlanning/workPlanning.selectors';
import { WorkPlanningsCalendarFilters } from './filters/workPlanningsCalendarFilters.types';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import { PageUrlParamsObject } from '../../../../../core/hooks/urlParams/types';

import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import Spin from '../../../../../components/spin/spin';
import { CalendarFilters } from '../../components/CalendarFilters/CalendarFilters';
import { CalendarBottomNavigation } from '../../components/СalendarBottomNavigation/СalendarBottomNavigation';
import { Card } from 'antd';
import { WorkPlanningsBaseCalendar } from './widgets/BaseCalendar/WorkPlanningsBaseCalendar';
import { URLDrawerParams } from '../../../../../components/page/entityGrid/types/params';
import { WorkPlanningsSplittedCalendar } from './widgets/SplittedCalendar/WorkPlanningsSplittedCalendar';
import { useWorkPlanningsCalendarGroups } from './hooks/useCalendarGroups';
import { CalendarId } from '../../components/Calendar/types/items';
import { useLazyGetWorkPlanningByIdQuery } from '../../../workPlannings/api/workPlannings.api';

export const workPlanningsCalendarPageName = CalendarPageTabsEnum.WORK_PLANNINGS;

interface WorkPlanningsCalendarProps {}

export const WorkPlanningsCalendar: FC<WorkPlanningsCalendarProps> = memo(() => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const screenLeft = useAppSelector(workPlanningsCalendarScreenLeft);
    const screenRight = useAppSelector(workPlanningsCalendarScreenRight);
    const currentEntity = useAppSelector(workPlanningEntitySelector);
    const [visibleItemsLimit, setVisibleItemsLimit] = useState<CalendarVisibleItemsLimit>(CalendarVisibleItemsLimit.FIRST_LIMIT);
    const [chosenWorkId, setChosenWorkId] = useState<CalendarId | undefined>(undefined);
    const [chosenProfessionId, setChosenProfessionId] = useState<number | undefined>(undefined);

    const pageParamsObject = usePageURLParams<WorkPlanningsCalendarFilters & URLDrawerParams>({
        paramsDescription: workPlanningsCalendarFiltersDescription,
        pageName: workPlanningsCalendarPageName,
    });
    const { pageParams } = pageParamsObject;

    const queryParams = useCalendarQueryParams({
        ...pageParams,
    });

    const isCalendarSplitted = pageParams.isSplitted;

    const {
        data: workPlanningsList,
        isFetching: baseCalendarFetching,
        isSuccess: baseCalendarIsSuccess,
    } = useListWorkPlanningsQuery(
        {
            businessAccountId,
            params: queryParams,
            visibleItemsLimit,
        },
        {
            skip: isCalendarSplitted,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const {
        data: workPlanningsGroupsData,
        isFetching: splittedCalendarFetching,
        isSuccess: splittedCalendarIsSuccess,
    } = useListWorkPlanningsGroupByCrewMemberQuery(
        {
            params: queryParams,
        },
        {
            skip: !isCalendarSplitted,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [getWorkPlanningById, { isLoading: isGetWorkPlanningByIdLoading }] = useLazyGetWorkPlanningByIdQuery();

    const workPlanningsCalendarGroups = useWorkPlanningsCalendarGroups((id) => {
        setChosenProfessionId(id);
    });

    const professionActions = useProfessionEntityActions({
        rootPath: `/${businessAccountId}/crew/professions`,
        urlSearchParams: new URLSearchParams(),
    });

    const pageName = workPlanningsCalendarPageName;

    const chosenWorkPlanningId =
        chosenWorkId != null ? (typeof chosenWorkId === 'number' ? chosenWorkId : +chosenWorkId.split('_')[0]) : undefined;

    const isBaseLoading = baseCalendarFetching || !baseCalendarIsSuccess;
    const isSplittedLoading = splittedCalendarFetching || !splittedCalendarIsSuccess;

    return (
        <>
            <Card>
                <Spin wrapperClassName={'clickable-spin'} spinning={isCalendarSplitted ? isSplittedLoading : isBaseLoading} delay={0}>
                    <CalendarFilters
                        pageName={pageName}
                        pageParamsDescription={workPlanningsCalendarFiltersDescription}
                        pageParamsObject={pageParamsObject as PageUrlParamsObject<WorkPlanningsCalendarFilters>}
                        filtersData={workPlanningsCalendarFilters}
                        useFiltersChanges={useFiltersChanges}
                        hiddenFieldsForCount={['isSplitted']}
                    />
                    {isCalendarSplitted ? (
                        <WorkPlanningsSplittedCalendar
                            workPlanningsGroupsData={workPlanningsGroupsData}
                            workPlanningsCalendarGroups={workPlanningsCalendarGroups}
                            pageParamsObject={pageParamsObject}
                            isLoading={isSplittedLoading}
                            setChosenWorkId={setChosenWorkId}
                            setChosenProfessionId={setChosenProfessionId}
                        />
                    ) : (
                        <WorkPlanningsBaseCalendar
                            pageParamsObject={pageParamsObject}
                            setVisibleItemsLimit={setVisibleItemsLimit}
                            visibleItemsLimit={visibleItemsLimit}
                            workPlanningsCalendarGroups={workPlanningsCalendarGroups}
                            isLoading={isBaseLoading}
                            setChosenWorkId={setChosenWorkId}
                            workPlanningsList={workPlanningsList}
                        />
                    )}
                    <CalendarBottomNavigation
                        capture={pageParams.capture}
                        pageName={pageName}
                        pageParams={pageParams}
                        positionTop={false}
                        screenLeft={screenLeft}
                        screenRight={screenRight}
                    />
                </Spin>
            </Card>
            {chosenWorkPlanningId && (
                <>
                    <WorkPlanningsDrawer
                        workPlanningsId={chosenWorkPlanningId}
                        onClose={() => setChosenWorkId(undefined)}
                        editWorkPlanning={(entity) => {
                            getWorkPlanningById({
                                businessAccountId,
                                entityId: chosenWorkPlanningId,
                            });

                            dispatch(
                                push(
                                    getPathFromState(location.pathname, location.search, {
                                        id: entity.id,
                                        modal: 'edit',
                                    })
                                )
                            );
                        }}
                        professionDrawerProps={{
                            onCalendarClick: () => {
                                setChosenWorkId(undefined);
                                setChosenProfessionId(undefined);
                            },
                        }}
                        isEntityActionsLoading={isGetWorkPlanningByIdLoading}
                    />
                    {currentEntity && pageParams.modal === 'edit' && pageParams.id && (
                        <Route
                            exact
                            path={location.pathname}
                            render={() => <WorkPlanningsEditModalWrapper entity={currentEntity} projectId={currentEntity.projectId} />}
                        />
                    )}
                </>
            )}
            {chosenProfessionId && (
                <ProfessionDrawer
                    professionId={chosenProfessionId}
                    onClose={() => setChosenProfessionId(undefined)}
                    editProfession={professionActions.editAction}
                    deleteProfession={professionActions.deleteActionForDrawer}
                    archiveProfession={professionActions.archiveActionForDrawer}
                    onCalendarClick={() => setChosenProfessionId(undefined)}
                    isEntityActionsLoading={professionActions.isLoading}
                />
            )}
        </>
    );
});

import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { ProjectRecord, serverApi, SubrentRecord } from '../../../../server';
import { ServerUtils } from '../../../../core/utils/serverUtils';
import { useAppSelector } from '../../../../store/hooks';

export interface SubrentSelectProps extends BaseSelectBaseProps<SubrentRecord> {
    supplierId?: number;
}

const SubrentSelectFC = ({ supplierId, ...props }: SubrentSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listSubrents(businessAccountId, undefined, 0, [`id;IN;${ids}`]);
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        let filters = supplierId ? ServerUtils.createRequestFilters<ProjectRecord>([['renterId', 'EQ', supplierId]]) : undefined;
        const response = await serverApi.listSubrents(businessAccountId, limit, startFrom, filters, 'lastActivityDate', 'DESC', search);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.shortName,
                children: record.shortName,
            })}
        />
    );
};

export class SubrentSelect extends React.PureComponent<SubrentSelectProps> {
    render() {
        return <SubrentSelectFC {...this.props} />;
    }
}

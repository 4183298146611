import React, { CSSProperties, FC, useContext } from 'react';
import { ActivityFrameItemContext } from '../../../../../../../../context/CalendarItemContext';
import { LineIconCircle } from '../../../LineIconCircle/LineIconCircle';
import { StringUtils } from '../../../../../../../../../../../../../core/utils/stringUtils';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../../../../../../shared/reducers/system.reducer';
import { rentersPageUrlRoute } from '../../../../../../../../../../../renters/data/tabs';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';
import './ActivityFramesInnerContent.less';

import { RentersPageTabsEnum } from '../../../../../../../../../../../../../shared/constants/tabEnums';

interface ActivityFramesInnerContentProps {
    backgroundColor: string | undefined;
    sortByValue?: string;
}

export const ActivityFramesInnerContent: FC<ActivityFramesInnerContentProps> = (props) => {
    const { sortByValue, backgroundColor } = props;
    const { item, itemContext } = useContext(ActivityFrameItemContext);
    const { visibleTimeStart } = useContext(TimeRangeContext);
    const {
        elementType,
        record: { shortName, counterpartyShortName, counterpartyId, stateCode },
        start_time,
        isDraft
    } = item;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    let borderColor: CSSProperties['borderColor'] =
        elementType === 'activitySubrent' ? CalendarColors.activityFramesStateCodeMap[stateCode] : undefined;
    const borderStyle: CSSProperties['borderStyle'] = isDraft ? 'dashed' : 'solid';
    if(isDraft) borderColor = CalendarColors.staticColors.TEXT_COLOR;

    const link = `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${counterpartyId}`;

    const itemWidth = itemContext.dimensions.width;

    const isStartAfterLeftBoundary = +start_time > visibleTimeStart;

    return (
        <>
            <LineIconCircle
                backgroundColor={backgroundColor}
                itemWidth={itemWidth}
                isDraft={isDraft}
                isStatic={elementType === 'activitySubrent'}
                style={{
                    borderColor,
                    borderStyle,
                    visibility: isStartAfterLeftBoundary ? 'visible' : 'hidden',
                }}
            />
            <span className={'line-main-block-content'}>
                {StringUtils.deleteSpaces(shortName)}
                <span style={{ fontWeight: 400 }}>
                    {sortByValue !== 'renter' && (
                        <>
                            :{' '}
                            <Link to={link} className={'activity-frames-counterparty-link'}>
                                {counterpartyShortName}
                            </Link>
                        </>
                    )}
                </span>
            </span>
        </>
    );
};

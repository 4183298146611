import { CalendarGroupsObject } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import { CategoryGroupLine } from '../../components/GroupLine/variants/CategoryGroupLine';
import { RenterGroupLine } from '../../components/GroupLine/variants/RenterGroupLine';
import { ProjectGroupLine } from '../../components/GroupLine/variants/ProjectGroupLine';
import { ProductGroupLine } from '../../components/GroupLine/variants/ProductGroupLine';
import { StatusGroupLine } from '../../components/GroupLine/variants/StatusGroupLine';

export type ElementsCalendarSortValue = 'renter' | 'project' | 'product' | 'stateCode' | 'startDate' | 'endDate' | 'category';

export const elementsCalendarGroups: CalendarGroupsObject<ElementsCalendarSortValue> = {
    category: CategoryGroupLine,
    renter: RenterGroupLine,
    project: ProjectGroupLine,
    product: ProductGroupLine,
    stateCode: StatusGroupLine,
};

import { CalendarDisplayType } from '../../../../../components/Calendar/utils/data/calendarConstants';
import { useMemo } from 'react';
import { CalendarLineUtils } from '../../../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import { WorkPlanningGroupRecord } from '../../../../../../../../server';
import { CalendarLine, WorkPlanningsCalendarItem } from '../../../../../components/Calendar/types/items';

export type WorkPlanningsSplittedCalendarGroup = {
    id: number;
    title: string;
    height: number;
    groupRecord: WorkPlanningGroupRecord | undefined;
    itemId: number | string;
};

export const useCalendarGroups = (
    items: CalendarLine<WorkPlanningsCalendarItem>[],
    displayType: CalendarDisplayType
): WorkPlanningsSplittedCalendarGroup[] => {
    return useMemo(() => {
        if (items.length === 0) return [{ id: 1000, title: `group_end`, height: 1, itemId: 1000, groupRecord: undefined }];

        return items.map((item, index) => {
            const groupRecord = item.elementType === 'work' || item.elementType === 'empty' ? item.crewMemberGroup : undefined;

            return {
                id: index + 1,
                itemId: item.id,
                title: item.elementType === 'group' ? '' : groupRecord?.shortName ?? '',
                height: CalendarLineUtils.map.groupHeightFromDisplayType.base[displayType],
                groupRecord: groupRecord,
            };
        });
    }, [items, displayType]);
};

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { push, replace } from 'connected-react-router';
import { Spin } from '../../../../components';
import { columns } from './customFieldsModuleListColums';
import debounce from 'lodash/debounce';
import { getPathFromState, getStateFromPath2 } from '../../../../shared/util/utils';
import { filters } from './customFieldsModuleListFilters';
import { showNotification } from '../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { IntlShape } from 'react-intl';
import { CustomFieldInfoUpdate, CustomFieldRecord } from '../../../../server/api';
import { hideShow, initialParamsState, loadEntities, reset } from './reducers/customFields.reducer';
import { deleteEntity } from './reducers/customField.reducer';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { CustomFieldsFieldPopover } from './customFieldsFieldPopover';
import { Grid } from '../../../../components/grid/Grid';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';
import { PageUtils } from '../../../../shared/util/pageUtils';
import { CustomFieldActionType } from './customFieldsModuleData';
import { deleteSelectedCustomIdsFromStorage } from './utils/deleteSelectedCustomIdsFromStorage';

interface IProps extends StateProps, DispatchProps {
    intl: IntlShape;
}

interface IState {}

const GRID_NAME = 'settings-customFields';

class CustomFieldsModuleList extends React.Component<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters;
    private initialValues;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['search', 'hideStandardFields', 'typeCode', 'processingType', 'sortBy', 'sortOrder'];

    constructor(props: IProps) {
        super(props);
        this.state = {};
        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = () => {
        this.initialValues = this.props.pageParams;
        //this.loadUsersModule();
        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    };

    componentDidUpdate = (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(GRID_NAME);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadUsersModule();
        if (!this.fromFilters) {
            this.filtersForm?.setFieldsValue({ ...initialParamsState, ...this.props.pageParams });
        }
        this.fromFilters = false;
    };

    componentWillUnmount = () => {
        this.props.reset();
    };

    loadUsersModule = async () => {
        let res = await this.props.loadEntities(this.props.businessAccountId);
        if (res instanceof Error) {
            showNotification(
                'error',
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__LOAD_ENTITIES_ERROR_MESSAGE)
            );
        }
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, { sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' })
        );
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { limit: size, page: 1 }));
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        this.fromFilters = true;
        data.page = 1;
        setGridStorageDataFilters(GRID_NAME, data);
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
    };

    onItemAction = async (item: CustomFieldRecord, action: CustomFieldActionType) => {
        if (action === CustomFieldActionType.edit) {
            this.props.push(
                getPathFromState(`/${this.props.businessAccountId}/settings/customFields/${item.id}/edit`, this.props.location.search)
            );
        } else if (action === CustomFieldActionType.delete) {
            showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_CONFIRM_MESSAGE),
                undefined,
                async () => {
                    let result = await this.props.deleteEntity(this.props.businessAccountId, item.id);
                    if (!(result instanceof Error)) {
                        deleteSelectedCustomIdsFromStorage(item.id, this.props.businessAccountId, this.props.email);
                        showNotification(
                            'success',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_SUCCESS_MESSAGE)
                        );
                        setTimeout(() => {
                            this.loadUsersModule();
                        }, 1500);
                    } else {
                        // TODO Мб норм сообщение об ошибке норм выводить
                        showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
                    }
                }
            );
        } else if (action === CustomFieldActionType.hide || action === CustomFieldActionType.show) {
            let data: CustomFieldInfoUpdate = {
                id: item.id,
                businessVersion: item.businessVersion,
                comment: item.comment,
                businessAccountId: item.businessAccountId,
                shortName: item.shortName,
                financial: item.financial,
                internal: action === CustomFieldActionType.hide,
                processingType: item.processingType,
                multipleValues: item.multipleValues,
            };
            let result = await this.props.hideShow(this.props.businessAccountId, item.id, data);
            if (!(result instanceof Error)) {
                showNotification(
                    'success',
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__UPDATE_SUCCESS_MESSAGE)
                );
                this.loadUsersModule();
            } else {
                // TODO Мб норм сообщение об ошибке норм выводить
                showNotification('error', 'Поле не изменено');
            }
        }
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            typeCode: undefined,
            hideStandardFields: undefined,
            processingType: undefined,
        };
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(GRID_NAME, data);
    };

    render() {
        let { entities, loading, filteredCount, pageParams } = this.props;

        return (
            <Spin spinning={loading}>
                <Grid
                    filtersData={filters}
                    filtersInitialValues={this.initialValues}
                    filtersCurrentValues={pageParams}
                    filtersDefaultValues={initialParamsState}
                    filtersGetFiltersFormRef={this.getFiltersForm}
                    filtersOnChange={this.onFiltersChange}
                    filtersResetFiltersCb={this.resetFilters}
                    ref={(ref) => {
                        if (!this.grid) this.grid = ref;
                    }}
                    onSortedChange={this.onSortedChange}
                    onPageChanged={this.onPageChanged}
                    onPageSizeChanged={this.onPageSizeChanged}
                    onRowAction={this.onItemAction}
                    filtered={filteredCount}
                    pageSize={pageParams.limit}
                    currentPage={pageParams.page}
                    columns={columns}
                    data={entities}
                    indexFieldName={'id'}
                    entityType={'customField'}
                    selectable={false}
                    defaultSorted={pageParams.sortBy}
                    defaultSortDesc={pageParams.sortOrder === 'DESC'}
                    rowPopoverComponent={CustomFieldsFieldPopover}
                    gridName={GRID_NAME}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.customFields.entities,
        loading: storeState.customFields.loading,
        location: storeState.router.location,
        pageParams: storeState.customFields.params,
        filteredCount: storeState.customFields.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        email: storeState.oidc.user?.profile.email,
    };
};

const mapDispatchToProps = { loadEntities, reset, push, deleteEntity, replace, hideShow };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsModuleList);

import React, { FC } from 'react';
import { NumberInputDiscount } from '../../../../numberInputs';

import { ExportSettings } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

interface DownloadDocumentPopoverDiscountInputProps {
    discount: number | undefined;
    setDiscount: (value: number | undefined) => void;
    documentsLocalStorage: ExportSettings;
    setDocumentsLocalStorage: (value: ExportSettings) => void;
}

export const DownloadDocumentPopoverDiscountInput: FC<DownloadDocumentPopoverDiscountInputProps> = (props) => {
    const { discount, setDiscount, documentsLocalStorage, setDocumentsLocalStorage } = props;

    return (
        <div className={'content'}>
            <div className={'title'} style={{ fontWeight: 400 }}>
                Скидка в процентах
            </div>
            <div>
                <NumberInputDiscount
                    defaultValue={discount}
                    onChange={(value) => {
                        setDiscount(value);
                        setDocumentsLocalStorage({
                            ...documentsLocalStorage,
                            discount: value,
                        });
                    }}
                />
            </div>
        </div>
    );
};

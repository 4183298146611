import {OperationTypeCodeEnum} from "../../../../../../../server";
import {LabelValue} from "../../labelValue/labelValue";
import {LabelValueTable} from "../../labelValue/labelValueTable";
import {LocalizationEnum} from "../../../../../../../localization";
import {DataDisplayState} from "../../../../../../../components/dataDisplay";
import React, {ReactNode} from "react";
import {useLocalize} from "../../../../../../../core/hooks/useLocalize";
import {useAppSelector} from "../../../../../../../store/hooks";
import {canViewFinancialDataSelector} from "../../../../../../../shared/reducers/permissions.reducer";

export interface ProjectEditBlockProps {
    editButton: ReactNode;
    actualSumAfterTaxes?: number;
    debtSum?: number;
    withoutLabel: boolean;
    parentEntityIsCreated: boolean;
    isNowEditing: boolean;
}

export const ProjectEditBlock = (props:ProjectEditBlockProps) => {
    const L = useLocalize();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const typeCode = useAppSelector((state) => state.operationForm.typeCode);

    return (
        <div style={{display: 'flex', marginLeft: 20}}>
            {
                /*(!typeCode || (typeCode && typeCode !== OperationTypeCodeEnum.EDIT) ) &&*/ !props.isNowEditing && (
                    <div>
                        {
                            props.withoutLabel ? props.editButton : <LabelValue label={<span>&nbsp;</span>}>
                                {props.editButton}
                            </LabelValue>
                        }
                    </div>
                )
            }
            {
                canViewFinancialData && (props.actualSumAfterTaxes !== undefined || props.debtSum !== undefined) && (
                    <div style={{marginLeft: 40, whiteSpace: 'nowrap'}}>
                        <LabelValueTable data={[
                            props.actualSumAfterTaxes !== undefined && {
                                label: L(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL),
                                value: props.actualSumAfterTaxes
                            },
                            props.parentEntityIsCreated && props.debtSum !== undefined && {
                                label: L(LocalizationEnum.PAGE__PROJECTS__CARD__DEBT_SUM),
                                value: props.debtSum,
                                state: props.debtSum === 0 ? DataDisplayState.Success : DataDisplayState.Problem
                            },
                        ]}/>
                    </div>
                )
            }
        </div>
    );
};

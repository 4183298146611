import { CounterpartyActivityTypeCodeEnum, ProjectSourceCodeEnum, ProjectStateCodeEnum, TagEntityTypeCode } from '../../../../../server';
import { FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { FiltersDateRangePicker } from '../../../../../components/datePicker/FiltersDateRangePicker';
import { LocalizationEnum } from '../../../../../localization';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../components/select/problemsFilter';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocationSelect } from '../../../../../components/v2/select/locationSelect/locationSelect';
import { StatusesFilter, StatusesFilterProps } from '../../../../../components/select/StatusesFilters/v2/StatusesFilter';
import { TagSelect } from '../../../../../components/select/TagSelect/TagSelect';
import { UserSelect } from '../../../../../components/v2/select/userSelect/userSelect';
import { SliderRangeWithManualInput } from '../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { getStoreState } from '../../../../../../index';

export const projectsListFilters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'status',
                type: FormItemType.Component,
                component: StatusesFilter,
                componentProps: (form: WrappedFormUtils): StatusesFilterProps => {
                    return {
                        selectItems: [
                            {
                                name: 'Черновик',
                                value: ProjectStateCodeEnum.DRAFT,
                            },
                            {
                                name: 'Согласование',
                                value: ProjectStateCodeEnum.COORDINATION,
                            },
                            {
                                name: LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS,
                                value: ProjectStateCodeEnum.INPROGRESS,
                            },
                            {
                                name: LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED,
                                value: ProjectStateCodeEnum.FINISHED,
                            },
                            {
                                name: 'Отменен',
                                value: ProjectStateCodeEnum.CANCELED,
                            },
                        ],
                    };
                },
            },
            {
                label: 'Теги',
                placeholder: 'Любые',
                id: 'tags',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: TagSelect,
                componentProps: {
                    tagType: TagEntityTypeCode.PROJECT,
                },
                className: 'rr-select-gray',
            },
            {
                label: 'Ответственный',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'assigneeId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: UserSelect,
                className: 'rr-select-gray',
            },
            {
                id: 'hide',
                label: 'Скрыть',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Архив', value: 'archive' },
                    { name: 'Отмененные', value: 'cancelled' },
                    { name: 'Архив и отмененные', value: 'archive+cancelled' },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'rent',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__START_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'startDate',
                type: FormItemType.Component,
                component: FiltersDateRangePicker,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__RENTER,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'renterId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: {
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    getEntityOperationName: 'getRenterById',
                    showSearch: true,
                    filters: [
                        `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.RENTERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
                    ],
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Локация',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'locationId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: LocationSelect,
            },
            {
                label: 'Источник',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'sourceCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                values: [
                    { name: 'Витрина', value: ProjectSourceCodeEnum.STOREFRONT },
                    { name: 'Система', value: ProjectSourceCodeEnum.INTERNAL },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__DEBT,
                id: 'debtSum',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000,
                    step: 1,
                },
                visible: () => canViewFinancialDataSelector(getStoreState()),
            },
        ],
    },
];

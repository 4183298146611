import { AccessibilityMapCalendarItem, CalendarItem } from '../../../components/Calendar/types/items';
import { useMemo } from 'react';
import moment from 'moment';
import { AccessibilityMapCalendarData } from '../utils/getAccessibilityMapCalendarDataFromBase64';
import { TimetableTypeCodeEnum } from '../../../../../../server';

export const useCalendarItems = (
    itemsData: AccessibilityMapCalendarData[] | undefined,
    stacks: TimetableTypeCodeEnum[],
    screenLeft: number,
    screenRight: number,
    accessibilityEntitySelected: boolean | undefined
): CalendarItem<AccessibilityMapCalendarItem>[] => {
    return useMemo(() => {
        if (itemsData == null || !accessibilityEntitySelected) return [];

        return itemsData
            .filter(({ typeCode }) => stacks.includes(typeCode))
            .map((itemData) => {
                const { typeCode, value, startTime, groupIndex, endTime } = itemData;

                if (
                    value === 0 &&
                    [
                        TimetableTypeCodeEnum.ORDER,
                        TimetableTypeCodeEnum.OCCUPIED,
                        TimetableTypeCodeEnum.SUBRENTED,
                        TimetableTypeCodeEnum.MAINTENANCE,
                    ].includes(typeCode)
                )
                    return undefined;
                if ((+startTime < screenLeft && +endTime < screenLeft) || (+startTime > screenRight && +endTime > screenRight))
                    return undefined;

                return {
                    id: `${value}_${endTime}_${typeCode}`,
                    elementType: 'accessibility',
                    group: groupIndex,
                    typeCode,
                    value: Number(value),
                    startTime: +startTime,
                    endTime: +endTime,
                    start_time: moment(Math.max(+startTime, screenLeft)),
                    end_time: moment(Math.min(+endTime, screenRight)),
                };
            })
            .filter(Boolean) as CalendarItem<AccessibilityMapCalendarItem>[];
    }, [accessibilityEntitySelected, itemsData, screenLeft, screenRight, stacks]);
};

import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../core/utils/formUtils';
import { LocalizationEnum } from '../../../../../../localization';
import { ListContactsParams } from '../api/types';

import { ContactBusinessType } from '../utils/constants';

export const contactsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListContactsParams>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__TYPE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'type',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Простой контакт', value: ContactBusinessType.BASE },
                    { name: 'Пользователь аккаунта', value: ContactBusinessType.USER },
                    { name: 'Проектный работник', value: ContactBusinessType.CREW_MEMBER },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
]);

import React, { FC, useEffect } from 'react';
import { Col, Icon, Row } from 'antd';
import { PricingSchemePricePopover } from '../../settings/pricingSchemes/pricingSchemePricePopover';
import { PricingSchemeMaps, PricingSchemeUtils } from '../../../../core/utils/pricingSchemeUtils';
import { InlineMoneyInput } from '../../../../components/inlineInputs/InlineMoneyInput/InlineMoneyInput';
import { LocalizationEnum, localize } from '../../../../localization';
import { PricingSchemeExternalRepresentationObj } from '../../../../server';
import { MoneyUtils } from '../../../../core/utils/moneyUtils';
import './priceBlock.less';

export interface PriceBlockProps {
    value?: number;
    onChange?: (number) => void;
    shiftCount: number;
    numberOfCrew: number;
    onPriceChanged?: () => void;
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    basePrice: number;
}

export const PriceBlock: FC<PriceBlockProps> = (props) => {
    const { shiftCount, numberOfCrew } = props;
    const pricingScheme = props.pricingScheme;
    const pricePerShiftOriginal = props.basePrice;
    let pricePerShift = 0;

    if (props.pricingScheme) {
        pricePerShift = PricingSchemeUtils.calculatePriceByPricingScheme({
            shiftCount,
            basePrice: props.basePrice,
            pricingScheme: props.pricingScheme,
        });
    } else {
        pricePerShift = props.value || 0;
    }

    let shiftPrice = MoneyUtils.calculateFinalInstancePrice(pricePerShift, 0),
        finalTotalPrice = MoneyUtils.calculateFinalTotalPrice(shiftPrice, numberOfCrew, shiftCount);

    useEffect(() => {
        if ((props.value || 0) !== shiftPrice) {
            props.onChange?.(shiftPrice);
        }
    }, [shiftPrice, props.value]);

    return (
        <Row className={'price-block'} type={'flex'} style={{ backgroundColor: '#525A95' }}>
            <Col
                span={12}
                className="rr-form-item"
                style={{
                    borderRight: 'none',
                    padding: '12px 32px',
                    marginBottom: 0,
                    backgroundColor: '#FFF',
                }}
            >
                <div className="rr-label">Цена смены</div>
                <div className="rr-value">
                    {pricingScheme ? (
                        <PricingSchemePricePopover
                            data={{
                                id: pricingScheme.id,
                                typeCode: pricingScheme?.typeCode,
                                label: pricingScheme.name || '',
                                price: pricePerShiftOriginal,
                                steps: pricingScheme?.steps || [],
                                shiftCount,
                            }}
                        >
                            <Icon
                                style={{
                                    fontSize: 22,
                                    color: '#3d64d4',
                                    marginRight: 4,
                                    marginTop: -5,
                                    verticalAlign: 'middle',
                                }}
                                component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                            />
                        </PricingSchemePricePopover>
                    ) : null}

                    <InlineMoneyInput
                        min={0}
                        value={shiftPrice}
                        onChange={(v) => {
                            if (shiftPrice !== v) {
                                props.onChange?.(v);
                                props.onPriceChanged?.();
                            }
                        }}
                    />
                </div>
            </Col>
            <Col
                span={12}
                className="rr-form-item"
                style={{
                    borderLeft: '1px solid #525A95',
                    padding: '12px 0px 12px 32px',
                    backgroundColor: '#525A95',
                    marginBottom: 0,
                    marginLeft: -16 - 1,
                }}
            >
                <div className="rr-label" style={{ color: '#FFFFFF' }}>
                    {localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE)}
                </div>
                <div className={'rr-value'} style={{ color: '#FFFFFF' }}>
                    <InlineMoneyInput
                        min={0}
                        canEdit={pricePerShift !== 0 && numberOfCrew > 0 && shiftCount > 0}
                        darkBg={true}
                        value={finalTotalPrice}
                        onChange={(v) => {
                            if (finalTotalPrice !== v) {
                                props.onPriceChanged?.();
                                props.onChange?.(v / (numberOfCrew * shiftCount));
                            }
                        }}
                    />
                </div>
            </Col>
        </Row>
    );
};

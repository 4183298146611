import React from 'react';
import { Switch, Tooltip } from 'antd';
import { isRentOrSubrentOperation, isReturnOrSubrentReturnOperation } from '../../utils/utils';
import { IconType, IconX } from '../../../../../components/v2/icon/icon';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setAddKitMembersWhenInstanceScan } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

export const FiltersCurrentModesInfo = () => {
    const dispatch = useAppDispatch();
    const autoRecalculateShiftsCountMode = useAppSelector((store) => store.operationForm.autoRecalculateShiftsCountMode);
    const actualizeDateMode = useAppSelector((store) => store.operationForm.actualizeDateMode);
    const typeCode = useAppSelector((store) => store.operationForm.typeCode);
    const targetStateCode = useAppSelector((store) => store.operationForm.targetStateCode);
    const addKitMembersWhenInstanceScan = useAppSelector((store) => store.userSettings.addKitMembersWhenInstanceScan);
    const modes: string[] = [];

    if (actualizeDateMode) {
        if (isRentOrSubrentOperation(targetStateCode || typeCode)) {
            modes.push('Дата выдачи');
        } else if (isReturnOrSubrentReturnOperation(targetStateCode || typeCode)) {
            modes.push('Дата возврата');
        }
    }
    if (autoRecalculateShiftsCountMode) modes.push('Смены к оплате');

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {modes.length > 0 && (
                <div
                    className={'rr-operationForm-elementsList-filters-item'}
                    style={{ display: 'inline-flex', height: 38, alignItems: 'center' }}
                >
                    <IconX type={IconType.INFO} style={{ fontSize: 22, color: '#525A95', marginLeft: 8, marginRight: 15 }} />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ lineHeight: 1, fontSize: 10 }}>В текущем режиме при добавлении устанавливается</div>
                        <div style={{ lineHeight: 1, marginTop: 8, fontSize: 15 }}>{modes.join(', ')}</div>
                    </div>
                </div>
            )}
            {
                <div className={'rr-operationForm-elementsList-filters-item'} style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Tooltip
                        title={
                            <>
                                При сканировании именованного экземпляра из существующего в проекте набора, будет добавлен весь набор с
                                анонимными и счетными экземплярами.
                                <br />
                                Это полезно, когда в проекте несколько наборов одного типа и сканирование по штрихкоду набора не позволяет
                                определить нужный.
                            </>
                        }
                    >
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <IconX type={IconType.BARCODE} style={{ fontSize: 22, color: '#525A95', marginLeft: 8, marginRight: 15 }} />
                            <span>Набор по экземпляру</span>
                        </div>
                    </Tooltip>
                    <Switch
                        checked={addKitMembersWhenInstanceScan}
                        style={{ marginLeft: 14 }}
                        onChange={(checked) => {
                            dispatch(setAddKitMembersWhenInstanceScan(checked));
                        }}
                    />
                </div>
            }
        </div>
    );
};

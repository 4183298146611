import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'antd';
import classNames from 'classnames';
import { IconAngleLeft, IconAngleRight } from '../../../../../icons';
import { calculateActionIncrementDelay, INITIAL_INCREMENT_FREQUENCY_TIME } from './utils/calculateActionSpeed';

interface ControlArrowProps {
    action: 'increment' | 'decrement';
    isActionEnabled: boolean;
    actionHandler: () => void;
}

const getDirectionIcon = (direction: ControlArrowProps['action']) => {
    return (
        {
            decrement: IconAngleLeft,
            increment: IconAngleRight,
        } satisfies Record<typeof direction, FC>
    )[direction];
};

export const ControlArrow: FC<ControlArrowProps> = memo(({ actionHandler, isActionEnabled, action }) => {
    const timerRef = useRef<NodeJS.Timeout>();
    const incrementStartTimeRef = useRef<number | undefined>(undefined);
    const [currentIncrementDelay, setCurrentIncrementDelay] = useState<{
        value: number;
    }>({ value: INITIAL_INCREMENT_FREQUENCY_TIME });
    const [isMousePressed, setIsMousePressed] = useState<boolean>(false);

    const repeatActionHandler = useCallback(() => {
        if (isActionEnabled && isMousePressed && incrementStartTimeRef.current) {
            actionHandler();
            const newDelay = calculateActionIncrementDelay(incrementStartTimeRef.current);
            setCurrentIncrementDelay({ value: newDelay });
        }
    }, [actionHandler, isActionEnabled, isMousePressed]);

    const onMouseDown = useCallback(() => {
        if (isActionEnabled) {
            actionHandler();
            incrementStartTimeRef.current = Date.now();
            setIsMousePressed(true);
        }
    }, [actionHandler, isActionEnabled]);

    const onMouseLeaveArrow = useCallback(() => {
        incrementStartTimeRef.current = undefined;
        setIsMousePressed(false);
        setCurrentIncrementDelay({ value: INITIAL_INCREMENT_FREQUENCY_TIME });

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }
    }, []);

    useEffect(() => {
        if (isMousePressed) {
            if (!timerRef.current) {
                timerRef.current = setTimeout(repeatActionHandler, currentIncrementDelay.value);
            }
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, [isMousePressed, currentIncrementDelay, repeatActionHandler]);

    return (
        <div className={'icon-container'} onMouseDown={onMouseDown} onMouseUp={onMouseLeaveArrow} onMouseLeave={onMouseLeaveArrow}>
            <Icon
                className={classNames('arrow', {
                    disabled: !isActionEnabled,
                })}
                component={getDirectionIcon(action)}
            />
        </div>
    );
});

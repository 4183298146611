import React, { FC } from 'react';
import RoundButton from '../../../../components/button/roundButton';
import { Icon } from 'antd';
import { IconPlus } from '../../../../components/icons';
import { SettingsPageWrapper } from '../settingsPageWrapper';
import { PaymentMethodsList } from './list/paymentMethodsList';
import { Link, useLocation } from 'react-router-dom';
import './paymentMethodsModule.less';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../store/hooks';
import { InterfaceBlockCode } from '../../../../components/pageHeader/components/InfoSelectDropdown/utils/constants';
import { InfoSelectDropdown } from '../../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown';

interface PaymentMethodsModuleProps {}

export const PaymentMethodsModule: FC<PaymentMethodsModuleProps> = (props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('modal', 'new');

    return (
        <SettingsPageWrapper
            title={'Способы оплаты'}
            extra={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Link to={`/${businessAccountId}/settings/paymentMethods?` + searchParams.toString()}>
                        <RoundButton colorScheme={'success'}>
                            <Icon component={IconPlus} />
                            <span>Создать способ оплаты</span>
                        </RoundButton>
                    </Link>
                    <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_SYSTEM_OPTIONS_PAYMENT_METHODS} />
                </div>
            }
        >
            <PaymentMethodsList
                gridName={'paymentMethods'}
                visible={true}
                urlPath={`settings/paymentMethods`}
            />
        </SettingsPageWrapper>
    );
};

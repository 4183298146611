import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectProps, BaseSelectValue, selectValueToNumber } from '../baseSelect';
import { CrewMemberRecord } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { useLazyListCrewMembersQuery } from '../../../../modules/main/crew/tabs/crewMembers/api/crewMembers.api';

export interface CrewMemberSelectProps extends BaseSelectBaseProps<CrewMemberRecord> {
    filters?: Filters;
    getOptionProps?: BaseSelectProps<CrewMemberRecord>['getOptionProps'];
}

interface Filters {
    hideArchive?: boolean;
    professionId?: number;
}

const sortBy: string = 'lastUpdateDate';
const sortOrder = 'DESC';

export const CrewMemberSelectFC = ({ ...props }: CrewMemberSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);
    const [fetchCrewMembers] = useLazyListCrewMembersQuery();

    const fetchRecordsById = async (id: BaseSelectValue) => {
        const ids = (Array.isArray(id) ? id : [id]).map((id) => selectValueToNumber(id));
        try {
            const response = await fetchCrewMembers({
                businessAccountId,
                params: { limit: ids.length, sortBy: '', sortOrder: 'ASC', page: 1, id: ids },
            });
            if (response.error) throw response.error;
            return response.data?.entitiesData.records ?? [];
        } catch (err) {
            throw err;
        }
    };

    const fetchRecords = async (startFrom: number, limit: number, search?: string) => {
        const response = await fetchCrewMembers({
            businessAccountId,
            params: {
                limit,
                search,
                page: startFrom / limit + 1,
                sortBy,
                sortOrder,
                hideArchive: props.filters?.hideArchive,
                professionId: props.filters?.professionId,
            },
        });
        if (response.error) throw response.error;
        return response.data?.entitiesData.records ?? [];
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={fetchRecordsById}
            fetchRecords={fetchRecords}
            getRecordId={(record) => record.id}
            getOptionProps={
                props.getOptionProps ||
                ((item) => ({
                    label: item.personShortName,
                    children: item.personShortName,
                }))
            }
        />
    );
};

export class CrewMemberSelect extends React.PureComponent<CrewMemberSelectProps> {
    render() {
        return <CrewMemberSelectFC {...this.props} />;
    }
}

import {Icon, Select} from 'antd';
import {
    IconBoxesSolid,
    IconClockO,
    IconHourglassEndSolid,
    IconShippingFastSolid,
    IconStickyNoteO,
    IconStopwatchSolid
} from '../icons';
import React, {FC} from 'react';
import {useAppSelector} from '../../store/hooks';
import {subrentModuleEnabledSelector} from '../../shared/reducers/businessAccountPreferences.reducer';
import {ProblemEnum} from '../../types';

type SelectItem = {
    name: string;
    value: string;
};

export interface ProblemsFilterProps {
    typeStatuses?: 'all' | 'rent' | 'subrent';
}

const { Option } = Select;

export const ProblemsFilter: FC<ProblemsFilterProps> = (props) => {
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    let items: SelectItem[] = [
        { name: 'Любая проблема', value: ProblemEnum.ANY_PROBLEM },
        { name: 'Любая проблема или предупр.', value: ProblemEnum.ANY_PROBLEM_OR_WARNING },
        { value: 'devider', name: '' },
    ];

    if (props.typeStatuses === 'all' || !props.typeStatuses || props.typeStatuses === 'rent') {
        items.push(
            { name: 'Просрочка возврата', value: ProblemEnum.RETURN_DELAY },
            { name: 'Просрочка выдачи', value: ProblemEnum.GIVEAWAY_DELAY },
            { name: 'Нехватка по брони', value: ProblemEnum.BOOK_SHORTAGE },
            { name: 'Нехватка по заявке', value: ProblemEnum.ORDER_SHORTAGE }
        );
    }

    if ((subrentModuleEnabled && props.typeStatuses === 'all') || !props.typeStatuses || props.typeStatuses === 'subrent') {
        items.push(
            { name: 'Просрочка поставки субаренды', value: ProblemEnum.SUBRENT_SHIPMENT_DELAY },
            { name: 'Просрочка возврата субаренды поставщику', value: ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY }
        );
    }

    items.push({ value: 'devider2', name: '' }, { name: 'Любая просрочка', value: ProblemEnum.ANY_DELAY });

    if (props.typeStatuses === 'all' || !props.typeStatuses || props.typeStatuses === 'rent') {
        items.push({ name: 'Любая нехватка', value: ProblemEnum.ANY_SHORTAGE });
    }

    const arr = items.map((item, index) => {
        let iconProblem;

        if (item.value === 'BOOK_SHORTAGE') {
            iconProblem = (
                <Icon
                    className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                    style={{ marginRight: '10px', marginTop: '-1px' }}
                    component={IconBoxesSolid}
                />
            );
        } else if (item.value === 'SUBRENT_SHIPMENT_DELAY') {
            iconProblem = (
                <Icon
                    className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                    style={{ marginRight: '10px', marginTop: '-1px' }}
                    component={IconShippingFastSolid}
                />
            );
        } else if (item.value === 'RETURN_DELAY') {
            iconProblem = (
                <Icon
                    className="current-indicators-problem-icons current-indicators-problem-icons-color-hard"
                    style={{ marginRight: '10px', marginTop: '-1px' }}
                    component={IconHourglassEndSolid}
                />
            );
        } else if (item.value === 'GIVEAWAY_DELAY') {
            iconProblem = (
                <Icon
                    className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                    style={{ marginRight: '10px', marginTop: '-1px' }}
                    component={IconClockO}
                />
            );
        } else if (item.value === 'SUBRENT_RETURN_TO_SHIPPER_DELAY') {
            iconProblem = (
                <Icon
                    className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                    style={{ marginRight: '10px', marginTop: '-1px' }}
                    component={IconStopwatchSolid}
                />
            );
        } else if (item.value === 'ORDER_SHORTAGE') {
            iconProblem = (
                <Icon
                    className="current-indicators-problem-icons current-indicators-problem-icons-color-lite"
                    style={{ marginRight: '10px', marginTop: '-1px' }}
                    component={IconStickyNoteO}
                />
            );
        } else if (item.value === 'ANY_PROBLEM') {
            iconProblem = (
                <div
                    className={`rr-dot`}
                    style={{
                        marginRight: '14px',
                        marginLeft: '5px',
                        backgroundColor: 'rgb(244, 81, 108)',
                    }}
                ></div>
            );
        } else if (item.value === 'ANY_PROBLEM_OR_WARNING') {
            iconProblem = (
                <div
                    className={`rr-dot`}
                    style={{
                        width: '11px',
                        height: '11px',
                        border: '2px solid rgb(244, 81, 108)',
                        borderRadius: '11px',
                        backgroundColor: '#FC8C2F',
                        marginRight: '14px',
                        marginLeft: '5px',
                    }}
                ></div>
            );
        }
        return (
            <Option key={index} value={item.value} disabled={item.value === 'devider' || item.value === 'devider2'} title={item.name}>
                {item.value !== 'devider' && item.value !== 'devider2' ? (
                    item.value === 'ANY_PROBLEM_OR_WARNING' ? (
                        <>
                            {iconProblem}
                            <span className="calendar-multi-select">{item.name}</span>
                        </>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {iconProblem}
                            <span className="calendar-multi-select">{item.name}</span>
                        </div>
                    )
                ) : (
                    <div className="rr-status-filter-separator">
                        <div style={{ width: '100%', borderBottom: '1px solid #ccc' }}></div>
                    </div>
                )}
            </Option>
        );
    });

    return (
        <Select
            {...props}
            style={{ maxWidth: 310, minWidth: 150 }}
            defaultActiveFirstOption={false}
            allowClear={true}
            dropdownMatchSelectWidth={false}
            dropdownRender={(menu) => <div style={{ minWidth: 261, backgroundColor: '#fff' }}>{menu}</div>}
        >
            {arr}
        </Select>
    );
};

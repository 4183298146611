import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { matchPath, Route, RouteComponentProps } from 'react-router';
import { RouteConfigComponentProps } from 'react-router-config';
import { push } from 'connected-react-router';
import { Icon } from 'antd';
import CustomFieldsModuleList from './customFieldsModuleList';
import { Link } from 'react-router-dom';
import RoundButton from '../../../../components/button/roundButton';
import { IconPlus } from '../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../localization';
import { reset } from './reducers/customFields.reducer';
import CustomFieldsEditModal from './customFieldsEditModal';
import { getPathFromState } from '../../../../shared/util/utils';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { loadEntity } from './reducers/customField.reducer';
import { SettingsPageWrapper } from '../settingsPageWrapper';

interface IProps extends StateProps, DispatchProps, RouteComponentProps, RouteConfigComponentProps, WrappedComponentProps {}

interface IState {}

class CustomFieldsModule extends React.PureComponent<IProps, IState> {
    componentWillUnmount = () => {
        this.props.reset();
    };

    componentDidMount = () => {
        if (this.props.entityId) {
            this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    };

    componentDidUpdate = (prevProps: IProps) => {
        if (this.props.entityId && prevProps.entityId !== this.props.entityId) {
            this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    };

    render() {
        let modalBackPath = getPathFromState(`/${this.props.businessAccountId}/settings/customFields`, this.props.location.search, {
            mode: undefined,
            id: undefined,
        });
        let createPath = getPathFromState(`/${this.props.businessAccountId}/settings/customFields/new`, this.props.location.search);

        return (
            <SettingsPageWrapper
                title={localize(LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__CUSTOM_FIELDS, 'span')}
                extra={
                    <Link to={createPath}>
                        <RoundButton colorScheme={'success'}>
                            <Icon component={IconPlus} />
                            <span>{localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__ACTION__CREATE, 'span')}</span>
                        </RoundButton>
                    </Link>
                }
            >
                <CustomFieldsModuleList intl={this.props.intl} />
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/customFields/new`}
                    render={(props) => (
                        <CustomFieldsEditModal
                            intl={this.props.intl}
                            title={localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__MODAL__CREATE__TITLE)}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            goBackPath={modalBackPath}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/customFields/${this.props.entityId}/edit`}
                    render={(props) =>
                        this.props.entity ? (
                            <CustomFieldsEditModal
                                initialValues={this.props.entity}
                                intl={this.props.intl}
                                title={localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__MODAL__EDIT__TITLE)}
                                goBackPath={modalBackPath}
                            />
                        ) : null
                    }
                />
            </SettingsPageWrapper>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: any) => {
    let entityId;
    let matchProps = matchPath(ownProps.location.pathname, ownProps.route) as any;
    if (matchProps && matchProps.params && matchProps.params.id) {
        entityId = matchProps.params.id;
    }

    return {
        businessAccountId: storeState.system.businessAccountId,
        entity: storeState.customField.entity,
        entityId: entityId,
    };
};

const mapDispatchToProps = { push, reset, loadEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomFieldsModule));

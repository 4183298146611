import { ExpensesState } from './expenses.types';
import { SetStatusForExpensesArgs } from '../../api/expenses.api.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showNotification } from '../../../../../components/notification/showNotification';
import { serverApi } from '../../../../../server';
import { expensesApi } from '../../api/expenses.api';
import { updateProjectEntity } from '../expense/expense.reducer';

const ACTION_TYPES = {
    CHANGE_STATUS: 'expenses/CHANGE_STATUS',
};

export const initialState: ExpensesState = {
    loading: false,
    loadingError: undefined,
    entities: undefined,
    filteredCount: 0,
};

export const setStatusForExpenses = createAsyncThunk(
    ACTION_TYPES.CHANGE_STATUS,
    async (args: SetStatusForExpensesArgs, { dispatch, getState }) => {
        const { businessAccountId, workflowDirectiveBulk } = args;

        try {
            await serverApi.transitExpenseWorkflowBulk(businessAccountId, workflowDirectiveBulk);

            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1 ? 'Статус затрат успешно обновлен' : 'Статус затраты успешно обновлен';
            showNotification('success', notificationMessage);
            dispatch(expensesApi.util?.invalidateTags(['ExpensesList', 'Expense']));
            // const project = (getState() as IRootState).project.entity;
            // if(project) dispatch(loadEntity(project.businessAccountId, project.id));
            updateProjectEntity(getState as any, dispatch as any); // TODO избавиться от any
        } catch (error: unknown) {
            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1 ? 'Не удалось обновить статус затрат' : 'Не удалось обновить статус затраты';
            showNotification('error', notificationMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

const expensesSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setStatusForExpenses.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(setStatusForExpenses.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(setStatusForExpenses.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default expensesSlice.reducer;

import React, {FC, useCallback, useState} from 'react';
import {Icon, Popover} from "antd";
import {PopoverContent} from "../popover/PopoverContent";
import {AxiosResponse} from "axios";
import {BarcodeTypeCodeEnum, serverApi} from "../../server";
import {useAppSelector} from "../../store/hooks";
import {businessAccountIdSelector} from "../../shared/reducers/system.reducer";
import {IconBarcodeSolid} from "../icons";
import classNames from "classnames";
import Spin from "../spin/spin";
import './barcodePopover.less';

interface BarcodePopoverProps {
    barcodeString?: string;
}

export const BarcodePopover: FC<BarcodePopoverProps> = ({barcodeString, children}) => {

    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const barcodeType = useAppSelector((state) => state.businessAccountPreferences.preferences?.barcodeType);
    const [data, setData] = useState<Blob | null>(null);
    const [imageLoadingError, setImageLoadingError] = useState<boolean>(false);
    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const onVisibleChange = useCallback(async (visible: boolean) => {
        if (visible && barcodeString) {
            try {
                setImageLoading(true);
                const res: AxiosResponse<Blob> = await serverApi.generateBarcodeImage(
                    businessAccountId,
                    barcodeString,
                    {responseType: 'blob'}
                );
                setImageLoading(false);
                setData(res.data);
            } catch (error) {
                setImageLoadingError(true);
                setImageLoading(false);
            }
        } else {
            setTimeout(() => {
                setData(null);
                setImageLoadingError(false);
            }, 50);
        }
    }, [businessAccountId, barcodeString]);

    let imageClassName: string | undefined;
    let barcodeTypeName: string | undefined;
    if (barcodeType === BarcodeTypeCodeEnum.CODE128) barcodeTypeName = 'code_128';
    else if (barcodeType === BarcodeTypeCodeEnum.DATAMATRIX) barcodeTypeName = 'data_matrix';
    else if (barcodeType === BarcodeTypeCodeEnum.QRCODE) barcodeTypeName = 'qr_code';
    imageClassName = `rr-barcode-popover-image-${barcodeTypeName}`;

    return (
        <Popover
            overlayStyle={{width: 'unset'}}
            overlayClassName={classNames('rr-grid-actions-popover', 'rr-DownloadDocumentPopover', 'rr-barcode-popover')}
            placement={"right"}
            onVisibleChange={onVisibleChange}
            trigger={'click'}
            arrowPointAtCenter={true}
            content={
                <PopoverContent
                    header={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Icon
                                component={IconBarcodeSolid}
                                style={{
                                    fontSize: 24,
                                    marginRight: 10
                                }}
                            />
                            Штриховой код
                        </div>
                    }
                    content={
                        <Spin spinning={imageLoading} delay={50}>
                            <div className={imageClassName} style={{padding: 16, paddingTop: 20, paddingBottom: 20, textAlign: 'center'}}>
                                {data && <img src={URL.createObjectURL(data)}/>}
                                {!data && imageLoadingError &&
                                <span className={'rr-barcode-popover-error-message'}>Не удалось загрузить изображение</span>}
                            </div>
                        </Spin>
                    }
                />
            }
        >
            {children}
        </Popover>
    );
};

import React, { FC, memo } from 'react';
import { CalendarVisibleItemsLimit } from '../../utils/data/calendarConstants';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import './CalendarFooter.less';

interface CalendarFooterProps {
    filteredCount: number;
    visibleItemsLimit: CalendarVisibleItemsLimit;
    setVisibleItemsLimit: React.Dispatch<React.SetStateAction<CalendarVisibleItemsLimit>>;
}

export const CalendarFooter: FC<CalendarFooterProps> = memo((props) => {
    const { filteredCount, visibleItemsLimit, setVisibleItemsLimit } = props;

    return filteredCount > CalendarVisibleItemsLimit.FIRST_LIMIT && visibleItemsLimit === CalendarVisibleItemsLimit.FIRST_LIMIT ? (
        <div className="calendar-footer-container" onClick={() => setVisibleItemsLimit(CalendarVisibleItemsLimit.SECOND_LIMIT)}>
            <span className={'calendar-footer'}>
                <span>{localize(LocalizationEnum.PAGE__CALENDAR__SHOW_ALL_ELEMENTS_BUTTON)} </span>
                <span className="items-count"> ({filteredCount})</span>
            </span>
        </div>
    ) : null;
});

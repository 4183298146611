import React, {ComponentType, CSSProperties, FC, SVGProps} from 'react';
import {
    IconAngleDoubleLeft,
    IconAngleDoubleRight,
    IconAngleDown,
    IconAngleLeft,
    IconAngleRight,
    IconAngleUp,
    IconArchive,
    IconArrowCircleDownSolid,
    IconArrowCircleUpSolid,
    IconArrowRightSolid,
    IconArrowsAltHSolid,
    IconBarcodeSolid,
    IconBell,
    IconBinoculars,
    IconBorderStyleSolid,
    IconBoxSolid,
    IconBoxesSolid,
    IconBriefcase,
    IconBuilding,
    IconBullhorn,
    IconCalculatorSolid,
    IconCalendar,
    IconCalendarPlus,
    IconCaretSquareLeftSolid,
    IconCaretSquareRightSolid,
    IconCaretUpSolid,
    IconCartArrowDownSolid,
    IconCartPlusSolid,
    IconCashRegisterSolid,
    // IconChalkboardSolid,
    // IconChartArea,
    // IconChartBar,
    // IconChartLineSolid,
    // IconChartPieSolid,
    IconCheck,
    IconCheckCircle,
    IconCheckSquare,
    IconChevronRightSolid,
    IconCircle,
    IconClipboard,
    IconClipboardCheckSolid,
    IconClipboardListSolid,
    IconClockO,
    IconClone,
    IconClose,
    IconCogSolid,
    IconCogs,
    IconCoinsSolid,
    IconComments,
    IconCompressArrowsAltSolid1,
    IconCompressArrowsAltSolid2,
    IconControlPointDuplicateBlack,
    IconCopy,
    IconCopyAnonymous,
    IconCrosshairsSolid,
    IconCube,
    IconCubesSolid,
    IconDollarSignSolid,
    IconDollyFlatbedSolid,
    IconDollySolid,
    IconDownloadSolid,
    IconEdit,
    IconEllipsisHSolid,
    IconEnvelope,
    IconEnvelopeOpen,
    IconEraser,
    IconExcel,
    IconExchange,
    IconExclamationCircleSolid,
    IconExclamationSolid,
    IconExclamationTriangleSolid,
    IconEyeSlashSolid,
    IconEyeSolid,
    IconFile,
    IconFileAlt,
    IconFileArchive,
    IconFileAudio,
    IconFileExcel,
    IconFileImage,
    IconFileInvoiceDollarSolid,
    IconFilePdf,
    IconFilePowerpoint,
    IconFileSignatureSolid,
    IconFileVideo,
    IconFileWord,
    IconFileZipO,
    IconFilter,
    IconFlagCheckered,
    IconFolder,
    IconGlobeSolid,
    IconGraduationCapSolid,
    IconGripVerticalSoft,
    IconGripVerticalSolid,
    IconHandStopO,
    IconHandshake,
    IconHardHatSolid,
    IconHistorySolid,
    //IconHome,
    IconHourglassEndSolid,
    IconHourglassHalfSolid,
    IconHourglassSolid,
    IconHourglassStart,
    IconIconsSolid,
    IconIdCard,
    IconInfoCircleSolid,
    IconInfoSolid,
    IconLadder,
    IconLayerGroupSolid,
    IconLevelUp,
    IconLinkSolid,
    IconListAlt,
    IconListUlSolid,
    IconLockSolid,
    IconLongArrowAltRightSolid,
    IconLuggageCartSolid,
    IconMagicSolid,
    IconMapMarkedAltSolid,
    IconMapMarkerSolid,
    IconMedkit,
    IconMicroscopeSolid,
    IconMinusSolid,
    IconMinusSquare,
    IconMoneyBillWaveSolid,
    IconNoImage,
    IconNounBreak,
    IconPaperclipSolid,
    //IconPenSquare,
    IconPencil,
    IconPencilRulerSolid,
    IconPhoneSolid,
    IconPlus,
    IconPlusCircle,
    IconPlusSquare,
    IconQuestionCircle,
    IconQuestionCircleSolid,
    IconRandomSolid,
    IconRedoAltSolid,
    IconRepeat,
    IconReply,
    IconRobotSolid,
    IconRocket,
    IconRouteSolid,
    IconSatelliteDishSolid,
    IconSave,
    IconScrollSolid,
    IconSearch,
    IconSearchMinusSolid,
    IconShapesSolid,
    IconShippingFastSolid,
    IconShoppingBasketSolid,
    IconShortage,
    IconSignOutAltSolid,
    IconSitemap,
    //IconSitemapSolid,
    IconSliders,
    IconSlidersHSolid,
    IconSortAmountDownAltSolid,
    IconSortSolid,
    IconStampSolid,
    IconStar,
    IconStarSolid,
    IconStepBackwardSolid,
    IconStickyNoteO,
    IconStopwatchSolid,
    IconStoreSolid,
    IconStreamSolid,
    IconSyncSolid,
    IconTachometerAltSolid,
    IconTagSolid,
    IconTags,
    IconThumbtackSolid,
    IconTimesCircle,
    IconToolsSolid,
    IconTrash,
    IconTrashRestoreSolid,
    IconTruckSolid,
    IconUnlinkSolid,
    IconUnlock,
    IconUnlockAlt,
    IconUnlockAltColor,
    IconUploadSolid,
    IconUsd,
    IconUser,
    IconUserPlus,
    IconUsers,
    IconWarehouseSolid,
    IconWrench,
    IconWrenchSolid
} from "../../icons";
import {Icon as IconComponent} from "antd";
import {CustomIconComponentProps} from "antd/lib/icon";

type IconData = Record<IconType, ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>>;

export enum IconType {
    ANGLE_DOUBLE_LEFT = 'angleDoubleLeft',
    ANGLE_DOUBLE_RIGHT = 'angleDoubleRight',
    ANGLE_DOWN = 'angleDown',
    ANGLE_LEFT = 'angleLeft',
    ANGLE_RIGHT = 'angleRight',
    ANGLE_UP = 'angleUp',
    IconArchive = 'IconArchive',
    IconArrowCircleDownSolid = 'IconArrowCircleDownSolid',
    IconArrowCircleUpSolid = 'IconArrowCircleUpSolid',
    IconArrowRightSolid = 'IconArrowRightSolid',
    IconArrowsAltHSolid = 'IconArrowsAltHSolid',
    BARCODE = 'barcode',
    IconBell = 'IconBell',
    IconBinoculars = 'IconBinoculars',
    IconBorderStyleSolid = 'IconBorderStyleSolid',
    IconBoxSolid = 'IconBoxSolid',
    IconBoxesSolid = 'IconBoxesSolid',
    IconBriefcase = 'IconBriefcase',
    IconBuilding = 'IconBuilding',
    IconBullhorn = 'IconBullhorn',
    IconCalculatorSolid = 'IconCalculatorSolid',
    IconCalendar = 'IconCalendar',
    IconCalendarPlus = 'IconCalendarPlus',
    IconCaretSquareLeftSolid = 'IconCaretSquareLeftSolid',
    IconCaretSquareRightSolid = 'IconCaretSquareRightSolid',
    IconCaretUpSolid = 'IconCaretUpSolid',
    IconCartArrowDownSolid = 'IconCartArrowDownSolid',
    IconCartPlusSolid = 'IconCartPlusSolid',
    IconCashRegisterSolid = 'IconCashRegisterSolid',
    //IconChalkboardSolid = 'IconChalkboardSolid',
    //IconChartArea = 'IconChartArea',
    //IconChartBar = 'IconChartBar',
    //IconChartLineSolid = 'IconChartLineSolid',
    //IconChartPieSolid = 'IconChartPieSolid',
    IconCheck = 'IconCheck',
    IconCheckCircle = 'IconCheckCircle',
    IconCheckSquare = 'IconCheckSquare',
    IconChevronRightSolid = 'IconChevronRightSolid',
    IconCircle = 'IconCircle',
    IconClipboard = 'IconClipboard',
    IconClipboardCheckSolid = 'IconClipboardCheckSolid',
    IconClipboardListSolid = 'IconClipboardListSolid',
    IconClockO = 'IconClockO',
    IconClone = 'IconClone',
    IconClose = 'IconClose',
    IconCogSolid = 'IconCogSolid',
    IconCogs = 'IconCogs',
    IconCoinsSolid = 'IconCoinsSolid',
    IconComments = 'IconComments',
    IconCompressArrowsAltSolid1 = 'IconCompressArrowsAltSolid1',
    IconCompressArrowsAltSolid2 = 'IconCompressArrowsAltSolid2',
    IconControlPointDuplicateBlack = 'IconControlPointDuplicateBlack',
    IconCopy = 'IconCopy',
    IconCopyAnonymous = 'IconCopyAnonymous',
    IconCrosshairsSolid = 'IconCrosshairsSolid',
    IconCube = 'IconCube',
    IconCubesSolid = 'IconCubesSolid',
    IconDollarSignSolid = 'IconDollarSignSolid',
    IconDollyFlatbedSolid = 'IconDollyFlatbedSolid',
    IconDollySolid = 'IconDollySolid',
    IconDownloadSolid = 'IconDownloadSolid',
    IconEdit = 'IconEdit',
    IconEllipsisHSolid = 'IconEllipsisHSolid',
    IconEnvelope = 'IconEnvelope',
    IconEnvelopeOpen = 'IconEnvelopeOpen',
    IconEraser = 'IconEraser',
    IconExcel = 'IconExcel',
    IconExchange = 'IconExchange',
    IconExclamationCircleSolid = 'IconExclamationCircleSolid',
    IconExclamationSolid = 'IconExclamationSolid',
    IconExclamationTriangleSolid = 'IconExclamationTriangleSolid',
    IconEyeSlashSolid = 'IconEyeSlashSolid',
    IconEyeSolid = 'IconEyeSolid',
    IconFile = 'IconFile',
    IconFileAlt = 'IconFileAlt',
    IconFileArchive = 'IconFileArchive',
    IconFileAudio = 'IconFileAudio',
    IconFileExcel = 'IconFileExcel',
    IconFileImage = 'IconFileImage',
    IconFileInvoiceDollarSolid = 'IconFileInvoiceDollarSolid',
    IconFilePdf = 'IconFilePdf',
    IconFilePowerpoint = 'IconFilePowerpoint',
    IconFileSignatureSolid = 'IconFileSignatureSolid',
    IconFileVideo = 'IconFileVideo',
    IconFileWord = 'IconFileWord',
    IconFileZipO = 'IconFileZipO',
    IconFilter = 'IconFilter',
    IconFlagCheckered = 'IconFlagCheckered',
    IconFolder = 'IconFolder',
    IconGlobeSolid = 'IconGlobeSolid',
    IconGraduationCapSolid = 'IconGraduationCapSolid',
    IconGripVerticalSoft = 'IconGripVerticalSoft',
    IconGripVerticalSolid = 'IconGripVerticalSolid',
    IconHandStopO = 'IconHandStopO',
    IconHandshake = 'IconHandshake',
    IconHardHatSolid = 'IconHardHatSolid',
    IconHistorySolid = 'IconHistorySolid',
    //IconHome = 'IconHome',
    IconHourglassEndSolid = 'IconHourglassEndSolid',
    IconHourglassHalfSolid = 'IconHourglassHalfSolid',
    IconHourglassSolid = 'IconHourglassSolid',
    IconHourglassStart = 'IconHourglassStart',
    IconIconsSolid = 'IconIconsSolid',
    IconIdCard = 'IconIdCard',
    IconInfoCircleSolid = 'IconInfoCircleSolid',
    INFO = 'info',
    IconLadder = 'IconLadder',
    IconLayerGroupSolid = 'IconLayerGroupSolid',
    IconLevelUp = 'IconLevelUp',
    IconLinkSolid = 'IconLinkSolid',
    IconListAlt = 'IconListAlt',
    IconListUlSolid = 'IconListUlSolid',
    IconLockSolid = 'IconLockSolid',
    IconLongArrowAltRightSolid = 'IconLongArrowAltRightSolid',
    IconLuggageCartSolid = 'IconLuggageCartSolid',
    IconMagicSolid = 'IconMagicSolid',
    IconMapMarkedAltSolid = 'IconMapMarkedAltSolid',
    IconMapMarkerSolid = 'IconMapMarkerSolid',
    IconMedkit = 'IconMedkit',
    IconMicroscopeSolid = 'IconMicroscopeSolid',
    IconMinusSolid = 'IconMinusSolid',
    IconMinusSquare = 'IconMinusSquare',
    IconMoneyBillWaveSolid = 'IconMoneyBillWaveSolid',
    IconNoImage = 'IconNoImage',
    IconNounBreak = 'IconNounBreak',
    IconPaperclipSolid = 'IconPaperclipSolid',
    //IconPenSquare = 'IconPenSquare',
    IconPencil = 'IconPencil',
    IconPencilRulerSolid = 'IconPencilRulerSolid',
    IconPhoneSolid = 'IconPhoneSolid',
    IconPlus = 'IconPlus',
    IconPlusCircle = 'IconPlusCircle',
    IconPlusSquare = 'IconPlusSquare',
    IconQuestionCircle = 'IconQuestionCircle',
    IconQuestionCircleSolid = 'IconQuestionCircleSolid',
    IconRandomSolid = 'IconRandomSolid',
    IconRedoAltSolid = 'IconRedoAltSolid',
    IconRepeat = 'IconRepeat',
    IconReply = 'IconReply',
    IconRobotSolid = 'IconRobotSolid',
    IconRocket = 'IconRocket',
    IconRouteSolid = 'IconRouteSolid',
    IconSatelliteDishSolid = 'IconSatelliteDishSolid',
    IconSave = 'IconSave',
    IconScrollSolid = 'IconScrollSolid',
    IconSearch = 'IconSearch',
    IconSearchMinusSolid = 'IconSearchMinusSolid',
    IconShapesSolid = 'IconShapesSolid',
    IconShippingFastSolid = 'IconShippingFastSolid',
    IconShoppingBasketSolid = 'IconShoppingBasketSolid',
    IconShortage = 'IconShortage',
    IconSignOutAltSolid = 'IconSignOutAltSolid',
    IconSitemap = 'IconSitemap',
    //IconSitemapSolid = 'IconSitemapSolid',
    IconSliders = 'IconSliders',
    IconSlidersHSolid = 'IconSlidersHSolid',
    IconSortAmountDownAltSolid = 'IconSortAmountDownAltSolid',
    IconSortSolid = 'IconSortSolid',
    IconStampSolid = 'IconStampSolid',
    IconStar = 'IconStar',
    IconStarSolid = 'IconStarSolid',
    IconStepBackwardSolid = 'IconStepBackwardSolid',
    IconStickyNoteO = 'IconStickyNoteO',
    IconStopwatchSolid = 'IconStopwatchSolid',
    IconStoreSolid = 'IconStoreSolid',
    IconStreamSolid = 'IconStreamSolid',
    IconSyncSolid = 'IconSyncSolid',
    IconTachometerAltSolid = 'IconTachometerAltSolid',
    IconTagSolid = 'IconTagSolid',
    IconTags = 'IconTags',
    IconThumbtackSolid = 'IconThumbtackSolid',
    IconTimesCircle = 'IconTimesCircle',
    IconToolsSolid = 'IconToolsSolid',
    IconTrash = 'IconTrash',
    IconTrashRestoreSolid = 'IconTrashRestoreSolid',
    IconTruckSolid = 'IconTruckSolid',
    IconUnlinkSolid = 'IconUnlinkSolid',
    IconUnlock = 'IconUnlock',
    IconUnlockAlt = 'IconUnlockAlt',
    IconUnlockAltColor = 'IconUnlockAltColor',
    IconUploadSolid = 'IconUploadSolid',
    IconUsd = 'IconUsd',
    IconUser = 'IconUser',
    IconUserPlus = 'IconUserPlus',
    IconUsers = 'IconUsers',
    IconWarehouseSolid = 'IconWarehouseSolid',
    IconWrench = 'IconWrench',
    IconWrenchSolid = 'IconWrenchSolid',
}

export const useIcon = () => {
    return (props:IconProps) => {
        const iconData = iconsMap[props.type];
        return <IconComponent component={iconData} className={props.className} style={props.style} />;
    };
};

const iconsMap: IconData = {
    [IconType.ANGLE_DOUBLE_LEFT] : IconAngleDoubleLeft,
    [IconType.ANGLE_DOUBLE_RIGHT] : IconAngleDoubleRight,
    [IconType.ANGLE_DOWN] : IconAngleDown,
    [IconType.ANGLE_LEFT] : IconAngleLeft,
    [IconType.ANGLE_RIGHT] : IconAngleRight,
    [IconType.ANGLE_UP] : IconAngleUp,
    [IconType.IconArchive] : IconArchive,
    [IconType.IconArrowCircleDownSolid] : IconArrowCircleDownSolid,
    [IconType.IconArrowCircleUpSolid] : IconArrowCircleUpSolid,
    [IconType.IconArrowRightSolid] : IconArrowRightSolid,
    [IconType.IconArrowsAltHSolid] : IconArrowsAltHSolid,
    [IconType.BARCODE] : IconBarcodeSolid,
    [IconType.IconBell] : IconBell,
    [IconType.IconBinoculars] : IconBinoculars,
    [IconType.IconBorderStyleSolid] : IconBorderStyleSolid,
    [IconType.IconBoxSolid] : IconBoxSolid,
    [IconType.IconBoxesSolid] : IconBoxesSolid,
    [IconType.IconBriefcase] : IconBriefcase,
    [IconType.IconBuilding] : IconBuilding,
    [IconType.IconBullhorn] : IconBullhorn,
    [IconType.IconCalculatorSolid] : IconCalculatorSolid,
    [IconType.IconCalendar] : IconCalendar,
    [IconType.IconCalendarPlus] : IconCalendarPlus,
    [IconType.IconCaretSquareLeftSolid] : IconCaretSquareLeftSolid,
    [IconType.IconCaretSquareRightSolid] : IconCaretSquareRightSolid,
    [IconType.IconCaretUpSolid] : IconCaretUpSolid,
    [IconType.IconCartArrowDownSolid] : IconCartArrowDownSolid,
    [IconType.IconCartPlusSolid] : IconCartPlusSolid,
    [IconType.IconCashRegisterSolid] : IconCashRegisterSolid,
    // [IconType.IconChalkboardSolid] : IconChalkboardSolid,
    // [IconType.IconChartArea] : IconChartArea,
    // [IconType.IconChartBar] : IconChartBar,
    // [IconType.IconChartLineSolid] : IconChartLineSolid,
    // [IconType.IconChartPieSolid] : IconChartPieSolid,
    [IconType.IconCheck] : IconCheck,
    [IconType.IconCheckCircle] : IconCheckCircle,
    [IconType.IconCheckSquare] : IconCheckSquare,
    [IconType.IconChevronRightSolid] : IconChevronRightSolid,
    [IconType.IconCircle] : IconCircle,
    [IconType.IconClipboard] : IconClipboard,
    [IconType.IconClipboardCheckSolid] : IconClipboardCheckSolid,
    [IconType.IconClipboardListSolid] : IconClipboardListSolid,
    [IconType.IconClockO] : IconClockO,
    [IconType.IconClone] : IconClone,
    [IconType.IconClose] : IconClose,
    [IconType.IconCogSolid] : IconCogSolid,
    [IconType.IconCogs] : IconCogs,
    [IconType.IconCoinsSolid] : IconCoinsSolid,
    [IconType.IconComments] : IconComments,
    [IconType.IconCompressArrowsAltSolid1] : IconCompressArrowsAltSolid1,
    [IconType.IconCompressArrowsAltSolid2] : IconCompressArrowsAltSolid2,
    [IconType.IconControlPointDuplicateBlack] : IconControlPointDuplicateBlack,
    [IconType.IconCopy] : IconCopy,
    [IconType.IconCopyAnonymous] : IconCopyAnonymous,
    [IconType.IconCrosshairsSolid] : IconCrosshairsSolid,
    [IconType.IconCube] : IconCube,
    [IconType.IconCubesSolid] : IconCubesSolid,
    [IconType.IconDollarSignSolid] : IconDollarSignSolid,
    [IconType.IconDollyFlatbedSolid] : IconDollyFlatbedSolid,
    [IconType.IconDollySolid] : IconDollySolid,
    [IconType.IconDownloadSolid] : IconDownloadSolid,
    [IconType.IconEdit] : IconEdit,
    [IconType.IconEllipsisHSolid] : IconEllipsisHSolid,
    [IconType.IconEnvelope] : IconEnvelope,
    [IconType.IconEnvelopeOpen] : IconEnvelopeOpen,
    [IconType.IconEraser] : IconEraser,
    [IconType.IconExcel] : IconExcel,
    [IconType.IconExchange] : IconExchange,
    [IconType.IconExclamationCircleSolid] : IconExclamationCircleSolid,
    [IconType.IconExclamationSolid] : IconExclamationSolid,
    [IconType.IconExclamationTriangleSolid] : IconExclamationTriangleSolid,
    [IconType.IconEyeSlashSolid] : IconEyeSlashSolid,
    [IconType.IconEyeSolid] : IconEyeSolid,
    [IconType.IconFile] : IconFile,
    [IconType.IconFileAlt] : IconFileAlt,
    [IconType.IconFileArchive] : IconFileArchive,
    [IconType.IconFileAudio] : IconFileAudio,
    [IconType.IconFileExcel] : IconFileExcel,
    [IconType.IconFileImage] : IconFileImage,
    [IconType.IconFileInvoiceDollarSolid] : IconFileInvoiceDollarSolid,
    [IconType.IconFilePdf] : IconFilePdf,
    [IconType.IconFilePowerpoint] : IconFilePowerpoint,
    [IconType.IconFileSignatureSolid] : IconFileSignatureSolid,
    [IconType.IconFileVideo] : IconFileVideo,
    [IconType.IconFileWord] : IconFileWord,
    [IconType.IconFileZipO] : IconFileZipO,
    [IconType.IconFilter] : IconFilter,
    [IconType.IconFlagCheckered] : IconFlagCheckered,
    [IconType.IconFolder] : IconFolder,
    [IconType.IconGlobeSolid] : IconGlobeSolid,
    [IconType.IconGraduationCapSolid] : IconGraduationCapSolid,
    [IconType.IconGripVerticalSoft] : IconGripVerticalSoft,
    [IconType.IconGripVerticalSolid] : IconGripVerticalSolid,
    [IconType.IconHandStopO] : IconHandStopO,
    [IconType.IconHandshake] : IconHandshake,
    [IconType.IconHardHatSolid] : IconHardHatSolid,
    [IconType.IconHistorySolid] : IconHistorySolid,
    //[IconType.IconHome] : IconHome,
    [IconType.IconHourglassEndSolid] : IconHourglassEndSolid,
    [IconType.IconHourglassHalfSolid] : IconHourglassHalfSolid,
    [IconType.IconHourglassSolid] : IconHourglassSolid,
    [IconType.IconHourglassStart] : IconHourglassStart,
    [IconType.IconIconsSolid] : IconIconsSolid,
    [IconType.IconIdCard] : IconIdCard,
    [IconType.IconInfoCircleSolid] : IconInfoCircleSolid,
    [IconType.INFO] : IconInfoSolid,
    [IconType.IconLadder] : IconLadder,
    [IconType.IconLayerGroupSolid] : IconLayerGroupSolid,
    [IconType.IconLevelUp] : IconLevelUp,
    [IconType.IconLinkSolid] : IconLinkSolid,
    [IconType.IconListAlt] : IconListAlt,
    [IconType.IconListUlSolid] : IconListUlSolid,
    [IconType.IconLockSolid] : IconLockSolid,
    [IconType.IconLongArrowAltRightSolid] : IconLongArrowAltRightSolid,
    [IconType.IconLuggageCartSolid] : IconLuggageCartSolid,
    [IconType.IconMagicSolid] : IconMagicSolid,
    [IconType.IconMapMarkedAltSolid] : IconMapMarkedAltSolid,
    [IconType.IconMapMarkerSolid] : IconMapMarkerSolid,
    [IconType.IconMedkit] : IconMedkit,
    [IconType.IconMicroscopeSolid] : IconMicroscopeSolid,
    [IconType.IconMinusSolid] : IconMinusSolid,
    [IconType.IconMinusSquare] : IconMinusSquare,
    [IconType.IconMoneyBillWaveSolid] : IconMoneyBillWaveSolid,
    [IconType.IconNoImage] : IconNoImage,
    [IconType.IconNounBreak] : IconNounBreak,
    [IconType.IconPaperclipSolid] : IconPaperclipSolid,
    //[IconType.IconPenSquare] : IconPenSquare,
    [IconType.IconPencil] : IconPencil,
    [IconType.IconPencilRulerSolid] : IconPencilRulerSolid,
    [IconType.IconPhoneSolid] : IconPhoneSolid,
    [IconType.IconPlus] : IconPlus,
    [IconType.IconPlusCircle] : IconPlusCircle,
    [IconType.IconPlusSquare] : IconPlusSquare,
    [IconType.IconQuestionCircle] : IconQuestionCircle,
    [IconType.IconQuestionCircleSolid] : IconQuestionCircleSolid,
    [IconType.IconRandomSolid] : IconRandomSolid,
    [IconType.IconRedoAltSolid] : IconRedoAltSolid,
    [IconType.IconRepeat] : IconRepeat,
    [IconType.IconReply] : IconReply,
    [IconType.IconRobotSolid] : IconRobotSolid,
    [IconType.IconRocket] : IconRocket,
    [IconType.IconRouteSolid] : IconRouteSolid,
    [IconType.IconSatelliteDishSolid] : IconSatelliteDishSolid,
    [IconType.IconSave] : IconSave,
    [IconType.IconScrollSolid] : IconScrollSolid,
    [IconType.IconSearch] : IconSearch,
    [IconType.IconSearchMinusSolid] : IconSearchMinusSolid,
    [IconType.IconShapesSolid] : IconShapesSolid,
    [IconType.IconShippingFastSolid] : IconShippingFastSolid,
    [IconType.IconShoppingBasketSolid] : IconShoppingBasketSolid,
    [IconType.IconShortage] : IconShortage,
    [IconType.IconSignOutAltSolid] : IconSignOutAltSolid,
    [IconType.IconSitemap] : IconSitemap,
    //[IconType.IconSitemapSolid] : IconSitemapSolid,
    [IconType.IconSliders] : IconSliders,
    [IconType.IconSlidersHSolid] : IconSlidersHSolid,
    [IconType.IconSortAmountDownAltSolid] : IconSortAmountDownAltSolid,
    [IconType.IconSortSolid] : IconSortSolid,
    [IconType.IconStampSolid] : IconStampSolid,
    [IconType.IconStar] : IconStar,
    [IconType.IconStarSolid] : IconStarSolid,
    [IconType.IconStepBackwardSolid] : IconStepBackwardSolid,
    [IconType.IconStickyNoteO] : IconStickyNoteO,
    [IconType.IconStopwatchSolid] : IconStopwatchSolid,
    [IconType.IconStoreSolid] : IconStoreSolid,
    [IconType.IconStreamSolid] : IconStreamSolid,
    [IconType.IconSyncSolid] : IconSyncSolid,
    [IconType.IconTachometerAltSolid] : IconTachometerAltSolid,
    [IconType.IconTagSolid] : IconTagSolid,
    [IconType.IconTags] : IconTags,
    [IconType.IconThumbtackSolid] : IconThumbtackSolid,
    [IconType.IconTimesCircle] : IconTimesCircle,
    [IconType.IconToolsSolid] : IconToolsSolid,
    [IconType.IconTrash] : IconTrash,
    [IconType.IconTrashRestoreSolid] : IconTrashRestoreSolid,
    [IconType.IconTruckSolid] : IconTruckSolid,
    [IconType.IconUnlinkSolid] : IconUnlinkSolid,
    [IconType.IconUnlock] : IconUnlock,
    [IconType.IconUnlockAlt] : IconUnlockAlt,
    [IconType.IconUnlockAltColor] : IconUnlockAltColor,
    [IconType.IconUploadSolid] : IconUploadSolid,
    [IconType.IconUsd] : IconUsd,
    [IconType.IconUser] : IconUser,
    [IconType.IconUserPlus] : IconUserPlus,
    [IconType.IconUsers] : IconUsers,
    [IconType.IconWarehouseSolid] : IconWarehouseSolid,
    [IconType.IconWrench] : IconWrench,
    [IconType.IconWrenchSolid] : IconWrenchSolid,
}

interface IconProps {
    type: IconType;
    className?: string;
    style?: CSSProperties;
}

/**
 * Пока эксперементальный компонент
 * @param props
 * @constructor
 */
export const IconX:FC<IconProps> = (props) => {
    return useIcon()(props);
};

import React from 'react';
import './InstancesWidget.less';
import { LocalizationEnum, localize } from '../../../../../../localization';

interface IProps {
    count?: number;
    shortage?: boolean;
    shortageType?: 'order' | 'book';
}

export const InstancesWidgetAnonymous = (props: IProps) => (
    <span
        className={`instanceWidget instanceWidgetAnonymous ${props.shortage ? 'instanceAnonymousShortage' : ''} ${
            props.shortageType ? (props.shortageType === 'order' ? 'instanceAnonymousOrderShortage' : 'instanceAnonymousBookShortage') : ''
        }`}
    >
        <div className="instanceWidget-content">
            {props.count}?&nbsp;<span>{localize(LocalizationEnum.ASPECT__PLURAL__ANONYMOUS, undefined, { value: props.count })}</span>
        </div>
    </span>
);

import { TransportationInfoRead } from '../../../../../../../../server';
import { TransportationDrawer } from '../TransportationDrawer';
import React, { memo } from 'react';
import { EntityGridDrawerWrapper } from '../../../../../../../../components/page/entityGrid/types/drawer';

export const TransportationDrawerWrapper: EntityGridDrawerWrapper<TransportationInfoRead> = memo((props) => {
    const { entityId, onClose, refetchEntitiesList, editActionForDrawer, deleteActionForDrawer, copyActionForDrawer, isLoading } = props;

    return (
        <TransportationDrawer
            transportationId={entityId}
            onClose={onClose}
            refetchList={refetchEntitiesList}
            editVehicle={editActionForDrawer}
            deleteVehicle={deleteActionForDrawer}
            copyVehicle={copyActionForDrawer}
            isEntityActionsLoading={isLoading}
        />
    );
});

import { FormUtils } from '../../../../core/utils/formUtils';
import { LocalizationEnum } from '../../../../localization';
import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { HeaderBlockFilters } from './headerBlockFilters.types';
import { UserSelect } from '../../../../components/v2/select/userSelect/userSelect';

export const headerBlockFilters = FormUtils.typedFormFields<HeaderBlockFilters>([
    {
        fields: [
            {
                label: 'Автор',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'authorId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: UserSelect,
            },
        ],
    },
]);

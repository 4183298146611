import { WorkPlanningsSortValue } from '../../../utils/data/sortData';
import React, { FC } from 'react';
import { SideBarGroup } from '../components/groupRenderer/components/sidebarGroup/utils/types';
import { RenterSidebarGroup } from '../components/groupRenderer/components/sidebarGroup/instances/RenterSidebarGroup/RenterSidebarGroup';
import { ProjectSidebarGroup } from '../components/groupRenderer/components/sidebarGroup/instances/ProjectSidebarGroup/ProjectSidebarGroup';
import { ProfessionSidebarGroup } from '../components/groupRenderer/components/sidebarGroup/instances/ProfessionSidebarGroup/ProfessionSidebarGroup';
import { StatusSidebarGroup } from '../components/groupRenderer/components/sidebarGroup/instances/StatusSidebarGroup/StatusSidebarGroup';

export const useGetSidebarGroup = (
    setChosenProfessionId: (value: React.SetStateAction<number | undefined>) => void
): ((sortByValue: WorkPlanningsSortValue | undefined) => FC<SideBarGroup> | undefined) => {
    return (sortByValue: WorkPlanningsSortValue | undefined): FC<SideBarGroup> | undefined => {
        return (
            {
                renter: RenterSidebarGroup,
                project: ProjectSidebarGroup,
                profession: ProfessionSidebarGroup(setChosenProfessionId),
                status: StatusSidebarGroup,
            } as Partial<Record<WorkPlanningsSortValue, FC>>
        )[sortByValue ?? ''];
    };
};

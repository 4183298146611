import React, {useState} from 'react';
import {DatePicker} from "antd";
import {DatePickerMode, DatePickerProps} from "antd/lib/date-picker/interface";

export interface DatePickerModifiedProps extends DatePickerProps {}

export const DatePickerModified = (props: DatePickerModifiedProps) => {

    const [mode, setMode] = useState<DatePickerMode>('date');

    return (
        <DatePicker
            {...props}
            mode={mode}
            onPanelChange={(value, m)=>{
                let newMode = m;
                if(mode === 'year' && m === 'date') newMode = 'month';
                setMode(newMode);
            }}
        />
    );
};

import React, { FC } from 'react';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { CounterpartyCreateModal } from './CounterpartyCreateModal';
import { createRenterModalFilters } from './CounterpartyCreateModalData';
import { ModalProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { RenterInfoRead } from '../../../../../../server';
import { useAppSelector } from '../../../../../../store/hooks';

export interface CounterpartyCreateModalNewProps extends ModalProps<RenterInfoRead> {
    counterpartyType?: 'renter' | 'supplier';
}

export const CounterpartyCreateModalNew: FC<CounterpartyCreateModalNewProps> = (props) => {
    const businessAccountId = useAppSelector((store) => store.system.businessAccountId);

    return (
        <CounterpartyCreateModal
            editMode={false}
            title={localize(LocalizationEnum.PAGE__RENTERS__FORM__FORM_TITLE__CREATE)}
            businessAccountId={businessAccountId}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            counterpartyType={props.counterpartyType}
            filters={createRenterModalFilters}
            modalName={'create-renter'}
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
        />
    );
};

import { ProductsListArgs } from './products.api.types';
import { ServerUtils } from '../../../../../core/utils/serverUtils';
import { NomenclatureEntityTypeCodeEnum, NomenclatureRecord } from '../../../../../server';
import { ProblemEnum } from '../../../../../types';
import { getStringServerProblem } from '../../../../../shared/util/utils';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../../components/page/entityGrid/types/api';

export abstract class ProductsApiUtils {
    static createEntityRequestFilters = (params: ProductsListArgs['params'], subrentModuleEnabled: boolean | undefined) => {
        const { status, hideArchive, problem, categoryIds, pricePerShift, hideVariants, instanceId } = params;

        const mainFilters = ServerUtils.createRequestFilters<NomenclatureRecord>([
            status && ['stateCode', 'EQ', status],
            hideArchive && ['archive', 'EQ', false],
            categoryIds && ['categoryIds', 'IN', Array.isArray(categoryIds) ? categoryIds : [categoryIds]],
            pricePerShift?.[0] != null && ['pricePerShift', 'GE', MoneyUtils.toCents(pricePerShift[0])],
            pricePerShift?.[1] != null && ['pricePerShift', 'LE', MoneyUtils.toCents(pricePerShift[1])],
            hideVariants
                ? ['nomenclatureEntityTypeCode', 'IN', [NomenclatureEntityTypeCodeEnum.PRODUCT]]
                : ['nomenclatureEntityTypeCode', 'IN', [NomenclatureEntityTypeCodeEnum.PRODUCT, NomenclatureEntityTypeCodeEnum.VARIANT]],
            instanceId && ['instanceIds', 'IN', Array.isArray(instanceId) ? instanceId : [instanceId]],
        ]);

        const additionalFilters = [
            problem &&
                !(
                    !subrentModuleEnabled &&
                    (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
                ) &&
                getStringServerProblem(problem),
        ].filter(Boolean) as string[];

        return mainFilters.concat(additionalFilters);
    };
    static createAvailableFiltersRequestFilters = (params: URLSearchParams): string[] => {
        const filters: string[] = [];

        if (params.get('hideVariants')) filters.push('nomenclatureEntityTypeCode;IN;PRODUCT');
        else {
            filters.push('nomenclatureEntityTypeCode;IN;PRODUCT;VARIANT');
        }

        return filters;
    };
    static intervalMapFunction: IntervalFiltersMetricsMap<NomenclatureRecord> = {
        pricePerShift: MoneyUtils.toWhole,
    };
    static intervalFormatStyleMap: IntervalFiltersFormatsMap<NomenclatureRecord> = {
        pricePerShift: 'currency',
    };
}

import React, { ReactNode } from 'react';
import { DownloadDocumentPopover, SUPPORTED_SERVER_METHOD } from '../../DownloadDocumentPopover';
import { availableFileTypes, ReportPageOnDownload } from './utils/data';

interface IProps {
    title: ReactNode;
    onDownload: ReportPageOnDownload;
    onVisibleChange?: (visible: boolean) => boolean;
    storageKey: string;
    reportInstanceId: number;
    defaultTemplateId?: number;
}

export class ExportToExcelReportPagePopover extends React.Component<IProps> {
    render() {
        return (
            <DownloadDocumentPopover
                availableFileTypes={[...availableFileTypes]}
                onVisibleChange={this.props.onVisibleChange}
                title={this.props.title}
                onDownload={this.props.onDownload}
                storageKey={this.props.storageKey}
                entityRemoteSelectCustomParams={{
                    reportInstanceId: this.props.reportInstanceId,
                    defaultTemplateId: this.props.defaultTemplateId,
                }}
                method={SUPPORTED_SERVER_METHOD.LIST_DOCUMENT_TEMPLATES_FOR_REPORT_INSTANCE}
                withTemplate
                isCompact
            >
                {this.props.children}
            </DownloadDocumentPopover>
        );
    }
}

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { inputNumberParser, onInputChange } from './numberInput.utils';
import classNames from 'classnames';

export interface NumberInputProps extends Omit<InputNumberProps, 'formatter'> {
    type?: 'number' | 'int' | 'uint';
    flexible?: boolean;
}

const symbolLength = 12;
const flexibleMaxSymbols = 14;
const buttonWidth = 56;

const maxLength = 11;
const maxValue = parseInt('9'.repeat(maxLength));

export const NumberInput: FC<NumberInputProps> = (props) => {
    const { value, onChange, type, flexible, className, ...inputProps } = props;
    const lastValueRef = useRef('');
    const [hasError, setHasError] = useState(false);

    const min = type === 'uint' ? 0 : Number.MIN_SAFE_INTEGER;
    const max = Number.MAX_SAFE_INTEGER;
    const precision = type === 'int' || type === 'uint' ? 0 : undefined;

    const currentWidth = flexible && value ? value.toString().length * symbolLength + buttonWidth * 2 + symbolLength * 1.5 : inputProps.style?.width;
    const maxWidth = flexible ? flexibleMaxSymbols * symbolLength + buttonWidth * 2 : inputProps.style?.maxWidth;

    const parser = useCallback(
        (displayValue: string | undefined): number | string => {
            if (displayValue && displayValue.length > maxLength) {
                setHasError(true);
                return lastValueRef.current;
            }

            let value = '' + inputNumberParser(displayValue);
            if (inputProps.parser) value = '' + inputProps.parser(value);

            const newValue = String(value).slice(0, maxLength);
            lastValueRef.current = newValue;

            return newValue;
        },
        [inputProps]
    );

    useEffect(() => {
        if (hasError) setTimeout(() => setHasError(false), 700);
    }, [hasError]);

    return (
        <InputNumber
            min={min}
            max={Math.min(max, maxValue)}
            precision={precision}
            {...inputProps}
            parser={parser}
            style={{
                ...inputProps.style,
                width: currentWidth,
                maxWidth: maxWidth,
            }}
            className={classNames({
                'has-error': hasError,
                fast: hasError,
            }, className)}
            {...value !== undefined ? {value} : {}}
            onChange={(value) => onInputChange(value, onChange)}
        />
    );
};

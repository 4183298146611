import {LocalizationEnum, localize} from '../../../../../localization';
import {OfferEditModal} from './offerEditModal';
import React, {memo} from 'react';
import {useAppSelector} from '../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../shared/reducers/system.reducer';
import {EntityGridEditModalWrapper} from '../../../../../components/page/entityGrid/types/modal';
import {editOfferModalFilters} from "../offerModalFormData";
import {ProjectInfoRead} from "../../../../../server";

export const OfferEditModalWrapper: EntityGridEditModalWrapper<ProjectInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <OfferEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование сметы'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            offer={entity}
            filters={editOfferModalFilters}
            modalName={'edit-offer'}
        />
    );
});

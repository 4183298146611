import React, { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { useVehicleByIdQuery } from '../../api/vehicles.api';
import { EntityDrawer } from '../../../../../../../components/v2/entityDrawer/EntityDrawer';
import { AttachmentParentEntityTypeCodeEnum, VehicleInfoRead } from '../../../../../../../server';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../../components/dataDisplay';
import { useDisplayItems } from '../hooks/VehicleDrawerHooks';
import { DrawerUtils } from '../../../../../../../shared/util/drawerUtils';
import { AttachmentsBlock } from '../../../../../../../components/attachmentsBlock/attachmentsBlock';
import { vehicleBodyTypeMap, vehicleTypeMap } from '../../utils/enumMappers';
import { toOptionalFixed } from '../../../../../../../shared/util/utils5';
import { MetricUtils } from '../../../../../../../core/utils/metricUtils';
import { VehicleDrawerTitle } from './VehicleDrawerTitle';
import { ContactsBlock } from '../../../../../../../components/contactsBlock/contactsBlock';
import { deleteContactFromVehicle } from '../../reducers/vehicle/vehicle.reducer';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';

export interface VehicleDrawerProps {
    vehicleId: number;
    onClose: () => void;
    refetchEntitiesList?: () => void;
    editVehicle?: (vehicle: VehicleInfoRead) => void;
    deleteVehicle?: (vehicle: VehicleInfoRead) => Promise<void>;
    archiveVehicle?: (vehicle: VehicleInfoRead) => void;
    isEntityActionsLoading?: boolean;
}

export const VehicleDrawer: FC<VehicleDrawerProps> = ({
    vehicleId,
    onClose,
    editVehicle,
    deleteVehicle,
    archiveVehicle,
    refetchEntitiesList,
    isEntityActionsLoading,
}) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);
    const dispatch = useAppDispatch();

    const {
        data: vehicle,
        error,
        isFetching,
        isLoading: isEntityLoading,
        refetch,
    } = useVehicleByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: vehicleId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const creationAuthorFullName = vehicle?.creationAuthorFullName ? DrawerUtils.getFullName(vehicle.creationAuthorFullName) : undefined;

    const {
        mainDisplayBlock,
        threeDimensionsDisplayItem,
        transportationCountDisplayItem,
        vehicleVolumeDisplayItem,
        loadingTypesDisplayItem,
    } = useDisplayItems(vehicle);

    const isLoading = isEntityLoading || isEntityActionsLoading;

    return (
        <EntityDrawer
            title={
                <VehicleDrawerTitle
                    vehicle={vehicle}
                    actionPopoverVisible={actionPopoverVisible}
                    setActionPopoverVisible={setActionPopoverVisible}
                    editVehicle={editVehicle}
                    deleteVehicle={deleteVehicle}
                    archiveVehicle={archiveVehicle}
                    onClose={onClose}
                    isLoading={isLoading || isFetching}
                />
            }
            onClose={onClose}
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
        >
            {vehicle && (
                <>
                    <DataDisplayBlock data={mainDisplayBlock} />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Марка и модель',
                                    type: DataDisplayItemType.String,
                                    value: vehicle.brandAndModel,
                                },
                                {
                                    label: 'Тип',
                                    type: DataDisplayItemType.String,
                                    value: vehicle.type ? vehicleTypeMap[vehicle.type] : undefined,
                                },
                            ],
                            [
                                {
                                    label: 'Грузоподъемность, т',
                                    type: DataDisplayItemType.String,
                                    value: vehicle.loadCapacity
                                        ? toOptionalFixed(MetricUtils.fromGramsToTons(vehicle.loadCapacity), 2)
                                        : undefined,
                                },
                                vehicleVolumeDisplayItem,
                            ],
                            [
                                threeDimensionsDisplayItem,
                                {
                                    label: 'Кузов',
                                    type: DataDisplayItemType.String,
                                    value: vehicle.bodyType ? vehicleBodyTypeMap[vehicle.bodyType] : undefined,
                                },
                            ],
                            [loadingTypesDisplayItem, transportationCountDisplayItem],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <ContactsBlock
                                            data={vehicle.contacts ?? []}
                                            parentEntityId={vehicle.id!}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.VEHICLE}
                                            deleteContact={async (contactId) => {
                                                await dispatch(
                                                    deleteContactFromVehicle({
                                                        businessAccountId: vehicle.businessAccountId,
                                                        id: vehicle.id,
                                                        vehicleInfoUpdate: vehicle,
                                                        contactId: contactId,
                                                    })
                                                );
                                                refetch();
                                                if (refetchEntitiesList) refetchEntitiesList();
                                            }}
                                        />
                                    ),
                                },
                            ],
                        ]}
                        paddings={false}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <AttachmentsBlock
                                            attachments={vehicle.attachments?.records ?? []}
                                            parentEntityId={vehicle.id!}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.VEHICLE}
                                            updateParentEntity={() => {
                                                refetch();
                                                if (refetchEntitiesList) refetchEntitiesList();
                                            }}
                                        />
                                    ),
                                },
                            ],
                        ]}
                        paddings={false}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Дата последнего изменения',
                                    type: DataDisplayItemType.DateWithTime,
                                    value: vehicle.lastUpdateDate,
                                },
                                {
                                    label: 'Автор последнего изменения',
                                    type: DataDisplayItemType.String,
                                    value: creationAuthorFullName,
                                },
                            ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Комментарий',
                                    type: DataDisplayItemType.Comment,
                                    value: vehicle.comment,
                                },
                            ],
                        ]}
                    />
                </>
            )}
        </EntityDrawer>
    );
};

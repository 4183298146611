import { EntityActions } from '../../../../components/page/entityGrid/hooks/useRowActions';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import { EntityGridRootPath, EntityGridURLParams } from '../../../../components/page/entityGrid/types/params';
import { ListPaymentsFilters, PaymentInfoReadWithId } from '../api/payments.api.types';
import { PageUrlParamsObject } from '../../../../core/hooks/urlParams/types';
import { push } from 'connected-react-router';
import { EntityActionType } from '../../../../../index';
import { useTransitWorkflowBulkMutation } from '../api/payments.api';
import { ActionTypeEnum } from '../../../../core/types/ActionTypeEnum';
import { PageURLParamDescriptions } from '../../../../core/utils/descriptions';
import { paymentsUtils } from '../utils/paymentsUtils';

export const useEntityActions = (
    rootPath: EntityGridRootPath,
    pageParamsObject: PageUrlParamsObject<EntityGridURLParams<ListPaymentsFilters>>,
    transitWorkflowBulk: ReturnType<typeof useTransitWorkflowBulkMutation>[0]
) => {
    const dispatch = useAppDispatch();

    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);

    const editPayment = useCallback(
        (
            payment: PaymentInfoReadWithId,
            options?: {
                withView?: boolean;
            }
        ) => {
            const searchParams = new URLSearchParams(urlSearchParams.toString());

            if (options?.withView) {
                const booleanParam = PageURLParamDescriptions.booleanParam.toString(true);
                if (booleanParam) {
                    searchParams.append('view', booleanParam);
                }
            }

            const path = `${rootPath}/${payment.id}/edit?` + searchParams.toString();

            dispatch(push(path));
        },
        [dispatch, rootPath, urlSearchParams]
    );

    const editPaymentForDrawerCallback = useCallback(
        (payment: PaymentInfoReadWithId) => {
            editPayment(payment, {
                withView: true,
            });
        },
        [editPayment]
    );

    const transitWorkflowBulkCallback = useCallback(
        (payment: PaymentInfoReadWithId, action: ActionTypeEnum) => {
            if (payment.id == null || payment.businessVersion == null) return;

            transitWorkflowBulk({
                directives: [
                    {
                        id: payment.id,
                        businessVersion: payment.businessVersion,
                        transitionCode: paymentsUtils.getTransitionCodeByActionType(action),
                    },
                ],
            });
        },
        [transitWorkflowBulk]
    );

    const onRowAction = useCallback(
        (payment: PaymentInfoReadWithId, action: EntityActionType) => {
            const actionHandler: Partial<Record<ActionTypeEnum, Function>> = {
                [ActionTypeEnum.EDIT_ENTITY]: () => editPayment(payment),
                [ActionTypeEnum.PAYMENT_MAKE]: () => transitWorkflowBulkCallback(payment, action as ActionTypeEnum),
                [ActionTypeEnum.PAYMENT_RECOVER]: () => transitWorkflowBulkCallback(payment, action as ActionTypeEnum),
                [ActionTypeEnum.PAYMENT_DELETE]: () => transitWorkflowBulkCallback(payment, action as ActionTypeEnum),
            };

            actionHandler[action]?.();
        },
        [transitWorkflowBulkCallback, editPayment]
    );

    return useMemo(
        () =>
            ({
                editAction: editPayment,
                editActionForDrawer: editPaymentForDrawerCallback,
                onRowAction,
            } satisfies EntityActions<PaymentInfoReadWithId>),
        [editPayment, editPaymentForDrawerCallback, onRowAction]
    );
};

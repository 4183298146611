import { BodyTypeCodeEnum, LoadingTypeCodeEnum, VehicleTypeCodeEnum } from '../../../../../../server';

export const vehicleTypeMap: Record<VehicleTypeCodeEnum, string> = {
    [VehicleTypeCodeEnum.TRACTORTRUCK]: 'Тягач',
    [VehicleTypeCodeEnum.STRAIGHTTRUCK]: 'Одиночка',
    [VehicleTypeCodeEnum.TRAILER]: 'Прицеп',
    [VehicleTypeCodeEnum.SEMITRAILER]: 'Полуприцеп',
    [VehicleTypeCodeEnum.MINIBUS]: 'Микроавтобус',
};

export const vehicleLoadingTypeMap: Record<LoadingTypeCodeEnum, string> = {
    [LoadingTypeCodeEnum.REAR]: 'Задняя',
    [LoadingTypeCodeEnum.REARHYDROBOARD]: 'Задняя гидроборт',
    [LoadingTypeCodeEnum.SIDE]: 'Боковая',
    [LoadingTypeCodeEnum.TOP]: 'Верхняя',
};

export const vehicleBodyTypeMap: Record<BodyTypeCodeEnum, string> = {
    [BodyTypeCodeEnum.SIDEOPEN]: 'Бортовой открытый',
    [BodyTypeCodeEnum.SIDEAWNING]: 'Бортовой тентованный',
    [BodyTypeCodeEnum.BOOTH]: 'Будка',
    [BodyTypeCodeEnum.ISOTHERMALBOOTH]: 'Будка изотерм',
};

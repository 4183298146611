import React, { FC, memo, useState } from 'react';
import { Button, Checkbox, Icon, Popover } from 'antd';
import { IconLinkSolid, IconSitemap, IconUnlinkSolid } from '../../icons';
import AdditionalEntityActions from '../additionalEntityActions';
import { BaseCategoriesSelect, BaseCategoriesSelectProps } from '../../baseCategoriesSelect/baseCategoriesSelect';
import { CategorisedEntityCodeEnum, KitRecord, RenterRecord } from '../../../server';
import './AdditionalEntityActionForCategories.less';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ArrayUtils } from '../../../core/utils/arrayUtils';
import { changeCategoryBinding } from '../../../modules/main/categories/reducers/categories.reducer';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { NomenclatureRecordCustom } from '../../../modules/main/inventory/products/api/products.api.types';
import { useCategoryIdStorageValue } from './hooks/useCategoryIdStorageValue';
import { useChosenIds } from './hooks/useChosenIds';

interface NestedAdditionalEntityActionsProps {
    categories: BaseCategoriesSelectProps['categories'];
    selectedCategoriesKeys: string[] | undefined;
    selectedRecords: RenterRecord[] | NomenclatureRecordCustom[] | KitRecord[];
    entityTypeCode: CategorisedEntityCodeEnum;
    clearSelection: () => void;
}

export const AdditionalEntityActionForCategories: FC<NestedAdditionalEntityActionsProps> = memo(
    ({ categories, selectedCategoriesKeys, selectedRecords, entityTypeCode, clearSelection }) => {
        const intl = useIntl();
        const dispatch = useAppDispatch();
        const { businessAccountId } = useAppSelector((state) => state.system);
        const [selectedKeyFromStorage, setSelectedStorageKey] = useCategoryIdStorageValue(entityTypeCode);
        const [selectedKey, setSelectedKey] = useState<number | undefined>(selectedKeyFromStorage);
        const [isCategoryBased, setIsCategoryBased] = useState(false);
        const [selectedCategoriesCurrentIndex, setSelectedCategoriesCurrentIndex] = useState(0);
        const renterCategories = categories;

        const recordsForFindingId =
            entityTypeCode === CategorisedEntityCodeEnum.PRODUCT
                ? (selectedRecords as NomenclatureRecordCustom[]).map((record) => ({ ...record, id: record.productId }))
                : selectedRecords;

        const { assignIds, detachIds } = useChosenIds(recordsForFindingId, selectedKey, isCategoryBased);

        const setSelectedKeyAndSaveToStore = (key: typeof selectedKey) => {
            setSelectedKey(key);
            setSelectedStorageKey(key);
        };

        const selectCurrentCategoryHandler = () => {
            if (selectedCategoriesKeys == null) return;

            setSelectedKeyAndSaveToStore(Number(selectedCategoriesKeys[selectedCategoriesCurrentIndex]));
            setSelectedCategoriesCurrentIndex((selectedCategoriesCurrentIndex + 1) % selectedCategoriesKeys.length);
        };

        const changeCategoryBindingCallback = (ids: number[], createLinks: boolean) => {
            if (!selectedKey) return;

            clearSelection();
            dispatch(
                changeCategoryBinding({
                    businessAccountId,
                    categoryBulkLinkObj: {
                        entityTypeCode,
                        categoryId: selectedKey,
                        entityIds: ArrayUtils.removeDuplicates(ids),
                    },
                    createLinks,
                    entityTypeCode,
                    intl,
                    makeMainCategory: isCategoryBased,
                })
            );
        };

        return (
            <Popover
                overlayClassName={classNames('grid-additional-actions-popover', 'rr-grid-actions-popover-nested')}
                autoAdjustOverflow
                placement="bottomLeft"
                trigger="click"
                align={{ offset: [12, 0] }}
                content={
                    <>
                        <div className={'rr-grid-actions-popover-header1'}></div>
                        <div className={'rr-grid-actions-popover-content'}>
                            <div className={'category-select-block'}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>Категория</span>
                                    {selectedCategoriesKeys ? (
                                        <a className={'rr-click-link'} onClick={selectCurrentCategoryHandler}>
                                            Выбрать текущую
                                        </a>
                                    ) : undefined}
                                </div>
                                <BaseCategoriesSelect
                                    categories={renterCategories}
                                    placeholder={'Выберите категорию'}
                                    dropdownClassName={'rr-base-categories-select_dropdown-nested'}
                                    value={selectedKey}
                                    onChange={(newSelectedKey) => {
                                        if (selectedCategoriesKeys) {
                                            const selectIndex = selectedCategoriesKeys.indexOf(String(newSelectedKey));
                                            if (selectIndex !== -1) {
                                                setSelectedCategoriesCurrentIndex((selectIndex + 1) % selectedCategoriesKeys.length);
                                            } else {
                                                setSelectedCategoriesCurrentIndex(0);
                                            }
                                        }
                                        setSelectedKeyAndSaveToStore(newSelectedKey);
                                    }}
                                    multiple={false}
                                    showSearch={renterCategories.length > 5}
                                />
                                <Checkbox
                                    value={isCategoryBased}
                                    onChange={(event) => {
                                        setIsCategoryBased(event.target.checked);
                                    }}
                                    style={{ paddingTop: 4 }}
                                >
                                    Сделать основной
                                </Checkbox>
                            </div>
                            <div className={'category-action-block'}>
                                <Button
                                    className={'action green'}
                                    block
                                    disabled={assignIds.length === 0}
                                    onClick={() => changeCategoryBindingCallback(assignIds, true)}
                                >
                                    <Icon className={'icon green'} component={IconLinkSolid} />
                                    <span>Назначить категорию</span>
                                    {assignIds.length > 0 ? <span className={'categories-count'}>({assignIds.length})</span> : undefined}
                                </Button>
                                <Button
                                    className={'action red'}
                                    block
                                    disabled={detachIds.length === 0}
                                    onClick={() => changeCategoryBindingCallback(detachIds, false)}
                                >
                                    <Icon className={'icon red'} component={IconUnlinkSolid} />
                                    <span>Отвязать категорию</span>
                                    {detachIds.length > 0 ? <span className={'categories-count'}>({detachIds.length})</span> : undefined}
                                </Button>
                            </div>
                        </div>
                    </>
                }
            >
                <Button className={'grid-additional-actions-button'} block>
                    <Icon component={IconSitemap} className={'change-category-button'} />
                    <AdditionalEntityActions content={<></>}>
                        <span>Изменить категории</span>
                    </AdditionalEntityActions>
                </Button>
            </Popover>
        );
    }
);

import {CrewMemberInfoRead, CrewMemberRecord, serverApi} from '../../../../../../server';
import {showNotification} from '../../../../../../components/notification/showNotification';
import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../../../../../store/rtqQueryApi';
import {ListCrewMembersArgs, ListCrewMembersData} from './types';
import {AxiosError} from 'axios';
import {CrewMembersApiUtils} from './crewMembers.api.utils';
import {AvailableIntervalFiltersData, GetEntityByIdArgs} from '../../../../../../components/page/entityGrid/types/api';

export const getCrewMemberById = async (args: GetEntityByIdArgs) => {
    const { businessAccountId, entityId } = args;

    try {
        return await serverApi.getCrewMember(businessAccountId, entityId);
    } catch (error) {
        showNotification('error', 'Не удалось загрузить проектного работника');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const crewMembersApi = createApi({
    reducerPath: 'crewMembersApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['CrewMembersList', 'CrewMember'],
    endpoints: (builder) => ({
        listCrewMembers: builder.query<ListCrewMembersData, ListCrewMembersArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = CrewMembersApiUtils.createRequestFilters(params);

                try {
                    const { data: crewMembersListAvailableFilters } = await serverApi.listCrewMembersAvailableFiltersValues(
                        businessAccountId
                    );
                    const { data: crewMembers } = await serverApi.listCrewMembers(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        params.sortBy || undefined,
                        params.sortOrder,
                        params.search
                    );

                    const satisfiedAvailableFilters: typeof crewMembersListAvailableFilters = {
                        filters: crewMembersListAvailableFilters.filters.map((filter) => ({
                            ...filter,
                            fieldName: filter.fieldName.split('.')[1],
                        })),
                    };

                    return {
                        data: {
                            entitiesData: crewMembers,
                            availableIntervalFiltersData: {
                                values: satisfiedAvailableFilters,
                                maps: {
                                    formats: CrewMembersApiUtils.pricePerShiftIntervalFormatMap,
                                    metrics: CrewMembersApiUtils.pricePerShiftIntervalMetricsMap,
                                },
                            } as AvailableIntervalFiltersData<CrewMemberRecord>,
                        },
                    };
                } catch (e) {
                    return {
                        error: e as AxiosError,
                    };
                }
            },
            providesTags: ['CrewMembersList'],
        }),
        crewMemberById: builder.query<CrewMemberInfoRead, GetEntityByIdArgs>({
            queryFn: getCrewMemberById,
            providesTags: ['CrewMember'],
        }),
    }),
});

export const { useListCrewMembersQuery, useLazyListCrewMembersQuery, useCrewMemberByIdQuery } = crewMembersApi;

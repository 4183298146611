import React from 'react';
import { Icon } from 'antd';
import { DocumentTypeCodeEnum } from '../../../../../server/api';
import { DownloadDocumentPopover, DownloadDocumentPopoverProps } from '../../DownloadDocumentPopover';
import { IconClipboardListSolid } from '../../../../icons';
import { availableFileTypes, BillOfLadingFileType, BillOfLadingOnDownload } from './utils/data';

interface ExportBillOfLadingPopoverProps extends Pick<DownloadDocumentPopoverProps<BillOfLadingFileType>, 'warnings'> {
    onDownload: BillOfLadingOnDownload;
    getPopupContainer?: DownloadDocumentPopoverProps<BillOfLadingFileType>['getPopupContainer'];
}

export class ExportBillOfLadingPopover extends React.Component<ExportBillOfLadingPopoverProps> {
    render() {
        return (
            <DownloadDocumentPopover
                documentType={DocumentTypeCodeEnum.BILLOFLADING}
                availableFileTypes={[...availableFileTypes]}
                withTemplate
                storageKey={'billOfLading'}
                title={
                    <>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconClipboardListSolid} />
                        <div>Транспортная накладная</div>
                    </>
                }
                onDownload={this.props.onDownload}
                getPopupContainer={this.props.getPopupContainer}
                warnings={this.props.warnings}
                showLegalDetails
                isCompact
            >
                {this.props.children}
            </DownloadDocumentPopover>
        );
    }
}

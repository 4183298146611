import { CSSProperties } from 'react';

export const getStylesForBtnError = (isBtnError: boolean, currentShortagedBookedElementsCount: number) => {
    const styles: CSSProperties = {
        margin: '0px 5px',
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    };

    if (isBtnError) {
        styles['border'] = '1px solid #F4516c';
        styles['backgroundColor'] = '#F4516c';
    } else {
        styles['backgroundColor'] = '#fff';
        styles['border'] = '1px solid #F4516c';
        styles['color'] = '#F4516c';
    }

    if (currentShortagedBookedElementsCount === 0) {
        delete styles['backgroundColor'];
        delete styles['color'];
    }

    if (currentShortagedBookedElementsCount) {
        styles['borderColor'] = '#F4516c';
    } else {
        styles['borderColor'] = '#d9d9d9';
    }

    return styles;
};

import React, {FC} from 'react';
import {Switch} from "antd";
import {RecalculateShiftsCountButton} from "../../../../../../modules/main/operationForm/RecalculateShiftsCountButton";
import {RecalculateShiftCountBlockProps} from "../../rangePicker.types";
import './recalculateBlock.less';

interface Props {
    value?: boolean;
    onChange?: (value: boolean)=>void;
    recalculateShiftCountBlockProps?: RecalculateShiftCountBlockProps;
}

const componentClassName = 'rr-recalculate-shifts-block';

export const RecalculateBlock:FC<Props> = (props) => {

    return (
        <div className={componentClassName}>
            <div>
                <RecalculateShiftsCountButton
                     hasOwnShiftLength={props.recalculateShiftCountBlockProps?.hasOwnShiftLength}
                     hint={props.recalculateShiftCountBlockProps?.shiftLengthHint}
                     shiftLengthInMinutes={props.recalculateShiftCountBlockProps?.shiftLengthInMin}
                    from={props.recalculateShiftCountBlockProps?.fromShiftCount}
                    to={props.recalculateShiftCountBlockProps?.toShiftCount}
                />
                <Switch
                    checked={props.value}
                    onChange={async (checked: boolean) => {
                        props.onChange?.(checked);
                    }}
                />
            </div>
            <div>
                Пересчет смен
            </div>
        </div>
    );
};

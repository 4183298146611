import { EntityActionsGridButtonsHook } from '../../../../../../components/page/entityGrid/hooks/useActionButtonsProps';
import { CrewMemberRecord, CrewMemberTransitionCodeEnum } from '../../../../../../server';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { RoundButton } from '../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Icon } from 'antd';
import { IconArchive, IconCheck, IconLevelUp, IconTrash, IconUnlock } from '../../../../../../components/icons';
import { showConfirm2 } from '../../../../../../components/confirm/showConfirm';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { archiveCrewMembers, deleteCrewMembers, setStatusForCrewMembers } from '../reducers/crewMembers/crewMembers.reducer';

export const useCrewMemberEntityActionsButtons = (({ selectedRecords, gridRef }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const deleteSelectedCrewMembers = useCallback(async () => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => ({
            id,
            businessVersion,
        }));

        let yes = await showConfirm2({
            intl: intl,
            title:
                versionedSelectedEntitiesObjects.length > 1
                    ? 'Удалить проектных работников безвозвратно?'
                    : 'Удалить проектного работника безвозвратно?',
        });

        if (yes) {
            dispatch(
                deleteCrewMembers({
                    businessAccountId,
                    versionedEntityObjList: {
                        entities: versionedSelectedEntitiesObjects,
                    },
                })
            );

            gridRef?.current?.clearSelection();
        }
    }, [businessAccountId, dispatch, gridRef, intl, selectedRecords]);

    const archiveSelectedCrewMembers = useCallback(() => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, archive, businessVersion }) => {
            return {
                id,
                archive: !archive,
                businessVersion,
            };
        });

        dispatch(
            archiveCrewMembers({
                businessAccountId,
                workflowDirectiveBulk: {
                    directives: versionedSelectedEntitiesObjects,
                },
            })
        );

        gridRef?.current?.clearSelection();
    }, [businessAccountId, dispatch, gridRef, selectedRecords]);

    const transitSelectedProfessions = useCallback(
        (transitionCode: CrewMemberTransitionCodeEnum) => {
            const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => {
                return {
                    id,
                    businessVersion,
                    transitionCode,
                };
            });

            dispatch(
                setStatusForCrewMembers({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: versionedSelectedEntitiesObjects,
                    },
                })
            );

            gridRef?.current?.clearSelection();
        },
        [businessAccountId, dispatch, gridRef, selectedRecords]
    );

    return useMemo(
        () => [
            _.every(selectedRecords, (crewMember: CrewMemberRecord) => !crewMember.archive && crewMember.archivable) ? (
                <RoundButton
                    key={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                    title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                    onClick={archiveSelectedCrewMembers}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}</span>
                </RoundButton>
            ) : null,
            _.every(selectedRecords, (crewMember: CrewMemberRecord) => crewMember.archive) ? (
                <RoundButton
                    title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                    onClick={archiveSelectedCrewMembers}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}</span>
                </RoundButton>
            ) : null,
            _.every(selectedRecords, (crewMember: CrewMemberRecord) =>
                crewMember.availableTransitionCodes?.includes(CrewMemberTransitionCodeEnum.ACTIVATE)
            ) ? (
                <RoundButton
                    title={LocalizationEnum.ASPECT__ACTION__ACTIVATE}
                    colorScheme={'success'}
                    onClick={() => {
                        transitSelectedProfessions(CrewMemberTransitionCodeEnum.ACTIVATE);
                    }}
                >
                    <Icon component={IconCheck} />
                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                </RoundButton>
            ) : null,
            _.every(selectedRecords, (crewMember: CrewMemberRecord) =>
                crewMember.availableTransitionCodes?.includes(CrewMemberTransitionCodeEnum.BLOCK)
            ) ? (
                <RoundButton
                    title={LocalizationEnum.ASPECT__ACTION__BLOCK}
                    colorScheme={'blocked'}
                    onClick={() => {
                        transitSelectedProfessions(CrewMemberTransitionCodeEnum.BLOCK);
                    }}
                >
                    <Icon component={IconUnlock} />
                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                </RoundButton>
            ) : null,
            _.every(selectedRecords, (crewMember: CrewMemberRecord) => crewMember.deleteable) ? (
                <RoundButton title={LocalizationEnum.ASPECT__ACTION__DELETE} onClick={deleteSelectedCrewMembers} colorScheme={'DELETE'}>
                    <Icon component={IconTrash} />
                    {localize(LocalizationEnum.ASPECT__ACTION__DELETE, 'span')}
                </RoundButton>
            ) : null,
        ],
        [archiveSelectedCrewMembers, deleteSelectedCrewMembers, selectedRecords, transitSelectedProfessions]
    );
}) satisfies EntityActionsGridButtonsHook<CrewMemberRecord>;

import {useCallback} from 'react';
import {ProfessionAssignmentObjWrite} from '../../../../../../../../../../../server';
import {updateCrewMemberProfessions} from '../../../../../../reducers/crewMember/crewMember.reducer';
import {showNotification} from '../../../../../../../../../../../components/notification/showNotification';
import {useAppDispatch, useAppSelector} from '../../../../../../../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../../../../../../../shared/reducers/system.reducer';
import {ProfessionsCompositionListColumnsObj} from '../../columns/professionsCompositionColumns';

interface UseOnSaveGrid {
    editData: ProfessionsCompositionListColumnsObj[] | undefined;
    crewMemberId: number;
    setIsEdit: (value: boolean) => void;
}

export const useOnSaveEditGrid = (props: UseOnSaveGrid) => {
    const { editData, crewMemberId, setIsEdit } = props;

    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        if (editData == null) return;

        const updatedEntities: ProfessionAssignmentObjWrite[] = editData.map(
            (data): ProfessionAssignmentObjWrite => ({
                professionId: data.professionId,
                rate: data.rate,
            })
        );

        try {
            await dispatch(
                updateCrewMemberProfessions({
                    businessAccountId,
                    crewMemberId,
                    professionAssignmentObjWrite: updatedEntities,
                })
            );
            showNotification('success', 'Список специальностей обновлён');
            setIsEdit(false);
        } catch (e) {
            showNotification('error', 'Не удалось обновить список специальностей');
        }
    }, [businessAccountId, crewMemberId, dispatch, editData, setIsEdit]);
};

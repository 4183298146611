import { locale as messages_en } from './en_US';
import { locale as messages_ru } from './ru_RU';
import antd_locale_en from 'antd/lib/locale-provider/en_US';
import antd_locale_ru from 'antd/lib/locale-provider/ru_RU';
// Это нужно для подключения локалей для moment.js
import 'moment/locale/ru';
import { countries_en } from './countries/en';
import { countries_ru } from './countries/ru';
// TODO Не забыть вставить нужные локали потом
// Это нужно что-бы работала прюрализация в Safari
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';

export interface ILocaleObject {
    code: string;
    locale: string;
    messages: any;
    antd_locale: any;
    countries: any;
}

export const locales: ILocaleObject[] = [
    {
        code: 'en_US',
        locale: 'en',
        messages: messages_en,
        antd_locale: {
            ...antd_locale_en,
            DatePicker: {
                ...antd_locale_en.DatePicker,
                lang: {
                    ...antd_locale_en.DatePicker.lang,
                    monthSelect: '',
                    yearSelect: '',
                    monthFormat: 'MMMM', // TODO Баг? Без этого в календаре в шапке месяц выводиться сокращенно: Сент. 2019
                },
                dateFormat: 'M/D/YYYY', // TODO Без этого DatePicker'e применяется форматирование по умолчанию https://github.com/ant-design/ant-design/issues/13196
            },
        },
        countries: countries_en,
    },
    {
        code: 'ru_RU',
        locale: 'ru',
        messages: messages_ru,
        antd_locale: {
            ...antd_locale_ru,
            DatePicker: {
                ...antd_locale_ru.DatePicker,
                lang: {
                    ...antd_locale_ru.DatePicker.lang,
                    monthSelect: '',
                    yearSelect: '',
                    placeholder: 'Выбрать дату',
                    monthFormat: 'MMMM', // TODO Баг? Без этого в календаре в шапке месяц выводиться сокращенно: Сент. 2019
                },
                dateFormat: 'DD.MM.YYYY', // TODO Без этого DatePicker'e применяется форматирование по умолчанию https://github.com/ant-design/ant-design/issues/13196
            },
        },
        countries: countries_ru,
    },
];

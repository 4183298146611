import { FormFieldsGroup, FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField } from '../../../../../core/utils/formUtils';
import { ListOffersParams } from '../../api/types';
import { LocalizationEnum } from '../../../../../localization';
import { CounterpartyActivityTypeCodeEnum, ProjectTypeCodeEnum, TagEntityTypeCode } from '../../../../../server';
import React from 'react';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { TagSelect } from '../../../../../components/select/TagSelect/TagSelect';
import { UserSelect } from '../../../../../components/v2/select/userSelect/userSelect';
import { ProjectSelect, ProjectSelectProps } from '../../../../../components/v2/select/projectSelect/projectSelect';
import { OfferStateBadge } from '../../components/OfferStateBadge/OfferStateBadge';
import { offerAllStateCodes } from '../../data/offersData';
import classNames from 'classnames';

const searchField: TypedFormField<ListOffersParams> = {
    label: '',
    placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
    id: 'search',
    type: FormItemType.Search,
    className: 'rr-input-gray',
};

const stateCodeField: TypedFormField<ListOffersParams> = {
    label: LocalizationEnum.ASPECT__FILTERS__STATUS,
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'stateCode',
    type: FormItemType.MultiSelect,
    className: classNames('rr-statuses-filter', 'rr-select-gray', 'calendar-filters'),
    values: (form) => {
        return offerAllStateCodes.map((code) => ({
            name: <OfferStateBadge type={code} dot={true} />,
            value: code,
        }));
    },
};

const tagsField: TypedFormField<ListOffersParams> = {
    label: 'Теги',
    placeholder: 'Любые',
    id: 'tags',
    type: FormItemType.Component,
    style: { minWidth: 120 },
    component: TagSelect,
    componentProps: {
        tagType: TagEntityTypeCode.PROJECT,
    },
    className: 'rr-select-gray',
};

const projectIdField: TypedFormField<ListOffersParams> = {
    label: 'Проект',
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'projectId',
    type: FormItemType.Component,
    style: { minWidth: 120 },
    component: ProjectSelect,
    componentProps: (): ProjectSelectProps => ({
        filters: {
            projectType: [ProjectTypeCodeEnum.BASE],
        },
    }),
};

const hideField: TypedFormField<ListOffersParams> = {
    id: 'hide',
    label: 'Скрыть',
    placeholder: 'Не выбрано',
    type: FormItemType.Select,
    style: { minWidth: 160, maxWidth: 260 },
    className: 'rr-select-gray',
    values: [{ name: 'Отмененные', value: 'cancelled' }],
};

const assigneeIdField: TypedFormField<ListOffersParams> = {
    label: 'Ответственный',
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'assigneeId',
    type: FormItemType.Component,
    style: { minWidth: 160 },
    component: UserSelect,
};

const partOfThePriceField: TypedFormField<ListOffersParams> = {
    label: 'Включена в стоимость',
    placeholder: 'Не выбрано',
    id: 'partOfThePrice',
    type: FormItemType.Select,
    style: { minWidth: 160 },
    values: [
        { name: 'Да', value: true },
        { name: 'Нет', value: false },
    ],
    className: 'rr-select-gray',
};

const renterIdField: TypedFormField<ListOffersParams> = {
    label: LocalizationEnum.ASPECT__FILTERS__RENTER,
    placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
    id: 'renterId',
    type: FormItemType.Component,
    style: { minWidth: 160 },
    component: EntityRemoteSelect,
    componentProps: {
        operationName: 'listRenters',
        nameField: 'shortName',
        sortBy: 'lastActivityDate',
        sortOrder: 'DESC',
        getEntityOperationName: 'getRenterById',
        showSearch: true,
        filters: [
            `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.RENTERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
        ],
    },
    getInitialValue: (value) => {
        return { key: value, label: '' };
    },
};

export const offersFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListOffersParams>([
    {
        fields: [searchField, stateCodeField, partOfThePriceField, tagsField, hideField],
    },
    {
        fields: [assigneeIdField, projectIdField, renterIdField],
    },
]);

export const projectOffersFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListOffersParams>([
    {
        fields: [searchField, stateCodeField, partOfThePriceField, tagsField, hideField],
    },
    {
        fields: [assigneeIdField],
    },
]);

import React from "react";
import {Checkbox} from "antd";

// export function ReactTableRowSelectCheckbox({indeterminate, className = '',
//                                    ...rest
//                                }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
//     const ref = React.useRef<HTMLInputElement>(null!);
//
//     React.useEffect(() => {
//         if (typeof indeterminate === 'boolean') {
//             ref.current.indeterminate = !rest.checked && indeterminate
//         }
//     }, [ref, indeterminate]);
//
//     return (
//         <input
//             type="checkbox"
//             ref={ref}
//             className={className + ' cursor-pointer'}
//             {...rest}
//         />
//     )
// }

// export const ReactTableRowSelectCheckbox = React.forwardRef<any, any>(
//     ({indeterminate, ...rest}, ref) => {
//         const defaultRef = React.useRef();
//         const resolvedRef = ref || defaultRef as any;
//
//         // React.useEffect(() => {
//         //     if (resolvedRef && resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
//         // }, [resolvedRef, indeterminate]);
//
//         return (
//             <Checkbox indeterminate={resolvedRef.current?.indeterminate} className={'rr-grid-select-checkbox'}
//                       ref={resolvedRef} {...rest} />
//         );
//     }
// );

interface IProps {
}

export class ReactTableRowSelectCheckbox extends React.PureComponent<IProps> {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Checkbox className={'rr-grid-select-checkbox'} {...this.props} />
        );
    }
}

import { ProjectRecord } from '../../../../server';
import React, { useCallback } from 'react';
import { _Grid } from '../../../../components/grid/Grid';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { includeOrExcludeOfferInProjectPriceBulk } from '../reducers/offers/offers.reducer';

export const useIncludeOrExcludeOfferInProjectPriceForOffersCallback = (
    selectedRecords: ProjectRecord[],
    gridRef?: React.RefObject<_Grid>
) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return useCallback(() => {
        const versionedEntityObjList = selectedRecords.map(({ id, businessVersion }) => ({ id, businessVersion }));
        dispatch(
            includeOrExcludeOfferInProjectPriceBulk({
                businessAccountId,
                include: selectedRecords[0].partOfThePrice !== true,
                versionedEntityObjList: {
                    entities: versionedEntityObjList,
                },
            })
        );

        if (gridRef != null) {
            gridRef.current?.clearSelection();
        }
    }, [businessAccountId, dispatch, gridRef, selectedRecords]);
};

import {CreatePaymentMethodArgs, DeletePaymentMethodArgs, PaymentMethodState, UpdatePaymentMethodArgs} from './paymentMethod.types';
import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {serverApi} from '../../../../../../server';
import {showNotification} from '../../../../../../components/notification/showNotification';
import {push} from 'connected-react-router';
import {getPaymentMethodByIdQueryFunction, paymentMethodsApi} from '../../api/paymentMethods.api';
import {getServerError} from '../../../../../../shared/util/utils';
import {ParamsUtils} from '../../../../../../core/utils/paramsUtils';
import {AppDispatch, GetState} from "../../../../../../../index";

const ACTION_TYPES = {
    CREATE_PAYMENT_METHOD: 'paymentMethod/CREATE_PAYMENT_METHOD',
    UPDATE_PAYMENT_METHOD: 'paymentMethod/UPDATE_PAYMENT_METHOD',
    DELETE_PAYMENT_METHOD: 'paymentMethod/DELETE_PAYMENT_METHOD',
    LOAD_PAYMENT_METHOD: 'paymentMethod/LOAD_PAYMENT_METHOD',
};

export const initialState: PaymentMethodState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createPaymentMethodThunk = createAsyncThunk(ACTION_TYPES.CREATE_PAYMENT_METHOD, async (args: CreatePaymentMethodArgs) => {
    const {businessAccountId, infoCreate} = args;

    try {
        return await serverApi.createPaymentMethod(businessAccountId, infoCreate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const createPaymentMethod = (args: CreatePaymentMethodArgs, rootPath: string, urlSearchParams?: URLSearchParams) => (dispatch, getState) => {
    return dispatch(createPaymentMethodThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Способ оплаты создан');
            let path = rootPath;
            if (urlSearchParams) {
                path += `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}`;
            }
            dispatch(push(path));
            dispatch(paymentMethodsApi.util?.invalidateTags(['PaymentMethodsList', 'PaymentMethod']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось создать способ оплаты');
        });
};

export const updatePaymentMethodThunk = createAsyncThunk(ACTION_TYPES.UPDATE_PAYMENT_METHOD, async (args: UpdatePaymentMethodArgs) => {
    const {businessAccountId, id, infoUpdate} = args;
    try {
        return await serverApi.updatePaymentMethodById(businessAccountId, id, infoUpdate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const updatePaymentMethod = (args: UpdatePaymentMethodArgs) => (dispatch: AppDispatch, getState: GetState) => {
    return dispatch(updatePaymentMethodThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Способ оплаты обновлен');
            //dispatch(goBack());
            dispatch(paymentMethodsApi.util?.invalidateTags(['PaymentMethodsList', 'PaymentMethod']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось обновить способ оплаты');
        });
};

export const deletePaymentMethodThunk = createAsyncThunk(ACTION_TYPES.DELETE_PAYMENT_METHOD, async (args: DeletePaymentMethodArgs) => {
    const {businessAccountId, id, businessVersion} = args;
    try {
        return await serverApi.deletePaymentMethodById(businessAccountId, id, {businessVersion});
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});

export const deletePaymentMethod = (args: DeletePaymentMethodArgs) => (dispatch: AppDispatch, getState: GetState) => {
    return dispatch(deletePaymentMethodThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Способ оплаты удален');
            dispatch(paymentMethodsApi.util?.invalidateTags(['PaymentMethodsList', 'PaymentMethod']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось удалить способ оплаты');
        });
};

export const loadPaymentMethod = createAsyncThunk(ACTION_TYPES.CREATE_PAYMENT_METHOD, getPaymentMethodByIdQueryFunction);

const slice = createSlice({
    name: 'paymentMethod',
    initialState,
    reducers: {
        clearPaymentMethod: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(loadPaymentMethod.pending, updatePaymentMethodThunk.pending, createPaymentMethodThunk.pending), (state, action) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(loadPaymentMethod.fulfilled, updatePaymentMethodThunk.fulfilled, createPaymentMethodThunk.fulfilled), (state, action) => {
                state.updating = false;
                state.entity = action.payload.data;
            })
            .addMatcher(isAnyOf(loadPaymentMethod.rejected, updatePaymentMethodThunk.rejected, createPaymentMethodThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = action.error;
            });
    },
});

export const {clearPaymentMethod} = slice.actions;
export default slice.reducer;

import React from "react";
import {Icon, Input} from "antd";
import {InputProps} from "antd/lib/input";

export class SearchInput extends React.PureComponent<InputProps> {
    render() {
        return (
            <Input autoComplete={'off'} {...this.props} prefix={<Icon type={'search'} />} className={'rr-custom-search ' + this.props.className} maxLength={255} />
        );
    }
}

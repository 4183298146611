import { ColumnTypes, TableColumn } from '../../../../components/grid/Table';
import { GridColumnCreator } from '../../../../components/grid/gridColumnCreator';
import { InventoryMovementRecord, RentActivityFrameTypeCodeEnum } from '../../../../server';
import { InventoryMovementsUtils } from '../utils/inventoryMovementsUtils';
import React from 'react';
import { ProductInstancePopover } from '../../inventory/instances/components/productInstancePopover/productInstancePopover';

const instancesSizes = GridColumnCreator.getColumnSizes(50);

type _InventoryMovementRecord = InventoryMovementRecord & {
    'instances.instanceId': any;
};

export const inventoryMovementsListColumns: TableColumn<
    _InventoryMovementRecord & {
        'instances.instanceId': any;
    }
>[] = [
    GridColumnCreator.createShortNameColumn<_InventoryMovementRecord>(
        'product',
        (row) => ({
            baId: row.businessAccountId,
            id: row.productId,
            name: row.productShortName,
            stopPropagation: true,
        }),
        {
            title: 'Продукт',
            dataIndex: 'productShortName',
        }
    ),
    GridColumnCreator.createProductVariantColumn<_InventoryMovementRecord>(
        (row) => ({
            baId: row.businessAccountId,
            variantId: row.variantId,
            variantName: row.variantName,
            productName: row.productShortName || '',
            productId: row.productId,
        }),
        {
            title: 'Вариант продукта',
            dataIndex: 'variantName',
        }
    ),
    {
        title: 'Экземпляры',
        dataIndex: 'instances',
        type: ColumnTypes.CustomRender,
        minWidth: instancesSizes.min,
        width: instancesSizes.width,
        maxWidth: instancesSizes.max,
        resizable: true,
        render: (instances: _InventoryMovementRecord['instances']) => {
            return (
                <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'}>
                    {instances
                        ?.filter(
                            (instance): instance is Required<NonNullable<_InventoryMovementRecord['instances']>[number]> =>
                                instance.instanceNameOrCode != null && instance.instanceId != null
                        )
                        ?.map((instance, index) => (
                            <span
                                style={{
                                    fontWeight: 400,
                                }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <ProductInstancePopover instanceId={instance.instanceId} placement={'right'}>
                                    <span
                                        className={'link-hover-underline'}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {instance.instanceNameOrCode}
                                    </span>
                                </ProductInstancePopover>
                                {index !== instances.length - 1 ? `, ` : null}
                            </span>
                        ))}
                </div>
            );
        },
    },
    GridColumnCreator.createIconTextColumn<_InventoryMovementRecord>(
        (row) => ({
            icon: InventoryMovementsUtils.getEventTypeIconByCode(row.eventType),
            text: InventoryMovementsUtils.getEventTypeByCode(row.eventType),
        }),
        {
            title: 'Тип события',
            dataIndex: 'eventType',
            minWidth: 250,
        }
    ),
    {
        title: 'Число экземпляров',
        dataIndex: 'instanceCount',
        type: ColumnTypes.String,
        minWidth: 132,
        resizable: false,
    },
    GridColumnCreator.createAttachmentsCountColumn<_InventoryMovementRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentsCount',
    }),
    {
        title: 'Цена за экземпляр',
        dataIndex: 'pricePerInstance',
        type: ColumnTypes.Money,
        minWidth: 132,
        resizable: false,
    },
    {
        title: 'Итоговая сумма',
        dataIndex: 'totalSum',
        type: ColumnTypes.Money,
        minWidth: 132,
        resizable: false,
    },
    GridColumnCreator.createCounterpartyColumn<_InventoryMovementRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.counterpartyId,
            name: row.counterpartyShortName,
        }),
        {
            title: 'Контрагент',
            dataIndex: 'counterpartyShortName',
        }
    ),
    GridColumnCreator.createActivityFrameColumn<_InventoryMovementRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.projectId,
            name: row.projectShortName,
            activityFrameTypeCode: RentActivityFrameTypeCodeEnum.PROJECT,
        }),
        {
            title: 'Проект',
            dataIndex: 'projectShortName',
        }
    ),
    GridColumnCreator.createUserColumn<_InventoryMovementRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор',
            dataIndex: 'lastUpdateAuthorShortName',
        }
    ),
    GridColumnCreator.createLastUpdateDateColumn({
        title: 'Дата',
        dataIndex: 'eventDate',
    }),
];

import React from 'react';
import {Modal} from "antd";
import {LocalizationEnum, localizeIntl} from '../../../localization';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {DynamicForm} from "../../../components";
import {FormItemType} from "../../../components/dynamicForm/DynamicForm";
import debounce from "lodash/debounce";
import {WrappedFormUtils} from "antd/lib/form/Form";
import './businessAccountDeleteModal.less';

interface IState {
    okButtonDisabled: boolean;
}

interface IProps extends WrappedComponentProps {
    onClose?: () => void;
    onOk?: (code: string) => void;
    companyShortName?: string;
}

class BusinessAccountDeleteModal extends React.PureComponent<IProps, IState> {

    _formRef;

    constructor(props) {
        super(props);

        this.state = {
            okButtonDisabled: !false
        };
    };

    getFormRef = ref => {
        return this._formRef = ref && ref.props && ref.props.form ? ref.props.form : null;
    };

    onOk = () => {
        if (this.props.onOk) this.props.onOk(this._formRef.getFieldValue('shortName'));
    };

    onCancel = () => {
        if (this.props.onClose) this.props.onClose();
    };

    render() {
        let okButtonStyle = this.state.okButtonDisabled ? {
            color: '#cccccc',
            border: '1px solid #cccccc',
            opacity: '1'
        } : {color: '#f4516c', border: '1px solid #f4516c'};

        return (
            <Modal
                className={'rr-businessAccount-delete-modal'}
                visible={true}
                title={'Удалить безвозвратно аккаунт компании?'}
                okButtonProps={{type: 'link', style: okButtonStyle, disabled: this.state.okButtonDisabled}}
                okText={localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__YES)}
                cancelText={localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__NO)}
                cancelButtonProps={{type: 'primary'}}
                width={560}
                onOk={this.onOk}
                onCancel={this.onCancel}
                closable={true}
                maskClosable={true}
                centered={true}
            >
                <DynamicForm data={[{
                    fields: [
                        {
                            label: 'Краткое наименование для подтверждения',
                            id: 'shortName',
                            required: true,
                            type: FormItemType.String,
                            validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                                if (value !== this.props.companyShortName) {
                                    cb('Проверочная строка не совпадает с целевой');
                                    this.setState({okButtonDisabled: true});
                                } else {
                                    cb();
                                    this.setState({okButtonDisabled: false});
                                }
                            }, 0),
                        }
                    ]
                }]} wrappedComponentRef={this.getFormRef}/>
            </Modal>
        );
    }
}

export default injectIntl(BusinessAccountDeleteModal);

import { useFormatDecimal } from '../../core/hooks/useFormatDecimal';

interface FormatPercentsProps {
    value: number;
    withoutDots?: boolean;
    precision?: number;
}

export const FormatPercents = ({ value, withoutDots, precision, ...props }: FormatPercentsProps) => {
    return useFormatDecimal(value, precision, 'percents', withoutDots);
};

import React, {InputHTMLAttributes} from 'react';
import {Input} from 'antd';
import {isOnlyDigits, removeSymbol} from '../../shared/util/isNumber';

interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> {
    value?: string;
    onChange: (value?: string) => void;
    placeholder?: string;
}

export const onlyDigitsInputNumber: React.FC<InputProps> = ({value, placeholder, onChange, ...inputProps}) => {
    return (
        <Input
            value={value}
            placeholder={placeholder}
            onChange={({target: {value}}) => {
                const correctValue = removeSymbol(value, '#');
                if (isOnlyDigits(correctValue) || !correctValue)
                    onChange(correctValue === null ? undefined : correctValue);
            }}
            {...inputProps}
        />
    );
};

import React from 'react';
import { Col, Icon, Row } from 'antd';
import { IRootState } from '../../../shared/reducers';
import { loadEntity, reset } from './reducers/element.reducer';
import { connect } from 'react-redux';
import {
    ImageObj,
    OperationTypeCodeEnum,
    PricingSchemeExternalRepresentationObj,
    RentActivityFrameTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../server/api';
import { IconNoImage } from '../../../components/icons';
import { Status } from '../../../components/status/status';
import { getImagePath, isDefined } from '../../../shared/util/utils';
import { Link } from 'react-router-dom';
import { OperationElement, removeConcurrentOperation, startNewOperation } from '../operationForm/reducers/operationForm.reducer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { showConfirm } from '../../../components/confirm/showConfirm';
import { showNotification } from '../../../components/notification/showNotification';
import { push } from 'connected-react-router';
import { LocalizationEnum, localize, localizeIntl } from '../../../localization';
import { HelpTooltip } from '../../../components/helpTooltip/HelpTooltip';
import { PopoverProblemLine } from '../../../components/gridRowPopover/popoverProblemLine';
import { ProductVariantPopover } from '../inventory/variants/components/productVariantPopover/productVariantPopover';
import { PopoverHeader } from '../../../components/gridRowPopover/PopoverHeader';
import { createOperation } from './create-operation-util';
import { convertRentElementInfoReadToRentElementsGridItem, RentElementsGridItem } from '../../../types';
import { ElementPopoverFooter } from './components/ElementPopover/element-popover-footer';
import { ElementPopoverAuditRecords } from './components/ElementPopover/element-popover-auditRecords';
import { ElementPopoverMainInfo } from './components/ElementPopover/element-popover-mainInfo';
import Spin from '../../../components/spin/spin';
import { AlertSimple } from '../../../components/alert/AlertSimple';
import { Avatar } from '../../../components/avatar/avatar';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';
import { listConcurrentOperationsAndShowConfirm } from '../operationForm/listConcurrentOperationsAndShowConfirm';
import { MoneyUtils } from '../../../core/utils/moneyUtils';

interface IElementPopoverContentProps extends StateProps, DispatchProps, WrappedComponentProps {
    closeCb: (key?: string) => void;
    entityId?: number;
    record?: OperationElement | RentElementsGridItem;
    onDataChangedCb: () => void;
    hideActionsButton?: boolean;
}

class ElementsElementPopoverContent extends React.PureComponent<IElementPopoverContentProps> {
    private ignoreEntityData = false;

    constructor(props) {
        super(props);

        let idElement = this.props.record?.id || this.props.entityId || 0;

        if (!this.props.entity || this.props.entity.id !== idElement) {
            this.props.reset();
            this.ignoreEntityData = true;
            this.props.loadEntity(this.props.businessAccountId, idElement);
        }
    }

    componentDidUpdate(prevProps: Readonly<IElementPopoverContentProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (!isDefined(prevProps.entity) && isDefined(this.props.entity)) this.props.onDataChangedCb();
    }

    onLinkClicked = () => {
        this.props.closeCb();
    };

    onRowAction = (item: RentElementsGridItem, action: string, instanceCount: number, targetStateCode?: RentStateCodeEnum) => {
        this.createOperation(action as OperationTypeCodeEnum, [item], targetStateCode, instanceCount);
    };

    createOperation = async (
        code: OperationTypeCodeEnum,
        selectedItems: Array<RentElementsGridItem>,
        targetStateCode?: RentStateCodeEnum,
        instanceCount?: number
    ) => {
        let canCreate = true;
        await this.setState({ correctionPopoverVisible: false, operationIsLoading: true });
        this.props.closeCb();
        if (this.props.operationFormMnemoKey) {
            canCreate = await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
            );
        }

        if (canCreate) {
            try {
                let activityFrameType: RentActivityFrameTypeCodeEnum = RentActivityFrameTypeCodeEnum.PROJECT;
                if (this.props.entity?.subrentId) activityFrameType = RentActivityFrameTypeCodeEnum.SUBRENT;
                else if (this.props.entity?.templateId) activityFrameType = RentActivityFrameTypeCodeEnum.TEMPLATE;
                canCreate = await listConcurrentOperationsAndShowConfirm(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entity?.projectId || this.props.entity?.subrentId || this.props.entity?.templateId || -1,
                    activityFrameType
                );
            } catch (e) {
                this.setState({ operationIsLoading: false });
                return;
            }
        }

        if (canCreate) {
            try {
                if (selectedItems[0].parent) {
                    selectedItems.push(selectedItems[0].parent);
                }
                if (this.props.currentOperationUUID) {
                    try {
                        //await this.props.removeConcurrentOperation(this.props.currentOperationUUID);
                    } catch (e) {}
                }
                await createOperation(
                    this.props.intl,
                    isDefined(selectedItems[0].templateId),
                    this.props.startNewOperation,
                    this.props.businessAccountId,
                    code,
                    selectedItems,
                    targetStateCode,
                    instanceCount
                );
                this.setState({ operationIsLoading: false });
                this.props.push(`/${this.props.businessAccountId}/operation?tab=elements`);
            } catch (e) {
                this.setState({ operationIsLoading: false });
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            }
        } else {
            this.setState({ operationIsLoading: false });
        }
    };

    onTryClick = () => {
        let idElement = this.props.record?.id || this.props.entityId || 0;
        this.props.loadEntity(this.props.businessAccountId, idElement);
    };

    render() {
        let { entity, record, loading } = this.props;

        if (this.ignoreEntityData) {
            entity = null;
            this.ignoreEntityData = false;
        }

        console.log('ElementsElementPopoverContent render()', this.ignoreEntityData, this.props, entity);

        let entityId = this.props.record?.id || this.props.entityId || 0;
        let numberInActivityFrame = this.props.record?.numberInActivityFrame;

        let instanceCount: number | undefined,
            productShortName: string | undefined,
            rentPeriodEndDate: any,
            rentPeriodStartDate: any,
            discount: number | undefined,
            shiftCount: number | undefined,
            calendarShiftCount: number | undefined,
            isProjectTemplate: undefined | boolean;

        let shortageEntity = false;
        let shortageInstanceCountEntity = 0;
        let delayEntity = false;

        let problemsAndWarnings;

        let effectivePrice: number | undefined;
        let finalTotalPrice: number | undefined;

        let withTrackedInstances: boolean | undefined; // Флаг значит что точно будет показан блок с экземплярами

        let auditRecordsCount: number | undefined;

        let productId: number | undefined;
        let variantId: number | undefined;
        let variantName: string | undefined;
        let renterId: number | undefined;
        let projectId: number | undefined;
        let subrentId: number | undefined;
        let templateId: number | undefined;

        let image: ImageObj | undefined;

        let stateCode: RentStateCodeEnum | undefined;

        let basePrice: number | undefined;
        let pricingScheme: PricingSchemeExternalRepresentationObj | undefined;

        if (record) {
            // record возможна 2х вариантов: RentElementsGridItem и OperationElement
            if ('rentTerms' in record) {
                // это для RentElementsGridItem
                record = record as RentElementsGridItem;
                //isProjectTemplate = !!record.templateId;
                instanceCount = record.instanceCount;
                productShortName = record.productShortName;
                rentPeriodEndDate = record.rentTerms.rentPeriodEndDate;
                rentPeriodStartDate = record.rentTerms.rentPeriodStartDate;
                discount = record.rentTerms.discount;
                shiftCount = record.rentTerms.shiftCount;
                const calendarShifts: any = record;
                calendarShiftCount = calendarShifts.calendarShifts.shiftCount;
                finalTotalPrice = record.finalTotalPrice;

                if (
                    record.instanceTrackingTypeCode === 'PRODUCT_INSTANCE_TRACKED' ||
                    record.instanceTrackingTypeCode === 'PRODUCT_VARIANT_INSTANCE_TRACKED'
                ) {
                    if ((record.instanceIds && record.instanceIds.length > 0) || record.anonymousInstanceCount > 0) {
                        withTrackedInstances = true;
                    }
                }
                isProjectTemplate = isDefined(record.templateId);
                auditRecordsCount = record.operationIds.length;
                variantName = record.variantName;
                productId = record.productId;
                variantId = record.variantId;

                renterId = record.renterId;
                projectId = record.projectId;
                subrentId = record.subrentId;
                templateId = record.templateId;

                console.log('RRRRR', subrentId, record);

                image = record.image;

                stateCode = record.stateCode;

                basePrice = record.basePriceAtTheTimeOfCreation;
                pricingScheme = record.effectivePricingScheme;
            } else if (!('rentTerms' in record)) {
                // это для OperationElement
                record = record as OperationElement;
                //isProjectTemplate = !!record.pro;
                instanceCount = record.instanceCount;
                productShortName = record.productShortName;
                rentPeriodEndDate = record.rentPeriodEndDate;
                rentPeriodStartDate = record.rentPeriodStartDate;
                discount = record.discount;
                shiftCount = record.shiftCount;
                finalTotalPrice = record.finalTotalPrice;

                if (
                    record.instanceTrackingTypeCode === 'PRODUCT_INSTANCE_TRACKED' ||
                    record.instanceTrackingTypeCode === 'PRODUCT_VARIANT_INSTANCE_TRACKED'
                ) {
                    if ((record.instanceIds && record.instanceIds.length > 0) || record.anonymousInstanceCount > 0) {
                        withTrackedInstances = true;
                    }
                }
                isProjectTemplate = isDefined(record.templateId);
                variantName = record.variantName;
                productId = record.productId;
                variantId = record.variantId;
                renterId = record.renterId;
                projectId = record.projectId;
                templateId = record.templateId;
                image = record.mainImage;
                stateCode = record.stateCode;
            }
            if ('shortage' in record) shortageEntity = record.problemsAndWarnings ? record.problemsAndWarnings.anyShortage : false;
            if ('delay' in record) delayEntity = record.problemsAndWarnings ? record.problemsAndWarnings.anyDelay : false;

            problemsAndWarnings = record.problemsAndWarnings;
        }

        if (entity) {
            instanceCount = entity.instanceCount;
            productShortName = entity.productShortName;
            rentPeriodEndDate = entity.rentTerms.rentPeriodEndDate;
            rentPeriodStartDate = entity.rentTerms.rentPeriodStartDate;
            discount = entity.rentTerms.discount;
            shiftCount = entity.rentTerms.shiftCount;
            shortageEntity = entity.rentIndicators.problemsAndWarnings.anyShortage;
            shortageInstanceCountEntity = entity.shortageInstanceCount;
            delayEntity = entity.rentIndicators.problemsAndWarnings.anyDelay;
            effectivePrice = entity.effectivePrice;
            finalTotalPrice = MoneyUtils.calculateFinalTotalPrice(
                MoneyUtils.calculateFinalInstancePrice(effectivePrice, discount),
                instanceCount,
                shiftCount
            );

            if (
                entity.instanceTrackingTypeCode === 'PRODUCT_INSTANCE_TRACKED' ||
                entity.instanceTrackingTypeCode === 'PRODUCT_VARIANT_INSTANCE_TRACKED'
            ) {
                if ((entity.instances && entity.instances.length > 0) || entity.anonymousInstanceCount > 0) {
                    withTrackedInstances = true;
                }
            }
            isProjectTemplate = isDefined(entity.templateId);
            auditRecordsCount = entity.rentAuditRecordList.records.length;
            variantName = entity.variantName;
            productId = entity.productId;
            variantId = entity.variantId;

            renterId = entity.counterpartyId;
            projectId = entity.projectId;
            templateId = entity.templateId;

            image = entity.image;
            stateCode = entity.stateCode;

            if (!calendarShiftCount) {
                calendarShiftCount = entity.calendarShiftCount;
            }
        }

        let shiftPrice = isDefined(effectivePrice) && isDefined(discount) ? effectivePrice - (effectivePrice * discount) / 100 : undefined;

        const { canViewFinancialData } = this.props;

        let hideCalendar = this.props.entity?.projectIsOffer === true ? true : undefined;
        if(this.props.record && 'projectIsOffer' in this.props.record && this.props.record.projectIsOffer === true){
            hideCalendar = true;
        }

        return (
            <Spin spinning={loading} delay={200}>
                <div className={'rr-grid-actions-popover-header'} style={{ display: 'flex' }}>
                    <Avatar
                        size={40}
                        style={{ minWidth: 40, marginRight: 16, backgroundColor: '#ffffff', verticalAlign: 'middle' }}
                        src={getImagePath(image, '40')}
                    >
                        <span style={{ fontSize: 18 }}>
                            <Icon component={IconNoImage} style={{ color: '#DDDDDD' }} />
                        </span>
                    </Avatar>
                    {isDefined(instanceCount) && isDefined(productShortName) && (
                        <span style={{ marginRight: 22, fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Link to={`/${this.props.businessAccountId}/inventory/products/${productId}`}>
                                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__001}>{instanceCount}</HelpTooltip>
                                &nbsp;x&nbsp;
                                <HelpTooltip
                                    content={
                                        <div>
                                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PRODUCT)}: {productShortName}
                                        </div>
                                    }
                                >
                                    <span className="popover-header-name">{productShortName}</span>
                                </HelpTooltip>
                            </Link>
                            {variantName && ': '}
                            {productId && variantId ? (
                                <HelpTooltip
                                    content={
                                        <div>
                                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT)}: {variantName}
                                        </div>
                                    }
                                >
                                    <span>
                                        <ProductVariantPopover productId={productId} variantId={variantId} onLinkClick={this.props.closeCb}>
                                            <span
                                                style={{ fontWeight: 400, fontSize: 16, cursor: 'pointer' }}
                                                className="popover-header-name"
                                            >
                                                {variantName}
                                            </span>
                                        </ProductVariantPopover>
                                    </span>
                                </HelpTooltip>
                            ) : null}
                        </span>
                    )}
                    {isDefined(stateCode) && (
                        <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__003}>
                            <Status entityType={'element'} style={{ marginRight: 16, overflow: 'unset' }} code={stateCode} />
                        </HelpTooltip>
                    )}
                    <div style={{ flex: '1 1 auto' }}></div>

                    <div style={{ marginRight: '-16px' }}>
                        <PopoverHeader
                            businessAccountId={this.props.businessAccountId}
                            title={<></>}
                            closeCb={this.props.closeCb}
                            entityType={'element'}
                            entity={
                                this.props.record ||
                                (this.props.entity && convertRentElementInfoReadToRentElementsGridItem(this.props.entity)) ||
                                {}
                            }
                            onAction={this.onRowAction}
                            closeElementPopup={this.props.closeCb}
                            hideActionsButton={this.props.hideActionsButton || this.props.entity?.projectIsSimpleOrder}
                            hideCalendar={hideCalendar}
                            projectIsSimpleOrder={this.props.entity?.projectIsSimpleOrder}
                        />
                    </div>
                </div>
                {this.props.loadingError && (
                    <AlertSimple
                        message={''}
                        description={
                            <>
                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__POPUP_NOTIFICATION__FAILED_TO_LOAD_DATA)}.{' '}
                                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.onTryClick}>
                                    {localize(LocalizationEnum.PAGE__ELEMENTS__ACTIONS__RETRY_AFTER_ERROR)}
                                </span>
                            </>
                        }
                        type="error"
                        closable
                        style={{ margin: 8 }}
                        afterClose={() => {
                            this.props.onDataChangedCb();
                        }}
                    />
                )}
                {shortageEntity || delayEntity ? (
                    <PopoverProblemLine
                        renterId={renterId}
                        projectId={projectId}
                        subrentId={subrentId}
                        elementId={entityId}
                        productId={productId}
                        variantId={variantId}
                        shortageCount={shortageInstanceCountEntity}
                        rentPeriodStartDate={rentPeriodStartDate}
                        rentPeriodEndDate={rentPeriodEndDate}
                        problemsAndWarnings={problemsAndWarnings}
                        stateCode={stateCode}
                        closeCb={this.props.closeCb}
                        entityType={'element'}
                        intl={this.props.intl}
                        rentElementsKitId={entity?.rentElementsKitId}
                    />
                ) : null}
                <Row style={{ padding: '18px 32px' }}>
                    <Col>
                        <ElementPopoverMainInfo
                            isProjectTemplate={isProjectTemplate}
                            withTrackedInstances={withTrackedInstances}
                            numberInActivityFrame={numberInActivityFrame || 0}
                            shiftCount={shiftCount}
                            calendarShiftCount={calendarShiftCount}
                            effectivePrice={effectivePrice}
                            discount={discount}
                            rentPeriodStartDate={rentPeriodStartDate}
                            rentPeriodEndDate={rentPeriodEndDate}
                            entity={entity}
                            closeCb={this.props.closeCb}
                            problemsAndWarnings={problemsAndWarnings}
                            showFinancialData={canViewFinancialData}
                        />
                        <ElementPopoverAuditRecords
                            onCollapseCB={() => {
                                this.props.onDataChangedCb();
                            }}
                            businessAccountId={this.props.businessAccountId}
                            auditRecords={entity?.rentAuditRecordList.records}
                            auditRecordsCount={auditRecordsCount}
                            onLinkClicked={this.onLinkClicked}
                            isPopover={true}
                        />
                    </Col>
                </Row>
                {canViewFinancialData && (
                    <ElementPopoverFooter
                        effectivePrice={effectivePrice}
                        shiftPrice={shiftPrice}
                        finalTotalPrice={finalTotalPrice}
                        shiftCount={shiftCount}
                        pricingScheme={pricingScheme}
                        basePrice={basePrice || 0}
                    />
                )}
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entity: storeState.element.entity,
        loading: storeState.element.loading,
        loadingError: storeState.element.loadingError,
        businessAccountId: storeState.system.businessAccountId,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
    };
};

const mapDispatchToProps = { loadEntity, reset, startNewOperation, push, removeConcurrentOperation };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ElementsElementPopoverContent));

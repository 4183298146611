import React, {ChangeEvent} from 'react';
import {connect} from "react-redux";
import {Card, Col, Icon, Input, Row} from "antd";
import moment from "moment";
import FormValue from "../../../../../components/formValue/FormValue";
import {formatMoney} from "../../../../../shared/util/formatMoney";
import {RangePickerValue} from "antd/lib/date-picker/interface";
import {
    commentSelector, commentVisibleSelector, reloadAllTimetables,
    resetDefaultDiscount,
    resetDefaultRentPeriod,
    resetDefaultShiftCount,
    setActualizeDateMode,
    setAutoRecalculateShiftsCountMode,
    setComment, setCommentVisibility,
    setDefaultDiscount,
    setDefaultRentPeriod,
    setDefaultShiftCount,
    setEndDateForAllElements, setLoading,
    setOperationEndDateToCurrent,
    setOperationStartDateToCurrent,
    setStartDateForAllElements, updateElementsContext
} from "../../reducers/operationForm.reducer";
import {CustomRangePicker} from "../../../../../components/datePicker/CustomRangePicker";
import {LocalizationEnum, localize} from '../../../../../localization';
import './OperationFormPageTopBlock.less';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {
    canEditDefaultOperationParams, is_OperationTimeError,
    isRentOrSubrentOperation,
    isReturnOrSubrentReturnOperation
} from "../../utils/utils";
import {
    IconCaretSquareLeftSolid,
    IconCaretSquareRightSolid,
    IconClose,
    IconCompressArrowsAltSolid1,
    IconCrosshairsSolid,
    IconEllipsisHSolid,
    IconSyncSolid
} from "../../../../../components/icons";
import {RecalculateShiftsCountButton} from "../../RecalculateShiftsCountButton";
import {getShiftCountFromDates, isDefined, joinClasses} from "../../../../../shared/util/utils";
import {OperationParamResetButton} from "../../components/operationParamResetButton/OperationParamResetButton";
import {canViewFinancialData} from "../../../../../shared/util/permissionUtils";
import {ActionsPopoverV2} from "../../../../../components/v2/actionsPopover/ActionsPopoverV2";
import classNames from "classnames";
import {NumberInputDiscount, NumberInputShiftCount} from "../../../../../components/numberInputs";
import {RootState} from "../../../../../../index";
import {showNotification} from "../../../../../components/notification/showNotification";
import {RangePicker} from "../../../../../components/v2/calendar/rangePicker/rangePicker";

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    //commentEnabled?: boolean;
    //onCommentDisabled: () => void;
    creationBlock: JSX.Element;
}

class _OperationFormPageTopBlock extends React.Component<IProps> {

    onCommentChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.props.setComment(e.target.value);
    };

    onRentPeriodChanged = async (dates: RangePickerValue, dateStrings: [string, string]) => {
        if (dates[0] && dates[1]) {
            let startDate = dates[0] ? dates[0].toDate() : undefined;
            let endDate = dates[1] ? dates[1].toDate() : undefined;

            if(this.props.actualizeDateMode){
                if(isRentOrSubrentOperation(this.props.targetStateCode || this.props.typeCode)){
                    startDate = this.props.operationStartTime;//?.toDate();
                }else if(isReturnOrSubrentReturnOperation(this.props.targetStateCode || this.props.typeCode)){
                    endDate = this.props.operationStartTime;//?.toDate();
                }
            }
            //await this.updateOperationDates(async()=>{
                this.props.setDefaultRentPeriod(moment(startDate), moment(endDate), this.props.recalculateShiftCount || this.props.autoRecalculateShiftsCountMode);
            //});
        }
    };

    updateOperationDates = async (fn:()=>Promise<void>) => {
        try{
            this.props.setLoading(true);
            await fn();
            await this.props.reloadAllTimetables();
        }catch(error){
            showNotification('error', 'Контекст не обновлен');
        }
        this.props.setLoading(false);
    };

    onShiftCountChanged = (value: number | undefined) => {
        let v = value === 0 ? 0 : (value || undefined);
        this.props.setDefaultShiftCount(v);
    };

    onDiscountChanged = (value: number | undefined) => {
        this.props.setDefaultDiscount(value || 0);
    };

    render() {
        console.log('OperationFormPageTopBlock render()', this.props);

        const {
            elements,
            rentPeriodStartDate,
            rentPeriodEndDate,
            discount,
            shiftCount,
            typeCode,
            comment,
            finalTotalPrice,
            finalTotalPriceWithTaxes,
            canViewFinancialData
        } = this.props;

        let rentPeriodIsDefault = elements.filter((item) => !item.kitId).every((item) => {
            return (item.rentPeriodStartDate.valueOf() === rentPeriodStartDate?.valueOf() && item.rentPeriodEndDate.valueOf() === rentPeriodEndDate?.valueOf()) && !item.rentPeriodChanged;
        });
        let discountIsDefault = elements.filter((item) => !item.kitId).every((item) => (item.discount === discount) && !item.discountChanged);
        let shiftCountIsDefault = elements.filter((item) => !item.kitId).every((item) => ((this.props.shiftCount !== undefined && item.shiftCount === shiftCount) || (this.props.shiftCount === undefined)) && !item.shiftCountChanged);

        let startDateIsDefault = elements.filter((item) => !item.kitId).every((item) => {
            return (item.rentPeriodStartDate.valueOf() === rentPeriodStartDate?.valueOf());
        });

        let endDateIsDefault = elements.filter((item) => !item.kitId).every((item) => {
            return (item.rentPeriodEndDate.valueOf() === rentPeriodEndDate?.valueOf());
        });

        if(this.props.autoRecalculateShiftsCountMode){
            shiftCountIsDefault = true;
            elements.filter((item) => !item.kitId).forEach((item)=>{
                if(item.shiftCountChanged) shiftCountIsDefault = false;
            });
        }

        let disabled = canEditDefaultOperationParams(typeCode);

        let rangePickerDefaultValue: RangePickerValue = !rentPeriodStartDate || !rentPeriodEndDate ? [] : [
            moment(rentPeriodStartDate),
            moment(rentPeriodEndDate)
        ];

        let calendarValue: any;
        let datesError = false;

        if (rentPeriodEndDate && rentPeriodStartDate) {
            datesError = is_OperationTimeError(moment(rentPeriodStartDate).valueOf(), moment(rentPeriodEndDate).valueOf());
        }

        let newShiftCount = this.props.rentPeriodStartDate && this.props.rentPeriodEndDate ? getShiftCountFromDates(undefined, moment(this.props.rentPeriodStartDate), moment(this.props.rentPeriodEndDate), this.props.defaultShiftLengthInMinutes, this.props.shiftCountRoundingType) : 0;

        let withComments = this.props.commentVisible;

        const totalPrice = isDefined(finalTotalPriceWithTaxes) ? finalTotalPriceWithTaxes : finalTotalPrice;

        return (
            <Card bordered={false}
                  className={joinClasses('rr-card-without-padding rr-operationForm-elements-top-block', !withComments ? 'rr-operationForm-elements-top-block_without-comments' : undefined)}
                  style={{marginBottom: 30}}>
                <div>
                    <div style={{display: 'flex'}}>
                        <div style={{flexGrow: 1}}>
                            <Row gutter={0} style={{padding: '0px 30px 20px 30px'}}>
                                <Col span={24} className={'rr-operationForm-elements-top-block__params-block'}>
                                    {
                                        !this.props.projectTemplate &&
                                        <div style={{display: 'inline-block', marginRight: 60, marginTop: 18}}>
                                            <div className='rr-form-item'>
                                                <div className='rr-label' style={{marginBottom: 10, display: 'flex', alignItems: 'center', height: 21}}>
                                                    <span style={{marginRight: 8}}>{localize(LocalizationEnum.ASPECT__RENT_BUSINESS__RENT_PERIOD)}</span>
                                                    <OperationParamResetButton changed={!rentPeriodIsDefault} onChange={async ()=>{
                                                        await this.updateOperationDates(async()=>{
                                                            this.props.resetDefaultRentPeriod();
                                                        });
                                                    }} />
                                                    <div style={{flexGrow: 1, textAlign: 'right'}}>
                                                        <ActionsPopoverV2
                                                            title={'Применить ко всем'}
                                                            icon={<Icon component={IconCompressArrowsAltSolid1} style={{fontSize: 19}}/>}
                                                            items={[
                                                                {
                                                                    icon: <Icon component={IconCaretSquareLeftSolid} style={{fontSize: 21, color: '#8b5cb1'}}/>,
                                                                    title: 'Начало периода',
                                                                    onClick: async () => {
                                                                        await this.updateOperationDates(async()=>{
                                                                            if(this.props.rentPeriodStartDate) this.props.setStartDateForAllElements(this.props.rentPeriodStartDate);
                                                                        });
                                                                    },
                                                                    disabled: startDateIsDefault
                                                                },
                                                                {
                                                                    icon: <Icon component={IconCaretSquareRightSolid} style={{fontSize: 21, color: '#8b5cb1'}}/>,
                                                                    title: 'Завершение периода',
                                                                    onClick: async () => {
                                                                        await this.updateOperationDates(async()=>{
                                                                            if(this.props.rentPeriodEndDate) this.props.setEndDateForAllElements(this.props.rentPeriodEndDate);
                                                                        });
                                                                    },
                                                                    disabled: endDateIsDefault
                                                                }
                                                            ]}
                                                        >
                                                            <Icon component={IconEllipsisHSolid} style={{ fontSize: 36, color: '#525a95', marginRight: this.props.actualizeDateMode ? 26 : undefined }} />
                                                        </ActionsPopoverV2>
                                                    </div>
                                                </div>
                                                <div className='rr-value'>
                                                    <>
                                                        {/*<CustomRangePicker*/}
                                                        {/*    startDateLabel={this.props.actualizeDateMode && isRentOrSubrentOperation(this.props.targetStateCode || this.props.typeCode) && <><Icon component={IconCrosshairsSolid} style={{color: '#34bfa3', fontSize: 21, position: 'relative', top: 2, marginRight: 5}}/> <span style={{fontWeight: 500, fontSize: 16}}>Сейчас</span></>}*/}
                                                        {/*    endDateLabel={this.props.actualizeDateMode && isReturnOrSubrentReturnOperation(this.props.targetStateCode || this.props.typeCode) && <><Icon component={IconCrosshairsSolid} style={{color: '#34bfa3', fontSize: 21, position: 'relative', top: 2, marginRight: 5}}/> <span style={{fontWeight: 500, fontSize: 16}}>Сейчас</span></>}*/}
                                                        {/*    allowClear={false}*/}
                                                        {/*    defaultValue={rangePickerDefaultValue}*/}
                                                        {/*    className={(datesError ? 'rr-input-error' : '')}*/}
                                                        {/*    value={rangePickerDefaultValue}*/}
                                                        {/*    showTime*/}
                                                        {/*    disabled={disabled}*/}
                                                        {/*    calendarValue={calendarValue}*/}
                                                        {/*    onChange={this.onRentPeriodChanged}*/}
                                                        {/*    shiftLengthInMin={this.props.defaultShiftLengthInMinutes}*/}
                                                        {/*    hasOwnShiftLength={false}*/}
                                                        {/*    style={{maxWidth: 380, minWidth: 310}}*/}
                                                        {/*    shiftCount={this.props.shiftCount}*/}
                                                        {/*    align={{points: ['bl', 't'], offset: [0, -6]}}*/}
                                                        {/*    hideShiftRecalculateBlock={this.props.autoRecalculateShiftsCountMode}*/}
                                                        {/*/>*/}
                                                        <RangePicker
                                                            startDateLabel={this.props.actualizeDateMode && isRentOrSubrentOperation(this.props.targetStateCode || this.props.typeCode) && <><Icon component={IconCrosshairsSolid} style={{color: '#34bfa3', fontSize: 21, position: 'relative', top: 2, marginRight: 5}}/> <span style={{fontWeight: 500, fontSize: 16}}>Сейчас</span></>}
                                                            endDateLabel={this.props.actualizeDateMode && isReturnOrSubrentReturnOperation(this.props.targetStateCode || this.props.typeCode) && <><Icon component={IconCrosshairsSolid} style={{color: '#34bfa3', fontSize: 21, position: 'relative', top: 2, marginRight: 5}}/> <span style={{fontWeight: 500, fontSize: 16}}>Сейчас</span></>}
                                                            allowClear={false}
                                                            className={(datesError ? 'rr-input-error' : '')}
                                                            value={rangePickerDefaultValue as any}
                                                            showTime
                                                            disabled={disabled}
                                                            onChange={this.onRentPeriodChanged as any}
                                                            style={{width: 420}}
                                                            shiftCount={this.props.shiftCount}
                                                            requiredTimeIndentProps={{
                                                                minutes: this.props.requiredTimeIndentBetweenElementsInMinutes,
                                                                hasOwnIndent: false
                                                            }}
                                                            recalculateShiftCountBlockProps={!this.props.autoRecalculateShiftsCountMode ? {
                                                                shiftLengthInMin: this.props.defaultShiftLengthInMinutes,
                                                                hasOwnShiftLength: false,
                                                                shiftLengthHint: undefined,
                                                                fromShiftCount: this.props.shiftCount
                                                            } : undefined}
                                                            getTodayDate={()=>{
                                                                return moment(this.props.operationStartTime)
                                                            }}
                                                            pricingScheme={undefined}
                                                        />
                                                        {
                                                            this.props.actualizeDateMode ?
                                                                (
                                                                    <Icon component={IconClose} style={{marginTop: -2, marginLeft: 4}}
                                                                          className={'rr-operationForm-elements-top-block__reset'}
                                                                          onClick={() => {
                                                                              this.props.setActualizeDateMode(false);
                                                                          }}
                                                                    />
                                                                ) : null
                                                        }
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        <div style={{display: 'inline-block', marginTop: 18, marginBottom: -18}}>
                                            <div className='rr-form-item'
                                                 style={{display: 'inline-block', marginRight: 30, width: 252}}>
                                                <div className='rr-label' style={{marginBottom: 10, display: 'flex', alignItems: 'center'}}><span
                                                    style={{marginRight: 8}}>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__SHIFT_COUNT)}</span>
                                                    <OperationParamResetButton changed={!shiftCountIsDefault} onChange={()=>{
                                                        this.props.resetDefaultShiftCount();
                                                    }} />
                                                </div>
                                                <div className={'rr-value'}>
                                                    {
                                                        this.props.autoRecalculateShiftsCountMode ? (
                                                            <div style={{height: 39, display: 'flex', alignItems: 'center'}}>
                                                                <span style={{fontWeight: 500, fontSize: 16, color: 'rgba(87, 89, 98, 0.8)'}}><Icon
                                                                    style={{
                                                                        marginRight: 10
                                                                    }}
                                                                    className={classNames('rr-RecalculateShiftsCountButton__icon', 'rr-RecalculateShiftsCountButton__icon__without-hover')}
                                                                    component={IconSyncSolid}
                                                                />Пересчет
                                                                <Icon component={IconClose} style={{marginTop: -2, marginLeft: 4}}
                                                                      className={'rr-operationForm-elements-top-block__reset'}
                                                                      onClick={() => {
                                                                          this.props.setAutoRecalculateShiftsCountMode(false);
                                                                      }}/>
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <NumberInputShiftCount value={shiftCount}
                                                                                       onChange={this.onShiftCountChanged}
                                                                                       disabled={disabled} />
                                                                {
                                                                    !disabled && <RecalculateShiftsCountButton
                                                                        shiftLengthInMinutes={this.props.defaultShiftLengthInMinutes}
                                                                        hasOwnShiftLength={false} style={{marginLeft: 10}}
                                                                        from={shiftCount} to={newShiftCount} onClick={() => {
                                                                        this.props.setDefaultShiftCount(newShiftCount);
                                                                    }}/>
                                                                }
                                                                {
                                                                    <span
                                                                        style={{opacity: !disabled && shiftCount !== undefined ? 1 : 0}}>
                                                                    <Icon component={IconClose}
                                                                          style={{marginTop: -2, marginLeft: 4}}
                                                                          className={'rr-operationForm-elements-top-block__reset'}
                                                                          onClick={() => {
                                                                              this.props.setDefaultShiftCount(undefined);
                                                                          }}/>
                                                                </span>
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        <div style={{display: 'inline-block', marginTop: 18, marginBottom: -18}}>
                                            {
                                                canViewFinancialData && (
                                                    <div className='rr-form-item' style={{display: 'inline-block'}}>
                                                        <div className='rr-label' style={{marginBottom: 10, display: 'flex', alignItems: 'center'}}><span
                                                            style={{marginRight: 8}}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}</span>
                                                            <OperationParamResetButton changed={!discountIsDefault} onChange={()=>{
                                                                this.props.resetDefaultDiscount();
                                                            }} />
                                                        </div>
                                                        <div className={'rr-value'}>
                                                            <NumberInputDiscount value={discount}
                                                                                 onChange={this.onDiscountChanged}
                                                                                 disabled={disabled}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                        <div style={{borderLeft: '1px solid #eaeaea', paddingRight: 30, paddingLeft: 30, paddingTop: 15}}>
                            <div style={{display: 'flex', alignItems: 'center' ,marginBottom: 6, minHeight: 26}}>
                                {
                                    canViewFinancialData && (
                                        <>
                                            <div style={{flexGrow: 1, textAlign: 'right', marginRight: 32}}>
                                                {localize(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}
                                            </div>
                                            <div className={'rr-value'}>
                                                <FormValue value={formatMoney(totalPrice)} originalValue={totalPrice}/>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div>
                                {this.props.creationBlock}
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            withComments && <Col span={24} xl={24} xxl={24} style={{borderTop: '1px solid #eaeaea'}}>
                                <Row gutter={0} style={{padding: '0px 34px 20px 34px'}}>

                                    <Col span={24} xl={24} xxl={24}
                                         className={'rr-form-item rr-operationForm-elements-top-block__comment-block'}
                                         style={{marginTop: 18}}>
                                        <div className='rr-label'
                                             style={{marginBottom: 11}}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}
                                            <Icon component={IconClose} style={{marginTop: -2, marginLeft: 4}}
                                                  className={'rr-operationForm-elements-top-block__reset'}
                                                  onClick={() => {
                                                      this.props.setCommentVisibility(false);
                                                  }}/>
                                        </div>
                                        <div className='rr-text'>
                                            <Input.TextArea value={comment}
                                                            onChange={this.onCommentChanged}
                                                            rows={5}
                                                            style={{resize: 'none', minHeight: 127}}
                                                            maxLength={2000}
                                                            autoSize={false}/>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        }
                    </div>
                </div>

            </Card>
        );
    }
}

const mapStateToProps = (storeState: RootState) => {
    return {
        comment: commentSelector(storeState),
        commentVisible: commentVisibleSelector(storeState),
        instanceCount: storeState.operationForm.instanceCount,
        priceBeforeDiscount: storeState.operationForm.priceBeforeDiscount,
        discountAmount: storeState.operationForm.discountAmount,
        finalTotalPrice: storeState.operationForm.finalTotalPrice,
        finalTotalPriceWithTaxes: storeState.operationForm.finalTotalPriceWithTaxes,
        rentPeriodStartDate: storeState.operationForm.rentPeriodStartDate,
        rentPeriodEndDate: storeState.operationForm.rentPeriodEndDate,
        discount: storeState.operationForm.discount,
        shiftCount: storeState.operationForm.shiftCount,
        elements: storeState.operationForm.elements.entities,
        typeCode: storeState.operationForm.typeCode,
        targetStateCode: storeState.operationForm.targetStateCode,
        projectTemplate: storeState.operationForm.projectTemplate,
        recalculateShiftCount: storeState.userSettings.operationFormRecalculateShiftCount,
        defaultShiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        operationStartTime: storeState.operationForm.operationStartTime,
        autoRecalculateShiftsCountMode: storeState.operationForm.autoRecalculateShiftsCountMode,
        actualizeDateMode: storeState.operationForm.actualizeDateMode,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        requiredTimeIndentBetweenElementsInMinutes: storeState.businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes,
    };
};

const mapDispatchToProps = {
    setDefaultRentPeriod,
    setDefaultShiftCount,
    setDefaultDiscount,
    resetDefaultRentPeriod,
    resetDefaultDiscount,
    resetDefaultShiftCount,
    setComment,
    setCommentVisibility,
    setOperationStartDateToCurrent,
    setOperationEndDateToCurrent,
    setAutoRecalculateShiftsCountMode,
    setActualizeDateMode,
    setStartDateForAllElements,
    setEndDateForAllElements,
    setLoading,
    reloadAllTimetables,
    updateElementsContext
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationFormPageTopBlock = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(_OperationFormPageTopBlock));

import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import {
    ProblematicRentElementInRecalculateOrNumberOfChanges,
    RentActivityFrameTypeCodeEnum,
    RentElementListAggregations,
    RentElementRecordList,
    serverApi,
    ServerError,
    VersionedEntityObjList,
} from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { IRootState } from '../../../../shared/reducers';
import {
    getDatesWithTimeFromParamsString,
    getServerError,
    getStateFromPath2,
    getStringServerProblem,
    test1,
    test2,
} from '../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { downloadDataFromExcel } from '../../../../shared/util/downloadDataFromExcel';
import { convertRentElementRecordToRentElementsGridItem, RentElementsGridItem } from '../../../../types';
import { getHideStateCodeFilterString } from '../../../../shared/util/utils4';
import { getCurrentTimezoneUTCOffset } from '../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import { gridDataChangedSignal } from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'rentElements/LOAD_ENTITIES',
    EXPORT_EXCEL: 'rentElements/EXPORT_EXCEL',
    RESET: 'rentElements/RESET',
    CLEAR_ELEMENTS: 'rentElements/CLEAR_ELEMENTS',
    UPDATE_PRICE_FROM_CATALOG: 'rentElements/UPDATE_PRICE_FROM_CATALOG',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    typeCode: undefined as string[] | undefined,
    page: 1,
    limit: 20,
    hideInactive: false,
    hide: undefined as string | undefined,
    renterId: undefined as string | undefined,
    projectId: undefined as number | undefined,
    creationAuthorId: undefined as string | undefined,
    problem: undefined as string | undefined,
    startDate: [undefined, undefined] as (number | undefined)[] | undefined,
    endDate: [undefined, undefined] as (number | undefined)[] | undefined,
    finalTotalPrice: undefined as number[] | undefined,
    discount: undefined as number[] | undefined,
    shiftCount: undefined as number[] | undefined,
    categoryIds: undefined as string | undefined,
    tags: undefined as string[] | undefined,
};

const initialState = {
    loading: true,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<RentElementsGridItem> | null,
    aggregations: undefined as RentElementListAggregations | undefined,
    totalCount: 0,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

// Reducer

export type RentElementsState = Readonly<typeof initialState>;

export default (state: RentElementsState = initialState, action): RentElementsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            const regexp =
                /\/(\d+)\/(history\/operations|projects\/production|projects\/offers|projects\/templates|projects\/simpleOrders|subrent\/templates|subrent\/shippings)\/(\d+)/;

            if (
                (regexp.test(pathName) && params.tab === 'elements') ||
                (/\/(\d+)\/subrent\/templates\/(\d+)/.test(pathName) && !params.tab) ||
                (/\/(\d+)\/subrent\/shippings\/(\d+)/.test(pathName) && !params.tab) ||
                (/\/(\d+)\/projects\/production\/(\d+)/.test(pathName) && !params.tab) ||
                (/\/(\d+)\/projects\/offers\/(\d+)/.test(pathName) && !params.tab) ||
                (/\/(\d+)\/projects\/templates\/(\d+)/.test(pathName) && !params.tab) ||
                (/\/(\d+)\/projects\/simpleOrders\/(\d+)/.test(pathName) && params.tab === 'description') ||
                (/\/(\d+)\/history\/operations\/(\d+)/.test(pathName) && !params.tab)
            ) {
                let arr = regexp.exec(pathName);
                if (arr) {
                    params.projectId = arr[3];
                }

                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        typeCode: test2(params.typeCode, initialParamsState.typeCode),
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideInactive: params.hideInactive !== undefined ? params.hideInactive : initialParamsState.hideInactive,
                        hide: params.hide !== undefined ? params.hide : initialParamsState.hide,
                        problem: params.problem !== undefined ? params.problem : initialParamsState.problem,
                        renterId: params.renterId !== undefined ? params.renterId : initialParamsState.renterId,
                        projectId: params.projectId !== undefined ? params.projectId : initialParamsState.projectId,
                        finalTotalPrice: test1(params.finalTotalPrice, initialParamsState.finalTotalPrice)?.map((price) =>
                            Math.round(price)
                        ),
                        startDate: getDatesWithTimeFromParamsString(params.startDate, initialParamsState.startDate),
                        endDate: getDatesWithTimeFromParamsString(params.endDate, initialParamsState.endDate),
                        discount: test1(params.discount, initialParamsState.discount),
                        shiftCount: test1(params.shiftCount, initialParamsState.shiftCount),
                        categoryIds: params.categoryIds !== undefined ? params.categoryIds : initialParamsState.categoryIds,
                        tags: params.tags !== undefined ? String(params.tags).split(',') : initialParamsState.tags,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            const entities: RentElementsGridItem[] = (action.payload.data as RentElementRecordList).records.map((item) => {
                return convertRentElementRecordToRentElementsGridItem(item);
            });
            const aggregations = (action.payload.data as RentElementRecordList).aggregations;

            return {
                ...state,
                loading: false,
                entities,
                aggregations,
                totalCount: (action.payload.data as RentElementRecordList).listAttributes.filteredCount,
                filteredCount: (action.payload.data as RentElementRecordList).listAttributes.filteredCount,
            };
        case REQUEST(ACTION_TYPES.UPDATE_PRICE_FROM_CATALOG):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.UPDATE_PRICE_FROM_CATALOG):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.UPDATE_PRICE_FROM_CATALOG):
            console.dir(action.payload);
            return {
                ...state,
                loading: false,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        case ACTION_TYPES.CLEAR_ELEMENTS:
            return {
                ...state,
                entities: null
            };
        default:
            return state;
    }
};

// Actions

// Загрузить список обязательств
export const loadEntities = (intl, businessAccountId: number, parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping') => {
    return (dispatch, getState) => {
        let state: RentElementsState = (getState() as IRootState).elements;
        let params = state.params;
        let filters: string[] = getElementsListFilters(params);
        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload:
                parentType === 'project'
                    ? serverApi.listProjectElements(
                          businessAccountId,
                          params.projectId || 0,
                          params.limit,
                          (params.page - 1) * params.limit,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          true,
                          undefined,
                          undefined,
                          { query: { filters: filters } }
                      )
                    : parentType === 'shipping'
                    ? serverApi.listSubrentElements(
                          businessAccountId,
                          params.projectId || 0,
                          params.limit,
                          (params.page - 1) * params.limit,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          true,
                          undefined,
                          undefined,
                          { query: { filters: filters } }
                      )
                    : parentType === 'projectTemplate'
                    ? serverApi.listTemplateElements(
                          businessAccountId,
                          params.projectId || 0,
                          params.limit,
                          (params.page - 1) * params.limit,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          true,
                          undefined,
                          undefined,
                          { query: { filters: filters } }
                      )
                    : serverApi.listOperationElements(
                          businessAccountId,
                          params.projectId || 0,
                          params.limit,
                          (params.page - 1) * params.limit,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          true,
                          undefined,
                          undefined,
                          { query: { filters: filters } }
                      ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
            }
        });
    };
};

export const updatePriceFromCatalog = (
    intl,
    businessAccountId: number,
    activityFrameId: number,
    activityFrameTypeCode: RentActivityFrameTypeCodeEnum,
    versionedEntityObjList: VersionedEntityObjList,
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping'
) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_PRICE_FROM_CATALOG,
            payload: serverApi.recalculateBusinessAccountElementsPrice(
                businessAccountId,
                activityFrameId,
                activityFrameTypeCode,
                versionedEntityObjList
            ),
        }).then((result: { value: { data: ProblematicRentElementInRecalculateOrNumberOfChanges } } | Error) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось обновить цены из каталога');
                return;
            }

            const {
                value: {
                    data: { fields, successfulUpdateCount },
                },
            } = result;

            if (fields && fields?.length > 0) {
                const errors = fields.map(
                    ({ kitShortName, productShortName, variantShortName, countInstance }) =>
                        `${kitShortName} - ${productShortName}: ${variantShortName ?? ''} ${countInstance} экз.`
                );
                showNotification(
                    'error',
                    <span>
                        Цена в наборе не определена для:
                        <br />
                        {errors.join('\n')}
                    </span>
                );
            } else {
                showNotification(
                    'success',
                    `Цены из каталога успешно обновлены. ` +
                        (successfulUpdateCount ? `Изменено ${successfulUpdateCount} обяз.` : 'Устаревших цен найдено на было.')
                );
            }

            loadEntities(intl, businessAccountId, parentType)(dispatch, getState);
        });
    };
};

// Экспорт в эксель
export const exportExcel = (
    intl,
    businessAccountId: number,
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping',
    visibleColumns: string[],
    expandKits: boolean,
    mnemo: string,
    selectedIds?: number[]
) => {
    return (dispatch, getState) => {
        let state: RentElementsState = (getState() as IRootState).elements;
        let params = state.params;
        let filters: string[] = getElementsListFilters(params);
        const selectedIdsFilter = selectedIds && selectedIds.length > 0 ? `id;IN;${selectedIds.join(';')}` : '';
        if (selectedIdsFilter) filters.push(selectedIdsFilter);
        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;

        return dispatch({
            type: ACTION_TYPES.EXPORT_EXCEL,
            payload:
                parentType === 'project'
                    ? serverApi.exportProjectElementsList(
                          businessAccountId,
                          params.projectId || 0,
                          visibleColumns,
                          expandKits,
                          getCurrentTimezoneUTCOffset(),
                          undefined,
                          0,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          { query: { filters: filters }, responseType: 'blob' }
                      )
                    : parentType === 'projectTemplate'
                    ? serverApi.exportTemplateElementsList(
                          businessAccountId,
                          params.projectId || 0,
                          visibleColumns,
                          expandKits,
                          getCurrentTimezoneUTCOffset(),
                          undefined,
                          0,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          { query: { filters: filters }, responseType: 'blob' }
                      )
                    : parentType === 'operation'
                    ? serverApi.exportOperationElementsList(
                          businessAccountId,
                          params.projectId || 0,
                          visibleColumns,
                          expandKits,
                          getCurrentTimezoneUTCOffset(),
                          undefined,
                          0,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          { query: { filters: filters }, responseType: 'blob' }
                      )
                    : serverApi.exportSubrentElementsList(
                          businessAccountId,
                          params.projectId || 0,
                          visibleColumns,
                          expandKits,
                          getCurrentTimezoneUTCOffset(),
                          undefined,
                          0,
                          undefined,
                          sortBy,
                          params.sortOrder,
                          params.search,
                          { query: { filters: filters }, responseType: 'blob' }
                      ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXPORT_TO_EXCEL_FAILED));
            } else {
                let fileName;
                if (parentType === 'project') {
                    fileName =
                        (localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXCEL_FILE_NAME_PROJECT_ELEMENTS) as string) +
                        ' ' +
                        mnemo;
                } else {
                    fileName =
                        (localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXCEL_FILE_NAME_OPERATION_ELEMENTS) as string) +
                        ' ' +
                        mnemo;
                }
                downloadDataFromExcel(result.action.payload.data, fileName);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const clearElements = () => ({
    type: ACTION_TYPES.CLEAR_ELEMENTS,
});

export const getElementsListFilters = (params: typeof initialParamsState, ignoreFinalTotalPrice: boolean = false): string[] => {
    let filters: string[] = [];

    if (params.typeCode) {
        filters.push('stateCode;IN;' + params.typeCode.join(';'));
    }

    if (params.problem) filters.push(getStringServerProblem(params.problem));

    if (params.renterId !== undefined) filters.push('renterId;EQ;' + params.renterId);
    if (params.creationAuthorId !== undefined) filters.push('creationAuthorId;EQ;' + params.creationAuthorId);

    if (params.startDate) {
        if (params.startDate[0] !== undefined) filters.push('rentTerms.rentPeriodStartDate;GE;' + params.startDate[0]);
        if (params.startDate[1] !== undefined) filters.push('rentTerms.rentPeriodStartDate;LE;' + params.startDate[1]);
    }

    if (params.endDate) {
        if (params.endDate[0] !== undefined) filters.push('rentTerms.rentPeriodEndDate;GE;' + params.endDate[0]);
        if (params.endDate[1] !== undefined) filters.push('rentTerms.rentPeriodEndDate;LE;' + params.endDate[1]);
    }

    if (params.discount) {
        if (params.discount[0] !== undefined) filters.push('rentTerms.discount;GE;' + params.discount[0]);
        if (params.discount[1] !== undefined) filters.push('rentTerms.discount;LE;' + params.discount[1]);
    }

    if (params.shiftCount) {
        if (params.shiftCount[0] !== undefined) filters.push('rentTerms.shifts.shiftCount;GE;' + params.shiftCount[0]);
        if (params.shiftCount[1] !== undefined) filters.push('rentTerms.shifts.shiftCount;LE;' + params.shiftCount[1]);
    }

    if (!ignoreFinalTotalPrice) {
        if (params.finalTotalPrice) {
            if (params.finalTotalPrice[0] !== undefined) filters.push('finalTotalPrice;GE;' + params.finalTotalPrice[0] * 100);
            if (params.finalTotalPrice[1] !== undefined) filters.push('finalTotalPrice;LE;' + params.finalTotalPrice[1] * 100);
        }
    }

    if (params.categoryIds !== undefined) filters.push('categoryIds;IN;' + params.categoryIds);

    if (params.tags !== undefined) filters.push('tags;IN;' + params.tags.join(';'));

    let hideStateCodeFilter = getHideStateCodeFilterString(params.hide);
    if (hideStateCodeFilter) filters.push(hideStateCodeFilter);

    return filters;
};

interface ListElementsArgs {
    parentEntityType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    businessAccountId: number;
    projectId: number;
    limit?: number;
    startFrom?: number;
    filters?: Array<string>;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    search?: string;
    excludeAggregations?: boolean;
    excludeRecords?: boolean;
}

export const listElements = async ({parentEntityType : t, businessAccountId, projectId, limit, startFrom, sortBy, sortOrder, search, excludeAggregations, excludeRecords, filters}: ListElementsArgs) => {
    const method = t === 'project' ? serverApi.listProjectElements : (t === 'shipping' ? serverApi.listSubrentElements : (t === 'projectTemplate' ? serverApi.listTemplateElements : serverApi.listOperationElements));
    let res = await method.call(serverApi,
        businessAccountId,
        projectId,
        limit,
        startFrom,
        undefined,
        sortBy,
        sortOrder,
        search,
        excludeAggregations,
        excludeRecords,
        undefined,
        filters && filters.length > 0 ? { query: { filters: filters } } : undefined
        );
    return res.data;
}

import { ProjectStateCodeEnum, ProjectTypeCodeEnum, WorkPlanningsStateCodeEnum } from '../../../../server';
import { WorkPlanningsCreateStateCodes, WorkPlanningsEditStateCodes } from '../data/workPlanningsData';

export const getOfferWorkPlanningsStateCodes = (id: number | undefined) =>
    id ? [WorkPlanningsStateCodeEnum.DRAFT, WorkPlanningsStateCodeEnum.CANCELED] : [WorkPlanningsStateCodeEnum.DRAFT];

export const getProjectWorkPlanningsStateCodes = (id: number | undefined, projectStateCode: ProjectStateCodeEnum) =>
    id
        ? WorkPlanningsEditStateCodes.stateCodesByProjectStateCodeMap[projectStateCode]
        : WorkPlanningsCreateStateCodes.stateCodesByProjectStateCodeMap[projectStateCode];

export const getWorkPlanningStateCodes = (
    projectType: ProjectTypeCodeEnum | undefined,
    id: number | undefined,
    projectStateCode: ProjectStateCodeEnum
) => {
    return (
        {
            [ProjectTypeCodeEnum.BASE]: getProjectWorkPlanningsStateCodes(id, projectStateCode),
            [ProjectTypeCodeEnum.OFFER]: getOfferWorkPlanningsStateCodes(id),
        } satisfies Record<ProjectTypeCodeEnum, WorkPlanningsStateCodeEnum[]>
    )[projectType ?? ProjectTypeCodeEnum.BASE];
};

export const getDefaultStateForCreateModal = (
    parentProjectEntityProjectType: ProjectTypeCodeEnum | undefined,
    projectStateCode: ProjectStateCodeEnum
) => {
    if (parentProjectEntityProjectType === ProjectTypeCodeEnum.OFFER) return WorkPlanningsStateCodeEnum.DRAFT;

    const availableStateCodes = WorkPlanningsCreateStateCodes.stateCodesByProjectStateCodeMap[projectStateCode];

    if (availableStateCodes.includes(WorkPlanningsStateCodeEnum.PLANNED)) return WorkPlanningsStateCodeEnum.PLANNED;

    return availableStateCodes[0];
};

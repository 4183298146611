import React, { FC } from 'react';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconSitemap } from '../../../../../../../../components/icons';
import { ElementsCalendarItem } from '../../../../../components/Calendar/types/items';
import { restItemsGroupId } from '../../../../../components/Calendar/hooks/useCalendarItems';

export const CategoryGroupLine: FC<GroupItemProps<ElementsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const {
        id,
        record: {
            commonFields: { mainCategoryName },
        },
    } = groupItem;

    const hasCategory = id !== restItemsGroupId;

    return id ? (
        <GroupLine
            IconComponent={IconSitemap}
            displayType={displayType}
            title={{
                text: hasCategory ? mainCategoryName : 'Без категории',
            }}
        />
    ) : null;
};

import _ from 'lodash';
import { ContactInfoRead, ContactInfoUpdate } from '../../../../../../../server';
import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../components/modalForm/utils';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import { goBack, push } from 'connected-react-router';
import { resetErrors } from '../../../../reducers/renters/renter.reducer';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { formFields } from '../ContactModalFields';
import { updateContact } from '../../reducers/contact/contact.reducer';
import { CategoryFormData } from '../create/ContactCreateModal';
import { ImageUtils } from '../../../../../../../core/utils/imageUtils';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    contact: ContactInfoRead;
}

export class _ContactEditModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = (data: unknown) => {
        const formData = _.cloneDeep(data) as CategoryFormData;
        const imageResponse = formData?.mainImage?.[0]?.response;
        const contactData = this.props.contact;

        const contactUpdateData: ContactInfoUpdate = {
            ...contactData,
            ...formData,

            archive: contactData.archive!,
            mainImage: imageResponse,
        };

        this.props.updateContact({
            businessAccountId: this.props.businessAccountId,
            contactId: this.props.contact.id,
            contactInfoUpdate: contactUpdateData,
        });
    };

    getInitialValues = () => {
        const { contact } = this.props;
        const mainImage = contact.mainImage
            ? ([ImageUtils.getImageFromImageObj(contact.mainImage)] as CategoryFormData['mainImage'])
            : undefined;

        let initialValues: CategoryFormData = {
            ...contact,
            mainImage,
            id: contact.id,
            isEdit: true,
            isUser: contact.isBusinessAccountRRUser,
        };

        if (this.props.initialValues) {
            initialValues = {
                ...initialValues,
                ...this.props.initialValues,
            } as CategoryFormData;
        }

        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.initialValues
                ? this.props.push(`/${this.props.businessAccountId}/crm/counterparties/${this.props.initialValues?.id}?tab=description`)
                : this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.contact.updating,
    updatingError: storeState.contact.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateContact,
    updateModalFilters,
    goBack,
    resetErrors,
    push,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const ContactEditModal = connector(injectIntl(_ContactEditModal));

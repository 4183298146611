import { IRootState } from '../../../../../shared/reducers';
import { createProject, resetError, updateEntity } from '../reducers/project.reducer';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    ContactPersonAPIObj, ContractValidationTypeCodeEnum,
    PersonFullName,
    ProjectInfoCreate,
    ProjectInfoRead,
    ProjectInfoUpdate,
    ProjectStateCodeEnum,
    ProjectTypeCodeEnum,
    RenterInfoRead,
    RenterStateCodeEnum,
    TypeOfPricingCodeEnum,
} from '../../../../../server';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../../components/dynamicForm/DynamicForm';
import { formFields } from './project-create-modal-fields';
import { printUserName } from '../../../../../shared/util/utils';
import { LabeledValue } from 'antd/lib/select';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { AxiosResponse } from 'axios';
import { showNotification } from '../../../../../components/notification/showNotification';
import { logEvent } from '../../../../../analytics/analytics';
import moment from 'moment';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import {
    contractValidationTypeSelector,
    crewModuleEnabledSelector,
    expensesModuleEnabledSelector,
    offersModuleEnabledSelector
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import {getStoreState} from "../../../../../../index";

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
    successCallback?: (data: ProjectInfoRead) => void;
    renter?: LabeledValue;
    copyMode?: boolean;
    validateAfterCreate?: boolean;
    renterObj?: RenterInfoRead | null;
    copyModeProject?: boolean;
    createFromOperationForm?: boolean;
}

interface IContacts extends PersonFullName, ContactPersonAPIObj {}

const setCopyParams = (newData:ProjectInfoCreate) => {
    const copyParams:any|undefined = newData['copyParams'];
    newData.copyExpenses = copyParams?.copyExpenses || false;
    newData.copyRentElements = copyParams?.copyRentElements || false;
    newData.copyWorkPlannings = copyParams?.copyWorkPlannings || false;
    newData.saveCrewMembers = copyParams?.saveCrewMembers || false;
    newData.saveTrackedInstances = copyParams?.saveTrackedInstances || false;
    delete newData['copyParams'];
}

class _ProjectCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = (data: any) => {
        if (data['dates']) {
            if (data['dates'][0]) data.startDate = data['dates'][0];
            if (data['dates'][1]) data.targetFinishDate = data['dates'][1];
            delete data['dates'];
        }
        if (!data.startDate) data.startDate = null;
        if (!data.targetFinishDate) data.targetFinishDate = null;

        delete data['_defaultDiscount'];
        delete data['renterDefaultDiscount'];
        delete data['createFromOperationForm'];

        data.projectType = ProjectTypeCodeEnum.BASE;

        if (data.projectContract?.contractDate) {
            data.projectContract.contractDate = data.projectContract.contractDate.format('YYYY-MM-DD');
        }

        if (data.projectContract?.contractExpirationDate) {
            data.projectContract.contractExpirationDate = data.projectContract.contractExpirationDate.format('YYYY-MM-DD');
        }

        if (!data.taxBaseType) {
            data.taxBaseType = this.props.businessAccountPreferences?.taxBaseType;
        }

        if (this.props.initialValues) {
            if (!this.props.copyMode) {
                // Редактирование
                let newData: any = { ...this.props.initialValues, ...data } as ProjectInfoUpdate;

                const useAssignee: any = newData;
                if (newData.assigneeId) newData.assigneeId = newData.assigneeId['key'];

                newData.hasOwnAssignee = !useAssignee.useAssignee;

                if (newData.description === '') delete newData.description;
                if (newData.comment === '') delete newData.comment;
                if (newData.fullName === '') delete newData.fullName;
                if (newData.contractNumber === '') delete newData.contractNumber;
                if (newData.location === '') delete newData.location;

                newData.hasOwnDefaultDiscount = !data['useRenterDefaultDiscount'];

                delete newData['useAssignee'];
                delete newData['useDefaultDiscount'];
                delete newData['defaultAssignee'];
                delete newData['copyMode'];
                this.contactNotEmptyString(newData);

                this.props.updateEntity(this.props.intl, this.props.businessAccountId, newData.id || 0, newData);
            } else {
                // Копирование
                data.isSimpleOrder = false;
                let newData: ProjectInfoCreate = {
                    defaultDiscount: 0,
                    taxRate: 0,
                    saveCrewMembers: true,
                    saveTrackedInstances: true,
                    ...data
                } as ProjectInfoCreate;
                if (newData.assigneeId) newData.assigneeId = newData.assigneeId['key'];
                //if (newData.renterId) newData.renterId = newData.renterId['key'];

                if (!newData.typeOfPricing) newData.typeOfPricing = TypeOfPricingCodeEnum.STRAIGHT;

                if (newData.description === '') delete newData.description;
                if (newData.comment === '') delete newData.comment;
                if (newData.fullName === '') delete newData.fullName;
                // #2120
                //if (newData.contractNumber === '') delete newData.contractNumber;
                // if (data['useRenterDefaultDiscount']) newData.defaultDiscount = undefined;

                newData.hasOwnDefaultDiscount = !newData['useRenterDefaultDiscount'];
                newData.hasOwnAssignee = !newData['useAssignee'];
                delete newData['useAssignee'];
                delete newData['useRenterDefaultDiscount'];
                delete newData['defaultAssignee'];
                delete newData['copyMode'];
                newData.hasOwnContacts = !(newData.hasOwnContacts == null || !newData.hasOwnContacts);

                if (this.props.copyModeProject) {
                    newData.baseTemplateId = this.props.initialValues.id;
                    delete newData['template'];
                    delete newData['fromTemplate'];
                }

                setCopyParams(newData);
                newData.sourceProjectId = this.props.initialValues.id;

                let request = this.props.createProject(
                    this.props.intl,
                    this.props.businessAccountId,
                    newData,
                    this.props.successCallback
                ) as any;
                request.then((result: any) => {
                    if (result instanceof Error) {
                    } else {
                        let res: AxiosResponse<ProjectInfoRead> = result.action.payload;
                        showNotification(
                            'success',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__PROJECTS__POPUP_NOTIFICATIONS__CREATED)
                        );
                        this.props.push(`/${this.props.businessAccountId}/projects/production/${res.data.id}?tab=description`);
                        if (this.props.successCallback) this.props.successCallback(res.data);
                        logEvent({
                            type: 'create project',
                            data: {
                                'entity id': data.id,
                            },
                        });
                    }
                });
            }
        } else {
            data.isSimpleOrder = false;
            // Создание
            let newData: ProjectInfoCreate = {
                defaultDiscount: 0,
                taxRate: 0,
                saveCrewMembers: true,
                saveTrackedInstances: true,
                ...data,
            } as ProjectInfoCreate;

            if (!newData.stateCode) newData.stateCode = ProjectStateCodeEnum.INPROGRESS;

            if (newData.assigneeId) newData.assigneeId = newData.assigneeId['key'];
            //if (newData.renterId) newData.renterId = newData.renterId['key'];

            if (!newData.typeOfPricing) newData.typeOfPricing = TypeOfPricingCodeEnum.STRAIGHT;

            if (newData.description === '') delete newData.description;
            if (newData.comment === '') delete newData.comment;
            if (newData.fullName === '') delete newData.fullName;

            if (data['useRenterDefaultDiscount']) newData.defaultDiscount = undefined;

            newData.hasOwnDefaultDiscount = !newData['useRenterDefaultDiscount'];
            newData.hasOwnAssignee = !newData['useAssignee'];

            newData.hasOwnContacts = !(newData.hasOwnContacts == null || !newData.hasOwnContacts);

            delete newData['useAssignee'];
            delete newData['useRenterDefaultDiscount'];
            delete newData['defaultAssignee'];
            delete newData['copyMode'];

            setCopyParams(newData);

            let request = this.props.createProject(
                this.props.intl,
                this.props.businessAccountId,
                newData,
                this.props.successCallback
            ) as any;

            request.then((result: any) => {
                if (result instanceof Error) {
                    // Сущность не создана
                } else {
                    let res: AxiosResponse<ProjectInfoRead> = result.action.payload;
                    showNotification(
                        'success',
                        localizeIntl(this.props.intl, LocalizationEnum.PAGE__PROJECTS__POPUP_NOTIFICATIONS__CREATED)
                    );

                    this.props.successCallback?.(res.data); // TODO Избавиться потом от этого
                    this.props.onSuccess?.(res.data);
                    logEvent({
                        type: 'create project',
                        data: {
                            'entity id': data.id,
                        },
                    });
                }
            });
        }
    };

    contactNotEmptyString = (newData) => {
        const contact: IContacts = newData ? newData.contacts![0].fullName : undefined;

        if (contact) {
            if (contact.secondname === '') {
                contact.secondname = undefined;
            } else if (contact.position === '') {
                contact.position = undefined;
            } else if (contact.phoneNumber === '') {
                contact.phoneNumber = undefined;
            } else if (contact.email === '') {
                contact.email = undefined;
            }
        }
    };

    getInitialValues = () => {
        let initialValues: any;
        const contractValidationEnabled = this.props.contractValidationType && [ContractValidationTypeCodeEnum.ONLYFILE, ContractValidationTypeCodeEnum.ALLFIELDS].includes(this.props.contractValidationType)

        if (this.props.initialValues) {
            initialValues = {
                ...this.props.initialValues,
                __fullName: this.props.initialValues.fullName,
                __shortName: this.props.initialValues.shortName,
                dates:
                    this.props.initialValues.startDate || this.props.initialValues.targetFinishDate
                        ? [
                              this.props.initialValues.startDate ? moment(this.props.initialValues.startDate) : undefined,
                              this.props.initialValues.targetFinishDate ? moment(this.props.initialValues.targetFinishDate) : undefined,
                          ]
                        : undefined,
            };

            if (this.props.copyModeProject) {
                initialValues.fromTemplate = true;
                initialValues.template = initialValues.shortName;
            } else {
                initialValues.projectEdit = true;
            }

            if (this.props.copyMode) {
                initialValues.id = undefined;
                initialValues.copyMode = true;

                if (initialValues.renterStateCode === RenterStateCodeEnum.BLOCKED) {
                    //
                    initialValues.renterId = undefined;
                    initialValues.stateCode = undefined;
                } else if (initialValues.renterStateCode === RenterStateCodeEnum.NEW) {
                    initialValues.stateCode = ProjectStateCodeEnum.DRAFT;
                } else if (initialValues.renterStateCode === RenterStateCodeEnum.ACTIVE) {
                    const {contractValidationType} = this.props;
                    if(contractValidationType === ContractValidationTypeCodeEnum.ONLYFILE || contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS){
                        initialValues.stateCode = ProjectStateCodeEnum.COORDINATION;
                    }else{
                        initialValues.stateCode = ProjectStateCodeEnum.INPROGRESS;
                    }
                }
                initialValues.copyParams = {
                    copyRentElements: true,
                    copyWorkPlannings: this.props.crewModuleEnabled ? true : undefined,
                    copyExpenses: this.props.expensesModuleEnabled ? true : undefined,
                };
            }

            if (initialValues.defaultAssignee) {
                initialValues.defaultAssignee = {
                    key: '' + initialValues.assigneeId,
                    label: printUserName(initialValues.assigneeFullName),
                };
            }

            if (this.props.initialValues.assigneeFullName) {
                initialValues.assigneeId = {
                    key: '' + initialValues.assigneeId,
                    label: printUserName(initialValues.assigneeFullName),
                };
            }

            if (this.props.initialValues.defaultAssignee) {
                initialValues.assigneeId = {
                    key: '' + initialValues.assigneeId,
                    label: printUserName(initialValues.assigneeFullName),
                };
            }

            if (initialValues.projectContract?.contractDate) {
                initialValues.projectContract.contractDate = moment(initialValues.projectContract.contractDate);
            }

            if (initialValues.projectContract?.contractExpirationDate) {
                initialValues.projectContract.contractExpirationDate = moment(initialValues.projectContract.contractExpirationDate);
            }

            initialValues.useAssignee = !initialValues.hasOwnAssignee;

            initialValues.useHasOwnContacts = !initialValues.hasOwnContacts;

            if (initialValues.renterContacts && initialValues.renterContacts[0]) {
                initialValues.contactsFIO =
                    (initialValues.renterContacts[0].fullName.lastname || '') +
                    ' ' +
                    (initialValues.renterContacts[0].fullName.firstname || '') +
                    ' ' +
                    (initialValues.renterContacts[0].fullName.secondname || '');
            } else {
                initialValues.contactsFIO = localizeIntl(this.props.intl, LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED) as string;
            }

            if (this.props.initialValues.renterAssigneeFullName) {
                initialValues.renterDefaultAssignee = {
                    key: '' + this.props.initialValues.renterAssigneeId,
                    label:
                        this.props.initialValues.renterAssigneeFullName.lastname +
                        ' ' +
                        this.props.initialValues.renterAssigneeFullName.firstname,
                };
            }

            if (initialValues.hasOwnDefaultDiscount) {
                initialValues.useRenterDefaultDiscount = false;
                initialValues._defaultDiscount = initialValues.defaultDiscount;
                initialValues['useRenterDefaultDiscount'] = false;
            } else {
                initialValues.defaultDiscount = initialValues.renterDefaultDiscount;
                initialValues._defaultDiscount = 0;
                initialValues['useRenterDefaultDiscount'] = true;
            }
        } else {
            if (this.props.renter) {
                initialValues = {
                    renterId: +(this.props.renter.key || this.props.renter),
                    renterStateCode: this.props.renterObj?.stateCode,
                    stateCode:
                        this.props.renterObj?.stateCode === RenterStateCodeEnum.NEW
                            ? ProjectStateCodeEnum.DRAFT
                            : (contractValidationEnabled ? ProjectStateCodeEnum.COORDINATION : ProjectStateCodeEnum.INPROGRESS),
                };

                if (this.props.renterObj) {
                    Object.assign(initialValues, {
                        defaultAssignee: this.props.renterObj.assigneeId,
                        assigneeId: this.props.renterObj.assigneeId,
                        hasOwnAssignee: true,
                        hasOwnDefaultDiscount: true,
                        defaultDiscount: this.props.renterObj.defaultDiscount,
                        _defaultDiscount: 0,
                        useRenterDefaultDiscount: true,
                        renterDefaultDiscount: this.props.renterObj.defaultDiscount,
                    });

                    if (this.props.renterObj.contacts && this.props.renterObj.contacts[0]) {
                        initialValues.contactsFIO = printUserName(this.props.renterObj.contacts[0].fullName);
                    } else {
                        initialValues.contactsFIO = localizeIntl(
                            this.props.intl,
                            LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED
                        ) as string;
                    }
                }
            }

            if (!initialValues) initialValues = {};
            initialValues.taxRate = this.props.businessAccountPreferences?.defaultTaxRate;
            initialValues.taxBaseType = this.props.businessAccountPreferences?.taxBaseType;
        }

        if (this.props.createFromOperationForm) {
            if (!initialValues) initialValues = {};
            initialValues.createFromOperationForm = this.props.createFromOperationForm;
        }

        if(contractValidationEnabled){
            if (!initialValues) initialValues = {};
            if(!initialValues.stateCode) initialValues.stateCode = ProjectStateCodeEnum.COORDINATION;
        }

        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            if (!this.props.onCancel) {
                if (this.props.initialValues) {
                    if (this.props.copyModeProject === false || this.props.copyModeProject === undefined) {
                        this.props.push(
                            `/${this.props.businessAccountId}/projects/production/${this.props.initialValues?.id}/?tab=description`
                        );
                    } else {
                        this.props.goBack();
                    }
                } else {
                    this.props.goBack();
                }
            } else {
                this.props.onCancel();
            }
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.project.updating,
    updatingError: storeState.project.updatingError,
    businessAccountPreferences: storeState.businessAccountPreferences.preferences,
    modalFilters: storeState.userSettings.modalFilters,
    offersModuleEnabled: offersModuleEnabledSelector(storeState),
    expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
    crewModuleEnabled: crewModuleEnabledSelector(storeState),
    contractValidationType: contractValidationTypeSelector(storeState)
});

const mapDispatchToProps = { createProject, updateEntity, goBack, resetError, push, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProjectCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ProjectCreateModal));

import React, {FC, useState} from 'react';
import './NomenclaturesInfo.less';
import {useAppSelector} from "../../../../../store/hooks";
import {InstanceRecord, NomenclatureEntityTypeCodeEnum, NomenclatureRecord} from "../../../../../server";
import {removeAllTimetables, TimeTable} from "../../reducers/operationForm.reducer";
import moment from "moment";
import {getStore} from "../../../../../../index";
import {getAllNomenclatureIntervalsForRentElements, NomenclatureOnInterval} from "../../utils/nomenclaturesUtils";
import classNames from "classnames";

interface Props {}

const getNomenclatureType = (type?: NomenclatureEntityTypeCodeEnum) => {
    if(type === NomenclatureEntityTypeCodeEnum.KIT) return 'Н';
    else if(type === NomenclatureEntityTypeCodeEnum.PRODUCT) return 'П';
    else if(type === NomenclatureEntityTypeCodeEnum.VARIANT) return 'В';
    else if(type === NomenclatureEntityTypeCodeEnum.INSTANCE) return 'Э';
    else return '?';
}

export const NomenclaturesInfo:FC<Props> = (props) => {

    const kitsInfo = useAppSelector((state)=>state.operationForm.kitsInfo);
    const timeTables = useAppSelector((state)=>state.operationForm.timeTables);
    const elements = useAppSelector((state)=>state.operationForm.elements.entities);
    const [visible, setVisible] = useState(false);
    const kits = timeTables.filter((tt)=>tt.type === NomenclatureEntityTypeCodeEnum.KIT);
    const products = timeTables.filter((tt)=>tt.type === NomenclatureEntityTypeCodeEnum.PRODUCT);
    const variants = timeTables.filter((tt)=>tt.type === NomenclatureEntityTypeCodeEnum.VARIANT);
    const instances = timeTables.filter((tt)=>tt.type === NomenclatureEntityTypeCodeEnum.INSTANCE);
    const withProblems = !true;

    const formatDate = (d?:number) => {
        return d !== undefined ? moment(d).format('DD.MM.YY HH:mm') : '?';
    };

    const printNomenclature = (tt:TimeTable) => {
        let name = '';
        if(tt.type === NomenclatureEntityTypeCodeEnum.INSTANCE) name = (tt.nomenclature as InstanceRecord).nameOrCode;
        else if(tt.type === NomenclatureEntityTypeCodeEnum.VARIANT) name = (tt.nomenclature as NomenclatureRecord).productShortName + ': ' + (tt.nomenclature as NomenclatureRecord).variantName;
        else if(tt.type === NomenclatureEntityTypeCodeEnum.PRODUCT) name = (tt.nomenclature as NomenclatureRecord).productShortName;
        else if(tt.type === NomenclatureEntityTypeCodeEnum.KIT) name = (tt.nomenclature as NomenclatureRecord).productShortName;
        if(name.length > 40) name = name.substring(0, 40) + '…';
        return <tr>
            <td>{getNomenclatureType(tt.type)}</td>
            <td>{tt.id}</td>
            <td>{name}</td>
            <td>{formatDate(tt.from)} - {formatDate(tt.until)}</td>
        </tr>;
    }

    const printAll = () => {
        return (
            <table>
                <tbody>
                {kits.map(item=>printNomenclature(item))}
                {products.map(item=>printNomenclature(item))}
                {variants.map(item=>printNomenclature(item))}
                {instances.map(item=>printNomenclature(item))}
                </tbody>
            </table>
        );
    }
    const printProblems = () => {
        const nomenclatures = getAllNomenclatureIntervalsForRentElements(elements);
        const problemNomenclatures:NomenclatureOnInterval[] = [];
        //const problem

        nomenclatures.forEach(n=>{
            const tt = timeTables.find(t=>t.id === n.id && t.type === n.type);
            //if()
        });

        // return (
        //     <table>
        //         <tbody>
        //         {nomenclatures.map(item=>printNomenclature(nomenclatureToTimetable(item)))}
        //         </tbody>
        //     </table>
        // );
    }

    const printCachedKits = () => {
        return (
            <>
                <table>
                    <tbody>
                    <tr>
                        <td>Наборы ({kitsInfo.length})</td>
                        <td>{kitsInfo.map(kit=>kit.id).toString()}</td>
                    </tr>
                    </tbody>
                </table>
            </>
        );
    }

    return (
        <div className={classNames('rr-NomenclaturesInfo', !visible && 'rr-NomenclaturesInfo-collapsed', withProblems && 'rr-NomenclaturesInfo-with-problems')}>
            <a className={'rr-NomenclaturesInfo-close'} onClick={()=>{
                setVisible(!visible);
            }}>[{visible ? '–' : '+'}]</a>
            {
                visible && (
                    <>
                        <div className={'rr-NomenclaturesInfo-header'} style={{fontWeight: 600}}>
                            Всего: {timeTables.length}{" "}
                            Продукты: {products.length}{" "}
                            Варианты: {variants.length}{" "}
                            Наборы: {kits.length}{" "}
                            Экземпляры: {instances.length}{" "}
                            <a onClick={()=>{
                                getStore().dispatch(removeAllTimetables());
                            }}>Удалить</a>
                        </div>
                        <div className={'rr-NomenclaturesInfo-body'}>
                            {printProblems()}
                            {printAll()}
                            {printCachedKits()}
                        </div>
                    </>
                )
            }
        </div>
    );
};

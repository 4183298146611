import { ColumnTypes, TableColumn } from '../../../../../../../../../../components/grid/Table';
import { ProfessionsCompositionListColumnsObj } from './professionsCompositionColumns';
import { LocalizationEnum, localize } from '../../../../../../../../../../localization';
import { MAX_WIDTH } from '../../../../../../../../../../config/constants';
import { Icon } from 'antd';
import { IconClose, IconEraser } from '../../../../../../../../../../components/icons';
import React from 'react';
import drag from '../../../../../../../../../../assets/images/drag.png';
import { EditableCurrencyField } from '../../../../../../../../../../components/fields/EditableField/variants/EditableCurrencyField';
import './professionsCompositionColumns.less';
import { MoneyUtils } from '../../../../../../../../../../core/utils/moneyUtils';
import { CrewMemberCompositionOnRowAction } from '../hooks/editGrid/useOnRowAction';
import {isDefined} from "../../../../../../../../../../shared/util/utils";

export const professionsCompositionEditColumns: TableColumn<ProfessionsCompositionListColumnsObj>[] = [
    {
        type: ColumnTypes.CustomRender,
        width: 60,
        maxWidth: 60,
        resizable: false,
        sortable: false,
        render: (value: boolean, professionData: ProfessionsCompositionListColumnsObj) => {
            return <div>{professionData.main ? null : <img src={drag} style={{ marginTop: '-3px' }} alt="" />}</div>;
        },
    },
    {
        title: 'Специальность',
        dataIndex: 'professionShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 250,
        maxWidth: MAX_WIDTH(250),
        resizable: true,
        dontHide: true,
        sortable: false,
        render: (value: string, professionData: ProfessionsCompositionListColumnsObj) => {
            return (
                <div
                    style={{ color: '#575962' }}
                    className={`rr-grid-shortName-cell ${professionData.main ? 'kit-of-product-main-element' : ''}`}
                >
                    {value}
                </div>
            );
        },
    },
    {
        title: 'Ставка за смену',
        dataIndex: 'rate.ratePerShift',
        type: ColumnTypes.CustomRender,
        minWidth: 250,
        maxWidth: MAX_WIDTH(250),
        resizable: true,
        sortable: false,
        render: (
            value: number | undefined,
            professionData: ProfessionsCompositionListColumnsObj,
            onRowAction: CrewMemberCompositionOnRowAction
        ) => {
            const {
                crewMember: {
                    defaultRates: { ratePerShift },
                },
            } = professionData;

            const v = isDefined(value) ? MoneyUtils.fromCents(value) : value;
            const baseValue = isDefined(ratePerShift) ? MoneyUtils.fromCents(ratePerShift) : ratePerShift;

            return (
                <EditableCurrencyField
                    currentValue={v}
                    baseValue={baseValue}
                    onChange={(value) => {
                        onRowAction('rate.ratePerShift', MoneyUtils.toCents(value), professionData);
                    }}
                />
            );
        },
    },
    {
        title: 'Ставка за час',
        dataIndex: 'rate.ratePerHour',
        type: ColumnTypes.CustomRender,
        minWidth: 250,
        maxWidth: MAX_WIDTH(250),
        resizable: true,
        sortable: false,
        render: (
            value: number | undefined,
            professionData: ProfessionsCompositionListColumnsObj,
            onRowAction: CrewMemberCompositionOnRowAction
        ) => {
            const {
                crewMember: {
                    defaultRates: { ratePerHour },
                },
            } = professionData;

            const v = isDefined(value) ? MoneyUtils.fromCents(value) : value;
            const baseValue = isDefined(ratePerHour) ? MoneyUtils.fromCents(ratePerHour) : ratePerHour;

            return (
                <EditableCurrencyField
                    currentValue={v}
                    baseValue={baseValue}
                    onChange={(value) => {
                        onRowAction('rate.ratePerHour', MoneyUtils.toCents(value), professionData);
                    }}
                />
            );
        },
    },
    {
        title: 'Сбросить к базовой ставке',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        maxWidth: MAX_WIDTH(140),
        resizable: true,
        sortable: false,
        render: (value: undefined, professionData: ProfessionsCompositionListColumnsObj, onRowAction: CrewMemberCompositionOnRowAction) => {
            if (professionData.rate == null) return null;

            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Icon
                        className={'actions-reset-icon'}
                        onClick={() => onRowAction('reset', value, professionData)}
                        component={IconEraser}
                    />
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__ACTION__DELETE),
        type: ColumnTypes.CustomRender,
        width: 90,
        maxWidth: MAX_WIDTH(90),
        resizable: true,
        sortable: false,
        render: (value: undefined, professionData: ProfessionsCompositionListColumnsObj, onRowAction: CrewMemberCompositionOnRowAction) => {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Icon
                        className={'actions-delete-icon'}
                        onClick={() => onRowAction('delete', value, professionData)}
                        component={IconClose}
                    />
                </div>
            );
        },
    },
];

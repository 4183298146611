import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { RentActivityFrameRecordList, serverApi } from '../../../../../../server';
import { ListRentActivityFramesArgs } from './activityFramesCalendar.api.types';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { AxiosError } from 'axios';
import { ActivityFramesCalendarApiUtils } from './activityFramesCalendar.api.utils';
import {
    logisticsModuleEnabledSelector,
    subrentModuleEnabledSelector,
} from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { IRootState } from '../../../../../../shared/reducers';
import { setCalendarBoundaries } from '../reducers/activityFramesCalendar.reducer';

export const activityFramesCalendarApi = createApi({
    reducerPath: 'activityFramesCalendarApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ActivityFramesCalendarList'],
    endpoints: (builder) => ({
        listRentActivityFrames: builder.query<RentActivityFrameRecordList, ListRentActivityFramesArgs>({
            queryFn: async ({ businessAccountId, params, visibleItemsLimit }, { getState, dispatch }) => {
                const state = getState() as IRootState;

                const subrentModuleEnabled = subrentModuleEnabledSelector(state);
                const logisticsModuleEnabled = logisticsModuleEnabledSelector(state);

                const group = logisticsModuleEnabled || params.group !== 'location' ? params.group : undefined;

                const sortBy = ActivityFramesCalendarApiUtils.getSortBy(group);
                const sortOrder = ActivityFramesCalendarApiUtils.getSortOrder(sortBy);
                const filters: string[] = ActivityFramesCalendarApiUtils.getFilters(params, subrentModuleEnabled, logisticsModuleEnabled);

                try {
                    const { data } = await serverApi.listRentActivityFrames(
                        businessAccountId,
                        visibleItemsLimit,
                        0,
                        filters,
                        sortBy,
                        sortOrder,
                        params.search
                    );

                    dispatch(
                        setCalendarBoundaries({
                            params,
                            records: data.records,
                        })
                    );

                    return {
                        data,
                    };
                } catch (error) {
                    showNotification('error', 'Не удалось загрузить список проектов');
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useListRentActivityFramesQuery } = activityFramesCalendarApi;

import {
    AvailableFilterValuesObj,
    CustomFieldDataTypeCodeEnum,
    CustomFieldObjRead,
    CustomFieldProcessingTypeCodeEnum,
} from '../../../../../server';
import { YesNoCheckbox } from './YesNoCheckbox/YesNoCheckbox';
import { FiltersDateRangePicker } from '../../../../../components/datePicker/FiltersDateRangePicker';
import { FormItemType, IFormField, SelectItem } from '../../../../../components/dynamicForm/DynamicForm';
import { UserSelect } from '../../../../../components/v2/select/userSelect/userSelect';
import { SliderRangeWithManualInput } from '../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { CustomFieldsUtils } from '../../../../../core/utils/customFieldsUtils';
import { ContactSelect } from '../../../../../components/v2/select/contactSelect/contactSelect';
import { SubrentSelect } from '../../../../../components/v2/select/subrentSelect/subrentSelect';
import { ProjectSelect } from '../../../../../components/v2/select/projectSelect/projectSelect';
import { RenterSelect } from '../../../../../components/v2/select/renterSelect/renterSelect';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';

export const getCustomFilterFieldProps = <PageParams extends object>(
    customField: CustomFieldObjRead,
    filtersInitialValues: PageParams,
    availableFiltersValues: Array<AvailableFilterValuesObj>
): Partial<IFormField> => {
    const { typeCode, multipleValues, processingType, customFieldIndexKey } = customField;

    if (typeCode === CustomFieldDataTypeCodeEnum.STRING) {
        const filter = availableFiltersValues.find(({ fieldName }) => CustomFieldsUtils.addPrefix(customFieldIndexKey) === fieldName);
        const { strings } = filter ?? {};

        const values: SelectItem[] =
            strings != null
                ? strings.map((string) => ({
                      name: string,
                      value: string,
                  }))
                : [];

        return {
            type: FormItemType.MultiSelect,
            style: { minWidth: 150 },
            placeholder: 'Любой',
            values,
        };
    }
    if (typeCode === CustomFieldDataTypeCodeEnum.BOOLEAN)
        return {
            component: YesNoCheckbox,
        };
    if (typeCode === CustomFieldDataTypeCodeEnum.DATE) {
        const placeholder = 'Выбрать дату';
        return {
            component: FiltersDateRangePicker,
            componentProps: () => ({
                showTime: false,
                placeholder: [placeholder, placeholder],
            }),
        };
    }
    if (typeCode === CustomFieldDataTypeCodeEnum.ENTITY) {
        const component = {
            [CustomFieldProcessingTypeCodeEnum.RENTERLINK]: RenterSelect,
            [CustomFieldProcessingTypeCodeEnum.PROJECTLINK]: ProjectSelect,
            [CustomFieldProcessingTypeCodeEnum.SUBRENTLINK]: SubrentSelect,
            [CustomFieldProcessingTypeCodeEnum.CONTACTLINK]: ContactSelect,
            [CustomFieldProcessingTypeCodeEnum.USERLINK]: UserSelect,
        };

        const isMultiple = Boolean(multipleValues);

        return {
            component: component[processingType],
            componentProps: {
                multiple: isMultiple,
                getInitialValue: (value) => ({ key: value, label: '' }),
                style: { minWidth: 180 },
                placeholder: 'Не выбрано',
                filters: ['archive;EQ;false'],
            },
        };
    }
    if (typeCode === CustomFieldDataTypeCodeEnum.LONG || typeCode === CustomFieldDataTypeCodeEnum.DOUBLE) {
        const filter = availableFiltersValues.find(({ fieldName }) => CustomFieldsUtils.addPrefix(customFieldIndexKey) === fieldName);
        let { minLong = tempMockMin, maxLong = tempMockMax } = filter ?? {};

        const isMoney = typeCode === CustomFieldDataTypeCodeEnum.LONG && processingType === CustomFieldProcessingTypeCodeEnum.MONEYSUM;

        if (isMoney) {
            [minLong, maxLong] = [minLong, maxLong].map((value) => MoneyUtils.toWhole(value));
        }

        filtersInitialValues[customFieldIndexKey] = [minLong, maxLong];

        return {
            component: SliderRangeWithManualInput,
            componentProps: () => ({
                formatStyle: isMoney ? 'currency' : undefined,
                min: minLong,
                max: maxLong,
                step: 1,
            }),
            style: { minWidth: 200 },
        };
    }

    return {};
};

export const tempMockMin = 0;
export const tempMockMax = 10;

import {MoneyUtils} from '../../../../../../../core/utils/moneyUtils';

export const changeInstanceCount = (instanceCount: number, discount: number, pricePerShift: number) => {
    const finalInstancePrice = MoneyUtils.calculateFinalInstancePrice(pricePerShift, discount);
    return {
        finalInstancePrice: finalInstancePrice,
        finalTotalPrice: finalInstancePrice * instanceCount,
    };
};

export const changeDiscount = (instanceCount: number, discount: number, pricePerShift: number) => {
    const finalInstancePrice = MoneyUtils.calculateFinalInstancePrice(pricePerShift, discount);
    return {
        finalInstancePrice: finalInstancePrice,
        finalTotalPrice: finalInstancePrice * instanceCount,
    };
};

export const changeFinalInstancePrice = (instanceCount: number, finalInstancePrice: number, pricePerShift: number) => {
    return {
        discount: 100 - (finalInstancePrice * 100) / pricePerShift,
        finalTotalPrice: finalInstancePrice * instanceCount,
    };
};

export const changeFinalTotalPrice = (instanceCount: number, finalTotalPrice: number, pricePerShift: number) => {
    return {
        discount: 100 - ((finalTotalPrice / instanceCount) * 100) / pricePerShift,
        finalInstancePrice: finalTotalPrice / instanceCount,
    };
};

import { ListProfessionsFilters } from '../api/types';
import { ProfessionStateCodeEnum } from '../../../../../../server';
import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';

export const ProfessionsPageFiltersDescription: PageURLParamDescriptionObject<ListProfessionsFilters> = {
    search: PageURLParamDescriptions.searchParam(),
    stateCode: PageURLParamDescriptions.enumSelectParam(ProfessionStateCodeEnum),
    hideArchive: PageURLParamDescriptions.switchParam,
    pricePerShift: PageURLParamDescriptions.numberInterval,
};

import React, { FC } from 'react';
import { workPlanningsCalendarSortValueToItemName } from '../../../../../../../../utils/data/sortData';
import { WorkPlanningsSplittedCalendarSidebarGroup } from '../../WorkPlanningsSplittedCalendarSidebarGroup';
import { useAppSelector } from '../../../../../../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../../../../../../shared/reducers/system.reducer';
import { ConditionalLink } from '../../../../../../../../../../../../../components/lib/ConditionalLink/ConditionalLink';
import { SideBarGroup } from '../../utils/types';
import { rentersPageUrlRoute } from '../../../../../../../../../../../renters/data/tabs';
import { RentersPageTabsEnum } from '../../../../../../../../../../../../../shared/constants/tabEnums';
import './ProjectSidebarGroup.less';
import { projectsPageUrlRoute } from '../../../../../../../../../../../projects/projectsPage';
import classNames from 'classnames';
import { IconBriefcase } from '../../../../../../../../../../../../../components/icons';

interface ProjectSidebarGroupProps extends SideBarGroup {}

export const ProjectSidebarGroup: FC<ProjectSidebarGroupProps> = (props) => {
    const { isCollapsed, onClick, workPlannings, displayType } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const titleKey = workPlanningsCalendarSortValueToItemName['project'];
    const { counterpartyId, counterpartyShortName, projectShortName, projectId } = workPlannings[0];

    const link = projectShortName && projectId ? `/${businessAccountId}/${projectsPageUrlRoute}/production/${projectId}` : undefined;
    const title = (
        <ConditionalLink
            condition={link != null}
            to={link}
            className={classNames({
                link: link != null,
            })}
        >
            {workPlannings[0][titleKey]}
        </ConditionalLink>
    );

    const subtitleLink = counterpartyShortName
        ? `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${counterpartyId}`
        : undefined;

    const subtitle = (
        <ConditionalLink condition={Boolean(subtitleLink)} to={subtitleLink} className="sidebar-project-group-link">
            {counterpartyShortName}
        </ConditionalLink>
    );

    return (
        <WorkPlanningsSplittedCalendarSidebarGroup
            workPlannings={workPlannings}
            displayType={displayType}
            onClick={onClick}
            isCollapsed={isCollapsed}
            iconComponent={IconBriefcase}
            title={title}
            subtitle={subtitle}
        />
    );
};

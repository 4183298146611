import React, {FC, memo, useCallback} from 'react';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { locationsColumns } from '../columns/locationsColumns';
import { locationsFilters } from '../filters/locationsFilters';
import { logisticsPageUrlRoute } from '../../../data/tabs';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { locationsApi, useListLocationsQuery } from '../api/locations.api';
import { locationEntitySelector } from '../reducers/location/location.selectors';
import { useSelectedRecords } from '../../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { useLocationEntityActions } from '../hooks/useLocationEntityActions';
import { useEntityActionsButtons } from '../hooks/useEntityActionsButtons';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { LocationCreateModalWrapper } from '../modal/create/LocationCreateModalWrapper';
import { LocationEditModalWrapper } from '../modal/edit/LocationEditModalWrapper';
import { clearLocation } from '../reducers/location/location.reducer';
import { LocationDrawerWrapper } from '../drawer/wrappers/LocationDrawerWrapper';
import { locationsPageFiltersDescription } from '../filters/locationsFiltersDescription';
import {
    ExcelExportTemplateTypeCodeEnum,
    LocationNotFinancialFieldsTypeCode,
    LocationRecord
} from '../../../../../../server';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import {ExportOffloadingPopover} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {canViewFinancialDataSelector} from "../../../../../../shared/reducers/permissions.reducer";
import {useIntl} from "react-intl";
import {LocationsUtils} from "../api/locations.api.utils";

interface LocationsListComponentProps {
    gridName: LogisticsPageTabsEnum;
}

const LocationsListComponent: FC<LocationsListComponentProps> = memo((props) => {
    const { gridName } = props;
    const location = useLocation();
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(locationEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: locationsPageFiltersDescription,
        gridName,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListLocationsQuery(
        {
            businessAccountId,
            params: queryParams,
        },
        {
            skip: !location.pathname.includes(gridName),
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const rootPath = `/${businessAccountId}/${logisticsPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;
    const entityActions = useLocationEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<LocationRecord>(entitySelection, queryData.data?.entitiesData.records);
    const { buttons: entityActionsButtons, isLoading: isEntityActionsButtonsLoading } = useEntityActionsButtons({
        selectedRecords,
        gridRef,
    });

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = LocationsUtils.createRequestFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.LOCATIONTEMPLATE,
                fileName: 'Экспорт локаций',
                fileType,
                filters,
                financialFieldsEnum: LocationNotFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: LocationNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined,
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: locationsColumns,
                    filtersData: locationsFilters,
                    pageParamsDescription: locationsPageFiltersDescription,
                },
                metaData: {
                    rootPath,
                    entityType: 'location',
                    gridName,
                },
                queryData,
                entitiesLoading: isEntityActionsButtonsLoading || entityActions.isLoading,
                entityActions,
                actionButtons: entityActionsButtons,
                exportBlock: <ExportOffloadingPopover storageKey={'locationsOffloading'} onDownload={onOffloadingDownload} />,
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: LocationCreateModalWrapper,
                    },
                    edit: {
                        component: LocationEditModalWrapper,
                    },
                },
                entityLoadActions: {
                    loadEntity: locationsApi.endpoints.locationById.initiate,
                    clearEntity: clearLocation,
                },
            }}
            drawerProps={{
                drawer: LocationDrawerWrapper,
                entityActions,
            }}
        />
    );
});

export const LocationsList: FC<LocationsListComponentProps> = withEntityListProviders(LocationsListComponent);

import React, { FC } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { usePaymentByIdQuery, useTransitWorkflowBulkMutation } from '../../api/payments.api';
import { EntityDrawer } from '../../../../../components/v2/entityDrawer/EntityDrawer';
import { PaymentDrawerTitle } from './PaymentDrawerTitle';
import { PaymentInfoReadWithId } from '../../api/payments.api.types';
import { PaymentDrawerDataBlocks } from './components/PaymentDrawerDataBlocks';

interface PaymentDrawerProps {
    paymentId: number;
    onClose: () => void;
    editPayment?: (payment: PaymentInfoReadWithId) => void;
}

export const PaymentDrawer: FC<PaymentDrawerProps> = (props) => {
    const { paymentId, onClose, editPayment } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const {
        data: payment,
        error,
        isFetching,
        isLoading: isEntityLoading,
    } = usePaymentByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: paymentId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [transitWorkflowBulk, { isLoading: transitWorkflowBulkIsLoading }] = useTransitWorkflowBulkMutation();

    const isLoading = Boolean(isEntityLoading || transitWorkflowBulkIsLoading);

    return (
        <EntityDrawer
            title={
                <PaymentDrawerTitle
                    payment={payment as PaymentInfoReadWithId}
                    editPayment={editPayment}
                    transitWorkflowBulk={transitWorkflowBulk}
                    isLoading={isLoading || isFetching}
                />
            }
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
            onClose={onClose}
        >
            <PaymentDrawerDataBlocks payment={payment} onClose={onClose} />
        </EntityDrawer>
    );
};

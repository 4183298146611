import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Dropdown, Icon } from 'antd';
import { IconQuestionCircle } from '../../../icons';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { KnowledgeBaseArticleLinkObj, LocaleCodeEnum, serverApi } from '../../../../server';
import { InterfaceBlockCode } from './utils/constants';
import { InfoSelectDropdownMenu } from './menu/InfoSelectDropdownMenu';
import './InfoSelectDropdown.less';
import { useIntl } from 'react-intl';
import { isStringInEnum } from '../../../../shared/util/utils';
import { useAppSelector } from '../../../../store/hooks';

interface InfoProps {
    interfaceBlockCode: InterfaceBlockCode;
}

export const InfoSelectDropdown: FC<InfoProps> = memo(({ interfaceBlockCode }) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [infoData, setInfoData] = useState<KnowledgeBaseArticleLinkObj[] | undefined>();
    const [isLoadingError, setIsLoadingError] = useState<any>();

    const loadInfoData = useCallback(() => {
        setIsLoadingError(undefined);

        serverApi
            .getBusinessAccountKnowledgeBaseArticleLinkList(
                businessAccountId,
                interfaceBlockCode,
                isStringInEnum((intl.locale ?? intl.defaultLocale).toUpperCase(), LocaleCodeEnum, LocaleCodeEnum.RU) as LocaleCodeEnum
            )
            .then((response) => {
                setInfoData(response.data.records);
            })
            .catch((error) => {
                setIsLoadingError(true);
            });
    }, [businessAccountId, interfaceBlockCode, intl.defaultLocale, intl.locale]);

    useEffect(() => {
        if (dropdownVisible) {
            loadInfoData();
        } else {
            setInfoData(undefined);
        }
    }, [dropdownVisible, loadInfoData]);

    return (
        <Dropdown
            onVisibleChange={(visible: boolean) => {
                setDropdownVisible(visible);
            }}
            visible={dropdownVisible}
            placement={'bottomRight'}
            overlay={infoData || isLoadingError ? <InfoSelectDropdownMenu infoData={infoData} isLoadingError={isLoadingError} /> : <></>}
            trigger={['click']}
            overlayStyle={{ maxHeight: 270, overflowY: infoData && infoData.length > 8 ? 'auto' : undefined }}
            overlayClassName={'ant-select-dropdown'}
        >
            <Icon className={'info-select-dropdown'} component={IconQuestionCircle} />
        </Dropdown>
    );
});

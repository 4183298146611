import {NomenclatureRecord} from '../../../../../../server';
import {ServerUtils} from '../../../../../../core/utils/serverUtils';
import {SummaryScheduleCalendarFilters} from "../filters/summaryScheduleCalendarFilters";
import {DeepKeys} from "@tanstack/react-table";

export abstract class SummaryScheduleCalendarApiUtils {

    /**  */
    static getNomenclatureListFilters = (params: SummaryScheduleCalendarFilters) => {
        const {categoryIds} = params;
        const filters = ServerUtils.createRequestFilters<NomenclatureRecord>([
            categoryIds && ['categoryIds', 'IN', Array.isArray(categoryIds) ? categoryIds : [categoryIds]],
            ['archive', 'EQ', false]
        ]);
        return filters.length > 0 ? filters : undefined;
    };

    /**  */
    static getNomenclatureSearch = (params: SummaryScheduleCalendarFilters) => {
        return params.search;
    };

    /**  */
    static getNomenclatureSortBy = (params: SummaryScheduleCalendarFilters) => {
        let sortBy:DeepKeys<NomenclatureRecord>|undefined;
        if(params.group === 'name') sortBy = 'productShortName';
        else if(params.group === 'category') sortBy = 'mainCategoryName';
        else if(params.group === 'article') sortBy = 'externalCode';
        else if(params.group === 'status') sortBy = 'stateCode';
        else if(params.group === 'type') sortBy = 'nomenclatureEntityTypeCode';
        return sortBy;
    };
}

import { Card } from 'antd';
import React from 'react';
import Spin from '../../../../../components/spin/spin';
import { typedMemo } from '../../../../../core/hooks/useMemo';
import { CalendarBottomNavigation } from '../СalendarBottomNavigation/СalendarBottomNavigation';
import { CalendarFilters, CalendarFiltersProps } from '../CalendarFilters/CalendarFilters';
import { Calendar } from '../Calendar/Calendar';
import { ICalendarItem } from '../Calendar/hooks/useCalendarItems';
import { CalendarItemType } from '../Calendar/types/items';
import { CalendarProps } from '../Calendar/types/props';

interface EntityCalendarProps<
    Record extends ICalendarItem,
    Params extends object,
    SortValue extends string,
    ICalendarItemType extends CalendarItemType
> extends CalendarProps<Record, Params, SortValue, ICalendarItemType>,
        CalendarFiltersProps<Params, SortValue> {}

const EntityCalendarComponent = <
    Record extends ICalendarItem,
    Params extends object,
    SortValue extends string,
    ICalendarItemType extends CalendarItemType
>(
    props: EntityCalendarProps<Record, Params, SortValue, ICalendarItemType>
) => {
    const {
        calendarType,
        filtersData,
        groupsData,
        isLoading,
        onClick,
        pageName,
        pageParamsDescription,
        pageParamsObject,
        processItemFunction,
        recordList,
        screenLeft,
        screenRight,
        setVisibleItemsLimit,
        sortData,
        sortGroupMap,
        useFiltersChanges,
        visibleItemsLimit,
        filtersFormRef,
        itemRenderer,
        headerButtons,
        hiddenFieldsForCount,
    } = props;

    const { pageParams } = pageParamsObject;

    return (
        <Card>
            <Spin wrapperClassName={'clickable-spin'} spinning={isLoading} delay={0}>
                <CalendarFilters
                    pageName={pageName}
                    pageParamsDescription={pageParamsDescription}
                    pageParamsObject={pageParamsObject}
                    filtersData={filtersData}
                    useFiltersChanges={useFiltersChanges}
                    filtersFormRef={filtersFormRef}
                    hiddenFieldsForCount={hiddenFieldsForCount}
                />
                <Calendar
                    groupsData={groupsData}
                    calendarType={calendarType}
                    isLoading={isLoading}
                    onClick={onClick}
                    pageParamsObject={pageParamsObject}
                    processItemFunction={processItemFunction}
                    recordList={recordList}
                    screenLeft={screenLeft}
                    screenRight={screenRight}
                    setVisibleItemsLimit={setVisibleItemsLimit}
                    sortData={sortData}
                    sortGroupMap={sortGroupMap}
                    visibleItemsLimit={visibleItemsLimit}
                    itemRenderer={itemRenderer}
                    headerButtons={headerButtons}
                />
                <CalendarBottomNavigation
                    capture={pageParams.capture}
                    pageName={pageName}
                    pageParams={pageParams}
                    positionTop={false}
                    screenLeft={screenLeft}
                    screenRight={screenRight}
                />
            </Spin>
        </Card>
    );
};

export const EntityCalendar = typedMemo(EntityCalendarComponent);

import { CategorisedEntityCodeEnum } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { IRootState } from '../../../../shared/reducers';
import { LocalStorageUtils } from '../../../../core/utils/localStorageUtils';
import { useCallback, useMemo } from 'react';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';

export const getChosenCategoryForActionStorageKey = (entityTypeCode: CategorisedEntityCodeEnum) => `${entityTypeCode}_target_category`;

export const useCategoryIdStorageValue = (entityTypeCode: CategorisedEntityCodeEnum) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const email = useAppSelector((state: IRootState) => state.oidc.user?.profile.email);
    const storageItemKey = LocalStorageUtils.getKey(getChosenCategoryForActionStorageKey(entityTypeCode), businessAccountId, email);

    const selectedKeyFromStorage = useMemo(() => {
        const record = localStorage.getItem(storageItemKey);
        if (record) {
            try {
                return Number(record);
            } catch (e) {}
        }
    }, [storageItemKey]);

    const setSelectedKeyAndSaveToStorage = useCallback(
        (key: number | undefined) => {
            if (key) {
                localStorage.setItem(storageItemKey, String(key));
            } else {
                localStorage.removeItem(storageItemKey);
            }
        },
        [storageItemKey]
    );

    return [selectedKeyFromStorage, setSelectedKeyAndSaveToStorage] as [number | undefined, (key: number | undefined) => void];
};

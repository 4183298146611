import React, { FC } from 'react';
import { AccessibilityMapCalendarGroup } from '../../hooks/useCalendarGroups';
import { AccessibilityMapLocalizationMap } from '../../data/localizationData';
import './AccessibilityMapCalendarGroupRenderer.less';
import { TimetableTypeCodeEnum } from '../../../../../../../server';
import { AccessibilityMapCalendarItem, CalendarItem } from '../../../../components/Calendar/types/items';
import classNames from 'classnames';

interface AccessibilityMapCalendarRendererProps {
    group: AccessibilityMapCalendarGroup;
    items: CalendarItem<AccessibilityMapCalendarItem>[];
}

export const AccessibilityMapCalendarGroupRenderer: FC<AccessibilityMapCalendarRendererProps> = (props) => {
    const { group, items } = props;
    const { typeCode, id: groupId } = group;

    if (typeCode === TimetableTypeCodeEnum.AVAILABLE) {
        const itemsForGroup = items.filter(({ group }) => group === groupId);
        const valuesForGroup = itemsForGroup.map(({ value }) => value);
        const minValue = valuesForGroup.length > 0 ? Math.min(...valuesForGroup) : 0;

        const color = minValue > 0 ? '#34bfa3' : minValue < 0 ? '#f4516c' : '#AAA';

        return (
            <div className={'sidebar-group-available-container'}>
                <span className={classNames('sidebar-group-title', 'bold')}>
                    {AccessibilityMapLocalizationMap[typeCode]}:{' '}
                    <span
                        className={'value'}
                        style={{
                            color,
                        }}
                    >
                        {minValue}
                    </span>
                </span>
                <span className={'sidebar-group-title-helper'}>Весь период</span>
            </div>
        );
    }

    return <span className={'sidebar-group-title'}>{AccessibilityMapLocalizationMap[typeCode]}</span>;
};

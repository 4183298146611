import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { IRootState } from '../../../../shared/reducers';
import { columns } from './product-page-variants-columns';
import { Card, Icon } from 'antd';
import {
    addCustomField,
    deleteEntities, getProductVariantsFilters,
    initialParamsState,
    loadAvailableFilters,
    loadEntities,
    removeCustomField,
    reset as resetVariants,
    setArchiveState,
} from './reducers/product-variants.reducer';
import { loadEntity, resetVariant } from './reducers/product-variant.reducer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ColumnTypes } from '../../../../components/grid/Table';
import {
    CustomFieldRecord,
    ExcelExportTemplateTypeCodeEnum,
    VariantFinancialFieldsTypeCode, VariantNotFinancialFieldsTypeCode,
    VariantRecord
} from '../../../../server';
import { getPathFromState, getStateFromPath2 } from '../../../../shared/util/utils';
import every from 'lodash/every';
import RoundButton from '../../../../components/button/roundButton';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { IconArchive, IconLevelUp, IconShapesSolid, IconStreamSolid, IconTrash } from '../../../../components/icons';
import { Link } from 'react-router-dom';
import { ProductVariantsCreateModal } from './product-variants-create-modal';
import { Location } from 'history';
import { CustomFieldAddPopover } from '../components/CustomFilterAddPopover/CustomFieldAddPopover';
import { ProductVariantPopover } from './components/productVariantPopover/productVariantPopover';
import debounce from 'lodash/debounce';
import { filters } from './product-page-variants-filters';
import { IFormField } from '../../../../components/dynamicForm/DynamicForm';
import { Spin } from '../../../../components';
import { EntityActionType } from '../../../../../index';
import { ProductVariantsChangeCountModal } from './product-variants-change-count-modal';
import { Styles } from '../../../../styles';
import { showConfirm, showConfirm2 } from '../../../../components/confirm/showConfirm';
import AddProductInstance from '../instances/modal/product-page-instances-modal';
import { Grid } from '../../../../components/grid/Grid';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';
import { PageUtils } from '../../../../shared/util/pageUtils';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { createProductVariantsModalFilters, editProductVariantsModalFilters } from './product-variants-create-modal-data';
import { createProductInstancesModalFilters } from '../instances/modal/product-page-instances-fields-data';
import { DeepKeys } from '@tanstack/react-table';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import _ from 'lodash';
import DescriptionCustomField from '../components/DescriptionCustomField/DescriptionCustomField';
import { ContactDrawer } from '../../renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { archiveContactEntityForClass, editContactEntityForClass } from '../components/DescriptionCustomField/utils/contactActions';
import {ExportOffloadingPopover} from "../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";

interface IState {
    selection: number[];
    spinner: boolean;
    instance: number;
    currentVariant: any;
    businessVersionVariant: number;
    filters: typeof filters;
    contactId?: number;
}

interface IProps extends StoreState, WrappedComponentProps {
    location: Location;
    productId: number;
    onDeleteVariants: (variantsId: number[]) => void;
}

export const PRODUCTS_VARIANTS_GRID_NAME = 'product-variants';

class _ProductPageVariantsTab extends React.PureComponent<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters;
    private initialValues;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['sortBy', 'sortOrder', 'search', 'finalTotalPrice', 'hideArchive'];

    constructor(props: IProps) {
        super(props);

        this.state = {
            selection: [],
            spinner: false,
            instance: 0,
            currentVariant: undefined,
            businessVersionVariant: 0,
            filters: filters,
            contactId: undefined,
        };
        this.updateFilters = debounce(this.updateFilters, 250);
    }

    async componentDidMount() {
        this.initialValues = this.props.pageParams;

        try {
            await this.props.loadAvailableFilters(this.props.businessAccountId, this.props.productId);

            this.updateFiltersBoundaries();

            if (this.props.entityId) {
                this.props.loadEntity(this.props.businessAccountId, this.props.productId, this.props.entityId);
            }
        } catch (e) {
            console.error(e);
            if (!this.props.pageParams.finalTotalPrice) {
                this.filtersForm.setFieldsValue({ finalTotalPrice: [0, 1000000] });
            }
        }

        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    }

    componentDidUpdate = async (prevProps: IProps) => {
        let params = { ...this.props.pageParams };
        params.mode = undefined;
        params.id = undefined;

        let prevParams = { ...prevProps.pageParams };
        prevParams.mode = undefined;
        prevParams.id = undefined;

        PageUtils.update(
            prevParams,
            params,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );

        if (this.props.entityId !== prevProps.entityId && this.props.entityId) {
            if (!this.props.productVariant) {
                this.props.resetVariant();
            }
            this.props.loadEntity(this.props.businessAccountId, this.props.productId, this.props.entityId);
        }

        if (this.props.needsToupdateEntities) {
            this.loadDataAndSetFilterValues();
        }

        if (prevProps.entities && prevProps.entities !== this.props.entities) {
            this.clearSelection();
        }

        if (!_.isEqual(prevProps.availableFilters?.pricePerShift, this.props.availableFilters?.pricePerShift)) {
            this.updateFiltersBoundaries();
        }
    };

    updateFiltersBoundaries = async () => {
        await this.props.loadAvailableFilters(this.props.businessAccountId, this.props.productId);

        let filter = this.props.availableFilters?.pricePerShift;
        if (filter) {
            const newFilters = _.cloneDeep(this.state.filters);
            const field = (newFilters[0].fields as IFormField[]).find((item) => item.id === 'finalTotalPrice');
            if (field) {
                const props = typeof field.componentProps !== 'function' ? field.componentProps : {};

                const newMinValue = filter[0] / 100;
                const newMaxValue = filter[1] / 100;

                if (newMinValue === props?.min && newMaxValue === props.max) return;

                field.componentProps = {
                    ...props,
                    formatStyle: 'currency',
                    min: newMinValue,
                    max: newMaxValue,
                };
                if (!this.props.pageParams.finalTotalPrice) {
                    setTimeout(() => {
                        this.filtersForm.setFieldsValue({ finalTotalPrice: [newMinValue, newMaxValue] });
                    });
                }
                this.setState({
                    filters: newFilters,
                });
            }
        }
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(PRODUCTS_VARIANTS_GRID_NAME);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = async () => {
        await this.loadProducts();
        await this.props.loadAvailableFilters(this.props.businessAccountId, this.props.productId);

        if (!this.fromFilters && this.filtersForm) {
            let values = { ...initialParamsState, ...this.props.pageParams };
            if (!values.finalTotalPrice) {
                if (this.props.availableFilters?.pricePerShift) {
                    values.finalTotalPrice = [
                        this.props.availableFilters.pricePerShift[0] / 100,
                        this.props.availableFilters.pricePerShift[1] / 100,
                    ];
                }
            }
            this.filtersForm.setFieldsValue(values);
        }
        this.fromFilters = false;
    };

    updateFilters = (data) => {
        const { locationPathname, locationSearchParams } = this.props;

        if (data.finalTotalPrice && this.props.availableFilters) {
            if (
                data.finalTotalPrice.toString() ===
                [this.props.availableFilters.pricePerShift[0] / 100, this.props.availableFilters.pricePerShift[1] / 100].toString()
            ) {
                data.finalTotalPrice = undefined;
            }
        }

        if (data.hideArchive === false) data.hideArchive = undefined;

        data.page = 1;

        this.fromFilters = true;
        setGridStorageDataFilters(PRODUCTS_VARIANTS_GRID_NAME, data);
        this.props.push(getPathFromState(locationPathname, locationSearchParams, data));
        this.clearSelection();
    };

    loadProducts = async () => {
        await this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.productId);
    };

    componentWillUnmount = () => {
        console.log('ProductPageVariantsTab componentWillUnmount()');
        this.props.resetVariant();
        this.props.resetVariants();
    };

    onRowAction = async (item: VariantRecord, action: EntityActionType) => {
        const handlers: Partial<Record<EntityActionType, Function>> = {
            edit: () => {
                let editPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
                    mode: 'edit',
                    id: item.id,
                });
                this.props.push(editPath);
            },
            copy: () => {
                const editPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
                    mode: 'copy',
                    id: item.id,
                });
                this.props.push(editPath);
            },
            archive: () => {
                this.props.setArchiveState(this.props.intl, this.props.businessAccountId, this.props.productId, [
                    { id: item.id, businessVersion: item.businessVersion, archive: !item.archive },
                ]);
            },
            setQuantity: () => {
                const path = getPathFromState(this.props.location.pathname, this.props.location.search, {
                    mode: 'edit-count',
                    id: item.id,
                });
                this.props.push(path);
            },
            addInstance: () => {
                this.setState({
                    currentVariant: { key: item.id, label: item.name },
                    businessVersionVariant: item.businessVersion,
                });
                this.props.push(`/${this.props.businessAccountId}/inventory/products/${this.props.productId}/addInst?tab=variants`);
            },
            delete: async () => {
                let yes = await showConfirm2({
                    intl: this.props.intl,
                    title: 'Удалить вариант и все его экземпляры безвозвратно?',
                });
                if (yes) {
                    this.props.deleteEntities(
                        this.props.intl,
                        this.props.businessAccountId,
                        this.props.productId,
                        [{ id: item.id, businessVersion: item.businessVersion }],
                        (variantsId) => {
                            this.updateFiltersBoundaries();
                            this.props.onDeleteVariants(variantsId);
                        }
                    );
                    this.clearSelection();
                }
            },
        };

        await handlers[action]?.();
    };

    spin = () => {};

    onPageSizeChanged = (size) => {
        const { locationPathname, locationSearchParams } = this.props;
        this.props.push(getPathFromState(locationPathname, locationSearchParams, { limit: size, page: 1 }));
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        const { locationPathname, locationSearchParams } = this.props;
        this.props.push(getPathFromState(locationPathname, locationSearchParams, { sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' }));
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        const { locationPathname, locationSearchParams } = this.props;
        this.props.push(getPathFromState(locationPathname, locationSearchParams, { page: page }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    getActionButtons = () => {
        let selectedItems = this.props.entities
            ? this.props.entities.filter((item: VariantRecord, index) => {
                  return this.state.selection.indexOf(item.id) > -1;
              })
            : [];
        return [
            every(selectedItems, (item) => !item.archive && item.archivable) ? (
                <RoundButton
                    key={0}
                    title={'' + localizeIntl(this.props.intl, LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            every(selectedItems, (item) => item.archive) ? (
                <RoundButton
                    key={1}
                    title={'' + localizeIntl(this.props.intl, LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            every(selectedItems, (item: VariantRecord) => item.deleteable) ? (
                <RoundButton key={5} title={'Удалить'} onClick={this.onDeleteBtnClick} colorScheme={'DELETE'}>
                    <Icon component={IconTrash} />
                    Удалить
                </RoundButton>
            ) : null,
        ];
    };

    onDeleteBtnClick = async () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => ({ id: item.id, businessVersion: item.businessVersion }));
            let isMany = selectedItems.length > 1;
            let yes = await showConfirm2({
                intl: this.props.intl,
                title: isMany ? 'Удалить варианты и все их экземпляры безвозвратно?' : 'Удалить вариант и все его экземпляры безвозвратно?',
            });
            if (yes) {
                this.props.deleteEntities(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.productId,
                    selectedItems,
                    (variantsId) => {
                        this.updateFiltersBoundaries();
                        this.props.onDeleteVariants(variantsId);
                    }
                );
                this.clearSelection();
            }
        }
    };

    onArchiveBtnClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => ({ id: item.id, businessVersion: item.businessVersion, archive: !item.archive }));
            this.props.setArchiveState(this.props.intl, this.props.businessAccountId, this.props.productId, selectedItems);
            this.clearSelection();
        }
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            finalTotalPrice: undefined,
            hideArchive: undefined,
        };
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(PRODUCTS_VARIANTS_GRID_NAME, data);
    };

    onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        let { pageParams, businessAccountId, canViewFinancialData, subrentModuleEnabled, intl,  } = this.props;

        const { sortOrder, sortBy, search } = pageParams;
        const filters = getProductVariantsFilters(pageParams);

        filters.push(`productId;EQ;${this.props.productId}`);

        await downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.VARIANTTEMPLATE,
            fileName: 'Экспорт вариантов',
            fileType,
            filters,
            financialFieldsEnum: VariantFinancialFieldsTypeCode,
            grid: this.grid,
            intl,
            notFinancialFieldsEnum: VariantNotFinancialFieldsTypeCode,
            selection: this.state.selection,
            values: options,
            sortOrder,
            sortBy,
            search,
            excludeColumns: undefined
        });
    };

    render() {
        console.log('ProductPageVariantsTab render()', this.props);

        let { canViewFinancialData, subrentModuleEnabled } = this.props;

        let modalBackPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
            mode: undefined,
            id: undefined,
        });
        let createPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
            mode: 'create',
            id: undefined,
        });

        let newColumns = [...columns];
        let addedIds: number[] = [];
        let ind = 0;

        if (this.props.productVariantsCustomFieldMarkers) {
            this.props.productVariantsCustomFieldMarkers.forEach((item, index) => {
                newColumns.splice(4 + index, 0, {
                    title: <>{item.name}</>,
                    dataIndex: ('customFieldValues.' + item.customFieldIndexKey) as any, // TODO
                    type: ColumnTypes.CustomRender,
                    minWidth: 140,
                    resizable: true,
                    removable: true,
                    render: (value: any, rowData: VariantRecord) => {
                        let field;
                        if (rowData.customFieldValues) {
                            let f = rowData.customFieldValues.find((field) => field.customFieldIndexKey === item.customFieldIndexKey);
                            if (f) {
                                field = {
                                    ...item,
                                    values: f.values,
                                    linkValues: f.linkValues,
                                };
                            }
                        }

                        if (field == null) return null;

                        return (
                            <DescriptionCustomField
                                field={field}
                                customFieldsParameters={{
                                    forGrid: true,
                                    separator: ', ',
                                }}
                                ignoreEmpty
                                ignoreDrawer
                                setContactId={(id: number) =>
                                    this.setState({
                                        contactId: id,
                                    })
                                }
                            />
                        );
                    },
                });
                addedIds.push(item.customFieldId);
            });
        }

        let productFieldIds: number[] = [];

        this.props.productEntity?.customFieldGroups?.map((gr) => {
            gr.fields.forEach((f) => {
                productFieldIds.push(f.customFieldId);
            });
        });

        let excludeColumns: DeepKeys<VariantRecord>[] = [];
        let filtersExcludeFields: string[] = [];

        if (!canViewFinancialData) {
            excludeColumns.push('pricePerShift');
            filtersExcludeFields.push('finalTotalPrice');
        }

        if (!subrentModuleEnabled) {
            excludeColumns.push('warehousePresenceCounters.subrentedInstanceCount');
        }

        // TODO потом нужно будет нормально выровнять иконку по вертикали!!!
        return (
            <>
                <Card
                    className={'rr-card'}
                    bodyStyle={{ display: 'none' }}
                    style={{ borderBottom: '1px solid #cccccc' }}
                    title={
                        <div style={{ padding: '11px 0px 11px 0px' }}>
                            <span className="card-title">
                                <Icon
                                    component={IconShapesSolid}
                                    style={{ marginRight: 10, marginTop: -4, color: '#979cbf' }}
                                    className={Styles.ICON_26}
                                />
                                {localize(LocalizationEnum.PAGE__VARIANTS__VARIANT_LIST)}
                            </span>
                        </div>
                    }
                    extra={
                        <>
                            <CustomFieldAddPopover
                                multiple
                                showIcons
                                productFieldIds={productFieldIds}
                                variantFieldIds={addedIds}
                                groupId={ind}
                                onCreate={async (groupId: number, fields: CustomFieldRecord[]) => {
                                    let productFields: number[] = [];
                                    let addedFields: CustomFieldRecord[] = [];
                                    if (this.props.productEntity) {
                                        this.props.productEntity.customFieldGroups?.forEach((group) => {
                                            group.fields.forEach((field) => {
                                                productFields.push(field.customFieldId);
                                            });
                                        });
                                    }

                                    fields.forEach((field) => {
                                        if (productFields.includes(field.id)) {
                                            addedFields.push(field);
                                        }
                                    });

                                    let ok: boolean = true;
                                    if (addedFields.length) {
                                        ok = await showConfirm(
                                            this.props.intl,
                                            `Некоторые из выбранных полей уже привязаны к продукту. Привязать ${
                                                addedFields.length
                                            } ${localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__FIELD, undefined, {
                                                value: addedFields.length,
                                            })} к вариантам по отдельности вместо продукта в целом?`
                                        );
                                    }

                                    if (ok) {
                                        this.props.addCustomField(
                                            this.props.intl,
                                            this.props.businessAccountId,
                                            this.props.productId,
                                            fields.map((field) => field.id)
                                        );
                                    }
                                }}
                            >
                                <RoundButton
                                    disabled={!this.props.entities || this.props.entities.length === 0}
                                    colorScheme={'defaultSecondary'}
                                    style={{ marginRight: 16 }}
                                    title={'' + localizeIntl(this.props.intl, LocalizationEnum.PAGE__VARIANTS__ADD_CUSTOM_FIELD)}
                                >
                                    <Icon component={IconStreamSolid} />
                                    {localize(LocalizationEnum.PAGE__VARIANTS__ADD_CUSTOM_FIELD, 'span')}
                                </RoundButton>
                            </CustomFieldAddPopover>

                            <Link to={createPath}>
                                <RoundButton
                                    icon={'plus'}
                                    title={'' + localizeIntl(this.props.intl, LocalizationEnum.PAGE__VARIANTS__CREATE_VARIANT)}
                                    colorScheme={'successSecondary'}
                                >
                                    {localize(LocalizationEnum.PAGE__VARIANTS__CREATE_VARIANT, 'span')}
                                </RoundButton>
                            </Link>
                        </>
                    }
                />

                {this.props.productEntity ? (
                    <Spin spinning={this.props.loading}>
                        <Card className={'rr-card'}>
                            <Grid
                                filtersData={this.state.filters}
                                filtersInitialValues={this.initialValues}
                                filtersCurrentValues={this.props.pageParams}
                                filtersDefaultValues={initialParamsState}
                                filtersGetFiltersFormRef={this.getFiltersForm}
                                filtersOnChange={this.onFiltersChange}
                                filtersResetFiltersCb={this.resetFilters}
                                filtersExcludeFields={filtersExcludeFields}
                                ref={(ref) => {
                                    if (!this.grid) this.grid = ref;
                                }}
                                onRowAction={this.onRowAction}
                                onSortedChange={this.onSortedChange}
                                onPageChanged={this.onPageChanged}
                                onPageSizeChanged={this.onPageSizeChanged}
                                onSelectionChanged={this.onSelectionChanged}
                                filtered={this.props.filteredCount}
                                pageSize={this.props.pageParams.limit}
                                currentPage={this.props.pageParams.page}
                                columns={newColumns}
                                data={this.props.entities}
                                indexFieldName={'id'}
                                actionButtons={this.getActionButtons()}
                                defaultSorted={this.props.pageParams.sortBy}
                                defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                                hideArchive={this.props.pageParams.hideArchive}
                                entityType={'variant'}
                                dynamicColumns
                                onColumnRemove={this.onColumnRemove}
                                rowPopoverComponent={ProductVariantPopover}
                                gridName={PRODUCTS_VARIANTS_GRID_NAME}
                                excludeColumns={excludeColumns}
                                exportBlock={<ExportOffloadingPopover storageKey={'productVariantsOffloading'} onDownload={this.onOffloadingDownload} />}
                            />
                        </Card>
                    </Spin>
                ) : null}
                {this.props.mode === 'create' && this.props.productEntity ? (
                    <ProductVariantsCreateModal
                        editMode={false}
                        title={localize(LocalizationEnum.PAGE__VARIANTS__FORM__FORM_TITLE__CREATE)}
                        intl={this.props.intl}
                        goBackPath={modalBackPath}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        filters={createProductVariantsModalFilters}
                        modalName={'create-product_variants'}
                    />
                ) : null}
                {this.props.mode === 'copy' && this.props.productEntity && this.props.productVariant ? (
                    <ProductVariantsCreateModal
                        editMode={false}
                        title={localize(LocalizationEnum.PAGE__VARIANTS__FORM__FORM_TITLE__CREATE)}
                        initialValues={this.props.productVariant}
                        intl={this.props.intl}
                        goBackPath={modalBackPath}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        filters={createProductVariantsModalFilters}
                        modalName={'create-product_variants'}
                        validateAfterCreate
                    />
                ) : null}
                {this.props.mode === 'edit' && this.props.productEntity && this.props.productVariant ? (
                    <ProductVariantsCreateModal
                        editMode={true}
                        title={localize(LocalizationEnum.PAGE__VARIANTS__FORM__FORM_TITLE__EDIT)}
                        initialValues={this.props.productVariant}
                        intl={this.props.intl}
                        goBackPath={modalBackPath}
                        filters={editProductVariantsModalFilters}
                        modalName={'edit-product_variants'}
                    />
                ) : null}
                {this.props.mode === 'edit-count' && this.props.productEntity && this.props.productVariant ? (
                    <ProductVariantsChangeCountModal
                        initialValues={this.props.productVariant}
                        title={localize(LocalizationEnum.PAGE__PRODUCTS__FORM__AMOUNT_EDITION_FORM_LABEL)}
                        intl={this.props.intl}
                        goBackPath={modalBackPath}
                    />
                ) : null}
                {this.props.addCountMode ? (
                    <AddProductInstance
                        spin={this.spin}
                        entityId={this.state.instance}
                        editMode={false}
                        variant={this.state.currentVariant}
                        businessVersionVariant={
                            this.state.businessVersionVariant
                                ? this.state.businessVersionVariant
                                : this.props
                                ? this.props.productVariant
                                    ? this.props.productVariant.businessVersion
                                    : 0
                                : 0
                        }
                        title={localize(LocalizationEnum.PAGE__FORM__FORM_TITLE__CREATE)}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        filters={createProductInstancesModalFilters}
                        modalName={'create-product_instances'}
                    />
                ) : null}
                {this.state.contactId && (
                    <ContactDrawer
                        contactId={this.state.contactId}
                        onClose={() => this.setState({ contactId: undefined })}
                        editContact={this.props.editContactEntityForClass}
                        archiveContact={this.props.archiveContactEntityForClass}
                    />
                )}
            </>
        );
    }

    onColumnRemove = async (id?: string) => {
        id = id?.replace('customFieldValues.', '');
        const marker = this.props.productVariantsCustomFieldMarkers?.find((m) => m.customFieldIndexKey === id);
        if (marker) {
            let ok = await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.PAGE__MODAL__REMOVE_FIELD));
            if (ok) {
                if (id)
                    this.props.removeCustomField(this.props.intl, this.props.businessAccountId, this.props.productId, marker.customFieldId);
            }
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    entities: storeState.productVariants.entities,
    availableFilters: storeState.productVariants.availableFilters,
    loading: storeState.productVariants.loading,
    loadingError: storeState.productVariants.loadingError,
    businessAccountId: storeState.system.businessAccountId,
    pageParams: storeState.productVariants.params,
    filteredCount: storeState.productVariants.filteredCount,
    locationSearchParams: storeState.router.location.search,
    locationPathname: storeState.router.location.pathname,
    mode: storeState.productVariants.params.mode,
    entityId: storeState.productVariants.params.id,
    productEntity: storeState.product.entity,
    productsEntity: storeState.products.entities,
    productVariantsCustomFieldMarkers: storeState?.product?.entity?.variantsCustomFieldMarkers,
    productVariant: storeState.productVariant.entity,
    addCountMode: !!(
        storeState.product.entity &&
        storeState.router.location.pathname ===
            `/${storeState.system.businessAccountId}/inventory/products/${storeState.product.entity?.id}/addInst`
    ),
    needsToupdateEntities: storeState.productVariants.needsToupdateEntities,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
});

const mapDispatchToProps = {
    loadEntities,
    loadEntity,
    resetVariant,
    push,
    replace,
    setArchiveState,
    removeCustomField,
    addCustomField,
    resetVariants,
    deleteEntities,
    loadAvailableFilters,
    editContactEntityForClass,
    archiveContactEntityForClass,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreState = ConnectedProps<typeof connector>;

export const ProductPageVariantsTab = connector(injectIntl(_ProductPageVariantsTab));

type FilteredObjectBtKeysMode = 'exclude' | 'extract';
type FilteredObjectBtKeys<T extends object, Keys extends keyof T, Mode extends FilteredObjectBtKeysMode> = Mode extends 'exclude'
    ? Omit<T, keyof T>
    : Mode extends 'extract'
    ? Pick<T, Extract<keyof T, Keys>>
    : never;

export abstract class ObjectUtils {
    static filterByKeys = <T extends object, Keys extends keyof T>(
        obj: T,
        keys: Keys[],
        mode: FilteredObjectBtKeysMode
    ): FilteredObjectBtKeys<T, Keys, FilteredObjectBtKeysMode> => {
        const omittedObject = {};

        Object.entries(obj).forEach(([key, value]) => {
            const isValueIncludes = keys.includes(key as Keys);
            if (mode === 'extract') {
                if (isValueIncludes) omittedObject[key] = value;
            }
            if (mode === 'exclude') {
                if (!isValueIncludes) omittedObject[key] = value;
            }
        });

        return omittedObject as FilteredObjectBtKeys<T, Keys, FilteredObjectBtKeysMode>;
    };
    static strictMap = <T extends object>(obj: T, mapFunction: (key: keyof T, value: T[keyof T]) => T[keyof T]): T => {
        const mappedObject: T = {} as T;

        Object.entries(obj).forEach(([key, value]) => {
            mappedObject[key] = mapFunction(key as keyof T, value);
        });

        return mappedObject;
    };
    static getObjectWordFromCount = (number: number, wordCase: boolean): string => {
        const initialString = String(number);
        const lastSymbol = initialString[initialString.length - 1];
        const words = wordCase ? ['объект', 'объекта', 'объектов'] : ['объекта', 'объектов', 'объектов'];

        if (Number(lastSymbol) === 1) return words[0];

        if ([2, 3, 4].includes(Number(lastSymbol))) return words[1];

        return words[2];
    };
}

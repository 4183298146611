import { FileTypeCodeEnum } from '../../../../../../server';
import { DownloadDocumentPopoverProps } from '../../../DownloadDocumentPopover';

export enum ExportToExcellPopoverOptions {
    ALL_COLUMNS = 'allColumns',
    EXPAND_KITS = 'expandKits',
}

export const availableFileTypes = [FileTypeCodeEnum.EXCEL] as const;
export type ExportToExcellFileType = (typeof availableFileTypes)[number];
export type ExportToExcellOnDownload = DownloadDocumentPopoverProps<ExportToExcellFileType, ExportToExcellPopoverOptions>['onDownload'];

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../components/modalForm/utils';
import { formFields } from '../LocationModalFields';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import { goBack, push } from 'connected-react-router';
import { resetErrors } from '../../../../../renters/reducers/renters/renter.reducer';
import { ContactLinkObjWrite, LocationInfoCreate } from '../../../../../../../server';
import { createLocation } from '../../reducers/location/location.reducer';
import { getStore } from '../../../../../../../../index';
import { showNotification } from '../../../../../../../components/notification/showNotification';

interface LocationCreateModalProps extends ModalFormNEWProps, WrappedComponentProps {}

export interface LocationFormData {
    address: string;
    comment?: string;
    id?: number;
    isEdit?: boolean;
    latitude?: string;
    longitude?: string;
    shortName: string;
    contacts?: ContactLinkObjWrite[];
}

export class _LocationCreateModal extends ModalFormNEW<LocationCreateModalProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = async (data: unknown) => {
        const formData = data as LocationFormData;
        let { contacts } = formData;
        if (contacts) contacts = contacts.filter((contact) => contact.contactId);

        try {
            const location = await getStore().dispatch(
                createLocation({
                    businessAccountId: this.props.businessAccountId,
                    locationInfoCreate: {
                        ...formData,
                        latitude: formData.latitude ? parseFloat(formData.latitude) : undefined,
                        longitude: formData.longitude ? parseFloat(formData.longitude) : undefined,
                        contacts,
                    } as LocationInfoCreate,
                })
            );
            showNotification('success', 'Локация создана');
            this.props.onSuccess?.(location);
        } catch (e) {
            showNotification('error', 'Не удалось создать локацию');
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.onCancel?.();
            this.props.resetErrors();
        }
    };

    getInitialValues = (): Partial<LocationFormData> => {
        return {
            contacts: [
                {
                    contactId: undefined as unknown,
                    position: '',
                } as ContactLinkObjWrite,
            ],
        };
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.location.updating,
    updatingError: storeState.location.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    push,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const LocationCreateModal = connector(injectIntl(_LocationCreateModal));

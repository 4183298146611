import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectProps, BaseSelectValue, selectValueToNumber } from '../baseSelect';
import { CounterpartyActivityTypeCodeEnum, RenterRecord, RenterStateCodeEnum, serverApi } from '../../../../server';
import { ServerUtils } from '../../../../core/utils/serverUtils';
import { useAppSelector } from '../../../../store/hooks';

type Record = RenterRecord;

interface Filters {
    stateCode?: RenterStateCodeEnum[];
    activityTypeCode?: CounterpartyActivityTypeCodeEnum[];
    archive?: boolean;
}

export interface RenterSelectProps extends BaseSelectBaseProps<Record> {
    getOptionProps?: BaseSelectProps<Record>['getOptionProps'];
    filters?: Filters;
}

const createFilters = (filters?: Filters, ids?: (number | string)[]) => {
    const f = ServerUtils.createRequestFilters<Record>([
        ids ? ['id', 'IN', ids] : undefined,
        ids == null ? ['archive', 'EQ', false] : undefined,
        filters?.stateCode ? ['stateCode', 'IN', filters.stateCode] : undefined,
        filters?.activityTypeCode ? ['activityTypeCode', 'IN', filters.activityTypeCode] : undefined,
    ]);

    return f;
};

export const RenterSelectFC = ({ ...props }: RenterSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const fetchRecordsById = async (id: BaseSelectValue) => {
        const ids = (Array.isArray(id) ? id : [id]).map((id) => selectValueToNumber(id));
        const filters = createFilters(props.filters, ids);
        const response = await serverApi.listRenters(businessAccountId, undefined, 0, filters);
        return response.data.records;
    };

    const fetchRecords = async (startFrom: number, limit: number, search?: string) => {
        const filters = createFilters(props.filters);
        const response = await serverApi.listRenters(businessAccountId, limit, startFrom, filters, 'lastActivityDate', 'DESC', search);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={fetchRecordsById}
            fetchRecords={fetchRecords}
            getRecordId={(record) => record.id}
            getOptionProps={
                props.getOptionProps ||
                ((record) => ({
                    label: record.shortName,
                }))
            }
        />
    );
};

export class RenterSelect extends React.Component<RenterSelectProps> {
    render() {
        return <RenterSelectFC {...this.props} />;
    }
}

import React, {FC} from 'react';
import {LegalDetailsInfoRead} from '../../../../server';
import {LegalDetailsActionType} from "../data/legalDetailsData";
import {legalDetailsApi} from "../api/legalDetails.api";
import {LegalDetailsModalProps} from "../modal/legalDetailsModal";
import {isDefined} from "../../../../shared/util/utils";
import {getStore} from "../../../../../index";
import {showNotification} from "../../../../components/notification/showNotification";
import {LegalDetailsBlock, LegalDetailsBlockProps} from "./legalDetailsBlock";
import {showConfirm2} from "../../../../components/confirm/showConfirm";
import {useIntl} from "react-intl";

export interface LegalDetailsBlockWrapperProps {
    data?: LegalDetailsInfoRead;
    onAction: (action: LegalDetailsActionType.create | LegalDetailsActionType.edit) => void;
    entityType: LegalDetailsModalProps['entityType'];
    afterDeleteCb: () => void;
    storageKey: LegalDetailsBlockProps['storageKey'];
}

export const LegalDetailsBlockWrapper: FC<LegalDetailsBlockWrapperProps> = ({storageKey, data, onAction, entityType, afterDeleteCb}) => {

    const intl = useIntl();

    return (
        <LegalDetailsBlock
            storageKey={storageKey}
            entityType={entityType}
            data={data}
            onAction={async (action) => {
                if (action === LegalDetailsActionType.delete) {
                    const yes = await showConfirm2({intl, title: 'Удалить реквизиты?'});
                    if (yes && data && isDefined(data.businessAccountId) && isDefined(data.id) && isDefined(data.businessVersion)) {
                        try {
                            await getStore().dispatch(
                                legalDetailsApi.endpoints.deleteLegalDetailById.initiate(
                                    {
                                        businessAccountId: data.businessAccountId,
                                        id: data.id,
                                        businessVersionObj: {
                                            businessVersion: data.businessVersion
                                        }
                                    })
                            ).unwrap();
                            showNotification('success', 'Реквизиты удалены');
                            afterDeleteCb();
                        } catch (error) {
                            showNotification('error', 'Реквизиты не удалены');
                        }
                    }
                } else if (action === LegalDetailsActionType.create || action === LegalDetailsActionType.edit) {
                    onAction(action);
                }
            }}
        />
    );
};


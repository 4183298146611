import { TimetableTypeCodeEnum } from '../../../../../../server';
import { useMemo } from 'react';
import { CalendarDisplayType } from '../../../components/Calendar/utils/data/calendarConstants';
import { CalendarLineUtils } from '../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import { getAvailableStacks } from '../utils/getAvailableStacks';
import { useAppSelector } from '../../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';

export type AccessibilityMapCalendarGroup = { id: number; title: string; typeCode: TimetableTypeCodeEnum; height: number };

export const useCalendarGroups = (stacks: TimetableTypeCodeEnum[], displayType: CalendarDisplayType): AccessibilityMapCalendarGroup[] => {
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const currentStacks = getAvailableStacks(subrentModuleEnabled);

    return useMemo(() => {
        return currentStacks
            .filter((typeCode) => stacks.includes(typeCode))
            .map((typeCode) => {
                const stackIndex = currentStacks.findIndex((stack) => typeCode === stack);

                return {
                    id: stackIndex,
                    title: ``,
                    typeCode: currentStacks[stackIndex],
                    height: CalendarLineUtils.map.groupHeightFromDisplayType.large[displayType],
                };
            });
    }, [currentStacks, stacks, displayType]);
};

import { AccessibilityMapFilters } from '../filters/accessibility/accessibilityMapCalendarFilters.types';
import { serverApi, TimetableObj } from '../../../../../../server';
import { getAvailableStacks } from '../utils/getAvailableStacks';
import { AccessibilityMapCalendarApiUtils } from './accessibilityMapCalendar.api.utils';
import { ListRentElementsWithAccessibilityResult } from '../../elements/api/elementsCalendar.api.types';
import { processActualCalendarBoundaries } from '../../elements/reducers/elementsCalendar.reducer.utils';
import { setCalendarBoundaries } from '../../elements/reducers/elementsCalendar.reducer';
import { getAccessibilityMapCalendarDataFromBase64 } from '../utils/getAccessibilityMapCalendarDataFromBase64';
import { CalendarVisibleItemsLimit } from '../../../components/Calendar/utils/data/calendarConstants';
import { ThunkDispatch } from 'redux-thunk';

type RequestArgs = {
    businessAccountId: number;
    dispatch: ThunkDispatch<any, any, any>;
    elementsFilters: string[];
    params: AccessibilityMapFilters;
    sortBy: string;
    sortOrder: 'ASC' | 'DESC';
    storeScreenLeft: number;
    storeScreenRight: number;
    subrentModuleEnabled: boolean | undefined;
    visibleItemsLimit: CalendarVisibleItemsLimit;
    specialFromStartDateIncludingDelays: Date;
};

export abstract class AccessibilityMapCalendarApiRequestUtils {
    static getAccessibilityMapList = async (
        businessAccountId: number,
        params: AccessibilityMapFilters,
        from: number,
        until: number,
        subrentModuleEnabled: boolean | undefined
    ): Promise<TimetableObj[] | undefined> => {
        const { instanceId } = params;
        const listOnIntervalRequestObject = {
            listFilters: AccessibilityMapCalendarApiUtils.getFilters(params),
        };
        const currentStacks = getAvailableStacks(subrentModuleEnabled);

        if (instanceId != null) {
            const { data } = await serverApi.listInstancesOnInterval(
                businessAccountId,
                from,
                until,
                listOnIntervalRequestObject,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                currentStacks
            );

            const instance = data.records.find((record) => record.id === instanceId);

            return instance?.stackMapList?.maps;
        } else {
            const { data } = await serverApi.listNomenclatureOnInterval(
                businessAccountId,
                from,
                until,
                listOnIntervalRequestObject,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                currentStacks
            );

            const nomenclature = data.records.find((record) => record.nomenclatureEntityTypeCode);

            return nomenclature?.stackMapList.maps;
        }
    };
    static getAccessibilityDataAsync = async (args: RequestArgs): Promise<ListRentElementsWithAccessibilityResult> => {
        const {
            businessAccountId,
            dispatch,
            elementsFilters,
            params,
            sortBy,
            sortOrder,
            storeScreenLeft,
            storeScreenRight,
            subrentModuleEnabled,
            visibleItemsLimit,
            specialFromStartDateIncludingDelays,
        } = args;

        const { data: elementsData } = await serverApi.listBusinessAccountElements(
            businessAccountId,
            visibleItemsLimit,
            0,
            elementsFilters,
            sortBy,
            sortOrder,
            undefined,
            undefined,
            undefined,
            specialFromStartDateIncludingDelays
        );

        const { screenLeft, screenRight } = processActualCalendarBoundaries(params, elementsData.records);

        dispatch(
            setCalendarBoundaries({
                screenLeft,
                screenRight,
            })
        );

        const from = screenLeft ?? storeScreenLeft;
        const until = screenRight ?? storeScreenRight;

        const accessibilityMapList = await this.getAccessibilityMapList(businessAccountId, params, from, until, subrentModuleEnabled);

        if (accessibilityMapList == null)
            return {
                elementsData,
                accessibilityData: [],
            };

        const accessibilityData = getAccessibilityMapCalendarDataFromBase64(accessibilityMapList);

        return {
            elementsData,
            accessibilityData,
        };
    };
    static getAccessibilityDataSync = async (args: RequestArgs): Promise<ListRentElementsWithAccessibilityResult> => {
        const {
            businessAccountId,
            elementsFilters,
            params,
            sortBy,
            sortOrder,
            storeScreenLeft,
            storeScreenRight,
            subrentModuleEnabled,
            visibleItemsLimit,
            specialFromStartDateIncludingDelays,
        } = args;
        const { screenLeft, screenRight } = params;

        const [{ data: elementsData }, accessibilityMapList] = await Promise.all([
            await serverApi.listBusinessAccountElements(
                businessAccountId,
                visibleItemsLimit,
                0,
                elementsFilters,
                sortBy,
                sortOrder,
                undefined,
                undefined,
                undefined,
                specialFromStartDateIncludingDelays
            ),
            this.getAccessibilityMapList(
                businessAccountId,
                params,
                screenLeft ?? storeScreenLeft,
                screenRight ?? storeScreenRight,
                subrentModuleEnabled
            ),
        ]);

        if (accessibilityMapList == null)
            return {
                elementsData,
                accessibilityData: [],
            };

        const accessibilityData = getAccessibilityMapCalendarDataFromBase64(accessibilityMapList);

        return {
            elementsData,
            accessibilityData,
        };
    };
    static getAccessibilityData = async (args: RequestArgs): Promise<ListRentElementsWithAccessibilityResult> => {
        const { params } = args;

        if (params.capture === 'all' || params.capture === 'allWithNow') {
            return this.getAccessibilityDataAsync(args);
        }

        return this.getAccessibilityDataSync(args);
    };
}

import React, {FC, useCallback} from 'react';
import {Icon} from "antd";
import {IconCalendar, IconCashRegisterSolid, IconEdit, IconSyncSolid} from "../../../../../../components/icons";
import {RoundButton} from "../../../../../../components";
import {ChangeElementsPricePopover} from "../../elementsList/components/changeElementsPricePopover";
import {RoundButtonGroup} from "../../../../../../components/button/roundButtonGroup";
import {ChangeElementsDateParamPopover} from "../../elementsList/components/ddd/changeElementsDateParamPopover";
import {ChangeElementsShiftCountPopover} from "../../elementsList/components/ddd/changeElementsShiftCountPopover";
import {ChangeElementsDiscountPopover} from "../../elementsList/components/ddd/changeElementsDiscountPopover";
import {useAppDispatch} from "../../../../../../store/hooks";
import {recalculateShiftCountForSelectedIds} from "../../../reducers/operationForm.reducer";

interface PropsSelectedElementsActionButtonsBlock {
    selectedIds: number[];
    onApply?: () => void;
    hideDateButtons?: boolean;
    hidePriceButtons?: boolean;
}

export const SelectedElementsActionButtonsBlock: FC<PropsSelectedElementsActionButtonsBlock> = ({selectedIds, onApply, hideDateButtons, hidePriceButtons}) => {

    const dispatch = useAppDispatch();

    const onCloseCB = useCallback(() => {
        //onApply?.();
    }, [onApply]);


    const showDateButtons = hideDateButtons !== true;
    const showPriceButtons = hidePriceButtons !== true;
    const showShiftCountsButton = true;
    const showRecalculateShiftCountsButton = true;

    return (
        <>
            {
                showDateButtons && (
                    <RoundButtonGroup>
                        <ChangeElementsDateParamPopover mode={'startDate'} selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_DATES"}><Icon component={IconCalendar}/> Начало</RoundButton>
                        </ChangeElementsDateParamPopover>
                        <ChangeElementsDateParamPopover mode={'range'} selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_DATES"}>Период</RoundButton>
                        </ChangeElementsDateParamPopover>
                        <ChangeElementsDateParamPopover mode={'endDate'} selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_DATES"}>Завершение</RoundButton>
                        </ChangeElementsDateParamPopover>
                    </RoundButtonGroup>
                )
            }

            {
                showShiftCountsButton && (
                    <ChangeElementsShiftCountPopover selectedIds={selectedIds} onClose={onCloseCB} >
                        <RoundButton colorScheme={"ACTION_ELEMENTS_SET_SHIFTS"}>
                            <Icon component={IconEdit}/> Смены
                        </RoundButton>
                    </ChangeElementsShiftCountPopover>
                )
            }

            {
                showPriceButtons && (
                    <RoundButtonGroup>
                        <ChangeElementsDiscountPopover selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_PRICE"}>
                                <Icon component={IconCashRegisterSolid}/> Скидка
                            </RoundButton>
                        </ChangeElementsDiscountPopover>
                        <ChangeElementsPricePopover selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_PRICE"}>Стоимость</RoundButton>
                        </ChangeElementsPricePopover>
                    </RoundButtonGroup>
                )
            }

            {
                showRecalculateShiftCountsButton && (
                    <RoundButton colorScheme={"ACTION_ELEMENTS_RECALCULATE_SHIFTS"} onClick={() => {
                        dispatch(recalculateShiftCountForSelectedIds(selectedIds));
                        onCloseCB();
                    }}>
                        <Icon component={IconSyncSolid}/> Пересчитать смены
                    </RoundButton>
                )
            }

        </>
    );
};

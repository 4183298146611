import React, {FC} from "react";
import classNames from "classnames";
import './dot.less';

interface DotProps {
    className?: string;
    style?: React.CSSProperties;
}

export const Dot:FC<DotProps> = (props) => (
    <span style={props.style} className={classNames('rr-dot', props.className)}></span>
);

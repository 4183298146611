import { CalendarId, CalendarItem, CalendarItemGroup, WorkPlanningsCalendarItem } from '../../../../../../components/Calendar/types/items';
import { CalendarLineContainer } from '../../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarLineContainer/CalendarLineContainer';
import { ItemContext, ReactCalendarItemRendererProps, ReactCalendarTimelineProps } from 'react-calendar-timeline';
import { CalendarGroupsObject } from '../../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import React, { FC } from 'react';
import { CalendarDisplayType } from '../../../../../../components/Calendar/utils/data/calendarConstants';
import { WorkPlanningsSortValue } from '../../../../utils/data/sortData';
import { WorkPlanningGroupGroupLine } from '../../../../components/GroupLine/variants/WorkPlanningGroupGroupLine';
import { WorkPlanningsItemRenderer } from '../../../../components/ItemRenderer/WorkPlanningsItemRenderer';

interface WorkPlanningsSplittedCalendarItemRendererComponentBaseProps {
    displayType: CalendarDisplayType;
    onItemClick: (itemId: CalendarId, itemContext: ItemContext) => void;
    groupsData: CalendarGroupsObject<WorkPlanningsSortValue>;
    sortByValue?: string;
}

interface WorkPlanningsSplittedCalendarItemRendererComponentProps {
    calendarProps: ReactCalendarItemRendererProps<CalendarItem<WorkPlanningsCalendarItem> | CalendarItemGroup>;
    baseProps: WorkPlanningsSplittedCalendarItemRendererComponentBaseProps;
}

const WorkPlanningsSplittedCalendarItemRendererComponent: FC<WorkPlanningsSplittedCalendarItemRendererComponentProps> = (props) => {
    const { baseProps, calendarProps } = props;
    const { displayType, onItemClick } = baseProps;
    const { item } = calendarProps;
    const { elementType } = item;

    const isItemGroup = elementType === 'group';
    const isItemEmpty = elementType === 'empty';

    if (isItemEmpty) return <></>;

    if (isItemGroup)
        return (
            <CalendarLineContainer
                calendarProps={calendarProps}
                displayType={displayType}
                borderRadius={undefined}
                onItemClick={onItemClick}
                linkPath={undefined}
                className={'group'}
            >
                <WorkPlanningGroupGroupLine displayType={displayType} groupItem={item} />
            </CalendarLineContainer>
        );

    return (
        <WorkPlanningsItemRenderer
            baseProps={{
                ...baseProps,
                onPopoverVisibleChange: () => {},
            }}
            calendarProps={calendarProps as ReactCalendarItemRendererProps<CalendarItem<WorkPlanningsCalendarItem>>}
        />
    );
};

interface WorkPlanningsSplittedCalendarItemRendererProps extends WorkPlanningsSplittedCalendarItemRendererComponentBaseProps {}
export const WorkPlanningsSplittedCalendarItemRenderer = (
    props: WorkPlanningsSplittedCalendarItemRendererProps
): ReactCalendarTimelineProps<CalendarItem<WorkPlanningsCalendarItem> | CalendarItemGroup>['itemRenderer'] => {
    return (calendarProps) => <WorkPlanningsSplittedCalendarItemRendererComponent baseProps={props} calendarProps={calendarProps} />;
};

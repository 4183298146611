import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useLocation } from 'react-router-dom';
import { canUpdateFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { DragGroup } from './types/types';
import { showConfirm } from '../../../../../../components/confirm/showConfirm';
import { getCustomFieldTypeByCode, LocalizationEnum, localize, localizeIntl } from '../../../../../../localization';
import { push } from 'connected-react-router';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { reorderDragItems } from './utils/reorderDragItems';
import { useGroupActions } from './hooks/useGroupActions';
import { useOnDragEnd } from './hooks/useOnDragEnd';
import { useDragIds } from './hooks/useDragIds';
import { PromptRedirect } from '../../../../../../components/prompt/PromptRedirect/PromptRedirect';
import { Button, Card, Icon } from 'antd';
import { IconAngleDown, IconAngleUp, IconPlus, IconStreamSolid, IconThumbtackSolid } from '../../../../../../components/icons';
import { Styles } from '../../../../../../styles';
import RoundButton from '../../../../../../components/button/roundButton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GroupNameInlineInput } from '../GroupNameInlineInput/GroupNameInlineInput';
import { CustomFieldAddPopover } from '../../../components/CustomFilterAddPopover/CustomFieldAddPopover';
import { CustomFieldGroupObjRead, CustomFieldRecord, KitInfoRead, ProductInfoRead } from '../../../../../../server';
import { SystemIcon, SystemIconType } from '../../../../../../components/v2/systemIcon/systemIcon';
import { getItemStyle } from './utils/getDraggableItemStyle';
import { CustomFieldsFieldPopover } from '../../../../settings/customFields/customFieldsFieldPopover';
import classNames from 'classnames';
import { ProductPageFeaturesCreateGroupModal } from '../../../products/tabs/features/product-page-features-create-group-modal';

interface EntityFeaturesEditProps<T extends ProductInfoRead | KitInfoRead> {
    entity: T | undefined;
    onSave: (groups: CustomFieldGroupObjRead[], entity: T) => Promise<void>;
    groups: Array<CustomFieldGroupObjRead>;
    updating: boolean;
}

export const EntityFeaturesEdit = <T extends ProductInfoRead | KitInfoRead>(props: EntityFeaturesEditProps<T>) => {
    const { entity, onSave, groups: _groups, updating } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const canUpdateFinancialData = useAppSelector(canUpdateFinancialDataSelector);
    const [groups, setGroups] = useState<DragGroup[]>(() =>
        _groups.map((group) => {
            let fields = group.fields.map((field) => {
                return field;
            });
            return { ...group, fields };
        })
    );
    const [groupCreation, setGroupCreation] = useState(false);
    const [groupIsEditing, setGroupIsEditing] = useState(false);
    const saving = useRef(false);

    const updateFields = useCallback(async () => {
        if (entity == null) return;

        let emptyGroups = false;

        saving.current = true;
        groups.forEach((group) => {
            if (group.fields.length === 0 && !group.main) emptyGroups = true;
        });

        let ok = emptyGroups
            ? await showConfirm(intl, localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__MODAL__QUESTIONS__DELETE_EMPTY_GROUPS))
            : true;
        if (ok) {
            onSave(groups, entity).finally(() => {
                saving.current = false;
            });
        }
    }, [entity, groups, intl, onSave]);

    const toViewMode = () =>
        dispatch(
            push(
                getPathFromState(location.pathname, location.search, {
                    edit: undefined,
                })
            )
        );

    const moveGroup = (index: number, up: boolean) => {
        const newGroups = reorderDragItems(groups, index, index + (up ? -1 : 1));

        setGroups(newGroups);
    };

    const { addNewGroup, renameGroup, deleteGroup } = useGroupActions(groups, setGroups);

    const onDragEnd = useOnDragEnd(groups, setGroups);

    const { variantFieldIds, addedIds } = useDragIds(groups, entity);

    return (
        <>
            {!updating && !saving.current && <PromptRedirect />}
            <Card
                className={'rr-product-page-features-view-card'}
                title={
                    <>
                        <div className="ant-modal-title" style={{ alignItems: 'center', padding: '7px 0px' }}>
                            <Icon component={IconStreamSolid} style={{ marginRight: 10, color: '#979cbf' }} className={Styles.ICON_26} />
                            {localize(LocalizationEnum.PAGE__PRODUCTS__CUSTOM_FIELDS_SETUP, 'span')}
                        </div>
                    </>
                }
                extra={
                    <RoundButton
                        icon={'plus'}
                        colorScheme={'successSecondary'}
                        onClick={() => {
                            setGroupCreation(true);
                        }}
                        style={{ marginRight: 16 }}
                    >
                        {localize(LocalizationEnum.PAGE__PRODUCTS__CREATE_GROUP, 'span')}
                    </RoundButton>
                }
                bodyStyle={{ padding: 0 }}
            >
                <div style={{ minHeight: 400 }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {groups.map((group, index, arr) => {
                            const canMoveUp = !group.main && index > 1;
                            const canMoveDown = !group.main && index < arr.length - 1;

                            return (
                                <>
                                    <Droppable key={index} droppableId={`${index}`}>
                                        {(provided, snapshot) => (
                                            <div className={'grrr'} ref={provided.innerRef} {...provided.droppableProps}>
                                                <div
                                                    className={
                                                        'rr-product-page-features-edit-group-title' +
                                                        (group.main ? ' rr-product-page-features-edit-group-title-main' : '')
                                                    }
                                                >
                                                    <div className={'rr-product-page-features-edit-group-title-move-icons'}>
                                                        {!group.main ? (
                                                            <Icon
                                                                className={
                                                                    'rr-product-page-features-edit-group-title-move-icon-up' +
                                                                    (canMoveUp ? '' : '-disabled')
                                                                }
                                                                component={IconAngleUp}
                                                                onClick={
                                                                    canMoveUp
                                                                        ? () => {
                                                                              moveGroup(index, true);
                                                                          }
                                                                        : undefined
                                                                }
                                                            />
                                                        ) : null}

                                                        {!group.main ? (
                                                            <Icon
                                                                className={
                                                                    'rr-product-page-features-edit-group-title-move-icon-down' +
                                                                    (canMoveDown ? '' : '-disabled')
                                                                }
                                                                component={IconAngleDown}
                                                                onClick={
                                                                    canMoveDown
                                                                        ? () => {
                                                                              moveGroup(index, false);
                                                                          }
                                                                        : undefined
                                                                }
                                                            />
                                                        ) : null}
                                                    </div>
                                                    {group.main ? (
                                                        <Icon
                                                            className={'rr-product-page-features-edit-group-title-move-icon-thumb'}
                                                            component={IconThumbtackSolid}
                                                        />
                                                    ) : null}
                                                    <GroupNameInlineInput
                                                        onStartEdit={() => {
                                                            setGroupIsEditing(true);
                                                        }}
                                                        canEdit={!group.main}
                                                        value={
                                                            group.main
                                                                ? (localizeIntl(
                                                                      intl,
                                                                      LocalizationEnum.PAGE__PRODUCTS__MAIN_ONES_LABEL
                                                                  ) as string)
                                                                : group.name
                                                        }
                                                        onChange={(value) => {
                                                            setGroupIsEditing(false);
                                                            renameGroup(index, value);
                                                        }}
                                                    />
                                                    <CustomFieldAddPopover
                                                        multiple={true}
                                                        forProduct={true}
                                                        showIcons={true}
                                                        variantFieldIds={variantFieldIds}
                                                        productFieldIds={addedIds}
                                                        groupId={index}
                                                        onCreate={(groupId: number, fields: CustomFieldRecord[]) => {
                                                            const newGroups = [...groups];
                                                            fields.forEach((field) => {
                                                                if (field) {
                                                                    newGroups[groupId].fields.push({
                                                                        customFieldId: field.id,
                                                                        name: field.shortName,
                                                                        standard: field.standard,
                                                                        typeCode: field.typeCode,
                                                                        multipleValues: field.multipleValues,
                                                                        values: [],
                                                                        financial: field.financial,
                                                                        processingType: field.processingType,
                                                                        customFieldIndexKey: '0',
                                                                    });
                                                                }
                                                            });
                                                            setGroups(newGroups);
                                                        }}
                                                    >
                                                        <Icon
                                                            className={'rr-product-page-features-edit-group-title-add-icon'}
                                                            component={IconPlus}
                                                        />
                                                    </CustomFieldAddPopover>
                                                    {group.fields.length === 0 && !group.main ? (
                                                        <SystemIcon
                                                            type={SystemIconType.delete}
                                                            className={'rr-product-page-features-edit-group-title-delete-icon'}
                                                            onClick={() => {
                                                                deleteGroup(index);
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>

                                                {group && group.fields && group.fields.length > 0 ? (
                                                    group.fields.map((item, deepIndex) => (
                                                        <Draggable
                                                            isDragDisabled={groupIsEditing}
                                                            key={'ii-' + item.customFieldId}
                                                            draggableId={'ii-' + item.customFieldId}
                                                            index={deepIndex}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <div className={'rr-product-page-features-edit-group-block-item'}>
                                                                        <CustomFieldsFieldPopover
                                                                            arrowPointAtCenter={false}
                                                                            record={item as any}
                                                                        >
                                                                            <span>
                                                                                <span
                                                                                    className={
                                                                                        'rr-product-page-features-edit-group-block-item-fieldName'
                                                                                    }
                                                                                >
                                                                                    {item.name}
                                                                                </span>
                                                                                <span
                                                                                    className={
                                                                                        'rr-product-page-features-edit-group-block-item-fielType'
                                                                                    }
                                                                                >
                                                                                    {getCustomFieldTypeByCode(
                                                                                        item.typeCode,
                                                                                        item.multipleValues
                                                                                    )}
                                                                                </span>
                                                                            </span>
                                                                        </CustomFieldsFieldPopover>
                                                                        {
                                                                            <SystemIcon
                                                                                className={classNames(
                                                                                    'rr-product-page-features-edit-item-delete-icon',
                                                                                    !canUpdateFinancialData &&
                                                                                        item.financial &&
                                                                                        'rr-product-page-features-edit-item-delete-icon-disabled'
                                                                                )}
                                                                                type={SystemIconType.delete}
                                                                                onClick={() => {
                                                                                    if (canUpdateFinancialData) {
                                                                                        const newGroups = [...groups];
                                                                                        newGroups[index].fields.splice(deepIndex, 1);
                                                                                        setGroups(newGroups);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                ) : (
                                                    <div style={{ color: '#aaaaaa', fontSize: 16, marginLeft: 132 }}>
                                                        {localize(
                                                            LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__LIST_PLACEHOLDER_NO_FIELDS_EXIST
                                                        )}
                                                    </div>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </>
                            );
                        })}
                    </DragDropContext>
                </div>

                {groupCreation ? (
                    <ProductPageFeaturesCreateGroupModal
                        groups={groups}
                        onOk={(data) => {
                            addNewGroup((data as any).name);
                            setGroupCreation(false);
                        }}
                        onCancel={() => {
                            setGroupCreation(false);
                        }}
                        businessAccountId={0}
                        intl={intl}
                        title={localize(LocalizationEnum.PAGE__PRODUCTS__GROUP_CREATION_MODAL)}
                        okButtonText={localize(LocalizationEnum.ASPECT__ACTION__CREATE)}
                    />
                ) : null}

                <div className="ant-modal-footer">
                    <div>
                        <Button className={'rr-btn-default'} onClick={toViewMode}>
                            {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                        </Button>
                        <Button
                            loading={updating}
                            type={'primary'}
                            onClick={() => {
                                updateFields();
                            }}
                        >
                            {localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                        </Button>
                    </div>
                </div>
            </Card>
        </>
    );
};

import { WorkPlanningsStateCodeEnum } from '../../../../../../../server';
import classNames from 'classnames';
import React from 'react';
import { CalendarColors } from '../../../../components/CalendarItemRenderer/utils/calendarColors';

export const workPlanningStateCodeToNameMap = {
    [WorkPlanningsStateCodeEnum.DRAFT]: 'Черновик',
    [WorkPlanningsStateCodeEnum.INPROGRESS]: 'В работе',
    [WorkPlanningsStateCodeEnum.FINISHED]: 'Завершены',
    [WorkPlanningsStateCodeEnum.PLANNED]: 'Запланированы',
    [WorkPlanningsStateCodeEnum.CANCELED]: 'Отменён',
} satisfies Record<WorkPlanningsStateCodeEnum, string>;

export const getStatusIcon = (stateCode: WorkPlanningsStateCodeEnum, iconSize: number) => {
    const color: string = CalendarColors.workPlanningsStateCodeMap[stateCode];
    const styles =
        stateCode === WorkPlanningsStateCodeEnum.DRAFT
            ? {
                  border: '1px dashed #575962',
                  color: '#575962',
                  background: '#fff',
              }
            : {
                  background: color,
              };

    return (
        <div
            className={classNames('rr-dot')}
            style={{
                ...styles,
                width: iconSize,
                height: iconSize,
            }}
        />
    );
};

import React from 'react';
import './archiveBadge.less';
import { LocalizationEnum, localize } from '../../localization';

export interface ArchiveBadgeProps {
    className?: string;
    style?: React.CSSProperties;
}

export const ArchiveBadge = (props: ArchiveBadgeProps) => (
    <span style={props.style} className={'rr-archive-badge' + (props.className ? ' ' + props.className : '')}>
        {localize(LocalizationEnum.ASPECT__GLOBAL__ARCHIVE)}
    </span>
);

import { createContext, useContext } from 'react';
import { EntityGridURLParams } from '../../types/params';
import { PageURLParamDescriptionObject } from '../../../../../core/utils/descriptions';
import { PageUrlParamsObject } from '../../../../../core/hooks/urlParams/types';

export const PageUrlParamsContext = createContext({});
export const usePageUrlParamsContext = <PageParams extends object>(): PageUrlParamsObject<EntityGridURLParams<PageParams>> => {
    return useContext(PageUrlParamsContext) as PageUrlParamsObject<EntityGridURLParams<PageParams>>;
};

export const PageUrlDescriptionContext = createContext({});
export const usePageUrlDescriptionContext = <PageParams extends object>(): PageURLParamDescriptionObject<PageParams> => {
    return useContext(PageUrlDescriptionContext) as PageURLParamDescriptionObject<PageParams>;
};

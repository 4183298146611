import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { OperationInfoRead, OperationInfoUpdate, serverApi, ServerError } from '../../../../server';
import { push } from 'connected-react-router';
import { getServerError } from '../../../../shared/util/utils';
import { showNotification } from '../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../localization';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'operation/LOAD_ENTITY',
    UPDATE_ENTITY: 'operation/UPDATE_ENTITY',
    CHANGE_ENTITY_STATUS: 'operation/CHANGE_ENTITY_STATUS',
    RESET: 'operation/RESET',
    RESET_ERROR: 'operation/RESET_ERROR',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entity: undefined as OperationInfoRead | undefined,
};

export type OperationState = Readonly<typeof initialState>;

// Reducer

export default (state: OperationState = initialState, action): OperationState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
            };

        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };

        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };

        case REQUEST(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false,
            };

        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                updating: false,
            };

        case SUCCESS(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false,
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined,
            };

        default:
            return state;
    }
};

// Actions

// Получение сущности
export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getOperationById(businessAccountId, id),
});

// Изменение сущности
export const updateEntity = (intl, businessAccountId: number, id: number, entity: OperationInfoUpdate) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateOperationById(businessAccountId, id, entity),
        }).then((result) => {
            if (result instanceof Error) {
                // Сущность не обновлена
            } else {
                // Сущность обновлена успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__OPERATIONS__POPUP_NOTIFICATIONS__COMMENT_UPDATED));
                dispatch(push('/' + businessAccountId + '/history/operations/' + id + '?tab=description'));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR,
});

import React from 'react';
import {Icon, Upload} from 'antd';
import {FormComponentProps} from 'antd/lib/form';
import {RcFile, UploadChangeParam} from 'antd/lib/upload';
import {UploadFile} from 'antd/lib/upload/interface';
import RoundButton from '../button/roundButton';
import {getUserAccessToken} from '../../../index';
import {getImagePath} from '../../shared/util/utils';
import {LocalizationEnum, localize} from '../../localization';
import './uploadImage.less';
import {IconUploadSolid} from "../icons";

interface IProps extends FormComponentProps {
    onChange: (info: UploadChangeParam) => void;
    fileList: UploadFile[];
    maxCount: number;
    action?: string | ((file: RcFile) => string) | ((file: RcFile) => PromiseLike<string>);
    multiple?: boolean;
}

class UploadImage extends React.Component<IProps> {

    static defaultProps = {
        maxCount: 100,
        multiple: false
    };

    state = {
        fileList: this.props.fileList
    };

    onChange = ({ fileList }) => {
        if (fileList && fileList.length > 0) {
            const newFileList = fileList.map(item => {
                if (item.error) {
                    if (item.thumbUrl !==  'error') {
                        item.name =  '      ' + item.name;
                    }
                    item.thumbUrl =  'error';
                }
                return item;
            });
            this.setState({
                fileList: newFileList
            })
        } else {
            this.setState({ fileList });
        }
        if (this.props.onChange) this.props.onChange(fileList);
    };

    render() {

        const { fileList } = this.state;
        const a:any = document.querySelectorAll('.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture .ant-upload-list-item-info a')

        if (a) {
            for (const item of a) {
                item.addEventListener('click', function (event) {
                    event.preventDefault();
                })
            }
        }

        const uploadButton = (
            <RoundButton colorScheme={'default'}>
                <Icon component={IconUploadSolid} /> {localize(this.props.multiple ? LocalizationEnum.ASPECT__FORMS__UPLOAD_FILES : LocalizationEnum.ASPECT__FORMS__UPLOAD_FILE, 'span')}
            </RoundButton>
        );

        return (
            <Upload
                headers={{ Authorization: 'Bearer ' + getUserAccessToken() }}
                previewFile={(file: File | Blob) =>
                    new Promise(resolve => {
                        let uid = file['uid'];
                        let fileData = this.state.fileList.find(item => item.uid === uid);
                        let interval = setInterval(() => {
                            if (fileData && fileData.response && fileData.response.externalId) {
                                clearInterval(interval);
                                resolve(
                                    getImagePath({
                                        externalId: fileData.response.externalId.replace(/-/g, ''),
                                        id: fileData.response.id
                                    })
                                );
                            }
                        }, 10);
                    })
                }
                onChange={this.onChange}
                listType="picture"
                action={this.props.action}
                multiple={this.props.multiple}
                fileList={fileList}
                accept=".png, .jpg, .jpeg">
                {fileList && fileList.length >= this.props.maxCount ? null : uploadButton}
            </Upload>
        );
    }
}

export default UploadImage;

import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../shared/util/validateField';
import { PricingSchemeEditor } from './pricingSchemeEditor';
import { PricingLadderStepObj, PricingSchemeTypeCodeEnum } from '../../../../server';
import { createHiddenField } from '../../../../components/modalForm/utils';
import { PricingSchemeMaps } from '../../../../core/utils/pricingSchemeUtils';

export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            {
                label: 'Наименование',
                id: 'name',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    let currentId = form.getFieldValue('id');
                    validateField('pricingScheme', fieldName, value, undefined, currentId, 'Наименование уже использовано', cb);
                }, 500),
            },
            {
                label: 'Тип',
                id: 'typeCode',
                type: FormItemType.RadioGroup,
                defaultValue: PricingSchemeTypeCodeEnum.LADDER,
                values: [
                    {
                        name: PricingSchemeMaps.pricingSchemeTypeCodeToTitleMap[PricingSchemeTypeCodeEnum.LADDER],
                        value: PricingSchemeTypeCodeEnum.LADDER,
                    },
                    {
                        name: PricingSchemeMaps.pricingSchemeTypeCodeToTitleMap[PricingSchemeTypeCodeEnum.MULTILEVEL],
                        value: PricingSchemeTypeCodeEnum.MULTILEVEL,
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                id: 'steps',
                type: FormItemType.Component,
                component: PricingSchemeEditor,
                customFormItemClassName: 'pricingSchemeStepsFormItem',
                validationRules: [
                    {
                        validator: (rule, value: PricingLadderStepObj[] | undefined, callback) => {
                            if (value) {
                                let numbers = value.map((step) => step.effectsAfterShiftCount);
                                const duplicates = numbers.filter((number, index, numbers) => {
                                    return numbers.indexOf(number) !== index;
                                });
                                if (duplicates.length > 0) {
                                    // callback('Дублируется количество смен!');
                                    callback(true);
                                    return;
                                }
                            }
                            callback();
                        },
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

import { UserBARoleCodeEnum, UserStateCodeEnum } from '../../../../server/api';
import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../localization';

export const filters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'status',
                type: FormItemType.Select,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                values: [
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__USER__ACTIVE), value: UserStateCodeEnum.ACTIVE },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__USER__BLOCKED), value: UserStateCodeEnum.BLOCKED },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__USER__INVITED), value: UserStateCodeEnum.INVITED },
                ],
            },
            {
                label: LocalizationEnum.PAGE__SETTINGS__USERS__FILTER__ROLE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'baRoles',
                type: FormItemType.Select,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                values: [
                    { name: localize(LocalizationEnum.USERS_BA__ROLES__AGENT), value: UserBARoleCodeEnum.AGENT },
                    { name: localize(LocalizationEnum.USERS_BA__ROLES__ADMIN), value: UserBARoleCodeEnum.ADMIN },
                    { name: 'Кладовщик', value: UserBARoleCodeEnum.STOREKEEPER },
                ],
            },
        ],
    },
];

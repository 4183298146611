import { ExpenseInfoRead } from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { ExpenseEditModal } from './expenseEditModal';
import React, { memo } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { EntityGridEditModalWrapper } from '../../../../../components/page/entityGrid/types/modal';

export const ExpenseEditModalWrapper: EntityGridEditModalWrapper<ExpenseInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <ExpenseEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование затраты'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            expense={entity}
        />
    );
});

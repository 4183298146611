import { ServerUtils } from '../../../../../core/utils/serverUtils';
import { getDashboardDataPeriod } from '../../../../../components/dashboardDate/dashboardDate';

export class DashboardApiUtils {
    static getCommonFilters = (period: string | undefined) => {
        const dataPeriod = getDashboardDataPeriod(period);
        const hasPeriodBoundary = dataPeriod.dataStart || dataPeriod.dataEnd;

        return ServerUtils.createRequestFilters([
            hasPeriodBoundary && period === '00' && ['periodDate', 'LE', dataPeriod.dataEnd],
            hasPeriodBoundary && period !== '00' && ['periodDate', 'GE', dataPeriod.dataEnd],
            hasPeriodBoundary && period !== '00' && ['periodDate', 'LE', dataPeriod.dataStart],
        ]);
    };
    static getDashboardFilterSortBy = (sortBy: string | undefined) => {
        const currentSortBy = {
            date: 'DATE',
            rentElementCount: 'RENT_ELEMENTS_COUNT',
            instanceCount: 'INSTANCES_COUNT',
            finalTotalSum: 'SUM',
        };

        return sortBy != null ? currentSortBy[sortBy] : undefined;
    };
}

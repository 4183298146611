import React, {FC, useContext} from 'react';
import classNames from 'classnames';
import { ActivityFramesContent } from '../components/LineContent/variants/activityFrames/ActivityFramesContent';
import { ActivityFramesBorders } from '../components/LineBorders/variants/activityFrames/ActivityFramesBorders';
import {ActivityFrameItemContext} from "../../../../../context/CalendarItemContext";

interface ActivityFramesCalendarItemProps {
    sortByValue?: string;
}

export const ActivityFramesCalendarItem: FC<ActivityFramesCalendarItemProps> = (props) => {
    const { sortByValue } = props;
    const { itemContext } = useContext(ActivityFrameItemContext);
    const lineWidth = itemContext.dimensions.width;

    return (
        <div className={classNames('rct-item-content', 'calendar-item-content')}>
            <ActivityFramesBorders lineWidth={lineWidth} />
            <ActivityFramesContent sortByValue={sortByValue} />
        </div>
    );
};

import React, { FC, ReactNode } from 'react';
import { Icon } from 'antd';
import { IconAngleDown, IconAngleUp } from '../../../../../../../../../../../components/icons';
import classNames from 'classnames';
import { CalendarDisplayType } from '../../../../../../../../components/Calendar/utils/data/calendarConstants';
import { WorkPlanningsRecord } from '../../../../../../../../../../../server';

export interface WorkPlanningsSplittedCalendarSidebarGroupProps {
    workPlannings: WorkPlanningsRecord[];
    displayType: CalendarDisplayType;
    isCollapsed: boolean;
    onClick: () => void;
    title: ReactNode;
    subtitle?: ReactNode;
    iconComponent?: JSX.Element | FC;
}

export const WorkPlanningsSplittedCalendarSidebarGroup: FC<WorkPlanningsSplittedCalendarSidebarGroupProps> = (props) => {
    const { workPlannings, title, onClick, isCollapsed, iconComponent, subtitle, displayType } = props;

    const isMoreThanOneWorkPlannings = workPlannings.length > 1;
    const subtitleVisible = isMoreThanOneWorkPlannings && isCollapsed;

    const isCompact = displayType === CalendarDisplayType.COMPACT;

    return (
        <div
            className={'sidebar-group'}
            onClick={() => {
                if (isMoreThanOneWorkPlannings) onClick();
            }}
            style={{
                cursor: isMoreThanOneWorkPlannings ? 'pointer' : undefined,
                userSelect: isMoreThanOneWorkPlannings ? 'none' : undefined,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                }}
            >
                {typeof iconComponent === 'function' ? (
                    <Icon
                        component={iconComponent}
                        style={{
                            fontSize: isCompact ? 14 : 20,
                        }}
                    />
                ) : (
                    iconComponent
                )}
                <div
                    className={classNames('titles-container', {
                        compact: isCompact,
                    })}
                >
                    <span className={'title'}>{title}</span>
                    {subtitleVisible && <span className={'subtitle'}>{subtitle}</span>}
                </div>
            </div>
            <Icon
                component={isCollapsed ? IconAngleUp : IconAngleDown}
                className={classNames('calendar-group-item-collapse-icon', {
                    normal: displayType === CalendarDisplayType.NORMAL,
                    compact: displayType === CalendarDisplayType.COMPACT,
                    unfold: isCollapsed,
                })}
                style={{
                    visibility: isMoreThanOneWorkPlannings ? 'visible' : 'hidden',
                }}
            />
        </div>
    );
};

import {useCallback} from 'react';
import _ from 'lodash';
import {showConfirm} from '../../../../../../../../components/confirm/showConfirm';
import {useIntl} from 'react-intl';
import {
    changeDiscount,
    changeFinalInstancePrice,
    changeFinalTotalPrice,
    changeInstanceCount
} from '../../utils/columnFormulas';
import {ProductCompositionColumnsObj} from '../../columns/productCompositionColumns.types';

export interface ProductCompositionOnEditRowAction {
    (
        dataIndex: 'main' | 'delete' | 'instanceCount' | 'discount' | 'finalInstancePrice' | 'finalTotalPrice',
        value: any,
        professionData: ProductCompositionColumnsObj
    ): void;
}

export const useOnRowEditAction = (
    editData: ProductCompositionColumnsObj[],
    setEditData: (data: typeof editData) => void
): ProductCompositionOnEditRowAction => {
    const intl = useIntl();

    return useCallback(
        async (dataIndex, value, productData) => {
            const updatedData = editData != null ? _.cloneDeep(editData) : [];
            const rowIndex = updatedData?.findIndex((currentData) => currentData.id === productData.id);

            const updateDataItemInPlace = (data: Partial<Record<typeof dataIndex, any>>) => {
                const item = updatedData[rowIndex];
                if (item.id === productData.id) {
                    updatedData[rowIndex] = { ...item, ...data, [dataIndex]: value };
                }
            };

            switch (dataIndex) {
                case 'main': {
                    if (productData.main) return;
                    if (!updatedData[0].main) {
                        updatedData[0].main = true;
                        break;
                    }
                    updatedData[0].main = false;
                    updatedData[rowIndex].main = true;
                    [updatedData[0], updatedData[rowIndex]] = [updatedData[rowIndex], updatedData[0]];
                    break;
                }
                case 'delete': {
                    const yes = await showConfirm(intl, 'Вы уверены что хотите удалить компонент из набора?');
                    if (yes) updatedData.splice(rowIndex, 1);
                    break;
                }
                case 'instanceCount': {
                    updateDataItemInPlace(changeInstanceCount(value, productData.discount, productData.pricePerShift));
                    break;
                }
                case 'discount': {
                    updateDataItemInPlace(changeDiscount(productData.instanceCount, value, productData.pricePerShift));
                    break;
                }
                case 'finalInstancePrice': {
                    updateDataItemInPlace(changeFinalInstancePrice(productData.instanceCount, value, productData.pricePerShift));
                    break;
                }
                case 'finalTotalPrice': {
                    updateDataItemInPlace(changeFinalTotalPrice(productData.instanceCount, value, productData.pricePerShift));
                    break;
                }
            }

            setEditData(updatedData);
        },
        [editData, intl, setEditData]
    );
};

import React, {FC} from 'react';
import {Icon} from 'antd';
import {IconBinoculars} from '../../../components/icons';
import {LocalizationEnum, useLocalize} from "../../../core/hooks/useLocalize";
import './notfoundModule.less';

interface NotfoundModuleProps {

}

const className = `rr-notfound-module`;

export const NotfoundModule:FC<NotfoundModuleProps> = (props) => {
    const L = useLocalize();
    return (
        <div className={className}>
            <div className={`${className}-img`}>
                <Icon component={IconBinoculars} />
            </div>
            <div className={`${className}-title`}>{L(LocalizationEnum.PAGE__ERROR__NOT_FOUND__SHORT_MESSAGE)}</div>
        </div>
    );
};

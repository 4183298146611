const UpOutline = {
    name: 'up',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: { viewBox: '0 0 32 32', focusable: false },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M 16 6.59375 L 15.28125 7.28125 L 2.78125 19.78125 L 4.21875 21.21875 L 16 9.4375 L 27.78125 21.21875 L 29.21875 19.78125 L 16.71875 7.28125 L 16 6.59375 z'
                }
            }
        ]
    }
};
export default UpOutline;
import React, { FC } from 'react';
import { ProjectInfoRead } from '../../../../../server';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { HeaderNavigationBlock } from '../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { IconMoneyCheckSolid } from '../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Link } from 'react-router-dom';
import { ProjectsPageTabsEnum, RentersPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { ArchiveBadge } from '../../../../../components/archiveBadge/archiveBadge';
import { rentersPageUrlRoute } from '../../../renters/data/tabs';
import classNames from 'classnames';
import { projectsPageUrlRoute } from '../../../projects/projectsPage';
import {OfferStateBadge} from "../OfferStateBadge/OfferStateBadge";
import {OfferStateCode} from "../../data/offersData";

interface OfferPageHeaderNavigationBlockProps {
    offer: ProjectInfoRead | undefined;
}

export const OfferPageHeaderNavigationBlock: FC<OfferPageHeaderNavigationBlockProps> = (props) => {
    const { offer } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (offer == null) return null;

    const { stateCode, renterShortName, renterId, mainProjectId, fullName, shortName, archive, mainProjectShortName } = offer;
    const name = fullName || shortName;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon
                            className={'icon'}
                            component={IconMoneyCheckSolid}
                            style={{
                                color: '#34bfa3',
                            }}
                        />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                        <OfferStateBadge type={stateCode as OfferStateCode} />
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES)}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${renterId}`}
                            className={classNames('link', 'bold')}
                        >
                            {renterShortName}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${renterId}?tab=projects`}
                            className={'link'}
                        >
                            {localize(LocalizationEnum.ASPECT__MAIN_MENU__PROJECTS)}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${mainProjectId}`}
                            className={classNames('link', 'bold')}
                        >
                            {mainProjectShortName}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${mainProjectId}?tab=offers`}
                            className={'link'}
                        >
                            Сметы
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

import moment from "moment/moment";
import {CalendarItemGroup} from "../../types/items";
import {ICalendarItem} from "../../hooks/useCalendarItems";

type ProcessGroupFunction = (props: {
    groupId: number;
    groupIndex: { current: number };
    isCollapsed: boolean;
    items: CalendarItemGroup[];
    record: ICalendarItem;
    screenLeft: number;
    screenRight: number;
    group: string;
}) => void;

export const processItemGroup: ProcessGroupFunction = ({ groupId, groupIndex, isCollapsed, items, record, screenLeft, screenRight, group }) => {
    items.push({
        id: groupId,
        record: record as ICalendarItem,
        group: groupIndex.current,
        start_time: moment(screenLeft),
        end_time: moment(screenRight),
        isCollapsed,
        elementType: 'group',
        sortGroup: group,
    } satisfies CalendarItemGroup);

    groupIndex.current += 1;
};
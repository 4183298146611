import {
    IconCompressArrowsAltSolid1,
    IconCompressArrowsAltSolid2
} from "../../../../../components/icons";
import {Icon} from "antd";
import React from "react";
import "./OperationParamResetButton.less";

interface OperationParamResetButtonProps {
    changed: boolean;
    onChange?: () => void;
    style?: React.CSSProperties;
}

export const OperationParamResetButton = (props: OperationParamResetButtonProps) => {

    const onClick = () => {
        if(props.onChange) props.onChange();
    }

    let className = `rr-operationForm-param-reset-button`;
    let iconComponent = IconCompressArrowsAltSolid2;
    if(props.changed){
        className += ` rr-operationForm-param-reset-button__changed`;
        iconComponent = IconCompressArrowsAltSolid1;
    }

    return (
        <Icon component={iconComponent}
              style={props.style}
              className={className}
              onClick={props.changed ? onClick : undefined}
        />
    );
};

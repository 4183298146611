import { FileTypeCodeEnum, serverApi } from '../../../../../server';

export interface WaybillGenerateArgs {
    businessAccountId: number;
    id: number;
    common: boolean;
    documentTemplateId: number;
    legalDetailId?: number;
}

export const generateBillOfLading = async (args: WaybillGenerateArgs, fileType: FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE) => {
    if (fileType === FileTypeCodeEnum.WORD) {
        const res = await serverApi.generateBillOfLadingDocx(
            args.businessAccountId,
            args.id,
            args.common,
            args.documentTemplateId,
            args.legalDetailId,
            {
                responseType: 'blob',
            }
        );

        return res.data;
    }

    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        const res = await serverApi.generateBillOfLadingPdf(
            args.businessAccountId,
            args.id,
            args.common,
            args.documentTemplateId,
            args.legalDetailId,
            {
                responseType: 'blob',
            }
        );

        return res.data;
    }
};

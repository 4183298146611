import { FC } from 'react';
import { Icon, Radio } from 'antd';
import { IconClose } from '../../../../../../components/icons';
import './YesNoCheckbox.less';
import { LocalizationEnum, localize } from '../../../../../../localization';

interface CheckboxProps {
    value?: string[];
    onChange: (value: string[] | undefined) => void;
}

const BUTTONS = [
    {
        label: localize(LocalizationEnum.ASPECT__GLOBAL__YES),
        value: '1',
    },
    {
        label: localize(LocalizationEnum.ASPECT__GLOBAL__NO),
        value: '0',
    },
    {
        label: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__N),
        value: '-1',
    },
];

export const YesNoCheckbox: FC<CheckboxProps> = (props) => {
    const { value, onChange } = props;

    return (
        <div className={'radio-buttons-group-container'}>
            <div className={'radio-buttons-group'}>
                {BUTTONS.map((b) => (
                    <Radio.Button
                        value={b.value}
                        checked={value?.includes(b.value)}
                        key={b.value}
                        onClick={() => {
                            let newValue: string[] = [];

                            if (!value?.includes(b.value)) {
                                newValue = [...(value ?? []), b.value];
                            } else {
                                newValue = value.filter((v) => v !== b.value);
                            }
                            onChange(newValue);
                        }}
                    >
                        {b.label}
                    </Radio.Button>
                ))}
            </div>
            {!!value?.length && (
                <Icon
                    className={'icon-close'}
                    component={IconClose}
                    onClick={() => {
                        onChange(undefined);
                    }}
                />
            )}
        </div>
    );
};

import React, { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { Icon, Popover, Radio } from 'antd';
import { IconSliders } from '../../../../../icons';
import './ModalFormFilters.less';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { ModalFormFiltersState } from '../../../../ModalFormNEW';
import { ModalFormDisplayGroup } from '../../types/displayData';
import classNames from 'classnames';

export interface IModalFormFilters<T extends string = 'ALL' | 'MAIN'> {
    data: ModalFormDisplayGroup[];
    defaultValue: T;
}

export interface ModalFormFiltersProps extends IModalFormFilters, Omit<ComponentProps<'div'>, 'defaultValue'> {
    setValue: (value: ModalFormFiltersState) => void;
    value: string;
    disabled: boolean;
}

export const ModalFormFilters: React.FC<ModalFormFiltersProps> = (props) => {
    const { value, setValue, data, disabled, ...divProps } = props;
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

    const closePopover = () => {
        setPopoverVisible(false);
    };

    const onGroupChange = (value: string) => {
        const currentData = data.find((data) => data.value === value)!;

        setValue({
            data: currentData,
            value,
        });
    };

    const radioButtons: ReactNode[] = data.map(({ value, title }, index) => (
        <Radio key={index} className={'radio'} value={value}>
            {title}
        </Radio>
    ));

    const { title, subGroup } = data.find(({ value: dataValue }) => dataValue === value)!;

    useEffect(() => {
        window.addEventListener('scroll', closePopover, true);
        window.addEventListener('resize', closePopover, true);

        return () => {
            window.removeEventListener('scroll', closePopover, true);
            window.removeEventListener('resize', closePopover, true);
        };
    }, []);

    return (
        <div {...divProps} className={classNames('modal-form-filters-container', divProps.className)}>
            <Popover
                visible={popoverVisible}
                onVisibleChange={(visible) => {
                    !disabled && setPopoverVisible(visible);
                }}
                trigger={'click'}
                placement="bottomLeft"
                destroyTooltipOnHide
                content={
                    <div className={'modal-form-filters-popover'}>
                        <div className="rr-calendar-popup-header">{localize(LocalizationEnum.PAGE__CALENDAR__POPUP_LABEL_RENDER_TYPE)}</div>

                        <Radio.Group className={'radio-group'} onChange={(event) => onGroupChange(event.target.value)} value={value}>
                            {radioButtons}
                        </Radio.Group>
                    </div>
                }
            >
                <Icon className={'icon-sliders'} component={IconSliders} />
            </Popover>
            <div className={'title-container'}>
                <span className={'title'}>{title}</span>
                {subGroup && (
                    <span
                        className={classNames('subtitle')}
                        onClick={() => {
                            if (!disabled) onGroupChange(subGroup.value);
                        }}
                    >
                        {subGroup.title}
                    </span>
                )}
            </div>
        </div>
    );
};

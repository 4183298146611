import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../../components/modalForm/utils';
import { updateModalFilters } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { crewMemberModalFields } from '../../data/crewMemberModalFields';
import { showConfirm } from '../../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../../localization';
import { CrewMemberFormData } from '../../types/CrewMemberFormData';
import { createCrewMember } from '../../../reducers/crewMember/crewMember.reducer';
import { CrewMemberInfoCreate } from '../../../../../../../../server';

interface CrewMemberCreateModalProps extends ModalFormNEWProps, WrappedComponentProps {}

export class CrewMemberCreateModalComponent extends ModalFormNEW<CrewMemberCreateModalProps & StoreProps> {
    static defaultProps = {
        formFields: crewMemberModalFields,
    };

    onOk = async (data: unknown) => {
        const formData = data as CrewMemberFormData;

        const crewMemberCreateData: CrewMemberInfoCreate = {
            contactId: formData.contact,
            stateCode: formData.stateCode,
            defaultRates: formData.defaultRates || { ratePerShift: 0, ratePerHour: 0 },
            professions: formData.professions?.map((professionId) => ({
                professionId,
            })),
            comment: formData.comment,
            description: formData.description,
        };

        try {
            const request = await this.props.createCrewMember({
                businessAccountId: this.props.businessAccountId,
                crewMemberInfoCreate: crewMemberCreateData,
            });

            if (this.props.onSuccess) this.props.onSuccess(request);
        } catch (error) {}
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        const yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.onCancel?.();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.crewMember.updating,
    updatingError: storeState.crewMember.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    createCrewMember,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const CrewMemberCreateModal = connector(injectIntl(CrewMemberCreateModalComponent));

import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {administrationAssignRolesToUser, initialParamsState, loadEntities, reset} from './reducers/users.reducer';
import {push, replace} from 'connected-react-router';
import {Spin} from '../../../../components';
import {usersListColumns} from './usersModuleListColums';
import debounce from 'lodash/debounce';
import {getPathFromState, getStateFromPath2} from '../../../../shared/util/utils';
import {filters} from './usersModuleListFilters';
import {inviteUserToBusinessAccount} from './reducers/user.reducer';
import {LocalizationEnum, localizeIntl} from '../../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {Grid} from '../../../../components/grid/Grid';
import {getGridStorageData, setGridStorageDataFilters} from '../../../../components/grid/utils';
import {PageUtils} from '../../../../shared/util/pageUtils';
import './usersModuleList.less';
import {BAUserRecord, UserStateCodeEnum} from '../../../../server';
import {showConfirm2} from '../../../../components/confirm/showConfirm';

interface UsersModuleListProps extends StateProps, DispatchProps, WrappedComponentProps {}

interface UsersModuleListState {}

const GRID_NAME = 'settings-users';

class UsersModuleList extends React.Component<UsersModuleListProps, UsersModuleListState> {
    private grid;
    private filtersForm;
    private fromFilters; // = true;
    private initialValues;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['search', 'status', 'baRoles', 'sortBy', 'sortOrder'];

    constructor(props: UsersModuleListProps) {
        super(props);
        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = () => {
        console.log('Users Module list componentDidMount()');
        this.initialValues = this.props.pageParams;
        //this.loadUsersModule();

        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    };

    componentDidUpdate = (prevProps: UsersModuleListProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(GRID_NAME);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadUsersModule();
        if (!this.fromFilters) {
            this.filtersForm.setFieldsValue({ ...initialParamsState, ...this.props.pageParams });
        }
        this.fromFilters = false;
    };

    componentWillUnmount = () => {
        console.log('Users Module list componentWillUnmount()');
        this.props.reset();
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    loadUsersModule = () => {
        this.props.loadEntities(this.props.intl, this.props.businessAccountId);
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                limit: size,
                page: 1,
            })
        );
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        this.fromFilters = true;
        data.page = 1;
        setGridStorageDataFilters(GRID_NAME, data);
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
    };

    onItemAction = async (item: BAUserRecord, action: string) => {
        if (action === 'invite') {
            this.props.inviteUserToBusinessAccount(this.props.intl, this.props.businessAccountId, item.email, item.baRoles[0]);
        } else if (action === 'edit') {
            if (item.stateCode === UserStateCodeEnum.INVITED) {
                this.props.inviteUserToBusinessAccount(this.props.intl, this.props.businessAccountId, item.email, item.baRoles[0]);
            } else {
                this.props.setProfileRules(this.props.intl, this.props.businessAccountId, item.id, {
                    businessVersion: item.businessVersion,
                    baRole: item.baRoles[0],
                });
            }
        } else if (action === 'remove') {
            if (item.stateCode === UserStateCodeEnum.INVITED) {
                // Отмена приглашения
                let yes = await showConfirm2({
                    intl: this.props.intl,
                    title: localizeIntl(this.props.intl, LocalizationEnum.PAGE__MODAL__QUESTION__CANCEL_INVITATION),
                });
                if (yes) {
                    this.props.inviteUserToBusinessAccount(this.props.intl, this.props.businessAccountId, item.email, undefined);
                }
            } else {
                // Удаление
                let yes = await showConfirm2({ intl: this.props.intl, title: 'Лишить пользователя роли?' });
                if (yes) {
                    this.props.setProfileRules(this.props.intl, this.props.businessAccountId, item.id, {
                        businessVersion: item.businessVersion,
                        baRole: undefined,
                    });
                }
            }
        }
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            status: undefined,
            baRoles: undefined,
        };
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(GRID_NAME, data);
    };

    render() {
        console.log('UsersModule render()', this.props);

        let { entities, usersBALoading, filteredCount } = this.props;

        const newEntities: any = entities?.map((el) => {
            return { ...el, businessAccountId: this.props.businessAccountId };
        });

        return (
            <Spin spinning={usersBALoading}>
                <Grid
                    filtersData={filters}
                    filtersInitialValues={this.initialValues}
                    filtersCurrentValues={this.props.pageParams}
                    filtersDefaultValues={initialParamsState}
                    filtersGetFiltersFormRef={this.getFiltersForm}
                    filtersOnChange={this.onFiltersChange}
                    filtersResetFiltersCb={this.resetFilters}
                    ref={(ref) => {
                        if (!this.grid) this.grid = ref;
                    }}
                    onRowAction={this.onItemAction}
                    onSortedChange={this.onSortedChange}
                    onPageChanged={this.onPageChanged}
                    onPageSizeChanged={this.onPageSizeChanged}
                    filtered={filteredCount}
                    pageSize={this.props.pageParams.limit}
                    currentPage={this.props.pageParams.page}
                    columns={usersListColumns}
                    data={newEntities}
                    entityType={'user'}
                    indexFieldName={'id'}
                    selectable={false}
                    defaultSorted={this.props.pageParams.sortBy}
                    defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                    gridName={GRID_NAME}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.usersBA.entities,
        usersBALoading: storeState.usersBA.loading,
        locationSearchParams: storeState.router.location.search,
        location: storeState.router.location,
        pageParams: storeState.usersBA.params,
        filteredCount: storeState.usersBA.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
    };
};

const mapDispatchToProps = {
    loadEntities,
    reset,
    push,
    replace,
    setProfileRules: administrationAssignRolesToUser,
    inviteUserToBusinessAccount,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UsersModuleList));

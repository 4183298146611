import React, { ComponentType, ReactNode } from 'react';
import FormValue from '../formValue/FormValue';
import { formatMoney } from '../../shared/util/formatMoney';
import { Col, Icon, Row } from 'antd';
import { IconBoxesSolid, IconCalendar, IconClockO, IconHourglassEndSolid, IconStickyNoteO } from '../icons';
import { LocalizationEnum, localize } from '../../localization';
import './currentRentIndicatorsBlock.less';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { InstanceInfoRead, OperationInfoRead, VariantInfoRead } from '../../server';
import { ProblemEnum } from '../../types';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { linkToCalendar } from '../../shared/util/createLinkPath';
import { CurrentRentIndicatorsEntity, CurrentRentIndicatorsEntityType } from './currentRentIndicatorsBlock';

interface IProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: CurrentRentIndicatorsEntity;
    showFinancialData: boolean;
}

export const CurrentRentIndicatorsProblems = (props: IProps) => {
    const businessAccountId = useSelector((store: IRootState) => store.system.businessAccountId);

    const getUrlToCalendarStatus = (type) => {
        if (props.entityType === 'project') {
            return linkToCalendar({
                typeCode: type,
                projectId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        if (props.entityType === 'renter') {
            return linkToCalendar({
                typeCode: type,
                renterId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        if (props.entityType === 'product') {
            return linkToCalendar({
                typeCode: type,
                productId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        if (props.entityType === 'operation') {
            return linkToCalendar({
                typeCode: type,
                projectId: (props.entity as OperationInfoRead).projectId,
                operationId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        if (props.entityType === 'product-instance') {
            return linkToCalendar({
                typeCode: type,
                productId: (props.entity as InstanceInfoRead).productId,
                instanceId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        if (props.entityType === 'kit') {
            return linkToCalendar({
                typeCode: type,
                kitId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        if (props.entityType === 'variant') {
            return linkToCalendar({
                typeCode: type,
                productId: (props.entity as VariantInfoRead).productId,
                variantId: props.entity.id,
                group: 'project',
                capture: getCapture(),
            });
        }
        return `/${businessAccountId}/calendar`;
    };

    const getUrlToCalendarProblem = (type: ProblemEnum) => {
        let capture: 'allWithNow' | 'all';

        if (
            type === ProblemEnum.RETURN_DELAY ||
            type === ProblemEnum.GIVEAWAY_DELAY ||
            type === ProblemEnum.SUBRENT_SHIPMENT_DELAY ||
            type === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY
        ) {
            capture = getCapture('allWithNow');
        } else {
            capture = getCapture('all');
        }

        if (props.entityType === 'project') {
            return linkToCalendar({
                problem: type,
                projectId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        if (props.entityType === 'renter') {
            return linkToCalendar({
                problem: type,
                renterId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        if (props.entityType === 'product') {
            return linkToCalendar({
                problem: type,
                productId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        if (props.entityType === 'operation') {
            return linkToCalendar({
                problem: type,
                projectId: (props.entity as OperationInfoRead).projectId,
                operationId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        if (props.entityType === 'product-instance') {
            return linkToCalendar({
                problem: type,
                productId: (props.entity as InstanceInfoRead).productId,
                instanceId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        if (props.entityType === 'kit') {
            return linkToCalendar({
                problem: type,
                kitId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        if (props.entityType === 'variant') {
            return linkToCalendar({
                problem: type,
                productId: (props.entity as VariantInfoRead).productId,
                variantId: props.entity.id,
                group: 'project',
                capture: capture,
            });
        }
        return `/${businessAccountId}/calendar`;
    };

    const renderProblemValue = (type: ProblemEnum, count: number, sum: number, iconImage: ComponentType) => {
        const getProblem = (count: number, sum: number, iconImage: ComponentType) => {
            let icon: ReactNode, dataIndicatorSum: ReactNode;
            dataIndicatorSum = <FormValue className="rr-collapsed-problem-indicator-sum" value={formatMoney(sum)} originalValue={sum} />;

            let classColorIcon =
                type === ProblemEnum.RETURN_DELAY || type === ProblemEnum.BOOK_SHORTAGE
                    ? 'problemsAndWarnings-icon-color-red'
                    : 'problemsAndWarnings-icon-color-yellow';
            icon = <Icon component={iconImage} className={`problemsAndWarnings-icon ${classColorIcon}`} />;

            return {
                count,
                dataIndicatorSum,
                icon,
            };
        };

        return (
            <div>
                <div className="rr-value">
                    {getProblem(count, sum, iconImage).count !== 0 ? (
                        <HelpTooltip content={LocalizationEnum['ASPECT__PROBLEM__TOOLTIP__' + type]}>
                            <div className="problemsAndWarnings-status-line">
                                <Link to={getUrlToCalendarProblem(type)} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                    {getProblem(count, sum, iconImage).icon}
                                    {getProblem(count, sum, iconImage).count}
                                    &nbsp;
                                    {props.showFinancialData && (
                                        <span className="rr-collapsed-problem-span-color rr-collapsed-problem-span">
                                            на {getProblem(count, sum, iconImage).dataIndicatorSum}
                                        </span>
                                    )}
                                    <Icon
                                        component={IconCalendar}
                                        className={`problemsAndWarnings-icon-calendar`}
                                        style={{ marginTop: '-1px' }}
                                    />
                                </Link>
                            </div>
                        </HelpTooltip>
                    ) : (
                        <span className="rr-value rr-collapsed-problem-not">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                    )}
                </div>
            </div>
        );
    };

    const renderDataValue = (title: string, color: string, sum: number | undefined, count: number | undefined, type) => {
        return (
            <Col span={12}>
                <div className="rr-label">{title}</div>
                <div className="rr-value">
                    {count !== 0 ? (
                        <span className="problemsAndWarnings-status-line">
                            <Link to={getUrlToCalendarStatus(type)} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                <div
                                    className={`rr-dot rr-dot-calendar-border rr-dot-indicators-border`}
                                    style={{ backgroundColor: color, borderColor: color }}
                                ></div>
                                <span>{count}</span>
                                &nbsp;
                                {props.showFinancialData && (
                                    <span className="rr-collapsed-problem-span-color rr-collapsed-problem-span">
                                        на{' '}
                                        <FormValue
                                            className="rr-collapsed-problem-indicator-sum"
                                            value={formatMoney(sum)}
                                            originalValue={sum}
                                        />
                                    </span>
                                )}
                                <Icon component={IconCalendar} className={`problemsAndWarnings-icon-calendar`} />
                            </Link>
                        </span>
                    ) : (
                        <>
                            <span className="rr-collapsed-problem-gray">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                        </>
                    )}
                </div>
            </Col>
        );
    };

    const problemsAndWarnings = props.entity.rentIndicators?.problemsAndWarnings;
    const rentIndicators = props.entity.rentIndicators;

    return (
        <>
            <Row>
                <Col span={24} className={'rr-form-item'} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {/* Просрочки */}
                    <Col span={12}>
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__PROBLEM__DELAYS)}</div>
                        <div style={{ alignItems: 'center' }}>
                            {problemsAndWarnings?.giveawayDelay.elementsCount === 0 &&
                            problemsAndWarnings.returnDelay.elementsCount === 0 ? (
                                <span className="rr-value rr-collapsed-problem-not">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                            ) : (
                                <>
                                    {problemsAndWarnings?.giveawayDelay.elementsCount !== 0 &&
                                    problemsAndWarnings?.returnDelay.elementsCount === 0
                                        ? null
                                        : renderProblemValue(
                                              ProblemEnum.RETURN_DELAY,
                                              problemsAndWarnings!.returnDelay.elementsCount,
                                              problemsAndWarnings!.returnDelay.sum,
                                              IconHourglassEndSolid
                                          )}
                                    {problemsAndWarnings?.giveawayDelay.elementsCount !== 0 ||
                                    problemsAndWarnings.returnDelay.elementsCount === 0 ? (
                                        <div style={{ marginTop: '4px' }}>
                                            {renderProblemValue(
                                                ProblemEnum.GIVEAWAY_DELAY,
                                                problemsAndWarnings!.giveawayDelay.elementsCount,
                                                problemsAndWarnings!.giveawayDelay.sum,
                                                IconClockO
                                            )}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </Col>
                    {/* Нехватки */}
                    <Col span={12}>
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__PROBLEM__SHORTAGES)}</div>
                        <div style={{ alignItems: 'center' }}>
                            {problemsAndWarnings?.bookShortage.elementsCount === 0 &&
                            problemsAndWarnings?.orderShortage.elementsCount === 0 ? (
                                <span className="rr-value rr-collapsed-problem-not">{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
                            ) : (
                                <>
                                    {problemsAndWarnings?.bookShortage.elementsCount === 0 &&
                                    problemsAndWarnings.orderShortage.elementsCount !== 0
                                        ? null
                                        : renderProblemValue(
                                              ProblemEnum.BOOK_SHORTAGE,
                                              problemsAndWarnings!.bookShortage.elementsCount,
                                              problemsAndWarnings!.bookShortage.sum,
                                              IconBoxesSolid
                                          )}

                                    {problemsAndWarnings?.bookShortage.elementsCount === 0 ||
                                    problemsAndWarnings?.orderShortage.elementsCount !== 0 ? (
                                        <div style={{ marginTop: '4px' }}>
                                            {renderProblemValue(
                                                ProblemEnum.ORDER_SHORTAGE,
                                                problemsAndWarnings!.orderShortage.elementsCount,
                                                problemsAndWarnings!.orderShortage.sum,
                                                IconStickyNoteO
                                            )}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </Col>
                </Col>
            </Row>
            <Row>
                {/* Сумма просрочек и Сумма нехваток */}
                <Col span={24} className={'rr-form-item'} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {renderDataValue(
                        'В заявках',
                        '#04b0f1',
                        rentIndicators?.currentOrdered.sum,
                        rentIndicators?.currentOrdered.elementsCount,
                        'ORDERED'
                    )}
                    {renderDataValue(
                        'Забронировано',
                        '#8b5cb1',
                        rentIndicators?.currentBooked.sum,
                        rentIndicators?.currentBooked.elementsCount,
                        'BOOKED'
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={24} className={'rr-form-item'} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {renderDataValue(
                        'В аренде',
                        '#FC8C2F',
                        rentIndicators?.currentRent.sum,
                        rentIndicators?.currentRent.elementsCount,
                        'RENT'
                    )}
                    {renderDataValue(
                        'Завершено аренды',
                        '#34bfa3',
                        rentIndicators?.totalReturned.sum,
                        rentIndicators?.totalReturned.elementsCount,
                        'RETURNED'
                    )}
                </Col>
            </Row>
        </>
    );
};

export const getCapture = (capture: 'allWithNow' | 'all' = 'all') => {
    return capture;
};

import React, { FC, memo } from 'react';
import { Card, Col, Row } from 'antd';
import { useAppSelector } from '../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { DashboardRentDelayWidget } from './components/widgets/delay/DashboardRentDelayWidget';
import { DashboardRentShortageWidget } from './components/widgets/shortage/DashboardRentShortageWidget';
import { DashboardRentDebtWidget } from './components/widgets/debt/DashboardRentDebtWidget';
import { DashboardRentOrderedWidget } from './components/widgets/ordered/DashboardRentOrderedWidget';
import { DashboardRentBookedWidget } from './components/widgets/booked/DashboardRentBookedWidget';
import { DashboardRentRentedWidget } from './components/widgets/rented/DashboardRentRentedWidget';
import { DashboardData } from '../../types/DashboardData';
import { DashboardRentLeftList } from '../../list/rent/left/DashboardRentLeftList';
import { DashboardRentRightList } from '../../list/rent/right/DashboardRentRightList';

interface DashboardRentProps {
    dashboardData: DashboardData;
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const DashboardRent: FC<DashboardRentProps> = memo((props) => {
    const { dashboardData, responsive } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const XXL = responsive === 'xxl';

    const DelayWidget = <DashboardRentDelayWidget dashboardData={dashboardData} style={{height: '100%', width: '100%'}} XXL={XXL} />;
    const ShortageWidget = <DashboardRentShortageWidget dashboardData={dashboardData} style={{height: '100%', width: '100%'}} XXL={XXL} />;
    const DebtWidget = <DashboardRentDebtWidget dashboardData={dashboardData} style={{height: '100%', width: '100%'}} XXL={XXL} />;
    const OrderedWidget = <DashboardRentOrderedWidget dashboardData={dashboardData} style={{height: '100%', width: '100%'}} XXL={XXL} />;
    const BookedWidget = <DashboardRentBookedWidget dashboardData={dashboardData} style={{height: '100%', width: '100%'}} XXL={XXL} />;
    const RentedWidget = <DashboardRentRentedWidget dashboardData={dashboardData} style={{height: '100%', width: '100%'}} XXL={XXL} />;

    const LeftTable = <DashboardRentLeftList dashboardData={dashboardData} />;
    const RightTable = <DashboardRentRightList dashboardData={dashboardData} />;

    return responsive === 'xs' || responsive === 'sm' || responsive === 'md' || responsive === 'lg' ? (
        <div>
            <Row type="flex" justify="space-between" gutter={20}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {DelayWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {ShortageWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }} style={{ height: !isCanViewFinancialData ? 138 : undefined }}>
                    {isCanViewFinancialData && DebtWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {OrderedWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {BookedWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {RentedWidget}
                </Col>
            </Row>
            <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftTable}</Card>
            <Card bodyStyle={{ padding: '0px', paddingTop: '25px', marginTop: 30, paddingBottom: '36px' }}>{RightTable}</Card>
        </div>
    ) : (
        <>
            {
                responsive !== 'xl' && (
                    <div className={'rr-dashboard-rent-indicators'} style={{display: 'flex', marginBottom: 30}}>
                        <div style={{width: '50%', paddingRight: 16, display: 'flex', gap: 20}}>
                            <div style={{width: '33.33%'}}>
                                {DelayWidget}
                            </div>
                            <div style={{width: '33.33%'}}>
                                {ShortageWidget}
                            </div>
                            <div style={{width: '33.33%'}}>
                                {isCanViewFinancialData && DebtWidget}
                            </div>
                        </div>
                        <div style={{width: '50%', paddingLeft: 16, display: 'flex', gap: 20}}>
                            <div style={{width: '33.33%'}}>
                                {OrderedWidget}
                            </div>
                            <div style={{width: '33.33%'}}>
                                {BookedWidget}
                            </div>
                            <div style={{width: '33.33%'}}>
                                {RentedWidget}
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="dashboard-main-grid">
                <div className="dashboard-grid-left">
                    {responsive === 'xl' &&
                    <Row type="flex" justify="space-between" gutter={20}>
                            <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                                {DelayWidget}
                            </Col>
                            <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                                {ShortageWidget}
                            </Col>
                            <Col
                                xxl={{ span: 8 }}
                                xl={{ span: 12 }}
                                lg={{ span: 24 }}
                                style={{ flexGrow: 1, height: !isCanViewFinancialData ? 138 : undefined }}
                            >
                                {isCanViewFinancialData && DebtWidget}
                            </Col>
                        </Row>
                    }
                    <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftTable}</Card>
                </div>

                <div className="dashboard-grid-right">
                    {responsive === 'xl' &&
                    <Row type="flex" justify="space-between" gutter={20}>
                            <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                                {OrderedWidget}
                            </Col>
                            <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                                {BookedWidget}
                            </Col>
                            <Col xxl={{ span: 8, order: 0 }} xl={{ span: 12, order: 1 }} lg={{ span: 24, order: 0 }} style={{ flexGrow: 1 }}>
                                {RentedWidget}
                            </Col>
                        </Row>
                    }
                    <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{RightTable}</Card>
                </div>
            </div>
        </>
    );
});

import { TransportationRecord, TransportationTransitionCodeEnum } from '../../../../../../server';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import RoundButton from '../../../../../../components/button/roundButton';
import { Icon } from 'antd';
import { IconCalendarPlus, IconEraser, IconFlagCheckered, IconShippingFastSolid, IconTrash } from '../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../localization';

import { useSetStatusForTransportationsCallback } from './useSetStatus';
import { useDeleteTransportationsMutation, useSetStatusForTransportationsMutation } from '../api/transportations.api';
import { GridRefContextValue } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';

export const useEntityActionsButtons = ({
    selectedRecords,
    gridRef,
}: {
    selectedRecords: TransportationRecord[];
    gridRef: GridRefContextValue;
}) => {
    const [deleteTransportations, { isLoading: isDeleteTransportationsLoading }] = useDeleteTransportationsMutation();

    const [setStatusForTransportations, { isLoading: isSetStatusForTransportationsLoading }] = useSetStatusForTransportationsMutation();

    const isLoading = isDeleteTransportationsLoading || isSetStatusForTransportationsLoading;

    const deleteSelectedTransportations = useCallback(() => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => {
            return {
                id,
                businessVersion,
            };
        });

        deleteTransportations({
            versionedEntityObjList: {
                entities: versionedSelectedEntitiesObjects,
            },
        });

        gridRef?.current?.clearSelection();
    }, [deleteTransportations, gridRef, selectedRecords]);

    const setStatusForTransportationsCallback = useSetStatusForTransportationsCallback(
        selectedRecords,
        gridRef,
        setStatusForTransportations
    );

    return useMemo(
        () => ({
            buttons: [
                _.every(selectedRecords, (item: TransportationRecord) =>
                    item.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.PLAN)
                ) ? (
                    <RoundButton
                        title={'Запланировать'}
                        colorScheme={'PLANNED'}
                        onClick={() => {
                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.PLAN);
                        }}
                    >
                        <Icon component={IconCalendarPlus} />
                        Запланировать
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (item: TransportationRecord) =>
                    item.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.START)
                ) ? (
                    <RoundButton
                        title={LocalizationEnum.PAGE__PROJECTS__ACTIONS__START}
                        colorScheme={'inRent'}
                        onClick={() => {
                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.START);
                        }}
                    >
                        <Icon component={IconShippingFastSolid} />
                        {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__START, 'span')}
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (item: TransportationRecord) =>
                    item.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.FINISH)
                ) ? (
                    <RoundButton
                        title={LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH}
                        colorScheme={'success'}
                        onClick={() => {
                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.FINISH);
                        }}
                    >
                        <Icon component={IconFlagCheckered} />
                        {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH, 'span')}
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (item: TransportationRecord) =>
                    item.availableTransitionCodes?.includes(TransportationTransitionCodeEnum.CANCEL)
                ) ? (
                    <RoundButton
                        title={LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL}
                        colorScheme={'CANCEL'}
                        onClick={() => {
                            setStatusForTransportationsCallback(TransportationTransitionCodeEnum.CANCEL);
                        }}
                    >
                        <Icon component={IconEraser} />
                        {localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL, 'span')}
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (item: TransportationRecord) => item.deleteable) ? (
                    <RoundButton
                        title={LocalizationEnum.ASPECT__ACTION__DELETE}
                        onClick={deleteSelectedTransportations}
                        colorScheme={'DELETE'}
                    >
                        <Icon component={IconTrash} />
                        {localize(LocalizationEnum.ASPECT__ACTION__DELETE, 'span')}
                    </RoundButton>
                ) : null,
            ],
            isLoading,
        }),
        [deleteSelectedTransportations, isLoading, selectedRecords, setStatusForTransportationsCallback]
    );
};

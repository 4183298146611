import React, { FC } from 'react';
import { Radio } from 'antd';
import { LocalizationEnum, localize } from '../../../../../../localization';

interface SwitchButtonsProps {
    title: string;
    value: boolean;
    setValue: (value: boolean) => void;
}

export const RegistrationStepTwoSwitchButtons: FC<SwitchButtonsProps> = (props) => {
    const { title, value, setValue } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 24,
                gap: 21,
            }}
        >
            <span
                style={{
                    textAlign: 'center',
                    fontWeight: 400,
                    fontSize: 16,
                }}
            >
                {title}
            </span>
            <Radio.Group
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                value={value}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Radio value={true}>{localize(LocalizationEnum.ASPECT__GLOBAL__YES)}</Radio>
                <Radio value={false}>{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</Radio>
            </Radio.Group>
        </div>
    );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { CrewMemberSimpleObj } from '../../server';
import { SimpleTable, SimpleTableColumn } from '../v2/simpleTable/SimpleTable';
import { CollapsibleBlock } from '../v2/collapsibleBlock/CollapsibleBlock';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import './crewMembersBlock.css';
import { Avatar } from '../avatar/avatar';
import { Icon } from 'antd';
import { IconNoImage } from '../icons';
import { LocalizationEnum } from '../../localization';
import { useLocalize } from '../../core/hooks/useLocalize';
import { getBusinessAccountId } from '../../../index';
import { AttachmentsBlockSettings, updateAttachmentsBlockSettings } from '../../shared/reducers/userSettings/userSettings.reducer';

interface AttachmentsBlockProps {
    records: CrewMemberSimpleObj[];
}

export const CrewMembersBlock = ({ records, ...props }: AttachmentsBlockProps) => {
    const settings: AttachmentsBlockSettings = useAppSelector(
        (state) => state.userSettings.attachmentsBlockSettings['workPlannings-crewMembers'] || { collapsed: false }
    );
    const dispatch = useAppDispatch();
    const setSettings = (_settings: AttachmentsBlockSettings) =>
        dispatch(updateAttachmentsBlockSettings({ ['workPlannings-crewMembers' as any]: { ...settings, ..._settings } }));

    const L = useLocalize();

    let columns: SimpleTableColumn<CrewMemberSimpleObj>[] = [
        {
            id: 'id',
            width: 26,
            minWidth: 26,
            maxWidth: 26,
            style: { lineHeight: 1 },
            render: (value, row) => {
                return (
                    <Avatar size={26} style={{ backgroundColor: '#ffffff', verticalAlign: 'middle' }}>
                        <span style={{ fontSize: 18 }}>
                            <Icon component={IconNoImage} style={{ color: '#DDDDDD' }} />
                        </span>
                    </Avatar>
                );
            },
        },
        {
            id: 'personFIO',
            width: 200,
            style: { alignItems: 'flex-start', marginLeft: 16 },
            className: 'rr-attachments-block_attachment-name-link',
            render: (value, row) => {
                return <Link to={`/${getBusinessAccountId()}/crew/crewMembers/${row.id}?tab=description`}>{value}</Link>;
            },
        },
    ];

    let collapsed = settings.collapsed;
    if (records.length === 0) collapsed = true;

    return (
        <div>
            <CollapsibleBlock
                collapsed={collapsed}
                onCollapse={(collapsed) => {
                    setSettings({ collapsed });
                }}
                className={'rr-attachments-block'}
                title={
                    records.length === 0 ? (
                        'Работники не назначены'
                    ) : (
                        <>
                            {records.length} {L(LocalizationEnum.ASPECT__PLURAL__CREW_MEMBER, { value: records.length })}
                        </>
                    )
                }
                icon={<SystemIcon type={SystemIconType.crew} />}
                disabled={records.length === 0}
            >
                <SimpleTable columns={columns} data={records} />
            </CollapsibleBlock>
        </div>
    );
};

import React, { FC } from 'react';
import { CalendarHeaderButtonsProps } from '../../../../components/Calendar/types/props';
import { SortByButton } from '../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { CalendarURLParams } from '../../../../components/Calendar/types/params';
import { CalendarDisplayTypeButton } from '../../../../components/Calendar/components/ActionsButtons/DisplayTypeButton/CalendarDisplayTypeButton';
import { WorkPlanningsCalendarFilters } from '../../filters/workPlanningsCalendarFilters.types';
import { WorkPlanningsSortValue } from '../../utils/data/sortData';
import { CrewMemberBreakdown } from '../../../../components/Calendar/components/ActionsButtons/CrewMemberBreakdown/CrewMemberBreakdown';
import {ExportOffLoadingPopoverWrapper} from "./exportOffLoadingPopoverWrapper";

type Params = WorkPlanningsCalendarFilters;
type SortValue = WorkPlanningsSortValue;

export const WorkPlanningsCalendarHeaderButtons: FC<CalendarHeaderButtonsProps<Params, SortValue>> = (props) => {
    const { isLoading, sortData, sortByValue, setSortByValue, pageParamsObject, displayType, setDisplayType } = props;
    const { updatePageParams } = pageParamsObject;

    return (
        <>
            <CrewMemberBreakdown pageParamsObject={pageParamsObject} />

            <ExportOffLoadingPopoverWrapper pageParamsObject={pageParamsObject} />

            <SortByButton
                disabled={isLoading}
                sortData={sortData}
                sortValue={sortByValue}
                onSortChange={(value) => {
                    setSortByValue(value);
                    setTimeout(() => {
                        updatePageParams({
                            group: value,
                        } as CalendarURLParams<Params, SortValue>);
                    }, 100);
                }}
            />
            <CalendarDisplayTypeButton
                displayType={displayType}
                onChange={(value) => {
                    setDisplayType(value);
                    updatePageParams(
                        {
                            displayType: value,
                        } as CalendarURLParams<Params, SortValue>,
                        {
                            replaceWithoutRerender: true,
                        }
                    );
                }}
            />
        </>
    );
};

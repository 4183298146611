import { IModalFormFilters } from '../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../components/modalForm/components/Header/types/displayData';
import { ProjectInfoRead } from '../../../../../server';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof ProjectInfoRead | 'dates' | 'copyParams'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['copyParams', 'shortName', 'renterId', 'locationId', 'dates', 'typeOfPricing', 'stateCode']],
    },
];

export const createProjectModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editProjectModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

import React from 'react';
import type {CSSObject, Theme} from '@ant-design/cssinjs';
import {createTheme, useCacheToken} from '@ant-design/cssinjs';

export type GetStyle = (prefixCls: string, token: DerivativeToken) => CSSObject;

export interface DesignToken {
    //
    defaultFontFace: string; // Montserrat
    defaultTextColor: string; // #575962
    defaultFontSize: number; // 14px

    // Меню боковое основное
    mainMenuItemTextColor: string; // #FFFFFF
    mainMenuItemBgColor: string; // #393F77
    mainMenuItemBgHoverColor: string; // #5b63a3
    mainMenuItemIconSize: number; // 22px

    //
    problemColor: string;
    warningColor: string;
    hoverOpacity: number;
}

export interface DerivativeToken extends DesignToken {
    primaryColorDisabled: string;
}

const defaultDesignToken: DesignToken = {
    //
    defaultFontFace: 'Montserrat',
    defaultTextColor: '#575962',
    defaultFontSize: 14,

    // Меню боковое основное
    mainMenuItemTextColor: '#FFFFFF',
    mainMenuItemBgColor: '#393F77',
    mainMenuItemBgHoverColor: '#5b63a3',
    mainMenuItemIconSize: 22,

    //
    problemColor: '#000000',
    warningColor: '#000000',
    hoverOpacity: .8
};

// 模拟推导过程
function derivative(designToken: DesignToken): DesignToken {
    return {
        ...designToken,
        // primaryColorDisabled: new TinyColor(designToken.primaryColor)
        //     .setAlpha(0.5)
        //     .toString(),
    };
}

export const ThemeContext = React.createContext(createTheme(derivative));

export const DesignTokenContext = React.createContext<{
    token?: Partial<DesignToken>;
    hashed?: string | boolean;
}>({
    token: defaultDesignToken,
});

export function useToken(): [Theme<any, any>, DerivativeToken, string] {
    const {token: rootDesignToken = {}, hashed} = React.useContext(DesignTokenContext);
    const theme = React.useContext(ThemeContext);

    const [token, hashId] = useCacheToken<DerivativeToken, DesignToken>(
        theme,
        [defaultDesignToken, rootDesignToken],
        {
            salt: typeof hashed === 'string' ? hashed : '',
        },
    );
    return [theme, token, hashed ? hashId : ''];
}

import React from 'react';
import './category-delete-modal.less';
import {IRootState} from '../../../shared/reducers';
import {connect} from 'react-redux';
import {Checkbox, Modal} from 'antd';
import {deleteCategory, hideDeleteConfirmModal, ICategoriesState} from './reducers/categories.reducer';
import {LocalizationEnum, localize, localizeIntl} from '../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {CategorySortOrderEnum, CategoryTypeCodeEnum} from '../../../server';

interface IState {
    deleteSubCategories: boolean;
    deleteProducts: boolean;
}

interface IProps extends StateProps, DispatchProps, ICategoriesState, WrappedComponentProps {
    typeCode: CategoryTypeCodeEnum;
    sortValue: CategorySortOrderEnum;
}

class CategoryDeleteModal extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);
        let { categoryInfo } = this.props;
        this.state = {
            deleteSubCategories: ((categoryInfo && categoryInfo['subcategoriesCount']) || 0) > 0,
            deleteProducts: categoryInfo && !categoryInfo.parentCategoryId ? true : false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.categoryInfo) !== JSON.stringify(this.props.categoryInfo)) {
            let { categoryInfo } = this.props;
            const relatedObjectsCount =
                ((categoryInfo && categoryInfo['directRelatedObjectsCount']) || 0) +
                ((categoryInfo && categoryInfo['transitiveRelatedObjectsCount']) || 0);
            this.setState({
                deleteSubCategories: ((categoryInfo && categoryInfo['subcategoriesCount']) || 0) > 0,
                deleteProducts: relatedObjectsCount > 0 || !!(categoryInfo && !categoryInfo.parentCategoryId),
            });
        }
    }

    onDeleteLinksChange = (e: CheckboxChangeEvent) => {
        if (this.props.categoryInfo && !this.props.categoryInfo.parentCategoryId) {
        } else {
            this.setState({ deleteProducts: e.target.checked });
        }
    };

    onDeleteSubcategories = (e: CheckboxChangeEvent) => {
        this.setState({ deleteSubCategories: e.target.checked });
    };

    onOk = () => {
        if (this.props.categoryInfo && this.props.categoryInfo.businessAccountId && this.props.categoryInfo.id) {
            this.props.deleteCategory(
                this.props.intl,
                this.props.categoryInfo.businessAccountId,
                this.props.typeCode,
                this.props.categoryInfo.id,
                this.state.deleteSubCategories,
                this.state.deleteProducts,
                this.props.sortValue
            );
        }
    };

    onCancel = () => {
        this.props.hideDeleteConfirmModal(this.props.typeCode);
    };

    render() {
        let { categoryInfo } = this.props;

        // Всего подкатегорий
        const subcategoriesCount = (categoryInfo && categoryInfo['subcategoriesCount']) || 0;
        const isCanDelSubcategories = subcategoriesCount > 0;
        const relatedObjectsCount =
            ((categoryInfo && categoryInfo['directRelatedObjectsCount']) || 0) +
            ((categoryInfo && categoryInfo['transitiveRelatedObjectsCount']) || 0);

        console.log('CategoryDeleteModal render()', this.props);

        let dis1 = false;
        if (this.props.categoryInfo && !this.props.categoryInfo.parentCategoryId) {
            dis1 = true;
        }

        return (
            <Modal
                className={'rr-category-delete-modal'}
                title={localize(LocalizationEnum.PAGE__CATEGORIES__MODAL__QUESTIONS__DELETE)}
                visible={this.props.deleteModalIsShowing}
                cancelButtonProps={{ type: 'link', style: { width: 130 } }}
                okButtonProps={{ style: { width: 130 }, loading: this.props.deleting }}
                okText={localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__YES)}
                width={620}
                onOk={this.onOk}
                onCancel={this.onCancel}
            >
                <Checkbox
                    disabled={!isCanDelSubcategories}
                    checked={this.state.deleteSubCategories}
                    onChange={this.onDeleteSubcategories}
                >
                    <>
                        {localize(LocalizationEnum.PAGE__CATEGORIES__MODAL__CHECKBOX__DELETE_SUBCATEGORIES)} (
                        <span style={{ fontWeight: 600 }}>{subcategoriesCount}</span> подкатегория)
                    </>
                </Checkbox>
                <Checkbox
                    disabled={relatedObjectsCount === 0}
                    className={
                        dis1 && !(relatedObjectsCount === 0) ? 'rr-category-delete-modal__disabled_checkbox' : undefined
                    }
                    checked={this.state.deleteProducts}
                    onChange={this.onDeleteLinksChange}
                >
                    <>
                        Убрать назначения удаляемых категорий (
                        <span style={{ fontWeight: 600 }}>{relatedObjectsCount} </span>{' '}
                        {this.props.typeCode === 'PRODUCT' ? 'продукта и набора' : 'контрагентов'})
                    </>
                </Checkbox>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({});

const mapDispatchToProps = {
    hideDeleteConfirmModal,
    deleteCategory,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoryDeleteModal));

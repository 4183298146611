import {RentElementsGridItem} from "../../../../types";
import {
    OperationInfoCreate,
    OperationTypeCodeEnum,
    ProjectInfoRead,
    RentElementInfoWrite,
    RentElementsKitObjWrite
} from "../../../../server";
import {ProductUtils} from "../../../../shared/util/productUtils";
import {isDefined} from "../../../../shared/util/utils";

export const getRentElementsToUpdate = (elements: RentElementsGridItem[]) => {
    let rentElementsToCreate: RentElementInfoWrite[] = elements
        .filter((element) => !element.kitId)
        .map((element) => {
            return ProductUtils.createRentElementInfoUpdate1(element);
        });
    return rentElementsToCreate;
};

export const getRentElementsKitsToUpdate = (elements: RentElementsGridItem[]) => {
    let rentElementsKitsToCreate: RentElementsKitObjWrite[] = elements
        .filter((element) => isDefined(element.kitId))
        .map((element) => {
                return {
                    id: element.id,
                    kitId: element.kitId!,
                    rentElements: element.subRows?.map(row=>ProductUtils.createRentElementInfoUpdate1(row)),
                    businessVersion: element.businessVersion,
                };
        });
    return rentElementsKitsToCreate;
}

export const getOperationInfoCreateForSimpleOrder = (project:ProjectInfoRead, elements:RentElementsGridItem[], type:OperationTypeCodeEnum) => {
    const rentElements = getRentElementsToUpdate(elements);
    const rentElementsKits = getRentElementsKitsToUpdate(elements);
    const operationInfoCreate: OperationInfoCreate = {
        rentElements: rentElements.length ? rentElements : undefined,
        rentElementsKits: rentElementsKits.length ? rentElementsKits : undefined,
        projectId: project.id,
        typeCode: type,
        commonRentTerms: {
            discount: 0,
            rentPeriodStartDate: project.startDate!,
            rentPeriodEndDate: project.targetFinishDate!,
        },
    };
    return operationInfoCreate;
};

import React, { ReactNode } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import ProductCreateModal from './products/modal/components/ProductCreateModal/ProductCreateModal';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import RoundButton from '../../../components/button/roundButton';
import { getPathFromState, getStateFromPath, isStringInEnum } from '../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { LocalizationEnum, localize, localizeIntl } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import KitCreateModal from './kits/kit-create-modal';
import { getGridStorageData } from '../../../components/grid/utils';
import { createKitModalFilters } from './kits/kit-create-modal-data';
import { createProjectFilters } from './products/modal/components/ProductCreateModal/ProductCreateModalData';
import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { InterfaceBlockCode } from '../../../components/pageHeader/components/InfoSelectDropdown/utils/constants';
import { ProductsList } from './products/list/ProductsList';
import { KitsList } from './kits/list/KitsList';
import { InfoSelectDropdown } from '../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown';

import { InventoryPageTabsEnum } from '../../../shared/constants/tabEnums';

interface IState {}

interface IProps extends StateProps, DispatchProps, IBasePageProps, WrappedComponentProps {}

export const inventoryPageUrlRoute = 'inventory';
const defaultTab = InventoryPageTabsEnum.PRODUCTS;

class Component extends BasePage<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${inventoryPageUrlRoute}`);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('inventory', this.currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
    }

    getPageHeaderProps(): IPageHeaderProps {
        return {
            rightBlock: <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_INVENTORY} />,
        };
    }

    onTabsChange = (key: string) => {
        key = isStringInEnum(key, InventoryPageTabsEnum, InventoryPageTabsEnum.PRODUCTS);

        if (key === InventoryPageTabsEnum.PRODUCTS) {
            let gridData = getGridStorageData(InventoryPageTabsEnum.PRODUCTS);
            this.props.push(
                getPathFromState(this.getCurrentUrl(InventoryPageTabsEnum.PRODUCTS), '', {
                    ...gridData.filters,
                    ...gridData.params,
                })
            );
        } else if (key === InventoryPageTabsEnum.KITS) {
            let gridData = getGridStorageData('inventory-kits');
            this.props.push(
                getPathFromState(this.getCurrentUrl(InventoryPageTabsEnum.KITS), '', {
                    ...gridData.filters,
                    ...gridData.params,
                })
            );
        }

        saveCurrentTabToStore('inventory', key);
    };

    createProducts = () => {
        this.props.push(
            getPathFromState(
                `/${this.props.businessAccountId}/${inventoryPageUrlRoute}/${InventoryPageTabsEnum.PRODUCTS}/new`,
                this.props.location.search
            )
        );
    };

    createKits = () => {
        this.props.push(
            getPathFromState(
                `/${this.props.businessAccountId}/${inventoryPageUrlRoute}/${InventoryPageTabsEnum.KITS}/new`,
                this.props.location.search
            )
        );
    };

    getCurrentUrl(tab: InventoryPageTabsEnum) {
        return `/${this.props.businessAccountId}/${inventoryPageUrlRoute}/${tab}`;
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(inventoryPageUrlRoute, InventoryPageTabsEnum);
    }

    getCurrentTabButton(currentTab: InventoryPageTabsEnum) {
        return (
            {
                [InventoryPageTabsEnum.PRODUCTS]: (
                    <RoundButton
                        icon={'plus'}
                        title={localizeIntl(this.props.intl, LocalizationEnum.PAGE__PRODUCTS__ACTIONS__CREATE) as string}
                        colorScheme={'success'}
                        onClick={this.createProducts}
                    >
                        {localize(LocalizationEnum.PAGE__PRODUCTS__ACTIONS__CREATE, 'span')}
                    </RoundButton>
                ),
                [InventoryPageTabsEnum.KITS]: (
                    <RoundButton
                        icon={'plus'}
                        title={LocalizationEnum.PAGE__PRODUCTS__ACTIONS__CREATE}
                        colorScheme={'success'}
                        onClick={this.createKits}
                    >
                        {localize(LocalizationEnum.PAGE__ACTIONS__CREATE_KIT, 'span')}
                    </RoundButton>
                ),
            } satisfies Record<InventoryPageTabsEnum, ReactNode>
        )[currentTab];
    }

    renderContent() {
        const { currentTab } = this;

        if (currentTab == null) {
            return <Redirect to={this.getCurrentUrl(isStringInEnum(this.props.tabFromStore, InventoryPageTabsEnum, defaultTab))} />;
        }

        const currentTabButton = this.getCurrentTabButton(currentTab);

        return (
            <>
                <Tabs
                    className={'rr-tabs'}
                    type="line"
                    animated={false}
                    activeKey={currentTab}
                    onChange={this.onTabsChange}
                    destroyInactiveTabPane
                    tabBarExtraContent={currentTabButton}
                >
                    <Tabs.TabPane tab={localize(LocalizationEnum.ASPECT__MAIN_MENU__PRODUCTS)} key={InventoryPageTabsEnum.PRODUCTS}>
                        <ProductsList />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={localize(LocalizationEnum.ASPECT__MAIN_MENU__KITS)} key={InventoryPageTabsEnum.KITS}>
                        <KitsList />
                    </Tabs.TabPane>
                </Tabs>
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/${inventoryPageUrlRoute}/${InventoryPageTabsEnum.PRODUCTS}/new`}
                    render={(props) => (
                        <ProductCreateModal
                            title={localize(LocalizationEnum.PAGE__PRODUCTS__FORM__FORM_TITLE__CREATE)}
                            copyMode={true}
                            businessAccountId={this.props.businessAccountId}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            filters={createProjectFilters}
                            modalName={'create-product'}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/${inventoryPageUrlRoute}/${InventoryPageTabsEnum.KITS}/new`}
                    render={(props) => (
                        <KitCreateModal
                            title={localize(LocalizationEnum.PAGE__KITS__FORM__FORM_TITLE__CREATE)}
                            businessAccountId={this.props.businessAccountId}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            filters={createKitModalFilters}
                            modalName={'create-kit'}
                        />
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let pageParams = getStateFromPath(ownProps.history.location.search);
    let categoryIds: string | undefined = pageParams['categoryIds'] || undefined;

    return {
        locationSearchParams: storeState.router.location.search,
        locationPathname: storeState.router.location.pathname,
        pageParams: storeState.products.params,
        businessAccountId: storeState.system.businessAccountId,
        categoryIds: categoryIds,
        tabFromStore: getCurrentTabsSelector(storeState)?.['inventory'],
    };
};

const mapDispatchToProps = {
    push,
    replace,
    resetOperationForm,
    setBlockedUrl,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const InventoryPage = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));

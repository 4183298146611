import { ExpensesListAggregationsPopover } from '../list/expensesListAggregationsPopover';
import { useLazyFetchAggregationsQuery } from '../api/expenses.api';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { ListExpensesFilters, ListExpensesQueryParams } from '../api/expenses.api.types';
import { EntityGridURLParams } from '../../../../components/page/entityGrid/types/params';
import React, { useCallback, useMemo } from 'react';
import { ExportOffloadingPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { ExcelExportTemplateTypeCodeEnum, ExpenseFinancialFieldsTypeCode } from '../../../../server';
import { ExpensesUtils } from '../api/expenses.api.utils';
import { downloadOffloading } from '../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { useIntl } from 'react-intl';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { useEntityGridContext } from '../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { OffloadingDocumentOnDownload } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';

export const useExportBlock = (
    queryParams: EntityGridURLParams<ListExpensesFilters>,
    staticPageParams: Partial<ListExpensesQueryParams> | undefined,
    projectId?: number
) => {
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();
    const [fetchAggregation, { isFetching: aggregationsIsFetching, isError: aggregationsIsError, data: aggregationsData }] =
        useLazyFetchAggregationsQuery();

    const fetchAggregationData = useCallback(() => {
        fetchAggregation({
            businessAccountId,
            params: {
                ...queryParams,
                ...staticPageParams,
            },
        });
    }, [businessAccountId, fetchAggregation, queryParams, staticPageParams]);

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            let { sortOrder, sortBy, search } = queryParams;

            const filters = ExpensesUtils.createRequestFilters(queryParams, projectId);

            sortBy = sortBy || undefined!;
            sortOrder = sortBy != null ? sortOrder : undefined!;

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.EXPENSETEMPLATE,
                fileName: 'Экспорт затрат',
                fileType,
                filters,
                financialFieldsEnum: ExpenseFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: ExpenseFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: projectId ? ['projectShortName'] : undefined,
                columnsMap: {
                    projectId: 'projectShortName',
                },
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, projectId, queryParams]
    );

    return useMemo(() => {
        return (
            <>
                <ExpensesListAggregationsPopover
                    data={aggregationsData}
                    fetchData={fetchAggregationData}
                    isFetching={aggregationsIsFetching}
                    isError={aggregationsIsError}
                />
                <ExportOffloadingPopover storageKey={'expensesOffloading'} onDownload={onOffloadingDownload} />
            </>
        );
    }, [aggregationsData, aggregationsIsError, aggregationsIsFetching, fetchAggregationData, onOffloadingDownload]);
};

import { useContext } from 'react';
import { ElementItemContext } from '../../../components/CalendarItemRenderer/context/CalendarItemContext';
import { isGiveawayDelayProblemForItemElement } from '../utils/checks/isGiveawayDelayProblemForRentElement';
import moment from 'moment';
import { TimeRangeContext } from '../../../components/CalendarItemRenderer/context/TimeRangeContext';

export const useItemWidth = (): [number, number | undefined] => {
    const { visibleTimeStart } = useContext(TimeRangeContext);
    const { item, itemContext } = useContext(ElementItemContext);
    const { start_time, end_time } = item;

    if (isGiveawayDelayProblemForItemElement(item)) {
        const timeDiff = +end_time - +start_time;
        const actualEndDate = Math.max(+moment(item.rentTerms.rentPeriodEndDate), visibleTimeStart);
        const mainItemDiff = actualEndDate - +start_time;
        const tailDiff = +end_time - actualEndDate;

        return [itemContext.dimensions.width * (mainItemDiff / timeDiff), itemContext.dimensions.width * (tailDiff / timeDiff)];
    }

    return [itemContext.dimensions.width, undefined];
};

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {SystemIcon, SystemIconType} from "../../../components/v2/systemIcon/systemIcon";
import {GRID_ACTIONS_COLUMN_WIDTH} from "../../../config/constants";
import {ActionButtonsPopover} from "../../../components/v2/actionButtons/actionButtonsPopover/actionButtonsPopover";
import React from "react";

export const tblColumnsActions = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.ACTIONS) {
        return (
            {
                title: <SystemIcon type={SystemIconType.actions} style={{display: 'block'}}/>,
                type: ColumnTypes.CustomRender,
                width: GRID_ACTIONS_COLUMN_WIDTH,
                maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
                minWidth: GRID_ACTIONS_COLUMN_WIDTH,
                resizable: false,
                sortable: false,
                className: 'actions',
                headerClassName: 'actions',
                render: (value: any, rowData: Record, rowAction, entityType) => {
                    const actions = column.getData(rowData).actions;
                    return (
                        <ActionButtonsPopover
                            data={column.actionsDataMap}
                            actions={actions}
                            onAction={(action) => {
                                if (rowAction) rowAction(rowData, action);
                            }}
                        >
                            <SystemIcon
                                type={SystemIconType.otherActions}
                                style={{fontSize: 28}}
                                className={'rr-grid-table-actions-icon'}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </ActionButtonsPopover>
                    );
                }
            }
        );
    } else {
        throw Error('error');
    }
};

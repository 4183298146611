import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { PaymentMethodRecord, serverApi } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { DeepKeys } from '@tanstack/react-table';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';

export interface UserSelectProps extends BaseSelectBaseProps<PaymentMethodRecord> {}

const sortBy: DeepKeys<PaymentMethodRecord> = 'name';
const sortOrder = 'ASC';

const PaymentMethodSelectSelectFC = ({ ...props }: UserSelectProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listPaymentMethods(businessAccountId, undefined, 0, [`id;IN;${ids}`], sortBy, sortOrder);
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number) => {
        const response = await serverApi.listPaymentMethods(businessAccountId, limit, startFrom, ['archive;EQ;false'], sortBy, sortOrder);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.name,
                children: record.name,
            })}
            showSearch={false} // TODO Тут поиск отключен т.к. нет этого на сервере
        />
    );
};

export class PaymentMethodSelect extends React.Component<UserSelectProps> {
    render() {
        return <PaymentMethodSelectSelectFC {...this.props} />;
    }
}

import { Icon } from 'antd';
import { IconBinoculars, IconIconsSolid } from '../icons';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { EntityType } from '../../../index';
import { LocalizationEnum, localize } from '../../localization';
import { Avatar } from '../avatar/avatar';
import classNames from 'classnames';

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    avatarSize?: 'big' | 'medium' | 'none';
    edit?: Boolean;
    entityType?: EntityType;
    gridEdit?: () => void;
    hideArchive?: boolean;
    message?: string;
    style?: React.CSSProperties;
    title?: string;
}

export const ItemsNotFoundBlock = (props: IProps) => {
    const { avatarSize = 'big', edit, entityType, gridEdit, hideArchive, message, title, ...divProps } = props;

    return (
        <div {...divProps} className={classNames('rr-grid-notfound-block', divProps.className)}>
            {avatarSize !== 'none' && (
                <Avatar className={avatarSize === 'big' ? 'rr-avatar-big' : 'rr-avatar-medium'}>
                    {entityType === 'kit-composition' ? <Icon component={IconIconsSolid} /> : <Icon component={IconBinoculars} />}
                </Avatar>
            )}
            <div className={'rr-grid-notfound-block-title'}>
                {title != null
                    ? title
                    : entityType === 'kit-composition'
                    ? localize(LocalizationEnum.PAGE__KITS__NO_COMPONENTS_EXIST)
                    : localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)}
            </div>
            <div className={'rr-grid-notfound-block-message'}>
                {message
                    ? message
                    : entityType === 'kit-composition'
                    ? localize(LocalizationEnum.PAGE__KITS__NEED_TO_ADD_COMPONENTS)
                    : localize(LocalizationEnum.PAGE__CALENDAR__EMPTY_LIST_MESSAGE)}
            </div>
            {entityType === 'kit-composition' && !edit ? (
                <div
                    style={{ marginTop: '20px', cursor: 'pointer' }}
                    className={'rr-product-page__main-params-show-all'}
                    onClick={gridEdit}
                >
                    {localize(LocalizationEnum.PAGE__ACTIONS__CUSTOMIZE_COMPONENTS)}
                </div>
            ) : null}
            {hideArchive ? (
                <div className={'rr-grid-notfound-block-message2'}>
                    {entityType === 'element'
                        ? localize(LocalizationEnum.PAGE__CALENDAR__EMPTY_LIST_MESSAGE_SHOW_INACTIVE)
                        : localize(LocalizationEnum.ASPECT__GRID__TO_SEARCH_IN_ARCHIVE_SWITCH_THE_MODE)}
                </div>
            ) : null}
        </div>
    );
};

import { IModalFormFilters } from '../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../components/modalForm/components/Header/types/displayData';
import { allFieldsDisplayGroup, mainFieldsDisplayGroup } from '../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [
            ['shortName', 'fullName', 'imageData'],
            ['contact.fullName.lastname', 'contact.fullName.firstname', 'contact.phoneNumber', 'contact.email'],
        ],
    },
];

export const createBusinessAccountModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editBusinessAccountModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

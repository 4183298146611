import React, { useMemo } from 'react';
import { _Grid } from '../../../../components/grid/Grid';
import { useSetStatusForWorkMutation } from '../api/workPlannings.api';
import { WorkPlanningsTransitionCodeEnum } from '../../../../server';

export const useSetStatusForWorkPlanningsCallback = (
    selectedRecords: { id: number; businessVersion: number }[],
    gridRef?: React.RefObject<_Grid>
) => {
    const [setStatusForWork, { isLoading: isSetStatusForWorkLoading }] = useSetStatusForWorkMutation();

    return useMemo(
        () => ({
            callback: (transitionCode: WorkPlanningsTransitionCodeEnum) => {
                const workflowDirectiveBulkDirectives = selectedRecords.map(({ id, businessVersion }) => {
                    return {
                        id,
                        businessVersion,
                        transitionCode,
                    };
                });

                setStatusForWork({
                    workflowDirectiveBulk: {
                        directives: workflowDirectiveBulkDirectives,
                    },
                });

                if (gridRef != null) {
                    gridRef.current?.clearSelection();
                }
            },
            isLoading: isSetStatusForWorkLoading,
        }),
        [gridRef, isSetStatusForWorkLoading, selectedRecords, setStatusForWork]
    );
};

import React from 'react';
import {SliderMarks, SliderProps, SliderValue} from 'antd/lib/slider';
import {formatPercents} from '../../../shared/util/formatPercents';
import NumberFormat from 'react-number-format';
import { CurrentCurrency } from '../../currentCurrency/currentCurrency';
import { formatMeters, formatTons } from '../../../shared/util/formatMetrics';
import { SliderRangeWrapper } from './hoc/SliderRangeWrapper/SliderRangeWrapper';

interface IState {
    marks: SliderMarks;
}

export type SliderRangeFormatStyle = 'currency' | 'percents' | 'number' | 'tons' | 'meters' | 'metersCubic';

export interface SliderRangeProps extends SliderProps {
    formatStyle: SliderRangeFormatStyle;
}

export const formatSliderRangeValue = (value: number, formatStyle: SliderRangeFormatStyle): JSX.Element | string | number => {
    const valuesRecord: Record<SliderRangeFormatStyle, JSX.Element | string | number> = {
        currency: (
            <>
                <NumberFormat value={value} displayType={'text'} thousandSeparator={' '} decimalSeparator={'.'} /> <CurrentCurrency />
            </>
        ),
        percents: formatPercents(value),
        tons: formatTons(value),
        meters: formatMeters(value),
        metersCubic: formatMeters(value, {
            cubic: true,
        }),
        number: value,
    };

    return valuesRecord[formatStyle] != null ? valuesRecord[formatStyle] : value;
};

export class SliderRange extends React.PureComponent<SliderRangeProps, IState> {
    constructor(props: SliderRangeProps) {
        super(props);

        this.state = {
            marks: {},
        };
    }

    componentDidMount() {
        if (this.props.defaultValue !== undefined) {
            this.onSliderChange(this.props.defaultValue, false);
        } else if (this.props.value !== undefined) {
            this.onSliderChange(this.props.value, false);
        }
    }

    componentDidUpdate(prevProps: SliderRangeProps) {
        if ('' + prevProps.value !== '' + this.props.value) {
            if (!this.props.value) {
                if (this.props.max !== undefined && this.props.min !== undefined) {
                    this.onSliderChange([this.props.min, this.props.max], false);
                }
            } else {
                this.onSliderChange(this.props.value, false);
            }
        }
    }

    onSliderChange = (value?: SliderValue, withEvent: boolean = true) => {
        const { min, max, formatStyle } = this.props;
        let marks: SliderMarks = {};

        const newValue = value;

        if (newValue instanceof Array) {
            newValue.forEach((item) => {
                let anchorValue = item;

                if (max != null && anchorValue > max) {
                    anchorValue = max;
                }

                if (min != null && anchorValue < min) {
                    anchorValue = min;
                }

                marks[anchorValue] = {
                    label: formatSliderRangeValue(item, formatStyle),
                };
            });
            if (max !== undefined && max === min) {
                marks[max + 1] = {
                    label: formatSliderRangeValue(max, formatStyle),
                };
            }
        } else if (newValue !== undefined) {
            marks[newValue] = formatSliderRangeValue(newValue, formatStyle);
        }

        this.setState({
            marks: marks,
        });

        if (this.props.onChange !== undefined && withEvent && newValue !== undefined) {
            this.props.onChange(newValue);
        }
    };

    render() {
        let className = this.props.className ? this.props.className + ' ' : '';
        if (this.props.max !== undefined && this.props.max === this.props.min) {
            className += 'rr-slider-range-with-one-value';
        }

        return <SliderRangeWrapper {...this.props} className={className} onSliderChange={this.onSliderChange} marks={this.state.marks} />;
    }
}

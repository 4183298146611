import { FC, useContext } from 'react';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { DraftBorder } from '../../../Borders/common/DraftBorder';
import { RentDelayBorder } from '../../../Borders/elements/rent/RentDelayBorder';
import { RentShortageBorder } from '../../../Borders/elements/rent/RentShortageBorder';
import { RentDelayShortageBorder } from '../../../Borders/elements/rent/RentDelayShortageBorder';
import { SubrentReturnDelayBorder } from '../../../Borders/elements/subrent/SubrentReturnDelayBorder';
import { SubrentShipmentDelayBorder } from '../../../Borders/elements/subrent/SubrentShipmentDelayBorder';
import { SubrentBaseBorder } from '../../../Borders/elements/subrent/SubrentBaseBorder';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';

interface ElementsLineBordersProps {
    lineWidth: number;
}

export const ElementsLineBorders: FC<ElementsLineBordersProps> = (props) => {
    const { lineWidth } = props;
    const { item } = useContext(ElementItemContext);

    const { isDraft, elementType, problemsAndWarnings, stateCode, start_time, end_time } = item;
    const {
        giveawayDelay,
        returnDelay,
        anyDelay,
        anyShortage: isShortage,
        subrentShipmentDelay,
        subrentReturnToShipperDelay,
    } = problemsAndWarnings;

    if (isDraft) {
        const border = `1px dashed ${CalendarColors.elementsStateCodeMap[stateCode]}`;
        return (
            <DraftBorder
                width={lineWidth}
                border={border}
                isRounded={elementType === 'subrent'}
                start_time={start_time}
                end_time={end_time}
            />
        );
    }
    if (elementType === 'rent') {
        if ((giveawayDelay || returnDelay) && !isShortage) return <RentDelayBorder width={lineWidth} />;
        if (!anyDelay && isShortage) return <RentShortageBorder width={lineWidth} />;
        if (anyDelay && isShortage) return <RentDelayShortageBorder width={lineWidth} />;
    }
    if (elementType === 'subrent') {
        if (subrentShipmentDelay) return <SubrentShipmentDelayBorder width={lineWidth} />;
        if (subrentReturnToShipperDelay) return <SubrentReturnDelayBorder width={lineWidth} />;

        return <SubrentBaseBorder width={lineWidth} />;
    }

    return null;
};

import React, { FC, memo } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { Card, Col, Row } from 'antd';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { DashboardSubrentDelayWidget } from './components/widgets/delay/DashboardSubrentDelayWidget';
import { DashboardSubrentDebtWidget } from './components/widgets/debt/DashboardSubrentDebtWidget';
import { DashboardSubrentBookedWidget } from './components/widgets/booked/DashboardSubrentBookedWidget';
import { DashboardSubrentSubrentedWidget } from './components/widgets/subrented/DashboardSubrentSubrentedWidget';
import { DashboardData } from '../../types/DashboardData';
import { DashboardSubrentLeftList } from '../../list/subrent/left/DashboardSubrentLeftList';
import { DashboardSubrentRightList } from '../../list/subrent/right/DashboardSubrentRightList';

interface DashboardSubrentProps {
    dashboardData: DashboardData;
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const DashboardSubrent: FC<DashboardSubrentProps> = memo((props) => {
    const { responsive, dashboardData } = props;
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const DelayWidget = <DashboardSubrentDelayWidget dashboardData={dashboardData} />;
    const DebtWidget = <DashboardSubrentDebtWidget dashboardData={dashboardData} />;
    const BookedWidget = <DashboardSubrentBookedWidget dashboardData={dashboardData} />;
    const SubrentedWidget = <DashboardSubrentSubrentedWidget dashboardData={dashboardData} />;

    const LeftList = <DashboardSubrentLeftList dashboardData={dashboardData} />;
    const RightList = <DashboardSubrentRightList dashboardData={dashboardData} />;

    return subrentModuleEnabled ? (
        responsive === 'xs' || responsive === 'sm' || responsive === 'md' || responsive === 'lg' ? (
            <div>
                <Row type="flex" justify="space-between" gutter={20}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        {DelayWidget}
                    </Col>
                    {isCanViewFinancialData && (
                        <Col md={{ span: 12 }} sm={{ span: 24 }}>
                            {DebtWidget}
                        </Col>
                    )}
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        {BookedWidget}
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        {SubrentedWidget}
                    </Col>
                </Row>
                <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftList}</Card>
                <Card bodyStyle={{ padding: '0px', paddingTop: '25px', marginTop: 30, paddingBottom: '36px' }}>{RightList}</Card>
            </div>
        ) : (
            <div className="dashboard-main-grid">
                <div className="dashboard-grid-left">
                    <Row type="flex" justify="space-between" gutter={20}>
                        <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                            {DelayWidget}
                        </Col>
                        <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                            {isCanViewFinancialData && DebtWidget}
                        </Col>
                    </Row>
                    <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftList}</Card>
                </div>
                <div className="dashboard-grid-right">
                    <Row type="flex" justify="space-between" gutter={20}>
                        <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                            {BookedWidget}
                        </Col>
                        <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 24 }} style={{ flexGrow: 1 }}>
                            {SubrentedWidget}
                        </Col>
                    </Row>
                    <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{RightList}</Card>
                </div>
            </div>
        )
    ) : null;
});

import { SliderRangeFormatStyle } from '../../../../../slider/SliderRange/SliderRange';

export const parseFloatToFixedString = (value: string, precision?: number): string | undefined => {
    if (precision == null || !value.includes('.')) return value;
    const [int, float] = value.split('.');

    if (float.length < precision) return value;

    return `${int}.${float.slice(0, precision)}`;
};

export const formatStyleToPrecisionPointMap: Partial<Record<SliderRangeFormatStyle, number>> = {
    currency: 2,
    meters: 3,
    tons: 6,
    metersCubic: 9,
};

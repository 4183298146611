import { DocumentTypeCodeEnum } from '../../../../server/api';
import React from 'react';
import { CustomIconComponentProps } from 'antd/lib/icon';
import {
    IconBarcodeSolid,
    IconClipboardCheckSolid,
    IconClipboardListSolid,
    IconFileAlt,
    IconFileInvoiceDollarSolid,
    IconFileSignatureSolid,
    IconMoneyCheckSolid,
    IconStampSolid,
} from '../../../../components/icons';
import { localize } from '../../../../localization';

export class DocumentTemplatesModuleUtils {
    static getDocumentTemplateCodes = (): DocumentTypeCodeEnum[] => {
        return [
            DocumentTypeCodeEnum.ACT,
            DocumentTypeCodeEnum.CONTRACT,
            DocumentTypeCodeEnum.INVENTORYLIST,
            DocumentTypeCodeEnum.ESTIMATE,
            DocumentTypeCodeEnum.PRICELIST,
            DocumentTypeCodeEnum.BILLOFLADING,
            DocumentTypeCodeEnum.BADGE,
            DocumentTypeCodeEnum.BILL,
        ];
    };

    static getDocumentTemplateTypeByCode = (code?: DocumentTypeCodeEnum): React.ReactElement => {
        let type;
        if (code === DocumentTypeCodeEnum.PRICELIST) type = 'Прайс-лист';
        else if (code === DocumentTypeCodeEnum.CONTRACT) type = 'Договор';
        else if (code === DocumentTypeCodeEnum.INVENTORYLIST) type = 'Инвентарный лист';
        else if (code === DocumentTypeCodeEnum.ESTIMATE) type = 'Смета';
        else if (code === DocumentTypeCodeEnum.ACT) type = 'Акт';
        else if (code === DocumentTypeCodeEnum.BILLOFLADING) type = 'Транспортная накладная';
        else if (code === DocumentTypeCodeEnum.BADGE) type = 'Этикетка';
        else if (code === DocumentTypeCodeEnum.BILL) type = 'Cчёт';
        else {
            console.error(`code "${code}" not found in DocumentTypeCodeEnum`);
        }
        return localize(type);
    };

    static getDocumentTemplateIconColorByCode = (code?: DocumentTypeCodeEnum): string => {
        return '#21bfa4';
    };

    static getDocumentTemplateIconComponentByCode = (
        code?: DocumentTypeCodeEnum
    ): React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined => {
        let component: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined;
        if (code === DocumentTypeCodeEnum.PRICELIST) component = IconFileInvoiceDollarSolid;
        else if (code === DocumentTypeCodeEnum.CONTRACT) component = IconStampSolid;
        else if (code === DocumentTypeCodeEnum.INVENTORYLIST) component = IconClipboardCheckSolid;
        else if (code === DocumentTypeCodeEnum.ESTIMATE) component = IconFileAlt;
        else if (code === DocumentTypeCodeEnum.ACT) component = IconFileSignatureSolid;
        else if (code === DocumentTypeCodeEnum.BILLOFLADING) component = IconClipboardListSolid;
        else if (code === DocumentTypeCodeEnum.BADGE) component = IconBarcodeSolid;
        else if (code === DocumentTypeCodeEnum.BILL) component = IconMoneyCheckSolid;
        return component;
    };

    static getFileExtensionByCode = (code?: DocumentTypeCodeEnum): 'docx' | 'zip' | undefined => {
        if (code === DocumentTypeCodeEnum.PRICELIST) return 'zip';
        else if (code === DocumentTypeCodeEnum.CONTRACT) return 'docx';
        else if (code === DocumentTypeCodeEnum.INVENTORYLIST) return 'zip';
        else if (code === DocumentTypeCodeEnum.ESTIMATE) return 'zip';
        else if (code === DocumentTypeCodeEnum.ACT) return 'docx';
        else if (code === DocumentTypeCodeEnum.BILLOFLADING) return 'docx';
        else if (code === DocumentTypeCodeEnum.BADGE) return 'zip';
        else if (code === DocumentTypeCodeEnum.BILL) return 'zip';
        return undefined;
    };
}

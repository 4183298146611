import React, { FC } from 'react';
import { Popover } from 'antd';
import { AdditionalEntityActionsButton } from '../additionalEntityActionsButton';

export const AdditionalEntityActionsPopoverContainer: FC = (props) => {
    const { children } = props;

    return (
        <Popover
            overlayClassName={'grid-additional-actions-popover-container'}
            autoAdjustOverflow
            arrowPointAtCenter
            placement="bottomLeft"
            trigger="click"
            destroyTooltipOnHide
            content={
                <>
                    <div className={'rr-grid-actions-popover-header1'}></div>
                    <div className={'rr-grid-actions-popover-content'}>{children}</div>
                </>
            }
        >
            <AdditionalEntityActionsButton />
        </Popover>
    );
};

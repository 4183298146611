import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { ContactInfoRead, serverApi } from '../../../../../../server';
import { AxiosError } from 'axios';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { ContactDeleteArgs, ContactsDeleteArgs, ContactsTransitArgs, ListContactsArgs, ListContactsData } from './types';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { GetEntityByIdArgs } from '../../../../../../components/page/entityGrid/types/api';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export const contactsApi = createApi({
    reducerPath: 'contactsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ContactsList', 'Contact'],
    endpoints: (builder) => ({
        listContacts: builder.query<ListContactsData, ListContactsArgs>({
            queryFn: async ({ businessAccountId, params }: ListContactsArgs) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = ServerUtils.createRequestFilters([
                    params.hideArchive ? ['archive', 'EQ', false] : undefined,
                    params.id ? ['id', 'EQ', params.id] : params.ids ? ['id', 'IN', params.ids] : undefined,
                    params.type ? ['personLinkTypeCodes', 'EQ', params.type] : undefined,
                ]);
                const sortBy = params.sortBy || undefined;
                const sortOrder = params.sortBy ? params.sortOrder : undefined;

                try {
                    const { data: contacts } = await serverApi.listContacts(
                        businessAccountId,
                        params.search,
                        params.limit,
                        startFrom,
                        sortBy,
                        sortOrder,
                        filters
                    );
                    return {
                        data: {
                            entitiesData: contacts,
                        },
                    };
                } catch (e) {
                    return {
                        error: e as AxiosError,
                    };
                }
            },
            providesTags: ['ContactsList'],
        }),
        getContactById: builder.query<ContactInfoRead, GetEntityByIdArgs>({
            queryFn: async (args: GetEntityByIdArgs) => {
                const { businessAccountId, entityId } = args;

                try {
                    return await serverApi.getContactById(businessAccountId, entityId);
                } catch (error: unknown) {
                    showNotification('error', 'Не удалось загрузить контакт');
                    const errorMessage = error instanceof Error ? error.message : undefined;
                    throw new Error(errorMessage);
                }
            },
            providesTags: ['Contact'],
        }),
        archiveContacts: builder.mutation<void, ContactsTransitArgs>({
            queryFn: async (args, { getState }) => {
                const { workflowDirectiveBulk } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitContactWorkflowBulk(businessAccountId, workflowDirectiveBulk);
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1 ? 'Контакты успешно изменены' : 'Контакт успешно изменен';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1 ? 'Не удалось изменить контакты' : 'Не удалось изменить контакт';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['ContactsList', 'Contact'],
        }),
        deleteContacts: builder.mutation<void, ContactsDeleteArgs>({
            queryFn: async (args, { getState }) => {
                const { versionedEntityObjList } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteContacts(businessAccountId, versionedEntityObjList);
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Контакты успешно удалены' : 'Контакт успешно удален';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Не удалось удалить контакт' : 'Не удалось удалить контакты';
                    showNotification('error', notificationMessage);

                    return { data: undefined };
                }
            },
            invalidatesTags: ['ContactsList', 'Contact'],
        }),
        deleteContact: builder.mutation<void, ContactDeleteArgs>({
            queryFn: async (args, { getState }) => {
                const { contactId, businessVersionObj } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteContactById(businessAccountId, contactId, businessVersionObj);
                    showNotification('success', 'Контакт удален');

                    return { data: undefined };
                } catch (error: unknown) {
                    showNotification('error', 'Контакт не удален');

                    return { data: undefined };
                }
            },
            invalidatesTags: ['ContactsList', 'Contact'],
        }),
    }),
});

export const {
    useListContactsQuery,
    useGetContactByIdQuery,
    useLazyListContactsQuery,
    useArchiveContactsMutation,
    useDeleteContactsMutation,
    useDeleteContactMutation,
} = contactsApi;

import React, {CSSProperties, FC, memo} from 'react';
import { IconCashRegisterSolid } from '../../../../../../../../components/icons';
import { DashboardData } from '../../../../../types/DashboardData';
import { DashboardWidgetBaseLinks } from '../../../../../components/DashboardWidget/instances/baseLinks/DashboardWidgetBaseLinks';

interface DashboardRentDebtWidgetProps {
    dashboardData: DashboardData;
    style?: CSSProperties;
    XXL?: boolean;
}

export const DashboardRentDebtWidget: FC<DashboardRentDebtWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const { currentRentDebtSum, currentRentDebtRentersCount, currentRentDebtProjectsCount, loading } = dashboardData;

    return (
        <DashboardWidgetBaseLinks
            loading={loading}
            color={'rgb(82, 89, 147)'}
            title={'Долг арендаторов'}
            elementsCount={currentRentDebtRentersCount}
            instancesCount={currentRentDebtProjectsCount}
            icon={IconCashRegisterSolid}
            sum={currentRentDebtSum}
            isSubrent={false}
            style={props.style}
            XXL={props.XXL}
        />
    );
});

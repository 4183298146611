import React, { ReactNode } from 'react';
import { Popover } from 'antd';
import './InfoPopover.less';

export interface IProps {
    content: ReactNode;
}

export interface IState {
    popoverVisible: boolean;
}

export class InfoPopover extends React.PureComponent<IProps, IState> {
    public static defaultProps = {
        trigger: 'click',
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            popoverVisible: false,
        };
    }

    closePopover = () => {
        this.setState({
            popoverVisible: false,
        });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.closePopover, true);
        window.addEventListener('resize', this.closePopover, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.closePopover, true);
        window.removeEventListener('resize', this.closePopover, true);
    }

    render() {
        const { children, content } = this.props;

        return (
            <Popover
                visible={this.state.popoverVisible}
                onVisibleChange={(visible) =>
                    this.setState({
                        popoverVisible: visible,
                    })
                }
                overlayClassName={'rr-infoPopover'}
                autoAdjustOverflow
                arrowPointAtCenter
                placement="bottomRight"
                trigger={'click'}
                content={content}
                destroyTooltipOnHide
            >
                {children}
            </Popover>
        );
    }
}

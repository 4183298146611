import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { ProductRecord, serverApi } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';

export interface ProductSelectProps extends BaseSelectBaseProps<ProductRecord> {}

const ProductSelectFC = ({ ...props }: ProductSelectProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listProducts(businessAccountId, undefined, 0, [`id;IN;${ids}`]);
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        const response = await serverApi.listProducts(businessAccountId, limit, startFrom, undefined, 'lastActivityDate', 'DESC', search);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(item) => item.id}
            getOptionProps={(item) => ({
                label: item.shortName,
                children: item.shortName,
            })}
        />
    );
};

export class ProductSelect extends React.Component<ProductSelectProps> {
    render() {
        return <ProductSelectFC {...this.props} />;
    }
}

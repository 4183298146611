import React from 'react';
import { Icon } from 'antd';
import { IconAngleLeft, IconAngleRight } from '../../../../../components/icons';
import { joinClasses } from '../../../../../shared/util/utils';
import classNames from 'classnames';

interface IProps {
    instanceCount: number;
    anonymousInstanceCount?: number;
    availableAnonymousInstanceCount?: number;
    //
    availableInstanceCount?: number;
    unavailableInstanceCount?: number;
    //
    addInstance?: () => void;
    removeInstance?: () => void;
    //
    hideControls?: boolean;
    withNewInstances?: boolean;
}

export class GridInstanceCountBlock extends React.Component<IProps> {
    onLeftClick = async (e: React.MouseEvent<HTMLElement>) => {
        if (this.props.removeInstance) this.props.removeInstance();
    };

    onRightClick = async (e: React.MouseEvent<HTMLElement>) => {
        if (this.props.addInstance) this.props.addInstance();
    };

    render() {
        const {
            instanceCount,
            unavailableInstanceCount,
            anonymousInstanceCount,
            availableInstanceCount,
            hideControls,
            withNewInstances,
            availableAnonymousInstanceCount,
        } = this.props;
        let minusButtonDisabled = !this.props.removeInstance;
        let plusButtonDisabled = !this.props.addInstance;

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div
                    className={classNames('rr-added-instances-block', {
                        'rr-added-instances-block__whitout-added-instances': instanceCount === 0,
                    })}
                >
                    {!hideControls && (
                        <Icon
                            className={classNames('rr-added-instances-block__minus-button', {
                                'rr-added-instances-block__minus-button--disabled': minusButtonDisabled,
                            })}
                            component={IconAngleLeft}
                            onClick={minusButtonDisabled ? undefined : this.onLeftClick}
                        />
                    )}
                    <span style={withNewInstances ? { color: '#33bfa3' } : {}}>{instanceCount}</span>
                    {unavailableInstanceCount && unavailableInstanceCount > 0 ? ` (-${unavailableInstanceCount})` : ''}
                    {anonymousInstanceCount !== undefined && anonymousInstanceCount > 0 ? (
                        <span style={{ fontWeight: instanceCount === 0 ? 400 : 500, opacity: 0.5 }}>({anonymousInstanceCount}?)</span>
                    ) : null}
                    {availableInstanceCount !== undefined ? <>&nbsp;{`/ ${availableInstanceCount}`}</> : null}
                    {availableInstanceCount !== undefined &&
                    availableAnonymousInstanceCount !== undefined &&
                    availableAnonymousInstanceCount > 0 ? (
                        <span style={{ fontWeight: instanceCount === 0 ? 400 : 500, opacity: 0.5 }}>
                            ({availableAnonymousInstanceCount}?)
                        </span>
                    ) : null}
                    {!hideControls && (
                        <Icon
                            className={joinClasses(
                                'rr-added-instances-block__plus-button',
                                plusButtonDisabled ? 'rr-added-instances-block__plus-button--disabled' : undefined
                            )}
                            component={IconAngleRight}
                            onClick={plusButtonDisabled ? undefined : this.onRightClick}
                        />
                    )}
                </div>
            </div>
        );
    }
}

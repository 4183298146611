import {LocalizationEnum, localize} from '../../../../localization';
import {NotificationCustomizationObjEventFilterEnum} from "../../../../server";

export const notificationCode2LocalizationEnum = (e:NotificationCustomizationObjEventFilterEnum):LocalizationEnum|undefined => {
    let res:LocalizationEnum|undefined;
    if(e === NotificationCustomizationObjEventFilterEnum.RENTACTIVITY) res = LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__RENTACTIVITY;
    else if(e === NotificationCustomizationObjEventFilterEnum.WARNING) res = LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__WARNING;
    else if(e === NotificationCustomizationObjEventFilterEnum.PROBLEM) res = LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__PROBLEM;
    else if(e === NotificationCustomizationObjEventFilterEnum.ASSIGNMENT) res = LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__ASSIGNMENT;
    else if(e === NotificationCustomizationObjEventFilterEnum.RENTCORRECTION) res = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CORRECT;
    else if(e === NotificationCustomizationObjEventFilterEnum.RENTLOSTORBROKEN) res = LocalizationEnum.PAGE__USER_PROFILE__EVENT_TYPE__LOST_OR_BROKEN;
    else if(e === NotificationCustomizationObjEventFilterEnum.NEWORDERFROMSTOREFRONT) res = LocalizationEnum.PAGE__USER_PROFILE__EVENT_TYPE__NEW_ORDER_FROM_STOREFRONT;
    else if(e === NotificationCustomizationObjEventFilterEnum.ORDERFROMSTOREFRONTCANCELED) res = LocalizationEnum.PAGE__USER_PROFILE__EVENT_TYPE__ORDER_FROM_STOREFRONT_CANCELED;
    else if(e === NotificationCustomizationObjEventFilterEnum.NEWPAYMENTFROMSTOREFRONT) res = LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__ASSIGNMENTTOWORK;
    else if(e === NotificationCustomizationObjEventFilterEnum.ASSIGNMENTTOWORK) res = LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__NEWPAYMENTFROMSTOREFRONT;
    return res;
};

import { LOCATION_CHANGE } from 'connected-react-router';
import { Analytics } from '../../analytics/analytics';
import { USER_FOUND } from 'redux-oidc';
import { getStoreState } from '../../../index';
import { IRootState } from './index';

const ACTION_TYPES = {
    SET_APP_VERSION: 'system/SET_APP_VERSION',
    SET_SERVER_TIME: 'system/SET_SERVER_TIME',
};

const initialState: SystemState = {
    businessAccountId: 0,
    appVersion: undefined,
};

export interface SystemState {
    businessAccountId: number;
    appVersion?: string;
    serverTime?: number;
    getServerTime?: number;
}

export default (state: SystemState = initialState, action: any): SystemState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let res = /^\/(\d+)\/*/.exec(pathName);
            let businessAccountId = res && res.length > 1 ? +res[1] : 0;
            return {
                ...state,
                businessAccountId: businessAccountId,
            };

        case USER_FOUND:
            Analytics.getInstance().setUserId(action.payload.profile.sub);
            return state;

        case ACTION_TYPES.SET_APP_VERSION:
            return {
                ...state,
                appVersion: action.payload,
            };

        case ACTION_TYPES.SET_SERVER_TIME:
            return {
                ...state,
                serverTime: action.payload,
                getServerTime: Date.now(),
            };

        default:
            return state;
    }
};

export const businessAccountIdSelector = (state: IRootState) => state.system.businessAccountId;

export const setAppVersion = (value: string) => ({
    type: ACTION_TYPES.SET_APP_VERSION,
    payload: value,
});

export const setServerTime = (value: number) => ({
    type: ACTION_TYPES.SET_SERVER_TIME,
    payload: value,
});

export const getCurrentServerTime = (): Date | undefined => {
    let { serverTime, getServerTime } = getStoreState().system;

    return serverTime !== undefined && getServerTime !== undefined ? new Date(serverTime + (Date.now() - getServerTime)) : undefined;
};

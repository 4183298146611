import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {isDefined} from "../../../shared/util/utils";
import {GridColumnCreator} from "../../../components/grid/gridColumnCreator";
import {Link} from "react-router-dom";
import React from "react";

export const tblColumnsUser = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.USER) {
        const sizes = GridColumnCreator.getColumnSizes(30);
        return {
            title: column.title,
            dataIndex: column.dataIndex,
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, rowData: Record, rowAction, entityType) => {
                const {businessAccountId, id, name, hasOwn, emptyValue} = column.getData(rowData);
                const style = {color: isDefined(hasOwn) && !hasOwn ? '#cccccc' : undefined};
                return (
                    <div className={'rr-grid-text-cell-left'} title={name}>
                        {emptyValue ? (
                            <span style={{color: '#aaaaaa'}}>{emptyValue}</span>
                        ) : id ? (
                            <Link to={`/${businessAccountId}/users/${id}`} style={style} onClick={(event) => event.stopPropagation()}>
                                {name}
                            </Link>
                        ) : (
                            <span style={style}>{name}</span>
                        )}
                    </div>
                );
            }
        };
    } else {
        throw Error('error');
    }
};

import { useMemo } from 'react';
import { DragGroup } from '../types/types';
import { KitInfoRead, ProductInfoRead } from '../../../../../../../server';

export const useDragIds = <T extends ProductInfoRead | KitInfoRead>(groups: DragGroup[], entity: T | undefined) => {
    const addedIds = useMemo(() => {
        const addedIds: number[] = [];

        groups.forEach((group) => {
            group.fields.forEach((field) => {
                addedIds.push(field.customFieldId);
            });
        });

        return addedIds;
    }, [groups]);

    const variantFieldIds = useMemo(() => {
        const variantFieldIds: number[] = [];

        if (entity != null && 'variantsCustomFieldMarkers' in entity) {
            entity.variantsCustomFieldMarkers?.forEach((item) => {
                variantFieldIds.push(item.customFieldId);
            });
        }

        return variantFieldIds;
    }, [entity]);

    return useMemo(
        () => ({
            addedIds,
            variantFieldIds,
        }),
        [addedIds, variantFieldIds]
    );
};

import React from 'react';
import {InputNumber} from "antd";
import {InputNumberProps} from "antd/lib/input-number";
import {inputNumberParser, inputNumberPercentFormatter, onInputChange} from ".";

export interface NumberInputDiscountProps extends Omit<InputNumberProps, 'formatter'|'parser'|'max'|'step'> {}

export class NumberInputDiscount extends React.Component<NumberInputDiscountProps> {
    render() {
        const {onChange, ...props} = this.props;
        return (
            <InputNumber
                min={Number.MIN_SAFE_INTEGER}
                {...props}
                parser={inputNumberParser}
                formatter={inputNumberPercentFormatter}
                onChange={(value) => onInputChange(value, onChange)}
                max={100}
                step={1}
            />
        );
    };
}

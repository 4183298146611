import { withEntityListProviders } from '../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { GridProps } from '../../../../components/grid/Grid';
import { ListOffersQueryParams } from '../api/types';
import { ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import React, { FC, useContext } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { EntityList } from '../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { offersFilters, projectOffersFilters } from './filters/offersFilters';
import { OffersPageFiltersDescription } from './filters/offersFiltersDescription';
import { GridRefContext } from '../../../../components/page/entityGrid/components/context/GridRefContext';
import { EntitiesSelectionContext } from '../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { offersLoadingSelector } from '../reducers/offers/offers.selectors';
import { offerEntitySelector } from '../reducers/offer/offer.selectors';
import { useSelectedRecords } from '../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { OfferCreateModalWrapper } from '../modal/create/offerCreateModalWrapper';
import { OfferEditModalWrapper } from '../modal/edit/offerEditModalWrapper';
import { clearOffer, loadOffer } from '../reducers/offer/offer.reducer';
import { EntityGridRootPath } from '../../../../components/page/entityGrid/types/params';
import { ProjectRecord } from '../../../../server';
import { usePageURLParamsFromDescription, useQueryParamsWithoutModalParams } from '../../../../components/page/entityGrid/hooks/useParams';
import { useListProjectsQuery } from '../api/offers.api';
import { offersListColumns } from './columns/offersListColumns';
import { projectsPageUrlRoute } from '../../projects/projectsPage';
import { ProjectPageGridNamesEnum } from '../../projects/production/page/projectPage';
import { ProjectsPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { ExportOffloadingPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { useEntityActions } from '../hooks/useOffersEntityActions';
import { useEntityActionsButtons } from '../hooks/useOffersEntityActionsButtons';
import { useExcludedValues } from '../hooks/useOffersExcludedValues';
import { useOffloadingDownload } from '../hooks/useOffloadingDownload';

interface OffersListComponentProps {
    gridName: ProjectsPageTabsEnum.OFFERS | ProjectPageGridNamesEnum.OFFERS;
    urlPath:
        | `${typeof projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}`
        | `projects/production/${number}?tab=${ProjectsPageTabsEnum.OFFERS}`;
    gridProps?: Partial<GridProps>;
    staticPageParams?: Partial<ListOffersQueryParams>;
    projectId?: number;
    modalProps?: Partial<ModalFormNEWProps>;
    visible?: boolean;
}

const OffersListComponent: FC<OffersListComponentProps> = (props) => {
    const { gridName, urlPath, visible, staticPageParams, gridProps, modalProps, projectId } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(offerEntitySelector);
    const entitiesLoading = useAppSelector(offersLoadingSelector);
    const gridRef = useContext(GridRefContext);
    const [entitySelection] = useContext(EntitiesSelectionContext);

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: OffersPageFiltersDescription,
        gridName,
        skipLocationChange: !visible,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListProjectsQuery(
        {
            businessAccountId: businessAccountId,
            params: {
                ...queryParams,
                ...staticPageParams,
            },
            projectId: projectId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    let rootPath = `/${businessAccountId}/${urlPath}` as EntityGridRootPath;
    if (projectId) {
        rootPath = rootPath.split('?')[0] as EntityGridRootPath;
    }

    const entityActions = useEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<ProjectRecord>(entitySelection, queryData.data?.entitiesData.records);
    const entityActionsButtons = useEntityActionsButtons({
        selectedRecords,
        gridRef,
        refetchFunction: queryData.refetch,
    });

    const { excludeColumns, filtersExcludeFields } = useExcludedValues(projectId);

    const onOffloadingDownload = useOffloadingDownload(pageParams, projectId, excludeColumns);

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: offersListColumns,
                    filtersData: projectId ? projectOffersFilters : offersFilters,
                    pageParamsDescription: OffersPageFiltersDescription,
                },
                metaData: {
                    entityType: 'offer',
                    gridName,
                    rootPath: rootPath,
                },
                queryData,
                entitiesLoading,
                entityActions,
                excludeColumns,
                filtersExcludeFields,
                actionButtons: entityActionsButtons,
                exportBlock: <ExportOffloadingPopover storageKey={'offersOffloading'} onDownload={onOffloadingDownload} />,
                ...gridProps,
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: OfferCreateModalWrapper,
                        props: { ...modalProps },
                    },
                    edit: {
                        component: OfferEditModalWrapper,
                    },
                    copy: {
                        component: OfferCreateModalWrapper,
                        props: { ...modalProps },
                    },
                },
                entityLoadActions: {
                    loadEntity: loadOffer,
                    clearEntity: clearOffer,
                },
            }}
        />
    );
};

export const OffersList = withEntityListProviders(OffersListComponent);

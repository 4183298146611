import {showConfirmNew} from '../../components/confirm/showConfirm';
import {Icon, Switch} from 'antd';
import {joinClasses} from './utils';
import {IconSyncSolid} from '../../components/icons';
import React from 'react';
import {IntlProvider, IntlShape} from 'react-intl';
import {getStore, getStoreState} from '../../../index';
import moment, {Moment} from 'moment';
import {getCurrentServerTime} from '../reducers/system.reducer';
import {DynamicForm} from '../../components';
import {FormItemType} from '../../components/dynamicForm/DynamicForm';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import './setNewReturnDatesBeforeProlongOperationConfirm.less';
import {updateRecalculateShiftsCount} from '../reducers/userSettings/userSettings.reducer';
import {DateTimePicker, DateTimePickerProps} from "../../components/dateTimePicker/dateTimePicker";

export interface SetNewReturnDatesForProlongConfirmResult {
    recalculateShiftCount: boolean;
    newData: Date | undefined;
    setNewDate: boolean;
}

export const setNewReturnDatesBeforeProlongOperationConfirm = async (
    intl: IntlShape,
    defaultDate: Date
): Promise<SetNewReturnDatesForProlongConfirmResult> => {
    const operationType = 'prolong';
    let recalculateShiftCount = getStoreState().userSettings.recalculateShiftsCount[operationType] || false;
    let date: Date | undefined = defaultDate;

    let _formRef: WrappedFormUtils | undefined;
    const getFormRef = (ref) => {
        return (_formRef = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    let interval = window.setInterval(() => {
        if (_formRef) {
            _formRef.validateFields();
            window.clearInterval(interval);
        }
    }, 100);

    const divRef = React.createRef<HTMLDivElement>();

    const componentClassName = 'rr-prolong-operation-confirm-modal';

    let yes = await showConfirmNew({
        className: componentClassName,
        intl: intl,
        title: 'Установить новую дату возврата?',
        content: (
            <IntlProvider {...intl}>
                <div ref={divRef} className={`${componentClassName}-block`}>
                    <DynamicForm
                        onChange={() => {}}
                        wrappedComponentRef={getFormRef}
                        data={[
                            {
                                fields: [
                                    {
                                        label: 'Новая дата возврата',
                                        id: 'date',
                                        type: FormItemType.Component,
                                        required: true,
                                        placeholder: 'Выбрать дату',
                                        defaultValue: moment(date),
                                        validationRules: [
                                            {
                                                validator: (rule, value: Moment, callback) => {
                                                    let buttonDisabled = false;
                                                    let error: string | undefined;
                                                    if (value && value.valueOf() <= moment(getCurrentServerTime()).valueOf()) {
                                                        error = 'Дата возврата должна быть позже текущей';
                                                        buttonDisabled = true;
                                                    } else if (!value) {
                                                        buttonDisabled = true;
                                                    }
                                                    const button: HTMLButtonElement | null = document.querySelector(
                                                        `.${componentClassName} .rr-btn-confirm-actualize-dates`
                                                    );
                                                    if (button) button.disabled = buttonDisabled;
                                                    if (error) {
                                                        callback(error);
                                                        return;
                                                    }
                                                    callback();
                                                },
                                            },
                                        ],
                                        onChange: (value: Moment) => {
                                            date = value ? moment(value).toDate() : undefined;
                                            if (!value) {
                                                const button: HTMLButtonElement | null = document.querySelector(
                                                    `.${componentClassName} .rr-btn-confirm-actualize-dates`
                                                );
                                                if (button) button.disabled = true;
                                            }
                                        },
                                        component: DateTimePicker,
                                        componentProps: ():DateTimePickerProps => ({
                                            getCalendarContainer: (triggerNode) => divRef?.current || (triggerNode.parentNode as HTMLElement) || triggerNode,
                                            getCurrentDate: () => {
                                                const d = moment(getCurrentServerTime()).add(5, 'minutes');
                                                const dRoundToHour = d.clone().startOf('hour');
                                                const diffMin = (d.valueOf() - dRoundToHour.valueOf()) / 1000 / 60;
                                                dRoundToHour.add(Math.ceil(diffMin / 5) * 5, 'minutes');
                                                return dRoundToHour;
                                            }
                                        })
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
                <div className={`${componentClassName}-block`}>
                    <Icon
                        style={{ marginRight: 10 }}
                        className={joinClasses(
                            'rr-RecalculateShiftsCountButton__icon',
                            'rr-RecalculateShiftsCountButton__icon__without-hover'
                        )}
                        component={IconSyncSolid}
                    />
                    Пересчитать смены
                    <Switch
                        style={{ marginLeft: 12, verticalAlign: 'middle' }}
                        defaultChecked={recalculateShiftCount}
                        onChange={(checked: boolean) => {
                            recalculateShiftCount = checked;
                            getStore().dispatch(updateRecalculateShiftsCount({ [operationType]: checked }));
                        }}
                    />
                    <div style={{ fontSize: 12, color: '#a6a8b0', marginTop: 22 }}>
                        Если включено, то при установке новой даты возврата число смен к оплате будет пересчитано
                    </div>
                </div>
            </IntlProvider>
        ),
        cancelText: 'Установить новую',
        cancelButtonProps: {
            icon: 'crosshairs',
            className: 'rr-btn-default rr-btn-confirm-actualize-dates',
        },
        okText: 'Пропустить',
    });
    return {
        recalculateShiftCount,
        newData: date,
        setNewDate: yes,
    };
};

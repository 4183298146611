export const getParamsScreenData = (firstTime:number, lastTime:number) => {
    if (!lastTime || !firstTime) {
        return {
            stepLeft: -15,
            stepRight: 15,
            screenLeft: Date.now()-(15*24*60*60*1000),
            screenRight: Date.now()+(15*24*60*60*1000),
            intervalR: 'hour',
        };
    }

    //длина экрана во времени
    const interval = (lastTime - firstTime) / 1000 / 60 / 60 ;

    //штамп времени середины экрана
    const centerTime = firstTime + ((lastTime - firstTime) / 2);

    let screenLeft;
    let screenRight;
    let stepLeft;
    let stepRight;
    let steps: any;
    let dateNow: number = +Date.now();
    let intervalR = 'hour';
    if (interval < (24*3)) {

        intervalR = 'hour';
        screenLeft = firstTime ;
        screenRight = lastTime ;

        let countSteps = (((lastTime - firstTime) / 1000/60/60 /2));

        // сколько секунд в шаге
        let timeStep = ((lastTime - firstTime) / (countSteps*2));

        //добавить стока шагов от тек. момента
        steps = (dateNow - centerTime) / timeStep;

        stepLeft = -countSteps -steps;
        stepRight = +countSteps -steps  ;
    }  else if (interval < (24 * 90)+24) {
        intervalR = 'day';
        screenLeft = firstTime;
        screenRight = lastTime;

        //сколько шагов
        let countSteps = (((lastTime - firstTime) / 1000/60/60/24/2));

        // сколько секунд в шаге
        let timeStep = ((lastTime - firstTime) / (countSteps*2));

        //добавить стока шагов от тек. момента
        steps = (dateNow - centerTime) / timeStep;

        stepLeft = -countSteps -steps;
        stepRight = +countSteps -steps  ;

    } else if (interval > (24 * 90)+24 ) {

        intervalR = 'month';
        screenLeft = firstTime;
        screenRight = lastTime;

        //сколько шагов
        let countSteps = ((lastTime - firstTime) / 1000/60/60/24/30/2);

        // сколько секунд в шаге
        let timeStep = ((lastTime - firstTime) / (countSteps*2));

            steps = (dateNow - centerTime) / timeStep;
            stepLeft = -countSteps - steps ;
            stepRight = countSteps - steps ;
    }

    return {
        stepLeft,
        stepRight,
        screenLeft,
        screenRight,
        intervalR,
    };
};

import { PureComponent } from 'react';
import { ProfessionSelect, ProfessionSelectProps } from './ProfessionSelect';
import { SelectAndCreate } from '../selectAndCreate';
import { ProfessionCreateModal } from '../../../../modules/main/crew/tabs/professions/modal/components/CreateModal/ProfessionCreateModal';
import { IRootState } from '../../../../shared/reducers';
import { connect, ConnectedProps } from 'react-redux';
import { LocalizationEnum, localize } from '../../../../localization';
import { ProfessionStateCodeEnum } from '../../../../server';

export interface ProfessionSelectAndCreateProps extends Omit<ProfessionSelectProps, 'value'> {
    value: number[] | undefined;
}

class _ProfessionSelectAndCreate extends PureComponent<ProfessionSelectAndCreateProps & StoreProps> {
    render() {
        const { props } = this;
        const { businessAccountId, value, multiple } = props;

        return (
            <SelectAndCreate
                select={() => <ProfessionSelect {...props} />}
                modal={({ close }) => (
                    <ProfessionCreateModal
                        businessAccountId={businessAccountId}
                        title={'Создание новой специальности'}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        modalName={'create-profession'}
                        initialValues={{
                            fromTemplate: true,
                            stateCode: ProfessionStateCodeEnum.ACTIVE,
                        }}
                        onSuccess={(data) => {
                            props.onChange?.(multiple ? [...(value ?? []), data.id] : data.id);
                            close();
                        }}
                        onCancel={() => {
                            close();
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
});

const connector = connect(mapStateToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const ProfessionSelectAndCreate = connector(_ProfessionSelectAndCreate);

import React, { ReactNode } from 'react';
import { moveElementToKit, OperationElement } from '../../reducers/operationForm.reducer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PopoverContent } from '../../../../../components/popover/PopoverContent';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Button, Icon, Select } from 'antd';
import { IconCrosshairsSolid, IconDollyFlatbedSolid } from '../../../../../components/icons';
import { IRootState } from '../../../../../shared/reducers';
import { connect } from 'react-redux';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import sortBy from 'lodash/sortBy';
import { cbOnValueDefined } from '../../../../../shared/util/utils';

interface IProps extends DispatchProps, StateProps, WrappedComponentProps {
    element: OperationElement;
    onClose: () => void;
}

interface IState {
    value?: any;
}

export class Component extends React.Component<IProps, IState> {
    private select;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        cbOnValueDefined(this.getSelect, this.setSelectFocus);
    }

    setSelectFocus = () => {
        setTimeout(() => {
            this.select.focus();
        }, 400);
    };

    getSelect = () => {
        return this.select;
    };

    getSelectRef = (ref) => {
        if (!this.select) this.select = ref;
    };

    renderData = (data: OperationElement[]): ReactNode => {
        return data.map((item, index) => {
            const isKit = !!item.kitId;
            const variantString = item.variantName ? `${item.variantName}` : '';
            const nameString = `${
                !isKit ? item.instanceCount + ' x ' + item.productShortName + (variantString ? ':' : '') : item.productShortName
            }`;
            const isCurrentKit = item.id === this.props.element.id;

            return (
                <Select.Option
                    className={item.parentId ? 'rr-operationForm-kit-add-element-popover-select-dropdown__item-nested' : undefined}
                    key={index}
                    value={item.id}
                    disabled={!!item.kitId}
                    title={nameString + (variantString ? ' ' + variantString : '')}
                    label={`${nameString} ${variantString}`}
                >
                    {isCurrentKit && (
                        <Icon
                            component={IconCrosshairsSolid}
                            className={'rr-operationForm-kit-add-element-popover-select-dropdown__current-kit-icon'}
                        />
                    )}
                    {isKit && (
                        <Icon
                            component={IconDollyFlatbedSolid}
                            className={'rr-operationForm-kit-add-element-popover-select-dropdown__kit-icon'}
                        />
                    )}
                    <span className={'rr-operationForm-kit-add-element-popover-select-dropdown__label'}>
                        <span style={{ fontWeight: 400 }}>{nameString}</span>
                        {variantString && <span>&nbsp;{variantString}</span>}
                    </span>
                </Select.Option>
            );
        });
    };

    onChange = (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[]) => {
        this.setState({ value: value && Array.isArray(value) ? (value as LabeledValue[]).map((item) => item.key) : [] });
    };

    onButtonClick = (e) => {
        this.props.moveElementToKit(this.props.element.id, this.state.value);
        this.props.onClose();
    };

    render() {
        let { entities, elements } = this.props;
        let sortFieldName = elements.sortBy || '';
        let sortAsc = elements.sortOrder === 'ASC';

        // Тут из массива нужно сделать массив со вложенными элементами
        let newEntities: OperationElement[] = [];
        entities.forEach((entity0) => {
            let entity = { ...entity0 };
            if (!entity.kitId && !entity.parentId) newEntities.push(entity);
            else if (entity.kitId) {
                let subRows = entities.filter((entity1) => entity1.parentId === entity.id);
                entity['subRows'] = subRows;
                newEntities.push(entity);
            }
        });

        let sortedData: OperationElement[] = sortBy(newEntities, sortFieldName);
        if (!sortAsc) sortedData.reverse();

        let variants: OperationElement[] = [];
        sortedData.forEach((item) => {
            variants.push(item);
            if (item['subRows'] && item.id !== this.props.element.id) variants.push(...item['subRows']);
        });

        return (
            <PopoverContent
                headerClassName={'rr-grid-actions-popover-header-xxx'}
                header={
                    <>
                        <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
                            mode={'multiple'}
                            maxTagCount={3}
                            ref={this.getSelectRef}
                            labelInValue
                            optionFilterProp={'label'}
                            allowClear
                            showSearch={false}
                            autoClearSearchValue={false}
                            showAction={['focus', 'click']}
                            defaultActiveFirstOption={false}
                            onChange={this.onChange}
                            style={{ width: '100%' }}
                            placeholder="Выберите обязательства"
                            notFoundContent={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)}
                            dropdownClassName={'rr-operationForm-kit-add-element-popover-select-dropdown'}
                        >
                            {variants ? this.renderData(variants) : null}
                        </Select>
                        <Button
                            className={'rr-btn-blue'}
                            style={{ marginLeft: 8 }}
                            onClick={this.onButtonClick}
                            disabled={!this.state.value || !this.state.value.length}
                        >
                            ОК
                        </Button>
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        elements: storeState.operationForm.elements,
        entities: storeState.operationForm.elements.entities,
    };
};

const mapDispatchToProps = { moveElementToKit };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const KitAddElementPopoverContent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));

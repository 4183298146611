import { useCallback, useState } from 'react';

type EntityId = number | undefined;
export const useIdForDrawer = () => {
    const [entityIdForDrawer, setEntityIdForDrawer] = useState<EntityId>(undefined);

    const clearEntityId = useCallback(() => {
        setEntityIdForDrawer(undefined);
    }, []);

    return [entityIdForDrawer, setEntityIdForDrawer, clearEntityId] as [EntityId, (entityId: EntityId) => void, () => void];
};

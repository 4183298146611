import React, { useCallback } from 'react';
import { useEntitySelection } from '../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import {
    ExcelExportTemplateTypeCodeEnum, FileTypeCodeEnum,
    KitFinancialFieldsTypeCode, KitNotFinancialFieldsTypeCode, NomenclatureEntityTypeCodeEnum,
} from '../../../../../server';
import { useAppSelector } from '../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import {ExportOffloadingPopover} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {useIntl} from "react-intl";
import {canViewFinancialDataSelector} from "../../../../../shared/reducers/permissions.reducer";
import {useEntityGridContext} from "../../../../../components/page/entityGrid/components/context/GridRefContext";
import {KitsListQueryParams} from "../api/kits.api.types";
import {KitsApiUtils} from "../api/kits.api.utils";
import {ExportProductsPriceListPopover} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportProductsPriceListPopover/ExportProductsPriceListPopover";
import {
    ExportProductsPriceListPopoverOptions,
    ProductsPriceListOnDownload
} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportProductsPriceListPopover/utils/data";
import {ProductsApiUtils} from "../../products/api/products.api.utils";
import {DownloadDocumentUtils} from "../../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils";
import {showNotification} from "../../../../../components/notification/showNotification";

export const useExportBlock = (pageParams: KitsListQueryParams) => {
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const entitySelection = useEntitySelection();
    const gridRef = useEntityGridContext();

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = KitsApiUtils.getRequestFilters(pageParams, subrentModuleEnabled);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.KITTEMPLATE,
                fileName: 'Экспорт наборов',
                fileType,
                filters,
                financialFieldsEnum: KitFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: KitNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    const getSelectedIdsFilterForDocumentGeneration = useCallback(() => {
        const ids = entitySelection.map((id) => {
            return `KIT_${id}`;
        });

        return ids.length > 0 ? `uuid;IN;${ids.join(';')}` : '';
    }, [entitySelection]);

    const onDownloadProductsPriceList: ProductsPriceListOnDownload = useCallback(
        async ({ fileType, options, templateId, isCommonTemplate, discount, legalDetailId }) => {
            let filters: string[] = KitsApiUtils.getRequestFilters(pageParams, subrentModuleEnabled);
            const selectedIdsFilter = getSelectedIdsFilterForDocumentGeneration();
            if (selectedIdsFilter) filters.push(selectedIdsFilter);
            const sortBy = pageParams.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : pageParams.sortBy;
            filters.push(`nomenclatureEntityTypeCode;IN;${NomenclatureEntityTypeCodeEnum.KIT}`);
            await DownloadDocumentUtils.nomenclaturePricelistGenerateAndDownload(
                {
                    businessAccountId: businessAccountId,
                    common: Boolean(isCommonTemplate),
                    documentTemplateId: templateId!,
                    appliedDiscount: discount ?? 0,
                    showDiscount: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_DISCOUNT),
                    showPricingSchemes: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_PRICING_SCHEMES),
                    showDescription: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_DESCRIPTION),
                    showCount: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_COUNT),
                    groupByCategories: options && options.includes(ExportProductsPriceListPopoverOptions.GROUP),
                    sortBy: sortBy,
                    sortOrder: pageParams.sortOrder,
                    search: pageParams.search,
                    filters: filters,
                    legalDetailId,
                },
                `Прайс-лист`,
                fileType as FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
            ).catch((e) => {
                showNotification('error', 'Ошибка генерации файла');
            });
        },
        [businessAccountId, getSelectedIdsFilterForDocumentGeneration, pageParams, subrentModuleEnabled]
    );

    return (
        <>
            <ExportOffloadingPopover storageKey={'kitsOffloading'} onDownload={onOffloadingDownload} />
            <ExportProductsPriceListPopover onDownload={onDownloadProductsPriceList} storageKey={'kitsPriceList'} />
        </>
    );
};

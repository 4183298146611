import { FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../../components/select/problemsFilter';
import { SliderRangeWithManualInput } from '../../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import {NomenclatureEntityTypeCodeEnum} from "../../../../../../server";

export const nomenclaturesListFilters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: 'Тип номенклатуры',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'nomenclatureEntityTypeCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Набор', value: NomenclatureEntityTypeCodeEnum.KIT },
                    { name: 'Продукт и вариант', value: NomenclatureEntityTypeCodeEnum.PRODUCT },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils) :ProblemsFilterProps=> ({
                    typeStatuses: 'all',
                }),
            },
            {
                label: LocalizationEnum.PAGE__NEW_OPERATION__TAB__WAREHOUSE__FILTER__UNIT_PRICE,
                id: 'pricePerShift',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                },
            },
        ],
    },
];

import React from 'react';
import {ActionsPopoverV2} from "../../../components/v2/actionsPopover/ActionsPopoverV2";
import {SystemIcon, SystemIconType} from "../../../components/v2/systemIcon/systemIcon";
import {LocalizationEnum, localize} from "../../../localization";

interface Props {
    onAction: (action: string) => void;
    children: React.ReactNode;
}

export const OperationRecordActionsPopover = ({onAction, children}: Props) => {
    return (
        <ActionsPopoverV2 items={[
            {
                title: localize(LocalizationEnum.ASPECT__ACTION__COMMENT, 'span'),
                icon: <SystemIcon type={SystemIconType.edit} />,
                onClick: () => onAction('edit')
            }
        ]}>
            {children}
        </ActionsPopoverV2>
    );
};

import React from 'react';
import { Col, Row } from 'antd';
import { CurrentSubrentIndicatorsTabProblems } from './currentSubrentIndicatorsTabProblems';
import { CurrentRentIndicatorsEntity, CurrentRentIndicatorsEntityType } from './currentRentIndicatorsBlock';
import { useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { canViewFinancialData } from '../../shared/util/permissionUtils';
import { DataDisplayItem, DataDisplayItemType } from '../dataDisplay';

interface IProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: CurrentRentIndicatorsEntity;
}

export const CurrentSubrentIndicatorsTab = (props: IProps) => {
    const showFinancialData = useSelector((store: IRootState) => canViewFinancialData(store.permissions.permissions));

    let sum = 0;
    if (props.entity && props.entity.rentIndicators)
        sum =
            props.entity.rentIndicators.currentSubrent.sum +
            props.entity.rentIndicators.currentSubrentShipmentBooked.sum +
            props.entity.rentIndicators.totalSubrentReturnedToShipper.sum;

    if (props.entityType === 'shipping') {
        sum = props.entity['sum'];
    } else if (props.entityType === 'renter') {
        sum = props.entity['allSubrentsSum'];
    }

    return (
        <Row className={'currentRentIndicators'}>
            <Col span={24}>
                <CurrentSubrentIndicatorsTabProblems
                    entity={props.entity}
                    entityType={props.entityType}
                    showFinancialData={showFinancialData}
                />
                {showFinancialData && props.entityType === 'shipping' && (
                    <Row>
                        <Col>
                            <DataDisplayItem type={DataDisplayItemType.Money} label={'Стоимость'} value={sum} />
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};

import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {matchPath, Route, RouteComponentProps} from 'react-router';
import {RouteConfigComponentProps} from 'react-router-config';
import {push} from 'connected-react-router';
import {Card, Icon} from 'antd';
import CustomFieldsModuleList from './documentTemplatesModuleList';
import {Link} from 'react-router-dom';
import RoundButton from '../../../../components/button/roundButton';
import {IconPlus} from '../../../../components/icons';
import {LocalizationEnum, localize} from '../../../../localization';
import {reset} from './reducers/documentTemplates.reducer';
import CustomFieldsEditModal from './documentTemplatesEditModal';
import {getPathFromState} from '../../../../shared/util/utils';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {loadEntity} from './reducers/documentTemplate.reducer';
import {serverApi} from '../../../../server';
import {SettingsPageWrapper} from "../settingsPageWrapper";

interface IProps
    extends StateProps,
        DispatchProps,
        RouteComponentProps,
        RouteConfigComponentProps,
        WrappedComponentProps {}

interface IState {
    common?: boolean;
}

class DocumentTemplatesModule extends React.PureComponent<IProps, IState> {
    constructor(porops: IProps) {
        super(porops);
        this.state = {};
    }

    componentWillUnmount = () => {
        this.props.reset();
    };

    componentDidMount = async () => {
        if (this.props.entityId) {
            const res = await serverApi.listDocumentTemplates(
                this.props.businessAccountId,
                1,
                0,
                [`id;EQ;${this.props.entityId}`],
                undefined,
                undefined,
                undefined,
                true
            );
            if (res.data.records && res.data.records[0]) {
                const common = res.data.records[0].common;
                this.setState({ common });
                this.props.loadEntity(this.props.businessAccountId, common, this.props.entityId);
            }
        }
    };

    componentDidUpdate = async (prevProps: IProps) => {
        if (this.props.entityId && prevProps.entityId !== this.props.entityId) {
            const res = await serverApi.listDocumentTemplates(
                this.props.businessAccountId,
                1,
                0,
                [`id;EQ;${this.props.entityId}`],
                undefined,
                undefined,
                undefined,
                true
            );
            if (res.data.records && res.data.records[0]) {
                const common = res.data.records[0].common;
                this.setState({ common });
                this.props.loadEntity(this.props.businessAccountId, common, this.props.entityId);
            }
        }
    };

    render() {
        let modalBackPath = getPathFromState(
            `/${this.props.businessAccountId}/settings/documentTemplates`,
            this.props.location.search,
            {
                mode: undefined,
                id: undefined,
            }
        );
        let createPath = getPathFromState(
            `/${this.props.businessAccountId}/settings/documentTemplates/new`,
            this.props.location.search
        );

        return (
            <SettingsPageWrapper title={'Шаблоны документов'} extra={
                <Link to={createPath}>
                    <RoundButton colorScheme={'success'}>
                        <Icon component={IconPlus} />
                        <span>Создать шаблон</span>
                    </RoundButton>
                </Link>
            }>
                <CustomFieldsModuleList intl={this.props.intl} />

                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/documentTemplates/new`}
                    render={(props) => (
                        <CustomFieldsEditModal
                            intl={this.props.intl}
                            title={'Создание шаблона документа'}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            goBackPath={modalBackPath}
                        />
                    )}
                />

                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/documentTemplates/${this.props.entityId}/edit`}
                    render={(props) =>
                        this.props.entity ? (
                            <CustomFieldsEditModal
                                initialValues={{
                                    ...this.props.entity,
                                    common: this.state.common,
                                    file: { name: 'Файл шаблона документа' },
                                }}
                                intl={this.props.intl}
                                title={'Редактирование шаблона документа'}
                                goBackPath={modalBackPath}
                                entityId={this.props.entityId}
                            />
                        ) : null
                    }
                />
            </SettingsPageWrapper>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: any) => {
    let entityId;
    let matchProps = matchPath(ownProps.location.pathname, ownProps.route) as any;
    if (matchProps && matchProps.params && matchProps.params.id) {
        entityId = matchProps.params.id;
    }

    return {
        businessAccountId: storeState.system.businessAccountId,
        entity: storeState.documentTemplate.entity,
        entityId: entityId,
    };
};

const mapDispatchToProps = { push, reset, loadEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentTemplatesModule));

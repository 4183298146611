import React, {FC} from 'react';
import {ContactPersonAPIObj, ProjectInfoRead} from "../../../../../../../../server";
import {ContactPersonBlock} from "../../../../../../../../components/contactPersonBlock/contactPersonBlock";

interface ProjectPageDescriptionContactsBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionContactsBlock:FC<ProjectPageDescriptionContactsBlockProps> = ({data}) => {

    let contData: ContactPersonAPIObj[];

    //if (data) {
        if (data.hasOwnContacts) {
            contData = data.contacts;
        } else {
            contData = data.renterContacts;
        }
    //} else {
    //    contData = [];
    //}

    const contPerson = contData.map((item: ContactPersonAPIObj, index: number) => (
        <ContactPersonBlock data={item} hasOwnContacts={data.hasOwnContacts} key={index} />
    ));

    return (
        <>
            {contPerson}
        </>
    );
};

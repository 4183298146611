import { TimetableObj, TimetableTypeCodeEnum } from '../../../../../../server';

export type AccessibilityMapCalendarData = {
    typeCode: TimetableTypeCodeEnum;
    groupIndex: number;
    value: number;
    startTime: number;
    endTime: number;
};

export const getAccessibilityMapCalendarDataFromBase64 = (accessibilityMapList: TimetableObj[]): AccessibilityMapCalendarData[] => {
    const items = [] as AccessibilityMapCalendarData[];

    accessibilityMapList.forEach(({ typeCode, mapString }, mapIndex) => {
        const timestampsFragmentsString = decodeURIComponent(escape(atob(mapString)));
        const timestampsFragments = timestampsFragmentsString.split(';').filter(Boolean);

        let timestampStart = timestampsFragments.splice(0, 1)[0];
        let lastValue: string | undefined = undefined;
        let lastTimestampEnd: string = timestampStart;

        timestampsFragments.forEach((timestamp, timestampIndex, timestampArray) => {
            const [value, timestampEnd] = timestamp.split(',');

            if (value !== lastValue && lastValue != null) {
                items.push({
                    groupIndex: mapIndex,
                    typeCode,
                    value: Number(lastValue),
                    startTime: +timestampStart,
                    endTime: +lastTimestampEnd,
                });
                timestampStart = lastTimestampEnd;
            }

            if (timestampIndex === timestampArray.length - 1) {
                items.push({
                    groupIndex: mapIndex,
                    typeCode,
                    value: Number(value),
                    startTime: +timestampStart,
                    endTime: +timestampEnd,
                });
            }

            lastValue = value;
            lastTimestampEnd = timestampEnd;
        });
    });

    return items;
};

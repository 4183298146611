import { VehicleInfoRead } from '../../../../../../../server';
import { Button, Icon, Popover } from 'antd';
import { IconArchive, IconEdit, IconLevelUp, IconTrash, IconTruckSolid } from '../../../../../../../components/icons';
import { EntityDrawerArchiveBadge } from '../../../../../../../components/v2/entityDrawer/components/ArchiveBadge';
import { RoundButton } from '../../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { AdditionalEntityActionsButton } from '../../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import React, { FC } from 'react';
import { VehicleDrawerProps } from './VehicleDrawer';

interface VehicleDrawerTitleProps extends Pick<VehicleDrawerProps, 'editVehicle' | 'deleteVehicle' | 'archiveVehicle' | 'onClose'> {
    vehicle?: VehicleInfoRead;
    actionPopoverVisible: boolean;
    setActionPopoverVisible: (visible: boolean) => void;
    isLoading: boolean;
}

export const VehicleDrawerTitle: FC<VehicleDrawerTitleProps> = ({
    vehicle,
    actionPopoverVisible,
    setActionPopoverVisible,
    editVehicle,
    deleteVehicle,
    archiveVehicle,
    onClose,
    isLoading,
}) => {
    return vehicle ? (
        <div className={'entity-drawer-header'}>
            <div className={'title-block'}>
                <Icon className={'title-icon'} component={IconTruckSolid} />
                <span className={'title'}>Транспорт</span>
                {vehicle?.archive ? <EntityDrawerArchiveBadge /> : null}
            </div>
            <div>
                {editVehicle ? (
                    <RoundButton
                        colorScheme={'default'}
                        onClick={() => {
                            editVehicle(vehicle);
                        }}
                        disabled={isLoading}
                    >
                        <Icon component={IconEdit} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                    </RoundButton>
                ) : undefined}
                <span style={{ marginLeft: '12px' }}>
                    <Popover
                        onVisibleChange={(visible) => {
                            setActionPopoverVisible(visible);
                        }}
                        visible={actionPopoverVisible}
                        overlayClassName={'rr-grid-actions-popover'}
                        autoAdjustOverflow
                        arrowPointAtCenter
                        placement="bottomLeft"
                        trigger="click"
                        content={
                            <>
                                <div className={'rr-grid-actions-popover-header1'} />
                                <div
                                    className={'rr-grid-actions-popover-content'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionPopoverVisible(false);
                                    }}
                                >
                                    <>
                                        {deleteVehicle ? (
                                            <Button
                                                disabled={!vehicle.deleteable}
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    deleteVehicle(vehicle).then(() => onClose());
                                                }}
                                            >
                                                <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                <span>Удалить</span>
                                            </Button>
                                        ) : undefined}
                                        {vehicle.archivable && !vehicle.archive && archiveVehicle ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveVehicle(vehicle);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-TO_ARCHIVE-color'} component={IconArchive} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                        {vehicle.archive && archiveVehicle ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveVehicle(vehicle);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                    </>
                                </div>
                            </>
                        }
                    >
                        {<AdditionalEntityActionsButton disabled={isLoading} />}
                    </Popover>
                </span>
            </div>
        </div>
    ) : null;
};

import { EntityActions, EntityActionsHook } from '../../../../components/page/entityGrid/hooks/useRowActions';
import {ProjectInfoRead, ProjectTransitionCodeEnum, TransportationInfoRead} from '../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { loadOffer } from '../reducers/offer/offer.reducer';
import { URLDrawerParams } from '../../../../components/page/entityGrid/types/params';
import { PageURLParamDescriptions } from '../../../../core/utils/descriptions';
import { push } from 'connected-react-router';
import { includeOrExcludeOfferInProjectPriceBulk, setStatusForOffers } from '../reducers/offers/offers.reducer';
import { OfferActionType } from '../data/offersData';

type OfferEntityActions = EntityActions<ProjectInfoRead>;

export const useEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const editOfferCallback: OfferEntityActions['editAction'] = useCallback(
        (offer: ProjectInfoRead, options) => {
            dispatch(
                loadOffer({
                    businessAccountId,
                    entityId: offer.id,
                })
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.delete('modal');
            searchParams.append('modal', modalType);
            searchParams.delete('id');
            searchParams.append('id', String(offer.id));

            if (options?.withView != null) {
                if (options.withView) {
                    const booleanParam = PageURLParamDescriptions.booleanParam.toString(options.withView);
                    if (booleanParam) {
                        searchParams.append('view', booleanParam);
                    }
                } else {
                    searchParams.delete('view');
                }
            }
            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const setStatusForOfferCallback = useCallback(
        ({ id, businessVersion }: ProjectInfoRead, transitionCode: ProjectTransitionCodeEnum) => {
            dispatch(
                setStatusForOffers({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id,
                                businessVersion,
                                transitionCode,
                            },
                        ],
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const includeOrExcludeOfferInProjectPriceBulkCallback = useCallback(
        ({ id, businessVersion }: ProjectInfoRead, include: boolean) => {
            dispatch(
                includeOrExcludeOfferInProjectPriceBulk({
                    businessAccountId,
                    include,
                    versionedEntityObjList: {
                        entities: [{ id, businessVersion }],
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const copyOfferCallback: OfferEntityActions['copyActionForDrawer'] = useCallback(
        (transportation: ProjectInfoRead, options) => {
            editOfferCallback(transportation, { copy: true, ...options });
        },
        [editOfferCallback]
    );

    const onRowAction: OfferEntityActions['onRowAction'] = useCallback(
        (item: ProjectInfoRead, action: any) => {
            // Тут было EntityActionType, а надо ExpenseEntityActionType, пока any
            const actionHandler: Partial<Record<typeof action, Function>> = {
                [OfferActionType.edit]: () => editOfferCallback(item),
                [OfferActionType.duplicate]: () => copyOfferCallback(item),
                [OfferActionType.approve]: () => setStatusForOfferCallback(item, ProjectTransitionCodeEnum.APPROVE),
                [OfferActionType.toCoordination]: () => setStatusForOfferCallback(item, ProjectTransitionCodeEnum.TOCOORDINATION),
                [OfferActionType.returnToDraft]: () => setStatusForOfferCallback(item, ProjectTransitionCodeEnum.RETURNTODRAFT),
                [OfferActionType.cancel]: () => setStatusForOfferCallback(item, ProjectTransitionCodeEnum.CANCEL),
                [OfferActionType.includeOfferInProjectPrice]: () => includeOrExcludeOfferInProjectPriceBulkCallback(item, true),
                [OfferActionType.excludeOfferInProjectPrice]: () => includeOrExcludeOfferInProjectPriceBulkCallback(item, false),
            };
            actionHandler[action]?.();
        },
        [editOfferCallback, includeOrExcludeOfferInProjectPriceBulkCallback, setStatusForOfferCallback]
    );

    return useMemo(
        () => ({
            editAction: editOfferCallback,
            onRowAction,
        }),
        [editOfferCallback, onRowAction]
    );
}) satisfies EntityActionsHook<ProjectInfoRead>;

import {LabeledValue} from "antd/lib/select";
import * as React from "react";
import {isDefined} from "./utils";

export const getLabeledValueNumberKey = (value: any): number|undefined => {
    if(isDefined(value)){
        if(typeof value === 'object' && 'key' in value) return +value.key;
        return +value;
    }else return undefined;
};

/**
 *
 * @param value
 */
export const valueAsLabeledValue = (value:any):LabeledValue|undefined => {
    return value as LabeledValue;
};

/**
 *
 * @param value
 */
export const getLabeledValueKey = (value:any):string|undefined => {
    return value && value.key !== undefined ? value.key : undefined;
};

/**
 *
 * @param value
 */
export const toNumber = (value:string|undefined):number|undefined => {
    return value !== undefined ? +value : undefined;
};

/**
 *
 * @param key
 * @param label
 */
export const createLabeledValue = (key:number, label:React.ReactNode):LabeledValue => {
    return {
        key: '' + key,
        label: label
    };
};

/**
 * Форматирует число с использованием нотации с фиксированной точкой,
 * если оно содержит десятичные знаки.
 *
 * @param {number} num - Число
 * @param {number} digits - Количество знаков после запятой
 * @returns {string} Строка с указанной точностью
 */
export const toOptionalFixed = (num: number, digits: number) =>
    `${Number.parseFloat(num.toFixed(digits))}`;
import { ColumnTypes, TableColumn } from '../../../components/grid/Table';
import React from 'react';
import { BusinessAccountRecord, BusinessAccountTypeCodeEnum , TariffCodeEnum } from '../../../server/api';
import { LocalizationEnum, localize } from '../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH, MAX_WIDTH } from '../../../config/constants';
import DashboardDate from '../../../components/dashboardDate/dashboardDate';
import { getTariffByCode,getBusinessAccountTypeByCode, getRentIndustryByCode } from '../../../shared/util/utils4';
import { Icon } from 'antd';
import { IconClone, IconDollarSignSolid, IconHourglassHalfSolid, IconMicroscopeSolid } from '../../../components/icons';
import moment from 'moment';
import { getDateString } from '../../../shared/util/getDateString';
import { SystemIcon, SystemIconType } from '../../../components/v2/systemIcon/systemIcon';
import { BusinessAccountRecordActionsPopover } from './businessAccountRecordActionsPopover';
import { GridColumnCreator } from '../../../components/grid/gridColumnCreator';

// GRID COLUMNS
export const businessAccountsPageColumns: TableColumn<BusinessAccountRecord>[] = [
    GridColumnCreator.createImageColumn(),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
        dataIndex: 'shortName',
        type: ColumnTypes.CustomRender,
        minWidth: 200,
        //maxWidth: MAX_WIDTH(200),
        resizable: true,
        render: (value: any, rowData: BusinessAccountRecord) => {
            return (
                <div className={'rr-grid-shortName-cell'} style={{ textAlign: 'left', fontWeight: 600 }} title={rowData.shortName}>
                    <a target={'_blank'} href={`/${rowData.id}/settings/businessAccountProfile`} rel="noreferrer">
                        {rowData.shortName}
                    </a>
                </div>
            );
        },
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
        dataIndex: 'stateCode',
        type: ColumnTypes.Status,
        width: 130,
        maxWidth: MAX_WIDTH(130),
        resizable: false,
    },
    {
        title: 'Тип',
        dataIndex: 'typeCode',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        //maxWidth: MAX_WIDTH(170),
        resizable: true,
        render: (value: any, rowData: BusinessAccountRecord) => {
            let icon;
            if (rowData.typeCode === BusinessAccountTypeCodeEnum.DEMOTEMPLATE)
                icon = <Icon component={IconClone} style={{ fontSize: 21, color: '#343f9a', marginRight: 12 }} />;
            else if (rowData.typeCode === BusinessAccountTypeCodeEnum.SANDBOX)
                icon = <Icon component={IconMicroscopeSolid} style={{ fontSize: 21, color: '#a0a0a0', marginRight: 12 }} />;
            else if (rowData.typeCode === BusinessAccountTypeCodeEnum.TRIAL)
                icon = <Icon component={IconHourglassHalfSolid} style={{ fontSize: 21, color: '#fc8c2f', marginRight: 12 }} />;
            else if (rowData.typeCode === BusinessAccountTypeCodeEnum.PRODUCTION)
                icon = <Icon component={IconDollarSignSolid} style={{ fontSize: 21, color: '#34bfa3', marginRight: 12 }} />;
            return (
                <div
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginLeft: 20,
                        //justifyContent: 'center'
                    }}
                >
                    {icon}
                    {getBusinessAccountTypeByCode(rowData.typeCode)}
                </div>
            );
        },
    },
    {
        title: 'Тариф',
        dataIndex: 'tariff',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: true,
        render: (v: TariffCodeEnum) => getTariffByCode(v),
    },
    {
        title: 'Отрасль',
        dataIndex: 'rentIndustryCode',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: true,
        render: (value: any, rowData: BusinessAccountRecord) => {
            return getRentIndustryByCode(rowData.rentIndustryCode);
        },
    },
    {
        title: 'Лимит пользователей',
        dataIndex: 'allRolesUserCountLimit',
        type: ColumnTypes.String,
        minWidth: 146,
        //maxWidth: MAX_WIDTH(146),
        resizable: false,
    },
    {
        title: 'Лимит менеджеров',
        dataIndex: 'managerRolesUserCountLimit',
        type: ColumnTypes.String,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
    },
    // {
    //     type: ColumnTypes.String,
    //     minWidth: 5,
    //     resizable: false,
    //     sortable: false
    // },
    {
        title: 'Триальное время',
        dataIndex: 'trialPeriodProlongedUntil',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        //maxWidth: 150,
        resizable: false,
        sortable: false,
        render: (value: any, rowData: BusinessAccountRecord) => {
            if (rowData.typeCode === BusinessAccountTypeCodeEnum.TRIAL) {
                let style: React.CSSProperties | undefined;
                if (rowData.trialPeriodProlongedUntil && moment(rowData.trialPeriodProlongedUntil).valueOf() > moment().valueOf()) {
                    style = { fontWeight: 600, color: '#8b5cb1' };
                    return (
                        <div style={style}>
                            Продлен до <br />
                            {moment(rowData.trialPeriodProlongedUntil).format('DD.MM.YYYY')}
                        </div>
                    );
                } else {
                    const diff = moment().diff(rowData.creationDate, 'd');
                    if (diff > 14 && diff <= 30) style = { fontWeight: 600, color: '#FC8C2F' };
                    else if (diff > 30) style = { fontWeight: 600, color: '#f4516c' };
                    return <div style={style}>{getDateString(moment(), rowData.creationDate)}</div>;
                }
            } else {
                return null;
            }
        },
    },
    {
        title: 'Дата создания',
        dataIndex: 'creationDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        //maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: Date, rowData: BusinessAccountRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.CustomRender,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: BusinessAccountRecord, onAction: (record: BusinessAccountRecord, action: string) => void) => {
            return (
                <BusinessAccountRecordActionsPopover
                    record={rowData}
                    onAction={(action) => {
                        onAction(rowData, action);
                    }}
                >
                    <SystemIcon type={SystemIconType.otherActions} className={'rr-grid-table-otherActions-icon'} />
                </BusinessAccountRecordActionsPopover>
            );
        },
    },
];

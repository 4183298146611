import React, {FC} from 'react';
import {OperationIndicatorsBlock} from "../../../../../components/operationIndicatorsBlock/operationIndicatorsBlock";
import {canViewFinancialData} from "../../../../../shared/util/permissionUtils";
import {useAppSelector} from "../../../../../store/hooks";

export const OperationBottomBlock:FC = (props) => {
    const params = useAppSelector((state)=>{
        const {instanceCount, priceBeforeDiscount: price, discountAmount: discount, finalTotalPrice, finalTotalPriceWithTaxes} = state.operationForm;
        const showFinancialData = canViewFinancialData(state.permissions.permissions);
        return {showFinancialData, instanceCount, price, discount, finalTotalPrice, finalTotalPriceWithTaxes};
    });
    return <OperationIndicatorsBlock {...params} editable={true} />;
}

import {IRouterItem} from "../../../config/routes";
import UserProfileDescription from "./user-profile-description";
import React from "react";
import UserProfileNotification from "./notifications/user-profile-notifications";
import NotFoundModule from "../settings/notFound/notFoundModule";

export const userProfileRoutes: Array<IRouterItem> = [
    {
        menuKey: 'description',
        title: 'Описание',
        path: '/:businessAccountId/userProfile/description',
        component: UserProfileDescription,
        sortOrder: 0
    },
    {
        menuKey: 'notifications',
        title: 'Настройки уведомлений',
        path: '/:businessAccountId/userProfile/notifications',
        component: UserProfileNotification,
        sortOrder: 0
    },
    {
        menuKey: 'notfound',
        path: `/:businessAccountId/userProfile/`,
        component: NotFoundModule,
        sortOrder: 0
    }
];

import { FC, useContext } from 'react';
import { ActivityFrameItemContext } from '../../../../../../../../context/CalendarItemContext';
import { ActivitySubrentBaseBorder } from '../../../Borders/activityFrames/subrent/ActivitySubrentBaseBorder';
import {DraftBorder} from "../../../Borders/common/DraftBorder";

interface ActivityFramesBordersProps {
    lineWidth: number;
}

export const ActivityFramesBorders: FC<ActivityFramesBordersProps> = (props) => {
    const { lineWidth } = props;
    const { item } = useContext(ActivityFrameItemContext);

    const { elementType, isDraft, start_time, end_time } = item;

    if (isDraft) {
        const border = `1px dashed #000000`;
        return (
            <DraftBorder
                width={lineWidth}
                border={border}
                isRounded={elementType === 'activitySubrent'}
                start_time={start_time}
                end_time={end_time}
            />
        );
    }

    if (elementType === 'activitySubrent') {
        return <ActivitySubrentBaseBorder />;
    }

    return null;
};

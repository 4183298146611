import { PaymentMethodRecord } from '../../../../../../server';
import { tblColumns, TblColumnType } from '../../../../../../core/tblColumns/tblColumns';
import { paymentMethodActionsMap, PaymentMethodActionType } from '../../data/paymentMethodsData';
import { historyPageUrlRoute } from '../../../../history/HistoryPage';
import { getPathFromState } from '../../../../../../shared/util/utils';

import { HistoryPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

export const paymentMethodsColumns = tblColumns<PaymentMethodRecord, PaymentMethodActionType>([
    {
        type: TblColumnType.ENTITY_NAME,
        title: 'Наименование',
        dataIndex: 'name',
        entityType: 'paymentMethod',
        getData: (row) => ({
            name: row.name,
        }),
    },
    {
        type: TblColumnType.BOOLEAN,
        title: 'Способ по умолчанию',
        dataIndex: 'isDefault',
        minWidth: 130,
    },
    {
        type: TblColumnType.LINK,
        title: 'Число платежей',
        dataIndex: 'cachedPaymentsLinkedNumber',
        minWidth: 130,
        getUrl: ({ businessAccountId, id, cachedPaymentsLinkedNumber }) => {
            if (!cachedPaymentsLinkedNumber) return undefined;

            return getPathFromState(`/${businessAccountId}/${historyPageUrlRoute}/${HistoryPageTabsEnum.PAYMENTS}`, '', {
                paymentMethodId: id,
            });
        },
    },
    {
        type: TblColumnType.USER,
        title: 'Автор изменения',
        dataIndex: 'lastUpdateAuthorShortName',
        getData: (row) => ({
            businessAccountId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
    },
    {
        type: TblColumnType.DATE,
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
        minWidth: 150,
    },
    {
        type: TblColumnType.SETTINGS_ACTIONS,
        actionsDataMap: paymentMethodActionsMap,
        numberOfIcons: 3,
        getData: (row) => ({
            mainActions: [PaymentMethodActionType.edit, { type: PaymentMethodActionType.delete, disabled: !row.deleteable }],
            additionalActions: [
                row.isDefault ? PaymentMethodActionType.unsetAsDefaultMethod : PaymentMethodActionType.setAsDefaultMethod,
                row.archivable && !row.archive ? PaymentMethodActionType.toArchive : undefined,
                row.archive ? PaymentMethodActionType.returnFromArchive : undefined,
            ],
        }),
    },
]);

import {OffloadingDocumentOnDownload} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import React, {useCallback} from "react";
import {downloadOffloading} from "../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {EntityLifecycleEventNotFinancialFieldsTypeCode, ExcelExportTemplateTypeCodeEnum} from "../../../../../../server";
import {ExportOffloadingPopover} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {useEntityGridContext} from "../../../../../../components/page/entityGrid/components/context/GridRefContext";
import {useAppSelector} from "../../../../../../store/hooks";
import {canViewFinancialDataSelector} from "../../../../../../shared/reducers/permissions.reducer";
import {businessAccountIdSelector} from "../../../../../../shared/reducers/system.reducer";
import {useIntl} from "react-intl";
import {EntityGridURLParams} from "../../../../../../components/page/entityGrid/types/params";
import {ListLifecycleEventsFilters} from "../api/lifecycleEvents.api.types";
import {LifecycleEventsApiUtils} from "../api/lifecycleEvents.api.utils";

export const useExportBlock = (pageParams: EntityGridURLParams<ListLifecycleEventsFilters>, excludeColumns: string[] | undefined) => {
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const gridRef = useEntityGridContext();

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy } = pageParams;
            const filters = LifecycleEventsApiUtils.createRequestFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.ENTITYLIFECYCLEEVENTTEMPLATE,
                fileName: 'Экспорт журнала действий',
                fileType,
                filters,
                financialFieldsEnum: EntityLifecycleEventNotFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: EntityLifecycleEventNotFinancialFieldsTypeCode,
                selection: [],
                values: options,
                sortOrder,
                sortBy,
                excludeColumns: undefined
            });
        },
        [businessAccountId, canViewFinancialData, gridRef, intl, pageParams]
    );

    return <ExportOffloadingPopover storageKey={'lifecycleEventsOffloading'} onDownload={onOffloadingDownload} />;
};

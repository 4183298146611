import React from 'react';
import {LocalizationEnum} from '../../localization';
import {
    InstanceStateCodeEnum,
    OperationTypeCodeEnum,
    PaymentStateCodeEnum,
    ProjectStateCodeEnum,
    RentStateCodeEnum,
    SubrentStateCodeEnum,
} from '../../server/api';
import {FormattedMessage} from 'react-intl';
import {EntityType} from '../../../index';
import './status.less';
import {paymentsUtils} from '../../modules/main/payments/utils/paymentsUtils';

export type StatusStateCode =
    | 'ACTIVE'
    | 'NEW'
    | 'BLOCKED'
    | 'NOT_AVAILABLE'
    | 'FINISHED'
    | 'IN_PROGRESS'
    | 'INVITED'
    | RentStateCodeEnum
    | SubrentStateCodeEnum
    | 'CUSTOM_FIELD_STANDARD'
    | 'CUSTOM_FIELD_NOT_STANDARD'
    | 'UNDER_MAINTENANCE'
    | 'DECOMMISSIONED'
    | 'OTHER'
    | OperationTypeCodeEnum
    | 'SUBRENT'
    | InstanceStateCodeEnum
    | ProjectStateCodeEnum.CANCELED
    | ProjectStateCodeEnum.RENTED
    | ProjectStateCodeEnum.BOOKED
    | ProjectStateCodeEnum.COORDINATION
    | 'PLANNED';

interface IProps {
    code?: StatusStateCode | 'UNDER_MAINTENANCE_BORDERED';
    style?: React.CSSProperties | undefined;
    entityType: EntityType;
    customText?: string;
}

export class Status extends React.PureComponent<IProps> {
    render() {
        let { code, entityType, style, customText } = this.props;

        let id;
        let type: string | undefined;
        if (entityType === 'product') {
            if (code === 'NEW') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW;
            } else if (code === 'ACTIVE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE;
            } else if (code === 'NOT_AVAILABLE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE;
            }
        } else if (entityType === 'renter' || entityType === 'businessAccount') {
            if (code === 'NEW') {
                id = LocalizationEnum.ASPECT__STATE_CODE__RENTER__NEW;
            } else if (code === 'ACTIVE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__RENTER__ACTIVE;
            } else if (code === 'BLOCKED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__RENTER__BLOCKED;
            }
        } else if (entityType === 'project') {
            if (code === 'PLANNED') {
                id = 'Запланировано';
            } else if (code === 'IN_PROGRESS') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS;
            } else if (code === 'FINISHED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED;
            } else if (code === 'CANCELED') {
                id = 'Отменен';
            } else if (code === 'DRAFT') {
                id = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT;
            } else if (code === ProjectStateCodeEnum.BOOKED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED;
            } else if (code === ProjectStateCodeEnum.RENTED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT;
            } else if (code === ProjectStateCodeEnum.COORDINATION) {
                id = 'Согласование';
            }
        } else if (entityType === 'transportation') {
            id = LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS;
        } else if (entityType === 'subrent') {
            if (code === 'IN_PROGRESS') {
                id = LocalizationEnum.ASPECT__STATE_CODE__SHIPPING__INPROGRESS;
            } else if (code === 'FINISHED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__SHIPPING__FINISHED;
            } else if (code === 'DRAFT') {
                id = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT;
            } else if (code === 'CANCELED') {
                id = 'Отменена';
            }
        } else if (entityType === 'element') {
            if (code === RentStateCodeEnum.ORDERED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED;
            } else if (code === RentStateCodeEnum.BOOKED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED;
            } else if (code === RentStateCodeEnum.RENT) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT;
            } else if (code === RentStateCodeEnum.RETURNED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED;
            } else if (code === RentStateCodeEnum.RETURNEDBROKEN) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED_BROKEN;
            } else if (code === RentStateCodeEnum.LOSTDURINGRENT) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__LOST_DURING_RENT;
            } else if (code === RentStateCodeEnum.CANCELED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__CANCELED;
            } else if (code === 'OTHER' || code === 'MIXED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER;
            } else if (code === RentStateCodeEnum.DRAFT) {
                id = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT;
            } else if (code === RentStateCodeEnum.SUBRENTDRAFT) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_DRAFT;
            } else if (code === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_SHIPMENT_BOOKED;
            } else if (code === RentStateCodeEnum.SUBRENT) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT;
            } else if (code === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_RETURNED_TO_SHIPPER;
            } else if (code === RentStateCodeEnum.SUBRENTCANCELED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_CANCELED;
            } else {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED;
            }
        } else if (entityType === 'user') {
            if (code === 'ACTIVE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__USER__ACTIVE;
            } else if (code === 'NEW') {
                id = LocalizationEnum.ASPECT__STATE_CODE__USER__NEW;
            } else if (code === 'BLOCKED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__USER__BLOCKED;
            } else if (code === 'INVITED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__USER__INVITED;
            }
        } else if (entityType === 'product-instances') {
            type = 'instance';
            if (code === InstanceStateCodeEnum.NEW) {
                id = LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__NOT_IN_STOCK;
            } else if (code === InstanceStateCodeEnum.ACTIVE) {
                id = LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__ACTIVE;
            } else if (code === InstanceStateCodeEnum.UNDERMAINTENANCE || code === 'UNDER_MAINTENANCE_BORDERED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__UNDERMAINTENANCE;
            } else if (code === InstanceStateCodeEnum.DECOMMISSIONED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__DECOMMISSIONED;
            } else if (code === 'SUBRENT') {
                id = 'Субаренда';
            } else if (code === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED) {
                id = 'Списан чужой';
            } else if (code === InstanceStateCodeEnum.SUPPLIERACTIVE) {
                id = 'Активный чужой';
            } else {
                id = LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__NOT_IN_STOCK;
            }
        } else if (entityType === 'kit' || entityType === 'kits-product' || entityType === 'kit-composition') {
            if (code === 'NEW') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW;
            } else if (code === 'ACTIVE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE;
            } else if (code === 'NOT_AVAILABLE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE;
            }
        } else if (entityType === 'operation') {
            if (code === RentStateCodeEnum.ORDERED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED;
            } else if (code === RentStateCodeEnum.BOOKED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED;
            } else if (code === RentStateCodeEnum.RENT) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT;
            } else if (code === RentStateCodeEnum.RETURNED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED;
            } else if (code === RentStateCodeEnum.RETURNEDBROKEN) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED_BROKEN;
            } else if (code === RentStateCodeEnum.LOSTDURINGRENT) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__LOST_DURING_RENT;
            } else if (code === RentStateCodeEnum.CANCELED) {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__CANCELED;
            } else if (code === RentStateCodeEnum.DRAFT) {
                id = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT;
            } else if (code === 'AUTOMATIC_RULE') {
                id = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__AUTOMATIC_RULE;
            } else if (code === 'MIXED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER;
            } else if (code === RentStateCodeEnum.SUBRENT) id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT;
            else if (code === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED)
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_SHIPMENT_BOOKED;
            else if (code === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER)
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_RETURNED_TO_SHIPPER;
            else if (code === RentStateCodeEnum.SUBRENTCANCELED) id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_CANCELED;
            else if (code === RentStateCodeEnum.SUBRENTDRAFT) id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_DRAFT;
            else {
                id = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER;
            }
        } else if (entityType === 'payment') {
            type = 'payment';
            id = paymentsUtils.getPaymentStateByCode(code as PaymentStateCodeEnum);
        } else if (entityType === 'profession' || entityType === 'crewMember') {
            if (code === 'ACTIVE') {
                id = LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE;
            } else if (code === 'BLOCKED') {
                id = LocalizationEnum.ASPECT__STATE_CODE__USER__BLOCKED;
            }
        }

        if (!id) console.error('Status localization id not found');

        return id ? (
            <FormattedMessage id={id}>
                {(txt) => (
                    <span className={`rr-status rr-status-bg-${type ? type + '-' : ''}${code}`} style={{ ...style }}>
                        {customText ?? txt}
                    </span>
                )}
            </FormattedMessage>
        ) : (
            <></>
        );
    }
}

import { OnDragEndResponder } from 'react-beautiful-dnd';
import { reorderDragItems } from '../utils/reorderDragItems';
import { moveDragItems } from '../utils/moveDragItems';
import { DragGroup } from '../types/types';

export const useOnDragEnd = (groups: DragGroup[], setGroups: (groups: DragGroup[]) => void): OnDragEndResponder => {
    return (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sourceIndex = +source.droppableId;
        const destinationIndex = +destination.droppableId;

        if (sourceIndex === destinationIndex) {
            const items = reorderDragItems(groups[sourceIndex].fields, source.index, destination.index);
            const newGroups = [...groups];
            newGroups[sourceIndex].fields = items;
            setGroups(newGroups);
        } else {
            const result = moveDragItems(groups[sourceIndex].fields, groups[destinationIndex].fields, source, destination);
            const newGroups = [...groups];
            newGroups[sourceIndex].fields = result[sourceIndex];
            newGroups[destinationIndex].fields = result[destinationIndex];
            setGroups(newGroups);
        }
    };
};

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { WorkPlanningsInfoRead, WorkPlanningsInfoUpdate } from '../../../../../server';
import { IRootState } from '../../../../../shared/reducers';
import { ModalActionData } from '../../../../../components/modalForm/utils';
import { connect } from 'react-redux';
import { workPlanningsModalFormFields } from '../workPlanningsModalFormFields';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { goBack } from 'connected-react-router';
import { resetErrors } from '../../../renters/reducers/renters/renter.reducer';
import { updateWorkPlanning } from '../../reducers/workPlanning/workPlanning.reducer';
import { WorkPlanningCreateModalFormData } from '../create/workPlanningsCreateModal.types';
import moment from 'moment';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    workPlanning: WorkPlanningsInfoRead;
}

export class _WorkPlanningEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: workPlanningsModalFormFields,
    };

    getInitialValues = () => {
        const { workPlanning } = this.props;

        let initialValues: Partial<WorkPlanningCreateModalFormData> = {
            id: workPlanning.id,
            name: workPlanning.name,
            professionId: workPlanning.professionId,
            state: workPlanning.stateCode,
            numberOfCrew: workPlanning.numberOfCrew,
            crewMemberIds: workPlanning.crewMemberIds,
            dates: [moment(workPlanning.startDate), moment(workPlanning.endDate)],
            shiftCount: workPlanning.shiftCount,
            comment: workPlanning.comment,
            projectId: workPlanning.projectId,
            pricingScheme: workPlanning.effectivePricingScheme,
            basePrice: workPlanning?.basePriceAtTheTimeOfCreation,
            effectivePrice: workPlanning?.effectivePrice,
        };

        return {
            ...initialValues,
            ...this.props.initialValues,
        };
    };

    onOk = (data: unknown) => {
        const formData = data as WorkPlanningCreateModalFormData;

        const workPlanningsInfoUpdate: WorkPlanningsInfoUpdate = {
            id: formData.id || 0,
            businessVersion: this.props.workPlanning.businessVersion,
            comment: formData.comment,
            name: formData.name,
            startDate: formData.dates?.[0]?.toDate() || new Date(),
            endDate: formData.dates?.[1]?.toDate() || new Date(),
            numberOfCrew: formData.numberOfCrew,
            crewMemberIds: formData.crewMemberIds,
            shiftCount: formData.shiftCount || 0,
            effectivePrice: formData.effectivePrice || 0,
            effectivePricingSchemeId: formData.pricingScheme?.id,
            stateCode: formData.state,
            professionId: formData.professionId,
        };

        this.props.updateWorkPlannings({
            businessAccountId: this.props.businessAccountId,
            id: this.props.workPlanning.id,
            workPlanningsInfoUpdate: workPlanningsInfoUpdate,
        });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.workPlanning.updating,
    updatingError: storeState.workPlanning.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    updateWorkPlannings: updateWorkPlanning,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const WorkPlanningsEditModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_WorkPlanningEditModal));

import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { Icon } from 'antd';
import { DocumentTemplatesModuleUtils } from './documentTemplatesModuleUtils';

export const filters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__TYPE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'documentType',
                type: FormItemType.Select,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                values: (form: WrappedFormUtils) => {
                    const list = DocumentTemplatesModuleUtils.getDocumentTemplateCodes();

                    return list.map((item) => {
                        return {
                            name: (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon
                                        style={{
                                            marginRight: 10,
                                            fontSize: 21,
                                            color: DocumentTemplatesModuleUtils.getDocumentTemplateIconColorByCode(item),
                                        }}
                                        component={DocumentTemplatesModuleUtils.getDocumentTemplateIconComponentByCode(item)}
                                    />
                                    {DocumentTemplatesModuleUtils.getDocumentTemplateTypeByCode(item)}
                                </div>
                            ),
                            value: item,
                        };
                    });
                },
            },
            {
                label: 'Скрыть стандартные шаблоны',
                id: 'hideStandardTemplates',
                type: FormItemType.Switch,
            },
        ],
    },
];

import { ProfessionInfoRead } from '../../../../../../../../server';
import { memo } from 'react';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { ProfessionEditModal } from '../EditModal/ProfessionEditModal';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { EntityGridEditModalWrapper } from '../../../../../../../../components/page/entityGrid/types/modal';

export const ProfessionEditModalWrapper: EntityGridEditModalWrapper<ProfessionInfoRead> = memo(({ entity, onOkCallback }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <ProfessionEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование специальности'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            profession={entity}
            onOkCallback={onOkCallback}
        ></ProfessionEditModal>
    );
});

export const CALENDAR_LINE_HEIGHT_RATIO = 1;
export const CALENDAR_LINE_HEIGHT_NORMAL = 26;
export const CALENDAR_LINE_HEIGHT_COMPACT = 20;

export const CALENDAR_GROUP_HEIGHT_BASE_NORMAL = 36;
export const CALENDAR_GROUP_HEIGHT_BASE_COMPACT = 26;
export const CALENDAR_GROUP_HEIGHT_LARGE_NORMAL = 42;
export const CALENDAR_GROUP_HEIGHT_LARGE_COMPACT = 36;

export const CALENDAR_LINE_FONT_SIZE_NORMAL = 14;
export const CALENDAR_LINE_FONT_SIZE_COMPACT = 11;

export const CALENDAR_LINE_BASE_RADIUS = 2;
export const CALENDAR_LINE_ROUND_RADIUS = 12;

export const CALENDAR_SIDEBAR_WIDTH_SMALL = 190;
export const CALENDAR_SIDEBAR_WIDTH_MEDIUM = 280;

export enum CalendarVisibleItemsLimit {
    FIRST_LIMIT = 100,
    SECOND_LIMIT = 1000,
}

export enum CalendarDisplayType {
    NORMAL = 'normal',
    COMPACT = 'compact',
}

import React from 'react';
import { Alert, Icon, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import { deleteEntity, loadEntity, reset, setStatusAndArchive } from './reducers/product.reducer';
import { IRootState } from '../../../../shared/reducers';
import { Spin } from '../../../../components';
import ProductCreateModal from './modal/components/ProductCreateModal/ProductCreateModal';
import { showConfirm, showConfirm2 } from '../../../../components/confirm/showConfirm';
import ProductChangeCountModal from './modal/components/ProductChangeCountModal/ProductChangeCountModal';
import './ProductPage.less';
import { BasePage, IBasePageProps } from '../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../components/pageHeader/pageHeader';
import { getPathFromState, getStateFromPath, isStringInEnum } from '../../../../shared/util/utils';
import getParamsLink from '../../../../shared/util/getParamsLink';
import { logEvent } from '../../../../analytics/analytics';
import { InstanceTrackingTypeCodeEnum, NomenclatureStateCodeEnum, ProductInfoRead, SimpleTransitionCodeEnum } from '../../../../server';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { ProductPageFeaturesTabPane } from './tabs/features/product-page-features-tab';
import ProductPageInstancesTabPane from '../instances/product-page-instances-tab';
import { ProductPageVariantsTab } from '../variants/product-page-variants-tab';
import { includes } from '../../../../shared/util/utils2';
import { resetOperationForm } from '../../operationForm/reducers/operationForm.reducer';
import ChangeProductTypeModal from './modal/components/ChangeProductTypeModal/ChangeProductTypeModal';
import { EntityCardActionsBlock } from '../../../../components/entityCardActionsBlock/EntityCardActionsBlock';
import { InventoryMovementsList } from '../../inventoryMovements/list/inventoryMovementsList';
import { createProjectFilters, editProjectFilters } from './modal/components/ProductCreateModal/ProductCreateModalData';
import { InterfaceBlockCode } from '../../../../components/pageHeader/components/InfoSelectDropdown/utils/constants';
import { getGridStorageData } from '../../../../components/grid/utils';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../shared/reducers/userSettings/userSettings.reducer';
import { statisticsModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ReportReferenceTypeEnum } from '../../../../components/pageHeader/ReportSelectDropdown';
import { GridName } from '../../../../components/grid/utils/types';
import { ProductPageDescriptionTabNew } from './components/ProductPageDescription/ProductPageDescription';
import { InfoSelectDropdown } from '../../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown';
import { LinkToCalendar } from '../../../../components/link/toCalendar/LinkToCalendar';
import { IconCalendarCheck } from '../../../../components/icons';
import { DateUtils } from '../../../../core/utils/dateUtils';
import moment from 'moment';
import { AccessibilityMapFilters } from '../../calendar/tabs/accessibilityMap/filters/accessibility/accessibilityMapCalendarFilters.types';
import { CalendarPageTabsEnum, HistoryPageTabsEnum, ProductPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { ProductPageHeaderNavigationBlock } from './components/HeaderNavigationBlock/ProductPageHeaderNavigationBlock';

interface IState {}

interface IProps extends StateProps, DispatchProps, IBasePageProps {
    deviceType: 'phone' | 'tablet' | 'desktop';
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const defaultTab = ProductPageTabsEnum.DESCRIPTION;

class ProductPage extends BasePage<IProps, IState> {
    private tabs = {};
    private entityLoaded: boolean = false;

    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        if (this.currentTab === ProductPageTabsEnum.INVENTORY_MOVEMENTS) {
            this.props.replace(
                this.getTabRedirectPath(
                    ProductPageTabsEnum.INVENTORY_MOVEMENTS,
                    'productInventoryMovements',
                    false,
                    this.props.location.search
                )
            );
        }
        this.logViewEvent();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    onTabsChange = (key: string) => {
        this.tabs[this.props.tab] = this.props.location.search;
        let searchString = this.getSearchString(key as ProductPageTabsEnum);
        saveCurrentTabToStore('product', key);
        if (key === HistoryPageTabsEnum.INVENTORY_MOVEMENTS) {
            this.props.push(this.getTabRedirectPath(key, 'productInventoryMovements', true));
        } else {
            this.props.push(getPathFromState(this.props.location.pathname, searchString, { tab: key }));
        }
    };

    onSetCountButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/inventory/products/${this.props.entityId}/edit-count`);
    };

    onActivateButtonClick = () => {
        let { entity } = this.props;
        if (entity)
            this.props.setStatusAndArchive(this.props.intl, this.props.businessAccountId, entity.id, entity.businessVersion, 'ACTIVATE');
    };

    onLockButtonClick = () => {
        let { entity } = this.props;
        //TODO Нормальный текст предупреждения придумать
        showConfirm(
            this.props.intl,
            localizeIntl(this.props.intl, LocalizationEnum.PAGE__PRODUCTS__MODAL__QUESTIONS__BLOCK),
            undefined,
            () => {
                if (entity)
                    this.props.setStatusAndArchive(
                        this.props.intl,
                        this.props.businessAccountId,
                        entity.id,
                        entity.businessVersion,
                        'BLOCK'
                    );
            }
        );
    };

    onChangeTypeButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/inventory/products/${this.props.entityId}/changeType`);
    };

    onUnlockButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            this.props.setStatusAndArchive(this.props.intl, this.props.businessAccountId, entity.id, entity.businessVersion, 'ACTIVATE');
        }
    };

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/inventory/products/${this.props.entityId}/edit`);
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/inventory/products/${this.props.entityId}/copy`);
    };

    onArchiveButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            if (!entity.archive) {
                //TODO Нормальный текст предупреждения придумать
                showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__PRODUCTS__MODAL__QUESTIONS__ARCHIVE),
                    undefined,
                    () => {
                        if (entity)
                            this.props.setStatusAndArchive(
                                this.props.intl,
                                this.props.businessAccountId,
                                entity.id,
                                entity.businessVersion,
                                undefined,
                                true
                            );
                    }
                );
            } else {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    entity.id,
                    entity.businessVersion,
                    undefined,
                    false
                );
            }
        }
    };

    onDeleteButtonClick = async () => {
        let yes = await showConfirm2({
            intl: this.props.intl,
            title: 'Удалить безвозвратно продукт со всеми его вариантами и экземплярами?',
        });
        if (yes) this.props.deleteEntity(this.props.businessAccountId, this.props.entity!.id, this.props.entity!.businessVersion);
    };

    onDeleteVariants = (variantsIds: number[]) => {
        const instancesSearch = this.tabs[ProductPageTabsEnum.INSTANCES];
        const instancesSearchParams = new URLSearchParams(instancesSearch);

        if (variantsIds.some((variantId) => String(variantId) === instancesSearchParams.get('variantId'))) {
            instancesSearchParams.delete('variantId');
            this.tabs[ProductPageTabsEnum.INSTANCES] = instancesSearchParams.toString();
        }
    };

    getPageHeaderProps(): IPageHeaderProps {
        const { entity, statisticsModuleEnabled } = this.props;

        const [screenLeft, screenRight] = DateUtils.getCalendarBoundaries(moment(Date.now()), 'month');

        const gridData = getGridStorageData(CalendarPageTabsEnum.ACCESSIBILITY_MAP);
        const { stacks } = gridData.filters as AccessibilityMapFilters;

        return {
            archive: Boolean(entity && entity.archive),
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            shortName: !Boolean(entity && entity.fullName),
            parentPagePath: entity ? `/${this.props.businessAccountId}/inventory/products` : undefined,
            rightBlock: (
                <>
                    <LinkToCalendar
                        calendarPath={getPathFromState(`/${this.props.businessAccountId}/calendar`, `${getParamsLink(entity, 'product')}`, {
                            tab: CalendarPageTabsEnum.ACCESSIBILITY_MAP,
                            screenLeft,
                            screenRight,
                            hide: 'cancelled',
                            stacks,
                        })}
                        text={'Расписание'}
                        icon={<Icon component={IconCalendarCheck} style={{ marginTop: -1, color: '#34bfa3' }} />}
                    />
                    <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_INVENTORY_PRODUCT} />
                </>
            ),
            reportReferenceType: statisticsModuleEnabled ? ReportReferenceTypeEnum.PRODUCT : undefined,
            reportReferenceEntityId: statisticsModuleEnabled ? this.props.entityId : undefined,
            NavigationBlock: <ProductPageHeaderNavigationBlock product={entity} />,
        };
    }

    logViewEvent = () => {
        logEvent({ type: 'view product', data: { 'entity id': this.props.entityId, tab: ProductPageTabsEnum.DESCRIPTION } });
    };

    getSearchString(key: ProductPageTabsEnum) {
        return key === ProductPageTabsEnum.FEATURES ||
            key === ProductPageTabsEnum.INSTANCES ||
            key === ProductPageTabsEnum.VARIANTS ||
            key === ProductPageTabsEnum.INVENTORY_MOVEMENTS
            ? this.tabs[key] || ''
            : '';
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    getTabRedirectPath = (tabName: string, gridName: GridName, fromStorage: boolean = false, search = ''): string => {
        const params = Array.from(new URLSearchParams(this.props.location.search).keys());
        const getParamsFromStorage = fromStorage || params.length === 0 || (params.length === 1 && params[0] === 'tab');
        const gridData = getParamsFromStorage ? getGridStorageData(gridName) : undefined;
        return getPathFromState(this.props.location.pathname, search, {
            tab: tabName,
            ...gridData?.filters,
            ...gridData?.params,
        });
    };

    renderContent() {
        let { entity, loading, businessAccountId, entityId } = this.props;
        const { currentTab } = this;

        if (entity) this.entityLoaded = true;

        if (currentTab == null && this.entityLoaded && this.props.tabFromStore === ProductPageTabsEnum.INVENTORY_MOVEMENTS) {
            return <Redirect to={this.getTabRedirectPath(ProductPageTabsEnum.INVENTORY_MOVEMENTS, 'productInventoryMovements')} />;
        }

        if (currentTab == null && this.entityLoaded) {
            if (
                (this.props.tabFromStore === ProductPageTabsEnum.VARIANTS &&
                    !includes(
                        [InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED, InstanceTrackingTypeCodeEnum.VARIANTBULK],
                        entity?.instanceTrackingTypeCode
                    )) ||
                (this.props.tabFromStore === ProductPageTabsEnum.INSTANCES &&
                    !includes(
                        [InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED, InstanceTrackingTypeCodeEnum.INSTANCETRACKED],
                        entity?.instanceTrackingTypeCode
                    ))
            ) {
                return (
                    <Redirect to={getPathFromState(this.props.location.pathname, this.getSearchString(defaultTab), { tab: defaultTab })} />
                );
            }

            const tab = isStringInEnum(this.props.tabFromStore, ProductPageTabsEnum, defaultTab);

            return <Redirect to={getPathFromState(this.props.location.pathname, this.getSearchString(tab), { tab })} />;
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        if (!businessAccountId || !entityId) return <Redirect to={'/'} />;

        return (
            <>
                <Spin spinning={loading}>
                    <div className={'rr-page-product'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}

                        {entity ? (
                            <div className="card-container">
                                <Tabs
                                    activeKey={this.props.tab}
                                    onChange={this.onTabsChange}
                                    className={'rr-tabs'}
                                    type="line"
                                    animated={false}
                                    tabBarExtraContent={
                                        <EntityCardActionsBlock
                                            onSetAmount={
                                                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK
                                                    ? this.onSetCountButtonClick
                                                    : undefined
                                            }
                                            onEdit={this.onEditButtonClick}
                                            onFromArchive={entity.archive ? this.onArchiveButtonClick : undefined}
                                            onActivate={
                                                entity.availableTransitionCodes!.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                                                entity.stateCode === NomenclatureStateCodeEnum.NEW
                                                    ? this.onActivateButtonClick
                                                    : undefined
                                            }
                                            onUnlock={
                                                entity.availableTransitionCodes!.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                                                entity.stateCode === NomenclatureStateCodeEnum.NOTAVAILABLE
                                                    ? this.onUnlockButtonClick
                                                    : undefined
                                            }
                                            onCopy={this.onCopyButtonClick}
                                            onToArchive={entity.archivable && !entity.archive ? this.onArchiveButtonClick : undefined}
                                            onLock={
                                                entity.availableTransitionCodes!.includes(SimpleTransitionCodeEnum.BLOCK)
                                                    ? this.onLockButtonClick
                                                    : undefined
                                            }
                                            onChangeType={this.onChangeTypeButtonClick}
                                            changeTypeDisabled={entity.hasAnyRentElements}
                                            onDelete={this.onDeleteButtonClick}
                                            deleteDisabled={!entity.deleteable}
                                        />
                                    }
                                >
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                        key={ProductPageTabsEnum.DESCRIPTION}
                                    >
                                        <ProductPageDescriptionTabNew
                                            responsive={this.props.responsive}
                                            deviceType={this.props.deviceType}
                                            updateParentEntity={() => {
                                                this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
                                            }}
                                        />
                                    </Tabs.TabPane>
                                    {includes(
                                        [InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED, InstanceTrackingTypeCodeEnum.VARIANTBULK],
                                        entity.instanceTrackingTypeCode
                                    ) ? (
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.PAGE__PRODUCTS__CARD__VARIANTS)}
                                            key={ProductPageTabsEnum.VARIANTS}
                                        >
                                            <ProductPageVariantsTab
                                                productId={this.props.entityId}
                                                location={this.props.location}
                                                onDeleteVariants={this.onDeleteVariants}
                                            />
                                        </Tabs.TabPane>
                                    ) : null}
                                    {includes(
                                        [InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED, InstanceTrackingTypeCodeEnum.INSTANCETRACKED],
                                        entity.instanceTrackingTypeCode
                                    ) ? (
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCES)}
                                            key={ProductPageTabsEnum.INSTANCES}
                                        >
                                            <ProductPageInstancesTabPane />
                                        </Tabs.TabPane>
                                    ) : null}
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FEATURES)}
                                        key={ProductPageTabsEnum.FEATURES}
                                    >
                                        <ProductPageFeaturesTabPane />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={'Движение'} key={ProductPageTabsEnum.INVENTORY_MOVEMENTS}>
                                        {this.props.entity && (
                                            <InventoryMovementsList gridName={'productInventoryMovements'} product={this.props.entity} />
                                        )}
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        ) : null}
                    </div>
                </Spin>

                {entity && this.props.editMode ? (
                    <ProductCreateModal
                        title={localize(LocalizationEnum.PAGE__PRODUCTS__FORM__FORM_TITLE__EDIT)}
                        businessAccountId={businessAccountId}
                        initialValues={entity as ProductInfoRead | undefined}
                        customFieldGroups={entity.customFieldGroups}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                        filters={editProjectFilters}
                        modalName={'edit-product'}
                    />
                ) : null}

                {entity && this.props.copyMode ? (
                    <ProductCreateModal
                        title={localize(LocalizationEnum.PAGE__PRODUCTS__FORM__FORM_TITLE__CREATE)}
                        businessAccountId={businessAccountId}
                        initialValues={entity as ProductInfoRead | undefined}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        copyMode={true}
                        validateAfterCreate={true}
                        filters={createProjectFilters}
                        modalName={'create-product'}
                        customFieldGroups={entity.customFieldGroups}
                    />
                ) : null}

                {this.props.editCountMode ? <ProductChangeCountModal businessAccountId={businessAccountId} initialValues={entity} /> : null}

                {entity != null && (
                    <Route
                        exact
                        path={`/${this.props.businessAccountId}/inventory/products/:id/changeType`}
                        render={(props) => <ChangeProductTypeModal businessAccountId={businessAccountId} initialValues={entity!} />}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.product.entity,
        loading: storeState.product.loading,
        loadingError: storeState.product.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: Boolean(storeState.product.entity && ownProps.match.params['action'] === 'edit'),
        copyMode: Boolean(storeState.product.entity && ownProps.match.params['action'] === 'copy'),
        editCountMode: Boolean(storeState.product.entity && ownProps.match.params['action'] === 'edit-count'),
        tab:
            searchParams.tab &&
            (searchParams.tab === ProductPageTabsEnum.DESCRIPTION ||
                searchParams.tab === ProductPageTabsEnum.FEATURES ||
                searchParams.tab === ProductPageTabsEnum.INSTANCES ||
                searchParams.tab === ProductPageTabsEnum.VARIANTS ||
                searchParams.tab === ProductPageTabsEnum.INVENTORY_MOVEMENTS)
                ? searchParams.tab
                : ProductPageTabsEnum.DESCRIPTION,
        tabFromStore: getCurrentTabsSelector(storeState)?.['product'],
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { loadEntity, deleteEntity, setStatusAndArchive, reset, push, replace, resetOperationForm };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

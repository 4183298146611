import React, { useEffect, useState } from 'react';
import { Button, Form, Icon } from 'antd';
import './assets/styles/main.less';
import './assets/styles/registration.less';
import {
    BusinessAccountModuleCodeEnum,
    BusinessAccountRegistrationObj,
    CountryCodeEnum,
    RentIndustryCodeEnum,
    serverApi,
} from '../../server';
import { showNotification } from '../../components/notification/showNotification';
import { useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import logo from './assets/images/logo-black.png';
import { LocalizationEnum, localize, localizeIntl } from '../../localization';
import { injectIntl } from 'react-intl';
import { IRootState } from '../../shared/reducers';
import userManager from '../../config/userManager';
import { hidePreloader } from '../../App';
import { rrLogout } from '../../shared/util/rrLogout';
import { IconSignOutAltSolid } from '../../components/icons';
import { canCreateBusinessAccount } from '../../shared/util/permissionUtils';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { RegistrationStepOne } from './components/steps/RegistrationStepOne/RegistrationStepOne';
import { RegistrationStepTwo, StepTwoRegistrationAnswers } from './components/steps/RegistrationStepTwo/RegistrationStepTwo';
import { getPathFromState } from '../../shared/util/utils';
import { RegistrationStepThree } from './components/steps/RegistrationStepThree/RegistrationStepThree';
import { ObjectUtils } from '../../core/utils/objectUtils';
import { getBusinessAccountPreferences } from '../../shared/reducers/businessAccountPreferences.reducer';
import { getCurrentTimezoneUTCOffset } from '../../utils/timeUtils/getCurrentTimezoneUTCOffset';

const Component = (props) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const urlParams = Object.fromEntries(new URLSearchParams(location.search).entries());

    const [nameValueInput, setNameValueInput] = useState<string | undefined>(undefined);
    const [phoneValueInput, setPhoneValueInput] = useState<string | undefined>(undefined);
    const [promocodeValueInput, setPromocodeValueInput] = useState(undefined as string | undefined);
    const [demoData, setDemoData] = useState<boolean | undefined>(undefined);
    const [industriesValueOk, setIndustriesValueOk] = useState(false);
    const [demoValueOk, setDemoValueOk] = useState(false);
    const [industriesValueInput, setIndustriesValueInput] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const oidcUser = useSelector((state: IRootState) => state.oidc.user);
    const [canRegister, setCanRegister] = useState(false);
    const [stepTwoAnswers, setStepTwoAnswers] = useState<StepTwoRegistrationAnswers>({
        [BusinessAccountModuleCodeEnum.LOGISTICS]: false,
        [BusinessAccountModuleCodeEnum.CREW]: false,
        [BusinessAccountModuleCodeEnum.SUBRENT]: false,
        [BusinessAccountModuleCodeEnum.OFFERS]: false,
    });
    const [countryCode, setCountryCode] = useState<CountryCodeEnum | undefined>(CountryCodeEnum.RU);

    const { step } = urlParams;

    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.remove('custom-scrollbar');
        }

        serverApi
            .getCurrentRolesAndPermissions()
            .then((response) => {
                if (canCreateBusinessAccount(response.data.globalPermissions)) {
                    setCanRegister(true);
                    hidePreloader();
                } else {
                    // Тут нужно получить список БА
                    serverApi
                        .listBusinessAccounts(1)
                        .then((response) => {
                            if (response.data.records.length > 0) {
                                const baId = response.data.records[0].id;
                                window.location.href = `/${baId}`;
                            } else {
                                setCanRegister(true);
                                hidePreloader();
                            }
                        })
                        .catch((e) => {
                            window.location.href = `/`;
                        });
                }
            })
            .catch((e) => {
                window.location.href = `/`;
            });

        return function cleanup() {
            if (body) body.classList.add('custom-scrollbar');
        };
    }, []);

    useEffect(() => {
        if (step == null || isNaN(Number(step)) || Number(step) !== 1) {
            dispatch(replace(`/companyRegistration?step=1`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentStep = Number(step);

    const onCreateCompany = () => {
        if (countryCode == null || nameValueInput == null || phoneValueInput == null || demoData == null) return;

        const sp = new URLSearchParams(window.location.search);
        const source = sp.get('utm_source');
        const data: BusinessAccountRegistrationObj = {
            shortName: nameValueInput.trim(),
            rentIndustryCode: RentIndustryCodeEnum[industriesValueInput ?? ''],
            fillWithDemoContent: demoData,
            phoneNumber: phoneValueInput,
            promoCode: promocodeValueInput,
            uTMDataString: source === null ? undefined : source,
            countryCode,
        };
        setLoading(true);
        serverApi
            .registerBusinessAccount(getCurrentTimezoneUTCOffset(), data)
            .then(async (res) => {
                await userManager.signinSilent();
                const disabledKeys = Object.keys(stepTwoAnswers).filter(
                    (key) => stepTwoAnswers[key] === false
                ) as (keyof typeof stepTwoAnswers)[];
                const disabledModules = ObjectUtils.filterByKeys(stepTwoAnswers, disabledKeys, 'extract');

                try {
                    await serverApi.enableOrDisableBusinessAccountModuleBulk(res.data.id, {
                        records: Object.keys(disabledModules).map((key) => ({
                            moduleCode: key as BusinessAccountModuleCodeEnum,
                            enable: false,
                            businessVersion: res.data.businessVersion,
                        })),
                    });
                } catch (err) {}

                setLoading(false);
                dispatch(getBusinessAccountPreferences(res.data.id, true));
                dispatch(push(`/${res.data.id}`));
            })
            .catch((err) => {
                if (err?.response?.status === 409) {
                    showNotification('error', 'Промокод уже использован');
                } else if (err?.response?.status === 404) {
                    showNotification('error', 'Некорректный промокод');
                } else {
                    showNotification(
                        'error',
                        localizeIntl(
                            props.intl,
                            LocalizationEnum.PAGE__COMPANY_REGISTRATION__POPUP_NOTIFICATIONS__FAILED_TO_REGISTER_BUSINESS_ACCOUNT
                        )
                    );
                }
                setLoading(false);
            });
    };

    const changeStep = (value: 2 | 3) => {
        dispatch(
            push(
                getPathFromState(location.pathname, location.search, {
                    step: value,
                })
            )
        );
    };

    return oidcUser && canRegister ? (
        <div className="auth-page register">
            <main className="main-wrapper">
                <header className="login-header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="container rr-company-registration-logo-div">
                            <a href="http://rentrabb.it/">
                                <img src={logo} className="rr-company-registration-logo-img" alt="logo" />
                            </a>
                            <div className="header_wrap d-flex"></div>
                        </div>
                        <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', right: '50px' }}>
                            <Button className={'rr-company-registration-exit'} onClick={() => rrLogout()}>
                                <span className="rr-company-registration-exit-icon">
                                    <Icon component={IconSignOutAltSolid} style={{ fontSize: '18px' }} />
                                </span>
                                {localize(LocalizationEnum.ASPECT__CURRENT_USER_PROFILE_POPUP__SIGN_OUT)}
                            </Button>
                        </div>
                    </div>
                </header>
                <main className="login-main">
                    <section className="section-register">
                        <div className="section-bg"></div>
                        <div className="container">
                            <h2 className="section-title white">{localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__LABEL)}</h2>
                            {currentStep === 1 && (
                                <RegistrationStepOne
                                    onChangeStep={() => changeStep(2)}
                                    nameValueInput={nameValueInput}
                                    setNameValueInput={setNameValueInput}
                                    phoneValueInput={phoneValueInput}
                                    setPhoneValueInput={setPhoneValueInput}
                                    promocodeValueInput={promocodeValueInput}
                                    setPromocodeValueInput={setPromocodeValueInput}
                                    demoData={demoData}
                                    setDemoData={setDemoData}
                                    industriesValueInput={industriesValueInput}
                                    setIndustriesValueInput={setIndustriesValueInput}
                                    industriesValueOk={industriesValueOk}
                                    setIndustriesValueOk={setIndustriesValueOk}
                                    demoValueOk={demoValueOk}
                                    setDemoValueOk={setDemoValueOk}
                                    countryCode={countryCode}
                                    setCountryCode={setCountryCode}
                                />
                            )}
                            {currentStep === 2 && (
                                <RegistrationStepTwo
                                    stepTwoAnswers={stepTwoAnswers}
                                    setStepTwoAnswers={setStepTwoAnswers}
                                    onChangeStep={() => changeStep(3)}
                                />
                            )}
                            {currentStep === 3 && (
                                <RegistrationStepThree
                                    stepTwoAnswers={stepTwoAnswers}
                                    onCreateCompany={onCreateCompany}
                                    loading={loading}
                                />
                            )}
                        </div>
                        <div className="geometry-1"></div>
                        <div className="geometry-2"></div>
                    </section>
                </main>
                <footer className="login-footer">
                    <div className="container">
                        <div className="copyright" style={{ color: '#383f4d' }}>
                            2018 - {new Date().getFullYear()} © RentRabbit. {localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__ALL_RIGHTS_RESERVED)}
                        </div>
                    </div>
                </footer>
            </main>
        </div>
    ) : null;
};

export const CompanyRegistration = Form.create({ name: 'coordinated' })(injectIntl(Component));

import React from 'react';
import {Popover} from "../../../../../../components/popover/Popover";
import {Icon} from "antd";
import {IconEdit} from "../../../../../../components/icons";
import {OperationElement} from "../../../reducers/operationForm.reducer";
import {VariantSelectPopoverContent} from "./variantSelectPopoverContent";
import './variantSelectPopover.less';

interface IProps {
    element: OperationElement;
}

interface IState {
    visible: boolean;
}

export class VariantSelectPopover extends React.Component<IProps, IState> {

    private confirmModalVisible = false;

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    handleVisibleChange = (visible: boolean) => {
        if (!this.confirmModalVisible) this.setState({visible});
    };

    onConfirmModalVisibleChanged = (visible: boolean) => {
        this.confirmModalVisible = visible;
    };

    render() {
        return (
            <Popover
                destroyTooltipOnHide
                overlayClassName={'rr-operationForm-variant-select-popover'}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
                content2={
                    <VariantSelectPopoverContent
                        element={this.props.element}
                        onConfirmModalVisibleChanged={this.onConfirmModalVisibleChanged}
                        onOk={()=>{
                            this.setState({visible: false});
                        }
                    }/>
                }>
                <Icon className={'rr-operationForm-variant-select-icon'} component={IconEdit}
                      style={{opacity: this.state.visible ? 1 : undefined}}/>
            </Popover>
        );
    }
}

import React, {CSSProperties} from 'react';
import {RentElementResolutionCodeEnum} from "../../../server";
import {Icon as IconComponent} from "antd";
import classNames from "classnames";
import {CustomIconComponentProps} from "antd/lib/icon";
import {IconNounBreak, IconSearchMinusSolid} from "../../../components/icons";
import './rentElementResolutionIcon.less';

interface InventoryMovementIconProps {
    code: RentElementResolutionCodeEnum;
    style?: CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const RentElementResolutionIcon = ({code, ...props}: InventoryMovementIconProps) => {

    let component: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined;
    let className: string | undefined = `rr-icon-rentElementResolution-${code}`;

    if (code === RentElementResolutionCodeEnum.RETURNEDBROKEN) {
        component = IconNounBreak;
    } else if (code === RentElementResolutionCodeEnum.LOSTDURINGRENT) {
        component = IconSearchMinusSolid;
    }

    return <IconComponent className={classNames('rr-icon-', className, props.className)} style={props.style}
                          component={component} onClick={props.onClick}/>;
};

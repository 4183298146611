import React from "react";
import ReactQuill from "react-quill";
import * as Quill from "quill";
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.less';

interface IProps {
    value?: string | Quill.Delta;
    onChange?: (
        content: string,
    ) => void;
}

let Block = (Quill as any).import("blots/block");
Block.tagName = 'DIV';
(Quill as any).register(Block, true);

let Link = (Quill as any).import('formats/link');

class CustomLink extends Link {

    static sanitize(url) {
        let value = super.sanitize(url);
        if (value) {
            for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++)
                if(value.startsWith(CustomLink.PROTOCOL_WHITELIST[i]))
                    return value;
            return `http://${value}`;
        }
        return value;
    }

    static create(value) {
        const node = super.create(value);
        node.setAttribute('target', '_blank');
        return node;
    }

}
(Quill as any).register(CustomLink);

export class RichTextEditor extends React.PureComponent<IProps> {

     onChange = (value: string) => {
         if(this.props.onChange) this.props.onChange(value);
     };

     render() {
         const modules = {
             toolbar: [
                 [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                 ['bold', 'italic', 'underline', 'strike'],
                 [{ 'color': [] }, { 'background': [] }],
                 [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],
                 ['link'],
                 [],
                 ['clean']
             ]
         };
         const formats = [
             'background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block',
         ];

        return (
            <ReactQuill
                modules={modules}
                formats={formats}
                className={'rr-rich-text-editor'}
                theme="snow" value={this.props.value || ''}
                onChange={this.props.onChange} />
        );
     }
}

import { TransportationInfoRead } from '../../../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { TransportationEditModal } from './TransportationEditModal';
import React, { memo } from 'react';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { editTransportationModalFilters } from '../../data/TransportationModalDisplayData';
import { EntityGridEditModalWrapper } from '../../../../../../../../components/page/entityGrid/types/modal';

export const TransportationEditModalWrapper: EntityGridEditModalWrapper<TransportationInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <TransportationEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование перевозки'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            transportation={entity}
            filters={editTransportationModalFilters}
            modalName={'edit-transportation'}
        />
    );
});

import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { serverApi, VariantRecord } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';

export interface ProductVariantSelectProps extends BaseSelectBaseProps<VariantRecord> {
    productId: number;
}

const ProductVariantSelectFC = ({ productId, ...props }: ProductVariantSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listProductVariants(
            businessAccountId,
            productId,
            undefined,
            0,
            [`id;IN;${ids}`],
            'lastActivityDate'
        ); // TODO Без sortBy возвращает ошибку: "Задано направление сортировки, но не задано поле сортировки"
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        const response = await serverApi.listProductVariants(
            businessAccountId,
            productId,
            limit,
            startFrom,
            undefined,
            'lastActivityDate',
            'DESC',
            search
        );
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.name,
                children: record.name,
            })}
        />
    );
};

export class ProductVariantSelect extends React.Component<ProductVariantSelectProps> {
    render() {
        return <ProductVariantSelectFC {...this.props} />;
    }
}

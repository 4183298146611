import React, { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
    ExpenseInfoRead,
    ExpenseRecord,
    ExpenseStateCodeEnum,
    ExpenseTransitionCodeEnum,
    ProjectInfoRead,
    ProjectTypeCodeEnum,
    VersionedEntityObjList,
} from '../../../../server';
import { push } from 'connected-react-router';
import { loadExpense } from '../reducers/expense/expense.reducer';
import { setStatusForExpenses } from '../reducers/expenses/expenses.reducer';
import { _Grid } from '../../../../components/grid/Grid';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { ActionButtonsGroup } from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { expenseEntityActionButtons, ExpenseEntityActionType } from '../data/expensesData';
import { PageURLParamDescriptions } from '../../../../core/utils/descriptions';
import { EntityActions, EntityActionsHook } from '../../../../components/page/entityGrid/hooks/useRowActions';
import { URLDrawerParams } from '../../../../components/page/entityGrid/types/params';
import { Button, Icon } from 'antd';
import { IconArrowRightSolid, IconCopy } from '../../../../components/icons';
import { expensesApi, useMoveExpensesToAnotherProjectMutation } from '../api/expenses.api';
import AdditionalEntityActions from '../../../../components/additionalEntityActions/additionalEntityActions';
import { AdditionalEntityActionsButton } from '../../../../components/additionalEntityActions/additionalEntityActionsButton';
import RoundButton from '../../../../components/button/roundButton';
import { SelectProjectPopover } from '../../elements/components/SelectProjectPopover/SelectProjectPopover';

type ExpenseEntityActions = EntityActions<ExpenseInfoRead>;

export const useEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const editExpenseCallback: ExpenseEntityActions['editAction'] = useCallback(
        (expense: ExpenseInfoRead, options) => {
            dispatch(
                loadExpense({
                    businessAccountId,
                    entityId: expense.id,
                })
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.delete('modal');
            searchParams.append('modal', modalType);
            searchParams.delete('id');
            searchParams.append('id', String(expense.id));
            searchParams.delete('view');

            if (options?.withView != null) {
                if (options.withView) {
                    const booleanParam = PageURLParamDescriptions.booleanParam.toString(options.withView);
                    if (booleanParam) {
                        searchParams.append('view', booleanParam);
                    }
                }
            }
            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editExpenseForDrawerCallback: ExpenseEntityActions['editActionForDrawer'] = useCallback(
        (expense: ExpenseInfoRead) => {
            editExpenseCallback(expense, { withView: true });
        },
        [editExpenseCallback]
    );

    const copyExpenseCallback: ExpenseEntityActions['copyActionForDrawer'] = useCallback(
        (expense: ExpenseInfoRead, options) => {
            editExpenseCallback(expense, { copy: true, ...options });
        },
        [editExpenseCallback]
    );

    const setStatusForExpenseCallback = useCallback(
        ({ id, businessVersion }: ExpenseInfoRead, transitionCode: ExpenseTransitionCodeEnum) => {
            dispatch(
                setStatusForExpenses({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id,
                                businessVersion,
                                transitionCode,
                            },
                        ],
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const onRowAction: ExpenseEntityActions['onRowAction'] = useCallback(
        (item: ExpenseInfoRead, action: any) => {
            // Тут было EntityActionType, а надо ExpenseEntityActionType, пока any
            const actionHandler: Partial<Record<typeof action, Function>> = {
                [ExpenseEntityActionType.Edit]: () => editExpenseCallback(item),
                [ExpenseEntityActionType.Copy]: () => copyExpenseCallback(item),
                [ExpenseEntityActionType.ToDraft]: () => setStatusForExpenseCallback(item, ExpenseTransitionCodeEnum.TODRAFT),
                [ExpenseEntityActionType.TakeIntoAccount]: () =>
                    setStatusForExpenseCallback(item, ExpenseTransitionCodeEnum.TAKEINTOACCOUNT),
                [ExpenseEntityActionType.Cancel]: () => setStatusForExpenseCallback(item, ExpenseTransitionCodeEnum.CANCEL),
            };
            actionHandler[action]?.();
        },
        [copyExpenseCallback, editExpenseCallback, setStatusForExpenseCallback]
    );

    return useMemo(
        () => ({
            editAction: editExpenseCallback,
            editActionForDrawer: editExpenseForDrawerCallback,
            copyActionForDrawer: copyExpenseCallback,
            copyAction: copyExpenseCallback,
            onRowAction,
        }),
        [copyExpenseCallback, editExpenseCallback, editExpenseForDrawerCallback, onRowAction]
    );
}) satisfies EntityActionsHook<ExpenseInfoRead>;

export const useSetStatusForExpensesCallback = (
    selectedRecords: { id: number; businessVersion: number }[],
    gridRef?: React.RefObject<_Grid>
) => {
    const dispatch = useAppDispatch();
    const { businessAccountId } = useAppSelector((state) => state.system);

    return useCallback(
        (transitionCode: ExpenseTransitionCodeEnum) => {
            const workflowDirectiveBulkDirectives = selectedRecords.map(({ id, businessVersion }) => {
                return {
                    id,
                    businessVersion,
                    transitionCode,
                };
            });

            dispatch(
                setStatusForExpenses({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: workflowDirectiveBulkDirectives,
                    },
                })
            );

            if (gridRef != null) {
                gridRef.current?.clearSelection();
            }
        },
        [businessAccountId, dispatch, gridRef, selectedRecords]
    );
};

export const useEntityActionsButtons = ({
    selectedRecords,
    gridRef,
    parentProjectEntity,
    moveExpensesToAnotherProjectMutation,
}: {
    selectedRecords: ExpenseRecord[];
    gridRef: React.RefObject<_Grid>;
    parentProjectEntity: ProjectInfoRead | undefined;
    moveExpensesToAnotherProjectMutation: ReturnType<typeof useMoveExpensesToAnotherProjectMutation>[0];
}) => {
    const dispatch = useAppDispatch();
    const projectId = useAppSelector((store) => store.project.entity?.id);
    const offerId = useAppSelector((store) => store.offer.entity?.id);
    const [closeMoveElementPopupVisible, setCloseMoveElementPopupVisible] = useState(false);

    const closeMoveElementPopup = useCallback(() => {
        setCloseMoveElementPopupVisible(true);
    }, []);

    const setStatusForExpensesCallback = useSetStatusForExpensesCallback(selectedRecords, gridRef);

    const moveAction = useCallback(
        (targetProjectId: number, copy: boolean, targetProjectType: ProjectTypeCodeEnum | undefined) => {
            const currentProjectId = projectId || offerId;
            if (currentProjectId == null) return;

            const versionedEntityObjList: VersionedEntityObjList = {
                entities: selectedRecords.map((record) => ({
                    id: record.id,
                    businessVersion: record.businessVersion,
                })),
            };

            moveExpensesToAnotherProjectMutation({
                projectId: currentProjectId,
                copy: copy,
                targetProjectId: targetProjectId,
                versionedEntityObjList,
                targetProjectType,
            }).then((res) => {
                if ('data' in res) {
                    gridRef.current?.clearSelection();
                    dispatch(expensesApi.util?.invalidateTags(['ExpensesList']));
                }
            });
        },
        [dispatch, gridRef, moveExpensesToAnotherProjectMutation, offerId, projectId, selectedRecords]
    );

    const stateCodes = useMemo(() => {
        const finished = selectedRecords.some((record) => [ExpenseStateCodeEnum.TAKENINTOACCOUNT].includes(record.state));

        return {
            active: false,
            finished,
        };
    }, [selectedRecords]);

    return useMemo(
        () => [
            <>
                <ActionButtonsGroup
                    data={expenseEntityActionButtons}
                    mainActions={[
                        selectedRecords.every((item) => item.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.CANCEL))
                            ? ExpenseEntityActionType.Cancel
                            : undefined,
                        selectedRecords.every((item) => item.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.TAKEINTOACCOUNT))
                            ? ExpenseEntityActionType.TakeIntoAccount
                            : undefined,
                        selectedRecords.every((item) => item.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.TODRAFT))
                            ? ExpenseEntityActionType.ToDraft
                            : undefined,
                    ]}
                    onAction={(action) => {
                        if (action === ExpenseEntityActionType.Cancel) {
                            setStatusForExpensesCallback(ExpenseTransitionCodeEnum.CANCEL);
                        } else if (action === ExpenseEntityActionType.TakeIntoAccount) {
                            setStatusForExpensesCallback(ExpenseTransitionCodeEnum.TAKEINTOACCOUNT);
                        } else if (action === ExpenseEntityActionType.ToDraft) {
                            setStatusForExpensesCallback(ExpenseTransitionCodeEnum.TODRAFT);
                        }
                    }}
                />
                {parentProjectEntity != null && parentProjectEntity.projectType === ProjectTypeCodeEnum.OFFER && (
                    <RoundButton
                        colorScheme={'success'}
                        onClick={() => {
                            if (parentProjectEntity.mainProjectId == null) return;

                            moveAction(parentProjectEntity.mainProjectId, true, ProjectTypeCodeEnum.BASE);
                        }}
                    >
                        <Icon component={IconCopy} style={{ color: '#FFFFFF' }} />
                        <span>В основной проект</span>
                    </RoundButton>
                )}
                <AdditionalEntityActions
                    disabled={closeMoveElementPopupVisible}
                    isElement
                    overlayStyle={{ minWidth: 320 }}
                    content={
                        <>
                            <SelectProjectPopover
                                stateCodes={stateCodes}
                                closeParentElement={closeMoveElementPopup}
                                projectId={selectedRecords[0]?.projectId}
                                copy={false}
                                onOkCallback={(targetProject) => {
                                    moveAction(targetProject.id, false, targetProject.projectType);
                                }}
                            >
                                <Button block>
                                    <Icon component={IconArrowRightSolid} style={{ color: '#8d75ca' }} />
                                    <span>{'Переместить'}</span>
                                </Button>
                            </SelectProjectPopover>
                            <SelectProjectPopover
                                stateCodes={stateCodes}
                                closeParentElement={closeMoveElementPopup}
                                projectId={selectedRecords[0]?.projectId}
                                copy={true}
                                onOkCallback={(targetProject) => {
                                    moveAction(targetProject.id, true, targetProject.projectType);
                                }}
                            >
                                <Button block>
                                    <Icon component={IconCopy} style={{ color: '#57d6b9' }} />
                                    <span>{'Скопировать'}</span>
                                </Button>
                            </SelectProjectPopover>
                        </>
                    }
                >
                    <AdditionalEntityActionsButton
                        onClick={() => {
                            setCloseMoveElementPopupVisible(false);
                        }}
                    />
                </AdditionalEntityActions>
            </>,
        ],
        [
            closeMoveElementPopup,
            closeMoveElementPopupVisible,
            moveAction,
            parentProjectEntity,
            selectedRecords,
            setStatusForExpensesCallback,
            stateCodes,
        ]
    );
};

type EntityId = number | undefined;
export const useIdForDrawer = () => {
    const [entityIdForDrawer, setEntityIdForDrawer] = useState<EntityId>(undefined);

    const clearEntityId = useCallback(() => {
        setEntityIdForDrawer(undefined);
    }, []);

    return [entityIdForDrawer, setEntityIdForDrawer, clearEntityId] as [EntityId, (entityId: EntityId) => void, () => void];
};

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../../shared/reducers';
import { connect, ConnectedProps } from 'react-redux';
import { showConfirm } from '../../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../../localization';
import { goBack } from 'connected-react-router';
import { resetErrors } from '../../../../../../renters/reducers/renters/renter.reducer';
import { transportationFormFields } from '../../data/TransportationModalFields';
import { updateModalFilters } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { ModalActionData } from '../../../../../../../../components/modalForm/utils';
import { TransportationCreateModalFormData } from './TransportationCreateModal.types';
import { TransportationInfoCreate } from '../../../../../../../../server';
import { createTransportation } from '../../../reducers/transportation/transportation.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    rootPath: string;
    urlSearchParams?: URLSearchParams;
}

export class _TransportationCreateModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: transportationFormFields,
    };

    onOk = (data: unknown) => {
        const formData = data as TransportationCreateModalFormData;

        const transportationInfoCreate: TransportationInfoCreate = {
            carId: Number(formData.car),
            cargo: formData.cargo,
            cargoDeliveryLocationId: Number(formData.cargoDeliveryLocation),
            comment: formData.comment,
            consigneeId: formData.consignee ? Number(formData.consignee) : undefined,
            dateTimeOfLoading: formData.dateTimeOfLoading.clone().utcOffset(0, true).toDate(),
            dateTimeOfUnloading: formData.dateTimeOfUnloading.clone().utcOffset(0, true).toDate(),
            driverIds: formData.driverIds,
            loadingLocationId: Number(formData.loadingLocation),
            projectId: formData.project ? Number(formData.project) : undefined,
            receiverId: Number(formData.receiver),
            recipientId: Number(formData.recipient),
            requestDate: formData.requestDate ? formData.requestDate.format('YYYY-MM-DD') : undefined,
            shipperId: formData.shipper ? Number(formData.shipper) : undefined,
            transportationDate: formData.transportationDate ? formData.transportationDate.format('YYYY-MM-DD') : undefined,
            trailerId: formData.trailer ? Number(formData.trailer) : undefined,
            unloadingLocationId: Number(formData.unloadingLocation),
            stateCode: formData.stateCode,
        };

        this.props.createTransportation(
            {
                businessAccountId: this.props.businessAccountId,
                transportationInfoCreate,
            },
            this.props.rootPath,
            this.props.urlSearchParams
        );
    };

    onCancel = async (isFieldsTouched: boolean) => {
        const yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.transportation.updating,
    updatingError: storeState.transportation.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    createTransportation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const TransportationCreateModal = connector(injectIntl(_TransportationCreateModal));

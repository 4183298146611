import React from 'react';
import {Icon} from "antd";
import {RoundButton} from "../index";
import {IconEllipsisHSolid} from "../icons";
import {ButtonProps} from "antd/lib/button/button";

interface IProps extends ButtonProps {

}

export const AdditionalEntityActionsButton = (props:IProps) => (
    <span style={{height: 40, display: 'inline-block'}}>
        <RoundButton colorScheme={'default'} className="button-three-points" {...props}>
            <Icon component={IconEllipsisHSolid} style={{ fontSize: '36px' }} />
        </RoundButton>
    </span>
);

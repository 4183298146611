import { FormFieldsGroup, FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InstanceTrackingTypeCodeEnum, NomenclatureStateCodeEnum } from '../../../../../server';
import React from 'react';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../components/select/problemsFilter';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { SliderRangeWithManualInput } from '../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { InlineNumberInputColorTheme } from '../../../../../components/inlineInputs/InlineNumberInput/soft/InlineNumberInput';
import { createHiddenField } from '../../../../../components/modalForm/utils';

export const kitsPageFilters: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('productInstanceTrackingTypeCode'),
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'status',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW), value: NomenclatureStateCodeEnum.NEW },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE), value: NomenclatureStateCodeEnum.ACTIVE },
                    {
                        name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE),
                        value: NomenclatureStateCodeEnum.NOTAVAILABLE,
                    },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>{' '}
                                <span className="calendar-multi-select">{item.name}</span>{' '}
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT,
                id: 'finalTotalPrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                className: 'rr-slider-gray',
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                    theme: InlineNumberInputColorTheme.DARK,
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'all',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT,
                id: 'totalDiscount',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    formatStyle: 'percents',
                    min: -100,
                    max: 100,
                    step: 1,
                }),
            },
            {
                label: 'Содержит продукт',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'productId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listProducts',
                    nameField: 'shortName',
                    getEntityOperationName: 'getProductById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                    onEntityDataLoad: (data: any) => {
                        form.setFieldsValue({
                            productInstanceTrackingTypeCode: data?.instanceTrackingTypeCode,
                        });
                    },
                }),
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: async (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        variantId: undefined,
                        productInstanceTrackingTypeCode: value?.data?.instanceTrackingTypeCode,
                    });
                },
            },
            {
                label: 'Содержит вариант',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'variantId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        operationName: 'listProductVariants',
                        nameField: 'name',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductVariantById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                visible: (getFieldValue, form, currentValues) => {
                    const instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.variantId != null ||
                        getFieldValue('variantId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ instanceId: undefined });
                },
            },
        ],
    },
];

import { CategoriesBlockProps, Category, CategoryData } from '../categories-block';
import { WrappedComponentProps } from 'react-intl';
import { loadCategory, selectCategory, updateCategory } from '../reducers/categories.reducer';
import { Icon } from 'antd';
import { useDispatch } from 'react-redux';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { MAX_DEEP_CATEGORIES } from '../../../../config/constants';
import { TreeProps } from 'antd/lib/tree';
import { IconInfoSolid } from '../../../../components/icons';
import { CategoryInfoUpdate, CategoryTypeCodeEnum } from '../../../../server';
import './TreeBySiblingOrder.less';
import { siblingOrderRootNodeValue, TreeBySiblingOrder } from './TreeBySiblingOrder';
import { getParentCategory } from '../utils';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../store/hooks';

declare module 'antd/lib/tree/Tree' {
    interface AntTreeNodeProps {
        dataRef?: Category & { level: number };
    }
}

interface TreeBySiblingOrderProps
    extends Pick<CategoriesBlockProps, 'searchString' | 'typeCode' | 'sortData'>,
        Pick<CategoryData, 'selectedCategoryId'>,
        WrappedComponentProps {
    availableCategories: Category[];
    onExpand: (selectedKeys: string[]) => void;
    expandedKeys: string[];
    categoryInfo: CategoriesBlockProps['data']['categoryInfo'];
}

const getCategoryDepth = (category: Category): number => {
    if (!category.children || category.children.length === 0) return 1;
    else {
        const childDepths = category.children.map(getCategoryDepth);
        return Math.max(...childDepths) + 1;
    }
};

export const TreeBySiblingOrderComponent: React.FC<TreeBySiblingOrderProps> = memo(
    ({
        availableCategories,
        searchString,
        selectedCategoryId,
        intl,
        typeCode,
        onExpand,
        expandedKeys,
        sortData: { value: sortValue },
        categoryInfo,
    }) => {
        const businessAccountId = useAppSelector(businessAccountIdSelector);
        const dispatch = useDispatch();

        const [draggedCategory, setDraggedCategory] = useState<Category | null>(null);

        const availableCategoriesMemo = useMemo(() => availableCategories, [JSON.stringify(availableCategories)]);

        const onSelect = useCallback(
            async (selectedKeys: string[]) => {
                let selectedCategoryKeyId = selectedKeys[0];
                if (selectedCategoryKeyId === undefined) return;
                if (selectedCategoryKeyId !== selectedCategoryId) {
                    loadCategory(businessAccountId, typeCode, +selectedCategoryKeyId)(dispatch);
                }

                dispatch(selectCategory(typeCode, selectedCategoryKeyId));
            },
            [businessAccountId, dispatch, selectedCategoryId, typeCode]
        );

        const onDragStart: TreeProps['onDragStart'] = useCallback(
            async (info) => {
                const { dataRef } = info.node.props;
                if (dataRef && dataRef.key !== siblingOrderRootNodeValue) {
                    await onSelect([String(dataRef.key)]);
                    setDraggedCategory(dataRef);
                }
            },
            [onSelect]
        );

        const onDragEnd: TreeProps['onDragEnd'] = useCallback(() => {
            setDraggedCategory(null);
            onSelect([]);
        }, [onSelect]);

        const onDrop: TreeProps['onDrop'] = useCallback(
            async (info) => {
                const dragCategory = info?.dragNode?.props?.dataRef;
                const dropCategory = info?.node?.props?.dataRef;
                const { dropPosition, dropToGap } = info;

                if (!dragCategory || !dropCategory) {
                    setDraggedCategory(null);
                    onSelect([]);
                    return;
                }

                const dragCategoryDepth = getCategoryDepth(dragCategory);
                const dragParentCategory: Category | null = getParentCategory(dragCategory, availableCategoriesMemo);

                // Если дроп внутрь категории
                if (!dropToGap) {
                    if (dragCategoryDepth + dropCategory.level > MAX_DEEP_CATEGORIES) {
                        setDraggedCategory(null);
                        onSelect([]);
                        return;
                    }

                    if (categoryInfo && dropCategory?.value !== dragParentCategory?.value) {
                        try {
                            const parentCategoryId = dropCategory?.value !== siblingOrderRootNodeValue ? dropCategory?.value : undefined;

                            const newData: CategoryInfoUpdate = {
                                ...categoryInfo,
                                typeCode: typeCode as string as CategoryTypeCodeEnum,
                                parentCategoryId,
                                siblingOrder:
                                    (dropCategory?.value === siblingOrderRootNodeValue
                                        ? availableCategoriesMemo[0]?.siblingOrder
                                        : dropCategory.children[0]?.siblingOrder) ?? 0,
                            };

                            await updateCategory(intl, businessAccountId, typeCode, categoryInfo?.id ?? 0, newData!, sortValue)(dispatch);

                            onExpand([...expandedKeys, String(dropCategory.key)]);
                        } catch {}
                    }
                }

                // Если дроп рядом
                if (dropToGap) {
                    if (dragCategoryDepth + dropCategory.level - 1 > MAX_DEEP_CATEGORIES) {
                        setDraggedCategory(null);
                        onSelect([]);
                        return;
                    }

                    if (categoryInfo) {
                        try {
                            const dropParentCategory: Category | null = getParentCategory(dropCategory, availableCategoriesMemo);
                            const dropIndex: number =
                                (dropParentCategory?.children ?? availableCategoriesMemo).findIndex(
                                    (category) => category.key === dropCategory.key
                                ) ?? 0;
                            const siblingOrderChange: 1 | -1 = (dropPosition - dropIndex) as 1 | -1;

                            let siblingOrder: number;

                            if (dropCategory?.value === siblingOrderRootNodeValue) {
                                siblingOrder = availableCategoriesMemo[0].siblingOrder;
                            } else {
                                siblingOrder = dropCategory.siblingOrder;
                                if (siblingOrderChange > 0) {
                                    siblingOrder += siblingOrderChange;
                                }
                            }

                            const parentCategoryId =
                                dropParentCategory?.value !== siblingOrderRootNodeValue ? dropParentCategory?.value : undefined;

                            const newData: CategoryInfoUpdate = {
                                ...categoryInfo,
                                typeCode: typeCode as string as CategoryTypeCodeEnum,
                                parentCategoryId,
                                siblingOrder,
                            };

                            await updateCategory(intl, businessAccountId, typeCode, categoryInfo?.id ?? 0, newData!, sortValue)(dispatch);
                        } catch {}
                    }
                }

                setDraggedCategory(null);
                onSelect([]);
            },
            [
                availableCategoriesMemo,
                businessAccountId,
                categoryInfo,
                dispatch,
                expandedKeys,
                intl,
                onExpand,
                onSelect,
                sortValue,
                typeCode,
            ]
        );

        return (
            <>
                <div className={'rr-categories-info-block'}>
                    <Icon className={'rr-categories-exclamation-icon'} component={IconInfoSolid} />
                    <span>Чтобы задать порядок и иерархию, перетаскивайте категории в дереве ниже</span>
                </div>
                <TreeBySiblingOrder
                    availableCategories={availableCategoriesMemo}
                    draggedCategory={draggedCategory}
                    expandedKeys={[...expandedKeys]}
                    onExpand={onExpand}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDrop={onDrop}
                    searchString={searchString}
                    selectedCategoryId={selectedCategoryId}
                />
            </>
        );
    }
);

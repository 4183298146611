import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CreateProfessionArgs, ProfessionState, UpdateProfessionArgs } from './types';
import { professionsApi } from '../../api/professions.api';
import { getServerError } from '../../../../../../../shared/util/utils';
import { AxiosError } from 'axios';
import { serverApi } from '../../../../../../../server';
import { AppDispatch } from '../../../../../../../../index';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { goBack } from 'connected-react-router';

const ACTION_TYPES = {
    LOAD_PROFESSION: 'profession/LOAD_PROFESSION',
    CREATE_PROFESSION: 'profession/CREATE_PROFESSION',
    UPDATE_PROFESSION: 'profession/UPDATE_PROFESSION',
    DELETE_PROFESSION: 'profession/DELETE_PROFESSION',
};

const initialState: ProfessionState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createProfessionThunk = createAsyncThunk(ACTION_TYPES.CREATE_PROFESSION, async (args: CreateProfessionArgs, { rejectWithValue }) => {
    const { businessAccountId, professionInfoCreate } = args;

    try {
        return await serverApi.createProfession(businessAccountId, professionInfoCreate);
    } catch (err) {
        throw rejectWithValue(err as AxiosError);
    }
});

export const createProfession = (args: CreateProfessionArgs) => (dispatch: AppDispatch) => {
    return dispatch(createProfessionThunk(args))
        .unwrap()
        .then((result) => {
            if (result.status === 201) {
                showNotification('success', 'Специальность создана');
            }

            dispatch(professionsApi.util?.invalidateTags(['ProfessionsList', 'Profession']));
            return result.data;
        })
        .catch((error) => {
            showNotification('error', 'Не удалось создать специальность');
            throw error;
        });
};

export const updateProfessionThunk = createAsyncThunk(
    ACTION_TYPES.UPDATE_PROFESSION,
    async (args: UpdateProfessionArgs, { rejectWithValue, getState }) => {
        const { businessAccountId, id, professionInfoUpdate } = args;

        try {
            const response = await serverApi.updateProfessionById(businessAccountId, id, professionInfoUpdate);
            return response.data;
        } catch (error: unknown) {
            throw rejectWithValue(error);
        }
    }
);

export const updateProfession = (args: UpdateProfessionArgs) => (dispatch: AppDispatch) => {
    return dispatch(updateProfessionThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Специальность обновлена');
            dispatch(goBack());
            dispatch(professionsApi.util?.invalidateTags(['ProfessionsList', 'Profession']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось обновить специальность');
        });
};

export const professionSlice = createSlice({
    name: 'profession',
    initialState,
    reducers: {
        clearProfession: (state) => {
            state.entity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateProfessionThunk.fulfilled, (state, action) => {
                state.updating = false;
                if (!('error' in action.payload)) {
                    state.entity = action.payload;
                }
            })
            .addCase(createProfessionThunk.fulfilled, (state, action) => {
                state.updating = false;
            })

            .addMatcher(professionsApi.endpoints.professionById.matchPending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addMatcher(professionsApi.endpoints.professionById.matchFulfilled, (state, { payload }) => {
                state.loading = false;
                state.entity = payload;
            })
            .addMatcher(professionsApi.endpoints.professionById.matchRejected, (state, { payload }) => {
                state.loading = false;
                state.loadingError = getServerError(payload);
            })

            .addMatcher(isAnyOf(updateProfessionThunk.pending, createProfessionThunk.pending), (state) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(updateProfessionThunk.rejected, createProfessionThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = getServerError(action.payload);
            });
    },
});

export const { clearProfession } = professionSlice.actions;
export default professionSlice.reducer;

import React from "react";
import {LocalizationEnum} from "../../localization";
import {printUserName} from "../../shared/util/utils";
import {ContactPersonAPIObj} from "../../server/api";
import {DataDisplayBlock, DataDisplayItemType} from "../dataDisplay";
import {useLocalize} from "../../core/hooks/useLocalize";

interface IProps {
    data: ContactPersonAPIObj;
    hasOwnContacts?: boolean;
}

export const ContactPersonBlock = (props: IProps) => {

    const L = useLocalize();
    const emptyValueText = L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED);

    return (
        <DataDisplayBlock data={[
            [
                {
                    type: DataDisplayItemType.String,
                    label: L(LocalizationEnum.ASPECT__FIELDS__COMMON__CONTACT_PERSON),
                    value: printUserName(props.data.fullName, true),
                    emptyValueText,
                    hint: props.hasOwnContacts === false ? `(${L(LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER)})` : undefined
                },
                {
                    type: DataDisplayItemType.String,
                    label: L(LocalizationEnum.ASPECT__FIELDS__COMMON__POSITION),
                    value: props.data.position,
                    style: {paddingLeft: 0},
                    emptyValueText
                }
            ],
            [
                {
                    type: DataDisplayItemType.Phone,
                    label: L(LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER),
                    value: props.data.phoneNumber,
                    emptyValueText
                },
                {
                    type: DataDisplayItemType.EMail,
                    label: 'E-mail',
                    value: props.data.email,
                    style: {paddingLeft: 0},
                    emptyValueText
                }
            ]
        ]} style={{marginLeft: -8, marginRight: -8}} />
    );
};

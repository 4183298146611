import { ContactInfoRead } from '../../../../../../../../server';
import { ContactDrawer } from '../ContactDrawer/ContactDrawer';
import React, { memo } from 'react';
import { EntityGridDrawerWrapper } from '../../../../../../../../components/page/entityGrid/types/drawer';

export const ContactDrawerWrapper: EntityGridDrawerWrapper<ContactInfoRead> = memo(
    ({ entityId, onClose, refetchEntitiesList, editActionForDrawer, deleteActionForDrawer, archiveActionForDrawer, isLoading }) => {
        return (
            <ContactDrawer
                contactId={entityId}
                onClose={onClose}
                refetchList={refetchEntitiesList}
                editContact={editActionForDrawer}
                deleteContact={deleteActionForDrawer}
                archiveContact={archiveActionForDrawer}
                isEntityActionsLoading={isLoading}
            />
        );
    }
);

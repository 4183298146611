import { CalendarSortValue } from '../../../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { CalendarSortGroupMap } from '../../../../../components/Calendar/hooks/useCalendarItems';

export type AccessibilityElementsCalendarSortValue = 'renter' | 'project' | 'stateCode' | 'startDate' | 'endDate';

export const accessibilityElementsCalendarSortData: CalendarSortValue<AccessibilityElementsCalendarSortValue>[] = [
    { value: 'renter', title: 'Контрагентам' },
    { value: 'project', title: 'Проектам и поставкам' },
    { value: 'stateCode', title: localize(LocalizationEnum.ASPECT__FILTERS__SORT_BY_STATE) },
    { value: 'startDate', title: localize(LocalizationEnum.PAGE__CALENDAR__GROUPING_OPTION__RENT_PERIOD_START) },
    { value: 'endDate', title: localize(LocalizationEnum.PAGE__CALENDAR__GROUPING_OPTION__RENT_PERIOD_END) },
];

export const accessibilityElementsCalendarSortValueToItemKey = {
    renter: 'counterpartyId',
    project: ['projectId', 'subrentId'],
    stateCode: 'commonFields.stateCode',
} satisfies CalendarSortGroupMap<AccessibilityElementsCalendarSortValue>;

import { EntityGridDrawerWrapper } from '../../../../../../components/page/entityGrid/types/drawer';
import { memo } from 'react';
import { PaymentDrawer } from '../PaymentDrawer';
import { PaymentInfoReadWithId } from '../../../api/payments.api.types';

export const PaymentDrawerWrapper: EntityGridDrawerWrapper<PaymentInfoReadWithId> = memo(
    ({ entityId, onClose, editActionForDrawer, isLoading }) => (
        <PaymentDrawer paymentId={entityId} onClose={onClose} editPayment={editActionForDrawer} />
    )
);

import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {matchPath, Route, RouteComponentProps} from 'react-router';
import {RouteConfigComponentProps} from 'react-router-config';
import {Icon} from 'antd';
import PricingSchemesModuleList from './pricingSchemesModuleList';
import {Link} from 'react-router-dom';
import RoundButton from '../../../../components/button/roundButton';
import {IconPlus} from '../../../../components/icons';
import {LocalizationEnum, localize} from '../../../../localization';
import {reset} from './reducers/pricingSchemes.reducer';
import PricingSchemeEditModal from './pricingSchemeEditModal';
import {getPathFromState} from '../../../../shared/util/utils';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {SettingsPageWrapper} from "../settingsPageWrapper";

interface PricingSchemesModuleProps
    extends StateProps,
        DispatchProps,
        RouteComponentProps,
        RouteConfigComponentProps,
        WrappedComponentProps {}

interface State {}

class PricingSchemesModule extends React.PureComponent<PricingSchemesModuleProps, State> {
    componentWillUnmount = () => {
        this.props.reset();
    };

    render() {
        let modalBackPath = getPathFromState(
            `/${this.props.businessAccountId}/settings/pricingSchemes`,
            this.props.location.search,
            { mode: undefined, id: undefined }
        );
        let createPath = getPathFromState(
            `/${this.props.businessAccountId}/settings/pricingSchemes/new`,
            this.props.location.search
        );

        return (
            <SettingsPageWrapper title={'Ценовые схемы'} extra={<Link to={createPath}>
                <RoundButton colorScheme={'success'}>
                    <Icon component={IconPlus} />
                    <span>Создать схему</span>
                </RoundButton>
            </Link>}>
                <PricingSchemesModuleList intl={this.props.intl} />
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/pricingSchemes/new`}
                    render={(props) => (
                        <PricingSchemeEditModal
                            intl={this.props.intl}
                            title={'Создание ценовой схемы'}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            goBackPath={modalBackPath}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/${this.props.businessAccountId}/settings/pricingSchemes/${this.props.entityId}/edit`}
                    render={(props) => (
                        <PricingSchemeEditModal
                            entityId={this.props.entityId}
                            intl={this.props.intl}
                            title={'Редактирование ценовой схемы'}
                            goBackPath={modalBackPath}
                        />
                    )}
                />
            </SettingsPageWrapper>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: any) => {
    let entityId;
    let matchProps = matchPath(ownProps.location.pathname, ownProps.route) as any;
    if (matchProps && matchProps.params && matchProps.params.id) {
        entityId = matchProps.params.id;
    }

    return {
        businessAccountId: storeState.system.businessAccountId,
        entityId: entityId,
    };
};

const mapDispatchToProps = { reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PricingSchemesModule));

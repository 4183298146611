import React, { FC, Fragment, ReactElement, ReactNode } from 'react';
import { FormValue } from '../../../../../../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../../../../../../localization';

interface FormValueItemProps {
    title: ReactNode;
    value?: string | number | ReactElement;
    tagWrapper?: keyof JSX.IntrinsicElements;
}

export const FormValueItem: FC<FormValueItemProps> = (props) => {
    const { title, value, tagWrapper } = props;

    return (
        <>
            <div className={'rr-label'}>{title}</div>
            <div className="rr-value">
                {React.createElement(
                    tagWrapper ?? Fragment,
                    undefined,
                    <FormValue value={value} emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)} />
                )}
            </div>
        </>
    );
};

import React, {useMemo} from 'react';
import {Popover} from "../../../../../../components/popover/Popover";
import {NomenclatureCalendarPopoverContent} from "./nomenclatureCalendarPopoverContent";
import {HelpTooltip} from "../../../../../../components/helpTooltip/HelpTooltip";
import {is_OperationTimeError} from "../../../utils/utils";
import {useAppSelector} from "../../../../../../store/hooks";
import {NomenclatureEntityTypeCodeEnum, NomenclatureRecord} from "../../../../../../server";
import './nomenclatureCalendarPopover.less';

interface NomenclatureCalendarPopoverProps {
    nomenclatureId: number;
    nomenclatureType: NomenclatureEntityTypeCodeEnum;
    availableInstanceCount: number;
    stockInstanceCount: number;
    nomenclature: NomenclatureRecord;
}

export const NomenclatureCalendarPopover = ({nomenclatureId, nomenclatureType, availableInstanceCount, stockInstanceCount, nomenclature}: NomenclatureCalendarPopoverProps) => {

    const startDate = useAppSelector((state)=>state.operationForm.products.params.startDate);
    const endDate = useAppSelector((state)=>state.operationForm.products.params.endDate);

    return useMemo(()=>{

        const isRentDatesError = () => {
            return is_OperationTimeError(
                startDate,
                endDate
            );
        };

        let _isRentDatesError = isRentDatesError();
        let fontW = availableInstanceCount === 0 ? 200 : undefined;
        if (availableInstanceCount < 0) fontW = 600;
        const color = availableInstanceCount > 0 ? '#343f9a' : availableInstanceCount === 0 ? '#abacb0' : '#f4516c';

        return (
            <Popover
                destroyTooltipOnHide={true}
                overlayClassName={'rr-calendar-popover'}
                content={
                    <NomenclatureCalendarPopoverContent
                        nomenclatureId={nomenclatureId}
                        nomenclatureType={nomenclatureType}
                        startDate={startDate || Date.now()}
                        endDate={endDate || Date.now()}
                        nomenclature={nomenclature}
                    />
                }>
                <div className={'operationForm-products-grid-available-instance-count'} style={{ color: color, fontWeight: fontW }}>
                    {_isRentDatesError ? '?' : availableInstanceCount}
                    {nomenclatureType === NomenclatureEntityTypeCodeEnum.KIT ? (
                        <HelpTooltip content={'В данной версии доступность набора не учитывает состава операции'}>*</HelpTooltip>
                    ) : null}{' '}
                    {'из'} {_isRentDatesError ? '?' : stockInstanceCount}
                </div>
            </Popover>
        );
    }, [startDate, endDate, availableInstanceCount, stockInstanceCount, nomenclatureId, nomenclatureType, nomenclature]);
};

import React from 'react';
import { IRootState } from '../../../../../../../shared/reducers';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import { InstanceTrackingTypeCodeEnum, ProductInfoRead, ProductInfoUpdate } from '../../../../../../../server/api';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { formFields } from './СhangeProductTypeModalFields';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import { resetError, updateEntity } from '../../../reducers/product.reducer';
import { Icon } from 'antd';
import { IconInfoSolid } from '../../../../../../../components/icons';
import { InfoPopover } from '../../../../../../../components/infoPopover/InfoPopover';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { AccountingTypeContent } from '../ProductCreateModal/ProductCreateModalFieldsData';
import { ProductTypeUtils } from '../../../utils/productTypeUtils';
import { CustomFieldsUtils } from '../../../../../../../core/utils/customFieldsUtils';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {}

class _ChangeProductTypeModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
        createFromOperationForm: false,
        title: (
            <div style={{ display: 'flex' }}>
                Изменение типа продукта
                <div style={{ flexGrow: 1, textAlign: 'right' }}>
                    <InfoPopover content={AccountingTypeContent}>
                        <Icon style={{ fontSize: 22, color: '#525A95', marginRight: 15 }} component={IconInfoSolid} />
                    </InfoPopover>
                </div>
            </div>
        ),
    };

    onOk = (data: any) => {
        const productEntity: ProductInfoRead = this.props.initialValues as ProductInfoRead;

        let newData: ProductInfoUpdate = {
            ...productEntity,
            ...data,
            categoryIds: productEntity?.categories?.map((category) => category.id) ?? [],
            stockInstanceCount: productEntity?.warehousePresenceCounters.stockInstanceCount ?? 0,
            underMaintenanceInstanceCount: productEntity?.warehousePresenceCounters.underMaintenanceInstanceCount ?? 0,
            customFieldGroups: (productEntity.customFieldGroups ?? []).map(
                CustomFieldsUtils.convertCustomFieldGroupObjReadToCustomFieldGroupObjWrite
            ),
        };

        let instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
        if (data.variant && data.tracked) instanceTrackingTypeCode = InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED;
        else if (data.variant && !data.tracked) instanceTrackingTypeCode = InstanceTrackingTypeCodeEnum.VARIANTBULK;
        else if (!data.variant && data.tracked) instanceTrackingTypeCode = InstanceTrackingTypeCodeEnum.INSTANCETRACKED;
        else instanceTrackingTypeCode = InstanceTrackingTypeCodeEnum.BULK;

        newData.instanceTrackingTypeCode = instanceTrackingTypeCode;

        delete data.variant;
        delete data.tracked;

        if (this.props.initialValues?.pricingScheme) {
            newData.pricingSchemeId = this.props.initialValues.pricingScheme.id;
        }
        delete newData['pricingScheme'];
        delete newData['warehousePresenceCounters'];

        this.props.updateEntity(this.props.intl, this.props.businessAccountId, newData.id || 0, newData);
    };

    getInitialValues = () => {
        let initialValues;

        if (this.props.initialValues) {
            initialValues = { ...this.props.initialValues };

            ProductTypeUtils.setProductTypeToInitialValues(initialValues);
        }

        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            this.props.goBack();
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.product.updating,
    updatingError: storeState.product.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = { resetError, updateEntity, goBack, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ChangeProductTypeModal));

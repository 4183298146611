import {PageURLParamDescriptionObject, PageURLParamDescriptions} from '../../../../../core/utils/descriptions';
import {ListOffersFilters} from '../../api/types';
import {HideValue} from "../../../calendar/tabs/elements/filters/elementsCalendarFilters.data";

export const OffersPageFiltersDescription: PageURLParamDescriptionObject<ListOffersFilters> = {
    search: PageURLParamDescriptions.searchParam(),
    stateCode: PageURLParamDescriptions.arrayOfStringsParam,
    tags: PageURLParamDescriptions.arrayOfStringsParam,
    projectId: PageURLParamDescriptions.numberParam,
    hide: PageURLParamDescriptions.enumSelectParam(HideValue),
    assigneeId: PageURLParamDescriptions.numberParam,
    partOfThePrice: PageURLParamDescriptions.booleanParam,
    renterId: PageURLParamDescriptions.numberParam
};

import React, {ReactNode} from 'react';
import {Button, Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import {IconEdit} from "../../icons";
import RoundButton from "../../button/roundButton";

interface EditButtonProps extends ButtonProps {
    label?: ReactNode;
    darkTheme?: boolean;
}

export const EditButton = (props: EditButtonProps) => {
    return (
        <RoundButton
            colorScheme={props.darkTheme ? 'default4' : 'defaultSecondary'}
            {...props}
        >
            <Icon component={IconEdit} />
            <span>{props.label === undefined ? 'Править' : props.label}</span>

        </RoundButton>
    );
};
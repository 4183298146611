import { Icon, Modal } from 'antd';
import { IconSortSolid } from '../../../../components/icons';
import React, { useCallback, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { siblingOrderRootNodeValue, TreeBySiblingOrder, TreeBySiblingOrderProps } from './TreeBySiblingOrder';
import { CategoriesBlockProps, Category } from '../categories-block';
import { TreeProps } from 'antd/lib/tree';
import { MAX_DEEP_CATEGORIES } from '../../../../config/constants';
import { getCategoryDepth, getParentCategory } from '../utils';
import { LocalizationEnum, localize } from '../../../../localization';
import cloneDeep from 'lodash/cloneDeep';
import { BulkCategoryUpdateOrderItem, serverApi } from '../../../../server';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import './TreeBySiblingOrderModal.less';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../store/hooks';
import {showConfirm} from "../../../../components/confirm/showConfirm";
import {useLocalize} from "../../../../core/hooks/useLocalize";
import {useIntl} from "react-intl";

interface TreeModalProps
    extends Pick<TreeBySiblingOrderProps, 'availableCategories' | 'expandedKeys' | 'onExpand'>,
        Pick<CategoriesBlockProps, 'typeCode' | 'sortData'> {
    visible: boolean;
    title: string;
    backPathUrl: string;
}

const mapCategoriesForUpdate = (categories: Category[]): BulkCategoryUpdateOrderItem[] => {
    return categories.map(
        (category: Category): BulkCategoryUpdateOrderItem => ({
            id: category.value,
            children: category.children ? mapCategoriesForUpdate(category.children) : [],
        })
    );
};

export const TreeBySiblingOrderModal: React.FC<TreeModalProps> = ({
    availableCategories,
    visible,
    expandedKeys,
    onExpand,
    title,
    backPathUrl,
    typeCode,
    sortData: { value: sortValue },
}) => {
    const L = useLocalize();
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useDispatch();

    const [availableCategoriesLocal, setAvailableCategoriesLocal] = useState<Category[]>(availableCategories);
    const [draggedCategory, setDraggedCategory] = useState<Category | null>(null);
    const [expandedKeysLocal, setExpandedKeysLocal] = useState<TreeBySiblingOrderProps['expandedKeys']>([...expandedKeys]);

    const onExpandLocal = useCallback((expandedKeys: string[]) => {
        setExpandedKeysLocal([...expandedKeys]);
    }, []);

    const onDragStart: TreeProps['onDragStart'] = useCallback(async (info) => {
        if (info.node.props.dataRef) {
            setDraggedCategory(info.node.props.dataRef);
        }
    }, []);

    const onDragEnd: TreeProps['onDragEnd'] = useCallback(() => {
        setDraggedCategory(null);
    }, []);

    const onDrop: TreeProps['onDrop'] = useCallback(
        async (info) => {
            const dragCategory = info?.dragNode?.props?.dataRef;
            const dropCategory = info?.node?.props?.dataRef;
            const { dropPosition, dropToGap } = info;

            if (!dragCategory || !dropCategory) {
                setDraggedCategory(null);
                return;
            }

            const dragCategoryDepth = getCategoryDepth(dragCategory);
            const dragParentCategory: Category | null = getParentCategory(dragCategory, availableCategoriesLocal);
            const dropParentCategory: Category | null = getParentCategory(dropCategory, availableCategoriesLocal);

            // Если дроп внутрь категории
            if (!dropToGap) {
                if (dragCategoryDepth + dropCategory.level > MAX_DEEP_CATEGORIES) {
                    setDraggedCategory(null);
                    return;
                }

                const categoriesArrayForFind = dragParentCategory ? dragParentCategory.children : availableCategoriesLocal;
                let deleteIndex = categoriesArrayForFind.findIndex((category) => category.value === dragCategory.value);

                // Добавить узел к выбранному узлу
                dropCategory.children.unshift(dragCategory);
                // Проверка на дроп в Корневой уровень
                if (
                    dragParentCategory?.value === dropCategory?.value ||
                    (dragParentCategory == null && dropCategory.value === siblingOrderRootNodeValue)
                ) {
                    deleteIndex++;
                }
                // Удалить узел у предыдущего родителя
                categoriesArrayForFind.splice(deleteIndex, 1);
                setAvailableCategoriesLocal(cloneDeep(availableCategoriesLocal));
                onExpandLocal([...expandedKeysLocal, String(dropCategory.key)]);
            }

            // Если дроп рядом
            if (dropToGap) {
                if (dragCategoryDepth + dropCategory.level - 1 > MAX_DEEP_CATEGORIES) {
                    setDraggedCategory(null);
                    return;
                }

                // Массивы, для обновления
                const categoriesForDropUpdate = dropParentCategory?.children ?? availableCategoriesLocal;
                const categoriesForDragUpdate = dragParentCategory?.children ?? availableCategoriesLocal;
                // Корректные индексы в этих массивах
                let dropIndex: number = categoriesForDropUpdate.findIndex((category) => category.key === dropCategory.key);
                if (dropIndex < 0) dropIndex = 0;
                let dragIndex: number = categoriesForDragUpdate.findIndex((category) => category.key === dragCategory.key);
                let siblingOrderChange: 1 | -1 = (dropPosition - dropIndex) as 1 | -1;
                // Добавить узел к выбранному узлу
                if (dropCategory.value === siblingOrderRootNodeValue) siblingOrderChange = -1;
                categoriesForDropUpdate.splice(Math.max(dropIndex + Math.max(siblingOrderChange, 0), 0), 0, dragCategory);

                if (dragParentCategory?.value === dropParentCategory?.value && dropIndex < dragIndex) {
                    dragIndex++;
                }
                // Удалить узел у предыдущего родителя
                categoriesForDragUpdate.splice(dragIndex, 1);
                setAvailableCategoriesLocal(cloneDeep(availableCategoriesLocal));
            }

            setDraggedCategory(null);
        },
        [JSON.stringify(availableCategoriesLocal), expandedKeysLocal]
    );

    const onOk = async () => {
        const categoriesForUpdate = mapCategoriesForUpdate(availableCategoriesLocal);
        await serverApi.bulkUpdateCategoriesOrder(businessAccountId, typeCode, {
            rootCategories: categoriesForUpdate,
        });
        await loadCategories(businessAccountId, typeCode, sortValue)(dispatch);
        await onExpand(expandedKeysLocal);
        dispatch(push(backPathUrl));
    };

    const onCancel = async () => {
        const yes = await showConfirm(intl, L(LocalizationEnum.ASPECT__MODAL__ABORT_EDIT));
        if(yes) dispatch(push(backPathUrl));
    };

    return (
        <Modal
            visible={visible}
            destroyOnClose
            width={800}
            title={
                <div style={{ display: 'flex', gap: '12px' }}>
                    <Icon component={IconSortSolid} />
                    <span>{title}</span>
                </div>
            }
            onOk={onOk}
            onCancel={onCancel}
            okText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            className={'rr-tree-by-sibling-modal'}
            cancelButtonProps={{
                className: 'ant-btn rr-btn-default',
            }}
        >
            <TreeBySiblingOrder
                availableCategories={availableCategoriesLocal}
                draggedCategory={draggedCategory}
                expandedKeys={[...expandedKeysLocal]}
                onExpand={onExpandLocal}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
            />
        </Modal>
    );
};

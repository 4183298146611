import React, {ReactNode, useState} from "react";
import {Col} from "antd";
import {OperationElement, setEffectivePriceAndDiscountForSelectedIds, setFinalPricePerShiftForSelectedIds, setFinalTotalPriceForSelectedIds} from "../../../reducers/operationForm.reducer";
import {NumberInputMoney} from "../../../../../../components/numberInputs";
import {useLocalize} from "../../../../../../core/hooks/useLocalize";
import {useAppDispatch} from "../../../../../../store/hooks";
import {isDefined} from "../../../../../../shared/util/utils";

export interface BlockData {
    content: ReactNode;
    onApply: ()=>void;
    canApply: boolean;
}

export interface CreateBlockParams {
    colSpan: number;
    label: string;
    input: ReactNode;
}

export const createBlock = (params: CreateBlockParams):ReactNode => {
    return (
        <Col span={params.colSpan} className="rr-form-item" style={{ marginBottom: 0 }}>
            <div className="rr-label" style={{ marginBottom: 10 }}>{params.label}</div>
            <div>{params.input}</div>
        </Col>
    );
}

interface Params {
    selectedElements: OperationElement[];
}

// Цена экз./см.
// effectivePrice
export const useRenderEffectivePriceBlock = ({selectedElements}: Params):BlockData => {
    const L = useLocalize();
    const dispatch = useAppDispatch();
    const defaultDiscount = selectedElements.every((el) => el.discount === selectedElements?.[0].discount) ? selectedElements[0].discount : undefined;
    const defaultPrice = selectedElements.every((el) => el.effectivePrice === selectedElements?.[0].effectivePrice) ? selectedElements[0].effectivePrice : undefined;
    const [discount, setDiscount] = useState(defaultDiscount);
    const [price, setPrice] = useState(defaultPrice);

    const priceInput = createBlock({
        colSpan: 12,
        label: 'Цена экз./см.',
        input: <NumberInputMoney step={50} min={0} value={price} onChange={(value)=>setPrice(value)} />
    });

    const onApply = () => {
        dispatch(setEffectivePriceAndDiscountForSelectedIds(selectedElements.map(el=>el.id), price, discount));
    };

    return {
        content: <>{priceInput}</>,
        onApply,
        canApply: isDefined(price)
    };
}

// Стоимость экз./см.
// finalPricePerShift
export const useRenderFinalPricePerShiftBlock = ({selectedElements}: Params):BlockData => {
    const dispatch = useAppDispatch();
    const defaultPrice = selectedElements.every((el) => el.finalPricePerShift === selectedElements[0].finalPricePerShift) ? selectedElements[0].finalPricePerShift : undefined;
    const [price, setPrice] = useState(defaultPrice);

    const priceInput = createBlock({
        colSpan: 24,
        label: 'Стоимость экз./см.',
        input: <NumberInputMoney step={50} value={price} onChange={(value)=>setPrice(value)} />
    });

    const onApply = () => {
        if(isDefined(price)) dispatch(setFinalPricePerShiftForSelectedIds(selectedElements.map(el=>el.id), price));
    };

    return {
        content: priceInput,
        onApply,
        canApply: isDefined(price)
    };
}

// Итоговая сумма
// finalTotalPrice
export const useRenderFinalTotalPriceBlock = ({selectedElements}: Params):BlockData => {
    const dispatch = useAppDispatch();
    const defaultPrice = selectedElements.every((el) => el.finalTotalPrice === selectedElements[0].finalTotalPrice) ? selectedElements[0].finalTotalPrice : undefined;
    const [price, setPrice] = useState(defaultPrice);

    const priceInput = createBlock({
        colSpan: 24,
        label: 'Итоговая сумма',
        input: <NumberInputMoney step={50} value={price} onChange={(value)=>setPrice(value)} />
    });

    const onApply = () => {
        if(isDefined(price)) dispatch(setFinalTotalPriceForSelectedIds(selectedElements.map(el=>el.id), price));
    };

    return {
        content: priceInput,
        onApply,
        canApply: isDefined(price)
    };
}

import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { CustomFieldInfoCreate, CustomFieldInfoUpdate } from '../../../../server';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { formFields } from './customFieldsEditModalFields';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { createEntity, getCustomFieldsLimit, resetError, updateEntity } from './reducers/customField.reducer';
import { IntlShape } from 'react-intl';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { loadEntities } from './reducers/customFields.reducer';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    goBackPath: string;
    intl: IntlShape;
}

class CustomFieldsEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    _componentDidMount = async () => {
        await this.props.getCustomFieldsLimit(this.props.businessAccountId);
    };

    getInitialValues = () => {
        let initialValues: any; //(CustomFieldInfoUpdate | undefined);
        if (this.props.initialValues) {
            initialValues = { ...this.props.initialValues } as CustomFieldInfoUpdate;
            initialValues.customFieldEdit = true;
        }

        return initialValues;
    };

    onOk = async (data: any) => {
        if (this.props.initialValues) {
            // Редактирование
            let newData: CustomFieldInfoUpdate = { ...this.props.initialValues, ...data } as CustomFieldInfoUpdate;
            if (newData.comment === '') delete newData.comment;
            let result = await this.props.updateEntity(this.props.businessAccountId, newData.id || 0, newData);
            if (!(result instanceof Error)) {
                showNotification(
                    'success',
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__UPDATE_SUCCESS_MESSAGE)
                );
                this.props.push(this.props.goBackPath);
                this.props.loadEntities(this.props.businessAccountId);
            } else {
                // TODO Мб норм сообщение об ошибке норм выводить
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            }
        } else {
            // Создание
            let newData: CustomFieldInfoCreate = { ...data, standard: false } as CustomFieldInfoCreate;
            if (newData.comment === '') delete newData.comment;
            if (!newData.multipleValues) newData.multipleValues = false;
            if (!newData.internal) newData.internal = false;
            if (!newData.financial) newData.financial = false;
            let result = await this.props.createEntity(this.props.businessAccountId, newData);
            if (!(result instanceof Error)) {
                showNotification(
                    'success',
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__CREATE_SUCCESS_MESSAGE)
                );
                this.props.push(this.props.goBackPath);
                this.props.loadEntities(this.props.businessAccountId);
            } else {
                // TODO Мб норм сообщение об ошибке норм выводить
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            }
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                localizeIntl(
                    this.props.intl,
                    this.props.initialValues ? LocalizationEnum.ASPECT__MODAL__ABORT_EDIT : LocalizationEnum.ASPECT__MODAL__ABORT_CREATION
                )
            ));
        if (yes) {
            this.props.push(this.props.goBackPath);
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.customField.updating,
    updatingError: storeState.customField.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    fieldsLimit: storeState.customField.fieldsLimit,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    createEntity,
    updateEntity,
    push,
    resetError,
    loadEntities,
    getCustomFieldsLimit,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsEditModal);

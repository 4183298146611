import React, { FC, memo, useCallback } from 'react';
import { RoundButton } from '../../../../../../../../../../components';
import { Button, Icon } from 'antd';
import {
    IconArchive,
    IconCheck,
    IconEdit,
    IconLevelUp,
    IconTrash,
    IconUnlock,
    IconUnlockAlt,
} from '../../../../../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../../../../../localization';
import { CrewMemberInfoRead, CrewMemberStateCodeEnum, CrewMemberTransitionCodeEnum } from '../../../../../../../../../../server';
import AdditionalEntityActions from '../../../../../../../../../../components/additionalEntityActions/additionalEntityActions';
import { AdditionalEntityActionsButton } from '../../../../../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { useSelector } from 'react-redux';
import { businessAccountIdSelector } from '../../../../../../../../../../shared/reducers/system.reducer';
import { useCrewMemberEntityActions } from '../../../../../hooks/useCrewMemberEntityActions';
import { CrewMemberUtils } from '../../../../../utils/helpers';
import { useAppDispatch } from '../../../../../../../../../../store/hooks';
import { loadCrewMember } from '../../../../../reducers/crewMember/crewMember.reducer';
import { replace } from 'connected-react-router';
import { getPathFromState } from '../../../../../../../../../../shared/util/utils';
import { getGridStorageData } from '../../../../../../../../../../components/grid/utils';
import { CrewMemberDescriptionModalType } from '../../../tabs/description/CrewMemberDescription';
import { CrewMemberPageTabsEnum, CrewPageTabsEnum } from '../../../../../../../../../../shared/constants/tabEnums';
import {showConfirm} from "../../../../../../../../../../components/confirm/showConfirm";
import {useIntl} from "react-intl";

interface TabBarContentProps {
    crewMember: CrewMemberInfoRead;
}

export const TabBarButtons: FC<TabBarContentProps> = memo((props) => {
    const { crewMember } = props;
    const businessAccountId = useSelector(businessAccountIdSelector);
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { onRowAction, editAction, deleteActionForDrawer, archiveActionForDrawer } = useCrewMemberEntityActions({
        rootPath: CrewMemberUtils.getCardPath(businessAccountId, crewMember.id),
        urlSearchParams: new URLSearchParams({
            tab: CrewMemberPageTabsEnum.DESCRIPTION,
            type: CrewMemberDescriptionModalType.CREW_MEMBER,
        }),
    });

    const archiveAndUpdateEntity = useCallback(() => {
        try {
            archiveActionForDrawer(crewMember);
            setTimeout(() => {
                dispatch(
                    loadCrewMember({
                        businessAccountId,
                        entityId: crewMember.id,
                    })
                );
            }, 300);
        } catch (err) {}
    }, [archiveActionForDrawer, businessAccountId, crewMember, dispatch]);

    const setStatusAndUpdateEntity = useCallback(() => {
        try {
            onRowAction(crewMember, 'lock');
            setTimeout(() => {
                dispatch(
                    loadCrewMember({
                        businessAccountId,
                        entityId: crewMember.id,
                    })
                );
            }, 300);
        } catch (err) {}
    }, [businessAccountId, crewMember, dispatch, onRowAction]);

    const deleteCrewMemberAndPushToList = useCallback(async () => {
        const yes = await showConfirm(intl, 'Вы уверены что хотите удалить проектного работника?');
        if(yes){
            await deleteActionForDrawer?.(crewMember);
            const gridData = getGridStorageData(CrewPageTabsEnum.CREW_MEMBERS);
            dispatch(
                replace(
                    getPathFromState(`${CrewMemberUtils.getRootPath(businessAccountId)}`, '', {
                        ...gridData.filters,
                        ...gridData.params,
                    })
                )
            );
        }
    }, [businessAccountId, crewMember, deleteActionForDrawer, dispatch]);

    return (
        <>
            {crewMember.archive ? (
                <RoundButton colorScheme={'FROM_ARCHIVE'} onClick={archiveAndUpdateEntity}>
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null}
            {crewMember.availableTransitionCodes?.includes(CrewMemberTransitionCodeEnum.ACTIVATE) &&
            crewMember.stateCode === CrewMemberStateCodeEnum.ACTIVE ? (
                <RoundButton colorScheme={'success'} onClick={setStatusAndUpdateEntity}>
                    <Icon component={IconCheck} />
                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                </RoundButton>
            ) : null}
            <RoundButton
                colorScheme={'default'}
                onClick={() => {
                    editAction(crewMember);
                }}
            >
                <Icon component={IconEdit} />
                {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
            </RoundButton>
            <span style={{ marginLeft: '16px' }}>
                <AdditionalEntityActions
                    content={
                        <>
                            {crewMember.archivable && !crewMember.archive ? (
                                <Button className={'ant-btn-page-actions'} block onClick={archiveAndUpdateEntity}>
                                    <Icon component={IconArchive} style={{ color: '#c4c5d6' }} />
                                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                                </Button>
                            ) : null}
                            {crewMember.availableTransitionCodes?.includes(CrewMemberTransitionCodeEnum.ACTIVATE) &&
                            crewMember.stateCode === CrewMemberStateCodeEnum.BLOCKED ? (
                                <Button className={'ant-btn-page-actions'} block onClick={setStatusAndUpdateEntity}>
                                    <Icon component={IconUnlockAlt} />
                                    {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                                </Button>
                            ) : null}
                            {crewMember.availableTransitionCodes!.includes(CrewMemberTransitionCodeEnum.BLOCK) ? (
                                <Button className={'ant-btn-page-actions'} block onClick={setStatusAndUpdateEntity}>
                                    <Icon component={IconUnlock} style={{ color: '#A0A0A0' }} />
                                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                                </Button>
                            ) : null}
                            {
                                <Button
                                    className={'ant-btn-page-actions'}
                                    block
                                    onClick={deleteCrewMemberAndPushToList}
                                    disabled={!crewMember.deleteable}
                                >
                                    <Icon component={IconTrash} style={{ color: '#F4516C' }} />
                                    {localize(LocalizationEnum.ASPECT__ACTION__DELETE, 'span')}
                                </Button>
                            }
                        </>
                    }
                >
                    <AdditionalEntityActionsButton />
                </AdditionalEntityActions>
            </span>
        </>
    );
});

import React, {useCallback, useState} from 'react';
import {Icon, Upload} from "antd";
import {UploadFile} from "antd/lib/upload/interface";
import {LocalizationEnum, localize} from "../../localization";
import {IconTrash} from "../icons";
import {SimpleTable, SimpleTableColumn} from "../v2/simpleTable/SimpleTable";
import {CollapsibleBlock} from "../v2/collapsibleBlock/CollapsibleBlock";
import {formatBytes} from "../../shared/util/formatBytes";
import {AttachmentIcon} from "../../modules/main/attachments/attachmentIcon";
import {FileTypeCodeEnum} from "../../server";
import {SystemIcon, SystemIconType} from "../v2/systemIcon/systemIcon";
import {AddButton} from "../buttons/addButton/addButton";

interface UploadAttachmentsProps {
    onChange: (files:File[]|undefined) => void;
}

export const UploadAttachments = ({onChange, ...props}: UploadAttachmentsProps) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [collapsed, setCollapsed] = useState(false);

    let toLoadColumns:SimpleTableColumn<UploadFile>[] = [
        {
            id: 'type',
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            style: {lineHeight: 1},
            render:(value, row)=>{
                return <AttachmentIcon type={FileTypeCodeEnum.OTHERTYPE} style={{fontSize: 24}} />;
            }
        },
        {
            id: 'name',
            width: 200,
            style: {alignItems: 'flex-start', marginLeft: 16},
            render:(value, row)=>{
                return <div>
                    <div className={'rr-attachments-block_attachment-name'}>{row.name}</div>
                </div>;
            }
        },
        {
            id: 'size',
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            style: {alignItems: 'flex-end', paddingRight: 30},
            render:(value, row)=>{
                return formatBytes(row.size, 2);
            }
        },
        {
            id: 'uid',
            width: 28,
            minWidth: 28,
            maxWidth: 28,
            style: {lineHeight: 1},
            render:(value, row)=>{
                return (
                    <Icon style={{fontSize: 21, color: '#F4516C'}} component={IconTrash} onClick={()=>{
                        const files = [...fileList];
                        const index = files.findIndex(file=>file.uid === row.uid);
                        if(index > -1) files.splice(index, 1);
                        setFileList(files);
                        if (onChange) onChange(files && files.map(f=>f.originFileObj as File));
                    }} />
                );
            }
        },
    ];

    const beforeUpload = useCallback((file:UploadFile, filelist:UploadFile[]) => {
        return false;
    }, []);

    return (

            <CollapsibleBlock
                collapsed={collapsed || fileList.length === 0}
                onCollapse={(collapsed)=>{
                    setCollapsed(collapsed);
                }}
                className={'rr-attachments-block'}
                title={fileList.length === 0 ? 'Вложений нет' : <>{fileList.length} {localize(LocalizationEnum.ASPECT__PLURAL__ATTACHMENT, undefined, {value: fileList.length})}</>}
                icon={
                    <SystemIcon type={SystemIconType.attachment} />
                }
                style={{marginLeft: -30, marginRight: -30, marginBottom: -32, borderTop: '1px solid #d8dce6'}} //TODO тут сделано для формы
                extraContent={
                    <div onClick={(e)=>{e.stopPropagation();}}>
                        <Upload
                            onChange={(param)=>{
                                const files = param.fileList.length > 0 ? param.fileList : undefined;
                                setFileList(files || []);
                                if (onChange) onChange(files && files.map(f=>f.originFileObj as File));
                            }}
                            multiple={true}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            fileList={fileList}
                        >
                            <AddButton />
                        </Upload>
                    </div>
                }
                disabled={fileList.length === 0}
            >
                <SimpleTable columns={toLoadColumns} data={fileList} />
            </CollapsibleBlock>
    );
};

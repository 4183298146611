import promiseMiddleware from 'redux-promise-middleware';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import reducer from '../shared/reducers';
import errorMiddleware from './../config/error-middleware';
import { history } from '../config/store';
import customMiddlewares from './customMiddlewares';
import { documentTemplatesApi } from '../modules/main/settings/documentTemplates2/documentTemplatesApi';
import { inventoryMovementsApi } from '../modules/main/inventoryMovements/api/inventoryMovementsApi';
import { contactsApi } from '../modules/main/renters/tabs/contacts/api/contactsApi';
import { elementsApi } from '../modules/main/elements/api/elements.api';
import { expensesApi } from '../modules/main/expenses/api/expenses.api';
import { crewMembersApi } from '../modules/main/crew/tabs/crewMembers/api/crewMembers.api';
import { professionsApi } from '../modules/main/crew/tabs/professions/api/professions.api';
import { locationsApi } from '../modules/main/logistics/tabs/locations/api/locations.api';
import { transportationsApi } from '../modules/main/logistics/tabs/transportations/api/transportations.api';
import { vehiclesApi } from '../modules/main/logistics/tabs/vehicles/api/vehicles.api';
import { lifecycleEventsApi } from '../modules/main/history/tabs/lifecycleEvents/api/lifecycleEvents.api';
import { tagsApi } from '../components/select/TagSelect/api/tags.api';
import { workPlanningsApi } from '../modules/main/workPlannings/api/workPlannings.api';
import { elementsCalendarApi } from '../modules/main/calendar/tabs/elements/api/elementsCalendar.api';
import { productVariantsApi } from '../modules/main/inventory/variants/api/productVariants.api';
import { productInstancesApi } from '../modules/main/inventory/instances/api/productInstances.api';
import { activityFramesCalendarApi } from '../modules/main/calendar/tabs/activityFrames/api/activityFramesCalendar.api';
import { workPlanningsCalendarApi } from '../modules/main/calendar/tabs/workPlannings/api/workPlanningsCalendar.api';
import { productsApi } from '../modules/main/inventory/products/api/products.api';
import { offersApi } from '../modules/main/offers/api/offers.api';
import { paymentMethodsApi } from '../modules/main/settings/paymentMethods/api/paymentMethods.api';
import { legalDetailsApi } from '../modules/main/legalDetails/api/legalDetails.api';
import { operationFormApi } from '../modules/main/operationForm/api/operationForm.api';
import { dashboardApi } from '../modules/main/dashboard/api/rent/dashboardApi';
import { kitsApi } from '../modules/main/inventory/kits/api/kits.api';
import { paymentsApi } from '../modules/main/payments/api/payments.api';
import {summaryScheduleCalendarApi} from "../modules/main/calendar/tabs/summarySchedule/api/summaryScheduleCalendarApi";

export const initializeStore = (initialState?: any) => {
    const store = configureStore({
        reducer: reducer(history),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false, // TODO Избавиться от этого
                immutableCheck: false, // TODO Избавиться от этого
            })
                .concat([
                    thunk,
                    errorMiddleware,
                    promiseMiddleware,
                    routerMiddleware(history),
                    documentTemplatesApi.middleware,
                    inventoryMovementsApi.middleware,
                    productsApi.middleware,
                    kitsApi.middleware,
                    contactsApi.middleware,
                    locationsApi.middleware,
                    dashboardApi.middleware,
                    vehiclesApi.middleware,
                    paymentsApi.middleware,
                    transportationsApi.middleware,
                    expensesApi.middleware,
                    elementsApi.middleware,
                    professionsApi.middleware,
                    crewMembersApi.middleware,
                    tagsApi.middleware,
                    workPlanningsApi.middleware,
                    lifecycleEventsApi.middleware,
                    operationFormApi.middleware,
                    elementsCalendarApi.middleware,
                    productVariantsApi.middleware,
                    productInstancesApi.middleware,
                    activityFramesCalendarApi.middleware,
                    workPlanningsCalendarApi.middleware,
                    summaryScheduleCalendarApi.middleware,
                    offersApi.middleware,
                    paymentMethodsApi.middleware,
                    legalDetailsApi.middleware,
                ])
                .concat(customMiddlewares),
        preloadedState: initialState,
        devTools: process.env.NODE_ENV === 'development'
    });
    setupListeners(store.dispatch);
    return store;
};

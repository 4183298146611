import React, { ComponentProps, FC, memo } from 'react';
import { EntityLifecycleEventTypeCode } from '../../../../../../../server';
import { lifecycleEventIconMap } from './utils/iconMap';
import { lifecycleEventTitleMap } from './utils/titleMap';
import './EventTypeItem.less';

interface EventTypeIconProps extends ComponentProps<'div'> {
    typeCode: EntityLifecycleEventTypeCode;
}

export const EventTypeItem: FC<EventTypeIconProps> = memo((props) => {
    const { typeCode, ...divProps } = props;

    return (
        <div className={'event-type-container'} {...divProps}>
            {lifecycleEventIconMap[typeCode]}
            <span>{lifecycleEventTitleMap[typeCode]}</span>
        </div>
    );
});

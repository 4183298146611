import React, { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';

interface RentDelayBorderProps {
    width: number;
}

export const RentDelayBorder: FC<RentDelayBorderProps> = (props) => {
    const { width } = props;
    const { item } = useContext(ElementItemContext);

    return (
        <div
            className={'calendar-line-border'}
            style={{
                border: `2px solid ${CalendarColors.staticColors.WARNING_COLOR}`,
                inset: 0,
                width,
                zIndex: 3,
                borderRadius: 2,
            }}
        />
    );
};

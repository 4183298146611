import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Button, Icon, Popover } from 'antd';
import { IconLinkSolid, IconTags, IconUnlinkSolid } from '../../../icons';
import { ProjectRecord, TagEntityTypeCode, TagsBulkLinkObj } from '../../../../server';
import { TagSelect } from '../../../select/TagSelect/TagSelect';
import './TagsChangeAction.less';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useLinkTagsMutation } from '../../../select/TagSelect/api/tags.api';
import Spin from '../../../spin/spin';
import { RentElementsGridItem } from '../../../../types';
import { ListTagsArgs } from '../../../select/TagSelect/api/types';
import classNames from 'classnames';
import { TagsUtils } from '../../../select/TagSelect/utils/tags.utils';

export type TagEntity = ProjectRecord | RentElementsGridItem;

interface NestedAdditionalEntityActionsForTagsContentProps {
    entities: TagEntity[];
    tagType: TagEntityTypeCode;
    clearSelection: () => void;
    onSuccess?: () => void;
    parentEntity?: ListTagsArgs['parentEntity'];
}

export const TagsChangeAction: FC<NestedAdditionalEntityActionsForTagsContentProps> = memo((props) => {
    const { entities, tagType, clearSelection, onSuccess, parentEntity } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [linkTags, { isLoading }] = useLinkTagsMutation();

    const availableEntitiesForAssign: TagEntity[] =
        selectedTags.length > 0
            ? entities.filter(
                  (entity) =>
                      entity.tags == null ||
                      selectedTags.some((selectedTag) => !entity.tags!.some((tag) => TagsUtils.compare(tag, selectedTag)))
              )
            : [];

    const availableEntitiesForDetach: TagEntity[] =
        selectedTags.length > 0
            ? entities.filter(
                  (entity) =>
                      entity.tags != null &&
                      entity.tags.some((tag) => selectedTags.some((selectedTag) => TagsUtils.compare(tag, selectedTag)))
              )
            : [];

    const tagBulkLinkObj: TagsBulkLinkObj = useMemo(
        () => ({
            tags: selectedTags,
            entityTypeCode: tagType,
            entities: entities.map((project) => ({
                id: project.id,
                businessVersion: project.businessVersion,
            })),
        }),
        [entities, selectedTags, tagType]
    );

    const assignTags = useCallback(() => {
        linkTags({
            businessAccountId,
            createLink: true,
            tagBulkLinkObj,
        })
            .unwrap()
            .then(() => {
                if (onSuccess) onSuccess();
                clearSelection();
                setSelectedTags([]);
            });
    }, [businessAccountId, clearSelection, linkTags, onSuccess, tagBulkLinkObj]);

    const detachTags = useCallback(() => {
        linkTags({
            businessAccountId,
            createLink: false,
            tagBulkLinkObj,
        })
            .unwrap()
            .then(() => {
                if (onSuccess) onSuccess();
                clearSelection();
                setSelectedTags([]);
            });
    }, [businessAccountId, clearSelection, linkTags, onSuccess, tagBulkLinkObj]);

    return (
        <Popover
            overlayClassName={classNames('rr-grid-actions-popover', 'rr-grid-actions-popover-nested', 'tags-manager-popover')}
            autoAdjustOverflow
            placement="bottomLeft"
            trigger="click"
            align={{ offset: [12, 0] }}
            destroyTooltipOnHide
            content={
                <Spin spinning={isLoading}>
                    <div className={'rr-grid-actions-popover-header1'}></div>
                    <div className={'content-container'}>
                        <div className={'select-block'}>
                            <span>Теги</span>
                            <TagSelect
                                tagType={tagType}
                                value={selectedTags}
                                onChange={setSelectedTags}
                                withButton
                                parentEntity={parentEntity}
                                ignoreQueryFilters={true}
                            />
                        </div>
                        <div className={'rr-grid-actions-popover-content'}>
                            <div className={'action-block'}>
                                <Button
                                    className={'action-button green'}
                                    block
                                    disabled={availableEntitiesForAssign.length === 0}
                                    onClick={assignTags}
                                >
                                    <Icon className={'icon green'} component={IconLinkSolid} />
                                    <span>Назначить теги</span>
                                    {availableEntitiesForAssign.length > 0 ? (
                                        <span className={'categories-count'}>({availableEntitiesForAssign.length})</span>
                                    ) : undefined}
                                </Button>
                                <Button
                                    className={'action-button red'}
                                    block
                                    disabled={availableEntitiesForDetach.length === 0}
                                    onClick={detachTags}
                                >
                                    <Icon className={'icon red'} component={IconUnlinkSolid} />
                                    <span>Отвязать теги</span>
                                    {availableEntitiesForDetach.length > 0 ? (
                                        <span className={'categories-count'}>({availableEntitiesForDetach.length})</span>
                                    ) : undefined}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Spin>
            }
        >
            <div className={'rr-grid-actions-popover-content'} onClick={(event) => event.stopPropagation()}>
                <Button className={'ant-btn-page-actions'} block>
                    <Icon component={IconTags} style={{ color: '#383f77' }} />
                    <span>Изменить теги</span>
                </Button>
            </div>
        </Popover>
    );
});

import React, { FC, memo } from 'react';
import { IconCashRegisterSolid } from '../../../../../../../../components/icons';
import { DashboardData } from '../../../../../types/DashboardData';
import { DashboardWidgetBaseLinks } from '../../../../../components/DashboardWidget/instances/baseLinks/DashboardWidgetBaseLinks';

interface DashboardSubrentDebtWidgetProps {
    dashboardData: DashboardData;
}

export const DashboardSubrentDebtWidget: FC<DashboardSubrentDebtWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const { currentSubrentDebtSum, currentSubrentDebtSuppliersCount, currentSubrentDebtSubrentsCount, loading } = dashboardData;

    return (
        <DashboardWidgetBaseLinks
            loading={loading}
            color="rgb(82, 89, 147)"
            title={'Долг перед поставщиками'}
            elementsCount={currentSubrentDebtSuppliersCount}
            instancesCount={currentSubrentDebtSubrentsCount}
            icon={IconCashRegisterSolid}
            sum={currentSubrentDebtSum}
            isSubrent
        />
    );
});

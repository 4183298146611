import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { CustomFieldWithValueObjRead } from '../../../../server';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { formFields } from './product-variants-create-modal-fields';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps, ModalFormNEWState } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup, IFormField, IFormFieldImageUpload } from '../../../../components/dynamicForm/DynamicForm';
import { createEntity, resetVariant, resetVariantError, updateEntity } from './reducers/product-variant.reducer';
import { IntlShape } from 'react-intl';
import { loadAvailableFilters, loadEntities, setLoading } from './reducers/product-variants.reducer';
import {
    customFieldObjToFormFieldObj,
    findFieldGroupIndexById,
    findFieldInGroupsById,
    getCustomFieldIndexByFieldId,
    setCustomFieldsinFormData,
} from '../../../../shared/util/customFieldsUtils';
import { getImagePath } from '../../../../shared/util/utils';
import { loadEntity as LoadProduct } from '../products/reducers/product.reducer';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { resetInstances } from '../instances/reducers/product-instances.reducer';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IState extends ModalFormNEWState {
    formFields: FormFieldsGroup[];
    variantsCustomFieldMarkers?: CustomFieldWithValueObjRead[];
}

interface IProps extends ModalFormNEWProps {
    formFields: FormFieldsGroup[];
    goBackPath: string;
    intl: IntlShape;
    copyMode?: boolean;
}

type AllProps = IProps & StateProps & DispatchProps;

class _ProductVariantsCreateModal extends ModalFormNEW<AllProps, IState> {
    static defaultProps = {
        formFields: formFields,
    };

    constructor(props: AllProps) {
        super(props);

        const variantsCustomFieldMarkers =
            (this.props.initialValues && this.props.initialValues.customFields) || this.props.variantsCustomFieldMarkers || undefined;

        this.state = {
            ...this.state,
            variantsCustomFieldMarkers,
        };
    }

    _componentDidMount = () => {
        this.initializeFormFields();
    };

    initializeFormFields = () => {
        let formFields: FormFieldsGroup[] = [...this.props.formFields];

        formFields = formFields.map((group) => {
            return {
                ...group,
                fields: group.fields.map((field) => field),
                header: group.header,
                title: group.title,
                visible: group.visible,
            };
        });

        if (this.props.variantsCustomFieldMarkers && this.props.variantsCustomFieldMarkers.length > 0) {
            const header = localizeIntl(this.props.intl, LocalizationEnum.PAGE__VARIANTS__FORM__VARIANT_FEATURES) as string;
            formFields.splice(formFields.length - 1, 0, {
                header: header,
                fields: [] as (IFormField | IFormFieldImageUpload)[],
                title: undefined,
                visible: undefined,
            });
        }

        if (this.props.initialValues) {
            // Редактирование
            this.props.initialValues.customFields.forEach((field: CustomFieldWithValueObjRead) => {
                if (field.values) {
                    if (field.values.length > 1) {
                        field.values.forEach((v, index, arr) => {
                            formFields[formFields.length - 2].fields.push(customFieldObjToFormFieldObj(field, v, index));
                        });
                    } else {
                        formFields[formFields.length - 2].fields.push(customFieldObjToFormFieldObj(field, field.values[0], 0));
                    }
                }

                if (field.linkValues) {
                    formFields[formFields.length - 2].fields.push(
                        customFieldObjToFormFieldObj(field, field.multipleValues ? field.linkValues : field.linkValues[0], 0)
                    );
                }
            });
        } else if (this.props.variantsCustomFieldMarkers) {
            this.props.variantsCustomFieldMarkers.forEach((field) => {
                formFields[formFields.length - 2].fields.push(customFieldObjToFormFieldObj(field, '', 0));
            });
        }

        this.setState({
            formFields: formFields,
        });
    };

    getInitialValues = () => {
        let initialValues;

        if (this.props.initialValues) {
            initialValues = { ...this.props.initialValues };
            initialValues.productId = this.props.productId;
            if (this.props.editMode) {
                initialValues.variantEdit = true;
            }

            initialValues.productExternalCode = this.props.product!.externalCode;
            initialValues.ownExternalCode = initialValues.externalCode;

            initialValues.productPricePerShift = this.props.product!.pricePerShift;
            initialValues.ownPricePerShift = initialValues.pricePerShift;

            if (this.props.initialValues.image) {
                initialValues.image = [
                    {
                        uid: this.props.initialValues.image.id,
                        url: getImagePath(this.props.initialValues.image),
                        response: this.props.initialValues.image,
                    },
                ];
            }

            initialValues.useExternalCodeProduct = !initialValues.hasOwnExternalCode;

            initialValues.useExternalCodeProductDefault = initialValues.useExternalCodeProduct;

            initialValues.usePricePerShiftProduct = !initialValues.hasOwnPricePerShift;
        } else {
            initialValues = {
                productId: this.props.productId,
                productExternalCode: this.props.product!.externalCode,
                productPricePerShift: this.props.product!.pricePerShift,

                externalCode: this.props.product!.externalCode,
                pricePerShift: this.props.product!.pricePerShift,
            };
        }

        return initialValues;
    };

    getFormFields = () => {
        const { formFields } = this.state;

        return this.getFilteredForms(formFields, false);
    };

    addField = (fieldName: string, index: number) => {
        let formFields = [...this.state.formFields];
        let baseField = findFieldInGroupsById(formFields, `${fieldName}[${index}]`);
        let groupIndex = findFieldGroupIndexById(formFields, `${fieldName}[${index}]`);

        if (baseField !== null && groupIndex !== null) {
            baseField = { ...baseField, values: [], defaultValue: undefined };

            let maxId = Math.max(
                ...formFields[groupIndex].fields
                    .filter((f) => {
                        return f.id.includes(fieldName);
                    })
                    .map((f, index) => {
                        return getCustomFieldIndexByFieldId(f.id) || 0;
                    })
            );

            baseField.id = `${fieldName}[${maxId + 1}]`;

            let filteredFields = formFields[groupIndex].fields.filter((f) => f.id.indexOf(fieldName) === 0);
            if (filteredFields) {
                formFields[groupIndex].fields.splice(
                    formFields[groupIndex].fields.indexOf(filteredFields[filteredFields.length - 1]) + 1,
                    0,
                    baseField
                );
            }

            this.setState((prevState) => ({
                ...prevState,
                formFields,
            }));
        }
    };

    removeField = (fieldName: string, index: number) => {
        let formFields = [...this.state.formFields];
        let groupIndex = findFieldGroupIndexById(formFields, `${fieldName}[${index}]`);

        if (groupIndex !== null) {
            let removeFrom = formFields[groupIndex].fields.findIndex((f) => {
                return f.id === `${fieldName}[${index}]`;
            });
            if (removeFrom > -1) {
                formFields[groupIndex].fields.splice(removeFrom, 1);
                this.setState({
                    formFields,
                });
            }
        }
    };

    onOk = async (data: any) => {
        let imageId: number =
            data.image && data.image[0] && data.image[0].response && data.image[0].response.id ? data.image[0].response.id : undefined;
        delete data.image;

        let { productId, productBusinessVersion } = this.props;

        if (productId !== undefined && productBusinessVersion !== undefined) {
            if (this.props.editMode) {
                // Редактирование
                if (this.props.initialValues) {
                    let newData = { ...this.props.initialValues, ...data };
                    newData.image = imageId ? { id: imageId } : undefined;
                    newData.hasOwnPricePerShift = !data.usePricePerShiftProduct;
                    newData.hasOwnExternalCode = !data.useExternalCodeProduct;

                    if (!newData.hasOwnExternalCode) {
                        newData.externalCode = undefined;
                    }

                    newData.stockInstanceCount = newData.warehousePresenceCounters.stockInstanceCount;
                    newData.underMaintenanceInstanceCount = newData.warehousePresenceCounters.underMaintenanceInstanceCount;

                    delete newData.useExternalCodeProduct;
                    delete newData.useExternalCodeProductDefault;
                    delete newData.ownExternalCode;
                    delete newData.ownPricePerShift;
                    delete newData.productExternalCode;
                    delete newData.productPricePerShift;
                    delete newData.variantEdit;
                    delete newData.warehousePresenceCounters;

                    if (newData.barcode === '') delete newData.barcode;
                    if (newData.comment === '') delete newData.comment;

                    newData = setCustomFieldsinFormData(newData);

                    let result = await this.props.updateEntity(this.props.businessAccountId, productId, newData.id || 0, newData);
                    if (!(result instanceof Error)) {
                        this.props.setLoading(true);
                        showNotification(
                            'success',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__VARIANTS__POPUP_NOTIFICATIONS__VARIANT_UPDATED)
                        );
                        this.props.push(this.props.goBackPath);
                        setTimeout(() => {
                            if (productId) this.props.loadEntities(this.props.intl, this.props.businessAccountId, productId);
                        }, 1500);
                        this.props.LoadProduct(this.props.businessAccountId, productId);
                        this.props.resetVariantError();
                        this.props.resetVariant();
                        this.props.loadAvailableFilters(this.props.businessAccountId, productId);
                    }
                }
            } else {
                // Создание
                let newData = { ...data };
                //if (newData.pricePerShift) newData.pricePerShift *= 100;
                //else newData.pricePerShift = undefined;
                newData.stockInstanceCount = 0;
                newData.underMaintenanceInstanceCount = 0;
                newData.productBusinessVersion = productBusinessVersion;
                newData.image = imageId ? { id: imageId } : undefined;

                newData.hasOwnPricePerShift = !data.usePricePerShiftProduct;
                newData.hasOwnExternalCode = !data.useExternalCodeProduct;

                if (data.usePricePerShiftProduct) {
                    newData.pricePerShift = undefined;
                }

                if (data.useExternalCodeProduct) {
                    newData.externalCode = undefined;
                }

                if (!newData.pricePerShift) {
                    newData.pricePerShift = 0;
                }

                newData = setCustomFieldsinFormData(newData);

                delete newData.useExternalCodeProduct;

                if (this.props.copyMode) {
                    delete newData.useExternalCodeProduct;
                    delete newData.useExternalCodeProductDefault;
                    delete newData.ownExternalCode;
                    delete newData.ownPricePerShift;
                    delete newData.productExternalCode;
                    delete newData.productPricePerShift;
                    delete newData.variantEdit;
                }

                let result = await this.props.createEntity(this.props.businessAccountId, productId, newData); // TODO Тут должен возвращаться типизированный ответ
                if (!(result instanceof Error)) {
                    showNotification(
                        'success',
                        localizeIntl(this.props.intl, LocalizationEnum.PAGE__VARIANTS__POPUP_NOTIFICATIONS__CREATED)
                    );
                    if (!this.props.onSuccess) {
                        this.props.setLoading(true);
                        this.props.push(this.props.goBackPath);
                        setTimeout(() => {
                            if (productId) this.props.loadEntities(this.props.intl, this.props.businessAccountId, productId);
                        }, 1500);
                        this.props.LoadProduct(this.props.businessAccountId, productId);
                    } else {
                        this.props.onSuccess(result['value'].data); // TODO Тут потом типизировать норм
                    }
                    this.props.resetVariantError();
                    this.props.resetVariant();
                    this.props.loadAvailableFilters(this.props.businessAccountId, productId);
                }
            }
        }

        this.props.resetInstances();
    };

    onCancel = async (isFieldsTouched: boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            if (!this.props.onCancel) {
                this.props.push(this.props.goBackPath);
            } else {
                this.props.onCancel();
            }
            this.props.resetVariantError();
            this.props.resetVariant();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.productVariant.updating,
    updatingError: storeState.productVariant.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    productId: storeState.product.entity?.id,
    product: storeState.product.entity,
    productBusinessVersion: storeState.product.entity?.businessVersion,
    variantsCustomFieldMarkers: storeState.product.entity?.variantsCustomFieldMarkers,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    createEntity,
    updateEntity,
    loadAvailableFilters,
    push,
    resetVariantError,
    resetVariant,
    loadEntities,
    setLoading,
    LoadProduct,
    resetInstances,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProductVariantsCreateModal = connect(mapStateToProps, mapDispatchToProps)(_ProductVariantsCreateModal);

import { useCallback, useMemo } from 'react';
import { LocationInfoRead } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { logisticsPageUrlRoute } from '../../../../logistics/data/tabs';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { push } from 'connected-react-router';
import { PageUtils } from '../../../../../../shared/util/pageUtils';

import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import {
    useArchiveLocationsMutation,
    useDeleteLocationMutation,
    useLazyLocationByIdQuery,
} from '../../../../logistics/tabs/locations/api/locations.api';

export const useLocationDrawerActions = () => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const logisticsRoute = `/${businessAccountId}/${logisticsPageUrlRoute}` as const;
    const rootPath = `${logisticsRoute}/${LogisticsPageTabsEnum.LOCATIONS}` satisfies EntityGridRootPath;

    const [archiveLocations, { isLoading: isArchiveLocationsLoading }] = useArchiveLocationsMutation();

    const [loadLocation, { isLoading: isLoadLocationLoading }] = useLazyLocationByIdQuery();

    const [deleteLocation, { isLoading: isDeleteLocationLoading }] = useDeleteLocationMutation();

    const isLoading = isArchiveLocationsLoading || isLoadLocationLoading || isDeleteLocationLoading;

    const editLocationCallback = useCallback(
        (location: LocationInfoRead) => {
            loadLocation({
                businessAccountId: businessAccountId,
                entityId: location.id,
            });

            const searchParams = new URLSearchParams(PageUtils.getPathForPushOnPageTab(logisticsRoute, LogisticsPageTabsEnum.LOCATIONS));
            searchParams.append('modal', 'edit');
            searchParams.append('id', String(location.id));

            const path = `${rootPath}/?` + searchParams.toString();
            dispatch(push(rootPath));
            dispatch(push(path));
        },
        [businessAccountId, dispatch, loadLocation, logisticsRoute, rootPath]
    );

    const archiveLocationCallback = useCallback(
        (location: LocationInfoRead) => {
            archiveLocations({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: location.id,
                            archive: !location.archive,
                            businessVersion: location.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveLocations]
    );

    const deleteLocationCallback = useCallback(
        async (location: LocationInfoRead) => {
            deleteLocation({
                id: location.id,
                businessVersionObj: {
                    businessVersion: location.businessVersion,
                },
            });
        },
        [deleteLocation]
    );

    return useMemo(
        () => ({
            edit: (location: LocationInfoRead) => editLocationCallback(location),
            archive: (location: LocationInfoRead) => archiveLocationCallback(location),
            delete: (location: LocationInfoRead) => deleteLocationCallback(location),
            isLoading,
        }),
        [archiveLocationCallback, deleteLocationCallback, editLocationCallback, isLoading]
    );
};

import React, { ComponentProps, FC, memo } from 'react';
import { CustomMarker, CustomMarkerChildrenProps } from 'react-calendar-timeline';
import './CalendarMarker.less';
import classNames from 'classnames';

interface CalendarMarkerProps extends ComponentProps<'div'> {}

export const CalendarMarker: FC<CalendarMarkerProps> = memo((props) => {
    const { ...divProps } = props;

    return (
        <CustomMarker date={Date.now()}>
            {(props: CustomMarkerChildrenProps) => (
                <div
                    {...divProps}
                    className={classNames('calendar-marker', divProps.className)}
                    style={{
                        ...props.styles,
                        ...divProps.style,
                    }}
                />
            )}
        </CustomMarker>
    );
});

import { connect } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { formFields } from './instance-create-modal-fields';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { IntlShape } from 'react-intl';
import { loadEntity as LoadProduct } from '../../../inventory/products/reducers/product.reducer';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { InstanceInfoCreate, InstanceInfoRead, InstanceStateCodeEnum, ProductInfoRead, VariantInfoRead } from '../../../../../server/api';
import { serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { showNotification } from '../../../../../components/notification/showNotification';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    intl: IntlShape;
    onClose: () => void;
    onInstanceCreated: (instance: InstanceInfoRead) => void;
    productId: number;
    variantId?: number;
    supplierId?: number;
    productBusinessVersion: number;
    variantBusinessVersion?: number;
}

class _InstanceQuickCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
        zIndex: 9999,
        updating: false,
    };

    componentDidMount = () => {};

    getInitialValues = () => {
        return {
            ...this.props.initialValues,
            productId: this.props.productId,
        };
    };

    onOk = async (data: any) => {
        let product: AxiosResponse<ProductInfoRead> | undefined;
        let variant: AxiosResponse<VariantInfoRead> | undefined;

        try {
            product = await serverApi.getProductById(this.props.businessAccountId, this.props.productId);
            variant = this.props.variantId
                ? await serverApi.getProductVariantById(this.props.businessAccountId, this.props.productId, this.props.variantId)
                : undefined;
        } catch (e) {
            showNotification('error', 'Не удалось создать экземпляр');
        }

        if (product) {
            let newInstance: InstanceInfoCreate = {
                businessVersion: 1,
                nameOrCode: data.nameOrCode,
                productBusinessVersion: product.data.businessVersion,
                stateCode: InstanceStateCodeEnum.SUPPLIERACTIVE,
                inventoryNumber: data.inventoryNumber,
                supplierId: this.props.supplierId,
                variantBusinessVersion: variant?.data.businessVersion,
            };

            let result: AxiosResponse<InstanceInfoRead> | undefined;
            if (this.props.variantId) {
                try {
                    result = await serverApi.createProductVariantInstance(
                        this.props.businessAccountId,
                        this.props.productId,
                        this.props.variantId,
                        newInstance
                    );
                } catch (e) {
                    showNotification('error', 'Не удалось создать экземпляр');
                }
            } else {
                try {
                    result = await serverApi.createProductInstance(this.props.businessAccountId, this.props.productId, newInstance);
                } catch (e) {
                    showNotification('error', 'Не удалось создать экземпляр');
                }
            }
            if (result) this.props.onInstanceCreated(result.data);
            this.props.onClose();
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.onClose();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        modalFilters: storeState.userSettings.modalFilters,
    };
};

const mapDispatchToProps = { push, LoadProduct, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const InstanceQuickCreateModal = connect(mapStateToProps, mapDispatchToProps)(_InstanceQuickCreateModal);

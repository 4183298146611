import React, { FC } from 'react';
import { ProjectInfoRead } from '../../../../../../server';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { HeaderNavigationBlock } from '../../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { IconBriefcase } from '../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Link } from 'react-router-dom';
import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { ArchiveBadge } from '../../../../../../components/archiveBadge/archiveBadge';
import { rentersPageUrlRoute } from '../../../../renters/data/tabs';
import { ProjectStateBadge } from '../ProjectStateBadge/ProjectStateBadge';
import { ProjectStateCode } from '../../data/projectData';
import classNames from 'classnames';

interface ProjectPageHeaderNavigationBlockProps {
    project: ProjectInfoRead | undefined;
}

export const ProjectPageHeaderNavigationBlock: FC<ProjectPageHeaderNavigationBlockProps> = (props) => {
    const { project } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (project == null) return null;

    const { stateCode, renterShortName, renterId, archive, fullName, shortName } = project;
    const name = fullName || shortName;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon className={'icon'} component={IconBriefcase} />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                        <ProjectStateBadge type={stateCode as ProjectStateCode} />
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES)}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${renterId}`}
                            className={classNames('link', 'bold')}
                        >
                            {renterShortName}
                        </Link>
                        <span>/</span>
                        <Link
                            to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${renterId}?tab=projects`}
                            className={'link'}
                        >
                            {localize(LocalizationEnum.ASPECT__MAIN_MENU__PROJECTS)}
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

import React, { useContext } from 'react';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { CalendarItem, WorkPlanningsCalendarItem as IWorkPlanningsCalendarItem } from '../../../../components/Calendar/types/items';
import { CalendarLineContainer } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarLineContainer/CalendarLineContainer';
import { CALENDAR_LINE_BASE_RADIUS } from '../../../../components/Calendar/utils/data/calendarConstants';
import { WorkPlanningItemContext } from '../../../../components/CalendarItemRenderer/context/CalendarItemContext';
import { WorkPlanningsCalendarItem } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarItem/variants/WorkPlanningsCalendarItem';
import { CalendarItemRendererProps } from '../../../../components/CalendarItemRenderer/CalendarItemRenderer';
import classNames from 'classnames';
import { TimeRangeContext } from '../../../../components/CalendarItemRenderer/context/TimeRangeContext';

export const WorkPlanningsItemRenderer = <SortValue extends string>(
    props: CalendarItemRendererProps<SortValue, IWorkPlanningsCalendarItem>
) => {
    const { baseProps, calendarProps } = props;
    const { displayType, onItemClick } = baseProps;
    const { item } = calendarProps;
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);
    const { start_time, end_time } = item;

    return (
        <CalendarLineContainer
            calendarProps={calendarProps}
            displayType={displayType}
            borderRadius={CALENDAR_LINE_BASE_RADIUS}
            onItemClick={onItemClick}
            linkPath={undefined}
            className={classNames('item', {
                'left-boundary': +start_time <= visibleTimeStart,
                'right-boundary': +end_time >= visibleTimeEnd,
            })}
        >
            <WorkPlanningItemContext.Provider
                value={calendarProps as ReactCalendarItemRendererProps<CalendarItem<IWorkPlanningsCalendarItem>>}
            >
                <WorkPlanningsCalendarItem />
            </WorkPlanningItemContext.Provider>
        </CalendarLineContainer>
    );
};

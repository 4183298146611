import React, { FC } from 'react';
import { IconUsers } from '../../../../../../../../components/icons';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { rentersPageUrlRoute } from '../../../../../../renters/data/tabs';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { ElementsCalendarItem } from '../../../../../components/Calendar/types/items';

import { RentersPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';

export const RenterGroupLine: FC<GroupItemProps<ElementsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { id, record } = groupItem;
    const { counterpartyShortName: title } = record;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const link = `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${id}`;

    return title ? (
        <GroupLine
            IconComponent={IconUsers}
            displayType={displayType}
            title={{
                text: title,
                link: link,
            }}
        />
    ) : null;
};

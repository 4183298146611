import React, { memo } from 'react';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { editVehicleModalFilters } from '../create/VehicleCreateModalData';
import { VehicleEditModal } from './VehicleEditModal';
import { VehicleInfoRead } from '../../../../../../../server';
import { useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { EntityGridEditModalWrapper } from '../../../../../../../components/page/entityGrid/types/modal';

export const VehicleEditModalWrapper: EntityGridEditModalWrapper<VehicleInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <VehicleEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование транспорта'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            filters={editVehicleModalFilters}
            modalName={'edit-vehicle'}
            vehicle={entity}
        />
    );
});

import React from 'react';
import {connect} from "react-redux";
import {IRootState} from "../../../shared/reducers";
import {BasePage, IBasePageProps} from "../../../components/page/BasePage";
import {push} from "connected-react-router";
import {IPageHeaderProps} from "../../../components/pageHeader/pageHeader";
import {iframeBaseParams} from "./support-page";
import Spin from "../../../components/spin/spin";
import './support-page.less';
import {SUPPORT_SERVER_BASE_PATH} from "../../../config/config";
import {Col, Row} from "antd";
import {ImgShowModal} from "../kb/img-show-modal";

interface IProps extends StateProps, DispatchProps, IBasePageProps {
}

interface IState {
    frameHeight?: number;
    requestTypeTitle?: string;
    frameIsLoading: boolean;
    imgSrc?: string;
}

class SupportRequestsPage extends BasePage<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            frameHeight: 0,
            frameIsLoading: true
        };
    }

    onImageHide = () => {
        this.setState({imgSrc: undefined});
    }
    handleIframeTask = (e: MessageEvent) => {
        if (e.data) {
            let {type} = e.data;

            if (type === 'sd_request_resized') {
                this.setState({frameHeight: e.data.height});
            } else if (type === 'sd_view_image') {
                this.setState({imgSrc: e.data.src});
            } else if (type === 'sd_view_wiki') {
                this.props.push(`/${this.props.businessAccountId}/kb/${e.data.page_id}`);
            }
        }
    };

    componentDidMount() {
        window.addEventListener('message', this.handleIframeTask);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeTask);
    }

    getPageHeaderProps(): IPageHeaderProps {
        return {
            name: this.props.requestId,
            parentPagePath: `/${this.props.businessAccountId}/support/requests`
        };
    }

    frameOnLoad = (e) => {
        this.setState((state, props) => {
            let newState = {...state, frameIsLoading: false};
            if (!newState.frameHeight) newState.frameHeight = 100;
            return newState;
        });
    };

    renderContent() {
        const frameProps: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = {
            src: `${SUPPORT_SERVER_BASE_PATH}/plugins/servlet/ru.rentrabbit.jira.jira-plugin/iframe/${this.props.userUUID}/${this.props.businessAccountId}/request/${this.props.requestId}?os_authType=none`,
            height: this.state.frameHeight,
            onLoad: this.frameOnLoad
        };

        return (
            <>
                <Row type="flex" justify="center">
                    <Col span={24} style={{maxWidth: '1024px'}}>
                        <Spin delay={0} spinning={this.state.frameIsLoading} wrapperClassName={'rr-support-page-spin'}>
                            <iframe {...iframeBaseParams} {...frameProps} title={'Запрос'}/>
                        </Spin>
                    </Col>
                </Row>
                {this.state.imgSrc && <ImgShowModal onCancel={this.onImageHide} src={this.state.imgSrc}/>}
            </>
        );
    }
}

const mapDispatchToProps = {push};

const mapStateToProps = (storeState: IRootState, ownProps: IBasePageProps) => ({
    businessAccountId: storeState.system.businessAccountId,
    userUUID: storeState.oidc.user ? storeState.oidc.user.profile.sub : '',
    requestId: ownProps.match.params['id']
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupportRequestsPage);
import React, {FocusEventHandler} from 'react';
import {Input} from "antd";

interface State {
    focus: boolean;
    prevValue: string|undefined;
}

interface GroupNameInlineInputProps {
    value:any;
    onChange:any;
    canEdit:boolean;
    onStartEdit:()=>void;
}

export class GroupNameInlineInput extends React.Component<GroupNameInlineInputProps, State> {

    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            prevValue: this.props.value
        };
    }

    onKeyDown = (e:KeyboardEvent) => {
        if (e.key === 'Escape'){
            this.setValueAndBlur(this.state.prevValue);
        }
    };

    onFocus:React.MouseEventHandler = () => {
        if(this.props.canEdit && this.props.onStartEdit) {
            this.props.onStartEdit();
            this.setState({focus: true, prevValue: this.props.value});
            window.addEventListener('keydown', this.onKeyDown);
        }
    };

    onInputFocus:FocusEventHandler<HTMLInputElement> = (e) => {
        e.currentTarget.select();
    };

    onBlur:FocusEventHandler<HTMLInputElement> = (e) => {
        let value = e.currentTarget.value;
        if(!value) value = this.props.value;
        this.setValueAndBlur(value);
    };

    onPressEnter:React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        let value = e.currentTarget.value;
        if(!value) value = this.props.value;
        this.setValueAndBlur(value);
    };

    setValueAndBlur = (value:string|undefined) => {
        if(this.props.onChange){
            this.props.onChange(value);
        }
        this.setState({focus: false});
        window.removeEventListener('keydown', this.onKeyDown);
    };

    render() {
        return (
            this.state.focus && this.props.canEdit ?
                <Input onPressEnter={this.onPressEnter} onFocus={this.onInputFocus} maxLength={30} style={{maxWidth: 500, fontSize: 18}} autoFocus defaultValue={this.props.value} onBlur={this.onBlur}/> :
                <span className={'rr-product-page-features-edit-group-name' + (this.props.canEdit ? '-editable' : '')} onClick={this.onFocus}>{this.props.value}</span>
        );
    }
}

import { useCallback, useMemo, useState } from 'react';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';

type UseDashboardGridState = [
    {
        page: number | undefined;
        pageSize: number | undefined;
        period: string | undefined;
        sortBy: string | undefined;
        sortOrder: boolean | undefined;
    },
    {
        onPageChanged: (page: number) => void;
        onPeriodChange: () => void;
        onSetPeriod: (value?: string) => void;
        onSortedChange: (id: string, desc: boolean) => void;
    }
];

export const useDashboardGridState = (gridName: string): UseDashboardGridState => {
    const gridData = getGridStorageData(gridName);

    const [page, setPage] = useState<number | undefined>(1);
    const [pageSize, setPageSize] = useState<number | undefined>(10);
    const [period, setPeriod] = useState<string | undefined>(gridData.filters?.period);
    const [sortBy, setSortBy] = useState<string | undefined>(gridData.params?.sortBy || 'date');
    const [sortOrder, setSortOrder] = useState<boolean | undefined>(gridData.params?.sortOrder === 'DESC' || false);

    const onPageChanged = useCallback((page: number) => {
        setPage(page);
    }, []);

    const onPeriodChange = useCallback(() => {
        setPage(1);
        setPageSize(10);
    }, []);

    const onSetPeriod = useCallback(
        (value?: string) => {
            setGridStorageDataFilters(gridName, { period: value });
            setPeriod(value);
            onPeriodChange();
        },
        [gridName, onPeriodChange]
    );

    const onSortedChange = useCallback((id: string, desc: boolean) => {
        setSortBy(id);
        setSortOrder(desc);
    }, []);

    return useMemo(
        () => [
            {
                page,
                pageSize,
                period,
                sortBy,
                sortOrder,
            },
            {
                onPageChanged,
                onPeriodChange,
                onSetPeriod,
                onSortedChange,
            },
        ],
        [onPageChanged, onPeriodChange, onSetPeriod, onSortedChange, page, pageSize, period, sortBy, sortOrder]
    );
};

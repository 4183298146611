import React, {memo} from 'react';
import {Icon, Spin as OriginalSpin} from 'antd';
import {SpinProps} from 'antd/lib/spin';
import './spin.less';
import classNames from 'classnames';

interface IProps extends SpinProps {
    children?: any;
}

const customSpinIndicator = <Icon type={'loading'} spin />;

const Spin = memo((props: IProps) => (
    <OriginalSpin
        tip={props.tip}
        style={props.style}
        className={classNames('rr-spin', props.className)}
        wrapperClassName={'rr-spin-wrapper ' + (props.wrapperClassName ? props.wrapperClassName : '')}
        size={props.size}
        spinning={props.spinning}
        delay={props.delay !== undefined ? props.delay : 300}
        indicator={props.indicator ? props.indicator : customSpinIndicator}
    >
        {props.children}
    </OriginalSpin>
));

export default Spin;

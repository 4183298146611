import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../store/rtqQueryApi';
import { ListDashboardArgs } from './dashboard.api.types';
import { showNotification } from '../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { GroupedRentElementRecordList, serverApi } from '../../../../../server';
import { DashboardApiUtils } from './dashboardApiUtils';
import { AxiosError } from 'axios';
import { IRootState } from '../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        listDashboard: builder.query<GroupedRentElementRecordList, ListDashboardArgs>({
            queryFn: async (args, { getState }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const { intl, params, side, isSubrent, filters } = args;
                const { sortOrder: _sortOrder, page } = params;

                const limit = 10;
                const startFrom = page != null && page > 1 ? (page - 1) * limit : 0;
                const sortOrder = _sortOrder ? 'DESC' : 'ASC';

                const sortBy = DashboardApiUtils.getDashboardFilterSortBy(params.sortBy);

                const periodStartDate = {
                    left: true,
                    right: false,
                }[side];

                try {
                    const { data } = await serverApi.listBusinessAccountElementsGroupedByPeriod(
                        businessAccountId,
                        isSubrent,
                        periodStartDate,
                        limit,
                        startFrom,
                        undefined,
                        sortBy,
                        sortOrder,
                        undefined,
                        {
                            query: {
                                filters,
                            },
                        }
                    );

                    return { data };
                } catch (error) {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));

                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useListDashboardQuery } = dashboardApi;

import { createApi } from '@reduxjs/toolkit/query/react';
import { DeleteProfessionArgs, DeleteProfessionsArgs, ListProfessionsArgs, ListProfessionsData, TransitProfessionsArgs } from './types';
import { AxiosError } from 'axios';
import { ProfessionsApiUtils } from './professions.api.utils';
import { GetEntityByIdArgs } from '../../../../../../components/page/entityGrid/types/api';
import { serverApi } from '../../../../../../server';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export const professionsApi = createApi({
    reducerPath: 'professionsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ProfessionsList', 'Profession'],
    endpoints: (builder) => ({
        listProfessions: builder.query<ListProfessionsData, ListProfessionsArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = ProfessionsApiUtils.createRequestFilters(params);

                try {
                    const { data: professionsListAvailableFilters } = await serverApi.listProfessionsAvailableFiltersValues(
                        businessAccountId
                    );
                    const { data: professions } = await serverApi.listProfessions(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        params.sortBy || undefined,
                        params.sortOrder,
                        params.search
                    );
                    return {
                        data: {
                            entitiesData: professions,
                            availableIntervalFiltersData: {
                                values: professionsListAvailableFilters,
                                maps: {
                                    formats: ProfessionsApiUtils.pricePerShiftIntervalFormatMap,
                                    metrics: ProfessionsApiUtils.pricePerShiftIntervalMetricsMap,
                                },
                            },
                        },
                    };
                } catch (e) {
                    return {
                        error: e as AxiosError,
                    };
                }
            },
            providesTags: ['ProfessionsList'],
        }),
        professionById: builder.query({
            queryFn: async (args: GetEntityByIdArgs) => {
                const { businessAccountId, entityId } = args;

                try {
                    return await serverApi.getProfessionById(businessAccountId, entityId);
                } catch (error) {
                    showNotification('error', 'Не удалось загрузить специальность');
                    const errorMessage = error instanceof Error ? error.message : undefined;
                    throw new Error(errorMessage);
                }
            },
            providesTags: ['Profession'],
        }),
        deleteProfessions: builder.mutation<void, DeleteProfessionsArgs>({
            queryFn: async (args, { getState }) => {
                const { versionedEntityObjList } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteProfessionsBulk(businessAccountId, versionedEntityObjList);
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Специальности успешно удалены' : 'Специальность успешно удалена';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1
                            ? 'Не удалось удалить специальности'
                            : 'Не удалось удалить специальность';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['ProfessionsList', 'Profession'],
        }),
        deleteProfession: builder.mutation<void, DeleteProfessionArgs>({
            queryFn: async (args, { getState }) => {
                const { id, businessVersionObj } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteProfessionById(businessAccountId, id, businessVersionObj);
                    showNotification('success', 'Специальность успешно удалена');

                    return { data: undefined };
                } catch (error: unknown) {
                    showNotification('error', 'Специальность не удалена');

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['ProfessionsList'/*, 'Profession'*/],
        }),
        setStatusForProfessions: builder.mutation<void, TransitProfessionsArgs>({
            queryFn: async (args, { getState }) => {
                const { workflowDirectiveBulk } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitProfessionWorkflowBulk(businessAccountId, workflowDirectiveBulk);

                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Статус специальностей успешно обновлен'
                            : 'Статус специальности успешно обновлен';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Не удалось обновить статус специальностей'
                            : 'Не удалось обновить статус специальности';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['ProfessionsList', 'Profession'],
        }),
    }),
});

export const {
    useListProfessionsQuery,
    useProfessionByIdQuery,
    useLazyProfessionByIdQuery,
    useSetStatusForProfessionsMutation,
    useDeleteProfessionsMutation,
    useDeleteProfessionMutation,
} = professionsApi;

import React from 'react';
import {FormFieldsGroup, FormItemType, SelectItem} from '../../../../../../components/dynamicForm/DynamicForm';
import {CounterpartyActivityTypeCodeEnum, RenterStateCodeEnum} from '../../../../../../server';
import debounce from 'lodash/debounce';
import {getStoreState} from '../../../../../../../index';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import {validateField} from '../../../../../../shared/util/validateField';
import {EntityRemoteSelect} from '../../../../../../components/select/EntityRemoteSelect';
import {LocalizationEnum, localize} from '../../../../../../localization';
import {CounterpartyCategoriesSelect} from '../categories/CounterpartyCategoriesSelect';
import {canUpdateFinancialData, canViewFinancialData} from '../../../../../../shared/util/permissionUtils';
import {createHiddenField} from '../../../../../../components/modalForm/utils';
import {subrentModuleEnabledSelector} from '../../../../../../shared/reducers/businessAccountPreferences.reducer';

const isStateCodeDisabled = (getFieldValue: Function) => getFieldValue('id');

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('counterpartyType'),
            createHiddenField('renterEdit'),
            createHiddenField('hasSubrents'),
            createHiddenField('hasProjects'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                required: true,
                type: FormItemType.String,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('renterEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'renter',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__RENTERS__CARD__SHORT_NAME_ALREADY_USE),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('renterEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'renter',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__RENTERS__CARD__FULL_NAME_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: RenterStateCodeEnum.ACTIVE,
                required: true,
                values: (form: WrappedFormUtils) => {
                    const values: SelectItem[] = [
                        {
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__NEW),
                            value: RenterStateCodeEnum.NEW,
                        },
                        {
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__ACTIVE),
                            value: RenterStateCodeEnum.ACTIVE,
                        },
                    ];

                    const isFieldDisabled = isStateCodeDisabled(form.getFieldValue);
                    if (isFieldDisabled) {
                        values.push({
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__BLOCKED),
                            value: RenterStateCodeEnum.BLOCKED,
                        });
                    }

                    return values.map((item) => {
                        return item
                            ? {
                                  name: (
                                      <>
                                          <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                                          <span className="calendar-multi-select">{item.name}</span>{' '}
                                      </>
                                  ),
                                  value: item.value,
                              }
                            : undefined;
                    });
                },
                disabled: (getFieldValue) => isStateCodeDisabled(getFieldValue),
            },
            {
                label: 'Вид деятельности',
                placeholder: 'Выберите вид деятельности',
                id: 'activityTypeCode',
                type: FormItemType.Select,
                required: true,
                values: (form: WrappedFormUtils) => {
                    if (form.getFieldValue('counterpartyType') === 'renter') {
                        return [
                            !form.getFieldValue('hasSubrents')
                                ? {
                                      name: 'Арендатор',
                                      value: CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                  }
                                : undefined,
                            {
                                name: 'Арендатор и поставщик',
                                value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            },
                        ].filter((i) => i !== undefined);
                    } else if (form.getFieldValue('counterpartyType') === 'supplier') {
                        return [
                            !form.getFieldValue('hasProjects')
                                ? {
                                      name: 'Поставщик',
                                      value: CounterpartyActivityTypeCodeEnum.SUPPLIERONLY,
                                  }
                                : undefined,
                            {
                                name: 'Арендатор и поставщик',
                                value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            },
                        ].filter((i) => i !== undefined);
                    } else {
                        return [
                            !form.getFieldValue('hasSubrents')
                                ? {
                                      name: 'Арендатор',
                                      value: CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                  }
                                : undefined,
                            !form.getFieldValue('hasProjects')
                                ? {
                                      name: 'Поставщик',
                                      value: CounterpartyActivityTypeCodeEnum.SUPPLIERONLY,
                                  }
                                : undefined,
                            {
                                name: 'Арендатор и поставщик',
                                value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            },
                        ].filter((i) => i !== undefined);
                    }
                },
                visible: () => {
                    return subrentModuleEnabledSelector(getStoreState()) === true;
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE,
                id: 'assigneeId',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                componentProps: {
                    operationName: 'listUsersSimple',
                    nameField: 'title',
                },
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_EMPLOYEE),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                component: CounterpartyCategoriesSelect,
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_CATEGORY),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__IMAGE,
                id: 'imageData',
                type: FormItemType.UploadImage,
                imagesMaxCount: 1,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT,
                id: 'defaultDiscount',
                type: FormItemType.Discount,
                required: true,
                defaultValue: 0,
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return getFieldValue('activityTypeCode') !== CounterpartyActivityTypeCodeEnum.SUPPLIERONLY && canViewFinancialData(p);
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CONTACT_PERSON),
        fields: [
            createHiddenField('contacts[0].id'),
            {
                label: LocalizationEnum.ASPECT__GLOBAL__LASTNAME,
                id: 'contacts[0].fullName.lastname',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__FIRSTNAME,
                id: 'contacts[0].fullName.firstname',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__SECONDNAME,
                id: 'contacts[0].fullName.secondname',
                type: FormItemType.String,
                maxLength: 60,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__POSITION,
                id: 'contacts[0].position',
                type: FormItemType.String,
                maxLength: 60,
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER,
                id: 'contacts[0].phoneNumber',
                type: FormItemType.String,
                maxLength: 25,
            },
            {
                label: 'Email',
                id: 'contacts[0].email',
                type: FormItemType.String,
                maxLength: 60,
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^.+@.+\\..{2,}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

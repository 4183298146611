import {Tooltip} from "antd";
import React, {FC, ReactNode} from "react";
import {isDefined} from "../../../shared/util/utils";

interface TooltipWIthItemsProps {
    data?: ReactNode[];
    visible?: boolean;
}

export const TooltipWIthItems:FC<TooltipWIthItemsProps> = ({visible, children, data}) => {
    return (
        <Tooltip title={printListOfItems(data)} {...isDefined(visible) ? {visible}: undefined}>
            {children}
        </Tooltip>
    );
};

export const printListOfItems = (data?: ReactNode[]) => {
    return data && data.length > 0 ? (
        <ul style={{margin: 0, paddingLeft: 18}}>
            {
                data.map((item, index, arr)=>{
                    return <li>{item}</li>
                })
            }
        </ul>
    ) : null;
};

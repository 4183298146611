import { rentersPageUrlRoute } from '../../../data/tabs';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';

import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

export abstract class ContactUtils {
    static getRootPath(businessAccountId: number): EntityGridRootPath {
        return `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.CONTACTS}`;
    }
}

import React, {FC, MouseEvent, useCallback} from 'react';
import RCCalendar from 'rc-calendar/lib/Calendar';
import moment, {Moment} from "moment";
import {TodayButton} from "../todayButton/todayButton";
import {RecalculateShiftCountBlockProps, RequiredTimeIndentProps} from "../../rangePicker.types";
import {CalendarFooter} from "./calendarFooter";
import './calendar.less';

export interface CalendarProps {
    value?: Moment;
    onChange?: (date:Moment) => void;
    renderDate?: (current: moment.Moment, today: moment.Moment) => React.ReactNode;
    onOk: () => void;
    recalculateShiftCountBlockProps?: RecalculateShiftCountBlockProps;
    requiredTimeIndentProps?: RequiredTimeIndentProps;

    onTodayButtonClick?:()=>void;
    onTodayTimeButtonClick?:(()=>void)|null;

    disabledDate?: (current:Moment) => boolean;
    disabledHours?: number[];
    disabledMinutes?: number[];
    selectionDisabled?: boolean; // Блокировать выбор
}

export const Calendar:FC<CalendarProps> = (props) => {

    const divOnClick = useCallback((e:MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    return (
        <div onClick={divOnClick}>
            <RCCalendar
                disabledDate={props.selectionDisabled ? ()=>{return true;} : props.disabledDate}
                prefixCls={'ant-calendar'}
                className={'ant-calendar-picker-container rr-dateTimePicker rr-calendar rr-range-calendar-with-intervals rr-single-calendar'}
                showToday={false}
                renderSidebar={()=>{
                    return (
                        <TodayButton
                            disabled={!props.onTodayButtonClick || props.selectionDisabled}
                            onClick={props.onTodayButtonClick}
                            style={{position: "absolute", zIndex: 11, top: 21, right: 68}}
                        />
                    )
                }}
                renderFooter={()=>{
                    return (
                        <CalendarFooter
                            value={props.value}
                            onChange={(date)=>{
                                if(date) props.onChange?.(date);
                            }}
                            recalculateShiftCountBlockProps={props.recalculateShiftCountBlockProps}
                            onOk={()=>{
                                props.onOk();
                            }}
                            disabledHours={props.disabledHours}
                            disabledMinutes={props.disabledMinutes}
                            onTodayButtonClick={props.onTodayTimeButtonClick}
                            selectionDisabled={props.selectionDisabled}
                        />
                    );
                }}
                onSelect={(date:Moment)=>{
                    const newDate = date.clone();
                    if(props.value){
                        newDate.hours(props.value.hours()).minutes(props.value.minutes())
                    }
                    props.onChange?.(newDate);
                }}
                defaultValue={props.value}
                selectedValue={props.value}
                //value={v}
                dateRender={props.renderDate}
                onChange={(date:Moment)=>{
                    //setV(undefined);
                    //props.onChange?.(date);
                    //console.log('UUU', date.toJSON());
                }}

            />
        </div>
    );
};

import debounce from 'lodash/debounce';
import {MutableRefObject, useCallback, useEffect, useMemo} from 'react';
import _ from 'lodash';
import {getFilteredParams, getPathFromState} from '../../../../shared/util/utils';
import {setGridStorageDataFilters} from '../../../grid/utils';
import {useAppDispatch} from '../../../../store/hooks';
import {replace} from 'connected-react-router';
import {useLocation} from 'react-router-dom';
import {AvailableIntervalFiltersData} from '../types/api';
import {ListParams, URLDrawerParams} from '../types/params';
import {getProcessedParamsWithIntervals} from './useFiltersProcessed';
import {EntityGridName} from '../types/grid';
import {usePageUrlDescriptionContext, usePageUrlParamsContext} from '../components/context/PageUrlParamsContext';
import {ObjectUtils} from '../../../../core/utils/objectUtils';
import {ParamsUtils} from '../../../../core/utils/paramsUtils';

export const useGridFiltersChanges = <PageParams extends URLDrawerParams & ListParams, EntityRecord extends object>(props: {
    processedFiltersInitialValues: PageParams;
    availableIntervalFiltersData: AvailableIntervalFiltersData<EntityRecord>;
    getFiltersForm: Function;
    isFormLoadedRef: MutableRefObject<boolean>;
}) => {
    const { processedFiltersInitialValues, availableIntervalFiltersData, getFiltersForm, isFormLoadedRef } = props;

    const pageParamsDescription = usePageUrlDescriptionContext<PageParams>();

    const { setPageParams, outerParams, deletePageParam, hiddenParams } = usePageUrlParamsContext<PageParams>();

    const updateFiltersCallback = useCallback(
        (params: PageParams) => {
            const newParams = getProcessedParams(params, processedFiltersInitialValues, availableIntervalFiltersData);

            newParams.page = 1;

            setPageParams(newParams, {
                resistOuterParams: true,
            });
        },
        [availableIntervalFiltersData, processedFiltersInitialValues, setPageParams]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateFilters = useCallback(debounce(updateFiltersCallback, 200), [updateFiltersCallback]);

    const onFiltersChange = useCallback(
        (data: PageParams) => {
            isFormLoadedRef.current = true;
            updateFilters(data);
        },
        [isFormLoadedRef, updateFilters]
    );

    const resetFilters = useCallback(() => {
        isFormLoadedRef.current = true;
        const emptyParams = {} as PageParams;
        for (const key in pageParamsDescription) {
            const assertedKey = key as string as keyof PageParams;
            emptyParams[assertedKey] = undefined!;
        }
        for (const key of hiddenParams ?? []) {
            emptyParams[key] = undefined;
        }
        setPageParams(emptyParams);
        const emptyParamsForForm = outerParams ? ObjectUtils.filterByKeys(emptyParams, outerParams, 'exclude') : emptyParams;
        getFiltersForm()?.setFieldsValue(_.assign(emptyParamsForForm, processedFiltersInitialValues));
    }, [isFormLoadedRef, setPageParams, outerParams, getFiltersForm, processedFiltersInitialValues, pageParamsDescription, hiddenParams]);

    const onHideCustomValue = useCallback(
        (key: string) => {
            deletePageParam(key as keyof PageParams);
        },
        [deletePageParam]
    );

    return useMemo(
        () => ({
            onFiltersChange,
            resetFilters,
            onHideCustomValue,
        }),
        [onFiltersChange, onHideCustomValue, resetFilters]
    );
};

export const useFiltersChangeEffect = <PageParams extends object>({
    getFiltersForm,
    filtersCurrentValues,
}: {
    getFiltersForm: Function;
    filtersCurrentValues: Partial<PageParams & ListParams>;
}) => {
    useEffect(() => {
        const form = getFiltersForm();
        form?.setFieldsValue(filtersCurrentValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

interface GripPageProcessingPipelineFunctionProps<PageParams extends object, EntityRecord extends object> {
    pageParams: PageParams;
    filtersInitialValues: Partial<PageParams & ListParams>;
    intervalFiltersValues?: AvailableIntervalFiltersData<EntityRecord>;
}
export type GripPageProcessingPipelineFunction = <PageParams extends object, EntityRecord extends object>(
    props: GripPageProcessingPipelineFunctionProps<PageParams, EntityRecord>
) => PageParams;

const createGridPageProcessingPipeline =
    <PageParams extends object, EntityRecord extends object>(pipelineFunctions: GripPageProcessingPipelineFunction[]) =>
    (props: GripPageProcessingPipelineFunctionProps<PageParams, EntityRecord>): PageParams => {
        const { pageParams } = pipelineFunctions.reduce(
            (acc, pipeFunction) => ({
                ...props,
                pageParams: pipeFunction(acc),
            }),
            props
        );

        return pageParams;
    };

const getProcessedParams = <PageParams extends object, EntityRecord extends object>(
    pageParams: PageParams,
    processedFiltersInitialValues: PageParams,
    availableIntervalFiltersData: AvailableIntervalFiltersData<EntityRecord>
): PageParams => {
    const pipeline = createGridPageProcessingPipeline<PageParams, EntityRecord>([
        ParamsUtils.getProcessedParamsWithSelectors,
        getProcessedParamsWithIntervals,
    ]);

    return pipeline({
        pageParams,
        filtersInitialValues: processedFiltersInitialValues,
        intervalFiltersValues: availableIntervalFiltersData,
    });
};

export const useFetchEntityErrorCallback = <PageParams extends URLDrawerParams & ListParams>({
    pageParams,
    gridName,
    getFiltersForm,
}: {
    pageParams: PageParams;
    gridName: EntityGridName;
    getFiltersForm: Function;
}) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    return useCallback(
        (id: number) => {
            const { filteredParams, deletedCount } = getFilteredParams(pageParams, id);

            if (deletedCount > 0) {
                setGridStorageDataFilters(gridName, filteredParams);
                dispatch(replace(getPathFromState(location.pathname, '', filteredParams)));
                getFiltersForm().setFieldsValue(filteredParams);
            }
        },
        [dispatch, getFiltersForm, gridName, location.pathname, pageParams]
    );
};

import { useCallback } from 'react';
import { CrewMemberInfoRead, CrewMemberRatesObj } from '../../../../../../../../../../../server';
import { showConfirm } from '../../../../../../../../../../../components/confirm/showConfirm';
import { ProfessionsCompositionListColumnsObj } from '../../columns/professionsCompositionColumns';
import { useIntl } from 'react-intl';

export interface CrewMemberCompositionOnRowAction {
    (
        dataIndex: 'rate.ratePerShift' | 'rate.ratePerHour' | 'delete' | 'reset',
        value: any,
        professionData: ProfessionsCompositionListColumnsObj
    ): void;
}

export const useOnRowAction = (
    crewMember: CrewMemberInfoRead,
    editData: ProfessionsCompositionListColumnsObj[] | undefined,
    setEditData: (data: typeof editData) => void
): CrewMemberCompositionOnRowAction => {
    const intl = useIntl();

    return useCallback(
        async (dataIndex: string, value: number, professionData: ProfessionsCompositionListColumnsObj) => {
            const updatedData = editData != null ? [...editData] : [];
            const rowIndex = updatedData?.findIndex((currentData) => currentData.professionId === professionData.professionId);

            if (dataIndex === 'rate.ratePerShift' || dataIndex === 'rate.ratePerHour') {
                const currentRateKey = dataIndex.split('.')[1] as 'ratePerShift' | 'ratePerHour';

                const newRate =
                    professionData.rate != null
                        ? ({
                              ...updatedData[rowIndex].rate,
                              [currentRateKey]: value,
                          } as CrewMemberRatesObj)
                        : {
                              ...crewMember.defaultRates,
                              [currentRateKey]: value,
                          };

                updatedData[rowIndex] = {
                    ...updatedData[rowIndex],
                    rate: newRate,
                };
            }

            if (dataIndex === 'delete') {
                const yes = await showConfirm(intl, 'Вы уверены что хотите удалить специальность?');
                if (yes) {
                    updatedData.splice(rowIndex, 1);
                }
            }

            if (dataIndex === 'reset') {
                updatedData[rowIndex] = {
                    ...updatedData[rowIndex],
                    rate: undefined,
                };
            }

            setEditData(updatedData);
        },
        [crewMember.defaultRates, editData, intl, setEditData]
    );
};

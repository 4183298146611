import {FAILURE, REQUEST, SUCCESS} from './action-type.util';
import {BusinessAccountRecordList, serverApi, ServerError} from "../../server";
import {getServerError} from "../util/utils";

export const ACTION_TYPES = {
    GET_BUSINESS_ACCOUNTS: 'businessAccounts/GET_BUSINESS_ACCOUNTS',
    RESET_BUSINESS_ACCOUNTS: 'businessAccounts/RESET_BUSINESS_ACCOUNTS'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entities: null as (BusinessAccountRecordList | null)
};

export type BusinessAccountsState = Readonly<typeof initialState>;

// Reducer

export default (state: BusinessAccountsState = initialState, action): BusinessAccountsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_BUSINESS_ACCOUNTS):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.GET_BUSINESS_ACCOUNTS):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.GET_BUSINESS_ACCOUNTS):
            return {
                ...state,
                entities: action.payload.data,
                loading: false
            };

        case ACTION_TYPES.RESET_BUSINESS_ACCOUNTS:
            return {...initialState};

        default:
            return state;
    }
};

// Actions

// Получение настроек
export const getBusinessAccounts = (limit?: number, startFrom?: number) => ({
    type: ACTION_TYPES.GET_BUSINESS_ACCOUNTS,
    payload: serverApi.listBusinessAccounts(limit, startFrom)
});

export const resetBusinessAccounts = () => ({
    type: ACTION_TYPES.RESET_BUSINESS_ACCOUNTS
});
import React, { FC, useEffect, useState } from 'react';
import { Popover } from 'antd';
import { AbstractTooltipProps } from 'antd/lib/tooltip';
import { ProductInstancePopoverContent } from './productInstancePopoverContent';
import { InstanceRecord } from '../../../../../../server';
import { isDefined } from '../../../../../../shared/util/utils';
import './productInstancePopover.less';

interface ProductVariantPopoverProps {
    instanceId: number;
    onClose?: () => void;
    onCloseButtonClick?: () => void;
    onLinkClick?: () => void;
    visible?: boolean;
    record?: InstanceRecord;
    placement?: AbstractTooltipProps['placement'];
}

export const ProductInstancePopover: FC<ProductVariantPopoverProps> = ({
    record,
    instanceId,
    children,
    onClose,
    onLinkClick,
    placement,
    onCloseButtonClick,
    ...props
}) => {
    const [visible, setVisible] = useState<boolean | undefined>(props.visible || false);

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <Popover
            overlayClassName={'rr-grid-actions-popover rr-instance-info-popover'}
            trigger={'click'}
            onVisibleChange={(visible) => {
                if (!isDefined(props.visible)) {
                    setVisible(visible);
                    if (!visible) onClose?.();
                }
            }}
            visible={visible}
            autoAdjustOverflow
            arrowPointAtCenter
            placement={placement ? placement : 'bottomLeft'} // TODO Проверить ставиться ли где в коде
            content={
                <ProductInstancePopoverContent
                    instanceId={record?.id || instanceId}
                    onClose={() => {
                        setVisible(false);
                        onClose?.();
                    }}
                    onLinkClick={() => {
                        setVisible(false);
                        onClose?.();
                        onLinkClick?.();
                    }}
                    onCloseButtonClick={onCloseButtonClick}
                />
            }
        >
            {typeof children === 'function' ? children() : children}
        </Popover>
    );
};

import { useMemo } from 'react';
import { ArrayUtils } from '../../../../../../../core/utils/arrayUtils';
import { ObjectUtils } from '../../../../../../../core/utils/objectUtils';
import { KitRecord } from '../../../../../../../server';

const baseData = {
    assignIds: [] as number[],
    detachIds: [] as number[],
    assignEntitiesIds: [] as number[],
    detachEntitiesIds: [] as number[],
};

export const useChosenIds = (selectedRecords: KitRecord[], selectedIds: number[]): typeof baseData => {
    return useMemo(() => {
        const assignIds: number[] = [];
        const assignEntitiesIds: number[] = [];
        const detachIds: number[] = [];
        const detachEntitiesIds: number[] = [];

        const assignMap: Map<number, number[]> = new Map();
        const detachMap: Map<number, number[]> = new Map();

        selectedIds.forEach((selectedId) => {
            selectedRecords.forEach(({ id, customFieldIds }) => {
                const includeCustomId = customFieldIds?.some((customFieldId) => customFieldId === selectedId) ?? false;
                if (includeCustomId) detachMap.set(selectedId, [...(detachMap.get(selectedId) ?? []), id]);
                else assignMap.set(selectedId, [...(assignMap.get(selectedId) ?? []), id]);
            });
        });

        assignMap.forEach((value, key) => {
            assignIds.push(key);
            assignEntitiesIds.push(...value);
        });

        detachMap.forEach((value, key) => {
            detachIds.push(key);
            detachEntitiesIds.push(...value);
        });

        return ObjectUtils.strictMap(
            {
                assignIds,
                assignEntitiesIds,
                detachIds,
                detachEntitiesIds,
            },
            (_, value) => ArrayUtils.removeDuplicates(value)
        );
    }, [selectedIds, selectedRecords]);
};

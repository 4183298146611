import React from 'react';
import {Card, Col, Row} from "antd";
import moment from "moment";
import {printUserName} from "../../shared/util/utils";
import {AbstractEntityInfo} from "../../server/api";
import {CardProps} from "antd/lib/card";
import './abstractEntityInfoCard.less';
import FormValue from "../formValue/FormValue";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {IRootState} from "../../shared/reducers";
import {LocalizationEnum, localize} from '../../localization';

interface IProps extends StateProps {}

interface IProps extends CardProps {
    data?: AbstractEntityInfo;
    leftBlockSpan: number,
    leftBlockSpanXXL: number
    leftBlockSpanXL: number
    leftBlockSpanLG: number;
    always2columns: boolean;
}

class AbstractEntityInfoCard extends React.PureComponent<IProps> {
    static defaultProps = {
        leftBlockSpan: 12,
        leftBlockSpanXXL: 12,
        leftBlockSpanXL: 12,
        leftBlockSpanLG: 12,
        always2columns: false
    };

     printCard = (data: AbstractEntityInfo) => {
        return (
            // <Card bordered={false} style={this.props.style} className={'rr-card-without-padding rr-abstract-entity-info-card ' + this.props.className}>
            <Card bordered={false} style={{...this.props.style, borderLeft: '6px solid #525A95'}} className={'rr-card-without-padding  ' + this.props.className}>
                <Row gutter={0} type="flex" justify="space-between">
                    <Col span={this.props.leftBlockSpan} lg={this.props.leftBlockSpanLG} xl={this.props.leftBlockSpanXL} xxl={this.props.leftBlockSpanXXL} style={{borderRight: '1px solid #eaeaea', padding:32, paddingTop:27}}>
                        <Row gutter={16} style={{padding: 0}}>
                            <Col span={this.props.always2columns ? 12 : 24} xxl={12} className={'rr-form-item'} style={{marginBottom: 0}}>
                                <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CREATION_DATE)}</div>

                                <span className="rr-value">
                                    { data.creationDate ? moment(data.creationDate).format('DD MMMM YYYY') : ''}  </span>
                                <span className='rr-value-date-time'>
                                    { data.creationDate ? moment(data.creationDate).format('HH:mm'): ''} </span>
                            </Col>
                            <Col span={this.props.always2columns ? 12 : 24} xxl={12} className={'rr-form-item'} style={{marginBottom: 0}}>
                                <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__AUTHOR_CREATION)}</div>
                                <div className='h5 rr-value'>
                                    <Link to={`/${this.props.businessAccountId}/users/${data.creationAuthorId}`}> { printUserName(data.creationAuthorFullName) }</Link>
                                </div>
                            </Col>
                        </Row>
                        {
                            (data.creationDate !== data.lastUpdateDate || data.creationAuthorId !== data.lastUpdateAuthorId) ?
                            <Row gutter={16} style={{padding: 0, marginTop: 18}}>
                                <Col span={this.props.always2columns ? 12 : 24} xxl={12} className={'rr-form-item'} style={{marginBottom: 0}}>
                                    <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__LAST_UPDATE_DATE)}</div>
                                    <span className="rr-value">
                                    { data.lastUpdateDate ? moment(data.lastUpdateDate).format('DD MMMM YYYY') : ''}  </span>
                                    <span className='rr-value-date-time'>
                                    { data.lastUpdateDate ? moment(data.lastUpdateDate).format('HH:mm'): ''} </span>


                                </Col>
                                <Col span={this.props.always2columns ? 12 : 24} xxl={12} className={'rr-form-item'} style={{marginBottom: 0}}>
                                    <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__LAST_UPDATE_AUTHOR)}</div>
                                    <div className='h5 rr-value'>
                                        <Link to={`/${this.props.businessAccountId}/users/${data.lastUpdateAuthorId}`}> { printUserName(data.lastUpdateAuthorFullName) }</Link>
                                    </div>
                                </Col>
                            </Row> : null
                        }
                    </Col>

                    <Col className={this.props.leftBlockSpanLG === 24 ? 'rr-abstract-entity-info-comment rr-page-renter-block-with-top-border-lg' : 'rr-abstract-entity-info-comment'} span={(24 - this.props.leftBlockSpan) > 0 ? (24 - this.props.leftBlockSpan) : 24} lg={(24 - this.props.leftBlockSpanLG) > 0 ? (24 - this.props.leftBlockSpanLG) : 24} xl={(24 - this.props.leftBlockSpanXL) > 0 ? (24 - this.props.leftBlockSpanXL) : 24} xxl={(24 - this.props.leftBlockSpanXXL) > 0 ? (24 - this.props.leftBlockSpanXXL) : 24} style={{padding: 32, paddingTop:27}}>
                        <Row gutter={16}>
                            <Col className={'rr-form-item'}>
                                <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}</div>
                                <div className={'rr-text'}><FormValue value={data.comment} emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}/></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    };

    render() {
        let {data} = this.props;
        return data ? this.printCard(data) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
    mapStateToProps
)(AbstractEntityInfoCard);


import {TblColumn, TblColumnType} from "../tblColumns.types";
import {isDefined} from "../../../shared/util/utils";
import {GridColumnCreator} from "../../../components/grid/gridColumnCreator";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import moment from "moment";

export const tblColumnsDate = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.DATE) {
        return {
            type: ColumnTypes.CustomRender,
            title: column.title,
            dataIndex: column.dataIndex,
            minWidth: 150,
            resizable: false,
            sortable: true,
            render: (value: Date) => {
                return moment(value).format('DD.MM.YY HH:mm');
            }
        };
    } else {
        throw Error('error');
    }
};

import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import DynamicFilters from '../../../../../components/dynamicFilters/DynamicFilters';
import { headerBlockFilters } from '../../filters/headerBlockFilters';
import { HeaderBlockFilters } from '../../filters/headerBlockFilters.types';
import { headerBlockFiltersDescription } from '../../filters/headerBlockFiltersDescription';
import { WrappedFormUtils } from 'antd/es/form/Form';
import CalendarNavigation from '../../../calendar/components/CalendarNavigation/CalendarNavigation';
import { GridName } from '../../../../../components/grid/utils/types';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import { useShareFiltersChangesFromTabs } from '../../../../../core/hooks/urlParams/useShareFiltersChangesFromTabs';

import { HistoryPageTabsEnum } from '../../../../../shared/constants/tabEnums';

interface HeaderBlockProps {
    gridName: GridName;
}

export const HeaderBlock: FC<HeaderBlockProps> = memo((props) => {
    const { gridName } = props;

    const { pageParams, setPageParams } = usePageURLParams({
        paramsDescription: headerBlockFiltersDescription,
        pageName: gridName,
    });
    const filtersRef = useRef<WrappedFormUtils>();

    const onFiltersChange = useCallback(
        (data: HeaderBlockFilters) => {
            setPageParams(data);
            window.dispatchEvent(new Event('storage'));
        },
        [setPageParams]
    );

    const wrapperComponentRef = useCallback((ref) => {
        return (filtersRef.current = ref && ref.props && ref.props.form ? ref.props.form : null);
    }, []);

    useShareFiltersChangesFromTabs(pageParams, headerBlockFiltersDescription, HistoryPageTabsEnum);

    useEffect(() => {
        if (filtersRef.current && pageParams) {
            setTimeout(() => {
                filtersRef.current?.setFieldsValue(pageParams);
            });
        }
    }, [pageParams]);

    return (
        <div className={'header-block-container'}>
            <div className={'calendar-navigation-container'}>
                <CalendarNavigation
                    pageName={gridName}
                    positionTop={true}
                    arrowType={'double'}
                    screenLeft={pageParams.screenLeft}
                    screenRight={pageParams.screenRight}
                    isAllMode={false}
                />
            </div>
            <DynamicFilters
                gridName={gridName}
                wrappedComponentRef={wrapperComponentRef}
                currentValues={pageParams}
                onChange={onFiltersChange}
                defaultValues={{}}
                data={headerBlockFilters}
                initialValues={{}}
                canResetFilters={false}
            />
        </div>
    );
});

import React from 'react';
import { Col, Row } from 'antd';
import { IRootState } from '../../../../shared/reducers';
import { connect } from 'react-redux';
import { CustomFieldRecord, CustomFieldWithValueObjRead } from '../../../../server/api';
import { push } from 'connected-react-router';
import { getCustomFieldTypeByCode, LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { showNotification } from '../../../../components/notification/showNotification';
import { deleteEntity } from './reducers/customField.reducer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { loadEntities } from './reducers/customFields.reducer';
import { PopoverHeader } from '../../../../components/gridRowPopover/PopoverHeader';
import { FormValue2 } from '../../../../components/formValue/FormValue2';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { deleteSelectedCustomIdsFromStorage } from './utils/deleteSelectedCustomIdsFromStorage';

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    record: CustomFieldRecord | CustomFieldWithValueObjRead;
    visible: boolean;
    closeCb: () => void;
}

class CustomFieldsFieldPopoverContent extends React.PureComponent<IProps> {
    onLinkClicked = () => {
        this.props.closeCb();
    };

    onAction = (item: CustomFieldRecord | CustomFieldWithValueObjRead, action: string) => {
        this.onLinkClicked();

        let id = -1;
        if ('id' in item) id = item.id;
        if ('customFieldId' in item) id = item.customFieldId;

        if (action === 'edit') {
            this.props.push(`/${this.props.businessAccountId}/settings/customFields/${id}/edit`);
        } else if (action === 'delete') {
            showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_CONFIRM_MESSAGE),
                undefined,
                async () => {
                    let res = await this.props.deleteEntity(this.props.businessAccountId, id);
                    if (res instanceof Error) {
                        showNotification(
                            'error',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_ERROR_MESSAGE)
                        );
                    } else {
                        deleteSelectedCustomIdsFromStorage(id, this.props.businessAccountId, this.props.email);
                        showNotification(
                            'success',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_SUCCESS_MESSAGE)
                        );
                        setTimeout(() => {
                            this.loadEntities();
                        }, 1500);
                    }
                }
            );
        }
    };

    loadEntities = async () => {
        let res = await this.props.loadEntities(this.props.businessAccountId);
        if (res instanceof Error) {
            showNotification(
                'error',
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__LOAD_ENTITIES_ERROR_MESSAGE)
            );
        }
    };

    render() {
        let { record } = this.props;

        return (
            <>
                <PopoverHeader
                    businessAccountId={this.props.businessAccountId}
                    title={
                        record.hasOwnProperty('shortName')
                            ? (record as CustomFieldRecord).shortName
                            : (record as CustomFieldWithValueObjRead).name
                    }
                    closeCb={this.props.closeCb}
                    entityType={'customField'}
                    entity={record}
                    onAction={this.onAction}
                    hideActionsButton={record.standard}
                    closeElementPopup={() => {}}
                />

                <Row style={{ padding: '32px 0' }}>
                    <Col span={24} style={{ marginBottom: 18 }}>
                        <Row style={{ padding: '0 32px' }}>
                            <Col span={13}>
                                <div className="rr-label">{localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ORIGIN)}</div>
                                <div className={'rr-value'}>
                                    <FormValue2
                                        content={
                                            <>
                                                <SystemIcon type={record.standard ? SystemIconType.standard : SystemIconType.custom} />
                                                <span>
                                                    {localize(
                                                        record.standard
                                                            ? LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__STANDARD_TYPE
                                                            : LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__NOT_STANDARD_TYPE
                                                    )}
                                                </span>
                                            </>
                                        }
                                    />
                                </div>
                            </Col>

                            {record.standard ? null : record.hasOwnProperty('entitiesLinkedNumber') ? (
                                <Col span={11}>
                                    <div className="rr-label">
                                        {localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ENTITIES_LINKED_NUMBER)}
                                    </div>
                                    <div className={'rr-value'}>
                                        <FormValue2 content={(record as CustomFieldRecord).entitiesLinkedNumber} emptyContent={'0'} />
                                    </div>
                                </Col>
                            ) : null}
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 18 }}>
                        <Row style={{ padding: '0 32px' }}>
                            <Col span={13}>
                                <div className="rr-label">{localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE)}</div>
                                <div className={'rr-value'}>
                                    <FormValue2 content={getCustomFieldTypeByCode(record.typeCode)} />
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className="rr-label">
                                    {localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__MULTIPLE_VALUES)}
                                </div>
                                <div className={'rr-value'}>
                                    <FormValue2
                                        content={record.multipleValues && localize(LocalizationEnum.ASPECT__GLOBAL__YES)}
                                        emptyContent={localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{ marginBottom: 30 }}>
                        <Row style={{ padding: '0 32px' }}>
                            <Col span={13}>
                                <div className="rr-label">Финансовое</div>
                                <div className={'rr-value'}>
                                    <FormValue2
                                        content={
                                            record.financial && (
                                                <>
                                                    <SystemIcon type={SystemIconType.financial} />
                                                    <span>{localize(LocalizationEnum.ASPECT__GLOBAL__YES)}</span>
                                                </>
                                            )
                                        }
                                        emptyContent={localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                    />
                                </div>
                            </Col>

                            <Col span={11}>
                                <div className="rr-label">Скрытое</div>
                                <div className={'rr-value'}>
                                    <FormValue2
                                        content={
                                            record['internal'] && (
                                                <>
                                                    <SystemIcon type={SystemIconType.hidden} />
                                                    <span>{localize(LocalizationEnum.ASPECT__GLOBAL__YES)}</span>
                                                </>
                                            )
                                        }
                                        emptyContent={localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row style={{ padding: '0 32px', borderTop: '1px solid #EAEAEA', paddingTop: 30 }}>
                            <Col span={24}>
                                <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}</div>
                                <div className={'rr-text'}>
                                    <FormValue2
                                        content={record.comment}
                                        emptyContent={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entity: storeState.element.entity,
        businessAccountId: storeState.system.businessAccountId,
        email: storeState.oidc.user?.profile.email,
        location: storeState.router.location,
    };
};

const mapDispatchToProps = { push, deleteEntity, loadEntities };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomFieldsFieldPopoverContent));

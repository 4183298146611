import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../../../../../store/rtqQueryApi';
import {
    InstanceTrackingTypeCodeEnum,
    RentElementRecord,
    serverApi,
    SummaryScheduleListRequestObject,
    SummaryScheduleListRequestObjectNomenclatureSortOrderEnum
} from '../../../../../../server';
import {showNotification} from '../../../../../../components/notification/showNotification';
import {AxiosError} from 'axios';
import {SummaryScheduleCalendarApiUtils} from "./summaryScheduleCalendarApiUtils";
import {SummaryScheduleCalendarFilters} from "../filters/summaryScheduleCalendarFilters";

export interface ListSummaryScheduleArgs {
    businessAccountId: number;
    includeRentElements?: boolean;
    params: SummaryScheduleCalendarFilters;
    limit: number;
    from: number;
    until: number;
}

export interface SummaryScheduleData {
    totalCount: number;
    entities: SummaryScheduleDataNomenclature[];
}

export interface SummaryScheduleDataNomenclature {
    availableMap: string;
    productId?: number;
    variantId?: number;
    kitId?: number;
    productName?: string;
    variantName?: string;
    kitName?: string;
    instanceTracked: boolean;
    instances?: SummaryScheduleDataInstance[];
    rentElements?: RentElementRecord[];
}

export interface SummaryScheduleDataInstance {
    id: number;
    productId: number;
    variantId?: number;
    name: string;
    availableMap?: string;
    rentElements?: RentElementRecord[];
}

export const summaryScheduleCalendarApi = createApi({
    reducerPath: 'summaryScheduleCalendarApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['SummaryScheduleCalendarList'],
    endpoints: (builder) => ({
        listSummarySchedule: builder.query<SummaryScheduleData, ListSummaryScheduleArgs>({
            queryFn: async ({ businessAccountId, params, limit, from, until}, { getState, dispatch }) => {
                const nomenclatureListFilters = SummaryScheduleCalendarApiUtils.getNomenclatureListFilters(params);
                const nomenclatureSearch = SummaryScheduleCalendarApiUtils.getNomenclatureSearch(params);
                const nomenclatureSortBy = SummaryScheduleCalendarApiUtils.getNomenclatureSortBy(params);
                const includeRentElements = Boolean(params.includeRentElements);
                const summaryScheduleListRequestObject:SummaryScheduleListRequestObject = {
                    nomenclatureListFilters,
                    nomenclatureSearch,
                    nomenclatureSortBy,
                    nomenclatureSortOrder: SummaryScheduleListRequestObjectNomenclatureSortOrderEnum.ASC
                };

                try {
                    const { data } = await serverApi.listSummarySchedule(businessAccountId, from, until, includeRentElements,summaryScheduleListRequestObject, limit, 0);

                    // Тут преобразуем в наш формат
                    let newData = data.entities.map((item)=>{
                        return {
                            availableMap: item.info.stackMapList.maps[0].mapString,
                            productId: item.info.productId,
                            variantId: item.info.variantId,
                            kitId: item.info.kitId,
                            productName: !item.info.kitId ? item.info.productShortName : undefined,
                            variantName: item.info.variantName,
                            kitName: item.info.kitId ? item.info.productShortName : undefined,
                            instanceTracked: item.info.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED || item.info.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED,
                            instances: item.instances?.map((inst)=>{
                                return {
                                    id: inst.info.id,
                                    productId: inst.info.productId,
                                    variantId: inst.info.variantId,
                                    name: inst.info.nameOrCode,
                                    availableMap: inst.info.stackMapList?.maps[0].mapString,
                                    rentElements: inst.rentElements
                                };
                            }),
                            rentElements: item.rentElements
                        };
                    });

                    return {
                        data: {
                            totalCount: data.listAttributes.filteredCount,
                            entities: newData
                        }
                    };
                } catch (error) {
                    showNotification('error', 'Не удалось загрузить общее расписание');
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useListSummaryScheduleQuery } = summaryScheduleCalendarApi;

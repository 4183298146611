import { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';

interface SubrentBaseBorderProps {
    width: number;
}

export const SubrentBaseBorder: FC<SubrentBaseBorderProps> = (props) => {
    const { width } = props;
    const { item } = useContext(ElementItemContext);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);
    const { stateCode } = item;

    const startsAfterLeftBoundary = +item.start_time > visibleTimeStart;
    const endsBeforeRightBoundary = +item.end_time < visibleTimeEnd;

    const borderColor = CalendarColors.elementsStateCodeMap[stateCode];
    const borderRadius = CALENDAR_LINE_ROUND_RADIUS;
    const border = `2px solid ${borderColor}`;

    return (
        <div
            className={'calendar-line-border'}
            style={{
                border,
                borderRadius,
                borderLeft: startsAfterLeftBoundary ? border : 'none',
                borderTopLeftRadius: startsAfterLeftBoundary ? borderRadius : 0,
                borderBottomLeftRadius: startsAfterLeftBoundary ? borderRadius : 0,
                borderRight: endsBeforeRightBoundary ? border : 'none',
                borderTopRightRadius: endsBeforeRightBoundary ? borderRadius : 0,
                borderBottomRightRadius: endsBeforeRightBoundary ? borderRadius : 0,
                width,
            }}
        />
    );
};

import {HeaderTitle, HeaderTitleLink} from "../../../../components/pageHeader/components/headerTitle/headerTitle";
import React, {FC} from "react";
import {linkToCounterpartiesPage, linkToCounterpartyPage, linkToCounterpartySimpleProjectsPage} from "../../renters/data/tabs";
import {LocalizationEnum} from "../../../../localization";
import {useAppSelector} from "../../../../store/hooks";
import {businessAccountIdSelector} from "../../../../shared/reducers/system.reducer";
import {Icon} from "antd";
import {IconShoppingBagSolid} from "../../../../components/icons";
import {useLocalize} from "../../../../core/hooks/useLocalize";
import {ProjectStateBadge} from "../../projects/production/components/ProjectStateBadge/ProjectStateBadge";
import {ProjectStateCode} from "../../projects/production/data/projectData";
import './orderPageHeaderNavigationBlock.less';

interface OperationPageHeaderTitlePropsData {
    counterpartyId?: number;
    counterpartyName?: string;
    orderName: string;
    stateCode: ProjectStateCode;
    archive?: boolean;
}

interface OperationPageHeaderTitleProps {
    data: OperationPageHeaderTitlePropsData;
}

export const OrderPageHeaderNavigationBlock: FC<OperationPageHeaderTitleProps> = ({data}) => {
    const L = useLocalize();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    let links: HeaderTitleLink[] = [];
    links.push({name: L(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES), link: linkToCounterpartiesPage({businessAccountId})});
    if (data && data.counterpartyId && data.counterpartyName) {
        links.push({
            name: data.counterpartyName,
            link: linkToCounterpartyPage({businessAccountId, counterpartyId: data.counterpartyId}),
            bold: true
        });
        links.push({name: 'Заказы', link: linkToCounterpartySimpleProjectsPage({businessAccountId, counterpartyId: data.counterpartyId})});
    } else {
        links.push({name: 'Арендатор не выбран', bold: true});
        links.push({name: 'Заказы'});
    }

    return (
        <HeaderTitle
            icon={<Icon component={IconShoppingBagSolid} />}
            title={data.orderName}
            links={links}
            badge={<ProjectStateBadge type={data.stateCode}/>}
            archive={data.archive}
            className={'orderPageHeaderNavigationBlock'}
        />
    );
};

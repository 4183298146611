import React from 'react';
import { LocationSelectFC, LocationSelectProps } from './locationSelect';
import { SelectAndCreate } from '../selectAndCreate';
import { LocationCreateModalNew } from '../../../../modules/main/logistics/tabs/locations/modal/create/LocationCreateModalNew';

export interface LocationSelectAndCreateProps extends LocationSelectProps {}

export class LocationSelectAndCreate extends React.Component<LocationSelectAndCreateProps> {
    render() {
        const { props } = this;
        return (
            <SelectAndCreate
                select={() => <LocationSelectFC {...props} />}
                modal={({ close }) => {
                    return (
                        <LocationCreateModalNew
                            onSuccess={(data) => {
                                props.onChange?.(data.id);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}

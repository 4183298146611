import isPromise from 'is-promise';

export default () => next => action => {
  // If not a promise, continue on
    if (!isPromise(action.payload)) {
        return next(action);
    }

    return next(action).catch(error => {
        console.log(error);
        return error;
    });
  //return next(action);
};
import { CustomFieldsUtils } from '../../../../core/utils/customFieldsUtils';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { CustomFieldObjRead } from '../../../../server';
import { PageURLParamDescriptionObject } from '../../../../core/utils/descriptions';

export const useCustomFieldsData = <Params extends object>(
    customFieldMarkers: CustomFieldObjRead[] | undefined,
    defaultDescription: PageURLParamDescriptionObject<Params>
) => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const searchParams = Object.fromEntries(urlParams.entries());

    const customFiltersRequestFilters = useMemo(
        () => CustomFieldsUtils.generateCustomFieldsFilters(customFieldMarkers, searchParams),
        [customFieldMarkers, searchParams]
    );

    const actualPageParamsDescription = useMemo(
        () => CustomFieldsUtils.generateCustomFieldsDescription(customFieldMarkers, defaultDescription),
        [customFieldMarkers, defaultDescription]
    );

    return useMemo(
        () => ({
            customFiltersRequestFilters,
            actualPageParamsDescription,
        }),
        [actualPageParamsDescription, customFiltersRequestFilters]
    );
};

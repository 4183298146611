import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { WorkPlanningsCalendarFilters } from './workPlanningsCalendarFilters.types';
import { WorkPlanningsStateCodeEnum } from '../../../../../../server';
import { CalendarDisplayType } from '../../../components/Calendar/utils/data/calendarConstants';
import { entityGridDrawerDescription } from '../../../../../../components/page/entityGrid/data/paramsDescription';

export const workPlanningsCalendarFiltersDescription: Required<PageURLParamDescriptionObject<WorkPlanningsCalendarFilters>> &
    typeof entityGridDrawerDescription = {
    search: PageURLParamDescriptions.searchParam(),
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    typeCode: PageURLParamDescriptions.arrayOfEnumParam(WorkPlanningsStateCodeEnum),
    group: PageURLParamDescriptions.searchParam(),
    capture: PageURLParamDescriptions.searchParam(),
    displayType: PageURLParamDescriptions.enumSelectParam(CalendarDisplayType),
    professionId: PageURLParamDescriptions.numberParam,
    crewMemberId: PageURLParamDescriptions.numberParam,
    renterId: PageURLParamDescriptions.numberParam,
    projectId: PageURLParamDescriptions.numberParam,
    id: PageURLParamDescriptions.numberParam,
    hideCancelled: PageURLParamDescriptions.switchParam,
    isSplitted: PageURLParamDescriptions.booleanParam,
    ...entityGridDrawerDescription,
};

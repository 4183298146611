import React from "react";
import {CurrencyCodeEnum} from '../../server/api';
import {getCurrencySign} from './getCurrencySign';
import {LocalizationEnum, localize} from "../../localization";

const currencyName = (currency: CurrencyCodeEnum) => {
    let name;
    if (currency === CurrencyCodeEnum.RUB) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__RU)}</>;
    } else if (currency === CurrencyCodeEnum.USD) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__US)}</>;
    } else if (currency === CurrencyCodeEnum.EUR) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__EU)}</>;
    } else if (currency === CurrencyCodeEnum.AMD) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__AMD)}</>;
    } else if (currency === CurrencyCodeEnum.GEL) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__GEL)}</>;
    } else if (currency === CurrencyCodeEnum.BYR) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__BYR)}</>;
    } else if (currency === CurrencyCodeEnum.KZT) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__KZT)}</>;
    } else if (currency === CurrencyCodeEnum.KGS) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__KGS)}</>;
    } else if (currency === CurrencyCodeEnum.UZS) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__UZS)}</>;
    } else if (currency === CurrencyCodeEnum.AZN) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__AZN)}</>;
    } else if (currency === CurrencyCodeEnum.THB) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__THB)}</>;
    } else if (currency === CurrencyCodeEnum.IDR) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__IDR)}</>;
    } else if (currency === CurrencyCodeEnum.AED) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__AED)}</>;
    } else if (currency === CurrencyCodeEnum.TJS) {
        name = <> — {localize(LocalizationEnum.ASPECT__CURRENCIES__TJS)}</>;
    }
    return <>{getCurrencySign(currency)} {name}</>;
};

export default currencyName;

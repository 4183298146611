import { EntityGrid } from '../../../EntityGrid';
import { EntityModals } from '../EntityModals/EntityModals';
import { EntityGridProviders } from '../../providers/DrawerUrlProvider/EntityGridProviders';
import { EntityDrawer } from '../EntityDrawer/EntityDrawer';
import { PageURLParamDescriptionObject } from '../../../../../../core/utils/descriptions';
import { useCheckEnabledProviders } from '../../../hooks/useCheckEnabledProviders';
import { EntityListProps } from './EntityList.types';
import { useEffect, useState } from 'react';
import { useCheckUrlParamsOnMountEffect } from '../../../../../../core/hooks/urlParams/useCheckUrlParamsOnMountEffect';

export const EntityList = <
    PageParamsDescription extends PageURLParamDescriptionObject,
    EntityRecord extends { id: number | string },
    EntityInfoRead extends { id: number }
>(
    props: EntityListProps<PageParamsDescription, EntityRecord, EntityInfoRead>
) => {
    const { pageParamsObject, gridProps, modalProps, drawerProps } = props;
    const {
        static: { pageParamsDescription },
        metaData: { rootPath, gridName },
        entitiesLoading,
        queryData: { refetch, data: { customFieldMarkers } = {}, isSuccess },
    } = gridProps;
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);

    useEffect(() => {
        if (isFirstLoaded) return;
        if (isSuccess) setIsFirstLoaded(true);
    }, [isFirstLoaded, isSuccess]);

    useCheckEnabledProviders();
    useCheckUrlParamsOnMountEffect(pageParamsObject.pageParams, gridName, rootPath);

    return (
        <EntityGridProviders
            pageParamsObject={pageParamsObject}
            rootPath={rootPath}
            drawer={drawerProps?.drawer}
            pageParamsDescription={pageParamsDescription}
            customFieldMarkers={customFieldMarkers}
        >
            <EntityGrid key={String(isFirstLoaded)} {...gridProps} />
            {modalProps ? (
                <EntityModals
                    rootPath={rootPath}
                    currentEntity={modalProps.currentEntity}
                    entitiesLoading={entitiesLoading}
                    modals={modalProps.modals}
                    entityLoadActions={modalProps.entityLoadActions}
                />
            ) : null}
            {drawerProps ? (
                <EntityDrawer drawer={drawerProps.drawer} entityActions={drawerProps.entityActions} refetchList={refetch} />
            ) : null}
        </EntityGridProviders>
    );
};

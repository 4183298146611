import React from "react";
import moment, {unitOfTime} from "moment";
import {LocalizationEnum, localize} from "../../localization";

export const getDateString = (now, date) => {
    let a = moment(now);
    let b = moment(date);
    let units:unitOfTime.Diff[] = ['y', 'M', 'd', 'h', 'm', 's'];
    let res:string[] = [];
    let diffSec:number = a.diff(b, 's');
    units.forEach(((item, index) => {
        let diff:number = 0;
        if(item === 'y') diff = Math.floor(diffSec / 12 / 30 / 24 / 60 / 60);
        else if(item === 'M') diff = Math.floor(diffSec / 30 / 24 / 60 / 60) % 12;
        else if(item === 'd') diff = Math.floor(diffSec / 24 / 60 / 60) % 30;
        else if(item === 'h') diff = Math.floor(diffSec / 60 / 60) % 24;
        else if(item === 'm') diff = Math.floor(diffSec / 60) % 60;
        else if(item === 's') diff = diffSec % 60;

        let resString = diff > 0 ? moment.duration(diff, units[index]).humanize() : '';
        if(resString && item !== 's' &&  /^\D/.test(resString)) resString = '1 ' + resString;
        res.push(resString);
    }));
    res = res.splice(res.findIndex(Boolean), diffSec < 60 * 60 ? 1 : 2).filter(Boolean);
    return res.join(' ');
};

export const convertMinutesToHoursMinString = (minutes) => {
    let hours = Math.floor(minutes / 60);
    let mins = minutes - hours * 60;
    return <>
        {hours > 0 ? <>{hours} {localize(LocalizationEnum.ASPECT__TIME__HOUR, undefined, {value: hours})}</> : null}
        {hours && mins ? ' ' : null}
        {!hours || (hours && mins > 0) ? <>{mins} {localize(LocalizationEnum.ASPECT__TIME__MINUTE, undefined, {value: mins})}</> : null}
    </>;
};

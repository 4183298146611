import React from 'react';
import { Grid } from '../../../../components/grid/Grid';

export class DashboardList extends React.Component<any> {
    render() {
        let excludeColumns = [...(this.props.excludeColumns || []), this.props.parentType === 'project' ? 'lastOperationId' : undefined];

        return (
            <Grid
                excludeColumns={excludeColumns}
                hidePageSizeSelect
                selectable={false}
                onSortedChange={this.props.onSortedChange}
                onPageChanged={this.props.onPageChanged}
                onPageSizeChanged={this.props.onPageSizeChanged}
                filtered={this.props.filtered}
                pageSize={10}
                currentPage={this.props.currentPage}
                columns={this.props.columns}
                data={this.props.data}
                indexFieldName={'id'}
                entityType={'element'}
                defaultSorted={this.props.defaultSorted}
                defaultSortDesc={this.props.defaultSortDesc}
                gridName={this.props.gridName}
            />
        );
    }
}

import amplitude, {AmplitudeClient} from 'amplitude-js';
import {IAnalyticsClient, IAnalyticsEvent} from "./analytics";

export class AmplitudeAnalytics implements IAnalyticsClient {
    private client:AmplitudeClient;

    constructor() {
        this.client = amplitude.getInstance();
    }

    public init(apiKey: string) {
        this.client.init(apiKey);
    }

    public setUserId(userId: string | null) {
        this.client.setUserId(userId);
    }

    public logEvent(event:IAnalyticsEvent) {
        this.client.logEvent(event.type, event.data);
    }

    public setUserProperties(data:any) {
        this.client.setUserProperties(data);
    }
}

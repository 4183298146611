import React from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { IconCalendar, IconCube, IconDollySolid } from '../../../../../../components/icons';
import { InstanceRecord, RentElementInfoRead, RentStateCodeEnum } from '../../../../../../server/api';
import { ProductInstancePopover } from '../../../../inventory/instances/components/productInstancePopover/productInstancePopover';
import './InstancesWidget.less';
import classNames from 'classnames';
import { linkToCalendarFromShortage } from '../../../../../../shared/util/createLinkPath';

interface InstancesWidgetProps {
    instanceWithCurrentElementShortageIds?: number[];
    entity: InstanceRecord;
    closeElementPopup?: () => void;
    element?: RentElementInfoRead; // Это надо для того что-бы понимать выводить иконку календарика и генерировать ссылку или нет
}

export const InstancesWidget = ({ instanceWithCurrentElementShortageIds, entity, closeElementPopup, element }: InstancesWidgetProps) => {
    const isProblem = instanceWithCurrentElementShortageIds ? instanceWithCurrentElementShortageIds.find((el) => el === entity.id) : false;
    const problemType = isProblem ? (element?.stateCode === RentStateCodeEnum.ORDERED ? 'order' : 'book') : undefined;
    const isSubrent = entity.subrentSupply;
    const calendarLink =
        problemType &&
        linkToCalendarFromShortage({
            productId: entity.productId,
            variantId: entity.variantId,
            instanceId: entity.id,
            rentPeriodStartDate: element?.rentTerms.rentPeriodStartDate,
            rentPeriodEndDate: element?.rentTerms.rentPeriodEndDate,
            stateCode: element?.stateCode,
            elementId: element?.id,
            kit: element?.rentElementsKitId,
        });

    return (
        <span
            className={classNames(
                'instanceWidgetWrapper',
                problemType
                    ? problemType === 'order'
                        ? 'instanceWidgetWrapper-orderShortage'
                        : 'instanceWidgetWrapper-bookShortage'
                    : undefined
            )}
        >
            <ProductInstancePopover instanceId={entity.id} placement={'right'} onLinkClick={closeElementPopup}>
                <span
                    className={classNames(
                        'instanceWidget',
                        problemType
                            ? problemType === 'order'
                                ? 'instanceWidget-orderShortage'
                                : 'instanceWidget-bookShortage'
                            : 'rr-product-instance-accounting-color-UNDER_MAINTENANCE',
                        calendarLink && 'instanceWidget-withCalendarLink'
                    )}
                >
                    <div className="instanceWidget-content">
                        <Icon component={isSubrent ? IconDollySolid : IconCube} className="instanceWidget-icon" />
                        {entity.nameOrCode}
                    </div>
                </span>
            </ProductInstancePopover>
            {calendarLink && (
                <div className={'instanceWidget-calendar-icon'} style={{}}>
                    <Link to={calendarLink}>
                        <Icon component={IconCalendar} />
                    </Link>
                </div>
            )}
        </span>
    );
};

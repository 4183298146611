
export const formatBytes = (bytes:number, decimals:number) => {
    if(bytes === 0) return '0 Б';
    const k = 1024,
        dm = decimals || 2,
        //sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        sizes = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {SystemIcon, SystemIconType} from "../../../components/v2/systemIcon/systemIcon";
import {getActionsColumnWidth, GRID_ACTIONS_COLUMN_WIDTH} from "../../../config/constants";
import {ActionButtonsPopover} from "../../../components/v2/actionButtons/actionButtonsPopover/actionButtonsPopover";
import React from "react";
import {LocalizationEnum, localize} from "../../../localization";
import {ActionButtonsTableGroup} from "../../../components/v2/actionButtons/actionButtonsTableGroup/actionButtonsTableGroup";

export const tblColumnsSettingsActions = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.SETTINGS_ACTIONS) {
        return (
            {
                title: localize(LocalizationEnum.ASPECT__GRID__POPUP__TITLE__ACTIONS),
                type: ColumnTypes.CustomRender,
                width: getActionsColumnWidth(column.numberOfIcons),
                maxWidth: getActionsColumnWidth(column.numberOfIcons),
                minWidth: getActionsColumnWidth(column.numberOfIcons),
                resizable: false,
                sortable: false,
                className: 'actions',
                headerClassName: 'actions',
                render: (value: any, rowData: Record, rowAction, entityType) => {
                    const mainActions = column.getData(rowData).mainActions;
                    const additionalActions = column.getData(rowData).additionalActions;
                    return (
                        <ActionButtonsTableGroup
                            data={column.actionsDataMap}
                            mainActions={mainActions}
                            additionalActions={additionalActions}
                            onAction={(action) => {
                                if (rowAction) rowAction(rowData, action);
                            }}
                         />
                    );
                }
            }
        );
    } else {
        throw Error('error');
    }
};

import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../../../../store/rtqQueryApi';
import {serverApi, VariantInfoRead} from '../../../../../server';
import {locationsApi} from "../../../logistics/tabs/locations/api/locations.api";

interface GetProductVariantByIdArgs {
    businessAccountId: number;
    productId: number;
    id: number;
}

export const getProductVariantByIdQueryFn = async ({ businessAccountId, productId, id }: GetProductVariantByIdArgs) => {
    try {
        return await serverApi.getProductVariantById(businessAccountId, productId, id);
    } catch (error: unknown) {
        //showNotification('error', 'Не удалось загрузить работы');
        //const errorMessage = error instanceof Error ? error.message : undefined;
        throw error;//new Error(errorMessage);
    }
};

export const productVariantsApi = createApi({
    reducerPath: 'productVariantsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ProductVariantList', 'ProductVariant'],
    endpoints: (builder) => ({
        getProductVariantById: builder.query<VariantInfoRead, GetProductVariantByIdArgs>({
            queryFn: getProductVariantByIdQueryFn,
            providesTags: ['ProductVariant'],
        }),
    }),
});

export const { useGetProductVariantByIdQuery } = productVariantsApi;

// dispatch(productVariantsApi.util?.invalidateTags(['ProductVariant']));
import React from 'react';
import { HelpTooltip } from '../../../../../components/helpTooltip/HelpTooltip';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Col, Icon, Row } from 'antd';
import { RentAuditRecord } from '../../../../../server/api';
import { Link } from 'react-router-dom';
import { OperationType } from '../../../../../components/operationType/operationType';
import { formatDate } from '../../../../../shared/util/formatDate';
import FormValue from '../../../../../components/formValue/FormValue';

interface IProps {
    businessAccountId: number;
    onLinkClicked: () => void;
    auditRecords?: RentAuditRecord[];
    auditRecordsCount: number | undefined; // Если мы из record'а можем получить кол-во операций, то сможем его норм отрисовать
    onCollapseCB: () => void;
    isPopover?: boolean;
}

interface IState {
    otherRecordsCollapsed: boolean;
}

export class ElementPopoverAuditRecords extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            otherRecordsCollapsed: true,
        };
    }

    renderAuditItem = (record: RentAuditRecord, index: number, status) => {
        let { businessAccountId, onLinkClicked } = this.props;

        return (
            <Row key={index} style={{ marginBottom: 15 }}>
                <Col span={8}>
                    <Link onClick={onLinkClicked} to={`/${businessAccountId}/history/operations/${record.operationId}?tab=description`}>
                        <OperationType code={record.operationTypeCode} isPopover={this.props.isPopover} status={status} />
                    </Link>
                </Col>
                <Col span={8}>
                    <Link onClick={onLinkClicked} to={`/${businessAccountId}/history/operations/${record.operationId}?tab=description`}>
                        <span className="popover-link-hover">{formatDate(record.operationCreationDate)}</span>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link onClick={onLinkClicked} to={`/${businessAccountId}/users/${record.operationAuthorId}`}>
                        <span className="popover-link-hover">{record.operationAuthorShortName}</span>
                    </Link>
                </Col>
            </Row>
        );
    };

    onCollapseClick = () => {
        this.setState({
            otherRecordsCollapsed: !this.state.otherRecordsCollapsed,
        });
        this.props.onCollapseCB();
    };

    render() {
        let { auditRecords, auditRecordsCount } = this.props;
        let { otherRecordsCollapsed } = this.state;

        if (auditRecords) auditRecords = auditRecords.slice().reverse();

        return (
            <Row>
                <Col className="rr-form-item" style={{ minHeight: !auditRecordsCount || auditRecordsCount <= 1 ? 60 : 90 }}>
                    <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__011}>
                        <span>{localize(LocalizationEnum.ASPECT__GLOBAL__LAST_ACTIVITY)}</span>
                    </HelpTooltip>
                    <div style={{ marginTop: 10 }}>
                        {auditRecords && this.renderAuditItem(auditRecords[0], 0, auditRecords[0].stateCode)}
                    </div>
                    {auditRecords && auditRecords.length > 1 && (
                        <>
                            <div>
                                <span onClick={this.onCollapseClick} style={{ cursor: 'pointer', userSelect: 'none' }}>
                                    {localize(LocalizationEnum.ASPECT__GLOBAL__MORE)} <strong>{auditRecords.length - 1}</strong>
                                    <Icon
                                        style={{
                                            fontSize: 22,
                                            verticalAlign: 'middle',
                                            marginLeft: 14,
                                            position: 'relative',
                                            top: -1,
                                            transition: 'all 1s',
                                        }}
                                        type={'down'}
                                        rotate={otherRecordsCollapsed ? 0 : 180}
                                    />
                                </span>
                            </div>
                            {!otherRecordsCollapsed && (
                                <div style={{ marginTop: 10 }}>
                                    {auditRecords.map((record: RentAuditRecord, index) => {
                                        return index ? this.renderAuditItem(record, index, record.stateCode) : null;
                                    })}
                                </div>
                            )}
                        </>
                    )}
                    {!auditRecords && <FormValue value={undefined} emptyText={'?'} />}
                </Col>
            </Row>
        );
    }
}

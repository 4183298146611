import { DeepKeys } from '@tanstack/react-table';
import { EntityType } from '../../../index';
import { ReactElement, ReactNode } from 'react';
import { Action, ActionButtonsData } from '../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { PricingSchemeExternalRepresentationObj } from '../../server';

export enum TblColumnType {
    TEXT = 'TEXT',
    ENTITY_NAME = 'ENTITY_NAME',
    COUNT = 'COUNT',
    STATUS = 'STATUS',
    MONEY = 'MONEY',
    PRICE = 'PRICE',
    SHIFT_COUNT = 'SHIFT_COUNT',
    DATE = 'DATE',
    LAST_UPDATE_DATE = 'LAST_UPDATE_DATE',
    ACTIONS = 'ACTIONS',
    SETTINGS_ACTIONS = 'SETTINGS_ACTIONS',
    CLICK = 'CLICK',
    BOOLEAN = 'BOOLEAN',
    USER = 'USER',
    LINK = 'LINK',
}

interface BaseColumnType<EntityRecord> {
    type: TblColumnType;
    title?: string;
    dataIndex?: DeepKeys<EntityRecord>;
}

interface TextColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.TEXT;
    getData: (row: EntityRecord) => { value: ReactNode };
    minWidth?: number;
}

interface EntityNameColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.ENTITY_NAME;
    getData: (row: EntityRecord) => { baId?: number; id?: number; name?: string; stopPropagation?: boolean };
    entityType: EntityType;
}

interface CountColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.COUNT;
    minWidth?: number;
}

interface StatusColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.STATUS;
    minWidth?: number;
    render: (row: EntityRecord) => ReactElement;
}

interface MoneyColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.MONEY;
    minWidth?: number;
}

interface PriceColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.PRICE;
    minWidth?: number;
    getData: (row: EntityRecord) => {
        price: number;
        pricingSchemeData?: { pricingScheme: PricingSchemeExternalRepresentationObj; shiftCount: number; basePrice: number };
    };
}

interface ShiftCountColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.SHIFT_COUNT;
    minWidth?: number;
}

interface DateColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.DATE;
    minWidth?: number;
}

interface LastUpdateDateColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.LAST_UPDATE_DATE;
    minWidth?: number;
}

interface ActionsColumnType<EntityRecord, T extends string> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.ACTIONS;
    actionsDataMap: ActionButtonsData<T>;
    getData: (row: EntityRecord) => { actions: Action<T>[] };
}

interface SettingsActionsColumnType<EntityRecord, T extends string> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.SETTINGS_ACTIONS;
    actionsDataMap: ActionButtonsData<T>;
    getData: (row: EntityRecord) => { mainActions: Action<T>[]; additionalActions?: Action<T>[] };
    numberOfIcons: number;
}

interface ClickColumnType<EntityRecord, T extends string> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.CLICK;
}

interface BooleanColumnType<EntityRecord, T extends string> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.BOOLEAN;
    minWidth?: number;
}

interface USerColumnType<EntityRecord, T extends string> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.USER;
    getData: (row: EntityRecord) => { businessAccountId?: number; id?: number; name?: string; hasOwn?: boolean; emptyValue?: ReactNode };
}

interface LinkColumnType<EntityRecord> extends BaseColumnType<EntityRecord> {
    type: TblColumnType.LINK;
    getUrl: (row: EntityRecord) => string | undefined;
    minWidth?: number;
}

export type TblColumn<EntityRecord, T extends string> =
    | EntityNameColumnType<EntityRecord>
    | CountColumnType<EntityRecord>
    | StatusColumnType<EntityRecord>
    | MoneyColumnType<EntityRecord>
    | PriceColumnType<EntityRecord>
    | ShiftCountColumnType<EntityRecord>
    | DateColumnType<EntityRecord>
    | LastUpdateDateColumnType<EntityRecord>
    | ActionsColumnType<EntityRecord, T>
    | SettingsActionsColumnType<EntityRecord, T>
    | ClickColumnType<EntityRecord, T>
    | BooleanColumnType<EntityRecord, T>
    | USerColumnType<EntityRecord, T>
    | TextColumnType<EntityRecord>
    | LinkColumnType<EntityRecord>;

import { ComponentProps, CSSProperties, FC, useContext } from 'react';
import { TimeRangeContext } from '../../../../../../../context/TimeRangeContext';
import { Moment } from 'moment';
import classNames from 'classnames';
import { CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../../Calendar/utils/data/calendarConstants';

interface DraftBorderProps extends ComponentProps<'div'> {
    width?: number;
    border: string;
    isRounded: boolean;
    start_time: Moment;
    end_time: Moment;
}

export const DraftBorder: FC<DraftBorderProps> = (props) => {
    const { width, border, start_time, end_time, isRounded, ...divProps } = props;
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const startsAfterLeftBoundary = +start_time > visibleTimeStart;
    const endsBeforeRightBoundary = +end_time < visibleTimeEnd;

    const roundedProps: CSSProperties = isRounded
        ? {
              borderTopLeftRadius: CALENDAR_LINE_ROUND_RADIUS,
              borderBottomLeftRadius: CALENDAR_LINE_ROUND_RADIUS,
              borderTopRightRadius: CALENDAR_LINE_ROUND_RADIUS,
              borderBottomRightRadius: CALENDAR_LINE_ROUND_RADIUS,
          }
        : {};

    return (
        <div
            {...divProps}
            className={classNames('calendar-line-border', divProps.className)}
            style={{
                ...divProps.style,
                ...roundedProps,
                width,
                border,
                borderRight: endsBeforeRightBoundary ? border : 'none',
                borderLeft: startsAfterLeftBoundary ? border : 'none',
            }}
        />
    );
};

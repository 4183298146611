import {Radio} from "antd";
import {contractValidationTypesAllTypes, contractValidationTypesMap} from "../../../../../../shared/util/utils4";
import React, {useMemo, useState} from "react";
import {ContractValidationTypeCodeEnum} from "../../../../../../server";
import {WarningsList} from "../../../../../../components/text/WarningsList/WarningsList";

interface ContractValidationTypeRadioGroupProps {
    value?: ContractValidationTypeCodeEnum;
    onChange?: (value: ContractValidationTypeCodeEnum) => void;
}

export const ContractValidationTypeRadioGroup = (props: ContractValidationTypeRadioGroupProps) => {
    const [defaultValue] = useState(props.value);

    const warning = useMemo(()=>{
        if (defaultValue === ContractValidationTypeCodeEnum.NOVALIDATION && props.value === ContractValidationTypeCodeEnum.ONLYFILE) {
            return 'После применения настройки все проекты, в которых не загружен файл договора будут переведены из статуса "В работе" в статус "Согласование"!';
        } else if ((defaultValue === ContractValidationTypeCodeEnum.NOVALIDATION || defaultValue === ContractValidationTypeCodeEnum.ONLYFILE) && props.value === ContractValidationTypeCodeEnum.ALLFIELDS) {
            return 'После применения настройки все проекты, в которых не загружен файл договора, не указан номер или дата договора, а также истек срок действия договора будут переведены из статуса "В работе" в статус "Согласование"!';
        }
    }, [defaultValue, props.value])

    return (
        <>
            <Radio.Group value={props.value} onChange={(e) => {
                props.onChange?.(e.target.value);
            }}>
                {
                    contractValidationTypesAllTypes.map(code => {
                        return (
                            <Radio value={code}>{contractValidationTypesMap[code]}</Radio>
                        );
                    })
                }
            </Radio.Group>
            {
                warning && <WarningsList warnings={[warning]}/>
            }
        </>

    );
};

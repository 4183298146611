import React from 'react';
import {connect} from "react-redux";
import {IRootState} from "../../../../shared/reducers";
import {RouteComponentProps} from "react-router";
import {RouteConfigComponentProps} from "react-router-config";
import {push} from "connected-react-router";
import UsersModuleList from "./usersModuleList";
import {Icon} from "antd";
import {IconPlus} from "../../../../components/icons";
import {RoundButton} from "../../../../components";
import UserInvitedModal from "./usersModuleInviteUserModal";
import {LocalizationEnum, localize} from '../../../../localization';
import {SettingsPageWrapper} from "../settingsPageWrapper";

interface UsersModuleProps extends StateProps, DispatchProps, RouteComponentProps, RouteConfigComponentProps {
}

interface State {
}

class UsersModule extends React.PureComponent<UsersModuleProps, State> {

    onAddButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/settings/users/inviteUser${this.props.locationSearchParams}`);
    };

    render() {
        let {businessAccount} = this.props;

        let usersCountLimit = businessAccount?.allRolesUserCounters?.userCountLimit || 0;
        let usersCount = businessAccount?.allRolesUserCounters?.userCount || 0;
        let usersActiveInvitationCount = businessAccount?.allRolesUserCounters?.activeInvitationCount || 0;

        let managersCountLimit = businessAccount?.managerRolesUserCounters?.userCountLimit || 0;
        let managersCount = businessAccount?.managerRolesUserCounters?.userCount || 0;
        let managersActiveInvitationCount = businessAccount?.managerRolesUserCounters?.activeInvitationCount || 0;

        let canAddUser = usersCount + usersActiveInvitationCount < usersCountLimit;

        return (
            <SettingsPageWrapper title={localize(LocalizationEnum.PAGE__SETTINGS__USERS__SECTION_TITLE)} extra={
                <>
                    <span style={{marginRight: 32}}>Всего пользователей:<span style={{
                      marginLeft: 6,
                      color: '#343F9B',
                      fontWeight: 600
                  }}>{usersCount + usersActiveInvitationCount} <span style={{fontWeight: 400}}>из</span> {usersCountLimit}</span></span>
                    <span style={{marginRight: 40}}>Менеджеры:<span style={{
                        marginLeft: 6,
                        color: '#343F9B',
                        fontWeight: 600
                    }}>{managersCount + managersActiveInvitationCount} <span style={{fontWeight: 400}}>из</span> {managersCountLimit}</span></span>
                    <RoundButton colorScheme={'success'} onClick={this.onAddButtonClick} disabled={!canAddUser}>
                        <Icon component={IconPlus}/>{localize(LocalizationEnum.PAGE__ACTIONS__INVITE_USER, 'span')}
                    </RoundButton>
                </>
            }>
                <UsersModuleList />
                {
                    this.props.editCountMode ? <UserInvitedModal /> : null
                }
            </SettingsPageWrapper>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.usersBA.entities,
        locationSearchParams: storeState.router.location.search,
        businessAccountId: storeState.system.businessAccountId,
        editCountMode: !!(storeState.usersBA.entities && storeState.router.location.pathname === `/${storeState.system.businessAccountId}/settings/users/inviteUser`),
        businessAccount: storeState.businessAccount.entity
    };
};

const mapDispatchToProps = {push};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersModule);

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { IRootState } from '../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../components/modalForm/utils';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { resetErrors } from '../../../../../renters/reducers/renters/renter.reducer';
import { formFields } from './VehicleCreateModalFields';
import { createVehicle } from '../../reducers/vehicle/vehicle.reducer';
import { ContactLinkObjWrite, VehicleInfoCreate, VehicleTypeCodeEnum } from '../../../../../../../server';
import { MetricUtils } from '../../../../../../../core/utils/metricUtils';
import { getStore } from '../../../../../../../../index';
import { showNotification } from '../../../../../../../components/notification/showNotification';

export interface VehicleCreateModalProps extends ModalFormNEWProps, WrappedComponentProps {
    typeCode?: VehicleTypeCodeEnum;
}

export class _VehicleCreateModal extends ModalFormNEW<VehicleCreateModalProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = async (data: unknown) => {
        const formData = data as VehicleInfoCreate;
        const imageResponse = formData?.image?.[0]?.response;
        const image = imageResponse
            ? {
                  id: imageResponse.id,
              }
            : undefined;
        let { contacts } = formData;
        if (contacts) contacts = contacts.filter((contact) => contact.contactId);
        try {
            const vehicle = await getStore().dispatch(
                createVehicle({
                    businessAccountId: this.props.businessAccountId,
                    vehicleInfoCreate: {
                        ...formData,
                        loadCapacity: formData.loadCapacity ? MetricUtils.fromTonsToGrams(formData.loadCapacity) : undefined,
                        length: formData.length ? MetricUtils.fromMetersToMillimeters(formData.length) : undefined,
                        width: formData.width ? MetricUtils.fromMetersToMillimeters(formData.width) : undefined,
                        height: formData.height ? MetricUtils.fromMetersToMillimeters(formData.height) : undefined,
                        image,
                        contacts,
                    },
                })
            );
            showNotification('success', 'Транспортное средство создано');
            this.props.onSuccess?.(vehicle);
        } catch (e) {
            showNotification('error', 'Не удалось создать транспортное средство');
        }
    };

    onCancel = async (isFieldsTouched: boolean) => {
        const yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.onCancel?.();
            this.props.resetErrors();
        }
    };

    getInitialValues = (): Partial<VehicleInfoCreate> => {
        return {
            contacts: [
                {
                    contactId: undefined as unknown,
                    position: '',
                } as ContactLinkObjWrite,
            ],
            type: this.props.typeCode,
        };
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.vehicle.updating,
    updatingError: storeState.vehicle.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    resetErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const VehicleCreateModal = connector(injectIntl(_VehicleCreateModal));

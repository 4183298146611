import React from 'react';
import {Popover} from "antd";
import {PopoverProps} from "antd/lib/popover";
import classNames from "classnames";
import './simplePopover.less';

interface SimplePopoverProps extends PopoverProps {

}

export const SimplePopover = (props: SimplePopoverProps) => {
    return (
        <Popover
            {...props}
            overlayClassName={classNames('rr-simple-popover', props.overlayClassName)}
        >
            {props.children}
        </Popover>
    );
};

import React, { FC, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../../../components/dataDisplay';
import { LocalizationEnum } from '../../../../../../../../localization';
import { DataDisplayItemTheme } from '../../../../../../../../components/dataDisplay/dataDisplayItemProps';
import { ProjectInfoRead, ProjectStateCodeEnum, RentStateCodeEnum, TypeOfPricingCodeEnum } from '../../../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import {
    crewModuleEnabledSelector,
    offersModuleEnabledSelector,
} from '../../../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { canViewFinancialDataSelector } from '../../../../../../../../shared/reducers/permissions.reducer';
import { Icon, Tooltip } from 'antd';
import { IconCoinsSolid } from '../../../../../../../../components/icons';
import { isDefined } from '../../../../../../../../shared/util/utils';
import { useLocalize } from '../../../../../../../../core/hooks/useLocalize';
import { CollapsibleBlock } from '../../../../../../../../components/v2/collapsibleBlock/CollapsibleBlock';
import { AddButton } from '../../../../../../../../components/buttons/addButton/addButton';
import { push } from 'connected-react-router';

interface ProjectPageDescriptionPriceBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionPriceBlock: FC<ProjectPageDescriptionPriceBlockProps> = ({ data }) => {
    const location = useLocation();
    const L = useLocalize();
    const dispatch = useAppDispatch();
    const crewModuleEnabled = useAppSelector(crewModuleEnabledSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const offersModuleEnabled = useAppSelector(offersModuleEnabledSelector);

    const newProjectAndPricingIsStraight =
        data.stateCode === ProjectStateCodeEnum.DRAFT && data.typeOfPricing === TypeOfPricingCodeEnum.STRAIGHT;

    let offerBlock: ReactElement | undefined;
    const finalProjectCostBlockTheme =
        data.typeOfPricing === TypeOfPricingCodeEnum.FROMOFFERS ? DataDisplayItemTheme.GREY : DataDisplayItemTheme.DARK;
    const finalProjectCostBlockHint =
        data.typeOfPricing === TypeOfPricingCodeEnum.FROMOFFERS ? <>(Не учитывается, ценообразование по сметам)</> : undefined;

    const isProjectCancelled = data?.stateCode === ProjectStateCodeEnum.CANCELED;

    if (offersModuleEnabled) {
        if (!data.totalNotCanceledOffersCount || data.totalNotCanceledOffersCount < 0) {
            offerBlock = (
                <CollapsibleBlock
                    title={'Смет нет'}
                    hideCollapseIcon={true}
                    disabled={true}
                    style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}
                    extraContent={
                        <Tooltip title={isProjectCancelled ? 'Действие недоступно в отмененном проекте' : undefined}>
                            <div>
                                <AddButton
                                    label={null}
                                    onClick={(e) => {
                                        dispatch(push(`/${data.businessAccountId}/projects/production/${data.id}/newOffer`));
                                    }}
                                    disabled={isProjectCancelled}
                                />
                            </div>
                        </Tooltip>
                    }
                />
            );
        } else {
            const offerCostBlockTheme =
                data.typeOfPricing === TypeOfPricingCodeEnum.STRAIGHT ? DataDisplayItemTheme.GREY : DataDisplayItemTheme.DARK;
            const offerCostBlockHint =
                data.typeOfPricing === TypeOfPricingCodeEnum.STRAIGHT ? <>(Не учитывается, ценообразование прямое)</> : undefined;
            const offerCheckIconColor = data.typeOfPricing === TypeOfPricingCodeEnum.STRAIGHT ? '#AAA' : '#fc8c2f';

            const isProjectCancelled = data?.stateCode === ProjectStateCodeEnum.CANCELED;

            offerBlock = (
                <div style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center', height: 21 }}>
                                            <span>Сметы проекта</span>
                                            <Tooltip title={isProjectCancelled ? 'Действие недоступно в отмененном проекте' : undefined}>
                                                <div>
                                                    <AddButton
                                                        style={{ marginLeft: 6 }}
                                                        label={null}
                                                        onClick={(e) => {
                                                            dispatch(
                                                                push(
                                                                    `/${data.businessAccountId}/projects/production/${data.id}/newOffer${location.search}`
                                                                )
                                                            );
                                                        }}
                                                        disabled={isProjectCancelled}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ),
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <span className={'rr-value'}>
                                            <Icon
                                                component={IconCoinsSolid}
                                                style={{ color: offerCheckIconColor, fontSize: 21, marginRight: 4 }}
                                            />
                                            {isDefined(data.totalPartOfPriceOffersCount) && data.totalPartOfPriceOffersCount > 0 ? (
                                                <>
                                                    <Link
                                                        to={`/${data.businessAccountId}/projects/production/${data.id}?tab=offers&hide=cancelled&partOfThePrice=true`}
                                                    >
                                                        {data.totalPartOfPriceOffersCount}
                                                        <span style={{ fontSize: 14, fontWeight: 'bold' }}> в стоимости</span>
                                                    </Link>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>, всего </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className={'rr-empty-value'}>0</span>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}> в стоимости, всего </span>
                                                </>
                                            )}
                                            {isDefined(data.totalNotCanceledOffersCount) && data.totalNotCanceledOffersCount > 0 ? (
                                                <Link
                                                    to={`/${data.businessAccountId}/projects/production/${data.id}?tab=offers&hide=cancelled&projectId=${data.id}`}
                                                >
                                                    {data.totalNotCanceledOffersCount}
                                                </Link>
                                            ) : (
                                                <span className={'rr-empty-value'}>0</span>
                                            )}
                                        </span>
                                    ),
                                    style: { marginRight: 32 + 8, height: '100%' },
                                },
                                {
                                    label: 'Итоговая стоимость по сметам',
                                    type: DataDisplayItemType.Money,
                                    value: data.offersSumAfterTaxes,
                                    emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                    theme: offerCostBlockTheme,
                                    style: { marginLeft: -32, height: '100%' },
                                    hint: offerCostBlockHint,
                                },
                            ],
                        ]}
                    />
                </div>
            );
        }
    }

    return (
        <>
            {canViewFinancialData && (
                <>
                    <div style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                        <DataDisplayBlock
                            data={
                                crewModuleEnabled
                                    ? [
                                          //TODO
                                          [
                                              {
                                                  label: 'Стоимость работ',
                                                  type: DataDisplayItemType.Money,
                                                  value: data.costOfWork,
                                                  rentType: newProjectAndPricingIsStraight ? RentStateCodeEnum.DRAFT : undefined,
                                                  emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                              },
                                              {
                                                  label: 'Стоимость затрат',
                                                  type: DataDisplayItemType.Money,
                                                  value: data.costOfExpenses,
                                                  rentType: newProjectAndPricingIsStraight ? RentStateCodeEnum.DRAFT : undefined,
                                                  emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                                  style: { paddingLeft: 0 },
                                              },
                                          ],
                                      ]
                                    : [
                                          [
                                              {
                                                  label: 'Стоимость затрат',
                                                  type: DataDisplayItemType.Money,
                                                  value: data.costOfExpenses,
                                                  rentType: newProjectAndPricingIsStraight ? RentStateCodeEnum.DRAFT : undefined,
                                                  emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                              },
                                          ],
                                      ]
                            }
                        />
                    </div>
                    <div style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Итого до налогов',
                                        type: DataDisplayItemType.Money,
                                        value: data.actualSumBeforeTaxes,
                                        emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                        style: { marginRight: 32 + 8 },
                                    },
                                    {
                                        label: 'Итоговая стоимость проекта',
                                        type: DataDisplayItemType.Money,
                                        value: data.actualSumAfterTaxes,
                                        emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                        theme: finalProjectCostBlockTheme,
                                        hint: finalProjectCostBlockHint,
                                        style: { marginLeft: -32 },
                                    },
                                ],
                            ]}
                        />
                    </div>
                    {offerBlock}
                </>
            )}
        </>
    );
};

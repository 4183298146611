import { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { EntityActions, EntityActionsHook } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { ProfessionInfoRead, SimpleTransitionCodeEnum } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { URLDrawerParams } from '../../../../../../components/page/entityGrid/types/params';
import {
    professionsApi,
    useDeleteProfessionMutation,
    useLazyProfessionByIdQuery,
    useSetStatusForProfessionsMutation,
} from '../api/professions.api';
import { showConfirm } from '../../../../../../components/confirm/showConfirm';
import { useIntl } from 'react-intl';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';

type ProfessionRowActions = EntityActions<ProfessionInfoRead>;

export const useProfessionEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const [setProfessionsStatus, { isLoading: isSetStatusLoading }] = useSetStatusForProfessionsMutation();

    const [deleteProfession, { isLoading: isDeleteProfessionLoading }] = useDeleteProfessionMutation();

    const [getProfessionById] = useLazyProfessionByIdQuery();

    const isLoading = isSetStatusLoading || isDeleteProfessionLoading;

    const editProfessionCallback: ProfessionRowActions['editAction'] = useCallback(
        (profession, options) => {
            dispatch(
                professionsApi.endpoints.professionById.initiate(
                    {
                        businessAccountId,
                        entityId: profession.id,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.delete('modal');
            searchParams.append('modal', modalType);
            searchParams.delete('id');
            searchParams.append('id', String(profession.id));

            if (options?.withView != null) {
                if (options.withView) {
                    const booleanParam = PageURLParamDescriptions.booleanParam.toString(options.withView);
                    if (booleanParam) {
                        searchParams.append('view', booleanParam);
                    }
                } else {
                    searchParams.delete('view');
                }
            }

            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editProfessionForDrawerCallback: ProfessionRowActions['editActionForDrawer'] = useCallback(
        (profession: ProfessionInfoRead) => {
            editProfessionCallback(profession, { withView: true });
        },
        [editProfessionCallback]
    );

    const archiveProfessionCallback = useCallback(
        (profession: ProfessionInfoRead) => {
            setProfessionsStatus({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: profession.id,
                            archive: !profession.archive,
                            businessVersion: profession.businessVersion,
                        },
                    ],
                },
            });
        },
        [setProfessionsStatus]
    );

    const deleteProfessionCallback = useCallback(
        async (profession: ProfessionInfoRead) => {
            const deleteCallback = () => {
                deleteProfession({
                    id: profession.id,
                    businessVersionObj: {
                        businessVersion: profession.businessVersion,
                    },
                });
            };

            try {
                const { data: actualProfession } = await getProfessionById({
                    businessAccountId,
                    entityId: profession.id,
                });
                if (actualProfession?.ownActiveCrewMemberCount) {
                    const yes = await showConfirm(
                        intl,
                        'Вы уверены что хотите удалить специальность?',
                        localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__DELETE_PROFESSION, undefined, {
                            value: actualProfession.ownActiveCrewMemberCount,
                            b: (text) => <b>{text}</b>,
                        }),
                        undefined,
                        700
                    );
                    if (yes) {
                        deleteCallback();
                    }
                } else {
                    const yes = await showConfirm(intl, 'Вы уверены что хотите удалить специальность?');
                    if(yes) deleteCallback();
                }
            } catch (e) {}
        },
        [businessAccountId, deleteProfession, getProfessionById, intl]
    );

    const setProfessionStatusCallback = useCallback(
        (profession: ProfessionInfoRead) => {
            const transitionCode = profession.stateCode === 'BLOCKED' ? SimpleTransitionCodeEnum.ACTIVATE : SimpleTransitionCodeEnum.BLOCK;

            setProfessionsStatus({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: profession.id,
                            businessVersion: profession.businessVersion,
                            transitionCode,
                        },
                    ],
                },
            });
        },
        [setProfessionsStatus]
    );

    const onRowAction: ProfessionRowActions['onRowAction'] = useCallback(
        (profession, action) => {
            const actionHandler = (
                {
                    edit: () => editProfessionCallback(profession),
                    archive: () => archiveProfessionCallback(profession),
                    delete: () => deleteProfessionCallback(profession),
                    lock: () => setProfessionStatusCallback(profession),
                } satisfies Partial<Record<typeof action, Function>>
            )[action];

            if (actionHandler) actionHandler();
        },
        [archiveProfessionCallback, deleteProfessionCallback, editProfessionCallback, setProfessionStatusCallback]
    );

    return useMemo(
        () => ({
            editAction: editProfessionCallback,
            editActionForDrawer: editProfessionForDrawerCallback,
            archiveActionForDrawer: archiveProfessionCallback,
            deleteActionForDrawer: deleteProfessionCallback,
            onRowAction,
            isLoading,
        }),
        [
            archiveProfessionCallback,
            deleteProfessionCallback,
            editProfessionCallback,
            editProfessionForDrawerCallback,
            isLoading,
            onRowAction,
        ]
    );
}) satisfies EntityActionsHook<ProfessionInfoRead>;

import { useCallback } from 'react';
import { TransportationRecord } from '../../../../../../server';

export const useOnCellAction = ({
    setLocationId,
    setVehicleId,
}: {
    setLocationId: (locationId?: number) => void;
    setVehicleId: (vehicleId?: number) => void;
}) => {
    return useCallback(
        (dataIndex: string, record: TransportationRecord) => {
            const handlers: Partial<Record<keyof TransportationRecord, Function>> = {
                loadingLocationName: () => {
                    setLocationId(record.loadingLocationId);
                },
                unloadingLocationName: () => {
                    setLocationId(record.unloadingLocationId);
                },
                carName: () => {
                    setVehicleId(record.carId);
                },
                trailerId: () => {
                    setVehicleId(record.trailerId);
                },
            };

            if (handlers[dataIndex]) handlers[dataIndex]();
        },
        [setLocationId, setVehicleId]
    );
};

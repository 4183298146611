import React from 'react';
import {Card, Col, Icon, Row, Tooltip} from "antd";
import {connect} from "react-redux";
import {IRootState} from "../../../../shared/reducers";
import {loadSettings, resetSettings, updateSettings} from "./reducers/systemOptions.reducer";
import {FormFieldsGroup, FormItemType, IFormField} from "../../../../components/dynamicForm/DynamicForm";
import {SystemOptionRecordTypeCodeEnum} from "../../../../server/api";
import Spin from "../../../../components/spin/spin";
import {replace} from "connected-react-router";
import {IconEdit, IconPencil} from "../../../../components/icons";
import {RoundButton} from "../../../../components";
import {Link, Route} from "react-router-dom";
import SystemSettingsEditModal from "./systemOptionsEditModal";
import './systemOptionsModule.less';
import {RouteComponentProps} from "react-router";
import {RouteConfigComponentProps} from "react-router-config";
import moment from "moment";
import {getStateFromPath2} from "../../../../shared/util/utils";
import {LocalizationEnum, localize} from '../../../../localization';

interface IProps extends StateProps, DispatchProps, RouteComponentProps, RouteConfigComponentProps {
}

class SystemOptionsModule extends React.PureComponent<IProps> {

    componentDidMount () {
        this.props.loadSettings(this.props.route?.sortOrder);
    };

    componentWillUnmount () {
        this.props.resetSettings();
    };

    render() {
        let { route, settings } = this.props;

        let data:FormFieldsGroup[] = settings ? settings.map((item) => {
            let fields = item.systemOptions ? item.systemOptions.map((option) => {

                let type;
                let value = option.value as any;
                if(option.typeCode === SystemOptionRecordTypeCodeEnum.BOOLEAN){
                    type = FormItemType.Switch;
                    value = value === 'true' ? true : value === 'false' ? false : undefined;
                }
                else if(option.typeCode === SystemOptionRecordTypeCodeEnum.DATE) type = FormItemType.Date;
                else if(option.typeCode === SystemOptionRecordTypeCodeEnum.DATETIME) type = FormItemType.DateTime;
                else if(option.typeCode === SystemOptionRecordTypeCodeEnum.DOUBLE) type = FormItemType.Number;
                else if(option.typeCode === SystemOptionRecordTypeCodeEnum.LONG) {
                    type = FormItemType.Integer;
                    value = +value;
                }
                else if(option.typeCode === SystemOptionRecordTypeCodeEnum.STRING) type = FormItemType.String;

                return {
                    label: option.fullName,
                    id: '' + option.id,
                    type: type,
                    defaultValue: value,
                    required: option.required,
                    tooltip: <><div>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CODE)}: <span style={{fontWeight: 600}}>{option.code}</span></div><div>{option.description}</div></>
                };
            }) : [];

            return {
                title: item.fullName,
                fields: fields,
                tooltip: <><div>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CODE)}: <span style={{fontWeight: 600}}>{item.code}</span></div><div>{item.description}</div></>,
                id: item.code
            };
        }) : [];

        let searchParams = getStateFromPath2(this.props.location.search);
        let sectionData:any = data.find((item) => searchParams.section && item.id === searchParams.section);
        if(sectionData) sectionData = [sectionData];

        return (
            <>
                <Spin spinning={this.props.loading}>
                    <div className={'rr-page-system-settings'}>
                        <Card className={'rr-card'}
                              bodyStyle={{display: 'none'}}
                              title={<span className="card-title">{route?.title}</span>}
                              extra={
                                  <Link to={`/${this.props.businessAccountId}/settings/extras/edit`}>
                                      <RoundButton colorScheme={'default'}><Icon component={IconEdit} /> {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</RoundButton>
                                  </Link>
                              }
                        />
                        { this.props.settings ? this.renderSettings(data) : null }
                    </div>
                </Spin>
                <Route exact path={`/${this.props.businessAccountId}/settings/extras/edit`} render={props => (
                    <SystemSettingsEditModal
                            initialValues={{}}
                            sectionId={this.props.route?.sortOrder}
                            settings={this.props.settings}
                            goBackPath={`/${this.props.businessAccountId}/settings/extras`}
                            formFields={sectionData ? sectionData : data}
                            title={localize(LocalizationEnum.PAGE__SETTINGS__EXTRA__EDITING_LABEL)} />
                )} />
            </>
        );
    }

    renderSettings = (data) => (
        <>
            {
                data.map((group, index) => (
                    <Card key={index}
                          id={group.id}
                          className={'rr-card'}
                          title={<>
                              <Tooltip mouseEnterDelay={.6} placement="right" title={group.tooltip}>{group.title}</Tooltip>
                              <Link to={`/${this.props.businessAccountId}/settings/extras/edit?section=${group.id}`}>
                                  <Icon style={{fontSize: 20, position: 'relative', top: 2, marginLeft: 10}} component={IconPencil} />
                              </Link>
                          </>}
                          style={{marginTop: 32}}>
                        <Row>
                            {
                                group.fields.map((field:IFormField, index) => {
                                    let value = field.defaultValue;
                                    if(field.type === FormItemType.Date){
                                        value = moment(field.defaultValue).format("D MMMM YYYY");
                                    }else if(field.type === FormItemType.DateTime){
                                        value = moment(field.defaultValue).format("D MMMM YYYY HH:mm");
                                    }else if(field.type === FormItemType.Switch){
                                        if(value === true || value === 'true') value = localize(LocalizationEnum.ASPECT__GLOBAL__YES);
                                        else if(value === false || value === 'false') value = localize(LocalizationEnum.ASPECT__GLOBAL__NO);
                                    }
                                    return (
                                        <Col key={index} className={'rr-form-item'}>
                                            <div className={'rr-label'}>{field.label}</div>
                                            <div className='rr-value'>{value}</div>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Card>
                ))
            }
        </>
    );

}

const mapStateToProps = (storeState: IRootState) => ({
    settings: storeState.systemOptions.entities,
    loading: storeState.systemOptions.loading,
    businessAccountId: storeState.system.businessAccountId
});

const mapDispatchToProps = { loadSettings, updateSettings, replace, resetSettings };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemOptionsModule);
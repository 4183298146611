import React, { FC } from 'react';
import AdditionalEntityActions from '../../../../../../../../components/additionalEntityActions/additionalEntityActions';
import { Button, Icon } from 'antd';
import { IconCalendarPlus, IconDownloadSolid, IconLinkSolid } from '../../../../../../../../components/icons';
import './ICalButton.less';
import { copyToClipboard } from '../../../../../../../../shared/util/copyToClipboard';
import { API_SERVER_BASE_PATH } from '../../../../../../../../config/config';
import { showNotification } from '../../../../../../../../components/notification/showNotification';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../../../../localization';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../../../../../store/hooks';

interface iCalButtonProps {}

export const ICalButton: FC<iCalButtonProps> = (props) => {
    const intl = useIntl();
    const calendarAccessToken = useAppSelector((storeState) => storeState.businessAccountPreferences.preferences?.calendarAccessToken);

    return calendarAccessToken ? (
        <AdditionalEntityActions
            content={
                <>
                    <Button
                        className={'ant-btn-page-actions'}
                        onClick={() => {
                            calendarAccessToken &&
                                copyToClipboard(`${API_SERVER_BASE_PATH}/calendar/${calendarAccessToken}`, () =>
                                    showNotification(
                                        'success',
                                        localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__POPUP_NOTIFICATIONS__LINK_COPIED)
                                    )
                                );
                        }}
                    >
                        <Icon component={IconLinkSolid} style={{ color: '#04b0f1' }} />
                        {localize(LocalizationEnum.ASPECT__GLOBAL__COPY_LINK, 'span')}
                    </Button>
                    <Button
                        className={'ant-btn-page-actions'}
                        href={`${API_SERVER_BASE_PATH}/calendar/${calendarAccessToken}`}
                        target={calendarAccessToken}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Icon component={IconDownloadSolid} style={{ color: '#21bfa4' }} />{' '}
                        {localize(LocalizationEnum.ASPECT__FORMS__DOWNLOAD_FILE, 'span')}
                    </Button>
                </>
            }
        >
            <span className={'iCalc-container'}>
                <Icon component={IconCalendarPlus} />
                <span>iCal</span>
            </span>
        </AdditionalEntityActions>
    ) : null;
};

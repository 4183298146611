import React, { FC, ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import { CollapsibleBlock, CollapsibleBlockProps } from '../v2/collapsibleBlock/CollapsibleBlock';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { canViewFinancialDataSelector } from '../../shared/reducers/permissions.reducer';
import './entityIndicatorsBlock.less';
import {
    getRentIndicatorsBlockSettingsSelector,
    RentIndicatorsBlockName,
    RentIndicatorsBlockSettings,
    updateRentIndicatorsBlockSettings,
} from '../../shared/reducers/userSettings/userSettings.reducer';

export interface EntityIndicatorsBlockProps {
    title: ReactNode;
    icon: ReactNode;
    storageKey: RentIndicatorsBlockName;
    problem?: 'warning' | 'error' | undefined;
    className?: string;
    children: ReactNode;
}

export const EntityIndicatorsBlock: FC<EntityIndicatorsBlockProps> = ({ title, icon, storageKey, className, problem, children }) => {
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const dispatch = useAppDispatch();
    const settings: RentIndicatorsBlockSettings | undefined = useAppSelector(getRentIndicatorsBlockSettingsSelector)[storageKey];
    const collapsed = settings?.collapsed;

    const onCollapse: CollapsibleBlockProps['onCollapse'] = useCallback((collapsed) => {
        dispatch(updateRentIndicatorsBlockSettings({ [storageKey]: { collapsed: collapsed } }));
    }, []);

    return (
        <CollapsibleBlock
            collapsed={collapsed}
            onCollapse={onCollapse}
            title={title}
            icon={icon}
            extraContent={canViewFinancialData ? 'Число и сумма обязательств' : 'Число обязательств'}
            className={classNames('rr-rent-indicators-block', className, problem && `rr-rent-indicators-block-${problem}`)}
        >
            {children}
        </CollapsibleBlock>
    );
};

import {AccessibilityMapCalendarItem as IAccessibilityMapCalendarItem, CalendarItem} from '../../../../components/Calendar/types/items';
import {CalendarItemRendererProps} from '../../../../components/CalendarItemRenderer/CalendarItemRenderer';
import {CalendarLineContainer} from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarLineContainer/CalendarLineContainer';
import {CALENDAR_LINE_BASE_RADIUS} from '../../../../components/Calendar/utils/data/calendarConstants';
import {AccessibilityMapItemContext} from '../../../../components/CalendarItemRenderer/context/CalendarItemContext';
import {ReactCalendarItemRendererProps} from 'react-calendar-timeline';
import {AccessibilityMapCalendarItem} from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarItem/variants/AccessibilityMapCalendarItem';
import {CalendarLineUtils} from '../../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import {useContext} from 'react';
import {TimeRangeContext} from '../../../../components/CalendarItemRenderer/context/TimeRangeContext';
import classNames from 'classnames';

export const SummaryScheduleCalendarItemRenderer = <SortValue extends string>(
    props: CalendarItemRendererProps<SortValue, IAccessibilityMapCalendarItem>
) => {
    const { baseProps, calendarProps } = props;
    const { displayType, onItemClick } = baseProps;
    const {
        item,
        itemContext: {
            dimensions: { height },
        },
    } = calendarProps;
    const { start_time, end_time } = item;
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const groupHeight = CalendarLineUtils.map.groupHeightFromDisplayType.large[displayType];

    return (
        <CalendarLineContainer
            calendarProps={calendarProps}
            displayType={displayType}
            borderRadius={CALENDAR_LINE_BASE_RADIUS}
            onItemClick={onItemClick}
            linkPath={undefined}
            className={classNames('item', 'small-shadow', {
                'left-boundary': +start_time <= visibleTimeStart,
                'right-boundary': +end_time >= visibleTimeEnd,
            })}
            style={{
                transform: `translate(0px, ${(groupHeight - height) / 2}px)`,
                marginTop: 0,
                zIndex: 2,
            }}
        >
            <AccessibilityMapItemContext.Provider
                value={calendarProps as ReactCalendarItemRendererProps<CalendarItem<IAccessibilityMapCalendarItem>>}
            >
                <AccessibilityMapCalendarItem />
            </AccessibilityMapItemContext.Provider>
        </CalendarLineContainer>
    );
};

import React, { FC, memo } from 'react';
import { Icon } from 'antd';
import { useAppSelector } from '../../../../../store/hooks';
import { useGetExpenseByIdQuery } from '../../api/expenses.api';
import { EntityDrawer } from '../../../../../components/v2/entityDrawer/EntityDrawer';
import { ExpenseInfoRead, ExpenseTransitionCodeEnum } from '../../../../../server';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../components/dataDisplay';
import { useSetStatusForExpensesCallback } from '../../hooks/expensesListHooks';
import { IconMoneyBillWaveSolid } from '../../../../../components/icons';
import { ActionButtonsGroup } from '../../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { expenseEntityActionButtons, ExpenseEntityActionType } from '../../data/expensesData';
import { ExpenseStateBadge } from '../../components/expenseStateBadge';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { EntityActionsModalOptions } from '../../../../../components/page/entityGrid/hooks/useRowActions';
import { expensesLoadingSelector } from '../../reducers/expenses/expenses.selectors';

export interface ExpenseDrawerProps {
    expenseId: number;
    onClose: () => void;
    isLoading?: boolean;
    editExpense?: (entity: ExpenseInfoRead) => void;
    copyExpense?: (entity: ExpenseInfoRead, options?: EntityActionsModalOptions) => void;
}

export const ExpenseDrawer: FC<ExpenseDrawerProps> = memo((props) => {
    const { expenseId, onClose, editExpense, copyExpense } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const isStatusChangeLoading = useAppSelector(expensesLoadingSelector);

    const {
        data: expense,
        error,
        isFetching,
        isLoading,
    } = useGetExpenseByIdQuery(
        {
            businessAccountId,
            entityId: expenseId,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const setStatusForExpensesCallback = useSetStatusForExpensesCallback(expense ? [expense] : []);

    return (
        <>
            <EntityDrawer
                headerProps={{
                    icon: <Icon component={IconMoneyBillWaveSolid} />,
                    title: <>Затрата <span style={{fontWeight: 400}}>#{expense?.numberInProject}</span></>,
                    actions: (
                        <ActionButtonsGroup
                            disabled={isLoading || isStatusChangeLoading || isFetching}
                            mainActions={[ExpenseEntityActionType.Edit]}
                            additionalActions={[
                                ExpenseEntityActionType.Copy,
                                expense?.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.CANCEL) &&
                                    ExpenseEntityActionType.Cancel,
                                expense?.availableTransitionCodes?.includes(ExpenseTransitionCodeEnum.TAKEINTOACCOUNT) &&
                                    ExpenseEntityActionType.TakeIntoAccount,
                            ]}
                            onAction={(action) => {
                                if (expense) {
                                    if (action === ExpenseEntityActionType.Edit) editExpense?.(expense);
                                    else if (action === ExpenseEntityActionType.Copy) copyExpense?.(expense);
                                    else if (action === ExpenseEntityActionType.Cancel)
                                        setStatusForExpensesCallback(ExpenseTransitionCodeEnum.CANCEL);
                                    else if (action === ExpenseEntityActionType.TakeIntoAccount)
                                        setStatusForExpensesCallback(ExpenseTransitionCodeEnum.TAKEINTOACCOUNT);
                                }
                            }}
                            data={expenseEntityActionButtons}
                        />
                    ),
                    stateBadge: expense && <ExpenseStateBadge type={expense.state} />,
                }}
                onClose={onClose}
                error={error}
                isLoading={isLoading || isStatusChangeLoading}
                isFetching={isFetching}
            >
                {expense && (
                    <>
                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Наименование',
                                        type: DataDisplayItemType.String,
                                        value: expense.name,
                                    },
                                    {
                                        label: 'Дата',
                                        type: DataDisplayItemType.DateWithTime,
                                        value: expense.date,
                                    },
                                ],
                                [
                                    {
                                        label: 'Арендатор проекта',
                                        type: DataDisplayItemType.Counterparty,
                                        value: {
                                            baId: businessAccountId,
                                            id: expense.renterId,
                                            name: expense.renterShortName,
                                        },
                                    },
                                    {
                                        label: 'Проект',
                                        type: DataDisplayItemType.Project,
                                        value: {
                                            baId: businessAccountId,
                                            id: expense.projectId,
                                            name: expense['projectShortName'],
                                        },
                                    },
                                ],
                            ]}
                        />

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Внутренняя стоимость',
                                        type: DataDisplayItemType.Money,
                                        value: expense.internalFinalTotalPrice,
                                    },
                                ],
                                [
                                    {
                                        label: 'Внешняя стоимость',
                                        type: DataDisplayItemType.Money,
                                        value: expense.externalFinalTotalPrice,
                                    },
                                    {
                                        label: 'Внешняя стоимость после налога',
                                        type: DataDisplayItemType.Money,
                                        value: expense.includeTaxInExternalExpenseSum ? expense.externalFinalTotalPriceAfterTaxes : undefined,
                                        emptyValueText: 'Не облагается налогом',
                                    }
                                ],
                            ]}
                        />

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Дата последнего изменения',
                                        type: DataDisplayItemType.DateWithTime,
                                        value: expense.lastUpdateDate,
                                    },
                                    {
                                        label: 'Автор последнего изменения',
                                        type: DataDisplayItemType.User,
                                        value: expense?.lastUpdateAuthorFullName
                                            ? {
                                                  name: expense.lastUpdateAuthorFullName,
                                                  id: expense.lastUpdateAuthorId,
                                                  baId: expense.businessAccountId,
                                              }
                                            : undefined,
                                    },
                                ],
                            ]}
                        />

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Комментарий',
                                        type: DataDisplayItemType.Comment,
                                        value: expense.comment,
                                    },
                                ],
                            ]}
                        />
                    </>
                )}
            </EntityDrawer>
        </>
    );
});

import { createApi } from '@reduxjs/toolkit/query/react';
import {
    DeleteTransportationArgs,
    DeleteTransportationsArgs,
    ListTransportationsArgs,
    ListTransportationsData,
    SetStatusForTransportationsArgs,
} from './transportations.api.types';
import { AxiosError } from 'axios';
import { TransportationsUtils } from './transportations.api.utils';
import { GetEntityByIdArgs } from '../../../../../../components/page/entityGrid/types/api';
import { serverApi, TransportationInfoRead } from '../../../../../../server';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export const getTransportationByIdQueryFunction = async ({ businessAccountId, entityId }: GetEntityByIdArgs) => {
    try {
        return await serverApi.getTransportationById(businessAccountId, entityId);
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить перевозку');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const transportationsApi = createApi({
    reducerPath: 'transportationsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['TransportationsList', 'Transportation'],
    endpoints: (builder) => ({
        listTransportations: builder.query<ListTransportationsData, ListTransportationsArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const { page, limit, sortBy, sortOrder } = params;
                const startFrom = ((page ?? 1) - 1) * (limit ?? 1);
                const filters: string[] = TransportationsUtils.createRequestFilters(params);

                try {
                    const response = await serverApi.listTransportations(
                        businessAccountId,
                        undefined,
                        limit,
                        startFrom,
                        sortBy,
                        sortOrder,
                        filters
                    );
                    return {
                        data: {
                            entitiesData: response.data,
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['TransportationsList'],
        }),
        transportationById: builder.query<TransportationInfoRead, GetEntityByIdArgs>({
            queryFn: getTransportationByIdQueryFunction,
            providesTags: ['Transportation'],
        }),
        deleteTransportations: builder.mutation<void, DeleteTransportationsArgs>({
            queryFn: async (args, { getState }) => {
                const { versionedEntityObjList } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteTransportations(businessAccountId, versionedEntityObjList);
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Перевозки успешно удалены' : 'Перевозка успешно удалена';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Не удалось удалить перевозки' : 'Не удалось удалить перевозку';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['TransportationsList', 'Transportation'],
        }),
        deleteTransportation: builder.mutation<void, DeleteTransportationArgs>({
            queryFn: async (args, { getState }) => {
                const { id, businessVersionObj } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteTransportationById(businessAccountId, id, businessVersionObj);
                    showNotification('success', 'Перевозка удалена');

                    return { data: undefined };
                } catch (error: unknown) {
                    showNotification('error', 'Транспортное средство не удалено');

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['TransportationsList', 'Transportation'],
        }),
        setStatusForTransportations: builder.mutation<void, SetStatusForTransportationsArgs>({
            queryFn: async (args, { getState }) => {
                const { workflowDirectiveBulk } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitTransportationWorkflowBulk(businessAccountId, workflowDirectiveBulk);

                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Статус перевозок успешно обновлен'
                            : 'Статус перевозки успешно обновлен';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Не удалось обновить статус перевозок'
                            : 'Не удалось обновить статус перевозки';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['TransportationsList', 'Transportation'],
        }),
    }),
});

export const {
    useListTransportationsQuery,
    useTransportationByIdQuery,
    useDeleteTransportationsMutation,
    useDeleteTransportationMutation,
    useSetStatusForTransportationsMutation,
} = transportationsApi;

import {
    CategorisedEntityCodeEnum,
    CategoryBulkLinkObj,
    CategoryInfoCreate,
    CategoryInfoRead,
    CategoryInfoUpdate,
    CategorySortOrderEnum,
    CategoryTypeCodeEnum,
    serverApi,
} from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import { logEvent } from '../../../../analytics/analytics';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { WrappedComponentProps } from 'react-intl';
import { loadEntities as loadKitsEntities } from '../../inventory/kits/reducers/kits.reducers';
import { loadEntities as loadRentersEntities } from '../../renters/reducers/renters/renters.reducer';
import { productsApi } from '../../inventory/products/api/products.api';
import { StringUtils } from '../../../../core/utils/stringUtils';
import { getChosenCategoryForActionStorageKey } from '../../../../components/additionalEntityActions/AdditionalEntityActionForCategories/hooks/useCategoryIdStorageValue';
import { LocalStorageUtils } from '../../../../core/utils/localStorageUtils';
import { IRootState } from '../../../../shared/reducers';

export const ACTION_TYPES = {
    SEARCH_RENTER_CATEGORIES: 'categories/SEARCH_RENTER_CATEGORIES',
    SEARCH_PRODUCT_CATEGORIES: 'categories/SEARCH_PRODUCT_CATEGORIES',

    SELECT_RENTER_CATEGORY: 'categories/SELECT_RENTER_CATEGORY',
    SELECT_PRODUCT_CATEGORY: 'categories/SELECT_PRODUCT_CATEGORY',

    CHANGE_TYPE: 'categories/CHANGE_TYPE',

    LOAD_CATEGORY: 'category/LOAD_CATEGORY',
    DELETE_CATEGORY: 'category/DELETE_CATEGORY',

    SHOW_DELETE_CONFIRM_MODAL: 'category/SHOW_DELETE_CONFIRM_MODAL',
    HIDE_DELETE_CONFIRM_MODAL: 'category/HIDE_DELETE_CONFIRM_MODAL',

    EDIT_CATEGORY: 'category/EDIT_CATEGORY',
    UPDATE_CATEGORY: 'category/UPDATE_CATEGORY',
    CREATE_CATEGORY: 'category/CREATE_CATEGORY',

    CREATE_CATEGORY1: 'category/CREATE_CATEGORY1',
    CHANGE_CATEGORY_BINDING: 'renters/CHANGE_CATEGORY_BINDING',

    //SET_DELETE_SUBCATEGORY: 'category/SET_DELETE_SUBCATEGORY',
    //SET_DELETE_PRODUCTS: 'category/SET_DELETE_PRODUCTS'
};

export interface ICategoriesState {
    searchString: string;
    selectedCategoryId?: string;
    loading: boolean;
    loadingError: any;
    deleting: boolean;
    deletingError: any;
    deleteModalIsShowing: boolean;
    categoryInfo: CategoryInfoRead | CategoryInfoUpdate | CategoryInfoCreate | null;
    editMode: boolean;
    parentCategory: CategoryInfoUpdate | CategoryInfoCreate | null;
}

export interface CategoriesState {
    selectedType: 'RENTER' | 'PRODUCT';
    renters: ICategoriesState;
    products: ICategoriesState;
    //deleteProducts: boolean,
    //deleteSubcategories: boolean
}

const initialState: CategoriesState = {
    selectedType: 'PRODUCT',
    //deleteProducts: false,
    //deleteSubcategories: false,
    renters: {
        searchString: '',
        loading: false,
        loadingError: null,
        deleting: false,
        deletingError: null,
        deleteModalIsShowing: false,
        categoryInfo: null,
        editMode: false,
        parentCategory: null,
    },
    products: {
        searchString: '',
        loading: false,
        loadingError: null,
        deleting: false,
        deletingError: null,
        deleteModalIsShowing: false,
        categoryInfo: null,
        editMode: false,
        parentCategory: null,
    },
};

// Reducer

export default (state: CategoriesState = initialState, action): CategoriesState => {
    switch (action.type) {
        case ACTION_TYPES.SEARCH_RENTER_CATEGORIES:
            return {
                ...state,
                renters: {
                    ...state.renters,
                    searchString: action.payload,
                },
            };

        case ACTION_TYPES.SEARCH_PRODUCT_CATEGORIES:
            return {
                ...state,
                products: {
                    ...state.products,
                    searchString: action.payload,
                },
            };

        case ACTION_TYPES.SELECT_PRODUCT_CATEGORY:
            return {
                ...state,
                products: {
                    ...state.products,
                    selectedCategoryId: action.payload,
                },
            };

        case ACTION_TYPES.SELECT_RENTER_CATEGORY:
            return {
                ...state,
                renters: {
                    ...state.renters,
                    selectedCategoryId: action.payload,
                },
            };

        case ACTION_TYPES.CHANGE_TYPE:
            return {
                ...state,
                selectedType: action.payload,
            };

        // case ACTION_TYPES.SET_DELETE_PRODUCTS: {
        //     return {
        //         ...state,
        //         //deleteProducts: action.payload
        //     };
        // }
        //
        // case ACTION_TYPES.SET_DELETE_SUBCATEGORY: {
        //     return {
        //         ...state,
        //         //deleteSubcategories: action.payload
        //     };
        // }

        ////

        case REQUEST(ACTION_TYPES.LOAD_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.loading = true;
            newObj.loadingError = null;

            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case FAILURE(ACTION_TYPES.LOAD_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.loading = false;
            newObj.loadingError = action.payload;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case SUCCESS(ACTION_TYPES.LOAD_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.editMode = false;
            newObj.loading = false;
            newObj.categoryInfo = action.payload.data;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case REQUEST(ACTION_TYPES.DELETE_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.deletingError = null;
            newObj.deleting = true;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case FAILURE(ACTION_TYPES.DELETE_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.deletingError = action.payload;
            newObj.deleting = false;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case SUCCESS(ACTION_TYPES.DELETE_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.deleting = false;
            newObj.deleteModalIsShowing = false;
            newObj.categoryInfo = null;
            newObj.parentCategory = null;
            newObj.selectedCategoryId = undefined;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case REQUEST(ACTION_TYPES.UPDATE_CATEGORY): {
            return {
                ...state,
            };
        }
        case FAILURE(ACTION_TYPES.UPDATE_CATEGORY): {
            return {
                ...state,
            };
        }
        case SUCCESS(ACTION_TYPES.UPDATE_CATEGORY): {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.editMode = false;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case ACTION_TYPES.EDIT_CATEGORY: {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.editMode = true;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case ACTION_TYPES.SHOW_DELETE_CONFIRM_MODAL: {
            //console.log('UUUU');
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.deleteModalIsShowing = true;
            //newObj.deleteProducts = false;
            //newObj.deleteSubcategories = false;
            return action.meta.typeCode === 'RENTER'
                ? {
                      ...state /*, deleteProducts: !action.meta.hasParrent*/ /*, deleteSubcategories: !action.meta.hasParrent*/,
                      renters: newObj,
                  }
                : {
                      ...state,
                      /*deleteProducts: !action.meta.hasParrent, */ /*deleteSubcategories: !action.meta.hasParrent,*/ products: newObj,
                  };
        }
        case ACTION_TYPES.HIDE_DELETE_CONFIRM_MODAL: {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.deleting = false;
            newObj.deleteModalIsShowing = false;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        case ACTION_TYPES.CREATE_CATEGORY: {
            let newObj = action.meta.typeCode === 'RENTER' ? { ...state.renters } : { ...state.products };
            newObj.parentCategory = action.payload;
            newObj.categoryInfo = null;
            return action.meta.typeCode === 'RENTER' ? { ...state, renters: newObj } : { ...state, products: newObj };
        }
        default:
            return state;
    }
};

// Actions
export const searchCategories = (searchString: string, typeCode: 'RENTER' | 'PRODUCT') => ({
    type: typeCode === 'RENTER' ? ACTION_TYPES.SEARCH_RENTER_CATEGORIES : ACTION_TYPES.SEARCH_PRODUCT_CATEGORIES,
    payload: searchString,
});

export const selectCategory = (typeCode: 'RENTER' | 'PRODUCT', id: any) => ({
    type: typeCode === 'RENTER' ? ACTION_TYPES.SELECT_RENTER_CATEGORY : ACTION_TYPES.SELECT_PRODUCT_CATEGORY,
    payload: id,
});

export const changeType = (typeCode: 'RENTER' | 'PRODUCT') => ({
    type: ACTION_TYPES.CHANGE_TYPE,
    payload: typeCode,
});

///////////////////////

// Загрузка категории
export const loadCategory = (businessAccountId: number, typeCode: 'RENTER' | 'PRODUCT', id: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.LOAD_CATEGORY,
            payload: serverApi.getCategoryById(businessAccountId, id),
            meta: { typeCode },
        }).then((result) => {
            if (result instanceof Error) {
                //notification.error({message: 'Не удалось загрузить категорию: ' + result.message});
            }
        });
    };
};

// Удаление категории
export const deleteCategory = (
    intl,
    businessAccountId: number,
    typeCode: CategoryTypeCodeEnum,
    id: number,
    deleteSubcategories,
    deleteEntityLinks,
    sortBy: CategorySortOrderEnum
) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_CATEGORY,
            payload: serverApi.deleteCategoryById(businessAccountId, id, deleteSubcategories, deleteEntityLinks),
            meta: { typeCode },
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__CATEGORIES__POPUP_NOTIFICATIONS__DELETION_FAILED));
            } else {
                const state = getState() as IRootState;
                for (let key in CategorisedEntityCodeEnum) {
                    const storageKey = LocalStorageUtils.getKey(
                        getChosenCategoryForActionStorageKey(CategorisedEntityCodeEnum[key]),
                        businessAccountId,
                        state.oidc.user?.profile.email
                    );
                    const storageValue = localStorage.getItem(storageKey);
                    if (Number(storageValue) === id) {
                        localStorage.removeItem(storageKey);
                    }
                }
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__CATEGORIES__POPUP_NOTIFICATIONS__DELETED));
                dispatch(loadCategories(businessAccountId, typeCode, sortBy));
            }
        });
    };
};

// Показать окно удаления категории
export const showDeleteConfirmModal = (typeCode: 'RENTER' | 'PRODUCT', hasParrent: Boolean) => ({
    type: ACTION_TYPES.SHOW_DELETE_CONFIRM_MODAL,
    meta: { typeCode, hasParrent },
});

// Отмена удаления категории
export const hideDeleteConfirmModal = (typeCode: 'RENTER' | 'PRODUCT') => ({
    type: ACTION_TYPES.HIDE_DELETE_CONFIRM_MODAL,
    meta: { typeCode },
});

// Редактирование категории
export const editCategory = (typeCode: 'RENTER' | 'PRODUCT') => ({
    type: ACTION_TYPES.EDIT_CATEGORY,
    meta: { typeCode },
});

// Обновление категории
export const updateCategory = (
    intl,
    businessAccountId: number,
    typeCode: CategoryTypeCodeEnum,
    id: number,
    categoryInfo: CategoryInfoUpdate,
    sortBy: CategorySortOrderEnum
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_CATEGORY,
            payload: serverApi.updateCategoryById(businessAccountId, id, categoryInfo),
            meta: { typeCode },
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__CATEGORIES__POPUP_NOTIFICATIONS__UPDATE_FAILED));
            } else {
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__CATEGORIES__POPUP_NOTIFICATIONS__UPDATED));
                dispatch(loadCategories(businessAccountId, typeCode, sortBy));
                dispatch(loadCategory(businessAccountId, typeCode, id));
                // updateEntityAnalytics('update category', {'entity id': result.value.data.id});
                logEvent({
                    type: 'update category',
                    data: {
                        'entity id': id,
                    },
                });
            }
        });
    };
};

export const createCategory = (
    typeCode: 'RENTER' | 'PRODUCT',
    parentCategory: CategoryInfoRead | CategoryInfoUpdate | CategoryInfoCreate | null
) => ({
    type: ACTION_TYPES.CREATE_CATEGORY,
    payload: parentCategory,
    meta: { typeCode },
});

export const createCategory1 = (
    intl,
    businessAccountId: number,
    typeCode: CategoryTypeCodeEnum,
    categoryInfo: CategoryInfoCreate,
    sortBy: CategorySortOrderEnum
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CREATE_CATEGORY1,
            payload: serverApi.createCategory(businessAccountId, categoryInfo),
            meta: { typeCode },
        }).then((result) => {
            if (result instanceof Error) {
                console.dir(result);
                result = result as any; //(<any>result);

                if (result && result.response && result.response && result.response.data) {
                    console.log(result.response.data);
                }
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__CATEGORIES__POPUP_NOTIFICATIONS__CREATION_FAILED));
            } else {
                console.log(result.value.data.id);
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__CATEGORIES__POPUP_NOTIFICATIONS__CREATED));
                dispatch(loadCategories(businessAccountId, typeCode, sortBy));
                dispatch(loadCategory(businessAccountId, typeCode, result.value.data.id));
                dispatch(selectCategory(typeCode, result.value.data.id));

                // createEntityAnalytics('create category', {'entity id': result.value.data.id});

                logEvent({
                    type: 'create category',
                    data: {
                        'entity id': result.value.data.id,
                    },
                });
            }
        });
    };
};

export const changeCategoryBinding = createAsyncThunk(
    ACTION_TYPES.CHANGE_CATEGORY_BINDING,
    async (
        args: {
            businessAccountId: number;
            categoryBulkLinkObj: CategoryBulkLinkObj;
            createLinks: boolean;
            entityTypeCode: CategorisedEntityCodeEnum;
            makeMainCategory: boolean;
        } & WrappedComponentProps,
        { dispatch, getState }
    ) => {
        const { businessAccountId, createLinks, categoryBulkLinkObj, makeMainCategory, entityTypeCode, intl } = args;

        const notificationErrorMessage = `Не удалось ${createLinks ? 'назначить' : 'отвязать'} категорию`;
        try {
            const successfulBindingCount = (
                await serverApi.linkCategoriesBulk(businessAccountId, createLinks, categoryBulkLinkObj, makeMainCategory)
            ).data;

            if (successfulBindingCount >= 0) {
                const correctForm = StringUtils.getWordFormFromCount(
                    successfulBindingCount,
                    createLinks,
                    ['объект', 'объекта', 'объектов'],
                    ['объекта', 'объектов', 'объектов']
                );
                const sentenceStart = createLinks ? 'Категория назначена на' : 'Категория отвязана от';

                const loadEntityFunctionByEntityTypeMap: Partial<Record<CategorisedEntityCodeEnum, Function>> = {
                    [CategorisedEntityCodeEnum.RENTER]: loadRentersEntities,
                    [CategorisedEntityCodeEnum.KIT]: loadKitsEntities,
                };

                await loadEntityFunctionByEntityTypeMap[entityTypeCode]?.(intl, businessAccountId)(dispatch, getState);
                dispatch(productsApi.util?.invalidateTags(['ProductsList', 'ProductsAvailableFiltersList']));
                showNotification('success', `${sentenceStart} ${successfulBindingCount} ${correctForm}`);
            } else {
                throw new Error(notificationErrorMessage);
            }
        } catch (error: unknown) {
            showNotification('error', notificationErrorMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

import React from 'react';
import {InventoryMovementEventTypeCodeEnum, InventoryMovementInfoRead} from "../../../../server";
import {InventoryMovementIcon} from "../components/inventoryMovementIcon";
import {ListInventoryMovementsFilters} from "../api/inventoryMovementsApi";
import {ArrayUtils} from "../../../../core/utils/arrayUtils";

export class InventoryMovementsUtils {

    static getAvailableEventTypeCodes() {
        return [
            InventoryMovementEventTypeCodeEnum.STOCKTAKING,
            InventoryMovementEventTypeCodeEnum.WRITEOFF,
            InventoryMovementEventTypeCodeEnum.ACQUISITION,
            InventoryMovementEventTypeCodeEnum.SALE,
            InventoryMovementEventTypeCodeEnum.DAMAGE,
            InventoryMovementEventTypeCodeEnum.LOSS,
            InventoryMovementEventTypeCodeEnum.MAINTENANCE,
            InventoryMovementEventTypeCodeEnum.MAINTENANCECOMPLETION,
        ];
    };

    static getEventTypeByCode(code: InventoryMovementEventTypeCodeEnum) {
        let eventType: string|undefined;
        if (code === InventoryMovementEventTypeCodeEnum.LOSS) eventType = 'Потеря';
        else if (code === InventoryMovementEventTypeCodeEnum.DAMAGE) eventType = 'Повреждение';
        else if (code === InventoryMovementEventTypeCodeEnum.SALE) eventType = 'Продажа';
        else if (code === InventoryMovementEventTypeCodeEnum.ACQUISITION) eventType = 'Приобретение';
        else if (code === InventoryMovementEventTypeCodeEnum.MAINTENANCE) eventType = 'Обслуживание';
        else if (code === InventoryMovementEventTypeCodeEnum.MAINTENANCECOMPLETION) eventType = 'Завершение обслуживания';
        else if (code === InventoryMovementEventTypeCodeEnum.STOCKTAKING) eventType = 'Постановка на учет';
        else if (code === InventoryMovementEventTypeCodeEnum.WRITEOFF) eventType = 'Списание';
        return eventType;
    };

    static getEventTypeIconByCode(code: InventoryMovementEventTypeCodeEnum) {
        return <InventoryMovementIcon code={code}/>;
    };

    static getInventoryMovementInfoReadSampleData() {
        const data: InventoryMovementInfoRead = {
            id: 0,
            businessVersion: 0,
            creationAuthorId: 0,
            creationAuthorFullName: {firstname: '', lastname: ''},
            creationDate: new Date(),
            lastUpdateAuthorId: 0,
            lastUpdateAuthorFullName: {firstname: '', lastname: ''},
            lastUpdateDate: new Date(),
            businessAccountId: 0,
            eventDate: new Date(),
            productId: 0,
            instanceCount: 0,
            eventType: ArrayUtils.random(InventoryMovementsUtils.getAvailableEventTypeCodes()),
            productShortName: ''
        };
        return data;
    };

    static linkToProductInventoryMovementsList(params:{businessAccountId: number, productId: number, filters: ListInventoryMovementsFilters}) {
        return `/${params.businessAccountId}}/inventory/products/${params.productId}?tab=inventoryMovements`;
    };

}

import React, {ReactNode} from 'react';
import {Button, Icon, Popover} from "antd";
import {removeInstances} from "../operationForm/reducers/operationForm.reducer";
import {connect} from "react-redux";
import {
    IconBorderStyleSolid,
    IconCheckSquare,
    IconEraser,
    IconHandStopO,
    IconHourglassStart,
    IconMagicSolid,
    IconReply,
    IconStickyNoteO,
    IconTimesCircle,
    IconWrench
} from "../../../components/icons";
import {InstanceTrackingTypeCodeEnum, OperationTypeCodeEnum, RentStateCodeEnum} from "../../../server/api";
import {getRentElementStateByCode, LocalizationEnum, localize} from "../../../localization";
import {RentElementsGridItem} from "../../../types";
import {NumberInput} from "../../../components/numberInputs";

interface IProps extends DispatchProps {
    record: RentElementsGridItem;
    onAction: Function;
    children: () => ReactNode;
    disabled: boolean;
}

interface IState {
    visible:boolean;
    correctionPopoverVisible:boolean;
    amount?: number;
}

class Component extends React.PureComponent<IProps, IState> {

    state = {
        visible: false,
        correctionPopoverVisible: false,
        amount: 0
    };

    handleVisibleChange = visible => {
        this.setState(visible ? { visible, amount: undefined} : { visible } );
    };

    handleCorrectionPopoverVisibleChange = visible => {
        this.setState({ correctionPopoverVisible: visible } );
    };

    onAmountChanged = (value: number | undefined) => {
        this.setState({amount: value});
    };

    onButtonClick = (e, type:OperationTypeCodeEnum, targetStateCode?:RentStateCodeEnum) => {
        let {record} = this.props;
        e.stopPropagation();
        this.setState({visible: false, correctionPopoverVisible: false});
        let instanceCount = this.state.amount === undefined ? record.instanceCount : this.state.amount;
        if(instanceCount !== undefined && instanceCount > 0 && this.props.onAction){
            this.props.onAction(record, type, instanceCount, targetStateCode);
        }
    };

    headerOnClick = (e) => {
        e.stopPropagation();
    };

    render() {
        console.log('ElementsActionPopover render()', this.props);

        let {record} = this.props;
        let {availableTransitionCodes, availableTargetCorrectionStates, instanceCount, projectFinished, instanceTrackingTypeCode, anonymousInstanceCount} = record;
        let amount = this.state.amount === undefined ? instanceCount : this.state.amount;
        let min = 1;
        let max = instanceCount;
        let instanceTracked = instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED;

        if(instanceTracked){
            min = instanceCount - anonymousInstanceCount === 0 ? 1 : (instanceCount - anonymousInstanceCount);
        }

        const createCorrectionButton = (code:RentStateCodeEnum, key:number) => {
            return availableTargetCorrectionStates?.includes(code) ?
                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.CORRECT, code)}>
                    <div className={`rr-dot rr-status-bg-${code}`}></div>
                    <span>{getRentElementStateByCode(code)}</span>
                </Button> : null
        };

        return (
            <Popover onVisibleChange={this.handleVisibleChange} visible={this.props.disabled === true ? false : this.state.visible} overlayClassName={'rr-grid-actions-popover'} autoAdjustOverflow arrowPointAtCenter placement="bottomLeft" trigger="click" content={
                <>
                    <div onClick={this.headerOnClick} className={'rr-grid-actions-popover-header'}>
                        <span style={{marginRight: 7}}>{localize( LocalizationEnum.PAGE__ELEMENTS__POPUP_CARD__AMOUNT)}</span>
                        <NumberInput style={{width: 92}} onChange={this.onAmountChanged} min={min} max={max} value={amount} className={'rr-input-number'} />
                        {
                            <span style={{fontWeight: 400, marginLeft: 2}}> {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)} {instanceCount}</span>
                        }
                    </div>
                    <div className={'rr-grid-actions-popover-content'}>
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.ORDER) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.ORDER)}>
                                    <Icon className={'rr-operation-status-color-ORDER'} component={IconStickyNoteO} />
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__ORDER)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.BOOK) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.BOOK)}>
                                    <Icon className={'rr-operation-status-color-BOOK'} component={IconCheckSquare} />
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__BOOK)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.RENT) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.RENT)}>
                                    <Icon className={'rr-operation-status-color-RENT'} component={IconHandStopO}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__GIVE_AWAY)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.PROLONG) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.PROLONG)}>
                                    <Icon className={'rr-operation-status-color-PROLONG'} component={IconHourglassStart}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__PROLONG)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.RETURN) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.RETURN)}>
                                    <Icon className={'rr-operation-status-color-RETURN'} component={IconReply}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__RETURN)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.RETURNBROKEN) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.RETURNBROKEN)}>
                                    <Icon className={'rr-operation-status-color-RETURN_BROKEN'} component={IconWrench}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__RETURN_BROKEN)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.LOSTNORETURN) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.LOSTNORETURN)}>
                                    <Icon className={'rr-operation-status-color-LOST_NO_RETURN'} component={IconTimesCircle}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__LOST_NO_RETURN)}</span>
                                </Button> : null
                        }

                        {/* Субаренда */}
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT)}>
                                    <Icon className={`rr-operation-status-color-${OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT}`} component={IconCheckSquare}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_BOOK_SHIPMENT)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT)}>
                                    <Icon className={`rr-operation-status-color-${OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT}`} component={IconHandStopO}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_ACCEPT_SHIPMENT)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTPROLONG) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.SUBRENTPROLONG)}>
                                    <Icon className={`rr-operation-status-color-${OperationTypeCodeEnum.SUBRENTPROLONG}`} component={IconHourglassStart}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_PROLONG)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER)}>
                                    <Icon className={`rr-operation-status-color-${OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER}`} component={IconReply}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_RETURN_TO_SHIPPER)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTCANCEL) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.SUBRENTCANCEL)}>
                                    <Icon className={`rr-operation-status-color-${OperationTypeCodeEnum.SUBRENTCANCEL}`} component={IconEraser}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_CANCEL)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.SUBRENTDRAFT) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.SUBRENTDRAFT)}>
                                    <Icon className={`rr-operation-status-color-${OperationTypeCodeEnum.SUBRENTDRAFT}`} component={IconBorderStyleSolid}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_DRAFT)}</span>
                                </Button> : null
                        }

                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.CORRECT) ?
                                <Popover visible={this.state.correctionPopoverVisible} onVisibleChange={this.handleCorrectionPopoverVisibleChange} overlayClassName={'rr-grid-actions-popover'} autoAdjustOverflow placement="leftBottom" trigger="hover" content={
                                    <>
                                        <div className={'rr-grid-actions-popover-header'}>
                                            <span style={{marginRight: 7}}>{localize( LocalizationEnum.PAGE__OPERATIONS__CARD__TARGET_ELEMENTS_STATE)}</span>
                                        </div>
                                        <div className={'rr-grid-actions-popover-content'}>
                                            {
                                                [
                                                    !projectFinished ? RentStateCodeEnum.ORDERED : null,
                                                    !projectFinished ? RentStateCodeEnum.BOOKED : null,
                                                    !projectFinished ? RentStateCodeEnum.RENT : null,
                                                    RentStateCodeEnum.RETURNED,
                                                    RentStateCodeEnum.RETURNEDBROKEN,
                                                    RentStateCodeEnum.LOSTDURINGRENT,
                                                    RentStateCodeEnum.CANCELED,
                                                    RentStateCodeEnum.DRAFT,
                                                    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                                                    RentStateCodeEnum.SUBRENT,
                                                    RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
                                                    RentStateCodeEnum.SUBRENTCANCELED,
                                                    RentStateCodeEnum.SUBRENTDRAFT
                                                ].map((item, index)=> item && createCorrectionButton(item, index))
                                            }
                                        </div>
                                    </>
                                }>
                                    <Button block onClick={this.headerOnClick}>
                                        <Icon className={'rr-operation-status-color-CORRECT'} component={IconMagicSolid}/>
                                        <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CORRECT)}</span>
                                    </Button>
                                </Popover> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.CANCEL) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.CANCEL)}>
                                    <Icon className={'rr-operation-status-color-CANCEL'} component={IconEraser}/>
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL)}</span>
                                </Button> : null
                        }
                        {
                            availableTransitionCodes?.includes(OperationTypeCodeEnum.DRAFT) ?
                                <Button block onClick={e => this.onButtonClick(e, OperationTypeCodeEnum.DRAFT)}>
                                    <Icon className={'rr-operation-status-color-DRAFT'} component={IconBorderStyleSolid} />
                                    <span>{localize(LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__DRAFT)}</span>
                                </Button> : null
                        }
                    </div>
                </>
            }>
                {
                    this.props.children()
                }
            </Popover>
        );
    }
}

const mapDispatchToProps = { removeInstances };
type DispatchProps = typeof mapDispatchToProps;

export const ElementsActionPopover = connect(undefined, mapDispatchToProps)(Component);

import React, {useEffect, useState} from "react";
import {Button, Dropdown, Icon, Menu, Modal} from "antd";
import {IconRandomSolid, IconUnlock} from "../icons";
import {BusinessAccountRecord} from "../../server/api";
import {serverApi} from "../../server";
import {listBusinessAccounts0} from "../userPopover/userPopover";
import {canViewBusinessAccount} from "../../shared/util/permissionUtils";
import {useSelector} from "react-redux";
import {IRootState} from "../../shared/reducers";
import classNames from "classnames";
import {rrLogout} from "../../shared/util/rrLogout";

interface BlockedCompanyModalProps {
    companyName?: string;
    companyId: number;
}

export const BlockedCompanyModal = ({companyName, companyId, ...props}:BlockedCompanyModalProps) => {

    const [businessAccounts, setBusinessAccounts] = useState([] as BusinessAccountRecord[]);
    const permissions = useSelector((state:IRootState) => state.permissions.permissions);
    const _canViewBusinessAccount = canViewBusinessAccount(permissions);

    useEffect(() => {
        serverApi.listBusinessAccounts(1000, 0).then((res)=>{
            setBusinessAccounts(res.data.records.filter(r=>r.id !== companyId));
        })
    }, []);

    const menu = <Menu>
        {
            listBusinessAccounts0(businessAccounts, _canViewBusinessAccount)
        }
    </Menu>;

    return (
        <Modal
            bodyStyle={{padding: 32, lineHeight: 1.75}}
            visible
            centered={true}
            title={
                <>
                    <Icon component={IconUnlock} style={{color: '#a1a1a1', marginRight: 10}} />
                    <span>Компания заблокирована</span>
                </>
            }
            closable={false}
            footer={
                <div>
                    <Dropdown overlayClassName={'ant-select-dropdown'} placement={"bottomRight"} disabled={businessAccounts.length < 1} overlay={menu} trigger={['click']} overlayStyle={{maxHeight: 270, overflowY: 'auto'}}>
                        <Button className={'ant-btn-primary'} style={{borderWidth: 1}}><Icon component={IconRandomSolid} style={{fontSize: 21}} />Сменить компанию</Button>
                    </Dropdown>
                    <Button style={{border: '1px solid #525a95', marginLeft: 20}} className={classNames('rr-btn-default', 'rr-btn-cancel')} onClick={()=>{
                        rrLogout();
                    }}>Выйти</Button>
                </div>
            }
        >
            <div>Компания "{companyName}" заблокирована.</div>
            <div>Обратитесь <a style={{textDecoration: 'underline', color: '#525a95'}} className={'app-link-hover'} href={'https://rentrabb.it/contacts/'} target={'contacts'}>в службу поддержки</a></div>
        </Modal>
    );
};

import { withEntityListProviders } from '../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { GridProps } from '../../../../components/grid/Grid';
import { WorkPlanningsQueryParams } from '../api/workPlannings.api.types';
import { ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import React, { FC, useCallback } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { EntityList } from '../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { workPlanningsColumns } from './columns/workPlanningsColumns';
import { workPlanningsFilters } from './filters/workPlanningsFilters';
import { WorkPlanningsPageFiltersDescription } from './filters/workPlanningsFiltersDescription';
import { useListWorkPlanningsQuery, useMoveWorkPlanningsToAnotherProjectMutation, workPlanningsApi } from '../api/workPlannings.api';
import { useEntityGridContext } from '../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { workPlanningEntitySelector } from '../reducers/workPlanning/workPlanning.selectors';
import { useSelectedRecords } from '../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { WorkPlanningsDrawerWrapper } from '../drawer/workPlanningsDrawerWrapper';
import { WorkPlanningsCreateModalWrapper, WorkPlanningsCreateModalWrapperProps } from '../modal/create/workPlanningsCreateModalWrapper';
import { WorkPlanningsEditModalWrapper, WorkPlanningsEditModalWrapperProps } from '../modal/edit/workPlanningsEditModalWrapper';
import { clearWorkPlanning } from '../reducers/workPlanning/workPlanning.reducer';
import { EntityGridRootPath } from '../../../../components/page/entityGrid/types/params';
import {
    ExcelExportTemplateTypeCodeEnum,
    ProjectInfoRead,
    WorkPlanningsFinancialFieldsTypeCode,
    WorkPlanningsInfoRead,
    WorkPlanningsNotFinancialFieldsTypeCode,
    WorkPlanningsRecord,
} from '../../../../server';
import { useQueryParamsWithoutModalParams } from '../../../../components/page/entityGrid/hooks/useParams';
import { ProfessionDrawer } from '../../crew/tabs/professions/drawer/components/ProfessionDrawer/ProfessionDrawer';
import { useProfessionEntityActions } from '../../crew/tabs/professions/hooks/useProfessionEntityActions';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { ProjectPageGridNamesEnum } from '../../projects/production/page/projectPage';
import { OfferPageGridNamesEnum } from '../../offers/page/offerPage';
import { usePageURLParamsFromDescription } from '../../../../core/hooks/urlParams/usePageURLParamsFromDescription';
import { ExportOffloadingPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { downloadOffloading } from '../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { useIntl } from 'react-intl';
import { WorkPlanningsUtils } from '../api/workPlannings.api.utils';
import { OffloadingDocumentOnDownload } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';
import { useEntityActions } from '../hooks/useWorkPlanningsEntityActions';
import { useEntityActionsButtons } from '../hooks/useWorkPlanningsEntityActionsButtons';
import { useIdForDrawer } from '../hooks/useWorkPlanningsIdForDrawer';

interface WorkPlanningsListComponentProps {
    gridName: ProjectPageGridNamesEnum.WORK_PLANNINGS | OfferPageGridNamesEnum.WORK_PLANNINGS;
    urlPath: `projects/production/${number}?tab=workPlannings` | `projects/offers/${number}?tab=workPlannings`;
    parentProjectEntity: ProjectInfoRead;
    gridProps?: Partial<GridProps>;
    staticPageParams?: Partial<WorkPlanningsQueryParams>;
    projectId?: number;
    modalProps?: Partial<ModalFormNEWProps>;
    visible?: boolean;
}

const WorkPlanningsListComponent: FC<WorkPlanningsListComponentProps> = (props) => {
    const { gridName, urlPath, projectId, visible, staticPageParams, gridProps, modalProps, parentProjectEntity } = props;
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const currentEntity = useAppSelector(workPlanningEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();
    const [professionIdForDrawer, setProfessionIdForDrawer, clearProfessionIdForDrawer] = useIdForDrawer();
    const professionActions = useProfessionEntityActions({
        rootPath: `/${businessAccountId}/crew/professions`,
        urlSearchParams: new URLSearchParams(),
    });
    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: WorkPlanningsPageFiltersDescription,
        gridName,
        skipLocationChange: !visible,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListWorkPlanningsQuery(
        {
            businessAccountId: businessAccountId,
            params: {
                ...queryParams,
                ...staticPageParams,
            },
            projectId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [moveWorkPlanningsToAnotherProject, { isLoading }] = useMoveWorkPlanningsToAnotherProjectMutation();

    let rootPath = `/${businessAccountId}/${urlPath}` as EntityGridRootPath;
    if (projectId) {
        rootPath = rootPath.split('?')[0] as EntityGridRootPath;
    }

    const entityActions = useEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<WorkPlanningsRecord>(entitySelection, queryData.data?.entitiesData.records);
    const { buttons: entityActionsButtons, isLoading: isEntityActionsButtonsLoading } = useEntityActionsButtons({
        selectedRecords,
        gridRef,
        parentProjectEntity,
        moveWorkPlanningsToAnotherProject,
    });

    const excludeColumns = canViewFinancialData ? undefined : ['effectivePrice', 'finalTotalPrice'];
    const excludeFilters = canViewFinancialData ? undefined : ['effectivePrice', 'finalTotalPrice'];

    const createModalProps: Partial<WorkPlanningsCreateModalWrapperProps> = {
        ...modalProps,
        projectId: parentProjectEntity?.id ?? projectId,
        projectType: parentProjectEntity?.projectType,
        projectStartDate: parentProjectEntity?.startDate,
        projectEndDate: parentProjectEntity?.targetFinishDate,
    };

    const editModalProps: Partial<WorkPlanningsEditModalWrapperProps<WorkPlanningsInfoRead>> = {
        ...modalProps,
        projectId: parentProjectEntity?.id ?? projectId,
        projectType: parentProjectEntity?.projectType,
    };

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            let { sortOrder, sortBy, search } = pageParams;

            const filters = WorkPlanningsUtils.createRequestFilters(pageParams, projectId);

            sortBy = sortBy || undefined!;
            sortOrder = sortBy != null ? sortOrder : undefined!;

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.WORKPLANNINGSTEMPLATE,
                fileName: 'Экспорт работ',
                fileType,
                filters,
                financialFieldsEnum: WorkPlanningsFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: WorkPlanningsNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: [WorkPlanningsNotFinancialFieldsTypeCode.CounterpartyShortName, WorkPlanningsNotFinancialFieldsTypeCode.ProjectShortName]
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams, projectId]
    );

    return (
        <>
            <EntityList
                pageParamsObject={pageParamsObject}
                gridProps={{
                    static: {
                        columns: workPlanningsColumns,
                        filtersData: workPlanningsFilters,
                        pageParamsDescription: WorkPlanningsPageFiltersDescription,
                    },
                    metaData: {
                        entityType: 'workPlanning',
                        gridName,
                        rootPath: rootPath,
                    },
                    excludeColumns,
                    filtersExcludeFields: excludeFilters,
                    queryData,
                    entitiesLoading: isLoading || isEntityActionsButtonsLoading,
                    onCellAction: (dataIndex: string, record) => {
                        setProfessionIdForDrawer(record.professionId);
                    },
                    entityActions,
                    actionButtons: entityActionsButtons,
                    exportBlock: <ExportOffloadingPopover storageKey={'workPlanningsOffloading'} onDownload={onOffloadingDownload} />,
                    ...gridProps,
                }}
                modalProps={{
                    currentEntity,
                    modals: {
                        create: {
                            component: WorkPlanningsCreateModalWrapper,
                            props: createModalProps,
                        },
                        edit: {
                            component: WorkPlanningsEditModalWrapper,
                            props: editModalProps,
                        },
                    },
                    entityLoadActions: {
                        loadEntity: workPlanningsApi.endpoints.getWorkPlanningById.initiate,
                        clearEntity: clearWorkPlanning,
                    },
                }}
                drawerProps={{
                    drawer: WorkPlanningsDrawerWrapper,
                    entityActions: entityActions,
                }}
            />
            {professionIdForDrawer && (
                <ProfessionDrawer
                    professionId={professionIdForDrawer}
                    onClose={() => {
                        clearProfessionIdForDrawer();
                    }}
                    editProfession={professionActions.editAction}
                    deleteProfession={professionActions.deleteActionForDrawer}
                    archiveProfession={professionActions.archiveActionForDrawer}
                    isEntityActionsLoading={professionActions.isLoading}
                    refetchList={queryData.refetch}
                />
            )}
        </>
    );
};

export const WorkPlanningsList = withEntityListProviders(WorkPlanningsListComponent);

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TransportationInfoRead, TransportationInfoUpdate } from '../../../../../../../../server';
import { IRootState } from '../../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../../components/modalForm/utils';
import { connect, ConnectedProps } from 'react-redux';
import { transportationFormFields } from '../../data/TransportationModalFields';
import { showConfirm } from '../../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../../localization';
import { goBack } from 'connected-react-router';
import { resetErrors } from '../../../../../../renters/reducers/renters/renter.reducer';
import { updateModalFilters } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { updateTransportation } from '../../../reducers/transportation/transportation.reducer';
import moment from 'moment';
import { TransportationCreateModalFormData } from '../create/TransportationCreateModal.types';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    transportation: TransportationInfoRead;
}

export class _TransportationEditModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: transportationFormFields,
    };

    getInitialValues = () => {
        const { transportation } = this.props;

        let initialValues: Partial<TransportationCreateModalFormData> = {
            car: transportation.car?.id,
            cargo: transportation.cargo,
            cargoDeliveryLocation: transportation.cargoDeliveryLocation?.id,
            comment: transportation.comment,
            consignee: transportation.consignee?.id,
            dateTimeOfLoading: moment.utc(transportation.dateTimeOfLoading),
            dateTimeOfUnloading: moment.utc(transportation.dateTimeOfUnloading),
            driverIds: transportation.drivers?.map((record) => record.contactId),
            isEdit: true,
            loadingLocation: transportation.loadingLocation.id,
            project: transportation.project?.id,
            receiver: transportation.receiver?.contactId,
            recipient: transportation.recipient?.contactId,
            requestDate: transportation.requestDate ? moment(transportation.requestDate) : undefined,
            shipper: transportation.shipper?.id,
            transportationDate: transportation.transportationDate ? moment(transportation.transportationDate) : undefined,
            trailer: transportation.trailer?.id,
            unloadingLocation: transportation.unloadingLocation.id,
            stateCode: transportation.stateCode,
        };

        return {
            ...initialValues,
            ...this.props.initialValues,
        };
    };

    onOk = (data: unknown) => {
        const formData = data as TransportationCreateModalFormData;

        const transportationInfoUpdate: TransportationInfoUpdate = {
            carId: Number(formData.car),
            cargoDeliveryLocationId: Number(formData.cargoDeliveryLocation),
            comment: formData.comment,
            consigneeId: formData.consignee ? Number(formData.consignee) : undefined,
            dateTimeOfLoading: formData.dateTimeOfLoading.clone().utcOffset(0, true).toDate(),
            dateTimeOfUnloading: formData.dateTimeOfUnloading.clone().utcOffset(0, true).toDate(),
            driverIds: formData.driverIds,
            loadingLocationId: Number(formData.loadingLocation),
            projectId: formData.project ? Number(formData.project) : undefined,
            receiverId: Number(formData.receiver),
            recipientId: Number(formData.recipient),
            requestDate: formData.requestDate ? formData.requestDate.format('YYYY-MM-DD') : undefined,
            shipperId: formData.shipper ? Number(formData.shipper) : undefined,
            transportationDate: formData.transportationDate ? formData.transportationDate.format('YYYY-MM-DD') : undefined,
            trailerId: formData.trailer ? Number(formData.trailer) : undefined,
            unloadingLocationId: Number(formData.unloadingLocation),
            cargo: formData.cargo,
            businessVersion: this.props.transportation.businessVersion,
        };

        this.props.updateTransportation({
            businessAccountId: this.props.businessAccountId,
            id: this.props.transportation.id,
            transportationInfoUpdate,
        });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.transportation.updating,
    updatingError: storeState.transportation.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    updateTransportation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const TransportationEditModal = connector(injectIntl(_TransportationEditModal));

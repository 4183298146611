import {useAppDispatch, useAppSelector} from '../../../../../../../store/hooks';
import {QueryHookData} from "../../../../../../../components/page/entityGrid/types/api";
import {useEffect} from "react";
import {InstancePropertiesEntityRecordCustom, setNomenclatures} from "../../../../reducers/operationForm.reducer";
import {isDefined} from "../../../../../../../shared/util/utils";

export const useNomenclaturesRecords = <EntityRecord extends object>(queryData: QueryHookData<EntityRecord>):QueryHookData<EntityRecord> => {
    const nomenclatures = useAppSelector(state=>state.operationForm.products.entities);
    const elements = useAppSelector(state=>state.operationForm.elements.entities);
    const projectTemplate = useAppSelector(state=>state.operationForm.projectTemplate);
    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(setNomenclatures(queryData.data?.entitiesData.records));
    }, [queryData.data]);

    const records:any = nomenclatures?.map((item:InstancePropertiesEntityRecordCustom)=>{
        let instanceAdded = 0;
        let instanceExisted = 0;
        const disabled = !projectTemplate && item.availableInstanceCount <= 0;
        if (!item.kitId) {
            elements
                .filter(
                    (element) =>
                        element.productId === item.productId && (!isDefined(item.variantId) || item.variantId === element.variantId)
                )
                .forEach((element) => {
                    instanceAdded += element.instanceCount;
                    if (element.id > 0) instanceExisted += element.instanceCount;
                });
            return {
                ...item,
                instanceAdded,
                instanceExisted,
                disabled
            };
        } else {
            elements
                .filter((element) => element.kitId === item.kitId)
                .forEach((element) => {
                    instanceAdded += 1;
                    if (element.id > 0) instanceExisted += 1;
                });
            return {
                ...item,
                instanceAdded,
                instanceExisted,
                disabled
            };
        }
    });

    let isSuccess = queryData.isSuccess;
    if(queryData['status'] === 'fulfilled') {
        isSuccess = !!records && !queryData.isFetching && !queryData.isLoading;
    }

    return {...queryData, isSuccess: isSuccess, data: queryData.data && records && queryData.data.entitiesData ? {...queryData.data, entitiesData: {...queryData.data.entitiesData, records}} : undefined};;
};

import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { ElementsLineInnerContent } from './ElementsLineInnerContent';
import { CalendarLineUtils } from '../../../../../../../../utils/calendarLineUtils';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';
import { CALENDAR_LINE_BASE_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { LineTail } from '../../../LineTail/LineTail';
import { isGiveawayDelayProblemForItemElement } from '../../../../../../../../../../tabs/elements/utils/checks/isGiveawayDelayProblemForRentElement';

interface ElementsLineContentProps {
    lineWidth: number;
    tailWidth: number | undefined;
}

export const ElementsLineContent: FC<ElementsLineContentProps> = (props) => {
    const { lineWidth, tailWidth } = props;
    const { item, itemContext } = useContext(ElementItemContext);
    const { visibleTimeStart } = useContext(TimeRangeContext);
    const { isDraft, elementType, stateCode } = item;

    const isGiveawayDelayProblem = isGiveawayDelayProblemForItemElement(item);

    const backgroundColor = isDraft || elementType === 'subrent' ? undefined : CalendarColors.elementsStateCodeMap[stateCode];
    const textColor = isDraft || elementType === 'subrent' ? CalendarColors.staticColors.TEXT_COLOR : '#fff';
    const borderRadius = isGiveawayDelayProblem
        ? `${CALENDAR_LINE_BASE_RADIUS}px 0 0 ${CALENDAR_LINE_BASE_RADIUS}px`
        : CALENDAR_LINE_BASE_RADIUS;

    let fromStartToNowWidth: number | undefined = undefined;

    if (isGiveawayDelayProblem) {
        const fromStartToNow = CalendarLineUtils.difference.fromStartToNow(item, visibleTimeStart);
        if (fromStartToNow > 0) {
            fromStartToNowWidth = CalendarLineUtils.ratio.sublineWidth(item, itemContext, visibleTimeStart, fromStartToNow);
        }
    }

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <>
                <div
                    className={classNames('line-main-block-container')}
                    style={{
                        background: backgroundColor,
                        color: textColor,
                        borderRadius,
                        width: lineWidth,
                    }}
                >
                    <span
                        className={classNames('line-main-block', 'forward')}
                        style={{
                            width: lineWidth,
                            clipPath: `inset(0 calc(100% - ${lineWidth}px) 0 0)`,
                        }}
                    >
                        {<ElementsLineInnerContent backgroundColor={backgroundColor} type={'forward'} />}
                    </span>
                    <span
                        className={classNames('line-main-block', 'backward', {
                            giveawayBackward: isGiveawayDelayProblem,
                        })}
                        style={{
                            clipPath: `inset(0 0 0 ${lineWidth}px)`,
                        }}
                    >
                        {<ElementsLineInnerContent backgroundColor={backgroundColor} type={'backward'} hasTail={isGiveawayDelayProblem} />}
                    </span>
                    {isGiveawayDelayProblem && fromStartToNowWidth && (
                        <span
                            className={classNames('line-main-block', 'backward')}
                            style={{
                                clipPath: `inset(0 0 0 ${fromStartToNowWidth}px)`,
                            }}
                        >
                            {<ElementsLineInnerContent backgroundColor={backgroundColor} type={'backward'} />}
                        </span>
                    )}
                </div>
            </>
            {tailWidth != null && <LineTail width={tailWidth} />}
        </div>
    );
};

import { WorkPlanningGroupRecord, WorkPlanningsStateCodeEnum } from '../../../../../../../server';
import { CalendarId, CalendarItem, WorkPlanningsCalendarItem } from '../../../../components/Calendar/types/items';
import moment from 'moment';
import _, { PropertyPath } from 'lodash';
import { workPlanningsCalendarSortValueToItemId, WorkPlanningsSortValue } from '../data/sortData';
import { restItemsGroupId } from '../../../../components/Calendar/hooks/useCalendarItems';

export const processSplittedCalendarItem = (args: {
    groupRecord: WorkPlanningGroupRecord;
    items: CalendarItem<WorkPlanningsCalendarItem>[];
    screenLeft: number;
    screenRight: number;
    groupIndex: { current: number };
    collapsedSidebarGroupsId: CalendarId[];
    group: WorkPlanningsSortValue | undefined;
}) => {
    const { groupRecord, items, groupIndex, screenLeft, screenRight, group, collapsedSidebarGroupsId } = args;
    const { workPlannings } = groupRecord;

    if (workPlannings == null) return;

    let currentGroupId: number | undefined = undefined;

    workPlannings.forEach((record, index) => {
        const startTime = +moment(record.startDate) < screenLeft ? moment(screenLeft) : moment(record.startDate);
        const entTime = +moment(record.endDate) > screenRight ? moment(screenRight) : moment(record.endDate);

        let groupId: number | undefined = undefined;
        const sortId = workPlanningsCalendarSortValueToItemId[group ?? ''];

        if (Array.isArray(sortId)) {
            for (const id of sortId) {
                const groupIdValue = _.get(record, id as PropertyPath);
                if (groupIdValue != null) {
                    groupId = groupIdValue;
                    break;
                }
            }
        } else {
            groupId = _.get(record, workPlanningsCalendarSortValueToItemId[group ?? ''] as PropertyPath);
        }

        const safeGroupId = groupId || restItemsGroupId;

        const isGroupCollapsed = !collapsedSidebarGroupsId.includes(safeGroupId + '_' + groupRecord.entityId);
        const isStartForNewGroup = safeGroupId !== currentGroupId;

        if (isGroupCollapsed || isStartForNewGroup) {
            items.push({
                elementType: !isGroupCollapsed && isStartForNewGroup ? 'empty' : 'work',
                id: record.id + '_' + groupRecord.entityId,
                record,
                start_time: startTime,
                end_time: entTime,
                group: groupIndex.current,
                isDraft: record.stateCode === WorkPlanningsStateCodeEnum.DRAFT,
                crewMemberGroup: isStartForNewGroup
                    ? {
                          ...groupRecord,
                          workPlannings: groupRecord.workPlannings?.filter((workPlanning) => workPlanning[sortId] === record[sortId]),
                      }
                    : undefined,
            });

            groupIndex.current += 1;
        }

        currentGroupId = safeGroupId;
    });
};

import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { IRootState } from '../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../components/modalForm/utils';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { goBack } from 'connected-react-router';
import { resetErrors } from '../../../../../renters/reducers/renters/renter.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import { ContactLinkObjRead, VehicleInfoCreate, VehicleInfoRead, VehicleTypeCodeEnum } from '../../../../../../../server';
import { updateVehicle } from '../../reducers/vehicle/vehicle.reducer';
import { formFields } from '../create/VehicleCreateModalFields';
import { MetricUtils } from '../../../../../../../core/utils/metricUtils';
import { ImageUtils } from '../../../../../../../core/utils/imageUtils';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    vehicle: VehicleInfoRead;
}

export class _VehicleEditModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    getInitialValues = () => {
        const { vehicle } = this.props;
        const image = vehicle.image ? [ImageUtils.getImageFromImageObj(vehicle.image)] : undefined;

        const contacts = vehicle.contacts ? [...vehicle.contacts] : [];
        if (contacts.length === 0)
            contacts.push({
                contactId: undefined as unknown,
                position: '',
            } as ContactLinkObjRead);

        let initialValues = {
            ...vehicle,
            height: vehicle.height ? MetricUtils.fromMillimetersTiMeters(vehicle.height) : undefined,
            id: vehicle.id,
            image: image,
            isEdit: true,
            length: vehicle.length ? MetricUtils.fromMillimetersTiMeters(vehicle.length) : undefined,
            loadCapacity: vehicle.loadCapacity ? MetricUtils.fromGramsToTons(vehicle.loadCapacity) : undefined,
            width: vehicle.width ? MetricUtils.fromMillimetersTiMeters(vehicle.width) : undefined,
            contacts,
            lockDimensions: vehicle.type === VehicleTypeCodeEnum.TRACTORTRUCK,
        };

        if (this.props.initialValues) {
            initialValues = {
                ...initialValues,
                ...this.props.initialValues,
            };
        }

        return initialValues;
    };

    onOk = (data: unknown) => {
        const formData = data as VehicleInfoCreate;
        const imageResponse = formData?.image?.[0]?.response;
        const image = imageResponse
            ? {
                  id: imageResponse.id,
              }
            : undefined;
        let { contacts } = formData;
        if (contacts) contacts = contacts.filter((contact) => contact.contactId);
        this.props.updateVehicle({
            businessAccountId: this.props.businessAccountId,
            id: this.props.vehicle.id,
            vehicleInfoUpdate: {
                ...this.props.vehicle,
                ...formData,
                loadCapacity: formData.loadCapacity ? MetricUtils.fromTonsToGrams(formData.loadCapacity) : undefined,
                length: formData.length ? MetricUtils.fromMetersToMillimeters(formData.length) : undefined,
                width: formData.width ? MetricUtils.fromMetersToMillimeters(formData.width) : undefined,
                height: formData.height ? MetricUtils.fromMetersToMillimeters(formData.height) : undefined,
                image,
                contacts,
            },
        });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.vehicle.updating,
    updatingError: storeState.vehicle.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    updateVehicle,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const VehicleEditModal = connector(injectIntl(_VehicleEditModal));

import {useState} from "react";
import {useScrollEffect} from "../../../../../../core/hooks/listeners/useScrollEffect";

export const useVisibleOnScroll = () => {
    const [visible, setVisible] = useState(false)

    useScrollEffect(() => {
        setVisible(window.scrollY >= 111)
    })

    return visible
}
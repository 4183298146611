import React from 'react';
import { Table } from '@tanstack/react-table';
import { ReactTableHeader } from './ReactTableHeader';
import { DndProps, ReactTableBody } from './ReactTableBody';
import { IconAngleLeft, IconAngleRight } from '../../icons';
import { Icon } from 'antd';
import isTouchDevice from 'is-touch-device';
import ResizeObserver from 'resize-observer-polyfill';

interface ReactTableTableProps {
    table: Table<any>;
    getRowProps?(state, rowInfo, column): void;
    onColumnRemove?(id?: string): void;
    getUniqId?(data: any): string;
    alwaysRedraw: boolean;
    resizeMode: boolean;
    onRowClick?(entity: any): void;
    dndProps?: DndProps;
}

interface State {
    resizeMode: boolean;
}

export class ReactTableTable extends React.Component<ReactTableTableProps, State> {
    private ref;
    private ref1;
    private scrollingTo: undefined | 'left' | 'right' = undefined;
    private isTouch = false;
    private resizeObserver: ResizeObserver | undefined;

    constructor(props) {
        super(props);

        this.state = {
            resizeMode: false,
        };

        this.isTouch = isTouchDevice();

        this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
            entries.forEach((entry) => {
                if (entry && entry.target && entry.target.parentElement) {
                    const scrollLeft = entry.target.parentElement.getBoundingClientRect().x - entry.target.getBoundingClientRect().x;
                    this.onTableScroll(scrollLeft);
                }
            });
        });
    }

    scroll = () => {
        const scrollToX = this.ref.scrollLeft + (this.scrollingTo === 'right' ? 12 : -12) * 1.5;
        if (this.ref && this.scrollingTo) {
            this.ref.scrollTo(scrollToX, 0);
            this.scrollTo(this.scrollingTo);
        }
    };

    scrollTo = (direction: 'left' | 'right' | undefined) => {
        this.scrollingTo = direction;
        if (direction) {
            window.requestAnimationFrame(this.scroll);
        }
    };

    windowOnScroll = (e: Event) => {
        this.updateButtonsPosition();
    };

    windowOnResize = (e: Event) => {
        this.updateButtonsPosition();
    };

    updateButtonsPosition = () => {
        const leftButtonElement = (this.ref1 as HTMLElement).getElementsByClassName('rt-table-scroll-left-button')[0] as
            | HTMLElement
            | undefined;
        const rightButtonElement = (this.ref1 as HTMLElement).getElementsByClassName('rt-table-scroll-right-button')[0] as
            | HTMLElement
            | undefined;
        if (leftButtonElement && rightButtonElement) {
            const tblElTop = leftButtonElement.parentElement?.getBoundingClientRect().top || 0;
            const tblElHeight = leftButtonElement.parentElement?.getBoundingClientRect().height || 0;
            let t = Math.round((window.innerHeight - tblElTop + 55) / 2 - 100 / 2);
            if (t < 55) t = 55;
            //else if (t > tblElHeight - 100) t = tblElHeight - 100;
            const nextBtnTop = tblElTop + t;
            if (nextBtnTop + 100 / 2 <= window.innerHeight / 2) {
                t = Math.round(window.innerHeight / 2 - tblElTop - 55);
            }
            if (t > tblElHeight - 100) t = tblElHeight - 100;
            leftButtonElement.style.top = `${t}px`;
            rightButtonElement.style.top = `${t}px`;
        }
    };

    componentDidMount() {
        if (!this.isTouch) {
            window.addEventListener('scroll', this.windowOnScroll);
            window.addEventListener('resize', this.windowOnResize);
            this.updateButtonsPosition();
            this.onTableScroll(0);
        }
        const tableElement = (this.ref1 as HTMLElement).getElementsByClassName('rt-table')[0] as HTMLElement | undefined;
        if (tableElement) this.resizeObserver?.observe(tableElement);
    }

    componentWillUnmount() {
        if (!this.isTouch) {
            window.removeEventListener('scroll', this.windowOnScroll);
            window.removeEventListener('resize', this.windowOnResize);
        }
        this.resizeObserver?.disconnect();
    }

    onTableScroll = (scrollLeft: number) => {
        const tableElement = (this.ref1 as HTMLElement).getElementsByClassName('rt-table')[0] as HTMLElement | undefined;
        const leftScrollElement = (this.ref1 as HTMLElement).getElementsByClassName('rt-table-scroll-left')[0] as HTMLElement | undefined;
        const rightScrollElement = (this.ref1 as HTMLElement).getElementsByClassName('rt-table-scroll-right')[0] as HTMLElement | undefined;

        if (tableElement && tableElement.parentElement && leftScrollElement && rightScrollElement) {
            const leftButtonVisibility = scrollLeft > 0;
            const rightButtonVisibility = scrollLeft < tableElement.offsetWidth - tableElement.parentElement.offsetWidth;
            leftScrollElement.className = 'rt-table-scroll-left' + (leftButtonVisibility ? ' rt-table-scroll-visible' : '');
            rightScrollElement.className = 'rt-table-scroll-right' + (rightButtonVisibility ? ' rt-table-scroll-visible' : '');
        }
    };

    render() {
        const { table, dndProps } = this.props;
        let columnsStr = table
            .getHeaderGroups()
            .map((headerGroup) => {
                return headerGroup.headers.map((header) => header.id + '_' + header.getSize()).toString();
            })
            .join();

        return (
            <div
                ref={(ref) => {
                    if (!this.ref1) this.ref1 = ref;
                }}
                style={{ position: 'relative' }}
            >
                <div
                    className={'rt-table-scroll-left'}
                    onMouseEnter={() => {
                        this.scrollTo('left');
                    }}
                    onMouseLeave={() => {
                        this.scrollTo(undefined);
                    }}
                >
                    <div className={'rt-table-scroll-left-shadow'}></div>
                    {!this.isTouch && (
                        <div className={'rt-table-scroll-left-button'}>
                            <Icon component={IconAngleLeft} />
                        </div>
                    )}
                </div>

                <div
                    className={'rt-table-scroll-right'}
                    onMouseEnter={() => {
                        this.scrollTo('right');
                    }}
                    onMouseLeave={() => {
                        this.scrollTo(undefined);
                    }}
                >
                    <div className={'rt-table-scroll-right-shadow'}></div>
                    {!this.isTouch && (
                        <div className={'rt-table-scroll-right-button'}>
                            <Icon component={IconAngleRight} />
                        </div>
                    )}
                </div>

                <div
                    onScroll={(e) => {
                        this.onTableScroll(e.currentTarget.scrollLeft);
                        window.dispatchEvent(new Event('table-scroll-x'));
                    }}
                    className="overflow-x-auto ReactTable ReactTableV7"
                    ref={(ref) => {
                        if (!this.ref) this.ref = ref;
                    }}
                >
                    <div
                        className={'divTable rt-table'}
                        style={{
                            width: table.getTotalSize(),
                            minWidth: '100%',
                        }}
                    >
                        <div className="thead rt-thead -header">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <ReactTableHeader
                                    onResizeModeChange={(resize: boolean) => {
                                        this.setState({ resizeMode: resize });
                                    }}
                                    key={headerGroup.id}
                                    headers={headerGroup.headers}
                                    table={table}
                                    onColumnRemove={this.props.onColumnRemove}
                                />
                            ))}
                        </div>
                        <ReactTableBody
                            resizeMode={this.state.resizeMode}
                            table={table}
                            columnsStr={columnsStr}
                            alwaysRedraw={this.props.alwaysRedraw}
                            getRowProps={this.props.getRowProps}
                            getUniqId={this.props.getUniqId}
                            onRowClick={this.props.onRowClick}
                            dndProps={dndProps}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

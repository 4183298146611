export abstract class StringUtils {
    static deleteSpaces(str: string) {
        return str.replace(/ +/g, ' ').trim();
    }
    static getWordFormFromCount = (
        number: number,
        wordCase: boolean,
        firstCaseWords: [string, string, string],
        secondCaseWords: [string, string, string]
    ): string => {
        const initialString = String(number);
        const lastSymbol = initialString[initialString.length - 1];
        const words = wordCase ? firstCaseWords : secondCaseWords;

        if (Number(lastSymbol) === 1) return words[0];

        if ([2, 3, 4].includes(Number(lastSymbol))) return words[1];

        return words[2];
    };
}

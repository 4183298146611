import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import './history-elements-popup.less';

interface IProps {
    value?: JSX.Element;
    canVisible: boolean;
}

export const HistoryElementsPopupWrapper: React.FC<IProps> = ({ value, canVisible, children }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (visible) {
                setVisible(false);
            }
        };

        window.addEventListener('table-scroll-x', handleScroll);

        return () => {
            window.removeEventListener('table-scroll-x', handleScroll);
        };
    }, [visible]);

    return (
        <Popover
            visible={canVisible && visible}
            onVisibleChange={(visible) => {
                setVisible(visible);
            }}
            destroyTooltipOnHide={true}
            autoAdjustOverflow
            trigger={'hover'}
            align={{
                offset: [0, -15],
            }}
            placement={'leftTop'}
            overlayClassName={'rr-history-elements-popup-wrapper'}
            content={
                value ? (
                    <div className={'rr-history-elements-popup-wrapper'}>
                        <span className={'value'}>{value}</span>
                        <span className={'signature'}>
                            Значение
                            <br />
                            до операции
                        </span>
                    </div>
                ) : null
            }
        >
            {children}
        </Popover>
    );
};

import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import React, { CSSProperties } from 'react';
import { InstanceRecord, ProjectRecord, ProjectSourceCodeEnum } from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../../config/constants';
import DashboardDate from '../../../../../components/dashboardDate/dashboardDate';
import { getProjectSourceByCode } from '../../../../../shared/util/utils4';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { tableCellPercentsRenderer } from '../../../../../components/grid/renderers/tableCellPercentsRenderer';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';

const tagsSizes = GridColumnCreator.getColumnSizes(50);

// GRID COLUMNS
const projectColumns: TableColumn<ProjectRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createShortNameColumn<ProjectRecord>('simpleOrder', (row) => ({
        baId: row.businessAccountId,
        id: row.id,
        name: row.shortName,
    })),
    GridColumnCreator.createStatusColumn(),
    GridColumnCreator.createCounterpartyColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.renterId,
            name: row.renterShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RENTER),
            dataIndex: 'renterShortName',
        }
    ),
    {
        title: 'Источник',
        dataIndex: 'sourceCode',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: any, rowData: ProjectRecord) => {
            let style: CSSProperties | undefined;
            if (rowData.sourceCode === ProjectSourceCodeEnum.INTERNAL) style = { color: '#CCCCCC' };
            return <span style={style}>{getProjectSourceByCode(rowData.sourceCode)}</span>;
        },
    },
    GridColumnCreator.createLocationColumn<ProjectRecord>({
        title: 'Локация',
        dataIndex: 'locationName',
    }),
    {
        title: 'Теги',
        dataIndex: 'tagsString',
        type: ColumnTypes.CustomRender,
        minWidth: tagsSizes.min,
        width: tagsSizes.width,
        maxWidth: tagsSizes.max,
        resizable: true,
        render: (value: string) => {
            return (
                <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} title={value}>
                    <span>{value}</span>
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
        dataIndex: 'startDate',
        type: ColumnTypes.DateTime,
        minWidth: 140,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__PROJECTS__SUPER_PAGE_TITLE__COLUMN__TARGET_FINISH_DATE),
        dataIndex: 'targetFinishDate', // TODO Тут видимо выводить или одну дату или другую
        type: ColumnTypes.DateTime,
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.PAGE__PROJECTS__SUPER_PAGE_TITLE__COLUMN__REAL_FINISH_DATE),
        dataIndex: 'realFinishDate', // TODO Тут видимо выводить или одну дату или другую
        type: ColumnTypes.DateTime,
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'defaultDiscount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        render: (value: any, rowData: ProjectRecord) => (
            <span style={{ color: !rowData.hasOwnDefaultDiscount ? '#cccccc' : undefined }}>{tableCellPercentsRenderer(value)}</span>
        ),
    },
    {
        title: 'В заявках',
        dataIndex: 'currentOrderedSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BOOKED),
        dataIndex: 'currentBookedSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RENT),
        dataIndex: 'currentRentSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true
    },

    {
        title: 'Стоимость аренды',
        dataIndex: 'costOfRent',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Стоимость работ',
        dataIndex: 'costOfWork',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Стоимость затрат',
        dataIndex: 'costOfExpenses',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },

    {
        title: 'Итоговая стоимость',
        dataIndex: 'sumToBePaidAfterTaxes',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DEBT),
        dataIndex: 'debtSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
    },
    GridColumnCreator.createUserColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.assigneeId,
            name: row.assigneeShortName,
            hasOwn: row.hasOwnAssignee,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RESPONSIBLE),
            dataIndex: 'assigneeShortName',
        }
    ),
    GridColumnCreator.createUserColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            name: row.contactPersonShortName,
            hasOwn: row.hasOwnContactPerson,
        }),
        {
            title: 'Контактное лицо',
            dataIndex: 'contactPersonShortName',
            defaultHidden: true,
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];

export default projectColumns;

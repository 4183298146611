import { FC, memo } from 'react';
import { EntityGridSelectionProvider } from '../providers/EntityGridProvider/EntityGridSelectionProvider';

export const withEntityListProviders = <Props extends object>(WrappedComponent: FC<Props>) => {
    return memo((props: Props) => (
        <EntityGridSelectionProvider>
            <WrappedComponent {...props} />
        </EntityGridSelectionProvider>
    ));
};

import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import DynamicFilters, { DynamicFiltersProps } from '../../../../../components/dynamicFilters/DynamicFilters';
import { CalendarURLParams } from '../Calendar/types/params';
import { getFilteredParams, getPathFromState } from '../../../../../shared/util/utils';
import { setGridStorageDataFilters } from '../../../../../components/grid/utils';
import { push, replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../store/hooks';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { PageURLParamDescriptionObject } from '../../../../../core/utils/descriptions';
import { DebouncedFunc, mapValues } from 'lodash';
import { FormFieldsGroup } from '../../../../../components/dynamicForm/DynamicForm';
import { PageUrlParamsObject } from '../../../../../core/hooks/urlParams/types';

import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';

export type UseFiltersChanges<Params extends object> = (
    filtersForm: WrappedFormUtils | undefined
) => DebouncedFunc<(params: Params) => Promise<void>>;

export interface CalendarFiltersProps<Params extends object, SortValue extends string> extends Partial<DynamicFiltersProps> {
    pageName: CalendarPageTabsEnum;
    pageParamsDescription: PageURLParamDescriptionObject<CalendarURLParams<Params, SortValue>>;
    pageParamsObject: PageUrlParamsObject<CalendarURLParams<Params, SortValue>>;
    filtersData: FormFieldsGroup[];
    useFiltersChanges: UseFiltersChanges<Params>;
    filtersFormRef?: MutableRefObject<WrappedFormUtils | undefined>;
    resetCallback?: () => void;
}

export const CalendarFilters = <Params extends object, SortValue extends string>(props: CalendarFiltersProps<Params, SortValue>) => {
    const {
        pageName,
        pageParamsDescription,
        filtersFormRef,
        pageParamsObject,
        useFiltersChanges,
        filtersData,
        resetCallback,
        ...filtersProps
    } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const defaultFiltersFormRef = useRef<WrappedFormUtils>();
    const currentFormRef = filtersFormRef ?? defaultFiltersFormRef;
    const getFiltersForm = useCallback(
        (ref) => {
            return (currentFormRef.current = ref && ref.props && ref.props.form ? ref.props.form : null);
        },
        [currentFormRef]
    );

    const { pageParams } = pageParamsObject;

    const initialValues = useRef({});
    const defaultValues = useMemo(() => mapValues(pageParamsDescription, () => undefined), [pageParamsDescription]);

    const fetchEntityErrorCallback = (id: number) => {
        const { filteredParams, deletedCount } = getFilteredParams(pageParams, id);

        if (deletedCount > 0) {
            setGridStorageDataFilters(pageName, filteredParams);
            dispatch(replace(getPathFromState(location.pathname, '', filteredParams)));
        }
    };

    const onChangeFilters = useFiltersChanges(currentFormRef.current);

    const resetFilters = useCallback(() => {
        const emptyCalendarParams = Object.assign({}, defaultValues);

        delete emptyCalendarParams.screenLeft;
        delete emptyCalendarParams.screenRight;
        delete emptyCalendarParams.capture;
        delete emptyCalendarParams.displayType;
        delete emptyCalendarParams.group;

        resetCallback?.();
        currentFormRef.current?.setFieldsValue(defaultValues);
        setGridStorageDataFilters(pageName, emptyCalendarParams);
        dispatch(push(getPathFromState(location.pathname, location.search, emptyCalendarParams)));
    }, [defaultValues, currentFormRef, pageName, dispatch, location.pathname, location.search, resetCallback]);

    useEffect(() => {
        currentFormRef.current?.setFieldsValue(pageParams);
    }, [currentFormRef, pageParams]);

    const currentValues = useMemo(
        () => ({
            ...defaultValues,
            ...pageParams,
        }),
        [defaultValues, pageParams]
    );

    return (
        <DynamicFilters
            gridName={pageName}
            canResetFilters
            currentValues={currentValues}
            data={filtersData}
            defaultValues={defaultValues}
            fetchEntityErrorCallback={fetchEntityErrorCallback}
            initialValues={initialValues.current}
            onChange={onChangeFilters}
            resetFiltersCb={resetFilters}
            wrappedComponentRef={getFiltersForm}
            {...filtersProps}
        />
    );
};

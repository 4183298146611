import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../shared/reducers';
import {BasePage, IBasePageProps} from '../../../components/page/BasePage';
import {IPageHeaderProps} from '../../../components/pageHeader/pageHeader';
import {createOrEditSimpleOrder, resetOperation, startNewSimpleOrderOperation} from "./reducers/operationForm.reducer";
import {OrderElements} from "./orderModule/orderElements";
import {OrderPageHeaderNavigationBlock} from "./orderModule/orderPageHeaderNavigationBlock";
import {ProjectInfoRead, ProjectStateCodeEnum} from "../../../server";
import {Spin} from "../../../components";
import {resetBusinessAccountIndicators} from "../../../shared/reducers/businessAccountIndicators.reducer";
import './OperationFormPage.less';
import {replace} from "connected-react-router";
import {showNotification} from "../../../components/notification/showNotification";
import {
    ordersModuleEnabledSelector,
    projectsModuleEnabledSelector,
    subrentModuleEnabledSelector
} from "../../../shared/reducers/businessAccountPreferences.reducer";
import {Redirect} from "react-router";
import {businessAccountIdSelector} from "../../../shared/reducers/system.reducer";
import {OrderCancelButton} from "./orderModule/comonents/orderCancelButton/orderCancelButton";
import {InterfaceBlockCode} from "../../../components/pageHeader/components/InfoSelectDropdown/utils/constants";
import {InfoSelectDropdown} from "../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown";

interface IProps extends StateProps, DispatchProps, IBasePageProps {
}

interface IState {
    loading: boolean;
}

class Component extends BasePage<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
        if (!this.props.mnemoKey){
            this.props.startNewSimpleOrderOperation();
        }
    }

    getPageHeaderProps(): IPageHeaderProps {
        let {renterId, renterShortName} = this.props;

        return {
            NavigationBlock: <OrderPageHeaderNavigationBlock data={{
                orderName: 'Новый заказ',
                counterpartyId: renterId,
                counterpartyName: renterShortName,
                stateCode: ProjectStateCodeEnum.DRAFT
            }}/>,
            rightBlock: <>
                <OrderCancelButton creationMode={true} />
                <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_ORDERS_ORDER_CARD} />
            </>
        };
    }

    createNewOrder = async () => {
        this.setState({loading: true});
        try{
            const project:ProjectInfoRead = await this.props.createSimpleOrder() as any;
            if(project){
                this.props.resetOperation();
                this.props.resetBusinessAccountIndicators();
                this.props.replace(`/${project.businessAccountId}/projects/simpleOrders/${project.id}?tab=description`)
                showNotification('success', 'Заказ создан');
            }else{
                showNotification('error', 'Заказ не создан');
            }
        }catch (e){
            showNotification('error', 'Заказ не создан');
        }
        this.setState({loading: false});
    };

    renderContent() {
        const {ordersModuleEnabled, businessAccountId} = this.props;

        if(!ordersModuleEnabled){
            return <Redirect to={`/${businessAccountId}/operation`} />;
        }

        return (
            <Spin spinning={this.state.loading}>
                <OrderElements createOperation={this.createNewOrder} />
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        renterId: storeState.operationForm.renterId,
        renterShortName: storeState.operationForm.renterShortName,
        mnemoKey: storeState.operationForm.mnemoKey,
        businessAccountId: businessAccountIdSelector(storeState),
        projectsModuleEnabled: projectsModuleEnabledSelector(storeState),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        ordersModuleEnabled: ordersModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {startNewSimpleOrderOperation, resetBusinessAccountIndicators, resetOperation, createSimpleOrder: createOrEditSimpleOrder, replace};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const NewOrderPage = connect(mapStateToProps, mapDispatchToProps)(Component);

import React from 'react';
import { Status } from '../../status/status';
import { EntityType, GridRecord } from '../../../../index';
import { LocalizationEnum, localize } from '../../../localization';

const style = { width: '100%' };

export const tableCellStatusRenderer = (
    value: any,
    rowData: GridRecord,
    entityType: EntityType,
    customTextMap?: Record<string, string>
) => {
    const customText = customTextMap ? customTextMap[value] : undefined;

    return value ? (
        <Status entityType={entityType} code={value} style={style} customText={customText} />
    ) : (
        <span style={{ color: '#575962', opacity: 0.4 }}>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT)}</span>
    );
};

import { DownloadDocumentPopoverProps, IDownloadDocumentParamsItem } from '../../../DownloadDocumentPopover';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { FileTypeCodeEnum } from '../../../../../../server';

export enum ExportActPopoverOptions {
    EXPAND_KITS = 'expandKits',
    FREE_ITEMS = 'freeItems',
    JOIN_SIMILAR_ROWS = 'joinSimilarRows',
    GROUP_BY_CATEGORIES = 'groupByCategories',
    WORK_PLANNINGS = 'workPlannings',
    EXPENSES = 'expenses',
}

export const defaultOptions: IDownloadDocumentParamsItem[] = [
    {
        key: ExportActPopoverOptions.EXPAND_KITS,
        label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS),
    },
    { key: ExportActPopoverOptions.FREE_ITEMS, label: 'Бесплатные позиции' },
    { key: ExportActPopoverOptions.JOIN_SIMILAR_ROWS, label: 'Одинаковые в одну строку' },
    { key: ExportActPopoverOptions.GROUP_BY_CATEGORIES, label: 'Группировку по категории' },
    { key: ExportActPopoverOptions.WORK_PLANNINGS, label: 'Работы' },
    { key: ExportActPopoverOptions.EXPENSES, label: 'Затраты' },
];

export const availableFileTypes = [FileTypeCodeEnum.WORD, FileTypeCodeEnum.PDFTYPE] as const;
export type ActFileType = (typeof availableFileTypes)[number];
export type ActDocumentOnDownload = DownloadDocumentPopoverProps<ActFileType, ExportActPopoverOptions>['onDownload'];

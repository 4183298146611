import React from 'react';
import { DateHeader, IntervalRenderer, Unit } from 'react-calendar-timeline';
import moment from 'moment';
import { formatDate } from '../../../../../../shared/util/formatDate';
import { LocalizationEnum, localize } from '../../../../../../localization';

interface IProps {
    unit: Unit;
    mode?: Unit;
    onIntervalClick(unit: Unit, primary: boolean, startTime: moment.Moment, endTime: moment.Moment): void;
    allNothing?: boolean;
}

export const CalendarPrimaryHeader = (props: IProps) => {
    return (
        <DateHeader
            unit={props.unit}
            intervalRenderer={(interval?: IntervalRenderer<null>) => {
                return props.allNothing ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            border: '1px solid #ccc',
                            padding: '10px 10px',
                            height: '40px',
                            textTransform: 'uppercase',
                        }}
                    >
                        {localize(LocalizationEnum.PAGE__CALENDAR__ANY_TIME)}
                    </div>
                ) : interval ? (
                    <div
                        {...interval.getIntervalProps()}
                        onClick={
                            props.unit !== 'year'
                                ? () => {
                                      props.onIntervalClick(
                                          props.unit,
                                          true,
                                          moment(interval.intervalContext.interval.startTime),
                                          moment(interval.intervalContext.interval.endTime)
                                      );
                                  }
                                : undefined
                        }
                        className={
                            'rct-dateHeader rct-dateHeader-primary' +
                            (props.unit === 'year' ? ' rct-dateHeader-disabled' : '') +
                            (props.unit === 'week' ? ' rct-dateHeader-primary-week' : '')
                        }
                    >
                        {props.unit === 'week' ? (
                            <span>
                                {localize(LocalizationEnum.ASPECT__TIME__WEEK, undefined, { value: 1 })}:{' '}
                                {formatDate(new Date(+interval.intervalContext.interval.startTime), 'D MMMM') +
                                    ' - ' +
                                    formatDate(new Date(+interval.intervalContext.interval.endTime), 'D MMMM')}
                            </span>
                        ) : (
                            <span style={{ textTransform: 'capitalize' }}> {interval.intervalContext.intervalText} </span>
                        )}
                    </div>
                ) : null;
            }}
        />
    );
};

import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../localization';
import { SliderRangeWithManualInput } from '../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { InlineNumberInputColorTheme } from '../../../../components/inlineInputs/InlineNumberInput/soft/InlineNumberInput';

export const filters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__SUM,
                id: 'rentElementsSum',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                className: 'rr-slider-gray',
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000,
                    step: 1,
                    theme: InlineNumberInputColorTheme.DARK,
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
];

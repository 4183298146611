import React from 'react';

export const IconNounBreak = () => (
    <svg viewBox="0 0 32 32" width="1em" height="1em" fill="currentColor">
<g transform="translate(0.000000, 4.000000)">
                <path d="M13.3628145,16.3462789 L16.1690055,14.0468777 C16.4028548,13.8497861 16.4028548,13.4556031 16.1355985,13.2913601 L13.1289652,11.386142 C12.8617089,11.2218991 12.8283019,10.827716 13.0955582,10.6306245 L14.3650256,9.57946963 C14.5654678,9.41522669 14.5988748,9.15243798 14.4318396,8.95534645 L13.4296286,7.73994867 C13.3294074,7.60855432 13.1957793,7.54285714 13.0621512,7.54285714 L1.23606034,7.54285714 C0.567919615,7.54285714 0,8.10128315 0,8.75825492 L0,15.2294269 C0,15.8863986 0.567919615,16.4448246 1.23606034,16.4448246 L13.0287441,16.4448246 C13.1623723,16.4776732 13.2625934,16.4448246 13.3628145,16.3462789 Z" id="Path"></path>
                <path d="M30.7814445,7.54285714 L17.6972361,7.54285714 C17.2915242,7.54285714 17.0886683,7.96890756 17.3253336,8.29663866 L17.8662827,8.98487395 C18.00152,9.18151261 17.9677107,9.47647059 17.7986641,9.60756303 L16.5139098,10.6563025 C16.2772445,10.8529412 16.2772445,11.2462185 16.5477191,11.410084 L19.5905583,13.3109244 C19.8610329,13.4747899 19.8948422,13.8680672 19.6243676,14.0647059 L17.6634268,15.6378151 C17.3253336,15.9 17.5281895,16.4571429 17.9677107,16.4571429 L30.7476352,16.4571429 C31.4238217,16.4571429 31.9985802,15.9 31.9985802,15.2445378 L31.9985802,8.75546218 C32.0323895,8.1 31.457631,7.54285714 30.7814445,7.54285714 Z" id="Path"></path>
                <path d="M16,4.8 C16.5673759,4.8 17.0212766,4.37647059 17.0212766,3.84705882 L17.0212766,0.952941176 C17.0212766,0.423529412 16.5673759,0 16,0 C15.4326241,0 14.9787234,0.423529412 14.9787234,0.952941176 L14.9787234,3.84705882 C14.9787234,4.37647059 15.4326241,4.8 16,4.8 Z" id="Path"></path>
                <path d="M11.9424957,5.87623318 C12.1265095,6.0607303 12.3841288,6.17142857 12.6417481,6.17142857 C12.8993675,6.17142857 13.1569868,6.0607303 13.3410006,5.87623318 C13.7090282,5.50723895 13.7090282,4.87994875 13.3410006,4.47405509 L11.2064405,2.33388853 C10.8384129,1.9648943 10.212766,1.9648943 9.8079356,2.33388853 C9.43990799,2.70288277 9.43990799,3.33017297 9.8079356,3.73606662 L11.9424957,5.87623318 Z" id="Path"></path>
                <path d="M18.6774008,6.17142857 C18.9350201,6.17142857 19.1926394,6.0607303 19.3766532,5.87623318 L21.5112133,3.73606662 C21.8792409,3.36707239 21.8792409,2.73978219 21.5112133,2.33388853 C21.1431857,1.9648943 20.5175388,1.9648943 20.1127085,2.33388853 L17.9781484,4.47405509 C17.6101208,4.84304933 17.6101208,5.47033953 17.9781484,5.87623318 C18.1621622,6.09762972 18.4197815,6.17142857 18.6774008,6.17142857 Z" id="Path"></path>
                <path d="M16,19.2 C15.4326241,19.2 14.9787234,19.6235294 14.9787234,20.1529412 L14.9787234,23.0470588 C14.9787234,23.5764706 15.4326241,24 16,24 C16.5673759,24 17.0212766,23.5764706 17.0212766,23.0470588 L17.0212766,20.1529412 C17.0212766,19.6235294 16.5673759,19.2 16,19.2 Z" id="Path"></path>
                <path d="M19.3766532,18.1053171 C19.0086256,17.7363229 18.3829787,17.7363229 17.9781484,18.1053171 C17.6101208,18.4743113 17.6101208,19.1016015 17.9781484,19.5074952 L20.1127085,21.6476618 C20.2967223,21.8321589 20.5543416,21.9428571 20.8119609,21.9428571 C21.0695802,21.9428571 21.3271995,21.8321589 21.5112133,21.6476618 C21.8792409,21.2786675 21.8792409,20.6513773 21.5112133,20.2454837 L19.3766532,18.1053171 Z" id="Path"></path>
                <path d="M11.9424957,18.1053171 L9.8079356,20.2454837 C9.43990799,20.6144779 9.43990799,21.2417681 9.8079356,21.6476618 C9.9919494,21.8321589 10.2495687,21.9428571 10.507188,21.9428571 C10.7648074,21.9428571 11.0224267,21.8321589 11.2064405,21.6476618 L13.3410006,19.5074952 C13.7090282,19.138501 13.7090282,18.5112108 13.3410006,18.1053171 C12.9361702,17.7363229 12.3105233,17.7363229 11.9424957,18.1053171 Z" id="Path"></path>
</g>
</svg>
);

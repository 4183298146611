import React, { FC } from 'react';
import { WorkPlanningsSplittedCalendarSidebarGroup } from '../../WorkPlanningsSplittedCalendarSidebarGroup';
import { workPlanningsCalendarSortValueToItemName } from '../../../../../../../../utils/data/sortData';
import { SideBarGroup } from '../../utils/types';
import { rentersPageUrlRoute } from '../../../../../../../../../../../renters/data/tabs';
import { RentersPageTabsEnum } from '../../../../../../../../../../../../../shared/constants/tabEnums';
import { useAppSelector } from '../../../../../../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../../../../../../shared/reducers/system.reducer';
import { Link } from 'react-router-dom';
import { IconUsers } from '../../../../../../../../../../../../../components/icons';

interface RenterSidebarGroupProps extends SideBarGroup {}

export const RenterSidebarGroup: FC<RenterSidebarGroupProps> = (props) => {
    const { isCollapsed, onClick, workPlannings, displayType } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const titleKey = workPlanningsCalendarSortValueToItemName['renter'];
    const { counterpartyId } = workPlannings[0];

    const link = `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${counterpartyId}`;

    const title = (
        <Link to={link} className={'link'}>
            {workPlannings[0][titleKey]}
        </Link>
    );

    return (
        <WorkPlanningsSplittedCalendarSidebarGroup
            workPlannings={workPlannings}
            displayType={displayType}
            onClick={onClick}
            isCollapsed={isCollapsed}
            iconComponent={IconUsers}
            title={title}
            subtitle={undefined}
        />
    );
};

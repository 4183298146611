import React, { CSSProperties, FC } from 'react';
import { Icon as IconComponent } from 'antd';
import classNames from 'classnames';
import { CustomIconComponentProps } from 'antd/lib/icon';
import {
    IconBriefcase,
    IconCashRegisterSolid,
    IconClose,
    IconDollySolid,
    IconDownloadSolid,
    IconEdit,
    IconEllipsisHSolid,
    IconExchange,
    IconEyeSlashSolid,
    IconEyeSolid,
    IconGlobeSolid,
    IconHardHatSolid,
    IconIdCard,
    IconPaperclipSolid,
    IconPencil,
    IconPencilRulerSolid,
    IconPlus,
    IconPollHSolid,
    IconTrash,
    IconUploadSolid,
} from '../../icons';
import './systemIcon.less';
import { IconChartPieSolid } from '../../icons/IconChartPieSolid';
import { IconSitemapSolid } from '../../icons/IconSitemapSolid';
import { IconChartLineSolid } from '../../icons/IconChartLineSolid';
import { IconChartBar } from '../../icons/IconChartBar';

export enum SystemIconType {
    /**
     * add
     */
    add = 'add',
    /**
     * standard
     */
    standard = 'standard',
    /**
     * custom
     */
    custom = 'custom',
    /**
     * financial
     */
    financial = 'financial',
    /**
     * hidden
     */
    hidden = 'hidden',
    /**
     * unhidden
     */
    unhidden = 'unhidden',
    /**
     * chartType_ClusteredColumnChart
     */
    chartType_ClusteredColumnChart = 'chartType_ClusteredColumnChart',
    /**
     * chartType_ColumnChart
     */
    chartType_ColumnChart = 'chartType_ColumnChart',
    /**
     * chartType_HighlightingChartSeries
     */
    chartType_HighlightingChartSeries = 'chartType_HighlightingChartSeries',
    /**
     * chartType_MultilevelTreeMap
     */
    chartType_MultilevelTreeMap = 'chartType_MultilevelTreeMap',
    /**
     * chartType_PieChart
     */
    chartType_PieChart = 'chartType_PieChart',
    /**
     * delete
     */
    delete = 'delete',
    /**
     * cancel
     */
    cancel = 'cancel',
    /**
     * edit
     */
    edit = 'edit',
    /**
     * download
     */
    download = 'download',
    /**
     * upload
     */
    upload = 'upload',
    /**
     * otherActions
     */
    otherActions = 'otherActions',
    /**
     * actions
     */
    actions = 'actions',

    /**
     * Иконка проекта - чемодан
     */
    project = 'project',

    /**
     * Иконка поставки - телега с коробкой
     */
    subrent = 'subrent',

    /**
     * Иконка действий над элементом - стрелки в разные стороны
     */
    rentElementActions = 'rentElementActions',

    /**
     * Иконка вложения - скрепка
     */
    attachment = 'attachment',

    /**
     * Иконка контакта - визитка
     */
    contact = 'contact',

    /**
     * Иконка каски - работник
     */
    crew = 'crew',

    /**
     * Иконка горизонтальные полоски в квадрате - реквизиты
     */
    legalAttributes = 'legalAttributes',
}

export interface SystemIconProps {
    type: SystemIconType;
    style?: CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

const iconsMap: Record<SystemIconType, React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>> = {
    [SystemIconType.add]: IconPlus,
    [SystemIconType.standard]: IconGlobeSolid,
    [SystemIconType.custom]: IconPencilRulerSolid,
    [SystemIconType.financial]: IconCashRegisterSolid,
    [SystemIconType.hidden]: IconEyeSlashSolid,
    [SystemIconType.unhidden]: IconEyeSolid,
    [SystemIconType.chartType_MultilevelTreeMap]: IconSitemapSolid, // TODO Вынести в отдельный компонент
    [SystemIconType.chartType_ClusteredColumnChart]: IconChartBar, // TODO Вынести в отдельный компонент
    [SystemIconType.chartType_HighlightingChartSeries]: IconChartLineSolid, // TODO Вынести в отдельный компонент
    [SystemIconType.chartType_ColumnChart]: IconChartBar, // TODO Вынести в отдельный компонент
    [SystemIconType.chartType_PieChart]: IconChartPieSolid, // TODO Вынести в отдельный компонент
    [SystemIconType.delete]: IconTrash,
    [SystemIconType.cancel]: IconClose,
    [SystemIconType.edit]: IconEdit,
    [SystemIconType.download]: IconDownloadSolid,
    [SystemIconType.upload]: IconUploadSolid,
    [SystemIconType.otherActions]: IconEllipsisHSolid,
    [SystemIconType.actions]: IconPencil,
    [SystemIconType.project]: IconBriefcase,
    [SystemIconType.subrent]: IconDollySolid,
    [SystemIconType.rentElementActions]: IconExchange,
    [SystemIconType.attachment]: IconPaperclipSolid,
    [SystemIconType.contact]: IconIdCard,
    [SystemIconType.crew]: IconHardHatSolid,
    [SystemIconType.legalAttributes]: IconPollHSolid,
};

export const SystemIcon: FC<SystemIconProps> = ({ type, ...props }) => {
    let className: string | undefined = `rr-icon-system-${type}`;
    const component = iconsMap[type];
    return (
        <IconComponent
            className={classNames('rr-icon-', className, props.className)}
            style={{ ...props.style }}
            component={component}
            onClick={props.onClick}
        />
    );
};

import { EntityGridRootPath, URLDrawerParams } from '../../../../components/page/entityGrid/types/params';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useSetStatusForWorkMutation, workPlanningsApi } from '../api/workPlannings.api';
import { useCallback, useMemo } from 'react';
import { WorkPlanningsInfoRead, WorkPlanningsTransitionCodeEnum } from '../../../../server';
import { PageURLParamDescriptions } from '../../../../core/utils/descriptions';
import { push } from 'connected-react-router';
import { workPlanningActionToTransitionCodeMap, WorkPlanningActionType } from '../data/workPlanningsData';
import { EntityActions } from '../../../../components/page/entityGrid/hooks/useRowActions';

type WorkPlanningEntityActions = EntityActions<WorkPlanningsInfoRead>;

export const useEntityActions = ({ rootPath, urlSearchParams }: { rootPath: EntityGridRootPath; urlSearchParams: URLSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const [setStatusForWork, { isLoading: isSetStatusForWorkLoading }] = useSetStatusForWorkMutation();

    const isLoading = isSetStatusForWorkLoading;

    const editWorkPlanningCallback: WorkPlanningEntityActions['editAction'] = useCallback(
        (workPlanning: WorkPlanningsInfoRead, options) => {
            dispatch(
                workPlanningsApi.endpoints.getWorkPlanningById.initiate(
                    {
                        businessAccountId,
                        entityId: workPlanning.id || 0,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.delete('modal');
            searchParams.append('modal', modalType);
            searchParams.delete('id');
            searchParams.append('id', String(workPlanning.id));

            if (options?.withView != null) {
                if (options.withView) {
                    const booleanParam = PageURLParamDescriptions.booleanParam.toString(options.withView);
                    if (booleanParam) {
                        searchParams.append('view', booleanParam);
                    }
                } else {
                    searchParams.delete('view');
                }
            }
            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editWorkPlanningForDrawerCallback: WorkPlanningEntityActions['editActionForDrawer'] = useCallback(
        (workPlanning: WorkPlanningsInfoRead) => {
            editWorkPlanningCallback(workPlanning, { withView: true });
        },
        [editWorkPlanningCallback]
    );

    const copyWorkPlanningCallback: WorkPlanningEntityActions['copyActionForDrawer'] = useCallback(
        (workPlanning: WorkPlanningsInfoRead, options) => {
            editWorkPlanningCallback(workPlanning, { copy: true, ...options });
        },
        [editWorkPlanningCallback]
    );

    const setStatusForWorkPlanningCallback = useCallback(
        ({ id, businessVersion }: WorkPlanningsInfoRead, transitionCode: WorkPlanningsTransitionCodeEnum) => {
            setStatusForWork({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: id || 0,
                            businessVersion: businessVersion || 0,
                            transitionCode,
                        },
                    ],
                },
            });
        },
        [setStatusForWork]
    );

    const onRowAction: WorkPlanningEntityActions['onRowAction'] = useCallback(
        (item: WorkPlanningsInfoRead, a: any) => {
            const action = a as WorkPlanningActionType;
            if (action === WorkPlanningActionType.edit) editWorkPlanningCallback(item);
            else {
                const code = workPlanningActionToTransitionCodeMap[action];
                if (code) setStatusForWorkPlanningCallback(item, code);
            }
        },
        [editWorkPlanningCallback, setStatusForWorkPlanningCallback]
    );

    return useMemo(
        () => ({
            editAction: editWorkPlanningCallback,
            editActionForDrawer: editWorkPlanningForDrawerCallback,
            copyActionForDrawer: copyWorkPlanningCallback,
            copyAction: copyWorkPlanningCallback,
            onRowAction,
            isLoading,
        }),
        [copyWorkPlanningCallback, editWorkPlanningCallback, editWorkPlanningForDrawerCallback, isLoading, onRowAction]
    );
};

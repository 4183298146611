import React, { FC, memo, useState } from 'react';
import './CrewMemberProfessions.less';
import { CrewMemberInfoRead } from '../../../../../../../../../server';
import { ProfessionModals } from './components/modal/ProfessionDrawer/ProfessionModals';
import { CrewMemberStaticGrid } from './components/grid/StaticGrid/CrewMemberStaticGrid';
import { CrewMemberEditGrid } from './components/grid/EditGrid/CrewMemberEditGrid';

interface CrewMemberProfessionsProps {
    crewMember: CrewMemberInfoRead;
}

export const CREW_MEMBER_COMPOSITION_GRID_NAME = 'crew-member-composition';

export const CrewMemberProfessions: FC<CrewMemberProfessionsProps> = memo((props) => {
    const { crewMember } = props;
    const [isEdit, setIsEdit] = useState(false);

    return (
        <>
            <div className="grid-container">
                {isEdit ? (
                    <CrewMemberEditGrid crewMember={crewMember} setIsEdit={setIsEdit} />
                ) : (
                    <CrewMemberStaticGrid crewMember={crewMember} setIsEdit={setIsEdit} />
                )}
            </div>
            {/* Modal */}
            <ProfessionModals crewMember={crewMember} />
        </>
    );
});

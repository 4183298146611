import React, { FC, useState } from 'react';
import { IRouterItem } from '../../config/routes';
import { matchPath } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { localize } from '../../localization';
import { Anchor, Icon } from 'antd';
import { IconAngleDown } from '../icons';
import { useSelector } from 'react-redux';
import { saveCurrentTabToStore } from '../../shared/reducers/userSettings/userSettings.reducer';

function scrollTop() {
    document.getElementsByTagName('body')[0].scrollIntoView({ behavior: 'smooth' });
}

interface SettingsMenuProps {
    settingsRoutes?: IRouterItem[] | undefined;
}

const SettingsMenu: FC<SettingsMenuProps> = (props) => {
    const pathname = useSelector((state: any) => state.router.location.pathname);
    const hash = useSelector((state: any) => state.router.location.hash);

    let _matchPath: any = null;

    const [collapsedMenuItems, setCollapsedMenuItems] = useState<any>([]);

    const onItemCollapsed = (id: string) => {
        let ids = [...collapsedMenuItems];
        if (!ids.includes(id)) ids.push(id);
        else ids.splice(ids.indexOf(id), 1);
        setCollapsedMenuItems(ids);
    };

    const saveRouteKey = (linkPath: string) => {
        const routeChunks = linkPath.split('/');
        const routeKey = routeChunks[routeChunks.length - 1];
        saveCurrentTabToStore('settings', routeKey);
    };

    const printMenuItem = (item: IRouterItem, selectedKeys: string[], level: number = 0, index: number = 0) => {
        let itemLinkPath = item.menuPath || item.path.toString();

        if (_matchPath) {
            for (let k in _matchPath.params) {
                itemLinkPath = itemLinkPath.replace(':' + k, _matchPath.params[k]);
            }
        }

        if (item.title) {
            if (item.childs !== undefined && item.childs.length > 0) {
                return (
                    <li
                        key={index}
                        className={
                            'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open' +
                            (selectedKeys?.includes(item.menuKey) ? ' ant-menu-item-selected' : '') +
                            (collapsedMenuItems?.includes(item.menuKey) ? ' ant-menu-item-collapsed' : '')
                        }
                        role="menuitem"
                    >
                        <div style={{ display: 'flex' }} className={'ant-menu-submenu-title'}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <NavLink
                                    to={itemLinkPath}
                                    onClick={() => {
                                        saveRouteKey(itemLinkPath);
                                        scrollTop();
                                    }}
                                >
                                    {localize(item.title)}
                                </NavLink>
                            </div>
                            <div style={{ flexGrow: 1, paddingLeft: 14 }}>
                                <Icon component={IconAngleDown} onClick={() => onItemCollapsed(item.menuKey)} />
                            </div>
                        </div>

                        <Anchor affix={false} style={{ maxHeight: 'unset' }}>
                            {item.childs.map((item: IRouterItem, index) => {
                                let linkPath = item.menuPath || item.path.toString();

                                if (_matchPath) {
                                    for (let k in _matchPath.params) {
                                        linkPath = linkPath.replace(':' + k, _matchPath.params[k]);
                                    }
                                }

                                return (
                                    <React.Fragment key={index}>
                                        <Link
                                            to={linkPath}
                                            onClick={(e) => {
                                                saveRouteKey(linkPath);
                                            }}
                                        >
                                            <Anchor.Link href={linkPath} title={item.title ? localize(item.title) : ''} />
                                        </Link>
                                    </React.Fragment>
                                );
                            })}
                        </Anchor>
                    </li>
                );
            } else {
                return (
                    <li
                        key={index}
                        className={'ant-menu-item' + (selectedKeys.includes(item.menuKey) ? ' ant-menu-item-selected' : '')}
                        role="menuitem"
                        style={{ paddingLeft: 26 * level }}
                    >
                        <NavLink
                            to={itemLinkPath}
                            onClick={(e) => {
                                saveRouteKey(itemLinkPath);
                            }}
                        >
                            {localize(item.title)}
                        </NavLink>
                    </li>
                );
            }
        }
        return null;
    };

    let matchKeys: string[] = [];

    if (props.settingsRoutes) {
        props.settingsRoutes.forEach((item) => {
            let match = matchPath(pathname, item);
            if (match) {
                if (!_matchPath) _matchPath = match;
                matchKeys.push(item.menuKey || '');
            }
            if (item.childs) {
                item.childs.forEach((item) => {
                    let match = matchPath(pathname + '' + hash, item);
                    if (match) {
                        if (!_matchPath) _matchPath = match;
                        matchKeys.push(item.menuKey || '');
                    }
                });
            }
        });
    }

    return (
        <div className={'rr-page-settings__menu'}>
            <ul className="ant-menu ant-menu-root" style={{ position: 'fixed' }}>
                {props.settingsRoutes
                    ? props.settingsRoutes.map((item: IRouterItem, index) => printMenuItem(item, matchKeys, 0, index))
                    : null}
            </ul>
        </div>
    );
};

export default SettingsMenu;

import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { EntityLifecycleEventRecord, EntityLifecycleEventTypeCode, EntityTypeCodeEnum } from '../../../../../../server';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { LocalizationEnum, localize } from '../../../../../../localization';
import React from 'react';
import { entityTypeCodeMap } from '../../../../../../localization/utils/entityTypeCodeMap';
import { EventTypeItem } from '../components/EventTypeItem/EventTypeItem';

const smallSizes = GridColumnCreator.getColumnSizes(20);
const mediumSizes = GridColumnCreator.getColumnSizes(60);

export const lifecycleEventsColumns: TableColumn<EntityLifecycleEventRecord>[] = [
    {
        title: 'Тип сущности',
        type: ColumnTypes.CustomRender,
        dataIndex: 'entityTypeCode',
        minWidth: 300,
        width: mediumSizes.width,
        maxWidth: mediumSizes.max,
        resizable: true,
        sortable: true,
        render: (code: EntityTypeCodeEnum) => {
            return <span>{localize(entityTypeCodeMap[code])}</span>;
        },
    },
    GridColumnCreator.createShortNameColumn<EntityLifecycleEventRecord>(
        'lifecycle-event',
        (row) => ({
            baId: row.businessAccountId,
            name: row.entityName,
            id: row.entityId,
        }),
        {
            title: 'Наименование сущности',
            dataIndex: 'entityName',
            dontHide: true,
            sortable: false,
            minWidth: 300,
            width: mediumSizes.width,
            maxWidth: mediumSizes.max,
            render: (value, rowData: EntityLifecycleEventRecord) => {
                return (
                    <div
                        className={'rr-grid-shortName-cell rr-grid-text-cell-left'}
                        style={{
                            color: rowData.deletedEntity ? '#aaaaaa' : undefined,
                        }}
                    >
                        {value}
                    </div>
                );
            },
        }
    ),
    {
        title: 'Тип действия',
        type: ColumnTypes.CustomRender,
        dataIndex: 'entityLifecycleEventTypeCode',
        minWidth: 300,
        width: mediumSizes.width,
        maxWidth: mediumSizes.max,
        resizable: true,
        sortable: true,
        className: 'rr-grid-text-cell-left',
        render: (code: EntityLifecycleEventTypeCode) => {
            return <EventTypeItem typeCode={code} style={{ justifyContent: 'start' }} />;
        },
    },
    GridColumnCreator.createLastUpdateDateColumn<EntityLifecycleEventRecord>({
        title: 'Дата события',
        dataIndex: 'activityDate',
        minWidth: smallSizes.min,
    }),
    GridColumnCreator.createUserColumn<EntityLifecycleEventRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.activityAuthorId,
            name: row.activityAuthorShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__AUTHOR),
            dataIndex: 'activityAuthorShortName',
            minWidth: 120,
            width: smallSizes.width,
            maxWidth: smallSizes.max,
        }
    ),
];

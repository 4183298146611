import {
    RentActivityFrameStateCodeEnum,
    RentStateCodeEnum,
    TimetableTypeCodeEnum,
    WorkPlanningsStateCodeEnum,
} from '../../../../../../server';

export abstract class CalendarColors {
    static staticColors = {
        WARNING_COLOR: '#FC8C2F',
        DELAY_COLOR: '#f4516c',
        TEXT_COLOR: 'rgb(87, 89, 98)',
    } satisfies Record<string, `${`#` | 'rgb'}${string}`>;
    static elementsStateCodeMap: Record<RentStateCodeEnum, string> = {
        [RentStateCodeEnum.ORDERED]: '#04b0f1',
        [RentStateCodeEnum.BOOKED]: '#8b5cb1',
        [RentStateCodeEnum.RENT]: '#fc8c2f',
        [RentStateCodeEnum.RETURNED]: '#34bfa3',
        [RentStateCodeEnum.RETURNEDBROKEN]: '#440000',
        [RentStateCodeEnum.LOSTDURINGRENT]: '#08004a',
        [RentStateCodeEnum.CANCELED]: '#a0a0a0',
        [RentStateCodeEnum.DRAFT]: '#575962',
        [RentStateCodeEnum.SUBRENTSHIPMENTBOOKED]: '#8b5cb1',
        [RentStateCodeEnum.SUBRENT]: '#fc8c2f',
        [RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER]: '#34bfa3',
        [RentStateCodeEnum.SUBRENTCANCELED]: '#a0a0a0',
        [RentStateCodeEnum.SUBRENTDRAFT]: '#575962',
        [RentStateCodeEnum.NOTEXISTS]: '#a0a0a0',
        [RentStateCodeEnum.MIXED]: 'rgb(82, 89, 147)',
    };
    static activityFramesStateCodeMap: Required<Record<RentActivityFrameStateCodeEnum, string>> = {
        [RentActivityFrameStateCodeEnum.DRAFT]: '#575962',
        [RentActivityFrameStateCodeEnum.BOOKED]: '#8b5cb1',
        [RentActivityFrameStateCodeEnum.RENTED]: '#fc8c2f',
        [RentActivityFrameStateCodeEnum.INPROGRESS]: '#fc8c2f',
        [RentActivityFrameStateCodeEnum.FINISHED]: '#34bfa3',
        [RentActivityFrameStateCodeEnum.CANCELED]: '#a0a0a0',
        [RentActivityFrameStateCodeEnum.COORDINATION]: '#04b0f1',
    };
    static workPlanningsStateCodeMap: Required<Record<WorkPlanningsStateCodeEnum, string>> = {
        [WorkPlanningsStateCodeEnum.DRAFT]: '#575962',
        [WorkPlanningsStateCodeEnum.PLANNED]: '#8b5cb1',
        [WorkPlanningsStateCodeEnum.INPROGRESS]: '#fc8c2f',
        [WorkPlanningsStateCodeEnum.FINISHED]: '#34bfa3',
        [WorkPlanningsStateCodeEnum.CANCELED]: '#a0a0a0',
    };
    static accessibilityStateCodeBackgroundMap: Required<Record<TimetableTypeCodeEnum, [string, string, string]>> = {
        [TimetableTypeCodeEnum.ORDERAVAILABLE]: ['#34bfa3', '#AAA', '#f4516c'],
        [TimetableTypeCodeEnum.AVAILABLE]: ['#34bfa3', '#AAA', '#f4516c'],
        [TimetableTypeCodeEnum.ORDER]: ['#04b0f1', '#AAA', '#f4516c'],
        [TimetableTypeCodeEnum.OCCUPIED]: ['#fc8c2f', 'transparent', 'transparent'],
        [TimetableTypeCodeEnum.MAINTENANCE]: ['#525993', 'transparent', 'transparent'],
        [TimetableTypeCodeEnum.STOCK]: ['#fff', '#AAA', 'transparent'],
        [TimetableTypeCodeEnum.SUBRENTED]: ['#fff', 'transparent', 'transparent'],
    };
    static accessibilityStateCodeBorderMap: Partial<Record<TimetableTypeCodeEnum, string>> = {
        [TimetableTypeCodeEnum.STOCK]: '#6f727d',
        [TimetableTypeCodeEnum.SUBRENTED]: '#ffb822',
    };
    static getAccessibilityStateCodeBorderMap = (typeCode: TimetableTypeCodeEnum, value: number) => {
        if (typeCode === TimetableTypeCodeEnum.STOCK && value === 0) return undefined;

        return this.accessibilityStateCodeBorderMap[typeCode];
    };
    static accessibilityStateCodeColorMap: Partial<Record<TimetableTypeCodeEnum, string>> = {
        [TimetableTypeCodeEnum.STOCK]: '#6f727d',
        [TimetableTypeCodeEnum.SUBRENTED]: ' #6f727d',
    };
    static getAccessibilityStateCodeColorMap = (typeCode: TimetableTypeCodeEnum, value: number) => {
        if (typeCode === TimetableTypeCodeEnum.STOCK && value === 0) return undefined;

        return this.accessibilityStateCodeColorMap[typeCode];
    };
}

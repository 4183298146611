import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../components/select/EntityRemoteSelect';
import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum } from '../../../../localization';
import { paymentsUtils } from '../utils/paymentsUtils';
import { onlyDigitsInputNumber } from '../../../../components/inputNumber/inputNumber3';
import { SliderRangeWithManualInput } from '../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { createHiddenField } from '../../../../components/modalForm/utils';
import { PaymentMethodSelect } from '../../../../components/v2/select/paymentMethodSelect/paymentMethodSelect';

export const paymentsFilters: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('authorId'),
            createHiddenField('screenLeft'),
            {
                label: 'Контрагент',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'counterpartyId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    getEntityOperationName: 'getRenterById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                }),
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ projectId: undefined, subrentId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'stateCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: paymentsUtils.getAllStateCodes().map((code) => ({
                    name: (
                        <>
                            <div className={`rr-dot rr-status-bg-payment-` + code} style={{ marginRight: '10px' }}></div>
                            {paymentsUtils.getPaymentStateByCode(code)}
                        </>
                    ),
                    value: code,
                })),
            },
            {
                label: 'Скрыть удаленные',
                id: 'hideDeleted',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils, customParams?: { [k: string]: any }) => {
                    let renterId =
                        form.getFieldValue('renterId') && form.getFieldValue('renterId').key
                            ? form.getFieldValue('renterId').key
                            : undefined;
                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: renterId && ['renterId;EQ;' + renterId],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ subrentId: undefined });
                },
            },
            {
                label: 'Поставка',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'subrentId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils, customParams?: { [k: string]: any }) => {
                    let renterId =
                        form.getFieldValue('renterId') && form.getFieldValue('renterId').key
                            ? form.getFieldValue('renterId').key
                            : undefined;
                    return {
                        operationName: 'listSubrents',
                        nameField: 'shortName',
                        getEntityOperationName: 'getSubrentById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: renterId && ['supplierId;EQ;' + renterId],
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ projectId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__SUM,
                id: 'amount',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                },
            },
            {
                label: 'Номер',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'number',
                type: FormItemType.Component,
                componentProps: () => {
                    return {
                        style: { width: '120px' },
                        autoComplete: 'off',
                    };
                },
                component: onlyDigitsInputNumber,
            },
            {
                label: 'Источник',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'sourceCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                values: paymentsUtils.getAllSourceCodes().map((code) => ({
                    name: paymentsUtils.getPaymentSourceByCode(code),
                    value: code,
                })),
            },
            {
                label: 'Способ оплаты',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'paymentMethodId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: PaymentMethodSelect,
            },
        ],
    },
];

import React, {useEffect} from 'react';
import {inventoryMovementCreateModalFields} from "./inventoryMovementCreateModalFields";
import {ModalForm} from "../../../../components/v2/modalForm/modalForm";
import {LocalizationEnum, useLocalize} from "../../../../core/hooks/useLocalize";
import {useCreateInventoryMovementMutation} from "../api/inventoryMovementsApi";
import {useRouteMatch} from "react-router-dom";
import {InventoryMovementEventTypeCodeEnum, InventoryMovementInfoCreate} from "../../../../server";
import {RentElementsGridItem} from "../../../../types";
import {showNotification} from "../../../../components/notification/showNotification";
import {InventoryMovementIcon} from "../components/inventoryMovementIcon";
import {useServerError} from "../../../../core/hooks/useServerError";
import './inventoryMovementCreateModal.less';

interface InventoryMovementCreateModalProps {
    rentElement?: RentElementsGridItem;
    eventType: InventoryMovementEventTypeCodeEnum;
    onClose?: (success:boolean) => void;
}

interface InventoryMovementFormData {
    eventDate: Date;
    instanceIds?: number[];
    instanceCount?: number;
    outOfOrder?: boolean;
    causedByRenter?: boolean;
    files: File[];
    comment?: string;
}

export const InventoryMovementCreateModal = (props: InventoryMovementCreateModalProps) => {
    const L = useLocalize();
    const match = useRouteMatch<{ businessAccountId: string }>();
    const businessAccountId = +match.params.businessAccountId;
    const [createInventoryMovement, result] = useCreateInventoryMovementMutation();

    const initialValues: Partial<InventoryMovementInfoCreate> & {rentElement?: RentElementsGridItem, eventType?: InventoryMovementEventTypeCodeEnum} = {
        eventDate: props.rentElement?.rentTerms.rentPeriodEndDate,
        rentElement: props.rentElement,
        eventType: props.eventType
    };

    const onOk = (data: InventoryMovementFormData) => {
        if(props.rentElement?.productId){
            const inventoryMovementInfoCreate: InventoryMovementInfoCreate = {
                eventType: props.eventType,
                eventDate: data.eventDate,
                rentElementId: props.rentElement.id,
                productId: props.rentElement.productId,
                variantId: props.rentElement.variantId,
                projectId: props.rentElement.projectId,
                counterpartyId: props.rentElement.renterId,
                instanceIds: data.instanceIds,
                instanceCount: data.instanceIds?.length || data.instanceCount || 0,
                outOfOrder: data.outOfOrder,
                causedByRenter: data.causedByRenter,
                comment: data.comment
            };
            const attachmentInfoCreates = data.files;

            createInventoryMovement({
                businessAccountId,
                inventoryMovementInfoCreate,
                attachmentInfoCreates
            });
        }
    };

    const onCancel = () => {
        if (props.onClose) props.onClose(false);
    };

    // Обработка Ошибки/Успеха
    useEffect(() => {
        let message:string|undefined;
        if (result.data) {
            if(props.eventType === InventoryMovementEventTypeCodeEnum.DAMAGE) message = 'Повреждение зафиксировано';
            else if(props.eventType === InventoryMovementEventTypeCodeEnum.LOSS) message = 'Потеря зафиксирована';
            showNotification('success', message);
            if (props.onClose) props.onClose(true);
        } else if (result.error) {
            if(props.eventType === InventoryMovementEventTypeCodeEnum.DAMAGE) message = 'Повреждение не зафиксировано';
            else if(props.eventType === InventoryMovementEventTypeCodeEnum.LOSS) message = 'Потеря не зафиксирована';
            showNotification('error', message);
        }
    }, [result.data, result.error, props]);

    let title:string|undefined;
    if(props.eventType === InventoryMovementEventTypeCodeEnum.DAMAGE) title = 'Фиксация повреждения на проекте';
    else if(props.eventType === InventoryMovementEventTypeCodeEnum.LOSS) title = 'Фиксация потери на проекте';

    const serverError = useServerError(result.error);
    const error = serverError;

    return (
        <ModalForm
            title={title}
            titleIcon={<InventoryMovementIcon code={props.eventType} />}
            okButtonText={L(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            formFields={inventoryMovementCreateModalFields}
            onCancel={onCancel}
            onOk={onOk as any}
            initialValues={initialValues}
            error={error}
            cancelPreventionMessage={L(LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)}
            isFetching={result.isLoading}
        />
    );
};

import React, { FC } from 'react';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { getRentElementStateByCode, LocalizationEnum, localize } from '../../../../../../../../localization';
import { RentStateCodeEnum } from '../../../../../../../../server';
import { ElementsCalendarItem } from '../../../../../components/Calendar/types/items';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';

export const StatusGroupLine: FC<GroupItemProps<ElementsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { record } = groupItem;
    const {
        commonFields: { stateCode },
    } = record;

    const uniqueTitles = {
        [RentStateCodeEnum.DRAFT]: localize(LocalizationEnum.PAGE__CALENDAR__TAB_LABEL_DRAFTS),
        [RentStateCodeEnum.MIXED]: 'Разный (только наборы)',
    };

    const title = uniqueTitles[stateCode] ?? getRentElementStateByCode(stateCode);

    const classStatusBg = 'rr-status-bg-' + stateCode;

    return title ? (
        <GroupLine
            IconComponent={<div className={`rr-dot ${classStatusBg}`}></div>}
            displayType={displayType}
            title={{
                text: title,
            }}
        />
    ) : null;
};

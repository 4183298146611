import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PaymentMethodInfoRead, PaymentMethodInfoUpdate } from '../../../../../../server';
import { ModalActionData } from '../../../../../../components/modalForm/utils';
import { connect } from 'react-redux';
import { paymentMethodModalFormFields } from '../paymentMethodModalFormFields';
import { showConfirm } from '../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { goBack } from 'connected-react-router';
import { updateModalFilters } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { clearPaymentMethod, updatePaymentMethod } from '../../reducers/paymentMethod/paymentMethod.reducer';
import { PaymentMethodModalFormData } from '../paymentMethodModalFormFields.types';
import { getStore, RootState } from '../../../../../../../index';

interface PaymentMethodEditModalComponentProps extends ModalFormNEWProps, WrappedComponentProps {
    entity: PaymentMethodInfoRead;
}

export class PaymentMethodEditModalComponent extends ModalFormNEW<PaymentMethodEditModalComponentProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: paymentMethodModalFormFields,
    };

    getInitialValues = () => {
        const { entity } = this.props;

        let initialValues: Partial<PaymentMethodModalFormData> = {
            id: entity.id,
            name: entity.name,
            isDefault: entity.isDefault,
            comment: entity.comment,
        };

        return initialValues;
    };

    onOk = async (data: unknown) => {
        const formData = data as PaymentMethodModalFormData;

        const infoUpdate: PaymentMethodInfoUpdate = {
            id: formData.id,
            name: formData.name,
            isDefault: formData.isDefault,
            comment: formData.comment,
            businessVersion: this.props.entity.businessVersion,
        };

        getStore()
            .dispatch(
                updatePaymentMethod({
                    businessAccountId: this.props.entity.businessAccountId!,
                    id: this.props.entity.id!,
                    infoUpdate,
                })
            )
            .then(() => {
                this.props.goBack();
            });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            //this.props.clearPaymentMethod();
        }
    };
}

const mapStateToProps = (storeState: RootState) => {
    return {
        updating: storeState.paymentMethod.updating,
        updatingError: storeState.paymentMethod.updatingError,
        modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
    };
};

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    clearPaymentMethod,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const PaymentMethodEditModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentMethodEditModalComponent));

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {isDefined} from "../../../shared/util/utils";

export const tblColumnsBoolean = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.BOOLEAN) {
        return {
            title: column.title,
            dataIndex: column.dataIndex,
            type: ColumnTypes.Boolean,
            resizable: false,
            minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
        };
    } else {
        throw Error('error');
    }
};

import React from 'react';
import { EntityDrawer } from '../../../../../../../../components/v2/entityDrawer/EntityDrawer';
import { useGetContactByIdQuery } from '../../../api/contactsApi';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { ContactInfoRead } from '../../../../../../../../server';
import './ContactDrawer.less';
import { ContactDrawerHeader } from '../ContactDrawerHeader/ContactDrawerHeader';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { ContactDataDisplayBlocks } from '../ContactDataDisplayBlocks/ContactDataDisplayBlocks';

export interface ContactDrawerProps {
    contactId: number;
    onClose: () => void;
    refetchList?: () => void;
    editContact?: (contact: ContactInfoRead) => void;
    deleteContact?: (contact: ContactInfoRead) => Promise<void>;
    archiveContact?: (contact: ContactInfoRead) => void;
    isEntityActionsLoading?: boolean;
}

export const ContactDrawer: React.FC<ContactDrawerProps> = ({
    contactId,
    onClose,
    refetchList,
    editContact,
    deleteContact,
    archiveContact,
    isEntityActionsLoading,
}) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const {
        data: contact,
        error,
        isFetching,
        isLoading: isEntityLoading,
        refetch: refetchContact,
    } = useGetContactByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: contactId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const isLoading = Boolean(isEntityLoading || isEntityActionsLoading);

    return (
        <EntityDrawer
            title={
                <ContactDrawerHeader
                    contact={contact}
                    onClose={onClose}
                    editContact={editContact}
                    deleteContact={deleteContact}
                    archiveContact={archiveContact}
                    isLoading={isLoading || isFetching}
                />
            }
            onClose={onClose}
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
        >
            {contact && <ContactDataDisplayBlocks contact={contact} refetchContact={refetchContact} refetchList={refetchList} />}
        </EntityDrawer>
    );
};

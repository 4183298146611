import {getIsCorrectCancelOperation, getIsElementReducesAvailability} from "./operationUtils";
import {InstanceTrackingTypeCodeEnum, OperationTypeCodeEnum, RentStateCodeEnum} from "../../../../server";
import {isOrderOperation} from "./utils";
import {ProductUtils} from "../../../../shared/util/productUtils";
import {OperationElement} from "../reducers/operationForm.reducer";

// Карта экземпляра: Для нового - вычетаем
const instanceIntervalsForNewElement_subtract = (intervals:[number, number][], availabilityFactor: number, element:OperationElement):[number, number][] => {
    let newIntervals = ProductUtils.getNewIntervals(
        intervals,
        [[availabilityFactor * 1, element.rentPeriodStartDate.getTime(), element.rentPeriodEndDate.getTime()]]
    );
    return newIntervals;
};

// Карта экземпляра: Для существующего - компенсируем
const instanceIntervalsForExistedElement_compensate = (intervals:[number, number][], availabilityFactor: number, element:OperationElement, stateTypeCode:OperationTypeCodeEnum|undefined, stateTargetStateCode:RentStateCodeEnum|undefined, elementsDelayedReturnDates:{ [key: number]: Date }):[number, number][] => {
    let newIntervals = intervals;
    let _isOrderOperation = isOrderOperation(stateTypeCode);
    if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
        let isElementReducesAvailability = getIsElementReducesAvailability(element);
        if (
            isElementReducesAvailability ||
            (element.stateCode === RentStateCodeEnum.ORDERED && _isOrderOperation)
        ) {
            let endDate = element.rentPeriodEndDateOriginal.getTime();
            if (element.stateCode === RentStateCodeEnum.RENT && elementsDelayedReturnDates[element.id]) {
                endDate = elementsDelayedReturnDates[element.id].getTime();
            }
            newIntervals = ProductUtils.getNewIntervals(
                intervals,
                [[availabilityFactor * -1, element.rentPeriodStartDateOriginal.getTime(), endDate]]
            );
        }
    }
    return newIntervals;
};

// Карта экземпляра: Для существующего - вычетаем что сейчас
const instanceIntervalsForExistedElement_subtract = (intervals:[number, number][], availabilityFactor: number, element:OperationElement, stateTypeCode:OperationTypeCodeEnum|undefined, stateTargetStateCode:RentStateCodeEnum|undefined):[number, number][] => {
    let newIntervals = intervals;
    let isCorrectCancelOperation = getIsCorrectCancelOperation(stateTypeCode, stateTargetStateCode);
    newIntervals = ProductUtils.getNewIntervals(
        newIntervals,
        [
            [
                availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * 1,
                element.rentPeriodStartDate.getTime(),
                element.rentPeriodEndDate.getTime(),
            ],
        ]
    );
    return newIntervals;
}

// Карта экземпляра: Для существующего - вычетаем остаток
const instanceIntervalsForExistedElement_subtract_leftover = (intervals:[number, number][], availabilityFactor: number, element:OperationElement) => {
    let newIntervals = intervals;
    if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
        newIntervals = ProductUtils.getNewIntervals(
            newIntervals,
            [
                [
                    availabilityFactor * 1,
                    element.rentPeriodStartDateOriginal.getTime(),
                    element.rentPeriodEndDateOriginal.getTime(),
                ],
            ]
        );
    }
    return newIntervals;
};

export const instanceTimetableToNormalState = (intervals:[number, number][], instanceId:number, elements:OperationElement[], isSubrent:boolean, stateTypeCode:OperationTypeCodeEnum|undefined, stateTargetStateCode:RentStateCodeEnum|undefined, elementsDelayedReturnDates:{ [key: number]: Date }) => {
    let availabilityFactor = isSubrent ? -1 : 1;
    let newIntervals = intervals;

    elements.forEach((element)=>{
        if (
            element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
        ) {
            let isElementReducesAvailability = getIsElementReducesAvailability(element);
            if (element.id < 0) {
                element.instanceIds?.forEach((id) => {
                    if(instanceId === id) newIntervals = instanceIntervalsForNewElement_subtract(newIntervals, availabilityFactor, element);
                });
            }else{
                if (element.variantId === element.variantIdOriginal) {
                    element.instanceIdsOriginal?.forEach((id) => {
                        if(instanceId === id) newIntervals = instanceIntervalsForExistedElement_compensate(newIntervals, availabilityFactor, element, stateTypeCode, stateTargetStateCode, elementsDelayedReturnDates);
                    });
                }

                if (element.isCancelled !== true) {
                    // Вычетаем что сейчас
                    element.instanceIds?.forEach((id) => {
                        if(instanceId === id) newIntervals = instanceIntervalsForExistedElement_subtract(newIntervals, availabilityFactor, element, stateTypeCode, stateTargetStateCode);
                    });

                    // #2112 [3.В правке проекта / поставки не работает уменьшение числа и именованного экземпляра
                    // В правке всегда остаток отключен, а сейчас считается как буд-то будет оставаться остаток]
                    if(element.keepLeftover){
                        // Вычетаем остаток
                        let ost: number[] = [];
                        element.instanceIdsOriginal?.forEach((id) => {
                            if (element.instanceIds && !element.instanceIds.includes(id)) {
                                ost.push(id);
                            }
                        });
                        if (isElementReducesAvailability && element.variantId === element.variantIdOriginal) {
                            ost.forEach((id) => {
                                if(instanceId === id) newIntervals = instanceIntervalsForExistedElement_subtract_leftover(newIntervals, availabilityFactor, element);
                            });
                        }
                    }
                }
            }
        }
    });
    return newIntervals;
};

import {SummaryScheduleDataNomenclature, SummaryScheduleDataInstance} from "../api/summaryScheduleCalendarApi";
import {SummaryScheduleCalendarSortValue} from "./sortData";
import {CalendarDisplayType} from "../../../components/Calendar/utils/data/calendarConstants";
import {CalendarLineUtils} from "../../../components/CalendarItemRenderer/utils/calendarLineUtils";
import {AccessibilityMapCalendarGroup} from "../hooks/useCalendarGroups1";
import {getAccessibilityMapCalendarDataFromBase64} from "../../accessibilityMap/utils/getAccessibilityMapCalendarDataFromBase64";
import {TimetableTypeCodeEnum} from "../../../../../../server";
import moment from "moment";
import {AccessibilityMapCalendarItem, CalendarItem} from "../../../components/Calendar/types/items";

interface processCalendarDataParams {
    data: SummaryScheduleDataNomenclature[];
    sortBy?: SummaryScheduleCalendarSortValue;
    includeRentElements?: boolean;
    displayType: CalendarDisplayType;
    screenLeft: number;
    screenRight: number;
    collapsed: string[];
}

export const processCalendarData = (params: processCalendarDataParams) => {
    let groupId = 0;
    const groups: AccessibilityMapCalendarGroup[] = [];
    const items: CalendarItem<AccessibilityMapCalendarItem>[] = [];
    const groupHeight = CalendarLineUtils.map.groupHeightFromDisplayType.medium[params.displayType];

    params.data.forEach((item, index) => {
        const key = getNomenclatureKey(item);
        const collapsed = !params.collapsed.includes(key);
        groups.push(itemToGroup(item, groupId, groupHeight, collapsed));
        availableMapToItem(item.availableMap, groupId, key, params.screenLeft, params.screenRight).forEach(item => {
            items.push(item);
        });

        if(params.includeRentElements){
            if(item.rentElements && !item.instanceTracked){
                groupId += 1;
                groups.push(groupForBulk(groupId, groupHeight));
                item.rentElements.forEach((rentElement)=>{

                });
            }
        }

        if (item.instances) {
            item.instances.forEach((instanceItem) => {
                groupId += 1;
                if(!collapsed){
                    groups.push(itemToGroup(instanceItem, groupId, groupHeight, false));
                    if(!params.includeRentElements){
                        availableMapToItem(instanceItem.availableMap || '', groupId, getNomenclatureKey(instanceItem), params.screenLeft, params.screenRight).forEach(item => {
                            items.push(item);
                        });
                    }else{
                        instanceItem.rentElements?.forEach((rentElement)=>{

                        });
                    }
                }
            })
        }
        groupId += 1;
    });

    return {
        groups,
        items
    };
};

const itemToGroup = (item: SummaryScheduleDataNomenclature | SummaryScheduleDataInstance, groupId: number, groupHeight: number, collapsed: boolean): AccessibilityMapCalendarGroup => {
    if ('id' in item) {
        return {
            id: groupId,
            title: '',
            height: groupHeight,
            productId: item.productId,
            variantId: item.variantId,
            instanceId: item.id,
            instanceName: item.name,
            collapsible: false,
            collapsed: false
        };
    } else {
        return {
            id: groupId,
            title: '',
            height: groupHeight,
            productId: item.productId,
            kitId: item.kitId,
            variantId: item.variantId,
            productName: item.productName,
            variantName: item.variantName,
            kitName: item.kitName,
            collapsible: Boolean(item.instances && item.instances.length > 0),
            collapsed
        };
    }
}

const groupForBulk = (groupId: number, groupHeight: number): AccessibilityMapCalendarGroup => {
    return {
        id: groupId,
        title: '',
        height: groupHeight,
        collapsible: false,
        collapsed: false
    };
}

const availableMapToItem = (map: string, groupId: number, nomenclatureKey: string, screenLeft: number, screenRight: number): CalendarItem<AccessibilityMapCalendarItem>[] => {
    const data: any[] = [];
    const itemsData = getAccessibilityMapCalendarDataFromBase64([{
        typeCode: TimetableTypeCodeEnum.AVAILABLE,
        mapString: map
    }]);
    itemsData.forEach((itemData) => {
        const {typeCode, value, startTime, endTime} = itemData;

        if ((startTime < screenLeft && endTime < screenLeft) || (startTime > screenRight && endTime > screenRight)) return;

        data.push({
            id: `${nomenclatureKey}_${value}_${endTime}`,
            elementType: 'accessibility',
            group: groupId,
            typeCode,
            value: Number(value),
            startTime: +startTime,
            endTime: +endTime,
            start_time: moment(Math.max(+startTime, screenLeft || 0)),
            end_time: moment(Math.min(+endTime, screenRight || 0)),
        });
    });
    return data;
}

export const getNomenclatureKey = (item: SummaryScheduleDataNomenclature | SummaryScheduleDataInstance): string => {
    let id = '';
    if ('id' in item) {
        id = 'I' + item.id;
    } else {
        if (item.kitId) id = 'K' + item.kitId;
        else if (item.variantId) id = 'V' + item.variantId;
        else if (item.productId) id = 'P' + item.productId;
    }
    return id;
}

interface PPP {
    productId?: number;
    variantId?: number;
    kitId?: number;
    instanceId?: number;
}

export const getNomenclatureKey11 = (item: PPP): string => {
    let id = '';
    if (item.instanceId) id = 'I' + item.instanceId;
    if (item.kitId) id = 'K' + item.kitId;
    else if (item.variantId) id = 'V' + item.variantId;
    else if (item.productId) id = 'P' + item.productId;
    return id;
}

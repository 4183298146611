import {useAppSelector} from '../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../shared/reducers/system.reducer';
import {ExpenseCreateModal} from './expenseCreateModal';
import {LocalizationEnum, localize} from '../../../../../localization';
import React, {FunctionComponent, memo, useMemo} from 'react';
import {ExpenseInfoRead, ExpenseStateCodeEnum, ProjectInfoRead, ProjectStateCodeEnum, ProjectTypeCodeEnum} from '../../../../../server';
import {ExpenseCreateModalFormData} from './expenseCreateModal.types';
import {ModalFormNEWProps} from '../../../../../components/modalForm/ModalFormNEW';
import {EntityGridRootPath} from '../../../../../components/page/entityGrid/types/params';

export type CreateModalWrapperProps<EntityInfoRead extends object> = {
    rootPath: EntityGridRootPath;
    urlSearchParams: URLSearchParams;
    entity?: EntityInfoRead;
    parentProjectEntity?: ProjectInfoRead;
} & Partial<ModalFormNEWProps>;

type CreateModalWrapper<EntityInfoRead extends object> = FunctionComponent<CreateModalWrapperProps<EntityInfoRead>>;

export const ExpenseCreateModalWrapper: CreateModalWrapper<ExpenseInfoRead> = memo(
    ({ rootPath, urlSearchParams, entity, parentProjectEntity, ...modalProps }) => {
        const businessAccountId = useAppSelector(businessAccountIdSelector);

        const initialValues: ExpenseCreateModalFormData | {} = useMemo(() => {

            let projectId:ExpenseCreateModalFormData['projectId'];
            let projectStateCode:ExpenseCreateModalFormData['projectStateCode'];
            let projectType:ExpenseCreateModalFormData['projectType'];
            let state:ExpenseCreateModalFormData['state'] = ExpenseStateCodeEnum.TAKENINTOACCOUNT;

            if(parentProjectEntity){
                projectId = parentProjectEntity.id;
                projectStateCode = parentProjectEntity.stateCode;
                projectType = parentProjectEntity.projectType;
            }else if(entity){
                projectId = entity.projectId;
            }

            if(projectStateCode === ProjectStateCodeEnum.DRAFT || projectType === ProjectTypeCodeEnum.OFFER){
                state = ExpenseStateCodeEnum.DRAFT;
            }

            if (entity == null){
                return {
                    projectId,
                    projectStateCode,
                    projectType,
                    state
                };
            }

            return {
                id: undefined,
                name: entity.name,
                internalFinalTotalPrice: entity.internalFinalTotalPrice,
                externalFinalTotalPrice: entity.externalFinalTotalPrice,
                comment: entity.comment,
                projectId,
                projectStateCode,
                projectType,
                state
            };
        }, [entity, modalProps]);

        return (
            <ExpenseCreateModal
                {...(modalProps ?? {})}
                initialValues={initialValues}
                businessAccountId={businessAccountId}
                title={'Создание новой затраты'}
                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                rootPath={rootPath}
                urlSearchParams={urlSearchParams}
                validateAfterCreate={entity ? true : false}
            />
        );
    }
);

import React from 'react';
import {Popover} from "../../../../../components/popover/Popover";
import {OperationElement} from "../../reducers/operationForm.reducer";
import {KitAddElementPopoverContent} from "./KitAddElementPopoverContent";
import './KitAddElementPopover.less'

interface IProps {
    element: OperationElement;
    //children: () => any;
}

interface IState {
    visible: boolean;
}

export class KitAddElementPopover extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    handleVisibleChange = (visible: boolean) => {
        this.setState({visible});
    };

    render() {
        return (
            <Popover
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                destroyTooltipOnHide
                overlayClassName={'rr-operationForm-kit-add-element-popover'}
                content2={
                    <KitAddElementPopoverContent element={this.props.element} onClose={() => {
                        this.setState({visible: false});
                    }} />
                }>
                {this.props.children}
            </Popover>
        );
    }
}

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {GridColumnCreator} from "../../../components/grid/gridColumnCreator";
import {TableColumn} from "../../../components/grid/Table";

export const tblColumnsClick = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.CLICK) {
        return GridColumnCreator.createLocationColumn<Record>({
            title: column.title,
            dataIndex: column.dataIndex
        });
    } else {
        throw Error('error');
    }
};

import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../core/utils/formUtils';
import { ListCrewMembersParams } from '../api/types';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { CrewMemberStateCodeEnum } from '../../../../../../server';
import React from 'react';
import { ProfessionSelect } from '../../../../../../components/v2/select/professionSelect/ProfessionSelect';
import { SliderRangeWithManualInput } from '../../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { getStoreState } from '../../../../../../../index';

export const crewMembersFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListCrewMembersParams>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'stateCode',
                type: FormItemType.Select,
                className: 'rr-select-gray',
                style: { minWidth: 120 },
                values: [
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE), value: CrewMemberStateCodeEnum.ACTIVE },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__USER__BLOCKED), value: CrewMemberStateCodeEnum.BLOCKED },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>{' '}
                                <span className="calendar-multi-select">{item.name}</span>{' '}
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: 'Специальность',
                placeholder: 'Любая',
                id: 'professionId',
                type: FormItemType.Component,
                component: ProfessionSelect,
                className: 'rr-select-gray',
                style: { minWidth: 120, maxWidth: 360 },
                componentProps: {
                    multiple: true,
                    getPopupContainer: () => document.body,
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Ставка за смену',
                id: 'ratePerShift',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                visible: () => canViewFinancialDataSelector(getStoreState()),
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 20,
                    step: 1,
                },
            },
            {
                label: 'Ставка за час',
                id: 'ratePerHour',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                visible: () => canViewFinancialDataSelector(getStoreState()),
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 20,
                    step: 1,
                },
            },
        ],
    },
]);

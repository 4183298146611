import { ActionButtonsData } from '../../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { Icon } from 'antd';
import { IconCheck, IconClose } from '../../../../../components/icons';
import React from 'react';
import { Color } from '../../../../../core/types/color';
import { BasicActionsData } from '../../../../../components/v2/actionButtons/actionButton/actionButton';
import { PaymentMethodRequirementTypeCodeEnum } from '../../../../../server';

export enum PaymentMethodActionType {
    edit = 'edit',
    delete = 'delete',
    setAsDefaultMethod = 'setAsDefaultMethod',
    unsetAsDefaultMethod = 'unsetAsDefaultMethod',
    toArchive = 'toArchive',
    returnFromArchive = 'returnFromArchive',
}

export const paymentMethodActionsMap: ActionButtonsData<PaymentMethodActionType> = {
    [PaymentMethodActionType.edit]: BasicActionsData.editAction,
    [PaymentMethodActionType.delete]: BasicActionsData.deleteAction,
    [PaymentMethodActionType.setAsDefaultMethod]: {
        name: 'Сделать способом по умолчанию',
        icon: <Icon component={IconCheck} />,
        color: Color.Green,
    },
    [PaymentMethodActionType.unsetAsDefaultMethod]: {
        name: 'Убрать маркер значения по умолчанию',
        icon: <Icon component={IconClose} />,
        color: Color.Red1,
    },
    [PaymentMethodActionType.toArchive]: BasicActionsData.toArchiveAction,
    [PaymentMethodActionType.returnFromArchive]: BasicActionsData.fromArchiveAction,
};

export const paymentMethodRequirementTypeNamesMap: Record<PaymentMethodRequirementTypeCodeEnum, string> = {
    [PaymentMethodRequirementTypeCodeEnum.OPTIONAL]: 'Необязательно',
    [PaymentMethodRequirementTypeCodeEnum.REQUIRED]: 'Обязательно',
    [PaymentMethodRequirementTypeCodeEnum.REQUIREDINSTATUSDONE]: 'Обязательно в статусе "Проведен"',
};

export const paymentMethodsRequirementAllTypes = [
    PaymentMethodRequirementTypeCodeEnum.OPTIONAL,
    PaymentMethodRequirementTypeCodeEnum.REQUIRED,
    PaymentMethodRequirementTypeCodeEnum.REQUIREDINSTATUSDONE,
];

import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getServerError } from '../../../../../../../shared/util/utils';
import { serverApi } from '../../../../../../../server';
import { AppDispatch, GetState } from '../../../../../../../../index';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { goBack } from 'connected-react-router';
import { contactsApi } from '../../api/contactsApi';
import { AxiosError } from 'axios';
import { ContactCreateArgs, ContactState, ContactUpdateArgs } from './types';
import { IRootState } from '../../../../../../../shared/reducers';
import { loadCrewMember } from '../../../../../crew/tabs/crewMembers/reducers/crewMember/crewMember.reducer';

export const ACTION_TYPES = {
    LOAD_CONTACT: 'contact/LOAD_CONTACT',
    CREATE_CONTACT: 'contact/CREATE_CONTACT',
    UPDATE_CONTACT: 'contact/UPDATE_CONTACT',
    DELETE_CONTACT: 'contact/DELETE_CONTACT',
};

const initialState: ContactState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

export const createContactThunk = createAsyncThunk(ACTION_TYPES.CREATE_CONTACT, async (args: ContactCreateArgs, { rejectWithValue }) => {
    const { businessAccountId, contactInfoCreate } = args;

    try {
        return await serverApi.createContact(businessAccountId, contactInfoCreate);
    } catch (error: unknown) {
        throw rejectWithValue(error as AxiosError);
    }
});

export const createContact = (args: ContactCreateArgs) => (dispatch: AppDispatch) => {
    return dispatch(createContactThunk(args))
        .unwrap()
        .then((result) => {
            if (result.status === 201) {
                showNotification('success', 'Контакт создан');
            }

            dispatch(contactsApi.util?.invalidateTags(['ContactsList']));
            return result.data;
        })
        .catch((error) => {
            showNotification('error', 'Не удалось создать контакт');
        });
};

const updateContactThunk = createAsyncThunk(ACTION_TYPES.UPDATE_CONTACT, async (args: ContactUpdateArgs, { rejectWithValue }) => {
    const { businessAccountId, contactId, contactInfoUpdate } = args;

    try {
        return await serverApi.updateContactById(businessAccountId, contactId, contactInfoUpdate);
    } catch (error: unknown) {
        throw rejectWithValue(error as AxiosError);
    }
});
export const updateContact = (args: ContactUpdateArgs) => (dispatch: AppDispatch, getState: GetState) => {
    return dispatch(updateContactThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Контакт успешно изменен');
            dispatch(goBack());
            dispatch(contactsApi.util?.invalidateTags(['ContactsList', 'Contact']));

            const state: IRootState = getState();
            if (state.crewMember.entity && state.crewMember.entity.contact.id === args.contactId) {
                dispatch(
                    loadCrewMember({
                        businessAccountId: args.businessAccountId,
                        entityId: state.crewMember.entity.id,
                    })
                );
            }
        })
        .catch((error) => {
            showNotification('error', 'Не удалось изменить контакт');
        });
};

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        clearContact: (state) => {
            state.entity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(contactsApi.endpoints.getContactById.matchPending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addMatcher(contactsApi.endpoints.getContactById.matchFulfilled, (state, { payload }) => {
                state.loading = false;
                state.entity = payload;
            })
            .addMatcher(contactsApi.endpoints.getContactById.matchRejected, (state, { payload }) => {
                state.loading = false;
                state.loadingError = getServerError(payload);
            })

            .addMatcher(isAnyOf(createContactThunk.pending, updateContactThunk.pending), (state) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(createContactThunk.fulfilled, updateContactThunk.fulfilled), (state) => {
                state.updating = false;
            })
            .addMatcher(isAnyOf(createContactThunk.rejected, updateContactThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = getServerError(action.payload);
            });
    },
});

export const { clearContact } = contactSlice.actions;
export default contactSlice.reducer;

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { matchPath } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { settingsRoutes } from './settingsRoutes';
import './settingsPage.less';
import { IRouterItem } from '../../../config/routes';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../config/constants';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { serverApi, SimpleTreeEntityRecordList } from '../../../server';
import { AxiosResponse } from 'axios';
import SystemOptionsModule from './systemOptions/systemOptionsModule';
import NotFoundModule from './notFound/notFoundModule';
import { Spin } from '../../../components';
import { LocalizationEnum } from '../../../localization';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import SettingsMenu from '../../../components/settingsMenu/settings-menu';
import intersection from 'lodash/intersection';
import { getCurrentTabsSelector, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { replace } from 'connected-react-router';
import { getPathFromState } from '../../../shared/util/utils';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

interface IState {
    settingsRoutes?: Array<IRouterItem>;
    collapsedMenuItems: Array<string>;
    settingsIsLoading: boolean;
}

const settingsPageUrlRoute = 'settings';

class settingsPage extends BasePage<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            collapsedMenuItems: [],
            settingsIsLoading: true,
        };
        this.props.resetOperationForm();
    }

    updateMenuPosition = (e) => {
        let el = document.querySelector('.rr-page-settings__menu .ant-menu') as HTMLElement;
        if (el) {
            if (el.parentElement) el.style.width = el.parentElement.offsetWidth + 'px';
            el.style.top = (window.scrollY > HEADER_HEIGHT ? 0 : HEADER_HEIGHT - window.scrollY) + 'px';
            el.style.height =
                window.innerHeight -
                (HEADER_HEIGHT + FOOTER_HEIGHT) +
                Math.min(HEADER_HEIGHT, window.scrollY) +
                Math.min(FOOTER_HEIGHT, document.body.scrollHeight - window.scrollY - window.innerHeight) +
                'px';
        }
    };

    componentDidMount = async () => {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${settingsPageUrlRoute}`);

        const urlChunks = this.props.location.pathname.split('/').filter(Boolean);
        const currentTab = urlChunks[2];

if (currentTab == null) {
            if (this.props.tabFromStore && this.props.tabFromStore !== currentTab) {
                urlChunks.splice(2, 1, this.props.tabFromStore);
                const path = `/${getPathFromState(urlChunks.join('/'), this.props.location.search)}`;
                this.props.replace(path);
            } else {
                const path = `/${getPathFromState(`${urlChunks.join('/')}/basic`, this.props.location.search)}`;
                this.props.replace(path);
            }
        }

        let res: AxiosResponse<SimpleTreeEntityRecordList>;
        try {
            res = await serverApi.getSystemOptionsTree(this.props.businessAccountId);

            let settingsRoutesNew: IRouterItem[] = [];
            settingsRoutes.forEach((route) => {
                if (
                    !route.permissions ||
                    route.permissions.length === 0 ||
                    (route.permissions &&
                        route.permissions.length > 0 &&
                        intersection(route.permissions, this.props.permissions).length > 0)
                ) {
                    settingsRoutesNew.push(route);
                }
            });

            res.data.records.forEach((record) => {
                let settingsItem: IRouterItem = {
                    menuKey: record.code,
                    title: (record.title as LocalizationEnum) || '',
                    path: `/:businessAccountId/settings/${record.code}`,
                    component: SystemOptionsModule,
                    sortOrder: record.id,
                    childs: record.children?.map((child) => {
                        return {
                            menuKey: child.code,
                            title: (child.title as LocalizationEnum) || '',
                            path: `/:businessAccountId/settings/${record.code}#${child.code}`,
                            component: SystemOptionsModule,
                            sortOrder: 0,
                        };
                    }),
                };
                settingsRoutesNew.push(settingsItem);
            });

            settingsRoutesNew.push({
                menuKey: 'notfound',
                path: `/:businessAccountId/settings/`,
                component: NotFoundModule,
                sortOrder: 0,
            });

            await this.setState({ settingsRoutes: settingsRoutesNew, settingsIsLoading: false });

            window.addEventListener('scroll', this.updateMenuPosition);
            window.addEventListener('resize', this.updateMenuPosition);
            this.updateMenuPosition(null);

            setTimeout(() => {
                let { hash } = window.location;
                if (hash) {
                    hash = hash.replace('#', '');
                    let element = document.getElementById(hash);
                    if (element) element.scrollIntoView();
                }
            }, 300);
        } catch (e) {
            await this.setState({ settingsIsLoading: false });
        }
    };

    componentWillUnmount = () => {
        this.props.setBlockedUrl(undefined);
        window.removeEventListener('scroll', this.updateMenuPosition);
        window.removeEventListener('resize', this.updateMenuPosition);
    };

    getPageHeaderProps(): IPageHeaderProps {
        let pageTitle;
        settingsRoutes.find((item) => {
            let match = matchPath(this.props.location.pathname, item);
            if (match) {
                pageTitle = item.title;
                return item;
            }
            return null;
        });

        return {
            name: pageTitle ? this.props.intl.formatMessage({ id: pageTitle }) : undefined,
            showName: false,
        };
    }

    renderContent() {
        return (
            <div className={'rr-page-settings'}>
                <Spin spinning={this.state.settingsIsLoading}>
                    {
                        <div className={'rr-page-settings-container'}>
                            <SettingsMenu settingsRoutes={this.state.settingsRoutes} />

                            <div className={'rr-page-settings__content'}>
                                {this.state.settingsRoutes ? renderRoutes(this.state.settingsRoutes, { intl: this.props.intl }) : null}
                            </div>
                        </div>
                    }
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        permissions: storeState.permissions.permissions,
        tabFromStore: getCurrentTabsSelector(storeState)?.['settings'],
    };
};

const mapDispatchToProps = { resetOperationForm, replace, setBlockedUrl };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(settingsPage);

import React from 'react';
import { ColumnTypes } from '../Table';
import { tableCellDateRenderer } from '../renderers/tableCellDateRenderer';
import { tableCellDateTimeRenderer } from '../renderers/tableCellDateTimeRenderer';
import { TableCellAvatarRendererWrapper } from '../renderers/tableCellAvatarRendererWrapper';
import { tableCellMoneyRenderer } from '../renderers/tableCellMoneyRenderer';
import { tableCellActionsRenderer } from '../renderers/tableCellActionsRenderer';
import { tableCellStatusRenderer } from '../renderers/tableCellStatusRenderer';
import { tableCellOperationRenderer } from '../renderers/tableCellOperationRenderer';
import { tableCellTextRenderer } from '../renderers/tableCellTextRenderer';
import { tableCellBooleanRenderer } from '../renderers/tableCellBooleanRenderer';
import { tableCellProblemRenderer } from '../renderers/tableCellProblemRenderer';
import { tableCellPercentsRenderer } from '../renderers/tableCellPercentsRenderer';
import { tableCellPhoneRenderer } from '../renderers/tableCellPhoneRenderer';
import { tableCellStringRenderer } from '../renderers/tableCellStringRenderer';
import { tableCellClickRenderer } from '../renderers/tableCellClickRenderer';

export const renderer = (data) => {
    const columnType = data.cell.column.columnDef.columnType;
    const v = data.cell.getValue();
    const d = data.cell.row.original;
    const columnCustomRenderer = data.cell.column.columnDef.columnCustomRenderer;

    const onRowAction = data.cell.column.columnDef.onRowAction;
    const onRowEditAction = data.cell.column.columnDef.onRowEditAction;
    const entityType = data.cell.column.columnDef.entityType;
    const rowPopoverComponent = data.cell.column.columnDef.rowPopoverComponent;
    const rowPopoverComponentProps = data.cell.column.columnDef.rowPopoverComponentProps;

    let render;
    if (columnType === ColumnTypes.CustomRender && columnCustomRenderer)
        render = cellRenderer(
            rowPopoverComponent,
            rowPopoverComponentProps,
            columnCustomRenderer,
            v,
            d,
            onRowAction,
            entityType,
            onRowEditAction,
            true
        );
    else if (columnType === ColumnTypes.Date)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellDateRenderer, v, d);
    else if (columnType === ColumnTypes.DateTime)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellDateTimeRenderer, v, d);
    else if (columnType === ColumnTypes.Image)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, TableCellAvatarRendererWrapper, v, d);
    else if (columnType === ColumnTypes.Money)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellMoneyRenderer, v, d);
    else if (columnType === ColumnTypes.Actions)
        render = cellRenderer(
            rowPopoverComponent,
            rowPopoverComponentProps,
            tableCellActionsRenderer,
            v,
            d,
            onRowAction,
            entityType,
            onRowEditAction,
            false
        );
    else if (columnType === ColumnTypes.Status)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellStatusRenderer, v, d, entityType);
    else if (columnType === ColumnTypes.Operation)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellOperationRenderer, v, d);
    else if (columnType === ColumnTypes.Text)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellTextRenderer, v, d);
    else if (columnType === ColumnTypes.Boolean)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellBooleanRenderer, v, d);
    else if (columnType === ColumnTypes.Problem3)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellProblemRenderer, v, d, undefined, entityType);
    else if (columnType === ColumnTypes.Percents)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellPercentsRenderer, v, d);
    else if (columnType === ColumnTypes.Phone)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellPhoneRenderer, v, d);
    else if (columnType === ColumnTypes.Click)
        render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellClickRenderer, v, d, data.cell.column.columnDef);
    else render = cellRenderer(rowPopoverComponent, rowPopoverComponentProps, tableCellStringRenderer, v, d);
    return render;
};

export const cellRenderer = (
    rowPopoverComponent,
    rowPopoverComponentProps,
    renderer: Function,
    v,
    d,
    param1: any = undefined,
    param2: any = undefined,
    param3: any = undefined,
    withPopover: boolean = true
) => {
    return withPopover && rowPopoverComponent ? (
        React.createElement(
            rowPopoverComponent,
            {
                record: d,
                ...rowPopoverComponentProps,
            },
            () => <div>{renderer(v, d, param1, param2)}</div>
        )
    ) : (
        <div>
            <div>{renderer(v, d, param1, param2, param3)}</div>
        </div>
    );
};

export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const getColumnMaxWidth = (width: number | undefined) => {
    if (width === Number.MAX_SAFE_INTEGER) return undefined;
    else return width;
};

import { ModalProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { LocationInfoRead } from '../../../../../../../server';
import React, { FC } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { LocationCreateModal } from './LocationCreateModal';
import { createLocationModalFilters } from './LocationCreateModalData';

export interface LocationCreateModalNewProps extends ModalProps<LocationInfoRead> {}

export const LocationCreateModalNew: FC<LocationCreateModalNewProps> = (props) => {
    const businessAccountId = useAppSelector((store) => store.system.businessAccountId);
    return (
        <LocationCreateModal
            businessAccountId={businessAccountId}
            title={'Создание новой локации'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            filters={createLocationModalFilters}
            modalName={'create-location'}
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
        />
    );
};

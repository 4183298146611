import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { VehicleRecord, VehicleTypeCodeEnum } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { vehiclesApi } from '../../../../modules/main/logistics/tabs/vehicles/api/vehicles.api';

interface Filters {
    typeCode?: VehicleTypeCodeEnum | VehicleTypeCodeEnum[];
    archive?: boolean;
}

export interface VehicleSelectProps extends BaseSelectBaseProps<Record> {
    filters?: Filters;
}

type Record = VehicleRecord;
const sortBy: string = 'lastUpdateDate';
const sortOrder = 'DESC';

export const VehicleSelectFC = ({ filters, ...props }: VehicleSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);
    const [fetchVehicles] = vehiclesApi.useLazyListVehiclesQuery();

    const fetchRecordsById = async (id: BaseSelectValue) => {
        const _id = Array.isArray(id) ? id[0] : id;
        const response = await fetchVehicles(
            {
                businessAccountId,
                params: { limit: 1, search: undefined, page: 1, sortBy, sortOrder, id: +_id },
            },
            true
        );
        if (response.error) throw response.error;
        return response.data?.entitiesData.records ?? [];
    };

    const fetchRecords = async (startFrom: number, limit: number, search?: string) => {
        const response = await fetchVehicles(
            {
                businessAccountId,
                params: {
                    limit,
                    search,
                    page: startFrom / limit + 1,
                    sortBy,
                    sortOrder,
                    type: filters?.typeCode,
                    hideArchive: filters?.archive,
                },
            },
            true
        );
        if (response.error) throw response.error;
        return response.data?.entitiesData.records ?? [];
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={fetchRecordsById}
            fetchRecords={fetchRecords}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.shortName,
                children: record.shortName,
            })}
        />
    );
};

export class VehicleSelect extends React.Component<VehicleSelectProps> {
    render() {
        return <VehicleSelectFC {...this.props} />;
    }
}

import React from 'react';
import {CurrencyCodeEnum} from '../../server/api';
import {getCurrencySign} from '../../shared/util/getCurrencySign';

export const CurrentCurrency = () => {
    return (
        <CurrencyContext.Consumer>
            {
                (currencyCode:CurrencyCodeEnum) => getCurrencySign(currencyCode)
            }
        </CurrencyContext.Consumer>
    );
};

export const CurrencyContext = React.createContext(CurrencyCodeEnum.RUB);
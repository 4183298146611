import React, {FC} from 'react';
import {LocalizationEnum, localize} from "../../../../../localization";
import {ProjectInfoRead} from "../../../../../server";
import {createProjectModalFilters} from "./projectsPageData";
import {ProjectCreateModal} from "./project-create-modal";
import {ModalProps} from "../../../../../components/modalForm/ModalFormNEW";
import {useAppSelector} from "../../../../../store/hooks";

export interface ProjectCreateModalNewProps extends ModalProps<ProjectInfoRead> {
}

export const ProjectCreateModalNew: FC<ProjectCreateModalNewProps> = (props) => {
    const businessAccountId = useAppSelector((store) => store.system.businessAccountId);
    return (
        <ProjectCreateModal
            title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__CREATE)}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            businessAccountId={businessAccountId}
            filters={createProjectModalFilters}
            modalName={'create-project'}
            onCancel={props.onCancel}
            onSuccess={props.onSuccess}
        />
    );
};

import { useEffect } from 'react';

export const useMouseDownEffect = (eventFunction: (event: MouseEvent) => void) => {
    useEffect(() => {
        document.addEventListener('mousedown', eventFunction);
        return () => {
            document.removeEventListener('mousedown', eventFunction);
        };
    }, [eventFunction]);
};

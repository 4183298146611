import { initialParamsState } from '../../modules/main/payments/reducers/payments.reducer';

export const getPath = (ba: number | undefined, path: string, params?: { [key: string]: any }): string => {
    let res = (ba ? '/' + ba : '') + path;
    if (params) {
        let keys = Object.keys(params);
        res +=
            '?' +
            keys
                .map((k, index, arr) => {
                    return `${k}=${params[k]}`;
                })
                .join('&');
    }
    return res;
};

export namespace navigate {
    export const toUser = (ba: number, id: number | string): string => {
        return getPath(ba, `/users/${id}`);
    };

    export type PaymentsModuleParams = Partial<typeof initialParamsState>;

    export const toPayments = (ba: number, params?: PaymentsModuleParams): string => {
        return getPath(ba, `/history/payments/`, params);
    };

    export const toPaymentEdit = (ba: number, id: number | string, params?: PaymentsModuleParams): string => {
        return getPath(ba, `/history/payments/${id}/edit`, params);
    };

    export const toOperations = (ba: number, params?: {}): string => {
        return getPath(ba, `/history/operations`, params);
    };

    export const toOperation = (ba: number, id: number | string, params?: {}): string => {
        return getPath(ba, `/history/operations/${id}`, params);
    };

    export interface AnalyticsReportPageParams {
        counterPartyId?: number;
        projectId?: number;
        shippingId?: undefined;
    }

    export interface AnalyticsReportPageParams1 {
        counterPartyId?: number;
        shippingId?: number;
        projectId?: number;
        productId?: number;
        subrentId?: number;
    }

    export type AnalyticsReportPageParams0 = AnalyticsReportPageParams | AnalyticsReportPageParams1;

    export const toAnalyticsReport = (ba: number, id: number | string, params?: AnalyticsReportPageParams0): string => {
        return getPath(ba, `/reports/${id}`, params);
    };
}

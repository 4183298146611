import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { rulesList } from '../../../../config/rules_ru';
import { LocalizationEnum, localize } from '../../../../localization';
import { UserBARoleCodeEnum } from '../../../../server';
import { getStoreState } from '../../../../../index';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: 'E-Mail',
                id: 'mail',
                type: FormItemType.String,
                required: true,
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^.+@.+\\..{2,}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
                    },
                ],
            },
            {
                label: 'Роль пользователя',
                id: 'rules',
                type: FormItemType.Select,
                placeholder: 'Выберите роль',
                required: true,
                values: () => {
                    return rulesList.map((role) => {
                        let disabled = false;
                        if (role.value === UserBARoleCodeEnum.ADMIN || role.value === UserBARoleCodeEnum.AGENT) {
                            let managersCountLimit = getStoreState().businessAccount.entity?.managerRolesUserCounters?.userCountLimit || 0;
                            let managersCount = getStoreState().businessAccount.entity?.managerRolesUserCounters?.userCount || 0;
                            let managersActiveInvitationCount =
                                getStoreState().businessAccount.entity?.managerRolesUserCounters?.activeInvitationCount || 0;
                            if (managersCount + managersActiveInvitationCount >= managersCountLimit) {
                                disabled = true;
                            }
                        }
                        return { ...role, disabled: disabled };
                    });
                },
            },
        ],
    },
];

import {ColumnTypes, TableColumn} from "../../../../components/grid/Table";
import React from "react";
import {LocalizationEnum, localize} from "../../../../localization";
import {
    BusinessAccountPermissionCodeEnum,
    DocumentTemplateRecord,
    DocumentTypeCodeEnum,
    GlobalPermissionCodeEnum
} from "../../../../server/api";
import {Icon} from "antd";
import {getActionsColumnWidth, MAX_WIDTH} from "../../../../config/constants";
import {UploadDocumentTemplateFile} from "../../../../components/upload/uploadDocumentTemplateFile";
import {ActionItem, ActionsPopoverV2} from "../../../../components/v2/actionsPopover/ActionsPopoverV2";
import {DocumentTemplatesModuleUtils} from "./documentTemplatesModuleUtils";
import classNames from "classnames";
import {getStoreState} from "../../../../../index";
import {SystemIcon, SystemIconType} from "../../../../components/v2/systemIcon/systemIcon";
import {IconsGroup} from "../../../../components/v2/gridActionsContainer/IconsGroup";

// GRID COLUMNS
export const columns: TableColumn<DocumentTemplateRecord>[] = [
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ORIGIN),
        dataIndex: 'common',
        type: ColumnTypes.CustomRender,
        minWidth: 64,
        maxWidth: MAX_WIDTH(64),
        resizable: false,
        sortable: true,
        render: (value: any, rowData: DocumentTemplateRecord, rowAction, entityType) => {
            return <SystemIcon type={rowData.common ? SystemIconType.standard : SystemIconType.custom} style={{display: 'block'}}/>;
        }
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
        dataIndex: 'shortName',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        //maxWidth: MAX_WIDTH(170),
        resizable: true,
        render: (value: any, rowData: DocumentTemplateRecord) =>
            <div style={{paddingLeft: 35}}
                 className={classNames('rr-grid-shortName-cell', 'main-value')}
                 title={rowData.shortName}>
                {rowData.shortName}
            </div>,
        dontHide: true
    },
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE),
        dataIndex: 'documentType',
        type: ColumnTypes.CustomRender,
        minWidth: 280,
        //maxWidth: MAX_WIDTH(220),
        resizable: false,
        render: (value: any, rowData: DocumentTemplateRecord) => {
            return (
                <div style={{display: 'flex', alignItems: 'center', textAlign: 'left', paddingLeft: 35}}>
                    <Icon style={{
                        fontSize: 21,
                        marginRight: 10,
                        color: DocumentTemplatesModuleUtils.getDocumentTemplateIconColorByCode(rowData.documentType)
                    }}
                          component={DocumentTemplatesModuleUtils.getDocumentTemplateIconComponentByCode(rowData.documentType)}/>
                    {DocumentTemplatesModuleUtils.getDocumentTemplateTypeByCode(rowData.documentType)}
                </div>
            );
        }
    },
    {
        title: "Финансовый",
        dataIndex: "financial",
        type: ColumnTypes.CustomRender,
        width: 130,
        minWidth: 130,
        //maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: any, rowData: DocumentTemplateRecord) => {
            return rowData.financial && <SystemIcon type={SystemIconType.financial} style={{display: 'block'}} />
        }
    },
    {
        title: "Скрытый",
        dataIndex: "hidden",
        type: ColumnTypes.CustomRender,
        width: 130,
        minWidth: 130,
        //maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: any, rowData: DocumentTemplateRecord) => {
            return rowData.hidden && <SystemIcon type={SystemIconType.hidden} style={{display: 'block'}}/>;
        },
        sortable: false
    },
    {
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
        type: ColumnTypes.DateTime,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__POPUP__TITLE__ACTIONS),
        type: ColumnTypes.CustomRender,
        width: getActionsColumnWidth(3),
        minWidth: getActionsColumnWidth(3),
        maxWidth: getActionsColumnWidth(3),
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: DocumentTemplateRecord, rowAction, entityType) => {
            let canEdit = getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) || (getStoreState().permissions.permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS) && rowData.common !== true);
            let canDelete = (getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) || (getStoreState().permissions.permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS) && rowData.common !== true));
            let isDeleteable = rowData.isDeleteable;
            let canUploadFile = getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) || (getStoreState().permissions.permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS) && rowData.common !== true && (rowData.documentType === DocumentTypeCodeEnum.ACT || rowData.documentType === DocumentTypeCodeEnum.CONTRACT));
            let canDownloadFile = getStoreState().permissions.permissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) || (getStoreState().permissions.permissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS) && (rowData.documentType === DocumentTypeCodeEnum.ACT || rowData.documentType === DocumentTypeCodeEnum.CONTRACT));
            let canHide = !rowData.hidden;

            return <IconsGroup items={[
                canEdit && (
                    <SystemIcon type={SystemIconType.edit}
                                style={(canEdit ? {} : {color: '#abacb0', cursor: 'default'})}
                                className={'rr-grid-table-edit-icon'}
                                onClick={e => {
                                e.stopPropagation();
                                if (canEdit) rowAction(rowData, 'edit');
                            }}
                    />
                ),
                canDelete && (
                    <SystemIcon type={SystemIconType.delete}
                                style={isDeleteable ? undefined : {color: '#abacb0', cursor: 'default'}}
                                className={'rr-grid-table-remove-icon'}
                                onClick={e => {
                                e.stopPropagation();
                                if (isDeleteable) rowAction(rowData, 'delete');
                            }}
                    />
                ),
                (canDownloadFile || canUploadFile) && (
                    <ActionsPopoverV2 items={[
                        canUploadFile ? {
                            disabled: !canUploadFile,
                            title: 'Загрузить файл шаблона',
                            icon: <SystemIcon type={SystemIconType.upload}/>,
                            wrapperComponent: UploadDocumentTemplateFile,
                            wrapperComponentProps: {
                                onChange: (data: any) => {
                                    rowAction(rowData, 'upload', data);
                                },
                                documentType: rowData.documentType
                            }
                        } : undefined,
                        canDownloadFile ? {
                            disabled: !canDownloadFile,
                            title: 'Скачать файл шаблона',
                            icon: <SystemIcon type={SystemIconType.download}/>,
                            onClick: (e) => {
                                rowAction(rowData, 'download');
                            }
                        } : undefined,
                        canHide ? {
                            title: 'Сделать скрытым',
                            icon: <SystemIcon type={SystemIconType.hidden}/>,
                            onClick: (e) => {
                                rowAction(rowData, 'hide');
                            }
                        } : undefined,
                        !canHide ? {
                            title: 'Убрать скрытие',
                            icon: <SystemIcon type={SystemIconType.unhidden}/>,
                            onClick: (e) => {
                                rowAction(rowData, 'show');
                            }
                        } : undefined,
                    ].filter(item => item !== undefined) as ActionItem[]}>
                        <SystemIcon type={SystemIconType.otherActions} className={'rr-grid-table-otherActions-icon'}/>
                    </ActionsPopoverV2>
                )
            ]}/>;

            /*
            return (
                <div style={{display: 'inline-flex', width: '100%'}}>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            canEdit && (
                                <RRIcon type={RRIconType.edit}
                                        style={(canEdit ? {} : {color: '#abacb0', cursor: 'default'})}
                                        className={'rr-grid-table-edit-icon'}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (canEdit) rowAction(rowData, 'edit');
                                        }}
                                />
                            )
                        }
                    </div>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            canDelete && (
                                <RRIcon type={RRIconType.delete}
                                        style={canDelete ? undefined : {color: '#abacb0', cursor: 'default'}}
                                        className={'rr-grid-table-remove-icon'}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (canDelete) rowAction(rowData, 'delete');
                                        }}
                                />
                            )
                        }
                    </div>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            (canDownloadFile || canUploadFile) && (
                                <ActionsPopoverV2 items={[
                                    canUploadFile ? {
                                        disabled: !canUploadFile,
                                        title: 'Загрузить файл шаблона',
                                        icon: <RRIcon type={RRIconType.upload} />,
                                        wrapperComponent: UploadDocumentTemplateFile,
                                        wrapperComponentProps: {
                                            onChange: (data: any) => {
                                                rowAction(rowData, 'upload', data);
                                            },
                                            documentType: rowData.documentType
                                        }
                                    } : undefined,
                                    canDownloadFile ? {
                                        disabled: !canDownloadFile,
                                        title: 'Скачать файл шаблона',
                                        icon: <RRIcon type={RRIconType.download} />,
                                        onClick: (e) => {
                                            rowAction(rowData, 'download');
                                        }
                                    } : undefined,
                                    canHide ? {
                                        title: 'Сделать скрытым',
                                        icon: <RRIcon type={RRIconType.hidden} />,
                                        onClick: (e) => {
                                            rowAction(rowData, 'hide');
                                        }
                                    } : undefined,
                                    !canHide ? {
                                        title: 'Убрать скрытие',
                                        icon: <RRIcon type={RRIconType.unhidden} />,
                                        onClick: (e) => {
                                            rowAction(rowData, 'show');
                                        }
                                    } : undefined,
                                ].filter(item=>item !== undefined) as ActionItem[]}>
                                    <RRIcon type={RRIconType.otherActions} className={'rr-grid-table-otherActions-icon'} />
                                </ActionsPopoverV2>
                            )
                        }
                    </div>
                </div>
            );
            */
        }
    }
];

import React, {FC} from 'react';
import {ButtonProps} from "antd/lib/button/button";
import {AddButton2} from "../../../../../../components/buttons/addButton2/addButton2";

interface Props {
    onClick?: ButtonProps['onClick'];
    style?: ButtonProps['style'];
}

export const AddNewElementsButton: FC<Props> = (props) => {
    return (
        <AddButton2 onClick={props.onClick} style={props.style} label={'Добавить новые'}/>
    );
};

import { connect } from 'react-redux';
import { IRootState } from '../../../../../../shared/reducers';
import { formFields } from './product-page-features-create-group-modal-fields';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../../../components/dynamicForm/DynamicForm';
import { IntlShape } from 'react-intl';
import { CustomFieldGroupObjRead } from '../../../../../../server/api';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { updateModalFilters } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    intl: IntlShape;
    onOk: (data: object) => void;
    onCancel: () => void;
    groups: Array<CustomFieldGroupObjRead>;
}

class _ProductPageFeaturesCreateGroupModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
        updating: false,
    };

    onOk = async (data: any, form) => {
        // Тут надо проверить значение на уникальность и выставить ошибку

        const err: string = localizeIntl(this.props.intl, LocalizationEnum.PAGE__PRODUCTS__GROUP_NAME_ALREADY_EXISTS) as string;
        const mainName: string = localizeIntl(this.props.intl, LocalizationEnum.PAGE__PRODUCTS__MAIN_ONES_LABEL) as string;

        if (
            this.props.groups.find((group) => {
                return group.name === data.name || group.name === mainName;
            })
        ) {
            form.setFields({
                name: {
                    value: data.name,
                    errors: [new Error(err)],
                },
            });
        } else {
            this.props.onOk(data);
        }
    };

    onCancel = (isFieldsTouched: Boolean) => {
        this.props.onCancel();
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProductPageFeaturesCreateGroupModal = connect(mapStateToProps, mapDispatchToProps)(_ProductPageFeaturesCreateGroupModal);

import React, { FC, memo } from 'react';
import { PageUrlParamsObject } from '../../../../../../../core/hooks/urlParams/types';
import { WorkPlanningsCalendarFilters } from '../../filters/workPlanningsCalendarFilters.types';
import { processBaseWorkPlanningsCalendarItem } from '../../utils/processing/processBaseCalendarItem';
import { workPlanningsCalendarSortData, workPlanningsCalendarSortValueToItemName, WorkPlanningsSortValue } from '../../utils/data/sortData';
import { WorkPlanningsItemRenderer } from '../../components/ItemRenderer/WorkPlanningsItemRenderer';
import { WorkPlanningsCalendarHeaderButtons } from '../../components/HeaderButtons/WorkPlanningsCalendarHeaderButtons';
import { CalendarGroupsObject } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import { Calendar } from '../../../../components/Calendar/Calendar';
import { WorkPlanningsRecordList } from '../../../../../../../server';
import { useAppSelector } from '../../../../../../../store/hooks';
import { workPlanningsCalendarScreenLeft, workPlanningsCalendarScreenRight } from '../../reducers/workPlanningsCalendar.selectors';
import { CalendarVisibleItemsLimit } from '../../../../components/Calendar/utils/data/calendarConstants';

interface WorkPlanningsBaseCalendarProps {
    workPlanningsCalendarGroups: CalendarGroupsObject<WorkPlanningsSortValue>;
    pageParamsObject: PageUrlParamsObject<WorkPlanningsCalendarFilters>;
    isLoading: boolean;
    setChosenWorkId: (value: number | undefined) => void;
    workPlanningsList: WorkPlanningsRecordList | undefined;
    visibleItemsLimit: CalendarVisibleItemsLimit;
    setVisibleItemsLimit: React.Dispatch<React.SetStateAction<CalendarVisibleItemsLimit>>;
}

export const WorkPlanningsBaseCalendar: FC<WorkPlanningsBaseCalendarProps> = memo((props) => {
    const {
        pageParamsObject,
        workPlanningsList,
        visibleItemsLimit,
        setVisibleItemsLimit,
        workPlanningsCalendarGroups,
        setChosenWorkId,
        isLoading,
    } = props;
    const screenLeft = useAppSelector(workPlanningsCalendarScreenLeft);
    const screenRight = useAppSelector(workPlanningsCalendarScreenRight);

    return (
        <Calendar
            groupsData={workPlanningsCalendarGroups}
            calendarType={'workPlannings'}
            isLoading={isLoading}
            onClick={(itemId) => {
                setChosenWorkId(+itemId);
            }}
            pageParamsObject={pageParamsObject as PageUrlParamsObject<WorkPlanningsCalendarFilters>}
            processItemFunction={processBaseWorkPlanningsCalendarItem}
            recordList={workPlanningsList}
            screenLeft={screenLeft}
            screenRight={screenRight}
            setVisibleItemsLimit={setVisibleItemsLimit}
            sortData={workPlanningsCalendarSortData}
            sortGroupMap={workPlanningsCalendarSortValueToItemName}
            visibleItemsLimit={visibleItemsLimit}
            itemRenderer={WorkPlanningsItemRenderer}
            headerButtons={WorkPlanningsCalendarHeaderButtons}
        />
    );
});

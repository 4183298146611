import React, {FC, ReactNode} from 'react';

export interface SelectItemProps {
    state: ReactNode;
    name: ReactNode;
    extra: ReactNode;
}

export const SelectItem:FC<SelectItemProps> = ({state, name, extra}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div>
                {state}
                {name}
            </div>
            <div style={{flexGrow: 1}}></div>
            <div style={{marginLeft: 20, marginRight: 30, display: 'flex', alignItems: 'center'}}>
                {extra}
            </div>
        </div>
    );
};

import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { VariantInfoUpdate } from '../../../../server/api';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { formFields } from './product-variants-change-count-modal-fields';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { resetVariant, resetVariantError, updateEntity1 } from './reducers/product-variant.reducer';
import { IntlShape } from 'react-intl';
import { loadEntities, setLoading } from './reducers/product-variants.reducer';
import { loadEntity as LoadProduct } from '../products/reducers/product.reducer';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    goBackPath: string;
    intl: IntlShape;
}

class _ProductVariantsChangeCountModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = async (data: any) => {
        let { productId, productBusinessVersion, initialValues, businessAccountId, goBackPath, intl } = this.props;

        if (productId !== undefined && productBusinessVersion !== undefined) {
            let newData: VariantInfoUpdate = {
                ...{
                    ...initialValues,
                    pricePerShift: initialValues ? (initialValues.hasOwnPricePerShift ? initialValues.pricePerShift : null) : null,
                    externalCode: initialValues ? (initialValues.hasOwnExternalCode ? initialValues.externalCode : null) : null,
                },
                ...data,
                stockInstanceCount: data?.warehousePresenceCounters?.stockInstanceCount ?? 0,
                underMaintenanceInstanceCount: data?.warehousePresenceCounters?.underMaintenanceInstanceCount ?? 0,
            } as VariantInfoUpdate;

            delete newData['warehousePresenceCounters'];

            try {
                let result = await this.props.updateEntity1(businessAccountId, productId, newData.id || 0, newData);
                if (!(result instanceof Error)) {
                    this.props.setLoading(true);
                    showNotification(
                        'success',
                        localizeIntl(this.props.intl, LocalizationEnum.PAGE__VARIANTS__POPUP_NOTIFICATIONS__VARIANT_UPDATED)
                    );
                    this.props.push(goBackPath);
                    setTimeout(() => {
                        if (productId) this.props.loadEntities(intl, businessAccountId, productId);
                    }, 1500);
                    this.props.LoadProduct(businessAccountId, productId);
                    this.props.resetVariantError();
                    this.props.resetVariant();
                }
            } catch (e) {}
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.push(this.props.goBackPath);
            this.props.resetVariantError();
            this.props.resetVariant();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.productVariant.updating,
    updatingError: storeState.productVariant.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    productId: storeState.product.entity?.id,
    productBusinessVersion: storeState.product.entity?.businessVersion,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    updateEntity1,
    push,
    resetVariantError,
    resetVariant,
    loadEntities,
    setLoading,
    LoadProduct,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProductVariantsChangeCountModal = connect(mapStateToProps, mapDispatchToProps)(_ProductVariantsChangeCountModal);

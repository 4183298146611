import React, {CSSProperties, useRef, useState} from 'react';
import {AttachmentDocumentTypeCodeEnum, AttachmentParentEntityTypeCodeEnum, AttachmentRecord, serverApi} from '../../server';
import {Icon, Progress} from 'antd';
import {IconEllipsisHSolid, IconTrash} from '../icons';
import {SimpleTable, SimpleTableColumn} from '../v2/simpleTable/SimpleTable';
import {CollapsibleBlock} from '../v2/collapsibleBlock/CollapsibleBlock';
import {Link} from 'react-router-dom';
import {ActionsPopoverV2} from '../v2/actionsPopover/ActionsPopoverV2';
import {formatBytes} from '../../shared/util/formatBytes';
import {showConfirm2} from '../confirm/showConfirm';
import {useIntl} from 'react-intl';
import {showNotification} from '../notification/showNotification';
import './attachmentsBlock.less';
import {LocalizationEnum, localize} from '../../localization';
import {AttachmentsUploader} from './attachmentsUploader';
import {CONTENT_SERVER_BASE_PATH} from '../../config/config';
import {UploadFile} from 'antd/lib/upload/interface';
import {MAX_ATTACHMENTS_LIMIT_FOR_ENTITY} from '../../config/constants';
import {SystemIcon, SystemIconType} from '../v2/systemIcon/systemIcon';
import {AttachmentIcon} from '../../modules/main/attachments/attachmentIcon';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {AttachmentsBlockSettings, updateAttachmentsBlockSettings} from '../../shared/reducers/userSettings/userSettings.reducer';
import {Spin} from '../index';
import {businessAccountIdSelector} from '../../shared/reducers/system.reducer';

interface AttachmentsBlockProps {
    attachments: AttachmentRecord[];
    updateParentEntity?: () => void;
    style?: CSSProperties;
    parentEntityId: number;
    parentEntityTypeCode: AttachmentsBlockName;
}

export type AttachmentsBlockName = AttachmentParentEntityTypeCodeEnum;

export const AttachmentsBlock = ({ attachments, ...props }: AttachmentsBlockProps) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [uploaderFiles, setUploaderFiles] = useState([] as UploadFile[]);
    const filesLimit = 0;
    const settings: AttachmentsBlockSettings = useAppSelector(
        (state) => state.userSettings.attachmentsBlockSettings[props.parentEntityTypeCode] || { collapsed: false }
    );
    const dispatch = useAppDispatch();
    const setSettings = (_settings: AttachmentsBlockSettings) =>
        dispatch(updateAttachmentsBlockSettings({ [props.parentEntityTypeCode]: { ...settings, ..._settings } }));
    const [fetching, setFetching] = useState(false);
    const divRef = useRef<HTMLDivElement | null>(null);

    const deleteAttachment = async (id: number, businessVersion: number) => {
        const yes = await showConfirm2({ intl, title: 'Удалить вложение?' });
        if (yes) {
            setFetching(true);
            try {
                await serverApi.deleteAttachmentById(businessAccountId, id, { businessVersion });
                if (props.updateParentEntity) {
                    props.updateParentEntity();
                }
                showNotification('success', 'Вложение удалено');
            } catch (e) {
                showNotification('error', 'Вложение не удалено');
            }
            setFetching(false);
        }
    };

    let columns: SimpleTableColumn<AttachmentRecord>[] = [
        {
            id: 'fileTypeCode',
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            style: { lineHeight: 1 },
            render: (value, row) => {
                return (
                    <a href={`${CONTENT_SERVER_BASE_PATH}/files/${row.relativeDownloadURL}`} target={'_blank'}>
                        <AttachmentIcon documentType={row.documentType} type={row.fileTypeCode} style={{ fontSize: 24 }} />
                    </a>
                );
            },
        },
        {
            id: 'fileName',
            width: 200,
            style: { alignItems: 'flex-start', marginLeft: 16 },
            className: 'rr-attachments-block_attachment-name-link',
            render: (value, row) => {
                let linkClassName:string|undefined;
                if(row.documentType === AttachmentDocumentTypeCodeEnum.CONTRACT) linkClassName = 'rr-attachments-block_attachment-name-link-CONTRACT';
                return (
                    <a href={`${CONTENT_SERVER_BASE_PATH}/files/${row.relativeDownloadURL}`} className={linkClassName} target={'_blank'}>
                        {value}
                    </a>
                );
            },
        },
        {
            id: 'fileSize',
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            style: { alignItems: 'flex-end', paddingRight: 30 },
            render: (value, row) => {
                return formatBytes(value, 2);
            },
        },
        {
            id: 'id',
            width: 32,
            minWidth: 32,
            maxWidth: 32,
            style: { lineHeight: 1 },
            render: (value, row) => {
                return (
                    <ActionsPopoverV2
                        getPopupContainer={(triggerNode) => divRef.current || (triggerNode.parentNode as HTMLElement) || triggerNode}
                        align={{ offset: [0, -6] }}
                        items={[
                            {
                                title: 'Удалить',
                                icon: <SystemIcon type={SystemIconType.delete} />,
                                onClick: () => {
                                    deleteAttachment(row.id, row.businessVersion);
                                },
                            },
                        ]}
                    >
                        <Icon component={IconEllipsisHSolid} style={{ fontSize: 36, color: '#525A95', userSelect: 'none' }} />
                    </ActionsPopoverV2>
                );
            },
        },
    ];

    let toLoadColumns: SimpleTableColumn<UploadFile>[] = [
        {
            id: 'percent',
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            style: { lineHeight: 1 },
            render: (value, row) => {
                return (
                    <Progress
                        type="circle"
                        percent={row.error ? 100 : row.percent}
                        width={30}
                        status={row.error ? 'exception' : undefined}
                    />
                );
            },
        },
        {
            id: 'name',
            width: 200,
            style: { alignItems: 'flex-start', marginLeft: 16 },
            render: (value, row) => {
                return (
                    <div>
                        <div className={'rr-attachments-block_attachment-name'}>{row.name}</div>
                        {row.error && <div className={'rr-attachments-block_attachment-error'}>{row.error.message}</div>}
                    </div>
                );
            },
        },
        {
            id: 'size',
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            style: { alignItems: 'flex-end', paddingRight: 30 },
            render: (value, row) => {
                return formatBytes(row.size, 2);
            },
        },
        {
            id: 'uid',
            width: 28,
            minWidth: 28,
            maxWidth: 28,
            style: { lineHeight: 1 },
            render: (value, row) => {
                return (
                    row.error && (
                        <Icon
                            style={{ fontSize: 21, color: '#F4516C' }}
                            component={IconTrash}
                            onClick={() => {
                                let files = [...uploaderFiles];
                                const file = files.find((file) => file.uid === row.uid);
                                if (file) {
                                    file.status = 'removed';
                                }
                                setUploaderFiles(files);
                            }}
                        />
                    )
                );
            },
        },
    ];

    let uploaderVisibleFiles = uploaderFiles.filter((file) => {
        if (file.status === 'done' && file.response.id && attachments.find((a) => a.id === file.response.id)) {
            file.status = 'removed';
        }
        return file.status !== 'removed';
    });

    let collapsed = settings.collapsed;
    if (attachments.length === 0 && uploaderVisibleFiles.length === 0) {
        collapsed = true;
    }

    return (
        <div ref={divRef}>
            <CollapsibleBlock
                collapsed={collapsed}
                onCollapse={(collapsed) => {
                    setSettings({ collapsed });
                }}
                className={'rr-attachments-block'}
                title={
                    attachments.length === 0 ? (
                        'Вложений нет'
                    ) : (
                        <>
                            {attachments.length}{' '}
                            {localize(LocalizationEnum.ASPECT__PLURAL__ATTACHMENT, undefined, { value: attachments.length })}
                        </>
                    )
                }
                icon={<SystemIcon type={SystemIconType.attachment} />}
                extraContent={
                    <AttachmentsUploader
                        parentEntityTypeCode={props.parentEntityTypeCode}
                        parentEntityId={props.parentEntityId}
                        onChange={(files) => {
                            setUploaderFiles([...files]);
                        }}
                        onComplete={(files) => {
                            if (props.updateParentEntity) props.updateParentEntity();
                            setUploaderFiles([...files]);
                        }}
                        disabled={
                            attachments.length + uploaderVisibleFiles.filter((f) => f.status !== 'error').length >=
                            MAX_ATTACHMENTS_LIMIT_FOR_ENTITY
                        }
                        attachmentsCount={attachments.length}
                    />
                }
                disabled={attachments.length === 0 && uploaderVisibleFiles.length === 0}
                style={props.style}
            >
                <Spin spinning={fetching}>
                    <SimpleTable columns={toLoadColumns} data={uploaderVisibleFiles} />
                    <SimpleTable columns={columns} data={attachments.slice(0, filesLimit > 0 ? filesLimit : undefined)} />
                    {filesLimit > 0 && attachments.length > filesLimit && (
                        <div style={{ marginTop: 20 }}>
                            <Link to={'/'} style={{ color: '#525993', textDecoration: 'underline' }}>
                                Смотреть все <span style={{ fontWeight: 500 }}>(еще {attachments.length - filesLimit})</span>
                            </Link>
                        </div>
                    )}
                </Spin>
            </CollapsibleBlock>
        </div>
    );
};

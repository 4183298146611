import React from "react";
import {Avatar as OriginalAvatar} from "antd";
import {AvatarProps} from "antd/lib/avatar";

interface IProps extends AvatarProps {
    originalSrc?: string;
}

export const Avatar = (props:IProps) => {
    // Если есть props.src, то фон не выводить
    let style = props.style ? {...props.style} : {};
    if(props.src){
        style.backgroundColor = 'unset';
    }

    const avatar = (
        <OriginalAvatar {...props} style={style}>
            {props.children}
        </OriginalAvatar>
    );
    return props.originalSrc ? <a href={props.originalSrc} target="_blank" rel="noopener noreferrer">{avatar}</a> : avatar;
};

import React, { FC } from 'react';
import { Icon } from 'antd';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { HeaderNavigationBlock } from '../../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { IconTags } from '../../../../../../components/icons';
import { ProductInfoRead } from '../../../../../../server';
import { Status } from '../../../../../../components/status/status';
import { ArchiveBadge } from '../../../../../../components/archiveBadge/archiveBadge';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { inventoryPageUrlRoute } from '../../../inventory-page';
import { InventoryPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

export interface ProductPageHeaderNavigationBlockProps {
    product: ProductInfoRead | null;
}

export const ProductPageHeaderNavigationBlock: FC<ProductPageHeaderNavigationBlockProps> = (props) => {
    const { product } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (product == null) return null;

    const { stateCode, archive, fullName, shortName } = product;
    const name = fullName || shortName;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon className={'icon'} component={IconTags} />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                        <Status entityType={'product'} code={stateCode} />
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${inventoryPageUrlRoute}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__MAIN_MENU__INVENTORY)}
                        </Link>
                        <span>/</span>
                        <Link to={`/${businessAccountId}/${inventoryPageUrlRoute}/${InventoryPageTabsEnum.PRODUCTS}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__MAIN_MENU__PRODUCTS)}
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

import React from 'react';
import { Tabs } from 'antd';
import { reset } from './reducers/renters/renters.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { goBack, push, replace } from 'connected-react-router';
import RoundButton from '../../../components/button/roundButton';
import { Link } from 'react-router-dom';
import { getPathFromState, isStringInEnum } from '../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { LocalizationEnum, localize } from '../../../localization';
import { CounterpartiesList } from './tabs/counterparties/list/CounterpartiesList';
import { getGridStorageData } from '../../../components/grid/utils';
import { CounterpartyCreateModalNew } from './tabs/counterparties/modal/CounterpartyCreateModalNew';
import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { defaultTab, rentersPageUrlRoute, tabToCreateButtonText } from './data/tabs';
import { ContactsList } from './tabs/contacts/list/ContactsList';
import { ParamsUtils } from '../../../core/utils/paramsUtils';

import { RentersPageTabsEnum } from '../../../shared/constants/tabEnums';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

class Component extends BasePage<IProps, {}> {
    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${rentersPageUrlRoute}`);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('renters', this.currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
        this.props.reset();
    }

    onTabChange = (key: string) => {
        const newTabKey = isStringInEnum(key, RentersPageTabsEnum, defaultTab);

        this.props.push(this.getFullUrl(newTabKey));

        saveCurrentTabToStore('renters', newTabKey);
    };

    getPathForNewEntity = (tab: string, search?: string): string => {
        let path = `/${this.props.businessAccountId}/${rentersPageUrlRoute}/${tab}`;
        if (tab === RentersPageTabsEnum.COUNTERPARTIES) {
            path += '/new' + (search || '');
        }
        if (tab === RentersPageTabsEnum.CONTACTS) {
            const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
            const searchParams = new URLSearchParams(urlSearchParams);

            searchParams.append('modal', 'new');

            const paramsString = searchParams.toString();

            path += `/?${paramsString}`;
        }
        return path;
    };

    getFullUrl(tab: RentersPageTabsEnum) {
        let gridData = getGridStorageData(tab);
        return getPathFromState(this.getSectionUrl(tab), '', {
            ...gridData.filters,
            ...gridData.params,
        });
    }

    getSectionUrl(tab: RentersPageTabsEnum) {
        const newTab = isStringInEnum(tab, RentersPageTabsEnum, defaultTab);
        return `/${this.props.businessAccountId}/${rentersPageUrlRoute}/${newTab}`;
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(rentersPageUrlRoute, RentersPageTabsEnum);
    }

    renderContent() {
        const { currentTab } = this;

        if (currentTab == null) {
            const url = this.getFullUrl(this.props.tabFromStore as RentersPageTabsEnum);
            return <Redirect to={url} />;
        }

        const currentTabCreateButtonText = tabToCreateButtonText[currentTab];

        return (
            <>
                <Tabs
                    activeKey={currentTab}
                    onChange={this.onTabChange}
                    className={'rr-tabs'}
                    type="line"
                    animated={false}
                    destroyInactiveTabPane
                    tabBarExtraContent={
                        <Link to={this.getPathForNewEntity(currentTab, this.props.location.search)}>
                            <RoundButton icon={'plus'} colorScheme={'success'}>
                                {currentTabCreateButtonText}
                            </RoundButton>
                        </Link>
                    }
                >
                    <Tabs.TabPane
                        tab={localize(LocalizationEnum.PAGE__RENTERS__LIST__TABS__COUNTERPARTIES)}
                        key={RentersPageTabsEnum.COUNTERPARTIES}
                    >
                        <CounterpartiesList
                            intl={this.props.intl}
                            history={this.props.history}
                            gridName={RentersPageTabsEnum.COUNTERPARTIES}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={'Контакты'} key={RentersPageTabsEnum.CONTACTS}>
                        <ContactsList gridName={RentersPageTabsEnum.CONTACTS} />
                    </Tabs.TabPane>
                </Tabs>

                <Route
                    exact
                    path={this.getPathForNewEntity(RentersPageTabsEnum.COUNTERPARTIES)}
                    render={(props) => (
                        <CounterpartyCreateModalNew
                            onSuccess={(data) => {
                                this.props.push(
                                    `/${data.businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${data.id}?tab=description`
                                );
                            }}
                            onCancel={() => {
                                this.props.goBack();
                            }}
                        />
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        tabFromStore: getCurrentTabsSelector(storeState)?.['renters'],
    };
};

const mapDispatchToProps = {
    reset,
    replace,
    push,
    goBack,
    setBlockedUrl,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const RentersPage = connect(mapStateToProps, mapDispatchToProps)(Component);

import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { VehicleInfoRead, VehicleRecord } from '../../../../../../server';
import React, { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { EntityActionType } from '../../../../../../../index';
import RoundButton from '../../../../../../components/button/roundButton';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Icon } from 'antd';
import { IconArchive, IconLevelUp, IconTrash } from '../../../../../../components/icons';
import _ from 'lodash';
import { PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { EntityActionsHook } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { useArchiveVehiclesMutation, useDeleteVehicleMutation, useDeleteVehiclesMutation, vehiclesApi } from '../api/vehicles.api';
import { _Grid } from '../../../../../../components/grid/Grid';

export const useVehicleEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const { businessAccountId } = useAppSelector((state) => state.system);

    const [archiveVehicles, { isLoading: isArchiveVehiclesLoading }] = useArchiveVehiclesMutation();

    const [deleteVehicle, { isLoading: isDeleteVehicleLoading }] = useDeleteVehicleMutation();

    const isLoading = isArchiveVehiclesLoading || isDeleteVehicleLoading;

    const editVehicleCallback = useCallback(
        (
            vehicleRecord: VehicleInfoRead,
            options?: {
                withView?: boolean;
            }
        ) => {
            dispatch(
                vehiclesApi.endpoints.vehicleById.initiate(
                    {
                        businessAccountId,
                        entityId: vehicleRecord.id,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());
            searchParams.append('modal', 'edit');
            searchParams.append('id', String(vehicleRecord.id));

            if (options?.withView) {
                const booleanParam = PageURLParamDescriptions.booleanParam.toString(true);
                if (booleanParam) {
                    searchParams.append('view', booleanParam);
                }
            }

            const path = `${rootPath}/?` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editVehicleForDrawerCallback = useCallback(
        (vehicleRecord: VehicleInfoRead) => {
            editVehicleCallback(vehicleRecord, {
                withView: true,
            });
        },
        [editVehicleCallback]
    );

    const archiveVehicleCallback = useCallback(
        (vehicleRecord: VehicleInfoRead) => {
            archiveVehicles({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id: vehicleRecord.id,
                            archive: !vehicleRecord.archive,
                            businessVersion: vehicleRecord.businessVersion,
                        },
                    ],
                },
            });
        },
        [archiveVehicles]
    );

    const deleteVehicleCallback = useCallback(
        async (vehicleRecord: VehicleInfoRead) => {
            deleteVehicle({
                id: vehicleRecord.id,
                businessVersionObj: {
                    businessVersion: vehicleRecord.businessVersion,
                },
            });
        },
        [deleteVehicle]
    );

    const onRowAction = useCallback(
        (item: VehicleInfoRead, action: EntityActionType) => {
            const actionHandler: Partial<Record<typeof action, Function>> = {
                edit: () => editVehicleCallback(item),
                archive: () => archiveVehicleCallback(item),
                delete: () => deleteVehicleCallback(item),
            };

            actionHandler[action]?.();
        },
        [archiveVehicleCallback, deleteVehicleCallback, editVehicleCallback]
    );

    return useMemo(
        () => ({
            editAction: editVehicleCallback,
            editActionForDrawer: editVehicleForDrawerCallback,
            archiveActionForDrawer: archiveVehicleCallback,
            deleteActionForDrawer: deleteVehicleCallback,
            onRowAction,
            isLoading,
        }),
        [archiveVehicleCallback, deleteVehicleCallback, editVehicleCallback, editVehicleForDrawerCallback, isLoading, onRowAction]
    );
}) satisfies EntityActionsHook<VehicleInfoRead>;

export const useActionsButtons = ({ selectedRecords, gridRef }: { selectedRecords: VehicleRecord[]; gridRef: React.RefObject<_Grid> }) => {
    const [archiveVehicles, { isLoading: isArchiveVehiclesLoading }] = useArchiveVehiclesMutation();

    const [deleteVehicles, { isLoading: isDeleteVehiclesLoading }] = useDeleteVehiclesMutation();

    const isLoading = isArchiveVehiclesLoading || isDeleteVehiclesLoading;

    const deleteSelectedVehicles = useCallback(() => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => {
            return {
                id,
                businessVersion,
            };
        });

        deleteVehicles({
            versionedEntityObjList: {
                entities: versionedSelectedEntitiesObjects,
            },
        });

        gridRef?.current?.clearSelection();
    }, [deleteVehicles, gridRef, selectedRecords]);

    const archiveSelectedVehicles = useCallback(() => {
        archiveVehicles({
            workflowDirectiveBulk: {
                directives: selectedRecords.map(({ id, archive, businessVersion }) => {
                    return {
                        id,
                        archive: !archive,
                        businessVersion,
                    };
                }),
            },
        });

        gridRef?.current?.clearSelection();
    }, [archiveVehicles, gridRef, selectedRecords]);

    return useMemo(
        () => ({
            buttons: [
                _.every(selectedRecords, (item: VehicleRecord) => !item.archive && item.archivable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        onClick={archiveSelectedVehicles}
                        colorScheme={'TO_ARCHIVE'}
                    >
                        <Icon component={IconArchive} />
                        {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                    </RoundButton>
                ) : null,

                _.every(selectedRecords, (item: VehicleRecord) => item.archive) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        onClick={archiveSelectedVehicles}
                        colorScheme={'FROM_ARCHIVE'}
                    >
                        <Icon component={IconLevelUp} />
                        {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                    </RoundButton>
                ) : null,

                _.every(selectedRecords, (item: VehicleRecord) => item.deleteable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__DELETE}
                        title={'Удалить'}
                        onClick={deleteSelectedVehicles}
                        colorScheme={'DELETE'}
                    >
                        <Icon component={IconTrash} />
                        {localize(LocalizationEnum.ASPECT__ACTION__DELETE, 'span')}
                    </RoundButton>
                ) : null,
            ],
            isLoading,
        }),
        [archiveSelectedVehicles, deleteSelectedVehicles, isLoading, selectedRecords]
    );
};

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {isDefined} from "../../../shared/util/utils";
import {GridColumnCreator} from "../../../components/grid/gridColumnCreator";
import {EntityType} from "../../../../index";

export const tblColumnsStatus = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.STATUS) {
        return (
            GridColumnCreator.createStatusColumn<Record>({
                title: column.title,
                dataIndex: column.dataIndex,
                type: ColumnTypes.CustomRender,
                minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
                //width: 160,
                //maxWidth: MAX_WIDTH(160),
                render: (value: any, rowData: Record, onAction, entityType: EntityType) => {
                    return column.render(rowData);
                },
            })
        );
    } else {
        throw Error('error');
    }
};

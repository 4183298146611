import React from 'react';
import { Button, Icon } from 'antd';
import moment, { Moment } from 'moment';
import { TimeRangePickerPanel } from '../timeRangePicker/timeRangePickerPanel';
import Trigger from 'rc-trigger';
import { IconCrosshairsSolid } from '../icons';
import { LocalizationEnum, localize } from '../../localization';
import { TimeInput } from './timeInput';
import './dateTimePicker.less';
import {DatePickerModified, DatePickerModifiedProps} from "../datePicker/DatePickerModified";
import {DateTimePickerFooter} from "./dateTimePickerFooter";

export interface DateTimePickerProps extends DatePickerModifiedProps {
    align?: { points?: string[]; offset?: number[] };
    getCurrentDate?: () => Moment;
}

interface State {
    selectedDate: Moment | null;
    calendarOpen: boolean;
    timePickerVisible: boolean;
}

const changeOnOk = false;

export class DateTimePicker extends React.Component<DateTimePickerProps, State> {
    constructor(props: DateTimePickerProps) {
        super(props);
        this.state = {
            selectedDate: props.value || props.defaultValue || null,
            calendarOpen: false,
            timePickerVisible: false,
        };
    }

    componentDidUpdate(prevProps: DateTimePickerProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                selectedDate: this.props.value || null,
            });
        }
    }

    render() {
        const currentDisplayValue = this.state.selectedDate || this.props.value || undefined;

        return (
            <DatePickerModified
                {...this.props}
                dropdownClassName={'rr-dateTimePicker'}
                showToday={false}
                onChange={(date) => {
                    if (date) date = date.clone().seconds(0).milliseconds(0);
                    if (!this.state.calendarOpen) {
                        // Это когда сбрасывааем по крестику
                        if (this.props.onChange) this.props.onChange(date, '');
                        this.setState({
                            selectedDate: date,
                        });
                    } else {
                        this.setState({
                            selectedDate: date,
                            calendarOpen: true,
                        });
                        if (!changeOnOk && this.props.onChange) this.props.onChange(date, '');
                    }
                }}
                onOpenChange={(open) => {
                    this.setState({
                        calendarOpen: open,
                    });
                    if (changeOnOk) {
                        if (!open)
                            this.setState({
                                selectedDate: null,
                            });
                        else
                            this.setState({
                                selectedDate: this.props.value || null,
                            });
                    }
                }}
                open={this.state.calendarOpen}
                value={currentDisplayValue}
                format={'DD.MM.YY HH:mm'}
                renderExtraFooter={() => {
                    return (
                        <DateTimePickerFooter
                            onSelectedDateChange={(value)=>{
                                this.setState({
                                    selectedDate: value
                                });
                            }}
                            onCalendarClose={()=>{
                                this.setState({
                                    calendarOpen: false,
                                });
                            }}
                            currentDisplayValue={currentDisplayValue}
                            getCurrentDate={this.props.getCurrentDate}
                            onChange={this.props.onChange}
                            selectedDate={this.state.selectedDate}
                        />
                    );
                }}
                {...(this.props.align ? { align: this.props.align } : { align: { points: ['bl', 't'], offset: [0, -6] } })}
            />
        );
    }
}

import { ActionButtonsData } from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { LocalizationEnum } from '../../../../localization';
import { Icon } from 'antd';
import {
    IconBorderStyleSolid,
    IconCheck,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconEraser,
    IconPlus
} from '../../../../components/icons';
import React from 'react';
import { StateBadgeData } from '../../../../components/v2/stateBadge/stateBadge';
import { ExpenseStateCodeEnum } from '../../../../server';
import { Color } from '../../../../core/types/color';

export enum ExpenseEntityActionType {
    Create = 'create',
    Edit = 'edit',
    Copy = 'copy',
    Cancel = 'cancel',
    TakeIntoAccount = 'takeIntoAccount',
    ToDraft = 'toDraft',
}

export const expenseEntityActionButtons: ActionButtonsData<ExpenseEntityActionType> = {
    [ExpenseEntityActionType.Create]: {
        name: 'Создать затрату',
        icon: <Icon component={IconPlus} />,
        color: Color.Green,
    },
    [ExpenseEntityActionType.Edit]: {
        localizationId: LocalizationEnum.ASPECT__ACTION__EDIT,
        icon: <Icon component={IconEdit} />,
        color: Color.Purple1,
    },
    [ExpenseEntityActionType.Copy]: {
        localizationId: LocalizationEnum.ASPECT__ACTION__DUPLICATE,
        icon: <Icon component={IconControlPointDuplicateBlack} />,
        color: Color.Green,
    },
    [ExpenseEntityActionType.Cancel]: {
        localizationId: LocalizationEnum.ASPECT__EXPENSE_TRANSITIONS__CANCEL,
        icon: <Icon component={IconEraser} />,
        color: Color.Grey2,
    },
    [ExpenseEntityActionType.TakeIntoAccount]: {
        localizationId: LocalizationEnum.ASPECT__EXPENSE_TRANSITIONS__TAKE_INTO_ACCOUNT,
        icon: <Icon component={IconCheck} />,
        color: Color.Green,
    },
    [ExpenseEntityActionType.ToDraft]: {
        name: 'Черновик',
        icon: <Icon component={IconBorderStyleSolid} />,
        color: Color.WhiteGrey1,
    },
};

export const expenseEntityStateBadgeData: StateBadgeData<ExpenseStateCodeEnum> = {
    [ExpenseStateCodeEnum.TAKENINTOACCOUNT]: {
        color: Color.Green,
        name: 'Учтена',
    },
    [ExpenseStateCodeEnum.CANCELED]: {
        color: Color.Grey2,
        name: 'Отменена',
    },
    [ExpenseStateCodeEnum.DRAFT]: {
        color: Color.WhiteGrey1,
        name: 'Черновик',
    },
};

export const expensesAllStateCodes:ExpenseStateCodeEnum[] = [
    ExpenseStateCodeEnum.DRAFT,
    ExpenseStateCodeEnum.TAKENINTOACCOUNT,
    ExpenseStateCodeEnum.CANCELED
];

export const expensesCreationStateCodes = expensesAllStateCodes.filter((code)=>code !== ExpenseStateCodeEnum.CANCELED);

export const expensesCreationStateCodesOnlyDraft = [ExpenseStateCodeEnum.DRAFT];

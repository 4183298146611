import { FAILURE, REQUEST, SUCCESS } from './action-type.util';
import { CategorySortOrderEnum, CategoryTypeCodeEnum, serverApi } from '../../server';
import sortBy from 'lodash/sortBy';
import { categoriesToTreeFormat } from '../util/utils';

const ACTION_TYPES = {
    LOAD_CATEGORIES: 'entities/LOAD_CATEGORIES',
};

export interface BaseCategory {
    key: number;
    title: string;
    value: number;
    siblingOrder: number;
}
export interface ProductCategory extends BaseCategory {
    children: ProductCategory[];
}

export interface RenterCategory extends BaseCategory {
    children: RenterCategory[];
}

const initialState = {
    categories: {
        products: null as ProductCategory[] | null | undefined,
        renters: null as RenterCategory[] | null | undefined,
    },
};

export type EntitiesState = Readonly<typeof initialState>;

export default (state: EntitiesState = initialState, action: any): EntitiesState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_CATEGORIES):
            return {
                ...state,
            };
        case FAILURE(ACTION_TYPES.LOAD_CATEGORIES):
            return {
                ...state,
            };
        case SUCCESS(ACTION_TYPES.LOAD_CATEGORIES): {
            let categories = sortBy(categoriesToTreeFormat(action.payload.data.results), (item) => item.siblingOrder);
            let newState = action.meta.typeCode === 'RENTER' ? { renters: categories } : { products: categories };

            return {
                ...state,
                categories: {
                    ...state.categories,
                    ...newState,
                },
            };
        }

        default:
            return state;
    }
};

export const loadCategories = (businessAccountId: number, typeCode: CategoryTypeCodeEnum, sortBy?: CategorySortOrderEnum) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.LOAD_CATEGORIES,
            payload: serverApi.listCategoryTrees(businessAccountId, typeCode as CategoryTypeCodeEnum, undefined, sortBy),
            meta: {
                typeCode: typeCode,
            },
        });
    };
};

import React, { ReactElement, ReactNode } from 'react';
import RentElementPopoverContent from './elements-element-popover-content';
import { Popover } from 'antd';
import './elements-element-popover.less';
import { NomenclatureEntityTypeCodeEnum } from '../../../server/api';
import { TooltipPlacement, TooltipTrigger } from 'antd/lib/tooltip';
import { OperationElement } from '../operationForm/reducers/operationForm.reducer';
import { RentElementsGridItem } from '../../../types';

export interface IElementPopoverProps {
    record?: OperationElement | RentElementsGridItem;
    children?: ReactElement | (() => ReactNode);
    placement?: TooltipPlacement;
    trigger?: TooltipTrigger;
    onClosePopover?: (visible: boolean, key?: string) => void;
    entityId?: number;
    hideActionsButton?: boolean;
}

interface IState {
    visible: boolean;
}

export class ElementsElementPopover extends React.PureComponent<IElementPopoverProps, IState> {
    private popoverRef;

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    onVisibleChange = (visible: boolean, key?: string) => {
        this.setState({ visible });
        if (this.props.onClosePopover) this.props.onClosePopover(visible, key);
    };

    closeCB = (key?: string) => {
        this.onVisibleChange(false, key);
    };

    // вызывается когда в дочернем компоненте меняются данные
    onDataChangedCb = () => {
        if (this.popoverRef) this.popoverRef.forceUpdate();
    };

    getPopoverRef = (ref) => {
        if (!this.popoverRef) this.popoverRef = ref;
    };

    render() {
        let { record } = this.props,
            { visible } = this.state;

        if (record) {
            if ('nomenclatureEntityTypeCode' in record && record.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.KIT)
                visible = false; // для RentElementsGridItem
            if ('kitId' in record && record.kitId) visible = false; // для OperationElement
        }

        return (
            <Popover
                ref={this.getPopoverRef}
                overlayClassName={'rr-grid-actions-popover rr-elements-list-info-popover'}
                trigger={this.props.trigger ? this.props.trigger : 'click'}
                onVisibleChange={this.onVisibleChange}
                visible={visible}
                autoAdjustOverflow
                placement={this.props.placement ? this.props.placement : 'right'}
                destroyTooltipOnHide
                content={
                    <RentElementPopoverContent
                        entityId={this.props.entityId}
                        record={this.props.record}
                        closeCb={this.closeCB}
                        onDataChangedCb={this.onDataChangedCb}
                        hideActionsButton={this.props.hideActionsButton}
                    />
                }
            >
                {typeof this.props.children === 'function' ? this.props.children() : this.props.children}
            </Popover>
        );
    }
}

export const downloadDataFromExcel = (data, fileName) => {
    fileDataToDownload(data, fileName, 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
};

export const downloadDataFromDocx = (data, fileName) => {
    fileDataToDownload(data, fileName, 'docx');
};

export const downloadDataFromPdf = (data, fileName) => {
    fileDataToDownload(data, fileName, 'pdf');
};

export const downloadDataFromJasper = (data, fileName) => {
    fileDataToDownload(data, fileName, 'zip');
};

export const fileDataToDownload = (
    data: Blob,
    fileName: string,
    fileExt: string | undefined = undefined,
    fileType: string | undefined = undefined
) => {
    const reader: any = new FileReader();
    const blob = new Blob([data], fileType ? { type: fileType } : undefined);
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + (fileExt ? '.' + fileExt : '');
        link.click();
        return false;
    };
};

export const pdfDataToPrint = (data: Blob) => {
    const blobURL = URL.createObjectURL(data);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = () => {
        setTimeout(() => {
            iframe.focus();
            iframe.contentWindow?.print();
        }, 1);
    };
};

import {IconExclamationSolid} from "../../../../../../components/icons";
import {Icon} from "antd";
import React, {CSSProperties} from "react";

interface ProblemIconProps {
    onClick: () => void;
    style?: CSSProperties;
}

export const ProblemIcon = (props: ProblemIconProps) => {

    return (
        <Icon
            onClick={props.onClick}
            component={IconExclamationSolid}
            style={{
                marginRight: 4,
                fontSize: 32,
                color: '#f8516c',
                verticalAlign: 'middle',
                ...props.style
            }}
        />
    );
};

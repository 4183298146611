import { WorkPlanningsSplittedCalendarGroup } from '../../hooks/useCalendarGroups';
import { FC } from 'react';
import { CalendarId } from '../../../../../../components/Calendar/types/items';
import { CalendarDisplayType } from '../../../../../../components/Calendar/utils/data/calendarConstants';
import { WorkPlanningsSortValue } from '../../../../utils/data/sortData';
import { WorkPlanningsSplittedCalendarSidebarGroupContainer } from './components/sidebarGroupContainer/WorkPlanningsSplittedCalendarSidebarGroupContainer';
import { SideBarGroup } from './components/sidebarGroup/utils/types';

interface WorkPlanningsSplittedCalendarGroupRendererProps {
    group: WorkPlanningsSplittedCalendarGroup;
    displayType: CalendarDisplayType;
    sortByValue: WorkPlanningsSortValue | undefined;
    collapsedSidebarGroupsId: CalendarId[];
    setCollapsedSidebarGroupsId: (value: CalendarId[]) => void;
    getSidebarGroup(sortByValue: WorkPlanningsSortValue | undefined): FC<SideBarGroup> | undefined;
}

export const WorkPlanningsSplittedCalendarGroupRenderer: FC<WorkPlanningsSplittedCalendarGroupRendererProps> = (props) => {
    const { group, displayType, sortByValue, collapsedSidebarGroupsId, setCollapsedSidebarGroupsId, getSidebarGroup } = props;

    const Sidebar = getSidebarGroup(sortByValue);

    if (Sidebar == null) return null;

    return (
        <WorkPlanningsSplittedCalendarSidebarGroupContainer
            collapsedSidebarGroupsId={collapsedSidebarGroupsId}
            setCollapsedSidebarGroupsId={setCollapsedSidebarGroupsId}
            group={group}
            sortByValue={sortByValue}
            displayType={displayType}
        >
            {(workPlannings, isCollapsed, onClick) => (
                <Sidebar workPlannings={workPlannings} isCollapsed={isCollapsed} onClick={onClick} displayType={displayType} />
            )}
        </WorkPlanningsSplittedCalendarSidebarGroupContainer>
    );
};

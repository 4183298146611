import React from 'react';
import {Tabs} from 'antd';
import {connect} from 'react-redux';
import {IRootState} from '../../../shared/reducers';
import {Redirect, Route} from 'react-router';
import RoundButton from '../../../components/button/roundButton';
import {ProjectCreateModal} from './production/modal/project-create-modal';
import {ProjectsList} from './production/list/projects-list';
import {BasePage, IBasePageProps} from '../../../components/page/BasePage';
import {LocalizationEnum, localize, localizeIntl} from '../../../localization';
import {resetOperationForm, startNewSimpleOrderOperation} from '../operationForm/reducers/operationForm.reducer';
import {ProjectInfoRead, TemplateInfoRead} from '../../../server';
import {push, replace} from 'connected-react-router';
import {TemplatesList} from './templates/templatesList';
import {TemplateCreateModal} from './templates/templateCreateModal';
import {getGridStorageData} from '../../../components/grid/utils';
import {getPathFromState, isStringInEnum} from '../../../shared/util/utils';
import {createProjectModalFilters} from './production/modal/projectsPageData';
import {createTemplateModalFilters} from './templates/templatePageData';
import {getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl} from '../../../shared/reducers/userSettings/userSettings.reducer';
import {OffersList} from '../offers/list/offersList';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {ParamsUtils} from '../../../core/utils/paramsUtils';
import {
    offersModuleEnabledSelector,
    ordersModuleEnabledSelector,
    projectsModuleEnabledSelector
} from '../../../shared/reducers/businessAccountPreferences.reducer';
import {ProjectsPageTabsEnum} from '../../../shared/constants/tabEnums';
import {SimpleOrdersList} from "./simpleOrders/list/simpleOrdersList";
import {showConfirm} from "../../../components/confirm/showConfirm";

interface IProps extends StateProps, DispatchProps, IBasePageProps, WrappedComponentProps {}

export const projectsPageUrlRoute = 'projects';
const defaultTab = ProjectsPageTabsEnum.PROJECTS;

class Component extends BasePage<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${projectsPageUrlRoute}`);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('projects', this.currentTab);
    }

    componentWillUnmount() {
        this.props.setBlockedUrl(undefined);
    }

    onTabChange = (key: string) => {
        const newTabKey = isStringInEnum(key, ProjectsPageTabsEnum, defaultTab);
        this.props.push(this.getCurrentUrl(newTabKey));
        saveCurrentTabToStore('projects', key);
    };

    createProject = () => {
        this.props.push(
            getPathFromState(
                `/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/new`,
                this.props.location.search
            )
        );
    };

    createTemplate = () => {
        this.props.push(
            getPathFromState(
                `/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.TEMPLATES}/new`,
                this.props.location.search
            )
        );
    };

    createOffer = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
        this.props.push(`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}${paramsString}`);
    };

    createSimpleOrder = async () => {
        let canCreate = true;
        if (this.props.operationFormMnemoKey) {
            canCreate = await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
            );
        }

        if(canCreate){
            // TODO Очистить текущую операцию
            this.props.startNewSimpleOrderOperation();
            this.props.push(`/${this.props.businessAccountId}/order`);
        }
    };

    getCurrentUrl(tab: ProjectsPageTabsEnum) {
        const saveTab = isStringInEnum(tab, ProjectsPageTabsEnum, defaultTab);
        let gridData = getGridStorageData(saveTab);

        return getPathFromState(`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${tab}`, '', {
            ...gridData.filters,
            ...gridData.params,
        });
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(projectsPageUrlRoute, ProjectsPageTabsEnum);
    }

    tabBarExtraContent = () => {
        const { intl, offersModuleEnabled, ordersModuleEnabled, projectsModuleEnabled } = this.props;
        const { currentTab } = this;
        let onClick: Function | undefined;
        let label: string | undefined;
        if (currentTab === ProjectsPageTabsEnum.PROJECTS && projectsModuleEnabled) {
            onClick = this.createProject;
            label = localizeIntl(intl, LocalizationEnum.ASPECT__ACTION__CREATE_PROJECT) as string;
        } else if (currentTab === ProjectsPageTabsEnum.OFFERS && offersModuleEnabled) {
            onClick = this.createOffer;
            label = 'Создать смету';
        } else if (currentTab === ProjectsPageTabsEnum.TEMPLATES) {
            onClick = this.createTemplate;
            label = 'Создать шаблон';
        } else if (currentTab === ProjectsPageTabsEnum.SIMPLE_ORDERS && ordersModuleEnabled) {
            onClick = this.createSimpleOrder;
            label = 'Создать заказ';
        }

        return (
            label && (
                <RoundButton
                    icon={'plus'}
                    title={label}
                    colorScheme={'success'}
                    onClick={() => {
                        onClick?.();
                    }}
                >
                    {label}
                </RoundButton>
            )
        );
    };

    renderContent() {
        const { businessAccountId, location, tabFromStore, offersModuleEnabled, ordersModuleEnabled, push } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            return <Redirect to={this.getCurrentUrl(isStringInEnum(tabFromStore, ProjectsPageTabsEnum, defaultTab))} />;
        }

        // Если модуль КП отключен, то редиректим на дефолтную вкладку
        if (!offersModuleEnabled && currentTab === ProjectsPageTabsEnum.OFFERS) {
            return <Redirect to={this.getCurrentUrl(defaultTab)} />;
        }

        // Если модуль Заказы отключен, то редиректим на дефолтную вкладку
        if (!ordersModuleEnabled && currentTab === ProjectsPageTabsEnum.SIMPLE_ORDERS) {
            return <Redirect to={this.getCurrentUrl(defaultTab)} />;
        }

        return (
            <>
                <Tabs
                    activeKey={currentTab}
                    animated={false}
                    className={'rr-tabs'}
                    onChange={this.onTabChange}
                    type="line"
                    tabBarExtraContent={this.tabBarExtraContent()}
                    destroyInactiveTabPane
                >
                    {
                        ordersModuleEnabled && (
                            <Tabs.TabPane tab={'Заказы'} key={ProjectsPageTabsEnum.SIMPLE_ORDERS}>
                                <SimpleOrdersList gridName={ProjectsPageTabsEnum.SIMPLE_ORDERS} location={location} />
                            </Tabs.TabPane>
                        )
                    }
                    <Tabs.TabPane tab={'Проекты'} key={ProjectsPageTabsEnum.PROJECTS}>
                        <ProjectsList gridName={ProjectsPageTabsEnum.PROJECTS} location={location} />
                    </Tabs.TabPane>
                    {offersModuleEnabled && (
                        <Tabs.TabPane tab={'Сметы проектов'} key={ProjectsPageTabsEnum.OFFERS}>
                            <OffersList
                                gridName={ProjectsPageTabsEnum.OFFERS}
                                urlPath={`${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}`}
                                visible={currentTab === ProjectsPageTabsEnum.OFFERS}
                            />
                        </Tabs.TabPane>
                    )}
                    <Tabs.TabPane tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__TEMPLATES)} key={ProjectsPageTabsEnum.TEMPLATES}>
                        <TemplatesList gridName={ProjectsPageTabsEnum.TEMPLATES} location={location} />
                    </Tabs.TabPane>
                </Tabs>

                {this.props.projectsModuleEnabled && location.pathname === `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/new` ? (
                    <Route
                        exact
                        path={`/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/new`}
                        render={(props) => (
                            <ProjectCreateModal
                                title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__CREATE)}
                                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                                businessAccountId={businessAccountId}
                                successCallback={(data: ProjectInfoRead) => {
                                    push(
                                        `/${data.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${data.id}?tab=description`
                                    );
                                }}
                                filters={createProjectModalFilters}
                                modalName={'create-project'}
                            />
                        )}
                    />
                ) : null}

                {location.pathname === `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.TEMPLATES}/new` ? (
                    <Route
                        exact
                        path={`/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.TEMPLATES}/new`}
                        render={(props) => (
                            <TemplateCreateModal
                                title={localize(LocalizationEnum.PAGE__TEMPLATES__FORM__CREATION_FORM_LABEL)}
                                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                                businessAccountId={businessAccountId}
                                successCallback={(data: TemplateInfoRead) => {
                                    push(
                                        `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.TEMPLATES}/${data.id}?tab=description`
                                    );
                                }}
                                filters={createTemplateModalFilters}
                                modalName={'create-template'}
                            />
                        )}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: IBasePageProps) => {
    return {
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        location: ownProps.location,
        tabFromStore: getCurrentTabsSelector(storeState)?.['projects'],
        offersModuleEnabled: offersModuleEnabledSelector(storeState),
        ordersModuleEnabled: ordersModuleEnabledSelector(storeState),
        projectsModuleEnabled: projectsModuleEnabledSelector(storeState),
        operationFormMnemoKey: storeState.operationForm.mnemoKey
    };
};

const mapDispatchToProps = { resetOperationForm, push, replace, setBlockedUrl, startNewSimpleOrderOperation };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProjectsPage = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));

import { WorkPlanningsRecord, WorkPlanningsStateCodeEnum } from '../../../../../../server';
import { WorkPlanningsSortValue } from '../utils/data/sortData';
import { DeepKeys } from '@tanstack/react-table';
import { WorkPlanningsCalendarFilters } from '../filters/workPlanningsCalendarFilters.types';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { workPlanningsAllStateCodes } from '../../../../workPlannings/data/workPlanningsData';
import { defaultScreenLeft, defaultScreenRight } from '../../../components/Calendar/utils/data/calendarBoundaries';

type WorkPlanningsKeys = DeepKeys<WorkPlanningsRecord>;

export abstract class WorkPlanningsCalendarApiUtils {
    static getSortBy = (sortGroup?: WorkPlanningsSortValue) => {
        const defaultValue: WorkPlanningsKeys = 'lastUpdateDate';
        if (sortGroup == null) return defaultValue;

        const sortByValues: Required<Record<WorkPlanningsSortValue, WorkPlanningsKeys>> = {
            renter: 'counterpartyShortName',
            project: 'projectShortName',
            profession: 'professionShortName',
            status: 'stateCode',
            start: 'startDate',
            end: 'endDate',
        };

        return sortByValues[sortGroup] ?? defaultValue;
    };
    static getSortOrder = (sortByValue: WorkPlanningsKeys): 'DESC' | 'ASC' => {
        const descValues: WorkPlanningsKeys[] = ['lastUpdateDate', 'stateCode'];

        if (descValues.includes(sortByValue)) return 'DESC';

        return 'ASC';
    };
    static getFilters = (params: WorkPlanningsCalendarFilters) => {
        const { typeCode, professionId, crewMemberId, capture, renterId, projectId, screenLeft, screenRight, hideCancelled } = params;

        return ServerUtils.createRequestFilters<WorkPlanningsRecord>([
            capture !== 'all' && capture !== 'allWithNow' && ['startDate', 'LE', screenRight ?? defaultScreenLeft()],
            capture !== 'all' && capture !== 'allWithNow' && ['endDate', 'GE', screenLeft ?? defaultScreenRight()],
            typeCode != null && ['stateCode', 'IN', typeCode],
            professionId != null && ['professionId', 'EQ', professionId],
            renterId != null && ['counterpartyId', 'EQ', renterId],
            projectId != null && ['projectId', 'EQ', projectId],
            crewMemberId != null && ['crewMemberIds', 'IN', [crewMemberId]],
            hideCancelled
                ? ['stateCode', 'IN', workPlanningsAllStateCodes.filter((stateCode) => stateCode !== WorkPlanningsStateCodeEnum.CANCELED)]
                : undefined,
            ['projectIsOffer', 'EQ', false],
        ]);
    };
}

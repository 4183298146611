import React, { FC } from 'react';
import { ArchiveBadge, ArchiveBadgeProps } from '../../../archiveBadge/archiveBadge';
import classNames from 'classnames';
import './ArchiveBadge.less';

export interface EntityDrawerArchiveBadge extends ArchiveBadgeProps {}

export const EntityDrawerArchiveBadge: FC<EntityDrawerArchiveBadge> = ({ className, style }) => {
    return <ArchiveBadge className={classNames('entity-drawer-archive-badge', className)} style={style} />;
};

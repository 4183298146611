import { useAppDispatch } from '../../../store/hooks';
import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { ObjectUtils } from '../../utils/objectUtils';
import { GridSettingsData, updateGridSettings } from '../../../shared/reducers/userSettings/userSettings.reducer';
import forEach from 'lodash/forEach';
import { getGridStorageData } from '../../../components/grid/utils';
import assign from 'lodash/assign';
import { PageURLParamDescriptionObject } from '../../utils/descriptions';

export const useShareFiltersChangesFromTabs = <Params extends object, Enum extends object>(
    params: Params,
    description: PageURLParamDescriptionObject<Params>,
    tabs: Enum
) => {
    const dispatch = useAppDispatch();
    const headerBlockParamsRef = useRef<Params | Omit<Params, keyof Params>>(params);

    useEffect(() => {
        if (isEqual(headerBlockParamsRef.current, params)) return;

        headerBlockParamsRef.current = ObjectUtils.filterByKeys(
            params,
            Object.keys(description) as (keyof typeof description)[],
            'extract'
        );

        const updatedData: GridSettingsData = {};

        forEach(tabs, (tab) => {
            const gridData = { ...getGridStorageData(tab as string) };
            gridData.filters = assign({
                ...gridData.filters,
                ...headerBlockParamsRef.current,
            });
            updatedData[tab as string] = gridData;
        });

        dispatch(updateGridSettings(updatedData));
    }, [description, dispatch, params, tabs]);
};

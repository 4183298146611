import React, {ReactNode} from 'react';
import {InstancePropertiesEntityRecordCustom, OperationElement} from '../../reducers/operationForm.reducer';
import {Popover} from "../../../../../components/popover/Popover";
import {OperationElementCreatePopoverContent} from "./OperationElementCreatePopoverContent";
import './OperationElementCreatePopover.less';

interface OperationElementCreatePopoverProps {
    children: () => ReactNode;
    instanceCount: number;
    availableInstanceCount: number;
    mode: 'create' | 'edit' | 'copy';
    productRecord?: InstancePropertiesEntityRecordCustom;
    elementRecord?: OperationElement;
    disabled?: boolean;
    onCloseCB?: () => void;
}

interface OperationElementCreatePopoverState {
    visible: boolean;
}

export class OperationElementCreatePopover extends React.PureComponent<OperationElementCreatePopoverProps, OperationElementCreatePopoverState> {

    private calendarIsVisible = false;

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    handleVisibleChange = (visible: boolean) => {
        // Если открыт календарь, то не меняем состояние
        if (!this.calendarIsVisible) {
            if (!this.props.disabled || !visible) this.setState({visible});
        }
    };

    onCloseCB = () => {
        this.setState({visible: false});
        this.props.onCloseCB?.();
    };

    onCalendarVisibleChangeCB = (visible: boolean) => {
        this.calendarIsVisible = visible;
    };

    render = () => (
        <Popover
            destroyTooltipOnHide
            overlayClassName={'rr-operationForm-element-create-popover'}
            onVisibleChange={this.handleVisibleChange}
            visible={this.props.disabled === true ? false : this.state.visible}
            content2={
                <OperationElementCreatePopoverContent
                    onCalendarVisibleChangeCB={this.onCalendarVisibleChangeCB}
                    onCloseCB={this.onCloseCB}
                    instanceCount={this.props.instanceCount}
                    availableInstanceCount={this.props.availableInstanceCount}
                    productRecord={this.props.productRecord}
                    elementRecord={this.props.elementRecord}
                    mode={this.props.mode}
                />
            }>
            {this.props.children}
        </Popover>
    );

}

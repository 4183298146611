import React, {useMemo} from 'react';
import {Popover} from "../../../../../../components/popover/Popover";
import {IconCalendar} from "../../../../../../components/icons";
import {Icon} from "antd";
import {InstanceCalendarPopoverContent} from "./instanceCalendarPopoverContent";
import {InstanceRecord} from "../../../../../../server";
import {InstanceState} from "../../product-instances-select/ProductInstancesSelect";

interface NomenclatureCalendarPopoverProps {
    startDate: number;
    endDate: number;
    nomenclature: InstanceRecord;
    getInstancesDataFn: (instances: InstanceRecord[]) => InstanceState[];
}

export const InstanceCalendarPopover = ({startDate, endDate, nomenclature, getInstancesDataFn}: NomenclatureCalendarPopoverProps) => {

    return useMemo(()=>{
        return (
            <div onClick={(e)=>{
                e.stopPropagation();
            }}>
                <Popover
                    destroyTooltipOnHide={true}
                    overlayClassName={'rr-calendar-popover'}
                    content={
                        <InstanceCalendarPopoverContent
                            startDate={startDate}
                            endDate={endDate}
                            nomenclature={nomenclature}
                            getInstancesDataFn={getInstancesDataFn}
                        />
                    }>
                    <Icon className={'rr-grid-table-calendar-icon'} component={IconCalendar} onClick={(e)=>{
                        e.stopPropagation();
                    }}/>
                </Popover>
            </div>
        );
    }, [startDate, endDate, nomenclature]);
};

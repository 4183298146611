import React from 'react';
import { SimpleTable, SimpleTableColumn } from '../../../../components/v2/simpleTable/SimpleTable';
import { ConcurrentOperationObj } from '../../../../server';
import { navigate } from '../../../../core/navigate/navigate';
import moment from 'moment';
import { getOperationStatusTypeData } from '../utils/utils';
import './concurrentOperationsList.less';
import { Icon } from 'antd';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../store/hooks';

interface CurrentOperationsListProps {
    data: ConcurrentOperationObj[];
    getParentModalObj?: () => { destroy: () => void };
}

export const ConcurrentOperationsList = (props: CurrentOperationsListProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    let columns: SimpleTableColumn<ConcurrentOperationObj>[] = [
        {
            id: 'operationType',
            width: 200,
            minWidth: 200,
            maxWidth: 200,
            align: 'left',
            //style: {alignItems: 'flex-start'},
            render: (value, row) => {
                const data = getOperationStatusTypeData(row.operationType);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data.iconComponent && (
                            <Icon
                                component={data.iconComponent}
                                className={'rr-operation-status-color-' + row.operationType}
                                style={{ fontSize: 26, marginRight: 5 }}
                            />
                        )}
                        {row.targetStateCode && (
                            <div className={`rr-dot rr-operation-status-point rr-status-bg-${row.targetStateCode}`}></div>
                        )}
                        <span style={{ fontWeight: 600 }}>{data.name}</span>
                    </div>
                );
            },
        },
        {
            id: 'progressStartedDate',
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            render: (value, row) => {
                return moment(row.progressStartedDate).format('HH:mm');
            },
        },
        {
            id: 'authorId',
            width: 'auto',
            minWidth: 120,
            maxWidth: 220,
            align: 'left',
            //style: {alignItems: 'flex-start'},
            render: (value, row) => {
                return (
                    row.authorId && (
                        <a
                            target={`user-${row.authorId}`}
                            className={'rr-concurrent-operations-list-user-link'}
                            href={navigate.toUser(businessAccountId, row.authorId)}
                            onClick={(e) => {
                                //if(props.getParentModalObj) props.getParentModalObj()?.destroy();
                            }}
                        >
                            {row.authorShortName}
                        </a>
                    )
                );
            },
        },
    ];

    return <SimpleTable className={'rr-concurrent-operations-list'} columns={columns} data={props.data}></SimpleTable>;
};

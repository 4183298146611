import React from 'react';
import { Alert, Button, Icon, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import { BasePage, IBasePageProps } from '../../../../components/page/BasePage';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { IRootState } from '../../../../shared/reducers';
import { getPathFromState, getStateFromPath, isStringInEnum } from '../../../../shared/util/utils';
import KitCreateModal from './kit-create-modal';
import { deleteEntity, loadEntity, reset, setStatusAndArchive } from './reducers/kit.reducer';
import { RoundButton, Spin } from '../../../../components';
import {
    IconArchive,
    IconCalendarCheck,
    IconCheck,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconLevelUp,
    IconUnlock,
    IconUnlockAlt,
} from '../../../../components/icons';
import { showConfirm, showConfirm2 } from '../../../../components/confirm/showConfirm';
import AdditionalEntityActions from '../../../../components/additionalEntityActions/additionalEntityActions';
import { CategoryTypeCodeEnum, KitInfoRead, NomenclatureStateCodeEnum, SimpleTransitionCodeEnum } from '../../../../server';
import { IPageHeaderProps } from '../../../../components/pageHeader/pageHeader';
import KitOfProductPageDescriptionTabNew from './kit-page-description';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import { AdditionalEntityActionsButton } from '../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { createKitModalFilters, editKitModalFilters } from './kit-create-modal-data';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../shared/reducers/userSettings/userSettings.reducer';
import { ProductComposition } from './components/ProductComposition/ProductComposition';
import { LinkToCalendar } from '../../../../components/link/toCalendar/LinkToCalendar';
import { DateUtils } from '../../../../core/utils/dateUtils';
import moment from 'moment/moment';
import { getGridStorageData } from '../../../../components/grid/utils';
import { AccessibilityMapFilters } from '../../calendar/tabs/accessibilityMap/filters/accessibility/accessibilityMapCalendarFilters.types';
import { CalendarPageTabsEnum, KitPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { KitFeaturesTab } from './tabs/features/KitFeaturesTab';
import { KitPageHeaderNavigationBlock } from './components/HeaderNavigationBlock/KitPageHeaderNavigationBlock';

interface IState {}

interface IProps extends StateProps, DispatchProps, IBasePageProps {
    deviceType: 'phone' | 'tablet' | 'desktop';
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const defaultTab = KitPageTabsEnum.DESCRIPTION;

class KitPage extends BasePage<IProps, IState> {
    private tabs = {};

    componentDidMount() {
        super.componentDidMount();

        this.props.loadCategories(this.props.businessAccountId, CategoryTypeCodeEnum.PRODUCT);
        this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.tab) saveCurrentTabToStore('kit', this.props.tab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    onTabsChange = (key: string) => {
        this.tabs[this.props.tab] = this.props.location.search;
        let searchString = this.getSearchString(key as KitPageTabsEnum);

        saveCurrentTabToStore('kit', key);

        this.props.push(getPathFromState(this.props.location.pathname, searchString, { tab: key }));
    };

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/inventory/kits/${this.props.entityId}/edit?tab=description`);
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/inventory/kits/${this.props.entityId}/copy?tab=description`);
    };

    onArchiveButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            if (!entity.archive) {
                //TODO Нормальный текст предупреждения придумать
                showConfirm(
                    this.props.intl,
                    // 'Отправить набор в архив?',
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__MODAL__QUESTIONS__SEND_TO_ARCHIVE),
                    undefined,
                    () => {
                        if (entity)
                            this.props.setStatusAndArchive(
                                this.props.intl,
                                this.props.businessAccountId,
                                entity.id,
                                entity.businessVersion,
                                undefined,
                                true
                            );
                    }
                );
            } else {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    entity.id,
                    entity.businessVersion,
                    undefined,
                    false
                );
            }
        }
    };

    onUnlockButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            this.props.setStatusAndArchive(this.props.intl, this.props.businessAccountId, entity.id, entity.businessVersion, 'ACTIVATE');
        }
    };

    onActivateButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            this.props.setStatusAndArchive(this.props.intl, this.props.businessAccountId, entity.id, entity.businessVersion, 'ACTIVATE');
        }
    };

    getPageHeaderProps(): IPageHeaderProps {
        const { entity } = this.props;

        const [screenLeft, screenRight] = DateUtils.getCalendarBoundaries(moment(Date.now()), 'month');

        const gridData = getGridStorageData(CalendarPageTabsEnum.ACCESSIBILITY_MAP);
        const { stacks } = gridData.filters as AccessibilityMapFilters;

        return {
            archive: Boolean(entity?.archive),
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            rightBlock: (
                <LinkToCalendar
                    calendarPath={getPathFromState(`/${this.props.businessAccountId}/calendar`, '', {
                        tab: CalendarPageTabsEnum.ACCESSIBILITY_MAP,
                        screenLeft,
                        screenRight,
                        kitId: entity?.id,
                        hide: 'cancelled',
                        stacks,
                    })}
                    text={'Расписание'}
                    icon={<Icon component={IconCalendarCheck} style={{ marginTop: -1, color: '#34bfa3' }} />}
                />
            ),
            parentPagePath: entity ? `/${this.props.businessAccountId}/inventory/kits` : undefined,
            NavigationBlock: <KitPageHeaderNavigationBlock kit={entity || undefined} />,
        };
    }

    onLockButtonClick = () => {
        let { entity } = this.props;
        showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.PAGE__MODAL__QUESTIONS__BLOCK), undefined, () => {
            if (entity)
                this.props.setStatusAndArchive(this.props.intl, this.props.businessAccountId, entity.id, entity.businessVersion, 'BLOCK');
        });
    };

    onDeleteButtonClick = async () => {
        let yes = await showConfirm2({ intl: this.props.intl, title: 'Удалить набор безвозвратно?' });
        if (yes) this.props.deleteEntity(this.props.businessAccountId, this.props.entity!.id, this.props.entity!.businessVersion);
    };

    getSearchString(key: KitPageTabsEnum) {
        return key === KitPageTabsEnum.KITS ? this.tabs[key] || '' : '';
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    renderContent() {
        let { entity, loading, updating, businessAccountId, entityId } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            const key = isStringInEnum(this.props.tabFromStore, KitPageTabsEnum, defaultTab);
            return <Redirect to={getPathFromState(this.props.location.pathname, this.getSearchString(key), { tab: key })} />;
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        if (!businessAccountId || !entityId) return <Redirect to={'/'} />;

        return (
            <>
                <Spin spinning={loading || updating}>
                    <div className={'rr-page-product'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}
                        {entity ? (
                            <div className={'rr-page-product'} style={{ minHeight: 400 }}>
                                <div className="card-container">
                                    <Tabs
                                        activeKey={this.props.tab}
                                        onChange={this.onTabsChange}
                                        className={'rr-tabs'}
                                        type="line"
                                        animated={false}
                                        tabBarExtraContent={
                                            <>
                                                <RoundButton colorScheme={'default'} onClick={this.onEditButtonClick}>
                                                    <Icon component={IconEdit} /> {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                                                </RoundButton>

                                                {entity.archive ? (
                                                    <RoundButton
                                                        colorScheme={'FROM_ARCHIVE'}
                                                        className={'ant-btn-page-actions'}
                                                        onClick={this.onArchiveButtonClick}
                                                    >
                                                        <Icon component={IconLevelUp} />
                                                        {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                                                    </RoundButton>
                                                ) : null}

                                                {entity.availableTransitionCodes!.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                                                entity?.stateCode === NomenclatureStateCodeEnum.NEW ? (
                                                    <RoundButton colorScheme={'success'} onClick={this.onActivateButtonClick}>
                                                        <Icon component={IconCheck} />
                                                        {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                                                    </RoundButton>
                                                ) : null}

                                                {entity.availableTransitionCodes!.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                                                entity?.stateCode === NomenclatureStateCodeEnum.NOTAVAILABLE ? (
                                                    <RoundButton colorScheme={'success'} onClick={this.onUnlockButtonClick}>
                                                        <Icon component={IconUnlockAlt} />
                                                        {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                                                    </RoundButton>
                                                ) : null}

                                                <span style={{ marginLeft: '16px' }}>
                                                    <AdditionalEntityActions
                                                        disabled={false}
                                                        content={
                                                            <>
                                                                <Button
                                                                    className={'ant-btn-page-actions'}
                                                                    block
                                                                    onClick={this.onCopyButtonClick}
                                                                >
                                                                    <Icon
                                                                        component={IconControlPointDuplicateBlack}
                                                                        style={{ color: '#34bfa3' }}
                                                                    />{' '}
                                                                    {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                                                                </Button>

                                                                {entity.archivable && !entity.archive ? (
                                                                    <Button
                                                                        className={'ant-btn-page-actions'}
                                                                        onClick={this.onArchiveButtonClick}
                                                                    >
                                                                        <Icon component={IconArchive} style={{ color: '#c4c5d6' }} />
                                                                        {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                                                                    </Button>
                                                                ) : null}
                                                                {entity.availableTransitionCodes!.includes(
                                                                    SimpleTransitionCodeEnum.BLOCK
                                                                ) ? (
                                                                    <Button
                                                                        className={'ant-btn-page-actions'}
                                                                        block
                                                                        onClick={this.onLockButtonClick}
                                                                    >
                                                                        <Icon component={IconUnlock} style={{ color: '#A0A0A0' }} />
                                                                        {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                                                                    </Button>
                                                                ) : null}
                                                                {
                                                                    <Button
                                                                        disabled={!entity.deleteable}
                                                                        className={'ant-btn-page-actions'}
                                                                        block
                                                                        onClick={this.onDeleteButtonClick}
                                                                    >
                                                                        <SystemIcon type={SystemIconType.delete} />
                                                                        <span>Удалить</span>
                                                                    </Button>
                                                                }
                                                            </>
                                                        }
                                                    >
                                                        <AdditionalEntityActionsButton />
                                                    </AdditionalEntityActions>
                                                </span>
                                            </>
                                        }
                                    >
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                            key={KitPageTabsEnum.DESCRIPTION}
                                        >
                                            <KitOfProductPageDescriptionTabNew
                                                responsive={this.props.responsive}
                                                deviceType={this.props.deviceType}
                                            />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.PAGE__KITS__TAB__COMPONENTS)}
                                            key={KitPageTabsEnum.KITS}
                                        >
                                            <ProductComposition kit={entity} />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.PAGE__VARIANTS__CARD_POPUP__FEATURES)}
                                            key={KitPageTabsEnum.FEATURES}
                                        >
                                            <KitFeaturesTab />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </Spin>

                {entity && this.props.copyMode ? (
                    <KitCreateModal
                        title={localize(LocalizationEnum.PAGE__KITS__FORM__FORM_TITLE__CREATE)}
                        businessAccountId={businessAccountId}
                        initialValues={entity as KitInfoRead | undefined}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        copyMode={true}
                        validateAfterCreate={true}
                        filters={createKitModalFilters}
                        modalName={'create-kit'}
                        customFieldGroups={entity.customFieldGroups}
                    />
                ) : null}

                {entity && this.props.editMode ? (
                    <KitCreateModal
                        title={localize(LocalizationEnum.PAGE__KITS__FORM__FORM_TITLE__EDIT)}
                        businessAccountId={businessAccountId}
                        initialValues={entity as KitInfoRead | undefined}
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                        filters={editKitModalFilters}
                        modalName={'create-kit'}
                        customFieldGroups={entity.customFieldGroups}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.kit.entity,
        loading: storeState.kit.loading,
        updating: storeState.kit.updating,
        loadingError: storeState.kit.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: Boolean(ownProps.match.params['action'] === 'edit'),
        copyMode: Boolean(storeState.kit.entity && ownProps.match.params['action'] === 'copy'),
        editCountMode: Boolean(storeState.kit.entity && ownProps.match.params['action'] === 'edit-count'),
        tab:
            searchParams.tab &&
            (searchParams.tab === KitPageTabsEnum.DESCRIPTION ||
                searchParams.tab === KitPageTabsEnum.KITS ||
                searchParams.tab === KitPageTabsEnum.FEATURES)
                ? searchParams.tab
                : KitPageTabsEnum.KITS,
        tabFromStore: getCurrentTabsSelector(storeState)?.['kit'],
    };
};

const mapDispatchToProps = { loadEntity, setStatusAndArchive, reset, push, replace, loadCategories, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(KitPage);

import { EntityActions, EntityActionsHook } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { TransportationInfoRead, TransportationTransitionCodeEnum } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { EntityActionType } from '../../../../../../../index';
import { PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { URLDrawerParams } from '../../../../../../components/page/entityGrid/types/params';
import { transportationsApi, useDeleteTransportationMutation, useSetStatusForTransportationsMutation } from '../api/transportations.api';

type TransportationEntityActions = EntityActions<TransportationInfoRead>;

export const useTransportationsEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const [setStatusForTransportations, { isLoading: isSetStatusForTransportationsLoading }] = useSetStatusForTransportationsMutation();

    const [deleteTransportation, { isLoading: isDeleteTransportationLoading }] = useDeleteTransportationMutation();

    const isLoading = isSetStatusForTransportationsLoading || isDeleteTransportationLoading;

    const editTransportationCallback: TransportationEntityActions['editAction'] = useCallback(
        async (transportation: TransportationInfoRead, options) => {
            dispatch(
                transportationsApi.endpoints.transportationById.initiate(
                    {
                        businessAccountId,
                        entityId: transportation.id,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.delete('modal');
            searchParams.append('modal', modalType);
            searchParams.delete('id');
            searchParams.append('id', String(transportation.id));

            if (options?.withView != null) {
                if (options.withView) {
                    const booleanParam = PageURLParamDescriptions.booleanParam.toString(options.withView);
                    if (booleanParam) {
                        searchParams.append('view', booleanParam);
                    }
                } else {
                    searchParams.delete('view');
                }
            }
            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const editTransportationForDrawerCallback: TransportationEntityActions['editActionForDrawer'] = useCallback(
        (transportation: TransportationInfoRead) => {
            editTransportationCallback(transportation, { withView: true });
        },
        [editTransportationCallback]
    );

    const deleteTransportationCallback: TransportationEntityActions['deleteActionForDrawer'] = useCallback(
        async (transportation: TransportationInfoRead) => {
            deleteTransportation({
                id: transportation.id,
                businessVersionObj: {
                    businessVersion: transportation.businessVersion,
                },
            });
        },
        [deleteTransportation]
    );

    const copyTransportationCallback: TransportationEntityActions['copyActionForDrawer'] = useCallback(
        (transportation: TransportationInfoRead, options) => {
            editTransportationCallback(transportation, { copy: true, ...options });
        },
        [editTransportationCallback]
    );

    const setStatusForTransportationsCallback = useCallback(
        ({ id, businessVersion }: TransportationInfoRead, transitionCode: TransportationTransitionCodeEnum) => {
            setStatusForTransportations({
                workflowDirectiveBulk: {
                    directives: [
                        {
                            id,
                            businessVersion,
                            transitionCode,
                        },
                    ],
                },
            });
        },
        [setStatusForTransportations]
    );

    const onRowAction: TransportationEntityActions['onRowAction'] = useCallback(
        (item: TransportationInfoRead, action: EntityActionType) => {
            const actionHandler: Partial<Record<typeof action, Function>> = {
                edit: () => editTransportationCallback(item),
                delete: () => deleteTransportationCallback(item),
                copy: () => copyTransportationCallback(item),
                [TransportationTransitionCodeEnum.PLAN]: () =>
                    setStatusForTransportationsCallback(item, TransportationTransitionCodeEnum.PLAN),
                [TransportationTransitionCodeEnum.START]: () =>
                    setStatusForTransportationsCallback(item, TransportationTransitionCodeEnum.START),
                [TransportationTransitionCodeEnum.FINISH]: () =>
                    setStatusForTransportationsCallback(item, TransportationTransitionCodeEnum.FINISH),
                [TransportationTransitionCodeEnum.CANCEL]: () =>
                    setStatusForTransportationsCallback(item, TransportationTransitionCodeEnum.CANCEL),
            };

            actionHandler[action]?.();
        },
        [copyTransportationCallback, deleteTransportationCallback, editTransportationCallback, setStatusForTransportationsCallback]
    );

    return useMemo(
        () => ({
            editAction: editTransportationCallback,
            editActionForDrawer: editTransportationForDrawerCallback,
            deleteActionForDrawer: deleteTransportationCallback,
            archiveActionForDrawer: () => {},
            copyActionForDrawer: copyTransportationCallback,
            onRowAction,
            isLoading,
        }),
        [
            copyTransportationCallback,
            deleteTransportationCallback,
            editTransportationCallback,
            editTransportationForDrawerCallback,
            onRowAction,
            isLoading,
        ]
    );
}) satisfies EntityActionsHook<TransportationInfoRead>;

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../core/utils/descriptions';
import { URLDrawerParams } from '../types/params';

export const entityGridDrawerDescription: PageURLParamDescriptionObject<URLDrawerParams> = {
    id: PageURLParamDescriptions.numberParam,
    modal: PageURLParamDescriptions.modalParam,
    view: PageURLParamDescriptions.switchParam,
};
export const entityGridMainParamsDescription: PageURLParamDescriptionObject<URLDrawerParams> &
    typeof PageURLParamDescriptions.basePageParams = {
    ...PageURLParamDescriptions.basePageParams,
    ...entityGridDrawerDescription,
};

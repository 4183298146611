import React, {FC, memo} from 'react';
import classNames from "classnames";
import {AggregationsPopoverDataItem} from "./aggregationsPopover";

interface AggregationsPopoverContentProps {
    data?: AggregationsPopoverDataItem[];
    isError?: boolean;
}

export const AggregationsPopoverContent: FC<AggregationsPopoverContentProps> = memo(({data, isError}) => {
    return (
        <div className={'content'}>
            <div className={'header-container'}>
                <div>
                    <span
                        style={{
                            fontSize: 36,
                        }}
                        className={'rr-icon-system-attachment'}
                    >
                        Σ
                    </span>
                </div>
                <div className={'header'}>
                    <h4>Сумма выборки</h4>
                    <span>Отменённые не учитываются</span>
                </div>
            </div>
            {
                !isError && (
                    <div className={'rows-container'}>
                        {
                            data?.map(item => {
                                return (
                                    <div className={classNames('row', item.summary && 'summary')}>
                                        <span>{item.label}</span>
                                        <b>{item.value}</b>
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            }
            {
                isError && (
                    <div className={'rr-aggregations-popover-error'}>Не удалось загрузить данные</div>
                )
            }
        </div>
    );
});

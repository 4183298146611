import React, {FC, useState} from 'react';
import {ChangeElementsParamPopover} from "../changeElementsParamPopover";
import {useAppDispatch, useAppSelector} from "../../../../../../../store/hooks";
import {isDefined} from "../../../../../../../shared/util/utils";
import {PPC} from "./PPC";
import {NumberInputDiscount} from "../../../../../../../components/numberInputs";
import {setEffectivePriceAndDiscountForSelectedIds} from "../../../../reducers/operationForm.reducer";

interface Props {
    selectedIds: number[];
    onClose?: ()=>void;
}

export const ChangeElementsDiscountPopover: FC<Props> = (props) => {
    return (
        <ChangeElementsParamPopover
            selectedIds={props.selectedIds}
            component={ChangeElementsDiscountPopoverContent}
            componentProps={{onClose: props.onClose}}
        >
            {props.children}
        </ChangeElementsParamPopover>
    );
};

interface ChangeElementsDateParamPopoverProps {
    selectedIds: number[];
    onClose: () => void;
}

export const ChangeElementsDiscountPopoverContent: FC<ChangeElementsDateParamPopoverProps> = ({children, selectedIds, onClose}) => {
    const dispatch = useAppDispatch();
    const elements = useAppSelector((state) => state.operationForm.elements.entities);
    const selectedElements = elements.filter((el) => selectedIds.includes(el.id));
    const defaultValue = selectedElements.every((el) => el.discount === selectedElements[0].discount) ? selectedElements[0].discount : undefined;

    const [value, setValue] = useState<number | undefined>(defaultValue);
    const canApply = isDefined(value);

    return (
        <PPC
            label={'Скидка'}
            input={
                <NumberInputDiscount onChange={(v) => {
                    setValue(v);
                }} value={value}/>
            }
            canApply={canApply}
            onApply={() => {
                if (isDefined(value)) {
                    onClose?.();
                    dispatch(setEffectivePriceAndDiscountForSelectedIds(selectedIds, undefined, value));
                }
            }}
            onCancel={() => {
                onClose?.();
            }}
        />
    );
};

import React, { FC } from 'react';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconMapMarkerSolid } from '../../../../../../../../components/icons';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { ActivityFramesCalendarItem } from '../../../../../components/Calendar/types/items';
import { restItemsGroupId } from '../../../../../components/Calendar/hooks/useCalendarItems';

export const LocationGroupLineWrapper =
    (onClick: (id: number) => void): FC<GroupItemProps<ActivityFramesCalendarItem>> =>
    ({ groupItem, displayType }) =>
        <LocationGroupLine groupItem={groupItem} displayType={displayType} onClick={onClick} />;

interface LocationGroupLineProps {
    onClick: (id: number) => void;
}

const LocationGroupLine: FC<GroupItemProps<ActivityFramesCalendarItem> & LocationGroupLineProps> = (props) => {
    const { groupItem, displayType, onClick } = props;
    const { id, record } = groupItem;
    const { locationName: title, locationId } = record;

    const hasCategory = id !== restItemsGroupId;

    return id ? (
        <GroupLine
            IconComponent={IconMapMarkerSolid}
            displayType={displayType}
            title={{
                text: hasCategory ? title : 'Локация не указана',
            }}
            className={'hovered'}
            onMouseUp={hasCategory ? () => onClick(locationId) : undefined}
            onClick={hasCategory ? (event) => event.stopPropagation() : undefined}
        />
    ) : null;
};

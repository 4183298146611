import React, {FC} from 'react';
import {Icon} from 'antd';
import {IconLockSolid} from '../../../components/icons';
import {useLocalize} from "../../../core/hooks/useLocalize";
import './disabledModule.less';

interface DisabledModuleProps {

}

const className = `rr-disabled-module`;

export const DisabledModule:FC<DisabledModuleProps> = (props) => {
    const L = useLocalize();
    return (
        <div className={className}>
            <div className={`${className}-img`}>
                <Icon component={IconLockSolid} />
            </div>
            <div className={`${className}-title`}>Модуль отключен</div>
            <div>Обратитесь к администратору компании!</div>
        </div>
    );
};

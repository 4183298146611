import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { ContactInfoRead } from '../../../../../../server';
import { ContactDrawerProps } from '../../../../renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { archiveContactEntity, editContactEntity } from '../utils/contactActions';

export const useContactActions = () => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const editEntity = useCallback(
        (contact: ContactInfoRead) => {
            editContactEntity(contact, dispatch, businessAccountId);
        },
        [businessAccountId, dispatch]
    ) satisfies ContactDrawerProps['editContact'];

    const archiveEntity = useCallback(
        (contact: ContactInfoRead) => {
            archiveContactEntity(contact, dispatch, businessAccountId);
        },
        [businessAccountId, dispatch]
    );

    return useMemo(
        () => ({
            edit: editEntity,
            archive: archiveEntity,
        }),
        [archiveEntity, editEntity]
    );
};

import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../../../localization';
import { StatusesFilter } from '../../../../../../components/select/StatusesFilters/v2/StatusesFilter';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ProfessionSelect } from '../../../../../../components/v2/select/professionSelect/ProfessionSelect';
import { CrewMemberSelect } from '../../../../../../components/v2/select/crewMemberSelect/crewMemberSelect';
import { EntityRemoteSelect } from '../../../../../../components/select/EntityRemoteSelect';
import { workPlanningsAllStateCodes } from '../../../../workPlannings/data/workPlanningsData';
import { WorkPlanningsStateBadge } from '../../../../workPlannings/components/WorkPlanningsStateBadge/WorkPlanningsStateBadge';
import classNames from 'classnames';
import { createHiddenField } from '../../../../../../components/modalForm/utils';

export const workPlanningsCalendarFilters: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('isSplitted'),
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.MultiSelect,
                component: StatusesFilter,
                style: { minWidth: 120 },
                className: classNames('rr-statuses-filter', 'rr-select-gray', 'calendar-filters'),
                values: () => {
                    return workPlanningsAllStateCodes.map((code) => ({
                        name: <WorkPlanningsStateBadge type={code} dot={true} />,
                        value: code,
                    }));
                },
            },
            {
                label: 'Специальность',
                placeholder: 'Любая',
                id: 'professionId',
                type: FormItemType.Component,
                component: ProfessionSelect,
                className: 'rr-select-gray',
                style: { minWidth: 120, maxWidth: 360 },
            },
            {
                label: 'Работник',
                placeholder: 'Любой',
                id: 'crewMemberId',
                type: FormItemType.Component,
                component: CrewMemberSelect,
                className: 'rr-select-gray',
                style: { minWidth: 120, maxWidth: 360 },
            },
            {
                label: 'Скрыть отменённые',
                id: 'hideCancelled',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Контрагент',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A8,
                id: 'renterId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    getEntityOperationName: 'getRenterById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                }),
                getInitialValue: (value) => {
                    return value;
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A9,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    let renterId = form.getFieldValue('renterId');
                    if (renterId?.key) renterId = renterId.key;
                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: renterId ? ['renterId;EQ;' + renterId] : undefined,
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
            },
        ],
    },
];

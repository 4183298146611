import React, {FC, memo, useCallback} from 'react';
import { ContactsPageFiltersDescription } from '../filters/contactsFiltersDescription';
import { useSelector } from 'react-redux';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { contactsListColumns } from '../columns/ContactsListColumns';
import { contactsFilters } from '../filters/contactsFilters';
import { contactsApi, useListContactsQuery } from '../api/contactsApi';
import { useAppSelector } from '../../../../../../store/hooks';
import { contactEntitySelector } from '../reducers/contact/contact.selectors';
import { useContactEntityActions } from '../hooks/useContactEntityActions';
import { clearContact } from '../reducers/contact/contact.reducer';
import { ContactCreateModalWrapper } from '../modal/create/ContactCreateModalWrapper';
import { ContactEditModalWrapper } from '../modal/edit/ContactEditModalWrapper';
import { ContactDrawerWrapper } from '../drawer/components/wrappers/ContactDrawerWrapper';
import { useSelectedRecords } from '../../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';
import { ContactUtils } from '../utils/helpers';
import { useContactOnCellAction } from '../hooks/useContactOnCellAction';
import { useContactEntityActionsButtons } from '../hooks/useContactEntityActionsButtons';
import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import {ExportOffloadingPopover} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {CrewMembersApiUtils} from "../../../../crew/tabs/crewMembers/api/crewMembers.api.utils";
import {downloadOffloading} from "../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {
    ContactNotFinancialFieldsTypeCode,
    ExcelExportTemplateTypeCodeEnum
} from "../../../../../../server";
import {useIntl} from "react-intl";
import {canViewFinancialDataSelector} from "../../../../../../shared/reducers/permissions.reducer";

interface ContactsListComponentProps {
    gridName: RentersPageTabsEnum;
}

const ContactsListComponent: FC<ContactsListComponentProps> = memo((props) => {
    const { gridName } = props;

    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(contactEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: ContactsPageFiltersDescription,
        gridName,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListContactsQuery(
        {
            businessAccountId,
            params: queryParams,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const rootPath = ContactUtils.getRootPath(businessAccountId);
    const entityActions = useContactEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords(entitySelection, queryData.data?.entitiesData.records);
    const { buttons: entityActionsButtons, isLoading: isEntityActionsButtonsLoading } = useContactEntityActionsButtons({
        selectedRecords,
        gridRef,
    });

    const onCellAction = useContactOnCellAction();

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = CrewMembersApiUtils.createRequestFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.CONTACTTEMPLATE,
                fileName: 'Экспорт контактов',
                fileType,
                filters,
                financialFieldsEnum: ContactNotFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: ContactNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: contactsListColumns,
                    filtersData: contactsFilters,
                    pageParamsDescription: ContactsPageFiltersDescription,
                },
                metaData: {
                    entityType: 'contact',
                    gridName,
                    rootPath,
                },
                queryData,
                entitiesLoading: isEntityActionsButtonsLoading || entityActions.isLoading,
                entityActions: entityActions,
                actionButtons: entityActionsButtons,
                onCellAction,
                exportBlock: <ExportOffloadingPopover storageKey={'contactsOffloading'} onDownload={onOffloadingDownload} />,
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: ContactCreateModalWrapper,
                    },
                    edit: {
                        component: ContactEditModalWrapper,
                    },
                },
                entityLoadActions: {
                    loadEntity: contactsApi.endpoints.getContactById.initiate,
                    clearEntity: clearContact,
                },
            }}
            drawerProps={{
                drawer: ContactDrawerWrapper,
                entityActions: entityActions,
            }}
        />
    );
});

export const ContactsList = withEntityListProviders(ContactsListComponent);

import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'antd';
import Spin from '../../../../../components/spin/spin';
import { CalendarFilters } from '../../components/CalendarFilters/CalendarFilters';
import { accessibilityMapCalendarFiltersDescription } from './filters/accessibility/accessibilityMapCalendarFilters.description';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import { accessibilityMapCalendarFilters } from './filters/accessibility/accessibilityMapCalendarFilters';
import { useFiltersChanges } from './hooks/useFiltersChanges';
import { ItemsNotFoundBlock } from '../../../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import { useCalendarQueryParams } from '../../components/Calendar/hooks/useCalendarQueryParams';
import Timeline, { TimelineHeaders } from 'react-calendar-timeline';
import { CalendarHeader } from '../../components/CalendarHeader/CalendarHeader';
import { CalendarMarker } from '../../components/CalendarMarker/CalendarMarker';
import { useDateHeaderUnits } from '../../components/Calendar/hooks/useDateHeaderUnits';
import { useIntervalClick } from '../../components/Calendar/hooks/useIntervalClick';
import {
    CALENDAR_LINE_HEIGHT_RATIO,
    CALENDAR_SIDEBAR_WIDTH_SMALL,
    CalendarDisplayType,
    CalendarVisibleItemsLimit,
} from '../../components/Calendar/utils/data/calendarConstants';
import { CalendarLineUtils } from '../../components/CalendarItemRenderer/utils/calendarLineUtils';
import { useCalendarGroups } from './hooks/useCalendarGroups';
import 'react-calendar-timeline/lib/Timeline.css';
import '../../components/Calendar/Calendar.less';
import '../../components/Calendar/CalendarLib.less';
import { CalendarItemRenderer } from '../../components/CalendarItemRenderer/CalendarItemRenderer';
import { AccessibilityMapCalendarItemRenderer } from './components/ItemRenderer/AccessibilityMapCalendarItemRenderer';
import { BASE_ITEM_CLASS } from '../../components/Calendar/utils/data/calendarClasses';
import { TimeRangeContext } from '../../components/CalendarItemRenderer/context/TimeRangeContext';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { AccessibilityMapCalendarGroupRenderer } from './components/GroupRenderer/AccessibilityMapCalendarGroupRenderer';
import './AccessibilityMapCalendar.less';
import { useListRentElementsWithAccessibilityQuery } from '../elements/api/elementsCalendar.api';
import { useIntl } from 'react-intl';
import { elementsCalendarScreenLeft, elementsCalendarScreenRight } from '../elements/reducers/elementsCalendar.reducer.selectors';
import { useManuallyParams } from './hooks/useManuallyParams';
import { useSetVisibleInstance } from './hooks/useSetVisibleInstance';
import { AccessibilityElementsCalendar } from './components/ElementsCalendar/AccessibilityElementsCalendar';
import { StacksCheckboxes } from './components/StacksCheckboxes/StacksCheckboxes';
import { useDisableMouseScrollEffect } from '../../components/Calendar/hooks/useDisableMouseScrollEffect';
import classNames from 'classnames';
import { useCalendarData } from './hooks/useCalendarData';
import { mapValues } from 'lodash';
import { LocalizationEnum, localize } from '../../../../../localization';
import { useCurrentStacks } from './hooks/useCurrentStacks';
import { InterfaceBlockCode } from '../../../../../components/pageHeader/components/InfoSelectDropdown/utils/constants';
import { InfoSelectDropdown } from '../../../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown';

import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';

export const accessibilityMapCalendarPageName = CalendarPageTabsEnum.ACCESSIBILITY_MAP;
const sidebarWidth = CALENDAR_SIDEBAR_WIDTH_SMALL;

export const AccessibilityMapCalendar: FC = memo(() => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const storeScreenLeft = useAppSelector(elementsCalendarScreenLeft);
    const storeScreenRight = useAppSelector(elementsCalendarScreenRight);
    const scrollRef = useRef<HTMLDivElement>();
    const filtersFormRef = useRef<WrappedFormUtils>();
    const [visibleItemsLimit, setVisibleItemsLimit] = useState<CalendarVisibleItemsLimit>(CalendarVisibleItemsLimit.FIRST_LIMIT);
    const greyFiltersFormRef = useRef<WrappedFormUtils>();
    const hideFiltersFormRef = useRef<WrappedFormUtils>();

    const manuallyParams = useManuallyParams(filtersFormRef);

    const pageParamsObject = usePageURLParams({
        paramsDescription: accessibilityMapCalendarFiltersDescription,
        pageName: accessibilityMapCalendarPageName,
        manuallyParams,
        initialValues: {
            group: 'project'
        }
    });
    const { pageParams, updatePageParams } = pageParamsObject;
    const { productId, kitId, screenLeft, screenRight } = pageParams;

    const queryParams = useCalendarQueryParams({
        ...pageParams,
    });

    const { data: { elementsData, accessibilityData } = {}, isFetching } = useListRentElementsWithAccessibilityQuery(
        {
            businessAccountId,
            intl,
            params: queryParams,
            visibleItemsLimit,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            skip: productId == null && kitId == null,
        }
    );

    const currentAccessibilityData = useMemo(
        () => (productId == null && kitId == null ? [] : accessibilityData),
        [accessibilityData, kitId, productId]
    );

    const [stacks, setStacks] = useCurrentStacks(pageParams);

    const [displayType, setDisplayType] = useState<CalendarDisplayType>(pageParams.displayType ?? CalendarDisplayType.COMPACT);

    const accessibilityEntitySelected = productId != null || kitId != null;

    const { deferredItems, visibleTimeStart, visibleTimeEnd } = useCalendarData(
        currentAccessibilityData,
        stacks,
        screenLeft ?? storeScreenLeft,
        screenRight ?? storeScreenRight,
        accessibilityEntitySelected,
        isFetching
    );

    const accessibilityGroups = useCalendarGroups(stacks, displayType);

    const lineHeight = CalendarLineUtils.map.lineHeightFromDisplayType[displayType];
    const onIntervalClick = useIntervalClick(updatePageParams);
    const { primaryDateHeaderUnit, secondaryDateHeaderUnit } = useDateHeaderUnits(visibleTimeStart, visibleTimeEnd);

    const setVisibleInstance = useSetVisibleInstance(filtersFormRef);

    const timeRangeContextValue = useMemo(
        () => ({
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [visibleTimeEnd, visibleTimeStart]
    );

    const defaultValues = useMemo(() => mapValues(accessibilityMapCalendarFiltersDescription, () => undefined), []);

    useEffect(() => {
        greyFiltersFormRef.current?.setFieldsValue(pageParams);
        hideFiltersFormRef.current?.setFieldsValue(pageParams);
    }, [greyFiltersFormRef, hideFiltersFormRef, pageParams]);

    useEffect(() => {
        if (pageParams.productId) setVisibleInstance(pageParams.productId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDisableMouseScrollEffect(scrollRef);

    return (
        <Spin wrapperClassName={'clickable-spin'} spinning={isFetching} delay={0}>
            <Card>
                <div className={classNames('calendar-filters-container', 'offset-left')}>
                    <span
                        style={{
                            height: '100%',
                            width: sidebarWidth,
                        }}
                    >
                        {localize(LocalizationEnum.PAGE__KITS__COMPONENTS_TAB__SELECT_NOMENCLATURE)}
                    </span>
                    <CalendarFilters
                        pageName={accessibilityMapCalendarPageName}
                        pageParamsDescription={accessibilityMapCalendarFiltersDescription}
                        pageParamsObject={pageParamsObject}
                        filtersData={accessibilityMapCalendarFilters}
                        useFiltersChanges={useFiltersChanges}
                        filtersFormRef={filtersFormRef}
                        hiddenFieldsForCount={['renterId', 'projectId', 'subrentId', 'hide']}
                        resetCallback={() => {
                            greyFiltersFormRef.current?.setFieldsValue(defaultValues);
                            hideFiltersFormRef.current?.setFieldsValue(defaultValues);
                        }}
                    />
                    <div style={{ textAlign: 'right', flexGrow: 0, paddingTop: 12, marginLeft: 0 }}>
                        <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_CALENDAR_AVAILABILITY_SCHEDULE} />
                    </div>
                </div>
                <div
                    className={classNames('calendar-timeline', 'accessibility', {
                        'not-found': !accessibilityEntitySelected,
                    })}
                >
                    <div className={'display-type-button-container'}>
                        <StacksCheckboxes pageParamsObject={pageParamsObject} stacks={stacks} setStacks={setStacks} />
                    </div>
                    <TimeRangeContext.Provider value={timeRangeContextValue}>
                        <Timeline
                            canMove={false}
                            canResize={false}
                            groups={accessibilityGroups}
                            itemHeightRatio={CALENDAR_LINE_HEIGHT_RATIO}
                            items={deferredItems}
                            lineHeight={lineHeight}
                            sidebarWidth={sidebarWidth}
                            horizontalLineClassNamesForGroup={() => [BASE_ITEM_CLASS]}
                            scrollRef={(el) => (scrollRef.current = el)}
                            visibleTimeStart={visibleTimeStart}
                            visibleTimeEnd={visibleTimeEnd}
                            groupRenderer={({ group }) => <AccessibilityMapCalendarGroupRenderer group={group} items={deferredItems} />}
                            itemRenderer={CalendarItemRenderer({
                                itemRenderer: AccessibilityMapCalendarItemRenderer,
                                displayType,
                                onPopoverVisibleChange: () => {},
                                onItemClick: () => {},
                                groupsData: {},
                                sortByValue: undefined,
                            })}
                        >
                            <TimelineHeaders>
                                <CalendarHeader.Primary
                                    unit={primaryDateHeaderUnit}
                                    onIntervalClick={onIntervalClick}
                                    allNothing={!visibleTimeEnd || !visibleTimeStart}
                                />
                                <CalendarHeader.Secondary
                                    primaryUnit={primaryDateHeaderUnit}
                                    unit={secondaryDateHeaderUnit}
                                    onIntervalClick={onIntervalClick}
                                    allNothing={!visibleTimeEnd || !visibleTimeStart}
                                    sidebarWidth={sidebarWidth}
                                />
                            </TimelineHeaders>
                            {deferredItems?.length && <CalendarMarker />}
                        </Timeline>
                    </TimeRangeContext.Provider>
                    {!accessibilityEntitySelected && (
                        <ItemsNotFoundBlock
                            avatarSize={'none'}
                            title={'Выберите по какой номенклатуре показывать расписание'}
                            message={'Укажите набор, продукт, вариант или экземпляр'}
                            className={'no-product-block'}
                            style={{
                                top:
                                    60 +
                                    (CalendarLineUtils.map.groupHeightFromDisplayType.base[displayType] * accessibilityGroups.length) / 2 -
                                    15,
                                left: `calc(100% - (100% - ${sidebarWidth}px)/2)`,
                            }}
                        />
                    )}
                </div>
            </Card>
            <AccessibilityElementsCalendar
                displayType={displayType}
                elementsData={elementsData}
                isLoading={isFetching}
                pageParams={pageParams}
                accessibilityEntitySelected={accessibilityEntitySelected}
                setDisplayType={setDisplayType}
                setVisibleItemsLimit={setVisibleItemsLimit}
                updatePageParams={updatePageParams}
                visibleItemsLimit={visibleItemsLimit}
                greyFiltersFormRef={greyFiltersFormRef}
                hideFiltersFormRef={hideFiltersFormRef}
            />
        </Spin>
    );
});

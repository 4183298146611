import React from 'react';
import { IconFileAlt } from '../../../../icons';
import { Icon } from 'antd';
import { DocumentTypeCodeEnum } from '../../../../../server/api';
import { DownloadDocumentPopover, IDownloadDocumentParamsItem } from '../../DownloadDocumentPopover';
import { IRootState } from '../../../../../shared/reducers';
import {
    crewModuleEnabledSelector,
    expensesModuleEnabledSelector,
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { connect } from 'react-redux';
import { ExportActPopoverOptions } from '../ExportActPopover/utils/data';
import { availableFileTypes, defaultOptions, EstimateDocumentOnDownload } from './utils/data';
import {canViewFinancialData} from "../../../../../shared/util/permissionUtils";

interface IProps {
    onDownload: EstimateDocumentOnDownload;
    options?: IDownloadDocumentParamsItem[];
}

export class _ExportEstimatePopover extends React.Component<IProps & StateProps> {
    render() {
        const { options: _options, crewModuleEnabled, expensesModuleEnabled } = this.props;

        const options = (_options ?? defaultOptions).filter(({ key }) => {
            if (!crewModuleEnabled && key === ExportActPopoverOptions.WORK_PLANNINGS) return false;
            if (key === ExportActPopoverOptions.EXPENSES && (!expensesModuleEnabled || !this.props.canViewFinancialData)) return false;

            return true;
        });

        return (
            <DownloadDocumentPopover
                documentType={DocumentTypeCodeEnum.ESTIMATE}
                availableFileTypes={[...availableFileTypes]}
                withTemplate
                storageKey={'estimate'}
                options={options}
                title={
                    <>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconFileAlt} />
                        <div>Скачать смету</div>
                    </>
                }
                onDownload={this.props.onDownload}
                showPeriod={this.props.canViewFinancialData}
                showLegalDetails
                isCompact
            >
                <span className={'rr-grid-export-button'}>
                    <Icon component={IconFileAlt} />
                    <span>Смета</span>
                </span>
            </DownloadDocumentPopover>
        );
    }
}
const mapStateToProps = (storeState: IRootState) => {
    return {
        crewModuleEnabled: crewModuleEnabledSelector(storeState),
        expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    };
};
const connector = connect(mapStateToProps);

type StateProps = ReturnType<typeof mapStateToProps>;

export const ExportEstimatePopover = connector(_ExportEstimatePopover);

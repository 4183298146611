import React from "react";
import {Tree} from "antd";

export const renderTreeNodes = (data) => {
    return data.map(item => {
        if (item.children) {
            return (
                <Tree.TreeNode title={item.title} key={item.key} dataRef={item} icon={null}>
                    {renderTreeNodes(item.children)}
                </Tree.TreeNode>
            );
        }
        return (
            <Tree.TreeNode {...item} />
        );
    });
};
import React, { FC } from 'react';
import { Route } from 'react-router';
import { CrewMemberEditModalWrapper } from '../../../../../../modal/components/wrappers/CrewMemberEditModalWrapper';
import { CrewMemberDescriptionModalType } from '../../CrewMemberDescription';
import { useLocation } from 'react-router-dom';
import { CrewMemberUtils } from '../../../../../../utils/helpers';
import { useSelector } from 'react-redux';
import { businessAccountIdSelector } from '../../../../../../../../../../../shared/reducers/system.reducer';
import { CrewMemberInfoRead } from '../../../../../../../../../../../server';

interface CrewMemberModalProps {
    crewMember: CrewMemberInfoRead;
}

export const CrewMemberModal: FC<CrewMemberModalProps> = (props) => {
    const { crewMember } = props;

    const location = useLocation();
    const businessAccountId = useSelector(businessAccountIdSelector);
    const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries());

    const currentRootPath = CrewMemberUtils.getRootPath(businessAccountId);

    return (
        <>
            {searchParams.modal === 'edit' && searchParams.id && searchParams.type === CrewMemberDescriptionModalType.CREW_MEMBER && (
                <Route path={currentRootPath} render={() => <CrewMemberEditModalWrapper entity={crewMember} />} />
            )}
        </>
    );
};

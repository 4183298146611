import {Icon} from 'antd';
import React from 'react';
import {ActionButtonsData} from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import {LocalizationEnum} from '../../../../localization';
import {
    IconBorderStyleSolid,
    IconCheck,
    IconCheckSquare,
    IconEdit,
    IconEraser,
    IconFlagCheckered,
    IconPlus,
    IconRocket,
} from '../../../../components/icons';
import {StateBadgeData} from '../../../../components/v2/stateBadge/stateBadge';
import {ProjectStateCodeEnum, WorkPlanningsStateCodeEnum, WorkPlanningsTransitionCodeEnum} from '../../../../server';
import {Color} from '../../../../core/types/color';

export enum WorkPlanningActionType {
    create = 'createWorkPlanning',
    edit = 'editWorkPlanning',
    activate = 'activateWorkPlanning',
    plan = 'planWorkPlanning',
    start = 'startWorkPlanning',
    toDraft = 'toDraftWorkPlanning',
    cancel = 'cancelWorkPlanning',
    finish = 'finishWorkPlanning',
}

export const workPlanningActionsMap: ActionButtonsData<WorkPlanningActionType> = {
    [WorkPlanningActionType.create]: {
        name: 'Создать работы',
        icon: <Icon component={IconPlus} />,
        color: Color.Green,
    },
    [WorkPlanningActionType.edit]: {
        localizationId: LocalizationEnum.ASPECT__ACTION__EDIT,
        icon: <Icon component={IconEdit} />,
        color: Color.Purple1,
    },
    [WorkPlanningActionType.activate]: {
        name: 'Активировать',
        icon: <Icon component={IconCheck} />,
        color: Color.Orange1,
    },
    [WorkPlanningActionType.plan]: {
        name: 'Запланировать',
        icon: <Icon component={IconCheckSquare} />,
        color: Color.Purple3,
    },
    [WorkPlanningActionType.start]: {
        name: 'Стартовать',
        icon: <Icon component={IconRocket} />,
        color: Color.Orange1,
    },
    [WorkPlanningActionType.toDraft]: {
        name: 'Черновик',
        icon: <Icon component={IconBorderStyleSolid} />,
        color: Color.WhiteGrey1,
    },
    [WorkPlanningActionType.cancel]: {
        name: 'Отменить',
        icon: <Icon component={IconEraser} />,
        color: Color.Grey2,
    },
    [WorkPlanningActionType.finish]: {
        name: 'Завершить',
        icon: <Icon component={IconFlagCheckered} />,
        color: Color.Green,
    },
};

export const workPlanningStatesMap: StateBadgeData<WorkPlanningsStateCodeEnum> = {
    [WorkPlanningsStateCodeEnum.DRAFT]: {
        color: Color.WhiteGrey1,
        name: 'Черновик',
    },
    [WorkPlanningsStateCodeEnum.PLANNED]: {
        color: Color.Purple3,
        name: 'Запланированы',
    },
    [WorkPlanningsStateCodeEnum.INPROGRESS]: {
        color: Color.Orange1,
        name: 'В работе',
    },
    [WorkPlanningsStateCodeEnum.FINISHED]: {
        color: Color.Green,
        name: 'Завершены',
    },
    [WorkPlanningsStateCodeEnum.CANCELED]: {
        color: Color.Grey2,
        name: 'Отменены',
    },
};

export const workPlanningActionToTransitionCodeMap: Partial<Record<WorkPlanningActionType, WorkPlanningsTransitionCodeEnum>> = {
    [WorkPlanningActionType.toDraft]: WorkPlanningsTransitionCodeEnum.TODRAFT,
    [WorkPlanningActionType.plan]: WorkPlanningsTransitionCodeEnum.PLAN,
    [WorkPlanningActionType.start]: WorkPlanningsTransitionCodeEnum.START,
    [WorkPlanningActionType.finish]: WorkPlanningsTransitionCodeEnum.FINISH,
    [WorkPlanningActionType.cancel]: WorkPlanningsTransitionCodeEnum.CANCEL,
};

export const workPlanningsAllStateCodes: ReadonlyArray<WorkPlanningsStateCodeEnum> = [
    WorkPlanningsStateCodeEnum.DRAFT,
    WorkPlanningsStateCodeEnum.PLANNED,
    WorkPlanningsStateCodeEnum.INPROGRESS,
    WorkPlanningsStateCodeEnum.FINISHED,
    WorkPlanningsStateCodeEnum.CANCELED,
];

export abstract class WorkPlanningsCreateStateCodes {
    static newStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.DRAFT];
    static coordinationStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.DRAFT, WorkPlanningsStateCodeEnum.PLANNED];
    static inProgressStateCodes: WorkPlanningsStateCodeEnum[] = [
        WorkPlanningsStateCodeEnum.DRAFT,
        WorkPlanningsStateCodeEnum.PLANNED,
        WorkPlanningsStateCodeEnum.INPROGRESS,
        WorkPlanningsStateCodeEnum.FINISHED,
    ];
    static finishedStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.DRAFT, WorkPlanningsStateCodeEnum.FINISHED];
    static cancelledStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.CANCELED];
    static stateCodesByProjectStateCodeMap: Record<ProjectStateCodeEnum, WorkPlanningsStateCodeEnum[]> = {
        [ProjectStateCodeEnum.DRAFT]: this.newStateCodes,
        [ProjectStateCodeEnum.INPROGRESS]: this.inProgressStateCodes,
        [ProjectStateCodeEnum.FINISHED]: this.finishedStateCodes,
        [ProjectStateCodeEnum.APPROVED]: this.finishedStateCodes,
        [ProjectStateCodeEnum.CANCELED]: this.cancelledStateCodes,
        [ProjectStateCodeEnum.BOOKED]: this.inProgressStateCodes,
        [ProjectStateCodeEnum.RENTED]: this.inProgressStateCodes,
        [ProjectStateCodeEnum.COORDINATION]: this.coordinationStateCodes,
    };
}

export abstract class WorkPlanningsEditStateCodes {
    static newStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.DRAFT, WorkPlanningsStateCodeEnum.CANCELED];
    static coordinationStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.DRAFT, WorkPlanningsStateCodeEnum.PLANNED, WorkPlanningsStateCodeEnum.CANCELED];
    static inProgressStateCodes: WorkPlanningsStateCodeEnum[] = [...workPlanningsAllStateCodes];
    static finishedStateCodes: WorkPlanningsStateCodeEnum[] = [
        WorkPlanningsStateCodeEnum.DRAFT,
        WorkPlanningsStateCodeEnum.CANCELED,
        WorkPlanningsStateCodeEnum.FINISHED,
    ];
    static cancelledStateCodes: WorkPlanningsStateCodeEnum[] = [WorkPlanningsStateCodeEnum.CANCELED];
    static stateCodesByProjectStateCodeMap: Record<ProjectStateCodeEnum, WorkPlanningsStateCodeEnum[]> = {
        [ProjectStateCodeEnum.DRAFT]: this.newStateCodes,
        [ProjectStateCodeEnum.INPROGRESS]: this.inProgressStateCodes,
        [ProjectStateCodeEnum.FINISHED]: this.finishedStateCodes,
        [ProjectStateCodeEnum.APPROVED]: this.finishedStateCodes,
        [ProjectStateCodeEnum.CANCELED]: this.cancelledStateCodes,
        [ProjectStateCodeEnum.BOOKED]: this.inProgressStateCodes,
        [ProjectStateCodeEnum.RENTED]: this.inProgressStateCodes,
        [ProjectStateCodeEnum.COORDINATION]: this.coordinationStateCodes,
    };
}

import { FileTypeCodeEnum } from '../../../../../../server';
import { DownloadDocumentUtils } from '../../../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { showNotification } from '../../../../../notification/showNotification';

export const onBillDownload = async (
    businessAccountId: number,
    id: number,
    fileType: FileTypeCodeEnum,
    templateId: number,
    isCommonTemplate?: boolean,
    legalDetailId?: number
) => {
    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        try {
            await DownloadDocumentUtils.billGenerateAndDownload(
                {
                    businessAccountId,
                    id,
                    common: Boolean(isCommonTemplate),
                    documentTemplateId: templateId,
                    legalDetailId,
                },
                'Экспорт счёта',
                FileTypeCodeEnum.PDFTYPE
            ).catch(() => showNotification('error', 'Ошибка генерации файла'));
        } catch (e) {}
    }
};

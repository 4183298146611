import React, {CSSProperties} from 'react';
import {InventoryMovementEventTypeCodeEnum} from "../../../../server";
import {Icon as IconComponent} from "antd";
import classNames from "classnames";
import {CustomIconComponentProps} from "antd/lib/icon";
import {
    IconCartPlusSolid,
    IconMinusSquare,
    IconNounBreak,
    IconPlusSquare,
    IconSearchMinusSolid,
    IconShoppingBasketSolid,
    IconToolsSolid,
    IconWrench
} from "../../../../components/icons";
import './inventoryMovementIcon.less';

interface InventoryMovementIconProps {
    code: InventoryMovementEventTypeCodeEnum;
    style?: CSSProperties;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const InventoryMovementIcon = ({code, ...props}: InventoryMovementIconProps) => {

    let component: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined;
    let className: string | undefined = `rr-icon-inventoryMovement-${code}`;

    if (code === InventoryMovementEventTypeCodeEnum.LOSS) {
        component = IconSearchMinusSolid;
    } else if (code === InventoryMovementEventTypeCodeEnum.DAMAGE) {
        component = IconNounBreak;
    } else if (code === InventoryMovementEventTypeCodeEnum.SALE) {
        component = IconShoppingBasketSolid;
    } else if (code === InventoryMovementEventTypeCodeEnum.ACQUISITION) {
        component = IconCartPlusSolid;
    } else if (code === InventoryMovementEventTypeCodeEnum.MAINTENANCE) {
        component = IconWrench;
    } else if (code === InventoryMovementEventTypeCodeEnum.MAINTENANCECOMPLETION) {
        component = IconToolsSolid;
    } else if (code === InventoryMovementEventTypeCodeEnum.STOCKTAKING) {
        component = IconPlusSquare;
    } else if (code === InventoryMovementEventTypeCodeEnum.WRITEOFF) {
        component = IconMinusSquare;
    }

    return <IconComponent className={classNames('rr-icon-', className, props.className)} style={props.style}
                          component={component} onClick={props.onClick}/>;
};

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { ElementsCalendarFilters } from './elementsCalendarFilters.types';
import { InstanceTrackingTypeCodeEnum, RentStateCodeEnum } from '../../../../../../server';
import { CalendarDisplayType } from '../../../components/Calendar/utils/data/calendarConstants';
import { HideValue } from './elementsCalendarFilters.data';
import { ProblemEnum } from '../../../../../../types';

export const ElementsCalendarFiltersDescription: Required<PageURLParamDescriptionObject<ElementsCalendarFilters>> = {
    search: PageURLParamDescriptions.searchParam(),
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    typeCode: PageURLParamDescriptions.arrayOfEnumParam(RentStateCodeEnum),
    problem: PageURLParamDescriptions.enumSelectParam(ProblemEnum),
    hide: PageURLParamDescriptions.enumSelectParam(HideValue),
    renterId: PageURLParamDescriptions.numberParam,
    projectId: PageURLParamDescriptions.numberParam,
    elementId: PageURLParamDescriptions.numberParam,
    subrentId: PageURLParamDescriptions.numberParam,
    operationId: PageURLParamDescriptions.numberParam,
    kitId: PageURLParamDescriptions.numberParam,
    productId: PageURLParamDescriptions.numberParam,
    variantId: PageURLParamDescriptions.numberParam,
    instanceId: PageURLParamDescriptions.numberParam,
    hideInactive: PageURLParamDescriptions.booleanParam,
    productInstanceTrackingTypeCode: PageURLParamDescriptions.enumSelectParam(InstanceTrackingTypeCodeEnum),
    group: PageURLParamDescriptions.searchParam(),
    capture: PageURLParamDescriptions.searchParam(),
    displayType: PageURLParamDescriptions.enumSelectParam(CalendarDisplayType),
    problemInstances: PageURLParamDescriptions.booleanParam,
    categoryIds: PageURLParamDescriptions.numbersSelectParam,
};

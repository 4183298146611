import React from 'react';
import {BasePage, IBasePageProps} from '../../../../components/page/BasePage';
import GridLayout from "react-grid-layout";
import './grid-page.less';

interface IProps extends IBasePageProps {
}

const layout = [
    {i: "a", x: 0, y: 0, w: 1, h: 2, static: !true},
    {i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
    {i: "c", x: 4, y: 0, w: 1, h: 2}
];

//

export class GridPage extends BasePage<IProps> {

    componentDidMount() {
        super.componentDidMount();
    }

    renderContent() {
        return (
            <div>
                <GridLayout
                    className="layout"
                    layout={layout}
                    cols={12}
                    //rowHeight={30}
                    width={1200}
                >
                    <div key="a">a</div>
                    <div key="b">b</div>
                    <div key="c">c</div>
                </GridLayout>
            </div>
        );
    }
}

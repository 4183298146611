import { useMemo } from 'react';
import { GridProps } from '../../../grid/Grid';
import { usePageUrlParamsContext } from '../components/context/PageUrlParamsContext';

interface EntityGridSettings extends Pick<GridProps, 'currentPage' | 'defaultSortDesc' | 'defaultSorted' | 'pageSize'> {}

export const useSettings = <PageParams extends object>(): EntityGridSettings => {
    const { pageParams } = usePageUrlParamsContext<PageParams>();

    return useMemo(
        () => ({
            currentPage: pageParams.page,
            defaultSortDesc: pageParams.sortOrder === 'DESC',
            defaultSorted: pageParams.sortBy,
            pageSize: pageParams.limit,
        }),
        [pageParams.limit, pageParams.page, pageParams.sortBy, pageParams.sortOrder]
    );
};

import React from 'react';
import { CustomHeader, CustomHeaderPropsChildrenFnProps, Unit } from 'react-calendar-timeline';
import moment from 'moment';

const getDateFormat = (unit: Unit, labelWidth: number, date): string => {
    let formatString: string = 'HH';

    if (unit === 'hour') {
        if (labelWidth > 40) formatString = 'HH:00';
        else {
            formatString = 'HH';
            if (labelWidth < 24) formatString = 'H';
        }
    } else if (unit === 'day') {
        if (labelWidth > 50) formatString = 'dd D';
        else formatString = 'DD';
    } else if (unit === 'month') {
        if (labelWidth > 100) formatString = 'MMMM';
        else if (labelWidth > 50) formatString = 'MMM';
        else if (labelWidth > 20) formatString = 'MM';
        else formatString = 'M';
    }
    return formatString;
};

interface IProps {
    unit: Unit;
    primaryUnit: Unit;
    onIntervalClick(unit: Unit, primary: boolean, startTime: moment.Moment, endTime: moment.Moment): void;
    sidebarWidth: number;
    allNothing?: boolean;
}

export const CalendarSecondaryHeader = (props: IProps) => {
    const { unit, primaryUnit, onIntervalClick, allNothing, sidebarWidth } = props;

    return (
        <CustomHeader height={30} {...props}>
            {(props?: CustomHeaderPropsChildrenFnProps<null>) => {
                if (props) {
                    const longScreenTime = (props.timelineContext.canvasTimeEnd - props.timelineContext.canvasTimeStart) / 3;
                    const longScreenOnTime = Date.now() - props.timelineContext.canvasTimeStart;
                    const percentScreenOnTime = (longScreenOnTime / longScreenTime) * 100 - 100;
                    const pixelLeft = props.timelineContext.timelineWidth * (percentScreenOnTime / 100) - 7 - 32 - 1;

                    const rootProps = props.getRootProps();
                    const { intervals } = props.headerContext;
                    const { getIntervalProps } = props;

                    const labelWidthArr = intervals.map((interval) => interval['labelWidth']);
                    const labelWidthMin = Math.min(...labelWidthArr);

                    return (
                        <>
                            {!allNothing ? (
                                <div {...rootProps}>
                                    {intervals.map((interval, index) => {
                                        let labelWidth = labelWidthMin; //interval.labelWidth;
                                        let formatString: string = getDateFormat(unit, labelWidth, interval.startTime);
                                        let fontSize;
                                        if (labelWidth <= 24) fontSize = 12;
                                        if (labelWidth <= 20) fontSize = 10;
                                        if (labelWidth <= 18) fontSize = 8;

                                        const isWeekDay =
                                            (interval.startTime.day() === 0 || interval.startTime.day() === 6) &&
                                            props.headerContext.unit === 'day';

                                        return (
                                            <div
                                                {...getIntervalProps({ interval, style: { fontSize: fontSize } })}
                                                key={index}
                                                className={
                                                    'rct-dateHeader' +
                                                    (unit === 'hour' ? ' rct-dateHeader-disabled' : '') +
                                                    (isWeekDay ? ' isWeekDay' : '')
                                                }
                                                onClick={
                                                    unit !== 'hour'
                                                        ? () =>
                                                              onIntervalClick(
                                                                  primaryUnit,
                                                                  false,
                                                                  moment(interval.startTime),
                                                                  moment(interval.endTime)
                                                              )
                                                        : undefined
                                                }
                                            >
                                                {interval.startTime.format(formatString).toUpperCase()}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div>&nbsp;</div>
                            )}
                            <div
                                style={{
                                    left: `${
                                        pixelLeft < 0
                                            ? -500
                                            : pixelLeft + 81 > props.timelineContext.timelineWidth
                                            ? -500
                                            : allNothing
                                            ? pixelLeft + sidebarWidth - 3
                                            : pixelLeft + sidebarWidth
                                    }px`,
                                    width: '80px',
                                    height: '8px',
                                    borderTop: '3px solid #383F77',
                                    position: 'absolute',
                                    margin: 'auto 0px',
                                    marginTop: `${allNothing ? '-50px' : '-60px'}`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    zIndex: 1,
                                }}
                            >
                                <div style={{ width: '5px', border: '5px solid transparent', borderTop: '5px solid #383F77' }}> </div>
                            </div>
                        </>
                    );
                } else return null;
            }}
        </CustomHeader>
    );
};

import { FileTypeCodeEnum } from '../../../../../../server';
import { DownloadDocumentPopoverProps } from '../../../DownloadDocumentPopover';

export enum ExportProductsPriceListPopoverOptions {
    SHOW_DISCOUNT = 'showDiscount',
    SHOW_PRICING_SCHEMES = 'showPricingSchemes',
    SHOW_DESCRIPTION = 'showDescription',
    SHOW_COUNT = 'showCount',
    GROUP = 'group',
}

export const options = [
    { key: ExportProductsPriceListPopoverOptions.SHOW_DISCOUNT, label: 'Отображение скидки' },
    { key: ExportProductsPriceListPopoverOptions.SHOW_PRICING_SCHEMES, label: 'Ценовые схемы' },
    { key: ExportProductsPriceListPopoverOptions.SHOW_DESCRIPTION, label: 'Описание' },
    { key: ExportProductsPriceListPopoverOptions.SHOW_COUNT, label: 'Количество в инвентаре' },
    { key: ExportProductsPriceListPopoverOptions.GROUP, label: 'Группировку по категории' },
];

export const availableFileTypes = [FileTypeCodeEnum.EXCEL, FileTypeCodeEnum.PDFTYPE] as const;
export type ProductsPriceListFileType = (typeof availableFileTypes)[number];
export type ProductsPriceListOnDownload = DownloadDocumentPopoverProps<
    ProductsPriceListFileType,
    ExportProductsPriceListPopoverOptions
>['onDownload'];

import { FC } from 'react';
import { EntityDrawer } from '../../../../../../../../components/v2/entityDrawer/EntityDrawer';
import { ProfessionInfoRead } from '../../../../../../../../server';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { ProfessionDrawerTitle } from '../ProfessionDrawerTitle/ProfessionDrawerTitle';
import { DataDisplayBlocks } from '../ProfessionDataDisplayBlocks/ProfessionDataDisplayBlocks';
import { useProfessionByIdQuery, useSetStatusForProfessionsMutation } from '../../../api/professions.api';

export interface ProfessionDrawerProps {
    professionId: number;
    className?: string;
    onClose: () => void;
    refetchList?: () => void;
    editProfession?: (profession: ProfessionInfoRead) => void;
    deleteProfession?: (profession: ProfessionInfoRead) => Promise<void>;
    archiveProfession?: (profession: ProfessionInfoRead) => void;
    onCalendarClick?: () => void;
    isEntityActionsLoading?: boolean;
}

export const ProfessionDrawer: FC<ProfessionDrawerProps> = (props) => {
    const {
        professionId,
        editProfession,
        deleteProfession,
        archiveProfession,
        className,
        onClose,
        refetchList,
        onCalendarClick,
        isEntityActionsLoading,
    } = props;

    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const {
        data: profession,
        error,
        isFetching,
        isLoading: isEntityLoading,
    } = useProfessionByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: professionId,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [setProfessionsStatus, { isLoading: isSetStatusLoading }] = useSetStatusForProfessionsMutation();

    const isLoading = Boolean(isEntityLoading || isEntityActionsLoading || isSetStatusLoading);

    return (
        <EntityDrawer
            title={
                <ProfessionDrawerTitle
                    profession={profession}
                    onClose={onClose}
                    editProfession={editProfession}
                    deleteProfession={deleteProfession}
                    archiveProfession={archiveProfession}
                    refetchList={refetchList}
                    onCalendarClick={onCalendarClick}
                    setProfessionsStatus={setProfessionsStatus}
                    isLoading={isLoading || isFetching}
                />
            }
            onClose={onClose}
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
            className={className}
        >
            {profession && <DataDisplayBlocks profession={profession} />}
        </EntityDrawer>
    );
};

import { useEffect, useState } from 'react';

export const useDeferredTime = (screenLeft: number, screenRight: number, isLoading: boolean) => {
    const [visibleTimeStart, setVisibleTimeStart] = useState(screenLeft);
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(screenRight);

    useEffect(() => {
        if (!isLoading) {
            setVisibleTimeStart(screenLeft);
            setVisibleTimeEnd(screenRight);
        }
    }, [isLoading, screenLeft, screenRight, visibleTimeEnd, visibleTimeStart]);

    return [visibleTimeStart, visibleTimeEnd];
};

import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { InstanceRecord, NomenclatureEntityTypeCodeEnum, NomenclatureRecord } from '../../../../../../server';
import { AccessibilityMapFilters } from '../filters/accessibility/accessibilityMapCalendarFilters.types';

export abstract class AccessibilityMapCalendarApiUtils {
    static getFilters = (params: AccessibilityMapFilters) => {
        const { productId, variantId, kitId, instanceId } = params;

        if (instanceId) {
            return ServerUtils.createRequestFilters<InstanceRecord>([['id', 'EQ', instanceId]]);
        }

        return ServerUtils.createRequestFilters<NomenclatureRecord & { id: number }>([
            variantId != null || productId != null || kitId != null ? ['id', 'EQ', (variantId || productId || kitId) as number] : undefined,
            variantId != null || productId != null || kitId != null
                ? [
                      'nomenclatureEntityTypeCode',
                      'IN',
                      [
                          variantId != null
                              ? NomenclatureEntityTypeCodeEnum.VARIANT
                              : productId != null
                              ? NomenclatureEntityTypeCodeEnum.PRODUCT
                              : NomenclatureEntityTypeCodeEnum.KIT,
                      ],
                  ]
                : undefined,
            instanceId != null ? ['instanceIds', 'IN', [instanceId]] : undefined,
        ]);
    };
}

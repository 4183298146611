import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { RouteComponentProps } from 'react-router';
import { injectIntl, IntlShape } from 'react-intl';
import { push, replace } from 'connected-react-router';
import { initialParamsState, loadEntities, reset } from './reducers/analytics.reducer';
import { PageUtils } from '../../../shared/util/pageUtils';
import { getPathFromState, getStateFromPath2 } from '../../../shared/util/utils';
import { getGridStorageData, setGridStorageDataFilters } from '../../../components/grid/utils';
import { showNotification } from '../../../components/notification/showNotification';
import { Spin } from '../../../components';
import { Grid } from '../../../components/grid/Grid';
import { analyticsPageColumns } from './analyticsPageColumns';
import { filters } from './analyticsPageFilters';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { Card, Tabs } from 'antd';
import debounce from 'lodash/debounce';

import './analyticsPage.less';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';

const gridName = 'statisticReports';

interface IProps extends StateProps, DispatchProps, IBasePageProps {
    intl: IntlShape;
}

interface IState {}

class StatisticReportsModuleList extends BasePage<IProps> {
    private grid;
    private filtersForm;
    private fromFilters;
    private updatingAfterSetFromStorage;
    private initialValues;
    private filters: string[] = ['search', 'reportType', 'visualizationType', 'sortBy', 'sortOrder'];

    constructor(props: IProps) {
        super(props);
        this.state = {};
        this.fromFilters = false;
        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount() {
        this.initialValues = this.props.pageParams;
        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    }

    componentDidUpdate(prevProps: IProps) {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    }

    loadDataAndSetFilterValues = () => {
        this.loadUsersModule();
        if (!this.fromFilters) {
            this.filtersForm.setFieldsValue({ ...initialParamsState, ...this.props.pageParams });
        }
        this.fromFilters = false;
    };

    loadUsersModule = async () => {
        let res = await this.props.loadEntities(this.props.businessAccountId);
        if (res instanceof Error) {
            showNotification('error', 'Не удалось загрузить список отчетов');
        }
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(gridName);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    componentWillUnmount() {
        this.props.reset();
    }

    resetFilters = () => {
        let data = {
            ...initialParamsState,
        };
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(gridName, data);
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        this.fromFilters = true;
        data.page = 1;
        setGridStorageDataFilters(gridName, data);
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
    };

    onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                limit: size,
                page: 1,
            })
        );
    };

    renderContent() {
        let { entities, loading, filteredCount, canViewFinancialData } = this.props;
        let excludeColumns: string[] | undefined;
        if (!canViewFinancialData) excludeColumns = ['financial'];

        return (
            <Spin spinning={loading}>
                <Tabs activeKey={'reports'} className={'rr-tabs'} type="line" animated={false}>
                    <Tabs.TabPane key={'reports'} tab={<>Отчеты</>}>
                        <Card bordered={false}>
                            <Grid
                                entityType={'analyticsReport'}
                                gridName={gridName}
                                filtered={filteredCount}
                                data={entities}
                                columns={analyticsPageColumns}
                                filtersData={filters}
                                filtersInitialValues={this.initialValues}
                                filtersCurrentValues={this.props.pageParams}
                                filtersDefaultValues={initialParamsState}
                                filtersGetFiltersFormRef={this.getFiltersForm}
                                filtersOnChange={this.onFiltersChange}
                                filtersResetFiltersCb={this.resetFilters}
                                ref={(ref) => {
                                    if (!this.grid) this.grid = ref;
                                }}
                                indexFieldName={'id'}
                                selectable={false}
                                defaultSorted={this.props.pageParams.sortBy}
                                defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                                currentPage={this.props.pageParams.page}
                                pageSize={this.props.pageParams.limit}
                                onSortedChange={this.onSortedChange}
                                onPageChanged={this.onPageChanged}
                                onPageSizeChanged={this.onPageSizeChanged}
                                excludeColumns={excludeColumns}
                            />
                        </Card>
                    </Tabs.TabPane>
                </Tabs>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        loading: storeState.analyticsList.loading,
        pageParams: storeState.analyticsList.params,
        entities: storeState.analyticsList.entities,
        filteredCount: storeState.analyticsList.filteredCount,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    };
};

const mapDispatchToProps = { loadEntities, reset, replace, push };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StatisticReportsModuleList));

import React, { memo } from 'react';
import { EntityGridDrawerWrapper } from '../../../../../../components/page/entityGrid/types/drawer';
import { ExpenseInfoRead } from '../../../../../../server';
import { ExpenseDrawer } from '../expenseDrawer';

export const ExpenseDrawerWrapper: EntityGridDrawerWrapper<ExpenseInfoRead> = memo((props) => {
    const { entityId, onClose, editActionForDrawer, copyActionForDrawer, isLoading } = props;

    return (
        <ExpenseDrawer
            expenseId={entityId}
            onClose={onClose}
            editExpense={editActionForDrawer}
            copyExpense={copyActionForDrawer}
            isLoading={isLoading}
        />
    );
});

import {useAppSelector} from '../../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../../shared/reducers/system.reducer';
import {PaymentMethodCreateModal} from './paymentMethodCreateModal';
import {LocalizationEnum, localize} from '../../../../../../localization';
import React, {FunctionComponent, memo} from 'react';
import {PaymentMethodInfoRead} from '../../../../../../server';
import {ModalFormNEWProps} from '../../../../../../components/modalForm/ModalFormNEW';
import {EntityGridRootPath} from '../../../../../../components/page/entityGrid/types/params';

export type PaymentMethodCreateModalWrapperProps<EntityInfoRead extends object> = {
    rootPath: EntityGridRootPath;
    urlSearchParams: URLSearchParams;
} & Partial<ModalFormNEWProps>;

type CreateModalWrapper<EntityInfoRead extends object> = FunctionComponent<PaymentMethodCreateModalWrapperProps<EntityInfoRead>>;

export const PaymentMethodCreateModalWrapper: CreateModalWrapper<PaymentMethodInfoRead> = memo(
    ({rootPath, urlSearchParams, ...modalProps}) => {
        const businessAccountId = useAppSelector(businessAccountIdSelector);

        // {/*{...(modalProps ?? {})}*/}
        return (
            <PaymentMethodCreateModal
                businessAccountId={businessAccountId}
                title={'Создание нового способа оплаты'}
                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                rootPath={rootPath}
                urlSearchParams={urlSearchParams}
            />
        );
    }
);

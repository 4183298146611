import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Grid } from '../../../../../../../../../../../../components/grid/Grid';
import { ProfessionsCompositionListColumnsObj } from '../../../columns/professionsCompositionColumns';
import { CREW_MEMBER_COMPOSITION_GRID_NAME } from '../../../CrewMemberProfessions';
import { professionsCompositionEditColumns } from '../../../columns/professionsCompositionEditColumns';
import { CrewMemberInfoRead } from '../../../../../../../../../../../../server';
import { useGridData } from '../../../hooks/useGridData';
import { CrewMemberCompositionOnRowAction, useOnRowAction } from '../../../hooks/editGrid/useOnRowAction';
import classNames from 'classnames';
import { GridHeader } from '../GridHeader/GridHeader';
import { Button, Card, Icon } from 'antd';
import { LocalizationEnum, localize } from '../../../../../../../../../../../../localization';
import { useOnSaveEditGrid } from '../../../hooks/editGrid/useOnSaveGrid';
import { useOnDragEnd } from '../../../../../../../../../../../../core/hooks/dnd/useOnDragEnd';
import RoundButton from '../../../../../../../../../../../../components/button/roundButton';
import { IconPlus } from '../../../../../../../../../../../../components/icons';
import {
    ProfessionSelectEntity,
    ProfessionSelectPopover,
} from '../../../../../../../../../../../../components/select/ProfessionSelect/SelectPopover/ProfessionSelectPopover';
import { PromptRedirect } from '../../../../../../../../../../../../components/prompt/PromptRedirect/PromptRedirect';
import { DndProps } from '../../../../../../../../../../../../components/grid/grid/ReactTableBody';
import {showConfirm} from "../../../../../../../../../../../../components/confirm/showConfirm";
import {useLocalize} from "../../../../../../../../../../../../core/hooks/useLocalize";
import {useIntl} from "react-intl";

interface CrewMemberEditGridProps {
    crewMember: CrewMemberInfoRead;
    setIsEdit: (value: boolean) => void;
}

export const CrewMemberEditGrid: FC<CrewMemberEditGridProps> = memo((props) => {
    const { crewMember, setIsEdit } = props;

    const L = useLocalize();
    const intl = useIntl();

    const gridData = useGridData({
        crewMember,
    });
    const [editData, setEditData] = useState<ProfessionsCompositionListColumnsObj[] | undefined>(gridData);

    const onRowAction: CrewMemberCompositionOnRowAction = useOnRowAction(crewMember, editData, setEditData);

    const onSaveGrid = useOnSaveEditGrid({
        editData,
        crewMemberId: crewMember.id,
        setIsEdit,
    });

    const onAddEntityOk = useCallback(
        (professions: ProfessionSelectEntity[]) => {
            if (editData == null) return;

            const newGridEntities = professions.map(
                (profession, index): ProfessionsCompositionListColumnsObj => ({
                    professionId: profession.data.id,
                    professionShortName: profession.data.shortName ?? '',
                    memberOrder: editData.length + index,
                    crewMember,
                    main: false,
                    rate: undefined,
                })
            );

            setEditData([...editData, ...newGridEntities]);
        },
        [crewMember, editData]
    );

    const selectedIds = useMemo(() => editData?.map(({ professionId }) => professionId), [editData]);

    const getUniqId = useCallback((rowData: ProfessionsCompositionListColumnsObj): string => {
        return String(rowData.professionShortName);
    }, []);

    const onDragEnd = useOnDragEnd({
        editData,
        setEditData,
    });

    const dndProps = useMemo(
        (): DndProps => ({
            onDragEnd,
        }),
        [onDragEnd]
    );

    return (
        <>
            <PromptRedirect />
            <Card
                className={classNames('grid-card', 'adaptive-width')}
                title={
                    <GridHeader
                        Button={
                            <ProfessionSelectPopover onOk={onAddEntityOk} selectedIds={selectedIds}>
                                <RoundButton colorScheme={'successSecondary'} className={'header-button'} onClick={() => {}}>
                                    <Icon component={IconPlus} />
                                    <span>{'Добавить специальность'}</span>
                                </RoundButton>
                            </ProfessionSelectPopover>
                        }
                    />
                }
            >
                <Grid
                    columns={professionsCompositionEditColumns}
                    data={editData}
                    dnd
                    filtered={editData?.length ?? 0}
                    hidePageSizeSelect
                    entityType={CREW_MEMBER_COMPOSITION_GRID_NAME}
                    selectable={false}
                    gridName={CREW_MEMBER_COMPOSITION_GRID_NAME}
                    indexFieldName={'id'}
                    defaultSorted={'memberOrder'}
                    onRowAction={onRowAction as any}
                    dndProps={dndProps}
                    getUniqId={getUniqId}
                />
            </Card>
            <div className={classNames('grid-footer', 'adaptive-width')}>
                <Card className="save-buttons-container">
                    <Button type={'primary'} onClick={onSaveGrid}>
                        <span>{localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}</span>
                    </Button>
                    <Button className="rr-btn-default" onClick={async () => {
                        const yes = await showConfirm(intl, L(LocalizationEnum.ASPECT__MODAL__ABORT_EDIT));
                        if(yes) setIsEdit(false);
                    }}>
                        <span>{localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}</span>
                    </Button>
                </Card>
            </div>
        </>
    );
});

import { ListProfessionsParams } from './types';
import { ProfessionRecord } from '../../../../../../server';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../../../components/page/entityGrid/types/api';
import { MoneyUtils } from '../../../../../../core/utils/moneyUtils';

export abstract class ProfessionsApiUtils {
    static createRequestFilters = (params: ListProfessionsParams) => {
        return ServerUtils.createRequestFilters<ProfessionRecord>([
            params.hideArchive ? ['archive', 'EQ', false] : undefined,
            params.stateCode ? ['stateCode', 'EQ', params.stateCode] : undefined,
            params.pricePerShift?.[0] != null && ['pricePerShift', 'GE', MoneyUtils.toCents(params.pricePerShift[0])],
            params.pricePerShift?.[1] != null && ['pricePerShift', 'LE', MoneyUtils.toCents(params.pricePerShift[1])],
        ]);
    };
    static pricePerShiftIntervalMetricsMap: IntervalFiltersMetricsMap<ProfessionRecord> = {
        pricePerShift: (value: number) => value / 100,
    };
    static pricePerShiftIntervalFormatMap: IntervalFiltersFormatsMap<ProfessionRecord> = {
        pricePerShift: 'currency',
    };
}

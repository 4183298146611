import { EntityActions } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { NomenclatureEntityTypeCodeEnum } from '../../../../../../server';
import { NomenclatureRecordCustom } from '../../api/products.api.types';
import { useCallback, useMemo } from 'react';
import { saveCurrentTabToStore } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { push } from 'connected-react-router';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { showConfirm2 } from '../../../../../../components/confirm/showConfirm';
import { useIntl } from 'react-intl';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { deleteProducts, setArchiveState, setStatus } from '../../reducers/products.reducer';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { ProductPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

type ProductEntityActions = EntityActions<NomenclatureRecordCustom>;

export const useProductsEntityActions = ({ rootPath }: { rootPath: EntityGridRootPath }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const gridRef = useEntityGridContext();

    const getIds = (product: NomenclatureRecordCustom) => ({
        productId: product.productId,
        variantId: product.variantId,
    });

    const editProductCallback: ProductEntityActions['editAction'] = useCallback(
        (product) => {
            const { productId, variantId } = getIds(product);

            const mainUrl = `${rootPath}/${productId}`;

            if (variantId) {
                saveCurrentTabToStore('product', ProductPageTabsEnum.VARIANTS);
                const searchParams = new URLSearchParams({
                    tab: 'variants',
                    mode: 'edit',
                    id: String(variantId),
                });

                const url = `${mainUrl}/?${searchParams.toString()}`;
                dispatch(push(url));
            } else {
                saveCurrentTabToStore('product', ProductPageTabsEnum.DESCRIPTION);
                dispatch(push(mainUrl));
                setTimeout(() => {
                    dispatch(push(`${mainUrl}/edit`));
                });
            }
        },
        [dispatch, rootPath]
    );

    const deleteProductCallback: ProductEntityActions['archiveActionForDrawer'] = useCallback(
        async (product) => {
            const { productId, variantId } = getIds(product);

            if (variantId) {
                let yes = await showConfirm2({
                    intl: intl,
                    title: 'Удалить безвозвратно продукт со всеми его вариантами и экземплярами?',
                });
                if (yes) {
                    deleteProducts(intl, businessAccountId, [
                        {
                            id: variantId,
                            businessVersion: product.businessVersion,
                            nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.VARIANT,
                        },
                    ])(dispatch);
                    gridRef?.current?.clearSelection();
                }
            } else {
                let yes = await showConfirm2({
                    intl: intl,
                    title: 'Удалить безвозвратно продукт со всеми его вариантами и экземплярами?',
                });
                if (yes) {
                    deleteProducts(intl, businessAccountId, [
                        {
                            id: productId,
                            businessVersion: product.businessVersion,
                            nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.PRODUCT,
                        },
                    ])(dispatch);
                    gridRef?.current?.clearSelection();
                }
            }
        },
        [businessAccountId, dispatch, gridRef, intl]
    );

    const copyProductCallback: ProductEntityActions['copyActionForDrawer'] = useCallback(
        (product) => {
            const { productId, variantId } = getIds(product);

            const mainUrl = `${rootPath}/${productId}`;

            if (variantId) {
                const searchParams = new URLSearchParams({
                    tab: 'variants',
                    mode: 'copy',
                    id: String(variantId),
                });

                const url = `${mainUrl}/?${searchParams.toString()}`;
                saveCurrentTabToStore('product', ProductPageTabsEnum.VARIANTS);
                dispatch(push(url));
                setTimeout(() => {
                    dispatch(push(url));
                });
            } else {
                saveCurrentTabToStore('product', ProductPageTabsEnum.DESCRIPTION);
                dispatch(push(`${mainUrl}`));
                setTimeout(() => {
                    dispatch(push(`${mainUrl}/copy`));
                });
            }
        },

        [dispatch, rootPath]
    );

    const archiveProductCallback: ProductEntityActions['archiveActionForDrawer'] = useCallback(
        (product) => {
            const { productId, variantId } = getIds(product);

            if (variantId) {
                setArchiveState(intl, businessAccountId, [
                    {
                        id: variantId,
                        businessVersion: product.businessVersion,
                        archive: !product.archive,
                        nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.VARIANT,
                    },
                ])(dispatch);
            } else {
                setArchiveState(intl, businessAccountId, [
                    {
                        id: productId,
                        businessVersion: product.businessVersion,
                        archive: !product.archive,
                        nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.PRODUCT,
                    },
                ])(dispatch);
            }
        },
        [businessAccountId, dispatch, intl]
    );

    const setStatusCallback = useCallback(
        (product: NomenclatureRecordCustom) => {
            const { variantId, productId } = getIds(product);

            if (variantId) {
                setStatus(intl, businessAccountId, [
                    {
                        id: variantId,
                        businessVersion: product.businessVersion,
                        transitionCode: product.stateCode === 'NEW' || product.stateCode === 'NOT_AVAILABLE' ? 'ACTIVATE' : 'BLOCK',
                        nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.VARIANT,
                    },
                ])(dispatch);
            } else {
                setStatus(intl, businessAccountId, [
                    {
                        id: productId,
                        businessVersion: product.businessVersion,
                        transitionCode: product.stateCode === 'NEW' || product.stateCode === 'NOT_AVAILABLE' ? 'ACTIVATE' : 'BLOCK',
                        nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.PRODUCT,
                    },
                ])(dispatch);
            }
        },
        [businessAccountId, dispatch, intl]
    );

    const onRowAction: ProductEntityActions['onRowAction'] = useCallback(
        (product, action) => {
            const actionHandler: Partial<Record<typeof action, Function>> = {
                edit: () => editProductCallback(product),
                archive: () => archiveProductCallback(product),
                lock: () => setStatusCallback(product),
                delete: () => deleteProductCallback(product),
                copy: () => copyProductCallback(product),
            };

            actionHandler[action]?.();
        },
        [archiveProductCallback, copyProductCallback, deleteProductCallback, editProductCallback, setStatusCallback]
    );

    return useMemo(
        () => ({
            editAction: editProductCallback,
            onRowAction,
        }),
        [editProductCallback, onRowAction]
    );
};

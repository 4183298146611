import { EntityGridDrawerWrapper } from '../../../../../../../../../components/page/entityGrid/types/drawer';
import { ProfessionInfoRead } from '../../../../../../../../../server';
import { memo } from 'react';
import { ProfessionDrawer } from '../ProfessionDrawer';

export const ProfessionDrawerWrapper: EntityGridDrawerWrapper<ProfessionInfoRead> = memo((props) => {
    const { entityId, onClose, refetchEntitiesList, editActionForDrawer, deleteActionForDrawer, archiveActionForDrawer, isLoading } = props;

    return (
        <ProfessionDrawer
            professionId={entityId}
            onClose={onClose}
            editProfession={editActionForDrawer}
            deleteProfession={deleteActionForDrawer}
            archiveProfession={archiveActionForDrawer}
            refetchList={refetchEntitiesList}
            isEntityActionsLoading={isLoading}
        />
    );
});

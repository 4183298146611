import React from 'react';
import {StateBadgeData} from "../../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../../core/types/color";
import {SubrentStateCodeEnum} from "../../../../../server";

export const subrentShippingStateBadgeData: StateBadgeData<SubrentStateCodeEnum> = {
    [SubrentStateCodeEnum.DRAFT]: {
        color: Color.WhiteGrey1, // Голубой
        name: 'Черновик', // TODO Локализовать
    },
    [SubrentStateCodeEnum.INPROGRESS]: {
        color: Color.Orange1, // Оранжевый
        name: 'В работе', // TODO Локализовать
    },
    [SubrentStateCodeEnum.FINISHED]: {
        color: Color.Green, // Зеленый
        name: 'Завершена', // TODO Локализовать
    },
    [SubrentStateCodeEnum.CANCELED]: {
        color: Color.Grey2, // Зеленый
        name: 'Отменена', // TODO Локализовать
    },
};

import { MutableRefObject, useCallback } from 'react';
import { serverApi } from '../../../../../../server';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export const useSetVisibleInstance = (filtersFormRef: MutableRefObject<WrappedFormUtils | undefined>) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return useCallback(
        async (productId: number) => {
            if (productId) {
                await serverApi.getProductById(businessAccountId, productId).then((data) => {
                    filtersFormRef.current?.setFieldsValue({
                        productInstanceTrackingTypeCode: data?.data?.instanceTrackingTypeCode,
                    });
                });
            }
        },
        [businessAccountId, filtersFormRef]
    );
};

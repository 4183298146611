import {OperationResetHeaderButton} from "../operationResetHeaderButton/operationResetHeaderButton";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {OperationTypeCodeEnum} from "../../../server";
import {showConfirm} from "../../confirm/showConfirm";
import {LocalizationEnum} from "../../../localization";
import {getPathFromState} from "../../../shared/util/utils";
import {useLocalize} from "../../../core/hooks/useLocalize";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";
import {push} from "connected-react-router";
import {fullResetOperation} from "../../../modules/main/operationForm/reducers/operationForm.reducer";
import {clearElements} from "../../../modules/main/elements/reducers/elements.reducer";

interface EditOperationResetButtonProps {
    entityId?: number;
}

export const EditOperationHeaderResetButton = (props: EditOperationResetButtonProps) => {

    const L = useLocalize();
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const typeCode = useAppSelector((state) => state.operationForm.typeCode);
    const mnemoKey = useAppSelector((state) => state.operationForm.mnemoKey);
    const projectId = useAppSelector((state) => state.operationForm.projectId);

    const onClick = async () => {
        let ok = await showConfirm(intl, L(LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL));
        if (ok){
            dispatch(push(getPathFromState(location.pathname, location.search, { page: 1 })));
            dispatch(fullResetOperation());
            dispatch(clearElements());
        }
    }

    const isNowEditing = typeCode === OperationTypeCodeEnum.EDIT && mnemoKey && projectId && props.entityId && projectId === props.entityId;

    if(!isNowEditing) return null;
    else return <OperationResetHeaderButton onClick={onClick} />;
}

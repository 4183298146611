import React, { FC } from 'react';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { RentActivityFrameStateCodeEnum } from '../../../../../../../../server';
import { ActivityFramesCalendarItem } from '../../../../../components/Calendar/types/items';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';

export const StatusGroupLine: FC<GroupItemProps<ActivityFramesCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { record } = groupItem;
    const { stateCode } = record;

    const status = (
        {
            [RentActivityFrameStateCodeEnum.RENTED]: 'В аренде',
            [RentActivityFrameStateCodeEnum.BOOKED]: 'Бронь',
            [RentActivityFrameStateCodeEnum.DRAFT]: 'Новый',
            [RentActivityFrameStateCodeEnum.INPROGRESS]: 'В работе',
            [RentActivityFrameStateCodeEnum.FINISHED]: 'Завершён',
            [RentActivityFrameStateCodeEnum.CANCELED]: 'Отменён',
            [RentActivityFrameStateCodeEnum.COORDINATION]: 'Согласование',
        } satisfies Record<RentActivityFrameStateCodeEnum, string>
    )[stateCode];

    const classStatus = 'rr-status-bg-' + stateCode;

    return status ? (
        <GroupLine
            IconComponent={<div className={`rr-dot ${classStatus}`}></div>}
            displayType={displayType}
            title={{
                text: status,
            }}
        />
    ) : null;
};

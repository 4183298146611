import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { serverApi, ServerError, UserBARoleCodeEnum } from '../../../../../server';
import { push } from 'connected-react-router';
import { getServerError } from '../../../../../shared/util/utils';
import { showNotification } from '../../../../../components/notification/showNotification';
import { loadEntities } from './users.reducer';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { getBusinessAccount } from '../../../../../shared/reducers/businessAccount.reducer';

export const ACTION_TYPES = {
    INVITE_USER_BA: 'userBA/INVITE_USER_BA',
    RESET_ERROR: 'userBA/RESET_ERROR',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entity: null as any | null,
};

export type UserStateBA = Readonly<typeof initialState>;

// Reducer

export default (state: UserStateBA = initialState, action): UserStateBA => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.INVITE_USER_BA):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };

        case FAILURE(ACTION_TYPES.INVITE_USER_BA):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };

        case SUCCESS(ACTION_TYPES.INVITE_USER_BA):
            return {
                ...state,
                updating: false,
            };
        default:
            return state;
    }
};
// Actions
export const inviteUserToBusinessAccount = (intl, businessAccountId: number, email: string, role: UserBARoleCodeEnum | undefined) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.INVITE_USER_BA,
            payload: serverApi.inviteUserToBusinessAccount(businessAccountId, { email, baRole: role }),
        }).then((result) => {
            if (result instanceof Error) {
                if (!role) {
                    showNotification('error', 'Не удалось отменить приглашение пользователя');
                } else {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__INVITATION_SENDING_FAILED));
                }
            } else {
                if (!role) {
                    showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__INVITATION_CANCELED)); // TODO мб выводить норм сообщение
                } else {
                    showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__POPUP_NOTIFICATIONS__INVITATION_SENT)); // TODO мб выводить норм сообщение
                }
                dispatch(loadEntities(intl, businessAccountId));
                dispatch(push('/' + businessAccountId + '/settings/users/'));
                setTimeout(() => dispatch(getBusinessAccount(businessAccountId, true)), 1000);
            }
        });
    };
};

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR,
});

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../../shared/reducers';
import { EntityFeaturesView } from '../../../features/components/EntityFeaturesView/EntityFeaturesView';
import { getPathFromState, getStateFromPath } from '../../../../../../shared/util/utils';
import './product-page-features-tab.less';
import { CustomFieldGroupObjRead, ProductInfoRead, ProductInfoUpdate } from '../../../../../../server';
import { CustomFieldsUtils } from '../../../../../../core/utils/customFieldsUtils';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { push } from 'connected-react-router';
import { updateEntity1 } from '../../reducers/product.reducer';
import { EntityFeaturesEdit } from '../../../features/components/EntityFeaturesEdit/EntityFeaturesEdit';

interface IState {}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {}

class _ProductPageFeaturesTabPane extends React.PureComponent<IProps, IState> {
    toViewMode = () => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { edit: undefined }));
    };

    render() {
        const { product, editMode, intl, updating } = this.props;

        const onSave = async (groups: CustomFieldGroupObjRead[], entity: ProductInfoRead) => {
            // TODO!!! Тут пока хак variantBased: true
            const newData = {
                ...entity,
                stockInstanceCount: entity?.warehousePresenceCounters.stockInstanceCount,
                underMaintenanceInstanceCount: entity?.warehousePresenceCounters.underMaintenanceInstanceCount,
                variantBased: true,
                categories: undefined,
                customFieldGroups: groups.map(CustomFieldsUtils.convertCustomFieldGroupObjReadToCustomFieldGroupObjWrite),
            } as ProductInfoUpdate;

            if (entity && entity.categories && entity.categories.length) {
                newData.categoryIds = entity.categories.map((item) => item.id);
            }

            if (entity.pricingScheme) {
                newData.pricingSchemeId = entity.pricingScheme.id;
            }
            delete newData['pricingScheme'];

            let res = await this.props.updateEntity1(this.props.businessAccountId, entity.id, newData);

            if (res instanceof Error) {
                // TODO Сообщение об ошибке
                showNotification(
                    'error',
                    localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CUSTOM_FIELDS_SETUP_UPDATE_FAILED)
                );
            } else {
                this.toViewMode();
                // TODO Сообщение об успехе
                showNotification(
                    'success',
                    localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CUSTOM_FIELDS_SETUP_UPDATED)
                );
            }
        };

        return (
            <div>
                {product?.customFieldGroups ? (
                    editMode ? (
                        <EntityFeaturesEdit groups={product.customFieldGroups} entity={product} onSave={onSave} updating={updating} />
                    ) : (
                        <EntityFeaturesView entity={product} />
                    )
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    const searchParams = getStateFromPath(storeState.router.location.search);
    return {
        businessAccountId: storeState.system.businessAccountId,
        product: storeState.product.entity,
        editMode: Boolean(searchParams && searchParams.edit === true),
        location: storeState.router.location,
        updating: storeState.product.updating,
    };
};

const mapDispatchToProps = { push, updateEntity1 };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ProductPageFeaturesTabPane = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ProductPageFeaturesTabPane));

import { useEffect } from 'react';
import { gridDataChangedSignal } from '../../components/controllers/scroll/SelectScrollController/SelectScrollController';

type Statuses = {
    isFetching: boolean;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    entitiesLoading: boolean;
};

export const useGridSignals = ({ isFetching, isLoading, isSuccess, isError, entitiesLoading }: Statuses) => {
    const isDataLoaded = !isFetching && !isLoading && !entitiesLoading && (isSuccess || isError);

    useEffect(() => {
        if (isDataLoaded) {
            gridDataChangedSignal();
        }
    }, [isDataLoaded]);

    return isDataLoaded;
};

import React from 'react';
import { DataDisplayItemProps, DataDisplayItemType } from '../../../../../../../components/dataDisplay';
import { ContactInfoRead } from '../../../../../../../server';
import { Col, Row } from 'antd';
import { DrawerUtils } from '../../../../../../../shared/util/drawerUtils';
import { DrawerAvatar } from '../../../../../../../components/v2/entityDrawer/components/DrawerAvatar';
import { FormValueItem } from '../../../../../crew/tabs/crewMembers/page/components/tabs/description/components/FormValueItem/FormValueItem';

export const useMainBlockItem = (contact?: ContactInfoRead): DataDisplayItemProps[][] => {
    const fullName = contact
        ? DrawerUtils.getFullName({
              secondname: contact.secondName,
              lastname: contact.lastName,
              firstname: contact.firstName,
          })
        : undefined;

    const isAvatarHighlighted = contact?.isBusinessAccountRRUser;

    return [
        [
            {
                type: DataDisplayItemType.Custom,
                value: (
                    <div className={'contact-main-block'}>
                        <div>
                            <DrawerAvatar image={contact?.mainImage} isHighlighted={isAvatarHighlighted} />
                        </div>
                        <Row>
                            <Col className={'rr-form-item'}>
                                <FormValueItem title={'Полное имя'} value={fullName} />
                            </Col>
                            <Col className={'rr-form-item'}>
                                <FormValueItem title={'Основная должность'} value={contact?.primaryPosition} />
                            </Col>
                        </Row>
                    </div>
                ),
            },
        ],
    ];
};

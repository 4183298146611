export enum DataDisplayState {
    /**
     * Проблема - красным
     */
    Problem = 'Problem',
    /**
     * Предупреждение - оранжевым
     */
    Warning = 'Warning',
    /**
     * все хорошо - зеленым
     */
    Success = 'Success',
}

import { IDownloadDocumentParamsItem } from '../DownloadDocumentPopover';

interface UseSelectedValuesArgs {
    options: IDownloadDocumentParamsItem[];
    selectedValues: string[];
}

const checkIncludesParent = (selectedValues: string[], parent: string | string[]): boolean => {
    return Array.isArray(parent) ? parent.some((parent) => selectedValues.includes(parent)) : selectedValues.includes(parent);
};

export const useSelectedValues = <Options extends object>(args: UseSelectedValuesArgs) => {
    let selectedValues: string[] = [];
    let disabledValues: string[] = [];

    const onGroupChange = (checkedValues: string[]) => {
        const newSelectedValues: string[] = [];

        args.options.forEach((option) => {
            if (!option.parent && checkedValues.includes(option.key)) newSelectedValues.push(option.key);
            if (option.parent) {
                if (checkIncludesParent(checkedValues, option.parent) !== checkIncludesParent(args.selectedValues, option.parent)) {
                    if (args.selectedValues.includes(option.key)) newSelectedValues.push(option.key);
                } else {
                    if (checkedValues.includes(option.key)) newSelectedValues.push(option.key);
                }
            }
        });

        return newSelectedValues;
    };

    args.options.forEach((option) => {
        let selected = false;
        let disabled = false;
        if (!option.parent && args.selectedValues.includes(option.key)) selected = true;
        if (option.parent) {
            const parentSelected = checkIncludesParent(args.selectedValues, option.parent);
            if (!parentSelected) {
                selected = false;
                disabled = true;
            } else {
                selected = args.selectedValues.includes(option.key);
            }
        }
        if (selected) selectedValues.push(option.key);
        if (disabled) disabledValues.push(option.key);

        if(option.disabled && !disabledValues.includes(option.key)) disabledValues.push(option.key);
        if(option.defaultSelected && !selectedValues.includes(option.key)) selectedValues.push(option.key);
    });

    return { selectedValues: selectedValues as Options, disabledValues, onGroupChange };
};

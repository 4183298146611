import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import React from 'react';
import { ProjectRecord, ProjectTransitionCodeEnum } from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH, MAX_WIDTH } from '../../../../../config/constants';
import DashboardDate from '../../../../../components/dashboardDate/dashboardDate';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { tableCellPercentsRenderer } from '../../../../../components/grid/renderers/tableCellPercentsRenderer';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';
import { OfferStateBadge } from '../../components/OfferStateBadge/OfferStateBadge';
import { ActionButtonsPopover } from '../../../../../components/v2/actionButtons/actionButtonsPopover/actionButtonsPopover';
import { offerActionButtons, OfferActionType } from '../../data/offersData';
import { IconCoinsSolid } from '../../../../../components/icons';
import { Icon } from 'antd';
import { canUpdateFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { getStoreState } from '../../../../../../index';

const tagsSizes = GridColumnCreator.getColumnSizes(50);

// GRID COLUMNS
export const offersListColumns: TableColumn<ProjectRecord>[] = [
    //GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createShortNameColumn<ProjectRecord>('offer', (row) => ({
        baId: row.businessAccountId,
        id: row.id,
        name: row.shortName,
    })),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
        dataIndex: 'stateCode',
        type: ColumnTypes.CustomRender,
        width: 130,
        maxWidth: MAX_WIDTH(130),
        resizable: false,
        sortable: true,
        render: (value, row) => <OfferStateBadge type={row.stateCode} style={{ width: '100%' }} />,
    },
    {
        title: 'Включена в стоимость',
        dataIndex: 'partOfThePrice',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        resizable: false,
        render: (value: boolean) => {
            return value ? <Icon component={IconCoinsSolid} style={{ color: '#fc8c2f', fontSize: 21 }} /> : null;
        },
    },
    GridColumnCreator.createCounterpartyColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.renterId,
            name: row.renterShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RENTER),
            dataIndex: 'renterShortName',
        }
    ),
    //
    GridColumnCreator.createProjectColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.mainProjectId,
            name: row.mainProjectShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PROJECT),
            dataIndex: 'mainProjectShortName',
        }
    ),
    GridColumnCreator.createLocationColumn<ProjectRecord>({
        title: 'Локация',
        dataIndex: 'locationName',
    }),
    {
        title: 'Теги',
        dataIndex: 'tagsString',
        type: ColumnTypes.CustomRender,
        minWidth: tagsSizes.min,
        width: tagsSizes.width,
        maxWidth: tagsSizes.max,
        resizable: true,
        render: (value: string) => {
            return (
                <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} title={value}>
                    <span>{value}</span>
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
        dataIndex: 'startDate',
        type: ColumnTypes.Date,
        minWidth: 100,
        resizable: false,
    },
    {
        title: 'Завершение',
        dataIndex: 'targetFinishDate', // TODO Тут видимо выводить или одну дату или другую
        type: ColumnTypes.Date,
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'defaultDiscount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        render: (value: any, rowData: ProjectRecord) => (
            <span style={{ color: !rowData.hasOwnDefaultDiscount ? '#cccccc' : undefined }}>{tableCellPercentsRenderer(value)}</span>
        ),
    },
    {
        title: 'Стоимость аренды',
        dataIndex: 'costOfRent',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Стоимость работ',
        dataIndex: 'costOfWork',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Стоимость затрат',
        dataIndex: 'costOfExpenses',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },

    {
        title: 'Итоговая стоимость',
        dataIndex: 'sumToBePaidAfterTaxes',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    GridColumnCreator.createUserColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.assigneeId,
            name: row.assigneeShortName,
            hasOwn: row.hasOwnAssignee,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RESPONSIBLE),
            dataIndex: 'assigneeShortName',
        }
    ),
    GridColumnCreator.createUserColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            name: row.contactPersonShortName,
            hasOwn: row.hasOwnContactPerson,
        }),
        {
            title: 'Контактное лицо',
            dataIndex: 'contactPersonShortName',
            defaultHidden: true,
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.CustomRender,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: ProjectRecord, rowAction, entityType) => {
            const canUpdateFinancialData = canUpdateFinancialDataSelector(getStoreState());
            return (
                <ActionButtonsPopover
                    data={offerActionButtons}
                    actions={[
                        OfferActionType.edit,
                        OfferActionType.duplicate,
                        rowData.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.APPROVE) && OfferActionType.approve,
                        rowData.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.TOCOORDINATION) && OfferActionType.toCoordination,
                        rowData.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.RETURNTODRAFT) && OfferActionType.returnToDraft,
                        rowData.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.CANCEL) && OfferActionType.cancel,
                        canUpdateFinancialData
                            ? !rowData.partOfThePrice
                                ? OfferActionType.includeOfferInProjectPrice
                                : OfferActionType.excludeOfferInProjectPrice
                            : undefined,
                    ]}
                    onAction={(action) => {
                        rowAction(rowData, action);
                    }}
                >
                    <SystemIcon
                        type={SystemIconType.otherActions}
                        style={{ fontSize: 28 }}
                        className={'rr-grid-table-actions-icon'}
                        onClick={(e) => e.stopPropagation()}
                    />
                </ActionButtonsPopover>
            );
        },
    },
];

import React from 'react';
import {IconCheck} from '../../../../../components/icons';
import {Icon} from 'antd';
import './EquipmentAddedBlock.less';
import {setElementsListParams,} from "../../reducers/operationForm.reducer";
import {IRootState} from "../../../../../shared/reducers";
import {connect} from "react-redux";
import {isDefined} from "../../../../../shared/util/utils";
import {RentElementsGridItem} from "../../../../../types";

interface IProps extends StateProps, DispatchProps {
    element:RentElementsGridItem;
    cancelled?:boolean;
}

class Component extends React.Component<IProps> {

    render() {
        let leftoverInstanceCount:undefined|number = undefined;
        let ok = false;

        if(this.props.element.subRows){
            let withoutLeftover = 0;
            this.props.element.subRows.forEach((e)=>{
                let element = this.props.elements.find((el)=>el.id === e.id);
                if(element && element.leftoverInstanceCount <= 0) withoutLeftover++;
            });
            ok = withoutLeftover > 0 && withoutLeftover === this.props.element.subRows.length;
        }else{
            if(this.props.elements && this.props.elements.length > 0){
                let element = this.props.elements.find((el)=>el.id === this.props.element.id);
                if(element){
                    leftoverInstanceCount = element.leftoverInstanceCount;
                    ok = isDefined(leftoverInstanceCount) && leftoverInstanceCount <= 0;
                }
            }
        }

        return (
            ok ? <Icon style={{color: '#34BFA3', fontSize: 28}} component={IconCheck} /> : null
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        elements: storeState.operationForm.elements.entities
    };
};

const mapDispatchToProps = {setElementsListParams};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const EquipmentSuccessIcon = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

import { Modal } from 'antd';
import React, { ReactNode } from 'react';
import './confirm.less';
import { LocalizationEnum, localizeIntl } from '../../localization';
import { IntlShape } from 'react-intl';
import { ButtonProps } from 'antd/lib/button/button';
import { joinClasses } from '../../shared/util/utils';
import classNames from 'classnames';

/**
 * Метод для показа простых уведомлений
 * @param intl
 * @param title
 * @param content
 * @param onOk
 * @param width
 */
export const showConfirm = async (
    intl: IntlShape,
    title: string | ReactNode,
    content?: string | ReactNode,
    onOk?: () => void,
    width?: number
): Promise<boolean> => {
    return new Promise((resolve) => {
        Modal.confirm({
            className: 'rr-modal-confirm',
            title: title ? <span className={'rr-modal-confirm-title'}>{title}</span> : null,
            content: content ? <span className={'rr-modal-confirm-content'}>{content}</span> : null,
            onCancel: () => {
                resolve(true);
                if (onOk) onOk();
            },
            onOk: () => {
                resolve(false);
            },
            centered: true,
            cancelButtonProps: { className: joinClasses('rr-btn-default', 'rr-btn-cancel-secondary') },
            autoFocusButton: 'ok',
            icon: null,
            width: width ?? 500,
            okText: localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__NO),
            cancelText: localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__YES),
            keyboard: false,
            zIndex: 9999,
        });
    });
};

interface showConfirmNewProps {
    intl: IntlShape;
    title: string | ReactNode;
    content?: string | ReactNode;
    onOk?: () => void;
    width?: number;
    okButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
    className?: string;
    okText?: ReactNode;
    cancelText?: ReactNode;
}

export const showConfirmNew = async (props: showConfirmNewProps): Promise<boolean> => {
    const { title, content, onOk, width, intl, okButtonProps, cancelButtonProps, className, okText, cancelText } = props;

    return new Promise((resolve) => {
        const m = Modal.confirm({
            className: classNames('rr-modal-confirm', className),
            title: title ? <span className={'rr-modal-confirm-title'}>{title}</span> : null,
            content: content ? (
                <span className={'rr-modal-confirm-content'}>{typeof content === 'function' ? content(() => m) : content}</span>
            ) : null,
            onCancel: () => {
                resolve(true);
                if (onOk) onOk();
            },
            onOk: () => {
                resolve(false);
            },
            centered: true,
            cancelButtonProps: cancelButtonProps, //{className: 'rr-btn-default'},
            okButtonProps: okButtonProps,
            autoFocusButton: 'ok',
            icon: null,
            width: width ? width : 520,
            okText: okText || localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__NO),
            cancelText: cancelText || localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__YES),
            keyboard: false,
            zIndex: 9999,
        });
    });
};

// Это предупреждение, на кнопке Да - сделана рамка
export const showConfirm2 = async (props: showConfirmNewProps): Promise<boolean> => {
    let cancelButtonProps: ButtonProps = props.okButtonProps ? { ...props.okButtonProps } : {};
    cancelButtonProps.className = joinClasses(cancelButtonProps.className, 'rr-btn-default', 'rr-btn-cancel-secondary');
    const newProps = { ...props, cancelButtonProps };
    return showConfirmNew(newProps);
};

// Это предупреждение, Должно заменить все остальные предупреждения
export const showConfirm3 = async ({
    title,
    content,
    onOk,
    width,
    intl,
    okButtonProps,
    cancelButtonProps,
    ...props
}: showConfirmNewProps): Promise<boolean> => {
    //const {title, content, onOk, width, intl, okButtonProps, cancelButtonProps} = props;

    return new Promise((resolve) => {
        Modal.confirm({
            className: 'rr-modal-confirm',
            title: title ? <span className={'rr-modal-confirm-title'}>{title}</span> : null,
            content: content ? <span className={'rr-modal-confirm-content'}>{content}</span> : null,
            onCancel: () => {
                resolve(true);
                if (onOk) onOk();
            },
            onOk: () => {
                resolve(false);
            },
            centered: true,
            cancelButtonProps: cancelButtonProps, //{className: 'rr-btn-default'},
            okButtonProps: okButtonProps,
            autoFocusButton: 'ok',
            icon: null,
            width: width ? width : 500,
            okText: localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__NO),
            cancelText: localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__YES),
            keyboard: false,
            zIndex: 9999,
        });
    });
};

import {useHistory, useLocation} from 'react-router-dom';
import {useCallback, useMemo, useRef} from 'react';
import {ParamsUtils} from '../../utils/paramsUtils';
import {ObjectUtils} from '../../utils/objectUtils';
import cloneDeep from 'lodash/cloneDeep';
import {PageUrlParamsObject, SetPageParams, UsePageURLParamsProps} from './types';

export const usePageURLParams = <Params extends object>({
    paramsDescription,
    pageName,
    initialValues,
    skip,
    outerParams,
    hiddenParams,
    manuallyParams,
                                                            storageSuppressedKeys
}: UsePageURLParamsProps<Params>): PageUrlParamsObject<Params> => {
    const location = useLocation();
    const history = useHistory();
    const locationPathname = location.pathname;
    const locationSearch = location.search;

    const queryParams = useMemo(() => ParamsUtils.getParamsFromSearchString(locationSearch), [locationSearch]);
    const savedParamsRef = useRef<Params>();

    const savedParams = useMemo(() => {
        if (skip && savedParamsRef.current != null) {
            return savedParamsRef.current;
        }

        const params = ParamsUtils.getParamsFromParamsDescription(paramsDescription, queryParams, initialValues);

        if (manuallyParams == null) return params;

        return {
            ...params,
            ...manuallyParams(params),
        };
    }, [initialValues, manuallyParams, paramsDescription, queryParams, skip]);
    savedParamsRef.current = savedParams;

    const getSearchParamsFromParams = useCallback(
        (params: Partial<Params>) => {
            const newQueryParams = { ...queryParams };

            for (const paramKey in params) {
                const paramValue = params[paramKey];
                const description = paramsDescription[paramKey];

                if (description) {
                    newQueryParams[paramKey] = description.toString(paramValue!)!;
                }
            }

            const searchParams = new URLSearchParams();

            for (const queryParamKey in newQueryParams) {
                const param = newQueryParams[queryParamKey];
                if (param != null && typeof param !== 'object') {
                    searchParams.append(queryParamKey, param);
                }
            }

            return searchParams;
        },
        [paramsDescription, queryParams]
    );

    const setPageParamsToUrl = useCallback<SetPageParams<Params>>(
        (params: Partial<Params>, opts) => {
            const { resistOuterParams, replaceWithoutRerender } = opts ?? {};

            const newParams =
                outerParams && resistOuterParams
                    ? ObjectUtils.strictMap(params, (key, value) => {
                          if (outerParams.includes(key)) return savedParams[key];
                          return value;
                      })
                    : params;

            const searchParams = getSearchParamsFromParams(newParams);
            const searchString = searchParams.toString();
            const searchParamsObject = Object.fromEntries(searchParams.entries());

            const filteredSearchParamsObject = storageSuppressedKeys != null
                ? ObjectUtils.filterByKeys(searchParamsObject, storageSuppressedKeys, "exclude")
                :searchParamsObject
            ParamsUtils.setGridStorageDataParamsFilters(pageName, filteredSearchParamsObject);

            const replaceUrl = (url: string) => {
                if (replaceWithoutRerender) {
                    window.history.replaceState(null, '', url);
                } else {
                    history.replace(url);
                }
            };

            if (searchString) {
                const newUrl = `${locationPathname}?${searchString}`;
                replaceUrl(newUrl);
            } else {
                replaceUrl(locationPathname);
            }
        },
        [outerParams, getSearchParamsFromParams, storageSuppressedKeys, pageName, savedParams, history, locationPathname]
    );

    const updatePageParams = useCallback<SetPageParams<Params>>(
        (params, opts) => {
            return setPageParamsToUrl(
                {
                    ...savedParams,
                    ...params,
                },
                opts
            );
        },
        [savedParams, setPageParamsToUrl]
    );

    const deletePageParam = useCallback(
        (key: keyof Params) => {
            const pageParamsCopy = cloneDeep(savedParams);

            pageParamsCopy[key] = undefined!;

            setPageParamsToUrl(pageParamsCopy);
        },
        [savedParams, setPageParamsToUrl]
    );

    return useMemo(
        () =>
            ({
                pageParams: savedParams,
                setPageParams: setPageParamsToUrl,
                updatePageParams,
                deletePageParam,
                getSearchParams: getSearchParamsFromParams,
                outerParams,
                hiddenParams,
            } satisfies PageUrlParamsObject<Params>),
        [deletePageParam, getSearchParamsFromParams, hiddenParams, outerParams, savedParams, setPageParamsToUrl, updatePageParams]
    );
};

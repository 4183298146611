import { connect } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import {
    createProductInstance,
    getProductInstanceById,
    listProductVariants,
    resetError,
    resetInstance,
    updateProductInstanceById,
} from '../reducers/product-instanсe.reducer';
import { goBack, push } from 'connected-react-router';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { formFields, getInstanceStateValueByCode } from './product-page-instances-fields';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { InstanceTrackingTypeCodeEnum } from '../../../../../server/api';
import { loadEntity } from '../../products/reducers/product.reducer';
import { getStateFromPath2 } from '../../../../../shared/util/utils';
import { ModalFormNEW, ModalFormNEWProps, ModalFormNEWState } from '../../../../../components/modalForm/ModalFormNEW';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

interface IState extends ModalFormNEWState {}

interface IProps
    extends Omit<ModalFormNEWProps, 'updating' | 'updatingError' | 'modalFilters'>,
        StateProps,
        DispatchProps,
        WrappedComponentProps {
    entityId: number;
    spin(): void;
    variant?: any;
    businessVersionVariant?: number;
    copyMode?: boolean;
}

class ProductPageInstancesModal extends ModalFormNEW<IProps, IState> {
    static defaultProps = {
        formFields: formFields,
    };

    _componentDidMount = () => {
        if ((this.props.editMode || this.props.copyMode) && this.props.entity && this.props.entityId) {
            this.props.getProductInstanceById(this.props.intl, this.props.businessAccountId, this.props.entity.id, this.props.entityId);
        }
    };

    onOk = (data) => {
        if (data.comment === '') {
            delete data.comment;
        }

        if (data.barcode === '') {
            delete data.barcode;
        }

        let productBusinessVersion: number;
        const instanceTrackingTypeCode = this.props.entity ? this.props.entity.instanceTrackingTypeCode : InstanceTrackingTypeCodeEnum.BULK;
        if (this.props.editMode) {
            if (this.props.entity && this.props.listVariants) {
                productBusinessVersion = this.props.listVariants?.productBusinessVersion || 0;

                if (data.sellerId) data.sellerId = data.sellerId['key'];

                this.props.updateProductInstanceById(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entity.id,
                    this.props.listVariants.id,
                    {
                        ...data,
                        productBusinessVersion: productBusinessVersion,
                        businessVersion: this.props.listVariants.businessVersion,
                        stateCode: this.props.listVariants.stateCode,
                    },
                    instanceTrackingTypeCode,
                    this.props.listVariants.variantId || 0
                );
            }
        } else {
            if (this.props.entity) {
                let variantKey = 0;
                if (this.props.copyMode) {
                    variantKey = this.props.listVariants?.variantId || 0;
                    productBusinessVersion = this.props.listVariants?.productBusinessVersion || 0;
                } else {
                    variantKey = data ? data.listVariants || 0 : 0;
                    productBusinessVersion = this.props.entity.businessVersion;
                }

                if (data.supplierId) data.supplierId = data.supplierId['key'];

                if (data.sellerId) data.sellerId = data.sellerId['key'];

                this.props.createProductInstance(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entity.id,
                    {
                        ...data,
                        productBusinessVersion,
                    },
                    instanceTrackingTypeCode,
                    variantKey
                );
            }
        }

        this.props.resetInstance();
        this.props.spin();
    };

    onCancel = async (isFieldsTouched: boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetError();
            this.props.resetInstance();
        }
    };

    getInitialValues = () => {
        let initialValues: Record<string, any>;
        const defaultValues: typeof initialValues = {
            productId: this.props.entity!.id,
            instanceTrackingTypeCode: this.props.entity!.instanceTrackingTypeCode,
            instanceEdit: this.props.editMode,
        };

        const variants =
            this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
            this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED;

        const createInitialValues = (initialValues = {
            variantId: { key: undefined, label: undefined },
            listVariants: getStateFromPath2(this.props.location.search).variantId
                ? getStateFromPath2(this.props.location.search).variantId
                : this.props.variant,
            variants,
        });
        const editInitialValues = {
            ...this.props.listVariants,
            nameOrCode: this.props.listVariants ? this.props.listVariants.nameOrCode : undefined,
            comment: this.props.listVariants && this.props.listVariants.comment ? this.props.listVariants.comment : '',
            stateDescription:
                this.props.listVariants && this.props.listVariants.stateDescription ? this.props.listVariants.stateDescription : '',
            stateCode: this.props.listVariants ? this.props.listVariants.stateCode : undefined,
            variantId: this.props.listVariants ? this.props.listVariants.variantId : undefined,
            instanceId: this.props.instance ? this.props.instance.id : undefined,
            variants: false,
            listVariants: this.props.listVariants?.variantId,
            supplierId: this.props?.instance?.supplierId
                ? {
                      key: '' + this.props.instance.supplierId,
                      label: this.props.instance.supplierShortName,
                  }
                : undefined,
            sellerId: this.props?.instance?.sellerId
                ? {
                    key: '' + this.props.instance.sellerId,
                    label: this.props.instance.sellerShortName,
                }
                : undefined,
        };

        const copyInitialValues = {
            ...editInitialValues,
            id: undefined,
            instanceId: undefined,
            variants,
            listVariants: this.props.listVariants?.variantId,
            stateCode: this.props.listVariants ? this.props.listVariants.stateCode : undefined,
        };

        if (this.props.editMode) {
            initialValues = editInitialValues;
        } else if (this.props.copyMode) {
            initialValues = copyInitialValues;
        } else {
            initialValues = createInitialValues;
        }

        return {
            ...defaultValues,
            ...initialValues,
        };
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    entity: storeState.product.entity,
    instance: storeState.conditionProductInstance.entity,
    businessAccountId: storeState.system.businessAccountId,
    listVariants: storeState.conditionProductInstance.entity,
    updating: storeState.changeInstanceStatus.updating,
    updatingError: storeState.productInstance.updatingError,
    location: storeState.router.location,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    createProductInstance,
    updateProductInstanceById,
    goBack,
    resetError,
    listProductVariants,
    getProductInstanceById,
    loadEntity,
    resetInstance,
    push,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductPageInstancesModal));

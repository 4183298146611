import {FormItemType} from '../../../../../components/dynamicForm/DynamicForm';
import {FormUtils, WrappedFormUtilsTyped} from '../../../../../core/utils/formUtils';
import {LocalizationEnum} from '../../../../../localization';
import React from 'react';
import {PaymentMethodModalFormData} from './paymentMethodModalFormFields.types';
import debounce from "lodash/debounce";
import {validateField} from "../../../../../shared/util/validateField";

export const paymentMethodModalFormFields = FormUtils.typedFormFields<PaymentMethodModalFormData>([
    {
        fields: [
            {
                id: 'id',
                type: FormItemType.Hidden,
            },
            {
                label: 'Наименование',
                id: 'name',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<PaymentMethodModalFormData>) => {
                    const id = form.getFieldValue('id');
                    validateField('paymentMethod', fieldName, value, undefined, id, 'Наименование способа оплаты уже использовано', cb);
                }, 500)
            },
            {
                label: 'Способ по умолчанию',
                id: 'isDefault',
                type: FormItemType.Switch,
                defaultValue: false
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);

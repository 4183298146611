import React from "react";
import {ProjectEditBlock, ProjectEditBlockProps} from "./projectEditBlock";
import {useAppSelector} from "../../../../../../../store/hooks";
import {IRootState} from "../../../../../../../shared/reducers";
import {OperationTypeCodeEnum} from "../../../../../../../server";

interface ProjectEditBlock11Props {
    editButton: ProjectEditBlockProps['editButton'];
    parentEntityId?: number;
    isNowEditing: boolean;
}

export const ProjectEditBlock11 = (props:ProjectEditBlock11Props) => {

    let actualSumAfterTaxes:number|undefined;
    let debtSum:number|undefined;

    const isEditOperation = useAppSelector((state: IRootState) => state.operationForm.typeCode === OperationTypeCodeEnum.EDIT);
    const currentProject = useAppSelector((state: IRootState) => state.project.entity);
    const currentOffer = useAppSelector((state: IRootState) => state.offer.entity);

    if(isEditOperation){
        actualSumAfterTaxes = undefined;
        debtSum = undefined;
    }else{
        if(currentProject){
            actualSumAfterTaxes = currentProject.actualSumAfterTaxes;
            debtSum = currentProject.debtSum;
        }else if(currentOffer){
            actualSumAfterTaxes = currentOffer.actualSumAfterTaxes;
        }
    }

    return (
        <ProjectEditBlock
            parentEntityIsCreated={true}
            withoutLabel={true}
            debtSum={debtSum}
            actualSumAfterTaxes={actualSumAfterTaxes}
            editButton={props.editButton}
            isNowEditing={props.isNowEditing}
        />
    );
};

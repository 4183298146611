import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { LinkTagsArgs, ListTagsArgs } from './types';
import { serverApi, TagEntityTypeCode } from '../../../../server';
import _ from 'lodash';
import { AxiosError } from 'axios';
import { showNotification } from '../../../notification/showNotification';
import { ServerUtils } from '../../../../core/utils/serverUtils';

export const tagsApi = createApi({
    reducerPath: 'tagsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Tags'],
    endpoints: (builder) => ({
        listTags: builder.query<string[], ListTagsArgs>({
            queryFn: async ({ businessAccountId, tagType, parentEntity, additionalQueryFilters, ignoreQueryFilters }) => {
                const queryMethod = {
                    [TagEntityTypeCode.PROJECT]: serverApi.listProjectsAvailableFiltersValues.bind(serverApi),
                    [TagEntityTypeCode.RENTELEMENT]: serverApi.listBusinessAccountElementsAvailableFiltersValues.bind(serverApi),
                }[tagType];

                const filterId: string = (
                    {
                        project: 'projectId',
                        operation: 'operationId',
                        projectTemplate: 'templateId',
                        shipping: 'subrentId',
                    } satisfies Partial<Record<(typeof parentEntity & object)['entityType'], string>>
                )[parentEntity?.entityType ?? ''];

                const queryFilters =
                    parentEntity != null && filterId != null
                        ? parentEntity?.entityType === 'operation'
                            ? ServerUtils.createRequestFilters([['operationIds', 'IN', [parentEntity.id]]])
                            : ServerUtils.createRequestFilters([[filterId, 'EQ', parentEntity.id]])
                        : undefined;

                try {
                    const response = await queryMethod(
                        businessAccountId,
                        ignoreQueryFilters ? undefined : (queryFilters ?? []).concat(additionalQueryFilters ?? [])
                    );
                    const { filters } = response.data;
                    return {
                        data: _.flatten(filters.map(({ strings }) => strings ?? [])),
                    };
                } catch (error: unknown) {
                    showNotification('error', 'Не удалось загрузить список тегов');
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['Tags'],
        }),
        linkTags: builder.mutation<number, LinkTagsArgs>({
            queryFn: async ({ businessAccountId, createLink, tagBulkLinkObj }) => {
                try {
                    const changesCount = (await serverApi.linkTagsBulk(businessAccountId, createLink, tagBulkLinkObj)).data;

                    if (changesCount > 0) {
                        showNotification('success', `Теги ${changesCount} объект${changesCount === 1 ? 'а' : 'ов'} изменены`);
                    } else {
                        showNotification('error', `Не удалось обновить теги`);
                    }

                    return {
                        data: changesCount,
                    };
                } catch (error) {
                    showNotification('error', `Не удалось обновить теги`);
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            invalidatesTags: ['Tags'],
        }),
    }),
});

export const { useLazyListTagsQuery } = tagsApi;
export const { useLinkTagsMutation } = tagsApi;

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { RentElementRecordList, serverApi } from '../../../../../../server';
import {
    ListAccessibilityRentElementsArgs,
    ListRentElementsArgs,
    ListRentElementsWithAccessibilityResult,
} from './elementsCalendar.api.types';
import { AxiosError } from 'axios';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { ElementsCalendarApiUtils } from './elementsCalendar.api.utils';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { IRootState } from '../../../../../../shared/reducers';
import { setCalendarBoundaries } from '../reducers/elementsCalendar.reducer';
import { processActualCalendarBoundaries } from '../reducers/elementsCalendar.reducer.utils';
import { elementsCalendarScreenLeft, elementsCalendarScreenRight } from '../reducers/elementsCalendar.reducer.selectors';
import { AccessibilityMapCalendarApiRequestUtils } from '../../accessibilityMap/api/accessibilityMapCalendar.api.utils.request';
import moment from 'moment';

export const elementsCalendarApi = createApi({
    reducerPath: 'elementsCalendarApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        listRentElements: builder.query<RentElementRecordList, ListRentElementsArgs>({
            queryFn: async (args, { getState, dispatch }) => {
                const { businessAccountId, intl, params, visibleItemsLimit } = args;
                const state = getState() as IRootState;
                const subrentModuleEnabled = subrentModuleEnabledSelector(state);
                const storeScreenLeft = elementsCalendarScreenLeft(state);
                const sortBy = ElementsCalendarApiUtils.getSortBy(params.group);
                const sortOrder = ElementsCalendarApiUtils.getSortOrder(sortBy);
                const filters: string[] = ElementsCalendarApiUtils.getFilters(params, subrentModuleEnabled);
                const { capture } = params;

                try {
                    const { data } = await serverApi.listBusinessAccountElements(
                        businessAccountId,
                        visibleItemsLimit,
                        0,
                        filters,
                        sortBy,
                        sortOrder,
                        params.search,
                        undefined,
                        undefined,
                        capture !== 'all' && capture !== 'allWithNow'
                            ? moment(params.screenLeft ?? storeScreenLeft)
                                  .utc()
                                  .toDate()
                            : undefined
                    );

                    const { screenLeft, screenRight } = processActualCalendarBoundaries(params, data.records);

                    dispatch(
                        setCalendarBoundaries({
                            screenLeft,
                            screenRight,
                        })
                    );

                    return {
                        data,
                    };
                } catch (error) {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
        listRentElementsWithAccessibility: builder.query<ListRentElementsWithAccessibilityResult, ListAccessibilityRentElementsArgs>({
            queryFn: async (args, { getState, dispatch }) => {
                const { businessAccountId, params, visibleItemsLimit, intl } = args;
                const state = getState() as IRootState;
                const subrentModuleEnabled = subrentModuleEnabledSelector(state);
                const storeScreenLeft = elementsCalendarScreenLeft(state);
                const storeScreenRight = elementsCalendarScreenRight(state);
                const sortBy = ElementsCalendarApiUtils.getSortBy(params.group);
                const sortOrder = ElementsCalendarApiUtils.getSortOrder(sortBy);
                const elementsFilters: string[] = ElementsCalendarApiUtils.getFilters(params, subrentModuleEnabled);

                try {
                    const data = await AccessibilityMapCalendarApiRequestUtils.getAccessibilityData({
                        businessAccountId,
                        dispatch,
                        elementsFilters,
                        params,
                        sortBy,
                        sortOrder,
                        storeScreenLeft,
                        storeScreenRight,
                        subrentModuleEnabled,
                        visibleItemsLimit,
                        specialFromStartDateIncludingDelays: moment(params.screenLeft ?? storeScreenLeft)
                            .utc()
                            .toDate(),
                    });

                    return {
                        data,
                    };
                } catch (error) {
                    showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useListRentElementsQuery, useListRentElementsWithAccessibilityQuery } = elementsCalendarApi;

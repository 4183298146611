import React, { FC } from 'react';
import { Icon } from 'antd';
import { IconTelegram } from '../../../icons';
import './TelegramLink.less';

interface TelegramLinkProps {}

export const TelegramLink: FC<TelegramLinkProps> = () => {
    return (
        <div className={'telegram-link-container'}>
            <Icon className={'telegram-link-icon'} component={IconTelegram} />
            <span className={'telegram-link-text'}>
                Чат службы поддержки в{' '}
                <a className={'telegram-link'} target={'_blank'} href="https://t.me/rentrabbit_support">
                    Telegram
                </a>
            </span>
        </div>
    );
};

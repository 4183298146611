import { Unit } from 'react-calendar-timeline';
import moment from 'moment/moment';
import { useCallback } from 'react';
import { CalendarURLParams } from '../types/params';
import { PageUrlParamsObject } from '../../../../../../core/hooks/urlParams/types';

export const useIntervalClick = <Params extends object, SortValue extends string>(
    updatePageParams: PageUrlParamsObject<CalendarURLParams<Params, SortValue>>['updatePageParams']
) => {
    const onIntervalChange = useCallback(
        (from: number, to: number) => {
            updatePageParams({
                screenLeft: from,
                screenRight: to,
                capture: undefined,
            } as CalendarURLParams<Params, SortValue>);
        },
        [updatePageParams]
    );

    return useCallback(
        (unit: Unit, primary: boolean, startTime: moment.Moment, endTime: moment.Moment) => {
            let units: Unit[] = ['hour', 'day', 'week', 'month', 'year'];
            let toUnit: Unit = units[units.indexOf(unit) + (primary ? 1 : -1)];
            let left: number | undefined, right: number | undefined;

            if (toUnit === 'day') {
                left = +startTime;
                right = +endTime;
            } else if (toUnit === 'week') {
                left = +startTime - 86400000 * 3.5;
                right = +startTime + 86400000 * 4.5;
            } else if (toUnit === 'month') {
                left = +startTime.startOf(toUnit);
                right = +startTime.endOf(toUnit);
            } else if (toUnit === 'year') {
                left = +startTime.month(0);
                right = +startTime.month(12);
            }

            if (left && right) onIntervalChange(left, right);
        },
        [onIntervalChange]
    );
};

import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { ActivityFramesCalendarFilters } from './activityFramesCalendarFilters.types';
import { CalendarDisplayType } from '../../../components/Calendar/utils/data/calendarConstants';
import { ProblemEnum } from '../../../../../../types';
import { RentActivityFrameStateCodeEnum, RentActivityFrameTypeCodeEnum } from '../../../../../../server';
import { HideValue } from '../../elements/filters/elementsCalendarFilters.data';

export const activityFramesCalendarFiltersDescription: Required<PageURLParamDescriptionObject<ActivityFramesCalendarFilters>> = {
    capture: PageURLParamDescriptions.searchParam(),
    displayType: PageURLParamDescriptions.enumSelectParam(CalendarDisplayType),
    hide: PageURLParamDescriptions.enumSelectParam(HideValue),
    problem: PageURLParamDescriptions.enumSelectParam(ProblemEnum),
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    search: PageURLParamDescriptions.searchParam(),
    group: PageURLParamDescriptions.searchParam(),
    rentActivityFrameTypeCode: PageURLParamDescriptions.enumSelectParam(RentActivityFrameTypeCodeEnum),
    typeCode: PageURLParamDescriptions.arrayOfEnumParam(RentActivityFrameStateCodeEnum),
    renterId: PageURLParamDescriptions.numberParam,
    locationId: PageURLParamDescriptions.numberParam,
    assigneeId: PageURLParamDescriptions.numberParam,
    tags: PageURLParamDescriptions.arrayOfStringsParam,
};

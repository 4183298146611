import React, {CSSProperties} from "react";
import {formatMoney} from "../../shared/util/formatMoney";

interface IProps {
    value: any;
    style?: CSSProperties;
    onClick?: any;
    className?: string;
    withOverpay: boolean;
    overpayBelow?: boolean;
}

export const FormValueMoneyProblem = (props: IProps) => {
    return <>
        <span onClick={props.onClick} style={props.style} className={(props.className ? props.className : '')}>{formatMoney(props.value)}</span>
        {
            props.value < 0 && props.withOverpay ? <span style={{fontSize: 15, verticalAlign: 'text-bottom', marginLeft: !props.overpayBelow ? 8 : 0, lineHeight: props.overpayBelow ? 1.5 : undefined}}>(Переплата)</span> : null
        }
    </>;
};

import React, { ReactNode } from 'react';
import { Popover as OriginalPopover } from 'antd';
import { PopoverContent } from './PopoverContent';
import { PopoverProps as OriginalPopoverProps } from 'antd/lib/popover';

export interface PopoverProps extends Partial<OriginalPopoverProps> {
    width?: number;
    visible?: boolean;
    children: (() => ReactNode) | ReactNode;
    onVisibleChange?: (visible: boolean) => void;
    header?: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
    headerClassName?: string;
    overlayClassName?: string;
    onClose?: () => void;
    content2?: ReactNode;
}

export interface IState {}

export class Popover extends React.PureComponent<PopoverProps, IState> {
    public static defaultProps = {
        trigger: 'click',
    };

    render() {
        const {
            width,
            onVisibleChange,
            visible,
            overlayClassName,
            header,
            headerClassName,
            onClose,
            content,
            footer,
            children,
            ...originalPopoverProps
        } = this.props;

        return (
            <OriginalPopover
                overlayStyle={{ width: width }}
                onVisibleChange={onVisibleChange}
                {...(visible !== undefined ? { visible: visible } : {})}
                overlayClassName={'rr-grid-actions-popover' + (overlayClassName ? ' ' + overlayClassName : '')}
                autoAdjustOverflow
                arrowPointAtCenter
                placement="bottomLeft"
                content={
                    !this.props.content2 ? (
                        <PopoverContent
                            content={content}
                            footer={footer}
                            headerClassName={headerClassName}
                            header={header}
                            onClose={onClose}
                        />
                    ) : (
                        this.props.content2
                    )
                }
                {...originalPopoverProps}
            >
                {typeof children === 'function' ? (children as Function)() : children}
            </OriginalPopover>
        );
    }
}

import { CalendarSortValue } from '../../../components/Calendar/components/ActionsButtons/SortButton/SortByButton';
import { TypeGroupLine } from '../components/GroupLine/variants/TypeGroupLine';
import { RenterGroupLine } from '../components/GroupLine/variants/RenterGroupLine';
import { LocationGroupLineWrapper } from '../components/GroupLine/variants/LocationGroupLine';
import { StatusGroupLine } from '../components/GroupLine/variants/StatusGroupLine';
import { AssigneeGroupLine } from '../components/GroupLine/variants/AssigneeGroupLine';
import { CalendarGroupsObject } from '../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import { useMemo } from 'react';
import { logisticsModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { useAppSelector } from '../../../../../../store/hooks';
import { CalendarSortGroupMap } from '../../../components/Calendar/hooks/useCalendarItems';

export type ActivityFramesSortValue = 'type' | 'renter' | 'location' | 'status' | 'start' | 'end' | 'assignee';

export const activityFramesCalendarSortData: CalendarSortValue<ActivityFramesSortValue>[] = [
    { value: 'type', title: 'Типу' },
    { value: 'renter', title: 'Контрагентам' },
    { value: 'status', title: 'Статусу' },
    { value: 'assignee', title: 'Ответственному' },
    { value: 'start', title: 'Началу' },
    { value: 'end', title: 'Завершению' },
];

export const useActivityFramesCalendarSortValueToItemKey = (): CalendarSortGroupMap<ActivityFramesSortValue> => {
    const logisticsModuleEnabled = useAppSelector(logisticsModuleEnabledSelector);

    return useMemo(() => {
        const groupMap: CalendarSortGroupMap<ActivityFramesSortValue> = {
            type: 'rentActivityFrameTypeCode',
            renter: 'counterpartyId',
            status: 'stateCode',
            assignee: 'assigneeId',
        };

        if (logisticsModuleEnabled) {
            groupMap.location = 'locationId';
        }

        return groupMap;
    }, [logisticsModuleEnabled]);
};

export const useActivityFramesCalendarGroups = (onClick: (id: number) => void): CalendarGroupsObject<ActivityFramesSortValue> => {
    return useMemo(
        () => ({
            type: TypeGroupLine,
            renter: RenterGroupLine,
            location: LocationGroupLineWrapper(onClick),
            status: StatusGroupLine,
            assignee: AssigneeGroupLine,
        }),
        [onClick]
    );
};

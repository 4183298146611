import { ReactNode, useMemo } from 'react';
import moment from 'moment/moment';

const upperCaseFirstSymbol = (str: string) => str[0].toUpperCase() + str.slice(1);

export type DownloadPeriodValues = 'currentMonth' | 'lastMonth' | 'monthBeforeLast' | 'currentYear' | 'lastYear';

export type DownloadDocumentPeriodOptionsData = {
    value: DownloadPeriodValues;
    name: ReactNode;
}[];

export const useSelectOptions = (): DownloadDocumentPeriodOptionsData => {
    return useMemo(() => {
        return [
            {
                value: 'currentMonth',
                name: upperCaseFirstSymbol(moment().startOf('month').format('MMMM YYYY')),
            },
            {
                value: 'lastMonth',
                name: upperCaseFirstSymbol(moment().subtract(1, 'months').format('MMMM YYYY')),
            },
            {
                value: 'monthBeforeLast',
                name: upperCaseFirstSymbol(moment().subtract(2, 'months').format('MMMM YYYY')),
            },
            {
                value: 'currentYear',
                name: `Весь ${upperCaseFirstSymbol(moment().startOf('year').format('YYYY'))}`,
            },
            {
                value: 'lastYear',
                name: `Весь ${upperCaseFirstSymbol(moment().subtract(1, 'years').format('YYYY'))}`,
            },
        ];
    }, []);
};

import { ExcelExportTemplateTypeCodeEnum, serverApi } from '../../../../../server';
import { downloadDataFromExcel } from '../../../../../shared/util/downloadDataFromExcel';
import { showNotification } from '../../../../notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { IntlShape } from 'react-intl';

export const downloadExcelOffloading = async (args: {
    intl: IntlShape;
    businessAccountId: number;
    columns: string[];
    filters: string[];
    fileName: string;
    exportTemplateType: ExcelExportTemplateTypeCodeEnum;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    search?: string;
}) => {
    const { intl, businessAccountId, columns, filters, sortBy, sortOrder, search, fileName, exportTemplateType } = args;

    try {
        const response = await serverApi.generateExportXlsx(
            businessAccountId,
            {
                columns,
                exportTemplateType,
            },
            filters,
            sortBy,
            sortOrder,
            search,
            {
                responseType: 'blob',
            }
        );

        downloadDataFromExcel(response.data, fileName);
    } catch (e) {
        showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXPORT_TO_EXCEL_FAILED));
    }
};

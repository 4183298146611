import { FC, ReactNode, useCallback } from 'react';

import { GridName } from '../../../grid/utils/types';

interface GridScrollControllerProps {
    children: (props: { onPageChange: () => void }) => ReactNode;
    gridName: GridName;
}

const eventName = 'gridDataChanged';

export const gridDataChangedSignal = () => {
    window.dispatchEvent(new Event(eventName));
};

export const getGridAnchorClassName = (gridName: GridName) => `${gridName}_anchor`;

export const SelectScrollController: FC<GridScrollControllerProps> = ({ children, gridName }) => {
    const handleScroll = useCallback(() => {
        const anchorNode = document.getElementsByClassName(getGridAnchorClassName(gridName))[0];
        if (anchorNode) {
            const nodeRectData = anchorNode.getBoundingClientRect();
            if (nodeRectData.y < 0) {
                anchorNode.scrollIntoView();
            }
        }
    }, [gridName]);

    const handleChange = useCallback(() => {
        const listenerCallback = () => {
            setTimeout(() => {
                handleScroll();
            }, 300);
            window.removeEventListener(eventName, listenerCallback);
        };

        window.addEventListener(eventName, listenerCallback);
    }, [handleScroll]);

    return <>{children({ onPageChange: handleChange })}</>;
};

import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    NomenclatureEntityObj,
    NomenclatureRecord,
    NomenclatureRecordList,
    NomenclatureWorkflowDirective,
    ProductRecordList,
    serverApi,
} from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getStateFromPath2, getStringServerProblem, test1 } from '../../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { changeCategoryBinding } from '../../../categories/reducers/categories.reducer';
import { ProblemEnum } from '../../../../../types';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { productsApi } from '../api/products.api';
import { changeCustomFieldsBindings } from '../../../settings/customFields/reducers/customFields.reducer';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'products/LOAD_ENTITIES',
    DELETE_ENTITIES: 'products/DELETE_ENTITIES',
    RESET: 'products/RESET',
    CHANGE_ARCHIVE: 'products/CHANGE_ARCHIVE',
    CHANGE_STATUS: 'products/CHANGE_STATUS',
    CHANGE_LOADING: 'products/CHANGE_LOADING',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'lastActivityDate' as string | undefined,
    sortOrder: 'DESC' as 'DESC' | 'ASC' | undefined,
    status: undefined as string | undefined,
    page: 1,
    limit: 10,
    hideArchive: undefined as boolean | undefined,
    categoryIds: undefined as string | undefined,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    pricePerShift: undefined as number[] | undefined,
    problem: undefined as string | undefined,
    hideVariants: undefined as boolean | undefined,
    instanceId: undefined as number | undefined,
};

const initialState = {
    loading: true,
    loadingError: null,
    entities: null as Array<NomenclatureRecordCustom> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    } as typeof initialParamsState & Record<string, any>,
};

export interface NomenclatureRecordCustom extends NomenclatureRecord {
    id: string;
}

export type ProductsState = Readonly<typeof initialState>;

// Reducer

export default (state: ProductsState = initialState, action): ProductsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (/\/\d+\/inventory\/products/.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        status: params.status !== undefined ? params.status : initialParamsState.status,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideArchive: params.hideArchive !== undefined ? params.hideArchive : initialParamsState.hideArchive,
                        categoryIds: params.categoryIds !== undefined ? params.categoryIds : initialParamsState.categoryIds,
                        shortage: params.shortage !== undefined ? params.shortage : initialParamsState.shortage,
                        delay: params.delay !== undefined ? params.delay : initialParamsState.delay,
                        pricePerShift: test1(params.pricePerShift, initialParamsState.pricePerShift)?.map((price) => Math.round(price)),
                        problem: params.problem !== undefined ? params.problem : initialParamsState.problem,
                        hideVariants: params.hideVariants !== undefined ? params.hideVariants : initialParamsState.hideVariants,
                        instanceId: params.instanceId !== undefined ? params.instanceId : initialParamsState.instanceId,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };

        case REQUEST(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            let records = (action.payload.data as NomenclatureRecordList).records.map((record) => ({
                id: record.productId + (record.variantId ? '_' + record.variantId : ''),
                ...record,
            }));
            return {
                ...state,
                loading: false,
                entities: records,
                filteredCount: (action.payload.data as ProductRecordList).listAttributes.filteredCount,
            };
        case REQUEST(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ENTITIES):
            return {
                ...state,
                loading: false,
            };
        case changeCategoryBinding.pending.type:
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case changeCategoryBinding.rejected.type:
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case changeCategoryBinding.fulfilled.type:
            return {
                ...state,
                loading: false,
                loadingError: null,
            };
        case changeCustomFieldsBindings.pending.type:
            return {
                ...state,
                loadingError: null,
                loading: true,
            };
        case changeCustomFieldsBindings.rejected.type:
            return {
                ...state,
                loadingError: action.payload,
                loading: false,
            };
        case changeCustomFieldsBindings.fulfilled.type:
            return {
                ...state,
                loading: false,
                loadingError: null,
            };
        case ACTION_TYPES.CHANGE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

export const loadEntities = (intl, businessAccountId: number, customFilters: string[] = []) => {
    return (dispatch, getState) => {
        const state = getState() as IRootState;
        const product: ProductsState = state.products;
        const subrentModuleEnabled = subrentModuleEnabledSelector(state);
        const { params } = product;
        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;

        const filters = getProductsListFilters(params, subrentModuleEnabled);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listNomenclature(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: [...filters, ...customFilters] } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
                productsApi.util?.invalidateTags(['ProductsList']);
            }
        });
    };
};

export const deleteProducts = (intl, businessAccountId: number, objects: NomenclatureEntityObj[]) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITIES,
            payload: serverApi.deleteNomenclatureBulk(businessAccountId, { entities: objects }),
        }).then((result) => {
            let isMany = objects.length > 1;
            if (result instanceof Error) {
                showNotification('error', isMany ? 'Номенклатуры не удалены' : 'Номенклатура не удалена');
            } else {
                showNotification('success', isMany ? 'Номенклатуры удалены' : 'Номенклатура удалена');
                dispatch(productsApi.util?.invalidateTags(['ProductsList']));
                dispatch(loadEntities(intl, businessAccountId));
            }
        });
    };
};

export const setArchiveState = (intl, businessAccountId: number, directives: Array<NomenclatureWorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitNomenclatureWorkflowBulk(businessAccountId, { directives: directives }), // TODO Тут должен быть метод для Продуктов, а не для Арендаторов
        }).then((result) => {
            if (result instanceof Error) {
                // В архив не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // В архив изменен
                showNotification('success', directives.length === 1 ? 'Номенклатура изменена' : 'Номенклатуры изменены');
                dispatch(productsApi.util?.invalidateTags(['ProductsList']));
                dispatch(loadEntities(intl, businessAccountId));
            }
        });
    };
};

export const setStatus = (intl, businessAccountId: number, directives: Array<NomenclatureWorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_STATUS,
            payload: serverApi.transitNomenclatureWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус арендатора не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Статус арендатора изменен
                showNotification('success', directives.length === 1 ? 'Номенклатура изменена' : 'Номенклатуры изменены');
                dispatch(productsApi.util?.invalidateTags(['ProductsList']));
                setTimeout(() => dispatch(loadEntities(intl, businessAccountId)), 1500);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const getProductsListFilters = (params: typeof initialParamsState, subrentModuleEnabled: boolean | undefined): string[] => {
    let filters: string[] = [];
    if (params.status) filters.push('stateCode;EQ;' + params.status);
    if (params.hideArchive === true) filters.push('archive;EQ;false');
    let problem: string | undefined = params.problem;
    if (
        !subrentModuleEnabled &&
        (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
    )
        problem = undefined;
    if (problem) filters.push(getStringServerProblem(problem));
    if (params.categoryIds !== undefined) filters.push('categoryIds;IN;' + params.categoryIds);
    if (params.pricePerShift) {
        if (params.pricePerShift[0] !== undefined) filters.push('pricePerShift;GE;' + MoneyUtils.toCents(params.pricePerShift[0]));
        if (params.pricePerShift[1] !== undefined) filters.push('pricePerShift;LE;' + MoneyUtils.toCents(params.pricePerShift[1]));
    }
    if (params.hideVariants) filters.push('nomenclatureEntityTypeCode;IN;PRODUCT');
    else {
        filters.push('nomenclatureEntityTypeCode;IN;PRODUCT;VARIANT');
    }
    if (params.instanceId) filters.push(`instanceIds;IN;${params.instanceId}`);
    return filters;
};

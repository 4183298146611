import React, { FC } from 'react';
import { EditableField, EditableFieldProps } from '../EditableField';

interface EditableAmountFieldProps extends Pick<EditableFieldProps, 'currentValue' | 'baseValue' | 'onChange'> {}

const minValue = 1;
const maxValue = 99999;

export const EditableAmountField: FC<EditableAmountFieldProps> = (props) => {
    const { currentValue, baseValue, onChange } = props;

    return (
        <EditableField
            currentValue={currentValue}
            baseValue={baseValue}
            onChange={onChange}
            formatStyle={'number'}
            minValue={minValue}
            maxValue={maxValue}
        />
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../../shared/reducers';
import { Card, Icon } from 'antd';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { IconAngleUp, IconStreamSolid } from '../../../../../../components/icons';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../../localization';
import { SearchInput } from '../../../../../../components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Highlight from 'react-highlighter';
import RoundButton from '../../../../../../components/button/roundButton';
import { CustomFieldGroupObjRead } from '../../../../../../server/api';
import { ItemsNotFoundBlock } from '../../../../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import { Styles } from '../../../../../../styles';
import { Avatar } from '../../../../../../components/avatar/avatar';
import { canViewFinancialData } from '../../../../../../shared/util/permissionUtils';
import DescriptionCustomField from '../../../components/DescriptionCustomField/DescriptionCustomField';

interface IState {
    searchString?: string;
    collapsedGroups: string[];
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    entity:
        | {
              customFieldGroups?: CustomFieldGroupObjRead[];
          }
        | undefined;
}

class _ProductFeaturesView extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            collapsedGroups: [],
        };
    }

    onSearchChange = (e) => {
        this.setState({ searchString: e.target.value });
    };

    collapseGroup = (id?: string) => {
        if (id) {
            let collapsedGroups = [...this.state.collapsedGroups];
            if (collapsedGroups.indexOf(id) > -1) {
                collapsedGroups.splice(collapsedGroups.indexOf(id), 1);
            } else {
                collapsedGroups.push(id);
            }
            this.setState({ collapsedGroups });
        }
    };

    render() {
        const { canViewFinancialData } = this.props;

        let createPath = getPathFromState(this.props.location.pathname, this.props.location.search, { edit: true });

        let groups: CustomFieldGroupObjRead[] = [];

        let fieldsCount = 0;

        this.props.entity?.customFieldGroups?.forEach((group) => {
            let fields = group.fields.filter(
                (field) =>
                    !this.state.searchString ||
                    (this.state.searchString && field.name.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1)
            );
            fieldsCount += fields.length;
            if (fields.length > 0) {
                groups.push({
                    name: group.name,
                    main: group.main,
                    fields: fields,
                });
            }
        });

        return this.props.entity && groups ? (
            <>
                <Card
                    className={'rr-product-page-features-view-card'}
                    title={
                        <>
                            <div
                                className="ant-modal-title"
                                style={{ alignItems: 'center', padding: '7px 0px', marginTop: '-1px', marginBottom: '-2px' }}
                            >
                                <Icon
                                    component={IconStreamSolid}
                                    style={{ marginRight: 10, color: '#979cbf' }}
                                    className={Styles.ICON_26}
                                />
                                {localize(LocalizationEnum.PAGE__PRODUCTS__FEATURE_LIST)}
                                <SearchInput
                                    autoFocus={true}
                                    autoComplete={'off'}
                                    style={{ width: 200, marginLeft: 26 }}
                                    placeholder={
                                        localizeIntl(this.props.intl, LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER) as string
                                    }
                                    onChange={this.onSearchChange}
                                    className={'rr-input-gray'}
                                    allowClear={true}
                                />
                            </div>
                        </>
                    }
                    extra={
                        <Link to={createPath}>
                            <RoundButton colorScheme={'defaultSecondary'} style={{ marginRight: 16 }}>
                                <Icon component={IconStreamSolid} />
                                <span>{localize(LocalizationEnum.PAGE__PRODUCTS__CUSTOMIZE_FIELDS_BUTTON)}</span>
                            </RoundButton>
                        </Link>
                    }
                    bodyStyle={{ padding: 0 }}
                >
                    {fieldsCount ? (
                        groups.map((group, index) => {
                            let collapsed = group.name && this.state.collapsedGroups.includes(group.name);
                            return (
                                <div className={'rr-product-page-features-view-group'} key={index}>
                                    <div
                                        className={'rr-product-page-features-view-group-title'}
                                        onClick={() => this.collapseGroup(group.name)}
                                    >
                                        <Icon
                                            className={'rr-product-page-features-view-group-title-collapse-icon'}
                                            component={IconAngleUp}
                                            style={collapsed ? { transform: 'rotate(180deg)' } : {}}
                                        />
                                        {group.main
                                            ? (localizeIntl(this.props.intl, LocalizationEnum.PAGE__PRODUCTS__MAIN_ONES_LABEL) as string)
                                            : group.name}
                                    </div>
                                    <div
                                        className={'rr-product-page-features-view-group-block'}
                                        style={{ display: collapsed ? 'none' : 'block' }}
                                    >
                                        {group.fields && group.fields.length > 0 ? (
                                            group.fields.map((field, index) => {
                                                return (
                                                    <div key={index} className={'rr-product-page-features-view-item'}>
                                                        <div className={'rr-product-page-features-view-item-name'}>
                                                            <div className={'test'}>
                                                                <span>
                                                                    <Highlight
                                                                        matchElement={'span'}
                                                                        matchStyle={{ color: 'red' }}
                                                                        search={this.state.searchString}
                                                                    >
                                                                        {field.name}
                                                                    </Highlight>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {!canViewFinancialData && field.financial ? (
                                                            <div className={'rr-product-page-features-view-item-value-empty'}>Скрыто</div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    'rr-product-page-features-view-item-value' +
                                                                    (field.values && field.values.length ? '' : '-empty')
                                                                }
                                                            >
                                                                <DescriptionCustomField field={field} />
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div style={{ color: '#aaaaaa', fontSize: 16 }}>
                                                {localize(
                                                    LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__LIST_PLACEHOLDER_NO_FIELDS_EXIST
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : !this.state.searchString ? (
                        <div style={{ marginTop: 56, marginBottom: 20 }}>
                            <div className={'rr-grid-notfound-block'}>
                                <Avatar className={'rr-avatar-medium'}>
                                    <Icon component={IconStreamSolid} />
                                </Avatar>
                                <div className={'rr-grid-notfound-block-title'}>
                                    {localize(LocalizationEnum.PAGE__PRODUCTS__FEATURE_LIST_IS_EMPTY)}
                                </div>
                                <div className={'rr-grid-notfound-block-message'}>
                                    {localize(LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__LIST_PLACEHOLDER_ADD_CUSTOM_FIELDS)}
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <Link className={'rr-link-underline'} to={createPath}>
                                        {localize(LocalizationEnum.PAGE__PRODUCTS__CUSTOMIZE_FIELDS_BUTTON)}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <ItemsNotFoundBlock avatarSize={'medium'} style={{ marginTop: 56, marginBottom: 20 }} />
                    )}
                </Card>
            </>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
    location: storeState.router.location,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
});

const mapDispatchToProps = { push };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const EntityFeaturesView = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ProductFeaturesView));

import React, { FC, memo } from 'react';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { IconShippingFastSolid, IconStopwatchSolid } from '../../../../../../../../components/icons';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';
import { ProblemEnum } from '../../../../../../../../types';
import { DashboardWidgetRedLine } from '../../../../../components/DashboardWidget/instances/redLine/DashboardWidgetRedLine';
import { DashboardData } from '../../../../../types/DashboardData';

interface DashboardSubrentDelayWidgetProps {
    dashboardData: DashboardData;
}

export const DashboardSubrentDelayWidget: FC<DashboardSubrentDelayWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const { problemsAndWarnings, loading } = dashboardData;

    return (
        <DashboardWidgetRedLine
            loading={loading}
            title={localize(LocalizationEnum.ASPECT__PROBLEM__DELAYS)}
            icons={[IconShippingFastSolid, IconStopwatchSolid]}
            sums={[problemsAndWarnings?.subrentShipmentDelay.sum, problemsAndWarnings?.subrentReturnToShipperDelay.sum]}
            elementsCount={[
                problemsAndWarnings?.subrentShipmentDelay.elementsCount,
                problemsAndWarnings?.subrentReturnToShipperDelay.elementsCount,
            ]}
            urls={[
                linkToCalendar({
                    problem: ProblemEnum.SUBRENT_SHIPMENT_DELAY,
                    group: 'project',
                    capture: 'allWithNow',
                }),
                linkToCalendar({
                    problem: ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY,
                    group: 'project',
                    capture: 'allWithNow',
                }),
            ]}
        />
    );
});

import React, { FC, useCallback, useState } from 'react';
import { ProfessionInfoRead, SimpleTransitionCodeEnum } from '../../../../../../../../server';
import { Button, Icon, Popover } from 'antd';
import {
    IconArchive,
    IconCheck,
    IconEdit,
    IconGraduationCapSolid,
    IconLevelUp,
    IconTrash,
    IconUnlock,
} from '../../../../../../../../components/icons';
import { EntityDrawerArchiveBadge } from '../../../../../../../../components/v2/entityDrawer/components/ArchiveBadge';
import { RoundButton } from '../../../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { ProfessionDrawerProps } from '../ProfessionDrawer/ProfessionDrawer';
import { AdditionalEntityActionsButton } from '../../../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { ProfessionStateBadge } from '../../../components/professionStateBadge';
import { LinkToCalendar } from '../../../../../../../../components/link/toCalendar/LinkToCalendar';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';

import { CalendarPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';
import { useSetStatusForProfessionsMutation } from '../../../api/professions.api';

interface ProfessionDrawerTitleProps
    extends Pick<ProfessionDrawerProps, 'editProfession' | 'archiveProfession' | 'deleteProfession' | 'onClose' | 'refetchList'> {
    profession?: ProfessionInfoRead;
    onCalendarClick?: () => void;
    setProfessionsStatus: ReturnType<typeof useSetStatusForProfessionsMutation>[0];
    isLoading: boolean;
}

export const ProfessionDrawerTitle: FC<ProfessionDrawerTitleProps> = (props) => {
    const {
        profession,
        editProfession,
        archiveProfession,
        deleteProfession,
        onClose,
        refetchList,
        onCalendarClick,
        setProfessionsStatus,
        isLoading,
    } = props;

    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);

    const transitProfession = useCallback(
        (transitionCode: SimpleTransitionCodeEnum) => {
            if (profession?.id) {
                setProfessionsStatus({
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id: profession?.id,
                                businessVersion: profession?.businessVersion,
                                transitionCode,
                            },
                        ],
                    },
                });
            }
        },
        [profession?.businessVersion, profession?.id, setProfessionsStatus]
    );

    return profession ? (
        <div className={'entity-drawer-header'}>
            <div className={'title-block'}>
                <Icon className={'title-icon'} component={IconGraduationCapSolid} />
                <span className={'title'}>Специальность</span>
                {profession.archive ? <EntityDrawerArchiveBadge /> : null}
                {!profession.archive ? <ProfessionStateBadge type={profession.stateCode} /> : null}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {editProfession ? (
                    <RoundButton
                        colorScheme={'default'}
                        disabled={isLoading}
                        onClick={() => {
                            editProfession(profession);
                        }}
                    >
                        <Icon component={IconEdit} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                    </RoundButton>
                ) : undefined}
                <span style={{ marginLeft: '12px' }}>
                    <Popover
                        onVisibleChange={(visible) => {
                            setActionPopoverVisible(visible);
                        }}
                        visible={actionPopoverVisible}
                        overlayClassName={'rr-grid-actions-popover'}
                        autoAdjustOverflow
                        arrowPointAtCenter
                        placement="bottomLeft"
                        trigger="click"
                        content={
                            <>
                                <div className={'rr-grid-actions-popover-header1'} />
                                <div
                                    className={'rr-grid-actions-popover-content'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionPopoverVisible(false);
                                    }}
                                >
                                    {profession ? (
                                        <>
                                            {profession.archivable && archiveProfession ? (
                                                <Button
                                                    block
                                                    key={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                                                    title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                                                    onClick={() => archiveProfession(profession)}
                                                >
                                                    <Icon style={{ color: '#c4c5d6' }} component={IconArchive} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}</span>
                                                </Button>
                                            ) : null}
                                            {profession.archive && archiveProfession ? (
                                                <Button
                                                    block
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActionPopoverVisible(false);
                                                        archiveProfession(profession);
                                                    }}
                                                >
                                                    <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp} />
                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}</span>
                                                </Button>
                                            ) : undefined}
                                            {profession.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) ? (
                                                <Button
                                                    block
                                                    title={LocalizationEnum.ASPECT__ACTION__ACTIVATE}
                                                    onClick={() => {
                                                        transitProfession(SimpleTransitionCodeEnum.ACTIVATE);
                                                    }}
                                                >
                                                    <Icon style={{ color: '#21bfa4' }} component={IconCheck} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                                                </Button>
                                            ) : null}
                                            {profession.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.BLOCK) ? (
                                                <Button
                                                    block
                                                    title={LocalizationEnum.ASPECT__ACTION__BLOCK}
                                                    onClick={() => {
                                                        transitProfession(SimpleTransitionCodeEnum.BLOCK);
                                                    }}
                                                >
                                                    <Icon style={{ color: '#A0A0A0' }} component={IconUnlock} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                                                </Button>
                                            ) : null}
                                            {profession.deleteable ? (
                                                <Button
                                                    block
                                                    title={LocalizationEnum.ASPECT__ACTION__DELETE}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActionPopoverVisible(false);
                                                        deleteProfession?.(profession).then(() => {
                                                            onClose();
                                                            refetchList?.();
                                                        });
                                                    }}
                                                >
                                                    <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                    {localize(LocalizationEnum.ASPECT__ACTION__DELETE, 'span')}
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>
                            </>
                        }
                    >
                        <AdditionalEntityActionsButton disabled={isLoading} />
                    </Popover>
                </span>
                <LinkToCalendar
                    calendarPath={linkToCalendar(
                        {
                            professionId: profession.id,
                            capture: 'all',
                            group: 'project',
                        },
                        CalendarPageTabsEnum.WORK_PLANNINGS
                    )}
                    linkStyle={{
                        cursor: isLoading ? 'default' : undefined,
                    }}
                    withText={false}
                    onClick={(e) => {
                        if (!isLoading) {
                            onCalendarClick?.();
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                />
            </div>
        </div>
    ) : null;
};

import React, { FC, memo } from 'react';
import { getOperationStatusTypeData } from '../../../../../../operationForm/utils/utils';
import { OperationTypeCodeEnum, RentStateCodeEnum } from '../../../../../../../../server';
import { linkToCalendar } from '../../../../../../../../shared/util/createLinkPath';
import { DashboardWidgetBase } from '../../../../../components/DashboardWidget/instances/base/DashboardWidgetBase';
import { IconCube, IconHandshake } from '../../../../../../../../components/icons';
import { DashboardData } from '../../../../../types/DashboardData';

interface DashboardSubrentSubrentedWidgetProps {
    dashboardData: DashboardData;
}

export const DashboardSubrentSubrentedWidget: FC<DashboardSubrentSubrentedWidgetProps> = memo((props) => {
    const { dashboardData } = props;

    const { currentSubrentSum, currentSubrentElementsCount, currentSubrentInstancesCount, loading } = dashboardData;

    return (
        <DashboardWidgetBase
            loading={loading}
            fill={false}
            color="#FC8C2F"
            title={'В субаренде'}
            sum={currentSubrentSum}
            elementsCount={currentSubrentElementsCount}
            elementsIcons={[IconHandshake, IconCube]}
            instancesCount={currentSubrentInstancesCount}
            icon={getOperationStatusTypeData(OperationTypeCodeEnum.RENT).iconComponent}
            url={linkToCalendar({
                typeCode: [RentStateCodeEnum.SUBRENT],
                group: 'project',
                hideInactive: true,
                capture: 'all',
            })}
        />
    );
});

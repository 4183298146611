import React, { FC } from 'react';
import { formatMoney } from '../../../../../../../../shared/util/formatMoney';
import { CurrentCurrency } from '../../../../../../../../components/currentCurrency/currentCurrency';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../../../../shared/reducers/permissions.reducer';

interface DashboardWidgetBaseCurrencyProps {
    elementsCount: number | [number | undefined, number | undefined];
    sum: number;
}

export const DashboardWidgetBaseCurrency: FC<DashboardWidgetBaseCurrencyProps> = (props) => {
    const { elementsCount, sum } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    return (
        <div className="left-block">
            {isCanViewFinancialData &&
                (elementsCount === 0 ? (
                    <div className={'rr-dashboard-rent-indicators-block-value'} style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '20px' }}>
                        <span
                            style={{
                                color: '#aaaaaa',
                                //marginRight: '5px',
                            }}
                        >
                            {formatMoney(sum, false)}
                        </span>
                        <span style={{ color: '#aaaaaa' }}>
                            {' '}
                            <CurrentCurrency />
                        </span>
                    </div>
                ) : (
                    <div className={'rr-dashboard-rent-indicators-block-value'} style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '20px' }}>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <span
                                style={{
                                    fontWeight: 700,
                                    //marginRight: '5px',
                                }}
                            >
                                {formatMoney(sum, false)}
                            </span>
                            <span style={{ color: 'rgb(87, 89, 98)' }}>
                                {' '}
                                <CurrentCurrency />
                            </span>
                        </span>
                    </div>
                ))}
        </div>
    );
};

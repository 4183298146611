const PlusSquareOutline = {
    name: 'plus-square',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: { viewBox: '64 64 896 896', focusable: false },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M328 544h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z'
                }
            }
        ]
    }
};
export default PlusSquareOutline;
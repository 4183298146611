import { EntityGridCreateModalWrapper } from '../../../../../../../../components/page/entityGrid/types/modal';
import { CrewMemberInfoRead } from '../../../../../../../../server';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { CrewMemberCreateModal } from '../CreateModal/CrewMemberCreateModal';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { goBack, replace } from 'connected-react-router';

export const CrewMemberCreateModalWrapper: EntityGridCreateModalWrapper<CrewMemberInfoRead> = memo(({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <CrewMemberCreateModal
            businessAccountId={businessAccountId}
            title={'Создание нового проектного работника'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            modalName={'create-crew_member'}
            onCancel={() => {
                dispatch(goBack());
            }}
            onSuccess={({ id }: CrewMemberInfoRead) => {
                dispatch(replace(`/${businessAccountId}/crew/crewMembers/${id}`));
            }}
        ></CrewMemberCreateModal>
    );
});

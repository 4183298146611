import { WorkPlanningsRecord, WorkPlanningsStateCodeEnum } from '../../../../../../../server';
import { ProcessItemFunction } from '../../../../components/Calendar/hooks/useCalendarItems';
import moment from 'moment/moment';

export const processBaseWorkPlanningsCalendarItem: ProcessItemFunction<WorkPlanningsRecord> = ({
    record,
    items,
    screenLeft,
    screenRight,
    groupIndex,
}) => {
    const startTime = +moment(record.startDate) < screenLeft ? moment(screenLeft) : moment(record.startDate);
    const entTime = +moment(record.endDate) > screenRight ? moment(screenRight) : moment(record.endDate);

    items.push({
        elementType: 'work',
        id: record.id,
        record,
        start_time: startTime,
        end_time: entTime,
        group: groupIndex.current,
        isDraft: record.stateCode === WorkPlanningsStateCodeEnum.DRAFT,
    });

    groupIndex.current += 1;
};

import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {ProductInfoCreate, ProductInfoRead, ProductInfoUpdate, serverApi, ServerError} from "../../../../../server";
import {push, replace} from "connected-react-router";
import {getServerError} from "../../../../../shared/util/utils";
import {showNotification} from "../../../../../components/notification/showNotification";
import {logEvent} from '../../../../../analytics/analytics';
import {LocalizationEnum, localizeIntl} from '../../../../../localization';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'product/LOAD_ENTITY',
    UPDATE_ENTITY: 'product/UPDATE_ENTITY',
    UPDATE_ENTITY_COUNTS: 'product/UPDATE_ENTITY_COUNTS',
    CHANGE_ENTITY_STATUS: 'product/CHANGE_ENTITY_STATUS',
    CREATE_ENTITY: 'product/CREATE_ENTITY',
    RESET: 'product/RESET',
    RESET_ERROR: 'product/RESET_ERROR',
    CREATE_PRODUCT_INSTANCE: 'product/CREATE_PRODUCT_INSTANCE',
    DELETE_ENTITY: 'product/DELETE_ENTITY'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    updating: false,
    updatingError: undefined as (undefined | ServerError),
    entity: null as (ProductInfoRead | null)
};

export type ProductState = Readonly<typeof initialState>;

// Reducer

export default (state: ProductState = initialState, action): ProductState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY_COUNTS):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY_COUNTS):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case REQUEST(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: true
            };

        case FAILURE(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY_COUNTS):
            return {
                ...state,
                updating: false
            };

        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                entity: action.payload.data
            };

        case SUCCESS(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };


        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined
            };

        case REQUEST(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: true
            };

        case FAILURE(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: false
            };

        case SUCCESS(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};

// Actions

// Получение состояния продукта
export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getProductById(businessAccountId, id)
});

//Создание продукта
export const createEntity = (intl,businessAccountId: number, entity: ProductInfoCreate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.CREATE_ENTITY,
            payload: serverApi.createProduct(businessAccountId, entity)
        }).then((result) => {
            if(result instanceof Error){
                // Продукт не создан
            }else{
                // Продукт создан
                let {id} = result.action.payload.data;
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CREATED)); // TODO мб выводить норм сообщение
                dispatch(push(`/${businessAccountId}/inventory/products/${id || ''}`));
                // createEntityAnalytics('create product', {'entity id': id});
                logEvent({
                    type: 'create product',
                    data: {
                        'entity id': id,
                    }
                });
            }
        });
    };
};

// Изменение продукта
export const updateEntity = (intl, businessAccountId: number, id: number, entity: ProductInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateProductById(businessAccountId, id, entity)
        }).then((result) => {
            if(result instanceof Error){
                // Продукт не обновлен
            }else{
                // Продукт обновлен успешно
                showNotification('success', localizeIntl(intl,LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__UPDATED)); // TODO мб выводить норм сообщение
                dispatch(push('/' + businessAccountId + '/inventory/products/' + id));
                dispatch(loadEntity(businessAccountId, id));
                // updateEntityAnalytics('update product', {'entity id': id});

                logEvent({
                    type: 'update product',
                    data: {
                        'entity id': id,
                    }
                });
            }
        });
    };
};

// Эксперементальное
export const updateEntity1 = (businessAccountId: number, id: number, entity: ProductInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateProductById(businessAccountId, id, entity)
        });
    };
};

export const updateEntityCounts = (intl, businessAccountId: number, id: number, entity: ProductInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY_COUNTS,
            payload: serverApi.updateProductById(businessAccountId, id, entity)
        }).then((result) => {
            if(result instanceof Error){
                // Продукт не обновлен
            }else{
                // Продукт обновлен успешно
                showNotification('success', localizeIntl(intl,LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__UPDATED)); // TODO мб выводить норм сообщение
                dispatch(push('/' + businessAccountId + '/inventory/products/' + id));
                dispatch(loadEntity(businessAccountId, id));
                // updateEntityAnalytics('update product', {'entity id': id});
                logEvent({
                    type: 'update product',
                    data: {
                        'entity id': id,
                    }
                });
            }
        });
    };
};

// Изменение статуса
export const setStatusAndArchive = (intl, businessAccountId: number, id: number, businessVersion: number, status?: 'ACTIVATE' | 'BLOCK', archive?: boolean) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ENTITY_STATUS,
            payload: serverApi.transitProductWorkflowBulk(businessAccountId, {directives: [{id, businessVersion, transitionCode: status, archive}]})
        }).then((result) => {
            if(result instanceof Error){
                // Статус не изменен
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            }else{
                if(archive === undefined){
                    showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__PRODUCT_STATE_UPDATED));
                }else{
                    showNotification('success', archive ? localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__ARCHIVED) : localizeIntl(intl, LocalizationEnum.PAGE__PRODUCTS__POPUP_NOTIFICATIONS__UNARCHIVED));
                }
                dispatch(loadEntity(businessAccountId, id));
                // updateEntityAnalytics('update product', {'entity id': id});

                logEvent({
                    type: 'update product',
                    data: {
                        'entity id': id,
                    }
                });
            }
        });
    };
};

export const deleteEntity = (businessAccountId: number, id: number, businessVersion: number) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITY,
            payload: serverApi.deleteProductById(businessAccountId, id, {businessVersion})
        }).then((result) => {
            if(result instanceof Error){
                // Статус не изменен
                showNotification('error', 'Продукт не удален');
            }else{
                showNotification('success', 'Продукт удален');
                dispatch(replace('/' + businessAccountId + '/inventory/products/'));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});

import {ServerUtils} from '../../../../core/utils/serverUtils';
import {NomenclatureEntityTypeCodeEnum, NomenclatureRecord} from '../../../../server';
import {getStringServerProblem} from '../../../../shared/util/utils';
import {MoneyUtils} from '../../../../core/utils/moneyUtils';
import {IntervalFiltersFormatsMap, IntervalFiltersMetricsMap} from '../../../../components/page/entityGrid/types/api';
import {ListNomenclaturesFunctionArgs} from "./operationForm.api";

export abstract class OperationFormApiUtils {
    static createEntityRequestFilters = (params: ListNomenclaturesFunctionArgs['params'], subrentModuleEnabled: boolean | undefined) => {
        const {problem, categoryIds, pricePerShift, nomenclatureEntityTypeCode} = params;
        const filters = ServerUtils.createRequestFilters<NomenclatureRecord>([
            categoryIds && ['categoryIds', 'IN', Array.isArray(categoryIds) ? categoryIds : [categoryIds]],
            pricePerShift?.[0] != null && ['pricePerShift', 'GE', MoneyUtils.toCents(pricePerShift[0])],
            pricePerShift?.[1] != null && ['pricePerShift', 'LE', MoneyUtils.toCents(pricePerShift[1])],
            nomenclatureEntityTypeCode && (nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.KIT || nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.PRODUCT) && (nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.KIT ? ['nomenclatureEntityTypeCode', 'IN', [NomenclatureEntityTypeCodeEnum.KIT]] : ['nomenclatureEntityTypeCode', 'IN', [NomenclatureEntityTypeCodeEnum.PRODUCT, NomenclatureEntityTypeCodeEnum.VARIANT]]),
            ['visible', 'EQ', 'true']
        ]);
        if(problem) filters.push(getStringServerProblem(problem));
        return filters;
    };
    static createAvailableFiltersRequestFilters = (params: URLSearchParams): string[] => {
        const filters: string[] = [];
        return filters;
    };
    static productsIntervalMapFunction: IntervalFiltersMetricsMap<NomenclatureRecord> = {
        pricePerShift: MoneyUtils.toWhole,
    };
    static productsIntervalFormatStyleMap: IntervalFiltersFormatsMap<NomenclatureRecord> = {
        pricePerShift: 'currency',
    };
}

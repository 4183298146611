import React from 'react';
import { Icon, Popover } from 'antd';
import { IconArrowsAltHSolid } from '../icons';
import './requiredTimeIndentBetweenElementsInMinutesIcon.less';
import { joinClasses } from '../../shared/util/utils';
import { convertMinutesToHoursMinString } from '../../shared/util/getDateString';

interface IProps {
    timeInMinutes: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export class RequiredTimeIndentBetweenElementsInMinutesIcon extends React.Component<IProps> {
    render = () => {
        const { timeInMinutes, productHasOwnRequiredTimeIndentBetweenElements, style, className } = this.props;

        return (
            <Popover
                trigger={'hover'}
                overlayClassName={'rr-RequiredTimeIndentBetweenElementsInMinutesIcon__popover'}
                placement={'right'}
                content={
                    <>
                        <div>{convertMinutesToHoursMinString(timeInMinutes)}</div>
                        <div>{productHasOwnRequiredTimeIndentBetweenElements ? '(По продукту)' : '(По компании)'}</div>
                    </>
                }
            >
                <Icon component={IconArrowsAltHSolid} style={style} className={joinClasses(className)} />
            </Popover>
        );
    };
}

const HandStopOutline = {
    name: 'hand-stop',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: { viewBox: '0 0 32 32', focusable: false },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M 16 2 C 14.618911 2 13.466988 2.9700225 13.125 4.25 C 12.773916 4.1044091 12.400961 4 12 4 C 10.35503 4 9 5.3550302 9 7 L 9 17 L 9 17.75 L 8.125 16.875 C 6.9619269 15.711927 5.0380731 15.711927 3.875 16.875 C 2.7119269 18.038073 2.7119269 19.961927 3.875 21.125 L 10.65625 27.90625 C 11.831677 29.077174 13.455692 30 15.375 30 L 20 30 C 23.854334 30 27 26.854334 27 23 L 27 11 C 27 9.3550302 25.64497 8 24 8 C 23.647017 8 23.315758 8.0732364 23 8.1875 L 23 7 C 23 5.3550302 21.64497 4 20 4 C 19.599039 4 19.226084 4.1044091 18.875 4.25 C 18.533012 2.9700225 17.381089 2 16 2 z M 16 4 C 16.56503 4 17 4.4349698 17 5 L 17 15 L 19 15 L 19 7 C 19 6.4349698 19.43497 6 20 6 C 20.56503 6 21 6.4349698 21 7 L 21 11 L 21 15 L 23 15 L 23 11 C 23 10.43497 23.43497 10 24 10 C 24.56503 10 25 10.43497 25 11 L 25 23 C 25 25.773666 22.773666 28 20 28 L 15.375 28 C 14.102308 28 13.006323 27.377826 12.09375 26.46875 L 5.28125 19.71875 C 4.8823231 19.319823 4.8823231 18.680177 5.28125 18.28125 C 5.6801769 17.882323 6.3198231 17.882323 6.71875 18.28125 L 9.28125 20.875 L 11 22.59375 L 11 20.1875 L 11 17 L 11 7 C 11 6.4349698 11.43497 6 12 6 C 12.56503 6 13 6.4349698 13 7 L 13 15 L 15 15 L 15 7 L 15 5 C 15 4.4349698 15.43497 4 16 4 z'
                }
            }
        ]
    }
};
export default HandStopOutline;
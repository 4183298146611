import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {
    DocumentTemplateInfoCreate, DocumentTemplateInfoRead,
    DocumentTemplateInfoUpdate,
    serverApi,
    ServerError
} from "../../../../../server";
import {getServerError} from "../../../../../shared/util/utils";

export const ACTION_TYPES = {
    LOAD_ENTITY: 'documentTemplate/LOAD_ENTITY',
    UPDATE_ENTITY: 'documentTemplate/UPDATE_ENTITY',
    CREATE_ENTITY: 'documentTemplate/CREATE_ENTITY',
    DELETE_ENTITY: 'documentTemplate/DELETE_ENTITY',
    RESET: 'documentTemplate/RESET',
    //RESET_ERROR: 'documentTemplate/RESET_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entity: null as (DocumentTemplateInfoRead | null),
    fieldsLimit: undefined,
    updating: false,
    updatingError: undefined as (undefined | ServerError),
};

export type DocumentTemplateState = Readonly<typeof initialState>;

// Reducer

export default (state: DocumentTemplateState = initialState, action): DocumentTemplateState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        // case ACTION_TYPES.RESET_ERROR:
        //     return {
        //         ...state,
        //         loadingError: undefined,
        //         updatingError: undefined
        //     };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false
            };
        default:
            return state;
    }
};

// Actions

export const loadEntity = (businessAccountId: number, common: boolean, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: common ? serverApi.getCommonDocumentTemplateById(id) : serverApi.getDocumentTemplateById(businessAccountId, id)
});

export const createEntity = (businessAccountId: number, common: boolean, file: any, data: DocumentTemplateInfoCreate) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.CREATE_ENTITY,
            payload: common ? serverApi.createCommonDocumentTemplate(file, new Blob([JSON.stringify(data)], {
                type: "application/json"
            }) as any) : serverApi.createDocumentTemplate(businessAccountId, file, new Blob([JSON.stringify(data)], {
                type: "application/json"
            }) as any)
        });
    };
};

export const updateEntity = (businessAccountId: number, common: boolean, id: number, file: any, data: DocumentTemplateInfoUpdate) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: common ? serverApi.updateCommonDocumentTemplate(id, file || new Blob(), new Blob([JSON.stringify(file ? false : true)], {
                type: "application/json"
            }) as any, new Blob([JSON.stringify(data)], {
                type: "application/json"
            }) as any) : serverApi.updateDocumentTemplate(businessAccountId, id, file || new Blob(), new Blob([JSON.stringify(file ? false : true)], {
                type: "application/json"
            }) as any, new Blob([JSON.stringify(data)], {
                type: "application/json"
            }) as any)
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

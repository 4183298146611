import { TimetableTypeCodeEnum } from '../../../../../../server';
import { AccessibilityMapFilters } from '../filters/accessibility/accessibilityMapCalendarFilters.types';

export const getAvailableStacks = (subrentModuleEnabled: boolean | undefined) => {
    return [
        TimetableTypeCodeEnum.ORDER,
        TimetableTypeCodeEnum.AVAILABLE,
        TimetableTypeCodeEnum.OCCUPIED,
        subrentModuleEnabled && TimetableTypeCodeEnum.SUBRENTED,
        TimetableTypeCodeEnum.MAINTENANCE,
        TimetableTypeCodeEnum.STOCK,
    ].filter(Boolean) as TimetableTypeCodeEnum[];
};

export const getDefaultAvailableStacks = (subrentModuleEnabled: boolean | undefined) => {
    return getAvailableStacks(subrentModuleEnabled).filter((stack) => stack !== TimetableTypeCodeEnum.ORDER);
};

export const getDisabledStacks = (pageParams: AccessibilityMapFilters, subrentModuleEnabled: boolean | undefined) => {
    if (pageParams.kitId) {
        return getAvailableStacks(subrentModuleEnabled).filter((stack) => ![TimetableTypeCodeEnum.STOCK].includes(stack));
    }

    return [TimetableTypeCodeEnum.AVAILABLE];
};

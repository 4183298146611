import { FormFieldsGroup, FormItemType } from '../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../components/select/EntityRemoteSelect';
import { FiltersDateTimeRangePicker } from '../../../components/datePicker/FiltersDateTimeRangePicker';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum } from '../../../localization';
import { ProblemsFilter, ProblemsFilterProps } from '../../../components/select/problemsFilter';
import TypeCodeFilter from '../../../components/select/typeCodeFilter';
import { SliderRangeWithManualInput } from '../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { createHiddenField } from '../../../components/modalForm/utils';

export const filters: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('authorId'),
            createHiddenField('screenLeft'),
            {
                id: 'parentEntityType',
                type: FormItemType.Hidden,
            },
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__TYPE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: TypeCodeFilter,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        type: form.getFieldValue('parentEntityType'), //undefined//'projectTemplate'//'shipping'//'project'
                    };
                },
            },
            {
                label: 'Контрагент',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'renterId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    getEntityOperationName: 'getRenterById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                }),
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ projectId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils, customParams?: { [k: string]: any }) => {
                    let renterId =
                        form.getFieldValue('renterId') && form.getFieldValue('renterId').key
                            ? form.getFieldValue('renterId').key
                            : undefined;
                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: renterId
                            ? ['renterId;EQ;' + renterId]
                            : customParams && customParams.renterId
                            ? ['renterId;EQ;' + customParams.renterId]
                            : undefined,
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ subrentId: undefined });
                },
            },
            {
                label: 'Поставка',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'subrentId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils, customParams?: { [k: string]: any }) => {
                    let renterId =
                        form.getFieldValue('renterId') && form.getFieldValue('renterId').key
                            ? form.getFieldValue('renterId').key
                            : undefined;
                    return {
                        operationName: 'listSubrents',
                        nameField: 'shortName',
                        getEntityOperationName: 'getSubrentById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: renterId
                            ? ['supplierId;EQ;' + renterId]
                            : customParams && customParams.renterId
                            ? ['supplierId;EQ;' + customParams.renterId]
                            : undefined,
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ projectId: undefined });
                },
            },
            {
                label: LocalizationEnum.PAGE__OPERATIONS__LIST__HIDE_AUTOMATIC,
                id: 'hideAuto',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'all',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__CREATION_DATE_TITLE_PART,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'startDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__SUM,
                id: 'finalTotalPrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                },
            },
        ],
    },
];

export const templateFilters = filters;

import {TblColumn, TblColumnType} from "../tblColumns.types";
import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {isDefined} from "../../../shared/util/utils";
import {PricingSchemePricePopover} from "../../../modules/main/settings/pricingSchemes/pricingSchemePricePopover";
import {Icon} from "antd";
import {PricingSchemeMaps} from "../../utils/pricingSchemeUtils";
import {tableCellMoneyRenderer} from "../../../components/grid/renderers/tableCellMoneyRenderer";
import React from "react";

export const tblColumnsPrice = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.PRICE) {
        return (
            {
                title: column.title,
                dataIndex: column.dataIndex,
                type: ColumnTypes.CustomRender,
                className: 'money',
                minWidth: isDefined(column.minWidth) ? column.minWidth : 150,
                resizable: false,
                render: (value: any, rowData: Record): any => {
                    const data = column.getData(rowData);
                    let {pricingSchemeData} = data;

                    return (
                        <>
                            {pricingSchemeData ? (
                                <PricingSchemePricePopover
                                    data={{
                                        id: pricingSchemeData.pricingScheme.id,
                                        typeCode: pricingSchemeData.pricingScheme.typeCode,
                                        label: pricingSchemeData.pricingScheme.name,
                                        price: pricingSchemeData.basePrice,
                                        steps: pricingSchemeData.pricingScheme.steps,
                                        shiftCount: pricingSchemeData.shiftCount
                                    }}
                                >
                                    <Icon
                                        onClick={(e) => e.stopPropagation()}
                                        className={'rr-grid-pricingScheme-icon'}
                                        component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingSchemeData.pricingScheme.typeCode]}
                                    />
                                </PricingSchemePricePopover>
                            ) : null}
                            {tableCellMoneyRenderer(data.price)}
                        </>
                    );
                },
            }
        );
    } else {
        throw Error('error');
    }
};

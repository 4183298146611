import { EntityActions, EntityActionsHook } from '../../../../../../components/page/entityGrid/hooks/useRowActions';
import { CrewMemberInfoRead, CrewMemberStateCodeEnum, CrewMemberTransitionCodeEnum } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { URLDrawerParams } from '../../../../../../components/page/entityGrid/types/params';
import { push } from 'connected-react-router';
import { deleteCrewMember, loadCrewMember } from '../reducers/crewMember/crewMember.reducer';
import { archiveCrewMembers, setStatusForCrewMembers } from '../reducers/crewMembers/crewMembers.reducer';
import {showConfirm} from "../../../../../../components/confirm/showConfirm";
import {getIntl} from "../../../../../../App";

type CrewMemberEntityActions = EntityActions<CrewMemberInfoRead>;

export const useCrewMemberEntityActions = (({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const editCrewMemberCallback: CrewMemberEntityActions['editAction'] = useCallback(
        (profession, options) => {
            dispatch(
                loadCrewMember({
                    businessAccountId,
                    entityId: profession.id,
                })
            );

            const searchParams = new URLSearchParams(urlSearchParams.toString());

            const modalType: URLDrawerParams['modal'] = options?.copy ? 'copy' : 'edit';
            searchParams.delete('modal');
            searchParams.append('modal', modalType);
            searchParams.delete('id');
            searchParams.append('id', String(profession.id));

            const connectionString = rootPath.includes('?') ? '&' : '/?';

            const path = `${rootPath}${connectionString}` + searchParams.toString();

            dispatch(push(path));
        },
        [businessAccountId, dispatch, rootPath, urlSearchParams]
    );

    const deleteCrewMemberCallback: CrewMemberEntityActions['deleteActionForDrawer'] = useCallback(
        async (crewMember: CrewMemberInfoRead) => {
            dispatch(
                deleteCrewMember({
                    businessAccountId,
                    id: crewMember.id,
                    businessVersionObj: {
                        businessVersion: crewMember.businessVersion,
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const deleteCrewMemberCallbackWithConfirm: CrewMemberEntityActions['deleteActionForDrawer'] = useCallback(
        async (crewMember: CrewMemberInfoRead) => {
            const yes = await showConfirm(getIntl(), 'Вы уверены что хотите удалить проектного работника?');
            if(yes){
                dispatch(
                    deleteCrewMember({
                        businessAccountId,
                        id: crewMember.id,
                        businessVersionObj: {
                            businessVersion: crewMember.businessVersion,
                        },
                    })
                );
            }
        },
        [businessAccountId, dispatch]
    );

    const archiveCrewMemberCallback = useCallback(
        (crewMember: CrewMemberInfoRead) => {
            dispatch(
                archiveCrewMembers({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id: crewMember.id,
                                archive: !crewMember.archive,
                                businessVersion: crewMember.businessVersion,
                            },
                        ],
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const setStatusForCrewMemberCallback = useCallback(
        (crewMember: CrewMemberInfoRead) => {
            const { id, businessVersion, stateCode } = crewMember;

            if (stateCode == null) return;

            const transitionCode =
                stateCode === CrewMemberStateCodeEnum.BLOCKED ? CrewMemberTransitionCodeEnum.ACTIVATE : CrewMemberTransitionCodeEnum.BLOCK;

            dispatch(
                setStatusForCrewMembers({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id,
                                businessVersion,
                                transitionCode,
                            },
                        ],
                    },
                })
            );
        },
        [businessAccountId, dispatch]
    );

    const onRowAction: CrewMemberEntityActions['onRowAction'] = useCallback(
        (crewMember, action) => {
            const actionHandler = {
                edit: () => editCrewMemberCallback(crewMember),
                delete: () => deleteCrewMemberCallbackWithConfirm(crewMember),
                archive: () => archiveCrewMemberCallback(crewMember),
                lock: () => setStatusForCrewMemberCallback(crewMember),
            }[action];

            if (actionHandler) actionHandler();
        },
        [archiveCrewMemberCallback, deleteCrewMemberCallbackWithConfirm, editCrewMemberCallback, setStatusForCrewMemberCallback]
    );

    return useMemo(
        () => ({
            editAction: editCrewMemberCallback,
            deleteActionForDrawer: deleteCrewMemberCallback,
            archiveActionForDrawer: archiveCrewMemberCallback,
            onRowAction,
        }),
        [archiveCrewMemberCallback, deleteCrewMemberCallback, editCrewMemberCallback, onRowAction]
    );
}) satisfies EntityActionsHook<CrewMemberInfoRead>;

import React, {CSSProperties} from "react";
import {VisualizationTypeEnum} from "../../../server";
import {SystemIcon, SystemIconType} from "../../../components/v2/systemIcon/systemIcon";

export class AnalyticsPageUtils {

    static getReportCodes = (): VisualizationTypeEnum[] => {
        return [
            VisualizationTypeEnum.HIGHLIGHTINGCHARTSERIES,
            VisualizationTypeEnum.COLUMNCHART,
            VisualizationTypeEnum.PIECHART,
            VisualizationTypeEnum.MULTILEVELTREEMAP
        ];
    };

    static getReportTypeIconByCode = (code?: VisualizationTypeEnum, style?: CSSProperties): React.ReactNode => {
        let type: SystemIconType | undefined;
        if (code === VisualizationTypeEnum.COLUMNCHART) type = SystemIconType.chartType_ColumnChart;
        else if (code === VisualizationTypeEnum.HIGHLIGHTINGCHARTSERIES) type = SystemIconType.chartType_HighlightingChartSeries;
        else if (code === VisualizationTypeEnum.PIECHART) type = SystemIconType.chartType_PieChart;
        else if (code === VisualizationTypeEnum.MULTILEVELTREEMAP) type = SystemIconType.chartType_MultilevelTreeMap;
        else if (code === VisualizationTypeEnum.CLUSTEREDCOLUMNCHART) type = SystemIconType.chartType_ClusteredColumnChart;
        else {
            console.error(`code "${code}" not found in VisualizationTypeEnum`);
        }
        return type && <SystemIcon type={type} style={style}/>;
    };

    static getReportTypeByCode = (code?: VisualizationTypeEnum): React.ReactNode => {
        let type: string | undefined;
        if (code === VisualizationTypeEnum.COLUMNCHART) type = 'Столбчатая';
        else if (code === VisualizationTypeEnum.HIGHLIGHTINGCHARTSERIES) type = 'График';
        else if (code === VisualizationTypeEnum.PIECHART) type = 'Круговая';
        else if (code === VisualizationTypeEnum.MULTILEVELTREEMAP) type = 'Иерархия';
        else if (code === VisualizationTypeEnum.CLUSTEREDCOLUMNCHART) type = 'Столбчатая';
        else {
            console.error(`code "${code}" not found in VisualizationTypeEnum`);
        }
        return type;
    };

}

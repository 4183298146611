import React, {FC, memo} from 'react';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { lifecycleEventsColumns } from '../columns/lifecycleEventsColumns';
import { lifecycleEventsFilters } from '../filters/lifecycleEventsFilters';
import { LifecycleEventsFiltersDescription } from '../filters/lifecycleEventsFilters.description';
import { historyPageUrlRoute } from '../../../HistoryPage';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { useQueryParamsWithoutModalParams } from '../../../../../../components/page/entityGrid/hooks/useParams';
import { useListLifecycleEventsQuery } from '../api/lifecycleEvents.api';
import { lifecycleEventsLoading } from '../reducers/lifecycleEvents.selectors';
import { lifecycleEventsOuterParams } from '../filters/lifecycleEventsFilters.data';
import { usePageURLParamsFromDescription } from '../../../../../../core/hooks/urlParams/usePageURLParamsFromDescription';
import { HistoryPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import {useExportBlock} from "../hooks/useExportBlock";

interface LifecycleEventsListProps {
    gridName: HistoryPageTabsEnum.LIFECYCLE_EVENTS;
}

const LifecycleEventsListComponent: FC<LifecycleEventsListProps> = memo((props) => {
    const { gridName } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const entitiesLoading = useAppSelector(lifecycleEventsLoading);

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: LifecycleEventsFiltersDescription,
        gridName,
        outerParams: lifecycleEventsOuterParams,
    });
    const { pageParams } = pageParamsObject;
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListLifecycleEventsQuery(
        {
            businessAccountId,
            params: queryParams,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const rootPath = `/${businessAccountId}/${historyPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;

    const exportBlock = useExportBlock(pageParams, undefined);

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: lifecycleEventsColumns,
                    filtersData: lifecycleEventsFilters,
                    pageParamsDescription: LifecycleEventsFiltersDescription,
                },
                metaData: {
                    gridName,
                    entityType: 'lifecycle-event',
                    rootPath,
                },
                queryData,
                entitiesLoading,
                selectable: false,
                exportBlock
            }}
        />
    );
});

export const LifecycleEventsList: FC<LifecycleEventsListProps> = withEntityListProviders(LifecycleEventsListComponent);

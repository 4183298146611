import { TimetableTypeCodeEnum } from '../../../../../../server';

export const AccessibilityMapLocalizationMap: Record<TimetableTypeCodeEnum, string> = {
    [TimetableTypeCodeEnum.ORDERAVAILABLE]: 'Доступно',
    [TimetableTypeCodeEnum.AVAILABLE]: 'Доступно',
    [TimetableTypeCodeEnum.ORDER]: 'Заявки',
    [TimetableTypeCodeEnum.OCCUPIED]: 'Занято',
    [TimetableTypeCodeEnum.MAINTENANCE]: 'Обслуживание',
    [TimetableTypeCodeEnum.STOCK]: 'Инвентарь',
    [TimetableTypeCodeEnum.SUBRENTED]: 'Поставки',
};

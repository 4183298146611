import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Tabs } from 'antd';
import { IRootState } from '../../../shared/reducers';
import { getStateFromPath, isStringInEnum } from '../../../shared/util/utils';
import { connect } from 'react-redux';
import { serverApi } from '../../../server';
import { getBusinessAccountIndicators } from '../../../shared/reducers/businessAccountIndicators.reducer';
import './dashboard.less';
import './components/DashboardWidget/DashboardWidget.less';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { GET_BUSINESS_ACCOUNT_DASHBOARD_DATA_INTERVAL_MSEC } from '../../../config/constants';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { replace } from 'connected-react-router';
import { subrentModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';
import { TabsExtraContent } from './components/TabsExtraContent/TabsExtraContent';
import { DashboardRent } from './tabs/rent/DashboardRent';
import { DashboardSubrent } from './tabs/subrent/DashboardSubrent';
import { DashboardData } from './types/DashboardData';

import { DashboardTabsEnum } from '../../../shared/constants/tabEnums';

interface DashboardProps extends StateProps, DispatchProps, IBasePageProps {
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export interface DashboardState {
    dashboardData: DashboardData;
    page: DashboardTabsEnum;
}

const defaultTab = DashboardTabsEnum.RENT;

class _Dashboard extends BasePage<DashboardProps, DashboardState> {
    private getIndicatorsTimeoutId;

    constructor(props: DashboardProps) {
        super(props);

        this.state = {
            dashboardData: {
                currentBookedSum: 0,
                currentBookedElementsCount: 0,
                currentBookedInstancesCount: 0,
                currentRentedSum: 0,
                currentRentedElementsCount: 0,
                currentRentedInstancesCount: 0,
                currentOrderedSum: 0,
                currentOrderedElementsCount: 0,
                currentOrderedInstancesCount: 0,
                maintenanceProductsCount: 0,
                maintenanceInstancesCount: 0,

                currentDelayedBookedElementsCount: 0,
                currentDelayedRentedElementsCount: 0,
                currentShortagedBookedElementsCount: 0,

                currentSubrentShipmentDelay: 0,
                currentSubrentReturnToShipperDelay: 0,

                currentShortagesInstancesCount: 0,
                currentDelaysSum: 0,
                currentDelaysElementsCount: 0,
                currentDelaysInstancesCount: 0,
                problemsAndWarnings: undefined,

                currentRentDebtSum: 0,
                currentRentDebtProjectsCount: 0,
                currentRentDebtRentersCount: 0,

                currentSubrentDebtSum: 0,
                currentSubrentDebtSubrentsCount: 0,
                currentSubrentDebtSuppliersCount: 0,

                currentSubrentSum: 0,
                currentSubrentElementsCount: 0,
                currentSubrentInstancesCount: 0,

                currentSubrentShipmentBookedSum: 0,
                currentSubrentShipmentBookedElementsCount: 0,
                currentSubrentShipmentBookedInstancesCount: 0,
                loading: true,
            },
            page: DashboardTabsEnum.RENT,
        };

        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();

        this.updateIndicators();

        this.getIndicatorsTimeoutId = setInterval(async () => {
            await this.updateIndicators();
        }, GET_BUSINESS_ACCOUNT_DASHBOARD_DATA_INTERVAL_MSEC);

        if (this.props.tabFromStore) {
            if (this.props.tabFromStore !== DashboardTabsEnum.SUBRENT || this.props.subrentModuleEnabled) {
                this.setState({
                    page: isStringInEnum(this.props.tabFromStore, DashboardTabsEnum, defaultTab),
                });
            }
        }
    }

    componentDidUpdate() {
        saveCurrentTabToStore('dashboard', this.state.page);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.getIndicatorsTimeoutId);
    }

    updateIndicators = async () => {
        await this.props.getBusinessAccountIndicators(this.props.businessAccountId);
        await this.getData();
    };

    getData = () => {
        serverApi.getBusinessAccountDashboardData(this.props.businessAccountId).then((result) => {
            this.setState({
                dashboardData: {
                    currentBookedSum: result.data.currentBooked?.sum || 0,
                    currentBookedElementsCount: result.data.currentBooked.elementsCount || 0,
                    currentBookedInstancesCount: result.data.currentBooked.instnacesCount || 0,

                    currentRentedSum: result.data.currentRent?.sum ? result.data.currentRent.sum : 0,
                    currentRentedElementsCount: result.data.currentRent?.elementsCount || 0,
                    currentRentedInstancesCount: result.data.currentRent?.instnacesCount || 0,

                    currentOrderedSum: result.data.currentOrdered?.sum || 0,
                    currentOrderedElementsCount: result.data.currentOrdered?.elementsCount || 0,
                    currentOrderedInstancesCount: result.data.currentOrdered?.instnacesCount || 0,

                    currentSubrentSum: result.data.currentSubrent?.sum || 0,
                    currentSubrentElementsCount: result.data.currentSubrent?.elementsCount || 0,
                    currentSubrentInstancesCount: result.data.currentSubrent?.instnacesCount || 0,

                    currentSubrentShipmentBookedSum: result.data.currentSubrentShipmentBooked?.sum || 0,
                    currentSubrentShipmentBookedElementsCount: result.data.currentSubrentShipmentBooked?.elementsCount || 0,
                    currentSubrentShipmentBookedInstancesCount: result.data.currentSubrentShipmentBooked?.instnacesCount || 0,

                    currentRentDebtSum: result.data.currentRentDebtSum,
                    currentRentDebtProjectsCount: result.data.currentRentDebtProjectsCount,
                    currentRentDebtRentersCount: result.data.currentRentDebtRentersCount,

                    currentSubrentDebtSum: result.data.currentSubrentDebtSum,
                    currentSubrentDebtSubrentsCount: result.data.currentSubrentDebtSubrentsCount,
                    currentSubrentDebtSuppliersCount: result.data.currentSubrentDebtSuppliersCount,

                    maintenanceProductsCount: result.data.maintenanceProductsCount || 0,
                    maintenanceInstancesCount: result.data.maintenanceInstancesCount || 0,

                    currentDelayedBookedElementsCount: result.data ? result.data.currentBookDelayElementsCount : 0,
                    currentDelayedRentedElementsCount: result.data ? result.data.problemsAndWarnings.returnDelay.elementsCount : 0,
                    currentShortagedBookedElementsCount: result.data ? result.data.problemsAndWarnings.bookShortage.elementsCount : 0,

                    currentSubrentShipmentDelay: result.data ? result.data.problemsAndWarnings.subrentShipmentDelay.elementsCount : 0,
                    currentSubrentReturnToShipperDelay: result.data
                        ? result.data.problemsAndWarnings.subrentReturnToShipperDelay.elementsCount
                        : 0,
                    currentShortagesInstancesCount: this.props.currentShortagesInstancesCount,
                    currentDelaysSum: this.props.currentDelaysSum,
                    currentDelaysElementsCount: this.props.currentDelaysElementsCount,
                    currentDelaysInstancesCount: this.props.currentDelaysInstancesCount,

                    problemsAndWarnings: result.data.problemsAndWarnings,
                    loading: false,
                },
            });
        });
    };

    onTabChange = (key: string) => {
        this.setState({
            page: DashboardTabsEnum[key],
        });

        saveCurrentTabToStore('dashboard', key);
    };

    renderContent() {
        const { subrentModuleEnabled, responsive } = this.props;
        const { page, dashboardData } = this.state;
        const { maintenanceProductsCount, maintenanceInstancesCount } = dashboardData;

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tabs
                        activeKey={page}
                        onChange={this.onTabChange}
                        className={'rr-tabs'}
                        type="line"
                        animated={false}
                        style={{ marginLeft: '0px', width: '100%' }}
                        tabBarExtraContent={
                            <TabsExtraContent
                                responsive={responsive}
                                maintenanceProductsCount={maintenanceProductsCount}
                                maintenanceInstancesCount={maintenanceInstancesCount}
                            />
                        }
                    >
                        <Tabs.TabPane tab={'Аренда'} key={DashboardTabsEnum.RENT}>
                            <DashboardRent responsive={responsive} dashboardData={dashboardData} />
                        </Tabs.TabPane>
                        {subrentModuleEnabled && (
                            <Tabs.TabPane tab={'Субаренда'} key={DashboardTabsEnum.SUBRENT}>
                                <DashboardSubrent responsive={responsive} dashboardData={dashboardData} />
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);

    return {
        businessAccountId: storeState.system.businessAccountId,
        tab:
            searchParams.tab && (searchParams.tab === DashboardTabsEnum.RENT || searchParams.tab === DashboardTabsEnum.SUBRENT)
                ? searchParams.tab
                : DashboardTabsEnum.RENT,
        currentShortagesInstancesCount: 0,
        currentDelaysSum: 0,
        currentDelaysElementsCount: 0,
        currentDelaysInstancesCount: 0,
        tabFromStore: getCurrentTabsSelector(storeState)?.['dashboard'],
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { getBusinessAccountIndicators, resetOperationForm, replace };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(_Dashboard);

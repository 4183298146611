import React, { ReactNode } from 'react';
import { Icon } from 'antd';
import { IconInfoSolid } from '../../../../../../../components/icons';

export const AccountingTypeContent: ReactNode = (
    <div>
        <div
            style={{
                display: 'flex',
                borderBottom: '1px solid #e8e8e8',
                padding: '16px 0px 16px 14px',
                alignItems: 'center',
            }}
        >
            <Icon component={IconInfoSolid} style={{ fontSize: 31, color: '#525A95' }} />
            <div style={{ fontWeight: 600 }}>Тип учета</div>
        </div>
        <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 12, fontWeight: 600 }}>Поддержка вариантов</div>
            <div style={{ marginBottom: 22 }}>
                Продукт может быть единым, либо состоять из вариантов. Каждый вариант имеет свое наименование, календарь доступности,
                статистику, а также пользовательские поля с отличиями, такими как цвет или объем памяти. Каждому варианту можно задать
                собственную цену и артикул.
                <br />
                <br />
                <i>Пример: "iPhone 13" — это продукт, а "iPhone 13: золотой" или "iPhone 13: красный" — его варианты.</i>
            </div>
            <div style={{ marginBottom: 12, fontWeight: 600 }}>Поддержка именованных экземпляров</div>
            <div>
                В зависимости от типа учета продукта, его экземпляры будут учитываться в системе как именованные, либо как счетные.
                <br />
                Счетные экземпляры являются просто анонимной массой, про которую известно только их общее число. В отличии от этого, каждый
                именованный экземпляр имеет уникальное наименование/номер, по каждому можно проследить историю его использования, календарь
                доступности, состояние, статистику.
                <br />
                <br />
                <i>Счетными обычно являются дешевые продукты: прищепки или гайки. Именованными являются дорогие: камеры или автомобили.</i>
            </div>
        </div>
    </div>
);

import React, {FC, useCallback, useState} from 'react';
import {Popover} from "../../../../../../components/popover/Popover";

interface ChangeElementsParamPopoverContentProps {
    onApply?: ()=>void;
    onCancel?: ()=>void;
    canApply?: boolean;
    width?: number;

    component: React.ComponentType<{
        onClose: ()=>void;
        selectedIds: number[];
    }>;
    componentProps?: any;

    selectedIds: number[];
}

export const ChangeElementsParamPopover:FC<ChangeElementsParamPopoverContentProps> = ({children, onApply, onCancel, canApply, width, component :Component , selectedIds, componentProps}) => {
    const [visible, setVisible] = useState(false);

    const onVisibleChange = useCallback((visible)=>{
        setVisible(visible);
    }, [setVisible]);

    return (
        <Popover
            overlayStyle={{minWidth: 0, width: 'unset'}}
            destroyTooltipOnHide
            onVisibleChange={onVisibleChange}
            visible={visible}
            content2={
                <Component {...componentProps} onClose={()=>{
                    setVisible(false);
                    componentProps?.onClose?.();
                }} selectedIds={selectedIds} />
            }>
            {children}
        </Popover>
    );
};

import React from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { loadEntities } from '../operations/reducers/operations.reducer';
import { OperationsList } from '../operations/operationsList';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { getGridStorageData } from '../../../components/grid/utils';
import { getPathFromState, isStringInEnum } from '../../../shared/util/utils';
import { push, replace } from 'connected-react-router';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';
import { Redirect } from 'react-router';
import { InventoryMovementsList } from '../inventoryMovements/list/inventoryMovementsList';
import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { ExpensesList } from '../expenses/list/expensesList';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { HeaderBlock } from './components/HeaderBlock/HeaderBlock';
import { LifecycleEventsList } from './tabs/lifecycleEvents/list/LifecycleEventsList';
import { expensesModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';

import { HistoryPageTabsEnum } from '../../../shared/constants/tabEnums';
import { PaymentsList } from '../payments/list/PaymentList';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

export const historyPageUrlRoute = 'history';

const defaultTab = HistoryPageTabsEnum.OPERATIONS;

class HistoryPage extends BasePage<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${historyPageUrlRoute}`);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('history', this.currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
    }

    onTabChange = (key: string) => {
        const gridData = getGridStorageData(key);

        this.props.push(
            getPathFromState(this.getCurrentUrl(key as HistoryPageTabsEnum), '', {
                ...gridData.filters,
                ...gridData.params,
            })
        );

        saveCurrentTabToStore('history', key);
    };

    getCurrentUrl(tab: HistoryPageTabsEnum) {
        return `/${this.props.businessAccountId}/${historyPageUrlRoute}/${tab}`;
    }

    get urlMainPath() {
        return `/${this.props.businessAccountId}/${historyPageUrlRoute}` as const;
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(historyPageUrlRoute, HistoryPageTabsEnum);
    }

    getPageHeaderProps(): IPageHeaderProps {
        const { currentTab } = this;

        return {
            mainBlock: currentTab ? <HeaderBlock gridName={currentTab} /> : undefined,
        };
    }

    renderContent() {
        const { canViewFinancialData, expensesModuleEnabled } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, HistoryPageTabsEnum, defaultTab);
            const gridData = getGridStorageData(tab);

            return (
                <Redirect
                    to={getPathFromState(`${this.urlMainPath}/${tab}`, '', {
                        ...gridData.filters,
                        ...gridData.params,
                    })}
                />
            );
        }

        return (
            <>
                <Tabs
                    className={'rr-tabs'}
                    type="line"
                    animated={false}
                    onChange={this.onTabChange}
                    activeKey={currentTab}
                    destroyInactiveTabPane
                >
                    {
                        <Tabs.TabPane tab={'Операции'} key={HistoryPageTabsEnum.OPERATIONS}>
                            <OperationsList gridName={HistoryPageTabsEnum.OPERATIONS} location={this.props.location} />
                        </Tabs.TabPane>
                    }
                    {canViewFinancialData && expensesModuleEnabled && (
                        <Tabs.TabPane tab={'Затраты'} key={HistoryPageTabsEnum.EXPENSES}>
                            <ExpensesList
                                gridName={HistoryPageTabsEnum.EXPENSES}
                                urlPath={`${historyPageUrlRoute}/${HistoryPageTabsEnum.EXPENSES}`}
                                visible={currentTab === HistoryPageTabsEnum.EXPENSES}
                            />
                        </Tabs.TabPane>
                    )}
                    {canViewFinancialData && (
                        <Tabs.TabPane tab={'Платежи'} key={HistoryPageTabsEnum.PAYMENTS}>
                            <PaymentsList />
                        </Tabs.TabPane>
                    )}
                    {
                        <Tabs.TabPane tab={'Движение инвентаря'} key={HistoryPageTabsEnum.INVENTORY_MOVEMENTS}>
                            <InventoryMovementsList gridName={HistoryPageTabsEnum.INVENTORY_MOVEMENTS} />
                        </Tabs.TabPane>
                    }
                    {
                        <Tabs.TabPane tab={'Журнал действий'} key={HistoryPageTabsEnum.LIFECYCLE_EVENTS}>
                            <LifecycleEventsList gridName={HistoryPageTabsEnum.LIFECYCLE_EVENTS} />
                        </Tabs.TabPane>
                    }
                </Tabs>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: IBasePageProps) => {
    return {
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        tabFromStore: getCurrentTabsSelector(storeState)?.['history'],
        expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { loadEntities, resetOperationForm, push, replace, setBlockedUrl };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);

import {LocalizationEnum} from "../../../localization";
import {FormItemType} from "../../../components/dynamicForm/DynamicForm";
import {ReportTypeCodeEnum} from "../../../server";
import React from "react";
import {AnalyticsPageUtils} from "./analyticsPageUtils";

export const filters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray'
            },
            {
                label: "Вид",
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'reportType',
                type: FormItemType.Select,
                style: {minWidth: 180},
                className: 'rr-select-gray',
                values: [
                    {
                        name: "Стандартный",
                        value: ReportTypeCodeEnum.COMMON
                    },
                    {
                        name: 'Пользовательский',
                        value: ReportTypeCodeEnum.CUSTOM
                    }
                ]
            },
            {
                label: "Тип диаграммы",
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'visualizationType',
                type: FormItemType.Select,
                style: {minWidth: 120},
                className: 'rr-select-gray',
                values: AnalyticsPageUtils.getReportCodes().map((code) => {
                    return {
                        name: <div style={{display: 'flex', alignItems: 'center'}}>
                            {AnalyticsPageUtils.getReportTypeIconByCode(code, {marginRight: 10})}
                            <span>{AnalyticsPageUtils.getReportTypeByCode(code)}</span>
                        </div>,
                        value: code
                    };
                }),
            }
        ]
    }
];

import React from 'react';
import { FormFieldsGroup, FormItemType } from '../../../components/dynamicForm/DynamicForm';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum, localize } from '../../../localization';
import debounce from 'lodash/debounce';
import { validateField } from '../../../shared/util/validateField';
import { LocaleCodeEnum } from '../../../server';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: 'Имя',
                id: 'fullName.firstname',
                type: FormItemType.String,
                required: true,
                maxLength: 60,
            },
            {
                label: 'Фамилия',
                id: 'fullName.lastname',
                type: FormItemType.String,
                required: true,
                maxLength: 60,
            },
            {
                label: 'Email',
                id: 'email',
                type: FormItemType.String,
                required: true,
                maxLength: 60,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    let email = form.getFieldValue('email');
                    validateField('user', fieldName, email, undefined, undefined, 'Email уже используется', cb);
                }, 500),
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^.+@.+\\..{2,}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
                    },
                ],
            },
            {
                label: 'Телефон',
                id: 'phoneNumber',
                type: FormItemType.String,
                required: false,
                maxLength: 25,
            },
            {
                label: 'Локаль',
                id: 'locale',
                type: FormItemType.Select,
                required: true,
                values: [
                    { name: 'Русский', value: LocaleCodeEnum.RU },
                    { name: 'Английский', value: LocaleCodeEnum.EN },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <span className="calendar-multi-select">{item.name}</span>
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
        ],
    },
];

import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { crewModuleEnabledSelector, subrentModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';
import { calendarPageDefaultTab, calendarPageUrlRoute } from './utils/tabs';
import { getGridStorageData } from '../../../components/grid/utils';
import { getPathFromState, isStringInEnum } from '../../../shared/util/utils';
import { Redirect, RouteComponentProps } from 'react-router';
import { Tabs } from 'antd';
import { ElementsCalendar } from './tabs/elements/ElementsCalendar';
import { IRootState } from '../../../shared/reducers';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { replace } from 'connected-react-router';
import { connect, ConnectedProps } from 'react-redux';
import { injectIntl } from 'react-intl';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { ActivityFramesCalendar } from './tabs/activityFrames/ActivityFramesCalendar';
import { WorkPlanningsCalendar } from './tabs/workPlannings/WorkPlanningsCalendar';
import React from 'react';
import { CalendarHeaderBlock } from './components/CalendarHeaderBlock/CalendarHeaderBlock';
import {AccessibilityMapCalendar} from './tabs/accessibilityMap/AccessibilityMapCalendar';

import { CalendarPageTabsEnum } from '../../../shared/constants/tabEnums';
import {SummaryScheduleCalendar} from "./tabs/summarySchedule/summaryScheduleCalendar";

interface IProps extends StoreState, IBasePageProps {}

class CalendarPage extends BasePage<IProps> {
    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(this.urlMainPath);
    }

    componentDidUpdate() {
        const { currentTab } = this.props;

        if (currentTab) saveCurrentTabToStore('calendar', currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
    }

    get urlMainPath() {
        return `/${this.props.businessAccountId}/${calendarPageUrlRoute}` as const;
    }

    getNewUrlForTab = (tab: CalendarPageTabsEnum) => {
        const gridData = getGridStorageData(tab);
        return getPathFromState(this.urlMainPath, '', {
            ...gridData.filters,
            ...gridData.params,
            tab: tab,
        });
    };

    onTabChange = (key: string) => {
        const newTabKey = isStringInEnum(key, CalendarPageTabsEnum, calendarPageDefaultTab);
        const path = this.getNewUrlForTab(newTabKey);
        this.props.replace(path);
    };

    getPageHeaderProps(): IPageHeaderProps {
        const { currentTab } = this.props;

        const saveCurrentTab = isStringInEnum(currentTab, CalendarPageTabsEnum, calendarPageDefaultTab);

        return {
            mainBlock: <CalendarHeaderBlock pageName={saveCurrentTab} />,
        };
    }

    renderContent() {
        const { currentTab, subrentModuleEnabled, tabFromStore, crewMemberModuleEnabled } = this.props;

        if (currentTab == null) {
            const actualTabFromStore =
                crewMemberModuleEnabled || tabFromStore !== CalendarPageTabsEnum.WORK_PLANNINGS ? tabFromStore : undefined;
            const tab = isStringInEnum(actualTabFromStore, CalendarPageTabsEnum, calendarPageDefaultTab);
            return <Redirect to={this.getNewUrlForTab(tab)} />;
        }

        const saveCurrentTab = isStringInEnum(currentTab, CalendarPageTabsEnum, calendarPageDefaultTab);

        return (
            <Tabs
                activeKey={saveCurrentTab}
                onChange={this.onTabChange}
                className={'rr-tabs'}
                type="line"
                animated={false}
                destroyInactiveTabPane
            >
                <Tabs.TabPane tab={subrentModuleEnabled ? 'Проекты и поставки' : 'Проекты'} key={CalendarPageTabsEnum.ACTIVITY_FRAMES}>
                    <ActivityFramesCalendar />
                </Tabs.TabPane>
                <Tabs.TabPane tab={'Обязательства'} key={CalendarPageTabsEnum.ELEMENTS}>
                    <ElementsCalendar />
                </Tabs.TabPane>
                {crewMemberModuleEnabled && (
                    <Tabs.TabPane tab={'Работы'} key={CalendarPageTabsEnum.WORK_PLANNINGS}>
                        <WorkPlanningsCalendar />
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab={'Общее расписание'} key={CalendarPageTabsEnum.SUMMARY_SCHEDULE}>
                    <SummaryScheduleCalendar />
                </Tabs.TabPane>
                <Tabs.TabPane tab={'Расписание доступности'} key={CalendarPageTabsEnum.ACCESSIBILITY_MAP}>
                    <AccessibilityMapCalendar />
                </Tabs.TabPane>
            </Tabs>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    return {
        businessAccountId: businessAccountIdSelector(storeState),
        tabFromStore: getCurrentTabsSelector(storeState)?.['calendar'],
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        crewMemberModuleEnabled: crewModuleEnabledSelector(storeState),
        currentTab: new URLSearchParams(ownProps.location.search).get('tab'),
    };
};

const mapDispatchToProps = {
    setBlockedUrl,
    replace,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreState = ConnectedProps<typeof connector>;

export default connector(injectIntl(CalendarPage));

import { FAILURE, REQUEST, SUCCESS } from '../../../../shared/reducers/action-type.util';
import { PaymentRecord, PaymentRecordList, PaymentStateCodeEnum, serverApi, ServerError, WorkflowDirective } from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { IRootState } from '../../../../shared/reducers';
import { getServerError, getStateFromPath2, test1 } from '../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { MoneyUtils } from '../../../../core/utils/moneyUtils';
import { getAvailableGridSortById } from '../../../../shared/util/getAvailableGridSortById';
import paymentsColumns from '../columns/paymentsListColumns';
import { getAllStatusesExceptSelected } from '../../../../shared/util/utils4';
import { gridDataChangedSignal } from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'payments/LOAD_ENTITIES',
    RESET: 'payments/RESET',
    TRANSIT_WORKFLOW_BULK: 'payments/TRANSIT_WORKFLOW_BULK',
};

export const initialParamsState = {
    hideDeleted: undefined as string | undefined,
    number: undefined as string | undefined,
    sortBy: 'lastUpdateDate' as string | undefined,
    sortOrder: 'DESC' as 'ASC' | 'DESC' | undefined,
    page: 1,
    limit: 20,
    counterpartyId: undefined as string | undefined,
    stateCode: undefined as string | undefined,
    sourceCode: undefined as string | undefined,
    subrentId: undefined as string | undefined,
    projectId: undefined as string | undefined,
    paymentMethodId: undefined as number | undefined,
    amount: undefined as number[] | undefined,
    authorId: undefined as number | undefined,
    screenLeft: undefined as number | undefined,
    screenRight: undefined as number | undefined,
};

const initialState = {
    loading: true,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<PaymentRecord> | null,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

export type PaymentsState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentsState = initialState, action): PaymentsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (/\/\d+\/history\/payments\/?/.test(pathName)) {
                return {
                    ...state,
                    params: {
                        ...state.params,
                        hideDeleted: params.hideDeleted !== undefined ? '' + params.hideDeleted : initialParamsState.hideDeleted,
                        number: params.number !== undefined ? '' + params.number : initialParamsState.number,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        counterpartyId: params.counterpartyId !== undefined ? params.counterpartyId : initialParamsState.counterpartyId,
                        stateCode: params.stateCode !== undefined ? params.stateCode : initialParamsState.stateCode,
                        sourceCode: params.sourceCode !== undefined ? params.sourceCode : initialParamsState.sourceCode,
                        projectId: params.projectId !== undefined ? params.projectId : initialParamsState.projectId,
                        paymentMethodId: params.paymentMethodId !== undefined ? params.paymentMethodId : initialParamsState.paymentMethodId,
                        subrentId: params.subrentId !== undefined ? params.subrentId : initialParamsState.subrentId,
                        amount: test1(params.amount, initialParamsState.amount)?.map((price) => Math.round(price)),
                        authorId: params.authorId != null ? params.authorId : initialParamsState.authorId,
                        screenLeft: params.screenLeft !== undefined ? params.screenLeft : initialParamsState.screenLeft,
                        screenRight: params.screenRight !== undefined ? params.screenRight : initialParamsState.screenRight,
                    },
                };
            }
            return state;
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as PaymentRecordList).records,
                filteredCount: (action.payload.data as PaymentRecordList).listAttributes.filteredCount,
            };

        case REQUEST(ACTION_TYPES.TRANSIT_WORKFLOW_BULK):
            return { ...state, loading: true };

        case FAILURE(ACTION_TYPES.TRANSIT_WORKFLOW_BULK):
            return { ...state, loading: false };

        case SUCCESS(ACTION_TYPES.TRANSIT_WORKFLOW_BULK):
            return { ...state, loading: false };

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

// Actions

// Загрузить список операций
export const loadEntities = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: PaymentsState = (getState() as IRootState).payments;
        let subrentModuleEnabled = subrentModuleEnabledSelector(getState() as IRootState);
        let params = state.params;

        const sortBy = getAvailableGridSortById(params.sortBy, paymentsColumns);
        let filters: string[] = getPaymentsFilters(params, subrentModuleEnabled);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listPayments(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                undefined,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось загрузить список платежей');
            } else {
            }
        });
    };
};

export const transitWorkflowBulk = (intl, businessAccountId: number, directives: WorkflowDirective[]) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ACTION_TYPES.TRANSIT_WORKFLOW_BULK,
            payload: serverApi.transitPaymentsWorkflowBulk(businessAccountId, { directives }),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось изменить платеж');
            } else {
                showNotification('success', 'Платеж изменен');
                dispatch(loadEntities(intl, businessAccountId));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const getPaymentsFilters = (params: typeof initialParamsState, subrentModuleEnabled: boolean | undefined): string[] => {
    let filters: string[] = [];
    if (params.counterpartyId !== undefined) filters.push('counterpartyId;EQ;' + params.counterpartyId);
    if (params.stateCode !== undefined) filters.push('stateCode;EQ;' + params.stateCode);
    if (params.sourceCode !== undefined) filters.push('sourceCode;EQ;' + params.sourceCode);
    if (params.projectId !== undefined) filters.push('projectId;EQ;' + params.projectId);
    if (subrentModuleEnabled && params.subrentId !== undefined) filters.push('subrentId;EQ;' + params.subrentId);
    if (params.number !== undefined) filters.push('numberInBusinessAccount;EQ;' + params.number);
    if (params.hideDeleted !== undefined && params.hideDeleted === 'true')
        filters.push(`stateCode;IN;${getAllStatusesExceptSelected(PaymentStateCodeEnum, PaymentStateCodeEnum.DELETED).join(';')}`);

    if (params.authorId != null) filters.push(`lastUpdateAuthorId;EQ;${params.authorId}`);
    if (params.screenLeft !== undefined) filters.push('date;GE;' + params.screenLeft);
    if (params.screenRight !== undefined) filters.push('date;LE;' + params.screenRight);

    if (params.amount) {
        if (params.amount[0] !== undefined) filters.push('amount;GE;' + MoneyUtils.toCents(params.amount[0]));
        if (params.amount[1] !== undefined) filters.push('amount;LE;' + MoneyUtils.toCents(params.amount[1]));
    }

    if (params.paymentMethodId !== undefined) filters.push('paymentMethodId;EQ;' + params.paymentMethodId);

    return filters;
};

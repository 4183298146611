import React, {ReactNode} from 'react';
import {RenterSelectFC, RenterSelectProps} from '../renterSelect/renterSelect';
import {
    CounterpartyCreateModalNew,
    CounterpartyCreateModalNewProps,
} from '../../../../modules/main/renters/tabs/counterparties/modal/CounterpartyCreateModalNew';
import {SelectAndCreate} from '../selectAndCreate';
import {IconUserPlus} from "../../../icons";
import {RRoundButton, RRoundButtonProps} from "../../../button/rRoundButton";

export interface CounterpartySelectAndCreateProps extends RenterSelectProps {
    counterpartyType?: CounterpartyCreateModalNewProps['counterpartyType'];
    customAddButton?: ReactNode;
}

const AddButton = (props:RRoundButtonProps)=>{
    return (
        <RRoundButton
            {...props}
            colorScheme={'success2'}
            icon={IconUserPlus}
            style={{marginLeft: 12, height: 38, lineHeight: '38px', padding: '0 16px'}}
        />
    );
}

export class CounterpartySelectAndCreate extends React.Component<CounterpartySelectAndCreateProps> {
    render() {
        const { props } = this;
        const { counterpartyType, onChange } = props;

        return (
            <SelectAndCreate
                select={() => <RenterSelectFC {...props} />}
                modal={({ close }) => {
                    return (
                        <CounterpartyCreateModalNew
                            counterpartyType={counterpartyType}
                            onSuccess={(data) => {
                                onChange?.(data.id);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
                customAddButtonComponent={AddButton}
            />
        );
    }
}


import React, { ComponentType, FC } from 'react';
import { Icon } from 'antd';

export interface DashboardWidgetHeaderIconBlockProps {
    color: string;
    icon: ComponentType;
    isDisabled: boolean;
    fill?: boolean;
}

export const DashboardWidgetHeaderIconBlock: FC<DashboardWidgetHeaderIconBlockProps> = (props) => {
    let { color, icon, isDisabled, fill = true } = props;

    if (isDisabled) color = '#C4C5D6';

    return (
        <div
            style={{
                backgroundColor: fill ? color : '#fff',
                border: '6px solid ' + color,
            }}
            className="rr-dashboard-info-panel-header"
        >
            <Icon
                component={icon}
                style={{
                    color: fill || isDisabled ? '#FFF' : color,
                    fontSize: 30,
                }}
            />
        </div>
    );
};

import React from "react";
import {OperationTypeCodeEnum} from "../../../server/api";
import {OperationType} from "../../operationType/operationType";
import {GridRecord} from "../../../../index";

export const tableCellOperationRenderer = (value:OperationTypeCodeEnum, rowData?:GridRecord) => {
    return (
        <div style={{textAlign: 'left', whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
            <OperationType code={value}/>
        </div>
    );
};

import React, {ReactNode} from "react";
import {DataDisplayState} from "../../../../../../components/dataDisplay";
import classNames from "classnames";
import FormValue from "../../../../../../components/formValue/FormValue";
import {formatMoney} from "../../../../../../shared/util/formatMoney";

interface LabelValueTableProps {
    data: (LabelValueTableData | boolean | undefined | null | 0 | '')[];
}

interface LabelValueTableData {
    label: ReactNode;
    value: number;
    state?: DataDisplayState;
    extra?: ReactNode;
}

export const LabelValueTable = (props: LabelValueTableProps) => {
    return (
        <div style={{display: 'table'}}>
            {
                (props.data.filter(item => Boolean(item)) as LabelValueTableData[]).map((item, index, arr) => {
                    return (
                        <div style={{display: 'table-row'}}>
                            <div style={{
                                display: 'table-cell',
                                paddingRight: 40,
                                paddingBottom: index < arr.length - 1 ? 8 : undefined,
                                textAlign: 'right'
                            }}>
                                {item.label}
                            </div>
                            <div style={{display: 'table-cell', textAlign: 'right', paddingBottom: index < arr.length - 1 ? 8 : undefined}}>
                                <div
                                    className={classNames('rr-value', item.state === DataDisplayState.Problem ? 'rr-dd-i-v-problem' : undefined, item.state === DataDisplayState.Success ? 'rr-dd-i-v-success' : undefined)}>
                                    <FormValue value={formatMoney(item.value)} originalValue={item.value}/>
                                    {item.extra}
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

import React, { FC } from 'react';
import { ReactCalendarItemRendererProps, ReactCalendarTimelineProps } from 'react-calendar-timeline';

import { CalendarItem, CalendarItemGroup, CalendarItemType, CalendarLine as ICalendarLine } from '../Calendar/types/items';
import { CalendarLineContainer } from './components/CalendarLine/components/CalendarLineContainer/CalendarLineContainer';
import { CalendarGroupItem } from './components/CalendarLine/components/CalendarGroupItem/CalendarGroupItem';
import './CalendarItemRenderer.less';
import { typedMemo } from '../../../../../core/hooks/useMemo';
import { CalendarLineProps } from './utils/types';

export type CalendarItemRendererCommonProps<
    SortValue extends string,
    ICalendarItemType extends CalendarItemType,
    LineType extends ICalendarLine = ICalendarLine
> = {
    baseProps: CalendarLineProps<SortValue>;
    calendarProps: ReactCalendarItemRendererProps<LineType extends CalendarItem ? CalendarItem<ICalendarItemType> : CalendarItemGroup>;
};

export type CalendarItemRendererProps<
    SortValue extends string,
    ICalendarItemType extends CalendarItemType
> = CalendarItemRendererCommonProps<SortValue, ICalendarItemType, CalendarItem>;

export type CalendarItemRendererComponentProps<SortValue extends string, ICalendarItemType extends CalendarItemType> = {
    itemRenderer: FC<CalendarItemRendererProps<SortValue, ICalendarItemType>>;
} & CalendarItemRendererCommonProps<SortValue, ICalendarItemType>;

const _CalendarItemRendererComponent = <SortValue extends string, ICalendarItemType extends CalendarItemType>(
    props: CalendarItemRendererComponentProps<SortValue, ICalendarItemType>
) => {
    const { baseProps, calendarProps, itemRenderer: ItemRenderer } = props;
    const { displayType, onItemClick, groupsData } = baseProps;
    const { item } = calendarProps;
    const { elementType } = item;

    const isItemGroup = elementType === 'group';

    if (isItemGroup)
        return (
            <CalendarLineContainer
                calendarProps={calendarProps as ReactCalendarItemRendererProps<CalendarItemGroup>}
                displayType={displayType}
                borderRadius={undefined}
                onItemClick={onItemClick}
                linkPath={undefined}
                className={'group'}
            >
                <CalendarGroupItem item={item} displayType={displayType} groupsData={groupsData} />
            </CalendarLineContainer>
        );

    return (
        <ItemRenderer
            baseProps={baseProps}
            calendarProps={calendarProps as ReactCalendarItemRendererProps<CalendarItem<ICalendarItemType>>}
        />
    );
};

const CalendarItemRendererComponent = typedMemo(_CalendarItemRendererComponent);

export const CalendarItemRenderer = <SortValue extends string, ICalendarItemType extends CalendarItemType>(
    props: CalendarLineProps<SortValue> & {
        itemRenderer: CalendarItemRendererComponentProps<SortValue, ICalendarItemType>['itemRenderer'];
    }
): ReactCalendarTimelineProps<ICalendarLine>['itemRenderer'] => {
    const { itemRenderer, ...baseProps } = props;

    return (calendarProps) => (
        <CalendarItemRendererComponent calendarProps={calendarProps} baseProps={baseProps} itemRenderer={itemRenderer} />
    );
};

import { FC, PureComponent } from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { CustomFieldRecord, serverApi } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';

export interface CustomFieldSelectProps extends BaseSelectBaseProps<CustomFieldRecord> {}

const CustomFieldSelectComponent: FC<CustomFieldSelectProps> = (props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listCustomFields(
            businessAccountId,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            {
                query: {
                    filters: [`id;IN;${ids}`],
                },
            }
        );
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        const response = await serverApi.listCustomFields(businessAccountId, search, limit, startFrom, undefined, undefined);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecords={getEntitiesList}
            fetchRecordsById={getEntitiesById}
            getRecordId={(item) => item.id}
            getOptionProps={(item) => ({
                label: item.shortName,
                children: item.shortName,
            })}
        />
    );
};

export class CustomFieldSelect extends PureComponent<CustomFieldSelectProps> {
    render() {
        return <CustomFieldSelectComponent {...this.props} />;
    }
}

import React, { Fragment } from 'react';
import { Icon } from 'antd';
import { CustomIconComponentProps } from 'antd/lib/icon';
import { Link } from 'react-router-dom';
import { IconBriefcase, IconDollySolid, IconMoneyCheckAltSolid, IconScrollSolid, IconUsers } from '../icons';
import './pageHeader.less';
import Spin from '../spin/spin';
import { LocalizationEnum, localize } from '../../localization';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ReportReferenceTypeEnum, ReportSelectDropdown } from './ReportSelectDropdown';
import { IconChartArea } from '../icons/IconChartArea';
import { ArchiveBadge } from '../archiveBadge/archiveBadge';

interface IState {}

export interface IPageHeaderProps {
    icon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
    title?: string | JSX.Element;
    subsectionTitle?: string | JSX.Element;
    subsectionIcon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
    archive?: boolean;
    name?: string;
    shortName?: boolean;
    showName?: boolean; // В настройках name не выводится в шапке, но используется в названии страницы в title!!

    businessAccountId?: number;
    parentPagePath?: string;
    subsectionPagePath?: string;

    renterId?: number;
    renterName?: string;

    supplierId?: number;
    supplierName?: string;

    projectId?: number;
    projectName?: string;
    projectTemplate?: boolean;
    projectIsOffer?: boolean;

    subrentId?: number;
    subrentName?: string;

    isLoading?: boolean;

    mainBlock?: JSX.Element;
    leftBlock?: JSX.Element;
    rightBlock?: JSX.Element;
    reportReferenceType?: ReportReferenceTypeEnum | undefined;
    reportReferenceEntityId?: number;

    NavigationBlock?: JSX.Element;
}

class _PageHeader extends React.PureComponent<IPageHeaderProps & WrappedComponentProps, IState> {
    static defaultProps = {
        showName: true,
    };

    render() {
        const { NavigationBlock } = this.props;

        return (
            <div className="rr-page-header">
                {NavigationBlock != null ? (
                    NavigationBlock
                ) : (
                    <>
                        {React.createElement(
                            this.props.parentPagePath ? Link : Fragment,
                            { to: this.props.parentPagePath || '' },
                            <span className={'rr-page-header-title'}>
                                {this.props.subsectionIcon || this.props.subsectionTitle ? (
                                    <>
                                        <Icon component={this.props.icon} style={{ color: '#979cbf' }} />
                                        <span style={{ color: '#979cbf' }}>{localize(this.props.title as LocalizationEnum)}</span>
                                        <span style={{ opacity: 0.6 }}>/</span>
                                        {this.props.subsectionIcon ? (
                                            <Icon component={this.props.subsectionIcon} style={{ paddingLeft: '23px' }} />
                                        ) : null}
                                        {this.props.subsectionPagePath ? (
                                            <Link to={this.props.subsectionPagePath} onClick={(event) => event.stopPropagation()}>
                                                {localize(this.props.subsectionTitle as LocalizationEnum)}
                                            </Link>
                                        ) : (
                                            <span>{localize(this.props.subsectionTitle as LocalizationEnum)}</span>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Icon component={this.props.icon} />
                                        <span>{localize(this.props.title as LocalizationEnum)}</span>
                                    </>
                                )}
                            </span>
                        )}

                        {this.props.archive ? <ArchiveBadge style={{ marginRight: 30 }} /> : null}

                        {this.props.mainBlock ? <>{this.props.mainBlock}</> : null}

                        {
                            /* Entity name */
                            this.props.name && this.props.showName ? (
                                <HelpTooltip
                                    content={
                                        this.props.shortName
                                            ? LocalizationEnum.ASPECT__GLOBAL__TOOLTIP__SHORT_NAME_FULL_NAME_DOES_NOT_EXISTS
                                            : LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME
                                    }
                                >
                                    <div
                                        className={'rr-page-header-name'}
                                        title={this.props.name.length > 50 ? this.props.name : undefined}
                                    >
                                        {this.props.name.length > 50 ? this.props.name.substr(0, 50) + '…' : this.props.name}
                                    </div>
                                </HelpTooltip>
                            ) : null
                        }

                        <Spin spinning={this.props.isLoading === true} delay={0} />
                    </>
                )}

                <div className={'divider'} style={{ flex: 'auto' }}></div>

                {this.props.leftBlock ? <div>{this.props.leftBlock}</div> : null}

                {
                    /* Renter */
                    !!this.props.renterId && !!this.props.renterName ? (
                        <Link to={`/${this.props.businessAccountId}/crm/counterparties/${this.props.renterId}`}>
                            <div className={'rr-page-header-right'}>
                                <Icon component={IconUsers} />
                                <div>
                                    <div>Арендатор</div>
                                    <div>{this.props.renterName}</div>
                                </div>
                            </div>
                        </Link>
                    ) : null
                }

                {
                    /* Project */
                    this.props.projectId && this.props.projectName && !this.props.projectTemplate && !this.props.projectIsOffer && (
                        <Link to={`/${this.props.businessAccountId}/projects/production/${this.props.projectId}`}>
                            <div className={'rr-page-header-right'}>
                                <Icon component={IconBriefcase} />
                                <div>
                                    <div>{localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT)}</div>
                                    <div>{this.props.projectName}</div>
                                </div>
                            </div>
                        </Link>
                    )
                }

                {
                    /* Offer */
                    this.props.projectId && this.props.projectName && !this.props.projectTemplate && this.props.projectIsOffer && (
                        <Link to={`/${this.props.businessAccountId}/projects/offers/${this.props.projectId}`}>
                            <div className={'rr-page-header-right'}>
                                <Icon component={IconMoneyCheckAltSolid} />
                                <div>
                                    <div>Смета</div>
                                    <div>{this.props.projectName}</div>
                                </div>
                            </div>
                        </Link>
                    )
                }

                {
                    /* Renter */
                    this.props.supplierId && this.props.supplierName ? (
                        <Link to={`/${this.props.businessAccountId}/crm/counterparties/${this.props.supplierId}`}>
                            <div className={'rr-page-header-right'}>
                                <Icon component={IconUsers} />
                                <div>
                                    <div>Поставщик</div>
                                    <div>{this.props.supplierName}</div>
                                </div>
                            </div>
                        </Link>
                    ) : null
                }

                {
                    /* Project template */
                    this.props.projectId && this.props.projectName && this.props.projectTemplate && (
                        <Link to={`/${this.props.businessAccountId}/projects/templates/${this.props.projectId}`}>
                            <div className={'rr-page-header-right'}>
                                <Icon component={IconScrollSolid} />
                                <div>
                                    <div>{localize(LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TEMPLATE)}</div>
                                    <div>{this.props.projectName}</div>
                                </div>
                            </div>
                        </Link>
                    )
                }

                {this.props.subrentId && this.props.subrentName && (
                    <Link to={`/${this.props.businessAccountId}/subrent/shippings/${this.props.subrentId}`}>
                        <div className={'rr-page-header-right'}>
                            <Icon component={IconDollySolid} />
                            <div>
                                <div>Поставка</div>
                                <div>{this.props.subrentName}</div>
                            </div>
                        </div>
                    </Link>
                )}

                {/* Статистика */}
                {this.props.reportReferenceType && this.props.reportReferenceEntityId && (
                    <ReportSelectDropdown referenceType={this.props.reportReferenceType} entityId={this.props.reportReferenceEntityId}>
                        <div className={'rr-page-header-right'} style={{ cursor: 'pointer', userSelect: 'none' }}>
                            <Icon component={IconChartArea} />
                            <div>
                                <div>Статистика</div>
                            </div>
                        </div>
                    </ReportSelectDropdown>
                )}

                {this.props.rightBlock ? this.props.rightBlock : null}
            </div>
        );
    }
}

export const PageHeader = injectIntl(_PageHeader);

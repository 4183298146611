import { useMemo } from 'react';

const baseData = {
    assignIds: [] as number[],
    detachIds: [] as number[],
};

export const useChosenIds = (
    selectedRecords: {
        id: number;
        categoryIds?: Array<number>;
        mainCategoryId?: number;
    }[],
    selectedId: number | undefined,
    isCategoryBased: boolean
): typeof baseData => {
    return useMemo(() => {
        if (selectedId == null) return baseData;

        return selectedRecords.reduce<typeof baseData>(
            (accumulator, { id, categoryIds, mainCategoryId }) => {
                const targetIds = isCategoryBased && mainCategoryId ? [mainCategoryId] : categoryIds;
                const isCategorySelected = (targetIds ?? []).some((id) => selectedId === id);
                const operationKey: keyof typeof baseData = isCategorySelected ? 'detachIds' : 'assignIds';

                if (accumulator[operationKey].includes(id)) return accumulator;

                return {
                    ...accumulator,
                    [operationKey]: accumulator[operationKey].concat([id]),
                };
            },
            { ...baseData }
        );
    }, [isCategoryBased, selectedId, selectedRecords]);
};

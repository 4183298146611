import React, { FC, useState } from 'react';
import { Button, Icon, Popover } from 'antd';
import { IconArchive, IconEdit, IconLevelUp, IconMapMarkerSolid, IconTrash } from '../../../../../../components/icons';
import { EntityDrawerArchiveBadge } from '../../../../../../components/v2/entityDrawer/components/ArchiveBadge';
import { RoundButton } from '../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { AdditionalEntityActionsButton } from '../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { LocationInfoRead } from '../../../../../../server';
import { LocationDrawerProps } from './LocationDrawer';

interface LocationDrawerTitleProps extends Pick<LocationDrawerProps, 'editLocation' | 'deleteLocation' | 'archiveLocation' | 'onClose'> {
    location?: LocationInfoRead;
    isLoading: boolean;
}

export const LocationDrawerTitle: FC<LocationDrawerTitleProps> = (props) => {
    const { location, editLocation, deleteLocation, archiveLocation, onClose, isLoading } = props;

    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);

    return location ? (
        <div className={'entity-drawer-header'}>
            <div className={'title-block'}>
                <Icon className={'title-icon'} component={IconMapMarkerSolid} />
                <span className={'title'}>Локация</span>
                {location?.archive ? <EntityDrawerArchiveBadge /> : null}
            </div>
            <div>
                {editLocation ? (
                    <RoundButton
                        colorScheme={'default'}
                        onClick={() => {
                            editLocation(location);
                        }}
                        disabled={isLoading}
                    >
                        <Icon component={IconEdit} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                    </RoundButton>
                ) : undefined}
                <span style={{ marginLeft: '12px' }}>
                    <Popover
                        onVisibleChange={(visible) => {
                            setActionPopoverVisible(visible);
                        }}
                        visible={actionPopoverVisible}
                        overlayClassName={'rr-grid-actions-popover'}
                        autoAdjustOverflow
                        arrowPointAtCenter
                        placement="bottomLeft"
                        trigger="click"
                        content={
                            <>
                                <div className={'rr-grid-actions-popover-header1'} />
                                <div
                                    className={'rr-grid-actions-popover-content'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionPopoverVisible(false);
                                    }}
                                >
                                    <>
                                        {deleteLocation ? (
                                            <Button
                                                disabled={!location.deleteable}
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    deleteLocation(location).then(() => onClose());
                                                }}
                                            >
                                                <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                <span>Удалить</span>
                                            </Button>
                                        ) : undefined}
                                        {location.archivable && !location.archive && archiveLocation ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveLocation(location);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-TO_ARCHIVE-color'} component={IconArchive} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                        {location.archive && archiveLocation ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveLocation(location);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                    </>
                                </div>
                            </>
                        }
                    >
                        {<AdditionalEntityActionsButton disabled={isLoading} />}
                    </Popover>
                </span>
            </div>
        </div>
    ) : null;
};

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { IPageHeaderProps, PageHeader } from '../pageHeader/pageHeader';
import DocumentTitle from 'react-document-title';
import { WrappedComponentProps } from 'react-intl';
import { Alert, Icon } from 'antd';
import intersection from 'lodash/intersection';
import { getFormattedMessageComponent } from '../../localization/localizationUtils';
import { LocalizationEnum } from '../../localization';
import { IconToolsSolid } from '../icons';
import { Avatar } from '../avatar/avatar';
import { ParamsDescription, ParamsUtils } from '../../core/utils/paramsUtils';

export interface IBasePageProps extends RouteComponentProps, WrappedComponentProps {
    route: {};
    businessAccountId: number;
}

export class BasePage<P extends IBasePageProps, S = {}> extends React.Component<P, S> {
    componentDidMount() {
        //console.log(`${this.constructor.name} componentDidMount()`);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        //console.log(`${this.constructor.name} componentWillUnmount()`);
    }

    getPageParams<Params extends object>(paramsDescription: ParamsDescription<Params>) {
        return ParamsUtils.getParamsFromParamsDescription(
            paramsDescription,
            Object.fromEntries(new URLSearchParams(this.props.location.search).entries())
        );
    }

    getPageHeaderProps(): IPageHeaderProps {
        return {};
    }

    getCurrentTabFromPathname = <T extends object>(sectionUrl: string, TabsEnum: T): T[Extract<keyof T, string>] | undefined => {
        const pathName = this.props.location.pathname;
        const rootUrlPath = `${this.props.businessAccountId}/${sectionUrl}`;

        let currentTab: T[Extract<keyof T, string>] | undefined;
        for (const tabEnum in TabsEnum) {
            if (pathName.includes(`${rootUrlPath}/${TabsEnum[tabEnum]}`)) {
                currentTab = TabsEnum[tabEnum];
                break;
            }
        }

        return currentTab;
    };

    renderContent() {
        return (
            <div className={'rr-grid-notfound-block'}>
                <Avatar className={'rr-avatar-big'}>
                    <Icon component={IconToolsSolid} />
                </Avatar>
                <div className={'rr-grid-notfound-block-title'}>
                    {getFormattedMessageComponent(LocalizationEnum.PAGE__ERROR__UNDER_DEVELOPMENT__SHORT_MESSAGE)}
                </div>
            </div>
        );
    }

    render() {
        const { route, intl } = this.props;
        let pageTitle = intl && route['title'] ? intl.formatMessage({ id: route['title'] }) : '';
        let headerProps = this.getPageHeaderProps();
        if (!headerProps.title) headerProps.title = pageTitle;
        if (!headerProps.icon) headerProps.icon = route['icon'];
        if (!headerProps.subsectionTitle) headerProps.subsectionTitle = route['subsectionTitle'];
        if (!headerProps.subsectionIcon) headerProps.subsectionIcon = route['subsectionIcon'];
        let hasPermission =
            !route['permissions'] ||
            route['permissions'].length === 0 ||
            (route['permissions'] &&
                route['permissions'].length > 0 &&
                intersection(route['permissions'], this.props['userPermissions']).length > 0);
        if (!hasPermission) headerProps = { title: pageTitle, icon: route['icon'] };

        return (
            <DocumentTitle
                title={
                    'RentRabbit' + (headerProps.title ? ' | ' + headerProps.title : '') + (headerProps.name ? ' - ' + headerProps.name : '')
                }
            >
                <>
                    <PageHeader {...headerProps} />
                    {hasPermission ? (
                        this.renderContent()
                    ) : (
                        <Alert
                            message={getFormattedMessageComponent(LocalizationEnum.PAGE__ERROR__FORBIDDEN__SHORT_MESSAGE)}
                            description={getFormattedMessageComponent(LocalizationEnum.PAGE__ERROR__FORBIDDEN__FULL_MESSAGE)}
                            type="error"
                        />
                    )}
                </>
            </DocumentTitle>
        );
    }
}

import React from 'react';
import { IconStampSolid } from '../../../../icons';
import { Icon } from 'antd';
import { DocumentTypeCodeEnum } from '../../../../../server/api';
import { DownloadDocumentPopover } from '../../DownloadDocumentPopover';
import { availableFileTypes, ContractDocumentOnDownload } from './utils/data';

interface ExportContractPopoverProps {
    onDownload: ContractDocumentOnDownload;
}

export class ExportContractPopover extends React.Component<ExportContractPopoverProps> {
    render() {
        return (
            <DownloadDocumentPopover
                documentType={DocumentTypeCodeEnum.CONTRACT}
                availableFileTypes={[...availableFileTypes]}
                withTemplate
                storageKey={'contract'}
                title={
                    <>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconStampSolid} />
                        <div>Скачать договор</div>
                    </>
                }
                onDownload={this.props.onDownload}
                showLegalDetails
                isCompact
            >
                {this.props.children}
            </DownloadDocumentPopover>
        );
    }
}

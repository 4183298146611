import React, { ComponentProps, FC, useContext } from 'react';
import './LineIconCircle.less';
import classNames from 'classnames';
import { DisplayTypeItemContext } from '../../../../../../context/CalendarItemContext';
import { CalendarDisplayType } from '../../../../../../../Calendar/utils/data/calendarConstants';
import merge from 'lodash/merge';

export interface IconCircleProps extends ComponentProps<'div'> {
    backgroundColor: string | undefined;
    itemWidth: number;
    isDraft: boolean;
    isStatic?: boolean;
}

export const LineIconCircle: FC<IconCircleProps> = (props) => {
    const { itemWidth, backgroundColor, isStatic, isDraft, ...divProps } = props;
    const displayType = useContext(DisplayTypeItemContext);

    const isReversed = !isStatic && !isDraft && itemWidth < 8;

    return (
        <div
            {...divProps}
            className={classNames(
                'icon-circle',
                {
                    normal: displayType === CalendarDisplayType.NORMAL,
                    compact: displayType === CalendarDisplayType.COMPACT,
                    outlined: isDraft,
                },
                divProps.className
            )}
            style={merge(
                {
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: isReversed ? '#fff' : backgroundColor,
                    backgroundColor: isReversed ? backgroundColor : undefined,
                },
                divProps.style
            )}
        ></div>
    );
};

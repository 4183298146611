import { downloadOffloading } from '../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import {
    ExcelExportTemplateTypeCodeEnum,
    OfferProjectFinancialFieldsTypeCode,
    OfferProjectNotFinancialFieldsTypeCode,
    ProjectRecord,
} from '../../../../server';
import { useCallback, useContext } from 'react';
import { GridRefContext } from '../../../../components/page/entityGrid/components/context/GridRefContext';
import { EntitiesSelectionContext } from '../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { ListOffersFilters } from '../api/types';
import { EntityGridURLParams } from '../../../../components/page/entityGrid/types/params';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { OffersUtils } from '../api/offers.api.utils';
import { DeepKeys } from '@tanstack/react-table';
import { ServerUtils } from '../../../../core/utils/serverUtils';

export const useOffloadingDownload = (
    pageParams: EntityGridURLParams<ListOffersFilters>,
    projectId: number | undefined,
    excludeColumns: DeepKeys<ProjectRecord>[]
) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const gridRef = useContext(GridRefContext);
    const [entitySelection] = useContext(EntitiesSelectionContext);

    return useCallback(
        async ({ fileType, options }) => {
            let { sortOrder, search } = pageParams;
            const filters = OffersUtils.createRequestFilters(pageParams);

            if (projectId) {
                filters.push(...ServerUtils.createRequestFilters<ProjectRecord>([['mainProjectId', 'EQ', projectId]]));
            }

            const sortBy = pageParams.sortBy || undefined;

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.OFFERPROJECTTEMPLATE,
                fileName: 'Экспорт смет',
                fileType,
                filters,
                financialFieldsEnum: OfferProjectFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: OfferProjectNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder: !sortBy ? undefined : sortOrder,
                sortBy,
                search,
                excludeColumns,
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, excludeColumns, gridRef, intl, pageParams, projectId]
    );
};

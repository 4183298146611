import { FC, ReactNode } from 'react';
import { CalendarLineUtils } from '../../../../../../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import { CalendarDisplayType } from '../../../../../../../../components/Calendar/utils/data/calendarConstants';
import { WorkPlanningsSplittedCalendarGroup } from '../../../../hooks/useCalendarGroups';
import {
    workPlanningsCalendarSortValueToItemId,
    workPlanningsCalendarSortValueToItemName,
    WorkPlanningsSortValue,
} from '../../../../../../utils/data/sortData';
import { CalendarId } from '../../../../../../../../components/Calendar/types/items';
import { ArrayUtils } from '../../../../../../../../../../../core/utils/arrayUtils';
import { WorkPlanningsRecord } from '../../../../../../../../../../../server';
import classNames from 'classnames';

interface WorkPlanningsSplittedCalendarSidebarGroupContainerProps {
    displayType: CalendarDisplayType;
    sortByValue: WorkPlanningsSortValue | undefined;
    group: WorkPlanningsSplittedCalendarGroup;
    collapsedSidebarGroupsId: CalendarId[];
    setCollapsedSidebarGroupsId: (value: CalendarId[]) => void;
    children: (workPlannings: WorkPlanningsRecord[], isCollapsed: boolean, onClick: () => void) => ReactNode;
}

export const WorkPlanningsSplittedCalendarSidebarGroupContainer: FC<WorkPlanningsSplittedCalendarSidebarGroupContainerProps> = (props) => {
    const { displayType, group, sortByValue, setCollapsedSidebarGroupsId, collapsedSidebarGroupsId, children } = props;
    const { groupRecord } = group;
    const { workPlannings } = groupRecord ?? {};

    if (!groupRecord || groupRecord.workPlannings == null || workPlannings == null || workPlannings.length === 0) return null;

    const lineHeight = CalendarLineUtils.map.groupHeightFromDisplayType.base[displayType];
    let itemsCount = groupRecord.workPlannings.length;

    const sidebarCollapsed = workPlanningsCalendarSortValueToItemName[sortByValue ?? ''] != null;

    if (!sidebarCollapsed) return null;

    const isCompact = displayType === CalendarDisplayType.COMPACT;

    const sortKey = workPlanningsCalendarSortValueToItemId[sortByValue ?? ''];
    if (sortKey == null) return null;
    const groupId = workPlannings[0][sortKey] + '_' + groupRecord.entityId;
    const isCollapsed = !collapsedSidebarGroupsId.includes(groupId);
    let verticalPadding = 2;

    if (!isCollapsed) {
        itemsCount = 1;
    }

    if (itemsCount <= 1) {
        verticalPadding = 0;
    }

    const onClick = () => {
        setCollapsedSidebarGroupsId(ArrayUtils.addOrDeleteItem(collapsedSidebarGroupsId, groupId));
    };

    return (
        <div
            className={classNames('sidebar-group-container', {
                compact: isCompact,
            })}
            style={{
                width: '100%',
                backgroundColor: '#f2f3f8',
                padding: `${verticalPadding}px 16px`,
                borderRadius: 2,
                height: itemsCount * lineHeight - 4,
            }}
        >
            {children(workPlannings, isCollapsed, onClick)}
        </div>
    );
};

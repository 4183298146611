import React, { useCallback } from 'react';
import { _Grid } from '../../../../components/grid/Grid';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { ProjectTransitionCodeEnum } from '../../../../server';
import { setStatusForOffers } from '../reducers/offers/offers.reducer';

export const useSetStatusForOffersCallback = (
    selectedRecords: { id: number; businessVersion: number }[],
    gridRef?: React.RefObject<_Grid>
) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return useCallback(
        (transitionCode: ProjectTransitionCodeEnum) => {
            // TODO
            const workflowDirectiveBulkDirectives = selectedRecords.map(({ id, businessVersion }) => {
                return {
                    id,
                    businessVersion,
                    transitionCode,
                };
            });

            dispatch(
                setStatusForOffers({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: workflowDirectiveBulkDirectives,
                    },
                })
            );

            if (gridRef != null) {
                gridRef.current?.clearSelection();
            }
        },
        [businessAccountId, dispatch, gridRef, selectedRecords]
    );
};

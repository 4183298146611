import {Checkbox as CheckboxComponent} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {LocalizationEnum, useLocalize} from "../../../core/hooks/useLocalize";
import './checkbox.less';

interface CheckboxProps {
    defaultChecked?: boolean;
    checked?: boolean;
    onChange?: (checked?: boolean)=>void;
}

const normalizeValue = (value:string|boolean|undefined) => {
    if(value === 'true') return true;
    else if(value === 'false') return false;
    else if(value === undefined || value === null) return undefined;
    else return Boolean(value);
}

export const Checkbox = (props: CheckboxProps) => {

    const L = useLocalize();
    const [checked, setChecked] = useState<boolean|undefined>(normalizeValue(props.defaultChecked) || normalizeValue(props.checked));
    const [indeterminate, setIndeterminate] = useState(checked === undefined);

    const valueChangeCallback = useCallback((value: boolean|undefined)=>{
        if(value === false){
            setChecked(false);
            setIndeterminate(false);
        }else if(value === true){
            setChecked(true);
            setIndeterminate(false);
        }else{
            setChecked(false);
            setIndeterminate(true);
        }
    }, [setChecked, setIndeterminate]);

    useEffect(()=>{
        let value:boolean|undefined = normalizeValue(props.checked);
        valueChangeCallback(value);
    }, [valueChangeCallback, props.checked]);

    const label = checked ? L(LocalizationEnum.ASPECT__GLOBAL__YES) : (indeterminate ?L(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__N) : L(LocalizationEnum.ASPECT__GLOBAL__NO));

    return (
        <CheckboxComponent
            className={'rr-checkbox'}
            indeterminate={indeterminate}
            checked={checked}
            onChange={(e)=>{
                let value:boolean|undefined;
                if(e.target.checked){
                    if(indeterminate) value = false;
                    else value = true;
                } else value = undefined;
                valueChangeCallback(value);
                props.onChange?.(value);
            }}
        >{label}</CheckboxComponent>
    );
};

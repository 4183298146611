import React, { Fragment } from 'react';
import { Col, Icon, Row } from 'antd';
import {
    InstanceStateCodeEnum,
    InstanceTrackingTypeCodeEnum,
    TimetableStackCountersObj,
    TimetableTypeCodeEnum,
} from '../../../../../server/api';
import {
    IconBriefcase,
    IconCheck,
    IconDollySolid,
    IconMinusSolid,
    IconTagSolid,
    IconToolsSolid,
    IconWarehouseSolid,
} from '../../../../../components/icons';
import { ProductCalendarPopover } from '../../../dev/OperationFormProductCalendarPopover';
import { Link, useLocation } from 'react-router-dom';
import { InventoryCurrentStateBlockItem } from './InventoryCurrentStateBlockItem';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { getPathFromState, isDefined } from '../../../../../shared/util/utils';
import { push } from 'connected-react-router';
import { ProductInstancePopover } from '../../instances/components/productInstancePopover/productInstancePopover';

export interface InventoryCurrentStateBlockProps {
    stockInstanceCount: number;
    underMaintenanceInstanceCount: number;
    rentedInstanceCount: number;
    subrentedInstanceCount: number;
    availableInstanceCount?: number;
    warehouseInstanceCount?: number;
    phoneMode: boolean;
    inventoryIcon?: any;
    inventoryLink?: string;
    inventoryLinkOnClick?: () => void;
    productId?: number;
    variantId?: number;
    instanceId?: number;
    kitId?: number;
    instanceStateCode?: InstanceStateCodeEnum;
    forProduct?: boolean;
    instanceTrackingTypeCode?: InstanceTrackingTypeCodeEnum;
    timetableStackCounters?: TimetableStackCountersObj;
    utfIcons?: boolean;
    selectedInstanceId?: number;
}

export const InventoryCurrentStateBlock = (props: InventoryCurrentStateBlockProps) => {
    const {
        stockInstanceCount,
        underMaintenanceInstanceCount,
        rentedInstanceCount,
        subrentedInstanceCount,
        phoneMode,
        inventoryIcon,
        inventoryLink,
        variantId,
        productId,
        kitId,
        instanceId,
        instanceStateCode,
        warehouseInstanceCount,
        selectedInstanceId,
    } = props;
    const isInstance = !!instanceId;
    const supplier =
        instanceStateCode === InstanceStateCodeEnum.SUPPLIERACTIVE || instanceStateCode === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED;
    const instanceAccounted =
        instanceStateCode === InstanceStateCodeEnum.ACTIVE || instanceStateCode === InstanceStateCodeEnum.UNDERMAINTENANCE;

    const timetableStackCounters__occupiedInstanceCount = props.timetableStackCounters?.occupiedInstanceCount;
    const timetableStackCounters__availableInstanceCount = props.timetableStackCounters?.availableInstanceCount;
    const timetableStackCounters__subrentInstanceCount = props.timetableStackCounters?.subrentedInstanceCount;

    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const dispatch = useAppDispatch();
    const location = useLocation();

    const printCount = (
        value: number | undefined,
        white: boolean = false,
        color: string | undefined = undefined,
        utfIcons: boolean = false
    ): JSX.Element => {
        if (!isInstance) {
            return <>{value}</>;
        } else {
            if (value === 1) {
                return utfIcons ? (
                    <span style={{ color: '#fc8c2f' }}>✓</span>
                ) : (
                    <Icon
                        component={IconCheck}
                        style={{
                            color: color ? color : white ? '#FFFFFF' : '#34bfa3',
                            fontSize: 22,
                            marginTop: white ? 3 : undefined,
                        }}
                    />
                );
            } else if (value === 0) {
                return utfIcons ? (
                    <span style={{ color: '#fc8c2f' }}>—</span>
                ) : (
                    <Icon component={IconMinusSolid} style={{ color: color ? color : white ? '#FFFFFF' : '#aaaaaa', fontSize: 22 }} />
                );
            } else if (value && value > 1) {
                return <span style={{ color: 'unset' }}>{value}</span>;
            } else {
                return <span style={{ color: color ? color : white ? '#FFFFFF' : '#f4516c' }}>{value}</span>;
            }
        }
    };

    let ttOccupied = false;
    let ttAvailable = false;
    let ttSubrent = false;

    if (
        timetableStackCounters__occupiedInstanceCount !== undefined &&
        rentedInstanceCount !== timetableStackCounters__occupiedInstanceCount
    ) {
        ttOccupied = true;
    }

    if (
        timetableStackCounters__availableInstanceCount !== undefined &&
        warehouseInstanceCount !== timetableStackCounters__availableInstanceCount
    ) {
        ttAvailable = true;
    }

    if (
        timetableStackCounters__subrentInstanceCount !== undefined &&
        subrentedInstanceCount !== timetableStackCounters__subrentInstanceCount
    ) {
        ttSubrent = true;
    }

    const instanceIcon = <Icon component={inventoryIcon} style={{ color: '#525993', fontSize: 30 }} />;

    return (
        <Row>
            <Col
                span={phoneMode ? 8 : 24}
                className={'rr-product-page__main-indicators-block-item'}
                style={{ marginTop: props.forProduct ? -5 : 0, marginBottom: isInstance ? 17 : 26 }}
            >
                <div style={{ fontSize: 18 }}>Текущее состояние</div>
                {props.forProduct ? <div style={{ color: '#aaaaaa', marginTop: 4, marginBottom: 15 }}>Число экземпляров</div> : null}
                <div>
                    {!isInstance ? (
                        <div>
                            <ProductCalendarPopover
                                productId={productId}
                                kitId={kitId}
                                variantId={variantId}
                                instanceId={instanceId}
                                typeCode={TimetableTypeCodeEnum.STOCK}
                            >
                                <div
                                    style={{ display: 'inline-flex', marginRight: 10 }}
                                    className={'rr-product-page__main-indicators-link'}
                                >
                                    {isDefined(selectedInstanceId) ? (
                                        <span onClick={(e) => e.stopPropagation()}>
                                            <ProductInstancePopover
                                                instanceId={selectedInstanceId}
                                                visible={!!selectedInstanceId}
                                                onCloseButtonClick={() => {
                                                    const path = getPathFromState(location.pathname, location.search, {
                                                        instanceId: undefined,
                                                    });
                                                    dispatch(push(path));
                                                }}
                                                placement={'right'}
                                            >
                                                {instanceIcon}
                                            </ProductInstancePopover>
                                        </span>
                                    ) : (
                                        <span>{instanceIcon}</span>
                                    )}
                                    <span className="product-page-description-link-span" style={{ marginLeft: 8, marginBottom: -4 }}>
                                        {stockInstanceCount}
                                    </span>
                                </div>
                            </ProductCalendarPopover>
                            {props.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK ||
                            props.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ? (
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 'normal',
                                        position: 'relative',
                                        top: -6,
                                    }}
                                >
                                    {' '}
                                    счетных
                                </span>
                            ) : null}
                            {props.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                            props.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? (
                                <span>
                                    {' '}
                                    <Link
                                        className={'app-link-hover'}
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            textDecoration: 'underline',
                                            color: '#525a95',
                                            position: 'relative',
                                            top: -6,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.inventoryLinkOnClick?.();
                                        }}
                                        to={inventoryLink || ''}
                                    >
                                        именованных
                                    </Link>
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </Col>

            {isInstance && !supplier && (
                <InventoryCurrentStateBlockItem
                    title={'Инвентаризация'}
                    phoneMode={phoneMode}
                    productId={productId}
                    variantId={variantId}
                    instanceId={instanceId}
                    countElement={printCount(instanceAccounted ? 1 : 0)}
                    typeCode={TimetableTypeCodeEnum.STOCK}
                    icon={IconTagSolid}
                    iconClass={'rr-product-page__main-indicators-block-item1-icon-5'}
                />
            )}

            {(!isInstance || (isInstance && !supplier)) && (
                <InventoryCurrentStateBlockItem
                    title={'Обслуживание'}
                    phoneMode={phoneMode}
                    productId={productId}
                    variantId={variantId}
                    instanceId={instanceId}
                    countElement={printCount(underMaintenanceInstanceCount)}
                    typeCode={TimetableTypeCodeEnum.MAINTENANCE}
                    icon={IconToolsSolid}
                    iconClass={'rr-product-page__main-indicators-block-item1-icon-3'}
                />
            )}

            {subrentModuleEnabled && (!isInstance || (isInstance && supplier)) && (
                <InventoryCurrentStateBlockItem
                    title={'Субаренда'}
                    phoneMode={phoneMode}
                    productId={productId}
                    variantId={variantId}
                    instanceId={instanceId}
                    countElement={printCount(subrentedInstanceCount)}
                    scheduleCountElement={
                        ttSubrent ? printCount(timetableStackCounters__subrentInstanceCount, false, undefined, true) : undefined
                    }
                    typeCode={TimetableTypeCodeEnum.SUBRENTED}
                    icon={IconDollySolid}
                    iconClass={'rr-product-page__main-indicators-block-item1-icon-2'}
                />
            )}

            <InventoryCurrentStateBlockItem
                title={'Аренда'}
                phoneMode={phoneMode}
                productId={productId}
                variantId={variantId}
                instanceId={instanceId}
                countElement={printCount(rentedInstanceCount)}
                scheduleCountElement={
                    ttOccupied ? printCount(timetableStackCounters__occupiedInstanceCount, false, undefined, true) : undefined
                }
                typeCode={TimetableTypeCodeEnum.OCCUPIED}
                icon={IconBriefcase}
                iconClass={'rr-product-page__main-indicators-block-item1-icon-1'}
            />

            <InventoryCurrentStateBlockItem
                title={'На складе'}
                phoneMode={phoneMode}
                productId={productId}
                variantId={variantId}
                instanceId={instanceId}
                countElement={printCount(warehouseInstanceCount)}
                scheduleCountElement={
                    ttAvailable ? printCount(timetableStackCounters__availableInstanceCount, false, undefined, true) : undefined
                }
                typeCode={TimetableTypeCodeEnum.AVAILABLE}
                icon={IconWarehouseSolid}
                iconClass={'rr-product-page__main-indicators-block-item1-icon-4'}
                isLast={true}
            />
        </Row>
    );
};

import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../shared/reducers';
import { rentersPageUrlRoute } from '../../../../renters/data/tabs';

import { RentersPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

interface CargoHandlerProps {
    counterpartyId?: number;
    counterpartyName?: string;
}

export const CargoCounterpartyHandler: FC<CargoHandlerProps> = ({ counterpartyId, counterpartyName }) => {
    const businessAccountId = useSelector((state: IRootState) => state.system.businessAccountId);
    const businessAccountShortName = useSelector((state: IRootState) => state.businessAccountContacts.entity?.shortName);

    return (
        <div className={'rr-grid-text-cell-left'} title={counterpartyName}>
            {counterpartyId && counterpartyName ? (
                <Link
                    to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${counterpartyId}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {counterpartyName}
                </Link>
            ) : (
                <span style={{ color: '#aaaaaa' }}>{businessAccountShortName ?? ''}</span>
            )}
        </div>
    );
};

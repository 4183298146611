import React, { FC } from 'react';
import { Skeleton } from 'antd';

export const DashboardWidgetLoader: FC = () => {
    return (
        <div style={{ display: 'flex', minWidth: 210, marginTop: 0, flexGrow: 1, marginBottom: 30 }}>
            <div style={{ backgroundColor: '#FFFFFF', flexGrow: 1, padding: 15 }}>
                <Skeleton active avatar paragraph={{ rows: 2 }} />
            </div>
        </div>
    );
};

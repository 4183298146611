import { VehicleInfoRead } from '../../../../../../../server';
import { IModalFormFilters } from '../../../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../../../components/modalForm/components/Header/types/displayData';
import {
    allFieldsDisplayGroup,
    mainFieldsDisplayGroup,
} from '../../../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof VehicleInfoRead> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['shortName', 'officialNumber', 'type', 'loadCapacity']],
    },
];

export const createVehicleModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editVehicleModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

import { useCallback } from 'react';
import { ContactRecord } from '../../../../../../server';

export const useContactOnCellAction = () => {
    return useCallback((dataIndex: string, record: Record<string, any>) => {
        const handlers: Partial<Record<keyof ContactRecord, Function>> = {
            crewMemberId: () => {},
        };

        if (handlers[dataIndex]) handlers[dataIndex]();
    }, []);
};

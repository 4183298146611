import { DetailedHTMLProps, FC, InputHTMLAttributes, memo, RefObject, useCallback, useEffect, useState } from 'react';
import { ControlArrow } from './components/ControlArrow/ControlArrow';
import classNames from 'classnames';
import './InlineNumberInput.less';
import _ from 'lodash';
import { inputNumberParser } from '../../../numberInputs';
import { formatStyleToPrecisionPointMap, parseFloatToFixedString } from './components/utils/parseFloatToFixed';
import { SliderRangeFormatStyle } from '../../../slider/SliderRange/SliderRange';

interface InputProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'step'> {
    defaultValue: number;
    min: number;
    max: number;
    formatStyle: SliderRangeFormatStyle;
    step?: number | null;
    ref?: RefObject<HTMLInputElement>;
    theme?: InlineNumberInputColorTheme;
    precision?: number;
}

export interface InlineNumberInputProps {
    inputProps: InputProps;
    containerProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    focusOnMount?: boolean;
}

export enum InlineNumberInputColorTheme {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

const themeClasses: Record<InlineNumberInputColorTheme, string> = {
    [InlineNumberInputColorTheme.LIGHT]: 'light-theme',
    [InlineNumberInputColorTheme.DARK]: 'dark-theme',
};

export const InlineNumberInput: FC<InlineNumberInputProps> = memo(
    (props) => {
        const {
            containerProps,
            inputProps: {
                defaultValue,
                min,
                max,
                step,
                ref: inputRef,
                theme = InlineNumberInputColorTheme.LIGHT,
                formatStyle,
                precision,
                ...inputRestProps
            },
            focusOnMount,
        } = props;

        const [inputValue, setInputValue] = useState<string>(String(defaultValue));

        const isDecrementEnabled = parseFloat(inputValue) > min;
        const isIncrementEnabled = parseFloat(inputValue) < max;

        const onChangeHandler = useCallback(
            (value: string) => {
                let newValue =
                    parseFloatToFixedString(String(inputNumberParser(value)), precision ?? formatStyleToPrecisionPointMap[formatStyle]) ??
                    '';

                if (newValue === '.') newValue = '0.';

                setInputValue(newValue);
            },
            [formatStyle, precision]
        );

        const onDecrementHandler = useCallback(() => {
            if (!isDecrementEnabled) return;
            onChangeHandler(String(parseFloat(inputValue) - (step ?? 1)));
        }, [inputValue, isDecrementEnabled, onChangeHandler, step]);

        const onIncrementHandler = useCallback(() => {
            if (!isIncrementEnabled) return;
            onChangeHandler(String(parseFloat(inputValue) + (step ?? 1)));
        }, [inputValue, isIncrementEnabled, onChangeHandler, step]);

        useEffect(() => {
            if (focusOnMount) {
                inputRef?.current?.focus();
                inputRef?.current?.select();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <div {...containerProps} className={classNames('inline-number-input-container', containerProps?.className)}>
                <ControlArrow action={'decrement'} isActionEnabled={isDecrementEnabled} actionHandler={onDecrementHandler} />
                <input
                    ref={inputRef}
                    size={String(inputValue).length}
                    maxLength={28}
                    value={String(inputValue)}
                    onChange={(event) => onChangeHandler(event.target.value)}
                    onClick={(event) => event.stopPropagation()}
                    {...inputRestProps}
                    className={classNames('inline-number-input', themeClasses[theme], {
                        empty: !inputValue,
                    })}
                />
                <ControlArrow action={'increment'} isActionEnabled={isIncrementEnabled} actionHandler={onIncrementHandler} />
            </div>
        );
    },
    (prevProps, newProps) => _.isEqual(prevProps, newProps)
);

import { FileTypeCodeEnum, serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import moment from 'moment/moment';

export interface EstimateGenerateArgs {
    businessAccountId: number;
    parentId: number;
    common: boolean;
    documentTemplateId: number;
    expandKits: boolean;
    joinSimilarRows: boolean;
    includeWorkPlannings: boolean;
    includeExpenses: boolean;
    search?: string;
    filters?: string[];
    period?: [string, string];
    legalDetailId?: number;
}

export const generateEstimate = async (
    args: EstimateGenerateArgs,
    parentType: 'operation' | 'project' | 'projectTemplate' | 'shipping',
    fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
) => {
    if (parentType === 'operation') {
        if (fileType === FileTypeCodeEnum.EXCEL) {
            const res: AxiosResponse<Blob> = await serverApi.generateOperationEstimateDocumentXlsx(
                args.businessAccountId,
                args.parentId,
                args.common,
                args.documentTemplateId,
                args.expandKits,
                args.joinSimilarRows,
                getCurrentTimezoneUTCOffset(),
                undefined,
                undefined,
                args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
                args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
                args.legalDetailId,
                undefined,
                'externalCode',
                'ASC',
                args.search,
                { query: { filters: args.filters }, responseType: 'blob' }
            );
            return res.data;
        } else {
            const res: AxiosResponse<Blob> = await serverApi.generateOperationEstimateDocumentPdf(
                args.businessAccountId,
                args.parentId,
                args.common,
                args.documentTemplateId,
                args.expandKits,
                args.joinSimilarRows,
                getCurrentTimezoneUTCOffset(),
                undefined,
                undefined,
                args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
                args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
                args.legalDetailId,
                undefined,
                'externalCode',
                'ASC',
                args.search,
                { query: { filters: args.filters }, responseType: 'blob' }
            );
            return res.data;
        }
    }

    if (fileType === FileTypeCodeEnum.EXCEL) {
        const res: AxiosResponse<Blob> = await serverApi.generateProjectEstimateDocumentXlsx(
            args.businessAccountId,
            args.parentId,
            args.includeWorkPlannings,
            args.includeExpenses,
            args.common,
            args.documentTemplateId,
            args.expandKits,
            args.joinSimilarRows,
            getCurrentTimezoneUTCOffset(),
            undefined,
            undefined,
            args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
            args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
            args.legalDetailId,
            undefined,
            'externalCode',
            'ASC',
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    } else {
        const res: AxiosResponse<Blob> = await serverApi.generateProjectEstimateDocumentPdf(
            args.businessAccountId,
            args.parentId,
            args.includeWorkPlannings,
            args.includeExpenses,
            args.common,
            args.documentTemplateId,
            args.expandKits,
            args.joinSimilarRows,
            getCurrentTimezoneUTCOffset(),
            undefined,
            undefined,
            args.period?.[0] ? moment(args.period?.[0]).toDate() : undefined,
            args.period?.[1] ? moment(args.period?.[1]).toDate() : undefined,
            args.legalDetailId,
            undefined,
            'externalCode',
            'ASC',
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    }
};

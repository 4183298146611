import { RefObject, useCallback, useLayoutEffect, useMemo, useState } from 'react';

export const usePosition = (
    inputRef: RefObject<HTMLDivElement>
):
    | {
          topOffset: number;
          leftOffset: number;
          rightOffset: number;
      }
    | undefined => {
    const setWindowSize = useState({ width: window.innerWidth, height: window.innerHeight })[1];

    const handleResize = useCallback(() => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }, [setWindowSize]);

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const inputContainerRects = inputRef.current?.getBoundingClientRect();
    const bodyRects = document.body.getBoundingClientRect();

    const topOffset = inputContainerRects?.top ? inputContainerRects.top + window.scrollY : undefined;
    const leftOffset = inputContainerRects?.left;
    const rightOffset = inputContainerRects ? bodyRects.width - inputContainerRects.right : undefined;

    return useMemo(() => {
        if (!(leftOffset && topOffset && rightOffset)) return undefined;

        return {
            topOffset,
            leftOffset,
            rightOffset,
        };
    }, [leftOffset, rightOffset, topOffset]);
};

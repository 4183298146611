import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField } from '../../../../../../core/utils/formUtils';
import { ListLifecycleEventsFilters } from '../api/lifecycleEvents.api.types';
import { createHiddenField } from '../../../../../../components/modalForm/utils';
import { entityTypeCodeMap } from '../../../../../../localization/utils/entityTypeCodeMap';
import { EntityLifecycleEventTypeCode, EntityTypeCodeEnum } from '../../../../../../server';
import React from 'react';
import { EventTypeItem } from '../components/EventTypeItem/EventTypeItem';
import _ from 'lodash';
import { localize } from '../../../../../../localization';

export const lifecycleEventsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListLifecycleEventsFilters>([
    {
        fields: [
            createHiddenField('authorId') as TypedFormField<ListLifecycleEventsFilters>,
            createHiddenField('screenLeft') as TypedFormField<ListLifecycleEventsFilters>,
            {
                label: 'Тип действия',
                id: 'entityLifecycleEventTypeCode',
                placeholder: 'Любой',
                type: FormItemType.Select,
                className: 'rr-select-gray',
                style: { minWidth: 120 },
                values: () => {
                    return [
                        EntityLifecycleEventTypeCode.CREATE,
                        EntityLifecycleEventTypeCode.UPDATE,
                        EntityLifecycleEventTypeCode.DELETE,
                        EntityLifecycleEventTypeCode.WORKFLOWTRANSITION,
                    ].map((value) => {
                        return {
                            name: (
                                <EventTypeItem
                                    typeCode={value}
                                    style={{
                                        justifyContent: 'start',
                                    }}
                                />
                            ),
                            value,
                        };
                    });
                },
            },
            {
                label: 'Тип сущности',
                id: 'entityTypeCode',
                placeholder: 'Любой',
                type: FormItemType.Select,
                className: 'rr-select-gray',
                style: { minWidth: 120 },
                values: () => {
                    return _.map(EntityTypeCodeEnum, (value) => ({
                        name: entityTypeCodeMap[value] ? localize(entityTypeCodeMap[value]) : undefined,
                        value: value,
                    })).filter(({ name }) => Boolean(name));
                },
            },
        ],
    },
]);

import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {serverApi, ServerError, VariantInfoCreate, VariantInfoRead, VariantInfoUpdate} from "../../../../../server";
import {getServerError} from "../../../../../shared/util/utils";
import {productVariantsApi} from "../api/productVariants.api";

export const ACTION_TYPES = {
    LOAD_ENTITY: 'productVariant/LOAD_ENTITY',
    UPDATE_ENTITY: 'productVariant/UPDATE_ENTITY',
    CREATE_ENTITY: 'productVariant/CREATE_ENTITY',
    DELETE_ENTITY: 'productVariant/DELETE_ENTITY',
    RESET: 'productVariant/RESET',
    RESET_ERROR: 'productVariant/RESET_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entity: null as (VariantInfoRead | null),
    updating: false,
    updatingError: undefined as (undefined | ServerError)
};

export type ProductVariantState = Readonly<typeof initialState>;

// Reducer

export default (state:ProductVariantState = initialState, action):ProductVariantState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case REQUEST(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: true
            };

        case FAILURE(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: false
            };

        case SUCCESS(ACTION_TYPES.DELETE_ENTITY):
            return {
                ...state,
                loading: false
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                loadingError: undefined,
                updatingError: undefined
            };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false
            };

        default:
            return state;
    }
};

// Actions

export const loadEntity = (businessAccountId: number, productId: number, id: number) => {
    return ({
        type: ACTION_TYPES.LOAD_ENTITY,
        payload: serverApi.getProductVariantById(businessAccountId, productId, id )
    });
};

export const createEntity = (businessAccountId: number, productId:number, variantInfoCreate: VariantInfoCreate) => ({
    type: ACTION_TYPES.CREATE_ENTITY,
    payload: serverApi.createProductVariant(businessAccountId, productId, variantInfoCreate)
});

export const updateEntity = (businessAccountId: number, productId: number, id: number, variantInfoUpdate: VariantInfoUpdate) => ({
    type: ACTION_TYPES.UPDATE_ENTITY,
    payload: serverApi.updateProductVariantById(businessAccountId, productId, id, variantInfoUpdate)
});

export const updateEntity1 = (businessAccountId: number, productId: number, id: number, variantInfoUpdate: VariantInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateProductVariantById(businessAccountId, productId, id, variantInfoUpdate)
        }).then((result) => {
            if(result instanceof Error){
                throw result;
            }else{
                dispatch(productVariantsApi.util?.invalidateTags(['ProductVariant']));
            }
        });
    };
};

export const deleteEntity = (businessAccountId: number, productId: number, id: number, businessVersion: number) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.DELETE_ENTITY,
            payload: serverApi.deleteProductVariantById(businessAccountId, productId, id, {businessVersion})
        }).then((result) => {
            if(result instanceof Error){
                throw result;
            }else{
                dispatch(productVariantsApi.util?.invalidateTags(['ProductVariant']));
            }
        });
    };
};

export const resetVariant = () => ({
    type: ACTION_TYPES.RESET
});

export const resetVariantError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});

import React, { FC, useContext } from 'react';
import { TimeRangeContext } from '../../../../../../context/TimeRangeContext';
import { CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../Calendar/utils/data/calendarConstants';
import { ElementItemContext } from '../../../../../../context/CalendarItemContext';
import classNames from 'classnames';
import './LineTail.less';
import moment from 'moment';

interface LineTailProps {
    width: number;
}

export const LineTail: FC<LineTailProps> = (props) => {
    const { width } = props;
    const { item, itemContext } = useContext(ElementItemContext);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const {
        end_time,
        problemsAndWarnings: { subrentReturnToShipperDelay },
    } = item;

    const lineHeight = itemContext.dimensions.height;

    const isTailEndsBeforeRightBoundary = +moment() < visibleTimeEnd;

    return (
        <div
            className={classNames('calendar-line-tail')}
            style={{
                background: '#f4516c',
                height: lineHeight,
                lineHeight: lineHeight,
                width,
                flex: 'none',
                borderTopRightRadius: subrentReturnToShipperDelay && isTailEndsBeforeRightBoundary ? CALENDAR_LINE_ROUND_RADIUS : undefined,
                borderBottomRightRadius:
                    subrentReturnToShipperDelay && isTailEndsBeforeRightBoundary ? CALENDAR_LINE_ROUND_RADIUS : undefined,
            }}
        >
            <div className={'boundaries'}>
                {+end_time <= visibleTimeStart && <div className={'left-boundary'} />}
                {+moment() >= visibleTimeEnd && <div className={'right-boundary'} />}
            </div>
        </div>
    );
};

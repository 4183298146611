import React, { ReactNode } from 'react';
import { Popover } from 'antd';

interface IProps {
    content: ReactNode;
    disabled?: boolean;
    isElement?: boolean; // TODO это хрень, надо переименовать
    dontClose?: boolean;
    overlayStyle?: React.CSSProperties;
}

interface IState {
    visible: boolean;
}

class AdditionalEntityActions extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    public close = () => {
        this.handleVisibleChange(false);
    };

    handleVisibleChange = (visible: boolean) => {
        if (this.props.dontClose !== true) {
            setTimeout(
                () => {
                    this.setState({ visible });
                },
                visible ? 0 : 100
            );
        }
    };

    onButtonClick = (e) => {
        e.stopPropagation();
        if (this.props.dontClose !== true) this.setState({ visible: !!this.props.isElement });
    };

    render() {
        return (
            <Popover
                onVisibleChange={this.handleVisibleChange}
                visible={this.props.disabled === true ? false : this.state.visible}
                overlayClassName={'rr-grid-actions-popover'}
                overlayStyle={this.props.overlayStyle}
                autoAdjustOverflow
                arrowPointAtCenter
                placement={'bottomLeft'}
                trigger={'click'}
                content={
                    <>
                        <div className={'rr-grid-actions-popover-header1'}></div>
                        <div className={'rr-grid-actions-popover-content'} onClick={this.onButtonClick}>
                            {this.props.content}
                        </div>
                    </>
                }
            >
                {this.props.children}
            </Popover>
        );
    }
}

export default AdditionalEntityActions;

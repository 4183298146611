import React from "react";
import {Alert as AlertOriginal, Icon} from "antd";
import {AlertProps} from "antd/lib/alert";
import {IconBell, IconCheckCircle, IconHandStopO} from "../icons";
import "./alert.less";

export const Alert = (props:AlertProps) => {
    let iconComponent, className, icon;

    if(props.type === 'success'){
        iconComponent = IconCheckCircle;
        className = 'rr-alert-icon';
    }
    else if(props.type === 'info' || props.type === 'warning'){
        iconComponent = IconBell;
        className = 'rr-alert-icon';
    }
    else if(props.type === 'error'){
        iconComponent = IconHandStopO;
    }

    if(iconComponent){
        icon = <Icon className={className} component={iconComponent} />;
    }

    return <AlertOriginal icon={icon} {...props} />;
};

// TODO Убрать дефолтный экспорт!
export default Alert;
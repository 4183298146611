import React from 'react';
import { Icon, Popover } from 'antd';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPathFromState } from '../../../../../shared/util/utils';
import { getStoreState } from '../../../../../../index';
import { CustomRangeCalendar } from '../../../../../components/datePicker/CustomRangeCalendar';
import { HelpTooltip } from '../../../../../components/helpTooltip/HelpTooltip';
import { LocalizationEnum, localize } from '../../../../../localization';
import { IconClose } from '../../../../../components/icons/IconClose';
import { Styles } from '../../../../../styles';
import {
    IconAngleDoubleLeft,
    IconAngleDoubleRight,
    IconAngleLeft,
    IconAngleRight,
    IconCrosshairsSolid,
} from '../../../../../components/icons';
import { getParamsScreenData } from './helpers/getScreenData';
import { formatDate } from '../../../../../shared/util/formatDate';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';

export interface CalendarNavigationProps extends DispatchProps {
    pageName: string;
    positionTop: boolean;
    isAllMode: boolean;
    screenLeft?: number;
    screenRight?: number;
    step?: number;
    arrowType?: 'single' | 'double';
    crossClose?(): void;
}

export const getTimeCalendar = (screenLeft?: number, screenRight?: number) => {
    if (!screenLeft || !screenRight) return undefined;

    let screenTimeLeft = screenLeft;
    let screenTimeRight = screenRight;
    let intervalDay;
    let intervalString;

    if (moment(screenTimeLeft).startOf('day').valueOf() === moment(screenTimeRight).startOf('day').valueOf()) {
        intervalDay = +screenTimeLeft + (screenTimeRight - screenTimeLeft) / 2;
        intervalString = formatDate(new Date(intervalDay), 'D MMMM YYYY');
    } else {
        if (formatDate(new Date(+screenTimeLeft), 'YYYY') === formatDate(new Date(+screenTimeRight), 'YYYY')) {
            if (formatDate(new Date(+screenTimeLeft), 'MM') === formatDate(new Date(+screenTimeRight), 'MM')) {
                intervalString =
                    '' + formatDate(new Date(+screenTimeLeft), 'D') + ' - ' + formatDate(new Date(+screenTimeRight), 'D MMMM YYYY');
            } else {
                intervalString =
                    '' + formatDate(new Date(+screenTimeLeft), 'D MMMM') + ' - ' + formatDate(new Date(+screenTimeRight), 'D MMMM YYYY');
            }
        } else {
            intervalString =
                '' + formatDate(new Date(+screenTimeLeft), 'D MMMM YYYY') + ' - ' + formatDate(new Date(+screenTimeRight), 'D MMMM YYYY');
        }
    }
    if (!screenTimeLeft || !screenTimeRight) {
        return undefined;
    }
    return intervalString;
};

class CalendarNavigation extends React.PureComponent<CalendarNavigationProps> {
    defaultStep: number = 1;

    state = {
        calendar: false,
    };

    get step() {
        return this.props.step ?? this.defaultStep;
    }

    stepIntervalLeft = () => {
        if (!this.props.screenLeft || !this.props.screenRight) return undefined;

        this.changeInterval(
            +this.props.screenLeft - (this.props.screenRight - this.props.screenLeft) * this.step,
            +this.props.screenRight - (this.props.screenRight - this.props.screenLeft) * this.step
        );
    };

    stepOnTime = () => {
        if (!this.props.screenLeft || !this.props.screenRight) return undefined;
        this.changeInterval(
            +Date.now() - (this.props.screenRight - this.props.screenLeft) / 2,
            +Date.now() + (this.props.screenRight - this.props.screenLeft) / 2
        );
    };

    stepIntervalRight = () => {
        if (!this.props.screenLeft || !this.props.screenRight) return undefined;

        this.changeInterval(
            +this.props.screenLeft + (+this.props.screenRight - +this.props.screenLeft) * this.step,
            +this.props.screenRight + (+this.props.screenRight - +this.props.screenLeft) * this.step
        );
    };

    handleVisibleChange = () => {
        this.setState({ calendar: !this.state.calendar });
    };

    onCalendarChange = (value) => {
        this.handleVisibleChange();

        let left: number = +value[0].startOf('day');
        let right: number = +value[1].startOf('day') + (1000 * 60 * 60 * 24 - 1);
        let firstTime;
        let lastTime;

        firstTime = +left;
        lastTime = +right;

        const paramScreen = getParamsScreenData(firstTime, lastTime);

        this.changeInterval(paramScreen.screenLeft, paramScreen.screenRight);
    };

    changeInterval = (screenLeft: number, screenRight: number) => {
        const left = screenLeft.toFixed(0);
        const right = screenRight.toFixed(0);

        const newParams = {
            screenLeft: Math.round(+left),
            screenRight: Math.round(+right),
            capture: undefined,
        };

        this.props.push(getPathFromState(getStoreState().router.location.pathname, getStoreState().router.location.search, newParams));

        ParamsUtils.setGridStorageDataParamsFilters(this.props.pageName, newParams, true);
    };

    render() {
        const { screenLeft, screenRight, positionTop, arrowType = 'single', crossClose, isAllMode } = this.props;

        const isDisabled = false;

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <Popover
                        overlayClassName={'rr-calendar-popover'}
                        onVisibleChange={this.handleVisibleChange}
                        visible={this.state.calendar}
                        autoAdjustOverflow
                        arrowPointAtCenter
                        trigger="click"
                        destroyTooltipOnHide
                        content={
                            <CustomRangeCalendar
                                selectedValue={[moment(screenLeft ?? Date.now()), moment(screenRight ?? Date.now())]}
                                onChange={this.onCalendarChange}
                                pricingScheme={undefined}
                            />
                        }
                    >
                        <div
                            style={{
                                fontSize: '18px',
                                cursor: 'pointer',
                                width: '338px',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: '32px',
                            }}
                        >
                            {!screenLeft || !screenRight || isAllMode
                                ? localize(LocalizationEnum.PAGE__CALENDAR__ANY_TIME)
                                : getTimeCalendar(screenLeft, screenRight)}
                        </div>
                    </Popover>

                    {!positionTop ? (
                        <Icon
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            className={Styles.ICON_22}
                            component={IconClose}
                            onClick={crossClose}
                        />
                    ) : null}
                </div>

                <div style={{ height: '7px' }}></div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                        disabled={isDisabled}
                        style={{
                            borderRadius: '0px',
                            border: 'none',
                            height: '35px',
                            width: '40px',
                            borderRight: '1px solid rgba(204, 204, 204, 0.8)',
                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ffffff',
                        }}
                        onClick={() => this.stepIntervalLeft()}
                    >
                        <Icon
                            component={arrowType === 'single' ? IconAngleLeft : IconAngleDoubleLeft}
                            style={{ color: isDisabled ? '#ccc' : '#383F77', margin: 'auto 0px' }}
                        />
                    </button>
                    <button
                        disabled={isDisabled}
                        onClick={() => this.stepOnTime()}
                        style={{
                            border: 'none',
                            height: '35px',
                            width: '40px',
                            borderRight: '1px solid rgba(204, 204, 204, 0.8)',
                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ffffff',
                            color: '#ccc',
                        }}
                    >
                        <Icon component={IconCrosshairsSolid} style={{ color: isDisabled ? '#ccc' : '#000', margin: 'auto 0px' }} />
                    </button>
                    <HelpTooltip content={LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A3}>
                        <button
                            disabled={isDisabled}
                            style={{
                                borderRadius: '0px',
                                border: 'none',
                                height: '35px',
                                width: '40px',
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                display: 'flex',
                                justifyItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#ffffff',
                                color: '#ccc',
                            }}
                            onClick={this.stepIntervalRight}
                        >
                            <Icon
                                component={arrowType === 'single' ? IconAngleRight : IconAngleDoubleRight}
                                style={{
                                    color: isDisabled ? '#ccc' : '#383F77',
                                    margin: 'auto 0px',
                                }}
                            />
                        </button>
                    </HelpTooltip>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = { push };

type DispatchProps = typeof mapDispatchToProps;

export default connect(undefined, mapDispatchToProps)(CalendarNavigation);

import { VehicleInfoRead } from '../../../../../../../../server';
import React, { memo } from 'react';
import { VehicleDrawer } from '../VehicleDrawer';
import { EntityGridDrawerWrapper } from '../../../../../../../../components/page/entityGrid/types/drawer';

export const VehicleDrawerWrapper: EntityGridDrawerWrapper<VehicleInfoRead> = memo(
    ({ entityId, onClose, refetchEntitiesList, editActionForDrawer, deleteActionForDrawer, archiveActionForDrawer, isLoading }) => {
        return (
            <VehicleDrawer
                vehicleId={entityId}
                onClose={onClose}
                editVehicle={editActionForDrawer}
                deleteVehicle={deleteActionForDrawer}
                archiveVehicle={archiveActionForDrawer}
                isEntityActionsLoading={isLoading}
                refetchEntitiesList={() => {
                    if (refetchEntitiesList) refetchEntitiesList();
                }}
            />
        );
    }
);

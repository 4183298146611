import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { ElementsLineContent } from '../components/LineContent/variants/elements/ElementsLineContent';
import { Icon } from 'antd';
import { IconAngleDown, IconAngleUp } from '../../../../../../../../../../components/icons';
import { DisplayTypeItemContext, ElementItemContext } from '../../../../../context/CalendarItemContext';
import {
    CALENDAR_LINE_HEIGHT_COMPACT,
    CALENDAR_LINE_HEIGHT_NORMAL,
    CalendarDisplayType,
} from '../../../../../../Calendar/utils/data/calendarConstants';
import { ElementsLineBorders } from '../components/LineBorders/variants/elements/ElementsBorders';
import { useItemWidth } from '../../../../../../../tabs/elements/hooks/useItemWidth';

interface ElementsCalendarItemProps {}

export const ElementsCalendarItem: FC<ElementsCalendarItemProps> = () => {
    const displayType = useContext(DisplayTypeItemContext);
    const { item } = useContext(ElementItemContext);
    const { kitId, isCollapsed } = item;

    const [lineWidth, tailWidth] = useItemWidth();

    return (
        <>
            {kitId && (
                <div
                    className={classNames('calendar-item-icon-container', {
                        compact: displayType === CalendarDisplayType.COMPACT,
                    })}
                    style={{
                        height: displayType === CalendarDisplayType.COMPACT ? CALENDAR_LINE_HEIGHT_COMPACT : CALENDAR_LINE_HEIGHT_NORMAL,
                    }}
                >
                    <Icon component={isCollapsed ? IconAngleUp : IconAngleDown} className="calendar-item-icon" />
                </div>
            )}
            <div className={classNames('rct-item-content', 'calendar-item-content')}>
                <ElementsLineBorders lineWidth={lineWidth} />
                <ElementsLineContent lineWidth={lineWidth} tailWidth={tailWidth} />
            </div>
        </>
    );
};

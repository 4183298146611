import React, { CSSProperties, FC, useContext } from 'react';
import { LineIconCircle } from '../../../LineIconCircle/LineIconCircle';
import { StringUtils } from '../../../../../../../../../../../../../core/utils/stringUtils';
import { WorkPlanningItemContext } from '../../../../../../../../context/CalendarItemContext';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';

interface WorkPlanningsInnerContentProps {
    backgroundColor: string | undefined;
}

export const WorkPlanningsInnerContent: FC<WorkPlanningsInnerContentProps> = (props) => {
    const { backgroundColor } = props;
    const { item, itemContext } = useContext(WorkPlanningItemContext);
    const { visibleTimeStart } = useContext(TimeRangeContext);
    const {
        record: { professionShortName, name, numberOfCrew },
        isDraft,
        start_time,
    } = item;
    const borderColor: CSSProperties['borderColor'] = isDraft ? CalendarColors.staticColors.TEXT_COLOR : undefined;
    const borderStyle: CSSProperties['borderStyle'] = isDraft ? 'dashed' : 'solid';

    const itemWidth = itemContext.dimensions.width;

    const isStartAfterLeftBoundary = +start_time > visibleTimeStart;

    return (
        <>
            <LineIconCircle
                backgroundColor={backgroundColor}
                itemWidth={itemWidth}
                isDraft={isDraft}
                style={{
                    borderColor,
                    borderStyle,
                    visibility: isStartAfterLeftBoundary ? 'visible' : 'hidden',
                }}
            />
            <span className={'line-main-block-content'}>
                {numberOfCrew} x {StringUtils.deleteSpaces(name)}
                <span style={{ fontWeight: 400 }}>: {professionShortName}</span>
            </span>
        </>
    );
};

import React from 'react';
import { getImagePath } from '../../../../../../shared/util/utils';

import { Icon } from 'antd';
import Slider from 'react-slick';
import './ProductImages.less';
import { ImageObj } from '../../../../../../server';

interface IState {
    currentImageIndex: number;
    selectedImage?: ImageObj;
}

interface IProps {
    images?: Array<ImageObj>;
    onChange: (ImageObj) => void;
    page?: 'kit';
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <Icon type={'right'} className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <Icon type={'left'} className={className} style={{ ...style }} onClick={onClick} />;
}

const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    //variableWidth: true,
    centerMode: false,
    swipeToSlide: false,
    draggable: false,
    responsive: [
        {
            breakpoint: 2000,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 1750,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3, //4
            },
        },
        {
            breakpoint: 1360,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 660,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 560,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            },
        },
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
};

class ProductImages extends React.PureComponent<IProps, IState> {
    state = {
        currentImageIndex: 0,
        selectedImage: undefined,
    };

    onPrevClick = () => {
        let index = this.state.currentImageIndex - 1;
        if (this.props.images && index < 0) index = 0;
        this.setState({ currentImageIndex: index });
    };

    onNextClick = () => {
        let index = this.state.currentImageIndex + 1;
        if (this.props.images && index >= this.props.images.length) index = this.props.images.length - 1;
        this.setState({ currentImageIndex: index });
    };

    onItemClick = (image: ImageObj) => {
        this.setState({
            selectedImage: image,
        });
        this.props.onChange(image);
    };

    render() {
        let images;

        if (this.props.images && this.props.images.length) images = this.props.images;

        let selectedImage = this.state.selectedImage;
        if (!selectedImage && images && images[0]) selectedImage = images[0];

        if (this.props.page === 'kit') {
            settings.responsive = [
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3, //4
                    },
                },
                {
                    breakpoint: 1360,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 5,
                    },
                },
                {
                    breakpoint: 760,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 660,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ];
        }

        return images ? (
            <div>
                <Slider {...settings}>
                    {images.map((image) => (
                        <div key={image.id} onClick={() => this.onItemClick(image)}>
                            {selectedImage === image ? (
                                <img alt={''} className={'currentImg'} src={getImagePath(image, '-x400')} />
                            ) : (
                                <img alt={''} style={{}} src={getImagePath(image, '-x400')} />
                            )}
                        </div>
                    ))}
                </Slider>
            </div>
        ) : null;
    }
}

export default ProductImages;

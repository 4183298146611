import React from "react";
import {Avatar} from "../avatar/avatar";
import {Icon} from "antd";
import {IconChartArea} from "../icons/IconChartArea";
import {IconPenSquare} from "../icons/IconPenSquare";

interface IProps {
    hideArchive?: boolean;
    style?: React.CSSProperties;
    avatarSize: 'big' | 'medium';
    edit?: Boolean;
    gridEdit?: () => void;
    title?: string;
    message?: string;
    iconStyle?: React.CSSProperties;
    avatarStyle?: React.CSSProperties;
    requiredNotSet?: boolean
}

export const ChartAreaNotGeneratedBlock = (props: IProps) => {

    return (
        <div
            className={'rr-grid-notfound-block'}
            style={props.style}>
            <Avatar style={props.avatarStyle}
                    className={props.avatarSize === 'big' ? 'rr-avatar-big' : 'rr-avatar-medium'}>
                <Icon style={props.iconStyle}
                      component={props.requiredNotSet === true ? IconPenSquare : IconChartArea}/>
            </Avatar>
            <div className={'rr-grid-notfound-block-title'}>
                {
                    props.title
                }
            </div>
            <div className={'rr-grid-notfound-block-message'}>
                {
                    props.message
                }
            </div>
        </div>
    );
};


ChartAreaNotGeneratedBlock.defaultProps = {
    avatarSize: 'big'
};

import {ColumnTypes, TableColumn} from "./Table";
import {GRID_ACTIONS_COLUMN_WIDTH} from "../../config/constants";
import React from "react";
import {SystemIcon, SystemIconType} from "../v2/systemIcon/systemIcon";

/**
 * Создать описание для колонки с действиями
 * @param render
 */
export function createActionsColumn<T extends object>(render: (value: any, rowData: T, onAction: (rowData: T, action: string) => void) => void): TableColumn<T> {
    return (
        {
            title: <SystemIcon type={SystemIconType.actions} style={{display: 'block'}}/>,
            type: ColumnTypes.CustomRender,
            minWidth: GRID_ACTIONS_COLUMN_WIDTH,
            width: GRID_ACTIONS_COLUMN_WIDTH,
            maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
            resizable: false,
            sortable: false,
            className: 'actions',
            headerClassName: 'actions',
            render: render
        }
    );
}

import { Button, Icon, Popover } from 'antd';
import { IconArchive, IconEdit, IconIdCard, IconLevelUp, IconTrash } from '../../../../../../../../components/icons';
import { EntityDrawerArchiveBadge } from '../../../../../../../../components/v2/entityDrawer/components/ArchiveBadge';
import { RoundButton } from '../../../../../../../../components';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { AdditionalEntityActionsButton } from '../../../../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import React, { FC, useState } from 'react';
import { ContactInfoRead } from '../../../../../../../../server';
import { ContactDrawerProps } from '../ContactDrawer/ContactDrawer';

interface ContactDrawerHeaderProps extends Pick<ContactDrawerProps, 'onClose' | 'editContact' | 'deleteContact' | 'archiveContact'> {
    contact: ContactInfoRead | undefined;
    isLoading: boolean;
}

export const ContactDrawerHeader: FC<ContactDrawerHeaderProps> = (props) => {
    const { contact, editContact, deleteContact, archiveContact, onClose, isLoading } = props;

    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);

    return (
        <div className={'contact-drawer'}>
            <div className={'contact-title-block'}>
                <Icon className={'title-icon'} component={IconIdCard} />
                <span className={'title'}>Контакт</span>
                {contact?.archive ? <EntityDrawerArchiveBadge /> : undefined}
            </div>
            <div>
                {editContact ? (
                    <RoundButton
                        colorScheme={'default'}
                        onClick={() => {
                            if (contact) {
                                editContact(contact);
                            }
                        }}
                        disabled={isLoading}
                    >
                        <Icon component={IconEdit} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                    </RoundButton>
                ) : undefined}
                <span style={{ marginLeft: '12px' }}>
                    <Popover
                        onVisibleChange={(visible) => {
                            setActionPopoverVisible(visible);
                        }}
                        visible={actionPopoverVisible}
                        overlayClassName={'rr-grid-actions-popover'}
                        autoAdjustOverflow
                        arrowPointAtCenter
                        placement="bottomLeft"
                        trigger="click"
                        content={
                            <>
                                <div className={'rr-grid-actions-popover-header1'} />
                                <div
                                    className={'rr-grid-actions-popover-content'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionPopoverVisible(false);
                                    }}
                                >
                                    <>
                                        {deleteContact ? (
                                            <Button
                                                disabled={!contact?.deleteable}
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    if (contact) {
                                                        deleteContact(contact).then(() => onClose());
                                                    }
                                                }}
                                            >
                                                <Icon style={{ color: '#F4516C' }} component={IconTrash} />
                                                <span>Удалить</span>
                                            </Button>
                                        ) : undefined}
                                        {contact?.archivable && !contact.archive && archiveContact ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveContact(contact);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-TO_ARCHIVE-color'} component={IconArchive} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                        {contact?.archive && archiveContact ? (
                                            <Button
                                                block
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActionPopoverVisible(false);
                                                    archiveContact(contact);
                                                }}
                                            >
                                                <Icon className={'rr-entity-action-FROM_ARCHIVE-color'} component={IconLevelUp} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}</span>
                                            </Button>
                                        ) : undefined}
                                    </>
                                </div>
                            </>
                        }
                    >
                        {<AdditionalEntityActionsButton disabled={isLoading} />}
                    </Popover>
                </span>
            </div>
        </div>
    );
};

import { PureComponent } from 'react';
import { BaseCategoriesSelect } from '../../../../components/baseCategoriesSelect/baseCategoriesSelect';
import { IRootState } from '../../../../shared/reducers';
import { connect, ConnectedProps } from 'react-redux';
import './CalendarCategoriesSelect.less';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import { CategoryTypeCodeEnum } from '../../../../server';

interface CalendarSelectProps {
    onChange?: (value: any, label: any, extra: any) => void;
    value?: string | number | Array<any>;
    placeholder?: string;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

class _CalendarCategoriesSelect extends PureComponent<CalendarSelectProps & StoreProps, {}> {
    componentDidMount() {
        this.props.loadCategories(this.props.businessAccountId, CategoryTypeCodeEnum.PRODUCT);
    }

    render() {
        return <BaseCategoriesSelect {...this.props} isHighlightValue={false} dropdownClassName={'calendar-categories-select-dropdown'} />;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
    categories: storeState.entities.categories.products || [],
});

const mapDispatchToProps = {
    loadCategories,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const CalendarCategoriesSelect = connector(_CalendarCategoriesSelect);

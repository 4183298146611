import React, { FC } from 'react';
import { DownloadDocumentPopover, DownloadDocumentPopoverProps } from '../../DownloadDocumentPopover';
import { DocumentTypeCodeEnum, FileTypeCodeEnum } from '../../../../../server';
import { Icon } from 'antd';
import { IconMoneyCheckSolid } from '../../../../icons';

interface ExportPricePopoverProps {
    onDownload: DownloadDocumentPopoverProps<FileTypeCodeEnum.PDFTYPE>['onDownload'];
    visible?: boolean;
    disabled?: boolean;
}

export const ExportBillPopover: FC<ExportPricePopoverProps> = (props) => {
    const { visible, disabled, onDownload, children } = props;

    return (
        <DownloadDocumentPopover
            documentType={DocumentTypeCodeEnum.BILL}
            availableFileTypes={[FileTypeCodeEnum.PDFTYPE]}
            storageKey={'price'}
            withTemplate
            title={
                <>
                    <Icon style={{ fontSize: 24, marginRight: 10 }} component={IconMoneyCheckSolid} />
                    <div>Скачать счёт</div>
                </>
            }
            onDownload={onDownload}
            outerVisible={disabled ? false : visible}
            showLegalDetails
        >
            {children}
        </DownloadDocumentPopover>
    );
};

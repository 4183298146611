import React, { CSSProperties } from 'react';
import { DataDisplayItemProps } from './dataDisplayItemProps';
import { DataDisplayGroup } from './dataDisplayGroup';
import classNames from 'classnames';
import './dataDisplay.less';

interface DataDisplayBlockProps {
    data?: (DataDisplayItemProps[] | null | undefined | '' | false | 0)[];
    paddings?: boolean;
    className?: string;
    style?: CSSProperties;
}

export const DataDisplayBlock = ({ data, paddings, className, style }: DataDisplayBlockProps) => {
    return data && data.length > 0 ? (
        <div className={classNames('rr-dd-b', 'rr-dd-b-bb', className)} style={style}>
            <DataDisplayGroup data={data} gutter={paddings !== false ? undefined : 0} paddings={paddings} />
        </div>
    ) : null;
};

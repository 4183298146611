import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, IntlShape } from 'react-intl';
import { LocalizationEnum } from './index';
import { CustomFieldDataTypeCodeEnum, OperationTypeCodeEnum, RentStateCodeEnum } from '../server/api';
import { locales } from './locales';
import { getStoreState } from '../../index';
import { DEFAULT_LOCALE } from '../config/constants';

/**
 *
 * @param id
 * @param values
 * @param tagName - Если надо что б было без тэга, нужно передать Fragment
 */
export const getFormattedMessageComponent = (
    id: LocalizationEnum | string,
    values?: Record<string, any>,
    tagName?: React.ElementType<any>
): React.ReactElement => {
    if (!id) return <></>;
    else if (!/^[0-9a-zA-Z._-]+$/.test(id)) return <>{id}</>;
    else return <FormattedMessage id={id} tagName={tagName} values={values} />;
};

/**
 *
 * @param id
 * @param values
 * @param tagName
 */
export const getFormattedHTMLMessageComponent = (
    id: LocalizationEnum | string,
    values?: Record<string, any>,
    tagName?: React.ElementType<any>
): React.ReactElement => {
    if (!id) return <></>;
    else if (!/^[0-9a-zA-Z._-]+$/.test(id)) return <>{id}</>;
    else return <FormattedHTMLMessage id={id} tagName={tagName} values={values} />;
};

/**
 * Предполагается это заменой двух методов сверху
 * @param id
 * @param tagName
 * @param values
 * @param withHTML
 */
export const localize = (
    id: LocalizationEnum,
    tagName: React.ElementType<any> = React.Fragment,
    values?: Record<string, any>,
    withHTML?: boolean
): React.ReactElement => {
    if (!id) return <></>;
    else if (!/^[0-9a-zA-Z._-]+$/.test(id)) {
        return <>{id}</>;
    } else {
        if (withHTML) return <FormattedHTMLMessage id={id} tagName={tagName} values={values} />;
        else return <FormattedMessage id={id} tagName={tagName} values={values} />;
    }
};

/**
 *
 * @param intl
 * @param id
 * @param tagName
 * @param values
 * @param withHTML
 */
export const localizeIntl = (
    intl: IntlShape,
    id: LocalizationEnum,
    tagName: React.ElementType<any> = React.Fragment,
    values?: Record<string, any>,
    withHTML?: boolean
): React.ReactNode | string => {
    if (!id) return '';
    else if (!/^[0-9a-zA-Z._-]+$/.test(id)) return id;
    else {
        if (withHTML) return intl.formatHTMLMessage({ id: id }, values);
        else return intl.formatMessage({ id: id }, values);
    }
};

/**
 *
 * @param code
 * @param intl
 */
export const getOperationTypeByCode = (code?: OperationTypeCodeEnum, intl?: IntlShape): React.ReactNode | string => {
    let type;
    if (code === OperationTypeCodeEnum.ORDER) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__ORDER;
    else if (code === OperationTypeCodeEnum.BOOK) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__BOOK;
    else if (code === OperationTypeCodeEnum.RENT) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__GIVE_AWAY;
    else if (code === OperationTypeCodeEnum.PROLONG) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__PROLONG;
    else if (code === OperationTypeCodeEnum.RETURN) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__RETURN;
    else if (code === OperationTypeCodeEnum.RETURNBROKEN) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__RETURN_BROKEN;
    else if (code === OperationTypeCodeEnum.LOSTNORETURN) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__LOST_NO_RETURN;
    else if (code === OperationTypeCodeEnum.CANCEL) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CANCEL;
    else if (code === OperationTypeCodeEnum.CORRECT) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CORRECT;
    else if (code === OperationTypeCodeEnum.DRAFT) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT;
    else if (code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT)
        type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_ACCEPT_SHIPMENT;
    else if (code === OperationTypeCodeEnum.SUBRENTDRAFT) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_DRAFT;
    else if (code === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT)
        type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_BOOK_SHIPMENT;
    else if (code === OperationTypeCodeEnum.SUBRENTCANCEL) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_CANCEL;
    else if (code === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER)
        type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_RETURN_TO_SHIPPER;
    else if (code === OperationTypeCodeEnum.SUBRENTPROLONG) type = LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_PROLONG;
    else {
        console.error(`code "${code}" not found in OperationTypeCodeEnum`);
    }
    return intl ? localizeIntl(intl, type) : localize(type);
};

/**
 *
 * @param code
 */
export const getRentElementStateByCode = (code?: RentStateCodeEnum): React.ReactElement => {
    let type;
    if (code === RentStateCodeEnum.ORDERED) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED;
    else if (code === RentStateCodeEnum.BOOKED) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED;
    else if (code === RentStateCodeEnum.RENT) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT;
    else if (code === RentStateCodeEnum.RETURNED) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED;
    else if (code === RentStateCodeEnum.RETURNEDBROKEN) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED_BROKEN;
    else if (code === RentStateCodeEnum.LOSTDURINGRENT) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__LOST_DURING_RENT;
    else if (code === RentStateCodeEnum.CANCELED) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__CANCELED;
    else if (code === RentStateCodeEnum.DRAFT) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__DRAFT;
    else if (code === RentStateCodeEnum.SUBRENT) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT;
    else if (code === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_SHIPMENT_BOOKED;
    else if (code === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER)
        type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_RETURNED_TO_SHIPPER;
    else if (code === RentStateCodeEnum.SUBRENTCANCELED) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_CANCELED;
    else if (code === RentStateCodeEnum.SUBRENTDRAFT) type = LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_DRAFT;
    else {
        console.error(`code "${code}" not found in RentStateCodeEnum`);
    }
    return localize(type);
};

/**
 * Возвращает название типа кастомного поля по типу
 * @param code
 * @param multiple
 */
export const getCustomFieldTypeByCode = (code?: CustomFieldDataTypeCodeEnum, multiple: boolean = false): React.ReactElement => {
    let type;
    if (multiple && code === CustomFieldDataTypeCodeEnum.STRING) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__MULTIPLE__STRING;
    else if (multiple && code === CustomFieldDataTypeCodeEnum.LONG) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__MULTIPLE__LONG;
    else if (multiple && code === CustomFieldDataTypeCodeEnum.DOUBLE) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__MULTIPLE__DOUBLE;
    else if (multiple && code === CustomFieldDataTypeCodeEnum.DATE) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__MULTIPLE__DATE;
    else if (multiple && code === CustomFieldDataTypeCodeEnum.BOOLEAN) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__MULTIPLE__BOOLEAN;
    else if (code === CustomFieldDataTypeCodeEnum.STRING) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__STRING;
    else if (code === CustomFieldDataTypeCodeEnum.LONG) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__LONG;
    else if (code === CustomFieldDataTypeCodeEnum.DOUBLE) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__DOUBLE;
    else if (code === CustomFieldDataTypeCodeEnum.DATE) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__DATE;
    else if (code === CustomFieldDataTypeCodeEnum.BOOLEAN) type = LocalizationEnum.ASPECT__CUSTOM_FIELD__BOOLEAN;
    else if (code === CustomFieldDataTypeCodeEnum.ENTITY) type = 'Ссылка на сущность';
    else {
        console.error(`code "${code}" not found in CustomFieldDataTypeCodeEnum`);
    }
    return localize(type);
};

/**
 * Метод возвращает массив стран, в зависимости от текущей локали в oidc
 */
export const getCountriesByCurrentLocale = () => {
    let oidc = getStoreState().oidc;
    let userLocale = oidc && oidc.user && oidc.user.profile.locale ? oidc.user.profile.locale : navigator.language || DEFAULT_LOCALE;
    userLocale = userLocale.replace('-', '_').substr(0, 2);

    for (let k in locales) {
        if (locales[k].locale === userLocale) {
            return locales[k].countries;
        }
    }
    return [];
};

export const getCalendarLocaleByCurrentLocale = () => {
    let oidc = getStoreState().oidc;
    let userLocale = oidc && oidc.user && oidc.user.profile.locale ? oidc.user.profile.locale : navigator.language;

    for (let k in locales) {
        if (locales[k].locale === userLocale) {
            return locales[k]?.antd_locale?.DatePicker?.lang || {};
        }
    }
    return {};
};

import './statuses-filters.less';
import { LocalizationEnum } from '../../../../localization';
import { SelectProps } from 'antd/lib/select';
import { useEffect } from 'react';
import { useLocalize } from '../../../../core/hooks/useLocalize';
import { Select } from 'antd';
import classNames from 'classnames';

type SelectItem = {
    name: string;
    value: string;
};

export interface StatusesFilterProps extends SelectProps {
    selectItems: (SelectItem | undefined)[];
}

export const StatusesFilter = (props: StatusesFilterProps) => {
    const { selectItems, value: propsValue, ...selectProps } = props;

    const L = useLocalize();

    const disableInput = () => {
        const selects: any = document.querySelectorAll(`.rr-statuses-filter .ant-select-search__field`);
        for (let el of selects) {
            el.setAttribute(`maxlength`, 0);
        }
    };

    const value = typeof propsValue === 'string' ? propsValue.split(',') : propsValue;

    useEffect(() => {
        disableInput();
    }, []);

    return (
        <Select
            {...selectProps}
            value={value}
            className={classNames('rr-statuses-filter', 'rr-select-gray', 'calendar-filters', selectProps.className)}
            defaultActiveFirstOption={false}
            dropdownMatchSelectWidth={false}
            mode="multiple"
            showSearch={false}
            autoClearSearchValue
            allowClear={true}
            dropdownRender={(menu) => <div className={'rr-statuses-filter-dropdown'}>{menu}</div>}
        >
            {selectItems.map((item, index) => {
                return item ? (
                    <Select.Option key={index} value={item.value} title={L(item.name as LocalizationEnum)}>
                        <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                        <span className="calendar-multi-select">{L(item.name as LocalizationEnum)}</span>
                    </Select.Option>
                ) : (
                    <Select.Option key={index} disabled={true}>
                        <div className="rr-status-filter-separator">
                            <div style={{ width: '100%', borderBottom: '1px solid #ccc' }}></div>
                        </div>
                    </Select.Option>
                );
            })}
        </Select>
    );
};

import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { formatSliderRangeValue, SliderRange, SliderRangeFormatStyle, SliderRangeProps } from '../SliderRange/SliderRange';
import './styles.less';
import classNames from 'classnames';
import {
    InlineNumberInput,
    InlineNumberInputColorTheme,
    InlineNumberInputProps,
} from '../../inlineInputs/InlineNumberInput/soft/InlineNumberInput';
import { SliderValue } from 'antd/lib/slider';
import { createPortal } from 'react-dom';
import { usePosition } from './hooks/usePosition';

export interface SliderRangeWithManualInputProps extends SliderRangeProps {
    formatStyle: SliderRangeFormatStyle;
    min: number;
    max: number;
    theme?: InlineNumberInputColorTheme;
    inputProps?: InlineNumberInputProps['inputProps'];
}

export const SliderRangeWithManualInput: FC<SliderRangeWithManualInputProps> = memo((props) => {
    const { value: sliderValue, onChange, theme = InlineNumberInputColorTheme.LIGHT, inputProps, ...sliderProps } = props;
    const { min, max, formatStyle, step } = sliderProps;

    const leftMarkContainerRef = useRef<HTMLDivElement>(null);
    const leftMarkInputRef = useRef<HTMLInputElement>(null);
    const [isLeftMarkOpen, setIsLeftMarkOpen] = useState(false);

    const rightMarkContainerRef = useRef<HTMLDivElement>(null);
    const rightMarkInputRef = useRef<HTMLInputElement>(null);
    const [isRightMarkOpen, setIsRightMarkOpen] = useState(false);

    const inputContainerRef = useRef<HTMLDivElement>(null);
    const sliderCoordinates = usePosition(inputContainerRef);

    const onChangeProxyCallback = useCallback(
        (value: SliderValue) => {
            onChange?.(value);
        },
        [onChange]
    ) satisfies typeof onChange;

    const setLeftMarkValue = useCallback(() => {
        const inputValue = leftMarkInputRef.current?.value;
        if (inputValue && sliderValue) {
            if (inputValue > sliderValue[1]) {
                onChangeProxyCallback([sliderValue[1], Math.min(Number(inputValue), max)]);
            } else {
                onChangeProxyCallback([Math.max(Number(inputValue), min), sliderValue[1]]);
            }
        }
        setIsLeftMarkOpen(false);
    }, [max, min, onChangeProxyCallback, sliderValue]);

    const leftMarkCallback = useCallback(
        (event: MouseEvent | globalThis.KeyboardEvent) => {
            if (leftMarkContainerRef.current && !leftMarkContainerRef.current.contains(event.target as Node)) {
                setLeftMarkValue();
            }
        },
        [setLeftMarkValue]
    );

    const setRightMarkValue = useCallback(() => {
        const inputValue = rightMarkInputRef.current?.value;
        if (inputValue && sliderValue) {
            if (inputValue < sliderValue[0]) {
                onChangeProxyCallback([Math.max(Number(inputValue), min), sliderValue[0]]);
            } else {
                onChangeProxyCallback([sliderValue[0], Math.min(Number(inputValue), max)]);
            }
        }
        setIsRightMarkOpen(false);
    }, [max, min, onChangeProxyCallback, sliderValue]);

    const rightMarkCallback = useCallback(
        (event: MouseEvent | globalThis.KeyboardEvent) => {
            if (rightMarkContainerRef.current && !rightMarkContainerRef.current.contains(event.target as Node)) {
                setRightMarkValue();
            }
        },
        [setRightMarkValue]
    );

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            leftMarkCallback(event);
            rightMarkCallback(event);
        },
        [leftMarkCallback, rightMarkCallback]
    );

    const onKeyPress = useCallback(
        (event: globalThis.KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (isLeftMarkOpen) setLeftMarkValue();
                if (isRightMarkOpen) setRightMarkValue();
            }

            if (event.key === 'Escape') {
                if (isLeftMarkOpen) setIsLeftMarkOpen(false);
                if (isRightMarkOpen) setIsRightMarkOpen(false);
            }
        },
        [isLeftMarkOpen, isRightMarkOpen, setLeftMarkValue, setRightMarkValue]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', onKeyPress);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', onKeyPress);
        };
    }, [handleClickOutside, onKeyPress]);

    return (
        <div
            className={classNames('sliders-range-manual', {
                'disable-left-mark': isLeftMarkOpen,
                'disable-right-mark': isRightMarkOpen,
            })}
        >
            {!isLeftMarkOpen && (
                <div
                    className={classNames('anchor', 'left')}
                    onClick={() => {
                        setIsLeftMarkOpen(true);
                    }}
                >
                    {sliderValue?.[0] != null && <span>{formatSliderRangeValue(sliderValue?.[0], formatStyle)}</span>}
                </div>
            )}
            {!isRightMarkOpen && (
                <div
                    className={classNames('anchor', 'right')}
                    onClick={() => {
                        setIsRightMarkOpen(true);
                    }}
                >
                    {sliderValue?.[1] != null && <span>{formatSliderRangeValue(sliderValue?.[1], formatStyle)}</span>}
                </div>
            )}
            <div ref={inputContainerRef} className={'input-container'}>
                {isLeftMarkOpen &&
                    sliderCoordinates &&
                    createPortal(
                        <InlineNumberInput
                            inputProps={{
                                ref: leftMarkInputRef,
                                defaultValue: sliderValue?.[0],
                                min: min,
                                max: max,
                                step: step,
                                theme,
                                formatStyle,
                                ...inputProps,
                            }}
                            containerProps={{
                                className: classNames('inline-number-input', 'left', 'absolute'),
                                ref: leftMarkContainerRef,
                                style: {
                                    top: sliderCoordinates.topOffset - 4,
                                    left: sliderCoordinates.leftOffset - 20,
                                },
                            }}
                            focusOnMount
                        />,
                        document.body
                    )}
                {isRightMarkOpen &&
                    sliderCoordinates &&
                    createPortal(
                        <InlineNumberInput
                            inputProps={{
                                ref: rightMarkInputRef,
                                defaultValue: sliderValue?.[1],
                                min: min,
                                max: max,
                                step: step,
                                theme,
                                formatStyle,
                                ...inputProps,
                            }}
                            containerProps={{
                                className: classNames('inline-number-input', 'right', 'absolute'),
                                ref: rightMarkContainerRef,
                                style: {
                                    top: sliderCoordinates.topOffset - 4,
                                    right: sliderCoordinates.rightOffset - 20,
                                },
                            }}
                            focusOnMount
                        />,
                        document.body
                    )}
            </div>
            <SliderRange {...sliderProps} value={sliderValue} onChange={onChangeProxyCallback} />
        </div>
    );
});

import React, { useCallback } from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { LocationRecord } from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { locationsApi } from '../../../../modules/main/logistics/tabs/locations/api/locations.api';

export interface LocationSelectProps extends BaseSelectBaseProps<LocationRecord> {}

const sortBy: string = 'lastUpdateDate';
const sortOrder = 'DESC';

export const LocationSelectFC = ({ ...props }: LocationSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);
    const [fetchLocations] = locationsApi.useLazyListLocationsQuery();

    const fetchRecordsById = useCallback(
        async (id: BaseSelectValue) => {
            const _id = Array.isArray(id) ? id[0] : id;
            const response = await fetchLocations(
                {
                    businessAccountId,
                    params: { limit: 1, search: undefined, page: 1, sortBy, sortOrder, id: +_id },
                },
                true
            );
            if (response.error) throw response.error;
            return response.data?.entitiesData.records ?? [];
        },
        [businessAccountId, fetchLocations]
    );

    const fetchRecords = useCallback(
        async (startFrom: number, limit: number, search?: string) => {
            const response = await fetchLocations(
                {
                    businessAccountId,
                    params: { limit, search, page: startFrom / limit + 1, sortBy, sortOrder },
                },
                true
            );
            if (response.error) throw response.error;
            return response.data?.entitiesData.records ?? [];
        },
        [businessAccountId, fetchLocations]
    );

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={fetchRecordsById}
            fetchRecords={fetchRecords}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.shortName,
                children: record.shortName,
            })}
        />
    );
};

export class LocationSelect extends React.Component<LocationSelectProps> {
    render() {
        return <LocationSelectFC {...this.props} />;
    }
}

import { CustomFieldDataTypeCodeEnum, CustomFieldProcessingTypeCodeEnum } from '../../../../server/api';
import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';

export const filters: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__TYPE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Select,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                values: [
                    { name: localize(LocalizationEnum.ASPECT__CUSTOM_FIELD__BOOLEAN), value: CustomFieldDataTypeCodeEnum.BOOLEAN },
                    { name: localize(LocalizationEnum.ASPECT__CUSTOM_FIELD__DATE), value: CustomFieldDataTypeCodeEnum.DATE },
                    { name: localize(LocalizationEnum.ASPECT__CUSTOM_FIELD__DOUBLE), value: CustomFieldDataTypeCodeEnum.DOUBLE },
                    { name: localize(LocalizationEnum.ASPECT__CUSTOM_FIELD__LONG), value: CustomFieldDataTypeCodeEnum.LONG },
                    { name: localize(LocalizationEnum.ASPECT__CUSTOM_FIELD__STRING), value: CustomFieldDataTypeCodeEnum.STRING },
                    { name: 'Ссылка на сущность', value: CustomFieldDataTypeCodeEnum.ENTITY },
                ],
                onChange: (value: CustomFieldDataTypeCodeEnum | undefined, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        processingType: undefined,
                    });
                },
            },
            {
                label: 'Формат',
                id: 'processingType',
                type: FormItemType.Select,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                values: (form: WrappedFormUtils) => {
                    const typeCode = form.getFieldValue('typeCode');

                    const longFormats = [
                        { value: CustomFieldProcessingTypeCodeEnum.LONGSUM, name: 'Число' },
                        { value: CustomFieldProcessingTypeCodeEnum.MONEYSUM, name: 'Сумма денег' },
                    ];

                    const entityFormats = [
                        { value: CustomFieldProcessingTypeCodeEnum.USERLINK, name: 'Пользователь' },
                        { value: CustomFieldProcessingTypeCodeEnum.CONTACTLINK, name: 'Контакт' },
                        { value: CustomFieldProcessingTypeCodeEnum.SUBRENTLINK, name: 'Контрагент' },
                        { value: CustomFieldProcessingTypeCodeEnum.PROJECTLINK, name: 'Проект' },
                        { value: CustomFieldProcessingTypeCodeEnum.RENTERLINK, name: 'Поставка' },
                    ];

                    if (typeCode === CustomFieldDataTypeCodeEnum.LONG) return longFormats;
                    if (typeCode === CustomFieldDataTypeCodeEnum.ENTITY) return entityFormats;

                    if (typeCode == null) return [...longFormats, ...entityFormats];

                    return [];
                },
                disabled: (getFieldValue) => {
                    const typeCode = getFieldValue('typeCode');

                    return !(typeCode === CustomFieldDataTypeCodeEnum.LONG || typeCode === CustomFieldDataTypeCodeEnum.ENTITY);
                },
            },
            {
                label: LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__HIDE_STANDARD_FIELDS,
                id: 'hideStandardFields',
                type: FormItemType.Switch,
            },
        ],
    },
];

import React, { ComponentProps, CSSProperties, FC, ReactElement } from 'react';
import { Icon } from 'antd';
import { IconCalendar } from '../../icons';
import { LocalizationEnum, localize } from '../../../localization';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface LinkToCalendarProps extends Omit<ComponentProps<'div'>, 'onClick'> {
    calendarPath: string;
    withText?: boolean;
    text?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    icon?: ReactElement;
    linkStyle?: CSSProperties;
}

export const LinkToCalendar: FC<LinkToCalendarProps> = (props) => {
    const { calendarPath, withText = true, text, onClick, icon, linkStyle, ...divProps } = props;

    return (
        <Link to={calendarPath} onClick={onClick} style={linkStyle}>
            <div {...divProps} className={classNames('rr-page-header-right', divProps.className)}>
                {icon ?? <Icon component={IconCalendar} />}
                {withText && (
                    <div>
                        <div>{text ?? localize(LocalizationEnum.ASPECT__MAIN_MENU__CALENDAR)}</div>
                    </div>
                )}
            </div>
        </Link>
    );
};

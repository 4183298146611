import React from 'react';
import {connect} from 'react-redux';
import './categories-page.less';
import {IRootState} from '../../../shared/reducers';
import {
    createCategory,
    createCategory1,
    editCategory,
    ICategoriesState,
    showDeleteConfirmModal,
    updateCategory,
} from './reducers/categories.reducer';
import {Button, Card, Col, Icon, Row} from 'antd';
import DynamicForm, {FormFieldsGroup, FormItemType} from '../../../components/dynamicForm/DynamicForm';
import {IconPencil, IconPlus, IconSitemap, IconTrash} from '../../../components/icons';
import {Spin} from '../../../components';
import {CategoryInfoCreate, CategoryInfoUpdate, CategorySortOrderEnum, CategoryTypeCodeEnum,} from '../../../server';
import RoundButton from '../../../components/button/roundButton';
import {Link} from 'react-router-dom';
import FormValue from '../../../components/formValue/FormValue';
import {LocalizationEnum, localize} from '../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {getStoreState} from '../../../../index';
import {getStateFromPath} from '../../../shared/util/utils';
import {MAX_DEEP_CATEGORIES} from '../../../config/constants';
import {Avatar} from '../../../components/avatar/avatar';
import {CategoryBlockSelect} from './category-block-select';

// dynamic form fields
const categoryCreateFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                id: 'elementId',
                type: FormItemType.Hidden,
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__NAME,
                id: 'name',
                type: FormItemType.String,
                required: true,
                requiredMessage: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__FIELD_IS_REQUIRED),
                maxLength: 50,
            },
            {
                label: LocalizationEnum.PAGE__CATEGORIES__PARENT_CATEGORY,
                id: 'parentCategoryName',
                type: FormItemType.Component,
                required: false,
                component: CategoryBlockSelect,
                placeholder: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT),
                componentProps: (props) => {
                    return {
                        operationName: 'listCategories',
                        nameField: 'name',
                        elementId: props.getFieldValue('elementId'),
                        typeCode:
                            !('tab' in getStateFromPath(getStoreState().router.location.search)) ||
                            getStateFromPath(getStoreState().router.location.search).tab === 'PRODUCT'
                                ? 'PRODUCT'
                                : 'RENTER',
                        multiple: false,
                        maxHeight: 240,
                        getPopupContainer: () => document.querySelector('body'),
                        categories:
                            !('tab' in getStateFromPath(getStoreState().router.location.search)) ||
                            getStateFromPath(getStoreState().router.location.search).tab === 'PRODUCT'
                                ? getStoreState().entities.categories.products || []
                                : getStoreState().entities.categories.renters || [],
                        dropdownStyle: { maxWidth: 300, maxHeight: 240 },
                        labelInValue: true,
                        dropdownClassName: 'rr-base-categories-select_dropdown_category-block',
                        disabledValues: [props.getFieldValue('elementId')],
                    };
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                required: false,
                maxLength: 2000,
            },
        ],
    },
];

enum CategoryBlockViewMode {
    VIEW = 'VIEW',
    EDIT = 'HIDDEN',
    CREATE = 'CREATE',
    NO_DATA = 'NO_DATA',
}

interface IState {}

interface IProps extends StateProps, DispatchProps, ICategoriesState, WrappedComponentProps {
    typeCode: CategoryTypeCodeEnum;
    height?: number;
    level?: number;
    entities?: { [k: string]: any }[] | null;
    sortValue: CategorySortOrderEnum;
    expandAllParentsCategoriesCallback: (categoryInfo: CategoryInfoCreate) => void;
}

class _CategoryBlock extends React.PureComponent<IProps, IState> {
    _formRef;

    constructor(props) {
        super(props);

        this.state = {
            mode: CategoryBlockViewMode.VIEW,
        };
    }

    getFormRef = (ref) => {
        this._formRef = ref;
    };

    createCategory = async () => {
        let { categoryInfo } = this.props;
        await this.props.createCategory(this.props.typeCode, categoryInfo);
    };

    onCreate = () => {
        if (this._formRef) {
            this._formRef.validateFieldsAndScroll({ scroll: { offsetTop: 72 } }, (err, values) => {
                if (!err) {
                    let categoryInfo: CategoryInfoCreate = {
                        comment: values.comment !== '' ? values.comment : null,
                        name: values.name,
                        typeCode: this.props.typeCode as CategoryTypeCodeEnum,
                        parentCategoryId: values.parentCategoryName ? values.parentCategoryName.value : undefined,
                        businessAccountId: this.props.businessAccountId,
                        siblingOrder: 0,
                    };

                    this.props.createCategory1(
                        this.props.intl,
                        this.props.businessAccountId,
                        this.props.typeCode,
                        categoryInfo,
                        this.props.sortValue
                    );

                    this.props.expandAllParentsCategoriesCallback(categoryInfo);
                }
            });
        }
    };

    onEdit = () => {
        this.props.editCategory(this.props.typeCode);
    };

    onDelete = () => {
        this.props.showDeleteConfirmModal(
            this.props.typeCode,
            !!(this.props.categoryInfo && this.props.categoryInfo.parentCategoryId)
        );
    };

    onUpdate = () => {
        if (this._formRef) {
            this._formRef.validateFieldsAndScroll({ scroll: { offsetTop: 72 } }, (err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                    // values.parentCategoryName = null;
                    let newData: CategoryInfoUpdate;
                    if (values.parentCategoryName) {
                        const label = values.parentCategoryName.label;
                        if (typeof label !== 'string' && 'props' in label) {
                            values.parentCategoryName.label = values.parentCategoryName.label.props.children[0];
                        }
                        newData = {
                            ...this.props.categoryInfo,
                            ...values,
                            parentCategoryId: values.parentCategoryName ? +values.parentCategoryName.value : undefined,
                        };
                    } else {
                        newData = { ...this.props.categoryInfo, ...values, parentCategoryId: undefined };
                    }

                    if (newData.comment === '') delete newData.comment;

                    if (this.props.categoryInfo && this.props.categoryInfo.businessAccountId !== undefined) {
                        this.props.updateCategory(
                            this.props.intl,
                            this.props.categoryInfo.businessAccountId,
                            this.props.typeCode,
                            this.props.categoryInfo && this.props.categoryInfo.id !== undefined
                                ? this.props.categoryInfo.id
                                : 0,
                            newData,
                            this.props.sortValue
                        );
                    }

                    this.props.expandAllParentsCategoriesCallback(newData);
                }
            });
        }
    };

    render = () => {
        console.log('CategoryBlock render()', this.props);

        let { categoryInfo, editMode, loading, parentCategory } = this.props;
        let iconComponent;
        let iconBgColor;

        let viewMode: CategoryBlockViewMode = CategoryBlockViewMode.CREATE;
        if (categoryInfo) {
            if (editMode) viewMode = CategoryBlockViewMode.EDIT;
            else viewMode = CategoryBlockViewMode.VIEW;
        }

        let initialValues;

        if (viewMode === CategoryBlockViewMode.VIEW /*|| viewMode === CategoryBlockViewMode.NO_DATA*/) {
            iconComponent = <Icon component={IconSitemap} />;
            iconBgColor = '#d3d8f4';
        } else if (viewMode === CategoryBlockViewMode.CREATE) {
            iconComponent = <Icon type={'plus'} />;
            iconBgColor = '#8fe2d1';
            initialValues = {
                parentCategoryName: parentCategory && {
                    value: parentCategory?.id,
                    label: parentCategory?.name,
                },
            };
        } else if (viewMode === CategoryBlockViewMode.EDIT) {
            iconComponent = <Icon component={IconPencil} />;
            iconBgColor = '#9ad1fb';
            initialValues = categoryInfo
                ? {
                      ...categoryInfo,
                      parentCategoryName: categoryInfo &&
                          categoryInfo?.parentCategoryId && {
                              value: categoryInfo?.parentCategoryId,
                              label: categoryInfo['parentCategoryName'],
                          },
                  }
                : {};
        }

        let canAddSubcategory = this.props.level !== undefined && this.props.level < MAX_DEEP_CATEGORIES;

        const addChildElements = (children, childrenElements) => {
            children.forEach((child) => {
                if (child.children) {
                    addChildElements(child.children, childrenElements);
                    if (child.key === (categoryInfo ? categoryInfo.id : '')) {
                        // childrenElements.push(child.key)
                        addChildElements2(child.children, childrenElements);
                    }
                }
            });
        };

        const addChildElements2 = (children, childrenElements) => {
            children.forEach(async (child) => {
                if (child.children) {
                    addChildElements2(child.children, childrenElements);
                    await childrenElements.push(child.key);
                }
            });
        };

        const childrenElements: any = [];

        if (this.props.entities) {
            addChildElements(this.props.entities, childrenElements);
        }

        return (
            <Card
                bordered={false}
                className={'rr-categories-category-card'}
                style={{
                    minHeight: /*this.props.height*/ Math.min(500, window.innerHeight - 110 + 40 + 20 + 6),
                    maxHeight: window.innerHeight - 110,
                    height: this.props.height,
                }}
            >
                <Spin spinning={loading}>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ textAlign: 'center', marginBottom: 32, marginTop: 30 }}>
                            <Avatar size={100} style={{ backgroundColor: iconBgColor, verticalAlign: 'middle' }}>
                                <span style={{ fontSize: 60 }}>{iconComponent}</span>
                            </Avatar>
                        </div>

                        <div style={{ flexGrow: 1, overflowY: 'auto', paddingLeft: 32, paddingRight: 32 }}>
                            <div>
                                {viewMode === CategoryBlockViewMode.VIEW ? (
                                    <>
                                        <Row className={'rr-categories-category-block-row'}>
                                            <Col>
                                                <div className={'rr-label'}>
                                                    {localize(LocalizationEnum.PAGE__CATEGORIES__CATEGORY_NAME)}
                                                </div>
                                                <h5>{categoryInfo ? categoryInfo.name : null}</h5>
                                            </Col>
                                        </Row>

                                        <Row className={'rr-categories-category-block-row'}>
                                            <Col>
                                                <div className={'rr-label'}>
                                                    {localize(LocalizationEnum.PAGE__CATEGORIES__PARENT_CATEGORY)}
                                                </div>
                                                <h5>
                                                    <FormValue
                                                        value={
                                                            categoryInfo && categoryInfo['parentCategoryName']
                                                                ? categoryInfo['parentCategoryName']
                                                                : null
                                                        }
                                                        emptyText={localize(
                                                            LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT
                                                        )}
                                                    />
                                                </h5>
                                            </Col>
                                        </Row>

                                        <Row className={'rr-categories-category-block-row'}>
                                            <Col>
                                                <div className={'rr-label'}>
                                                    {this.props.typeCode === 'PRODUCT'
                                                        ? 'Продуктов и наборов'
                                                        : localize(
                                                              LocalizationEnum.PAGE__CATEGORIES__BLOCK_CARD__COUNTER__RENTERS
                                                          )}
                                                </div>
                                                {categoryInfo ? (
                                                    categoryInfo['directRelatedObjectsCount'] === 0 ? (
                                                        <span
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: 400,
                                                                color: '#aaaaaa',
                                                            }}
                                                        >
                                                            {categoryInfo['directRelatedObjectsCount']}
                                                        </span>
                                                    ) : (
                                                        <h5>
                                                            <Link
                                                                className={'rr-link'}
                                                                to={`/${this.props.businessAccountId}/${
                                                                    this.props.typeCode === 'PRODUCT'
                                                                        ? 'inventory/products'
                                                                        : 'crm/counterparties'
                                                                }?categoryIds=${categoryInfo ? categoryInfo.id : ''}`}
                                                            >
                                                                {categoryInfo
                                                                    ? categoryInfo['directRelatedObjectsCount']
                                                                    : null}
                                                            </Link>
                                                        </h5>
                                                    )
                                                ) : null}
                                            </Col>
                                        </Row>

                                        <Row className={'rr-categories-category-block-row'}>
                                            <Col>
                                                <div className={'rr-label'}>
                                                    {localize(
                                                        LocalizationEnum.PAGE__CATEGORIES__BLOCK_CARD__COUNTER__SUBCATEGORIES
                                                    )}
                                                </div>
                                                {categoryInfo ? (
                                                    categoryInfo['subcategoriesCount'] === 0 ? (
                                                        <span
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: 400,
                                                                color: '#aaaaaa',
                                                            }}
                                                        >
                                                            {categoryInfo['subcategoriesCount']}
                                                        </span>
                                                    ) : (
                                                        <h5> {categoryInfo['subcategoriesCount']} </h5>
                                                    )
                                                ) : null}
                                            </Col>
                                        </Row>

                                        <Row className={'rr-categories-category-block-row'}>
                                            <Col>
                                                <div className={'rr-label'}>
                                                    {this.props.typeCode === 'PRODUCT'
                                                        ? 'Продуктов и наборов в подкатегориях'
                                                        : localize(
                                                              LocalizationEnum.PAGE__CATEGORIES__BLOCK_CARD__COUNTER__RENTERS_IN_SUBCATEGORIES
                                                          )}
                                                </div>
                                                {categoryInfo ? (
                                                    categoryInfo['transitiveRelatedObjectsCount'] === 0 ? (
                                                        <span
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: 400,
                                                                color: '#aaaaaa',
                                                            }}
                                                        >
                                                            {categoryInfo['transitiveRelatedObjectsCount']}
                                                        </span>
                                                    ) : (
                                                        <h5>
                                                            <Link
                                                                className={'rr-link'}
                                                                to={`/${this.props.businessAccountId}/${
                                                                    this.props.typeCode === 'PRODUCT'
                                                                        ? 'inventory/products'
                                                                        : 'crm/counterparties'
                                                                }?categoryIds=${
                                                                    childrenElements
                                                                        ? encodeURIComponent(childrenElements.join(';'))
                                                                        : ''
                                                                }`}
                                                            >
                                                                {categoryInfo['transitiveRelatedObjectsCount']}
                                                            </Link>{' '}
                                                        </h5>
                                                    )
                                                ) : null}
                                            </Col>
                                        </Row>

                                        <Row className={'rr-categories-category-block-row'}>
                                            <Col>
                                                <div className={'rr-label'} style={{ marginBottom: 10 }}>
                                                    {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}
                                                </div>
                                                <div className={'rr-text'}>
                                                    <FormValue
                                                        value={categoryInfo ? categoryInfo.comment : null}
                                                        emptyText={localize(
                                                            LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}

                                {viewMode === CategoryBlockViewMode.EDIT ? (
                                    <>
                                        <DynamicForm
                                            ref={this.getFormRef}
                                            data={categoryCreateFields}
                                            initialValues={{
                                                ...initialValues,
                                                elementId: initialValues.id,
                                            }}
                                        />
                                    </>
                                ) : null}

                                {viewMode === CategoryBlockViewMode.CREATE ? (
                                    <>
                                        <DynamicForm
                                            ref={this.getFormRef}
                                            data={categoryCreateFields}
                                            initialValues={initialValues}
                                        />
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div style={{ padding: 32, paddingBottom: 25 }}>
                            {viewMode === CategoryBlockViewMode.VIEW ? (
                                <>
                                    <Button
                                        type={'link'}
                                        className={'rr-categories-add-subcategory-button'}
                                        disabled={!canAddSubcategory}
                                        onClick={this.createCategory}
                                    >
                                        <Icon component={IconPlus} />
                                        <span>
                                            {localize(LocalizationEnum.PAGE__CATEGORIES__ACTIONS__CREATE_SUBCATEGORY)}
                                        </span>
                                    </Button>

                                    <Row style={{ marginBottom: 12, marginTop: 7 }}>
                                        <Col span={12}>
                                            <Button
                                                type={'link'}
                                                className={'rr-categories-edit-category-button'}
                                                onClick={this.onEdit}
                                            >
                                                <Icon component={IconPencil} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT)}</span>
                                            </Button>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <Button
                                                type={'link'}
                                                className={'rr-categories-delete-category-button'}
                                                onClick={this.onDelete}
                                            >
                                                <Icon component={IconTrash} />
                                                <span>{localize(LocalizationEnum.ASPECT__ACTION__DELETE)}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            ) : null}

                            {viewMode === CategoryBlockViewMode.EDIT ? (
                                <>
                                    <RoundButton colorScheme={'info'} onClick={this.onUpdate} style={{ width: '100%' }}>
                                        {localize(LocalizationEnum.ASPECT__GLOBAL__SAVE, 'span')}
                                    </RoundButton>
                                </>
                            ) : null}

                            {viewMode === CategoryBlockViewMode.CREATE ? (
                                <>
                                    <RoundButton
                                        colorScheme={'success'}
                                        onClick={this.onCreate}
                                        style={{ width: '100%' }}
                                    >
                                        {localize(LocalizationEnum.ASPECT__ACTION__CREATE)}
                                    </RoundButton>
                                </>
                            ) : null}
                        </div>
                    </div>
                </Spin>
            </Card>
        );
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
});

const mapDispatchToProps = { showDeleteConfirmModal, editCategory, updateCategory, createCategory, createCategory1 };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const CategoryBlock = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_CategoryBlock));

import { Icon } from 'antd';
import { ComponentProps, ComponentType, CSSProperties, FC, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CalendarDisplayType } from '../../../../../Calendar/utils/data/calendarConstants';
import './GroupLine.less';

export interface GroupLineProps extends Omit<ComponentProps<'div'>, 'title'> {
    displayType: CalendarDisplayType;
    title: {
        text: ReactNode;
        link?: string;
    };
    subtitle?: {
        text: string;
        link?: string;
    };
    IconComponent?: ComponentType | ReactElement;
}

export const GroupLine: FC<GroupLineProps> = (props) => {
    const { IconComponent, displayType, title, subtitle, ...divProps } = props;

    const isCompact = displayType === CalendarDisplayType.COMPACT;

    const titleClassName = classNames('title', {
        link: Boolean(title.link),
        compact: isCompact,
        hovered: Boolean(divProps.onMouseUp),
    });

    const subtitleClassName = classNames(titleClassName, 'subtitle');

    const titleStyles = IconComponent
        ? undefined
        : ({
              marginLeft: 0,
          } satisfies CSSProperties);

    return (
        <div {...divProps} className={classNames('calendar-group-line', divProps.className)}>
            {IconComponent &&
                ('type' in IconComponent ? (
                    IconComponent
                ) : (
                    <Icon
                        component={IconComponent}
                        className={classNames('icon', {
                            compact: isCompact,
                        })}
                    />
                ))}
            {title.link ? (
                <Link to={title.link} className={titleClassName} style={titleStyles}>
                    {title.text}
                </Link>
            ) : (
                <span className={titleClassName} style={titleStyles}>
                    {title.text}
                </span>
            )}
            {subtitle ? (
                subtitle?.link ? (
                    <Link to={subtitle.link} className={subtitleClassName}>
                        {subtitle.text}
                    </Link>
                ) : (
                    <span className={subtitleClassName}>{subtitle.text}</span>
                )
            ) : null}
        </div>
    );
};

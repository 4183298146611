export const isNumber = (value:any) => {
    return !isNaN(value);
};

export function isOnlyDigits(str: string) {
    return /^\d+$/.test(str);
}

export function removeSymbol(str: string, symbol: string) {
    if (str.includes(symbol)) {
        return str.replace(symbol, '');
    }
    return str;
}
import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {
    PricingSchemeInfoCreate, PricingSchemeInfoRead, PricingSchemeInfoUpdate,
    serverApi,
    ServerError
} from "../../../../../server";
import {getServerError} from "../../../../../shared/util/utils";

export const ACTION_TYPES = {
    LOAD_ENTITY: 'pricingScheme/LOAD_ENTITY',
    UPDATE_ENTITY: 'pricingScheme/UPDATE_ENTITY',
    CREATE_ENTITY: 'pricingScheme/CREATE_ENTITY',
    DELETE_ENTITY: 'pricingScheme/DELETE_ENTITY',
    RESET: 'pricingScheme/RESET',
    RESET_ERROR: 'pricingScheme/RESET_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entity: null as (PricingSchemeInfoRead | null),
    updating: false,
    updatingError: undefined as (undefined | ServerError),
};

export type PricingSchemeState = Readonly<typeof initialState>;

// Reducer

export default (state: PricingSchemeState = initialState, action): PricingSchemeState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                loadingError: undefined,
                updatingError: undefined
            };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false
            };
        default:
            return state;
    }
};

// Actions

export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getPricingSchemeById(businessAccountId, id)
});

export const createEntity = (businessAccountId: number, pricingSchemeInfoCreate: PricingSchemeInfoCreate) => ({
    type: ACTION_TYPES.CREATE_ENTITY,
    payload: serverApi.createPricingScheme(businessAccountId, pricingSchemeInfoCreate)
});

export const updateEntity = (businessAccountId: number, id: number, pricingSchemeInfoUpdate: PricingSchemeInfoUpdate) => ({
    type: ACTION_TYPES.UPDATE_ENTITY,
    payload: serverApi.updatePricingSchemeById(businessAccountId, id, pricingSchemeInfoUpdate)
});

export const deleteEntity = (businessAccountId: number, id: number, businessVersionId: number) => ({
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: serverApi.deletePricingSchemeById(businessAccountId, id, {businessVersion: businessVersionId})
});

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});

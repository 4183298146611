import { LocalizationEnum, localize } from '../../../../../../../localization';
import React, { memo } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { LocationInfoRead } from '../../../../../../../server';
import { editLocationModalFilters } from '../create/LocationCreateModalData';
import { LocationEditModal } from './LocationEditModal';
import { EntityGridEditModalWrapper } from '../../../../../../../components/page/entityGrid/types/modal';

export const LocationEditModalWrapper: EntityGridEditModalWrapper<LocationInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <LocationEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование локации'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            filters={editLocationModalFilters}
            modalName={'edit-location'}
            location={entity}
        />
    );
});

import React from 'react';
import {Button, Icon, Popover} from "antd";
import {OperationTypeCodeEnum, RentStateCodeEnum} from "../../../../server/api";
import {getRentElementStateByCode, localize} from "../../../../localization";
import './OperationTypeSelectButton.less';
import {ALL_OPERATION_TYPES, IRentStateData} from "../../../../types";

interface IProps {
    data: IRentStateData[];
    onSelect:(type:RentStateCodeEnum)=>void;
}

export class CorrectionTargetStateSelectPopover extends React.Component<IProps> {

    constructor(props) {
        super(props);
    }

    render = () => {
        let buttons = this.props.data.map((type) => {
            return (
                <Button block onClick={() => {
                    this.props.onSelect(type.code);
                }}>
                    <div className={`rr-dot rr-status-bg-${type.code}`}></div>
                    <span>{getRentElementStateByCode(type.code)}</span>
                </Button>
            )
        });

        let correctionType = ALL_OPERATION_TYPES.find((type) => type.code === OperationTypeCodeEnum.CORRECT);
        return correctionType && (
            <Popover overlayClassName={'rr-operationForm-type-select-buttons-group__correction-popover-content'}
                     placement={'leftBottom'}
                     trigger={'hover'}
                     arrowPointAtCenter={true}
                     overlayStyle={{width: 300}}
                     content={
                <div className={'rr-grid-actions-popover-content'}>
                    {buttons}
                </div>
            }>
                <Button block>
                    <Icon className={'rr-operation-status-color-' + correctionType.colorScheme}
                          component={correctionType.icon} />
                    {localize(correctionType.localizationCode, 'span')}
                </Button>
            </Popover>
        );
    };
}

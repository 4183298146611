import moment, {Moment} from "moment";
import {
    IdDatePairObj,
    InstanceRecord,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    RentStateCodeEnum,
    serverApi,
    TimetableList,
    TimetableTypeCodeEnum
} from "../../../../server";
import {RentElementsGridItem} from "../../../../types";
import {isDefined} from "../../../../shared/util/utils";
import {ProductUtils} from "../../../../shared/util/productUtils";
import {OperationElement, TimeTable} from "../reducers/operationForm.reducer";
import {BaseNomenclatureData} from "./operationFormUtils";
import {getStore} from "../../../../../index";
import {isOrderOperation} from "./utils";
import {MAXIMUM_OF_IDS_FOR_ONE_NOMENCLATURES_ON_INTERVAL_REQUEST, NOMENCLATURES_TO_LOAD_BATCH_SIZE} from "../../../../config/constants";

export interface NomenclatureBaseObj {
    id: number;
    type: NomenclatureEntityTypeCodeEnum;
}

export interface NomenclatureOnInterval extends NomenclatureBaseObj {
    from: number;
    until: number;
    nomenclature?: NomenclatureRecord | InstanceRecord;
}

export interface NomenclaturesOnIntervalList {
    records: Array<NomenclatureOnInterval>;
    elementsDelayedReturnDates?: Array<IdDatePairObj>;
}

// Метод, которому передается список всех номенклатур которые нужно загрузить
export const loadNomenclaturesOnIntervals = async (businessAccountId: number, nomenclatures: NomenclatureOnInterval[], targetStackTypeCodes: TimetableTypeCodeEnum[], possibleDelayedRentElementIds: number[]) => {

    const nomenclaturesToLoadTmp = [
        ...iii(nomenclatures.filter(item => item.type === NomenclatureEntityTypeCodeEnum.KIT), NomenclatureEntityTypeCodeEnum.KIT),
        ...iii(nomenclatures.filter(item => item.type === NomenclatureEntityTypeCodeEnum.PRODUCT), NomenclatureEntityTypeCodeEnum.PRODUCT),
        ...iii(nomenclatures.filter(item => item.type === NomenclatureEntityTypeCodeEnum.VARIANT), NomenclatureEntityTypeCodeEnum.VARIANT),
        ...iii(nomenclatures.filter(item => item.type === NomenclatureEntityTypeCodeEnum.INSTANCE), NomenclatureEntityTypeCodeEnum.INSTANCE),
    ];

    const nomenclaturesToLoad:(typeof nomenclaturesToLoadTmp) = [];

    // Грузим по N айдишников за раз а не все сразу (при загрузке 350 на проде происходил таймаут)
    nomenclaturesToLoadTmp.forEach((item)=>{
        const totalIds = item[2].length;
        if(totalIds <= MAXIMUM_OF_IDS_FOR_ONE_NOMENCLATURES_ON_INTERVAL_REQUEST){
            nomenclaturesToLoad.push(item);
        }else{
            for(let i = 0; i < Math.max(totalIds / MAXIMUM_OF_IDS_FOR_ONE_NOMENCLATURES_ON_INTERVAL_REQUEST); i++){
                const ids = item[2].slice(i*MAXIMUM_OF_IDS_FOR_ONE_NOMENCLATURES_ON_INTERVAL_REQUEST, (i+1)*MAXIMUM_OF_IDS_FOR_ONE_NOMENCLATURES_ON_INTERVAL_REQUEST);
                nomenclaturesToLoad.push([item[0], item[1], ids, item[3]]);
            }
        }
    });

    const result: NomenclatureOnInterval[] = [];
    const elementsDelayedReturnDates: IdDatePairObj[] = [];

    const batchesToLoad:(typeof nomenclaturesToLoad)[] = [];
    let groupIndex = 0;
    // Грузим пачками запросы, а не все сразу, по N одновременных запросов
    nomenclaturesToLoad.forEach((item, index)=>{
        if(index && index % NOMENCLATURES_TO_LOAD_BATCH_SIZE === 0) groupIndex = groupIndex + 1;
        if(!batchesToLoad[groupIndex]) batchesToLoad[groupIndex] = [];
        batchesToLoad[groupIndex].push(item);
    });

    try {
        for(const batch of batchesToLoad) {
            const res = await Promise.all(
                batch.map((item) => {
                    return loadNomenclaturesOnIntervalByIds(businessAccountId, item[0], item[1], item[2], item[3], targetStackTypeCodes, possibleDelayedRentElementIds);
                })
            );
            res.forEach((data) => {
                data.records.forEach(record => {
                    result.push(record);
                });
                data.elementsDelayedReturnDates?.forEach((obj) => {
                    if (!elementsDelayedReturnDates.find(el => el.id === obj.id)) elementsDelayedReturnDates.push({
                        id: obj.id,
                        date: moment(obj.date).toDate()
                    });
                });
            })
        }
        return {
            nomenclatures: result,
            elementsDelayedReturnDates: elementsDelayedReturnDates
        };
    } catch (error) {
        throw error;
    }
}

const iii = (nomenclatures: NomenclatureOnInterval[], nomenclatureType: NomenclatureEntityTypeCodeEnum) => {
    const intervals: [number, number, number[], NomenclatureEntityTypeCodeEnum][] = [];
    nomenclatures.forEach((item) => {
        let interval = intervals.find(interval => interval[0] === item.from && interval[1] === item.until);
        if (interval) {
            if (!interval[2].includes(item.id)) interval[2].push(item.id);
        } else {
            intervals.push([item.from, item.until, [item.id], nomenclatureType]);
        }
    });
    return intervals;
};

export const loadNomenclaturesOnIntervalByIds = async (businessAccountId: number, from: number, until: number, ids: number[], nomenclatureType: NomenclatureEntityTypeCodeEnum, targetStackTypeCodes: TimetableTypeCodeEnum[], possibleDelayedRentElementIds?: number[]): Promise<NomenclaturesOnIntervalList> => {

    if (ids.length === 0) throw new Error('Nomenclature ids array is empty');
    if (targetStackTypeCodes.length === 0) throw new Error('Nomenclature targetStackTypeCodes array is empty');

    try {
        let listFilters: string[] | undefined;

        if (nomenclatureType === NomenclatureEntityTypeCodeEnum.INSTANCE) {
            listFilters = [`id;IN;${ids.join(';')}`]
        } else if (nomenclatureType === NomenclatureEntityTypeCodeEnum.PRODUCT) {
            listFilters = [`productId;IN;${ids.join(';')}`];
        } else if (nomenclatureType === NomenclatureEntityTypeCodeEnum.VARIANT) {
            listFilters = [`variantId;IN;${ids.join(';')}`];
        } else if (nomenclatureType === NomenclatureEntityTypeCodeEnum.KIT) {
            listFilters = [`kitId;IN;${ids.join(';')}`];
        }

        if (nomenclatureType === NomenclatureEntityTypeCodeEnum.INSTANCE) {
            const res = await serverApi.listInstancesOnInterval(
                businessAccountId,
                from,
                until,
                {
                    possibleDelayedRentElementIds,
                    listFilters
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                targetStackTypeCodes
            );
            return {
                records: res.data.records.map(record => instanceToSimpleObj(record, from, until)),
                elementsDelayedReturnDates: res.data.elementsDelayedReturnDates
            };
        } else {
            const res = await serverApi.listNomenclatureOnInterval(
                businessAccountId,
                from,
                until,
                {
                    possibleDelayedRentElementIds,
                    listFilters
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                targetStackTypeCodes
            );
            return {
                records: res.data.records.map(record => nomenclatureToSimpleObj(record, from, until)),
                elementsDelayedReturnDates: res.data.elementsDelayedReturnDates
            };
        }
    } catch (error: unknown) {
        throw error;
        //console.log('XXXXXX EEE', error);
        // TODO тут передавать норм сообщение об ошибке
        //  const errorMessage = error instanceof Error ? error.message : undefined;
        //throw new Error(errorMessage);
    }
};

export const loadInstancesOnInterval = async (businessAccountId: number, from: number, until: number, startFrom:number, limit: number|undefined, targetStackTypeCodes: TimetableTypeCodeEnum[], possibleDelayedRentElementIds?: number[]): Promise<NomenclaturesOnIntervalList> => {
    //if (targetStackTypeCodes.length === 0) throw new Error('Nomenclature targetStackTypeCodes array is empty');

    const res = await serverApi.listInstancesOnInterval(
        businessAccountId,
        from,
        until,
        {
            possibleDelayedRentElementIds
        },
        limit,
        startFrom,
        undefined,
        undefined,
        undefined,
        targetStackTypeCodes
    );
    return {
        records: res.data.records.map(record => instanceToSimpleObj(record, from, until)),
        elementsDelayedReturnDates: res.data.elementsDelayedReturnDates
    };
};

/*
Можно или брать максимальный интервал, Или по каждым интервалам загружать
* */

/**
 *
 * @param rentElements
 */
// export const getAllNomenclaturesForRentElements = (rentElements: RentElementsGridItem[]) => {
//     const nomenclaturesToLoad: NomenclatureOnInterval[] = [];
//     rentElements.forEach((item) => {
//         let id: number | undefined;
//         if (item.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.KIT) {
//             if (isDefined(item.kitId)) id = item.kitId;
//         } else if (item.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.PRODUCT) {
//             if (isDefined(item.productId)) id = item.productId;
//         } else if (item.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.VARIANT) {
//             if (isDefined(item.variantId)) id = item.variantId;
//         }
//
//         if (id === undefined) {
//             throw new Error(`Nomenclature id for "${item.nomenclatureEntityTypeCode}" is undefined`);
//         } else {
//             nomenclaturesToLoad.push({
//                 from: moment(item.rentTerms.rentPeriodStartDate).valueOf(),
//                 until: moment(item.rentTerms.rentPeriodEndDate).valueOf(),
//                 id: id,
//                 type: item.nomenclatureEntityTypeCode
//             });
//         }
//
//         if (item.instanceIds) {
//             item.instanceIds.forEach((id) => {
//                 nomenclaturesToLoad.push({
//                     from: moment(item.rentTerms.rentPeriodStartDate).valueOf(),
//                     until: moment(item.rentTerms.rentPeriodEndDate).valueOf(),
//                     id: id,
//                     type: NomenclatureEntityTypeCodeEnum.INSTANCE
//                 });
//             });
//         }
//     });
//     return nomenclaturesToLoad;
// };

/**
 * Преобразуем NomenclatureRecord в NomenclatureOnInterval
 * @param nomenclature
 */
export const nomenclatureToSimpleObj = (nomenclature: NomenclatureRecord, from: number, until: number): NomenclatureOnInterval => {
    const id = nomenclature.kitId || nomenclature.variantId || nomenclature.productId;
    const type = nomenclature.nomenclatureEntityTypeCode;
    return {
        id,
        type,
        from,
        until,
        nomenclature: nomenclature
    };
};

/**
 * Преобразуем InstanceRecord в NomenclatureOnInterval
 * @param instance
 */
export const instanceToSimpleObj = (instance: InstanceRecord, from: number, until: number): NomenclatureOnInterval => {
    const id = instance.id;
    const type = NomenclatureEntityTypeCodeEnum.INSTANCE;
    return {
        id,
        type,
        from,
        until,
        nomenclature: instance
    };
};

/**
 * По списку карт полученных с сервера, получаем интервалы для нужного типа
 * @param list Список карт с сервера
 * @param typeCode Тип карты
 */
export const getIntervalsFromListByType = (list: TimetableList, typeCode: TimetableTypeCodeEnum): [number, number][] | undefined => {
    const map = list.maps.find(item => item.typeCode === typeCode)?.mapString;
    return isDefined(map) ? ProductUtils.parseProductStackMap(map) : undefined;
}

/**
 * Метод по списку переданных элементов возвращает все интервалы номенклатур,
 * которые нужны для этого списка (наборы, варианты, продукты, экземпляры)
 * @param elements Список элементов операции (наборы, продукты, варианты)
 */
export const getAllNomenclatureIntervalsForElements = (elements: (OperationElement)[]) => {
    const nomenclaturesForElements: NomenclatureOnInterval[] = [];

    elements.forEach((element) => {
        let nomenclatureType: NomenclatureEntityTypeCodeEnum | undefined;
        let nomenclatureId: number | undefined;
        if (element.kitId) {
            nomenclatureType = NomenclatureEntityTypeCodeEnum.KIT;
            nomenclatureId = element.kitId;
        } else if (element.variantId) {
            nomenclatureType = NomenclatureEntityTypeCodeEnum.VARIANT;
            nomenclatureId = element.variantId;
        } else if (element.productId) {
            nomenclatureType = NomenclatureEntityTypeCodeEnum.PRODUCT;
            nomenclatureId = element.productId;
        }

        if (nomenclatureType && nomenclatureId) {
            nomenclaturesForElements.push(
                {
                    id: nomenclatureId,
                    type: nomenclatureType,
                    from: moment(element.rentPeriodStartDate).valueOf(),
                    until: moment(element.rentPeriodEndDate).valueOf()
                }
            );
        }

        if (element.instanceIds && element.instanceIds.length > 0) {
            element.instanceIds.forEach((id) => {
                nomenclaturesForElements.push(
                    {
                        id,
                        type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                        from: moment(element.rentPeriodStartDate).valueOf(),
                        until: moment(element.rentPeriodEndDate).valueOf()
                    }
                );
            });
        }
    });

    // Тут из массива всех нужных номенклатур собираем массив с максимальными датами

    const arr: NomenclatureOnInterval[] = [];

    nomenclaturesForElements.forEach((item) => {
        const existedIndex = arr.findIndex((arrItem) => arrItem.type === item.type && arrItem.id === item.id);
        const existed = arr[existedIndex];
        if (existed) {
            if (item.from < existed.from || item.until > existed.until) {
                const n = {...existed};
                if (item.from < existed.from) n.from = item.from;
                if (item.until > existed.until) n.until = item.until;
                arr[existedIndex] = n;
            }
        } else {
            arr.push(item);
        }
    });

    //console.log('all', nomenclaturesForElements);
    //console.log('need', arr);

    return arr;
}

export const nomenclatureToTimetable = (nomenclature: NomenclatureOnInterval) => {
    const record = nomenclature.nomenclature;

    const newTimeTable = (record: NomenclatureRecord | InstanceRecord): TimeTable => {
        return {
            list: record.stackMapList,
            timetableVersion: record.timetableVersion,
            available1: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.AVAILABLE),
            orderAvailable: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.ORDERAVAILABLE),
            stock: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.STOCK),
            order: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.ORDER),
            shiftLengthInMinutes: ('shiftLengthInMinutes' in record) ? record.shiftLengthInMinutes : undefined,
            hasOwnShiftLength: ('productHasOwnShiftLength' in record) ? record.productHasOwnShiftLength : undefined,
            requiredTimeIndentBetweenElementsInMinutes: ('requiredTimeIndentBetweenElementsInMinutes' in record) ? record.requiredTimeIndentBetweenElementsInMinutes : undefined,
            productHasOwnRequiredTimeIndentBetweenElements: ('productHasOwnRequiredTimeIndentBetweenElements' in record) ? record.productHasOwnRequiredTimeIndentBetweenElements : undefined,
            subrentSupply: ('subrentSupply' in record) ? record.subrentSupply : undefined,
            pricingScheme: ('pricingScheme' in record) ? record.pricingScheme : undefined,
            nomenclature: record
        };
    };

    let timeTable: TimeTable | undefined;

    if (record) {
        if ('kitId' in record && isDefined(record.kitId)) {
            timeTable = {
                id: record.kitId,
                type: NomenclatureEntityTypeCodeEnum.KIT,
                kitId: record.kitId,
                ...newTimeTable(record)
            };
        } else if ('id' in record && isDefined(record.id)) {
            timeTable = {
                id: record.id,
                type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                productId: record.productId,
                variantId: record.variantId || undefined,
                instanceId: record.id,
                ...newTimeTable(record)
            };
        } else if (('productId' in record) && isDefined(record.productId)) {
            let id: number;
            let type: NomenclatureEntityTypeCodeEnum;
            if (record.productId && record.variantId) {
                id = record.variantId;
                type = NomenclatureEntityTypeCodeEnum.VARIANT;

            } else {
                id = record.productId;
                type = NomenclatureEntityTypeCodeEnum.PRODUCT;
            }
            timeTable = {
                id: id,
                type: type,
                productId: record.productId,
                variantId: record.variantId || undefined,
                ...newTimeTable(record)
            };
        }

        if (timeTable) {
            timeTable.shiftLengthInMinutes = ('shiftLengthInMinutes' in record) ? record.shiftLengthInMinutes : undefined;
            timeTable.hasOwnShiftLength = ('productHasOwnShiftLength' in record) ? record.productHasOwnShiftLength : undefined;
            if (timeTable.timetableVersion !== record.timetableVersion) {
                timeTable.list = record.stackMapList;
                timeTable.timetableVersion = record.timetableVersion;
            }
            timeTable.pricingScheme = ('pricingScheme' in record) ? record.pricingScheme : undefined;

            timeTable.from = nomenclature.from;
            timeTable.until = nomenclature.until;
        }
    }
    return timeTable;
};

export const nomenclaturesToTimetables = (nomenclatures: NomenclatureOnInterval[]) => {
    const timetables: TimeTable[] = [];
    nomenclatures.forEach((nomenclature) => {
        const tt = nomenclatureToTimetable(nomenclature);
        if (tt) timetables.push(tt);
    });
    return timetables;
};

export const findTimeTableById = (nomenclatureId: number, nomenclatureType: NomenclatureEntityTypeCodeEnum, timetables: TimeTable[]) => {
    const timeTable = timetables.find((tt) => tt.id === nomenclatureId && tt.type === nomenclatureType);
    return timeTable;
};

export const findNomenclature = (nomenclatureId: number, nomenclatureType: NomenclatureEntityTypeCodeEnum, nomenclatures: NomenclatureOnInterval[]) => {
    return nomenclatures.find((n) => n.id === nomenclatureId && n.type === nomenclatureType);
};


export const getPossibleDelayedRentElementIds = (elements: { productId: number; stateCode?: RentStateCodeEnum; id: number }[]): number[] => {
    return elements.filter((item) => item.productId && item.stateCode === RentStateCodeEnum.RENT).map(item => item.id);
};


/**
 * По элементу возвращает объект с id и типом номенклатуры
 * @param element
 */
export const rentElementToNomenclatureBaseObj = (element: OperationElement | RentElementsGridItem|NomenclatureRecord|BaseNomenclatureData): NomenclatureBaseObj => {
    let nomenclatureType: NomenclatureEntityTypeCodeEnum;
    let nomenclatureId: number;
    if (element.kitId) {
        nomenclatureType = NomenclatureEntityTypeCodeEnum.KIT;
        nomenclatureId = element.kitId;
    } else if (element.variantId) {
        nomenclatureType = NomenclatureEntityTypeCodeEnum.VARIANT;
        nomenclatureId = element.variantId;
    } else {
        nomenclatureType = NomenclatureEntityTypeCodeEnum.PRODUCT;
        nomenclatureId = element.productId || -1;
    }

    return {
        id: nomenclatureId,
        type: nomenclatureType
    };
}

/**
 * По элементу возвращает объект с интервалом для номенклатуры
 * @param element
 */
export const rentElementToNomenclatureOnInterval = (element: OperationElement | RentElementsGridItem): NomenclatureOnInterval => {
    let from: number;
    let until: number;

    if ('rentTerms' in element) {
        from = moment(element.rentTerms.rentPeriodStartDate).valueOf();
        until = moment(element.rentTerms.rentPeriodEndDate).valueOf();
    } else {
        from = getElementStartDate(element);//moment(element.rentPeriodStartDate).valueOf();
        until = getElementEndDate(element);//moment(element.rentPeriodEndDate).valueOf();
    }
    return {
        ...rentElementToNomenclatureBaseObj(element),
        from,
        until
    };
};

/**
 * Метод по списку переданных элементов возвращает все интервалы номенклатур,
 * которые нужны для этого списка (наборы, варианты, продукты, экземпляры)
 * @param elements Список элементов операции (наборы, продукты, варианты)
 */
export const getAllNomenclatureIntervalsForRentElements = (elements: (OperationElement | RentElementsGridItem)[]) => {
    let nomenclatures: NomenclatureOnInterval[] = [];

    elements.forEach((element) => {
        const nomenclature = rentElementToNomenclatureOnInterval(element);
        nomenclatures.push(nomenclature);

        // Если изменили вариант, то старый так-же нужен
        if('variantIdOriginal' in element && element.variantIdOriginal){
            nomenclatures.push({id: element.variantIdOriginal, type: NomenclatureEntityTypeCodeEnum.VARIANT, from: nomenclature.from, until: nomenclature.until});
        }

        let instances:number[] = [];
        element.instanceIds?.forEach((id) => {
            if(!instances.includes(id)) instances.push(id);
        });

        if('instanceIdsOriginal' in element){
            element.instanceIdsOriginal?.forEach((id) => {
                if(!instances.includes(id)) instances.push(id);
            });
        }

        instances.forEach((id) => {
            nomenclatures.push(
                {
                    id,
                    type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                    from: nomenclature.from,
                    until: nomenclature.until
                }
            );
        });

        if (element.instanceIds && element.instanceIds.length > 0) {
            element.instanceIds.forEach((id) => {
                nomenclatures.push(
                    {
                        id,
                        type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                        from: nomenclature.from,
                        until: nomenclature.until
                    }
                );
            });
        }
    });

    // Тут фильтруем с левыми датами, что б ошибки с сервера не было
    nomenclatures = nomenclatures.filter((nomenclature) => nomenclature.until >= nomenclature.from);

    // Тут из массива всех нужных номенклатур собираем массив с максимальными датами
    const arr: NomenclatureOnInterval[] = [];

    nomenclatures.forEach((item) => {
        const existedIndex = arr.findIndex((arrItem) => arrItem.type === item.type && arrItem.id === item.id);
        const existed = arr[existedIndex];
        if (existed) {
            if (item.from < existed.from || item.until > existed.until) {
                const n = {...existed};
                if (item.from < existed.from) n.from = item.from;
                if (item.until > existed.until) n.until = item.until;
                arr[existedIndex] = n;
            }
        } else {
            arr.push(item);
        }
    });
    //console.log('all', nomenclatures);
    //console.log('need', arr);
    return arr;
}

export const loadAllNomenclaturesForRentElements = async (businessAccountId: number, elements: (OperationElement | RentElementsGridItem)[], targetStackTypeCodes: TimetableTypeCodeEnum[]) => {
    const nomenclatures = getAllNomenclatureIntervalsForRentElements(elements);
    const possibleDelayedRentElementIds = getPossibleDelayedRentElementIds(elements);
    //
    return await loadNomenclaturesOnIntervals(businessAccountId, nomenclatures, targetStackTypeCodes/*[TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]*/, possibleDelayedRentElementIds);
}

export const getTargetStackTypeCodes = (params:{isProjectTemplate: boolean, isOrderOperation: boolean}) => {
    return params.isProjectTemplate ? [TimetableTypeCodeEnum.STOCK] : (params.isOrderOperation ? [TimetableTypeCodeEnum.ORDERAVAILABLE] : [TimetableTypeCodeEnum.AVAILABLE])
};

/**
 * Методу передается список номенклатур, с интервалами которые нам понадобятся
 * Нужно найти там максимальные интервалы по каждой номенклатуре
 * Потом берем все номенклатуры которые исп-ся в операции (обязательства)
 * Получаем макс интервалы
 */
export const getMaxIntervalsForNomenclatures = (nomenclatures:NomenclatureOnInterval[], elements: OperationElement[]) => {
    const nn:NomenclatureOnInterval[] = [];
    // Получаем массив максимальных интервалов для номенклатур, возвращаются только уникальные
    nomenclatures.forEach((n)=>{
        let fnn = nn.find((nnn)=>nnn.id === n.id && nnn.type === n.type);
        if(fnn){
            fnn.from = Math.min(fnn.from, n.from);
            fnn.until = Math.max(fnn.until, n.until);
        }else{
            fnn = {...n};
            nn.push(fnn);
        }
    });

    const allNomenclatures = getAllNomenclatureIntervalsForElements(elements);
    nn.forEach((n)=>{
        let from = n.from;
        let until = n.until;
        let fns = allNomenclatures.filter((nnn)=>nnn.id === n.id && nnn.type === n.type);
        fns.forEach((fn)=>{
            from = Math.min(from, fn.from);
            until = Math.max(until, fn.until);
        })
        n.from = from;
        n.until = until;
    });
    return nn;
};

/**
 * Метод по тому что сохранено решает что нужно загрузить и на каких интервалах
 * @param nomenclatures
 * @param timeTables
 */
const getNomenclaturesIntervalsToUpdateFromServer = (nomenclatures: NomenclatureOnInterval[], timeTables: TimeTable[]) => {
    // Сюда передается
    const toLoad: NomenclatureOnInterval[] = [];
    nomenclatures.forEach((n) => {
        if(n.from < n.until){
            const existed = timeTables.find((t) => n.id === t.id && n.type === t.type);
            if (existed && existed.from !== undefined && existed.until !== undefined) {
                if (n.from < existed.from || n.until > existed.until) {
                    toLoad.push(n);
                }
            } else {
                toLoad.push(n);
            }
        }
    });
    return toLoad;
    // Если есть карта в которую влезаем, то не грузим, иначе грузим
};

export const loadNomenclaturesOnIntervalsIfNeed = async (nomenclatures: NomenclatureOnInterval[]) => {
    const state = getStore().getState();
    const businessAccountId = state.system.businessAccountId;
    const isProjectTemplate = state.operationForm.projectTemplate;
    const operationType = state.operationForm.typeCode;
    const elements = state.operationForm.elements.entities;
    const timeTables = state.operationForm.timeTables;
    const possibleDelayedRentElementIds = getPossibleDelayedRentElementIds(elements);

    const xxx = getMaxIntervalsForNomenclatures(nomenclatures, elements);
    const nomenclaturesToLoad = getNomenclaturesIntervalsToUpdateFromServer(xxx, timeTables);

    const res = await loadNomenclaturesOnIntervals(businessAccountId, nomenclaturesToLoad, getTargetStackTypeCodes({isProjectTemplate, isOrderOperation: isOrderOperation(operationType)}), possibleDelayedRentElementIds);
    let ttt:TimeTable[] = res.nomenclatures.map((item)=>nomenclatureToTimetable(item)).filter(item=>item !== undefined) as TimeTable[];
    return {timetables: ttt, elementsDelayedReturnDates: res.elementsDelayedReturnDates};
};

export const getDateIntervalsForCalendar = (rentPeriodStartDate:Date|number|Moment|undefined, rentPeriodEndDate:Date|number|Moment|undefined) => {
    if(rentPeriodStartDate === undefined || rentPeriodEndDate === undefined) throw new Error('Дата не должна быть пустой');
    const from = moment(rentPeriodStartDate).startOf('year').subtract(1, 'year').valueOf();
    const until = moment(rentPeriodEndDate).endOf('year').add(1, 'year').valueOf();
    return [from, until];
}

export const getElementStartDate = (element:OperationElement) => {
    let time = moment(element.rentPeriodStartDate).valueOf();
    if(element.rentPeriodStartDateOriginal) time = Math.min(moment(element.rentPeriodStartDateOriginal).valueOf(), time);
    return time;
}

export const getElementEndDate = (element:OperationElement) => {
    let time = moment(element.rentPeriodEndDate).valueOf();
    if(element.rentPeriodEndDateOriginal) time = Math.max(moment(element.rentPeriodEndDateOriginal).valueOf(), time);
    return time;
}

import { AxiosError } from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { ProjectTypeCodeEnum, serverApi, WorkPlanningsInfoRead, WorkPlanningsRecord, WorkPlanningsStateCodeEnum } from '../../../../server';
import {
    ListWorkPlanningsArgs,
    ListWorkPlanningsData,
    MoveWorkPlanningToAnotherProjectArgs,
    SetStatusForWorkPlanningsArgs,
} from './workPlannings.api.types';
import { showNotification } from '../../../../components/notification/showNotification';
import { WorkPlanningsUtils } from './workPlannings.api.utils';
import { GetEntityByIdArgs } from '../../../../components/page/entityGrid/types/api';
import { ServerUtils } from '../../../../core/utils/serverUtils';
import { IRootState } from '../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { push } from 'connected-react-router';
import React from 'react';
import { projectsPageUrlRoute } from '../../projects/projectsPage';
import { ProjectPageTabsEnum, ProjectsPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { getPathFromState } from '../../../../shared/util/utils';
import { updateProjectEntity } from '../../expenses/reducers/expense/expense.reducer';
import { GetState } from '../../../../../index';

export const getWorkPlanningByIdQueryFunction = async ({ businessAccountId, entityId }: GetEntityByIdArgs) => {
    try {
        return await serverApi.getWorkPlanning(businessAccountId, entityId);
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить работы');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const workPlanningsApi = createApi({
    reducerPath: 'workPlanningsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['WorkPlanningsList', 'WorkPlanning'],
    endpoints: (builder) => ({
        listWorkPlannings: builder.query<ListWorkPlanningsData, ListWorkPlanningsArgs>({
            queryFn: async ({ businessAccountId, params, projectId }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = WorkPlanningsUtils.createRequestFilters(params);
                const sortBy = params.sortBy || undefined;

                try {
                    const { data: listAvailableFilters } = await serverApi.listWorkPlanningsAvailableFiltersValues(
                        businessAccountId,
                        ServerUtils.createRequestFilters<WorkPlanningsRecord>([projectId && ['projectId', 'EQ', projectId]])
                    );
                    const response = await serverApi.listWorkPlannings(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        sortBy,
                        params.sortOrder,
                        params.search,
                        true
                    );
                    return {
                        data: {
                            entitiesData: {
                                ...response.data,
                                records: response.data.records.map((item) => ({
                                    ...item,
                                    cancelled: item.stateCode === WorkPlanningsStateCodeEnum.CANCELED,
                                })),
                            },
                            availableIntervalFiltersData: {
                                values: listAvailableFilters,
                                maps: {
                                    metrics: WorkPlanningsUtils.pricePerShiftIntervalMetricsMap,
                                    formats: WorkPlanningsUtils.pricePerShiftIntervalFormatMap,
                                },
                            },
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['WorkPlanningsList'],
        }),
        getWorkPlanningById: builder.query<WorkPlanningsInfoRead, GetEntityByIdArgs>({
            queryFn: getWorkPlanningByIdQueryFunction,
            providesTags: ['WorkPlanning'],
        }),
        moveWorkPlanningsToAnotherProject: builder.mutation<void, MoveWorkPlanningToAnotherProjectArgs>({
            queryFn: async (args, { getState, dispatch }) => {
                const { projectId, targetProjectId, copy, versionedEntityObjList, saveCrewMembers, targetProjectType } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    const { data } = await serverApi.moveWorkPlanningsToOtherProject(
                        businessAccountId,
                        projectId,
                        targetProjectId,
                        copy,
                        versionedEntityObjList,
                        saveCrewMembers
                    );

                    const targetData: { title: string; url: string } | undefined = {
                        [ProjectTypeCodeEnum.OFFER]: {
                            title: 'Смета',
                            url: getPathFromState(
                                `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}/${targetProjectId}`,
                                '',
                                {
                                    tab: ProjectPageTabsEnum.WORK_PLANNINGS,
                                }
                            ),
                        },
                        [ProjectTypeCodeEnum.BASE]: {
                            title: 'Проект',
                            url: getPathFromState(
                                `/${businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${targetProjectId}`,
                                '',
                                {
                                    tab: ProjectPageTabsEnum.WORK_PLANNINGS,
                                }
                            ),
                        },
                    }[targetProjectType ?? ''];

                    const successMessage =
                        targetData == null ? (
                            <span>Работы успешно {copy ? 'скопированы' : 'перенесены'}</span>
                        ) : (
                            <span>
                                Работы успешно {copy ? 'скопированы' : 'перенесены'} в{' '}
                                <a
                                    href={targetData.url}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(push(targetData.url));
                                    }}
                                    style={{
                                        color: '#fff',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {targetData.title}
                                </a>
                            </span>
                        );

                    showNotification('success', successMessage);

                    return {
                        data,
                    };
                } catch (error) {
                    const errorMessage = `Не удалось ${copy ? 'скопировать' : 'переместить'} работы`;
                    showNotification('error', errorMessage);

                    return { error: error as AxiosError };
                }
            },
        }),
        setStatusForWork: builder.mutation<void, SetStatusForWorkPlanningsArgs>({
            queryFn: async (args, { getState, dispatch }) => {
                const { workflowDirectiveBulk } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitWorkPlanningWorkflowBulk(businessAccountId, workflowDirectiveBulk);

                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1 ? 'Статус работ успешно обновлен' : 'Статус работ успешно обновлен';
                    showNotification('success', notificationMessage);
                    updateProjectEntity(getState as GetState, dispatch);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1
                            ? 'Не удалось обновить статус работ'
                            : 'Не удалось обновить статус работ';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['WorkPlanningsList', 'WorkPlanning'],
        }),
    }),
});

export const {
    useListWorkPlanningsQuery,
    useGetWorkPlanningByIdQuery,
    useLazyGetWorkPlanningByIdQuery,
    useSetStatusForWorkMutation,
    useMoveWorkPlanningsToAnotherProjectMutation,
} = workPlanningsApi;

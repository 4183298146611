import { ActivityFramesSortValue } from '../utils/calendarData';
import { DeepKeys } from '@tanstack/react-table';
import { ProjectStateCodeEnum, RentActivityFrameRecord, RentActivityFrameTypeCodeEnum } from '../../../../../../server';
import { ActivityFramesCalendarFilters } from '../filters/activityFramesCalendarFilters.types';
import { ProblemEnum } from '../../../../../../types';
import { getStringServerProblem } from '../../../../../../shared/util/utils';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { ProjectsHideState } from '../../../../projects/production/reducers/projects.reducer';
import { getAllStatusesExceptSelected } from '../../../../../../shared/util/utils4';
import { defaultScreenLeft, defaultScreenRight } from '../../../components/Calendar/utils/data/calendarBoundaries';

export abstract class ActivityFramesCalendarApiUtils {
    static getSortBy = (sortGroup?: ActivityFramesSortValue) => {
        const defaultValue = 'lastActivityDate';
        if (sortGroup == null) return defaultValue;

        const sortByValues: Required<Record<ActivityFramesSortValue, DeepKeys<RentActivityFrameRecord>>> = {
            type: 'rentActivityFrameTypeCode',
            renter: 'counterpartyShortName',
            location: 'locationName',
            status: 'stateCode',
            start: 'startDate',
            end: 'finishDate',
            assignee: 'assigneeShortName',
        };

        return sortByValues[sortGroup] ?? defaultValue;
    };
    static getSortOrder = (sortByValue: string): 'DESC' | 'ASC' => {
        const descValues = ['lastActivityDate', 'stateCode'];

        if (descValues.includes(sortByValue)) return 'DESC';

        return 'ASC';
    };
    static getFilters = (
        params: ActivityFramesCalendarFilters,
        subrentModuleEnabled: boolean | undefined,
        logisticsModuleEnabled: boolean | undefined
    ): string[] => {
        const {
            hide,
            typeCode,
            problem,
            capture,
            screenLeft,
            screenRight,
            renterId,
            locationId,
            assigneeId,
            rentActivityFrameTypeCode,
            tags,
        } = params;

        const hideFilters = hide
            ? {
                  [ProjectsHideState.Archive]: ['archive;EQ;false'],
                  [ProjectsHideState.Cancelled]: [
                      `stateCode;IN;${getAllStatusesExceptSelected(ProjectStateCodeEnum, ProjectStateCodeEnum.CANCELED).join(';')}`,
                  ],
                  [ProjectsHideState.ArchiveCancelled]: [
                      'archive;EQ;false',
                      `stateCode;IN;${getAllStatusesExceptSelected(ProjectStateCodeEnum, ProjectStateCodeEnum.CANCELED).join(';')}`,
                  ],
              }[hide]
            : [];

        const additionalFilters: string[] = [...hideFilters];
        if (
            problem &&
            (subrentModuleEnabled ||

            (!subrentModuleEnabled &&
                    ![ProblemEnum.SUBRENT_SHIPMENT_DELAY, ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY].includes(problem)))
        ) {
            additionalFilters.push(getStringServerProblem(problem));
        }

        return ServerUtils.createRequestFilters<
            RentActivityFrameRecord & {
                startAndFinishDatesAreSet: boolean;
                isOffer: boolean;
            }
        >([
            capture !== 'all' && capture !== 'allWithNow' && ['startDate', 'LE', screenRight ?? defaultScreenLeft()],
            capture !== 'all' && capture !== 'allWithNow' && ['finishDate', 'GE', screenLeft ?? defaultScreenRight()],
            typeCode != null && ['stateCode', 'IN', typeCode],
            renterId != null && ['counterpartyId', 'EQ', renterId],
            logisticsModuleEnabled && locationId != null && ['locationId', 'EQ', locationId],
            assigneeId != null && ['assigneeId', 'EQ', assigneeId],
            subrentModuleEnabled
                ? rentActivityFrameTypeCode != null && ['rentActivityFrameTypeCode', 'EQ', rentActivityFrameTypeCode]
                : ['rentActivityFrameTypeCode', 'EQ', RentActivityFrameTypeCodeEnum.PROJECT],
            tags != null && ['tags', 'IN', tags],
            ['startAndFinishDatesAreSet', 'EQ', true],
            ['isOffer', 'EQ', false],
        ]).concat(additionalFilters);
    };
}

import React from 'react';
import { EntityDrawer } from '../../../../components/v2/entityDrawer/EntityDrawer';
import { useGetInventoryMovementByIdQuery } from '../api/inventoryMovementsApi';
import { DataDisplayBlock, DataDisplayItemProps, DataDisplayItemType, DataDisplayState } from '../../../../components/dataDisplay';
import { AttachmentsBlock } from '../../../../components/attachmentsBlock/attachmentsBlock';
import { AttachmentParentEntityTypeCodeEnum, InventoryMovementEventTypeCodeEnum } from '../../../../server';
import { isDefined } from '../../../../shared/util/utils';
import { InventoryMovementsUtils } from '../utils/inventoryMovementsUtils';
import { useAppSelector } from '../../../../store/hooks';
import { IconBookSolid } from '../../../../components/icons';
import { Icon } from 'antd';

interface DocumentTemplatesPageTemplateDrawerProps {
    className?: string;
    id?: number | null;
    onClose?: () => void;
    onStartClose?: () => void;
    refetchList?: () => void;
}

export const InventoryMovementDrawer = ({
    id,
    onClose,
    onStartClose,
    refetchList,
    className,
}: DocumentTemplatesPageTemplateDrawerProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const {
        data: serverData,
        error,
        isFetching,
        isLoading,
        refetch,
    } = useGetInventoryMovementByIdQuery(
        {
            businessAccountId: businessAccountId,
            id: id ? id : -1,
        },
        { skip: !id, refetchOnReconnect: true, refetchOnMountOrArgChange: true }
    );
    const serverError = error; //useServerError(error);

    const fakeData = InventoryMovementsUtils.getInventoryMovementInfoReadSampleData();
    const data = isLoading ? fakeData : serverData;
    return (
        <EntityDrawer
            className={className}
            headerProps={{
                title: 'Движение инвентаря',
                icon: <Icon component={IconBookSolid} style={{ marginRight: 4 }} />,
            }}
            isFetching={isFetching}
            isLoading={isLoading}
            error={serverError}
            onClose={onClose}
            onStartClose={onStartClose}
        >
            {data && (
                <>
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Тип события',
                                    type: DataDisplayItemType.IconText,
                                    value: {
                                        icon: InventoryMovementsUtils.getEventTypeIconByCode(data.eventType),
                                        text: InventoryMovementsUtils.getEventTypeByCode(data.eventType),
                                    },
                                },
                                { label: 'Дата события', type: DataDisplayItemType.DateWithTime, value: data.eventDate },
                            ],
                            [
                                {
                                    label: data.variantId ? 'Продукт и вариант' : 'Продукт',
                                    type: DataDisplayItemType.Product,
                                    value: {
                                        baId: data.businessAccountId,
                                        productId: data.productId,
                                        productName: data.productShortName,
                                        variantId: data.variantId,
                                        variantName: data.variantName,
                                    },
                                },
                                { label: 'Количество', type: DataDisplayItemType.Number, value: data.instanceCount },
                            ],
                            data.instances &&
                                data.instances.length > 0 && [
                                    {
                                        label: 'Экземпляры',
                                        type: DataDisplayItemType.InstancesList,
                                        value: data.instances,
                                    },
                                ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={
                            data.eventType === InventoryMovementEventTypeCodeEnum.DAMAGE ||
                            data.eventType === InventoryMovementEventTypeCodeEnum.LOSS
                                ? [
                                      [
                                          data.eventType === InventoryMovementEventTypeCodeEnum.DAMAGE
                                              ? {
                                                    label: 'Выведен из строя',
                                                    type: DataDisplayItemType.Boolean,
                                                    value: data.outOfOrder,
                                                    values: ['Нет', 'Да'],
                                                    state: data.outOfOrder && DataDisplayState.Problem,
                                                }
                                              : undefined,
                                          {
                                              label: 'Ответственная сторона',
                                              type: DataDisplayItemType.String,
                                              value:
                                                  data.causedByRenter === true
                                                      ? 'Арендатор'
                                                      : data.causedByRenter === false
                                                      ? 'Наш сотрудник'
                                                      : undefined,
                                              emptyValueText: 'Не определена',
                                          },
                                      ].filter((item) => item !== undefined) as DataDisplayItemProps[],
                                  ]
                                : undefined
                        }
                    />

                    <DataDisplayBlock
                        data={
                            data.counterpartyId || data.projectId
                                ? [
                                      [
                                          {
                                              label: 'Контрагент',
                                              type: DataDisplayItemType.Counterparty,
                                              value: data.counterpartyId
                                                  ? {
                                                        baId: data.businessAccountId,
                                                        id: data.counterpartyId,
                                                        name: data.counterpartyShortName,
                                                    }
                                                  : undefined,
                                          },
                                          {
                                              label: 'Проект',
                                              type: DataDisplayItemType.Project,
                                              value: data.projectId
                                                  ? {
                                                        baId: data.businessAccountId,
                                                        id: data.projectId,
                                                        name: '' + data.projectShortName,
                                                    }
                                                  : undefined,
                                          },
                                      ],
                                  ]
                                : undefined
                        }
                    />

                    <DataDisplayBlock
                        data={
                            data.eventType !== InventoryMovementEventTypeCodeEnum.STOCKTAKING &&
                            (isDefined(data.pricePerInstance) || isDefined(data.totalSum))
                                ? [
                                      [
                                          {
                                              label: 'Цена за экземпляр',
                                              type: DataDisplayItemType.Money,
                                              value: data.pricePerInstance,
                                          },
                                          { label: 'Итоговая сумма', type: DataDisplayItemType.Money, value: data.totalSum },
                                      ],
                                  ]
                                : undefined
                        }
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Автор',
                                    type: DataDisplayItemType.User,
                                    value: {
                                        name: data.lastUpdateAuthorFullName,
                                        id: data.lastUpdateAuthorId,
                                        baId: data.businessAccountId,
                                    },
                                },
                            ],
                        ]}
                    />

                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <AttachmentsBlock
                                            attachments={data.attachments?.records || []}
                                            parentEntityId={data.id}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.INVENTORYMOVEMENT}
                                            updateParentEntity={() => {
                                                refetch();
                                                refetchList?.();
                                            }}
                                        />
                                    ),
                                },
                            ],
                        ]}
                        paddings={false}
                    />

                    <DataDisplayBlock data={[[{ label: 'Комментарий', type: DataDisplayItemType.Comment, value: data.comment }]]} />
                </>
            )}
        </EntityDrawer>
    );
};

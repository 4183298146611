import {ColumnTypes, TableColumn} from '../../../../components/grid/Table';
import React from 'react';
import {getCustomFieldTypeByCode, LocalizationEnum, localize} from '../../../../localization';
import {CustomFieldProcessingTypeCodeEnum, CustomFieldRecord} from '../../../../server/api';
import {HelpTooltip} from '../../../../components/helpTooltip/HelpTooltip';
import {getActionsColumnWidth, MAX_WIDTH} from '../../../../config/constants';
import {joinClasses} from '../../../../shared/util/utils';
import {SystemIcon, SystemIconType} from '../../../../components/v2/systemIcon/systemIcon';
import {CustomFieldsUtils} from '../../../../core/utils/customFieldsUtils';
import {ActionButtonsTableGroup} from "../../../../components/v2/actionButtons/actionButtonsTableGroup/actionButtonsTableGroup";
import {customFieldActionsMap, CustomFieldActionType} from "./customFieldsModuleData";

export const columns: TableColumn<CustomFieldRecord>[] = [
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ORIGIN),
        dataIndex: 'standard',
        type: ColumnTypes.CustomRender,
        minWidth: 64,
        maxWidth: MAX_WIDTH(64),
        resizable: false,
        sortable: true,
        render: (value: any, rowData: CustomFieldRecord) => {
            return (
                <HelpTooltip
                    content={
                        rowData.standard
                            ? LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__STANDARD_TYPE
                            : LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__NOT_STANDARD_TYPE
                    }
                >
                    <SystemIcon type={rowData.standard ? SystemIconType.standard : SystemIconType.custom} style={{ display: 'block' }} />
                </HelpTooltip>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
        dataIndex: 'shortName',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        resizable: true,
        render: (value: any, rowData: CustomFieldRecord) => (
            <div
                style={{ paddingLeft: 35 }}
                className={joinClasses('rr-grid-shortName-cell', 'main-value', 'app-link-underline')}
                title={rowData.shortName}
            >
                {rowData.shortName}
            </div>
        ),
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE),
        dataIndex: 'typeCode',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        resizable: false,
        render: (value: any, rowData: CustomFieldRecord) => getCustomFieldTypeByCode(rowData.typeCode),
    },
    {
        title: 'Формат',
        dataIndex: 'processingType',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        render: (value?: CustomFieldProcessingTypeCodeEnum) => {
            return CustomFieldsUtils.processingTypeValueMap[value ?? ''];
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__MULTIPLE_VALUES),
        dataIndex: 'multipleValues',
        type: ColumnTypes.Boolean,
        minWidth: 110,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ENTITIES_LINKED_NUMBER),
        dataIndex: 'entitiesLinkedNumber',
        type: ColumnTypes.String,
        minWidth: 120,
        resizable: false,
    },
    {
        title: 'Финансовое',
        dataIndex: 'financial',
        type: ColumnTypes.CustomRender,
        width: 130,
        minWidth: 130,
        resizable: false,
        render: (value: any, rowData: CustomFieldRecord) => {
            return rowData.financial && <SystemIcon type={SystemIconType.financial} style={{ display: 'block' }} />;
        },
    },
    {
        title: 'Скрытое',
        dataIndex: 'internal',
        type: ColumnTypes.CustomRender,
        width: 130,
        minWidth: 130,
        resizable: false,
        render: (value: any, rowData: CustomFieldRecord) => {
            return rowData.internal && <SystemIcon type={SystemIconType.hidden} style={{ display: 'block' }} />;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__POPUP__TITLE__ACTIONS),
        type: ColumnTypes.CustomRender,
        width: getActionsColumnWidth(3),
        minWidth: getActionsColumnWidth(3),
        maxWidth: getActionsColumnWidth(3),
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: CustomFieldRecord, rowAction) => {
            let canDelete = !rowData.standard && rowData.entitiesLinkedNumber === 0;

            return (
                <ActionButtonsTableGroup
                    data={customFieldActionsMap}
                    mainActions={[
                        !rowData.standard ? {type: CustomFieldActionType.edit} : undefined,
                        !rowData.standard ? {type: CustomFieldActionType.delete, disabled: !canDelete} : undefined
                    ]}
                    additionalActions={[
                        !rowData.standard && !rowData.internal ? {type: CustomFieldActionType.hide} : undefined,
                        !rowData.standard && rowData.internal ? {type: CustomFieldActionType.show} : undefined
                    ]}
                    onAction={(action) => {
                        if (rowAction) rowAction(rowData, action);
                    }}
                />
            );
        },
    }
];

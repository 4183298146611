import React, { FC, memo } from 'react';
import { Icon } from 'antd';
import { useAppSelector } from '../../../../store/hooks';
import { useGetWorkPlanningByIdQuery } from '../api/workPlannings.api';
import { EntityDrawer } from '../../../../components/v2/entityDrawer/EntityDrawer';
import { WorkPlanningsInfoRead, WorkPlanningsTransitionCodeEnum } from '../../../../server';
import { DataDisplayBlock, DataDisplayItemType, DataDisplayState } from '../../../../components/dataDisplay';
import { IconHammerSolid } from '../../../../components/icons';
import { ActionButtonsGroup } from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { workPlanningActionsMap, WorkPlanningActionType } from '../data/workPlanningsData';
import { WorkPlanningsStateBadge } from '../components/WorkPlanningsStateBadge/WorkPlanningsStateBadge';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { DataDisplayItemTheme } from '../../../../components/dataDisplay/dataDisplayItemProps';
import { CrewMembersBlock } from '../../../../components/crewMembersBlock/crewMembersBlock';
import { ProfessionDrawer, ProfessionDrawerProps } from '../../crew/tabs/professions/drawer/components/ProfessionDrawer/ProfessionDrawer';
import { useProfessionEntityActions } from '../../crew/tabs/professions/hooks/useProfessionEntityActions';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { useSetStatusForWorkPlanningsCallback } from '../hooks/useSetStatusForWorkPlanningsCallback';
import { isActionAvailable } from '../utils/isActionAvailable';
import { useIdForDrawer } from '../hooks/useWorkPlanningsIdForDrawer';

export interface WorkPlanningsDrawerProps {
    workPlanningsId: number;
    onClose: () => void;
    editWorkPlanning?: (entity: WorkPlanningsInfoRead) => void;
    professionDrawerProps?: Partial<ProfessionDrawerProps>;
    isEntityActionsLoading?: boolean;
}

export const WorkPlanningsDrawer: FC<WorkPlanningsDrawerProps> = memo((props) => {
    const { workPlanningsId, onClose, editWorkPlanning, professionDrawerProps, isEntityActionsLoading } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const {
        data,
        error,
        isFetching,
        isLoading: isEntityLoading,
    } = useGetWorkPlanningByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: workPlanningsId,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const { callback: setStatusForWorkPlanningsCallback, isLoading: isSetStatusForWorkPlanningsCallbackLoading } =
        useSetStatusForWorkPlanningsCallback(data ? [data] : []);

    let numberOfVacancies = (data?.numberOfCrew || 0) - (data?.crewMemberIds?.length || 0);

    const [professionIdForDrawer, setProfessionIdForDrawer, clearProfessionIdForDrawer] = useIdForDrawer();
    const professionActions = useProfessionEntityActions({
        rootPath: `/${businessAccountId}/crew/professions`,
        urlSearchParams: new URLSearchParams(),
    });

    const isLoading = Boolean(isEntityLoading || isEntityActionsLoading || isSetStatusForWorkPlanningsCallbackLoading);

    return (
        <>
            <EntityDrawer
                headerProps={{
                    icon: <Icon component={IconHammerSolid} />,
                    title: 'Работы',
                    actions: (
                        <ActionButtonsGroup
                            disabled={isLoading || isFetching}
                            mainActions={[WorkPlanningActionType.edit]}
                            additionalActions={[
                                isActionAvailable(data, WorkPlanningsTransitionCodeEnum.TODRAFT, WorkPlanningActionType.toDraft),
                                isActionAvailable(data, WorkPlanningsTransitionCodeEnum.PLAN, WorkPlanningActionType.plan),
                                isActionAvailable(data, WorkPlanningsTransitionCodeEnum.START, WorkPlanningActionType.start),
                                isActionAvailable(data, WorkPlanningsTransitionCodeEnum.FINISH, WorkPlanningActionType.finish),
                                isActionAvailable(data, WorkPlanningsTransitionCodeEnum.CANCEL, WorkPlanningActionType.cancel),
                            ]}
                            onAction={(action) => {
                                if (data == null) return;
                                switch (action) {
                                    case WorkPlanningActionType.edit: {
                                        editWorkPlanning?.(data);
                                        break;
                                    }
                                    case WorkPlanningActionType.toDraft: {
                                        setStatusForWorkPlanningsCallback(WorkPlanningsTransitionCodeEnum.TODRAFT);
                                        break;
                                    }
                                    case WorkPlanningActionType.finish: {
                                        setStatusForWorkPlanningsCallback(WorkPlanningsTransitionCodeEnum.FINISH);
                                        break;
                                    }
                                    case WorkPlanningActionType.cancel: {
                                        setStatusForWorkPlanningsCallback(WorkPlanningsTransitionCodeEnum.CANCEL);
                                        break;
                                    }
                                    case WorkPlanningActionType.plan: {
                                        setStatusForWorkPlanningsCallback(WorkPlanningsTransitionCodeEnum.PLAN);
                                        break;
                                    }
                                    case WorkPlanningActionType.start: {
                                        setStatusForWorkPlanningsCallback(WorkPlanningsTransitionCodeEnum.START);
                                        break;
                                    }
                                }
                            }}
                            data={workPlanningActionsMap}
                        />
                    ),
                    stateBadge: data && <WorkPlanningsStateBadge type={data.stateCode} />,
                }}
                onClose={onClose}
                error={error}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                {data && (
                    <>
                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Наименование',
                                        type: DataDisplayItemType.String,
                                        value: data.name,
                                    },
                                    {
                                        label: 'Специальность',
                                        type: DataDisplayItemType.Drawer,
                                        value: {
                                            text: data.professionShortName,
                                            onClick: () => {
                                                setProfessionIdForDrawer(data.professionId);
                                            },
                                        },
                                    },
                                ],
                                [
                                    {
                                        label: 'Количество работников',
                                        type: DataDisplayItemType.Number,
                                        value: data.numberOfCrew,
                                    },
                                    {
                                        label: 'Количество вакансий',
                                        type: DataDisplayItemType.Number,
                                        value: numberOfVacancies === 0 ? undefined : numberOfVacancies,
                                        state: numberOfVacancies === 0 ? DataDisplayState.Success : undefined,
                                        emptyValueText: numberOfVacancies === 0 ? 'Нет' : undefined,
                                    },
                                ],
                            ]}
                        />

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        type: DataDisplayItemType.Custom,
                                        value: <CrewMembersBlock records={data.crewMembers} />,
                                    },
                                ],
                            ]}
                            paddings={false}
                        />

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Период работ',
                                        type: DataDisplayItemType.DateWithTimeInterval,
                                        value: {
                                            from: data.startDate,
                                            to: data.endDate,
                                        },
                                    },
                                    {
                                        label: 'Смены к оплате',
                                        type: DataDisplayItemType.Number,
                                        value: data.shiftCount,
                                    },
                                ],
                            ]}
                        />

                        {canViewFinancialData && (
                            <DataDisplayBlock
                                data={[
                                    [
                                        {
                                            label: 'Цена смены',
                                            type: DataDisplayItemType.Price,
                                            value: data.effectivePrice,
                                            pricingSchemeData: data.effectivePricingScheme && {
                                                pricingScheme: data.effectivePricingScheme,
                                                basePrice: data.basePriceAtTheTimeOfCreation,
                                                shiftCount: data.shiftCount,
                                            },
                                        },
                                        {
                                            label: 'Итоговая сумма',
                                            type: DataDisplayItemType.Money,
                                            value: data.finalTotalPrice,
                                            theme: DataDisplayItemTheme.DARK,
                                        },
                                    ],
                                ]}
                            />
                        )}

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Дата последнего изменения',
                                        type: DataDisplayItemType.DateWithTime,
                                        value: data.lastUpdateDate,
                                    },
                                    {
                                        label: 'Автор последнего изменения',
                                        type: DataDisplayItemType.User,
                                        value: data?.lastUpdateAuthorFullName
                                            ? {
                                                  name: data.lastUpdateAuthorFullName,
                                                  id: data.lastUpdateAuthorId,
                                                  baId: data.businessAccountId,
                                              }
                                            : undefined,
                                    },
                                ],
                            ]}
                        />

                        <DataDisplayBlock
                            data={[
                                [
                                    {
                                        label: 'Комментарий',
                                        type: DataDisplayItemType.Comment,
                                        value: data.comment,
                                    },
                                ],
                            ]}
                        />

                        {professionIdForDrawer && (
                            <ProfessionDrawer
                                professionId={professionIdForDrawer}
                                onClose={() => {
                                    clearProfessionIdForDrawer();
                                }}
                                editProfession={professionActions.editAction}
                                deleteProfession={professionActions.deleteActionForDrawer}
                                archiveProfession={professionActions.archiveActionForDrawer}
                                isEntityActionsLoading={professionActions.isLoading}
                                {...professionDrawerProps}
                            />
                        )}
                    </>
                )}
            </EntityDrawer>
        </>
    );
});

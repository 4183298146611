import { FormFieldsGroup, FormItemType } from '../../../../../../../components/dynamicForm/DynamicForm';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum, localize } from '../../../../../../../localization';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: LocalizationEnum.PAGE__PRODUCTS__FORM__FIELD__TOTAL_AMOUNT__LONG_TITLE,
                id: 'warehousePresenceCounters.stockInstanceCount',
                type: FormItemType.Integer,
                required: true,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(
                        value < +form.getFieldValue('warehousePresenceCounters.underMaintenanceInstanceCount')
                            ? 'Общее число экземпляров не должно быть больше числа экземпляров на обслуживании'
                            : undefined
                    );
                },
                defaultValue: 0,
            },
            {
                label: LocalizationEnum.PAGE__PRODUCTS__FORM__FIELD__UNDER_MAINTENANCE_AMOUNT__LONG_TITLE,
                id: 'warehousePresenceCounters.underMaintenanceInstanceCount',
                type: FormItemType.Integer,
                required: true,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(
                        value > +form.getFieldValue('warehousePresenceCounters.stockInstanceCount')
                            ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__UNDER_MAINTENANCE_MORE_THAN_TOTAL)
                            : undefined
                    );
                },
                defaultValue: 0,
            },
        ],
    },
];

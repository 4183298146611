import React, { FC } from 'react';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { WorkPlanningsCalendarItem } from '../../../../../components/Calendar/types/items';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { getStatusIcon, workPlanningStateCodeToNameMap } from '../../../utils/data/statusData';
import { CalendarDisplayType } from '../../../../../components/Calendar/utils/data/calendarConstants';

export const StatusGroupLine: FC<GroupItemProps<WorkPlanningsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { record } = groupItem;
    const { stateCode } = record;

    const status = workPlanningStateCodeToNameMap[stateCode];

    const isCompact = displayType === CalendarDisplayType.COMPACT;

    const iconSize = isCompact ? 8 : 11;

    return status ? (
        <GroupLine
            IconComponent={getStatusIcon(stateCode, iconSize)}
            displayType={displayType}
            title={{
                text: status,
            }}
        />
    ) : null;
};

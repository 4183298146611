import {ColumnTypes, TableColumn} from "../../../components/grid/Table";
import {MAX_WIDTH} from "../../../config/constants";
import {ReportChartVisualizationRecord, ReportTypeCodeEnum} from "../../../server";
import {HelpTooltip} from "../../../components/helpTooltip/HelpTooltip";
import {LocalizationEnum} from "../../../localization";
import React from "react";
import {joinClasses} from "../../../shared/util/utils";
import {Link} from "react-router-dom";
import {getBusinessAccountId} from "../../../../index";
import moment from "moment";
import {SystemIcon, SystemIconType} from "../../../components/v2/systemIcon/systemIcon";
import {AnalyticsPageUtils} from "./analyticsPageUtils";

// GRID COLUMNS
export const analyticsPageColumns: TableColumn<ReportChartVisualizationRecord>[] = [
    {
        title: "Вид",
        dataIndex: 'reportType',
        type: ColumnTypes.CustomRender,
        minWidth: 64,
        width: 64,
        maxWidth: MAX_WIDTH(64),
        resizable: false,
        sortable: true,
        render: (value: any, rowData: ReportChartVisualizationRecord, rowAction, entityType) => {
            return (
                <HelpTooltip
                    content={(rowData.reportType === ReportTypeCodeEnum.COMMON) ? LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__STANDARD_TYPE : LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__NOT_STANDARD_TYPE}>
                    <SystemIcon
                        type={rowData.reportType === ReportTypeCodeEnum.COMMON ? SystemIconType.standard : SystemIconType.custom}
                        style={{display: 'block'}}/>
                </HelpTooltip>
            );
        }
    },
    {
        title: "Наименование",
        dataIndex: "reportConfigurationName",
        type: ColumnTypes.CustomRender,
        minWidth: 300,
        //width: 150,
        //maxWidth: 500,
        resizable: true,
        render: (value: any, rowData: ReportChartVisualizationRecord) =>
            <div style={{paddingLeft: 35}}
                 className={joinClasses('rr-grid-shortName-cell', 'main-value')}
                 title={rowData.reportConfigurationName}>
                <Link className={'rr-report-grid-name-cell'}
                      to={`/${getBusinessAccountId()}/reports/${rowData.id}`}>{rowData.reportConfigurationName}</Link>
            </div>,
        dontHide: true
    },
    {
        title: "Вариант отображения",
        dataIndex: "name",
        type: ColumnTypes.CustomRender,
        minWidth: 300,
        //width: 200,
        //maxWidth: 500,
        resizable: true,
        render: (value: any, rowData: ReportChartVisualizationRecord) =>
            <div style={{paddingLeft: 35}}
                 className={joinClasses('rr-report-grid-name-cell', 'rr-grid-shortName-cell')}>
                <div title={rowData.name}>
                    <Link className={'rr-report-grid-name-cell'}
                          to={`/${getBusinessAccountId()}/reports/${rowData.id}`}>{rowData.name}</Link>
                </div>
            </div>,
        dontHide: true
    },
    {
        title: "Тип диаграммы",
        dataIndex: "visualizationType",
        type: ColumnTypes.CustomRender,
        minWidth: 220,
        //maxWidth: 500,
        resizable: false,
        render: (value: any, rowData: ReportChartVisualizationRecord) => {
            return (
                <div style={{display: 'flex', alignItems: 'center', paddingLeft: 35, textAlign: 'left'}}>
                    {AnalyticsPageUtils.getReportTypeIconByCode(rowData.visualizationType, {marginRight: 10})}
                    <span>{AnalyticsPageUtils.getReportTypeByCode(rowData.visualizationType)}</span>
                </div>
            );
        },
        dontHide: false
    },
    {
        title: "Финансовый",
        dataIndex: "financial",
        type: ColumnTypes.CustomRender,
        width: 130,
        minWidth: 130,
        //maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: any, rowData: ReportChartVisualizationRecord) => {
            return value && <SystemIcon type={SystemIconType.financial} style={{display: 'block'}}/>;
        }
    },
    // {
    //     type: ColumnTypes.String,
    //     minWidth: 5,
    //     resizable: false,
    //     sortable: false
    // },
    {
        title: "Дата создания",
        dataIndex: 'creationDate',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        render: (value: Date, rowData: ReportChartVisualizationRecord) => {
            return (value ? moment(value).format('DD MMMM YYYY, HH:mm') : null);
        },
        dontHide: true
    }
];

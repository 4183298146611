import {
    CategorisedEntityCodeEnum,
    KitRecord,
    NomenclatureStateCodeEnum,
    SimpleTransitionCodeEnum,
    VersionedEntityObj,
    WorkflowDirective,
} from '../../../../../server';
import every from 'lodash/every';
import RoundButton from '../../../../../components/button/roundButton';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Icon } from 'antd';
import { IconArchive, IconCheck, IconLevelUp, IconTrash, IconUnlock, IconUnlockAlt } from '../../../../../components/icons';
import { AdditionalEntityActionsPopoverContainer } from '../../../../../components/additionalEntityActions/AdditionalEntityActionsPopoverContainer/AdditionalEntityActionsPopoverContainer';
import { AdditionalEntityActionForCategories } from '../../../../../components/additionalEntityActions/AdditionalEntityActionForCategories/AdditionalEntityActionForCategories';
import React, { useCallback } from 'react';
import { useEntityGridContext } from '../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { deleteEntities, setArchiveState, setStatus } from '../reducers/kits.reducers';
import { useIntl } from 'react-intl';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { showConfirm2 } from '../../../../../components/confirm/showConfirm';
import { KitsListFilters } from '../api/kits.api.types';
import { KitAdditionalEntityActionForCustomFields } from '../components/AdditionalEntityActionForCustomFields/KitAdditionalEntityActionForCustomFields';

export const useActionsButtons = (records: KitRecord[] | undefined, selectedRecords: KitRecord[], params: KitsListFilters) => {
    const intl = useIntl();
    const gridRef = useEntityGridContext();
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const categories = useAppSelector((store) => store.entities.categories.products);

    const { categoryIds } = params;
    const selectedCategoriesKeys =
        categoryIds != null ? (Array.isArray(categoryIds) ? categoryIds.map(String) : [categoryIds].map(String)) : undefined;

    const clearSelection = useCallback(() => {
        gridRef?.current?.clearSelection();
    }, [gridRef]);

    const onChangeStateButtonClick = useCallback(() => {
        if (records == null) return;

        const directives: WorkflowDirective[] = selectedRecords.map((item) => ({
            id: item.id,
            businessVersion: item.businessVersion,
            transitionCode: item.stateCode === 'ACTIVE' ? 'BLOCK' : 'ACTIVATE',
        }));

        dispatch(setStatus(intl, businessAccountId, directives));
        clearSelection();
    }, [businessAccountId, clearSelection, dispatch, intl, records, selectedRecords]);

    const onArchiveBtnClick = useCallback(() => {
        if (records == null) return;

        const directives: WorkflowDirective[] = selectedRecords.map((item) => ({
            id: item.id,
            businessVersion: item.businessVersion,
            archive: !item.archive,
        }));

        dispatch(setArchiveState(intl, businessAccountId, directives));
        clearSelection();
    }, [businessAccountId, clearSelection, dispatch, intl, records, selectedRecords]);

    const onDeleteBtnClick = useCallback(async () => {
        if (records == null) return;

        const objects: VersionedEntityObj[] = selectedRecords.map((item) => ({ id: item.id, businessVersion: item.businessVersion }));

        const isMany = objects.length > 1;
        const yes = await showConfirm2({
            intl,
            title: isMany ? 'Удалить наборы безвозвратно?' : 'Удалить набор безвозвратно?',
        });
        if (yes) {
            dispatch(deleteEntities(intl, businessAccountId, objects));
            clearSelection();
        }
    }, [businessAccountId, clearSelection, dispatch, intl, records, selectedRecords]);

    return [
        every(
            selectedRecords,
            (item) =>
                item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                item.stateCode === NomenclatureStateCodeEnum.NEW
        ) ? (
            <RoundButton title={LocalizationEnum.ASPECT__ACTION__ACTIVATE} colorScheme={'success'} onClick={onChangeStateButtonClick}>
                <Icon component={IconCheck} />
                {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
            </RoundButton>
        ) : null,
        every(selectedRecords, (item) => item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.BLOCK)) ? (
            <RoundButton title={LocalizationEnum.ASPECT__ACTION__BLOCK} colorScheme={'blocked'} onClick={onChangeStateButtonClick}>
                <Icon component={IconUnlock} />
                {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
            </RoundButton>
        ) : null,
        every(
            selectedRecords,
            (item) =>
                item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                item.stateCode === NomenclatureStateCodeEnum.NOTAVAILABLE
        ) ? (
            <RoundButton title={LocalizationEnum.ASPECT__ACTION__UNLOCK} colorScheme={'success'} onClick={onChangeStateButtonClick}>
                <Icon component={IconUnlockAlt} />
                {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
            </RoundButton>
        ) : null,
        every(selectedRecords, (item) => !item.archive && item.archivable) ? (
            <RoundButton title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE} onClick={onArchiveBtnClick} colorScheme={'TO_ARCHIVE'}>
                <Icon component={IconArchive} />
                {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
            </RoundButton>
        ) : null,
        every(selectedRecords, (item) => item.archive) ? (
            <RoundButton title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE} onClick={onArchiveBtnClick} colorScheme={'FROM_ARCHIVE'}>
                <Icon component={IconLevelUp} />
                {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
            </RoundButton>
        ) : null,
        every(selectedRecords, (item: KitRecord) => item.deleteable) ? (
            <RoundButton title={'Удалить'} onClick={onDeleteBtnClick} colorScheme={'DELETE'}>
                <Icon component={IconTrash} />
                Удалить
            </RoundButton>
        ) : null,
        <AdditionalEntityActionsPopoverContainer>
            <AdditionalEntityActionForCategories
                categories={categories ?? []}
                selectedRecords={selectedRecords}
                entityTypeCode={CategorisedEntityCodeEnum.KIT}
                clearSelection={clearSelection}
                selectedCategoriesKeys={selectedCategoriesKeys}
            />
            <KitAdditionalEntityActionForCustomFields selectedRecords={selectedRecords} clearSelection={clearSelection} />
        </AdditionalEntityActionsPopoverContainer>,
    ];
};

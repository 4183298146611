import { FC } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { useLazyListElementsForEstimateQuery } from '../../api/elements.api';
import { ListElementsArgs } from '../../api/types';
import { AggregationsPopover } from '../../../../../components/aggregationsPopover/aggregationsPopover';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { formatMoney } from '../../../../../shared/util/formatMoney';

interface ElementsAggregationsPopoverProps extends Pick<ListElementsArgs, 'entityType'> {}

export const ElementsAggregationsPopover: FC<ElementsAggregationsPopoverProps> = ({ entityType }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const { params } = useAppSelector((state) => state.elements);
    const [loadAggregationsData, { data, isFetching, isError }] = useLazyListElementsForEstimateQuery();
    const aggregations = data?.aggregations;
    const { instanceCount, sumBeforeDiscountAndTaxes, sumAfterDiscountBeforeTaxes, finalTotalSum } = aggregations ?? {};

    return (
        <AggregationsPopover
            fetchData={() => {
                loadAggregationsData({ businessAccountId, params, entityType });
            }}
            data={
                aggregations && [
                    { label: 'Кол-во экземпляров', value: instanceCount },
                    { label: 'Сумма до скидки', value: formatMoney(sumBeforeDiscountAndTaxes) },
                    {
                        label: 'Скидка',
                        value: formatMoney(Math.abs((sumBeforeDiscountAndTaxes ?? 0) - (sumAfterDiscountBeforeTaxes ?? 0))),
                    },
                    { label: 'Сумма до налога', value: formatMoney(sumAfterDiscountBeforeTaxes) },
                    { label: 'Налог', value: formatMoney(Math.abs((sumAfterDiscountBeforeTaxes ?? 0) - (finalTotalSum ?? 0))) },
                    { label: 'Итого', value: formatMoney(finalTotalSum), summary: true },
                ]
            }
            isFetching={isFetching}
            isError={isError}
        />
    );
};

import React, { FC } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { HeaderNavigationBlock } from '../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Link } from 'react-router-dom';
import { ReportChartVisualizationInfoRead } from '../../../../../server';
import { IconChartArea } from '../../../../../components/icons/IconChartArea';

interface ReportPageHeaderNavigationBlockProps {
    report: ReportChartVisualizationInfoRead | undefined;
}

export const ReportPageHeaderNavigationBlock: FC<ReportPageHeaderNavigationBlockProps> = (props) => {
    const { report } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (report == null) return null;

    const { reportConfigurationName, name } = report;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon className={'icon'} component={IconChartArea} />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {reportConfigurationName}: {name}
                        </span>
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/reports`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__MAIN_MENU__ANALYTICS)}
                        </Link>
                        <span>/</span>
                        <Link to={`/${businessAccountId}/reports`} className={'link'}>
                            Отчеты
                        </Link>
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

import {serverApi, ServerError, UserInfoRead} from "../../../server";
import {showNotification} from "../../notification/showNotification";
import {FAILURE, REQUEST, SUCCESS} from "../../../shared/reducers/action-type.util";
import {getServerError} from "../../../shared/util/utils";
import {LocalizationEnum, localizeIntl} from '../../../localization';

export const ACTION_TYPES = {
    LOAD_USER_PROFILE: 'LOAD_USER_PROFILE'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    entity: null as (UserInfoRead | null)
};

export type UserProfilePopoverState = Readonly<typeof initialState>;

export default (state: UserProfilePopoverState = initialState, action): UserProfilePopoverState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_USER_PROFILE):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };
        case FAILURE(ACTION_TYPES.LOAD_USER_PROFILE):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };
        case SUCCESS(ACTION_TYPES.LOAD_USER_PROFILE):
            return {
                ...state,
                loading: false,
                entity: action.payload.data
            };
        default:
            return state;
    }
};


export const userProfilePopover = (intl) => {
    return (dispatch, getState) => {

        serverApi.listBusinessAccounts()

        return dispatch({
            type: ACTION_TYPES.LOAD_USER_PROFILE,
            payload: serverApi.getCurrentProfile()
        }).then((result) => {
            if(result instanceof Error){
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__GET_FAILED));
            }else{
                
            }
        });
    };
};



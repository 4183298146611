import {TblColumn, TblColumnType} from "../tblColumns.types";
import {GridColumnCreator} from "../../../components/grid/gridColumnCreator";
import {TableColumn} from "../../../components/grid/Table";

export const tblColumnsEntityName = <Record extends object, Action extends string = ''>(column: TblColumn<Record, Action>): TableColumn<Record> => {
    if (column.type === TblColumnType.ENTITY_NAME) {
        return (
            GridColumnCreator.createShortNameColumn<Record>(column.entityType, column.getData, {
                title: column.title,
                dataIndex: column.dataIndex,
            })
        );
    } else {
        throw Error('error');
    }
};

import React from 'react';
import {DataDisplayItemProps} from './dataDisplayItemProps';
import {Col, Row} from 'antd';
import {DataDisplayItem} from './dataDisplayItem';
import './dataDisplay.less';

const GROUP_CLASSNAME = 'rr-dd-g';
export const GROUP_ROW_CLASSNAME = 'rr-dd-g-r';

interface DataDisplayBlockProps {
    data: (DataDisplayItemProps[] | null | undefined | '' | false | 0)[];
    gutter?: number;
    paddings?: boolean;
}

export const DataDisplayGroup = ({data, gutter, paddings}: DataDisplayBlockProps) => {
    return (
        <div className={GROUP_CLASSNAME}>
            {data.map((r, rIndex) => {
                return r ? (
                    <Row className={GROUP_ROW_CLASSNAME} gutter={gutter ?? 0} key={rIndex} type={'flex'}>
                        {r.map((c, cIndex) => {
                            const span = 24 / r.length;
                            return (
                                <Col span={span} key={cIndex}>
                                    <DataDisplayItem {...c} paddings={paddings} />
                                </Col>
                            );
                        })}
                    </Row>
                ) : null;
            })}
        </div>
    );
};

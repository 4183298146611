import React from 'react';
import { RangePickerProps, RangePickerValue } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import { LocalizationEnum, localize } from '../../localization';
import { DatePicker } from './DatePicker';

interface IProps extends RangePickerProps {
    value?: RangePickerValue;
    defaultValue?: RangePickerValue;
    onChange?: (dates: RangePickerValue) => void;
    placeholder?: [any, any];
}

interface IState {
    value?: RangePickerValue;
}

export class FiltersDateRangePicker extends React.PureComponent<IProps, IState> {
    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        if (nextProps /* && nextProps.value*/) {
            return {
                value: nextProps.value,
            };
        } else return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue : this.props.value ? this.props.value : [],
        };
    }

    onStartDateChange = async (date: moment.Moment | null) => {
        const newDate = date?.clone().startOf('day');
        const v = [newDate, this.state.value ? this.state.value[1] : undefined] as RangePickerValue;
        await this.setState({ value: v });
        if (this.props.onChange) this.props.onChange(v);
    };

    onEndDateChange = async (date: moment.Moment | null) => {
        const newDate = date?.clone().endOf('day');
        const v = [this.state.value ? this.state.value[0] : undefined, newDate] as RangePickerValue;
        await this.setState({ value: v });
        if (this.props.onChange) this.props.onChange(v);
    };

    render() {
        const value = this.state.value || [];

        return (
            <>
                <div style={{ display: 'inline-block' }} className={value[0] ? 'rr-filters-selected-filter' : ''}>
                    <DatePicker
                        style={{ width: 150 }}
                        placeholder={this.props.placeholder && this.props.placeholder[0] ? this.props.placeholder[0] : undefined}
                        value={value[0] ? moment(value[0]) : value[0]}
                        onChange={this.onStartDateChange}
                        align={{ points: ['tc', 'bc'], offset: [0, 6] }}
                    />
                </div>

                <div className="ant-form-item-label" style={{ verticalAlign: 'unset', lineHeight: 'unset', overflow: 'unset' }}>
                    <label htmlFor="startDate1" className="ant-form-item-no-colon" style={{ marginLeft: 10 }}>
                        {localize(LocalizationEnum.ASPECT__FILTERS__UPPER_DATE_LIMIT_LABEL)}
                    </label>
                </div>
                <div style={{ display: 'inline-block' }} className={value[1] ? 'rr-filters-selected-filter' : ''}>
                    <DatePicker
                        style={{ width: 150 }}
                        placeholder={this.props.placeholder && this.props.placeholder[1] ? this.props.placeholder[1] : undefined}
                        value={value[1] ? moment(value[1]) : value[1]}
                        onChange={this.onEndDateChange}
                        align={{ points: ['tc', 'bc'], offset: [0, 6] }}
                    />
                </div>
            </>
        );
    }
}

import { FileTypeCodeEnum, serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';

export interface ContractGenerateArgs {
    businessAccountId: number;
    projectId: number;
    common: boolean;
    documentTemplateId: number;
    legalDetailId?: number;
}

export const generateContract = async (
    args: ContractGenerateArgs,
    fileType: FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
): Promise<Blob> => {
    if (fileType === FileTypeCodeEnum.WORD) {
        const res: AxiosResponse<Blob> = await serverApi.generateContractDocumentDocx(
            args.businessAccountId,
            args.projectId,
            args.documentTemplateId,
            args.common,
            getCurrentTimezoneUTCOffset(),
            args.legalDetailId,
            { responseType: 'blob' }
        );

        return res.data;
    }

    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        const res: AxiosResponse<Blob> = await serverApi.generateContractDocumentPdf(
            args.businessAccountId,
            args.projectId,
            args.documentTemplateId,
            args.common,
            getCurrentTimezoneUTCOffset(),
            args.legalDetailId,
            { responseType: 'blob' }
        );

        return res.data;
    }

    const _: never = fileType;
    return new Blob();
};

import { ListVehiclesParams } from './vehicles.api.types';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { VehicleRecord } from '../../../../../../server';
import { MetricUtils } from '../../../../../../core/utils/metricUtils';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../../../components/page/entityGrid/types/api';

export abstract class VehiclesUtils {
    static createRequestFilters = (params: ListVehiclesParams) => {
        return ServerUtils.createRequestFilters<VehicleRecord>([
            // Левая и правая границы слайдера
            params.loadCapacity?.[0] != null && ['loadCapacity', 'GE', MetricUtils.fromTonsToGrams(params.loadCapacity[0])],
            params.loadCapacity?.[1] != null && ['loadCapacity', 'LE', MetricUtils.fromTonsToGrams(params.loadCapacity[1])],
            // Левая и правая границы слайдера
            params.volume?.[0] != null && ['volume', 'GE', MetricUtils.fromCubicMetersToCubicMillimeters(params.volume[0])],
            params.volume?.[1] != null && ['volume', 'LE', MetricUtils.fromCubicMetersToCubicMillimeters(params.volume[1])],
            // Переключатель
            params.hideArchive ? ['archive', 'EQ', false] : undefined,
            // Единичный выбор из списка
            params.type && !Array.isArray(params.type) && ['type', 'EQ', params.type],
            params.type && Array.isArray(params.type) && ['type', 'IN', params.type],
            // Множественный выбор
            params.loadingTypes?.length && ['loadingTypes', 'IN', params.loadingTypes],
            // Левая и правая границы слайдера
            params.length?.[0] != null && ['length', 'GE', MetricUtils.fromMetersToMillimeters(params.length[0])],
            params.length?.[1] != null && ['length', 'LE', MetricUtils.fromMetersToMillimeters(params.length[1])],
            // Левая и правая границы слайдера
            params.width?.[0] != null && ['width', 'GE', MetricUtils.fromMetersToMillimeters(params.width[0])],
            params.width?.[1] != null && ['width', 'LE', MetricUtils.fromMetersToMillimeters(params.width[1])],
            // Левая и правая границы слайдера
            params.height?.[0] != null && ['height', 'GE', MetricUtils.fromMetersToMillimeters(params.height[0])],
            params.height?.[1] != null && ['height', 'LE', MetricUtils.fromMetersToMillimeters(params.height[1])],
            params.id && ['id', 'EQ', params.id],
        ]);
    };
    static vehiclesIntervalMapFunction: IntervalFiltersMetricsMap<VehicleRecord> = {
        loadCapacity: MetricUtils.fromGramsToTons,
        height: MetricUtils.fromMillimetersTiMeters,
        width: MetricUtils.fromMillimetersTiMeters,
        length: MetricUtils.fromMillimetersTiMeters,
        volume: MetricUtils.fromCubicMillimetersToCubicMeters,
    };
    static vehiclesIntervalFormatStyleMap: IntervalFiltersFormatsMap<VehicleRecord> = {
        loadCapacity: 'tons',
        volume: 'metersCubic',
        length: 'meters',
        width: 'meters',
        height: 'meters',
    };
}

import moment from "moment";
import {roundMaxFilterValue, roundMinFilterValue} from "../../../shared/util/utils";

export const elementsListFiltersPrepareData = (data, availableFilters) => {
    data.renterId = data.renterId && data.renterId.key ? data.renterId.key : data.renterId ? data.renterId : undefined;
    data.projectId = data.projectId && data.projectId.key ? data.projectId.key : data.projectId ? data.projectId : undefined;
    data.creationAuthorId =
        data.creationAuthorId && data.creationAuthorId.key
            ? data.creationAuthorId.key
            : data.creationAuthorId
                ? data.creationAuthorId
                : undefined;

    if (data.startDate) {
        const startDate = data.startDate.map((item) => (item ? moment(item).valueOf() : ''));
        if (!startDate[0] && !startDate[1]) data.startDate = undefined;
        else if (!startDate[1]) data.startDate = [startDate[0]];
        else data.startDate = startDate;
    }

    if (data.endDate) {
        const endDate = data.endDate.map((item) => (item ? moment(item).valueOf() : ''));
        if (!endDate[0] && !endDate[1]) data.endDate = undefined;
        else if (!endDate[1]) data.endDate = [endDate[0]];
        else data.endDate = endDate;
    }

    if (availableFilters) {
        if (data.discount) {
            if (
                data.discount.toString() ===
                [availableFilters['rentTerms.discount'][0], availableFilters['rentTerms.discount'][1]].toString()
            ) {
                data.discount = undefined;
            }
        }

        if (data.shiftCount) {
            if (
                data.shiftCount.toString() ===
                [
                    availableFilters['rentTerms.shifts.shiftCount'][0],
                    availableFilters['rentTerms.shifts.shiftCount'][1],
                ].toString()
            ) {
                data.shiftCount = undefined;
            }
        }

        if (data.finalTotalPrice) {
            if (
                data.finalTotalPrice.toString() ===
                [
                    roundMinFilterValue(availableFilters['finalTotalPrice'][0] / 100),
                    roundMaxFilterValue(availableFilters['finalTotalPrice'][1] / 100),
                ].toString()
            ) {
                data.finalTotalPrice = undefined;
            }
        }
    }

    if (data.hideInactive === false) data.hideInactive = undefined;

    data.page = 1;

    if (Array.isArray(data.typeCode) && data.typeCode.length === 0) {
        data.typeCode = undefined;
    }
    if (Array.isArray(data.tags) && data.tags.length === 0) {
        data.tags = undefined;
    }

    data.rentOrSubrent = undefined;
    data.parentEntityType = undefined;

    return data;
}
import React, {FC} from 'react';
import {LocalizationEnum} from '../../localization';
import {useLocalize} from "../../core/hooks/useLocalize";
import {isDefined} from "../../shared/util/utils";
import {DataDisplayItem, DataDisplayItemType} from "../dataDisplay";
import classNames from "classnames";
import {showConfirm} from "../confirm/showConfirm";
import {getIntl} from "../../App";
import {useAppDispatch} from "../../store/hooks";
import {setFinalTotalPrice, setTotalDiscount} from "../../modules/main/operationForm/reducers/operationForm.reducer";
import './operationIndicatorsBlock.less';

interface OperationIndicatorsBlockProps {
    editable?: boolean;
    showFinancialData: boolean;
    instanceCount: number;
    price: number;
    discount: number;
    finalTotalPrice: number;
    finalTotalPriceWithTaxes?: number;
}

export const OperationIndicatorsBlock:FC<OperationIndicatorsBlockProps> = ({showFinancialData, instanceCount, price, discount, finalTotalPrice, finalTotalPriceWithTaxes, editable}) => {
    const L = useLocalize();
    const withTaxRate = isDefined(finalTotalPriceWithTaxes);
    const className = 'rr-operation-indicators-block';
    const classNameElementsCount = `${className}-${showFinancialData ? (withTaxRate ? 6 : 4) : 1}el`;
    const colClassName = 'rr-operation-indicators-block-item';
    const colLabelClassName = `${colClassName}-label`;
    const colValueClassName = `${colClassName}-value`;
    const dispatch = useAppDispatch();

    return (
        <div className={classNames(className, classNameElementsCount)}>
            {
                <div className={colClassName}>
                    <DataDisplayItem
                        type={DataDisplayItemType.Number}
                        label={L(LocalizationEnum.ASPECT__RENT_INDICATORS__INSTANCE_COUNT)}
                        labelClassName={colLabelClassName}
                        valueClassName={colValueClassName}
                        value={instanceCount} />
                </div>
            }
            {
                showFinancialData &&
                <div className={colClassName}>
                    <DataDisplayItem
                        type={DataDisplayItemType.Money}
                        label={L(LocalizationEnum.ASPECT__RENT_INDICATORS__PRICE_BEFORE_DISCOUNT)}
                        labelClassName={colLabelClassName}
                        valueClassName={colValueClassName}
                        value={price} />
                </div>
            }
            {
                showFinancialData &&
                <div className={colClassName}>
                    <DataDisplayItem
                        type={DataDisplayItemType.Money}
                        label={L(LocalizationEnum.ASPECT__RENT_INDICATORS__DISCOUNT)}
                        labelClassName={colLabelClassName}
                        valueClassName={colValueClassName}
                        value={discount}
                        editable={editable}
                        max={price}
                        onBeforeBlur={(value)=>{
                            const confirm = showConfirm(getIntl(), 'Применить перерасчет скидки ко всем позициям в операции?');
                            confirm.then((yes)=>{
                                if(yes && isDefined(value)) dispatch(setTotalDiscount(value));
                            });
                            return confirm;
                        }}
                    />
                </div>
            }
            {
                showFinancialData && withTaxRate &&
                <div className={colClassName}>
                    <DataDisplayItem
                        type={DataDisplayItemType.Money}
                        label={L(LocalizationEnum.ASPECT__RENT_INDICATORS__COST_BEFORE_TAXES)}
                        labelClassName={colLabelClassName}
                        valueClassName={colValueClassName}
                        value={finalTotalPrice}
                        editable={editable}
                        onBeforeBlur={(value)=>{
                            const confirm = showConfirm(getIntl(), 'Применить перерасчет скидки ко всем позициям в операции?');
                            confirm.then((yes)=>{
                                if(yes && isDefined(value)) dispatch(setFinalTotalPrice(value));
                            });
                            return confirm;
                        }}
                    />
                </div>
            }
            {
                showFinancialData && withTaxRate &&
                <div className={colClassName}>
                    <DataDisplayItem
                        type={DataDisplayItemType.Money}
                        label={L(LocalizationEnum.ASPECT__RENT_INDICATORS__TAX_RATE)}
                        labelClassName={colLabelClassName}
                        valueClassName={colValueClassName}
                        value={finalTotalPriceWithTaxes - finalTotalPrice} />
                </div>
            }
            {
                showFinancialData &&
                <div className={classNames(colClassName, `${colClassName}-total`)}>
                    <DataDisplayItem
                        type={DataDisplayItemType.Money}
                        label={L(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}
                        value={withTaxRate ? finalTotalPriceWithTaxes : finalTotalPrice}
                        labelClassName={colLabelClassName}
                        valueClassName={colValueClassName}
                    />
                </div>
            }
        </div>
    );
}

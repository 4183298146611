import React from 'react';
import { Button, Icon, Popover, Radio } from 'antd';
import { IconSave } from '../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../localization';
import { rulesList } from '../../../../config/rules_ru';
import { UserBARoleCodeEnum } from '../../../../server';
import { getStoreState } from '../../../../../index';

interface IProps {
    roles: UserBARoleCodeEnum[];
    role?: UserBARoleCodeEnum;
    onChange: (role?: UserBARoleCodeEnum) => void;
}

interface IState {
    role?: UserBARoleCodeEnum;
    visible: boolean;
}

const radioStyle = {
    display: 'block',
    height: 30,
    lineHeight: '30px',
    marginBottom: 10,
};

export class UsersModuleRoleSelectPopover extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            role: this.props.role,
            visible: false,
        };
    }

    handleVisibleChange = (visible: boolean) => {
        this.setState({ visible });
        if (visible) {
            this.setState({ role: this.props.role });
        }
    };

    render() {
        let btnDisabled = this.state.role === this.props.role;
        return (
            <Popover
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
                overlayClassName={'rr-grid-actions-popover'}
                autoAdjustOverflow
                arrowPointAtCenter
                placement="bottomLeft"
                trigger="click"
                content={
                    <>
                        <div className={'rr-grid-actions-popover-header'}>
                            <span style={{ marginRight: 8 }}>Роль пользователя</span>
                        </div>
                        <div className={'rr-grid-actions-popover-content'}>
                            <div style={{ padding: '20px 24px 10px 24px' }}>
                                <Radio.Group
                                    onChange={(e) => {
                                        this.setState({ role: e.target.value });
                                    }}
                                    value={this.state.role}
                                >
                                    {rulesList.map((el, index: number) => {
                                        let disabled = false;
                                        if (el.value === UserBARoleCodeEnum.ADMIN || el.value === UserBARoleCodeEnum.AGENT) {
                                            if (
                                                !this.props.roles.includes(UserBARoleCodeEnum.ADMIN) &&
                                                !this.props.roles.includes(UserBARoleCodeEnum.AGENT)
                                            ) {
                                                let managersCountLimit =
                                                    getStoreState().businessAccount.entity?.managerRolesUserCounters?.userCountLimit || 0;
                                                let managersCount =
                                                    getStoreState().businessAccount.entity?.managerRolesUserCounters?.userCount || 0;
                                                let managersActiveInvitationCount =
                                                    getStoreState().businessAccount.entity?.managerRolesUserCounters
                                                        ?.activeInvitationCount || 0;
                                                if (managersCount + managersActiveInvitationCount >= managersCountLimit) {
                                                    disabled = true;
                                                }
                                            }
                                        }
                                        return (
                                            <Radio key={index} style={radioStyle} value={el.value} disabled={disabled}>
                                                <span>{el.name}</span>
                                            </Radio>
                                        );
                                    })}
                                </Radio.Group>
                            </div>
                            <Button
                                disabled={btnDisabled}
                                block
                                onClick={(e) => {
                                    this.setState({ visible: false });
                                    if (this.props.onChange) this.props.onChange(this.state.role);
                                }}
                                style={{ paddingLeft: 20 }}
                                className={'rr-entity-action-TO_ARCHIVE-color'}
                            >
                                <Icon
                                    component={IconSave}
                                    style={{
                                        color: '#383F77',
                                        fontSize: '24px',
                                    }}
                                />
                                <span>{localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}</span>
                            </Button>
                        </div>
                    </>
                }
            >
                {this.props.children}
            </Popover>
        );
    }
}

import React, { FC, RefObject, useMemo, useRef } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';

import { createFormItem } from '../../../../../../../../../components/dynamicForm/formUtils';
import { useIntl } from 'react-intl';
import _, { mapValues } from 'lodash';
import { accessibilityMapCalendarFiltersDescription } from '../../../../filters/accessibility/accessibilityMapCalendarFilters.description';
import debounce from 'lodash/debounce';
import { AccessibilityElementsCalendarFilters } from '../../../../filters/elements/accessibilityElementsCalendarFilters.description';
import { ParamsUtils } from '../../../../../../../../../core/utils/paramsUtils';
import { setGridStorageDataFilters } from '../../../../../../../../../components/grid/utils';
import { accessibilityMapCalendarPageName } from '../../../../AccessibilityMapCalendar';
import { push } from 'connected-react-router';
import { getPathFromState } from '../../../../../../../../../shared/util/utils';
import { useAppDispatch } from '../../../../../../../../../store/hooks';
import { useLocation } from 'react-router-dom';
import { AccessibilityMapFilters } from '../../../../filters/accessibility/accessibilityMapCalendarFilters.types';
import { accessibilityElementsCalendarHideFilters } from '../../../../filters/elements/accessibilityElementsCalendarFilters';

export interface _AccessibilityCalendarHideFormProps {
    pageParams: AccessibilityMapFilters;
    filtersContainerRef: RefObject<HTMLDivElement>;
    form: WrappedFormUtils;
}

const _AccessibilityCalendarHideForm: FC<_AccessibilityCalendarHideFormProps> = (props) => {
    const { filtersContainerRef, form, pageParams } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const initialValues = useRef({});
    const defaultValues = useMemo(() => mapValues(accessibilityMapCalendarFiltersDescription, () => undefined), []);

    const currentValues = useMemo(
        () => ({
            ...defaultValues,
            ...pageParams,
        }),
        [defaultValues, pageParams]
    );

    const onChange = useMemo(
        () =>
            debounce((params: AccessibilityElementsCalendarFilters) => {
                if ('getFieldsValue' in params) return;

                const newParams = _.cloneDeep(
                    ParamsUtils.getProcessedParamsWithSelectors({
                        pageParams: {
                            ...pageParams,
                            ...params,
                        },
                    })
                );

                setGridStorageDataFilters(accessibilityMapCalendarPageName, newParams);
                dispatch(push(getPathFromState(location.pathname, location.search, newParams)));
            }, 300),
        [dispatch, location.pathname, location.search, pageParams]
    );

    return (
        <Form layout={'vertical'}>
            {accessibilityElementsCalendarHideFilters[0].fields.map((item, index) =>
                createFormItem(
                    item,
                    index,
                    {
                        form,
                        data: accessibilityElementsCalendarHideFilters,
                        intl,
                        onChange,
                        initialValues: initialValues.current,
                        currentValues,
                        defaultValues,
                        highlightChangedFields: true,
                    },
                    filtersContainerRef.current
                )
            )}
        </Form>
    );
};

export const AccessibilityCalendarHideForm = Form.create<_AccessibilityCalendarHideFormProps>()(_AccessibilityCalendarHideForm);

import { FileTypeCodeEnum, serverApi } from '../../../../../server';
import { AxiosResponse } from 'axios';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';

export interface ProductsInventoryListGenerateArgs {
    businessAccountId: number;
    common: boolean;
    documentTemplateId: number;
    showBarcode: boolean;
    expandInstances: boolean;
    showPurchaseCost: boolean;
    showCarryingValue: boolean;
    expandInstanceStateDescription: boolean;
    showColumnActualAvailability: boolean;
    showColumnConvergence: boolean;
    showColumnNotes: boolean;
    groupByCategories: boolean;
    includeSubrentedInstances: boolean;
    withSupplier: boolean;
    filters?: Array<string>;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    search?: string;
    legalDetailId?: number;
}

export const generateProductsInventoryList = async (
    args: ProductsInventoryListGenerateArgs,
    fileType: FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
): Promise<Blob> => {
    if (fileType === FileTypeCodeEnum.EXCEL) {
        const res: AxiosResponse<Blob> = await serverApi.generateProductsInventoryListDocumentXlsx(
            args.businessAccountId,
            args.common,
            args.documentTemplateId,
            args.showBarcode,
            args.expandInstances,
            args.includeSubrentedInstances,
            args.showPurchaseCost,
            args.showCarryingValue,
            args.expandInstanceStateDescription,
            args.showColumnActualAvailability,
            args.showColumnConvergence,
            args.showColumnNotes,
            args.withSupplier,
            args.groupByCategories,
            getCurrentTimezoneUTCOffset(),
            undefined,
            undefined,
            args.legalDetailId,
            undefined,
            1000,
            0,
            args.sortBy,
            args.sortOrder,
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    }

    if (fileType === FileTypeCodeEnum.PDFTYPE) {
        const res: AxiosResponse<Blob> = await serverApi.generateProductsInventoryListDocumentPdf(
            args.businessAccountId,
            args.common,
            args.documentTemplateId,
            args.showBarcode,
            args.expandInstances,
            args.includeSubrentedInstances,
            args.showPurchaseCost,
            args.showCarryingValue,
            args.expandInstanceStateDescription,
            args.showColumnActualAvailability,
            args.showColumnConvergence,
            args.showColumnNotes,
            args.withSupplier,
            args.groupByCategories,
            getCurrentTimezoneUTCOffset(),
            undefined,
            undefined,
            args.legalDetailId,
            undefined,
            1000,
            0,
            args.sortBy,
            args.sortOrder,
            args.search,
            { query: { filters: args.filters }, responseType: 'blob' }
        );

        return res.data;
    }

    const _: never = fileType;
    return new Blob();
};

import { ColumnTypes, TableColumn } from '../../../../../../../../../../components/grid/Table';
import { LocalizationEnum, localize } from '../../../../../../../../../../localization';
import { MAX_WIDTH } from '../../../../../../../../../../config/constants';
import { Icon } from 'antd';
import { IconStarSolid } from '../../../../../../../../../../components/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { getBusinessAccountId } from '../../../../../../../../../../../index';
import { getPathFromState } from '../../../../../../../../../../shared/util/utils';
import { formatMoneyWithFixedDecimalScale } from '../../../../../../../../../../shared/util/formatMoney';
import { CrewMemberInfoRead, ProfessionAssignmentObjRead } from '../../../../../../../../../../server';
import { crewPageUrlRoute } from '../../../../../../../utils/tabs';
import { CrewMemberPageTabsEnum, CrewPageTabsEnum } from '../../../../../../../../../../shared/constants/tabEnums';

export type ProfessionsCompositionListColumnsObj = ProfessionAssignmentObjRead & {
    memberOrder: number;
    crewMember: CrewMemberInfoRead;
    main?: boolean;
};

export const professionsCompositionColumns: TableColumn<ProfessionsCompositionListColumnsObj>[] = [
    {
        title: localize(LocalizationEnum.PAGE__KITS__ORDER),
        dataIndex: 'memberOrder',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        maxWidth: MAX_WIDTH(150),
        render: (value: number, data: ProfessionsCompositionListColumnsObj) => {
            return (
                <div>
                    {data.main ? (
                        <Icon style={{ marginBottom: '1px', marginRight: '4px', fontSize: '18px' }} component={IconStarSolid} />
                    ) : null}
                    #{+value + 1}
                </div>
            );
        },
        resizable: true,
        sortable: false,
        dontHide: true,
    },
    {
        title: 'Специальность',
        dataIndex: 'professionShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 250,
        maxWidth: MAX_WIDTH(250),
        resizable: true,
        dontHide: true,
        sortable: false,
        render: (value: string, data: ProfessionsCompositionListColumnsObj) => {
            const { professionId, crewMember } = data;

            const drawerLink = getPathFromState(
                `/${getBusinessAccountId()}/${crewPageUrlRoute}/${CrewPageTabsEnum.CREW_MEMBERS}/${crewMember.id}`,
                '',
                {
                    tab: CrewMemberPageTabsEnum.PROFESSIONS,
                    modal: 'view',
                    id: professionId,
                }
            );

            return (
                <div className={'rr-grid-shortName-cell'}>
                    <Link to={drawerLink} style={{ color: 'unset' }}>
                        {value}
                    </Link>
                </div>
            );
        },
    },
    {
        title: 'Тип ставки',
        dataIndex: 'rate',
        type: ColumnTypes.CustomRender,
        minWidth: 210,
        maxWidth: MAX_WIDTH(210),
        resizable: true,
        sortable: false,
        render: (value: string, data: ProfessionsCompositionListColumnsObj) => {
            const currentValue = value != null ? 'Фиксированная' : 'Базовая';
            const currentColor = value != null ? undefined : '#CCCCCC';

            return <span style={{ color: currentColor }}>{currentValue}</span>;
        },
    },
    {
        title: 'Ставка за смену',
        dataIndex: 'rate.ratePerShift',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 250,
        maxWidth: MAX_WIDTH(250),
        resizable: true,
        sortable: false,
        render: (value: number | undefined, data: ProfessionsCompositionListColumnsObj) => {
            const {
                crewMember: {
                    defaultRates: { ratePerShift },
                },
            } = data;
            const currentValue = value != null ? value : ratePerShift;
            const currentColor = value != null ? undefined : '#CCCCCC';

            return <span style={{ color: currentColor }}>{formatMoneyWithFixedDecimalScale(currentValue)}</span>;
        },
    },
    {
        title: 'Ставка за час',
        dataIndex: 'rate.ratePerHour',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 250,
        maxWidth: MAX_WIDTH(250),
        resizable: true,
        sortable: false,
        render: (value: number | undefined, data: ProfessionsCompositionListColumnsObj) => {
            const {
                crewMember: {
                    defaultRates: { ratePerHour },
                },
            } = data;
            const currentValue = value != null ? value : ratePerHour;
            const currentColor = value != null ? undefined : '#CCCCCC';

            return <span style={{ color: currentColor }}>{formatMoneyWithFixedDecimalScale(currentValue)}</span>;
        },
    },
];

import React from 'react';

export const useFormatDecimal = (value: number, precision?: number, type?: 'number' | 'percents', withoutDots?: boolean) => {
    let v = '';
    let tv = '';
    let withTitle = false;
    const dots = '…';

    if (value !== null && value !== undefined) {
        v = '' + value;
        let p = v.split('.');
        let p1;
        if (precision !== undefined && p[1] && p[1].length > precision) {
            p1 = p[1].substr(0, precision) + (withoutDots ? '' : dots);
            withTitle = true;
        } else {
            p1 = p[1];
        }
        v = p[0] + (p1 ? '.' + p1 : '');
        tv = '' + value;
        if (type === 'percents') {
            v += '%';
            tv += '%';
        }
    }

    return <span title={withTitle && !withoutDots ? '' + tv : undefined}>{v}</span>;
};

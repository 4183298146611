import React from 'react';
import {connect} from "react-redux";
import {IRootState} from "../../../shared/reducers";
import {BasePage, IBasePageProps} from "../../../components/page/BasePage";
import {push} from "connected-react-router";
import {SUPPORT_SERVER_BASE_PATH} from "../../../config/config";
import {IPageHeaderProps} from "../../../components/pageHeader/pageHeader";
import {iframeBaseParams} from "./support-page";
import Spin from "../../../components/spin/spin";
import './support-page.less';
import {Col, Row} from "antd";

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

interface IState {
    frameHeight?: number;
    frameIsLoading: boolean;
}

class SupportCreateRequestPage extends BasePage<IProps, IState> {

    constructor(props: IProps){
        super(props);
        this.state = {
            frameHeight: 0,
            frameIsLoading: true
        };
    }

    handleIframeTask = (e:MessageEvent) => {
        if(e.data){
            let { type } = e.data;
            if(type === 'sd_create_resized') {
                this.setState({frameHeight: e.data.height});
            }else if(type === 'sd_create_cancel') {
                this.props.push(`/${this.props.businessAccountId}/support`);
            } else if (type === 'sd_view_wiki') {
                this.props.push(`/${this.props.businessAccountId}/kb/${e.data.page_id}`);
            }else if(type === 'sd_create_created') {
                this.props.push(`/${this.props.businessAccountId}/support/requests/${e.data.request_id}`);
            }
        }
    };

    componentDidMount () {
        window.addEventListener('message', this.handleIframeTask);
    }

    componentWillUnmount () {
        window.removeEventListener('message', this.handleIframeTask);
    }

    getPageHeaderProps():IPageHeaderProps {
        return {
            name: 'Создание запроса поддержки',
            parentPagePath: `/${this.props.businessAccountId}/support`
        };
    }

    frameOnLoad = (e) => {
        this.setState((state, props) => {
            let newState = {...state,frameIsLoading:false};
            if (!newState.frameHeight) newState.frameHeight = 100;
            return newState;
        });
    };

    renderContent() {
        const frameProps:React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = {
            src: `${SUPPORT_SERVER_BASE_PATH}/plugins/servlet/ru.rentrabbit.jira.jira-plugin/iframe/${this.props.userUUID}/${this.props.businessAccountId}/create/${this.props.requestTypeId}?os_authType=none`,
            height: this.state.frameHeight,
            onLoad: this.frameOnLoad
        };

        return (
            <Row type="flex" justify="center">
                <Col span={24} style={{maxWidth: '690px'}}>

                    <Spin delay={0} spinning={this.state.frameIsLoading} wrapperClassName={'rr-support-page-spin'}>
                        <iframe {...iframeBaseParams} {...frameProps} title={'Новый запрос'} />
                    </Spin>

                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = { push };

const mapStateToProps = (storeState: IRootState, ownProps:IBasePageProps) => ({
    businessAccountId: storeState.system.businessAccountId,
    userUUID: storeState.oidc.user ? storeState.oidc.user.profile.sub : '',
    requestTypeId: ownProps.match.params['requestTypeId']
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupportCreateRequestPage);
import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum } from '../../../../../../localization';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: LocalizationEnum.PAGE__PRODUCTS__GROUP_NAME,
                id: 'name',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
            },
        ],
    },
];

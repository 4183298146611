import React, { useContext } from 'react';
import { CalendarItemRendererProps } from '../../../../components/CalendarItemRenderer/CalendarItemRenderer';
import { CalendarLineContainer } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarLineContainer/CalendarLineContainer';
import { CALENDAR_LINE_BASE_RADIUS, CALENDAR_LINE_ROUND_RADIUS } from '../../../../components/Calendar/utils/data/calendarConstants';
import { ElementItemContext } from '../../../../components/CalendarItemRenderer/context/CalendarItemContext';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { CalendarItem as ICalendarItem, ElementsCalendarItem as IElementsCalendarItem } from '../../../../components/Calendar/types/items';
import { CalendarLineUtils } from '../../../../components/CalendarItemRenderer/utils/calendarLineUtils';
import {
    convertNestedRentElementRecordToRentElementsGridItem,
    convertRentElementRecordToRentElementsGridItem,
} from '../../../../../../../types';
import { ElementsCalendarItem } from '../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarItem/variants/ElementsCalendarItem';
import { ElementsElementPopover } from '../../../../../elements/elements-element-popover';
import { TimeRangeContext } from '../../../../components/CalendarItemRenderer/context/TimeRangeContext';
import classNames from 'classnames';
import { isGiveawayDelayProblemForItemElement } from '../../utils/checks/isGiveawayDelayProblemForRentElement';

export const ElementsCalendarItemRenderer = <SortValue extends string>(
    props: CalendarItemRendererProps<SortValue, IElementsCalendarItem>
) => {
    const { baseProps, calendarProps } = props;
    const { onItemClick, displayType, onPopoverVisibleChange } = baseProps;
    const { item, itemContext } = calendarProps;
    const { kitId, isKitChild, elementType, start_time, end_time } = item;
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);

    const popoverPlacement = CalendarLineUtils.measures.placement(item, itemContext, visibleTimeStart, visibleTimeEnd);

    const convertedItem = isKitChild
        ? convertNestedRentElementRecordToRentElementsGridItem(item.parentRecord, item.record, {
              parent: convertRentElementRecordToRentElementsGridItem(item.parentRecord),
          })
        : convertRentElementRecordToRentElementsGridItem(item.record);

    const isGiveawayDelayProblem = isGiveawayDelayProblemForItemElement(item);
    const borderRadius =
        elementType === 'subrent'
            ? CALENDAR_LINE_ROUND_RADIUS
            : isGiveawayDelayProblem
            ? (`${CALENDAR_LINE_ROUND_RADIUS}px 0 0 ${CALENDAR_LINE_ROUND_RADIUS}px` as const)
            : CALENDAR_LINE_BASE_RADIUS;

    // Обязательный костыль, гнилой antd поповер требует кликабельный дочерний react(!) контейнер
    const jsx = CalendarLineContainer({
        calendarProps: calendarProps,
        displayType: displayType,
        borderRadius: borderRadius,
        onItemClick: onItemClick,
        linkPath: undefined,
        className: classNames('item', {
            'left-boundary': +start_time <= visibleTimeStart,
            'right-boundary': +end_time >= visibleTimeEnd,
        }),
        children: (
            <ElementItemContext.Provider value={calendarProps as ReactCalendarItemRendererProps<ICalendarItem<IElementsCalendarItem>>}>
                <ElementsCalendarItem />
            </ElementItemContext.Provider>
        ),
    });

    return kitId ? (
        jsx
    ) : (
        <ElementsElementPopover
            entityId={convertedItem.id}
            record={convertedItem}
            onClosePopover={onPopoverVisibleChange}
            placement={popoverPlacement}
        >
            {jsx ?? undefined}
        </ElementsElementPopover>
    );
};

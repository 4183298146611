import React, {ReactNode} from 'react';
import {removeInstances, removeRentElement} from "../../reducers/operationForm.reducer";
import {connect} from "react-redux";
import {showConfirm} from "../../../../../components/confirm/showConfirm";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {OperationTypeCodeEnum} from "../../../../../server";
import {getStore} from "../../../../../../index";
import {IRootState} from "../../../../../shared/reducers";
import {canViewFinancialData} from "../../../../../shared/util/permissionUtils";

interface IProps extends DispatchProps, WrappedComponentProps, StateProps {
    children: () => ReactNode;
    id: number;
    disabled: boolean;
}

class _OperationFormKitRemovePopover extends React.Component<IProps> {

    onClick = async (e) => {
        let yes = await showConfirm(this.props.intl, 'Убрать набор из операции?');
        if (yes) {
            if(this.props.operationTypeCode === OperationTypeCodeEnum.EDIT && this.props.selectedIds && this.props.selectedIds.length > 0){
                getStore().dispatch(removeRentElement(this.props.id));
                return;
            }
            this.props.removeInstances(this.props.id, 1);
        }
    };

    render() {
        return (
            <span onClick={this.onClick}>
                {
                    this.props.children()
                }
            </span>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    operationTypeCode: storeState.operationForm.typeCode,
    selectedIds: storeState.operationForm.elements.selectedIds
});

const mapDispatchToProps = {removeInstances, removeRentElement};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationFormKitRemovePopover = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_OperationFormKitRemovePopover));

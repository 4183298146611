import { FileTypeCodeEnum } from '../../../../../../server';
import { DownloadDocumentPopoverProps, IDownloadDocumentParamsItem } from '../../../DownloadDocumentPopover';
import { LocalizationEnum, localize } from '../../../../../../localization';

export enum ExportEstimatePopoverOptions {
    EXPAND_KITS = 'expandKits',
    FREE_ITEMS = 'freeItems',
    JOIN_SIMILAR_ROWS = 'joinSimilarRows',
    WORK_PLANNINGS = 'workPlannings',
    EXPENSES = 'expenses',
}

export const defaultOptions: IDownloadDocumentParamsItem[] = [
    {
        key: ExportEstimatePopoverOptions.EXPAND_KITS,
        label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS),
    },
    { key: ExportEstimatePopoverOptions.FREE_ITEMS, label: 'Бесплатные позиции' },
    { key: ExportEstimatePopoverOptions.JOIN_SIMILAR_ROWS, label: 'Одинаковые в одну строку' },
    { key: ExportEstimatePopoverOptions.WORK_PLANNINGS, label: 'Работы' },
    { key: ExportEstimatePopoverOptions.EXPENSES, label: 'Затраты' },
];

export const availableFileTypes = [FileTypeCodeEnum.EXCEL, FileTypeCodeEnum.PDFTYPE] as const;
export type EstimateFileType = (typeof availableFileTypes)[number];
export type EstimateDocumentOnDownload = DownloadDocumentPopoverProps<EstimateFileType, ExportEstimatePopoverOptions>['onDownload'];

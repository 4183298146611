import React, {FC} from 'react';
import {Popover as PopoverComponent} from "antd";
import {PopoverProps as PopoverComponentProps} from "antd/lib/popover";
import classNames from "classnames";
import './popover.less';

interface PopoverProps extends Pick<PopoverComponentProps, 'placement' | 'visible' | 'onVisibleChange' | 'overlayClassName' | 'content' | 'trigger' | 'getPopupContainer'> {
}

export const Popover:FC<PopoverProps> = ({children, placement, content, overlayClassName, visible, onVisibleChange, trigger = 'click', getPopupContainer}) => {
    return (
        <PopoverComponent
            onVisibleChange={onVisibleChange}
            overlayClassName={classNames('rr-popover', overlayClassName)}
            placement={placement}
            autoAdjustOverflow
            arrowPointAtCenter
            trigger={trigger}
            visible={visible}
            getPopupContainer={getPopupContainer}
            content={
                <>
                    <div className={'rr-popover-header'}></div>{/*TODO Где-то есть заголовок и иконка в шапке, где-то нет*/}
                    <div className={'rr-popover-body'}>{content}</div>
                </>
            }
        >
            {children}
        </PopoverComponent>

    );
};

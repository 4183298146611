import { EntityGridProps } from '../../../../../../../components/page/entityGrid/types/grid';
import { useCallback, useEffect, useMemo } from 'react';
import { ProductsListQueryParams } from '../../../../../inventory/products/api/products.api.types';
import { CategoryTypeCodeEnum } from '../../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { loadCategories } from '../../../../../../../shared/reducers/entities.reducer';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { useEntityGridContext } from '../../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useCategoryIds } from '../../../../../inventory/products/hooks/useCategoryIds';
import { PageUrlParamsObject } from '../../../../../../../core/hooks/urlParams/types';
import {NomenclaturesListQueryParams} from "../../../../api/operationForm.api";

export const useCategoriesProps = (
    pageParamsObject: PageUrlParamsObject<NomenclaturesListQueryParams>
): EntityGridProps<never, never, never>['categoriesProps'] => {
    const { pageParams, updatePageParams } = pageParamsObject;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();

    const categoryIds = useCategoryIds(pageParams);

    const categoriesOnSelect = useCallback(
        (selectedKeys?: string[]) => {
            if (selectedKeys == null) return;

            gridRef?.current?.clearSelection();
            updatePageParams({
                page: 1,
                categoryIds: selectedKeys.map(Number),
            });
        },
        [gridRef, updatePageParams]
    );

    useEffect(() => {
        dispatch(loadCategories(businessAccountId, CategoryTypeCodeEnum.PRODUCT));
    }, [businessAccountId, dispatch]);

    return useMemo(
        () =>
            ({
                categoriesType: 'PRODUCT',
                categoriesFilterOffsetBottom: 131,
                categoriesSelectedKeys: categoryIds,
                categoriesOnSelect: categoriesOnSelect,
                categoriesFilter: true,
            } satisfies EntityGridProps<never, never, never>['categoriesProps']),
        [categoriesOnSelect, categoryIds]
    );
};

export enum DataDisplayItemType {
    /**
     * Любая строка
     */
    String = 'String',

    /**
     * Число, если 0, то серое
     */
    Number = 'Number',

    /**
     * Логическое
     */
    Boolean = 'Boolean',

    /**
     * Комментарий
     */
    Comment = 'Comment',

    /**
     * Дата со временем
     */
    DateWithTime = 'DateWithTime',

    /**
     * Интервал дат со временем
     */
    DateWithTimeInterval = 'DateWithTimeInterval',

    /**
     * Дата без времени
     */
    Date = 'Date',

    /**
     * Почта со ссылкой
     */
    EMail = 'EMail',

    /**
     * Номер телефона со ссылкой
     */
    Phone = 'Phone',

    /**
     * Пользователь системы, название со ссылкой на пользователя
     */
    User = 'User',

    /**
     * Контрагент, название со ссылкой на контрагента
     */
    Counterparty = 'Counterparty',

    /**
     * Контакт, название со ссылкой на Контакт
     */
    Contact = 'Contact',

    /**
     * Проект, название со ссылкой на поставку
     */
    Project = 'Project',

    /**
     * Поставка, название со ссылкой на поставку
     */
    Shipping = 'Shipping',

    /**
     * Деньги
     */
    Money = 'Money',

    /**
     * Цена / Может быть с ценовой схемой
     */
    Price = 'Price',

    /**
     * Продукт, Продукт: Вариант
     */
    Product = 'Product',

    /**
     * Список экземпляров
     */
    InstancesList = 'InstancesList',

    /**
     * Иконка и текст
     */
    IconText = 'IconText',

    /**
     * Картинка
     */
    Image = 'Image',

    /**
     * Кастомный компонент
     */
    Custom = 'Custom',

    /**
     * Ссылка
     */
    Link = 'Link',

    /**
     * Ссылка
     */
    SocialNetworkLink = 'SocialNetworkLink',

    /**
     * Выдвижной попап
     */
    Drawer = 'Drawer',

    /**
     * Текст в HTML
     */
    RichText = 'RichText',

    /**
     * Ценовая схема
     */
    PricingScheme = 'PricingScheme',

    /**
     * Длительность смены
     */
    ShiftDuration = 'ShiftDuration',
}

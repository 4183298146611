import {ColumnTypes, TableColumn} from '../../../../components/grid/Table';
import React from 'react';
import {LocalizationEnum, localize} from '../../../../localization';
import {PricingSchemeRecord} from '../../../../server';
import {Icon} from 'antd';
import {joinClasses} from '../../../../shared/util/utils';
import {getStoreState} from '../../../../../index';
import {PricingSchemeUtils} from '../../../../core/utils/pricingSchemeUtils';
import {pricingSchemeActionsMap, PricingSchemeActionType} from "./pricingSchemeEditModalData";
import {canUpdateFinancialDataSelector} from "../../../../shared/reducers/permissions.reducer";
import {TblColumnType} from "../../../../core/tblColumns/tblColumns.types";
import {tblColumn} from "../../../../core/tblColumns/tblColumns";

// GRID COLUMNS
export const pricingSchemesModuleListColums: TableColumn<PricingSchemeRecord>[] = [
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
        dataIndex: 'name',
        minWidth: 320,
        type: ColumnTypes.CustomRender,
        resizable: true,
        render: (value: any, rowData: PricingSchemeRecord) => (
            <div
                style={{ marginLeft: 35 }}
                className={joinClasses('rr-grid-shortName-cell', 'main-value', 'app-link-underline')}
                title={rowData.name}
            >
                {rowData.name}
            </div>
        ),
        dontHide: true,
    },
    {
        title: 'Тип',
        dataIndex: 'typeCode',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: any, rowData: PricingSchemeRecord) => {
            const { typeTitle, typeIcon } = PricingSchemeUtils.getPricingSchemeData(rowData.typeCode);

            return (
                <>
                    <Icon style={{ fontSize: 21, marginRight: 10, color: '#3d64d4' }} component={typeIcon} />
                    <span>{typeTitle}</span>
                </>
            );
        },
    },
    {
        title: 'Число продуктов',
        dataIndex: 'linkedProductCount',
        type: ColumnTypes.String,
        minWidth: 120,
        resizable: false,
    },
    {
        title: 'Число специальностей',
        dataIndex: 'linkedProfessionsCount',
        type: ColumnTypes.String,
        minWidth: 120,
        resizable: false,
    },
    {
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
        type: ColumnTypes.Date,
        minWidth: 120,
        resizable: false,
    },
    tblColumn(
        {
            type: TblColumnType.SETTINGS_ACTIONS,
            actionsDataMap: pricingSchemeActionsMap,
            numberOfIcons: 2,
            getData: (row) => {
                let canUpdateFinancialData = canUpdateFinancialDataSelector(getStoreState());
                let canEdit = canUpdateFinancialData;
                let canDelete =
                    row.linkedProductCount === 0 &&
                    row.linkedProfessionsCount === 0 &&
                    canUpdateFinancialData;
                return {
                    mainActions: [
                        {type: PricingSchemeActionType.edit, disabled: !canEdit},
                        {type: PricingSchemeActionType.delete, disabled: !canDelete}
                    ]
                };
            }
        }
    )
];

import React from 'react';
import {Button, Icon} from "antd";
import {ButtonProps} from "antd/lib/button/button";
import {IconClose} from "../../icons";

interface OperationResetButtonProps {
    onClick?:ButtonProps['onClick'];
    style?:ButtonProps['style'];
    title?: string;
}

export const OperationResetButton = (props: OperationResetButtonProps) => {
    return (
        <Button
            type={'link'} style={{paddingLeft: 0, paddingRight: 0, color: '#f4516c', fontWeight: 500, display: 'flex', alignItems: 'center', ...props.style}}
            onClick={props.onClick}
        >
            <Icon component={IconClose} style={{fontSize: 22, lineHeight: 0}} />
            {props.title || 'Сбросить изменения'}
        </Button>
    )
};
export type BarcodeScanEvent = {code: string};

interface IEventTarget {
    addListener(callback: (evt: CustomEvent<BarcodeScanEvent>) => void): void
    dispatch(event: BarcodeScanEvent): boolean;
    removeListener(callback: (evt: CustomEvent<BarcodeScanEvent>) => void): void
}

export class BarcodeScanEventTarget extends EventTarget implements IEventTarget {
    private readonly targetType = 'barcode-scan-event';

    constructor() {
        super();
    }

    addListener(callback: (evt: CustomEvent<BarcodeScanEvent>) => void): void {
        return this.addEventListener(this.targetType, callback as (evt: Event) => void);
    }

    dispatch(event: BarcodeScanEvent): boolean {
        return this.dispatchEvent(new CustomEvent(this.targetType, { detail: event }));
    }

    removeListener(callback: (evt: CustomEvent<BarcodeScanEvent>) => void): void {
        return this.removeEventListener(this.targetType, callback as (evt: Event) => void);
    }
};

import React from 'react';
import { LocalizationEnum, localizeIntl } from '../../../localization';
import { AxiosResponse } from 'axios';
import { ConcurrentOperationObjList, RentActivityFrameTypeCodeEnum, serverApi } from '../../../server';
import { showNotification } from '../../../components/notification/showNotification';
import { IntlShape } from 'react-intl';
import { showConcurrentOperationsConfirm } from './concurrentOperations/showConcurrentOperationsConfirm';
import { getStore } from '../../../../index';

export const listConcurrentOperationsAndShowConfirm = async (
    intl: IntlShape,
    businessAccountId: number,
    _activityFrameId: number | number[],
    activityFrameType: RentActivityFrameTypeCodeEnum,
    compareWithUUID: boolean = true
): Promise<boolean> => {
    let activityFrameId: number[];
    if (!Array.isArray(_activityFrameId)) {
        activityFrameId = [_activityFrameId];
    } else {
        activityFrameId = _activityFrameId;
    }

    return new Promise(async (resolve, reject) => {
        let createOperation = true;
        let concurrentOperationsResp: AxiosResponse<ConcurrentOperationObjList>[] | undefined;

        try {
            concurrentOperationsResp = (await Promise.all(
                activityFrameId.map((activityFrameId) =>
                    serverApi.listConcurrentOperations(businessAccountId, activityFrameId, activityFrameType)
                )
            )) as unknown as AxiosResponse<ConcurrentOperationObjList>[];
        } catch (e) {
            showNotification(
                'error',
                <span>
                    {localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)}.<br />
                    {'Не удалось получить список операций'}
                </span>
            );
            reject(e);
            return;
        }

        const state = getStore().getState();
        const uuid = state.operationForm.currentOperationUUID;
        const operations = concurrentOperationsResp!
            .map((resp) => resp.data.records)
            .flat(1)
            .filter((r) => !compareWithUUID || r.uuid !== uuid);
        const allowConcurrentOperationsPermission = !!state.businessAccountPreferences.preferences?.allowConcurrentOperations;

        if (operations.length > 0) {
            createOperation = await showConcurrentOperationsConfirm(intl, operations, allowConcurrentOperationsPermission);
        }
        resolve(createOperation);
    });
};

import React from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { getColumnMaxWidth } from './ReactTable_utils';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

interface IProps {
    row: Row<any>;
    selected: string;
    expanded: boolean;
    columns: string;
    index: number;
    indeterminate: boolean;
    uniqId: string;

    getUniqId?(data: any): string;

    subrow: boolean;

    getRowProps?(state, rowInfo, column): void;

    alwaysRedraw: boolean;

    onRowClick?(entity: any): void;
    parentIndex?: number;

    isDnd?: boolean;
}

const renderRow = (row: Row<any>, props: IProps): any => {
    return row.getVisibleCells().map((cell, index) => {
        return (
            <div
                key={cell.id}
                onClick={() => {
                    if (props.onRowClick && !cell.id.includes('_select')) props.onRowClick(row.original);
                }}
                className={`td rt-td ${(cell.column.columnDef as any)?.className || ''}`}
                style={{
                    width: cell.column.getSize(),
                    minWidth: cell.column.getSize(),
                    maxWidth: cell.column.getCanResize() ? cell.column.getSize() : getColumnMaxWidth(cell.column.columnDef.maxSize), //cell.column.columnDef.maxSize ? cell.column.columnDef.maxSize : undefined,
                }}
            >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
        );
    });
};

export class ReactTableRow extends React.Component<IProps> {
    render() {
        const { row, index, uniqId, getRowProps, isDnd } = this.props;

        let customRowProps: any = getRowProps ? getRowProps({} as any, row, {} as any) : {};

        let rowClassName = classNames(
            'rt-tr',
            {
                '-even': !isDnd && index % 2,
                '-odd': !isDnd && !(index % 2),
            },
            customRowProps.className
        );

        let rowCanExpand = row.getCanExpand();
        let rowIsExpanded = row.getIsExpanded();
        let rowIndex = 0;

        if (this.props.subrow) {
            let even = this.props.parentIndex !== undefined && !(this.props.parentIndex % 2) ? index % 2 : !(index % 2);
            let rowClassName = 'ggg rt-tr ' + (even ? '-even' : '-odd') + (customRowProps.className ? ' ' + customRowProps.className : '');
            return <div className={rowClassName}>{renderRow(row, this.props)}</div>;
        }

        const isMain: boolean = row.original.main;

        return rowCanExpand ? (
            <div key={index} className={`rt-tr-group rt-tr-group-expandable ${rowIsExpanded ? 'rt-tr-group-expanded' : ''}  `}>
                <div className={rowClassName}>{renderRow(row, this.props)}</div>
                {rowIsExpanded
                    ? row.subRows.map((subRow) => {
                          rowIndex += 1;
                          return (
                              <ReactTableRow
                                  uniqId={subRow.original.id}
                                  index={rowIndex}
                                  row={subRow}
                                  selected={this.props.selected}
                                  indeterminate={subRow.getIsSomeSelected()}
                                  expanded={subRow.getIsExpanded()}
                                  columns={this.props.columns}
                                  getRowProps={this.props.getRowProps}
                                  subrow={true}
                                  alwaysRedraw={this.props.alwaysRedraw}
                                  parentIndex={index}
                              />
                          );
                      })
                    : null}
            </div>
        ) : (
            <div className={`rt-tr-group`}>
                {isMain ? (
                    <div className={rowClassName}>{renderRow(row, this.props)}</div>
                ) : (
                    <Draggable isDragDisabled={!isDnd} draggableId={uniqId} index={index} key={index}>
                        {(provided, snapshot) => {
                            const dndProps = {
                                ...provided.draggableProps,
                                ...provided.dragHandleProps,
                            };

                            return (
                                <div ref={provided.innerRef} {...dndProps} className={rowClassName}>
                                    {renderRow(row, this.props)}
                                </div>
                            );
                        }}
                    </Draggable>
                )}
            </div>
        );
    }
}

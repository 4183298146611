import React from 'react';
import {Layout} from "antd";
import "./content.less";

const Content = props => (
    <Layout.Content className={'rr-app-content'}>
        {props.children}
    </Layout.Content>
);

export default Content;
import React from 'react';
import { ExcelExportTemplateTypeCodeEnum } from '../../../../server';
import { downloadOffloading } from '../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { useEntityGridContext } from '../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ListPaymentsFilters } from '../api/payments.api.types';
import { EntityGridURLParams } from '../../../../components/page/entityGrid/types/params';
import { PaymentsApiUtils } from '../api/payments.api.utils';
import { ExportOffloadingPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { OffloadingDocumentOnDownload } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';

export const useExportBlock = (pageParams: EntityGridURLParams<ListPaymentsFilters>) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const gridRef = useEntityGridContext();
    const selection = useEntitySelection();

    const onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        const { sortOrder, sortBy } = pageParams;
        const filters: string[] = PaymentsApiUtils.createRequestFilters(pageParams, subrentModuleEnabled);

        const financialFields = [
            'numberInBusinessAccount',
            'counterpartyShortName',
            'rentActivityFrameShortName',
            'date',
            'balanceChangeAmount',
            'stateCode',
            'sourceCode',
            'paymentMethodName',
            'lastUpdateAuthorShortName',
            'lastUpdateDate',
        ];

        downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.PAYMENTTEMPLATE,
            fileName: 'Экспорт платежей',
            fileType,
            filters,
            financialFieldsEnum: financialFields,
            grid: gridRef.current,
            intl,
            notFinancialFieldsEnum: financialFields,
            selection,
            values: options,
            sortOrder,
            sortBy,
            excludeColumns: undefined,
        });
    };

    return <ExportOffloadingPopover storageKey={'paymentsOffloading'} onDownload={onOffloadingDownload} />;
};

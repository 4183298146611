import React, { RefObject } from 'react';
import { TreeSelect } from 'antd';
import { IRootState } from '../../../../../shared/reducers';
import { connect } from 'react-redux';
import './ProductCategoriesSelect.less';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { TreeNodeNormal } from 'antd/lib/tree-select/interface';
import { ProductCategory } from '../../../../../shared/reducers/entities.reducer';
import { TreeNode } from 'antd/es/tree-select';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

interface IProps extends StateProps, TreeSelectProps<number[]> {}

export const findCategoryRecursively = (categories: ProductCategory[], key: ProductCategory['key']): ProductCategory | null => {
    for (let category of categories) {
        if (category.key === key) return category;
        if (!!category.children && category.children.length > 0) {
            const deepCategory = findCategoryRecursively(category.children, key);
            if (deepCategory != null) return deepCategory;
        }
    }

    return null;
};

export const mapProductsCategoriesToTreeNodeNormal = (
    categories: ProductCategory[],
    highlightValue?: number,
    parentValue?: TreeNodeNormal['value'],
    disabledValues?: number[]
): TreeNodeNormal[] => {
    return categories.map(({ key, value, title, children }) => {
        const nodeDisabled: boolean = disabledValues == null ? false : disabledValues.includes(value);

        if (nodeDisabled) {
            // Выключить все дочерние ноды
            disabledValues = (disabledValues ?? []).concat(children.map(({ value }) => value));
        }

        return {
            key: String(key),
            value: value,
            title: (
                <span
                    style={
                        value === highlightValue
                            ? {
                                  fontWeight: 600,
                              }
                            : {}
                    }
                >
                    {title}
                    {value === highlightValue ? <span style={{ fontWeight: 400 }}> (основная)</span> : ''}
                </span>
            ),
            children: mapProductsCategoriesToTreeNodeNormal(children, highlightValue, value, disabledValues),
            className: classNames({
                'tree-node-highlight': value === highlightValue,
                'tree-node-suppress-highlight': parentValue === highlightValue,
            }),
            disabled: nodeDisabled,
        };
    });
};

interface BaseCategoriesSelectState {
    open: boolean;
}

class _ProductCategoriesSelect extends React.Component<IProps & StateProps, BaseCategoriesSelectState> {
    treeSelectRef: RefObject<TreeSelect<number[]>>;

    constructor(props: IProps) {
        super(props);
        this.treeSelectRef = React.createRef();
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event: globalThis.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.setState({
                open: false,
            });
        }
    };

    focusInput() {
        const treeNode = ReactDOM.findDOMNode(this.treeSelectRef?.current);
        if (treeNode instanceof Element) {
            const inputNode = treeNode?.querySelector('input');
            if (inputNode) inputNode.focus();
        }
    }

    render() {
        const { onChange, value, placeholder, getPopupContainer, categories, ...treeSelectProps } = this.props;

        const treeData: TreeNodeNormal[] = mapProductsCategoriesToTreeNodeNormal(categories, value?.[0]);

        return (
            <TreeSelect
                ref={this.treeSelectRef}
                dropdownClassName={'rr-ProductCategoriesSelect-TreeSelect'}
                getPopupContainer={getPopupContainer}
                showSearch
                treeData={treeData}
                filterTreeNode={(search, item: TreeNode) => {
                    const category = findCategoryRecursively(categories, Number(item.key));
                    if (category == null) return false;
                    return category.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
                style={{ width: '100%' }}
                value={value}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={placeholder}
                allowClear
                treeDefaultExpandAll
                multiple
                onChange={onChange}
                onSelect={() => this.focusInput()}
                treeIcon={false}
                className={'rr-ProductCategoriesSelect'}
                open={this.state.open}
                onDropdownVisibleChange={(visible) =>
                    this.setState({
                        open: visible,
                    })
                }
                {...treeSelectProps}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    categories: storeState.entities.categories.products || [],
});

type StateProps = ReturnType<typeof mapStateToProps>;

const ProductCategoriesSelectInternal = connect(mapStateToProps)(_ProductCategoriesSelect);

export class ProductCategoriesSelect extends React.Component<IProps> {
    render() {
        return <ProductCategoriesSelectInternal {...this.props} />;
    }
}

import React, { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { goBack, push, replace } from 'connected-react-router';
import { getStore } from '../../../index';
import { LocationDrawer } from '../../modules/main/projects/production/page/tabs/description/LocationDrawer';
import { InventoryMovementDrawer } from '../../modules/main/inventoryMovements/drawer/inventoryMovementDrawer';
import './modals.less';
import { InventoryMovementEventTypeCodeEnum } from '../../server';

const MODAL_PARAM_NAME = 'm';

enum Action {
    view = 'view',
    edit = 'edit',
    create = 'create',
}

enum ModalName {
    inventoryMovement = 'inventoryMovement',
    location = 'location',
}

type ViewAction = `${`:${number | string}` | `.${Action.view}:${number | string}`}`;
type EditAction = `${`.${Action.edit}:${number | string}`}`;
type CreateAction = `.${Action.create}`;

type ShowInventoryMovementModalParam = `${ModalName.inventoryMovement}${
    | ViewAction
    | `.${Action.create}:${InventoryMovementEventTypeCodeEnum.DAMAGE | InventoryMovementEventTypeCodeEnum.LOSS}`}`;
type ShowLocationMovementModalParam = `${ModalName.location}${ViewAction}`;
type ShowModalParam = ShowInventoryMovementModalParam | ShowLocationMovementModalParam;

interface ModalData {
    name: ModalName;
    action?: Action.view | Action.edit | Action.create;
    id: number | string;
}

//
const modalsFromString1 = (str: string | undefined) => {
    const modals: ShowModalParam[] = [];
    if (str) {
        //if(str.)
        // const parts = str.split(',');
        // parts.forEach(pStr=>{
        //     const m = xxxFromStr(pStr);
        //     if(m) modals.push(m);
        // })
        //modals.push('inventoryMovement.create:DAMAGE');
    }
    return modals;
};

const modalsFromString = (str: string | undefined) => {
    const modals: ModalData[] = [];
    if (str) {
        const parts = str.split(',');
        parts.forEach((pStr) => {
            const m = xxxFromStr(pStr);
            if (m) modals.push(m);
        });
    }
    return modals;
};

const xxxFromStr = (str: string): ModalData | undefined => {
    const p = str.split(':');
    if (p[0] && p[1]) {
        const name = p[0];
        const n = name.split('.');
        const action = n[1] && (n[1] === Action.view || n[1] === Action.edit || n[1] === Action.create) ? n[1] : Action.view;
        return { name: n[0] as ModalName, action: action, id: p[1] };
    }
};

const modalsToString = (modals: ModalData[]) => {
    let str = '';
    modals.forEach((modal, index) => {
        str += `${index > 0 ? ',' : ''}${modal.name}:${modal.id}`;
    });
    return str;
};

const getUrlParams = (): URLSearchParams => {
    const location = getStore().getState().router.location;
    return new URLSearchParams(location.search);
};

const getModalsFromParams = (params: URLSearchParams): ModalData[] => {
    const locationParamsObj = Object.fromEntries(params.entries());
    const mStr = locationParamsObj[MODAL_PARAM_NAME];
    return modalsFromString(mStr);
};

const setParams = (params: URLSearchParams, modals: ModalData[]) => {
    if (modals.length > 0) {
        params.set(MODAL_PARAM_NAME, modalsToString(modals));
    } else {
        params.delete(MODAL_PARAM_NAME);
    }
};

const modalRemove = (index: number) => {
    if (window.history.length > 4) {
        getStore().dispatch(goBack());
    } else {
        const params = getUrlParams();
        const modals = getModalsFromParams(params);
        modals.splice(index, 1);
        setParams(params, modals);
        const location = getStore().getState().router.location;
        getStore().dispatch(replace(`${location.pathname}?${params.toString()}`));
    }
};

export const modalShow = (p: ShowModalParam) => {
    const params = getUrlParams();
    const modals = getModalsFromParams(params);
    const m = xxxFromStr(p);
    if (m) modals.push(m);
    setParams(params, modals);
    const location = getStore().getState().router.location;
    getStore().dispatch(push(`${location.pathname}?${params.toString()}`));
};

export const InventoryMovementUpdateEvent = 'inventoryMovementUpdate';

export const modalAddEventListener = (event: string, listener: (data: unknown) => void) => {
    window.addEventListener(event, listener);
};

export const modalRemoveEventListener = (event: string, listener: (data: unknown) => void) => {
    window.removeEventListener(event, listener);
};

export const modalDispatchEvent = (event: string, data: any) => {
    const e = new CustomEvent(event, data);
    window.dispatchEvent(e);
};

export const Modals: FC = (props) => {
    const location = useLocation();
    const locationParamsObj = Object.fromEntries(new URLSearchParams(location.search).entries());
    const modals = modalsFromString(locationParamsObj[MODAL_PARAM_NAME]);
    const components: ReactNode[] = [];
    const [closableId, setClosableId] = useState<number | undefined>(undefined);

    const max = 30;
    const maxModalsCount = modals.length > max ? max : modals.length;
    const maxLvl = (modals.length > maxModalsCount ? maxModalsCount : modals.length) - 1;

    let startFrom = modals.length - maxModalsCount;
    if (startFrom < 0) startFrom = 0;
    for (let i = startFrom; i < modals.length; i++) {
        const modal = modals[i];
        const className = 'rr-drawer-lvl-' + (maxLvl - (i - startFrom) - (i === closableId || closableId === undefined ? 0 : 1));
        if (!modal.action || modal.action === 'view') {
            // Дроверы

            const drawerParams = {
                className: className,
                id: +modal.id,
                onClose: () => {
                    setClosableId(undefined);
                    modalRemove(modals.indexOf(modal));
                },
                onStartClose: () => {
                    setClosableId(i);
                },
            };

            if (modal.name === 'location') {
                components.push(<LocationDrawer {...drawerParams} />);
            } else if (modal.name === 'inventoryMovement') {
                components.push(
                    <InventoryMovementDrawer
                        {...drawerParams}
                        refetchList={() => {
                            modalDispatchEvent(InventoryMovementUpdateEvent, undefined);
                        }}
                    />
                );
            }
        } else {
            if (modal.name === 'location') {
            } else if (modal.name === 'inventoryMovement') {
            }
            // Тут остальные модальные окна, создания, редактирования
        }
    }
    return <>{components}</>;
};

/* TODO Когда дровер локации открывается из проекта, и жмем на ссылко проектов, то там не получается нормального перехода((( */

import React, { FC, memo, ReactElement } from 'react';
import { IconGraduationCapSolid } from '../../../../../../../../../../../../components/icons';
import { Styles } from '../../../../../../../../../../../../styles';
import { Icon } from 'antd';
import classNames from 'classnames';
import './GridHeader.less';

interface GridHeaderProps {
    Button: ReactElement;
}

export const GridHeader: FC<GridHeaderProps> = memo((props) => {
    const { Button } = props;

    return (
        <div className="header-container">
            <div className="left-side">
                <Icon component={IconGraduationCapSolid} className={classNames(Styles.ICON_26, 'icon')} />
                <span style={{ marginLeft: 12 }}>Список специальностей</span>
            </div>
            <div className={'right-side'}>{Button}</div>
        </div>
    );
});

import { FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { FiltersDateTimeRangePicker } from '../../../../../components/datePicker/FiltersDateTimeRangePicker';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum } from '../../../../../localization';
import { StatusesFilter, StatusesFilterProps } from '../../../../../components/select/StatusesFilters/v1/StatusesFilter';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../components/select/problemsFilter';
import { FiltersCurrentModesInfo } from './FiltersCurrentModesInfo';

export const OperationFormEquipmentListFilters = [
    {
        fields: [
            {
                id: 'rentOrSubrent',
                type: FormItemType.Hidden,
            },
            {
                id: 'parentEntityType',
                type: FormItemType.Hidden,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'typeCode',
                type: FormItemType.Component,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                component: StatusesFilter,
                componentProps: (form: WrappedFormUtils): StatusesFilterProps => {
                    return {
                        type: form.getFieldValue('parentEntityType'),
                    };
                },
            },
            {
                id: 'info',
                type: FormItemType.Component,
                component: FiltersCurrentModesInfo,
            },
            {
                label: 'Скрыть',
                id: 'hide',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                values: (form: WrappedFormUtils) => {
                    return [
                        { name: 'Неактивные', value: 'inactive' },
                        { name: 'Отмененные', value: 'cancelled' },
                        { name: 'Отмененные и черновики', value: 'cancelled+draft' },
                    ];
                },
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: form.getFieldValue('rentOrSubrent'),
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__START_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'startDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__END_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'endDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
        ],
    },
];

import React from "react";
import {Col, Row} from "antd";
import {LocalizationEnum, localize} from "../../localization";
import {CurrentRentIndicatorsProblems} from "./currentRentIndicatorsProblems";
import moment from "moment";
import {CurrentRentIndicatorsEntity, CurrentRentIndicatorsEntityType} from "./currentRentIndicatorsBlock";
import {isDefined} from "../../shared/util/utils";
import {formatMoney} from "../../shared/util/formatMoney";
import {useAppSelector} from "../../store/hooks";
import {canViewFinancialDataSelector} from "../../shared/reducers/permissions.reducer";

interface IProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: CurrentRentIndicatorsEntity;
    costOfRent?: number;
}

export const CurrentRentIndicatorsTab = (props: IProps) => {

    const showFinancialData = useAppSelector(canViewFinancialDataSelector);

    return (
        <Row className={'currentRentIndicators'}>
            <Col span={24}>
                <CurrentRentIndicatorsProblems entity={props.entity} entityType={props.entityType} showFinancialData={showFinancialData} />
                <Row>
                    {
                        showFinancialData && isDefined(props.costOfRent) && (
                            <Col span={12} className={'rr-form-item'} >
                                <div className='rr-label'>Стоимость аренды</div>
                                <div className='rr-value'>
                                    {formatMoney(props.costOfRent)}
                                </div>
                            </Col>
                        )
                    }
                    <Col span={12} className={'rr-form-item'} >
                        <div className='rr-label'>{localize(LocalizationEnum.ASPECT__COUNTER__LAST_RETURN_DATE)}</div>
                        <div className='rr-value'>
                            {
                                props.entity.rentIndicators?.lastRentEndDate ?
                                    <>
                                <span className="rr-value">
                                    {moment(props.entity.rentIndicators.lastRentEndDate).format('DD MMMM YYYY')}
                                </span>
                                        &nbsp;
                                        <span className='rr-value-date-time'>
                                    {moment(props.entity.rentIndicators.lastRentEndDate).format('HH:mm')}
                                </span>
                                    </> :
                                    <span className="rr-0-value">
                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT)}
                            </span>
                            }
                        </div>
                    </Col>
                </Row>
            </Col>
            {/*{*/}
            {/*    showFinancialData && (*/}
            {/*        <CurrentRentIndicatorsTax entity={props.entity}  entityType={props.entityType} />*/}
            {/*    )*/}
            {/*}*/}
        </Row>
    );
};

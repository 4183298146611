import React from 'react';
import {InputNumberProps} from "antd/lib/input-number";
import { formatMoney } from '../../../shared/util/formatMoney';
import FormValue from '../../formValue/FormValue';
import { NumberInputMoney } from '../../numberInputs/numberInputMoney';
import {isDefined} from "../../../shared/util/utils";
import './InlineMoneyInput.less';

interface State {
    focus: boolean;
    beforeEditValue?: number;
    value?: number;
    enterPressed?: boolean;
}

interface MoneyInputInlineProps {
    value: any;
    onChange: any;
    canEdit: boolean;
    onStartEdit?: () => void;
    darkBg?: boolean;
    onBeforeBlur?: (value: number|undefined) => Promise<boolean>;
    min?: number;
    max?: number;
}

export class InlineMoneyInput extends React.Component<MoneyInputInlineProps, State> {
    public static defaultProps = {
        canEdit: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            beforeEditValue: props.value,
            value: props.value
        };
    }

    onKeyDown = (e) => {
        if (e.key === 'Escape' && this.props.canEdit && this.state.focus) {
            this.onChange(this.state.beforeEditValue);
            this.setState({ focus: false });
        }else if (e.key === 'Enter' && this.props.canEdit && this.state.focus) {
            //this.onChange(this.state.beforeEditValue);
            // this.onChange(this.state.value);
            // this.setState({ focus: false });
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    componentDidUpdate(prevProps: Readonly<MoneyInputInlineProps>, prevState: Readonly<State>, snapshot?: any) {
        //console.log('DDD', prevProps.value, this.props.value);
        // if(prevProps.value !== this.props.value && this.state.value !== this.props.value){
        //     this.setState({value: this.props.value});
        // }
    }

    onFocus = () => {
        if (this.props.canEdit) {
            if (this.props.onStartEdit) this.props.onStartEdit();
            this.setState({ focus: true, beforeEditValue: this.props.value, value: this.props.value, enterPressed: false});
        }
    };

    onInputFocus = (e) => {
        e.currentTarget.select();
    };

    onBlur = async (e) => {
        if(this.props.onBeforeBlur && this.state.value !== this.props.value && !this.state.enterPressed){
            await this.props.onBeforeBlur(this.state.value);
            this.setState({ focus: false });
        }else{
            if(!this.state.enterPressed) this.setState({ focus: false });
        }
    };

    onChange = (value: number | undefined) => {
        if(isDefined(this.props.max) && isDefined(value) && value > this.props.max){
            value = this.props.max;
        }
        if(isDefined(this.props.min) && isDefined(value) && value < this.props.min){
            value = this.props.min;
        }

        if(value !== undefined && this.props.onChange && ((isDefined(this.props.min) && value >= this.props.min) || (isDefined(this.props.max) && value <= this.props.max))){
            this.props.onChange(value);
        }

        this.setState({value});
    };

    render() {
        let value = this.state.value;
        if (isDefined(value) && isDefined(this.props.min) && value < this.props.min) value = this.props.min;

        return this.state.focus && this.props.canEdit ? (
            <NumberInputMoney
                className={'rr-money-input-inline' + (this.props.darkBg ? ' rr-money-input-inline-white' : '')}
                onChange={this.onChange}
                onFocus={this.onInputFocus}
                autoFocus={true}
                value={value}
                onBlur={this.onBlur}
                min={this.props.min}
                max={this.props.max}
                onPressEnter={async () => {
                    if(this.props.onBeforeBlur && this.state.value !== this.props.value){
                        await this.setState({enterPressed: true});
                        await this.props.onBeforeBlur(this.state.value);
                        this.setState({ focus: false });
                        this.onChange(value);
                    }else{
                        this.setState({ focus: false });
                        this.onChange(value);
                    }
                }}
            />
        ) : (
            <FormValue
                onClick={this.onFocus}
                className={this.props.canEdit ? 'rr-inline-editable' : ''}
                value={formatMoney(this.props.value)}
                originalValue={this.props.value}
            />
        );
    }
}

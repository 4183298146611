import React from "react";
import {replace} from "connected-react-router";
import Spin from "../../components/spin/spin";
import {IRootState} from "../../shared/reducers";
import {connect} from "react-redux";
import {serverApi, UserBaInvitationInfoRead} from "../../server";
import axios, {AxiosResponse} from "axios";
import {Col, Icon, Modal} from "antd";
import {IconCheck} from "../../components/icons";
import {rulesList} from "../../config/rules_ru";
import {showNotification} from "../../components/notification/showNotification";
import {ReactComponent as LogoIcon} from "../../assets/images/rr-logo-icon.svg";
import {ReactComponent as LogoText} from "../../assets/images/rr-logo-text.svg";
import {LocalizationEnum, localize, localizeIntl} from '../../localization';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {API_SERVER_BASE_PATH} from "../../config/config";
import './invitation.less';
import userManager from "../../config/userManager";

interface IProps extends StateProps, DispatchProps, WrappedComponentProps{
}

interface IState {
    data?: UserBaInvitationInfoRead;
    loading: boolean;
    loadingError: boolean;
    user: any;
    visible: any;
}

class Invitation extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingError: false,
            data: undefined,
            user: undefined,
            visible: true
        };
    }

    async componentDidMount() {
        const ref: any = window.location.pathname.replace('/acceptInvitation/', '');

        let res:AxiosResponse|undefined;
        try{
            res = await axios.get(`${API_SERVER_BASE_PATH}/invitations/${ref}`);
            this.setState({
                loading: false,
                data: res?.data
            });
        }catch (e) {
            this.setState({
                loading: false,
                loadingError: true
            });
            showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__POPUP_NOTIFICATIONS__INVITATION_OUT_OF_DATE));
        }

        console.log('AAA', res);

        await serverApi.answerToTheInvitation(ref, true).then(
            (result) => {
                console.log(result.data);
            }).catch(error => {
                this.setState({
                    loadingError: true
                });
                console.log(error);
            });
    }

    handleOk = e => {
        let newBAId = this.state.data?.businessAccountId;
        if(newBAId){
            this.props.replace(`/${newBAId}`);
            userManager.removeUser();
        }
    };

    render() {
        const {data, loading} = this.state;
        const okButtonDisabled = !data;
        const baFullName = data?.businessAccountFullName;
        const baRoles = data?.baRoles.map((el, num) => {
            let rule:React.ReactElement;
            if (data.baRoles.length===num+1) {
                rule = <span>{rulesList.find(element => element.value === el)!.name} </span>;
            } else {
                rule = <span>{rulesList.find(element => element.value === el)!.name}, </span>;
            }
            return rule;
        });

        return loading ? (
            <Spin>
                <div></div>
            </Spin>
        ) : (
            <Modal
                title={
                    <>
                        <div className={'rr-menu-logo-container2'} style={{position: 'absolute', backgroundColor: '#8b899a', zIndex: 2001, left: 'calc(50% - 165px)', top: -120 }}>
                            <LogoIcon />
                            <LogoText />
                        </div>
                        <Icon component={IconCheck}
                              style={{marginRight: 20, fontSize: 28, color: 'rgb(0, 173, 238)'}}/>
                        <span style={{color: '#fff'}}>{localize(LocalizationEnum.PAGE__INVITATIONS__POPUP_NOTIFICATIONS__INVITATION_ACCEPTED)}</span>
                    </>
                }
                width={800}
                visible={this.state.visible}
                onOk={this.handleOk}
                okText={localizeIntl(this.props.intl, LocalizationEnum.PAGE__INVITATIONS__PROCEED_BUTTON)}
                closable={false}
                maskClosable={false}
                cancelButtonProps={{style: {display: 'none'}}}
                okButtonProps={{disabled: okButtonDisabled}}
                wrapClassName="invitation"
                centered
            >
                <Col className='rr-form-item' style={{marginBottom: 29}}>
                    <div className='rr-value'>{localize(LocalizationEnum.PAGE__INVITATIONS__INVITATION_TEXT_ROLES_GRANTED)}</div>
                </Col>
                <Col className='rr-form-item' style={{marginBottom: 29}}>
                    <div className='rr-label'>{localize(LocalizationEnum.ASPECT__ENTITY_TYPE__BUSINESS_ACCOUNT)}</div>
                    <div className='rr-value'>{baFullName || '-'}</div>
                </Col>
                <Col className='rr-form-item' style={{marginBottom: 29}}>
                    <div className='rr-label'>{localize(LocalizationEnum.PAGE__SECURITY__ROLES)}</div>
                    <div className='rr-value'>{baRoles || '-'}</div>
                </Col>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        params: storeState.oidc
    };
};

const mapDispatchToProps = {
    replace
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Invitation));

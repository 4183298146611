import React, {FC, useCallback} from "react";
import {
    ExportOffloadingPopoverOptions,
    OffloadingDocumentOnDownload
} from "../../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {ExportOffloadingPopover} from "../../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {IDownloadDocumentParamsItem} from "../../../../../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover";
import {LocalizationEnum, localize} from "../../../../../../../localization";
import {WorkPlanningsCalendarFilters} from "../../filters/workPlanningsCalendarFilters.types";
import {PageUrlParamsObject} from "../../../../../../../core/hooks/urlParams/types";
import {useAppSelector} from "../../../../../../../store/hooks";
import {businessAccountIdSelector} from "../../../../../../../shared/reducers/system.reducer";
import {canViewFinancialDataSelector} from "../../../../../../../shared/reducers/permissions.reducer";
import {useIntl} from "react-intl";
import {
    ExcelExportTemplateTypeCodeEnum,
    WorkPlanningsFinancialFieldsTypeCode,
    WorkPlanningsNotFinancialFieldsTypeCode
} from "../../../../../../../server";
import {downloadOffloading} from "../../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {WorkPlanningsCalendarApiUtils} from "../../api/workPlanningsCalendar.api.utils";

export const exportOffloadingPopoverOptions: IDownloadDocumentParamsItem<ExportOffloadingPopoverOptions>[] = [
    {
        key: ExportOffloadingPopoverOptions.ALL_COLUMNS,
        label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__ALL_COLUMNS),
        defaultSelected: true,
        disabled: true
    },
];

interface ExportOffLoadingPopoverWrapperProps {
    pageParamsObject: PageUrlParamsObject<WorkPlanningsCalendarFilters>;
}

export const ExportOffLoadingPopoverWrapper: FC<ExportOffLoadingPopoverWrapperProps> = ({pageParamsObject}) => {
    const {pageParams} = pageParamsObject;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const intl = useIntl();

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({fileType, options}) => {
            const {search} = pageParams;
            const sortBy = WorkPlanningsCalendarApiUtils.getSortBy(pageParams.group);
            const sortOrder = WorkPlanningsCalendarApiUtils.getSortOrder(sortBy);
            const filters = WorkPlanningsCalendarApiUtils.getFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.WORKPLANNINGSTEMPLATE,
                fileName: 'Экспорт работ',
                fileType,
                filters,
                grid: null,
                intl,
                financialFieldsEnum: WorkPlanningsFinancialFieldsTypeCode,
                notFinancialFieldsEnum: WorkPlanningsNotFinancialFieldsTypeCode,
                selection: [],
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined,
            });
        },
        [businessAccountId, canViewFinancialData, intl, pageParams]
    );

    return (
        <ExportOffloadingPopover
            storageKey={'workPlanningsCalendarOffloading'}
            onDownload={onOffloadingDownload}
            options={exportOffloadingPopoverOptions}
        />
    );
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { ListLifecycleEventsArgs, ListLifecycleEventsData } from './lifecycleEvents.api.types';
import { AxiosError } from 'axios';
import { serverApi } from '../../../../../../server';
import { LifecycleEventsApiUtils } from './lifecycleEvents.api.utils';

export const lifecycleEventsApi = createApi({
    reducerPath: 'lifecycleEventsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['LifecycleEvents'],
    endpoints: (builder) => ({
        listLifecycleEvents: builder.query<ListLifecycleEventsData, ListLifecycleEventsArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = LifecycleEventsApiUtils.createRequestFilters(params);

                try {
                    const { data: lifecycleEvents } = await serverApi.listEntityLifecycleEvents(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        params.sortBy || undefined,
                        params.sortOrder
                    );

                    return {
                        data: {
                            entitiesData: lifecycleEvents,
                        },
                    };
                } catch (e) {
                    return {
                        error: e as AxiosError,
                    };
                }
            },
            providesTags: ['LifecycleEvents'],
        }),
    }),
});

export const { useListLifecycleEventsQuery } = lifecycleEventsApi;

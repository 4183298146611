import React from 'react';

import './popoverProblemLine.less';
import { Icon } from 'antd';
import { IconBoxesSolid, IconClockO, IconHourglassEndSolid, IconShippingFastSolid, IconStickyNoteO, IconStopwatchSolid } from '../icons';
import { Link } from 'react-router-dom';
import { getBusinessAccountId } from '../../../index';
import { LocalizationEnum, localize } from '../../localization';
import { IntlShape } from 'react-intl';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { ProblemAndWarningRentIndicatorsRecordObj, RentStateCodeEnum } from '../../server/api';
import { getDateString } from '../../shared/util/getDateString';
import { linkToCalendar, linkToCalendarFromShortage } from '../../shared/util/createLinkPath';

interface IProps {
    shortageCount: number;
    closeCb(): void;
    intl: IntlShape;
    stateCode?: RentStateCodeEnum;
    problemsAndWarnings?: ProblemAndWarningRentIndicatorsRecordObj;
}

interface IVariantProps {
    productId?: number;
    entityType: 'variant' | 'product-instances';
    delayCount: number;
    subrentId?: number;
}

interface IElementProps {
    rentPeriodStartDate?: Date;
    rentPeriodEndDate?: Date;
    productId?: number;
    variantId?: number;
    renterId?: number;
    projectId?: number;
    subrentId?: number;
    elementId?: number;
    entityType: 'element';
    rentElementsKitId?: number;
}

export class PopoverProblemLine extends React.Component<IProps & (IVariantProps | IElementProps)> {
    render() {
        let { productId, subrentId, entityType, closeCb, shortageCount, problemsAndWarnings } = this.props;
        let delayLinkPath: string;
        let shortageLink: string;
        let delayTitle;
        let shortageTitle;
        let delayValue: JSX.Element;
        let shortageValue: JSX.Element;

        if (entityType === 'variant' || entityType === 'product-instances') {
            let { delayCount } = this.props as IVariantProps;

            delayTitle = <>{localize(LocalizationEnum.ASPECT__PROBLEM__DELAYS)}</>;
            shortageTitle = <>{localize(LocalizationEnum.ASPECT__PROBLEM__SHORTAGES)}</>;
            delayLinkPath = `/${getBusinessAccountId()}/inventory/products/${productId ? productId : null}?tab=description`;
            shortageLink = `/${getBusinessAccountId()}/inventory/products/${productId ? productId : null}?tab=description`;

            delayValue = (
                <>
                    {delayCount}
                    <span>обяз.</span>
                </>
            );

            shortageValue = (
                <>
                    {shortageCount}
                    <span>обяз.</span>
                </>
            );
        } else {
            let {
                renterId,
                projectId,
                elementId,
                variantId,
                rentPeriodStartDate,
                rentPeriodEndDate,
                rentElementsKitId: kit,
            } = this.props as IElementProps;

            delayTitle = <>{localize(LocalizationEnum.ASPECT__PROBLEM__DELAY)}</>;
            shortageTitle = <>{localize(LocalizationEnum.ASPECT__PROBLEM__SHORTAGE)}</>;
            delayLinkPath = linkToCalendar({
                elementId,
                renterId,
                projectId,
                subrentId,
                group: 'project',
                capture: 'allWithNow',
            });
            shortageLink = linkToCalendarFromShortage({
                productId,
                variantId,
                rentPeriodStartDate,
                rentPeriodEndDate,
                stateCode: this.props.stateCode,
                elementId,
                kit,
            });

            delayValue = (
                <>
                    {(this.props.stateCode === RentStateCodeEnum.RENT || this.props.stateCode === RentStateCodeEnum.SUBRENT) &&
                    rentPeriodEndDate
                        ? getDateString(new Date(), rentPeriodEndDate)
                        : getDateString(new Date(), rentPeriodStartDate)}
                </>
            );

            shortageValue = (
                <>
                    {shortageCount}
                    <span>{localize(LocalizationEnum.ASPECT__PLURAL__INSTANCE, undefined, { value: shortageCount })}</span>
                </>
            );
        }

        const renderProblem = (
            problem:
                | 'bookShortage'
                | 'subrentShipmentDelay'
                | 'returnDelay'
                | 'giveawayDelay'
                | 'subrentReturnToShipperDelay'
                | 'orderShortage',
            problems
        ) => {
            let typeProblem: 'problem' | 'warning';

            let shortageOrDelay: 'shortage' | 'delay';

            let isWarning = problems.bookShortage || problems.returnDelay || problems.subrentShipmentDelay;
            let isProblem = problems.giveawayDelay || problems.orderShortage || problems.subrentReturnToShipperDelay;

            if (problem === 'bookShortage' || problem === 'subrentShipmentDelay' || problem === 'returnDelay') {
                typeProblem = 'problem';
            } else {
                typeProblem = 'warning';
            }

            if (problem === 'bookShortage' || problem === 'orderShortage') {
                shortageOrDelay = 'shortage';
            } else {
                shortageOrDelay = 'delay';
            }

            let iconProblem;
            let tooltipProblem;

            if (problem === 'bookShortage') {
                iconProblem = IconBoxesSolid;
                tooltipProblem = LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__BOOK_SHORTAGE;
            } else if (problem === 'subrentShipmentDelay') {
                iconProblem = IconShippingFastSolid;
                tooltipProblem = LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__SUBRENT_SHIPMENT_DELAY;
            } else if (problem === 'returnDelay') {
                iconProblem = IconHourglassEndSolid;
                tooltipProblem = LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__RETURN_DELAY;
            } else if (problem === 'giveawayDelay') {
                iconProblem = IconClockO;
                tooltipProblem = LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__GIVEAWAY_DELAY;
            } else if (problem === 'subrentReturnToShipperDelay') {
                iconProblem = IconStopwatchSolid;
                tooltipProblem = LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__SUBRENT_RETURN_TO_SHIPPER_DELAY;
            } else if (problem === 'orderShortage') {
                iconProblem = IconStickyNoteO;
                tooltipProblem = LocalizationEnum.ASPECT__PROBLEM__TOOLTIP__ORDER_SHORTAGE;
            }

            return (
                <div className={typeProblem === 'problem' ? 'popover-problem-line' : 'popover-warning-line'}>
                    <Icon component={iconProblem} className="icon" />
                    <Link to={shortageOrDelay === 'shortage' ? shortageLink : delayLinkPath} onClick={() => closeCb()}>
                        <HelpTooltip content={tooltipProblem}>
                            <span>
                                {shortageOrDelay === 'shortage' ? shortageTitle : delayTitle}
                                <span className="popover-problem-line-span">
                                    {shortageOrDelay === 'shortage' ? shortageValue : delayValue}
                                </span>
                            </span>

                            {(isWarning && isProblem && typeProblem === 'problem') ||
                            (problems.giveawayDelay && problems.orderShortage && problem === 'giveawayDelay') ? (
                                <span className="popover-problem-line-span-comma"> ,</span>
                            ) : null}
                        </HelpTooltip>
                    </Link>
                </div>
            );
        };

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {problemsAndWarnings?.bookShortage ? renderProblem('bookShortage', this.props.problemsAndWarnings) : null}
                    {problemsAndWarnings?.subrentShipmentDelay
                        ? renderProblem('subrentShipmentDelay', this.props.problemsAndWarnings)
                        : null}
                    {problemsAndWarnings?.returnDelay ? renderProblem('returnDelay', this.props.problemsAndWarnings) : null}
                    {problemsAndWarnings?.giveawayDelay ? renderProblem('giveawayDelay', this.props.problemsAndWarnings) : null}
                    {problemsAndWarnings?.subrentReturnToShipperDelay
                        ? renderProblem('subrentReturnToShipperDelay', this.props.problemsAndWarnings)
                        : null}
                    {problemsAndWarnings?.orderShortage ? renderProblem('orderShortage', this.props.problemsAndWarnings) : null}
                </div>
            </>
        );
    }
}

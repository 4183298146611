import React, {ReactNode} from 'react';
import {changeVariantOfElement, OperationElement} from "../../../reducers/operationForm.reducer";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {PopoverContent} from "../../../../../../components/popover/PopoverContent";
import {LocalizationEnum, localize} from "../../../../../../localization";
import {Button, Icon, Select} from "antd";
import {IconCheckCircle, IconExclamationSolid} from "../../../../../../components/icons";
import {IRootState} from "../../../../../../shared/reducers";
import {connect} from "react-redux";
import {VariantRecord} from "../../../../../../server/api";
import {SelectValue} from "antd/lib/select";
import {showConfirm} from "../../../../../../components/confirm/showConfirm";
import {serverApi} from "../../../../../../server";
import {showNotification} from "../../../../../../components/notification/showNotification";
import {Spin} from "../../../../../../components";

interface IProps extends DispatchProps, StateProps, WrappedComponentProps {
    element: OperationElement;
    onConfirmModalVisibleChanged: (visible: boolean) => void;
    onOk: () => void;
}

interface IState {
    value?: any;
    variants?: VariantRecord[];
    loading: boolean;
    loadingError: boolean;
    opened: boolean;
}

export class Component extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingError: false,
            opened: false
        };

        this.loadData();
    }

    loadData = async () => {
        try{

            setTimeout(()=>{
                this.setState({opened: true});
            }, 200);

            const res = await serverApi.listProductVariants(
                this.props.businessAccountId,
                this.props.element.productId,
                undefined,
                undefined,
                undefined,
                'lastActivityDate',
                'ASC'
            );
            this.setState({loading: false, variants: res.data.records});
        }catch(error){
            this.setState({loading: false, loadingError: true});
        }
    }

    renderData = (data: VariantRecord[]): ReactNode => {
        return data.map((item, index) => {
            return (
                <Select.Option
                    key={index}
                    value={item.id}
                >
                    {item.name}
                </Select.Option>
            );
        });
    };

    onChange = (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[]) => {
        this.setState({ value });
    };

    onButtonClick = async (e) => {
        let ok = true;

        if (this.props.element.instanceIds && this.props.element.instanceIds.length > 0) {
            this.props.onConfirmModalVisibleChanged(true);
            ok = await showConfirm(this.props.intl, 'Убрать все отслеживаемые экземпляры для смены варианта?');
            this.props.onConfirmModalVisibleChanged(false);
        }

        if (ok) {
            let variantId: number = this.state.value;
            let variant = this.state.variants?.find((item) => item.id === variantId);
            if (variant) {
                try{
                    await this.props.changeVariantOfElement(this.props.element.id, variantId, variant && variant.name ? variant.name : '', variant.pricePerShift, variant.externalCode);
                    this.props.onOk();
                }catch(error){
                    showNotification('error', 'Ошибка');
                    console.error(error);
                }
            }
        }
    };

    render() {
        let variants =
            this.state.variants && this.state.variants.filter((item) => item.id && item.id !== this.props.element.variantId);

        return (
            <PopoverContent
                header={
                    <Select
                        onDropdownVisibleChange={(open)=>{
                            this.setState({opened: open});
                        }}
                        open={this.state.opened}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
                        showAction={['click']}
                        defaultActiveFirstOption={false}
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        placeholder="Выбрать вариант"
                        suffixIcon={this.state.loadingError && <Icon style={{ color: '#F4516C' }} component={IconExclamationSolid} />}
                        notFoundContent={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)}
                        dropdownRender={(menu)=>{
                            return (
                                <>
                                    {
                                        this.state.loading && (
                                            <div className={'rr-custom-select-loading-block'}>
                                                <Spin size={'small'} delay={0} />
                                                <span>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</span>
                                            </div>
                                        )
                                    }
                                    {!this.state.loading ? menu : null}
                                </>
                            );
                        }}
                    >
                        {variants ? this.renderData(variants) : null}
                    </Select>
                }
                content={
                    <Button block onClick={this.onButtonClick} disabled={!this.state.value}>
                        <Icon style={{ color: '#04b0f1' }} component={IconCheckCircle} />
                        <span>Применить</span>
                    </Button>
                }
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        operationStartTime: storeState.operationForm.operationStartTime,
        projectTemplate: storeState.operationForm.projectTemplate,
    };
};

const mapDispatchToProps = { changeVariantOfElement };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const VariantSelectPopoverContent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));

import React, { FC, memo, useCallback } from 'react';
import { formatMoneyWithFixedDecimalScale } from '../../../../shared/util/formatMoney';
import { EditableField, EditableFieldProps } from '../EditableField';

interface EditableCurrencyFieldProps extends Pick<EditableFieldProps, 'currentValue' | 'baseValue' | 'onChange' | 'labelProps'> {}

const minValue = 0;
const maxValue = 999999;

export const EditableCurrencyField: FC<EditableCurrencyFieldProps> = memo((props) => {
    const { currentValue, baseValue, onChange, labelProps } = props;

    const onChangeProxy = useCallback(
        (value: number) => {
            const boundedValue = Math.min(Math.max(value, minValue), maxValue);
            onChange(boundedValue);
        },
        [onChange]
    );

    return (
        <EditableField
            currentValue={currentValue}
            baseValue={baseValue}
            minValue={minValue}
            maxValue={maxValue}
            formatStyle={'currency'}
            onChange={onChangeProxy}
            valueFormatter={(value) => formatMoneyWithFixedDecimalScale(value, true, false)}
            labelProps={labelProps}
        />
    );
});

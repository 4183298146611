import { WorkPlanningGroupRecord } from '../../../../../../../../server';
import { useMemo } from 'react';
import { useDeferredTime } from '../../../../../components/Calendar/hooks/useDeferredTime';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { workPlanningsCalendarScreenLeft, workPlanningsCalendarScreenRight } from '../../../reducers/workPlanningsCalendar.selectors';
import {
    CalendarId,
    CalendarItem,
    CalendarItemGroup,
    CalendarLine,
    WorkPlanningsCalendarItem,
} from '../../../../../components/Calendar/types/items';
import { processSplittedCalendarItem } from '../../../utils/processing/processSplittedCalendarItem';
import { WorkPlanningsCalendarFilters } from '../../../filters/workPlanningsCalendarFilters.types';
import { processWorkPlanningsSplittedCalendarGroup } from '../../../utils/processing/processSplittedCalendarGroup';

export const useCalendarItems = (
    groupRecords: WorkPlanningGroupRecord[] | undefined,
    isLoading: boolean,
    pageParams: WorkPlanningsCalendarFilters,
    collapsedGroupsId: CalendarId[],
    collapsedSidebarGroupsId: CalendarId[]
) => {
    const _screenLeft = useAppSelector(workPlanningsCalendarScreenLeft);
    const _screenRight = useAppSelector(workPlanningsCalendarScreenRight);
    const { group } = pageParams;
    const screenLeft = pageParams.screenLeft ?? _screenLeft;
    const screenRight = pageParams.screenRight ?? _screenRight;
    const [visibleTimeStart, visibleTimeEnd] = useDeferredTime(screenLeft, screenRight, isLoading);

    const items = useMemo(() => {
        if (groupRecords == null) return [];

        const items: CalendarLine<WorkPlanningsCalendarItem>[] = [];
        let groupIndex = {
            current: 1,
        };

        for (const groupRecord of groupRecords) {
            const { workPlannings, entityId } = groupRecord;
            if (workPlannings == null || workPlannings.length === 0 || entityId == null) continue;

            const isGroupCollapsed = !collapsedGroupsId.includes(entityId);

            processWorkPlanningsSplittedCalendarGroup({
                items: items as CalendarItemGroup[],
                groupIndex,
                screenLeft,
                screenRight,
                groupRecord,
                isCollapsed: isGroupCollapsed,
            });

            if (isGroupCollapsed) {
                processSplittedCalendarItem({
                    groupRecord,
                    items: items as CalendarItem<WorkPlanningsCalendarItem>[],
                    screenLeft,
                    screenRight,
                    groupIndex,
                    collapsedSidebarGroupsId,
                    group,
                });
            }
        }

        return items;
    }, [groupRecords, collapsedGroupsId, screenLeft, screenRight, collapsedSidebarGroupsId, group]);

    return useMemo(
        () => ({
            items,
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [items, visibleTimeEnd, visibleTimeStart]
    );
};

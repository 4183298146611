import React from 'react';
import { CheckboxDragController } from '../../../../controllers/drag/CheckboxDragController/CheckboxDragController';
import { Checkbox } from 'antd';
import { IDownloadDocumentParamsItem } from '../../DownloadDocumentPopover';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ExportSettings } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import classNames from 'classnames';

interface DownloadDocumentPopoverOptionsProps<Options extends Object> {
    options: IDownloadDocumentParamsItem[];
    selectedValues: Options[];
    setSelectedValues: (value: string[]) => void;
    disabledValues: string[];
    documentsLocalStorage: ExportSettings;
    setDocumentsLocalStorage: (value: ExportSettings) => void;
    onGroupChange: (checkedValues: string[]) => string[];
}

export const DownloadDocumentPopoverOptions = <Options extends Object>(props: DownloadDocumentPopoverOptionsProps<Options>) => {
    const {
        options,
        selectedValues,
        setSelectedValues,
        disabledValues,
        documentsLocalStorage,
        setDocumentsLocalStorage,
        onGroupChange: _onGroupChange,
    } = props;

    const onGroupChange = (values: CheckboxValueType[]) => {
        if (values.every((value): value is string => typeof value === 'string')) {
            setSelectedValues(values);
            setDocumentsLocalStorage({
                ...documentsLocalStorage,
                params: values,
            });
        }
    };

    return (
        <div className={'content'}>
            <div className={'title'}>Включить:</div>
            {
                <CheckboxDragController>
                    {({ refSetter, onMouseEnter }) => (
                        <Checkbox.Group
                            value={selectedValues as unknown as string[]}
                            className={classNames('content-checkbox-group')}
                            onChange={(values: any[]) => {
                                onGroupChange(_onGroupChange(values));
                            }}
                        >
                            {options?.map((option, index) => {
                                const disabled = disabledValues.includes(option.key);

                                return (
                                    <Checkbox
                                        ref={refSetter(index)}
                                        key={option.key}
                                        value={option.key}
                                        onMouseEnter={() => onMouseEnter(index)}
                                        disabled={disabled}
                                    >
                                        {option.label}
                                    </Checkbox>
                                );
                            })}
                        </Checkbox.Group>
                    )}
                </CheckboxDragController>
            }
        </div>
    );
};

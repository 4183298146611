export class ReactTableController {

    private table;

    constructor(table) {
        this.table = table;
    }

    resetRowSelection(defaultState?: boolean) {
        this.table.resetRowSelection(defaultState);
    }

}

import React, {FC} from 'react';
import {NumberInputShiftCount} from "../../../../components/numberInputs";
import {RecalculateShiftsCountButton} from "../../operationForm/RecalculateShiftsCountButton";
import {NumberInputShiftCountProps} from "../../../../components/numberInputs/numberInputShiftCount";

export interface ShiftCountInputProps {
    value?: NumberInputShiftCountProps['value'];
    onChange?: NumberInputShiftCountProps['onChange'];
    newShiftCount?: number;
    shiftLengthInMinutes?: number;
    hasOwnShiftLength?: boolean;
}

export const ShiftCountInput:FC<ShiftCountInputProps> = ({value, onChange, newShiftCount, shiftLengthInMinutes, hasOwnShiftLength}) => {

    return (
        <React.Fragment>
            <NumberInputShiftCount onChange={onChange} value={value} />
            {
                newShiftCount !== undefined && (
                    <RecalculateShiftsCountButton
                        style={{marginLeft: 10}}
                        from={value}
                        to={newShiftCount}
                        onClick={()=>{
                            onChange?.(newShiftCount);
                        }}
                        shiftLengthInMinutes={shiftLengthInMinutes}
                        hint={!hasOwnShiftLength ? '(По компании)' : ''}
                    />
                )
            }
        </React.Fragment>
    );
};

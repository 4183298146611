import React from 'react';
import {StateBadgeData} from '../../../../../../components/v2/stateBadge/stateBadge';
import {ProfessionStateCodeEnum} from '../../../../../../server';
import {Color} from "../../../../../../core/types/color";

export const professionsStateBadgeData: StateBadgeData<ProfessionStateCodeEnum> = {
    [ProfessionStateCodeEnum.ACTIVE]: {
        color: Color.Green,
        name: 'Активная',
    },
    [ProfessionStateCodeEnum.BLOCKED]: {
        color: Color.Grey2,
        name: 'Заблокирована',
    }
};

export const professionsAllStateCodes:ProfessionStateCodeEnum[] = [
    ProfessionStateCodeEnum.ACTIVE,
    ProfessionStateCodeEnum.BLOCKED
];

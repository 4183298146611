import React, { FC } from 'react';
import { GroupLine } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/components/CalendarGroupLine/GroupLine';
import { IconBriefcase, IconDollySolid } from '../../../../../../../../components/icons';
import { rentersPageUrlRoute } from '../../../../../../renters/data/tabs';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { projectsPageUrlRoute } from '../../../../../../projects/projectsPage';
import { GroupItemProps } from '../../../../../components/CalendarItemRenderer/components/CalendarLine/utils/types';
import { subrentPageUrlRoute } from '../../../../../../subrent/subrentPage';
import { ElementsCalendarItem } from '../../../../../components/Calendar/types/items';

import { RentersPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';

export const ProjectGroupLine: FC<GroupItemProps<ElementsCalendarItem>> = (props) => {
    const { groupItem, displayType } = props;
    const { record } = groupItem;
    const { projectShortName, projectId, subrentShortName, subrentId, counterpartyShortName: subtitle, counterpartyId } = record;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const title = projectShortName ?? subrentShortName;

    const link = projectShortName
        ? `/${businessAccountId}/${projectsPageUrlRoute}/production/${projectId}`
        : subrentShortName
        ? `/${businessAccountId}/${subrentPageUrlRoute}/shippings/${subrentId}`
        : undefined;

    const sublink = `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}/${counterpartyId}`;

    return title ? (
        <GroupLine
            IconComponent={projectShortName ? IconBriefcase : IconDollySolid}
            displayType={displayType}
            title={{
                text: title,
                link: link,
            }}
            subtitle={
                subtitle
                    ? {
                          text: subtitle,
                          link: sublink,
                      }
                    : undefined
            }
        />
    ) : null;
};

import React, { ReactNode } from 'react';
import {
    PaymentInfoRead,
    PaymentRecord,
    PaymentSourceCodeEnum,
    PaymentStateCodeEnum,
    PaymentTransitionCodeEnum,
    PaymentTypeCodeEnum,
} from '../../../../server';
import { ActionTypeEnum } from '../../../../core/types/ActionTypeEnum';
import { Action } from '../../../../components/v2/gridActions/gridActions';
import { Icon } from 'antd';
import { IconReply } from '../../../../components/icons';
import { Link } from 'react-router-dom';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';

export namespace paymentsUtils {
    export const getPaymentSourceByCode = (code: PaymentSourceCodeEnum): ReactNode => {
        if (code === PaymentSourceCodeEnum.MANAGERINTERFACE) return 'Система';
        else if (code === PaymentSourceCodeEnum.STOREFRONT) return 'Витрина';
        else return null;
    };

    export const getPaymentStateByCode = (code: PaymentStateCodeEnum): string | null => {
        if (code === PaymentStateCodeEnum.DONE) return 'Проведен';
        else if (code === PaymentStateCodeEnum.PLANNED) return 'Запланирован';
        else if (code === PaymentStateCodeEnum.DELETED) return 'Удален';
        else return null;
    };

    export const getPaymentTypeByCode = (code: PaymentTypeCodeEnum): ReactNode => {
        if (code === PaymentTypeCodeEnum.PAYMENT) return 'Оплата';
        else if (code === PaymentTypeCodeEnum.RETURN) return 'Возврат';
        else return null;
    };

    export const getPaymentTypeIconByCode = (code: PaymentTypeCodeEnum): ReactNode => {
        if (code === PaymentTypeCodeEnum.PAYMENT) return undefined;
        else if (code === PaymentTypeCodeEnum.RETURN)
            return (
                <Icon
                    component={IconReply}
                    style={{
                        fontSize: 21,
                        color: '#8b5cb1',
                        marginRight: 10,
                    }}
                />
            );
        else return null;
    };

    export const getPaymentDisplayValueByCode = (code: PaymentTypeCodeEnum): ReactNode => {
        return (
            <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                {getPaymentTypeIconByCode(code)}
                {getPaymentTypeByCode(code)}
            </div>
        );
    };

    export const getPaymentStatusByCode = (code: PaymentStateCodeEnum): ReactNode => {
        const type = 'payment';
        return <span className={`rr-status rr-status-bg-${type ? type + '-' : ''}${code}`}>{getPaymentStateByCode(code)}</span>;
    };

    export const getAvailableActionsForRecord = (record: PaymentRecord | PaymentInfoRead): Action[] => {
        return [
            ActionTypeEnum.EDIT_ENTITY,
            record.availableTransitionCodes?.includes(PaymentTransitionCodeEnum.MAKE) && ActionTypeEnum.PAYMENT_MAKE,
            record.availableTransitionCodes?.includes(PaymentTransitionCodeEnum.RECOVER) && ActionTypeEnum.PAYMENT_RECOVER,
            record.availableTransitionCodes?.includes(PaymentTransitionCodeEnum.DELETE) && ActionTypeEnum.PAYMENT_DELETE,
        ];
    };

    export const getTransitionCodeByActionType = (type: ActionTypeEnum): PaymentTransitionCodeEnum | undefined => {
        if (type === ActionTypeEnum.PAYMENT_MAKE) return PaymentTransitionCodeEnum.MAKE;
        else if (type === ActionTypeEnum.PAYMENT_RECOVER) return PaymentTransitionCodeEnum.RECOVER;
        else if (type === ActionTypeEnum.PAYMENT_DELETE) return PaymentTransitionCodeEnum.DELETE;
    };

    export const getAllStateCodes = (): PaymentStateCodeEnum[] => {
        return [PaymentStateCodeEnum.PLANNED, PaymentStateCodeEnum.DONE, PaymentStateCodeEnum.DELETED];
    };

    export const getCreationStateCodes = (): PaymentStateCodeEnum[] => {
        return [PaymentStateCodeEnum.PLANNED, PaymentStateCodeEnum.DONE];
    };

    export const getAllSourceCodes = (): PaymentSourceCodeEnum[] => {
        return [PaymentSourceCodeEnum.MANAGERINTERFACE, PaymentSourceCodeEnum.STOREFRONT];
    };

    export const getCounterpartyDisplayValue = (record: PaymentRecord): ReactNode => {
        return (
            <span>
                <SystemIcon
                    type={record.projectPayment ? SystemIconType.project : SystemIconType.subrent}
                    style={{ verticalAlign: 'bottom', marginRight: 6 }}
                />
                <Link
                    title={record.rentActivityFrameShortName}
                    to={
                        record.projectPayment
                            ? `/${record.businessAccountId}/projects/production/${record.rentActivityFrameId}`
                            : `/${record.businessAccountId}/subrent/shippings/${record.rentActivityFrameId}`
                    }
                >
                    {record.rentActivityFrameShortName}
                </Link>
            </span>
        );
    };
}

import React, {useMemo} from "react";
import moment from "moment";
import {TimetableTypeCodeEnum} from "../../server";

interface CustomRangeCalendarDateItemProps {
    current: moment.Moment; // Дата для текущей ячейки, которая отрисовывается
    today: moment.Moment; // Это выбранная дата
    intervals?: [number, number][];
    typeCode: TimetableTypeCodeEnum;
    instanceCount?: number;
    dontShowBadges?: boolean;
    instance?: boolean;
    v: number;
}

export const CustomRangeCalendarDateItem = ({current, today, ...props}: CustomRangeCalendarDateItemProps) => {
    let available: number | undefined;
    let current1 = current.clone().subtract(1, "days");
    let availableOnNextDate: number | undefined;
    let className = '';

    let intervals;
    // if(props.intervals){
    //     const ldts = current.clone().subtract(50, "days").startOf('day').valueOf();
    //     const ndts = current.clone().subtract(-50, "days").endOf('day').valueOf();
    //     let nextInterval;
    //     console.log('======', current.toString(), moment(ldts).toString(), moment(ndts).toString());
    //     intervals = [];
    //     // Для текущего дня нужно интервалы получить
    //     for(let i = 0; i < props.intervals.length; i++){
    //         const ts = props.intervals[i][1];
    //         if(ts >= ldts && ts <= ndts){
    //             if(intervals.length === 0 && props.intervals[i-1]) intervals.push(props.intervals[i-1]);
    //             intervals.push(props.intervals[i]);
    //             if(props.intervals[i+1]) nextInterval = props.intervals[i+1];
    //         }
    //     }
    //     if(nextInterval){
    //         intervals.push(nextInterval);
    //     }
    // }

    intervals = props.intervals;

    const m = useMemo(() => {
        if (intervals && current.month() === today.month()) {
            intervals.forEach((interval, index, arr) => {
                if (index) {
                    let amount: number = interval[0];
                    let date: moment.Moment | undefined = moment(arr[index - 1][1]).hours(0).minutes(0).seconds(0).milliseconds(0);
                    let nextDate: moment.Moment | undefined = moment(interval[1]).hours(0).minutes(0).seconds(0).milliseconds(0);

                    if (current.isSameOrAfter(date) && current.isSameOrBefore(nextDate)) {
                        available = amount;
                        // ищим за этот день самое минимальное количество
                    }

                    if (current1.isSameOrAfter(date) && current1.isSameOrBefore(nextDate)) {
                        availableOnNextDate = amount;
                    }
                }
            });

            if (intervals && available !== undefined) {
                let minAmount = available;
                intervals.forEach((interval) => {
                    let intervalDate = moment(interval[1]);
                    if (intervalDate.year() === current.year() && intervalDate.month() === current.month() && intervalDate.date() === current.date()) {
                        minAmount = props.typeCode === TimetableTypeCodeEnum.AVAILABLE ? Math.min(minAmount, interval[0]) : Math.max(minAmount, interval[0]);
                    }
                });
                available = minAmount;
            }

            if (intervals && availableOnNextDate !== undefined) {
                let minAmount = availableOnNextDate;
                intervals.forEach((interval) => {
                    let intervalDate = moment(interval[1]);
                    if (intervalDate.year() === current1.year() && intervalDate.month() === current1.month() && intervalDate.date() === current1.date()) {
                        minAmount = props.typeCode === TimetableTypeCodeEnum.AVAILABLE ? Math.min(minAmount, interval[0]) : Math.max(minAmount, interval[0]);
                    }
                });
                availableOnNextDate = minAmount;
            }

            if (props.typeCode === TimetableTypeCodeEnum.AVAILABLE) {
                if (props.instanceCount !== undefined) {
                    if (available !== undefined) {
                        if (available - props.instanceCount < 0) className = 'rr-calendar-date-shortage';
                        else if (available - props.instanceCount > 0) className = 'rr-calendar-date-available';
                        else className = 'rr-calendar-date-unavailable';
                    }
                } else {
                    if (available !== undefined) {
                        if (available < 0) className = 'rr-calendar-date-shortage';
                        else if (available > 0) className = 'rr-calendar-date-available';
                        else className = 'rr-calendar-date-unavailable';
                    }
                }
            } else {

                if (props.typeCode === TimetableTypeCodeEnum.STOCK) {
                    if (available !== undefined) {
                        if (available > 0) className = 'rr-calendar-date-stock ';
                        else className = 'rr-calendar-date-stock';
                    }

                } else if (props.typeCode === TimetableTypeCodeEnum.MAINTENANCE) {
                    if (available !== undefined) {
                        if (available > 0) className = 'rr-calendar-date-shortage ';
                        else className = 'rr-calendar-date-shortage';
                    }
                } else if (props.typeCode === TimetableTypeCodeEnum.OCCUPIED || props.typeCode === TimetableTypeCodeEnum.SUBRENTED) {
                    if (available !== undefined) {
                        if (available > 0) className = 'rr-calendar-date-rent ';
                        else className = 'rr-calendar-date-unavailable';
                    }
                }
            }

        }

        let showBadge = ((available !== undefined && available !== availableOnNextDate) || (available !== undefined && current.date() === 1));
        // Это для экземпляров, что б не выводились цифры для 0 и 1
        if ((props.dontShowBadges) && available !== undefined && available > -1) showBadge = false;

        return props.typeCode === TimetableTypeCodeEnum.AVAILABLE ? (
                <div className="ant-calendar-date" style={{position: "relative",}}>
                    <div className={className}>
                        {current.date()}
                    </div>
                    {
                        props.instance ? showBadge ? <div
                                className={'rr-calendar-date-amount-badge'}>{available === 0 ? '-' : available === 1 ? '✓' : available}</div> : null :
                            showBadge ? <div className={'rr-calendar-date-amount-badge'}>{available}</div> : null

                    }
                </div>
            ) :
            (
                <div className="ant-calendar-date" style={{position: "relative"}}>
                    <div className={available === 0 ? 'rr-calendar-date-zero' : className}
                         style={available === 0 ? {backgroundColor: '#f4f5f8'} : {}}>
                        {current.date()}
                    </div>
                    {
                        showBadge ?
                            props.instance ?
                                <div
                                    className={'rr-calendar-date-amount-badge'}>{available === 0 ? '-' : available === 1 ? '✓' : available}</div> :
                                <div className={'rr-calendar-date-amount-badge'}>{available}</div> : null
                    }
                </div>
            );
    }, [current.valueOf(), today.valueOf(), props.typeCode, props.instanceCount, props.dontShowBadges, props.instance, props.v]);

    return m;
};

import React, { ReactNode } from 'react';
import { Icon, Popover, Radio } from 'antd';
import { IconSortAmountDownAltSolid } from '../../../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import '../ControlPopoverStyles.less';
import { typedMemo } from '../../../../../../../../core/hooks/useMemo';

export interface CalendarSortValue<T extends string> {
    value: T;
    title: ReactNode;
}

export interface SortButtonProps<T extends string> {
    sortValue: T | undefined;
    sortData: CalendarSortValue<T>[];
    onSortChange: (value: T) => void;
    disabled: boolean;
    placement?: 'left' | 'right';
}

const SortByButtonComponent = <T extends string>(props: SortButtonProps<T>) => {
    const { sortValue, disabled, sortData, onSortChange, placement = 'left' } = props;

    return (
        <Popover
            trigger={'click'}
            placement={placement === 'left' ? 'bottomRight' : 'bottomLeft'}
            arrowPointAtCenter
            content={
                <div className={'control-popover-container'}>
                    <div className="control-popover-header">{localize(LocalizationEnum.ASPECT__FILTERS__SORT_BY)}:</div>

                    <Radio.Group
                        disabled={disabled}
                        onChange={(event) => setTimeout(() => onSortChange(event.target.value as T))}
                        value={sortValue}
                    >
                        {sortData.map(({ value, title }) => (
                            <Radio key={value} className={'radio-button'} value={value}>
                                {title}
                            </Radio>
                        ))}
                    </Radio.Group>
                </div>
            }
        >
            <Icon className={'rr-grid-settings-icon'} component={IconSortAmountDownAltSolid} style={{ marginRight: 17 }} />
        </Popover>
    );
};

export const SortByButton = typedMemo(SortByButtonComponent);

import {useCallback} from 'react';
import {DndProps} from '../../../components/grid/grid/ReactTableBody';

export interface UseOnChangeOrderDnd {
    editData: unknown[] | undefined;
    setEditData: (editData: any[]) => void;
}

export const useOnDragEnd = (props: UseOnChangeOrderDnd): DndProps['onDragEnd'] => {
    const { editData, setEditData } = props;

    return useCallback(
        (result) => {
            const { source, destination } = result;

            if (editData == null || destination == null) return;

            const newDataSorted = [...editData];
            const sourceIndex = newDataSorted.findIndex((_, index) => index === source.index);
            const destinationIndex = newDataSorted.findIndex((_, index) => index === destination.index);

            const sourceItem = newDataSorted[sourceIndex];

            newDataSorted.splice(sourceIndex, 1);
            newDataSorted.splice(destinationIndex, 0, sourceItem);

            setEditData(newDataSorted);
        },
        [editData, setEditData]
    );
};

import { professionsCompositionColumns } from '../../../columns/professionsCompositionColumns';
import { Grid } from '../../../../../../../../../../../../components/grid/Grid';
import React, { FC, memo } from 'react';
import { CREW_MEMBER_COMPOSITION_GRID_NAME } from '../../../CrewMemberProfessions';
import { CrewMemberInfoRead } from '../../../../../../../../../../../../server';
import { useGridData } from '../../../hooks/useGridData';
import classNames from 'classnames';
import { GridHeader } from '../GridHeader/GridHeader';
import { Card, Icon } from 'antd';
import { IconListAlt } from '../../../../../../../../../../../../components/icons';
import RoundButton from '../../../../../../../../../../../../components/button/roundButton';

interface CrewMemberStaticGridProps {
    crewMember: CrewMemberInfoRead;
    setIsEdit: (value: boolean) => void;
}

export const CrewMemberStaticGrid: FC<CrewMemberStaticGridProps> = memo((props) => {
    const { crewMember, setIsEdit } = props;

    const gridData = useGridData({
        crewMember,
    });

    return (
        <Card
            className={classNames('grid-card', 'adaptive-width')}
            title={
                <GridHeader
                    Button={
                        <RoundButton colorScheme={'defaultSecondary'} className={'header-button'} onClick={() => setIsEdit(true)}>
                            <Icon component={IconListAlt} />
                            <span>{'Настроить специальности и ставки'}</span>
                        </RoundButton>
                    }
                />
            }
        >
            <Grid
                columns={professionsCompositionColumns}
                data={gridData}
                filtered={gridData?.length ?? 0}
                hidePageSizeSelect
                entityType={CREW_MEMBER_COMPOSITION_GRID_NAME}
                selectable={false}
                gridName={CREW_MEMBER_COMPOSITION_GRID_NAME}
                indexFieldName={'id'}
                defaultSorted={'memberOrder'}
            />
        </Card>
    );
});

import React, { FC } from 'react';
import { Button } from 'antd';
import { StepTwoRegistrationAnswers } from '../RegistrationStepTwo/RegistrationStepTwo';
import { RegisterStepThreeModuleLine } from './components/ModuleLine/RegistrationStepThreeModuleLine';
import {
    IconDollySolid,
    IconHardHatSolid,
    IconMapMarkedAltSolid,
    IconMoneyBillWaveSolid,
    IconMoneyCheckAltSolid,
} from '../../../../../components/icons';
import { IconChartArea } from '../../../../../components/icons/IconChartArea';
import { BusinessAccountModuleCodeEnum } from '../../../../../server';

interface RegistrationStepThreeProps {
    stepTwoAnswers: StepTwoRegistrationAnswers;
    loading: boolean;
    onCreateCompany: () => void;
}

export const RegistrationStepThree: FC<RegistrationStepThreeProps> = (props) => {
    const { loading, onCreateCompany, stepTwoAnswers } = props;

    return (
        <div
            className="form-wrap"
            style={{
                padding: '36px 112px 56px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <h1
                style={{
                    color: '#5087e0',
                    fontWeight: 600,
                    textAlign: 'center',
                }}
            >
                Будут включены следующие модули
            </h1>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    marginTop: 28,
                    marginBottom: 40,
                    gap: 8,
                }}
            >
                <RegisterStepThreeModuleLine
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.LOGISTICS]}
                    icon={IconMapMarkedAltSolid}
                    title={'Модуль логистики'}
                />
                <RegisterStepThreeModuleLine
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.CREW]}
                    icon={IconHardHatSolid}
                    title={'Модуль персонала'}
                />
                <RegisterStepThreeModuleLine
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.SUBRENT]}
                    icon={IconDollySolid}
                    title={'Модуль субаренды'}
                />
                <RegisterStepThreeModuleLine value={true} icon={IconChartArea} title={'Модуль статистики'} />
                <RegisterStepThreeModuleLine value={true} icon={IconMoneyBillWaveSolid} title={'Модуль затрат'} />
                <RegisterStepThreeModuleLine
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.OFFERS]}
                    icon={IconMoneyCheckAltSolid}
                    title={'Модуль смет'}
                />
            </div>
            <span
                style={{
                    textAlign: 'center',
                    marginBottom: 21,
                }}
            >
                После начала работы, вы всегда сможете включить и отключить нужные модули без потери данных!
            </span>

            <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className={`btn-green btn-registration`}
                onClick={() => onCreateCompany()}
            >
                <span>Начать работу</span>
            </Button>
        </div>
    );
};

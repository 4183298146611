import React from 'react';
import { FileTypeCodeEnum } from '../../../../../server';
import { LinkButton } from '../../../../buttons/linkButton/linkButton';
import { AttachmentIcon } from '../../../../../modules/main/attachments/attachmentIcon';
import { DownloadDocumentPopoverProps, DownloadDocumentTemplateSelectedValue } from '../../DownloadDocumentPopover';
import { DownloadPeriodValues } from '../Period/hooks/useSelectOptions';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { getPeriodValuesByOptionValue } from '../Period/utils/getPeriodValuesByOptionValue';
import moment from 'moment';
import classNames from 'classnames';

interface DownloadDocumentPopoverExportsProps<FileType extends FileTypeCodeEnum, Options extends object> {
    availableFileTypes: FileTypeCodeEnum[];
    withTemplate: boolean | undefined;
    selectedTemplateValue: DownloadDocumentTemplateSelectedValue | undefined;
    selectedLegalDetailValue: number | undefined;
    onDownload: DownloadDocumentPopoverProps<FileType, Options>['onDownload'];
    selectedValues: Options[];
    discount: number | undefined;
    setVisible: (value: boolean) => void;
    periodValue: DownloadPeriodValues | RangePickerValue | undefined;
    setLoading: (value: boolean) => void;
}

export const DownloadDocumentPopoverExports = <FileType extends FileTypeCodeEnum, Options extends object>(
    props: DownloadDocumentPopoverExportsProps<FileType, Options>
) => {
    const {
        availableFileTypes,
        discount,
        onDownload,
        selectedValues,
        periodValue,
        selectedTemplateValue,
        setVisible,
        withTemplate,
        selectedLegalDetailValue,
        setLoading,
    } = props;

    const getAdditionalFiltersByPeriodValue = (): [string, string] | undefined => {
        if (periodValue == null) return undefined;

        if (Array.isArray(periodValue)) {
            if (periodValue[0] == null || periodValue[1] == null) return undefined;

            return [moment(periodValue[0]).toISOString(), moment(periodValue[1]).toISOString()];
        }

        const dates = getPeriodValuesByOptionValue(periodValue);

        return [moment(dates[0]).toISOString(), moment(dates[1]).toISOString()];
    };

    const exportDocument = async (fileType: FileType) => {
        const period = getAdditionalFiltersByPeriodValue();
        setLoading(true);
        await onDownload({
            fileType,
            options: selectedValues,
            templateId: selectedTemplateValue?.key,
            isCommonTemplate: selectedTemplateValue?.common,
            discount,
            period,
            legalDetailId: selectedLegalDetailValue,
        });
        setLoading(false);
        setVisible(false);
    };

    const downloadButtonDisabled = withTemplate && !selectedTemplateValue;

    return (
        <div className={'content'}>
            <span className={'title'}>Выберите формат:</span>
            <div
                className={classNames('exports', {
                    compact: true,
                })}
            >
                {availableFileTypes.includes(FileTypeCodeEnum.EXCEL) && (
                    <LinkButton block disabled={downloadButtonDisabled} onClick={() => exportDocument(FileTypeCodeEnum.EXCEL as FileType)}>
                        <AttachmentIcon type={FileTypeCodeEnum.EXCEL} />
                        Скачать в Excel
                    </LinkButton>
                )}
                {availableFileTypes.includes(FileTypeCodeEnum.WORD) && (
                    <LinkButton block disabled={downloadButtonDisabled} onClick={() => exportDocument(FileTypeCodeEnum.WORD as FileType)}>
                        <AttachmentIcon type={FileTypeCodeEnum.WORD} />
                        Скачать в Word
                    </LinkButton>
                )}
                {availableFileTypes.includes(FileTypeCodeEnum.PDFTYPE) && (
                    <LinkButton
                        block
                        disabled={downloadButtonDisabled}
                        onClick={() => exportDocument(FileTypeCodeEnum.PDFTYPE as FileType)}
                    >
                        <AttachmentIcon type={FileTypeCodeEnum.PDFTYPE} />
                        Скачать в PDF
                    </LinkButton>
                )}
            </div>
        </div>
    );
};

import {KitInfoRead, KitMemberObjRead} from '../../../../../../../server';
import {useMemo} from 'react';
import {ProductCompositionColumnsObj} from '../columns/productCompositionColumns.types';

export const useGridData = (
    kit: KitInfoRead,
    pageParams?: ProductCompositionFilters
): {
    gridData: ProductCompositionColumnsObj[];
    filtered: number;
} => {
    const getGridId = (kitMember: KitMemberObjRead) =>
        kitMember.variantId ? kitMember.id + 'v' + kitMember.variantId : kitMember.id + 'p' + kitMember.productId;

    return useMemo(() => {
        let gridData = [...kit.members];

        if (pageParams?.search) {
            gridData = gridData.filter((item) => item.productShortName.toLowerCase().includes(pageParams.search!.toLowerCase()));
        }

        const filtered = gridData.length;

        if (pageParams == null) {
            return {
                gridData: gridData.map((kitMember, index) => ({
                    ...kitMember,
                    memberOrder: index,
                    id: getGridId(kitMember),
                })),
                filtered,
            };
        }

        const { page = 1, limit = 10 } = pageParams;

        const gridDataWithIndex = gridData
            .map((gridData, index) => [gridData, index] as const)
            .slice((page - 1) * limit, (page - 1) * limit + limit);

        const extendedGridData: ProductCompositionColumnsObj[] = gridDataWithIndex.map(([member, index]) => ({
            ...member,
            memberOrder: index,
            id: getGridId(member),
        }));

        return { gridData: extendedGridData, filtered: filtered };
    }, [kit.members, pageParams]);
};

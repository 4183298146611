import { LocationRecord } from '../../../../../../server';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import RoundButton from '../../../../../../components/button/roundButton';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Icon } from 'antd';
import { IconArchive, IconLevelUp, IconTrash } from '../../../../../../components/icons';
import { useArchiveLocationsMutation, useDeleteLocationsMutation } from '../api/locations.api';
import { _Grid } from '../../../../../../components/grid/Grid';

export const useEntityActionsButtons = ({
    selectedRecords,
    gridRef,
}: {
    selectedRecords: LocationRecord[];
    gridRef: React.RefObject<_Grid>;
}) => {
    const [archiveLocations, { isLoading: isArchiveLocationsLoading }] = useArchiveLocationsMutation();

    const [deleteLocations, { isLoading: isDeleteLocationsLoading }] = useDeleteLocationsMutation();

    const isLoading = isArchiveLocationsLoading || isDeleteLocationsLoading;

    const deleteSelectedLocations = useCallback(() => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => {
            return {
                id,
                businessVersion,
            };
        });

        deleteLocations({
            versionedEntityObjList: {
                entities: versionedSelectedEntitiesObjects,
            },
        });

        gridRef?.current?.clearSelection();
    }, [deleteLocations, gridRef, selectedRecords]);

    const archiveSelectedLocations = useCallback(() => {
        archiveLocations({
            workflowDirectiveBulk: {
                directives: selectedRecords.map(({ id, archive, businessVersion }) => {
                    return {
                        id,
                        archive: !archive,
                        businessVersion,
                    };
                }),
            },
        });

        gridRef?.current?.clearSelection();
    }, [archiveLocations, gridRef, selectedRecords]);

    return useMemo(
        () => ({
            buttons: [
                _.every(selectedRecords, (item: LocationRecord) => !item.archive && item.archivable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        onClick={archiveSelectedLocations}
                        colorScheme={'TO_ARCHIVE'}
                    >
                        <Icon component={IconArchive} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}</span>
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (item: LocationRecord) => item.archive) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        onClick={archiveSelectedLocations}
                        colorScheme={'FROM_ARCHIVE'}
                    >
                        <Icon component={IconLevelUp} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}</span>
                    </RoundButton>
                ) : null,
                _.every(selectedRecords, (item: LocationRecord) => item.deleteable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__DELETE}
                        title={'Удалить'}
                        onClick={deleteSelectedLocations}
                        colorScheme={'DELETE'}
                    >
                        <Icon component={IconTrash} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__DELETE)}</span>
                    </RoundButton>
                ) : null,
            ],
            isLoading,
        }),
        [archiveSelectedLocations, deleteSelectedLocations, isLoading, selectedRecords]
    );
};

import React from 'react';
import {AggregationsPopover} from "../../../../components/aggregationsPopover/aggregationsPopover";
import {ExpenseListAggregations} from "../../../../server";
import {AggregationsPopoverWrapper} from "../../../../components/page/entityGrid/types/popover";
import {formatMoney} from "../../../../shared/util/formatMoney";

export const ExpensesListAggregationsPopover: AggregationsPopoverWrapper<ExpenseListAggregations> = ({data, isFetching, fetchData, isError}) => {

    return (
        <AggregationsPopover
            data={data && [
                {label: 'Кол-во затрат', value: data.count},
                {label: 'Внутренняя сумма', value: formatMoney(data.internalExpensesSum)},
                {label: 'Внешняя сумма', value: formatMoney(data.externalExpensesSum)},
                {label: 'Внешний налог', value: formatMoney((data.externalExpensesSumAfterTax ?? 0) - (data.externalExpensesSum ?? 0))},
                {label: 'Внешняя сумма после налога', value: formatMoney(data.externalExpensesSumAfterTax)},
            ]}
            fetchData={fetchData}
            isFetching={isFetching}
            isError={isError}
        />
    );
};
import React, { FC, ReactNode, useState } from 'react';
import { SettingsPageWrapper } from '../settingsPageWrapper';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ModuleInfo } from './moduleInfo';
import { Icon } from 'antd';
import {
    IconBriefcase,
    IconHardHatSolid,
    IconMapMarkedAltSolid,
    IconMoneyBillWaveSolid,
    IconMoneyCheckAltSolid, IconShoppingBagSolid
} from '../../../../components/icons';
import { BusinessAccountModuleCodeEnum } from '../../../../server';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { IconChartArea } from '../../../../components/icons/IconChartArea';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { useIntl } from 'react-intl';
import {
    enableOrDisableBusinessAccountModule,
    getBusinessAccountPreferences,
} from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { LocalizationEnum, useLocalize } from '../../../../core/hooks/useLocalize';
import { showNotification } from '../../../../components/notification/showNotification';

interface ModuleData {
    code: BusinessAccountModuleCodeEnum;
    icon: ReactNode;
    name: ReactNode;
    description: ReactNode;
    enabled?: boolean | null;
    canDisable: boolean;
    inProgress?: boolean;
}

export const ModulesModule: FC = () => {
    const intl = useIntl();
    const L = useLocalize();
    const preferences = useAppSelector((state) => state.businessAccountPreferences.preferences);
    const modules = preferences?.modules;

    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const businessAccount = useAppSelector((state) => state.businessAccount.entity);
    const availableModules = businessAccount?.availableModules || [];

    const data: ModuleData[] = [
        {
            code: BusinessAccountModuleCodeEnum.ORDERS,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__ORDERS_MODULE_NAME),
            icon: <Icon component={IconShoppingBagSolid} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__ORDERS_MODULE_DESCRIPTION),
            enabled: modules?.ordersModuleEnabled,
            canDisable: modules?.projectsModuleEnabled === true
        },
        {
            code: BusinessAccountModuleCodeEnum.PROJECTS,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__PROJECTS_MODULE_NAME),
            icon: <Icon component={IconBriefcase} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__PROJECTS_MODULE_DESCRIPTION),
            enabled: modules?.projectsModuleEnabled,
            canDisable: modules?.ordersModuleEnabled === true
        },
        {
            code: BusinessAccountModuleCodeEnum.LOGISTICS,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__LOGISTICS_MODULE_NAME),
            icon: <Icon component={IconMapMarkedAltSolid} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__LOGISTICS_MODULE_DESCRIPTION),
            enabled: modules?.logisticsModuleEnabled,
            canDisable: true
        },
        {
            code: BusinessAccountModuleCodeEnum.CREW,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__CREW_MODULE_NAME),
            icon: <Icon component={IconHardHatSolid} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__CREW_MODULE_DESCRIPTION),
            enabled: modules?.crewModuleEnabled,
            canDisable: true
        },
        {
            code: BusinessAccountModuleCodeEnum.SUBRENT,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__SUBRENT_MODULE_NAME),
            icon: <SystemIcon type={SystemIconType.subrent} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__SUBRENT_MODULE_DESCRIPTION),
            enabled: modules?.subrentModuleEnabled,
            canDisable: true
        },
        {
            code: BusinessAccountModuleCodeEnum.STATISTICS,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__STATISTICS_MODULE_NAME),
            icon: <Icon component={IconChartArea} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__STATISTICS_MODULE_DESCRIPTION),
            enabled: modules?.statisticsModuleEnabled,
            canDisable: true
        },
        {
            code: BusinessAccountModuleCodeEnum.EXPENSES,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__EXPENSES_MODULE_NAME),
            icon: <Icon component={IconMoneyBillWaveSolid} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__EXPENSES_MODULE_DESCRIPTION),
            enabled: modules?.expensesModuleEnabled,
            canDisable: true
        },
        {
            code: BusinessAccountModuleCodeEnum.OFFERS,
            name: L(LocalizationEnum.PAGE__SETTINGS__MODULES__OFFERS_MODULE_NAME),
            icon: <Icon component={IconMoneyCheckAltSolid} />,
            description: L(LocalizationEnum.PAGE__SETTINGS__MODULES__OFFERS_MODULE_DESCRIPTION),
            enabled: modules?.offerModuleEnabled,
            canDisable: true
        },
    ];

    return (
        <SettingsPageWrapper title={L(LocalizationEnum.PAGE__SETTINGS__SECTION_TREE__MODULES)} bodyStyle={{ padding: 0 }} loading={loading}>
            {data.map((module) => {
                let enabled = module.enabled;
                if(!availableModules || !availableModules.includes(module.code)) enabled = null;

                return enabled !== undefined ? (
                    <ModuleInfo
                        name={module.name}
                        icon={module.icon}
                        enabled={enabled}
                        canDisable={module.canDisable}
                        description={module.description}
                        inProgress={module.inProgress}
                        toggleEnabled={async () => {
                            if (preferences && (enabled === true || enabled === false)) {
                                const ok = await showConfirm(
                                    intl,
                                    enabled
                                        ? L(LocalizationEnum.PAGE__SETTINGS__MODULES__DISABLE_MODULE_WARNING)
                                        : L(LocalizationEnum.PAGE__SETTINGS__MODULES__ENABLE_MODULE_WARNING)
                                );
                                if (ok) {
                                    const enable = !enabled;
                                    setLoading(true);
                                    try {
                                        await dispatch(
                                            enableOrDisableBusinessAccountModule({
                                                businessAccountId,
                                                moduleCode: module.code,
                                                enable: enable,
                                                businessVersion: preferences.businessVersion,
                                            })
                                        );
                                        showNotification(
                                            'success',
                                            enable
                                                ? L(LocalizationEnum.PAGE__SETTINGS__MODULES__ENABLE_MODULE_SUCCESS_MESSAGE)
                                                : L(LocalizationEnum.PAGE__SETTINGS__MODULES__DISABLE_MODULE_SUCCESS_MESSAGE)
                                        );
                                        try {
                                            await dispatch(getBusinessAccountPreferences(businessAccountId, true));
                                        } catch (err) {}
                                    } catch (err) {
                                        showNotification(
                                            'error',
                                            enable
                                                ? L(LocalizationEnum.PAGE__SETTINGS__MODULES__ENABLE_MODULE_ERROR_MESSAGE)
                                                : L(LocalizationEnum.PAGE__SETTINGS__MODULES__DISABLE_MODULE_ERROR_MESSAGE)
                                        );
                                    }
                                    setLoading(false);
                                }
                            }
                        }}
                    />
                ) : null;
            })}
        </SettingsPageWrapper>
    );
};

import { CustomFieldWithValueObjRead } from '../../../../../../../server';
import { DraggableLocation } from 'react-beautiful-dnd';

export const moveDragItems = (
    source: CustomFieldWithValueObjRead[],
    destination: CustomFieldWithValueObjRead[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

import React, { FC, useCallback } from 'react';
import { Route } from 'react-router';
import { ProfessionDrawerWrapper } from '../../../../../../../../professions/drawer/components/ProfessionDrawer/wrappers/ProfessionDrawerWrapper';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { businessAccountIdSelector } from '../../../../../../../../../../../../shared/reducers/system.reducer';
import { CrewMemberUtils } from '../../../../../../../utils/helpers';
import { useSetUrlForDrawer } from '../../../../../../../../../../../../core/hooks/useDrawer';
import { CrewMemberInfoRead } from '../../../../../../../../../../../../server';
import { useProfessionEntityActions } from '../../../../../../../../professions/hooks/useProfessionEntityActions';
import { ProfessionEditModalWrapper } from '../../../../../../../../professions/modal/components/wrappers/ProfessionEditModalWrapper';
import { useProfessionByIdQuery } from '../../../../../../../../professions/api/professions.api';
import { useAppDispatch } from '../../../../../../../../../../../../store/hooks';
import { loadCrewMember } from '../../../../../../../reducers/crewMember/crewMember.reducer';

import { CrewMemberPageTabsEnum } from '../../../../../../../../../../../../shared/constants/tabEnums';

interface ProfessionModalsProps {
    crewMember: CrewMemberInfoRead;
}

export const ProfessionModals: FC<ProfessionModalsProps> = (props) => {
    const { crewMember } = props;

    const location = useLocation();
    const dispatch = useAppDispatch();
    const businessAccountId = useSelector(businessAccountIdSelector);
    const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries());

    const currentRootPath = CrewMemberUtils.getRootPath(businessAccountId);
    const currentCardPath = CrewMemberUtils.getCardPath(businessAccountId, crewMember.id);

    const [setEntityId, clearEntityId] = useSetUrlForDrawer<CrewMemberInfoRead>(currentCardPath, new URLSearchParams());

    const entityActions = useProfessionEntityActions({
        rootPath: currentCardPath,
        urlSearchParams: new URLSearchParams({
            tab: CrewMemberPageTabsEnum.PROFESSIONS,
        }),
    });

    const isModalVisible = searchParams.id && searchParams.modal === 'edit';
    const isDrawerVisible =
        searchParams.id && (searchParams.modal === 'view' || searchParams.modal === 'edit' || searchParams.view === 'true');

    const { data: profession } = useProfessionByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: Number(searchParams.id),
        },
        {
            skip: !isModalVisible,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const onOkCallback = useCallback(() => {
        dispatch(
            loadCrewMember({
                businessAccountId,
                entityId: crewMember.id,
            })
        );
    }, [businessAccountId, crewMember.id, dispatch]);

    return (
        <>
            {isModalVisible && profession && (
                <Route
                    path={currentRootPath}
                    render={() => <ProfessionEditModalWrapper entity={profession} onOkCallback={onOkCallback} />}
                />
            )}
            {isDrawerVisible && (
                <Route
                    path={currentRootPath}
                    render={() => (
                        <ProfessionDrawerWrapper
                            entityId={Number(searchParams.id)}
                            onClose={() => clearEntityId()}
                            editActionForDrawer={entityActions.editActionForDrawer}
                            archiveActionForDrawer={entityActions.archiveActionForDrawer}
                            deleteActionForDrawer={entityActions.deleteActionForDrawer}
                        />
                    )}
                />
            )}
        </>
    );
};

import { useCallback } from 'react';
import { NomenclatureRecordCustom } from '../../api/products.api.types';
import { NomenclatureEntityTypeCodeEnum } from '../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useIntl } from 'react-intl';
import { setStatus } from '../../reducers/products.reducer';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';

export const useSetStatusForProducts = (selectedRecords: NomenclatureRecordCustom[]) => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const dispatch = useAppDispatch();
    const gridRef = useEntityGridContext();

    return useCallback(() => {
        const selectedItems = selectedRecords.map((item) => {
            return {
                id: item.variantId || item.productId,
                businessVersion: item.businessVersion,
                transitionCode: item.stateCode === 'ACTIVE' ? 'BLOCK' : 'ACTIVATE',
                nomenclatureEntityTypeCode: item.variantId
                    ? NomenclatureEntityTypeCodeEnum.VARIANT
                    : NomenclatureEntityTypeCodeEnum.PRODUCT,
            };
        });

        setStatus(intl, businessAccountId, selectedItems)(dispatch);

        gridRef?.current?.clearSelection();
    }, [businessAccountId, dispatch, gridRef, intl, selectedRecords]);
};

import React, { FC, SetStateAction } from 'react';
import { Checkbox, Icon, Popover } from 'antd';
import { getAvailableStacks, getDisabledStacks } from '../../utils/getAvailableStacks';
import { useAppSelector } from '../../../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { IconSliders } from '../../../../../../../components/icons';
import { AccessibilityMapLocalizationMap } from '../../data/localizationData';
import { TimetableTypeCodeEnum } from '../../../../../../../server';
import { ArrayUtils } from '../../../../../../../core/utils/arrayUtils';
import { AccessibilityMapFilters } from '../../filters/accessibility/accessibilityMapCalendarFilters.types';
import { PageUrlParamsObject } from '../../../../../../../core/hooks/urlParams/types';

interface StacksCheckboxesProps {
    pageParamsObject: PageUrlParamsObject<AccessibilityMapFilters>;
    stacks: TimetableTypeCodeEnum[];
    setStacks: React.Dispatch<SetStateAction<TimetableTypeCodeEnum[]>>;
}

export const StacksCheckboxes: FC<StacksCheckboxesProps> = (props) => {
    const { pageParamsObject, stacks, setStacks } = props;
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const { pageParams, updatePageParams } = pageParamsObject;

    const currentStacks = getAvailableStacks(subrentModuleEnabled);
    const disabledStacks = getDisabledStacks(pageParams, subrentModuleEnabled);

    const onChange = (typeCode: TimetableTypeCodeEnum) => {
        const newValue = ArrayUtils.addOrDeleteItem(stacks, typeCode);
        updatePageParams(
            {
                stacks: newValue,
            },
            {
                replaceWithoutRerender: true,
            }
        );
        setStacks(newValue);
    };

    return (
        <Popover
            trigger={'click'}
            placement="bottomRight"
            arrowPointAtCenter
            overlayClassName={'rr-grid-columns-control-popover-overlay'}
            destroyTooltipOnHide
            content={
                <div className={'rr-grid-columns-control-popover-content'}>
                    <div className="rr-calendar-popup-header">Показатели:</div>
                    {currentStacks.map((typeCode, index) => {
                        const checked = stacks.includes(typeCode);
                        const isDisabled = disabledStacks.includes(typeCode);

                        return (
                            <Checkbox key={index} disabled={isDisabled} checked={checked} onChange={() => onChange(typeCode)}>
                                {AccessibilityMapLocalizationMap[typeCode]}
                            </Checkbox>
                        );
                    })}
                    {pageParams.kitId && (
                        <span
                            style={{
                                marginTop: 4,
                                marginBottom: 4,
                                fontSize: 11,
                                color: '#aaa',
                                width: 'min-content',
                                lineHeight: '19px',
                            }}
                        >
                            Выбран набор, для наборов поддерживается меньше показателей
                        </span>
                    )}
                </div>
            }
        >
            <Icon className={'rr-grid-settings-icon'} component={IconSliders} />
        </Popover>
    );
};

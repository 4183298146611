import {FormFieldsGroup, FormItemType} from "../../../../../../../components/dynamicForm/DynamicForm";

export const formFields: FormFieldsGroup[] = [
    {
        //title: 'Тип продукта',
        fields: [
            {
                id: 'id',
                type: FormItemType.Hidden,
            },
            {
                id: 'childVariantCount',
                type: FormItemType.Hidden,
            },
            {
                id: 'childInstanceCount',
                type: FormItemType.Hidden,
            },
            {
                label: 'Поддержка вариантов',
                id: 'variant',
                type: FormItemType.Switch,
                defaultValue: false,
                disabled: getFieldValue => (!!getFieldValue('childVariantCount') || !!getFieldValue('childInstanceCount'))
            },
            {
                label: 'Поддержка именованных экземпляров',
                id: 'tracked',
                type: FormItemType.Switch,
                defaultValue: false,
                disabled: getFieldValue => !!getFieldValue('childInstanceCount')
            }
        ]
    }
];

import { IModalFormFilters } from '../../../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../../../components/modalForm/components/Header/types/displayData';
import { ProductInfoRead, ProductRecord } from '../../../../../../../server';
import {
    allFieldsDisplayGroup,
    mainFieldsDisplayGroup,
} from '../../../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof (ProductRecord & ProductInfoRead) | 'variant' | 'tracked'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [
            ['id', 'shortName'],
            ['variant', 'tracked'],
            ['externalCode', 'categoryIds'],
            ['pricePerShift', 'pricingScheme'],
        ],
    },
];

export const createProjectFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editProjectFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};

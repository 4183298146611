import { EntityGridEditModalWrapper } from '../../../../../../../../components/page/entityGrid/types/modal';
import { CrewMemberInfoRead } from '../../../../../../../../server';
import { memo } from 'react';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { CrewMemberEditModal } from '../EditModal/CrewMemberEditModal';

export const CrewMemberEditModalWrapper: EntityGridEditModalWrapper<CrewMemberInfoRead> = memo(({ entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <CrewMemberEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование проектного работника'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            crewMember={entity}
        />
    );
});

import ReactDOM from 'react-dom';
import React from 'react';
import { Modal } from 'antd';
import DynamicForm from '../dynamicForm/DynamicForm';
import './modalForm.less';
import Spin from '../spin/spin';
import { Alert } from '../alert/alert';
import { LocalizationEnum, localize, localizeIntl } from '../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface IState {
    validating: boolean;
}

interface IProps extends WrappedComponentProps {
    title?: string | React.ReactNode;
    visible?: boolean;
    formFieldsData?: any; // TODO Заменить на норм тип
    initialValues?: any; // TODO Заменить на норм тип
    onOk?(data: any): void;
    onCancel?(isFieldsTouched: Boolean): void;
    saving?: boolean;
    error?: any;
    cancelButtonText?: string | React.ReactNode;
    okButtonText?: string | React.ReactNode;
    validateAfterCreate?: boolean;
    addField?: (fieldName: string, index: number) => void;
    removeField?: (fieldName: string, index: number) => void;
}

class ModalForm extends React.Component<IProps, IState> {
    state = {
        validating: false,
    };

    _formRef;

    public static defaultProps = {
        visible: false,
        saving: false,
    };

    componentDidMount = () => {
        console.log('ModalForm componentDidMount()');
        if (this.props.validateAfterCreate) {
            this.interval = setInterval(() => {
                if (this._formRef) {
                    this.onOk();
                    clearInterval(this.interval);
                }
            }, 100);
        }
    };

    interval;

    componentWillUnmount = () => {
        console.log('ModalForm componentWillUnmount()');
    };

    componentDidUpdate = (prevProps: IProps) => {
        // Если есть ошибка, то нужно скролить на верх
        if (this.props.error && prevProps.error !== this.props.error) {
            let node = ReactDOM.findDOMNode(this);
            if (node instanceof Element && (node as Element).getElementsByClassName('ant-modal-wrap ')[0]) {
                (node as Element).getElementsByClassName('ant-modal-wrap ')[0].scrollTop = 0;
            }
        }
    };

    onOk = () => {
        if (this._formRef) {
            this.setState({ validating: true });
            this._formRef.validateFieldsAndScroll({ scroll: { offsetTop: 36 } }, (err, values) => {
                this.setState({ validating: false });
                if (!err) {
                    if (this.props.onOk) this.props.onOk(values);
                }
            });
        }
    };

    onCancel = () => {
        let fields: string[] = [];
        this.props.formFieldsData.map((group) => group.fields.map((field) => fields.push(field.id)));
        if (this.props.onCancel) this.props.onCancel(this._formRef.isFieldsTouched(fields));
    };

    getFormRef = (ref) => {
        return (this._formRef = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    render() {
        let { title, visible, saving, error, formFieldsData, initialValues } = this.props;

        console.log('ModalForm render', this.state, this.props);

        let loading = saving || this.state.validating;

        return (
            <Modal
                className={'rr-modal'}
                width={800}
                cancelButtonProps={{ className: 'rr-btn-default' }}
                title={title}
                visible={visible}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText={
                    this.props.okButtonText ? this.props.okButtonText : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__SAVE)
                }
                cancelText={
                    this.props.cancelButtonText
                        ? this.props.cancelButtonText
                        : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__CANCEL)
                }
                closable={!!title}
                confirmLoading={loading}
            >
                <Spin spinning={loading}>
                    {error ? (
                        <Alert
                            message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                            description={error}
                            type="error"
                            showIcon
                            closable
                            style={{ marginTop: 32 }}
                        />
                    ) : null}
                    <DynamicForm
                        intl={this.props.intl}
                        addField={this.props.addField}
                        removeField={this.props.removeField}
                        validateInitialValues={this.props.validateAfterCreate}
                        wrappedComponentRef={this.getFormRef}
                        data={formFieldsData}
                        initialValues={initialValues}
                    />
                </Spin>
            </Modal>
        );
    }
}

export default injectIntl(ModalForm);

import React, {CSSProperties, FC, ReactNode} from 'react';
import './settingsPageWrapper.less';
import {Spin} from "../../../components";

interface SettingsPageWrapperProps {
    title: ReactNode;
    extra?: ReactNode;
    bodyStyle?: CSSProperties;
    loading?: boolean;
}

const className = 'rr-settings-page__content-wrapper';

export const SettingsPageWrapper:FC<SettingsPageWrapperProps> = ({title, bodyStyle, extra, children, loading}) => {
    return (
        <Spin spinning={loading === true}>
            <div className={`${className}`}>
                <div className={`${className}-header`}>
                    <div className={`${className}-header-title`}>{title}</div>
                    <div className={`${className}-header-extra`}>{extra}</div>
                </div>
                {children && (
                    <div className={`${className}-body`} style={bodyStyle}>
                        {children}
                    </div>
                )}
            </div>
        </Spin>
    );
};

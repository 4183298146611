import React, { FC, memo, useCallback, useMemo } from 'react';
import { Button, Card, Icon } from 'antd';
import classNames from 'classnames';
import { GridHeader } from '../GridHeader/GridHeader';
import { ProductCompositionColumnsObj } from '../../../columns/productCompositionColumns.types';
import { useIntl } from 'react-intl';
import { LocalizationEnum, useLocalize } from '../../../../../../../../../core/hooks/useLocalize';
import { ProductCompositionOnEditRowAction, useOnRowEditAction } from '../../../hooks/editGrid/useOnRowEditAction';
import { useSaveGrindHandler } from '../../../hooks/editGrid/useSaveGrindHandler';
import { useAddProducts } from '../../../hooks/editGrid/useAddProducts';
import { useOnDragEnd } from '../../../../../../../../../core/hooks/dnd/useOnDragEnd';
import { DndProps } from '../../../../../../../../../components/grid/grid/ReactTableBody';
import { PromptRedirect } from '../../../../../../../../../components/prompt/PromptRedirect/PromptRedirect';
import { AddKitPopover } from '../../../../../add-kit-popover';
import { RoundButton } from '../../../../../../../../../components';
import { IconPlus } from '../../../../../../../../../components/icons';
import { localize } from '../../../../../../../../../localization';
import { Grid } from '../../../../../../../../../components/grid/Grid';
import { productCompositionEditColumns } from '../../../columns/productCompositionEditColumns';
import { PRODUCT_COMPOSITION_GRID_NAME } from '../../../ProductComposition';
import { showConfirm } from '../../../../../../../../../components/confirm/showConfirm';
import { NomenclatureStateCodeEnum } from '../../../../../../../../../server';

interface ProductCompositionEditGridProps {
    editData: ProductCompositionColumnsObj[];
    setEditData: React.Dispatch<React.SetStateAction<ProductCompositionColumnsObj[]>>;
    setIsEdit: (value: boolean) => void;
    state: NomenclatureStateCodeEnum;
}

export const ProductCompositionEditGrid: FC<ProductCompositionEditGridProps> = memo((props) => {
    const L = useLocalize();
    const intl = useIntl();

    const { editData, setEditData, setIsEdit, state } = props;

    const onRowAction: ProductCompositionOnEditRowAction = useOnRowEditAction(editData, setEditData);

    const onSaveGrid = useSaveGrindHandler(editData, setIsEdit);

    const selectedIds = useMemo(() => editData?.map(({ id }) => id), [editData]);

    const addProducts = useAddProducts(editData, setEditData);

    const getUniqId = useCallback((rowData: ProductCompositionColumnsObj): string => {
        return String(rowData.id);
    }, []);

    const onDragEnd = useOnDragEnd({
        editData,
        setEditData,
    });

    const dndProps = useMemo(
        (): DndProps => ({
            onDragEnd,
        }),
        [onDragEnd]
    );

    const onRedirect = useCallback(() => {
        setIsEdit(false);
    }, [setIsEdit]);

    return (
        <>
            <PromptRedirect onRedirect={onRedirect} />
            <Card
                className={classNames('product-composition-grid-card')}
                title={
                    <GridHeader
                        Button={
                            <div>
                                <AddKitPopover getNewProduct={addProducts} stateCode={state} selectedIds={selectedIds}>
                                    <RoundButton colorScheme={'successSecondary'} style={{ marginRight: 16 }}>
                                        <Icon component={IconPlus} />
                                        <span>{localize(LocalizationEnum.PAGE__ACTIONS__ADD_COMPONENT)}</span>
                                    </RoundButton>
                                </AddKitPopover>
                            </div>
                        }
                    />
                }
            >
                <Grid
                    columns={productCompositionEditColumns}
                    data={editData}
                    pageSize={editData.length}
                    defaultSorted={'memberOrder'}
                    dnd
                    dndProps={dndProps}
                    entityType={PRODUCT_COMPOSITION_GRID_NAME}
                    filtered={editData.length}
                    getUniqId={getUniqId}
                    gridName={PRODUCT_COMPOSITION_GRID_NAME}
                    hidePageSizeSelect
                    indexFieldName={'id'}
                    onRowAction={onRowAction as any}
                    selectable={false}
                />
            </Card>
            <div className={classNames('grid-footer')}>
                <Card className="save-buttons-container">
                    <Button type={'primary'} onClick={onSaveGrid}>
                        <span>{localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}</span>
                    </Button>
                    <Button
                        className="rr-btn-default"
                        onClick={async () => {
                            const yes = await showConfirm(intl, L(LocalizationEnum.ASPECT__MODAL__ABORT_EDIT));
                            if (yes) setIsEdit(false);
                        }}
                    >
                        <span>{localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}</span>
                    </Button>
                </Card>
            </div>
        </>
    );
});

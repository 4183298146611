import React, { FC } from 'react';
import { RenterInfoRead } from '../../../../../server';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { HeaderNavigationBlock } from '../../../../../components/page/headerNavigationBlock/HeaderNavigationBlock';
import { Icon } from 'antd';
import { IconUsers } from '../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Status } from '../../../../../components/status/status';
import { Link } from 'react-router-dom';
import { rentersPageUrlRoute } from '../../data/tabs';
import { RentersPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { ArchiveBadge } from '../../../../../components/archiveBadge/archiveBadge';

interface CounterpartyPageNavigationBlockProps {
    renter: RenterInfoRead | undefined;
}

export const CounterpartyPageNavigationBlock: FC<CounterpartyPageNavigationBlockProps> = (props) => {
    const { renter } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (renter == null) return null;

    const { stateCode, archive, shortName, fullName } = renter;
    const name = fullName || shortName;

    return (
        <HeaderNavigationBlock>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        <Icon className={'icon'} component={IconUsers} />
                        <span
                            className={'title'}
                            style={{
                                maxWidth,
                            }}
                        >
                            {localize(name as LocalizationEnum)}
                        </span>
                        <Status entityType={'renter'} code={stateCode} />
                    </div>
                    <div className={'bottom-section'}>
                        <Link to={`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`} className={'link'}>
                            {localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES)}
                        </Link>
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

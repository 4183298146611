import React, { FC } from 'react';
import { Icon } from 'antd';
import { IconCalendarCheck } from '../../icons';
import { Link } from 'react-router-dom';
import { getPathFromState } from '../../../shared/util/utils';
import { calendarPageUrlRoute } from '../../../modules/main/calendar/utils/tabs';
import { useAppSelector } from '../../../store/hooks';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';

import { CalendarPageTabsEnum } from '../../../shared/constants/tabEnums';

interface CustomRangeCalendarAccessibilityCustomFooterProps {
    productId: number | undefined;
    kitId: number | undefined;
    variantId: number | undefined;
    screenLeft: number | undefined;
    screenRight: number | undefined;
    instanceId: number | undefined;
}

export const CustomRangeCalendarAccessibilityCustomFooter: FC<CustomRangeCalendarAccessibilityCustomFooterProps> = (props) => {
    const { productId, kitId, variantId, instanceId, screenLeft, screenRight } = props;

    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const linkToAccessibilityCalendar = getPathFromState(`/${businessAccountId}/${calendarPageUrlRoute}`, '', {
        tab: CalendarPageTabsEnum.ACCESSIBILITY_MAP,
        instanceId: instanceId || undefined,
        productId: productId || undefined,
        kitId: kitId || undefined,
        variantId: variantId || undefined,
        screenLeft,
        screenRight,
    });

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 12,
                paddingBottom: 15,
            }}
        >
            <Icon style={{ color: '#34bfa3', fontSize: 24 }} component={IconCalendarCheck} />
            <Link
                to={linkToAccessibilityCalendar}
                style={{ lineHeight: '12px', color: '#343f9a', textDecoration: 'underline', cursor: 'pointer' }}
            >
                Смотреть расписание доступности
            </Link>
        </div>
    );
};

import { CrewMembersState, DeleteCrewMembersArgs, TransitCrewMembersArgs } from './types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SetStatusForCrewMembersArgs } from '../crewMember/types';
import { serverApi } from '../../../../../../../server';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { crewMembersApi } from '../../api/crewMembers.api';

const ACTION_TYPES = {
    CHANGE_STATUS_CREW_MEMBERS: 'crewMembers/CHANGE_STATUS_CREW_MEMBERS',
    DELETE_CREW_MEMBERS: 'crewMembers/DELETE_CREW_MEMBERS',
    TRANSIT_CREW_MEMBERS: 'crewMembers/TRANSIT_CREW_MEMBERS',
};

const initialState: CrewMembersState = {
    loading: false,
    loadingError: undefined,
    entries: undefined,
    filteredCount: 0,
};

export const archiveCrewMembers = createAsyncThunk(
    ACTION_TYPES.TRANSIT_CREW_MEMBERS,
    async (args: TransitCrewMembersArgs, { dispatch }) => {
        const { businessAccountId, workflowDirectiveBulk } = args;

        try {
            await serverApi.transitCrewMemberWorkflowBulk(businessAccountId, workflowDirectiveBulk);
            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1 ? 'Проектные работники успешно изменены' : 'Проектный работник успешно изменён';
            showNotification('success', notificationMessage);
            dispatch(crewMembersApi.util?.invalidateTags(['CrewMembersList', 'CrewMember']));
        } catch (error: unknown) {
            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1
                    ? 'Не удалось изменить проектных работников'
                    : 'Не удалось изменить проектного работника';
            showNotification('error', notificationMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

export const deleteCrewMembers = createAsyncThunk(
    ACTION_TYPES.DELETE_CREW_MEMBERS,
    async (args: DeleteCrewMembersArgs, { dispatch, rejectWithValue }) => {
        const { businessAccountId, versionedEntityObjList } = args;

        try {
            await serverApi.deleteCrewMembersBulk(businessAccountId, versionedEntityObjList);
            const notificationMessage =
                versionedEntityObjList.entities.length > 1 ? 'Проектные работники успешно удалены' : 'Проектный работник успешно удалён';
            showNotification('success', notificationMessage);
            dispatch(crewMembersApi.util?.invalidateTags(['CrewMembersList', 'CrewMember']));
        } catch (error: unknown) {
            const notificationMessage =
                versionedEntityObjList.entities.length > 1
                    ? 'Не удалось удалить проектных работников'
                    : 'Не удалось удалить проектного работника';
            showNotification('error', notificationMessage);
            throw rejectWithValue(error);
        }
    }
);

export const setStatusForCrewMembers = createAsyncThunk(
    ACTION_TYPES.CHANGE_STATUS_CREW_MEMBERS,
    async (args: SetStatusForCrewMembersArgs, { dispatch }) => {
        const { businessAccountId, workflowDirectiveBulk } = args;

        try {
            await serverApi.transitCrewMemberWorkflowBulk(businessAccountId, workflowDirectiveBulk);

            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1
                    ? 'Статус проектных работников успешно обновлен'
                    : 'Статус проектного работника успешно обновлен';
            showNotification('success', notificationMessage);
            dispatch(crewMembersApi.util?.invalidateTags(['CrewMembersList', 'CrewMember']));
        } catch (error) {
            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1
                    ? 'Не удалось обновить статус проектных работников'
                    : 'Не удалось обновить статус проектного работника';
            showNotification('error', notificationMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

export const crewMembersSlice = createSlice({
    name: 'crewMembers',
    initialState,
    reducers: {},
});

export default crewMembersSlice.reducer;

import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { getCustomFieldTypeByCode, LocalizationEnum, localize } from '../../../../localization';
import { CustomFieldDataTypeCodeEnum, CustomFieldProcessingTypeCodeEnum } from '../../../../server/api';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../shared/util/validateField';
import { getStoreState } from '../../../../../index';
import React from 'react';
import { Icon, Tooltip } from 'antd';
import { IconUnlock } from '../../../../components/icons';
import { Styles } from '../../../../styles';
import { createHiddenField } from '../../../../components/modalForm/utils';
import { CustomFieldsUtils } from '../../../../core/utils/customFieldsUtils';

export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('customFieldEdit'),
            createHiddenField('entitiesLinkedNumber'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('customFieldEdit') ? form.getFieldValue('id') : undefined;

                    validateField(
                        'customField',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        'Краткое наименование поля уже использовано',
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE,
                id: 'typeCode',
                required: true,
                type: FormItemType.Select,
                placeholder: localize(LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE__PLACEHOLDER),
                values: (value: any, form: WrappedFormUtils) => {
                    return getStoreState().customField.fieldsLimit?.limits?.map((item) => {
                        if (item.currentlyUsed < item.maxAllowed) {
                            return {
                                name: (
                                    <Tooltip
                                        title={localize(
                                            LocalizationEnum.PAGE__SETTINGS__FIELDS__TOOLTIP__FIELD_TYPE_COMPANY_RESTRICTION,
                                            'span',
                                            { from: item.currentlyUsed, to: item.maxAllowed },
                                            true
                                        )}
                                    >
                                        <div>{getCustomFieldTypeByCode(CustomFieldDataTypeCodeEnum[item.typeCode])}</div>
                                    </Tooltip>
                                ),
                                value: CustomFieldDataTypeCodeEnum[item.typeCode],
                            };
                        } else {
                            return {
                                name: (
                                    <Tooltip
                                        title={localize(
                                            LocalizationEnum.PAGE__SETTINGS__FIELDS__TOOLTIP__FIELD_TYPE_COMPANY_RESTRICTION,
                                            'span',
                                            { from: item.currentlyUsed, to: item.maxAllowed },
                                            true
                                        )}
                                    >
                                        <div style={{ cursor: 'not-allowed', color: '#ccc' }} onClick={(e) => e.stopPropagation()}>
                                            <Icon
                                                component={IconUnlock}
                                                style={{ color: '#ccc', marginBottom: '5px' }}
                                                className={`rr-customField-select-list-item-icon ${Styles.ICON_20}`}
                                            />
                                            {getCustomFieldTypeByCode(CustomFieldDataTypeCodeEnum[item.typeCode])}
                                        </div>
                                    </Tooltip>
                                ),
                                value: undefined,
                            };
                        }
                    });
                },
                disabled: (getFieldValue) => getFieldValue('id') !== undefined,
                onChange: async (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({
                        processingType: undefined,
                    });
                    if (value === CustomFieldDataTypeCodeEnum.BOOLEAN) form.setFieldsValue({ multipleValues: false });
                },
            },
            {
                label: 'Формат',
                id: 'processingType',
                type: FormItemType.Select,
                placeholder: 'Выберите формат',
                required: true,
                values: (form: WrappedFormUtils) => {
                    const typeCode = form.getFieldValue('typeCode');

                    if (typeCode === CustomFieldDataTypeCodeEnum.LONG) {
                        return [
                            {
                                value: CustomFieldProcessingTypeCodeEnum.LONGSUM,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.LONGSUM],
                            },
                            {
                                value: CustomFieldProcessingTypeCodeEnum.MONEYSUM,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.MONEYSUM],
                            },
                        ];
                    }

                    if (typeCode === CustomFieldDataTypeCodeEnum.ENTITY) {
                        return [
                            {
                                value: CustomFieldProcessingTypeCodeEnum.USERLINK,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.USERLINK],
                            },
                            {
                                value: CustomFieldProcessingTypeCodeEnum.CONTACTLINK,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.CONTACTLINK],
                            },
                            {
                                value: CustomFieldProcessingTypeCodeEnum.RENTERLINK,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.RENTERLINK],
                            },
                            {
                                value: CustomFieldProcessingTypeCodeEnum.PROJECTLINK,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.PROJECTLINK],
                            },
                            {
                                value: CustomFieldProcessingTypeCodeEnum.SUBRENTLINK,
                                name: CustomFieldsUtils.processingTypeValueMap[CustomFieldProcessingTypeCodeEnum.SUBRENTLINK],
                            },
                        ];
                    }
                },
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    const typeCode = form.getFieldValue('typeCode');

                    cb(
                        value == null && (typeCode === CustomFieldDataTypeCodeEnum.ENTITY || typeCode === CustomFieldDataTypeCodeEnum.LONG)
                            ? 'Необходимо указать формат'
                            : undefined
                    );
                },
                validateInitialValue: true,
                visible: (getFieldValue) => {
                    return (
                        getFieldValue('typeCode') === CustomFieldDataTypeCodeEnum.LONG ||
                        getFieldValue('typeCode') === CustomFieldDataTypeCodeEnum.ENTITY
                    );
                },
                disabled: (getFieldValue) => {
                    //const typeCode = getFieldValue('typeCode');
                    const entitiesLinkedNumber = getFieldValue('entitiesLinkedNumber');
                    return /*typeCode === CustomFieldDataTypeCodeEnum.ENTITY &&*/ Boolean(entitiesLinkedNumber);
                },
            },
            {
                label: LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__MULTIPLE_VALUES,
                id: 'multipleValues',
                type: FormItemType.Switch,
                disabled: (getFieldValue) => {
                    if (getFieldValue('id') !== undefined || getFieldValue('typeCode') === CustomFieldDataTypeCodeEnum.BOOLEAN) return true;

                    return getFieldValue('entitiesLinkedNumber');
                },
            },
            {
                label: 'Финансовое',
                id: 'financial',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

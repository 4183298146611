import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../../components/modalForm/utils';
import { connect, ConnectedProps } from 'react-redux';
import { updateModalFilters } from '../../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { showConfirm } from '../../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../../localization';
import { ProfessionModalFields } from '../../data/ProfessionModalFields';
import { ProfessionFormData } from '../../types/ProfessionFormData';
import { createProfession } from '../../../reducers/profession/profession.reducer';
import { ProfessionInfoCreate } from '../../../../../../../../server';
import _ from 'lodash';

interface ProfessionCreateModalProps extends ModalFormNEWProps, WrappedComponentProps {}

export class ProfessionCreateModalComponent extends ModalFormNEW<ProfessionCreateModalProps & StoreProps> {
    static defaultProps = {
        formFields: ProfessionModalFields,
    };

    getInitialValues = () => {
        const { shiftLengthInMinutes, initialValues } = this.props;
        return {
            ...initialValues,
            shiftLengthInMinutes: shiftLengthInMinutes,
            _shiftLengthInMinutes: shiftLengthInMinutes,
        };
    };

    onOk = async (data: unknown) => {
        const formData: ProfessionFormData = _.cloneDeep(data as ProfessionFormData);
        const imageResponse = formData?.mainImage?.[0]?.response;
        const mainImage = imageResponse
            ? {
                  id: imageResponse.id,
              }
            : undefined;

        formData.hasOwnShiftLength = !formData.hasOwnShiftLength;

        const pricingSchemeId =
            formData.pricingScheme?.['id'] ?? formData.pricingScheme?.['key']
                ? Number(formData.pricingScheme?.['id'] ?? formData.pricingScheme?.['key'])
                : undefined;

        const professionCreateData: ProfessionInfoCreate = {
            ...formData,
            stateCode: formData.stateCode,
            pricingSchemeId,
            mainImage,
        };

        if (professionCreateData.pricePerShift == null) professionCreateData.pricePerShift = 0;

        delete professionCreateData['_shiftLengthInMinutes'];
        delete professionCreateData['pricingScheme'];
        delete professionCreateData['fromTemplate'];

        try {
            const profession = await this.props.createProfession({
                businessAccountId: this.props.businessAccountId,
                professionInfoCreate: professionCreateData,
            });
            if (this.props.onSuccess) this.props.onSuccess(profession);
        } catch {}
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)));
        if (yes) {
            this.props.onCancel?.();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.profession.updating,
    updatingError: storeState.profession.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
    shiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
});

const mapDispatchToProps = {
    updateModalFilters,
    createProfession,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const ProfessionCreateModal = connector(injectIntl(ProfessionCreateModalComponent));

import {ColumnTypes, TableColumn} from '../../../../../components/grid/Table';
import {Link} from 'react-router-dom';
import React from 'react';
import {InstancePropertiesEntityRecordCustom, OperationElement, RentElementsGridItemCustom} from '../../reducers/operationForm.reducer';
import {EntityType, getBusinessAccountId, getStoreState} from '../../../../../../index';
import {ElementsElementPopover} from '../../../elements/elements-element-popover';
import {InstanceTrackingTypeCodeEnum} from '../../../../../server/api';
import {LocalizationEnum, localize} from '../../../../../localization';
import {GRID_PROBLEM_COLUMN_WIDTH, MAX_WIDTH} from '../../../../../config/constants';
import {joinClasses} from "../../../../../shared/util/utils";
import {ProductVariantPopover} from "../../../inventory/variants/components/productVariantPopover/productVariantPopover";
import {VariantSelectPopover} from "../components/variantSelectPopover/variantSelectPopover";
import {
    addElementToElementsFromScan,
    addElementToElementsFromScan1,
    canAddNewProductsToOperation,
    canChangeVariantOnElement,
    canCreateOperationWithAnonymousInstances
} from "../../utils/utils";
import {Icon} from "antd";
import {IconBell, IconCheck} from "../../../../../components/icons";
import {CurrentIndicatorsProblemIcons} from "../../../../../components/currentRentIndicators/currentIndicatorsProblemIcons";
import {EquipmentSuccessIcon} from "./EquipmentSuccessIcon";
import {tableCellStatusRenderer} from "../../../../../components/grid/renderers/tableCellStatusRenderer";
import {GridInstanceCountBlock} from "../../components/gridInstanceCountBlock/GridInstanceCountBlock";
import {OperationFormUtils} from "../../utils/operationFormUtils";
import {GridColumnCreator} from "../../../../../components/grid/gridColumnCreator";
import {OperationFormEquipmentListActionsPopover1} from "./operationFormEquipmentListActionsPopover1";

// GRID COLUMNS
export const columns: TableColumn<any>[] = [
    {
        title: <Icon style={{fontSize: 21, paddingTop: 3}} component={IconBell}/>,
        dataIndex: 'problemSeverity',
        type: ColumnTypes.CustomRender,
        minWidth: GRID_PROBLEM_COLUMN_WIDTH,
        width: GRID_PROBLEM_COLUMN_WIDTH,
        maxWidth: GRID_PROBLEM_COLUMN_WIDTH,
        resizable: false,
        sortable: true,
        dontHide: true,
        render: (value: Date, rowData: RentElementsGridItemCustom) => {
            return <CurrentIndicatorsProblemIcons problemsAndWarnings={rowData?.problemsAndWarnings} entityType={'elements'} typeRent={'all'} id={rowData.id} entity={rowData}/>
        }
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NUMBER),
        dataIndex: 'id',
        type: ColumnTypes.CustomRender,
        minWidth: 90,
        maxWidth: MAX_WIDTH(90),
        resizable: false,
        render: (value: any, rowData: RentElementsGridItemCustom) => {
            return rowData && rowData.id > -1 ? (
                <ElementsElementPopover record={rowData} hideActionsButton={true}>
                    <span className={'rr-grid-id'}
                          style={{
                              fontWeight: 700,
                              cursor: rowData.kitId ? undefined : 'pointer'
                          }}>#{rowData.numberInActivityFrame}</span>
                </ElementsElementPopover>
            ) : (
                <span className={'rr-dot'} style={{backgroundColor: '#34bfa3'}}></span>
            );
        }
    },
    GridColumnCreator.createImageColumn(),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),//localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRODUCT),
        dataIndex: 'productShortName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: true,
        render: (value: any, rowData: RentElementsGridItemCustom) => rowData ? (
            <div className={'rr-grid-shortName-cell'} style={{paddingLeft: 10 + (rowData['parentId'] ? 22 : 0)}}
                 title={rowData.productShortName}>
                <Link
                    className={rowData.id > -1 ? undefined : 'new-value'}
                    to={`/${getBusinessAccountId()}/inventory/${rowData.kitId ? 'kits' : 'products'}/${rowData.kitId ? rowData.kitId : rowData.productId}`}>
                    {rowData.mainKitMember ? '★ ' : null}{rowData.productShortName}{rowData.kitId && rowData.subRows && rowData.rentElementsKitMemberCount && rowData.rentElementsKitMemberCount > rowData.subRows.length ? <span style={{fontWeight: 400}}> ({rowData.subRows.length} из {rowData.rentElementsKitMemberCount})</span> : null}
                </Link>
            </div>
        ) : null,
        dontHide: true
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT),
        dataIndex: 'variantName',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: true,
        render: (value: any, rowData: OperationElement) => {

            const operationTypeCode = getStoreState().operationForm.typeCode;
            const canChangeVariant = (rowData.variantId && canChangeVariantOnElement(operationTypeCode));

            return rowData && rowData.productId && rowData.variantId ? (
                <div className={joinClasses(rowData.id > -1 ? undefined : 'new-value')} style={{textAlign: 'left'}}>
                    <span className={'rr-operationForm-elementsList-variant'}>
                        {
                            rowData.variantId ? (
                                <ProductVariantPopover
                                    productId={rowData.productId}
                                    variantId={rowData.variantId}
                                >
                                    <span className={'app-link-underline'}>{rowData.variantName}</span>
                                </ProductVariantPopover>
                            ) : null
                        }
                        {canChangeVariant && <VariantSelectPopover element={rowData}></VariantSelectPopover>}
                    </span>
                </div>
            ) : null
        }
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
        dataIndex: 'instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 90,
        resizable: false,
        sortable: true,
        className: 'rr-grid-table-cell-fix',
        render: (value: any, rowData: OperationElement) => {
            let anonymousInstanceCount = 0;
            let hasError = rowData.problems && rowData.problems.includes('instanceCount');
            let hasWarning = !hasError && rowData.warnings && rowData.warnings.includes('instanceCount');
            let instanceCount = 0;

            if (!rowData['subRows']) {
                if (rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) anonymousInstanceCount = rowData.anonymousInstanceCount !== undefined ? rowData.anonymousInstanceCount : 0;
                instanceCount = rowData.instanceCount;
            } else {
                (rowData['subRows'] as OperationElement[]).forEach((element) => {
                    if (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) anonymousInstanceCount += (element.anonymousInstanceCount !== undefined ? element.anonymousInstanceCount : 0);
                    instanceCount = 1;
                });
            }

            return rowData ? (
                <div
                    className={'rr-grid-table-cell-fix-container' + (hasError ? ' rr-grid-table-error-cell' : (hasWarning ? ' rr-grid-table-warning-cell' : ''))}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className={rowData.id > -1 ? 'main-value' : 'new-value'} style={{fontWeight: 600}}>
                            {instanceCount}
                        </div>
                    </div>
                </div>
            ) : null;
        }
    },
    {
        title: 'Кол-во чужих',
        dataIndex: 'subrentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        defaultHidden: true,
        render: (value: any, rowData: RentElementsGridItemCustom, onAction, entityType: EntityType) => {
            if(value) return value;
            else return '';
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
        dataIndex: 'externalCode',
        type: ColumnTypes.CustomRender,
        minWidth: 110,
        resizable: true,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) =>
            rowData.externalCode ? (
                <div className={'rr-grid-text-cell-center'}>
                    <Link to={`/${rowData.businessAccountId}/inventory/products/${rowData.productId}`}
                          style={{color: 'unset'}}>
                        {rowData.externalCode}
                    </Link>
                </div>
            ) : null
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
        dataIndex: 'stateCode',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: any, rowData: RentElementsGridItemCustom, onAction, entityType: EntityType) => {
            return (
                value ? tableCellStatusRenderer(value, rowData, entityType) : <span style={{color: '#575962', opacity: .4}}>{localize(LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER)}</span>
            );
        }
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
        dataIndex: 'rentTerms.rentPeriodStartDate',
        type: ColumnTypes.DateTime,
        minWidth: 130,
        resizable: false
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__END_DATE),
        dataIndex: 'rentTerms.rentPeriodEndDate',
        type: ColumnTypes.DateTime,
        minWidth: 130,
        resizable: false
    },
    {
        title: 'Добавлено',
        dataIndex: 'added',
        type: ColumnTypes.CustomRender,
        width: 160,
        minWidth: 160,
        resizable: false,
        sortable: true,
        render: (value: any, rowData: RentElementsGridItemCustom) => {
            const state = getStoreState();
            let total:number|undefined = rowData.instanceCount;
            const elements = state.operationForm.elements.entities;
            let instanceCount = rowData.elementInstanceCount || 0;
            const typeCode = state.operationForm.typeCode;

            let plusButtonDisabled = false;
            let minusButtonDisabled = false;
            //let isCancelled:boolean|undefined = false;
            let anonymousInstanceCount:number|undefined;

            let availableAnonymousInstanceCount = rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? rowData.anonymousInstanceCount : undefined;

            if(elements){
                let element = elements.find((el)=>el.id === rowData.id);
                if(element){
                    //instanceCount = element.instanceCount;
                    anonymousInstanceCount = rowData.elementAnonymousInstancesCount;//element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? element.anonymousInstanceCount : undefined;
                    if(!canAddNewProductsToOperation(typeCode) && ((element.leftoverInstanceCount === 0) || (element.leftoverInstanceCount !== 0 && element.instanceCountOriginal === element.instanceCount))){
                        plusButtonDisabled = true;
                    }
                    //isCancelled = element.isCancelled;
                }
            }

            if(rowData.subRows){
                let _availableAnonymousInstanceCount = 0;
                //let _anonymousInstanceCount = 0;
                rowData.subRows.forEach((chEl=>{
                    _availableAnonymousInstanceCount += (chEl.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || chEl.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? chEl.anonymousInstanceCount : 0);
                    //let element = elements.find((el)=>el.id === chEl.id);
                    //if(element){
                    //    _anonymousInstanceCount += (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? element.anonymousInstanceCount : 0);
                    //}
                }));
                availableAnonymousInstanceCount = _availableAnonymousInstanceCount;
                anonymousInstanceCount = rowData.elementAnonymousInstancesCount;
            }

            minusButtonDisabled = instanceCount <= 0;

            const addInstance = () => {
                const elements = state.operationForm.elements.entities;
                let addedEl = elements.find((el)=>el.id === rowData.id);
                if(addedEl){
                    // Уже добавлен, надо просто приплюсовать. Или приплюсовать или просто добавить новый экземпляр
                    addElementToElementsFromScan1(rowData);
                }else{
                    // Элемент не добавлен, нужно его добавить и загрузить карты
                    addElementToElementsFromScan(rowData, undefined);
                }
            };

            const removeInstance = () => {
                OperationFormUtils.removeItemFromEquipment(rowData);
            };

            if(rowData.subRows){
                let withoutLeftover = 0;
                rowData.subRows.forEach((e)=>{
                    let element = elements.find((el)=>el.id === e.id);
                    if(element && element.leftoverInstanceCount <= 0) withoutLeftover++;
                });
                const kitAdded = withoutLeftover > 0 && withoutLeftover === rowData.subRows.length;
                if(kitAdded) plusButtonDisabled = true;
            }

            if(rowData.isCancelled){
                return (
                    <span style={{textDecoration: 'line-through', color: '#abacb0'}}>&nbsp;{rowData.instanceCount}&nbsp;</span>
                );
            }

            let withNewInstances = false;
            if(instanceCount > total) withNewInstances = true;

            return rowData ? (
                <GridInstanceCountBlock
                    withNewInstances={withNewInstances}
                    instanceCount={instanceCount}
                    availableInstanceCount={total}
                    addInstance={!plusButtonDisabled ? addInstance : undefined}
                    removeInstance={!minusButtonDisabled ? removeInstance : undefined}
                    anonymousInstanceCount={anonymousInstanceCount}
                    availableAnonymousInstanceCount={availableAnonymousInstanceCount}
                />
            ) : null;
        }
    },
    {
        title: 'Все экземпляры',
        dataIndex: 'allAdded',
        type: ColumnTypes.CustomRender,
        width: 130,
        //maxWidth: 200,
        resizable: false,
        sortable: true,
        className: 'rr-grid-table-cell-fix',
        render: (value: any, rowData: RentElementsGridItemCustom) => {
            let state = getStoreState().operationForm;
            let _canCreateOperationWithAnonymousInstances = canCreateOperationWithAnonymousInstances(state.targetStateCode || state.typeCode);
            let addedElement = state.elements.entities.find(el=>el.id === rowData.id);
            let anonymousInstanceCount = addedElement && (addedElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED || addedElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED) && addedElement.anonymousInstanceCount ? addedElement.anonymousInstanceCount : 0;
            let isCancelled = addedElement?.isCancelled;

            if(rowData.subRows){
                anonymousInstanceCount = 0;
                rowData.subRows.forEach((chEl)=>{
                    let addedElement = state.elements.entities.find(el=>el.id === chEl.id);
                    if(addedElement && addedElement.isCancelled) return;
                    let _anonymousInstancesCount = addedElement && (addedElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED || addedElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED) && addedElement.anonymousInstanceCount ? addedElement.anonymousInstanceCount : 0;
                    if(_anonymousInstancesCount > 0) anonymousInstanceCount += _anonymousInstancesCount;
                })
            }

            // Красным подсвечиваем, если операция не допускает анонимных и среди добавленных есть анонимные

            return rowData ? (
                !isCancelled && !_canCreateOperationWithAnonymousInstances &&/* instanceTracked && addedElement && addedElement.*/anonymousInstanceCount > 0 ? (
                    <div className={'rr-grid-table-cell-fix-container rr-grid-table-error-cell'}>
                        <div>{anonymousInstanceCount}?</div>
                    </div>
                ) : (
                    <div className={'rr-grid-table-cell-fix-container'}>
                        {
                            isCancelled ? <Icon style={{color: '#abacb0', fontSize: 28}} component={IconCheck} /> : <EquipmentSuccessIcon element={rowData} />
                        }
                    </div>
                )
            ) : null;
        }
    },
    {
        title: 'Действия',
        type: ColumnTypes.CustomRender,
        width: 120,
        maxWidth: 200,
        resizable: false,
        sortable: true,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: RentElementsGridItemCustom) => {
            return !rowData.kitId ? (
                <div className={'rr-grid-table-actions-group'}>
                    <div>
                        <OperationFormEquipmentListActionsPopover1 rowData={rowData} />
                    </div>
                </div>
            ) : null;
            // let state = getStoreState().operationForm;
            // let element = state.elements.entities.find(el => el.id === rowData.id);
            // let cancelled = rowData.isCancelled;//element && element.isCancelled ? element.isCancelled : false;
            //
            // return !rowData.kitId && (
            //     <div className={'rr-grid-table-actions-group'}>
            //         <div>
            //             <Popover arrowPointAtCenter={true} overlayClassName={'rr-actions-popover'}
            //                      placement="bottomRight" trigger={'click'} overlayStyle={{width: 300}} content={
            //                 <>
            //                     <div className={'rr-grid-actions-popover-header1'}></div>
            //                     <div className={'rr-grid-actions-popover-content'}>
            //                         {
            //                             !cancelled && <Button
            //                                 disabled={element && element.instanceCount === element.instanceCountOriginal}
            //                                 className={'ant-btn-page-actions'} onClick={async () => {
            //                                 addAll(rowData);
            //                             }}>
            //                                 <Icon
            //                                     style={{cursor: 'pointer'}}
            //                                     component={IconAngleDoubleRight}
            //                                 />
            //                                 {'Добавить все'}
            //                             </Button>
            //                         }
            //
            //                         {
            //                             <Button className={'ant-btn-page-actions'} onClick={async ()=> {
            //                                 cancelAll(rowData);
            //                             }}>
            //                                 <Icon
            //                                     className={joinClasses('rr-OperationElementCancelToggleButton', cancelled ? 'rr-OperationElementCancelToggleButton-cancelled' : '')}
            //                                     style={{cursor: 'pointer', top: 0}}
            //                                     component={cancelled ? IconReply : IconEraser}
            //                                 />
            //                                 {!cancelled ? 'Отменить' : 'Вернуть в операцию'}
            //                             </Button>
            //                         }
            //
            //                         {
            //                             !cancelled ? (
            //                                 element ? (
            //                                     <OperationElementCreatePopover
            //                                         elementRecord={element}
            //                                         disabled={!!rowData.kitId}
            //                                         mode={'edit'}
            //                                         instanceCount={element.instanceCount}
            //                                         availableInstanceCount={element.availableInstanceCount}
            //                                     >{
            //                                         (() => (
            //                                             <Button className={'ant-btn-page-actions'}>
            //                                                 <Icon
            //                                                     className={'rr-grid-table-edit-icon'}
            //                                                     style={{cursor: 'pointer', top: 0}}
            //                                                     component={IconEdit}
            //                                                 />
            //                                                 {'Редактировать'}
            //                                             </Button>
            //                                         ))
            //                                     }
            //                                     </OperationElementCreatePopover>
            //                                 ) : (
            //                                     <OperationElementEditPopover
            //                                         elementRecord={rowData}
            //                                         disabled={!!rowData.kitId}
            //                                         mode={'create'}
            //                                         instanceCount={rowData.instanceCount}
            //                                         availableInstanceCount={rowData.instanceCount}
            //                                     >{
            //                                         (() => (
            //                                             <Button className={'ant-btn-page-actions'}>
            //                                                 <Icon
            //                                                     className={'rr-grid-table-edit-icon'}
            //                                                     style={{cursor: 'pointer', top: 0}}
            //                                                     component={IconEdit}
            //                                                 />
            //                                                 {'Редактирование'}
            //                                             </Button>
            //                                         ))
            //                                     }
            //                                     </OperationElementEditPopover>
            //                                 )
            //                             ) : null
            //                         }
            //
            //                         <Button disabled={!element} className={'ant-btn-page-actions'} onClick={()=>{
            //                             if(element) getStore().dispatch(removeInstances(rowData.id, element.instanceCount));
            //                         }}>
            //                             <Icon
            //                                 className={'rr-grid-table-remove-icon'}
            //                                 style={{cursor: 'pointer', top: 0}}
            //                                 component={IconClose}
            //                             />
            //                             {'Убрать из операции'}
            //                         </Button>
            //
            //                     </div>
            //                 </>
            //             }>
            //                 <span>
            //                     <Icon className={'rr-grid-table-edit-icon'}
            //                           style={{cursor: 'pointer', fontSize: 30}} component={IconEllipsisHSolid}/>
            //                 </span>
            //             </Popover>
            //         </div>
            //     </div>
            // );
        }
    }
];

//
// const addAll = async (rowData: RentElementsGridItemCustom) => {
//     let state = getStoreState().operationForm;
//     let element = state.elements.entities.find(el => el.id === rowData.id);
//
//     let nomenclatures:NomenclatureOnInterval[] = [rentElementToNomenclatureOnInterval(rowData)];
//     if(rowData.parent) nomenclatures.push(rentElementToNomenclatureOnInterval(rowData.parent));
//     const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
//     getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
//
//     let n = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[0].id && tt.type === nomenclatures[0].type);
//
//     // let n = await OperationFormUtils.getNomenclaturesById([{
//     //     productId: rowData.productId,
//     //     variantId: rowData.variantId
//     // }], true);
//     if (n?.nomenclature) {
//         // Нужно добавить к элементам
//         let el = OperationFormUtils.createOperationElementFromElement(rowData, n.nomenclature as NomenclatureRecord);
//         if (!element) {
//             if (rowData.parent && state.elements.entities.find(el => rowData.parent && el.id === rowData.parent.id)) {
//                 el.parentId = rowData.parent.id;
//                 getStore().dispatch(addElement(el));
//             }else{
//                 let kitN:TimeTable|undefined;
//                 if(rowData.parent){
//                     // kitN = await OperationFormUtils.getNomenclaturesById([{
//                     //     kitId: rowData.parent.kitId
//                     // }], true);
//                     kitN = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[1].id && tt.type === nomenclatures[1].type);
//                 }
//                 if(kitN?.nomenclature && rowData.parent){
//                     let kitEl = OperationFormUtils.createOperationElementFromElement(rowData.parent, kitN.nomenclature as NomenclatureRecord);
//                     el.parentId = rowData.parent.id;
//                     getStore().dispatch(addElement([kitEl, el]));
//                 }else {
//                     getStore().dispatch(addElement(el));
//                 }
//             }
//         } else {
//             if(rowData.parent && state.elements.entities.find(el=>rowData.parent && el.id === rowData.parent.id)){
//                 el.parentId = rowData.parent.id;
//             }
//             getStore().dispatch(updateElement(el));
//         }
//     }
// };

// const cancelAll = async (rowData: RentElementsGridItemCustom) => {
//     let cancelled = rowData.isCancelled;
//     let state = getStoreState().operationForm;
//     let element = state.elements.entities.find(el => el.id === rowData.id);
//
//     let nomenclatures:NomenclatureOnInterval[] = [rentElementToNomenclatureOnInterval(rowData)];
//     if(rowData.parent) nomenclatures.push(rentElementToNomenclatureOnInterval(rowData.parent));
//     const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
//     getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
//
//     if (!element) {
//         let n = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[0].id && tt.type === nomenclatures[0].type);
//
//         // let n = await OperationFormUtils.getNomenclaturesById([{
//         //     productId: rowData.productId,
//         //     variantId: rowData.variantId
//         // }], true);
//         if (n?.nomenclature) {
//             // Нужно добавить к элементам
//             let el = OperationFormUtils.createOperationElementFromElement(rowData, n.nomenclature as NomenclatureRecord);
//             if (rowData.parent && state.elements.entities.find(el => rowData.parent && el.id === rowData.parent.id)) {
//                 el.parentId = rowData.parent.id;
//                 await getStore().dispatch(addElement(el));
//             }else{
//                 let kitN:TimeTable|undefined;
//                 if (!element && rowData.parent) {
//                     // kitN = await OperationFormUtils.getNomenclaturesById([{
//                     //     kitId: rowData.parent.kitId
//                     // }], true);
//                     kitN = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[1].id && tt.type === nomenclatures[1].type);
//                 }
//                 if (kitN?.nomenclature && rowData.parent) {
//                     let kitEl = OperationFormUtils.createOperationElementFromElement(rowData.parent, kitN?.nomenclature as NomenclatureRecord);
//                     el.parentId = rowData.parent.id;
//                     await getStore().dispatch(addElement([kitEl, el]));
//                 } else {
//                     await getStore().dispatch(addElement(el));
//                 }
//             }
//             getStore().dispatch(cancelOperationElement(rowData.id, true));
//         }
//     } else {
//         getStore().dispatch(cancelOperationElement(rowData.id, !cancelled));
//     }
// };


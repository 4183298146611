import React, {FC, ReactNode, useState} from 'react';
import {Popover} from "../v2/popover/popover";
import {AggregationsPopoverContent} from "./aggregationsPopoverContent";
import './aggregationsPopover.less';

export interface AggregationsPopoverDataItem {
    label: ReactNode;
    value: ReactNode;
    summary?: boolean;
}

interface AggregationsPopoverProps {
    data?: AggregationsPopoverDataItem[];
    fetchData?: ()=>void;
    isFetching?: boolean;
    isError?: boolean;
}

export const AggregationsPopover:FC<AggregationsPopoverProps> = ({data, fetchData, isFetching, isError}) => {
    const [visible, setVisible] = useState(false);

    return <Popover
        visible={(Boolean(data) || isError) && visible && !isFetching}
        onVisibleChange={(visible) => {
            setVisible(visible);
            if(visible) fetchData?.();
        }}
        placement={'bottomRight'}
        overlayClassName={'rr-aggregations-popover'}
        content={<AggregationsPopoverContent data={data} isError={isError} />}
    >
        <div className={'rr-grid-export-button'}>
            <div style={{color: '#8B5CB1', fontSize: 24, lineHeight: '24px'}}>Σ</div>
            <span>Сумма</span>
        </div>
    </Popover>;
};

import {Button, Popover} from "antd";
import React, {ReactNode} from "react";
import {ErrorItem, OperationProblemModalType} from "../../../modules/main/operationForm/operationModule/OperationProblemModal";
import {WarningIcon} from "../../../modules/main/operationForm/operationModule/components/icons/WarningIcon";
import {ProblemIcon} from "../../../modules/main/operationForm/operationModule/components/icons/ProblemIcon";
import {OperationResetButton} from "../../buttons/operationResetButton/operationResetButton";
import {showConfirm} from "../../confirm/showConfirm";
import {LocalizationEnum} from "../../../localization";
import {useLocalize} from "../../../core/hooks/useLocalize";
import {useIntl} from "react-intl";

interface OperationEditSaveBlockProps {
    createOperation?: ()=> void;
    problemType?: OperationProblemModalType;
    onProblemIconClick: ()=>void;
    problems:ErrorItem[];
    operationWarningMessage: ReactNode;
    onCancel?: ()=>void;
    customSaveButton?: ReactNode;
    customCancelButton?: ReactNode;
}

export const OperationEditSaveBlock = ({operationWarningMessage, problemType, createOperation, problems, onProblemIconClick, onCancel, customSaveButton, customCancelButton}: OperationEditSaveBlockProps) => {

    const L = useLocalize();
    const intl = useIntl();
    const buttonDisabled = Boolean(operationWarningMessage) || problemType === OperationProblemModalType.ERROR;

    const saveButton = customSaveButton || <Button disabled={buttonDisabled} type={'primary'} style={{borderWidth: 1}} onClick={()=>{
        if(!buttonDisabled) createOperation?.();
    }}>Сохранить</Button>;

    return (
        <div style={{display: 'inline-flex', alignItems: 'center', marginLeft: 38}}>

            {/* Предупреждение */}
            {problemType === OperationProblemModalType.WARNING && <WarningIcon style={{marginRight: 10}} onClick={() => onProblemIconClick()} />}

            {/* Ошибка */}
            {problemType === OperationProblemModalType.ERROR && problems.length > 0 && <ProblemIcon style={{marginRight: 2}} onClick={() => onProblemIconClick()} />}

            {operationWarningMessage ? (
                <Popover
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                    autoAdjustOverflow={true}
                    content={operationWarningMessage}
                    overlayStyle={{ maxWidth: 400 }}
                >
                    {saveButton}
                </Popover>
            ) : (
                saveButton
            )}
            {customCancelButton ? customCancelButton : onCancel && <OperationResetButton onClick={async ()=>{
                let ok = await showConfirm(intl, L(LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL));
                if (ok) onCancel();
            }} style={{marginLeft: 18}} />}
        </div>
    );
};

import React, { CSSProperties } from 'react';
import './RichTextView.less';

interface IProps {
    value?: string;
    style?: CSSProperties;
}

export class RichTextView extends React.PureComponent<IProps> {
    render = () => (
        <div
            className={'rr-rich-text ql-editor'}
            style={this.props.style}
            dangerouslySetInnerHTML={{ __html: this.props.value || '' }}
        ></div>
    );
}

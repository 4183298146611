import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../store/rtqQueryApi';
import { PaymentMethodInfoRead, PaymentMethodRecord, serverApi } from '../../../../../server';
import { GetDefaultPaymentMethodIdArgs, ListPaymentMethodsArgs, ListPaymentMethodsData } from './types';
import { AxiosError } from 'axios';
import { showNotification } from '../../../../../components/notification/showNotification';
import { PaymentMethodsUtils } from './paymentMethods.api.utils';
import { GetEntityByIdArgs } from '../../../../../components/page/entityGrid/types/api';
import { ServerUtils } from '../../../../../core/utils/serverUtils';
import { DeepKeys } from '@tanstack/react-table';

export const getPaymentMethodByIdQueryFunction = async ({ businessAccountId, entityId }: GetEntityByIdArgs) => {
    try {
        return await serverApi.getPaymentMethodById(businessAccountId, entityId);
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить способ оплаты');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const paymentMethodsApi = createApi({
    reducerPath: 'paymentMethodsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['PaymentMethodsList', 'PaymentMethod'],
    endpoints: (builder) => ({
        listPaymentMethods: builder.query<ListPaymentMethodsData, ListPaymentMethodsArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = PaymentMethodsUtils.createRequestFilters(params);
                const sortBy = params.sortBy || undefined;
                const sortOrder = params.sortBy ? params.sortOrder : undefined;

                try {
                    const response = await serverApi.listPaymentMethods(
                        businessAccountId,
                        params.limit,
                        startFrom,
                        filters,
                        sortBy,
                        sortOrder
                    );
                    return {
                        data: {
                            entitiesData: {
                                ...response.data,
                                records: response.data.records.map((item) => ({
                                    ...item,
                                })),
                            },
                        },
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['PaymentMethodsList'],
        }),
        getDefaultPaymentMethodId: builder.query<number | undefined, GetDefaultPaymentMethodIdArgs>({
            queryFn: async ({ businessAccountId }) => {
                try {
                    const sortBy: DeepKeys<PaymentMethodRecord> = 'name';
                    const response = await serverApi.listPaymentMethods(
                        businessAccountId,
                        undefined,
                        undefined,
                        ServerUtils.createRequestFilters<PaymentMethodRecord>([['isDefault', 'EQ', true]]),
                        sortBy
                    );
                    return {
                        data: response.data.records[0]?.id,
                    };
                } catch (error: unknown) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['PaymentMethodsList', 'PaymentMethod'],
        }),
        getPaymentMethodById: builder.query<PaymentMethodInfoRead, GetEntityByIdArgs>({
            queryFn: getPaymentMethodByIdQueryFunction,
            providesTags: ['PaymentMethod'],
        }),
    }),
});

export const {
    useListPaymentMethodsQuery,
    useGetPaymentMethodByIdQuery,
    useGetDefaultPaymentMethodIdQuery,
    useLazyGetDefaultPaymentMethodIdQuery,
} = paymentMethodsApi;

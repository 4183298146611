import React from 'react';
import { Icon } from 'antd';
import { DownloadDocumentPopover } from '../../DownloadDocumentPopover';
import { DocumentTypeCodeEnum } from '../../../../../server';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { IconClipboardCheckSolid } from '../../../../icons';
import { availableFileTypes, financialOptions, options, ProductsInventoryListOnDownload } from './utils/data';

interface IProps extends StateProps {
    onDownload: ProductsInventoryListOnDownload;
}

class _ExportProductsInventoryListPopover extends React.Component<IProps> {
    get exportOptions() {
        const exportOptions = [...options];
        if (canViewFinancialData(this.props.permissions)) {
            exportOptions.splice(4, 0, ...financialOptions);
        }
        return exportOptions;
    }

    render() {
        return (
            <DownloadDocumentPopover
                documentType={DocumentTypeCodeEnum.INVENTORYLIST}
                availableFileTypes={[...availableFileTypes]}
                storageKey={'productsInventoryList'}
                withTemplate
                options={this.exportOptions}
                title={
                    <>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconClipboardCheckSolid} />
                        <div>Скачать инвентарный лист</div>
                    </>
                }
                onDownload={this.props.onDownload}
                showLegalDetails
                isCompact
            >
                <span className={'rr-grid-export-button'}>
                    <Icon component={IconClipboardCheckSolid} />
                    <span>Инвентарный лист</span>
                </span>
            </DownloadDocumentPopover>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    permissions: storeState.permissions.permissions,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const ExportProductsInventoryListPopover = connect(mapStateToProps)(_ExportProductsInventoryListPopover);

import React, { createRef, ReactNode, RefObject } from 'react';
import { Button, Icon, Select } from 'antd';
import { LocalizationEnum, localize } from '../../../../localization';
import { IconCheck } from '../../../../components/icons';
import { EntityRemoteSelect } from '../../../../components/select/EntityRemoteSelect';
import { NomenclatureRecord, NomenclatureStateCodeEnum } from '../../../../server';
import { SelectValue } from 'antd/lib/select';
import { Popover as Popover1 } from '../../../../components/popover/Popover';
import './add-kit-popover.less';
import classNames from 'classnames';
import { Dot } from '../../../../components/dot/dot';
import ReactDOM from 'react-dom';

export interface ProductValue extends NomenclatureRecord {
    id: string;
}

interface IProps {
    children: ReactNode;
    getNewProduct: (value: ProductValue[] | undefined) => void;
    showIcons?: boolean;
    forProduct?: boolean;
    stateCode?: NomenclatureStateCodeEnum;
    selectedIds?: string[];
}

interface IState {
    visible: boolean;
    value?: SelectValue;
}

export class AddKitPopover extends React.Component<IProps, IState> {
    _ref: RefObject<Select>;

    constructor(props) {
        super(props);

        this._ref = createRef();

        this.state = {
            visible: false,
        };
    }

    componentDidUpdate(_: IProps, prevState: IState) {
        if (this.state.visible !== prevState.visible && this.state.visible) {
            setTimeout(() => {
                const node = ReactDOM.findDOMNode(this._ref.current);
                if (node != null) {
                    const event = new MouseEvent('click', { bubbles: true });
                    node.dispatchEvent(event);
                }
            }, 250);
        }
    }

    handleVisibleChange = (visible: boolean) => {
        if (!visible) {
            this.setState({ visible, value: undefined });
        } else {
            this.setState({ visible });
        }
    };

    onButtonClick = async (e) => {
        e.stopPropagation();
        if (Array.isArray(this.state.value)) {
            this.props.getNewProduct((this.state.value as any).map((item) => item.data));
        } else {
            this.props.getNewProduct([(this.state.value as any).data]);
        }

        this.setState({
            value: undefined,
        });

        this.handleVisibleChange(false);
    };

    onChange = (value: SelectValue) => {
        if (value) {
            this.setState({
                value: value,
            });
        } else {
            this.setState({
                value: undefined,
            });
        }
    };

    notAdd = (e, isLocked: boolean) => {
        if (isLocked) e.stopPropagation();
    };

    isLocked = (item: NomenclatureRecord): boolean => {
        const { selectedIds } = this.props;
        const itemStateCode = item.stateCode;

        if (selectedIds != null && selectedIds.includes(String(item.productId))) return true;

        return this.props.stateCode === NomenclatureStateCodeEnum.ACTIVE && itemStateCode !== NomenclatureStateCodeEnum.ACTIVE;
    };

    customChangeData = (data: Array<NomenclatureRecord>) => {
        const newData = data.map((item) => {
            let id = '';
            let name;
            let isLock = <></>;
            let isLocked = this.isLocked(item);

            if (item.nomenclatureEntityTypeCode === 'VARIANT') {
                id = 'v' + item.variantId;
                name = (
                    <div
                        onClick={(e) => this.notAdd(e, isLocked)}
                        style={
                            isLocked
                                ? {
                                      color: '#ccc',
                                      cursor: 'not-allowed',
                                      margin: '-5px -12px',
                                      padding: '5px  12px',
                                  }
                                : {}
                        }
                    >
                        {isLock} <span>{item.productShortName + ': ' + item.variantName}</span>
                    </div>
                );
            } else {
                id = 'p' + item.productId;
                name = (
                    <div
                        onClick={(e) => this.notAdd(e, isLocked)}
                        style={
                            isLocked
                                ? {
                                      color: '#ccc',
                                      cursor: 'not-allowed',
                                      margin: '-5px -12px',
                                      padding: '5px 12px',
                                  }
                                : {}
                        }
                    >
                        {isLock} <span>{item.productShortName}</span>
                    </div>
                );
            }

            return { ...item, id, name };
        });

        return newData;
    };

    optionRenderer = ({ id, data }: { id: number; data: NomenclatureRecord }) => {
        const { stateCode, productId } = data;
        const isSelected = (this.props.selectedIds ?? []).some((selectedId) => selectedId === String(productId));
        const isBlocked = stateCode === NomenclatureStateCodeEnum.NOTAVAILABLE;

        return (
            <Select.Option disabled={isSelected || isBlocked} key={data['id']}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {isSelected && <Icon component={IconCheck} className={classNames('crew-member-icon', 'select-option')} />}
                    {isBlocked && <Dot className={`rr-status-bg-` + stateCode} style={{ marginRight: 10 }} />}
                    {data['name']}
                </div>
            </Select.Option>
        );
    };

    render() {
        const { visible } = this.state;

        return (
            <Popover1
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                headerClassName={'rr-grid-actions-popover-header-xxx'}
                overlayClassName={'rr-operationForm-kit-add-element-popover rr-operationForm-kit-add-element-popover1'}
                destroyTooltipOnHide
                header={
                    <>
                        <EntityRemoteSelect
                            _ref={this._ref}
                            multiple={true}
                            maxTagCount={5}
                            style={{ width: '100%' }}
                            placeholder={<>{localize(LocalizationEnum.PAGE__KITS__COMPONENTS_TAB__SELECT_NOMENCLATURE)}</>}
                            nameField={'name'}
                            onChange={this.onChange}
                            className={classNames('rr-select-gray')}
                            showSearch={true}
                            operationName={'listNomenclature'}
                            value={this.state.value}
                            filters={[`addableToKit;EQ;true`, `archive;EQ;false`]}
                            sortOrder={'DESC'}
                            sortBy={'lastActivityDate'}
                            dropdownStyle={{ maxWidth: 360 }}
                            renderer={this.optionRenderer}
                            customChangeData={this.customChangeData}
                        />
                        <Button
                            className={'rr-btn-blue'}
                            style={{ marginLeft: 8 }}
                            onClick={this.onButtonClick}
                            disabled={!this.state.value}
                        >
                            OK
                        </Button>
                    </>
                }
            >
                {this.props.children}
            </Popover1>
        );
    }
}

import React, { CSSProperties, FC, ReactNode } from 'react';
import { SliderRangeProps } from '../../SliderRange';
import { Slider } from 'antd';
import { SliderValue } from 'antd/lib/slider';
import './styles.less';

interface SliderRangeWrapperProps extends SliderRangeProps {
    marks: SliderRangeProps['marks'];
    onSliderChange: (value?: SliderValue) => void;
}

export const SliderRangeWrapper: FC<SliderRangeWrapperProps> = (props) => {
    const { marks, onSliderChange, step, ...restProps } = props;

    if (marks == null) return <Slider {...props} range tooltipVisible={false} onChange={onSliderChange} />;

    let Mark:
        | ReactNode
        | {
              style: CSSProperties;
              label: ReactNode;
          } = undefined;

    if (Object.keys(marks).length === 1) {
        Mark = Object.values(marks)[0];

        if (!React.isValidElement(Mark)) {
            Mark = (
                Mark! as {
                    style: CSSProperties;
                    label: ReactNode;
                }
            ).label;
        }
    }

    const isStepDecimal = Array.isArray(restProps.value) ? restProps.value.some((value) => String(value).includes('.')) : false;

    return (
        <div className={'slider-range'}>
            <div className={'custom-mark'}>{Mark}</div>
            <Slider
                {...restProps}
                range
                tooltipVisible={false}
                onChange={onSliderChange}
                marks={marks}
                step={isStepDecimal && step ? 0.01 : undefined}
            />
        </div>
    );
};

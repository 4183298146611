import React, { FC, memo } from 'react';
import {DownloadDocumentPopover, IDownloadDocumentParamsItem} from '../../DownloadDocumentPopover';
import { Icon } from 'antd';
import { IconDownloadSolid } from '../../../../icons';
import {
    availableFileTypes,
    ExportOffloadingPopoverOptions,
    exportOffloadingPopoverOptions,
    OffloadingDocumentOnDownload
} from './utils/data';

interface ExportOffloadingPopoverProps {
    onDownload: OffloadingDocumentOnDownload;
    storageKey: string;
    options?: IDownloadDocumentParamsItem<ExportOffloadingPopoverOptions>[];
}

export const ExportOffloadingPopover: FC<ExportOffloadingPopoverProps> = memo((props) => {
    const { onDownload, storageKey } = props;

    return (
        <DownloadDocumentPopover
            availableFileTypes={[...availableFileTypes]}
            storageKey={storageKey}
            options={props.options || exportOffloadingPopoverOptions}
            title={
                <>
                    <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconDownloadSolid} />
                    <div>Скачать выгрузку</div>
                </>
            }
            onDownload={onDownload}
            isCompact
        >
            <span className={'rr-grid-export-button'} style={{ marginRight: 17 }}>
                <Icon component={IconDownloadSolid} />
                <span>Выгрузка</span>
            </span>
        </DownloadDocumentPopover>
    );
});

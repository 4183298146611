import { ProjectInfoRead, WorkPlanningsInfoRead } from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { WorkPlanningsEditModal } from './workPlanningsEditModal';
import React, { FunctionComponent, memo } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { WorkPlanningCreateModalFormData } from '../create/workPlanningsCreateModal.types';
import { ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';

export type WorkPlanningsEditModalWrapperProps<EntityInfoRead extends object> = {
    entity: EntityInfoRead;
    projectId: ProjectInfoRead['id'];
    projectType?: ProjectInfoRead['projectType'];
    onOkCallback?: () => void;
} & Partial<ModalFormNEWProps>;

export type EntityGridEditModalWrapper<EntityInfoRead extends object> = FunctionComponent<
    WorkPlanningsEditModalWrapperProps<EntityInfoRead>
>;

export const WorkPlanningsEditModalWrapper: EntityGridEditModalWrapper<WorkPlanningsInfoRead> = memo((props) => {
    const { entity, projectId, projectType, initialValues: _initialValues } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const initialValues: Partial<WorkPlanningCreateModalFormData> = {
        ..._initialValues,
        projectId,
        projectType,
    };

    return (
        <WorkPlanningsEditModal
            initialValues={initialValues}
            businessAccountId={businessAccountId}
            title={'Редактирование работ'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            workPlanning={entity}
        />
    );
});

import { useCallback, useState } from 'react';

export type DrawerEntityId = number | undefined;
export const useIdForDrawer = () => {
    const [entityIdForDrawer, setEntityIdForDrawer] = useState<DrawerEntityId>(undefined);

    const clearEntityId = useCallback(() => {
        setEntityIdForDrawer(undefined);
    }, []);

    return [entityIdForDrawer, setEntityIdForDrawer, clearEntityId] as [DrawerEntityId, (entityId: DrawerEntityId) => void, () => void];
};

import { AccessibilityMapCalendarData } from '../utils/getAccessibilityMapCalendarDataFromBase64';
import { TimetableTypeCodeEnum } from '../../../../../../server';
import { useCalendarItems } from './useCalendarItems';
import { useEffect, useMemo, useState } from 'react';

export const useCalendarData = (
    itemsData: AccessibilityMapCalendarData[] | undefined,
    stacks: TimetableTypeCodeEnum[],
    screenLeft: number,
    screenRight: number,
    accessibilityEntitySelected: boolean | undefined,
    isLoading: boolean
) => {
    const items = useCalendarItems(itemsData, stacks, screenLeft, screenRight, accessibilityEntitySelected);

    const [visibleTimeStart, setVisibleTimeStart] = useState(screenLeft);
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(screenRight);
    const [deferredItems, setDeferredItems] = useState(items);

    useEffect(() => {
        if (!isLoading) {
            setVisibleTimeStart(screenLeft);
            setVisibleTimeEnd(screenRight);
            setDeferredItems(items);
        }
    }, [isLoading, items, screenLeft, screenRight]);

    return useMemo(
        () => ({
            deferredItems,
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [deferredItems, visibleTimeEnd, visibleTimeStart]
    );
};

import {PaymentMethodInfoRead} from '../../../../../../server';
import {LocalizationEnum, localize} from '../../../../../../localization';
import {PaymentMethodEditModal} from './paymentMethodEditModal';
import React, {memo} from 'react';
import {useAppSelector} from '../../../../../../store/hooks';
import {businessAccountIdSelector} from '../../../../../../shared/reducers/system.reducer';
import {EntityGridEditModalWrapper} from '../../../../../../components/page/entityGrid/types/modal';

export const PaymentMethodEditModalWrapper: EntityGridEditModalWrapper<PaymentMethodInfoRead> = memo(({entity}) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <PaymentMethodEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование способа оплаты'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            entity={entity}
        />
    );
});

import { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';

interface RentShortageBorderProps {
    width: number;
}

export const RentShortageBorder: FC<RentShortageBorderProps> = (props) => {
    const { width } = props;
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);
    const { item } = useContext(ElementItemContext);
    const { start_time, end_time } = item;

    const startsAfterLeftBoundary = +start_time > visibleTimeStart;
    const endsBeforeRightBoundary = +end_time < visibleTimeEnd;

    const border = `2px dashed ${CalendarColors.staticColors.WARNING_COLOR}`;

    return (
        <div
            className={'calendar-line-border'}
            style={{
                border,
                borderRight: endsBeforeRightBoundary ? border : 'none',
                borderLeft: startsAfterLeftBoundary ? border : 'none',
                width,
            }}
        />
    );
};

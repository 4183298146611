import { ProfessionInfoRead } from '../../../../../../../../server';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { ProfessionCreateModal } from '../CreateModal/ProfessionCreateModal';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { goBack, push } from 'connected-react-router';
import { ParamsUtils } from '../../../../../../../../core/utils/paramsUtils';
import { EntityGridCreateModalWrapper } from '../../../../../../../../components/page/entityGrid/types/modal';

export const ProfessionCreateModalGridWrapper: EntityGridCreateModalWrapper<ProfessionInfoRead> = memo(({ rootPath, urlSearchParams }) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <ProfessionCreateModal
            businessAccountId={businessAccountId}
            title={'Создание новой специальности'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            modalName={'create-profession'}
            onCancel={() => {
                dispatch(goBack());
            }}
            onSuccess={(data) => {
                dispatch(push(`${rootPath}${urlSearchParams ? `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}` : ''}`));
            }}
        />
    );
});

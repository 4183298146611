import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { AxiosError } from 'axios';
import { serverApi, WorkPlanningGroupRecordList, WorkPlanningsRecord, WorkPlanningsRecordList } from '../../../../../../server';
import { ListWorkPlanningsArgs, ListWorkPlanningsGroupByCrewMemberArgs } from './workPlanningsCalendar.api.types';
import { WorkPlanningsCalendarApiUtils } from './workPlanningsCalendar.api.utils';
import { setCalendarBoundaries } from '../reducers/workPlanningsCalendar.reducer';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

export const workPlanningsCalendarApi = createApi({
    reducerPath: 'workPlanningsCalendarApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['WorkPlanningsList'],
    endpoints: (builder) => ({
        listWorkPlannings: builder.query<WorkPlanningsRecordList, ListWorkPlanningsArgs>({
            queryFn: async ({ businessAccountId, params, visibleItemsLimit }, { dispatch }) => {
                const sortBy = WorkPlanningsCalendarApiUtils.getSortBy(params.group);
                const sortOrder = WorkPlanningsCalendarApiUtils.getSortOrder(sortBy);
                const filters = WorkPlanningsCalendarApiUtils.getFilters(params);

                try {
                    const { data } = await serverApi.listWorkPlannings(
                        businessAccountId,
                        visibleItemsLimit,
                        0,
                        filters,
                        sortBy,
                        sortOrder,
                        params.search
                    );

                    dispatch(
                        setCalendarBoundaries({
                            params,
                            records: data.records,
                        })
                    );

                    return {
                        data,
                    };
                } catch (error) {
                    showNotification('error', 'Не удалось загрузить список работ');
                    return {
                        error: error as AxiosError,
                    };
                }
            },
            providesTags: ['WorkPlanningsList'],
        }),
        listWorkPlanningsGroupByCrewMember: builder.query<WorkPlanningGroupRecordList, ListWorkPlanningsGroupByCrewMemberArgs>({
            queryFn: async (args, { getState, dispatch }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);
                const { params } = args;
                const sortBy = WorkPlanningsCalendarApiUtils.getSortBy(params.group);
                const sortOrder = WorkPlanningsCalendarApiUtils.getSortOrder(sortBy);
                const filters = WorkPlanningsCalendarApiUtils.getFilters(params);

                try {
                    const { data } = await serverApi.listWorkPlanningsGroupedByCrewMember(businessAccountId, filters, sortBy, sortOrder);

                    const allRecords =
                        data.records?.reduce((acc, { workPlannings }) => {
                            acc.push(...(workPlannings ?? []));

                            return acc;
                        }, [] as WorkPlanningsRecord[]) ?? [];

                    dispatch(
                        setCalendarBoundaries({
                            params,
                            records: allRecords,
                        })
                    );

                    return {
                        data,
                    };
                } catch (error) {
                    showNotification('error', 'Не удалось загрузить список работ');
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useListWorkPlanningsQuery, useListWorkPlanningsGroupByCrewMemberQuery } = workPlanningsCalendarApi;

import { getBusinessAccountId, getStore, getStoreState } from '../../../index';
import { updateGridSettings } from '../../shared/reducers/userSettings/userSettings.reducer';
import _ from 'lodash';

export const getGridStorageKey = (gridName: string, withBa: boolean = false) => {
    let login = getStoreState().oidc.user?.profile.email;
    let ba = getBusinessAccountId();
    return `rr-grid-${gridName}` + (login ? '_' + login : '') + (ba && withBa ? '_' + ba : '');
};

export interface GridStorageData {
    columns?: { [k: string]: { hidden?: boolean; width?: number } };
    columnsOrder: [];
    hideCategories?: boolean;
    filters?: { [k: string]: any };
    visibleFilters?: { [k: string]: any };
    hideFilters?: boolean;
    viewMode?: 'compact' | 'normal';
    params?: {
        sortBy?: string;
        sortOrder?: 'ASC' | 'DESC';
        limit?: number;
    };
}

interface GridCategoriesStorageData {
    selectedIds?: string[];
    expandedIds?: string[];
}

export const getGridStorageData = (gridName: string): GridStorageData => {
    // let key = getGridStorageKey(gridName, true);
    // if(!localStorage.getItem(key)){
    //     key = getGridStorageKey(gridName, false);
    // }
    //let data:GridStorageData = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '{}') : {};
    const data = (getStore().getState().userSettings.gridSettings[gridName] || {}) as GridStorageData;

    if (!data.columns) {
        // Если были данные то нужно скопировать в колонки
        let columns = {};
        for (let k in data) {
            if (k !== 'columns' && k !== 'filters' && k !== 'expandedCategoryIds') columns[k] = data[k];
        }
        data.columns = columns;
    }
    if (!data.filters) data.filters = {};
    if (!data.params) data.params = {};
    return data;
};

export const setGridStorageData = (gridName: string, data: GridStorageData) => {
    let key = getGridStorageKey(gridName, true);
    localStorage.setItem(key, JSON.stringify(data));
};

// Нужен метод, по которому можем задать фильтр
export const setGridStorageDataFilters = (gridName: string, filters: { [k: string]: any }) => {
    let gridData = { ...getGridStorageData(gridName) };
    gridData.filters = _.assign({
        ...gridData.filters,
        ...filters,
    });
    // Тут не даем сохранять номер страницы
    if (gridData.filters.page !== undefined) delete gridData.filters.page;
    getStore().dispatch(updateGridSettings({ [gridName]: gridData }));
    window.dispatchEvent(new Event('storage'));
};

export const getGridCategoriesStorageData = (gridName: string): GridCategoriesStorageData => {
    const data = getStore().getState().userSettings.gridSettings[gridName + '-categories'] || {};
    if (!data.selectedIds) data.selectedIds = [];
    if (!data.expandedIds) data.expandedIds = [];
    return data;
};

export const setGridCategoriesStorageData = (gridName: string, data: GridCategoriesStorageData) => {
    let gridData = getGridCategoriesStorageData(gridName);
    gridData = {
        ...gridData,
        ...data,
    };
    getStore().dispatch(updateGridSettings({ [gridName + '-categories']: gridData }));
    window.dispatchEvent(new Event('storage'));
};

import { FC, useContext } from 'react';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { CALENDAR_LINE_ROUND_RADIUS } from '../../../../../../../../../Calendar/utils/data/calendarConstants';
import { ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';

interface SubrentReturnDelayBorderProps {
    width: number;
}

export const SubrentReturnDelayBorder: FC<SubrentReturnDelayBorderProps> = (props) => {
    const { width } = props;
    const { item } = useContext(ElementItemContext);
    const { visibleTimeStart } = useContext(TimeRangeContext);

    const { stateCode } = item;

    const startsAfterLeftBoundary = +item.start_time > visibleTimeStart;

    const borderColor = CalendarColors.elementsStateCodeMap[stateCode];
    const border = `2px solid ${borderColor}`;
    const borderRadius = CALENDAR_LINE_ROUND_RADIUS;

    return (
        <div
            className={'calendar-line-border'}
            style={{
                border,
                borderColor,
                borderRadius,
                borderLeft: startsAfterLeftBoundary ? border : 'none',
                borderTopLeftRadius: startsAfterLeftBoundary ? borderRadius : 0,
                borderBottomLeftRadius: startsAfterLeftBoundary ? borderRadius : 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: 'none',
                width,
            }}
        />
    );
};

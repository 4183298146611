import { IncludeOrExcludeOfferInProjectPriceBulkArgs, SetStatusForOffersArgs } from '../../api/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showNotification } from '../../../../../components/notification/showNotification';
import { ProjectRecord, serverApi, ServerError } from '../../../../../server';
import { offersApi } from '../../api/offers.api';
import { RootState } from '../../../../../../index';
import { loadOffer } from '../offer/offer.reducer';

const ACTION_TYPES = {
    CHANGE_STATUS: 'offers/CHANGE_STATUS',
    INCLUDE_OR_EXCLUDE_OFFER_IN_PROJECT_PRICE: 'offers/INCLUDE_OR_EXCLUDE_OFFER_IN_PROJECT_PRICE',
};

export interface OffersState {
    loading: boolean;
    loadingError?: ServerError;
    entities?: Array<ProjectRecord>;
    filteredCount: number;
}

export const initialState: OffersState = {
    loading: false,
    loadingError: undefined,
    entities: undefined,
    filteredCount: 0,
};

export const setStatusForOffers = createAsyncThunk(
    ACTION_TYPES.CHANGE_STATUS,
    async (args: SetStatusForOffersArgs, { dispatch, getState }) => {
        const { businessAccountId, workflowDirectiveBulk } = args;

        try {
            await serverApi.transitProjectWorkflowBulk(businessAccountId, workflowDirectiveBulk);

            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1
                    ? 'Статус смет успешно обновлен'
                    : 'Статус сметы успешно обновлен';
            showNotification('success', notificationMessage);
            dispatch(offersApi.util?.invalidateTags(['ProjectsList', 'Project']));
            const offer = (getState() as RootState).offer.entity;
            if (offer) dispatch(loadOffer({ businessAccountId: offer.businessAccountId, entityId: offer.id }));
        } catch (error: unknown) {
            const notificationMessage =
                workflowDirectiveBulk.directives.length > 1
                    ? 'Не удалось обновить статус смет'
                    : 'Не удалось обновить статус сметы';
            showNotification('error', notificationMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

export const includeOrExcludeOfferInProjectPriceBulk = createAsyncThunk(
    ACTION_TYPES.INCLUDE_OR_EXCLUDE_OFFER_IN_PROJECT_PRICE,
    async (args: IncludeOrExcludeOfferInProjectPriceBulkArgs, { dispatch, getState }) => {
        const { businessAccountId, include, versionedEntityObjList } = args;

        try {
            await serverApi.includeOrExcludeOfferInProjectPriceBulk(businessAccountId, include, versionedEntityObjList);

            const notificationMessage =
                versionedEntityObjList.entities.length > 1
                    ? include
                        ? 'Сметы включены в стоимость'
                        : 'Сметы исключены из стоимости'
                    : include
                    ? 'Смета включена в стоимость'
                    : 'Смета исключена из стоимости';
            showNotification('success', notificationMessage);
            dispatch(offersApi.util?.invalidateTags(['ProjectsList', 'Project']));
            const offer = (getState() as RootState).offer.entity;
            if (offer) dispatch(loadOffer({ businessAccountId: offer.businessAccountId, entityId: offer.id }));
        } catch (error: unknown) {
            const notificationMessage =
                versionedEntityObjList.entities.length > 1
                    ? include
                        ? 'Не удалось включить в стоимость сметы'
                        : 'Не удалось исключить из стоимости сметы'
                    : include
                        ? 'Не удалось включить в стоимость смету'
                        : 'Не удалось исключить из стоимости смету';
            showNotification('error', notificationMessage);
            const errorMessage = error instanceof Error ? error.message : undefined;
            throw new Error(errorMessage);
        }
    }
);

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {},
});

export default offersSlice.reducer;

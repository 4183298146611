import { TableColumn } from '../../components/grid/Table';
import { TblColumn, TblColumnType } from './tblColumns.types';
import { tblColumnsEntityName } from './renderers/tblColumnsEntityName';
import { tblColumnsCount } from './renderers/tblColumnsCount';
import { tblColumnsStatus } from './renderers/tblColumnsStatus';
import { tblColumnsMoney } from './renderers/tblColumnsMoney';
import { tblColumnsPrice } from './renderers/tblColumnsPrice';
import { tblColumnsShiftCount } from './renderers/tblColumnsShiftCount';
import { tblColumnsActions } from './renderers/tblColumnsActions';
import { tblColumnsDate } from './renderers/tblColumnsDate';
import { tblColumnsClick } from './renderers/tblColumnsClick';
import { tblColumnsBoolean } from './renderers/tblColumnsBoolean';
import { tblColumnsUser } from './renderers/tblColumnsUser';
import { tblColumnsSettingsActions } from './renderers/tblColumnsSettingsActions';
import { tblColumnsText } from './renderers/tblColumnsText';
import { tblColumnsLink } from './renderers/tblColumnsLink';
import {tblColumnsLastUpdateDate} from "./renderers/tblColumnsLastUpdateDate";

export { TblColumnType };

export const tblColumns = <EntityRecord extends object, ActionType extends string = ''>(
    columns: TblColumn<EntityRecord, ActionType>[]
): TableColumn<EntityRecord>[] => {
    return columns.map((column) => {
        return tblColumn(column);
    });
};

export const tblColumn = <EntityRecord extends object, ActionType extends string = ''>(
    column: TblColumn<EntityRecord, ActionType>
): TableColumn<EntityRecord> => {
    const map: Record<TblColumnType, (column: TblColumn<EntityRecord, ActionType>) => TableColumn<EntityRecord>> = {
        [TblColumnType.TEXT]: tblColumnsText,
        [TblColumnType.ENTITY_NAME]: tblColumnsEntityName,
        [TblColumnType.COUNT]: tblColumnsCount,
        [TblColumnType.STATUS]: tblColumnsStatus,
        [TblColumnType.MONEY]: tblColumnsMoney,
        [TblColumnType.PRICE]: tblColumnsPrice,
        [TblColumnType.SHIFT_COUNT]: tblColumnsShiftCount,
        [TblColumnType.DATE]: tblColumnsDate,
        [TblColumnType.LAST_UPDATE_DATE]: tblColumnsLastUpdateDate,
        [TblColumnType.ACTIONS]: tblColumnsActions,
        [TblColumnType.SETTINGS_ACTIONS]: tblColumnsSettingsActions,
        [TblColumnType.CLICK]: tblColumnsClick,
        [TblColumnType.BOOLEAN]: tblColumnsBoolean,
        [TblColumnType.USER]: tblColumnsUser,
        [TblColumnType.LINK]: tblColumnsLink,
    };
    return map[column.type](column);
};
